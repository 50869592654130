// Main stepper
export const STEPONE = 1
export const STEPTWO = 2
export const STEPTHREE = 3
export const STEPFOUR = 4
export const STEPFIVE = 5
export const STEPSIX = 6

// Auction main tabs
export const AUCTIONINFO = "Auction Info"
export const AUCTIONDETAILS = "Auction Details"
export const CHECKLIST = "Checklist"
export const INVOICESETTINGS = "Invoices Settings"
export const EINVOICES = "E-Invoices"
export const VALUESETTINGS = "Values Settings"
export const AUCTIONCLOSE = "Auction Close"
export const EMAILREPORTS = "Email Reports"

// Auction info tabs
export const ADDRESSDATES = "Address & Dates"
export const LOGOPHOTOS = "Logo & Photos"
export const AUCTOMATEDEMAIL = "Automated Email"
export const WEBTEXT = "Web Text"

export const AUCTIONSELLER = "Auction Seller"
export const AUCTIONCUSTOMERS = "Auction Customers"
export const AUCTIONINVENTORY = "Auction Inventory"
export const AUCTIONINVOICE = "Auction Invoice"
export const AUCTIONSALES = "Auction Sales"
export const AUCTIONSALESREP = "Auction Sales Rep"

export const AUCTIONCHECKLIST = "Checklist"

export const AUTOINVOICING = "Auto Invoicing"
export const INVOICEFOOTER = "Invoice Footer"
export const SELLERREPORTEMAILSETTING = "Seller Report Email Setting"

export const AUCTIONEINVOICES = "E-Invoices"

export const BUYERSPREMIUM = "Buyers Premium"
export const COMMISSIONSANDCATEGORIES = "Commissions & Categories"
export const AUCTIONRESULTSCONFIG = "Auction Results Config"