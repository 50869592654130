import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { GETSALESWITHCOST } from '../../../../common/apiConstatnts';
import * as routeConstant from '../../../../common/routeConstants';
import { encryptId } from '../../../../Encryption/Encryption';
import handleError from '../../../../Utils/ApiCommonErrorMessages';
import { formatCurrency, formatNumber, removeHtmlTags } from '../../../../Utils/Utils';


const SalesWithCost = ({ activeTab, setValue, calculationData, setCalculationData, setLoading, setSalesWithCostData, salesWithCostData }: any) => {
    const salesRepDataTab = useSelector((state: any) => state.salesRep.AuctionInfoActiveTab)
    const [searchText, setSearchText] = useState('')
    const salesRepDetails = useSelector((state: any) => state.salesRep.contact)
    const selectedAuction = useSelector((state: any) => state.salesRep.selectedAuction);

    const auctionId = useSelector((state: any) => state.salesRep.selectedAuction.value);
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
    useEffect(() => {
        if (auctionId && activeTab == 'SalesWithCost' && salesRepDataTab == 'sales') {
            setLoading(true)
            axios.get(`${GETSALESWITHCOST}?salesRepId=${salesRepDetails.saleRepId}&auctionId=${auctionId}`, config).then((response) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    setSalesWithCostData([]);
                    return;
                }
                else {
                    const data = response.data.data
                    setValue('defaultScaleSold', data?.defaultScaleSold)
                    setValue('defaultScalesPassed', data?.defaultScalesPassed)
                    setValue('defaultScalesNonPayer', data?.defaultScalesNonPayer)
                    setValue('defaultScalesBuyback', data?.defaultScalesBuyback)
                    setSalesWithCostData(data)
                    setCalculationData(data)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }
    }, [auctionId])
    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = salesWithCostData?.filter((x: any) =>
            x.title?.toLocaleLowerCase()?.includes(keyword) ||
            x.status?.toLocaleLowerCase()?.includes(keyword) ||
            x.lotNo?.toString()?.includes(keyword)
        )
        setSalesWithCostData(filterData)

    }
    const generateExcelWithTitle = () => {
        let data = salesWithCostData.map((data: any) => {
            return {
                lotNo: data?.lotNo,
                title: data?.title,
                extPrice: formatCurrency(data?.extPrice),
                buyersPrem: formatCurrency(data?.buyersPrem),
                total: formatCurrency(data?.total),
                commission: formatCurrency(data?.commission),
                scale: data?.scale,
                invoiceId: data?.invoiceId,
                lotStatus: data?.lotStatus,
            }
        })
        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Sales With Cost Data'],
            [`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Lot #', 'Title', 'Ext Price', 'Buyers Prem', 'Ext Price + BP', 'Commission', 'Scale', 'Status', 'Invoice Id'],
            ...data.map((item: any) => Object.values(item))
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Sales_With_Cost.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    const exportPDF = () => {
        const doc: any = new jsPDF('l', 'mm', 'a4');
        doc.setFont("helvetica", "bold").setFontSize(14).text(`Sales Rep Name: ${salesRepDetails.firstName} ${salesRepDetails.LastName}`, 15, 10);
        doc.text(`Auction Name: ${selectedAuction?.label}`, 15, 20);
        doc.setFontSize(11).text(`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Lot #', 'Title', 'Ext Price', 'Buyers Prem', 'Ext Price + BP', 'Commission', 'Scale', 'Status', 'Invoice Id']]
        const data = salesWithCostData.map((data: any) => {
            return {
                lotNo: data?.lotNo,
                title: data?.title,
                extPrice: formatCurrency(data?.extPrice),
                buyersPrem: formatCurrency(data?.buyersPrem),
                total: formatCurrency(data?.total),
                commission: formatCurrency(data?.commission),
                scale: data?.scale,
                invoiceId: data?.invoiceId,
                lotStatus: data?.lotStatus,
            }
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Sales_With_Cost.pdf`);
    }

    // useEffect(() => {
    //     handleSearch(searchText);
    // }, [searchText])
    return (
        <div className="mt-3 mb-4 border-300">
            <div className="col-12 d-flex flex-column justify-content-start">
                <div className="mb-3 d-flex justify-content-between">
                    {/* <div className="search-box mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="d-flex gap-2">
                        <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" type="button" onClick={generateExcelWithTitle} disabled={salesWithCostData?.length <= 0}>
                            <i className="fas fa-download"></i>
                            <span>Excel</span>
                        </button>
                        <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" type="button" onClick={() => exportPDF()} disabled={salesWithCostData?.length <= 0}>
                            <i className="fas fa-download"></i>
                            <span>Pdf</span>
                        </button>
                    </div> */}
                </div>
            </div>
            <div className="table-responsive scrollbar mb-3">
                <table className="table fs--1 mb-4 border-top border-200">
                    <thead>
                        <tr>
                            <th className="bg-gradiant " scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Lot #</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="User">
                                <div className='d-flex align-items-center'>
                                    <span>Title</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Email">
                                <div className='d-flex align-items-center'>
                                    <span>Ext. Price</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Email">
                                <div className='d-flex align-items-center'>
                                    <span>Buyers Prem.</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Email">
                                <div className='d-flex align-items-center'>
                                    <span>Ext Price + BP</span>
                                </div>
                            </th>

                            <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                <div className='d-flex align-items-center'>
                                    <span>Commission</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                <div className='d-flex align-items-center'>
                                    <span>Scale</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                <div className='d-flex align-items-center'>
                                    <span>Status</span>
                                </div>
                            </th>
                            <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                <div className='d-flex align-items-center'>
                                    <span>Inv ID</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="list" id="table-latest-review-body">
                        {salesWithCostData?.lstSalesWithCost?.length > 0 ? salesWithCostData?.lstSalesWithCost?.map((data: any, index: any) => {
                            return (
                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                    <td className="align-middle  p-2">
                                        <Link
                                            className="btn text-decoration-underline m-0 p-0 fw-normal"
                                            data-bs-dismiss="modal"
                                            target="_blank"
                                            to={`${routeConstant.UPDATEINVENTORY
                                                }/${encryptId(data?.lotId)}`}
                                        >
                                            {data?.displayLotNo ? data?.displayLotNo : data?.lotNo}
                                        </Link>
                                    </td>
                                    <td className="align-middle  p-2">{removeHtmlTags(data?.title)}</td>
                                    <td className="align-middle review p-2">{data?.extPrice ? formatCurrency(data?.extPrice) : '$0.00'}</td>
                                    <td className="align-middle text-start status p-2">{data?.buyerPremium ? formatCurrency(data?.buyerPremium) : '$0.00'}</td>
                                    <td className="align-middle  p-2">{data?.totalPrice ? formatCurrency(data?.totalPrice) : '$0.00'}</td>
                                    <td className="align-middle text-start status p-2">{data?.commission ? formatCurrency(data?.commission) : '$0.00'}</td>
                                    <td className="align-middle text-start status p-2">{data?.scale}</td>
                                    <td className="align-middle white-space-pre p-2">
                                        {" "}
                                        <span
                                            className={`badge badge-dpa status-${data?.strLotStatus
                                                ?.replace(/ /g, "")
                                                .toLowerCase()}`}
                                        >
                                            {data?.strLotStatus}
                                        </span>
                                    </td>
                                    <td className="align-middle text-start status p-2">{data?.invoiceId}</td>
                                </tr>
                            )
                        }) :
                            <tr className="hover-actions-trigger btn-reveal-trigger position-static text-center">
                                <td colSpan={10}>No record found</td>
                                <p></p>
                            </tr>}
                    </tbody>
                </table>
            </div>
            <div className="table-responsive scrollbar mb-3 input-view">
                <table className="table fs--1 mb-4 border border-200">
                    <thead>
                        <tr>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Total Lots</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Sold</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Passed</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Buyback</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Non-Payer</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Total Price</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Inv Adj</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>SalesRep Adj</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Commission</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Commission + Adjust</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Sales Tax</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Payments</span>
                                </div>
                            </th>
                            <th className="bg-gradiant align-middle p-2 white-space-pre border" scope="col" data-sort="Initials">
                                <div className='d-flex align-items-center'>
                                    <span>Balance Due</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="list" id="table-latest-review-body">
                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalLots ? formatNumber(calculationData?.totalLots) : "0"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalSold ? formatNumber(calculationData?.totalSold) : "0"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalPassed ? formatNumber(calculationData?.totalPassed) : "0"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalBuyBack ? formatNumber(calculationData?.totalBuyBack) : "0"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalNonPayer ? formatNumber(calculationData?.totalNonPayer) : "0"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalPrice ? formatCurrency(calculationData?.totalPrice) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalInvAdj ? formatCurrency(calculationData?.totalInvAdj) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalSalesRepAdj ? formatCurrency(calculationData?.totalSalesRepAdj) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalComm ? formatCurrency(calculationData?.totalComm) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalCommAndAdj ? formatCurrency(calculationData?.totalCommAndAdj) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalSalesTax ? formatCurrency(calculationData?.totalSalesTax) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalPayments ? formatCurrency(calculationData?.totalPayments) : "$0.00"}
                            </td>
                            <td className="align-middle p-2 white-space-pre border">
                                {calculationData?.totalBalDue ? formatCurrency(calculationData?.totalBalDue) : "$0.00"}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SalesWithCost