
import jsPDF from "jspdf"
import moment from "moment"
import * as XLSX from 'xlsx'
import 'jspdf-autotable';
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import Swal from "sweetalert2";
import MomentUtil from "../../Utils/MomentUtil";
import { formatNumber } from "../../Utils/Utils";
import { formatPhoneNumber, UTCdateConverter } from "../../common/constants";
import * as XLSXJS from 'xlsx-js-style';

const formatAmount = (number: any) => {
  const absoluteValue = Math.abs(number);
  const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return number < 0 ? `(${formattedNumber})` : formattedNumber;
};

const formatAmount1 = (number: any) => {
  const absoluteValue = Math.abs(number);
  const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return number < 0 ? formattedNumber : `(${formattedNumber})`;
};

const momentUtil = MomentUtil.getInstance()
const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() });
const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();

{/** ********************** Seller Reports Start ************************* */ }

export const generateAllSellerSummaryPdf = (allSellerSummary: any) => {
  const columns = [
    { header: "#", dataKey: "sellerId" },
    { header: "Seller Name", dataKey: "sellerName" },
    // { header: "Mobile", dataKey: "mobile" },
    // { header: "Email", dataKey: "email" },
    { header: "Company", dataKey: "company" },
    { header: "Items", dataKey: "items" },
    { header: "Total Sold", dataKey: "totalSold" }
  ];

  const firstItem = allSellerSummary.length > 0 ? allSellerSummary[0] : {};

  let auctionId = firstItem.auctionCode || '';
  let auctionTitle = firstItem.auctionTitle || '';
  let city = firstItem.auctionCityName || '';
  let state = firstItem.auctionStateName || '';
  let zipCode = firstItem.auctionZipCode || '';
  let address = firstItem.auctionAddressLine1 || '';
  let auctionDate = firstItem.auctionStartDate || '';
  auctionDate = auctionDate.replace(/-/g, '/');

  const rows = allSellerSummary.map((item: any, index: any) => ({
    sellerId: { content: item.sellerId, styles: { halign: 'left' } },
    sellerName: { content: `(#${item.sellerId}) ${item.sellerName}`, styles: { halign: 'left' } },
    // mobile: { content: item.mobile ? `${formatPhoneNumber(item.mobile)}` : '-', styles: { halign: 'left' } },
    // email: { content: item.email, styles: { halign: 'left' } },
    company: { content: item.company ? item.company : '-', styles: { halign: 'left' } },
    items: { content: item.items ? item.items : '0', styles: { halign: 'center' } },
    totalSold: { content: item.items == 0 ? '$0.00' : `$${formatAmount(item.totalSold)}`, styles: { halign: 'right' } }
  }));

  const doc: any = new jsPDF("p", "pt");

  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 10, 30, 75, 30);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 100, 30, { fontStyle: 'bold' });
    doc.text(`${auctionTitle}`, 100, 45, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 100, 60);
    doc.text(`${city}, ${state} ${zipCode}`, 100, 75);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("All Seller Summary Report", rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 45, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${moment(auctionDate).format('MM/DD/YYYY')}`, rightAlignX, 60, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const todayTime = new Date().toLocaleString();
    doc.text(`${pageCount}`, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  doc.autoTable({
    // head: [columns.map(col => col.header)],
    head: [
      [
        { content: "#", styles: { halign: 'left' } },
        { content: "Seller Name", styles: { halign: 'left' } },
        { content: "Company", styles: { halign: 'left' } },
        { content: "Items", styles: { halign: 'center' } },
        { content: "Total Sold", styles: { halign: 'right' } },
      ]
    ],
    body: rows.map((row: any) => Object.values(row)),
    startY: 100,
    margin: { top: 100, left: 10, right: 10 },
    showHead: "everyPage",
    theme: 'striped',
    styles: {
      fontSize: 8,
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 }
    },
    headStyles: {
      halign: 'left',
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
      fillColor: null,  // No fill color
      textColor: '#666666',
    },
    didDrawPage: function () {
      header();
      footer();
    }
  });
  doc.save("All_Seller_Summary_Report.pdf");
};

export const generateSellerCommissionPdf = (sellerCommissionSummaryData: any) => {
  const doc: any = new jsPDF();

  const tableColumn = [
    { header: "#", dataKey: "sellerId" },
    { header: "Seller Name", dataKey: "sellerName" },
    { header: "Sold Count", dataKey: "sold" },
    { header: "Passed Count", dataKey: "passed" },
    { header: "Buyback Count", dataKey: "buyBack" },
    { header: "Non-Payer Count", dataKey: "nonPayer" },
    { header: "Comm Total", dataKey: "commTotal" },
    { header: "Adjust Total", dataKey: "adjustTotal" },
    { header: "Seller Lot Count", dataKey: "count" },
    { header: "Notes", dataKey: "notes" },
  ];

  const rows: any = [];

  let totals = {
    sold: 0,
    passed: 0,
    buyback: 0,
    nonPayer: 0,
    commTotal: 0,
    adjustTotal: 0,
    sellerLotCount: 0,
  };

  let auctionId = '';
  let auctionTitle = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let address = '';
  let auctionDate = '';

  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: 'bold' });
    doc.text(`${auctionTitle}`, 40, 17, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 40, 22);
    doc.text(`${city}, ${state} ${zipCode}`, 40, 27);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Seller Commission Report", rightAlignX, 12, { align: 'right', fontStyle: 'bold' });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 17, { align: 'right' });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${moment(auctionDate).format('MM/DD/YYYY')}`, rightAlignX, 22, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const todayTime = new Date().toLocaleString();
    doc.text(`${pageCount}`, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  sellerCommissionSummaryData?.forEach((item: any) => {
    auctionId = item.auctionCode;
    auctionTitle = item.auctionDisplayName;
    city = item.auctionCityName;
    state = item.auctionStateName;
    zipCode = item.auctionZipCode;
    address = item.auctionAddressLine1;
    auctionDate = item.auctionStartDate;
    auctionDate = auctionDate.replace(/-/g, '/');

    totals.sold += item.sold;
    totals.passed += item.passed;
    totals.buyback += item.buyback;
    totals.nonPayer += item.nonPayer;
    totals.commTotal += item.commTotal;
    totals.adjustTotal += item.adjustTotal;
    totals.sellerLotCount += item.sellerLotCount;

    rows.push({
      sellerId: { content: item.sellerId, styles: { halign: 'center' } },
      sellerName: { content: item.sellerName, styles: { halign: 'left' } },
      sold: { content: item.sold, styles: { halign: 'right' } },
      passed: { content: item.passed, styles: { halign: 'right' } },
      buyBack: { content: item.buyback, styles: { halign: 'right' } },
      nonPayer: { content: item.nonPayer, styles: { halign: 'right' } },
      commTotal: { content: item.commTotal == 0 ? '$0.00' : `$${formatAmount(item.commTotal)}`, styles: { halign: 'right' } },
      adjustsTotal: { content: item.adjustTotal == 0 ? '$0.00' : `$${formatAmount(item.adjustTotal)}`, styles: { halign: 'right' } },
      count: { content: item.sellerLotCount ? item.sellerLotCount : '0', styles: { halign: 'right' } },
      notes: { content: item.notes ? item.notes : '-', styles: { halign: 'left' } },

    })
  });

  const totalsRow = {
    sellerId: { content: '', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold' } },
    sellerName: { content: 'Totals', colSpan: 1, styles: { halign: 'left', fontStyle: 'bold' } },
    sold: { content: totals.sold, styles: { halign: 'right', fontStyle: 'bold' } },
    passed: { content: totals.passed, styles: { halign: 'right', fontStyle: 'bold' } },
    buyBack: { content: totals.buyback, styles: { halign: 'right', fontStyle: 'bold' } },
    unSold: { content: totals.nonPayer, styles: { halign: 'right', fontStyle: 'bold' } },
    commTotal: { content: totals.commTotal == 0 ? '$0.00' : `$${formatAmount(totals.commTotal)}`, styles: { halign: 'right', fontStyle: 'bold' } },
    adjustTotal: { content: totals.adjustTotal == 0 ? '$0.00' : `$${formatAmount(totals.adjustTotal)}`, styles: { halign: 'right', fontStyle: 'bold' } },
    sellerLotCount: { content: totals.sellerLotCount ? totals.sellerLotCount : 0, styles: { halign: 'right', fontStyle: 'bold' } },
  };

  // rows.unshift(totalsRow);

  const totalsHeadingRow = {
    sellerId: { content: '' },
    sellerName: { content: '' },
    sold: { content: 'Sold Count', styles: { halign: 'right', fontStyle: 'bold' } },
    passed: { content: 'Passed Count', styles: { halign: 'right', fontStyle: 'bold' } },
    buyBack: { content: 'Buyback Count', styles: { halign: 'right', fontStyle: 'bold' } },
    nonPayer: { content: 'Non-Payer Count', styles: { halign: 'right', fontStyle: 'bold' } },
    commTotal: { content: 'Comm Total', styles: { halign: 'right', fontStyle: 'bold' } },
    adjustTotal: { content: 'Adjust Total', styles: { halign: 'right', fontStyle: 'bold' } },
    sellerLotCount: { content: 'Seller Lot Count', styles: { halign: 'right', fontStyle: 'bold' } },
    // notes: {content: ''}
  };
  rows.push(totalsHeadingRow);
  rows.push(totalsRow);

  doc.autoTable({
    startY: 35,
    head: [tableColumn?.map(col => col.header)],
    body: rows?.map((row: any) => Object.values(row)),
    theme: 'striped',
    showHead: "everyPage",
    margin: { top: 60, left: 5, right: 5 },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 1, bottom: 3, left: 1 },
    },
    headStyles: {
      halign: 'center',
      cellPadding: { top: 2, right: 1, bottom: 3, left: 1 },
      fillColor: null,
      textColor: '#666666',
    },
    didDrawPage: () => {
      header();
      footer();
    }
  });
  doc.save('Seller_Commission_Report.pdf');
};

export const generateSellerCommissionSummaryPdf = (sellerCommissionSummary: any) => {
  const doc: any = new jsPDF("p", "pt", "a4");
  const columns = [
    { header: "#", dataKey: "sellerId" },
    { header: "Seller Name", dataKey: "sellerName" },
    { header: "Total Ext Price", dataKey: "totalExtPrice" },
    { header: "Sold", dataKey: "sold" },
    { header: "Passed", dataKey: "passed" },
    { header: "Buyback", dataKey: "buyBack" },
    { header: "Non-Payer", dataKey: "unSold" },
    { header: "Comm Total", dataKey: "commTotal" },
    { header: "Adjusts", dataKey: "adjust" },
    { header: "Payments", dataKey: "payments" },
    { header: "NET", dataKey: "net" }
  ];

  const rows: any = [];
  let totals = {
    totalExtPrice: 0,
    sold: 0,
    passed: 0,
    buyBack: 0,
    unSold: 0,
    commTotal: 0,
    adjust: 0,
    payments: 0,
    net: 0
  };
  let auctionId = '';
  let auctionTitle = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let address = '';
  let auctionDate = '';

  sellerCommissionSummary.forEach((item: any) => {
    totals.totalExtPrice += item.totalExtPrice;
    totals.sold += item.sold;
    totals.passed += item.passed;
    totals.buyBack += item.buyBack;
    totals.unSold += item.unSold;
    totals.commTotal += item.commTotal;
    totals.adjust += item.adjust;
    totals.payments += item.payments;
    totals.net += item.net;
    auctionId = item.auctionCode;
    auctionTitle = item.auctionTitle;
    city = item.auctionCityName;
    state = item.auctionStateName;
    zipCode = item.auctionZipCode;
    address = item.auctionAddressLine1;
    auctionDate = item.auctionStartDate;
    auctionDate = auctionDate.replace(/-/g, '/');

    rows.push({
      sellerId: { content: item.sellerId, styles: { halign: 'center', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      sellerName: { content: item.sellerName, styles: { halign: 'left', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      totalExtPrice: { content: `$${formatAmount(item.totalExtPrice)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      sold: { content: `$${formatAmount1(item.sold)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      passed: { content: `$${formatAmount1(item.passed)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      buyBack: { content: `$${formatAmount1(item.buyBack)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      unSold: { content: `$${formatAmount1(item.unSold)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      commTotal: { content: `$${formatAmount1(item.commTotal)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      adjust: { content: `$${formatAmount1(item.adjust)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      payments: { content: `$${formatAmount1(item.payments)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } },
      net: { content: `$${formatAmount(item.net)}`, styles: { halign: 'right', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, cellspacing: 0, } }
    });

    // Add the new row for company information
    rows.push({
      sellerId: { content: '', styles: { halign: 'center' } },
      CompanyName: { content: `    ${item.company}`, colSpan: 10, styles: { halign: 'left', fontSize: 8, cellPadding: { top: 1, left: 6, bottom: 2 } } }
    });
  });

  const totalsRow = {
    sellerId: { content: '', colSpan: 1, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    sellerName: { content: 'Totals', colSpan: 1, styles: { halign: 'left', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    totalExtPrice: { content: `$${formatAmount(totals.totalExtPrice)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    sold: { content: `$${formatAmount1(totals.sold)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    passed: { content: `$${formatAmount1(totals.passed)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    buyBack: { content: `$${formatAmount1(totals.buyBack)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    unSold: { content: `$${formatAmount1(totals.unSold)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    commTotal: { content: `$${formatAmount1(totals.commTotal)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    adjust: { content: `$${formatAmount1(totals.adjust)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    payments: { content: `$${formatAmount1(totals.payments)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    net: { content: `$${formatAmount(totals.net)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } }
  };

  rows.unshift(totalsRow);

  const totalsHeadingRow = {
    sellerId: { content: '', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    sellerName: { content: '', styles: { halign: 'left', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    totalExtPrice: { content: 'Total Ext Price', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    sold: { content: 'Sold', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    passed: { content: 'Passed', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    buyBack: { content: 'Buyback', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    unSold: { content: 'Non-Payer', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    commTotal: { content: 'Comm Total', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    adjust: { content: 'Adjusts', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    payments: { content: 'Payments', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
    net: { content: 'NET', styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } }
  };
  rows.push(totalsHeadingRow);
  rows.push(totalsRow);

  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 10, 30, 75, 30);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 100, 30, { fontStyle: 'bold' });
    doc.text(`${auctionTitle}`, 100, 45, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 100, 60);
    doc.text(`${city}, ${state} ${zipCode}`, 100, 75);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Seller Commission Summary Report", rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 45, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${moment(auctionDate).format('MM/DD/YYYY')}`, rightAlignX, 60, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    const text1 = "(NET does not include any Sales Tax on";
    const text2 = "Commissions or Adjustments if applicable)";
    doc.text(text1, rightAlignX, 75, { align: 'right' });
    doc.text(text2, rightAlignX, 85, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const todayTime = new Date().toLocaleString();
    doc.text(`${pageCount}`, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  const customHeaderRow = [
    { content: '', colSpan: 2 },
    { content: '<-  Commissions  ->', colSpan: 5, styles: { halign: 'center', fontSize: 10, fontStyle: 'bold' } },
    { content: '', colSpan: 4 }
  ];

  doc.autoTable({
    head: [columns?.map(col => col.header)],
    body: rows?.map((row: any) => Object.values(row)),
    startY: 130,
    margin: { top: 60, left: 12, right: 12 },
    showHead: "everyPage",
    theme: "plain",
    styles: {
      fontSize: 8,
      cellPadding: { top: 3, right: 8, bottom: 2, left: 8 }
    },
    headStyles: {
      halign: 'center',
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
      fillColor: null,
      textColor: '#666666',
    },
    didDrawPage: function (data: any) {
      header();
      doc.autoTable({
        body: [customHeaderRow],
        startY: 110,
        theme: "plain",
        styles: {
          fontSize: 10,
          cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
          textColor: '#666',
          theme: 'grid'
        }
      });
      footer();
    },
  });

  doc.save("Seller_Commission_Summary_Report.pdf");
};

export const generateSellerAdjustmentPdf = (sellerAdjustData: any) => {
  const doc: any = new jsPDF("p", "pt");

  const calculateTotalAmount = () => {
    let total = 0;
    sellerAdjustData.forEach((item: any) => {
      total += item.totalAmount;
    });
    return formatAmount(total);
  };

  const renderTable = () => {
    const headers = [
      { header: "", dataKey: "empty" },
      { header: "Seller # & Name", dataKey: "sellerName", colSpan: 2 },
      { header: "Type", dataKey: "type", textAlign: "center" },
      { header: "Amount", dataKey: "amount", textAlign: "right" },
      { header: "Sales Tax", dataKey: "salesTax", textAlign: "right" }
    ];

    const rows: any = [];
    let auctionId = '';
    let auctionTitle = '';
    let city = '';
    let state = '';
    let zipCode = '';
    let address = '';
    let auctionDate = '';

    sellerAdjustData.forEach((item: any, index: any) => {
      auctionId = item.auctionCode;
      auctionTitle = item.auctionTitle;
      city = item.auctionCityName;
      state = item.auctionStateName;
      zipCode = item.auctionZipCode;
      address = item.auctionAddressLine1;
      auctionDate = item.auctionStartDate;
      auctionDate = auctionDate.replace(/-/g, '/');

      rows.push([
        { content: "", styles: { halign: 'left', fillColor: '#e1e1e1' } },
        { content: item.description, colSpan: 2, styles: { fontStyle: 'bold', halign: 'left', fillColor: '#e1e1e1' } },
        { content: `$${formatAmount(item.totalAmount)}`, styles: { fontStyle: 'bold', halign: 'right', fillColor: '#e1e1e1' } },
        { content: "", styles: { halign: 'right', fillColor: '#e1e1e1' } }
      ]);

      item.lstSellerAdjust.forEach((adjustment: any, idx: any) => {
        rows.push([
          { content: moment(adjustment.date).format("MM/DD/YYYY"), styles: { halign: 'left', } },
          { content: `${adjustment.sellerId}  ${adjustment.sellerName}`, styles: { halign: 'left', } },
          { content: adjustment.type, styles: { halign: 'center', } },
          { content: adjustment.type === "Credit" ? `$(${formatAmount(adjustment.amount)})` : `$${formatAmount(adjustment.amount)}`, styles: { halign: 'right', } },
          { content: "-", styles: { halign: 'center', } }
        ]);
      });
    });

    const header = () => {
      const pageWidth = doc.internal.pageSize.width;
      const img = new Image();
      img.src = dpa_logo;
      doc.addImage(img, "SVG", 10, 30, 75, 30);

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Del Peterson & Associates, Inc.", 100, 30, { fontStyle: 'bold' });
      doc.text(`${auctionTitle}`, 100, 45, { fontStyle: 'bold' });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(`${address}`, 100, 60);
      doc.text(`${city}, ${state} ${zipCode}`, 100, 75);
      const rightAlignX = pageWidth - 10;
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text("Seller Adjustments", rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

      doc.setFont("helvetica", "bold");
      doc.text(`Auction: ${auctionId} - ${moment(auctionDate).format('MM/DD/YYYY')}`, rightAlignX, 45, { align: 'right' });

      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const todayTime = new Date().toLocaleString();
      doc.text(`Printed: ${moment(todayTime).format('MM/DD/YYYY')}`, rightAlignX, 60, { align: 'right' });
    };

    const footer = () => {
      let pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
      doc.text(`Invoice Summary: Auction ${auctionId}`, pageSize.width / 2, pageHeight - 10, { align: 'center' });
      const todayTime = new Date().toLocaleString();
      doc.text(todayTime, pageSize.width - 15, pageHeight - 10, { align: 'right' });
    };

    const footerRows = [
      [{ content: "TOTAL Seller Adjustments", colSpan: 3, styles: { halign: 'right', fontStyle: 'bold' } },
      { content: `$${calculateTotalAmount()}`, styles: { halign: 'right' } },
      { content: "", styles: { halign: 'center' } }]
    ];

    doc.autoTable({
      startY: 110,
      // head: [headers.map(col => col.header)],
      head: [
        [
          { content: "", styles: { halign: 'left' } },
          { content: "Seller # & Name", styles: { halign: 'left' } },
          { content: "Type", styles: { halign: 'center' } },
          { content: "Amount", styles: { halign: 'right' } },
          { content: "Sales Tax", styles: { halign: 'center' } },
        ]
      ],
      body: rows,
      foot: footerRows,
      theme: "grid",
      showHead: 'firstPage',
      margin: { top: 100, left: 12, right: 12 },
      didDrawPage: function () {
        header();
        footer();
      },
      styles: {
        fontSize: 9,
        cellPadding: { top: 5, right: 5, bottom: 5, left: 5 }
      },
      headStyles: {
        halign: 'left',
        cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
        fillColor: null,  // No fill color
        textColor: '#666666',
      },
      footStyles: {
        halign: 'right',
        fontStyle: 'bold',
        cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
        fillColor: null,
        fontSize: 10,
        textColor: '#666666'
      }
    });
  };

  renderTable();
  doc.save("Seller_Adjustments_Report.pdf");
};

export const generateSellerOverallSummaryPdf = (sellerOverallData: any) => {
  const doc: any = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  let currentPage = 1;

  const margin = 8;
  const lineHeight = 6;

  let auctionId = '';
  let auctionTitle = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let address = '';
  let auctionDate = '';
  let soldCount = '';

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 5, 8, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: 'bold' });
    doc.text(`${auctionTitle}`, 40, 17, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 40, 22);
    doc.text(`${city}, ${state} ${zipCode}`, 40, 27);

    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Seller Summary", rightAlignX, 12, { align: 'right', fontStyle: 'bold' });

    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId} - ${moment(auctionDate).format('MM/DD/YYYY')}`, rightAlignX, 17, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Items Sold: ${soldCount}`, rightAlignX, 22, { align: 'right' });
    doc.text("Tax Rate: NONE", rightAlignX, 27, { align: 'right' });
  };

  const footer = (doc: any) => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(`Seller Summary for: Auction ${auctionId}`, pageSize.width / 2, pageHeight - 10, { align: 'center' });
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  const checkAndDrawHeaderFooter = (doc: any) => {
    const newPage = doc.internal.getNumberOfPages();
    if (newPage !== currentPage) {
      header(doc);
      footer(doc);
      currentPage = newPage;
    }
  };

  sellerOverallData.forEach((item: any, index: any) => {
    auctionId = item.auctionCode;
    auctionTitle = item.auctionTitle;
    city = item.auctionCityName;
    state = item.auctionStateName;
    zipCode = item.auctionZipCode;
    address = item.auctionAddressLine1;
    auctionDate = item.auctionStartDate;
    auctionDate = auctionDate.replace(/-/g, '/');
    soldCount = item.soldCount;

    header(doc);
    footer(doc);

    // if (index !== 0) {
    //   doc.addPage();
    //   currentPage += 1; 
    // }
    // header(doc);


    const marginNew = 75;

    doc.setFontSize(9);
    // Left-aligned text
    doc.text('Auction Summary - Commission: Based per Item', margin, 38);
    doc.setFont("helvetica", "bold");
    doc.text(`Total Sales:....................... $${formatAmount(item.totalSoldPrice)}`, margin, 43);
    doc.setFont("helvetica", "normal");
    doc.text(`Total Commission:................... $${formatAmount(item.commTotal)}`, margin, 48);
    doc.text(`Net Inventory Adjustments:....................`, margin, 53);
    doc.text(`Net Seller Adjustments:.................. $${formatAmount(item.adjusts)}`, margin, 58);
    doc.text(`Payments:.....................$${formatAmount(item.payments)}`, margin, 63);
    doc.text(`Sales Tax:.......................`, margin, 68);
    doc.setFont("helvetica", "bold");
    doc.text(`Net Balance:................... $${formatAmount(item.net)}`, margin, 73);
    doc.setFont("helvetica", "normal");
    doc.text(`Total Passed:.................... $${formatAmount(item.totalPassedPrice)}`, margin, 78);

    // Right-aligned text
    doc.text(`Seller Number: ${item.sellerId}`, pageWidth - marginNew, 43, { align: 'left' });
    doc.setFont("helvetica", "bold");

    doc.text(`${item.sellerName}`, pageWidth - marginNew, 48, { align: 'left' });
    doc.text(`${item.company}`, pageWidth - marginNew, 53, { align: 'left' });

    doc.setFont("helvetica", "normal");
    doc.text(`${item.addressLine1}`, pageWidth - marginNew, 58, { align: 'left' });
    doc.text(`${item.cityName} ${item.stateName} ${item.zipCode}`, pageWidth - marginNew, 63, { align: 'left' });
    doc.text(item.mobile ? `C: ${formatPhoneNumber(item.mobile)}` : '', pageWidth - marginNew, 68, { align: 'left' });
    // doc.text(`Commission: 0 or more @ 0.0%`, pageWidth - marginNew, 73, { align: 'left' });

    if (item.soldCount > 0 || item.passsCount > 0 || item.buyBack > 0 || item.nonPayerCount > 0) {

      const soldRows = item?.lstSellerSummary !== null && item?.lstSellerSummary?.length > 0 && item?.lstSellerSummary.filter((data: any) => data.status === "Sold").flatMap((data: any) => {
        const lotTitle = data.lotTitle.replace(/<[^>]*>?/gm, "");
        const lotTitleStyles = lotTitle.length > 30 ? { halign: 'left' } : { halign: 'left' };

        const mainRow = [
          { content: data.lotNo, styles: { halign: 'left' } },
          { content: lotTitle, lotTitleStyles },
          { content: data.qty, styles: { halign: 'left' } },
          { content: `$${formatAmount(data.price)}`, styles: { halign: 'right' } },
          { content: `$${formatAmount(data.extPrice)}`, styles: { halign: 'right' } },
          { content: data.commissionRate ? data.commissionRate : '-', styles: { halign: 'right' } },
          { content: `$${formatAmount(data.commission)}`, styles: { halign: 'right' } }
        ];

        function addIndentationToEachLine(text: any, indentation: any) {
          const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 20);
          const indentedLines = lines.map((line: any) => `${indentation}${line}`);
          return indentedLines.join('\n');
        }
        const indentation = ' '.repeat(13);

        const descriptionAndVin = `Description: ${data.lotDescription.replace(/<[^>]*>?/gm, "")}${data.vin1 ? ` Vin1: ${data.vin1}` : ''}`;
        const indentedDescription = addIndentationToEachLine(descriptionAndVin, indentation);

        const combinedRow = [
          {
            content: indentedDescription,
            colSpan: 8,
            styles: {
              halign: 'left',
              cellPadding: { top: 1, right: 8, bottom: 1, left: 10 },
              overflow: 'linebreak',
            }
          }
        ];

        return [mainRow, combinedRow];
      });

      const tableHeaderY = 55 + 30;
      const tableHeaderLineY = tableHeaderY + 7;
      const tableHeaderLineX = 8;
      const tableHeaderLineWidth = pageWidth - 16;

      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0); // Set line color (black in this case)
      doc.line(tableHeaderLineX, tableHeaderLineY, tableHeaderLineX + tableHeaderLineWidth, tableHeaderLineY);

      doc.autoTable({
        head: [
          [
            { content: "Lot #", styles: { halign: 'left', cellWidth: 15 } },
            { content: "Lot Title", styles: { halign: 'left', cellWidth: 45 } },
            { content: "Qty", styles: { halign: 'right', cellWidth: 15 } },
            { content: "Price", styles: { halign: 'right', cellWidth: 30 } },
            { content: "Ext Price", styles: { halign: 'right', cellWidth: 27 } },
            { content: "Commission Rate", styles: { halign: 'right', cellWidth: 30 } },
            { content: "Commission", styles: { halign: 'right', cellWidth: 35 } }
          ]
        ],
        body: [],
        startY: tableHeaderY,
        theme: 'plain',
        margin: { top: 40, left: 8, right: 8 },
        styles: {
          fontSize: 9,
          fontStyle: 'normal',
          textColor: [0, 0, 0],
        },
        headStyles: {
          fillColor: null,
          borderBottom: "3px ",
          halign: 'center'
        },
        columnStyles: {
          0: { halign: 'left', cellWidth: 15 },
          1: { halign: 'left', cellWidth: 52 },
          2: { halign: 'right', cellWidth: 15 },
          3: { halign: 'right', cellWidth: 25 },
          4: { halign: 'right', cellWidth: 25 },
          5: { halign: 'right', cellWidth: 30 },
          6: { halign: 'right', cellWidth: 35 },
        },
        didDrawPage: () => {
          checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
        }
      });

      if (item.soldCount > 0) {
        const customSoldHeaderRow = [
          { content: `Sold (${item.soldCount})`, styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalSoldPrice)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalSoldCommission)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } }
        ];

        doc.autoTable({
          head: [],
          body: [customSoldHeaderRow],
          startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 0 : 20,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'bold',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 50 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });

        doc.autoTable({
          head: [],
          body: soldRows,
          startY: doc.previousAutoTable.finalY,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 52 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });
      }
      if (item.passsCount > 0) {
        const customPassedHeaderRow = [
          { content: `Passed (${item.passsCount})`, styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalPassedPrice)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalPassedCommission)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } }
        ];

        doc.autoTable({
          head: [],
          body: [customPassedHeaderRow],
          startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 0 : 20,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'bold',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 48 },
            2: { halign: 'right', cellWidth: 17 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });

        const passedRows = item?.lstSellerSummary !== null && item?.lstSellerSummary?.length > 0 && item?.lstSellerSummary.filter((data: any) => data.status === "Passed").flatMap((data: any) => {
          const lotTitle = data.lotTitle.replace(/<[^>]*>?/gm, "");
          const lotTitleStyles = lotTitle.length > 30 ? { halign: 'left', overflow: 'linebreak' } : { halign: 'left' };

          const mainRow = [
            { content: data.lotNo, styles: { halign: 'left' } },
            { content: lotTitle, styles: lotTitleStyles },
            { content: data.qty, styles: { halign: 'left' } },
            { content: `$${formatAmount(data.price)}`, styles: { halign: 'right' } },
            { content: `$${formatAmount(data.extPrice)}`, styles: { halign: 'right' } },
            { content: data.commissionRate ? data.commissionRate : '-', styles: { halign: 'right' } },
            { content: `$${formatAmount(data.commission)}`, styles: { halign: 'right' } }
          ];
          function addIndentationToEachLine(text: any, indentation: any) {
            const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 20);
            const indentedLines = lines.map((line: any) => `${indentation}${line}`);
            return indentedLines.join('\n');
          }
          const indentation = ' '.repeat(13);

          const descriptionAndVin = `Description: ${data.lotDescription.replace(/<[^>]*>?/gm, "")}${data.vin1 ? ` Vin1: ${data.vin1}` : ''}`;
          const indentedDescription = addIndentationToEachLine(descriptionAndVin, indentation);

          const combinedRow = [
            {
              content: indentedDescription,
              colSpan: 8,
              styles: {
                halign: 'left',
                cellPadding: { top: 1, right: 8, bottom: 1, left: 10 },
                overflow: 'linebreak',
              }
            }
          ];

          return [mainRow, combinedRow];
        })

        doc.autoTable({
          head: [],
          body: passedRows,
          startY: doc.previousAutoTable.finalY,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 52 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });
      }
      if (item.buyBackCount > 0) {
        const custombuyBackHeaderRow = [
          { content: `buyBack (${item.buyBackCount})`, styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalBuyBackPrice)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalBuyBackCommission)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } }
        ];

        doc.autoTable({
          head: [],
          body: [custombuyBackHeaderRow],
          startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 0 : 20,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'bold',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 50 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });

        const buyBackRows = item?.lstSellerSummary !== null && item?.lstSellerSummary?.length > 0 && item?.lstSellerSummary.filter((data: any) => data.status === "BuyBack").flatMap((data: any) => {
          const lotTitle = data.lotTitle.replace(/<[^>]*>?/gm, "");
          const lotTitleStyles = lotTitle.length > 30 ? { halign: 'left', overflow: 'linebreak' } : { halign: 'left' };

          const mainRow = [
            { content: data.lotNo, styles: { halign: 'left' } },
            { content: lotTitle, styles: lotTitleStyles },
            { content: data.qty, styles: { halign: 'left' } },
            { content: `$${formatAmount(data.price)}`, styles: { halign: 'right' } },
            { content: `$${formatAmount(data.extPrice)}`, styles: { halign: 'right' } },
            { content: data.commissionRate ? data.commissionRate : '-', styles: { halign: 'right' } },
            { content: `$${formatAmount(data.commission)}`, styles: { halign: 'right' } }
          ];
          function addIndentationToEachLine(text: any, indentation: any) {
            const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 20);
            const indentedLines = lines.map((line: any) => `${indentation}${line}`);
            return indentedLines.join('\n');
          }
          const indentation = ' '.repeat(13);

          const descriptionAndVin = `Description: ${data.lotDescription.replace(/<[^>]*>?/gm, "")}${data.vin1 ? ` Vin1: ${data.vin1}` : ''}`;
          const indentedDescription = addIndentationToEachLine(descriptionAndVin, indentation);

          const combinedRow = [
            {
              content: indentedDescription,
              colSpan: 8,
              styles: {
                halign: 'left',
                cellPadding: { top: 1, right: 8, bottom: 1, left: 10 },
                overflow: 'linebreak',
              }
            }
          ];

          return [mainRow, combinedRow];
        })

        doc.autoTable({
          head: [],
          body: buyBackRows,
          startY: doc.previousAutoTable.finalY,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 52 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });
      }
      if (item.nonPayerCount > 0) {
        const customNonPayerHeaderRow = [
          { content: `Non-Payer (${item.nonPayerCount})`, styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalNonPayerPrice)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: '', styles: { fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } },
          { content: `$${formatAmount(item.totalNonPayerCommission)}`, styles: { halign: 'right', fillColor: '#cccccc', lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0 }, lineColor: [0, 0, 0], cellPadding: { top: 1, bottom: 1, } } }
        ];

        doc.autoTable({
          head: [],
          body: [customNonPayerHeaderRow],
          startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 0 : 20,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'bold',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 50 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });

        const nonPayerRows = item?.lstSellerSummary !== null && item?.lstSellerSummary?.length > 0 && item?.lstSellerSummary.filter((data: any) => data.status === "NonPayer").flatMap((data: any) => {

          const lotTitle = data.lotTitle.replace(/<[^>]*>?/gm, "");
          const lotTitleStyles = lotTitle.length > 30 ? { halign: 'left', overflow: 'linebreak' } : { halign: 'left' };

          const mainRow = [
            { content: data.lotNo, styles: { halign: 'left' } },
            { content: lotTitle, styles: lotTitleStyles },
            { content: data.qty, styles: { halign: 'left' } },
            { content: `$${formatAmount(data.price)}`, styles: { halign: 'right' } },
            { content: `$${formatAmount(data.extPrice)}`, styles: { halign: 'right' } },
            { content: data.commissionRate ? data.commissionRate : '-', styles: { halign: 'right' } },
            { content: `$${formatAmount(data.commission)}`, styles: { halign: 'right' } }
          ];
          function addIndentationToEachLine(text: any, indentation: any) {
            const lines = doc.splitTextToSize(text, doc.internal.pageSize.width - 20);
            const indentedLines = lines.map((line: any) => `${indentation}${line}`);
            return indentedLines.join('\n');
          }
          const indentation = ' '.repeat(13);

          const descriptionAndVin = `Description: ${data.lotDescription.replace(/<[^>]*>?/gm, "")}${data.vin1 ? ` Vin1: ${data.vin1}` : ''}`;
          const indentedDescription = addIndentationToEachLine(descriptionAndVin, indentation);

          const combinedRow = [
            {
              content: indentedDescription,
              colSpan: 8,
              styles: {
                halign: 'left',
                cellPadding: { top: 1, right: 8, bottom: 1, left: 10 },
                overflow: 'linebreak',
              }
            }
          ];

          return [mainRow, combinedRow];
        })

        doc.autoTable({
          head: [],
          body: nonPayerRows,
          startY: doc.previousAutoTable.finalY,
          theme: 'plain',
          styles: {
            fontSize: 8,
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            cellPadding: 2,
          },
          margin: { top: 40, left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'left', cellWidth: 15 },
            1: { halign: 'left', cellWidth: 52 },
            2: { halign: 'right', cellWidth: 15 },
            3: { halign: 'right', cellWidth: 25 },
            4: { halign: 'right', cellWidth: 25 },
            5: { halign: 'right', cellWidth: 30 },
            6: { halign: 'right', cellWidth: 35 },
          },
          didDrawPage: () => {
            checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
          }
        });
      }
    }
    const halfPageWidth = (pageWidth - margin * 3) / 2;
    const gapBetweenTables = 10;
    const textLeftMargin = margin;

    const adjustmentsTableStartY = doc.previousAutoTable ? doc.previousAutoTable.finalY + 5 : 45;

    const lineY = adjustmentsTableStartY - 4;

    doc.setLineWidth(0.5);
    doc.line(margin, lineY, margin + halfPageWidth * 2 + gapBetweenTables, lineY);

    const adjustmentsColumns = ["Description", "Chg/Cr", "Amount", "Tax"];
    const adjustmentsRows = item?.lstSellerSummaryAdjust !== null && item?.lstSellerSummaryAdjust.length > 0 && item?.lstSellerSummaryAdjust.map((adjust: any) => [
      { content: adjust.description },
      { content: adjust.stringchgCr },
      { content: `$${formatAmount(adjust.amount)}`, styles: { halign: 'right' } },
      { content: `$${formatAmount(adjust.tax)}`, styles: { halign: 'right' } },
    ]);

    doc.autoTable({
      head: [['Seller Charges/Credits/Adjustments', 'Amount', 'Tax']],
      body: [],
      startY: adjustmentsTableStartY,
      margin: { top: 50, left: margin, right: margin + halfPageWidth },
      styles: {
        fontSize: 9,
        fontStyle: 'normal',
        textColor: [0, 0, 0],
        cellPadding: 2,
      },
      theme: "grid",
      headStyles: {
        fillColor: null,
        textColor: 'black',
        fontStyle: 'bold',
      },
      didDrawCell: function (data: any) {
        if (data.row.index === 0 && data.cell.section === 'head') {
          const lineColor = [0, 0, 0];
          doc.setDrawColor(...lineColor);
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          );
        }
      },
      didDrawPage: () => {
        checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
      }
    });

    doc.autoTable({
      body: adjustmentsRows,
      startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 1 : adjustmentsTableStartY + 8,
      margin: { top: 40, left: margin, right: margin + halfPageWidth },
      styles: {
        fontSize: 8,
        fontStyle: 'normal',
        textColor: [0, 0, 0],
        cellPadding: 2,
      },
      theme: "grid",
      headStyles: {
        fillColor: null,
        textColor: "black",
        fontStyle: 'bold',
      },
      didDrawPage: () => {
        checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
      }
    });

    const fontSize = 9;
    const textColor = [0, 0, 0];
    const cellPadding = 0;
    const summaryTableX = margin + halfPageWidth + margin;
    const summaryTableY = adjustmentsTableStartY + 2;

    doc.setFontSize(fontSize);
    doc.setTextColor(...textColor);

    const summaryRows = [
      ["Total Sales", `$${formatAmount(item.totalSoldPrice)}`],
      ["Total Commissions", `$${formatAmount(item.commTotal)}`],
      ["Sales Tax - Commissions", ''],
      ["Net Inventory Adjustments", ''],
      ["Sales Tax - Inv Adjusts", ''],
      ["Net Seller Adjustments", `$${formatAmount(item.adjusts)}`],
      ["Sales Tax - Seller Adjusts", ''],
      ["Sales Tax Total", ''],
      ["Payments to/from Seller", `$${formatAmount(item.payments)}`],
      ["Balance Due to Seller", `$${formatAmount(item.net)}`],
    ];

    const topMargin = 1;
    const balanceDueTopMargin = 6;
    const rightShiftOffset = 11;
    const lineXOffset = summaryTableX - 2;
    const lineShiftAmount = 7;
    const adjustedLineXOffset = lineXOffset + lineShiftAmount;

    summaryRows.forEach((row, rowIndex) => {
      const lineHeight = fontSize * 0.6;
      // let yOffset = summaryTableY + topMargin + (rowIndex * lineHeight);
      let yOffset = summaryTableY + (rowIndex * lineHeight);

      const startY = summaryTableY + (0.1 * lineHeight);
      const endY = summaryTableY + (5.8 * lineHeight);

      if (row[0] === "Balance Due to Seller") {
        yOffset += balanceDueTopMargin;
      }

      doc.setLineDash([]);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.1);
      doc.line(adjustedLineXOffset, startY, adjustedLineXOffset, endY);

      const horizontalLineLength = 5;
      const horizontalLineStartX = adjustedLineXOffset - horizontalLineLength;
      doc.line(horizontalLineStartX, startY, adjustedLineXOffset, startY);

      const horizontalLineEndX = adjustedLineXOffset + horizontalLineLength;
      doc.line(adjustedLineXOffset, endY, horizontalLineEndX, endY);

      const newLineYOffset = 4.8;
      const newLineStartY = endY - newLineYOffset;
      const newHorizontalLineLength = horizontalLineLength + 5;
      const newHorizontalLineEndX = adjustedLineXOffset + newHorizontalLineLength;

      doc.line(adjustedLineXOffset, newLineStartY, newHorizontalLineEndX, newLineStartY)

      row.forEach((cell, colIndex) => {
        const cellWidth = 45;
        const xOffset = summaryTableX + (colIndex * cellWidth) + rightShiftOffset;
        let textXOffset = xOffset;
        if (colIndex === 1) {
          textXOffset = xOffset - 10;
        }
        const textWidth = doc.getTextWidth(cell);
        const rightAlignedXOffset = textXOffset + cellWidth - textWidth;
        if (row[0] === "Sales Tax Total" || row[0] === "Balance Due to Seller") {
          doc.setFont('helvetica', 'bold');
        } else {
          doc.setFont('helvetica', 'normal');
        }
        doc.text(cell, rightAlignedXOffset, yOffset);
      });
    });

    const paymentsColumns = ["Description", "-", "Paid", "Received"];
    const paymentsRows = item?.lstSellerSummaryPayment !== null && item?.lstSellerSummaryPayment.length > 0 && item?.lstSellerSummaryPayment.map((payment: any) => [
      { content: payment.payType },
      { content: moment(payment.date).format('MM/DD/YYYY') },
      { content: `$${formatAmount(payment.amtPaid)}`, styles: { halign: 'right' } },
      { content: `$${formatAmount(payment.amtReceived)}`, styles: { halign: 'right' } }
    ]);
    doc.autoTable({
      head: [['Seller Payments Paid or Rec\'d', 'Paid', 'Received']],
      body: [],
      startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 8 : 100,
      margin: { top: 40, left: margin, right: margin + halfPageWidth },
      styles: {
        fontSize: 9,
        fontStyle: 'normal',
        textColor: [0, 0, 0],
        cellPadding: 2,
      },
      headStyles: {
        fillColor: null,
        textColor: 'black',
        fontStyle: 'bold',
      },
      didDrawCell: function (data: any) {
        if (data.row.index === 0 && data.cell.section === 'head') {
          const lineColor = [0, 0, 0];
          doc.setDrawColor(...lineColor);
          doc.line(
            data.cell.x,
            data.cell.y + data.cell.height,
            data.cell.x + data.cell.width,
            data.cell.y + data.cell.height
          );
        }
      },
      didDrawPage: () => {
        checkAndDrawHeaderFooter(doc);  // Only draw if a new page was created
      }
    });

    doc.autoTable({
      body: paymentsRows,
      startY: doc.previousAutoTable ? doc.previousAutoTable.finalY + 1 : 110,
      margin: { top: 40, left: margin, right: margin + halfPageWidth },
      styles: {
        cellWidth: 'wrap',
        fontSize: 8,
        fontStyle: 'normal',
        textColor: [0, 0, 0],
      },
      theme: "grid",
      fillColor: null,
      textColor: 'black',
      fontStyle: 'bold',
      didDrawPage: () => {
        checkAndDrawHeaderFooter(doc);
      }
    });

    // code commented --- code to show seller notes in yellow background 

    // if (item?.lstSellerNotes && item?.lstSellerNotes?.length > 0) {
    //   const notesStartY = doc.previousAutoTable ? doc.previousAutoTable.finalY + 10 : doc.autoTableEndPosY() + 10;
    //   const notes = item?.lstSellerNotes?.map((note: any) => `${note?.description}`).join('\n');
    //   doc.setFont('helvetica', 'normal');
    //   doc.setFontSize(9);
    //   const lineHeight = 8;
    //   const textMargin = 5;
    //   const notesLines = doc.splitTextToSize(notes, 190);
    //   notesLines.forEach((line: string, index: number) => {
    //     const textWidth = doc.getTextWidth(line);
    //     const yOffset = notesStartY + (index * lineHeight);
    //     // doc.setFillColor(255, 255, 0);
    //     doc.rect(margin, yOffset - 5, textWidth + textMargin * 2, lineHeight, 'F');
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(line, margin + textMargin, yOffset);
    //   });
    //   doc.setTextColor(0, 0, 0);
    //   doc.text("", margin, notesStartY - 5);
    // }

    // code to show seller notes without yellow background 

    if (item?.lstSellerNotes && item?.lstSellerNotes?.length > 0) {
      const notesStartY = doc.previousAutoTable ? doc.previousAutoTable.finalY + 10 : doc.autoTableEndPosY() + 10;
      const notes = item?.lstSellerNotes?.map((note: any) => `${note?.description}`).join('\n');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      const lineHeight = 6;
      const textMargin = 3;
      const notesLines = doc.splitTextToSize(notes, 190);   
      notesLines.forEach((line: string, index: number) => {
        const yOffset = notesStartY + (index * lineHeight);
        doc.text(line, margin + textMargin, yOffset);
      });
    }

    if (index !== sellerOverallData.length - 1) {
      doc.addPage();
    }
  });
  doc.save('Seller_Summary_Report.pdf');
};

export const generateSellerPaymentSummaryPdf = (sellerPaymentsData: any) => {
  const columns = [
    { header: "#", dataKey: "sellerId" },
    { header: "Seller Name", dataKey: "sellerName" },
    { header: "Comm", dataKey: "commTotal" },
    { header: "Adjust", dataKey: "adjust" },
    { header: "Date", dataKey: "date" },
    { header: "Amt Paid", dataKey: "amtPaid" },
    { header: "Amt Rec'd", dataKey: "amtRecived" },
    { header: "Type", dataKey: "type" },
    { header: "Check #", dataKey: "check" },
    { header: "User", dataKey: "user" }
  ];

  const rows = [];
  let grandTotalAmountPaid = 0;
  let grandTotalAmountRecived = 0;
  let auctionId: any = ''
  let auctionTitle: any = ''
  let city: any = '';
  let state: any = '';
  let zipCode: any = '';
  let address: any = '';
  let auctionDate: any = '';

  sellerPaymentsData?.forEach((item: any, index: any) => {
    let totalAmountPaid = 0;
    let totalAmountRecived = 0;
    item.lstSellerAdjust.forEach((transaction: any) => {
      totalAmountPaid += transaction.amtPaid;
      totalAmountRecived += transaction.amtRecived;
    });
    grandTotalAmountPaid += totalAmountPaid;
    grandTotalAmountRecived += totalAmountRecived;
    auctionId = item.auctionCode;
    auctionTitle = item.auctionTitle;
    city = item.auctionCityName;
    state = item.auctionStateName;
    zipCode = item.auctionZipCode;
    address = item.auctionAddressLine1;
    auctionDate = item.auctionStartDate;
    auctionDate = auctionDate?.replace(/-/g, '/');

    rows.push({
      sellerId: { content: item.sellerId, styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      sellerName: { content: item.sellerName, styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      commTotal: { content: item.commTotal ? `$${formatAmount(item.commTotal)}` : '$0.00', styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      adjust: { content: item.adjust ? `$${formatAmount(item.adjust)}` : '$0.00', styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      date: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      amtPaid: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      amtRecived: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      type: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      check: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
      user: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } }
    });

    item.lstSellerAdjust.forEach((transaction: any, i: any) => {
      rows.push({
        sellerId: '',
        sellerName: '',
        commTotal: '',
        adjust: '',
        date: { content: moment(transaction.date).format('MM/DD/YYYY'), styles: { halign: 'left', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        amtPaid: { content: transaction.amtPaid == 0 ? '$0.00' : `$${formatAmount(transaction.amtPaid)}`, styles: { halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        amtRecived: { content: transaction.amtRecived == 0 ? '$0.00' : `$${formatAmount(transaction.amtRecived)}`, styles: { halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        type: { content: transaction.typeString ? transaction.typeString : '-', styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        check: { content: transaction.check == 0 ? '-' : transaction.check, styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        user: { content: transaction.user ? transaction.user : '-', styles: { halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } }
      });
    });
  });

  rows.push({
    sellerId: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    sellerName: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    commTotal: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    adjust: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    date: { content: 'Totals', styles: { fontStyle: 'bold', halign: 'center', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    amtPaid: { content: `$${formatAmount(grandTotalAmountPaid)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    amtRecived: { content: `$${formatAmount(grandTotalAmountRecived)}`, styles: { halign: 'right', fontStyle: 'bold', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    type: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    check: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } },
    user: { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }, lineColor: [0, 0, 0] } }
  });

  const doc: any = new jsPDF("p", "pt");

  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 10, 30, 75, 30);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 100, 30, { fontStyle: 'bold' });
    doc.text(`${auctionTitle}`, 100, 45, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 100, 60);
    doc.text(`${city}, ${state} ${zipCode}`, 100, 75);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Seller Payment Summary", rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 45, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const todayTime = new Date().toLocaleString();
    doc.text(`${moment(todayTime).format('MM/DD/YYYY')}`, rightAlignX, 60, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text("Seller Payment Summary", pageSize.width / 2, pageHeight - 10, { align: 'center' });
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  doc.autoTable({
    head: [columns.map(col => col.header)],
    body: rows.map(row => Object.values(row)),
    startY: 110,
    margin: { top: 100, left: 12, right: 12 },
    showHead: "everyPage",
    theme: "plain",
    styles: {
      fontSize: 9,
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 }
    },
    headStyles: {
      halign: 'center',
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
      fillColor: null,
      textColor: '#666666',
    },
    didDrawPage: function () {
      header();
      footer();
    }
  });

  doc.save("Seller_Payment_Summary_Report.pdf");
};

export const generateSellerPickupSummaryPdf = (sellerPickupSummary: any) => {
  const doc: any = new jsPDF();

  const header = (auctionTitle: any, auctionId: any, auctionDate: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, {
      fontStyle: "bold",
    });
    doc.text('PO Box 654', 40, 17, { fontStyle: "bold" });
    doc.text('Fremont, NE 68026', 40, 22, { fontStyle: "bold" });

    const rightAlignX = pageWidth - 10;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Seller Pickup Summary", rightAlignX, 12, {
      align: "right",
      fontStyle: "bold",
    });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Date: ${moment(auctionDate).format("MMMM D, YYYY")}`, rightAlignX, 22, {
      align: "right",
    });
  };

  const footer = (auctionTitle: any) => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Seller Pickup Summary of ${auctionTitle}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  sellerPickupSummary.forEach((item: any, index: any) => {
    if (index > 0) doc.addPage();

    header(item.auctionTitle, item.auctionId, item.auctionStartDate);

    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 9;
    const startY = 43;
    let currentY = 43;

    const capitalizedSellerName = item.sellerName.charAt(0).toUpperCase() + item.sellerName.slice(1);
    doc.setFontSize(10);
    // Left-aligned text
    doc.setFont("helvetica", "bold");
    doc.text(`To:`, margin, currentY);
    doc.setFont("helvetica", "normal");
    doc.text(`${capitalizedSellerName}`, margin + doc.getTextWidth('To: ') + 2, currentY);
    currentY += 5;
    if (item.company) {
      doc.text(`${item.company}`, 16, currentY);
      currentY += 5;
    }
    doc.text(`${item.sellerCityName} ${item.sellerStateName}`, 16, currentY);
    currentY += 5;
    doc.text(`${item.sellerZipCode}`, 16, currentY);
    currentY += 5;
    doc.text(`C: ${formatPhoneNumber(item.sellerMobile)}`, 16, currentY);

    currentY += 12;
    doc.setFont("helvetica", "bold");
    doc.text(`Regarding: `, 16, currentY);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.auctionTitle} (Auction: ${item.auctionCode})`, 18 + doc.getTextWidth('Regarding: '), currentY);

    doc.setFont("helvetica", "bold");
    doc.text(
      `Auction Summary- Commission Based per item`,
      pageWidth - 100,
      startY,
      { align: "left", fontStyle: "bold" }
    );

    doc.setFont("helvetica", "normal");
    doc.text(
      `Total Sales:................................... $${formatAmount(item.totalSales)}`,
      pageWidth - 100,
      startY + 5,
      { align: "left" }
    );
    doc.text(
      `Total Passed:................................ $${formatAmount(item.totalPassed)}`,
      pageWidth - 100,
      startY + 10,
      { align: "left" }
    );
    doc.text(
      `Total Buyback:.............................. $${formatAmount(item.totalBuyBack)}`,
      pageWidth - 100,
      startY + 15,
      { align: "left" }
    );

    let tableBody: any = [];

    item.lstinvoicepickUpReport.forEach((lstData: any) => {
      const invoiceDetails = [
        [
          {
            content: `Invoice: ${lstData.invoiceNumber} - Bidder Invoice Verification Code: ${lstData.verificationCode}\n${lstData.customerName} | #${lstData.bidderNumber} | c: ${formatPhoneNumber(lstData.customerMobile)} | ${lstData.customerEmail}`,
            colSpan: 2,
            styles: { fontStyle: 'bold' },
            invoiceRow: true
          },
          {
            content: `$${lstData.totalSalesPrice ? formatAmount(lstData.totalSalesPrice) : "0.00"}`,
            invoiceRow: true,
            styles: { halign: 'right' }
          }
        ],
      ];


      const lotsData = lstData.lstLotDetails.map((lot: any) => [
        {
          content: lot.lotNo,
          styles: { halign: 'left', }
        },
        {
          content: lot.lotTitle.replace(/<[^>]*>?/gm, ""),
          styles: { halign: 'left', }
        },
        {
          content: `$${formatAmount(lot.lotPrice)}`,
          styles: { halign: 'right', cellWidth: 'wrap', }
        }
      ]);

      tableBody = tableBody.concat(invoiceDetails).concat(lotsData);

      tableBody.push([
        {
          content: '',
          colSpan: 3,
          styles: {
            lineWidth: { top: 0.1 },
            lineColor: [0, 0, 0]
          },

        }
      ]);

    });

    tableBody.push([
      {
        content: `Seller Number: ${item.sellerId}`,
        colSpan: 3,
        styles: { halign: 'left', fontStyle: 'bold' }
      }
    ]);

    const tableHeaderY = startY + 35;
    const tableHeaderLineY = tableHeaderY + 7;
    const tableHeaderLineX = 8;
    const tableHeaderLineWidth = pageWidth - 16;

    doc.setLineWidth(0);
    doc.line(tableHeaderLineX, tableHeaderLineY, tableHeaderLineX + tableHeaderLineWidth, tableHeaderLineY);

    doc.autoTable({
      head: [
        [
          { content: "Lot #", styles: { halign: 'left' } },
          { content: "Lot Title", styles: { halign: 'left' } },
          { content: "Sale Price", styles: { halign: 'right' } },
        ]
      ],
      body: tableBody,
      startY: tableHeaderY,
      theme: "plain",
      margin: { top: 100, left: 8, right: 8 },
      didDrawPage: (data: any) => {
        footer(item.auctionTitle);
      },
      didParseCell: function (data: any) {
        if (data.cell.raw && data.cell.raw.invoiceRow) {
          data.cell.styles.fillColor = '#ECEFF7';
        }
      },
      styles: {
        fontSize: 9,
        cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
      },
      headStyles: {
        halign: "left",
        cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
        fillColor: null,
        textColor: '#666666',
      },
      bodyStyles: {
        valign: 'top'
      },
    });
  });
  doc.save("Seller_Pickup_Summary_Report.pdf");
};

export const generateBalanceDueByBidderPdf = (balanceDueData: any, totals: any, totalLotLength: any) => {
  // Extract data for header
  const firstItem = balanceDueData[0] || {};
  const auctionTitle = firstItem.auctionTitle || '';
  const auctionId = firstItem.auctionCode || '';
  const auctionDate = firstItem.startDateTime ? moment(firstItem.startDateTime).format('MM/DD/YYYY') : '';
  const address = firstItem.auctionAddress || '';
  const city = firstItem.cityName || '';
  const state = firstItem.stateName || '';
  const zipCode = firstItem.zipCode || '';

  const doc: any = new jsPDF();
  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${auctionTitle}`, 40, 17);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 40, 22);
    doc.text(`${city}, ${state} ${zipCode}`, 40, 27);
    doc.setFontSize(12);

    const rightAlignX = pageWidth - 10;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Balance Due Lots", rightAlignX, 12, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Auction #${auctionId} - ${auctionDate}`, rightAlignX, 17, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Balance Due Lots for Auction ${auctionId}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  const rows = balanceDueData.flatMap((data: any) => {
    const customerRows = [
      [
        { content: `${data.customerId} - ${data.customerName} ${data.company || ''} ${formatPhoneNumber(data.mobile)}`, styles: { fontStyle: 'bold', fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: `$${formatAmount(data.customerInvoiceTotal)}`, styles: { fillColor: [220, 220, 220], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: data.customerPaidToDateTotal ? `$${formatAmount(data.customerPaidToDateTotal)}` : '$0.00', styles: { fillColor: [220, 220, 220], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: `$${formatAmount(data.customerBalDueTotal)}`, styles: { fillColor: [220, 220, 220], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },

      ],
      ...(data.lstInvoiceBalDue || []).map((InvData: any) => [
        [
          { content: `    Invoice ID: ${InvData.invoiceMasterId}`, styles: { fontStyle: 'bold', fillColor: [230, 230, 230], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: '', styles: { fillColor: [230, 230, 230], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: '', styles: { fillColor: [230, 230, 230], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: `$${formatAmount(InvData.invoiceTotal)}`, styles: { fillColor: [230, 230, 230], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: InvData.paidToDate ? `$${formatAmount(InvData.paidToDate)}` : '$0.00', styles: { fillColor: [230, 230, 230], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: `$${formatAmount(InvData.balDue)}`, styles: { fillColor: [230, 230, 230], halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: '', styles: { fillColor: [230, 230, 230], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
          { content: '', styles: { fillColor: [230, 230, 230], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], cellPadding: { top: 1, right: 1, bottom: 1, left: 1 } } },
        ],
        ...(InvData.lstLotInvoice || []).map((lotData: any) => [
          { content: `         ${lotData.lotNo} ${lotData.lotTitle.replace(/<[^>]*>?/gm, "")}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: `$${formatAmount(lotData.lotPrize)}`, styles: { halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '-', styles: { halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '-', styles: { halign: 'right', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
          { content: '', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
        ]),
      ]).flat()
    ];
    return customerRows;
  });

  rows.push([
    { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: `Totals for ${totalLotLength} lots`, styles: { fontStyle: 'bold', fontSize: '10', fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: `$${totals.totalInvoice ? formatAmount(totals.totalInvoice) : '0.00'}`, styles: { fillColor: [220, 220, 220], halign: 'right', fontStyle: 'bold', fontSize: '10', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: `$${totals.totalPaid ? formatAmount(totals.totalPaid) : '0.00'}`, styles: { fillColor: [220, 220, 220], halign: 'right', fontStyle: 'bold', fontSize: '10', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: `$${totals.totalBalanceDue ? formatAmount(totals.totalBalanceDue) : '0.00'}`, styles: { fillColor: [220, 220, 220], halign: 'right', fontStyle: 'bold', fontSize: '10', lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
    { content: '', styles: { fillColor: [220, 220, 220], lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] } },
  ]);

  const startY = 50;
  doc.autoTable({
    head: [
      [
        { content: "", styles: {} },
        { content: "", styles: {} },
        { content: "", styles: {} },
        { content: "Invoice Total  ", styles: { halign: 'right' } },
        { content: "  Paid to Date", styles: { halign: 'right' } },
        { content: "  Balance Due", styles: { halign: 'right' } },
        { content: "", styles: {} },
        { content: "", styles: {} },
      ]
    ],
    body: rows,
    startY: 35,
    styles: {
      fontSize: 9,
      cellPadding: { top: 1.2, right: 1, bottom: 1.2, left: 1 },
    },
    theme: 'plain',
    didDrawPage: function () {
      header();
      footer();
    },
    margin: { top: 60, left: 8, right: 8 },
    headStyle: {
      lineWidth: { bottom: 0, left: 0, right: 0, top: 1 }
    }
  });

  doc.save('Balance_Due_Lots_Report.pdf');
};

export const generateSummaryOfSellerBySalesRepPdf = (sellerSummaryBySalesRepData: any, sellerSummaryTotals: any) => {
  const doc: any = new jsPDF('l', 'mm', 'a4');

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Header
  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${sellerSummaryTotals?.auctionName}`, 40, 17);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${sellerSummaryTotals?.auctionAddress}`, 40, 22);
    doc.text(`${sellerSummaryTotals?.auctionCity}, ${sellerSummaryTotals?.auctionState} ${sellerSummaryTotals?.auctionZipCode}`, 40, 27);
    doc.setFontSize(12);

    const rightAlignX = pageWidth - 10;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Summary of Sellers by Sales Rep", rightAlignX, 12, { align: 'right' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Auction #${sellerSummaryTotals?.auctionCode} - ${moment(sellerSummaryTotals.auctionStartDate).format('MM/DD/YYYY')}`, rightAlignX, 17, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Seller Summary for Auction ${sellerSummaryTotals?.auctionCode}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  // Add content
  sellerSummaryBySalesRepData.forEach((data: any, index: any) => {
    if (index > 0) doc.addPage();

    header();

    const rows = data.lstSellerSummary.map((seller: any) => [
      { content: moment(seller.startDateTime).format('MM/DD/YYYY'), styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left' } },
      { content: sellerSummaryTotals.auctionCode, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left' } },
      { content: seller.sellerName, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left' } },
      { content: seller.sellerId, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left' } },
      { content: `$${formatAmount(seller.soldAmount)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.soldCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.buyBackAmount)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.buyBackCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.passedAmount)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.passedCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.buyerPremium)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(seller.listingFees)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } }
    ]);

    doc.autoTable({
      startY: 35,
      // head: head,
      head: [
        [
          { content: "Date", styles: { halign: 'left' } },
          { content: "Auc Code", styles: { halign: 'left' } },
          { content: "Seller Name", styles: { halign: 'left' } },
          { content: "ID", styles: { halign: 'left' } },
          { content: "Total Sold", styles: { halign: 'right' } },
          { content: "Commission", styles: { halign: 'right' } },
          { content: "Buyback", styles: { halign: 'right' } },
          { content: "Comm", styles: { halign: 'right' } },
          { content: "Passed", styles: { halign: 'right' } },
          { content: "Comm", styles: { halign: 'right' } },
          { content: "BP", styles: { halign: 'right' } },
          { content: "Listing Fees", styles: { halign: 'right' } },
        ]
      ],
      body: [
        [
          { content: data.strSalesRep, colSpan: 4, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'left' } },
          { content: `$${formatAmount(data.totalSold)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalSoldCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalBuyBack)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalBuyBackCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalPassed)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalPassedCommission)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalBuyerPremium)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } },
          { content: `$${formatAmount(data.totalListingFees)}`, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, cellspacing: 0, lineColor: [0, 0, 0], fillColor: "#d3d3d3", halign: 'right' } }
        ],
        ...rows,
      ],
      theme: 'plain',
      headStyles: {
        fontSize: 9,
        textColor: "#000000",
        fontStyle: 'bold',
      },
      bodyStyles: {
        fontSize: 8,
        valign: 'middle',
        textColor: "#000000",
      },
      didDrawCell: (data: any) => {
        if (data.section === 'body' && data.row.index === rows.length) {
          doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
        }
      },
      margin: { top: 40, left: 7, right: 7 },
      pageBreak: 'auto',
      didDrawPage: () => {
        footer();
      }
    });

    const summaryRows = [
      ['Net Sales', `$${formatAmount(data.totalNetSales)}`, 'Listing Fees', `$${formatAmount(data.listingFees)}`],
      ['Net Sales Commission', `$${formatAmount(data.totalNetSalesCommission)}`, 'Listing Fee Credits', `$${formatAmount(data.listingFeesCredits)}`],
      ['Net No Sales', `$${formatAmount(data.totalNetNoSales)}`, 'Fastline Advertising', `$${formatAmount(data.fastLineAdvertising)}`],
      ['Net No Sales Commission', `$${formatAmount(data.totalNetNoSalesCommission)}`, 'Lien Search Fee', `$${formatAmount(data.lienSearchFee)}`],
      ['', '', 'Direct Social Package', `$${formatAmount(data.directSocialPackage)}`],
      ['', '', 'Preferred Social Fee', `$${formatAmount(data.preferredSocialFee)}`],
      ['', '', 'Removal Fee', `$${formatAmount(data.removalFee)}`],
      ['', '', 'Commission Credit', `$${formatAmount(data.commissionCredit)}`],
      ['', '', 'Bad Debt', `$${formatAmount(data.badDebt)}`],
    ];

    summaryRows.forEach((row) => {
      const cellHeight = 6;
      const marginLeft = 15;
      const startY = doc.lastAutoTable.finalY + 2;

      const fontSize = 9;
      doc.setFontSize(fontSize);

      const labelWidth = 40;
      const valueWidth = 30;
      const labelValueGap = 1;

      const boxLineColor = [0, 0, 0];
      doc.setDrawColor(...boxLineColor);

      if (row[0]) {
        const label1X = marginLeft + labelWidth;
        doc.text(row[0], label1X, startY + cellHeight / 2 + 1, { align: 'right' });
      }
      if (row[1]) {
        const value1X = marginLeft + labelWidth + valueWidth + labelValueGap - 1;
        doc.rect(marginLeft + labelWidth + labelValueGap, startY, valueWidth, cellHeight);
        doc.text(row[1], value1X, startY + cellHeight / 2 + 1, { align: 'right' });
      }
      if (row[2]) {
        const label2X = marginLeft + labelWidth + valueWidth + 10 + labelWidth;
        doc.text(row[2], label2X, startY + cellHeight / 2 + 1, { align: 'right' });
      }
      if (row[3]) {
        const value2X = marginLeft + labelWidth + valueWidth + labelWidth + labelValueGap + 10 + valueWidth - 1;
        doc.rect(marginLeft + labelWidth + valueWidth + labelWidth + labelValueGap + 10, startY, valueWidth, cellHeight);
        doc.text(row[3], value2X, startY + cellHeight / 2 + 1, { align: 'right' });
      }

      doc.lastAutoTable.finalY += cellHeight;
    });

    const totalCommissionsRow = [
      '', '', 'Total Commissions', `$${formatAmount(data.totalCommissions)}`
    ];

    const cellHeight = 6;
    const marginLeft = 95;
    const startY = doc.lastAutoTable.finalY - cellHeight * 0.7;

    const fontSize = 9;
    doc.setFontSize(fontSize);

    const labelWidth = 40;
    const valueWidth = 30;
    const labelValueGap = 1;

    const boxLineColor = [0, 0, 0];
    doc.setDrawColor(...boxLineColor);

    if (totalCommissionsRow[2]) {
      doc.setFont('Helvetica', 'bold');
      const label2X = marginLeft + labelWidth + valueWidth + 10 + labelWidth;
      doc.text(totalCommissionsRow[2], label2X, startY + cellHeight / 2 + 1, { align: 'right' });
      doc.setFont('Helvetica', 'normal');
    }

    if (totalCommissionsRow[3]) {
      const value2X = marginLeft + labelWidth + valueWidth + labelWidth + labelValueGap + 10 + valueWidth - 1;
      doc.rect(marginLeft + labelWidth + valueWidth + labelWidth + labelValueGap + 10, startY, valueWidth, cellHeight);
      doc.text(totalCommissionsRow[3], value2X, startY + cellHeight / 2 + 1, { align: 'right' });
    }
    doc.lastAutoTable.finalY += cellHeight;
  })

  doc.save("Summary_Of_All_Sellers_By_SalesRep.pdf");
}

///////////////////////////// CSV starts /////////////////////////////////

export const generateAllSellerSummaryCsv = (allSellerSummary: any) => {
  if (!allSellerSummary || allSellerSummary.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }

  const auctionLabel = allSellerSummary.length > 0 ? allSellerSummary[0].auctionTitle : '';

  let data = allSellerSummary.map((item: any, index: any) => ({
    "#": item.sellerId,
    "Seller Name": `(#${item.sellerId}) ${item.sellerName}`,
    "Company": item.company,
    "Items": item.items ? item.items : 0,
    "Total Sold": item.items == 0 ? '$0.00' : `$${formatAmount(item.totalSold)}`

  }));

  const wsData = [
    ['All Seller Summary Report'],
    [`Auction Name: ${auctionLabel}`],
    [`Printed On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['ID', 'Seller Name', 'Company', 'Items', 'Total Sold'],
    ...data.map((item: any) => [
      item['#'],
      item['Seller Name'],
      item['Company'],
      item['Items'],
      item['Total Sold']
    ]),
  ];

  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 50 },
    { wpx: 200 },
    { wpx: 150 },
    { wpx: 80 },
    { wpx: 100 },
  ];

  const headingStyle = {
    font: { bold: true },
    alignment: { horizontal: 'center' }
  };

  ws['A5'].s = headingStyle;
  ws['B5'].s = headingStyle;
  ws['C5'].s = headingStyle;
  ws['D5'].s = headingStyle;
  ws['E5'].s = headingStyle;

  const rightAlignStyle = {
    alignment: { horizontal: 'right' }
  };

  const startRow = 6;
  for (let i = 0; i < data.length; i++) {
    const cellRef = `E${startRow + i}`;
    if (!ws[cellRef]) ws[cellRef] = {};
    ws[cellRef].s = rightAlignStyle;
  }

  const wb = XLSXJS.utils.book_new();
  XLSXJS.utils.book_append_sheet(wb, ws, 'Seller Summary');

  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'All_Seller_Summary_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateSellerCommissionCsv = (sellerCommissionSummaryData: any) => {
  if (!sellerCommissionSummaryData || sellerCommissionSummaryData.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }

  const auctionLabel = sellerCommissionSummaryData.length > 0 ? sellerCommissionSummaryData[0].auctionTitle : '';

  const data = sellerCommissionSummaryData?.map((data: any) => {
    return {
      "#": data?.sellerId,
      "Seller Name": data?.sellerName,
      "Sold Count": `${data?.sold}` || '0',
      "Passed": `${data?.passed}` || '0',
      "Buyback": `${data?.buyback}` || '0',
      "Non-Payer": `${data?.nonPayer}` || '0',
      "Comm Total": `$${formatAmount(data?.commTotal)}` || '$0.00',
      "Adjust Total": `$${formatAmount(data?.adjustTotal)}` || '$0.00',
      "Seller Lot Count": data?.sellerLotCount || '0',
      "Notes": data?.notes || '-',
    };
  });

  const totals = {
    sellerId: '',
    sellerName: 'Totals',
    sold: `${sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.sold), 0)}`,
    passed: `${sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.passed), 0)}`,
    buyBack: `${sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.buyback), 0)}`,
    unSold: `${sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.nonPayer), 0)}`,
    commTotal: sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.commTotal || 0), 0) === 0
      ? '$0.00'
      : `$${formatAmount(sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.commTotal || 0), 0))}`,
    adjustTotal: sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.adjustTotal || 0), 0) === 0
      ? '$0.00'
      : `$${formatAmount(sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + (item.adjustTotal || 0), 0))}`,
    sellerLotCount: `${sellerCommissionSummaryData.reduce((acc: any, item: any) => acc + parseFloat(item.sellerLotCount), 0)}`,
  };

  const wsData = [
    ['Seller Commission Report'],
    [`Auction Name: ${auctionLabel}`],
    [`Created On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['#', 'Seller Name', 'Sold Count', 'Passed Count', 'Buyback Count', 'Non-Payer Count', 'Comm Total', 'Adjust Total', 'Seller Lot Count', 'Notes'],
    ...data.map((item: any) => Object.values(item)),
    [],
    ['', '', 'Sold Count', 'Passed Count', 'Buyback Count', 'Non-Payer Count', 'Comm Total', 'Adjust Total', 'Seller Lot Count'],
    Object.values(totals)
  ];

  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 50 },
    { wpx: 200 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 120 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 150 },
  ];

  const headingStyle = {
    font: { bold: true },
    alignment: { horizontal: 'center' }
  };

  const rightAlignStyle = {
    alignment: { horizontal: 'right' },
    font: { bold: false }
  };

  const boldTotalsStyle = {
    font: { bold: true }
  };

  const headerRow = 4;
  for (let col = 0; col < wsData[headerRow].length; col++) {
    const cellRef = XLSXJS.utils.encode_cell({ c: col, r: headerRow });
    if (!ws[cellRef]) ws[cellRef] = {};
    ws[cellRef].s = headingStyle;
  }

  for (let row = headerRow + 1; row < wsData.length - 2; row++) {
    const commTotalCellRef = XLSXJS.utils.encode_cell({ c: 6, r: row });
    const adjustsTotalCellRef = XLSXJS.utils.encode_cell({ c: 7, r: row });

    if (!ws[commTotalCellRef]) ws[commTotalCellRef] = {};
    ws[commTotalCellRef].s = rightAlignStyle;

    if (!ws[adjustsTotalCellRef]) ws[adjustsTotalCellRef] = {};
    ws[adjustsTotalCellRef].s = rightAlignStyle;
  }

  const summaryLabelsRow = wsData.length - 2;
  const totalsRow = wsData.length - 1;

  for (let col = 0; col < wsData[summaryLabelsRow].length; col++) {
    const summaryCellRef = XLSXJS.utils.encode_cell({ c: col, r: summaryLabelsRow });
    const totalsCellRef = XLSXJS.utils.encode_cell({ c: col, r: totalsRow });

    if (!ws[summaryCellRef]) ws[summaryCellRef] = {};
    ws[summaryCellRef].s = boldTotalsStyle;

    if (!ws[totalsCellRef]) ws[totalsCellRef] = {};
    ws[totalsCellRef].s = boldTotalsStyle;
  }

  const wb = XLSXJS.utils.book_new();
  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');

  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Seller_Commission_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateSellerCommissionSummaryCsv = (sellerCommissionSummary: any) => {
  if (!sellerCommissionSummary || sellerCommissionSummary.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }
  const auctionLabel = sellerCommissionSummary.length > 0 ? sellerCommissionSummary[0].auctionTitle : '';

  let data = sellerCommissionSummary?.map((data: any) => ({
    sellerId: data?.sellerId,
    sellerName: data?.sellerName,
    totalExtPrice: `$${formatAmount(data?.totalExtPrice)}` || '0',
    sold: `$${formatAmount1(data?.sold)}` || '0',
    passed: `$${formatAmount1(data?.passed)}` || '0',
    buyBack: `$${formatAmount1(data?.buyBack)}` || '0',
    unSold: `$${formatAmount1(data?.unSold)}` || '0',
    commTotal: `$${formatAmount1(data?.commTotal)}` || '0',
    adjust: `$${formatAmount1(data?.adjust)}` || '0',
    payments: `$${formatAmount1(data?.payments)}` || '0',
    net: `$${formatAmount(data?.net)}` || '0',
  }));

  const totals = {
    sellerId: '',
    sellerName: 'Totals',
    totalExtPrice: `$${formatAmount(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.totalExtPrice), 0))}`,
    sold: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.sold), 0))}`,
    passed: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.passed), 0))}`,
    buyBack: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.buyBack), 0))}`,
    unSold: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.unSold), 0))}`,
    commTotal: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.commTotal), 0))}`,
    adjust: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.adjust), 0))}`,
    payments: `$${formatAmount1(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.payments), 0))}`,
    net: `$${formatAmount(sellerCommissionSummary.reduce((acc: any, item: any) => acc + parseFloat(item.net), 0))}`,
  };

  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Seller Commission Summary Report'],
    [`Auction Name: ${auctionLabel}`],
    [`Created On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['Seller ID', 'Seller Name', 'Total Ext Price', 'Sold', 'Passed', 'Buyback', 'Non-Payer', 'Comm Total', 'Adjusts', 'Payments', 'NET'],
    Object.values(totals),
    ...data?.map((item: any) => Object.values(item)),
    [],
    ['', '', 'Total Ext Price', 'Sold', 'Passed', 'Buyback', 'Non-Payer', 'Comm Total', 'Adjusts', 'Payments', 'NET'],
    Object.values(totals)
  ];

  const ws: any = XLSXJS.utils.aoa_to_sheet(wsData);

  const headerStyle = {
    font: { bold: true },
  };
  const amountStyle = {
    alignment: { horizontal: 'right' },
  };
  const totalsStyle = {
    font: { bold: true },
  };

  const columns = [
    { wpx: 120 },
    { wpx: 200 },
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 120 },
    { wpx: 120 },
    { wpx: 120 },
    { wpx: 120 },
  ];

  ws['!cols'] = columns;

  const range = XLSXJS.utils.decode_range(ws['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell_ref_header = XLSXJS.utils.encode_cell({ c: C, r: 4 });
    const cell_ref_totals = XLSXJS.utils.encode_cell({ c: C, r: 5 });

    if (ws[cell_ref_header]) {
      ws[cell_ref_header].s = headerStyle;
    }
    if (ws[cell_ref_totals]) {
      ws[cell_ref_totals].s = totalsStyle;
    }
  }

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Seller_Commission_Summary_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateSellerAdjustmentCsv = (sellerAdjustData: any) => {
  if (!sellerAdjustData || sellerAdjustData.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }

  let totalAdjustmentAmount = 0;
  sellerAdjustData?.forEach((item: any) => {
    totalAdjustmentAmount += item.totalAmount;
  });

  const auctionLabel = sellerAdjustData.length > 0 ? sellerAdjustData[0].auctionTitle : '';

  let data = sellerAdjustData?.map((item: any) => ({
    description: item.description,
    totalAmount: `$${formatAmount(item.totalAmount)}`,
    lstSellerAdjust: item.lstSellerAdjust?.map((adjustment: any) => ({
      date: moment(adjustment.date).format("MM/DD/YYYY"),
      sellerId: adjustment.sellerId,
      sellerName: adjustment.sellerName,
      type: adjustment.type,
      amount: adjustment.type === "Credit" ? `$(${formatAmount(adjustment.amount)})` : `$${formatAmount(adjustment.amount)}`,
      salesTax: '-'
    }))
  }));

  const wsData = [
    ['Seller Adjustments Report'],
    [`Auction Name: ${auctionLabel}`],
    [`Printed: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['Description', 'Date', 'Seller ID', 'Seller Name', 'Type', 'Amount', 'Sales Tax'],
    ...data.flatMap((item: any) => [
      [item.description, '', '', '', '', item.totalAmount],
      ...item?.lstSellerAdjust?.map((adjustment: any) => [
        '',
        moment(adjustment.date).format("MM/DD/YYYY"),
        adjustment.sellerId,
        adjustment.sellerName,
        adjustment.type,
        adjustment.amount,
        '-'
      ]),
    ]),
    ['', '', '', '', 'TOTAL Adjustments Amount', `$${formatAmount(totalAdjustmentAmount)}`, '-'],
  ];
  const wb = XLSX.utils.book_new();
  const ws: any = XLSX.utils.aoa_to_sheet(wsData);

  const headerStyle = {
    font: { bold: true },
  };
  const totalStyle = {
    font: { bold: true },
    alignment: { horizontal: 'right' },
  };
  const amountStyle = {
    alignment: { horizontal: 'right' },
  };

  const columns = [
    { wpx: 200 },
    { wpx: 100 },
    { wpx: 50 },
    { wpx: 200 },
    { wpx: 170 },
    { wpx: 120 },
    { wpx: 100 },
  ];

  ws['!cols'] = columns;

  const range = XLSXJS.utils.decode_range(ws['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const headerCell = XLSXJS.utils.encode_cell({ c: C, r: 4 });
    const totalCell = XLSXJS.utils.encode_cell({ c: C, r: wsData.length - 1 });

    if (ws[headerCell]) ws[headerCell].s = headerStyle;
    if (ws[totalCell]) ws[totalCell].s = totalStyle;
  }

  for (let R = 5; R < wsData.length - 1; ++R) {
    for (let C = 5; C <= 5; ++C) {
      const cell_ref = XLSXJS.utils.encode_cell({ c: C, r: R });
      if (ws[cell_ref]) ws[cell_ref].s = amountStyle;
    }
  }

  XLSXJS.utils.book_append_sheet(wb, ws, 'Seller Adjustments');

  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Seller_Adjustments_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateSellerPaymentSummaryCsv = (sellerPaymentsData: any) => {
  const wb = XLSXJS.utils.book_new();

  const auctionLabel = sellerPaymentsData.length > 0 ? sellerPaymentsData[0].auctionTitle : '';

  let totalPaid = 0;
  let totalRecived = 0;

  sellerPaymentsData.forEach((item: any) => {
    let itemTotalPaid = 0;
    let itemTotalRecived = 0;
    item.lstSellerAdjust.forEach((transaction: any) => {
      itemTotalPaid += transaction.amtPaid;
      itemTotalRecived += transaction.amtRecived;
    });
    totalPaid += itemTotalPaid;
    totalRecived += itemTotalRecived;
  });

  const wsData = [
    ['Seller Payment Summary Report'],
    [`Auction Name: ${auctionLabel}`],
    [`Printed: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['#', 'Seller Name', 'Comm', 'Adjust', 'Date', 'Amt Paid', `Amt Rec'd`, 'Type', 'Check #', 'User'],
  ];
  const sellerPayments = sellerPaymentsData.flatMap((item: any, index: any) => [
    {
      sellerId: item.sellerId,
      sellerName: item.sellerName,
      commTotal: item.commTotal ? `$${formatAmount(item.commTotal)}` : '$0.00',
      adjust: item.adjust ? `$${formatAmount(item.adjust)}` : '$0.00',
      date: '',
      amtPaid: '',
      amtRecived: '',
      type: '',
      check: '',
      user: ''
    },
    ...item.lstSellerAdjust.map((transaction: any) => ({
      sellerId: '',
      sellerName: '',
      commTotal: '',
      adjust: '',
      date: transaction.date,
      amtPaid: transaction.amtPaid == 0 ? '$0.00' : `$${formatAmount(transaction.amtPaid)}`,
      amtRecived: transaction.amtRecived == 0 ? '$0.00' : `$${formatAmount(transaction.amtRecived)}`,
      type: transaction.typeString,
      check: transaction.check == 0 ? '-' : transaction.check,
      user: transaction.user ? transaction.user : '-'
    }))
  ]);
  sellerPayments.forEach((item: any) => {
    wsData.push([
      item.sellerId,
      item.sellerName,
      item.commTotal,
      item.adjust,
      item.date,
      item.amtPaid,
      item.amtRecived,
      item.type,
      item.check,
      item.user
    ]);
  });

  const ws: any = XLSXJS.utils.aoa_to_sheet(wsData);

  const headerStyle = {
    font: { bold: true },
    alignment: { horizontal: 'center' }
  };
  const totalStyle = {
    font: { bold: true },
    alignment: { horizontal: 'right' }
  };
  const amountStyle = {
    alignment: { horizontal: 'right' }
  };

  const columns = [
    { wpx: 50 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 50 },
    { wpx: 50 },
    { wpx: 150 },
  ];

  ws['!cols'] = columns;

  const headerRowIndex = 4;
  const headerRow = wsData[headerRowIndex];
  headerRow.forEach((_, colIndex) => {
    const cell_ref = XLSX.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = headerStyle;
  });

  const totalRow = ['', '', '', '', 'Totals',
    `$${formatAmount(totalPaid)}`,
    `$${formatAmount(totalRecived)}`,
    '', '', ''
  ];

  wsData.push(totalRow);
  XLSXJS.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });

  const totalsRowIndex = wsData.length - 1;
  const totalsRow = wsData[totalsRowIndex];
  totalsRow.forEach((_, colIndex) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: totalsRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = totalStyle;
  });

  for (let R = 5; R < wsData.length - 1; ++R) {
    for (let C = 5; C <= 6; ++C) {
      const cell_ref = XLSXJS.utils.encode_cell({ c: C, r: R });
      if (ws[cell_ref]) ws[cell_ref].s = amountStyle;
    }
  }

  const commColIndex = 2;
  const adjustColIndex = 3;

  for (let R = 5; R < wsData.length; ++R) {
    const commCell_ref = XLSXJS.utils.encode_cell({ c: commColIndex, r: R });
    const adjustCell_ref = XLSXJS.utils.encode_cell({ c: adjustColIndex, r: R });
    if (ws[commCell_ref]) ws[commCell_ref].s = amountStyle;
    if (ws[adjustCell_ref]) ws[adjustCell_ref].s = amountStyle;
  }

  XLSXJS.utils.book_append_sheet(wb, ws, 'Seller Payments');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Seller_Payment_Summary_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateSellerPickupSummaryCsv = (sellerPickupSummary: any) => {
  const data: any = [];

  if (!sellerPickupSummary) {
    console.error("Seller pickup summary data is undefined or null.");
    return;
  }

  sellerPickupSummary.forEach((item: any, index: any) => {
    data.push(["To:", `${item.sellerName}`, 'Auction Summary- Commission Based per item']);
    data.push(["", `${item.company}`, `Total Sales: $${formatAmount(item.totalSales)}`]);
    data.push(["", `${item.sellerCityName} ${item.sellerStateName} ${item.sellerZipCode}`, `Total Passed: $${formatAmount(item.totalPassed)}`]);
    data.push(["", `C: ${formatPhoneNumber(item.sellerMobile)}`, `Total Buyback: $${formatAmount(item.totalBuyBack)}`]);
    data.push([]);
    data.push(["Regarding:", `Auction Title: ${item.auctionTitle} (Auction: ${item.auctionCode})`]);
    data.push([]);

    if (Array.isArray(item.lstinvoicepickUpReport)) {
      item.lstinvoicepickUpReport.forEach((lstData: any) => {
        data.push(["Lot #", "Lot Title", "Sale Price"]);
        data.push([
          { t: 's', v: `Invoice: ${lstData.invoiceNumber}`, s: { font: { bold: true } } },
          { t: 's', v: `Bidder Invoice Verification Code: ${lstData.verificationCode}` },
          { t: 's', v: `$${lstData.totalSalesPrice ? formatAmount(lstData.totalSalesPrice) : '0.00'}` }
        ]);
        data.push([`${lstData.customerName} | #${lstData.bidderNumber} | c: ${formatPhoneNumber(lstData.customerMobile)} | ${lstData.customerEmail}`]);

        if (Array.isArray(lstData.lstLotDetails)) {
          lstData.lstLotDetails.forEach((lot: any) => {
            data.push([
              lot.lotNo,
              lot.lotTitle.replace(/<[^>]*>?/gm, ""),
              `$${formatAmount(lot.lotPrice)}`
            ]);
          });
        }
        data.push([]);
      });
    }

    data.push([]);
    data.push([`Seller Number: ${item.sellerId}`]);

    if (index < sellerPickupSummary.length - 1) {
      data.push([]);
    }
  });

  const wsData = [
    ["Seller Pickup Summary Report"],
    [`Created On: ${moment().format("MM/DD/YYYY hh:mm A")} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`],
    [],
    ...data
  ];
  const wb = XLSXJS.utils.book_new();
  const ws: any = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 200 },
    { wpx: 250 },
    { wpx: 150 }
  ];

  const boldCells = ['A4', 'A9'];
  boldCells.forEach(cell => {
    if (ws[cell]) {
      ws[cell].s = { ...ws[cell].s, font: { bold: true } };
    }
  });

  const lastRowIndex = wsData.length - 1;
  const cellAddress = `A${lastRowIndex + 1}`; // Adjust column letter if necessary
  if (ws[cellAddress]) {
    ws[cellAddress].s = { font: { bold: true } };
  }

  XLSXJS.utils.book_append_sheet(wb, ws, "Seller Pickup Summary");
  const excelBuffer = XLSXJS.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "Seller_Pickup_Summary_Report.xlsx";
  a.click();
  URL.revokeObjectURL(url);
};

export const generateBalanceDueByBidderCsv = (balanceDueData: any, totals: any, totalLotLength: any) => {
  // Extract data for header
  const firstItem = balanceDueData[0] || {};
  const auctionTitle = firstItem.auctionTitle || '';
  const auctionId = firstItem.auctionCode || '';
  const auctionDate = firstItem.auctionDate ? moment(firstItem.auctionDate).format('MM/DD/YYYY') : '';
  const address = firstItem.auctionAddress || '';
  const city = firstItem.cityName || '';
  const state = firstItem.stateName || '';
  const zipCode = firstItem.zipCode || '';

  const headerStyle = {
    font: {
      bold: true,
      sz: 12,
    },
  };

  const contentStyle = {
    font: {
      sz: 11,
    },
  };

  const wsData: any = [
    [{ v: 'Del Peterson & Associates, Inc.', s: headerStyle }],
    [{ v: `${auctionTitle}`, }],
    [{ v: `${address}`, }],
    [{ v: `${city}, ${state} ${zipCode}`, }],
    [],
    [{ v: 'Balance Due Lots', s: headerStyle }],
    [{ v: `Auction #${auctionId}` }],
    [],
    [
      { v: "", s: headerStyle },
      { v: "Invoice Total", s: headerStyle },
      { v: "Paid to Date", s: headerStyle },
      { v: "Balance Due", s: headerStyle },
      { v: "", s: headerStyle },
      { v: "", s: headerStyle },
      { v: "", s: headerStyle },
      { v: "", s: headerStyle },
    ]
  ];

  balanceDueData.forEach((data: any) => {

    // Add customer row
    const customerRow: any = [
      { v: `${data.customerId} - ${data.customerName}; ${data.company || ''} ${formatPhoneNumber(data.mobile)}`, s: { ...contentStyle, font: { bold: true }, fill: { fgColor: { rgb: "cccccc" } } } },

      { v: `$${formatAmount(data.customerInvoiceTotal)}`, s: { ...contentStyle, fill: { fgColor: { rgb: "cccccc" } } } },
      { v: data.customerPaidToDateTotal ? `$${formatAmount(data.customerPaidToDateTotal)}` : '$0', s: { ...contentStyle, fill: { fgColor: { rgb: "cccccc" } } } },
      { v: `$${formatAmount(data.customerBalDueTotal)}`, s: { ...contentStyle, fill: { fgColor: { rgb: "cccccc" } } } },
      { v: "", s: { ...contentStyle } },
      { v: "", s: contentStyle, }
    ];
    wsData.push(customerRow);

    (data.lstInvoiceBalDue || []).forEach((InvData: any) => {
      const invoiceRow: any = [
        { v: `Invoice ID: ${InvData.invoiceMasterId}`, s: { ...contentStyle, font: { bold: true }, fill: { fgColor: { rgb: "ebebeb" } } } },
        { v: `$${formatAmount(InvData.invoiceTotal)}`, s: { ...contentStyle, fill: { fgColor: { rgb: "ebebeb" } } } },
        { v: InvData.paidToDate ? `$${formatAmount(InvData.paidToDate)}` : '$0', s: { ...contentStyle, fill: { fgColor: { rgb: "ebebeb" } } } },
        { v: `$${formatAmount(InvData.balDue)}`, s: { ...contentStyle, fill: { fgColor: { rgb: "ebebeb" } } } },
        { v: "", s: { ...contentStyle } },
        { v: "", s: { ...contentStyle } }
      ];

      wsData.push(invoiceRow);

      (InvData.lstLotInvoice || []).forEach((lotData: any) => {
        const lotRow: any = [
          { v: `       ${lotData.lotNo} ${lotData.lotTitle.replace(/<[^>]*>?/gm, "")}`, s: contentStyle },
          { v: `$${formatAmount(lotData.lotPrize)}`, s: contentStyle },
          { v: "-", s: contentStyle },
          { v: "-", s: contentStyle },
          { v: "", s: contentStyle },
          { v: "", s: contentStyle }
        ];
        wsData.push(lotRow);
      });
    });
  });

  const footerRow: any = [
    { v: `Totals for ${totalLotLength} lots`, s: { ...headerStyle, font: { sz: 12, bold: true }, fill: { fgColor: { rgb: "cccccc" } } } },
    { v: `$${totals.totalInvoice ? formatAmount(totals.totalInvoice) : '$0'}`, s: { ...headerStyle, font: { sz: 12, bold: true }, fill: { fgColor: { rgb: "cccccc" } } } },
    { v: `$${totals.totalPaid ? formatAmount(totals.totalPaid) : '0'}`, s: { ...headerStyle, font: { sz: 12, bold: true }, fill: { fgColor: { rgb: "cccccc" } } } },
    { v: `$${totals.totalBalanceDue ? formatAmount(totals.totalBalanceDue) : '$0'}`, s: { ...headerStyle, font: { sz: 12, bold: true }, fill: { fgColor: { rgb: "cccccc" } } } },
    { v: '', s: contentStyle },
    { v: '', s: contentStyle },
    { v: '', s: contentStyle },
  ];
  wsData.push([]);
  wsData.push(footerRow);

  wsData.push(['', '', '', '', '', '', '', '']);
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  const cols = [
    { wpx: 280 },
    { wpx: 120 },
    { wpx: 120 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 }
  ];

  ws['!cols'] = cols;
  const wb = XLSXJS.utils.book_new();
  XLSXJS.utils.book_append_sheet(wb, ws, 'Balance Due Lots');
  XLSXJS.writeFile(wb, 'Balance_Due_Lots_Report.xlsx');
};

export const generateSummaryOfSellerBySalesRepCsv = (sellerSummaryBySalesRepData: any, sellerSummaryTotals: any) => {
  const wsData = [
    ['Del Peterson & Associates, Inc.'],
    [`${sellerSummaryTotals?.auctionName}`],
    [`${sellerSummaryTotals?.auctionAddress}`],
    [`${sellerSummaryTotals?.auctionCity}, ${sellerSummaryTotals?.auctionState} ${sellerSummaryTotals?.auctionZipCode}`],
    [],
    ['Summary of Sellers by Sales Rep'],
    [`Auction #${sellerSummaryTotals?.auctionCode} - ${sellerSummaryTotals?.auctionStartDate}`],
    [],
    [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    [],
    ['Date', 'Auc Code', 'Seller Name', 'ID', 'Total Sold', 'Commission', 'Buyback', 'Comm', 'Passed', 'Comm', 'BP', 'Listing Fees']
  ];

  sellerSummaryBySalesRepData.forEach((data: any) => {
    wsData.push([
      data.strSalesRep, '', '', '',
      `$${formatAmount(data.totalSold)}`,
      `$${formatAmount(data.totalSoldCommission)}`,
      `$${formatAmount(data.totalBuyBack)}`,
      `$${formatAmount(data.totalBuyBackCommission)}`,
      `$${formatAmount(data.totalPassed)}`,
      `$${formatAmount(data.totalPassedCommission)}`,
      `$${formatAmount(data.totalBuyerPremium)}`,
      `$${formatAmount(data.totalListingFees)}`
    ]);

    data.lstSellerSummary.forEach((sellerLst: any) => {
      wsData.push([
        moment(sellerLst.startDateTime).format('MM/DD/YYYY'),
        sellerSummaryTotals.auctionCode,
        sellerLst.sellerName,
        sellerLst.sellerId,
        `$${formatAmount(sellerLst.soldAmount)}`,
        `$${formatAmount(sellerLst.soldCommission)}`,
        `$${formatAmount(sellerLst.buyBackAmount)}`,
        `$${formatAmount(sellerLst.buyBackCommission)}`,
        `$${formatAmount(sellerLst.passedAmount)}`,
        `$${formatAmount(sellerLst.passedCommission)}`,
        `$${formatAmount(sellerLst.buyerPremium)}`,
        `$${formatAmount(sellerLst.listingFees)}`
      ]);
    });

    wsData.push([], []);

    wsData.push(
      ['', '', 'Net Sales', '',
        `$${formatAmount(data.totalNetSales)}`, '', 'Listing Fees',
        `$${formatAmount(data.listingFees)}`, '', '', '', ''
      ],
      ['', '', 'Net Sales Commission', '',
        `$${formatAmount(data.totalNetSalesCommission)}`, '', 'Listing Fee Credits',
        `$${formatAmount(data.listingFeesCredits)}`, '', '', '', ''
      ],
      ['', '', 'Net No Sales', '',
        `$${formatAmount(data.totalNetNoSales)}`, '', 'Fastline Advertising',
        `$${formatAmount(data.fastLineAdvertising)}`, '', '', '', ''
      ],
      ['', '', 'Net No Sales Commission', '',
        `$${formatAmount(data.totalNetNoSalesCommission)}`, '', 'Lien Search Fee',
        `$${formatAmount(data.lienSearchFee)}`, '', '', '', ''
      ],
      ['', '', '', '', '', '', 'Direct Social Package',
        `$${formatAmount(data.directSocialPackage)}`, '', '', '', ''
      ],
      ['', '', '', '', '', '', 'Preferred Social Fee',
        `$${formatAmount(data.preferredSocialFee)}`, 'Total Commissions', `$${formatAmount(data.totalCommissions)}`, ''
      ],
      ['', '', '', '', '', '', '', '', '', '', '', '']
    );
  });

  wsData.push(
    [],
    ['', '', '', 'Totals',
      `$${formatAmount(sellerSummaryTotals.finalSold)}`,
      `$${formatAmount(sellerSummaryTotals.finalSoldCommission)}`,
      `$${formatAmount(sellerSummaryTotals.finalBuyBack)}`,
      `$${formatAmount(sellerSummaryTotals.finalBuyBackCommission)}`,
      `$${formatAmount(sellerSummaryTotals.finalPassed)}`,
      `$${formatAmount(sellerSummaryTotals.finalPassedCommission)}`,
      `$${formatAmount(sellerSummaryTotals.finalBuyerPremium)}`,
      `$${formatAmount(sellerSummaryTotals.finalListingFees)}`
    ]
  );

  const ws = XLSXJS.utils.aoa_to_sheet(wsData);
  const wb = XLSXJS.utils.book_new();

  ws['!cols'] = [
    { wpx: 150 },
    { wpx: 70 },
    { wpx: 150 },
    { wpx: 50 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 }
  ];

  const headerRowIndex = 10;
  wsData[headerRowIndex].forEach((_, colIndex) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = { font: { bold: true } };
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Seller Summary');
  XLSXJS.writeFile(wb, 'Summary_Of_All_Sellers_By_SalesRep.xlsx');
};

{/** ********************** Seller Reports End ************************* */ }

{/** ********************* Sales Rep Report Starts ***********************/ }

export const generateSalesRepCommReportPdf = (salesRepCommissionData: any) => {
  const doc: any = new jsPDF();

  let auctionId = '';
  let auctionTitle = '';

  const data = salesRepCommissionData.map((data: any) => {
    auctionId = data.auctionCode;
    auctionTitle = data.auctionTitle ? data.auctionTitle : '-';
    return [
      {
        content: data.salesRepId, styles: {
          lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 },
          lineColor: [0, 0, 0], halign: 'left'
        }
      },
      {
        content: data?.salesRepName, styles: {
          lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 },
          lineColor: [0, 0, 0], halign: 'left'
        }
      },
      { content: data?.commissionType, styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'left' } },
      { content: data?.salesRepSold || '-', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'left' } },
      { content: data?.salesRepPassed || '-', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'left' } },
      { content: data?.salesRepPassed || '-', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'left' } },
      { content: `$${formatAmount(data?.salesRepCommissionTotal)}` || '$0.00', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(data?.salesRepAdjustTotal)}` || '$0.00', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'right' } },
      { content: `$${formatAmount(data?.salesRepInvoiceAdjustsTotal)}` || '$0.00', styles: { lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], halign: 'right' } },
    ];
  });

  const newr: any = data.map((i: any) => {
    return Object.values(i);
  });

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });
    doc.text(`${auctionTitle}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Sales Rep Commission Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 17, { align: "right" });

  };

  const footer = (doc: any, auctionId: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Sales Rep Commission Report Auction #${auctionId}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    head: [
      [
        { content: "Sales Rep ID", styles: { halign: 'left' } },
        { content: "Sales Rep Name", styles: { halign: 'left' } },
        { content: "Comm Type", styles: { halign: 'left' } },
        { content: "Sold", styles: { halign: 'left' } },
        { content: "Passed", styles: { halign: 'left' } },
        { content: "Buyback", styles: { halign: 'left' } },
        { content: "Comm Total", styles: { halign: 'left' } },
        { content: "Adjust Total", styles: { halign: 'left' } },
        { content: "Invoice Total", styles: { halign: 'left' } }
      ]
    ],
    body: newr,
    startY: 35,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 8,
      fillColor: null,
      fontStyle: 'bold',
      textColor: "#000000",
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 50, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc);
      footer(doc, auctionId);
    },
  });
  doc.save(`Sales_Rep_Commission_Report.pdf`);
}

export const generateSalesRepCommReportCsv = (salesRepCommissionData: any) => {
  if (!salesRepCommissionData || salesRepCommissionData.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }
  let auctionTitle = '';
  let data = salesRepCommissionData?.map((data: any) => {
    auctionTitle = data.auctionTitle ? data.auctionTitle : '-';
    return {
      salesRepId: data?.salesRepId,
      salesRepName: data?.salesRepName,
      commissionType: data?.commissionType,
      sold: data?.salesRepSold || '-',
      passed: data?.salesRepPassed || '-',
      buyback: data?.salesRepPassed || '-',
      salesRepCommissionTotal: `$${formatAmount(data?.salesRepCommissionTotal)}` || '0',
      salesRepAdjustTotal: `$${formatAmount(data?.salesRepAdjustTotal)}` || '0',
      salesRepInvoiceAdjustsTotal: `$${formatAmount(data?.salesRepInvoiceAdjustsTotal)}` || '0',
    }
  });

  const wb = XLSX.utils.book_new();
  const wsData = [
    ['Sales Rep Commission Report'],
    [`Auction Name:- ${auctionTitle}`],
    [`Created On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
    [],
    ['Sales Rep ID', 'Sales Rep Name', 'Comm Type', 'Sold', 'Passed', 'Buyback', 'Comm Total', 'Adjust Total', 'Invoice Total'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 100 },
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
  ];

  const headerRowIndex = 4;
  wsData[headerRowIndex].forEach((_: any, colIndex: any) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = { font: { bold: true } };
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Sales_Rep_Commission_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

{/** ********************* Sales Rep Report ends ***********************/ }

{/** ********************* Inventory Report Starts ***********************/ }

export const generateInventoryImportLogsPdf = (notifications: any) => {
  const doc: any = new jsPDF();

  const head = [['#', 'Module', 'Mode', 'Filename', 'Total Count', 'Success Count', 'Failed Count', 'Updated On']]
  const data = notifications.map((data: any) => {
    return {
      srNo: data.srNo || "-",
      // notificationId: data.notificationId || "-",
      module: data.module || "-",
      mode: data.mode || "-",
      fileName: data.fileName || "-",
      // sourceFilePath: data.sourceFilePath ? data.sourceFilePath.split('\\')[3] : "-",
      // responseFilePath: data.responseFilePath ? data.responseFilePath.split('\\')[3] : "-",
      totalCount: formatNumber(data.totalCount) || "0",
      successCount: formatNumber(data.successCount) || "0",
      failedCount: formatNumber(data.failedCount) || "0",
      // createdOn: data.createdOn || "-",
      updatedOn:
        data.updatedOn ? moment(data.updatedOn).format('MM/DD/YYYY hh:mm A')
          :
          data.createdOn ? moment(data.createdOn).format('MM/DD/YYYY hh:mm A') : "-",

    }
  })
  const newr: any = data.map((i: any) => {
    return Object.values(i)
  })

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Inventory Import Logs Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");

  };

  const footer = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Inventory Import Logs`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    startY: 30,
    head: head,
    body: newr,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 8,
      fillColor: null,
      fontStyle: 'bold',
      textColor: "#000000",
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 40, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc);
      footer(doc);
    },
  });

  doc.save(`Inventory_Import_Logs.pdf`);
}

export const generateInventoryImportLogsCsv = (notifications: any) => {
  let data = notifications?.map((data: any) => {
    return {
      srNo: data.srNo || "-",
      // notificationId: data.notificationId || "-",
      module: data.module || "-",
      mode: data.mode || "-",
      fileName: data.fileName || "-",
      // sourceFilePath: data.sourceFilePath ? data.sourceFilePath.split('\\')[3] : "-",
      // responseFilePath: data.responseFilePath ? data.responseFilePath.split('\\')[3] : "-",
      totalCount: formatNumber(data.totalCount) || "0",
      successCount: formatNumber(data.successCount) || "0",
      failedCount: formatNumber(data.failedCount) || "0",
      // createdOn: data.createdOn || "-",
      // updatedOn: data.updatedOn || data.createdOn || "-",
      updatedOn:
        data.updatedOn ? moment(data.updatedOn).format('MM/DD/YYYY hh:mm A')
          :
          data.createdOn ? moment(data.createdOn).format('MM/DD/YYYY hh:mm A') : "-",
    };
  });

  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Inventory Import Logs Report'],
    [`Printed On (in UTC): ${moment(UTCdateConverter(new Date())).format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['#', 'Module', 'Mode', 'Filename', 'Total Count', 'Success Count', 'Failed Count', 'Updated On'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 50 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 200 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 150 },
  ];

  const headerRowIndex = 3;
  wsData[headerRowIndex].forEach((_: any, colIndex: any) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = { font: { bold: true } };
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Inventory_Import_Logs.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

export const generateInventoryBySellerPdf = (inventoryBySellerLisr: any, getGlobalAuction: any) => {
  const doc: any = new jsPDF();
  const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
  const tableColumn = [
    'Seller Id', 'Company Name ', 'Seller Name', 'Inventory Lots', 'Sales Lots', 'Sold Lots'
  ];
  const tableRows: any = [];
  let auctionId = '';
  let auctionDate = ''

  inventoryBySellerLisr.forEach((data: any, index: any) => {
    const rowData = [
      data.sellerId || "-",
      data.company || "-",
      data.sellerName || "-",
      data.inventoryLots || "-",
      formatNumber(data.salesLots) || "0",
      formatNumber(data.soldLots) || "0",
    ];
    tableRows.push(rowData);
  });

  const header = (doc: any, auctionId: any, auctionDate: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold", });
    doc.text(`${getGlobalAuction?.label}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Inventory By Seller Report", rightAlignX, 12, { align: "right", fontStyle: "bold", });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${getGlobalAuction?.value}`, rightAlignX, 17, { align: "right" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, rightAlignX, 22, {
      align: "right",
    });
  };

  const footer = (doc: any, auctionId: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Inventory By Seller Report ${getGlobalAuction?.value}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 40,
    theme: "striped",
    margin: { top: 50, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc, auctionId, auctionDate);
      footer(doc, auctionId);
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
    },
    headStyles: {
      halign: "left",
      cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
      fillColor: null,
      textColor: '#666666',
    },
    columnStyles: {
      3: { cellWidth: 50 }
    },
  });
  doc.save('Inventory_By_Seller.pdf');
}

export const generateInventoryBySellerCsv = (inventoryBySellerLisr: any, getGlobalAuction: any) => {
  let data = inventoryBySellerLisr?.map((data: any) => {
    return {
      sellerId: data?.sellerId || "-",
      company: data?.company || "-",
      sellerName: data?.sellerName || "-",
      inventoryLots: data?.inventoryLots || "-",
      salesLots: formatNumber(data?.salesLots) || "0",
      soldLots: formatNumber(data?.soldLots) || "0",
    }
  });

  const wb = XLSXJS?.utils?.book_new();
  const wsData = [
    ['Inventory by Seller-Report'],
    [`Printed On (in UTC): ${moment(UTCdateConverter(new Date())).format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['Seller Id', 'Company Name ', 'Seller Name', 'Inventory Lots', 'Sales Lots', 'Sold Lots'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 50 },
    { wpx: 170 },
    { wpx: 150 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 100 },
  ];

  const headerRowIndex = 3;
  wsData[headerRowIndex].forEach((_: any, colIndex: any) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) {
      ws[cell_ref].s = {
        font: { bold: true },
      };
    }
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Inventory_By_Seller_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

export const generateTaxSummaryByJurisdictionPdf = (filterTaxData: any, getGlobalAuction: any) => {
  const doc: any = new jsPDF('l', 'mm', 'a4');
  const tableRows: any = [];
  let auctionId = '';
  let date: any = '';
  let totalsByTaxRegion : any = ''; 
  let auctionCode = '';

 const calculateTotals = (filterTaxData:any) => {
  const totals:any = {};
  filterTaxData?.forEach((regionData:any) => {
      const { taxRegion, subResp }:any= regionData;

      if (!totals[taxRegion]) {
          totals[taxRegion] = {
              taxTypes: {},
              totalLotPrice: 0.00,
              totalTaxCharged: 0.00
          };
      }
      subResp.forEach((sub:any) => {
          totals[taxRegion].totalLotPrice += sub.lotPrice;
          totals[taxRegion].totalTaxCharged += sub.taxCharged;
          if (!totals[taxRegion].taxTypes[sub.taxType]) {
              totals[taxRegion].taxTypes[sub.taxType] = 0;
          }
          totals[taxRegion].taxTypes[sub.taxType] += sub.taxCharged;
      });
  });

  return totals;
};

// total of over all lot price 
const calculateTotalLotPrice = () => {
  let total = 0;
  filterTaxData?.forEach((item:any) => {
    item.subResp?.forEach((subItem:any) => {
      total += subItem.lotPrice;
    });
  });
  return total;
};
const totalLotPrice = calculateTotalLotPrice();
// total of over all tax charged 
const calculateTotalTaxCharged = () => {
  let total = 0;
  filterTaxData?.forEach((item:any) => {
    item.subResp?.forEach((subItem:any) => {
      total += subItem.taxCharged;
    });
  });
  return total;
};
const totalTaxCharged = calculateTotalTaxCharged();


  filterTaxData?.forEach((data: any) => {
    date = data?.auctionDate;
    auctionCode = data.auctionCode;
    const newData = calculateTotals(filterTaxData);
    totalsByTaxRegion = newData;
    // Tax Region row (full span)
    tableRows?.push([
      { content: `${data.taxRegion}`, colSpan: 10, styles: { fontStyle: 'bold', halign: 'start', fillColor: '#c0c0c0', fontSize: 10 } },
      { content: `$${formatAmount(totalsByTaxRegion[data.taxRegion]?.totalTaxCharged)}`, styles: { fontStyle: 'bold', halign: 'right', fillColor: '#c0c0c0', fontSize: 10 } }
    ]);

    tableRows.push([
      { content: '', colSpan: 3, styles: { fillColor: '#f1f1f1' } },  // Empty cell for Auction Code
      { content: `${data.taxRegion}`,  styles: { fontStyle: 'bold', halign: 'left', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } },
      { content: `${data.taxType}`, colSpan: 1, styles: { fontStyle: 'bold', halign: 'left', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } },
      { content: '', colSpan: 4, styles: { fillColor: '#f1f1f1' } },
      { content: `$${formatAmount(totalsByTaxRegion[data.taxRegion]?.totalLotPrice)}`, colSpan: 1, styles: { fontStyle: 'bold', halign: 'right', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } },
      { content: `$${formatAmount(totalsByTaxRegion[data.taxRegion]?.totalTaxCharged)}`, colSpan: 1, styles: { fontStyle: 'bold', halign: 'right', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } },
    ]);

    // Add the sub rows for each lot
    data?.subResp?.forEach((subItem: any) => {
      // tableRows.push(totalsHeader);
      const rowData = [
        { content: data?.sellerId || '-', styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: data?.sellerName, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: data.taxCode, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: data.taxRegion || "-", styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: data.taxType || "-", styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: subItem.taxName, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: subItem.lotNo, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: subItem.address, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: subItem.type, styles: { halign: 'left', fillColor: '#f7f9fb' } },
        { content: `$${formatAmount(subItem?.lotPrice)}`, styles: { halign: 'right', fillColor: '#f7f9fb' } },
        { content: `$${formatAmount(subItem?.taxCharged)}`, styles: { halign: 'right', fillColor: '#f7f9fb' } }
      ];
      tableRows.push(rowData);
    });
  });
  const header = (doc: any, auctionId: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold", });
    doc.text(`${getGlobalAuction?.label}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Tax Summary By Jurisdiction Report", rightAlignX, 12, { align: "right", fontStyle: "bold", });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionCode}`, rightAlignX, 17, { align: "right" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setFontSize(11).text(`${date}`, rightAlignX, 22, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.setFontSize(9).text(`Total Lot Price: $${formatAmount(totalLotPrice)}`, rightAlignX, 27, {
      align: "right",
    });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.setFontSize(9).text(`Total Tax Charged: $${formatAmount(totalTaxCharged)}`, rightAlignX, 32, {
      align: "right",
    });
  };

  const footer = (doc: any,auctionId:any) => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Tax Summary by Jurisdiction`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  tableRows.push([
    { content: 'Totals', colSpan: 9, styles: { halign: 'right', fontStyle: 'bold', fillColor: '#f1f1f1', textColor: '#000000' } },
    { content: `$${formatAmount(totalLotPrice)}`, styles: { halign: 'right', fillColor: '#f1f1f1', textColor: '#666666' ,fontStyle: 'bold'} },
    { content: `$${formatAmount(totalTaxCharged)}`, styles: { halign: 'right', fillColor: '#f1f1f1', textColor: '#666666',fontStyle: 'bold' } }
  ]);

  doc.autoTable({
    head: [
      [
        { content: "Seller Id", styles: { halign: 'left', width: "15%" } },
        { content: "Seller Name", styles: { halign: 'left', width: "15%" } },
        { content: "Tax Code", styles: { halign: 'left', width: "15%" } },
        { content: "Tax Region", styles: { halign: 'left', width: "15%" } },
        { content: "Tax Type", styles: { halign: 'left', width: "15%" } },
        { content: "Tax Name", styles: { halign: 'left', width: "15%" } },
        { content: "Lot #", styles: { halign: 'left', width: "15%" } },
        { content: "Address", styles: { halign: 'left', width: "18%" } },
        { content: "Type", styles: { halign: 'left', width: "15%" } },
        { content: "Lot Price", styles: { halign: 'right', width: "15%" } },
        { content: "Tax Charged", styles: { halign: 'right', width: "15%" } },
      ]
    ],
    body: tableRows,
    startY: 40,
    theme: "plain",
    margin: { top: 35, left: 6, right: 6 },
    didDrawPage: () => {
      header(doc, auctionId);
      footer(doc,auctionId);
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 1, left: 2 },
    },
    headStyles: {
      halign: "center",
      fontSize: 9,
      fillColor: null,
      textColor: '#666666',
    }
  });

  doc.save('Tax_Summary_By_Jurisdiction_Report.pdf');
};

export const generateTaxSummaryByJurisdictionCsv = (filterTaxData: any, getGlobalAuction: any) => {
  const wb = XLSXJS.utils.book_new();
  let auctionCode = getGlobalAuction?.label.split('-')[0]

  const headers = [
    'Seller Id', 'Seller Name', 'Tax Code', 'Tax Region', 'Tax Type',
    'Tax Name', 'Lot #', 'Address', 'Type', 'Lot Price', 'Tax Charged'
  ];

  const wsData = [
    ['Tax Summary By Jurisdiction Report'], 
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [`Auction #${getGlobalAuction?.label}`],
    [], 
    headers, 
  ];

  const calculateTotals = (filterTaxData: any) => {
    const totals: any = {};
    filterTaxData?.forEach((regionData: any) => {
      const { taxRegion, subResp } = regionData;

      if (!totals[taxRegion]) {
        totals[taxRegion] = {
          totalLotPrice: 0.00,
          totalTaxCharged: 0.00,
        };
      }

      subResp?.forEach((sub: any) => {
        totals[taxRegion].totalLotPrice += sub.lotPrice;
        totals[taxRegion].totalTaxCharged += sub.taxCharged;
      });
    });
    return totals;
  };

  const totalsByTaxRegion = calculateTotals(filterTaxData);

  filterTaxData?.forEach((data: any) => {
    const { taxRegion, taxType, subResp } = data;
    
    
    wsData.push([
      `${taxRegion}`,'', '', '', '', '', '', '', '',
      '', `$${formatAmount(totalsByTaxRegion[taxRegion]?.totalTaxCharged)}`
    ]);

    wsData.push([
      '', '', '', 
      `${data.taxRegion}`, 
      `${data.taxType}`, 
      '', '', '','', 
      `$${formatAmount(totalsByTaxRegion[data.taxRegion]?.totalLotPrice)}`, 
      `$${formatAmount(totalsByTaxRegion[data.taxRegion]?.totalTaxCharged)}`, 
    ]);

    subResp?.forEach((subItem: any) => {
      wsData.push([
        data?.sellerId || '-', 
        data?.sellerName || '-', 
        data?.taxCode || '-', 
        data?.taxRegion || '-', 
        data?.taxType || '-', 
        subItem?.taxName || '-', 
        subItem?.lotNo || '-', 
        subItem?.address || '-', 
        subItem?.type || '-', 
        `$${formatAmount(subItem?.lotPrice)}`, 
        `$${formatAmount(subItem?.taxCharged)}`, 

      ]);
    });
  });

  const totalLotPrice = filterTaxData.reduce((acc: any, item: any) => {
    return acc + item.subResp?.reduce((subAcc: any, subItem: any) => subAcc + subItem.lotPrice, 0);
  }, 0);
  
  const totalTaxCharged = filterTaxData.reduce((acc: any, item: any) => {
    return acc + item.subResp?.reduce((subAcc: any, subItem: any) => subAcc + subItem.taxCharged, 0);
  }, 0);
  
  wsData.push([
    '', '', '', '', '', '', '', '', 'Totals',
    `$${formatAmount(totalLotPrice)}`, `$${formatAmount(totalTaxCharged)}`
  ]);

  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 100 }, // Seller Id
    { wpx: 150 }, // Seller Name
    { wpx: 80 }, // Tax Code
    { wpx: 100 }, // Tax Region
    { wpx: 80 }, // Tax Type
    { wpx: 100 }, // Tax Name
    { wpx: 80 },  // Lot #
    { wpx: 250 }, // Address
    { wpx: 80 }, // Type
    { wpx: 100 }, // Lot Price
    { wpx: 100 }, // Tax Charged
  ];

  const headerStyle = {
    font: { bold: true }, // Bold header
    alignment: { horizontal: 'center' }, // Center align headers
    fill: { fgColor: { rgb: 'c0c0c0' } },
  };

  headers.forEach((_, colIdx) => {
    const cellRef = XLSXJS.utils.encode_cell({ r: 4, c: colIdx });
    if (!ws[cellRef]) ws[cellRef] = {}; // Ensure the cell exists
    ws[cellRef].s = headerStyle; // Assign the style to the header cells
  });

  // Style cells in the "Lot Price" and "Tax Charged" columns (columns 9 and 10)
  const rightAlignStyle = {
    alignment: { horizontal: 'right' }, // Right align
  };

  // Apply right alignment to the "Lot Price" and "Tax Charged" columns for all rows
  wsData.forEach((_, rowIdx) => {
    const lotPriceCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 9 }); // Lot Price (10th column)
    const taxChargedCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 10 }); // Tax Charged (11th column)

    if (ws[lotPriceCell]) ws[lotPriceCell].s = rightAlignStyle;
    if (ws[taxChargedCell]) ws[taxChargedCell].s = rightAlignStyle;
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Tax_Summary_By_Jurisdiction_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);

};

{/** ********************* Inventory Report ends ***********************/ }

{/** ********************* Customer Report Starts ***********************/ }

export const generateSuspendCustomerHistoryPdf = (suspendData: any) => {
  const doc: any = new jsPDF();
  const head = [['Suspend Id', 'Buyer Id', 'Buyer Name', 'Suspended Date', 'Suspended Reason', 'Suspend Status']]
  const data = suspendData.map((data: any) => {
    return {
      customersuspendId: data.customersuspendId,
      customerId: data.customerId,
      customerName: data.customerName || "-",
      datetime: data.datetime ? momentUtil.parseTimeInUtc({ dateTimeString: data?.datetime }).format('MM/DD/YYYY hh:mm A') : "-",
      suspendedReason: data.suspendedReason || "-",
      isSuspend: data.isSuspend || "-"
    }
  })
  const newr: any = data.map((i: any) => {
    return Object.values(i)
  })

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Suspend Buyer Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const todayTime = new Date().toLocaleString();
    doc.text(`Printed: ${moment(todayTime).format('MM/DD/YYYY')}`, rightAlignX, 17, { align: 'right' });

  };

  const footer = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Suspend Buyer Report`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    startY: 35,
    head: head,
    body: newr,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 9,
      fillColor: null,
      textColor: '#666666',
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 40, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc);
      footer(doc);
    },
  });

  doc.save(`Suspend_Customer_Report.pdf`);
}

export const generateSuspendCustomerHistoryCsv = (suspendData: any) => {
  let data = suspendData?.map((data: any) => {
    return {
      customersuspendId: data.customersuspendId,
      customerId: data.customerId,
      customerName: data.customerName || "-",
      datetime: data.datetime ? momentUtil.parseTimeInUtc({ dateTimeString: data?.datetime }).format('MM/DD/YYYY hh:mm A') : "-",
      suspendedReason: data.suspendedReason || "-",
      isSuspend: data.isSuspend || "-"
    };
  });

  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Suspend-Buyer-Report'],
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['Suspend Id', 'Buyer Id', 'Buyer Name', 'Date Time', 'Suspended Reason', 'Suspend Status'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 120 },
    { wpx: 150 },
    { wpx: 200 },
    { wpx: 100 },
  ];

  const headerStyle = {
    font: { bold: true, sz: 12 },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const headerRowIndex = 3;
  const headerCells = ['A', 'B', 'C', 'D', 'E', 'F'];

  headerCells.forEach((cell, index) => {
    ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Suspend_Buyer_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

export const generateInvoiceSummaryCsv = (invoiceData: any, getGlobalAuction: any) => {
  if (!invoiceData || invoiceData?.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Excel.',
      confirmButtonColor: '#006800',
    });
    return;
  }

  // Format the data
  const data = invoiceData?.lstInvoiceMasterDetial?.map((data: any) => ({
    invoiceNumber: data?.invoiceNumber || "-",
    bidder: data?.bidder || "-", // Ensure Bidder data is mapped
    items: `$${formatAmount(data?.items)}` || "-",
    premium: `$${formatAmount(data?.premium)}` || "-",
    taxable: `$${formatAmount(data?.taxable)}` || "-",
    salesTax: `$${formatAmount(data?.salesTax)}` || "-",
    invoiceAdjust: `$${formatAmount(data?.adjust)}` || "-",
    total: `$${formatAmount(data?.total)}` || "-",
    totalPayment: `$${formatAmount(data?.totalPayment)}` || "-",
    balanceDue: `$${formatAmount(data?.balanceDue)}` || "-"
  }));

  const wb: any = XLSXJS?.utils?.book_new();

  // Invoice Summary Report Data
  const wsData = [
    ['Invoice Summary Report'],
    ['Del Peterson & Associates, Inc.'],
    [`Auction # ${invoiceData?.auctionDisplayName}`],
    [`${invoiceData?.auctionAddressLine1}`],
    [],
    [],
    ['Inv #', 'Bidder #', 'Items', 'Premium', 'Total Taxable', 'Sales Tax', 'Adjust', 'Total', 'Total Payments', 'Balance Due'], // Ensure Bidder is present in the header

    // The first "Auction Totals" row, appearing before the data rows
    ['Auction Totals', '', `$${formatAmount(invoiceData?.sumItems)}`, `$${formatAmount(invoiceData?.sumPremium)}`, `$${formatAmount(invoiceData?.sumTaxable)}`, `$${formatAmount(invoiceData?.sumSalesTax)}`, `$${formatAmount(invoiceData?.sumAdjust)}`, `$${formatAmount(invoiceData?.sumTotal)}`, `$${formatAmount(invoiceData?.sumTotalPayment)}`, `$${formatAmount(invoiceData?.sumBalanceDue)}`],

    // Add the mapped data rows
    ...data?.map((item: any) => Object?.values(item)),

    // The second "Auction Totals" row, appearing after the data rows
    ['Auction Totals', '', `$${formatAmount(invoiceData?.sumItems)}`, `$${formatAmount(invoiceData?.sumPremium)}`, `$${formatAmount(invoiceData?.sumTaxable)}`, `$${formatAmount(invoiceData?.sumSalesTax)}`, `$${formatAmount(invoiceData?.sumAdjust)}`, `$${formatAmount(invoiceData?.sumTotal)}`, `$${formatAmount(invoiceData?.sumTotalPayment)}`, `$${formatAmount(invoiceData?.sumBalanceDue)}`],
  ];

  const ws: any = XLSXJS.utils.aoa_to_sheet(wsData);

  // Set column widths to make sure Bidder column is visible
  ws['!cols'] = [
    { wpx: 120 }, // Invoice column
    { wpx: 120 }, // Bidder column (ensure visibility)
    { wpx: 120 }, // Item total column
    { wpx: 120 }, // Buyer premium column
    { wpx: 120 }, // Total taxable column
    { wpx: 120 }, // Sales tax column
    { wpx: 120 }, // Invoice adjustment column
    { wpx: 120 }, // Total column
    { wpx: 120 }, // Total payment column
    { wpx: 120 }  // Balance due column
  ];

  // Apply header style
  const headerStyle = {
    font: { bold: true, sz: 12 },
    alignment: { horizontal: 'center', vertical: 'center' },
    fill: { fgColor: { rgb: "DCE6F1" } },
    border: {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    },
  };

  // Apply bold for auction totals row
  const boldStyle = {
    font: { bold: true },
    alignment: { horizontal: 'right', vertical: 'center' },
    border: {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    },
  };

  // Data style for rows
  const dataStyle = {
    alignment: { horizontal: 'right', vertical: 'center' },
    border: {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    },
  };

  // Title and header rows
  const headerCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  const headerRowIndex = 6; // Header row starts at index 6

  // Style headers
  headerCells.forEach((cell) => {
    const cellAddress = `${cell}${headerRowIndex + 1}`;
    if (!ws[cellAddress]) {
      ws[cellAddress] = { v: '' }; // Initialize if missing
    }
    ws[cellAddress].s = headerStyle; // Apply header style
  });

  // Apply data style to all data rows
  const dataStartRow = headerRowIndex + 3;
  data.forEach((_: any, rowIndex: number) => {
    headerCells.forEach((cell) => {
      const cellAddress = `${cell}${dataStartRow + rowIndex}`;
      if (ws[cellAddress]) {
        ws[cellAddress].s = dataStyle; // Apply data style
      }
    });
  });

  // Apply bold style to the first auction totals row (before the data rows)
  const firstAuctionTotalRowIndex = headerRowIndex + 2;
  headerCells.forEach((cell) => {
    const auctionTotalCellAddress = `${cell}${firstAuctionTotalRowIndex}`;
    if (!ws[auctionTotalCellAddress]) {
      ws[auctionTotalCellAddress] = { v: '' }; // Initialize if missing
    }
    ws[auctionTotalCellAddress].s = boldStyle; // Apply bold for first auction totals row
  });

  // Apply bold style to the second auction totals row (after the data rows)
  const secondAuctionTotalRowIndex = dataStartRow + data.length;
  headerCells.forEach((cell) => {
    const auctionTotalCellAddress = `${cell}${secondAuctionTotalRowIndex}`;
    if (!ws[auctionTotalCellAddress]) {
      ws[auctionTotalCellAddress] = { v: '' }; // Initialize if missing
    }
    ws[auctionTotalCellAddress].s = boldStyle; // Apply bold for second auction totals row
  });

  // Create and download the Excel file
  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Invoice_Summary_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateInvoiceSummaryPdf = (invoiceSummaryData: any, getGlobalAuction: any) => {

  const doc: any = new jsPDF('l', 'mm', 'a4');

  // Header

  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: 'bold' });
    doc.text(`${invoiceSummaryData?.auctionDisplayName}`, 40, 17, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${invoiceSummaryData?.auctionAddressLine1}`, 40, 22);
    doc.text(`${invoiceSummaryData?.auctionCityName}, ${invoiceSummaryData?.auctionStateName}, ${invoiceSummaryData?.auctionCountryName}, ${invoiceSummaryData?.auctionZipCode}`, 40, 27);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor('#551e1c');
    doc.text("Invoice Summary Report", rightAlignX, 12, { align: 'right', fontStyle: 'bold' });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${invoiceSummaryData?.auctionCode}`, rightAlignX, 17, { align: 'right' });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${moment(invoiceSummaryData?.auctionStartDate).format('MM/DD/YYYY')}`, rightAlignX, 22, { align: 'right' });
  };

  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);

    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.setFont("helvetica", "normal");
    doc.setTextColor('#000000');
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Invoice Summary ${invoiceSummaryData?.auctionCode}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  // Add content

  header();
  const rows = invoiceSummaryData?.lstInvoiceMasterDetial?.map((data: any) => [
    { content: data?.invoiceNumber, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'center' } },
    { content: data?.bidder, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'center' } },
    { content: `$${formatAmount(data.items)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.premium)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.taxable)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.salesTax)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.adjust)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.total)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.totalPayment)}`, styles: { lineWidth: { bottom: 0.1, left: 0.8, right: 0.1, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
    { content: `$${formatAmount(data.balanceDue)}`, styles: { lineWidth: { bottom: 0.1, left: 0, right: 0, top: 0 }, cellspacing: 0, lineColor: [172, 172, 172], halign: 'right' } },
  ]);

  doc.autoTable({
    startY: 35,
    // head: head,
    head: [
      [
        { content: "Inv #", styles: { halign: 'center' } },
        { content: "Bidder #", styles: { halign: 'center' } },
        { content: "Items", styles: { halign: 'right' } },
        { content: "Premium", styles: { halign: 'right' } },
        { content: "Taxable", styles: { halign: 'right' } },
        { content: "Sales Tax", styles: { halign: 'right' } },
        { content: "Adjust", styles: { halign: 'right' } },
        { content: "Total", styles: { halign: 'right' } },
        { content: "Total Payments", styles: { halign: 'right' } },
        { content: "Balance Due", styles: { halign: 'right' } },
      ]
    ],
    body: [
      [
        { content: `${"Auction Totals"}`, colSpan: 2, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left', fontStyle: "bold", fontSize: 9 } },

        { content: `$${formatAmount(invoiceSummaryData?.sumItems)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumPremium)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumTaxable)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumSalesTax)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumAdjust)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumTotal)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumTotalPayment)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumBalanceDue)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } }
      ],
      ...rows,
      [
        { content: `${"Auction Totals"}`, colSpan: 2, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'left', fontStyle: "bold", fontSize: 9 } },

        { content: `$${formatAmount(invoiceSummaryData?.sumItems)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumPremium)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumTaxable)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumSalesTax)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumAdjust)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumTotal)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },

        { content: `$${formatAmount(invoiceSummaryData?.sumTotalPayment)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0.1, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } },
        { content: `$${formatAmount(invoiceSummaryData?.sumBalanceDue)}`, styles: { lineWidth: { bottom: 0.5, left: 0, right: 0, top: 0.5 }, cellspacing: 0, lineColor: [0, 0, 0], halign: 'right' } }
      ],
    ],
    theme: 'plain',
    headStyles: {
      fontSize: 8,
      textColor: "#000000",
      fontStyle: 'bold',
    },
    bodyStyles: {
      fontSize: 8,
      valign: 'middle',
      textColor: "#000000",
    },
    columnStyles: {
      7: {
        lineWidth: { right: 1.6 },
      },
    },
    didDrawCell: function (data: any) {
      if (data.column.index === 7 && data.row.section === 'body') {
        var { cell } = data;
        doc.setDrawColor(172, 172, 172);
        doc.setLineWidth(0.9);
        doc.line(
          cell.x + cell.width,
          cell.y,
          cell.x + cell.width,
          cell.y + cell.height
        );
      }
    },
    margin: { top: 40, left: 7, right: 7 },
    pageBreak: 'auto',
    didDrawPage: () => {
      footer();
    }
  });
  doc.save("Invoice_Summary_Report.pdf");
}

{/** ********************* Customer Report Ends ***********************/ }

{/** ********************* Other Reports Start ************************ */ }

export const generateAutoTitlePdf = (autoTitleReportData: any) => {
  const doc: any = new jsPDF();

  const tableColumn = [
    'Buyer',
    'Seller',
    'Title Assignment',
    'Lot/Ctrl #',
    'Status',
    'Lot Title',
    ''
  ];
  const tableRows: any = [];
  let auctionId = '';
  let auctionTitle = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let address = '';
  let auctionDate = ''
  let lotcount = ''

  autoTitleReportData.forEach((item: any) => {
    auctionId = item.auctionCode;
    auctionTitle = item.auctionTitle;
    city = item.auctionCityName;
    state = item.auctionStateName;
    zipCode = item.auctionzipCode;
    address = item.auctionAddressLine1;
    auctionDate = item.auctionDate;
    auctionDate = auctionDate?.replace(/-/g, '/');
    lotcount = item.lotcount;

    const rowData1 = [
      {
        content: [
          `Bidder: ${item.bidderNumber ? item.bidderNumber : 'N/A'}`,
          item.bidderCompany ?? '',
          item.bidderName ?? '',
          item.bidderAddress ?? '',
          item.bidderPhone ? `W: ${formatPhoneNumber(item.bidderPhone)}` : 'W: N/A',
          item.bidderPhone2 ? `C: ${formatPhoneNumber(item.bidderPhone2)}` : 'C: N/A'
        ].filter(line => line).join('\n'),
        styles: { cellWidth: 35, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] }
      },
      {
        content: [
          `Seller: ${item.sellerId ? item.sellerId : 'N/A'}`,
          item.sellerCompany ?? '',
          item.sellerName ?? '',
          item.sellerAddress ?? '',
          item.sellerPhone ? `W: ${formatPhoneNumber(item.sellerPhone)}` : 'W: N/A',
          item.sellerPhone2 ? `C: ${formatPhoneNumber(item.sellerPhone2)}` : 'C: N/A'
        ].filter(line => line).join('\n'),
        styles: { cellWidth: 35, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] }
      },
      {
        content: [
          item.lotTitleAssignmentName ?? 'N/A',
          item.lotTitleAssignmentCompany ?? '',
          item.lotTitleAssignmentAddress ?? '',
          item.lotTitleAssignmentCityName
            ? `${item.lotTitleAssignmentCityName} ${item.lotTitleAssignmentStateName ?? ''} ${item.lotTitleAssignmentCountryName ?? ''} ${item.lotTitleAssignmentZipCode ?? ''}`
            : ''
        ].filter(line => line).join('\n'),
        styles: { cellWidth: 30, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] }
      },
      {
        content: [item.lotNo ?? '', item.ctrlNo ?? ''].filter(line => line).join('\n'),
        styles: { cellWidth: 15, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], fontStyle: 'bold' }
      },
      {
        content: item.inventoryStatus || '',
        styles: { cellWidth: 15, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0], fontStyle: 'bold' }
      },
      {
        content: [
          item.lotTitle.replace(/<[^>]*>?/gm, "-"),
          [
            { content: `VIN`, styles: { fontStyle: 'bold' } },
            ` ${item.vin1 ? item.vin1 : '-'}` // 
          ].map(line => (typeof line === 'string' ? line : line.content)).join(''),
        ].filter(line => line).join('\n\n'), 
        styles: { cellWidth: 38, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] }
      },
      {
        content: [
          'Received',
          item.titleRecived ? `${moment(item.titleRecived).format("MM/DD/YYYY hh:mm A")}\n` : '',
          'Sent',
          item.titleSent ? moment(item.titleSent).format("MM/DD/YYYY hh:mm A") : ''
        ].filter(line => line).join('\n'),
        styles: { cellWidth: 30, lineWidth: { bottom: 0, left: 0, right: 0, top: 0.1 }, lineColor: [0, 0, 0] }
      },
    ];

    tableRows.push(rowData1);
  });

  const header = (doc: any, auctionId: any, auctionDate: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold", });
    doc.text(`${auctionTitle}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${address}`, 40, 22);
    doc.text(`${city}, ${state} ${zipCode}`, 40, 27);

    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Auto Title Summary", rightAlignX, 12, { align: "right", fontStyle: "bold", });
    doc.setFont("helvetica", "bold");
    doc.text(`Auction #${auctionId}`, rightAlignX, 17, { align: "right" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${moment(auctionDate, 'YYYY/MM/DD').format("MMMM D, YYYY")}`, rightAlignX, 22, {
      align: "right",
    });
    doc.text(`Total: ${lotcount} Lots`, rightAlignX, 27, {
      align: "right",
    });
  };

  const footer = (doc: any, auctionId: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Auto Title Summary (from Sales) - Auction ${auctionId}`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 40,
    theme: "plain",
    margin: { top: 50, left: 8, right: 8 },
    showHead: "everyPage",
    didDrawPage: () => {
      header(doc, auctionId, auctionDate);
      footer(doc, auctionId);
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
    },
    columnStyles: {
      0: { cellWidth: 35 },
      1: { cellWidth: 35 },
      2: { cellWidth: 30 },
      3: { cellWidth: 18 },
      4: { cellWidth: 15 },
      5: { cellWidth: 35 },
      6: { cellWidth: 30 }
    },
    headStyles: {
      halign: "left",
      cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
      fillColor: null,
      textColor: 'black',
    },
  });
  doc.save('Auto_Title_Summary_Report.pdf');
};

export const generateAutoTitleCsv = (autoTitleReportData: any) => {
  const sellerName = autoTitleReportData.length > 0 ? autoTitleReportData[0].sellerName : '';
  const auctionId = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionCode : '';
  const auctionTitle = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionTitle : '';
  const city = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionCityName : '';
  const state = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionStateName : '';
  const zipCode = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionzipCode : '';
  const address = autoTitleReportData.length > 0 ? autoTitleReportData[0].auctionAddressLine1 : '';
  const auctionDate = autoTitleReportData.length > 0 ? moment(autoTitleReportData[0].auctionDate).format("MMMM D, YYYY") : '';
  const lotcount = autoTitleReportData.length > 0 ? autoTitleReportData[0].lotcount : '';

  const wsData = [
    ["Del Peterson & Associates, Inc."],
    [`Auction: ${auctionTitle}`],
    [`Address: ${address}`],
    [`Location: ${city}, ${state} ${zipCode}`],
    [`Auction Date: ${auctionDate}`],
    [`Total Lots: ${lotcount}`],
    [],
    ['Bidder', 'Seller', 'Title Assignment', 'Lot/Ctrl #', 'Status', 'Lot Title'],
    ...autoTitleReportData.map((item: any) => [
      [
        `Bidder: ${item.bidderNumber ?? 'N/A'}`,
        item.bidderCompany,
        item.bidderName,
        item.bidderAddress,
        item.bidderPhone ? `W: ${formatPhoneNumber(item.bidderPhone)}` : null,
        item.bidderPhone2 ? `C: ${formatPhoneNumber(item.bidderPhone2)}` : null
      ].filter(Boolean).join('\n'), 

      [
        `Seller: ${item.sellerId ?? 'N/A'}`,
        item.sellerCompany,
        item.sellerName,
        item.sellerAddress,
        item.sellerPhone ? `W: ${formatPhoneNumber(item.sellerPhone)}` : null,
        item.sellerPhone2 ? `C: ${formatPhoneNumber(item.sellerPhone2)}` : null
      ].filter(Boolean).join('\n'), 

      [
        item.lotTitleAssignmentName ?? 'N/A',
        item.lotTitleAssignmentCompany,
        item.lotTitleAssignmentAddress,
        item.lotTitleAssignmentCityName ? `${item.lotTitleAssignmentCityName}, ${item.lotTitleAssignmentStateName ?? ''} ${item.lotTitleAssignmentZipCode ?? ''}` : null
      ].filter(Boolean).join('\n'), 

      [
        `${item.lotNo ?? ''}\n/${item.ctrlNo ?? ''}`.trim() 
      ].filter(Boolean).join('\n'), 

      item.inventoryStatus || '', 

      [
        item.lotTitle.replace(/<[^>]*>?/gm, ""),
        `VIN ${item.vin1 ? item.vin1 : '-'}`
      ].filter(Boolean).join('\n'),

      [
        'Received',
        item.titleRecived ? `${moment(item.titleRecived).format("MM/DD/YYYY hh:mm A")}\n` : null,
        'Sent',
        item.titleSent ? moment(item.titleSent).format("MM/DD/YYYY hh:mm A") : null
      ].filter(Boolean).join('\n')
    ]),
  ];

  const ws: any = XLSXJS.utils.aoa_to_sheet(wsData);
  const wb = XLSXJS.utils.book_new();

  ws['!cols'] = [
    { wpx: 250 },
    { wpx: 250 },
    { wpx: 250 },
    { wpx: 100 },
    { wpx: 50 },
    { wpx: 200 },
    { wpx: 200 },
  ];

  const headerRowIndex = 7;
  wsData[headerRowIndex].forEach((_: any, colIndex: any) => {
    const cell_ref = XLSXJS.utils.encode_cell({ c: colIndex, r: headerRowIndex });
    if (ws[cell_ref]) ws[cell_ref].s = { font: { bold: true } };
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Auto Title Summary Report');
  XLSXJS.writeFile(wb, 'Auto_Title_Summary_Report.xlsx');
};

export const generateTitledReportPdf = (titleData: any, getGlobalAuction: any) => {
  const doc: any = new jsPDF();

  const head = [['Lot #', 'Ctrl', 'Lot Title', 'Vin', 'Status', 'Buyer', 'Seller', 'Bidder #', 'Sent', 'Received']]
  const data = titleData.map((data: any) => {
    return {
      lotNo: data.lotNo || "-",
      ctrl: data.ctrlNo || "-",
      LotTitle: data.lotTitle || "-",
      vin: data.vin || "-",
      status: data.stringInventoryStatus || "-",
      buyer: data.customerName  || "-",
      seller: data.sellerName || "-",
      bidderNumber: data.bidderNumber || "-",
      sent: data.titleSent ? moment(data.titleSent).format('MM/DD/YYYY hh:mm A').toString() : "-",
      received: data.titleRecord ? moment(data.titleRecord).format('MM/DD/YYYY hh:mm A').toString() : "-",
    }
  })
  const newr: any = data.map((i: any) => {
    return Object.values(i)
  })

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });
    doc.text(`${getGlobalAuction?.label}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Titled Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const todayTime = new Date().toLocaleString();
    doc.text(`Printed: ${moment(todayTime).format('MM/DD/YYYY')}`, rightAlignX, 17, { align: 'right' });

  };

  const footer = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Titled Report`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    startY: 35,
    head: head,
    body: newr,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 9,
      fillColor: null,
      textColor: '#666666',
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 40, left: 6, right: 6 },
    didDrawPage: () => {
      header(doc);
      footer(doc);
    },
  });
  doc.save(`Titled_Report.pdf`);
}

export const generateTitledReportCsv = (titleData: any, getGlobalAuction: any) => {
  let data = titleData?.map((data: any) => {
    return {
      lotNo: data.lotNo || "-",
      ctrl: data.ctrlNo || "-",
      LotTitle: data.lotTitle || "-",
      vin: data.vin || "-",
      status: data.stringInventoryStatus || "-",
      buyer: data.customerName  || "-",
      seller: data.sellerName || "-",
      bidderNumber: data.bidderNumber || "-",
      sent: data.titleSent ? moment(data.titleSent ).format('MM/DD/YYYY hh:mm A').toString() : "-",
      received: data.titleRecord ? moment(data.titleRecord).format('MM/DD/YYYY hh:mm A').toString() : "-",
    };
  });
  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Titled-Report'],
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['Lot #', 'Ctrl', 'Description', 'Vin', 'Status', 'Buyer', 'Seller', 'Bidder #', 'Sent', 'Received'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 50 },
    { wpx: 50 },
    { wpx: 200 },
    { wpx: 80 },
    { wpx: 80 },
    { wpx: 100 },
    { wpx: 120 },
    { wpx: 80 },
    { wpx: 100 },
    { wpx: 100 },
  ];

  const headerStyle = {
    font: { bold: true, sz: 12 },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const headerRowIndex = 3;
  const headerCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

  headerCells.forEach((cell, index) => {
    ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Titled_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

export const generateTransportationMessagePdf = (transporter: any) => {
  const doc: any = new jsPDF();
  const head = [['Sr. No.', 'Name', 'Email', 'Phone No.', 'Message', 'Date']]
  const data = transporter.map((data: any) => {
    return {
      transporterId: data.transporterId || 0,
      name: data.name || '-',
      email: data.email || '-',
      phone: data?.phone ? formatPhoneNumber(data?.phone) : '',
      message: data.message || "-",
      date: data.date ? moment(data?.date).format('MM/DD/YYYY hh:mm A') : "-"
    }
  })
  const newr: any = data.map((i: any) => {
    return Object.values(i)
  })

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Transportation Message Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const todayTime = new Date().toLocaleString();
    doc.text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, rightAlignX, 17, { align: 'right' });

  };

  const footer = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Transportation Message Report`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    startY: 35,
    head: head,
    body: newr,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 9,
      fillColor: null,
      textColor: '#666666',
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 40, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc);
      footer(doc);
    },
  });

  doc.save(`Transportation_Report.pdf`);

}

export const generateTransportationMessageCsv = (transporter: any) => {
  let data = transporter?.map((data: any) => {
    return {
      transporterId: data?.transporterId,
      name: data?.name || '-',
      email: data?.email || '-',
      phone: data?.phone ? formatPhoneNumber(data?.phone) : '',
      message: data?.message || '-',
      date: data.date ? moment(data?.date).format('MM/DD/YYYY hh:mm A') : '',
    };
  });

  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Transportation-Report'],
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['Transporter Id', 'Name', 'Email', 'Phone', 'Message', 'Date'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws = XLSXJS.utils.aoa_to_sheet(wsData);
  ws['!cols'] = [
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 200 },
    { wpx: 100 },
    { wpx: 250 },
    { wpx: 100 },
  ];

  const headerStyle = {
    font: { bold: true, sz: 12 },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const headerRowIndex = 3;
  const headerCells = ['A', 'B', 'C', 'D', 'E', 'F'];

  headerCells.forEach((cell, index) => {
    ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Transportation_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}

export const generateFictitiousBidderPdf = (bidderData: any) => {
  const doc: any = new jsPDF('l', 'mm', 'a4');
  if (!bidderData || bidderData.length == 0) {
    Swal.fire({
      icon: 'warning',
      title: 'No Records',
      text: 'No record found to generate Pdf.',
      confirmButtonColor: '#006800',
    });
    return;
  }

  const head = [['Auction Code - Title', 'Bidder Name', 'Email', 'Address', 'City', 'State', 'Country', 'Zip Code', 'Phone', 'Notes']]
  const data = bidderData.map((data: any) => {
    return {
      auctionCode: `${data?.auctionCode}-${data?.auctionTitle}`,
      bidderName: `${data?.firstName} ${data?.lastName}`,
      email: data.email || "-",
      address: data.addressLine1 || "-",
      city: data.cityName || "-",
      state: data.stateName || "-",
      country: data.countryName || "-",
      zipCode: data.zipCode ? data.zipCode : '-',
      phone: data.mobile ? formatPhoneNumber(data.mobile) : '-',
      notes: data.notes ? data.notes : '-'
    };
  })
  const newr: any = data.map((i: any) => {
    return Object.values(i)
  })

  const header = (doc: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Fictitious Bidder Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.setFont("helvetica", "bold");
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const todayTime = new Date().toLocaleString();
    doc.text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, rightAlignX, 17, { align: 'right' });

  };

  const footer = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    const pageSize = doc.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Fictitious Bidder Report`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  doc.autoTable({
    startY: 35,
    head: head,
    body: newr,
    headStyles: {
      cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
      fontSize: 9,
      fillColor: null,
      textColor: '#666666',
    },
    styles: {
      fontSize: 8,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
    },
    margin: { top: 40, left: 8, right: 8 },
    didDrawPage: () => {
      header(doc);
      footer(doc);
    },
  });

  doc.save(`Fictitious_Bidder_Report.pdf`);
}

export const generateFictitiousBidderCsv = (bidderData: any) => {
  let data = bidderData?.map((data: any) => {
    return {
      auctionCode: `${data?.auctionCode}-${data?.auctionTitle}`,
      bidderName: `${data?.firstName} ${data?.lastName}`,
      email: data.email || "-",
      address: data.addressLine1 || "-",
      city: data.cityName || "-",
      state: data.stateName || "-",
      country: data.countryName || "-",
      zipCode: data.zipCode ? data.zipCode : '-',
      phone: data.mobile ? formatPhoneNumber(data.mobile) : '-',
      notes: data.notes ? data.notes : '-'
    };
  });

  const wb = XLSXJS.utils.book_new();
  const wsData = [
    ['Fictitious-Bidder-Report'],
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [],
    ['Auction Code - Title', 'Bidder Name', 'Email', 'Address', 'City', 'State', 'Country', 'Zip Code', 'Phone', 'Notes'],
    ...data.map((item: any) => Object.values(item)),
  ];
  const ws:any = XLSXJS.utils.aoa_to_sheet(wsData);

  ws['!cols'] = [
    { wpx: 120 },
    { wpx: 200 },
    { wpx: 270 },
    { wpx: 120 },
    { wpx: 100 },
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 150 },
  ];

  const headerStyle = {
    font: { bold: true, sz: 12 },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const headerRowIndex = 3;
  const headerCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  headerCells.forEach((cell) => {
    ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
  });

  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Fictitious_Bidder_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
}
export const generateTaxExemptSummaryCsv = (filterTaxData: any, getGlobalAuction: any) => {
  const wb = XLSXJS.utils.book_new();
  let auctionCode = getGlobalAuction?.label.split('-')[0];

  const calculateTotalLotPrice = () => {
    let total = 0;
    filterTaxData?.forEach((item: any) => {
      item?.subRes?.forEach((subItem: any) => {
        total += subItem.lotPrice;
      });
    });
    return total;
  };
  const totalLotPrice = calculateTotalLotPrice();

  const headers = ['Auc', 'Date', 'Name', 'Lot #', 'Address', 'Lot Price'];

  const wsData = [
    ['Tax Exempt Summary Report'],
    [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
    [`Auction #${getGlobalAuction?.label}`],
    [],
    headers,
  ];

  filterTaxData?.forEach((data: any) => {
    const { subRes } = data;

    wsData.push([`${data?.state}`, '', '', '', '', '', '', '']);
    wsData.push(['', '', `${data?.lotCity}`, '', '', `$${formatAmount(totalLotPrice)}`]);

    subRes?.forEach((subItem: any) => {
      wsData.push([
        data?.auctionCode || "-",
        moment(data?.auctionDate).format('MM/DD/YY'),
        data?.lotCity || "-",
        subItem?.lotDisplayNo || "-",
        subItem?.lotAddress || "-",
        `$${formatAmount(subItem?.lotPrice || 0)}`
      ]);
    });
  });

  // Add the 'Totals' row to the data
  wsData.push(['', '', '', '', 'Totals', `$${formatAmount(totalLotPrice)}`]);

  const ws = XLSXJS.utils.aoa_to_sheet(wsData);

  // Apply style to the 'Totals' row like the header row
  const totalsRowIndex = wsData.length - 1; // The last row that was just added
  const totalsStyle = {
    font: { bold: true }, // Bold text
    alignment: { horizontal: 'center' }, // Center align text
    fill: { fgColor: { rgb: 'c0c0c0' } }, // Background color like header
  };

  // Apply the style to each cell in the 'Totals' row (columns E and F)
  for (let colIdx = 4; colIdx <= 5; colIdx++) {
    const cellRef = XLSXJS.utils.encode_cell({ r: totalsRowIndex, c: colIdx });
    if (!ws[cellRef]) ws[cellRef] = { t: 's', v: wsData[totalsRowIndex][colIdx] || '' };
    ws[cellRef].s = totalsStyle;
  }

  // Set column widths
  ws['!cols'] = [
    { wpx: 80 },  // Auc
    { wpx: 80 },  // Date
    { wpx: 120 }, // Name
    { wpx: 80 },  // Lot #
    { wpx: 200 }, // Address
    { wpx: 100 }, // Lot Price
  ];

  // Define cell styles for the header
  const headerStyle = {
    font: { bold: true }, // Bold headers
    alignment: { horizontal: 'center' }, // Center-align headers
    fill: { fgColor: { rgb: 'c0c0c0' } }, // Background color
  };

  const centerAlignStyle = {
    alignment: { horizontal: 'center' }, // Center-align specific cells
  };

  const leftAlignStyle = {
    alignment: { horizontal: 'left' }, // Left-align specific cells
  };

  const rightAlignStyle = {
    alignment: { horizontal: 'right' }, // Right-align Lot Price
  };

  // Apply header styles
  headers.forEach((_, colIdx) => {
    const cellRef = XLSXJS.utils.encode_cell({ r: 4, c: colIdx });
    if (!ws[cellRef]) ws[cellRef] = {};
    ws[cellRef].s = headerStyle;
  });

  // Apply styles to specific columns
  wsData.forEach((_, rowIdx) => {
    const aucCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 0 }); // Auc column
    const dateCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 1 }); // Date column
    const lotCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 3 }); // Lot # column
    const addressCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 4 }); // Address column
    const lotPriceCell = XLSXJS.utils.encode_cell({ r: rowIdx + 5, c: 5 }); // Lot Price column

    // Apply center alignment to Auc, Date, and Lot #
    if (ws[aucCell]) ws[aucCell].s = centerAlignStyle;
    if (ws[dateCell]) ws[dateCell].s = centerAlignStyle;
    if (ws[lotCell]) ws[lotCell].s = centerAlignStyle;

    // Apply left alignment to Address
    if (ws[addressCell]) ws[addressCell].s = leftAlignStyle;

    // Apply right alignment to Lot Price
    if (ws[lotPriceCell]) ws[lotPriceCell].s = rightAlignStyle;
  });

  // Add worksheet to the workbook and trigger download
  XLSXJS.utils.book_append_sheet(wb, ws, 'Sheet 1');
  const excelBuffer = XLSXJS.write(wb, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Tax_Exempt_Summary_Report.xlsx';
  a.click();
  URL.revokeObjectURL(url);
};

export const generateTaxExemptSummaryPdf = (filterTaxExemptData: any, getGlobalAuction: any) => {
  const doc: any = new jsPDF();
  const tableRows: any = [];
  let auctionId = '';
  let date: any = '';

  const calculateTotalLotPriceForCity = (subRes: any[]) => {
    let total = 0;
    subRes.forEach((item: any) => {
      total += item.lotPrice || 0;
    });
    return total;
  };
  const totalLotPriceofAll = filterTaxExemptData?.reduce((acc: any, cityData: any) => {
    const cityTotal = cityData?.subRes?.reduce((subAcc: any, subResItem: any) => subAcc + subResItem.lotPrice, 0);
    return acc + cityTotal;
  }, 0);


  const calculateTotalLotPrice = () => {
    let total = 0;
    filterTaxExemptData?.forEach((item: any) => {
      item?.subRes?.forEach((subItem: any) => {
        total += subItem.lotPrice;
      });
    });
    return total;
  };
  const totalLotPrice = calculateTotalLotPrice();

  filterTaxExemptData?.forEach((data: any) => {
    date = data?.auctionDate;
    // State row (full span)
    tableRows?.push([
      { content: `${data.state}`, colSpan: 7, styles: { fontStyle: 'bold', halign: 'left', fillColor: '#c0c0c0', fontSize: 10 } }
    ]);

    tableRows.push([
      { content: '', colSpan: 1, styles: { fillColor: '#f1f1f1' } },  // Empty cell for Auction Code
      { content: '', colSpan: 1, styles: { fillColor: '#f1f1f1' } },  // Empty cell for Date
      { content: `${data.lotCity}`, colSpan: 1, styles: { fontStyle: 'bold', halign: 'left', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } }, // City Name under Name column
      { content: '', colSpan: 2, styles: { fillColor: '#f1f1f1' } },   // Empty cells for other columns
      { content: `$${formatAmount(calculateTotalLotPriceForCity(data.subRes))}`, styles: { fontStyle: 'bold', halign: 'right', fillColor: '#f1f1f1', textColor: '#666666', fontSize: 10 } }

    ]);

    // Add the sub rows for each lot
    data?.subRes?.forEach((subItem: any) => {
      const rowData = [
        { content: data?.auctionCode || '-', styles: { halign: 'center', fillColor: '#f7f9fb', cellWidth: 30 } },
        { content: moment(data?.auctionDate).format('MM/DD/YYYY') || '-', styles: { halign: 'center', fillColor: '#f7f9fb', cellWidth: 30 } },
        { content: `${data.lotCity}`, styles: { halign: 'left', fillColor: '#f7f9fb', cellWidth: 30 } },
        { content: subItem.lotDisplayNo || "-", styles: { halign: 'left', fillColor: '#f7f9fb', cellWidth: 30 } },
        { content: subItem.lotAddress || "-", styles: { halign: 'left', fillColor: '#f7f9fb', cellWidth: 50 } },
        { content: `$${formatAmount(subItem?.lotPrice || 0)}`, styles: { halign: 'right', fillColor: '#f7f9fb', cellWidth: 30 } }
      ];
      tableRows.push(rowData);
    });
  });

  const header = (doc: any, auctionId: any) => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 7, 9, 30, 12);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold", });
    doc.text(`${getGlobalAuction?.label}`, 40, 17, { fontStyle: "bold" });
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    const rightAlignX = pageWidth - 10;
    doc.setFont("helvetica", "bold");
    doc.text("Tax Exempt Summary Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
    doc.text(`Auction #${filterTaxExemptData[0]?.auctionCode}`, rightAlignX, 17, { align: "right" });
    doc.setFontSize(11).text(`${moment(date).format('MM/DD/YYYY')}`, rightAlignX, 22, { align: "right" });
    doc.text(`Totals: $${formatAmount(totalLotPriceofAll)}`, rightAlignX, 27, { align: "right" });
  };

  const footer = (doc: any) => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
    doc.text(
      `Tax Exempt Summary`,
      pageSize.width / 2,
      pageHeight - 10,
      { align: "center" }
    );
    const todayTime = new Date().toLocaleString();
    doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
      align: "right",
    });
  };

  const footerRows = [
    [{ content: "Totals", colSpan: 5,  styles: { halign: 'right', fontStyle: 'bold', fillColor: '#f1f1f1', textColor: '#000000' }},
    { content: `$${formatAmount(totalLotPrice)}`,styles: { halign: 'right', fillColor: '#f1f1f1', textColor: '#666666' ,fontStyle: 'bold' } }]
  ];

  doc.autoTable({
    head: [
      [
        { content: "Auc", styles: { halign: 'center' } },
        { content: "Date", styles: { halign: 'center' } },
        { content: "Name", styles: { halign: 'left' } },
        { content: "Lot #", styles: { halign: 'left' } },
        { content: "Address", styles: { halign: 'left' } },
        { content: "Lot Price", styles: { halign: 'right' } }
      ]
    ],
    body: tableRows,
    foot: footerRows,
    startY: 35,
    theme: "plain",
    margin: { top: 35, left: 6, right: 6 },
    didDrawPage: () => {
      header(doc, auctionId);
      footer(doc);
    },
    styles: {
      fontSize: 9,
      cellPadding: { top: 2, right: 2, bottom: 1, left: 2 },
    },
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 30 },
      2: { cellWidth: 30 },
      3: { cellWidth: 30 },
      4: { cellWidth: 50 },
      5: { cellWidth: 30 },
    },
    headStyles: {
      halign: "center",
      fontSize: 10,
      fillColor: null,
      textColor: '#666666',
    }
  });
  doc.save('Tax_Exempt_SummaryReport.pdf');
}

{/** ********************* Other Reports End ************************ */ }


