// Main stepper
export const STEPONE = 1
export const STEPTWO = 2
export const STEPTHREE = 3
export const STEPFOUR = 4
export const STEPFIVE = 5
export const STEPSIX = 6

// Inventor main tabs
export const LOTS = "lots"
export const LOTCOMMCOST = "commCost"
export const LOTNOTES = "notes"
export const LOTAUTO = "auto"
export const LOTTITLEASSIGNMENT = "titleAssignment"
export const LOTREALESTATE = "realEstate"
export const LOTRECORDS = "records"
export const LOTDEEDLOCATION = "deedLocation"
export const LOTAGENTS = "agents"
export const LOTTAXES = "taxes"
export const LOTPROPERTYDETAILS = "propertyDetails"
export const LOTINTERIOR = "interior"
export const LOTEXTERIOR = "exterior"
export const LOTSILENT = "silent"
export const LOTDOCURLS = "docURls"
export const LOTDOCS = "lotDoc"

// Inventory sub-tabs
export const LOTSPAGE = "lotsPage"
export const LOTADDRESS = "address"
export const LOTLOGOPHOTO = "logoPhotos"

export const REALESTATERECORDS = "records"
export const REALESTATEAGENT = "agents"

export const LOTCOMMCOSTBIDOWN = "costCommBidDown"


export const PropertyDetailsChecks = [
    {
        heading: "Special Features",
        checks: [
            { label: "1st Floor Master Bedroom", key: "floorMasterBedroom" },
            { label: "In-Law Apartment", key: "inlawApartment" },
            { label: "Water Front", key: "waterFront" },
            { label: "Water View", key: "waterView" },
            { label: "Pool", key: "pool" }
        ]
    },
    {
        heading: "Access",
        checks: [
            { label: "At Light", key: "atlight" },
            { label: "Median", key: "median" },
            { label: "Cut", key: "cut" },
            { label: "DOT Permit", key: "dotPermit" },
            { label: "Traffic Report", key: "trafficReports" },
            { label: "Right of Way", key: "rightOfWay" },
            { label: "Public Street", key: "publicStreet" },
            { label: "Pri", key: "pri" }
        ]
    },
    {
        heading: "Environmental",
        checks: [
            { label: "Soil boring", key: "soilBorings" },
            { label: "Compaction Test", key: "compactionTest" },
            { label: "Flood Zone", key: "floodZone" },
            { label: "Wetland", key: "wetland" },
            { label: "Ponds/Streams", key: "pond" },
            { label: "Underground Tanks", key: "underGroundTrank" },
            { label: "Phase 1", key: "phase1" }
        ]
    },
]

export const InteriorChecks = [
    { label: "Bar-Dry", key: "barDry" },
    { label: "Basement, Unfinished", key: "basementUnFurnished" },
    { label: "Carbon Monooxide Detector", key: "carbonMonoxideDetector" },
    { label: "Disposal", key: "disposal" },
    { label: "Eat in Kitchen", key: "eatInKitchen" },
    { label: "Fire Place", key: "firePlace" },
    { label: "High Ceilings", key: "highCeilings" },
    { label: "Intercom", key: "interCom" },
    { label: "Range/Over", key: "rangeOver" },
    { label: "Security System", key: "securitySystem" },
    { label: "SkyLight", key: "skyLight" },
    { label: "Track Lighting", key: "trackLighting" },
    { label: "Walk-in Closets", key: "walkInClosets" },
    { label: "Whirlpool", key: "whirlpool" },
    { label: "Bar-Wet", key: "barWet" },
    { label: "Beam Ceilings", key: "beamCeilings" },
    { label: "Cathedral Ceilings", key: "cathedralCeilings" },
    { label: "Central Vacccum", key: "centrlVaccum" },
    { label: "Drapes", key: "drapes" },
    { label: "Elevator", key: "elevator" },
    { label: "Frezzer-Freestanding", key: "freezerFreeStanding" },
    { label: "Hot Tub", key: "hotTub" },
    { label: "Laundry Room", key: "laundryRoom" },
    { label: "Range/Oven Gas", key: "rangeOvenGas" },
    { label: "Smoke Detector", key: "smokeDetector" },
    { label: "Trash Compactor", key: "trashCompactor" },
    { label: "Waster", key: "waster" },
    { label: "Window Treatements", key: "windowTreatments" },
    { label: "Basement Finished", key: "basementFinished" },
    { label: "Blinds", key: "blinds" },
    { label: "Ceiling Fans", key: "ceilingFans" },
    { label: "Dishwasher", key: "dishWasher" },
    { label: "Dryer", key: "dryer" },
    { label: "Fire Alarm System", key: "fireAlarmSystem" },
    { label: "Guest Suite", key: "guestSuite" },
    { label: "Instant Hot water", key: "instantHotWater" },
    { label: "Microwave Oven", key: "microwaveOven" },
    { label: "Refrigerator", key: "refrigerator" },
    { label: "Shades", key: "shades" },
    { label: "Tile Floors", key: "tileFloors" },
    { label: "W/W carpets", key: "wwCarpets" },
    { label: "Water Filters", key: "waterFilters" },
    { label: "Wood Floors", key: "woodFloors" },
]

export const ExteriorChecks = [
    { label: "Alarm System", key: "alarmSystem" },
    { label: "Balcony", key: "balconey" },
    { label: "Bar-wet", key: "barWet" },
    { label: "Barbeque", key: "barbeque" },
    { label: "Barn/Stable", key: "barn" },
    { label: "Boat Facilities", key: "boatFacilities" },
    { label: "Carport", key: "carpot" },
    { label: "Carriage House", key: "carriageHouse" },
    { label: "Corner Lot", key: "cornerLot" },
    { label: "Cul-de-sac", key: "culDeSac" },
    { label: "Curbs", key: "curbs" },
    { label: "Deck", key: "deck" },
    { label: "Dock", key: "dock" },
    { label: "Dog Run", key: "dogRun" },
    { label: "Doorman", key: "doorman" },
    { label: "Enclosed Porch", key: "enclosedPorch" },
    { label: "Greenhouse Room", key: "grrenhouseRoom" },
    { label: "Handscap Equipped", key: "handcapEquipped" },
    { label: "Horse Facilities", key: "horseFacilities" },
    { label: "Hot Tub/Spa", key: "hotTub" },
    { label: "Lake Privileges", key: "lakePrivileges" },
    { label: "Metal Fence", key: "metalfence" },
    { label: "Mountain View", key: "mountainView" },
    { label: "Not Prec Tested", key: "notPrecTested" },
    { label: "Open Porch", key: "openPorch" },
    { label: "OutBuildings", key: "outBuildings" },
    { label: "Partially Fenced", key: "partiallyFenced" },
    { label: "Patio", key: "patio" },
    { label: "Pool-in Ground", key: "poolInGround" },
    { label: "Privacy Fence", key: "privancyFence" },
    { label: "Satellite Dish", key: "satelliteDish" },
    { label: "Sauna", key: "sauna" },
    { label: "Screened Porch", key: "screenedporch" },
    { label: "Sidewalk", key: "sidewalk" },
    { label: "Site Security", key: "siteSecurity" },
    { label: "State Roof", key: "stateRoof" },
    { label: "Storage Shed", key: "storageShed" },
    { label: "Storm Door(s)", key: "stormDoor" },
    { label: "Strom Windows", key: "stromWindows" },
    { label: "Tennis Court", key: "tennisCourt" },
    { label: "Thermal Win/Doors", key: "thermalWin" },
    { label: "Tile Roof", key: "tileRoof" },
    { label: "Underground Sprinkler", key: "undergroundSprinkler" },
    { label: "Wood Floors", key: "woodFloors" },
    { label: "Wooded Lot", key: "woodedLot" },
    { label: "Workshop", key: "workshop" },
    { label: "Yard", key: "yard" },
]