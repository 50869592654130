import { yupResolver } from "@hookform/resolvers/yup"
import axios from "axios"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import CurrencyInput from "react-currency-input-field"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import deleteIcon from "../../assets/delet_icon_tab.svg"
import editIcon from "../../assets/edit_icon_tab.svg"
import { GETALLSALESREPCOMMISSIONSCALE } from "../../common/apiConstatnts"
import { CommonMessages, errorMessages } from "../../common/messageConstants"
import { DECIMAL5, DECIMAL5_MESSAGE, DECIMAL63, DECIMAL63_MESSAGE, PERCENTAGE, PERCENTAGE_MESSAGE } from "../../common/regexConstants"
import { saleDefaults, sellerSaved, setSalesCommSettingId, setSalesRepData, showLoader } from "../../store/actions/CommissionAction"
import handleError from "../../Utils/ApiCommonErrorMessages"
import { ERROR_MESSAGES, formatCurrency, formatNumber, handleKeyDown, trimObjectValues } from "../../Utils/Utils"

const SalesRepCommission = () => {

    const initialRangeData = {
        salesRepCommissionScaleRangeId: 0,
        salesRepCommissionScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        from: 0,
        upto: "",
        percentage: "",
        fixedAmount: "",
        // levelMin: "",
        // levelCap: "",
        fixedPercentageType: "1",
        isActive: true,
        mode: "add"
    }

    const radioSelection = [
        { label: '%', value: '1', selected: true, key: 'isPercentage', listingLabel: 'Percentage', clearLabel: 'fixedAmount' },
        { label: 'Fixed', value: '2', selected: false, key: 'isFixed', listingLabel: 'Fixed', clearLabel: 'percentage' },
        { label: 'Both', value: '3', selected: false, key: 'isBoth', listingLabel: 'Both', clearLabel: '' }
    ]

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const salesRep = useSelector((data: any) => data.commission.salesRepCommission)
    const salesDefaultsData = useSelector((data: any) => data.commission.salesDefaults)
    const isSaved = useSelector((data: any) => data.commission.isSalesSave)
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleModalRange, setToggleValueRange] = useState<boolean>(false)
    const [salesRepCommission, setSalesRepCommission] = useState<any>([])
    const [salesRepCommissionRange, setSalesRepCommissionRange] = useState([])
    const [activeSalesRepId, setActiveSalesRepId] = useState<any>();
    const [activeUniqueId, setActiveUniqueId] = useState<any>();
    const [isLast, setIsLast] = useState<boolean>(false)
    const [mode, setMode] = useState('add');
    const [deleteDisable, setDeleteDisable] = useState<any>('')
    // const [lastUptoValue, setLastUptoValue] = useState<any>()
    const [lastUptoValue, setLastUptoValue] = useState<any>(0);
    const [radioValue, setRadioValue] = useState<any>("1")
    const [uptoError, setUptoError] = useState<boolean>(false)
    const [isLimitReach, setIsLimitReach] = useState<boolean>(false)
    const [rangeMode, setRangeMode] = useState('add');

    const maxWordCount = 50
    const [remainingCount, setRemainingCount] = useState(maxWordCount);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount);


    const initialSalesRepData = {
        salesRepCommissionScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        label: "",
        taxRegionName: "",
        isActive: true,
        mode: mode,
    }

    const [initialSalesRepFormState, setInitialSalesRepFormState] = useState<any>(initialSalesRepData)
    const [initialRangeFormState, setInitialRangeFormState] = useState<any>(initialRangeData)
    const decimalValForNxt: any = 0.01

    const schema: any = yup.object().shape({
        label: yup.string().required("Label is required.").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).max(50, "Please enter upto 50 characters."),
        taxRegionName: yup.string().required('Tax Region Name is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).max(50, "Please enter upto 50 characters.")
    });

    const rangeSchema: any = yup.object().shape({
        from: yup.string().required('From is required.')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL63, DECIMAL63_MESSAGE)
            .test('is-greater-from',
                `Please enter valid number greater than ${parseFloat(lastUptoValue ? lastUptoValue : 0) - parseFloat(decimalValForNxt)}`,
                val => rangeMode == 'edit' || !lastUptoValue ? true : parseFloat(val) > parseFloat(`${lastUptoValue - decimalValForNxt}`
                )),
        upto: yup
            .string()
            .required("Upto is required.")
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL63, DECIMAL63_MESSAGE).test("is-less-from", 'Please enter valid number greater than From value', val => parseFloat(val) > from),
        fixedPercentageType: yup.string().required("Fixed/Percentage/Type is required."),
        percentage: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "1" || value === "3",
            then: () => yup.string()
                .test('is-text', 'Please Enter Valid number',
                    function (value: any) {
                        let re = /^(?:[1-9]\d*|0)?(?:\.\d{1,5})?$/
                        return re.test(value)
                    }
                )
                .matches(PERCENTAGE, PERCENTAGE_MESSAGE).required('Percentage is required')
        }),
        fixedAmount: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "2" || value === "3",
            then: () => yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL5, DECIMAL5_MESSAGE).required('Fix amount is required')
        }),
        // levelMin: yup.string().required('Level Min is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),
        // levelCap: yup.string().required('Level Cap is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),

    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialSalesRepFormState, mode: 'onChange' })

    const {
        register: rangeRegister,
        handleSubmit: rangeHandleSubmit,
        reset: rangeReset,
        setValue: rangeSetValue,
        clearErrors: rangeClearErrors,
        watch,
        getValues,
        formState: { errors: rangeErrors, isValid: rangeIsValid },
    } = useForm({ resolver: yupResolver(rangeSchema), defaultValues: initialRangeFormState, mode: 'onChange' })
    const fixedAmount = watch('fixedAmount')

    const from = parseFloat(watch('from'))
    const upto = parseFloat(watch('upto'))

    useEffect(() => {
        const upto = getValues('upto')
        const errorKeys = Object.keys(rangeErrors)
        if (from >= upto && upto !== '' && !errorKeys.includes('upto')) {
            setUptoError(true)
            return
        }
        setUptoError(false)
        rangeClearErrors('from')
    }, [from])


    useEffect(() => {
        setUptoError(false)
    }, [upto])

    const handleClose = () => {
        reset(initialSalesRepFormState)
        setToggleValue(!toggleModal)
    }

    const handleCloseRangeModel = () => {
        rangeReset(initialRangeFormState)
        setToggleValueRange(!toggleModalRange)
    }

    const getAllSalesRepCommissionData = () => {
        dispatch(showLoader(true))
        axios.get(GETALLSALESREPCOMMISSIONSCALE, config).then((response) => {
            dispatch(showLoader(false))
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data
                dispatch(saleDefaults({
                    sold: data?.strSalesRepCommissionSold,
                    passedUnsold: data?.strSalesRepCommissionUnSold,
                    buyBack: data?.strSalesRepCommissionBuyPack,
                    nonPayer: data?.strSalesRepCommissionNonPayer,
                    passedLot: data?.salesRepCommissionPassedLot,
                    unPassedLot: data?.salesRepCommissionUnPassesLot
                }))
                const salesRepCommData = data?.lstSalesRepCommissionScale
                const updateSalesRepCommData = salesRepCommData?.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstSalesRepCommissionScaleRange: data?.lstSalesRepCommissionScaleRange?.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })

                const firstIndexScale = updateSalesRepCommData ? updateSalesRepCommData[0] : []
                const firstIndexRange = updateSalesRepCommData ? updateSalesRepCommData[0]?.lstSalesRepCommissionScaleRange : []
                setSalesRepCommission(updateSalesRepCommData ?? []);
                setActiveSalesRepId(firstIndexScale?.salesRepCommissionScaleId);
                setActiveUniqueId(firstIndexScale?.uniqueId);
                setStartingFrom(firstIndexRange);
                setSalesRepCommissionRange(firstIndexRange);
                dispatch(setSalesRepData(updateSalesRepCommData));
                dispatch(setSalesCommSettingId(data?.salesRepCommissionScaleSettingId));
            }
        }).catch((error) => {
            dispatch(showLoader(false));
            handleError(error);
        });
    }

    const getSalesRepId = (scalerId: number, uniqueId: string) => {
        const found: any = salesRepCommission?.find((obj: any) => {
            return obj.uniqueId === uniqueId;
        });
        const range = found.lstSalesRepCommissionScaleRange
        setActiveSalesRepId(scalerId);
        setStartingFrom(range)
        setActiveUniqueId(found.uniqueId)
        setSalesRepCommissionRange(range?.filter((data: any) => data.mode !== 'delete'))

    }

    const handleCheckClick = (e: any, className: string, uniqueId: string, keyName: string, defaultsKeyName: string, type: string) => {
        e.stopPropagation();
        const chkEvent = e.target
        const isChecked = chkEvent.checked
        if (isChecked) {
            chkEvent.checked = false
            const newData: any = salesRepCommission?.find((data: any) => data.uniqueId === uniqueId)
            const checks: any = document.getElementsByClassName(className)
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to set "${newData.label}" as default ${type}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (let i = 0; i < checks.length; i++) {
                        if (checks[i] === chkEvent) {
                            checks[i].checked = true
                        } else {
                            checks[i].checked = false
                        }
                    }

                    const updateComm = salesRepCommission?.map((data: any) => {
                        if (data.uniqueId === uniqueId) {
                            return { ...data, [keyName]: true }
                        }
                        return { ...data, [keyName]: false }
                    })

                    const activeSalesRepRegion = salesRepCommission?.find((data: any) => data.uniqueId === uniqueId).taxRegionName
                    const updateDefaults = { ...salesDefaultsData, [defaultsKeyName]: activeSalesRepRegion }
                    setSalesRepCommission(updateComm)
                    dispatch(setSalesRepData(updateComm))
                    dispatch(saleDefaults(updateDefaults))
                }
            })

        } else {
            chkEvent.checked = true
        }
    }

    const onSubmitSalesRepScale = (dataOriginal: any) => {

        const data = trimObjectValues(dataOriginal)

        let updatedData: any;

        if (data?.salesRepCommissionScaleId === 0 && data?.uniqueId === undefined) {
            const newScale: any = { ...data, lstSalesRepCommissionScaleRange: [], mode: "add", uniqueId: uuidv4() }
            updatedData = [...salesRepCommission, newScale]
        } else {
            updatedData = salesRepCommission?.map((sData: any) => {
                if (sData?.uniqueId === data?.uniqueId) {
                    return { ...sData, ...data }
                }
                return sData
            })
        }
        setSalesRepCommission(updatedData)
        setActiveSalesRepId(updatedData[0]?.salesRepCommissionScaleId)
        setActiveUniqueId(updatedData[0]?.uniqueId)
        dispatch(setSalesRepData(updatedData))
        dispatch(saleDefaults({
            sold: data?.salesRepCommissionSold ? data?.taxRegionName : salesDefaultsData?.sold,
            passedUnsold: data?.salesRepCommissionUnSold ? data?.taxRegionName : salesDefaultsData?.passedUnsold,
            buyBack: data?.salesRepCommissionBuyBack ? data?.taxRegionName : salesDefaultsData?.buyBack,
            nonPayer: data?.salesRepCommissionNonPayer ? data?.taxRegionName : salesDefaultsData?.nonPayer,
            passedLot: salesDefaultsData?.passedLot,
            unPassedLot: salesDefaultsData?.unPassedLot
        }))
        setToggleValue(!toggleModal)
        reset(initialSalesRepFormState)
    }

    const handleEditModal = (e: any, uniqId: number) => {
        e.stopPropagation()
        setMode('edit')
        setToggleValue(!toggleModal)
        const getSalesData = salesRepCommission?.find((data: any) => data.uniqueId === uniqId)
        reset(getSalesData)
    }

    const setStartingFrom = (rangeData: any) => {
        const getActiveRange = rangeData?.filter((data: any) => data.mode !== 'delete')
        const lastIndexRange = getActiveRange?.length > 0 ? getActiveRange[getActiveRange?.length - 1] : 0
        const setUpto = lastIndexRange.upto ? parseFloat(lastIndexRange.upto) + decimalValForNxt : 0
        setLastUptoValue(parseFloat(setUpto))
        rangeSetValue('from', parseFloat(parseFloat(setUpto)?.toFixed(2)))
    }

    const handleSalesCommDelete = (e: any, uniqueId: number) => {
        e.stopPropagation()
        const checkDefault: any = salesRepCommission?.find((data: any) => data.uniqueId === uniqueId)
        if (checkDefault.salesRepCommissionBuyBack || checkDefault.salesRepCommissionNonPayer || checkDefault.salesRepCommissionSold || checkDefault.salesRepCommissionUnSold) {
            setDeleteDisable(uniqueId)
            toast.error(errorMessages.defaultValueScale, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            setTimeout(() => {
                setDeleteDisable('')
            }, 3000)
            return
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this scale!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updateScale: any = salesRepCommission?.map((data: any) => {
                    if (uniqueId === data.uniqueId) {
                        if (data?.salesRepCommissionScaleId == 0) {
                            return null
                        }
                        return { ...data, mode: 'delete', isActive: false }
                    }
                    return data
                }).filter((i: any) => i)
                const getActive = updateScale?.filter((data: any) => data.mode !== 'delete')
                const firstIndexRange = getActive[0]?.lstSalesRepCommissionScaleRange ?? []
                setSalesRepCommission(updateScale)
                dispatch(setSalesRepData(updateScale))
                setActiveSalesRepId(getActive[0]?.salesRepCommissionScaleId)
                setStartingFrom(firstIndexRange)
                setSalesRepCommissionRange(firstIndexRange)
                setActiveUniqueId(getActive[0]?.uniqueId)
            }
        })
        return
    }

    const handleRangeDelete = (scaleUniqueId: any, rangeUniqueId: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this range!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const modifyScale: any = salesRepCommission?.map((data: any) => {
                    const modifyRange = data.lstSalesRepCommissionScaleRange?.map((rg: any) => {
                        if (rg.uniqueId === rangeUniqueId) {
                            if (rg?.salesRepCommissionScaleRangeId == 0) {
                                return null
                            }
                            return { ...rg, mode: "delete", isActive: false }
                        }
                        return rg
                    }).filter((i: any) => i)
                    return { ...data, lstSalesRepCommissionScaleRange: modifyRange }
                })
                const getBid = modifyScale?.find((data: any) => data.uniqueId === scaleUniqueId)?.lstSalesRepCommissionScaleRange
                let getLastUpto = getBid?.filter((data: any) => data.mode !== "delete")
                setLastUptoValue(parseFloat(getLastUpto[getLastUpto?.length - 1]?.upto) + decimalValForNxt)
                setSalesRepCommissionRange(getLastUpto)
                setSalesRepCommission(modifyScale)
                dispatch(setSalesRepData(modifyScale))
            }
        })
        return
    }

    const handleFormRadio = (value: string, clearLabel: string) => {
        if (clearLabel !== '') {
            rangeSetValue(`${clearLabel}`, '');
            rangeClearErrors(`${clearLabel}`);
        }
        setRadioValue(value);
        rangeSetValue('fixedPercentageType', value);
    }

    const onSubmitRange = (data: any) => {
        const updatedScaleData: any = salesRepCommission?.map((bData: any) => {
            if (bData.uniqueId === activeUniqueId) {
                const updateMode = bData.lstSalesRepCommissionScaleRange?.map((item: any) => (
                    {
                        ...item,
                        mode: (item.salesRepCommissionScaleId === 0 && item.mode != 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                    }
                ))
                const newScaleData = { ...bData, lstSalesRepCommissionScaleRange: updateMode }

                let updatedRangeData: any
                if (rangeMode == 'edit') {
                    //first delete range which is edited then add new range. bcoz edit mode not working.
                    updatedRangeData = {
                        ...bData, lstSalesRepCommissionScaleRange: bData?.lstSalesRepCommissionScaleRange?.map((udata: any) => {
                            if (udata.uniqueId == data.uniqueId) {
                                if (data?.salesRepCommissionScaleRangeId == 0) {
                                    return null
                                }
                                return { ...udata, ...data, mode: 'delete', isActive: false }
                            }
                            return udata
                        }).filter((i: any) => i)
                    }
                    //add new range here, which is edited.
                    updatedRangeData = {
                        ...updatedRangeData,
                        lstSalesRepCommissionScaleRange: [...updatedRangeData?.lstSalesRepCommissionScaleRange,
                        {
                            ...data,
                            percentage: (data.percentage) ? parseInt(data.percentage) : 0,
                            fixedAmount: (data.fixedAmount) ? parseInt(data.fixedAmount) : 0,
                            salesRepCommissionScaleId: activeSalesRepId,
                            salesRepCommissionScaleRangeId: 0,
                            mode: "add",
                            uniqueId: uuidv4()
                        }
                        ]
                    }
                } else {
                    updatedRangeData = {
                        ...newScaleData,
                        lstSalesRepCommissionScaleRange: [...newScaleData.lstSalesRepCommissionScaleRange,
                        {
                            ...data,
                            percentage: (data.percentage) ? parseInt(data.percentage) : 0,
                            fixedAmount: (data.fixedAmount) ? parseInt(data.fixedAmount) : 0,
                            salesRepCommissionScaleId: activeSalesRepId,
                            mode: "add",
                            uniqueId: uuidv4()
                        }
                        ]
                    }
                }
                return updatedRangeData
            }
            const updateMode = bData.lstSalesRepCommissionScaleRange?.map((item: any) => (
                {
                    ...item,
                    mode: (item.salesRepCommissionScaleRangeId === 0 && item.mode != 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                }
            ))
            return { ...bData, lstSalesRepCommissionScaleRange: updateMode }
        })

        const updateBidRange: any = updatedScaleData?.find((data: any) => data.uniqueId === activeUniqueId)?.lstSalesRepCommissionScaleRange
        setSalesRepCommissionRange(updateBidRange);
        setSalesRepCommission(updatedScaleData);
        setLastUptoValue(parseFloat(data.upto) + decimalValForNxt);
        dispatch(setSalesRepData(updatedScaleData));
        rangeReset(initialRangeFormState);
        setToggleValueRange(!toggleModalRange);
    }

    const handleRangeEditModal = (scaleUniqueId: any, rangeUniqueId: string) => {
        setRangeMode('edit')
        //   Find a record from range data which matches with the unique ID :
        const matchedRecord = salesRepCommissionRange.find((item: any) => item.uniqueId == rangeUniqueId)
        setToggleValueRange(true);
        rangeReset(matchedRecord)
    }

    useEffect(() => {
        const checkLast = salesRepCommissionRange?.length > 0 ? salesRepCommissionRange?.filter((data: any) => data.mode !== 'delete') : [];
        const getBool: any = checkLast?.length === 1 ? true : false;
        setIsLast(getBool);
    }, [salesRepCommissionRange]);

    useEffect(() => {
        if (isSaved) {
            setSalesRepCommission([]);
            setSalesRepCommissionRange([]);
            getAllSalesRepCommissionData();
            dispatch(sellerSaved(false));
        }
    }, [isSaved])

    useEffect(() => {
        if (salesRep && salesRep?.length > 0) {
            const firstIndexScale = salesRep[0] ?? [];
            const firstIndexRange = salesRep[0]?.lstSalesRepCommissionScaleRange ?? [];
            setSalesRepCommission(salesRep);
            setActiveSalesRepId(firstIndexScale?.salesRepCommissionScaleId);
            setActiveUniqueId(firstIndexScale?.uniqueId);
            setStartingFrom(firstIndexRange);
            setSalesRepCommissionRange(firstIndexRange);
            return
        }
        getAllSalesRepCommissionData();
    }, [])

    useEffect(() => {
        const lastUpto: any = parseFloat(lastUptoValue ? lastUptoValue : 0) - decimalValForNxt;
        const CommissionScaleLimit: any = process.env.REACT_APP_COMMISSION_FROM_LIMIT
        if (lastUpto >= parseFloat(CommissionScaleLimit)) {
            // setIsLimitReach(true);

            return;
        }
        setIsLimitReach(false);
    }, [lastUptoValue])

    return (
        <>
            {/* Add Scale */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmitSalesRepScale)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{mode === 'add' ? 'Add New Scale' : 'Update Scale'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Label <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Label"
                                    maxLength={51}
                                    {...register("label")}
                                />
                                {errors.label && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.label.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Tax Region Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Tax Region Name"
                                    maxLength={51}
                                    {...register("taxRegionName")}
                                />
                                {errors.taxRegionName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRegionName.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit"
                        >{mode === 'add' ? (CommonMessages.saveButton) : (CommonMessages.updateButton)}</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Add Range */}
            < Modal show={toggleModalRange} size={isLimitReach ? 'sm' : 'xl'} centered >
                <form onSubmit={rangeHandleSubmit(onSubmitRange)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Add New Range</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseRangeModel} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {!isLimitReach ?
                            <div className="row g-3 mb-3">
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">From ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="From"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={13}
                                        {...rangeRegister('from')}
                                        onKeyDown={handleKeyDown}
                                        disabled={rangeMode == 'edit'}
                                    />
                                    {rangeErrors.from && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.from.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Upto ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="Upto"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={13}
                                        {...rangeRegister('upto')}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.upto && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.upto.message}</span>}
                                    {uptoError && <span className='text-danger' style={{ fontSize: "12px" }}>Upto must greater than From</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label" htmlFor="text">Percentage/Fixed/Both<sup className="text-danger">*</sup></label>
                                    <div className="code-to-copy">
                                        {radioSelection?.map((selection: any, index: any) => {
                                            return (
                                                <div className="form-check form-check-inline mt-2" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        name="commType"
                                                        id={`commType_${index}`}
                                                        type="radio"
                                                        defaultChecked={selection.selected}
                                                        onChange={() => handleFormRadio(selection.value, selection.clearLabel)}
                                                    />
                                                    <label className="form-label" htmlFor={`commType_${index}`}>{selection.label}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Percentage ($){radioValue !== "2" && <sup className="text-danger">*</sup>}</label>
                                    <input
                                        placeholder="Percentage"
                                        className="form-control form-icon-input"
                                        type="text"
                                        {...rangeRegister('percentage')}
                                        maxLength={5}
                                        disabled={radioValue === "2"}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.percentage && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.percentage.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Fixed Amount ($){radioValue !== "1" && <sup className="text-danger">*</sup>}</label>
                                    <CurrencyInput
                                        type="text"
                                        placeholder="Tax Amount"
                                        {...rangeRegister('fixedAmount')}
                                        prefix="$"
                                        onKeyDown={handleKeyDown}
                                        value={fixedAmount}
                                        onValueChange={(value: any) => rangeSetValue("fixedAmount", value)}
                                        className="form-control form-icon-input"
                                        disabled={radioValue === "1"}

                                    />
                                    {rangeErrors.fixedAmount && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.fixedAmount.message}</span>}
                                </div>


                            </div>
                            :
                            <span><span className="text-danger">**</span>You have exceeded the range limit. So you can't add new range.</span>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={!isLimitReach ? "btn btn-outline-danger" : "btn btn-primary"} type="button" data-bs-dismiss="modal" onClick={handleCloseRangeModel}>{!isLimitReach ? CommonMessages.cancelButton : "Ok"}</button>
                        {/* <button className="btn btn-primary" type="submit" disabled={!rangeIsValid}>Create</button> */}
                        {!isLimitReach && <button className="btn btn-primary" type="submit"
                        // disabled={!rangeIsValid || uptoError}
                        >{rangeMode === 'add' ? CommonMessages.createButton : 'Update'}</button>}
                    </Modal.Footer>
                </form>
            </Modal >
            <div className="row g-3">
                <div className="col-lg-4">
                    <div className="d-flex align-items-center justify-content-end mb-1">
                        <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                            onClick={() => {
                                setToggleValue(!toggleModal)
                                setMode('add')
                            }}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Scale</span>
                        </button>
                    </div>

                    <div id="tableExample4">
                        <div className="table-responsive tbody_scroll_cus">
                            <table className="table border-radius border td_bottom_border_0 fs--1 table-sm mb-0">
                                <thead>
                                    <tr className="bg-gradiant">
                                        <th className="border-bottom ps-3 pe-3 pt-2 pb-2 pe-2">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "20%" }}> Label </div>
                                                <div className="me-auto align-middle tax pe-3" style={{ width: "60%" }}>Tax Region Name</div>
                                                <div className="align-middle payment text-end" style={{ width: "20%" }}> Action </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list scrollbar">
                                    {salesRepCommission?.length > 0 && salesRepCommission?.filter((data: any) => data?.mode !== 'delete')?.length > 0 ?
                                        salesRepCommission?.map((salesData: any, index: any) => {
                                            return (
                                                <>
                                                    {(salesData.mode !== 'delete') && <tr onClick={() => { getSalesRepId(salesData.salesRepCommissionScaleId, salesData.uniqueId) }} key={index}>
                                                        <td className="align-middle ps-1 pe-1 pt-2 pb-2 pe-2 name pt-2 pb-2">
                                                            <div className={`d-flex flex-wrap border_ac ${activeUniqueId == salesData.uniqueId && activeSalesRepId == salesData.salesRepCommissionScaleId ? 'active' : ''}`}>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between position-relative">
                                                                    <div style={{ width: "20%" }} className="d-flex pe-3 flex-wrap align-items-center" >
                                                                        <span className="ellipsis">{salesData.label}</span>
                                                                    </div>
                                                                    <div style={{ width: "65%" }} className="me-auto align-middle tax pe-3">
                                                                        <span className="ellipsis">{salesData.taxRegionName}</span>
                                                                    </div>
                                                                    <div style={{ width: "15%" }} className="align-middle payment text-end">
                                                                        <div className="action_btn">
                                                                            <button
                                                                                type="button"
                                                                                className="action_icons text-decoration-none btn p-0"
                                                                                onClick={(e) => handleEditModal(e, salesData.uniqueId)}
                                                                            > <img src={editIcon} /> </button>
                                                                            <button
                                                                                type="button"
                                                                                className="action_icons text-decoration-none ms-2 btn p-0"
                                                                                disabled={deleteDisable == salesData.uniqueId}
                                                                                onClick={(e) => handleSalesCommDelete(e, salesData.uniqueId)}
                                                                            >
                                                                                <img src={deleteIcon} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2" >
                                                                    <input
                                                                        className="form-check-input form-check-sold"
                                                                        id={`sold_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-sold', salesData.uniqueId, 'salesRepCommissionSold', 'sold', 'Sold')}
                                                                        checked={salesData.salesRepCommissionSold === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`sold_${index}`}>Sold</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-passUnsold"
                                                                        id={`passUnsold_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-passUnsold', salesData.uniqueId, 'salesRepCommissionUnSold', 'passedUnsold', 'Passed/Unsold')}
                                                                        checked={salesData.salesRepCommissionUnSold === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`passUnsold_${index}`}>Passed/Unsold</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-buyBack"
                                                                        id={`buyBack_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-buyBack', salesData.uniqueId, 'salesRepCommissionBuyBack', 'buyBack', 'Buyback')}
                                                                        checked={salesData.salesRepCommissionBuyBack === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`buyBack_${index}`}>Buyback</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-nonPayer"
                                                                        id={`nonPayer_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-nonPayer', salesData.uniqueId, 'salesRepCommissionNonPayer', 'nonPayer', 'Non-Payer')}
                                                                        checked={salesData.salesRepCommissionNonPayer === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`nonPayer_${index}`}>Non-Payer</label>
                                                                </div>
                                                            </div>
                                                        </td >
                                                    </tr>}
                                                </>
                                            )
                                        })
                                        :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={6}>No Scale found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="col-lg-8">
                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                        <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please add atleast one range against each scale.</i></span>
                        <button className="btn btn-outline-secondary ms-auto Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                            onClick={() => {
                                setToggleValueRange(!toggleModalRange)
                                setRadioValue('1')
                                rangeSetValue('from', parseFloat(lastUptoValue ? lastUptoValue?.toFixed(2) : 0) ?? 0)
                            }}
                            disabled={salesRepCommission?.length == 0}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Range</span>
                        </button>
                    </div>

                    <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                        <div className="table-responsive scrollbar border-top border-bottom">
                            <table className="table table-bordered table-sm mb-0 fs--1">
                                <thead>
                                    <tr>
                                        <th className="bg-gradiant border-bottom border-top p-2" >From</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Upto</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Percentage</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Fixed Amount</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >%/Fixed/Both</th>
                                        {/* <th className="bg-gradiant border-bottom border-top p-2" >Level Min</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Level Cap</th> */}
                                        <th className="bg-gradiant border-bottom text-end align-middle pe-0 border-top ps-3 pe-3 pt-2 pb-2" style={{ width: "30px" }} scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {salesRepCommissionRange?.length > 0 ?
                                        salesRepCommissionRange?.map((rangeData: any, index: any) => {
                                            return (
                                                <>
                                                    {rangeData.mode !== "delete" && <tr key={index}>
                                                        <td className="p-2">{formatNumber(rangeData.from)}</td>
                                                        <td className="p-2">{formatNumber(rangeData.upto)}</td>
                                                        <td className="p-2">{rangeData.percentage}</td>
                                                        <td className="p-2">{formatCurrency(rangeData.fixedAmount)}</td>
                                                        <td className="p-2">{radioSelection.find((data) => data.value == rangeData.fixedPercentageType)?.listingLabel}</td>
                                                        {/* <td className="p-2">{rangeData.levelMin}</td>
                                                        <td className="p-2">{rangeData.levelCap}</td> */}
                                                        <td className="p-2" style={{ width: "50px" }}>
                                                            {(salesRepCommissionRange?.length === index + 1) &&
                                                                <div className="action_btn align-items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="action_icons text-decoration-none btn p-0"
                                                                        onClick={() => handleRangeEditModal(activeUniqueId, rangeData.uniqueId)}
                                                                    ><img src={editIcon} height={12} />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="action_icons text-decoration-none ms-2 btn p-0"
                                                                        onClick={() => handleRangeDelete(activeUniqueId, rangeData.uniqueId)}
                                                                    ><img src={deleteIcon} height={12} /></button>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr >}
                                                </>
                                            )
                                        })
                                        :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={8}>No Range found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SalesRepCommission
