import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import LotsPage from "./LotsPage";
import Address from "./Address";
import LogoPhotos from "./LogosPhotos/LogoPhotos";
import { useLocation, useParams } from "react-router";
import { setLotId, setMode } from "../../../../store/actions/Inventory";
import { decryptId } from "../../../../Encryption/Encryption";

const Lots = ({ InvTypeText }: any) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const [activeTab, setActiveTab] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const ActiveT: any = useSelector((state: any) => state?.inventory?.activePage);
    const lotId = useSelector((state: any) => state.inventory.lotId);
    const type = location.state?.type ?? null;
    const typeFromStore = useSelector((state: any) => state.inventory.mode);
    const clonedLotId = useSelector((state: any) => state.inventory.clonedLotId);
    const [modeType] = useState<any>(type === null ? typeFromStore : type)
    const ID = params.id ? decryptId(params.id) : 0;
    const [showEnabled, setShowEnabled] = useState<boolean>(false)

    const handleChange = (key: string) => {
        setLoading(true)
        setActiveTab(key);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    useEffect(() => {
        if (ActiveT && ActiveT?.hasOwnProperty("activeTab")) {
            handleChange(ActiveT.activeTab);
            setActiveTab(ActiveT.activeTab);
        }
    }, [ActiveT]);

    useEffect(() => {
        setActiveTab('lotsPage')
    }, [])

    useEffect(() => {
        let checkCondition: boolean
        if (modeType === "create" || modeType === "edit") {
            checkCondition = lotId > 0 ? true : false
        } else {
            checkCondition = clonedLotId > 0 ? true : false
        }
        setShowEnabled(checkCondition)
    }, [lotId, clonedLotId])

    useEffect(() => {
        if (modeType !== null) {
            dispatch(setMode(modeType))
        }
        if (ID) {
            dispatch(setLotId(ID))
        }
    }, [modeType])

    return (
        <>
            <div className="row top_border justify-content-center">
                {loading && <div className="loader-wrap">
                    <img src={`${localStorage.getItem('globalLoader')}`} />
                </div>}
                <div className="col-xxl-10 mt-3">
                    <LotsPage cloneEnv={InvTypeText} />
                    {/* <Tabs
                        activeKey={activeTab}
                        defaultActiveKey="lotsPage"
                        className="nav-underline border-bottom"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey='lotsPage' title='Lots'></Tab>
                        <Tab eventKey='address' title='Address' disabled={!(showEnabled)}></Tab>
                        <Tab eventKey='logoPhotos' title='Logos & Photos' disabled={!(showEnabled)}></Tab>

                    </Tabs>
                    <div className="mt-3">
                        {
                            activeTab === 'lotsPage' ? (
                                <LotsPage cloneEnv={InvTypeText} />
                            ) : activeTab === 'address' ? (
                                <Address />
                            ) : activeTab == 'logoPhotos' ? (
                                <LogoPhotos />
                            ) : (
                                <LotsPage />
                            )
                        }
                    </div> */}
                </div>
            </div>
        </>
    )
}
export default Lots;