import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants'
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import { city, country, state } from "../../store/actions/CommonAction";
import axios from "axios";
import { GENERATELOTCTRLREC, GETALLFUTUREAUCTION, GETCOMMONVALUE } from "../../common/apiConstatnts";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setGeneratedLotCtrlRec, setLogPage } from "../../store/actions/Inventory";
import { errorMessages } from "../../common/messageConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const AddNewLot = () => {
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Inventory', url: routeConstant.INVENTORY },
        { label: 'New Inventory', url: '' }
    ]

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [futureAuctionData, setFutureAuctionData] = useState<any[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>("")

    const getAllFutureAuctions = () => {
        setLoading(true)
        axios.get(GETALLFUTUREAUCTION).then((response) => {
            const futureAuction = response?.data;
            setFutureAuctionData(futureAuction?.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        })
    }

    const CountryStateCity = () => {
        axios.get(`${GETCOMMONVALUE}?filter=Country&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            dispatch(country(response.data.data));
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        })

        axios.get(`${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            dispatch(state(response.data.data))
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        })

        axios.get(`${GETCOMMONVALUE}?filter=City&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            dispatch(city(response.data.data))
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        })
    }

    const handleSave = (value?: any) => {
        setLoading(true)
        const auctionId = value === null ? selectedAuction : 0
        axios.get(`${GENERATELOTCTRLREC}?auctionId=${auctionId}`).then((response) => {
            setLoading(true);
            const respData = response.data.data
            const objToDispatch = auctionId === 0 ?
                { recId: respData.recId, ctrlNo: respData.ctrlNo, auctionId: auctionId , lotNo : 0} 
                : 
                { lotNo: respData.lotNo, ctrlNo: respData.ctrlNo, auctionId: auctionId, recId: 0  }
            const getAuctionType = futureAuctionData.find((fData: any) => fData.auctionId == auctionId)?.auctionType ?? ""
            dispatch(setGeneratedLotCtrlRec(objToDispatch))
            dispatch(setLogPage({ auctionType: getAuctionType }))
            navigate(routeConstant.ADDNEWINVENTORY)
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        })
    }

    useEffect(() => {
        getAllFutureAuctions()
        CountryStateCity()
    }, []);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row">
                        <div className="col-12">
                            <div className="pt-3 mb-5 border-300">
                                <div>
                                    <div className="row bg-gradiant align-items-center justify-content-between ps-3 pe-3 pt-3 pb-3 g-2 custom_border">
                                        <div className="col-auto">
                                            <h4>New Inventory</h4>
                                        </div>
                                    </div>
                                    <div className="row top_border justify-content-center">
                                        <div className="col-xxl-10 mt-5">
                                            <div className="form-group">
                                                <label htmlFor="futureAuction">Auctions</label>
                                                <select name="futureAuction" id="futureAuction" className="form-select" onChange={(e) => setSelectedAuction(e.target.value)}>
                                                    <option value="">Select Auction</option>
                                                    {futureAuctionData?.map((aData, aIndex) => {
                                                        return (
                                                            <option value={aData.auctionId} key={aIndex}>{aData.auctionCode} - {aData.auctionTitle}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom">
                                                <div className="">
                                                    <Link className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" to={routeConstant.INVENTORY}>Cancel</Link>
                                                    <button className="btn btn-outline-primary ms-2" onClick={() => handleSave(0)} type="button" data-bs-dismiss="modal"><span>Continue without auction</span></button>
                                                    <button className="btn btn-primary ms-2" type="button" onClick={() => handleSave(null)} disabled={!(selectedAuction !== "")}>Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddNewLot;