import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setColorMode, setTheme } from '../../store/actions/ThemeAction'
import tinycolor from 'tinycolor2'
import { colorModes, themes } from '../../common/constants'

const ThemeChanger = () => {
    const dispatch = useDispatch()
    const themeData = useSelector((state: any) => state?.theme?.themeData)
    const colorMode = useSelector((state: any) => state?.theme?.colorMode)
    const [updatedTheme, setUpdatedTheme] = useState(themes)
    document.body.classList.add(colorMode)


    function setColor(property: string, value: string) {
        document.documentElement.style.setProperty(property, value, 'important');
    }

    const changeThemeColor = (themeData: any) => {
        let color = themeData.themeColor;
        dispatch(setTheme({ themeName: themeData.themeName, themeColor: color.toString() }))
        setColor('--dpa-primary', `${color}`);
        setColor('--dpa-primary-soft', tinycolor(`${color}`).lighten(10).toString());
        setColor('--dpa-primary-rgb', tinycolor(`${color}`).toRgbString().slice(4, -1));
        setColor('--dpa-navbar-vertical-link-hover-bg', tinycolor(`${color}`).setAlpha(0.12).toRgbString());
        setColor('--dpa-link-hover-color', tinycolor(`${color}`).darken(5).toString());

        setColor('--dpa-btn-primary-hover-bg', tinycolor(`${color}`).darken(5).toString());
        setColor('--dpa-btn-primary-hover-border-color', tinycolor(`${color}`).darken(10).toString());
        setColor('--dpa-btn-primary-active-bg', tinycolor(`${color}`).darken(5).toString());
    }

    const handleColorMode = () => {
        if (document.body.classList.contains("light")) {
            document.body.classList.remove("light")
            document.body.classList.add("dark")
            dispatch(setColorMode('dark'))
            setUpdatedTheme(
                themes.map(theme => ({
                    ...theme,
                    themeColor: tinycolor(theme.themeColor).isDark() ? tinycolor(theme.themeColor).brighten(25).toString() : theme.themeColor,
                }))
            )

        } else if (document.body.classList.contains("dark")) {
            document.body.classList.remove("dark")
            document.body.classList.add("light")
            dispatch(setColorMode('light'))
            setUpdatedTheme(themes)
        }
    }


    useEffect(() => {
        if (colorMode === 'dark') {
            setUpdatedTheme(
                themes.map(theme => ({
                    ...theme,
                    themeColor: tinycolor(theme.themeColor).isDark() ? tinycolor(theme.themeColor).brighten(25).toString() : theme.themeColor,
                }))
            )
        }
    }, [])

    useEffect(() => {
        dispatch(setTheme({
            themeName: themeData.themeName,
            themeColor: updatedTheme[themes.findIndex(
                theme => theme.themeName === themeData.themeName
            )].themeColor
        }
        ))
    }, [updatedTheme])


    useEffect(() => {
        changeThemeColor(themeData)
    }, [themeData.themeName, themeData.themeColor])

    // useEffect(() => {
    //     document.body.classList.add(colorMode)
    // }, [colorMode])

    return (
        <Dropdown className='custom-dropdown'>
            <Dropdown.Toggle className="px-1 dropdown-caret-none" variant='none'>
                <i title="Change Theme"> 
                    <span className='menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0'>
                        <i className='fas fa-cog'></i>
                    </span>
                </i>
            </Dropdown.Toggle>
            <Dropdown.Menu variant='none' className='p-0 dropdown-menu-end navbar-dropdown-caret shadow border border-300'>
                <div className="card position-relative border-0">
                    <Dropdown.Header className='border-bottom'>Select Theme</Dropdown.Header>
                    <div className='theme-switch-wrapper'>
                        <div className="theme-switcher">
                            {
                                colorModes?.map((item, index) => {
                                    return (
                                        <React.Fragment key={index} >
                                            <input type="radio"
                                                id={item.mode}
                                                name="color-mode"
                                                defaultChecked={item.mode === colorMode}
                                                onChange={handleColorMode}
                                            />
                                            <label htmlFor={item.mode}>
                                                <span className='text-capitalize'>
                                                    <i data-feather="sun"></i>{item.mode}
                                                </span>
                                            </label>
                                        </React.Fragment>
                                    )
                                })
                            }
                            <span className="slider"></span>
                        </div>
                    </div>
                    <div className='color-block-wrapper'>
                        {
                            updatedTheme?.map((theme, index) => {
                                return (
                                    <div key={index}>
                                        <input
                                            id={theme.themeName}
                                            type="radio"
                                            name="theme-colors"
                                            onChange={() => changeThemeColor(theme)}
                                            className='d-none'
                                            checked={theme.themeName === themeData.themeName}
                                        />
                                        <label htmlFor={theme.themeName}
                                            className='color-block'
                                            style={{ backgroundColor: theme.themeColor }}
                                        >
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ThemeChanger