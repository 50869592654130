import React from 'react'
import GooglePlacesAutocomplete from 'react-google-autocomplete';

const GooglePlaces = ({ value, handleChangeAddressLine, type, onPlaceSelected, name }: any) => {
    return (
        <>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyCctFdaMeJboFN7ryzo3eApSZdZLakSUd4"
                value={value}
                onChange={handleChangeAddressLine}
                className="form-control"
                name={name}
                onLoadFailed={(error: any) => console.error('Error loading Google Places Autocomplete: ', error)}
                onPlaceSelected={onPlaceSelected}
                options={{
                    types: ['geocode', 'establishment'],
                }}
            />
            {/* {
                remainingCountAdd2 && remainingCountAdd2 !== 250 && (
                    <div className={remainingCountAdd2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                        {remainingCountAdd2 <= 0 ? 'Maximum character limit reached' : `Remaining characters: ${remainingCountAdd2}`}
                    </div>
                )
            } */}
        </>
    )
}

export default GooglePlaces