import { yupResolver } from "@hookform/resolvers/yup"
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { decryptId, encryptId } from '../../../Encryption/Encryption'
import { ERROR_MESSAGES, handleKeyDown, removeHtmlTags } from '../../../Utils/Utils'
import defaultImg from "../../../assets/img/dpa_banner.png"
import { CHECKINVOICEREVIEW, CHECKVOIDINVOICE, CREATESALESRECORDINFO, GETALLACTIVECUSTOMER, GETAUCTIONSELLERMAPPING, GETBIDDERSBYCUSTOMERID, GETBLOBPHOTOSBYLOTID, GETBYSALESRECORDID, GETCUSTOMERBYAUCTIONID, GETCUSTOMERBYID, GETINVOICERECORDBYSALESRECORD, GETREGTOBIDCUSTOMERS, UPDATELOTSTATUS, UPDATESALESRECORD } from '../../../common/apiConstatnts'
import { auctionType, formatPhoneNumber, lotStatus, wordCounter } from '../../../common/constants'
import { Breadcrumb } from "../../../common/interface"
import { CommonMessages, SalesRecMessages, errorMessages } from '../../../common/messageConstants'
import { AlPHANUMERIC, INTEGER, INTEGER_MESSAGE, SPECIALCHARS } from '../../../common/regexConstants'
import * as routeConstant from '../../../common/routeConstants'
import { setAdjBidsFromResp, shippingData } from '../../../store/actions/SalesRecordAction'
import InnerHeader from '../../InnerHeader/InnerHeader'
import LastModified from '../../LastModifiedDateTime/LastModifiedDateTime'
import ModifiedBy from '../../LastModifiedDateTime/ModifiedBy'
import Sidebar from '../../Sidebar/Sidebar'
import Commission from './Tabs/Commission'
import Invoice from './Tabs/Invoice'
import handleError from "../../../Utils/ApiCommonErrorMessages"
import CommonHelmet from "../../../common/HelmetWrapper"

const MoreSalesInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const existsData = location.state;

  useEffect(() => {
    reset(existsData)
  }, [])

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  };

  const maxWordCount = 250
  const [remainingCount, setRemainingCount] = useState(maxWordCount)
  const [customerOption, setCustomerOption] = useState<any>([])
  const [customerChanged, setCustomerChanged] = useState<any>(false)

  const query = window.location.href;
  const vars = query.split("/");
  const ID = vars[5];
  const Id = decryptId(ID);
  const breadcrumbData: Breadcrumb[] = [
    { label: 'Sales Records', url: routeConstant.SALESRECORDS },
    { label: ID ? 'Update Sales Record' : 'New Sales Record', url: '' }
  ]
  const taxAttemptType = [
    { label: 'Yes', value: true, selected: false },
    { label: 'No', value: false, selected: true }
  ]
  const companyId = localStorage.getItem('companyId')
  const initialData = {
    salesRecordId: 0,
    companyId: companyId,
    lotNumber: "",
    lotId: '',
    lotTitle: "",
    price: "",
    bidderNumber: "",
    quantity: "",
    auctionType: "",
    sellerNumber: "",
    ctrlNumber: "",
    sold: "",
    lotDescription: "",
    // ring: "",
    status: true,
    bidDown: true,
    taxAttempt: true,
    taxRate: "",
    taxAmount: "",
    reserve: "",
    start: "",
    left: "",
    percentBid: "",
    surcharge: "",
    inventoryAdjust: "",
    // internetFee: "",
    buyersPremium: "",
    auctionId: 0,
    titleAs: "",
    isSend: true,
    isReceived: true,
    receivedComment: "",
    sendComment: "",
    isActive: true,
    lotStatus: 0,
    customerId: 0,
    sellerId: 0,
    bpScaleId: 0,
    categoryId: 0,
    isDelete: false,
    salesRecordCommissionId: 25,
    salesPersonBuyBackId: 0,
    salesPersonNonPayerId: 0,
    salesPersonSoldId: 0,
    salesPersonUnSoldId: 0,
    sellerBuyBackId: 0,
    sellerNonPayerId: 0,
    sellerSoldId: 0,
    sellerUnSoldId: 0,
    salesPersonCalculatedCommission: 0,
    salesPersonReportedCommission: 0,
    sellerCalculatedCommission: 0,
    sellerReportedCommission: 0,

  }
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [initialFormValue, setFormValue] = useState<any>(initialData);
  const [modifiedBy, setModifiedBy] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customer, setCustomer] = useState({})
  const [lotImage, setLotImage] = useState('')
  const [bidderList, setBidderList] = useState<any>([]);
  const [sellerList, setSellerList] = useState<any>([]);
  const [customerData, setCustomerData]: any = useState({})
  // Display seller dropdown if true
  const [displaySeller, setDisplaySeller] = useState(false)
  const AbsBidState = useSelector((state: any) => state.salesRecord.absBids);
  const shippingState: any = useSelector((state: any) => state.salesRecord.shipping)
  // Schema
  const schema: any = yup.object().shape({
    ctrlNumber: yup.number().typeError('Ctrl Number must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      })
      .required('Ctrl Number is required.'),
    // taxAttempt: yup.boolean(),
    taxRate: yup.string().required('Tax Rate is required.').matches(/^\d+(\.\d*)?$/, "Please enter Valid number.")
      .test('less-than-100', 'please enter number upto 99', (value: any) => parseFloat(value) < 100),
    taxAmount: yup.string().typeError('Tax Amount must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      })
      .required('Tax Amount is required.').matches(/^\d+(\.\d*)?$/, "Please enter Valid number.")
      .test("max-9-digits", "Maximum 9 digits allowed", (value) => {
        return value ? value.length <= 9 : true;
      }),
    percentBid: yup.string().required('Percent Bid is required.').matches(/^\d+(\.\d*)?$/, "Please enter valid number.")
      .test('less-than-100', 'please enter number upto 99', (value: any) => parseFloat(value) < 100),
    inventoryAdjust: yup.number().typeError('Inventory Adjust must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      })
      .required('Inventory Adjust is required.'),
    buyersPremium: yup.string().typeError('Buyers Premium must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      })
      .required('buyersPremium is required.').test("max-9-digits", "Maximum 9 digits allowed", (value) => {
        return value ? value.length <= 9 : true;
      }),
    // ring: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    // }),
    status: yup.boolean(),
    bidDown: yup.boolean(),
    lotDescription: yup.string().required("Lot Description is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
    // lotNumber: yup.number().typeError('Lot Number  must be a number.')
    //     .transform((value, originalValue) => {
    //         return (originalValue === '') ? undefined : value
    //     }).required("Lot Number is required.").moreThan(0, 'Lot Number must be greater than 0.'),
    lotId: yup.number().typeError('Lot Id  must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }).required("Lot ID is required.").moreThan(0, 'Lot Id must be greater than 0.'),
    lotTitle: yup.string().required("Lot Title is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
    price: yup
      .string()
      .required("Price is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(/^\d+(\.\d*)?$/, "Only digits are allowed")
      .test("max-9-digits", "Maximum 9 digits allowed", (value) => {
        return value ? value.length <= 9 : true;
      }),
    bidderNumber: yup.string().required("Bidder Number is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).max(9, "Please enter 9 characters."),
    quantity: yup.number().typeError('Quantity must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }).required("Quantity is required.").moreThan(0, 'Quantity must be greater than 0.').max(9, "Please enter 9 characters."),
    // auctionType: yup.string().required("Please select auction type."),
    titleAs: yup.string().nullable()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(SPECIALCHARS, 'Please enter valid title').max(50, "Please enter upto 50 characters only"),
    receivedComment: yup.string().nullable()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(SPECIALCHARS, 'Please enter valid received comment').max(20, "Please enter upto 50 characters only"),
    sendComment: yup.string().nullable()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid send comment.").max(20, "Please enter upto 50 characters only"),
    // sellerNumber: yup.number().typeError('Seller Number be a number.')
    //     .transform((value, originalValue) => {
    //         return (originalValue === '') ? undefined : value
    //     }).required("Seller Number  is required."),
    sellerReportedCommission: yup.number().nullable().typeError('Commission must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }),
    sellerCalculatedCommission: yup.number().nullable().typeError('Commission must be a number')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }),
    salesPersonCalculatedCommission: yup.number().nullable().typeError('Commission must be a number')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }),
    salesPersonReportedCommission: yup.number().nullable().typeError('Commission must be a number')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      })
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    formState: { errors, isValid }
  } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' });

  const salesData = getValues()
  const price = watch('price')
  const auctionTypeValue = watch('auctionType')
  const quantity = watch('quantity')
  const taxAmount = watch('taxAmount')
  const taxRate = watch('taxRate')
  // const internetFee = watch('internetFee')
  const watchLotStatus = watch('lotStatus')
  const bidderNumber = watch('bidderNumber')
  const customerId = watch('customerId')
  const sellerId = watch('sellerId')
  const auctionId = watch('auctionId')
  const lotId = watch('lotId')
  const taxAttempt = watch('taxAttempt')
  const customerName = watch('customerName')
  const invoiceNumber = watch('invoiceNumber')
  const invoiceId = watch('invoiceId')
  const buyersPremium = watch('buyersPremium')
  const inventoryAdjust = watch('inventoryAdjust')
  const left = watch('left')
  const sold = watch('sold')
  const start = watch('start')
  const reserve = watch('reserve')
  const sellerName = sellerList?.find((item: any) => item.sellerId == sellerId)

  const handleLotStatusChange = (value: any) => {
    // if click yes in popup fire API and change lot status in inventory
    Swal.fire({
      title: "Do you really want to change lot status in inventory?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setValue("lotStatus", value);
        // If status is buy back then only seller dropdown display and call get all seller API
        if (value == 3) {
          setDisplaySeller(true)
          getAllSellers(auctionId);
        } else {
          setDisplaySeller(false)
        }
        axios.get(`${UPDATELOTSTATUS}?lotId=${lotId}&InventoryStatus=${value}`).then((response) => {
          if (response?.data?.success == 'Success') {
            toast.success(SalesRecMessages.LotStatusUpdated, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
      }
    });
  }

  const onSubmit = (data: any) => {
    setLoading(true);
    const salesData = {
      salesRecordId: data.salesRecordId === undefined ? 0 : data.salesRecordId,
      companyId: companyId,
      lotNumber: `${data.lotNumber}`,
      lotId: data.lotId,
      lotTitle: data.lotTitle,
      price: parseInt(data.price),
      bidderNumber: data.bidderNumber,
      quantity: data.quantity,
      auctionType: parseInt(data.auctionType),
      sellerNumber: 0,
      ctrlNumber: data.ctrlNumber,
      sold: data.sold,
      lotDescription: data.lotDescription,
      // ring: data.ring,
      status: data.status,
      bidDown: data.bidDown,
      taxAttempt: JSON.parse(data.taxAttempt),
      taxRate: data.taxRate,
      taxAmount: data.taxAmount,
      reserve: data.reserve,
      start: data.start,
      left: data.left,
      percentBid: data.percentBid,
      surcharge: null,
      inventoryAdjust: data.inventoryAdjust,
      // internetFee: data.internetFee,
      buyersPremium: data.buyersPremium,
      lotStatus: data.lotStatus ?? 0,
      customerId: data.customerId ?? 0,
      sellerId: data.sellerId ?? 0,
      extendedPrize: 0,
      isActive: data.isActive,
      auctionId: data.auctionId,
      salesRecordCommissionInformationReqModel: {
        salesRecordCommissionId: data?.salesRecordCommissionId,
        salesRecordId: data?.salesRecordId,
        bpScaleId: data?.bpScaleId,
        categoryId: data?.categoryId,
        sellerSoldId: data?.sellerSoldId,
        sellerUnSoldId: data?.sellerUnSoldId,
        sellerBuyBackId: data?.sellerBuyBackId,
        sellerNonPayerId: data?.sellerNonPayerId,
        sellerCalculatedCommission: data?.sellerCalculatedCommission,
        sellerReportedCommission: data?.sellerReportedCommission,
        salesPersonSoldId: data?.salesPersonSoldId,
        salesPersonUnSoldId: data?.salesPersonUnSoldId,
        salesPersonBuyBackId: data?.salesPersonBuyBackId,
        salesPersonNonPayerId: data?.salesPersonNonPayerId,
        salesPersonCalculatedCommission: data?.salesPersonCalculatedCommission,
        salesPersonReportedCommission: data?.salesPersonReportedCommission,
        isActive: data?.isActive
      },
      lstSalesRecordAbsenteeBids: AbsBidState?.map((lastData: any) => {

        if (lastData?.salesRecordAbsenteeBidId === undefined) {
          return {
            ...lastData,
            salesRecordAbsenteeBidId: 0,
            salesRecordId: 0,
            companyId: companyId,
            isActive: true,
            isDelete: false
          }
        }
        return {
          ...lastData,
        }
      }),
      salesRecordShippingReqModel: {
        salesRecordShippingId: data.salesRecordShippingId === undefined ? 0 : data.salesRecordShippingId,
        salesRecordId: data.salesRecordId === undefined ? 0 : data.salesRecordId,
        companyId: companyId,
        tracking: shippingState.tracking,
        box: shippingState.box,
        shipper: shippingState.shipper,
        pickUpOnly: shippingState.pickUpOnly,
        weight: shippingState.weight,
        pkgType: shippingState.pkgType,
        returnedToSeller: shippingState.returnedToSeller,
        units: shippingState.units,
        service: shippingState.service,
        shipping: shippingState.shipping,
        length: shippingState.length,
        driver: shippingState.driver,
        packing: shippingState.packing,
        height: shippingState.height,
        licence: shippingState.licence,
        miscChg: shippingState.miscChg,
        depth: shippingState.depth,
        place: shippingState.place,
        total: shippingState.total,
        girth: shippingState.girth,
        notes: shippingState.notes,
        isActive: true
      },
      "lstSalesRecordInvoice": [
        {
          "salesRecordInvoice": 0,
          "salesRecordId": 0,
          "companyId": 0,
          "lotNumber": 0,
          "isActive": true
        }
      ]

    }


    if (ID === undefined) {
      axios.post(CREATESALESRECORDINFO, salesData, { ...config, 'ContentType': 'application/json' })
        .then((response) => {
          if (response?.data?.success === "Fail") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          }
          else {
            toast.success(SalesRecMessages.salesRecCreated, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            })
            navigate(routeConstant.SALESRECORDS);
            reset(initialData);
          }
          setLoading(false)
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      axios.post(UPDATESALESRECORD, salesData, { ...config, 'ContentType': 'application/json' })
        .then((response) => {
          if (response?.data?.success === "Fail") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          }
          else {
            toast.success(SalesRecMessages.salesRecUpdated, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            })
          }
          navigate(routeConstant.SALESRECORDS)
          reset(initialData)
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  }

  const getAllCustomers = () => {
    axios.get(`${GETCUSTOMERBYAUCTIONID}?auctionId=${auctionId}`, config).then(
      (response) => {
        if (response?.data?.success === "Fail") {
          // toast.error(response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          return;
        }
        const data = response?.data?.data
        const customerData = data?.map((lData: any) => {
          return {
            label: `${lData.customerId} - ${lData.firstName} ${lData.lastName}`,
            value: lData.customerId,
          };
        });
        setCustomerOption(customerData)
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  }

  // get all bidder number
  const getBidderNumbers = (customerId: number) => {
    setLoading(true);
    axios
      .get(`${GETBIDDERSBYCUSTOMERID}?customerId=${customerId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success === "Fail") {
          Swal.fire({
            title: "No Bidder # available for the selected buyer.",
            text: "Please select another auction.",
            icon: 'warning',
            confirmButtonColor: '#006800',
            confirmButtonText: 'Ok'
          })
          setBidderList([]);
        } else {
          const biddersData: any = response.data.data;
          const bidderData = biddersData?.map((lData: any) => {
            return { label: `${lData.bidderNumber}`, value: lData.bidderNumber };
          });
          setBidderList([{ label: 'Select', value: '' }, ...bidderData]);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const getAllSellers = (auctionId: any) => {
    setLoading(true);
    const pageNumber = -1;
    axios.get(`${GETAUCTIONSELLERMAPPING}?auctionId=${auctionId}&pageNumber=${pageNumber}`, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.data?.success === "Fail") {
          setSellerList([]);

          return;
        } else {
          const sellers = response?.data?.data?.data;
          const sellerData = sellers?.map((lData: any) => {
            return {
              value: lData.sellerId,
              label: `${lData.sellerId} - ${lData.firstName} ${lData.lastName}`
            };
          })
          setSellerList(sellerData);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  // If customer change and bidder number change then call API to check if invoice is exist & reviews
  const handleCustomerChange = (e: any) => {
    const selectedCustomerId = e?.value;
    setSelectedCustomer(selectedCustomerId);
    setCustomerChanged(true)
    getBidderNumbers(selectedCustomerId);
    setValue("bidderNumber", "");
  };

  const handleBidderNumberChange = (value: any) => {
    if (!value) return
    axios.get(`${CHECKINVOICEREVIEW}?customerId=${customerId}&bidderNumber=${value}&lotId=${lotId}`).then((response) => {
      if (response?.data?.success == 'Success') {
        Swal.fire({
          title: "This buyer has invoice and reviewed !",
          text: "Do you really want to change buyer ? ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            axios.get(`${CHECKVOIDINVOICE}?customerId=${customerId}&bidderNumber=${value}&lotId=${lotId}`).then((response) => {
            }).catch((error) => {
              setLoading(false);
              handleError(error)
            })
          } else {
            // setSelectedCustomer('');
            // setBidderList([])
            // setCustomer({});
            setValue("bidderNumber", bidderNumber);
            // setValue("customerId", '');
            // toast.error(
            //   'Please select another customer !!',
            //   {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 2000,
            //   }
            // );
          }
        });
      } else {
        toast.error('Invoice not attached with selected bidder number!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }

  useEffect(() => {
    if (ID !== undefined) {
      axios.get(GETBYSALESRECORDID, { ...config, params: { salesRecordId: decryptId(ID) } }).then((response: any) => {
        setLoading(true)
        if (response?.data?.success === "Fail") {
          setLoading(false);
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        else {
          setLoading(false);
          const data = response.data.data;
          const shippingD = data?.salesRecordShipping;
          const absBidsData = data?.lstSalesRecordAbsenteeBids;
          setModifiedBy(response.data.data.modifiedBy);
          const newData = { ...data, ...shippingD, ...absBidsData }
          dispatch(shippingData(shippingD));
          dispatch(setAdjBidsFromResp(data.lstSalesRecordAbsenteeBids.map((absdata: any) => { return { ...absdata, absBidsUniqueId: uuidv4() } })));
          setFormValue(data);
          const title = removeHtmlTags(data.lotTitle)
          const description = removeHtmlTags(data.lotDescription)
          reset({
            ...data,
            lotTitle: title,
            lotDescription: description,
            percentBid: Number(data?.percentBid)?.toFixed(2),
            taxRate: Number(data?.taxRate)?.toFixed(2),
            price: Number(data?.price)?.toFixed(2),
            reserve: Number(data?.reserve)?.toFixed(2),
            start: Number(data?.start)?.toFixed(2),
            sold: Number(data?.sold)?.toFixed(2),
            left: Number(data?.left)?.toFixed(2),
            inventoryAdjust: Number(data?.inventoryAdjust)?.toFixed(2),
            buyersPremium: Number(data?.buyersPremium)?.toFixed(2),
            taxAmount: Number(data?.taxAmount)?.toFixed(2),
            taxAttempt: data.taxAttempt,
            salesRecordCommissionId: data?.salesRecordCommissionId,
            salesRecordId: data?.salesRecordId,
            bpScaleId: data?.salesRecordCommisson?.bpScaleId,
            categoryId: data?.salesRecordCommisson?.categoryId,
            sellerSoldId: data?.salesRecordCommisson?.sellerSoldId,
            sellerUnSoldId: data?.salesRecordCommisson?.sellerUnSoldId,
            sellerBuyBackId: data?.salesRecordCommisson?.sellerBuyBackId,
            sellerNonPayerId: data?.salesRecordCommisson?.sellerNonPayerId,
            sellerCalculatedCommission: Number(data?.salesRecordCommisson?.sellerCalculatedCommission)?.toFixed(2),
            sellerReportedCommission: Number(data?.salesRecordCommisson?.sellerReportedCommission)?.toFixed(2),
            salesPersonSoldId: data?.salesRecordCommisson?.salesPersonSoldId,
            salesPersonUnSoldId: data?.salesRecordCommisson?.salesPersonUnSoldId,
            salesPersonBuyBackId: data?.salesRecordCommisson?.salesPersonBuyBackId,
            salesPersonNonPayerId: data?.salesRecordCommisson?.salesPersonNonPayerId,
            salesPersonCalculatedCommission: data?.salesRecordCommisson?.salesPersonCalculatedCommission,
            salesPersonReportedCommission: data?.salesRecordCommisson?.salesPersonReportedCommission,
            isActive: data?.isActive

          });
          // for dropdown valid format
          setCustomer({ value: data?.customerId, label: data?.customerName });
          data?.customerId && getBidderNumbers(data?.customerId)
          setValue("customerId", data?.customerId)
          setSelectedCustomer(data?.customerId)

          data?.customerId && axios.get(GETCUSTOMERBYID, { ...config, params: { customerId: data?.customerId } }).then((response: any) => {
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            }
            else {
              const data = response?.data.data
              setCustomerData(data)
            }
          })

          if (data.lotStatus == 3) {
            setDisplaySeller(true)
            getAllSellers(data?.auctionId);
            setValue('sellerId', data?.sellerId)
          }
          if (data?.lotId) {
            axios.get(`${GETBLOBPHOTOSBYLOTID}?lotId=${data?.lotId}`, config)
              .then((response) => {
                if (response?.data?.success === "Fail") {
                  setLoading(false)
                  return;
                }
                else {
                  const imgData = response.data.data.find((item: any) => item.inventoryOrDefaults == 1);

                  setLotImage(`${imgData.photos}?${imgData.photosWithSasToken}`)
                }
              })
          }
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
    }
  }, [])

  const handleRadio = (value: any) => {
    setFormValue({ ...initialFormValue, taxAttempt: value });
    if (!value) {
      setValue('taxRate', '0.00');
    }
    setValue('exempt', value);
    clearErrors('taxRate');
  }

  useEffect(() => {
    if (auctionId) {
      getAllCustomers()
      setLoading(true)
    }
  }, [auctionId])

  useEffect(() => {
    if (customerChanged) {
      setTimeout(() => setCustomerChanged(false), 50)
    }
  }, [customerId])

  // When click on confirm button it will redirect to create invoice 
  const handleInvoiceConfirm = () => {
    // API call here
    setLoading(true);
    axios.get(`${GETINVOICERECORDBYSALESRECORD}?strLot=${lotId}&AuctionId=${auctionId}&BidderNo=${bidderNumber}`).then((response: any) => {
      setLoading(false);
      const data = response?.data?.data;

      if (data) {
        navigate(routeConstant.CREATEINVOICE, {
          state: {
            auction: {
              "label": `${data?.auctionCode} - ${data?.auctionTitle}`,
              "value": data?.auctionId
            }, customer: {
              "label": `${data?.customerId} - ${data?.customerName}`,
              "value": data?.customerId
            },
            bidder: {
              "label": bidderNumber,
              "value": parseInt(bidderNumber)
            },
            lot: {
              "label": `${data?.lotNo} - ${removeHtmlTags(data?.lotTitle)}`,
              "value": data?.lotId
            },
            lotData: data?.invoiceDetail?.map((lData: any) => {
              return {
                label: `${lData.lotNo} - ${removeHtmlTags(lData.lotTitle)}`,
                value: lData.lotId,
              };
            }),
          },
        });
      }

    }).catch((error) => {
      setLoading(false);
    });

  }

  return (
    <>
      <CommonHelmet title={"Sales - "} name={"sales"} content={"Sales Record"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <div className="">
          <InnerHeader
            breadCrumb={breadcrumbData}
            title={ID ? "Update Sales Record" : "New Sales Record"}
          ></InnerHeader>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid ">
            <div className="row justify-content-center top_border">
              <div className="col-xxl-10">
                <div className="row g-3 mt-3">
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label">
                      Lot #
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lot No"
                      {...register("lotNumber")}
                      disabled={Id}
                    />
                    {errors.lotNumber && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.lotNumber.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label">
                      Lot Id
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lot Id"
                      {...register("lotId")}
                      disabled={Id}
                    />
                    {errors.lotId && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.lotId.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label"> Auction Id </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title As"
                      {...register("auctionId")}
                      disabled={Id}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label">
                      Ctrl No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Ctrl No"
                      {...register("ctrlNumber")}
                      disabled={Id}
                    />
                    {errors.ctrlNumber && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.ctrlNumber.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <label className="form-label">
                      Title
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Lot Title"
                      {...register("lotTitle")}
                    />
                    {errors.lotTitle && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.lotTitle.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <label className="form-label">
                      Lot Description
                    </label>
                    <textarea
                      disabled
                      className="form-control form-icon-input"
                      placeholder="Lot Description"
                      rows={4}
                      maxLength={maxWordCount}
                      {...register("lotDescription", {
                        onChange: (e) =>
                          setRemainingCount(
                            wordCounter(e.target.value, maxWordCount)
                          ),
                      })}
                    />
                    {remainingCount != maxWordCount && (
                      <div
                        className={remainingCount <= 0 ? "text-danger" : ""}
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          width: "100%",
                          fontSize: "12px",
                        }}
                      >
                        {remainingCount <= 0
                          ? "Maximum word limit reached"
                          : `Remaining words: ${remainingCount}`}
                      </div>
                    )}
                    {errors.lotDescription && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.lotDescription.message}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-lg-3 col-md-3">
                      <label className="form-label"> Ring </label>
                      <input
                        type="text"
                        maxLength={6}
                        className="form-control"
                        placeholder="Ring"
                        {...register("ring")}
                      />
                      {errors.ring && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.ring.message}
                        </span>
                      )}
                    </div> */}
                  <div className="col-md-3 col-md-3">
                    <label className="form-label">
                      Auction Type
                    </label>
                    <div className="code-to-copy">
                      <Select
                        isDisabled
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        options={auctionType}
                        {...register("auctionType")}
                        value={auctionType?.find(
                          (option: any) => option?.value == auctionTypeValue
                        )}
                        onChange={(e: any) => {
                          setValue("auctionType", e.value);
                        }}
                        className="isActive"
                      />
                    </div>
                    {errors.auctionType && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.auctionType.message}
                      </span>
                    )}
                  </div>
                  {/* Get customer and */}
                  <div className="col-md-3 col-md-3">
                    <label className="form-label">Buyer Name <sup className="text-danger">*</sup></label>
                    <Select
                      classNamePrefix="dpaSelect"
                      placeholder="Select Buyer"
                      options={customerOption}
                      {...register("categoryId")}
                      value={customerOption?.find(
                        (option: any) => option?.value == customerId
                      )}
                      onChange={(e: any) => {
                        if (e.value == customerId) return;
                        handleCustomerChange(e);
                        setCustomer(e);
                        setValue("customerId", e?.value);
                      }}
                      className="isActive"
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 mb-3">
                    <label className="form-label">
                      Bidder #
                      {bidderList?.length > 0 ? (
                        <sup className="text-danger">* </sup>
                      ) : (
                        ""
                      )}
                    </label>
                    {!customerChanged && bidderList?.length > 0 ? (
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive swap-to-lot-menu clone-inventory"
                        {...register("bidderNumber")}
                        name="bidderNumber"
                        value={bidderList?.find(
                          (option: any) => option?.value == bidderNumber
                        )}
                        options={bidderList}
                        onChange={(e: any) => {
                          handleBidderNumberChange(e.value);
                          setValue("bidderNumber", e.value);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder={
                          !selectedCustomer
                            ? "Select a Buyer first"
                            : "No Bidder number exist"
                        }
                        disabled
                      />
                    )}

                    {errors.bidderNumber && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.bidderNumber.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 col-md-3">
                    <div className="row g-3">
                      <div className="col-md-12">
                        <label className="form-label"> Tax Exempt </label>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-md-4">
                        <div className="form-check">
                          <input
                            type="radio"
                            value="true"
                            {...register("taxAttempt")}
                            checked={initialFormValue.taxAttempt === true}
                            className="form-check-input"
                            onChange={() => handleRadio(true)}
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-check">
                          <input
                            type="radio"
                            value="false"
                            {...register("taxAttempt")}
                            checked={initialFormValue.taxAttempt === false}
                            className="form-check-input"
                            onChange={() => handleRadio(false)}
                          />
                          <label className="form-check-label"> No </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-md-3">
                    <label className="form-label"> Tax Rate (%){initialFormValue.taxAttempt === true && <sup className="text-danger">* </sup>}</label>
                    <input
                      {...register('taxRate')}
                      type='text'
                      disabled={initialFormValue.taxAttempt === false}
                      className="form-control"
                      maxLength={5}
                      onKeyDown={handleKeyDown}
                    />
                    {errors.taxRate && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.taxRate.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 col-md-3">
                    <label className="form-label">
                      Price ($)<sup className="text-danger">* </sup>
                    </label>
                    <CurrencyInput
                      placeholder="Price"
                      {...register("price")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={price}
                      onValueChange={(value: any) => setValue("price", value)}
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.price && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.price.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <label className="form-label">
                      Quantity <sup className="text-danger">* </sup>
                    </label>
                    <CurrencyInput
                      type="text"
                      placeholder="Quantity"
                      {...register("quantity")}
                      allowDecimals={false}
                      decimalsLimit={10}
                      onKeyDown={handleKeyDown}
                      value={quantity}
                      onValueChange={(value: any) =>
                        setValue("quantity", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.quantity && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <label className="form-label"> Reserve ($)</label>

                    <CurrencyInput
                      type="text"
                      disabled
                      placeholder="Reserve"
                      {...register("reserve")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={reserve}
                      onValueChange={(value: any) =>
                        setValue("reserve", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.reserve && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.reserve.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <label className="form-label"> Start ($)</label>

                    <CurrencyInput
                      disabled
                      type="text"
                      placeholder="Start"
                      {...register("start")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={start}
                      onValueChange={(value: any) =>
                        setValue("start", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.start && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.start.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 col-md-3">
                    <label className="form-label"> Sold ($)</label>

                    <CurrencyInput
                      disabled
                      type="text"
                      placeholder="Sold"
                      {...register("sold")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={sold}
                      onValueChange={(value: any) =>
                        setValue("sold", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.sold && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.sold.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <label className="form-label"> Left ($)</label>

                    <CurrencyInput
                      disabled
                      type="text"
                      placeholder="Left"
                      {...register("left")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={left}
                      onValueChange={(value: any) =>
                        setValue("left", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.left && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.left.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label"> Percent Bid (%)<sup className="text-danger">* </sup></label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={5}
                      placeholder="Percent Bid "
                      {...register("percentBid")}
                      onKeyDown={handleKeyDown}
                    />
                    {errors.percentBid && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.percentBid.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label"> Inventory Adjust ($)<sup className="text-danger">* </sup></label>

                    <CurrencyInput
                      type="text"
                      placeholder="Inventory Adj"
                      {...register("inventoryAdjust")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={inventoryAdjust}
                      onValueChange={(value: any) =>
                        setValue("inventoryAdjust", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.inventoryAdjust && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.inventoryAdjust.message}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-lg-3 col-md-4">
                      <label className="form-label"> Internet Fee <sup className="text-danger">* </sup></label>
                      <CurrencyInput
                        type="text"
                        placeholder="Internet Fee"
                        prefix="$"
                        {...register("internetFee")}
                        allowDecimals={false}
                        decimalsLimit={10}
                        onKeyDown={handleKeyDown}
                        value={internetFee}
                        onValueChange={(value: any) =>
                          setValue("internetFee", value)
                        }
                        className="form-control form-icon-input"
                      />
                      {errors.internetFee && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.internetFee.message}
                        </span>
                      )}
                    </div> */}
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label"> Buyers Premium ($)<sup className="text-danger">* </sup></label>

                    <CurrencyInput
                      type="text"
                      placeholder="Buyers Premium"
                      {...register("buyersPremium")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={buyersPremium}
                      onValueChange={(value: any) =>
                        setValue("buyersPremium", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.buyersPremium && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.buyersPremium.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <label className="form-label"> Tax Amount ($)<sup className="text-danger">* </sup></label>
                    <CurrencyInput
                      type="text"
                      placeholder="Tax Amount"
                      {...register("taxAmount")}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                      value={taxAmount}
                      onValueChange={(value: any) =>
                        setValue("taxAmount", value)
                      }
                      className="form-control form-icon-input"
                      required
                    />
                    {errors.taxAmount && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.taxAmount.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-3 col-md-3">
                    <label className="form-label"> Lot Status <sup className="text-danger">* </sup></label>
                    <div className="code-to-copy">
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        options={lotStatus}
                        {...register("lotStatus")}
                        value={lotStatus?.find(
                          (option: any) => option?.value == watchLotStatus
                        )}
                        onChange={(e: any) => {
                          handleLotStatusChange(e.value);
                        }}
                        className="isActive"
                      />
                    </div>
                  </div>
                  {displaySeller && (
                    <div className="col-lg-3 col-md-3">
                      <label className="form-label">Seller</label>
                      <Select
                        aria-label="Default select example"
                        options={sellerList}
                        name="seller"
                        noOptionsMessage={() => "No record found"}
                        value={sellerList?.find(
                          (option: any) => option?.value == sellerId
                        )}
                        onChange={(selectedOption: any) => {
                          setValue("sellerId", selectedOption?.value);
                        }}
                        onMenuOpen={() => {
                          if (sellerList?.length === 0) {
                            Swal.fire({
                              icon: "warning",
                              title: "Oops!, No sellers found",
                              text: "Please select sellers from this auction first ",
                              confirmButtonColor: "#006800",
                              confirmButtonText: "ok",
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="col-lg-3 col-md-3">
                    {ID && (
                      <div className="">
                        <label>Status </label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            {...register("isActive")}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Active
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Read only info for Bidder, Seller and Invoice */}
                <div className="row g-3 mt-3">
                  <div className="col-lg-6 col-md-6">
                    <div className="seller_invoice_img border p-3 border-radius">
                      <img
                        src={lotImage ? lotImage : defaultImg}
                        alt="Lot Image"
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-2">
                    <div className="row g-3">
                      {
                        customerData?.firstName ?
                          <div className="col-lg-6">
                            <div className='card h-100 border bg-light p-3 pe-5'>
                              <Link
                                target="_blank"
                                to={`${routeConstant.CUSTOMERSMOREINFO
                                  }/${encryptId(
                                    customerData?.customerId
                                  )}`}
                              >
                                <i className='edit_card_box' title='Edit Buyer'>
                                  <i className='fa fa-pencil'></i>
                                </i>
                              </Link>
                              <p className="m-0 fs-14">
                                Bidder # :
                                <span className="fw-bold">{" "}{bidderNumber}</span>
                              </p>
                              <p className="m-0 fs-14">
                                Buyer Name :
                                <span className="fw-bold">
                                  {" "}{customerData?.firstName}{" "}{customerData?.lastName}
                                </span>
                              </p>
                              <p className="m-0 fs-14">
                                Email :
                                <span className="fw-bold">{" "}{customerData?.userName} </span>
                              </p>
                              <p className="m-0 fs-14">
                                Phone :
                                <span className="fw-bold">
                                  {" "}{customerData?.mobile
                                    ? formatPhoneNumber(customerData?.mobile)
                                    : "-"}
                                </span>
                              </p>
                            </div>
                          </div>
                          :
                          <div className="col-lg-6">
                            <div className='card h-100 border bg-light p-3 pe-5'>
                              Buyer not associated.
                            </div>
                          </div>
                      }

                      {sellerId ?
                        <div className="col-lg-6">
                          <div className='card h-100 border bg-light p-3 pe-5'>
                            <Link
                              target="_blank"
                              to={`${routeConstant.SELLERMOREINFO
                                }/${encryptId(sellerId)}`}
                            >
                              <i className='edit_card_box' title="Edit Seller">
                                <i className='fa fa-pencil'></i>
                              </i>
                            </Link>
                            <p className="m-0 fs-14">
                              Seller # :
                              <span className="fw-bold">{" "}{sellerId}</span>
                            </p>
                            <p className="m-0 fs-14">
                              Seller Name :
                              <span className="fw-bold">{" "}{salesData?.sellerName}</span>
                            </p>
                            <p className="m-0 fs-14">
                              Email :
                              <span className="fw-bold">{" "}{salesData?.sellerEmail}</span>
                            </p>
                            <p className="m-0 fs-14">
                              Phone :
                              <span className="fw-bold">
                                {" "}{salesData?.sellerMobile
                                  ? formatPhoneNumber(salesData?.sellerMobile)
                                  : '-'}
                              </span>
                            </p>
                            <p className="m-0 fs-14">
                              Reporting Email :
                              <span className="fw-bold">{" "}{salesData?.sellerReportingMail ? salesData?.sellerReportingMail : ' -'}</span>
                            </p>
                          </div>
                        </div> :
                        <div className="col-lg-6">
                          <div className='card h-150 border bg-light p-3 pe-5'>
                            Seller not associated.
                          </div>
                        </div>
                      }
                      {watchLotStatus == 1 ?
                        <div className='col-12'>
                          <div className="card h-100 border bg-light p-3 pe-5">
                            {invoiceNumber ?
                              <Link className="col-lg-12"
                                target="_blank"
                                to={`${routeConstant.UPDATEINVOICE
                                  }/${encryptId(
                                    salesData?.invoiceId ? salesData?.invoiceId : 194
                                  )}`}
                              >
                                <i className='edit_card_box' title="Edit Invoice">
                                  <i className='fa fa-pencil'></i>
                                </i>
                              </Link>
                              : <></>}
                            {invoiceNumber ?
                              <p className="m-0 fs-14">
                                Invoice # :
                                <span className="fw-bold">
                                  {" "}{invoiceNumber}
                                </span>
                              </p> :
                              <p>
                                No invoice available,<button className='fw-bold btn btn-link p-0 fs-1' type="button" onClick={handleInvoiceConfirm} >Click here to create new Invoice </button>.
                              </p>}
                          </div>
                        </div>
                        : <div className='col-12'>
                          <div className="card h-100 border bg-light p-3 pe-5">
                            <p>
                              Lot status is Passed/Buyback or Pending, So invoice cannot be generated.
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                {/* Tabs */}
                <Tabs
                  defaultActiveKey="commision"
                  className="mt-3 mb-3 nav-underline border-bottom"
                >
                  <Tab eventKey="commision" title="Commission">
                    <Commission register={register} setValue={setValue} watch={watch} getValues={getValues} setLoading={setLoading} errors={errors} />
                  </Tab>
                  <Tab eventKey="invoice" title="Invoice">
                    <Invoice invoiceId={invoiceId} />
                  </Tab>
                  {/* <Tab eventKey="tax" title="Tax">
                      <Tax />
                    </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
          <div className="container-fluid for-fix-bottom-space">
            <div
              className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom"
              data-wizard-footer="data-wizard-footer"
            >
              <Link
                className="btn btn-outline-danger me-auto"
                type="button"
                data-bs-dismiss="modal"
                to={routeConstant.SALESRECORDS}
              >
                {CommonMessages.cancelButton}
              </Link>
              <ModifiedBy data={initialFormValue} />
              <LastModified data={initialFormValue} />
              <button className="btn btn-primary mx-2">
                {initialFormValue?.salesRecordId !== undefined
                  ? CommonMessages.updateButton
                  : CommonMessages.saveButton}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default MoreSalesInfo;