import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import moment from 'moment';
import { formatCurrency } from "../../Utils/Utils";
import axios from "axios";
import { GETCOMMONVALUE } from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import handleError from "../../Utils/ApiCommonErrorMessages";
interface DataListingProps {

    showAdjustList: any[],
    showPaymentList: any[],
    handleEditAdjust: (id: any, data: any) => void
    handleEditPayment: (id: any, data: any) => void
    handleDeletePayment: ( data: any) => void
    handleDeleteAdjust: (id: any, data: any) => void,
    setNetPayment:any,
    netPayment:any
}
const ShowAdjustPaymentListing: React.FC<DataListingProps> = ({

    showPaymentList,
    showAdjustList,
    handleEditAdjust,
    handleEditPayment,
    handleDeleteAdjust,
    handleDeletePayment,
    setNetPayment,
    netPayment
}) => {
  const [paymentTypeData, setPaymentTypeData] = useState<any>([]);

  useEffect(() => {
    const totalPaymentAmounts = showPaymentList?.reduce(
      (total: any, payment: any) => {
        const amount = parseFloat(payment?.amountPaid || 0);
        return total + amount;
      },
      0
    );
    setNetPayment(totalPaymentAmounts);
  }, [showPaymentList,netPayment]);

  // Get payment types to display paytype
   useEffect(() => {
     axios
       .get(
         `${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem(
           "companyId"
         )}`
       )
       .then(
         (response) => {
           if (response?.data?.success === "Fail") {
             toast.error(response?.data?.message, {
               position: toast.POSITION.TOP_RIGHT,
               autoClose: 2000,
             });
             return;
           } else {
             setPaymentTypeData(response?.data?.data);
           }
         } ) .catch((error) => {
          handleError(error)
        });;
   }, []);

   return (
    <>
      <div className="col-xl-12">
        <div className="row">
          <div className="col-xl-12 mb-3">
            <div className="mb-1 d-flex flex-wrap justify-content-between">
              <h5>Payment</h5>
              <h6 className="mt-1 fst-italic fw-normal fs-12 ">
                Net Payment: {formatCurrency(netPayment.toFixed(2))}
              </h6>
            </div>
            <div className="table-responsive scrollbar border">
              <table className="table fs--1 mb-0 border-top border-200">
                <thead>
                  <tr>
                    <th className="bg-gradiant" scope="col">
                      Action
                    </th>
                    <th
                      className="bg-gradiant p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Date</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Type</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Payment Amt</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Convenience Charge</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="list scrollbar" id="table-latest-review-body">
                  {(() => {
                    const activePayments = showPaymentList?.filter((i: any) => i?.isActive && !i?.isDelete);
                    return activePayments?.length > 0 ? (
                      activePayments?.map((data: any, index: any) => {
                        const payType = paymentTypeData.find(
                          (type: any) => type?.objectValueId === data?.payType
                        )?.objectTypeValue || "-";
                        return (
                          <tr
                            className="hover-actions-trigger btn-reveal-trigger position-static"
                            key={index}
                          >
                            <td className="align-middle white-space-nowrap text-start p-2">
                              <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                <Dropdown.Toggle
                                  className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                  variant="none"
                                >
                                  <img
                                    src="/assets/img/ellipsis-solid.svg"
                                    style={{ width: "20px" }}
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className={`dropdown-menu dropdown-menu-start py-2`}
                                >
                                  <Dropdown.Item
                                    type="button"
                                    className="dropdown-item d-flex align-items-center justify-content-between"
                                    onClick={() =>
                                      handleEditPayment(
                                        data?.invoicePaymentId,
                                        data,
                                      )
                                    }
                                  >
                                    Edit
                                    <i className="fas fa-pen dropdown-icon"></i>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    type="button"
                                    className="dropdown-item d-flex align-items-center justify-content-between"
                                    onClick={() =>
                                      handleDeletePayment(
                                        data
                                      )
                                    }
                                  >
                                    Delete
                                    <i className="fas fa-trash dropdown-icon"></i>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td className="align-middle  p-2">
                              <p className="fs--1 text-1000 mb-0">
                                {moment(data?.date)
                                  .format("MM/DD/YYYY")
                                  .toString()}
                              </p>
                            </td>
                            <td className="align-middle white-space-nowrap p-2">
                              <p className="fs--1 text-1000 mb-0">
                                {payType}
                              </p>
                            </td>
                            <td className="align-middle white-space-nowrap p-2">
                              <p className="fs--1 text-1000 mb-0">
                                {formatCurrency(data?.amountPaid ? data?.amountPaid : 0)}
                              </p>
                            </td>
                            <td className="align-middle white-space-nowrap p-2">
                              <p className="fs--1 text-1000 mb-0">
                                {formatCurrency(data?.convenienceCharge ? data?.convenienceCharge : 0)}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="text-center p-4" colSpan={6}>
                          {/* No records found! */}
                        </td>
                      </tr>
                    );
                  })()}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-xl-12 mb-3">
            <div className="mb-1">
              <h5>Adjustment</h5>
            </div>
            <div className="table-responsive scrollbar border">
              <table className="table fs--1 mb-0 border-top border-200">
                <thead>
                  <tr>
                    <th className="bg-gradiant" scope="col">
                      Action
                    </th>
                    <th
                      className="bg-gradiant white-space-nowrap p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Date</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant white-space-nowrap p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Description</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant white-space-nowrap p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Adjustment</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant white-space-nowrap p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Amount</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant white-space-nowrap p-2"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Taxable(Only Charges)</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="table-latest-review-body">
                  {(() => {
                    const activeList = showAdjustList?.filter((i: any) => i?.isActive === true && i?.isDelete === false);
                    return activeList.length > 0 ? (
                      activeList.map((data: any, index: any) => (
                        <tr
                          className="hover-actions-trigger btn-reveal-trigger position-static"
                          key={index}
                        >
                          <td className="align-middle white-space-nowrap text-start p-2">
                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                              <Dropdown.Toggle
                                className="m-0 p-0"
                                variant="none"
                              >
                                <button
                                  className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                  type="button"
                                >
                                  <img
                                    src="/assets/img/ellipsis-solid.svg"
                                    style={{ width: "20px" }}
                                  />
                                </button>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-start py-2">
                                <Dropdown.Item
                                  type="button"
                                  className="dropdown-item d-flex align-items-center justify-content-between"
                                  onClick={() =>
                                    handleEditAdjust(
                                      data?.invoiceAdjustId,
                                      data
                                    )
                                  }
                                >
                                  Edit
                                  <i className="fas fa-pen dropdown-icon"></i>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  type="button"
                                  className="dropdown-item d-flex align-items-center justify-content-between"
                                  onClick={() =>
                                    handleDeleteAdjust(
                                      data?.invoiceAdjustId,
                                      data,
                                    )
                                  }
                                >
                                  Delete
                                  <i className="fas fa-trash dropdown-icon"></i>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td className="align-middle white-space-nowrap p-2">
                            <p className="fs--1 text-1000 mb-0">
                              {moment(data?.date)
                                .format("MM/DD/YYYY")
                                .toString()}
                            </p>
                          </td>
                          <td className="align-middle white-space-nowrap p-2 w-25">
                            <p
                              className="fs--1 text-1000 mb-0"
                              data-toggle="tooltip"
                              title={data?.description}
                            >
                              {data?.description &&
                                `${data?.description.substring(0, 33)}${data?.description.length > 33 ? "..." : ""
                                }`}
                            </p>
                          </td>
                          <td className="align-middle white-space-nowrap p-2">
                            <p className="fs--1 text-1000 mb-0">
                              {data?.chgCr == "2" ? "Charge" : "Credit"}
                            </p>
                          </td>
                          <td className="align-middle white-space-nowrap review p-2">
                            <p className="fs--1 text-1000 mb-0">
                              {data.chgCr == 2
                                ? formatCurrency(data?.amount)
                                : `(${formatCurrency(data?.amount)})`}
                            </p>
                          </td>
                          <td className="align-middle white-space-nowrap p-2">
                            <p className="fs--1 text-1000 mb-0">
                              {data?.chgCr == "2"
                                ? formatCurrency(data?.taxAmount)
                                : " -"}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="text-center p-4" colSpan={6}>
                          {/* No records found! */}
                        </td>
                      </tr>
                    );
                  })()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ShowAdjustPaymentListing;