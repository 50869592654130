import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoInvoicing from "./AutoInvoicing";
import InvoiceFooter from "./InvoiceFooter";
import { setAuctionInvoiceActive } from "../../../../store/actions/AuctionAction";
import { Tab, Tabs } from "react-bootstrap";

const InvoiceSetting = () => {
    const activeInvoice = useSelector((state: any) => state.auction.invoice)
    const [activeStep, setActiveStep] = useState(1)
    const [activeLabel, setActiveLabel] = useState("Auto Invoicing")
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveStep(activeInvoice.activeStep)
    }, [activeInvoice.activeStep])

    useEffect(() => {
        setActiveLabel(activeInvoice.activeLabel)
    }, [activeInvoice.activeLabel])

    const handleChange = (key: string) => {
        if (key === 'AutoInvoicing') {
            dispatch(setAuctionInvoiceActive({ activeStep: 1, activeLabel: "Auto invoicing" }))
        } else if (key === 'InvoiceFooter') {
            dispatch(setAuctionInvoiceActive({ activeStep: 2, activeLabel: "Invoice Footer" }))
        };
    }

    return (
        <>
            <div className="for-fix-bottom-space">
                <div className="row justify-content-start">
                    <div className="mt-2 mb-3">
                        <div className="mb-2">
                            <h4>Invoices - {activeLabel}</h4>
                        </div>
                        <Tabs
                            activeKey={activeStep === 1 ? "AutoInvoicing" : "InvoiceFooter"}
                            defaultActiveKey="AutoInvoicing"
                            className="nav-underline border-bottom  nav nav-tabs mb-3"
                            onSelect={(key: any) => handleChange(key)}
                        >
                            <Tab eventKey="AutoInvoicing" title="Auto Invoicing"></Tab>
                            <Tab eventKey="InvoiceFooter" title="Invoice Footer"></Tab>
                        </Tabs>
                        {
                            activeStep == 1 ? <AutoInvoicing /> : <InvoiceFooter />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceSetting