import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import jwt_decode from "jwt-decode";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import { Breadcrumb } from "../../common/interface";
import * as routeConsant from "../../common/routeConstants";
import axios from "axios";
import {
  CREATEEINVOICESETTINGS,
  GETEINVOICESETTINGS,
  UPDATEEINVOICESETTINGS,
} from "../../common/apiConstatnts";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import { Modal } from "react-bootstrap";
import { wordCounter } from "../../common/constants";
import { EMAIL, EMAIL_MESSAGE, URL, URL_MESSAGE } from "../../common/regexConstants";
import { CommonMessages, EInvoieSettingMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, trimObjectValues } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

interface Props {
  initialMessage: string;
}

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConsant.DASHBOARD },
  { label: "E-Invoice Settings", url: routeConsant.ROLE },
];
const EInvoiceSetting = () => {
  const [loading, setLoading] = useState(false);
  const [modifiedBy, setModifiedBy] = useState('');
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [content, setContent] = useState<any>('');
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;
  const maxWordCount = 50
  const [remainingCount, setRemainingCount] = useState(maxWordCount)
  const [remainingCount2, setRemainingCount2] = useState(maxWordCount)

  const schema: any = yup.object().shape({
    auctionEmail: yup
      .string()
      .required("Email is a required field.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters"),
    ccAddress: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .required("CC is a required field.").matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters"),
    bccAddress: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters"),
    correspondenceAddress: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .required("Correspondence address is a required field.")
      .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters"),
    paymentUrl: yup.string()
      .required("Payment URL is a required field.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(URL, URL_MESSAGE),
    emailInvoiceFooterText: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .max(50, "Email Invoice Footer Text must be at most 50 characters.")
    ,
    autoInvoiceCashDiscountText: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .max(50, "Auto Invoice Cash Discount Text must be at most 50 characters.")
  });
  const initialData: any = {
    einvoicesettingsId: 0,
    companyId: 0,
    auctionEmail: "",
    ccAddress: "",
    bccAddress: "",
    correspondenceAddress: "",
    paymentUrl: "",
    emailInvoiceFooterText: "",
    autoInvoiceCashDiscountText: "",
  };
  const [formValues, setFormValues] = useState<any>(initialData);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
    mode: "onChange",
  });

  const contentFooter = watch("emailInvoiceFooterText")
  const contentDiscount = watch("autoInvoiceCashDiscountText")

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleClose = () => {
    setToggleValue(!toggleModal);
  }

  const onSubmitSearch = () => {

  }

  const onSubmit = () => {
    if (superAdminRole === "True") {
      setLoading(true);
      const dataOriginal = getValues();
      const data = trimObjectValues(dataOriginal);
      const url =
        formValues.einvoicesettingsId === 0
          ? CREATEEINVOICESETTINGS
          : UPDATEEINVOICESETTINGS;
      axios.post(url, data, { ...config, ContentType: "application/json" }).then(
        (response:any) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          }
          else {
            if (formValues.einvoicesettingsId === 0) {
              toast.success(EInvoieSettingMessages.eInvoiceCreated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            } else {
              toast.success(EInvoieSettingMessages.eInvoiceUpdated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
          }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error);
    });
    }
    else {
      setLoading(false);
      toast.error("Only super admin has permission to perform this operation on the page.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };


  const MessageHighlighter: React.FC<Props> = ({ initialMessage }) => {
    const [message, setMessage] = useState(initialMessage);
    const [searchText, setSearchText] = useState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchText = event.target.value;
      setSearchText(searchText);
      highlightText(searchText);
    };

    const highlightText = (textToHighlight: string) => {
      if (!textToHighlight) {
        setMessage(initialMessage);
        return;
      }

      const regex = new RegExp(textToHighlight, 'gi');
      const highlightedMessage = initialMessage.replace(
        regex,
        (match) => `<span class='highlight'>${match}</span>`
      );
      setMessage(highlightedMessage);
    };

    return (
      <div>
        <input className="form-control w-25" type="text" placeholder="Search" aria-label="Search" onChange={handleSearch} />

        <div
          style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '10px', marginTop: '10px', minHeight: '200px' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
    );
  };


  useEffect(() => {
    setLoading(true);
    axios.get(GETEINVOICESETTINGS, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        let eInvoiceSettingsData = response.data.data;
        setModifiedBy(eInvoiceSettingsData.modifiedBy)
        const initialFormData = {
          einvoicesettingsId: eInvoiceSettingsData.einvoicesettingsId,
          companyId: eInvoiceSettingsData.companyId,
          auctionEmail: eInvoiceSettingsData.auctionEmail,
          ccAddress: eInvoiceSettingsData.ccAddress,
          bccAddress: eInvoiceSettingsData.bccAddress,
          correspondenceAddress: eInvoiceSettingsData.correspondenceAddress,
          paymentUrl: eInvoiceSettingsData.paymentUrl,
          emailInvoiceFooterText: eInvoiceSettingsData.emailInvoiceFooterText,
          autoInvoiceCashDiscountText:
            eInvoiceSettingsData.autoInvoiceCashDiscountText,
          isActive: eInvoiceSettingsData.isActive,
        };
        setFormValues(initialFormData);
        reset(initialFormData);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
  });
  }, []);

  return (
    <>
      <CommonHelmet title={"E-Invoice - "} name={"einvoiceSetting"} content={"E-Invoice Setting"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Modal show={toggleModal} size='xl' centered>
        <form onSubmit={handleSubmit(onSubmitSearch)}>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">Preview Content</h5>
            <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-3 mb-3">
              {/* <div className="d-flex align-items-center">
                            <form className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) =>{}} />
                                <span className="fas fa-search search-box-icon mx-2"></span>
                            </form>
                        </div>
                        <div className="border border-dark p-2" style={{minHeight:'200px',borderRadius:'8px'}}>
                            {content}
                        </div> */}
              <MessageHighlighter initialMessage={content} />

              {/* <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Tax Ragion Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Tax Region Name"
                                    {...register('taxRegionName')}
                                />
                                {errors.taxRegionName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRegionName.message}</span>}
                            </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
            {/* <button className="btn btn-primary" type="submit" disabled={!isValid} >{mode === 'add' ? 'Create' : 'Update'}</button> */}
          </Modal.Footer>
        </form>
      </Modal>
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
        <div className="container-fluid for-fix-bottom-space">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row top_border justify-content-center">
              <div className="col-xxl-10">
                <fieldset className="mb-3">
                  <div className="row pt-4 pb-4">
                    <h4 className="mb-3">E-Invoice Settings</h4>
                    <h5 className="mb-2">Auction Email :</h5>
                    <div className="col-lg-12">
                      <label className="form-label" htmlFor="text">
                        Email <sup className="text-danger">*</sup>{" "}
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="Email"
                        maxLength={51}
                        {...register("auctionEmail")}
                      />
                      {errors?.auctionEmail && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.auctionEmail.message}
                        </span>
                      )}
                    </div>
                    <div className="col-12 mt-0">
                      <hr />
                    </div>
                    <h5 className="mb-2 mt-0">E-Invoice Settings :</h5>
                    <div className="col-sm-4 mb-4">
                      <label className="form-label" htmlFor="text">
                        CC <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="abc@gmail"
                        maxLength={51}
                        {...register("ccAddress")}
                      />
                      {errors?.ccAddress && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.ccAddress.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-4 mb-4">
                      <label className="form-label" htmlFor="text">
                        BCC{" "}
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="abc@gmail"
                        maxLength={51}
                        {...register("bccAddress")}
                      />
                      {errors?.bccAddress && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.bccAddress.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-4 mb-4">
                      <label className="form-label" htmlFor="text">
                        Correspondence Address{" "}
                        <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={51}
                        placeholder="Correspondence Address"
                        {...register("correspondenceAddress")}
                      />
                      {errors?.correspondenceAddress && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.correspondenceAddress.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label className="form-label" htmlFor="text">
                        Payment URL <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="https://www.google.com"
                        {...register("paymentUrl")}
                      />
                      {errors?.paymentUrl && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.paymentUrl.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-12 mb-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label" htmlFor="text">
                          Email Invoice Footer Text{" "}
                        </label>
                        <button className="btn btn-outline-primary btn-sm fs--2 hover-bg-100 hover-text-primary mb-2" type="button" onClick={() => { setToggleValue(true); setContent(contentFooter) }}>Preview Content <i className="fas fa-eye ms-1"></i></button>
                      </div>
                      <textarea
                        className="form-control form-icon-input"
                        placeholder="Email Invoice Footer Text"
                        rows={4}
                        maxLength={maxWordCount}
                        {...register("emailInvoiceFooterText", { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                      ></textarea>

                      {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                      </div>}

                      {errors?.emailInvoiceFooterText &&
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.emailInvoiceFooterText.message}
                        </span>
                      }
                    </div>
                    <div className="col-12">
                      <hr />
                    </div>
                    <div className="col-lg-12 ">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="form-label" htmlFor="text">
                          Auto Invoice Cash Discount Text{" "}
                        </label>
                        <button className="btn btn-outline-primary btn-sm fs--2 hover-bg-100 hover-text-primary mb-2" type="button" onClick={() => { setToggleValue(true); setContent(contentDiscount) }}>Preview Content <i className="fas fa-eye ms-1"></i></button>
                      </div>
                      <textarea
                        className="form-control form-icon-input"
                        placeholder="Auto Invoice Cash Discount Text"
                        rows={4}
                        maxLength={maxWordCount}
                        {...register("autoInvoiceCashDiscountText", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount)) })}
                      ></textarea>
                      {
                        errors?.autoInvoiceCashDiscountText ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors?.autoInvoiceCashDiscountText.message}
                          </span>
                        ) :
                          remainingCount2 != maxWordCount &&
                          <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                            {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                          </div>}

                    </div>
                  </div>
                </fieldset>

                {/* Static Footer */}
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                  <Link
                    to={routeConsant.DASHBOARD}
                    className="btn btn-outline-danger me-auto"
                    type="button"
                    data-wizard-prev-btn="data-wizard-prev-btn"
                  >
                    {CommonMessages.cancelButton}
                  </Link>
                  <ModifiedBy data={formValues} />
                  <LastModified data={formValues} />
                  <button
                    className="btn btn-primary mx-2"
                    type="submit"
                    data-wizard-next-btn="data-wizard-next-btn"
                  >
                    {CommonMessages.saveButton}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EInvoiceSetting;
