import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import moment from "moment";
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import { ADVANCEFILTERSALESRECORD, DELETESALESRECORD, GETALLACTIVECUSTOMER, GETALLAUCTIONLIST, GETALLSALESRECORDS, GETBIDDERSBYCUSTOMERID, UPDATEBIDDERNUMBERINSALES } from '../../common/apiConstatnts';
import { activationStatus } from "../../common/constants";
import CommonHelmet from '../../common/HelmetWrapper';
import Helper from '../../common/helpers';
import { Breadcrumb } from "../../common/interface";
import { CommonMessages, SalesRecMessages } from '../../common/messageConstants';
import * as routeConstant from '../../common/routeConstants';
import * as actions from '../../common/types';
import { encryptId } from '../../Encryption/Encryption';
import handleError from '../../Utils/ApiCommonErrorMessages';
import DateTime from '../../Utils/DateTime';
import { ERROR_MESSAGES, formatCurrency, formatNumber, handleKeyDown, removeHtmlTags, trimObjectValues, useDebounce } from '../../Utils/Utils';
import InnerHeaderTab from '../InnerHeader/InnerHeaderTab/InnerHeaderTab';
import Sidebar from '../Sidebar/Sidebar';
import SalesWithCosts from './Tabs/SalesWithCosts';

const breadcrumbData: Breadcrumb[] = [
  { label: 'Home', url: routeConstant.DASHBOARD },
  { label: 'Sales Records', url: '' }
]

interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const SalesRecordListing = () => {

  const initialData = {
    salesRecordId: 0,
    companyId: localStorage.getItem('companyId'),
    lotNumber: "",
    lotTitle: "",
    price: "",
    bidderNumber: "",
    quantity: "",
    auctionType: "",
    sellerNumber: "",
    isActive: true
  }
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }
  const navigate = useNavigate();
  const getPermission: any = Helper.decodePermissions()?.find(
    (data: any) => data.moduleName === "Sales Record"
  );
  const dispatch = useDispatch();
  const [permission] = useState<any>(getPermission);
  const [initialFormValue, setFormValue] = useState<any>(initialData)
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleModalBidder, setToggleValueBidder] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState(false);
  const [salesCosts, setSalesCosts]: any = useState([]);
  const [filterSalesCosts, setFilterSalesCosts] = useState<any>([]);
  const [bidderNumber, setBidderNumber] = useState([])
  const [customerNumber, setCustomerNumber] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [originalSalesCosts, setOriginalSalesCosts] = useState([]);
  const [searchText, setSearchText] = useState('')
  const searchData = useSelector((state: any) => state.dashboard.searchData)
  const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [defaultBidder, setDefaultBidder] = useState<any>();
  const [salesRecordId, setSalesRecordId] = useState<any>();
  const [index, setIndex] = useState<any>();
  const [defaultCustomerId, setDefaultCustomerId] = useState('');
  const [resetDate, setResetDate] = useState(true)
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber]: any = useState<PageData>({});
  const [auctionList, setAuctionList] = useState<any>([]);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [error, setError] = useState({
    lotNo: '',
    bidderNumber: '',
    lotTitle: ''
  });

  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);

  const initialFilterData = {
    lotTitle: "",
    AuctionType: "",
    status: -1,
    isActive: -1,
    bidderNumber: "",
    lotNo: "",
    auctionDate: "",
    keyword: "",
    auctionIds: searchData?.id ? searchData?.id : hammerAuctionId ? hammerAuctionId : "",
    invoiceNumber: "",
  };
  const [filterData, setFilterData] = useState<any>(initialFilterData);
  // Form defination for advance search filter
  const {
    register: filterRegister,
    handleSubmit: filterHandleSubmit,
    reset: filterReset,
    clearErrors: clearFilterError,
    setValue: filterSetValue,
    watch: filterWatch,
    getValues: filterGetValues,
  } = useForm({
    defaultValues: initialFilterData,
    mode: "onChange",
  });
  const invoiceNumber = filterWatch("invoiceNumber");

  const clearSearchBar = () => {
    const searchbar: any = document.getElementById('searchBar')
    searchbar.value = ''
  }

  useEffect(() => {
    if (hammerAuctionId) {
      setAlreadyFetchedDataPageNumber({})
      const resetFilter = {
        lotNumber: "",
        lotTitle: "",
        salesRecordId: "",
        startDateTime: "",
        endDateTime: "",
        bidderNumber: "",
        isActive: -1,
        auctionIds: hammerAuctionId ? hammerAuctionId : '',
        pageNumber: 1,
        invoiceNumber: "",
        auctionDate: "",
        keyword: "",
      }
      setFilterData(resetFilter)
      filterReset(resetFilter)
      clearSearchBar()
      currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)
      setShowFilter(true);
    } else {
      if (searchData?.id || searchData?.name || searchData?.date) {
        setShowFilter(false);
        dispatch({ type: actions.RESETSEARCHDATA });
        return;
      }
      else {
        clearAdvanceFilterData();
        clearSearchBar()
        setShowFilter(false);
      }
    }
  }, [hammerAuctionId]);



  // Create record
  const schema: any = yup.object().shape({
    // lotNumber: yup.number().typeError('Lot Number  must be a number.')
    //   .transform((value, originalValue) => {
    //     return (originalValue === '') ? undefined : value
    //   }).required("Lot Number is required.").moreThan(0, 'Lot Number must be greater than 0.'),
    // lotTitle: yup.string().required("Lot Title is required.")
    //   .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   })
    // ,
    price: yup.number().typeError('Price must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }).required("Price is required.").moreThan(0, 'Price must be greater than 0.'),
    bidderNumber: yup.string().required("Bidder Number is required."),
    quantity: yup.number().typeError('Quantity must be a number.')
      .transform((value, originalValue) => {
        return (originalValue === '') ? undefined : value
      }).required("Quantity is required.").moreThan(0, 'Quantity must be greater than 0.'),
    auctionType: yup.string().required("Please select auction type."),
    sellerNumber: yup.string().required("Seller Number is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
  });

  const auctionTypes = [
    { value: "", label: "All" },
    { value: "Land Auction", label: "Land Auction" },
    { value: "Equipment Auction", label: "Equipment Auction" },
    { value: "Business Auction", label: "Business Auction" },
  ]

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
    updateData(page, data);
    setFilterSalesCosts(data);
  };


  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const getAllSalesConstsData = (
    pageSize = 10,
    pageNumber = 1,
    searchData: any = null
  ) => {
    if (hammerAuctionId && hammerAuctionId?.length !== 0) {
      setShowFilter(true);
      handleAdvanceFilter(filterData);
      return;
    }
    setLoading(true);
    axios.get(`${GETALLSALESRECORDS}?pageNumber=${pageNumber}&pageSize=${paginationSize}`, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          // toast.error(response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          setFilterSalesCosts([]);
          return;
        } else {
          const SalesRecData = response?.data?.data?.data;
          setSelectedOption(SalesRecData?.bidderNumber);
          setOriginalSalesCosts(SalesRecData);
          paginateData(SalesRecData, pageNumber);
          setTotalRecords(response.data.data.totalRecords);
          updateData(pageNumber, response?.data?.data?.data);
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  const handleSorting = (type: string, fieldName: string) => {
    let sortedRecords = [...filterSalesCosts];
    if (type === "ASC") {
      if (typeof sortedRecords[0][fieldName] === "string") {
        sortedRecords.sort((a: any, b: any) =>
          a[fieldName].localeCompare(b[fieldName])
        );
      } else if (typeof sortedRecords[0][fieldName] === "number" || sortedRecords[0][fieldName] == null) {
        sortedRecords.sort((a: any, b: any) => (a[fieldName] || 0) - (b[fieldName] || 0));
      }
    } else {
      if (typeof sortedRecords[0][fieldName] === "string") {
        sortedRecords.sort((a: any, b: any) =>
          b[fieldName].localeCompare(a[fieldName])
        );
      } else if (typeof sortedRecords[0][fieldName] === "number" || sortedRecords[0][fieldName] == null) {
        sortedRecords.sort((a: any, b: any) => (b[fieldName] || 0) - (a[fieldName] || 0));
      }
    }
    paginateData(sortedRecords, currentPage);
  }

  const handleCloseBidder = () => {
    setDefaultCustomerId('')
    setToggleValueBidder(!toggleModalBidder)
  }

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const changeHandler = (fieldName: string, value: any) => {
    let formattedValue = value;
    if (fieldName == "auctionDate") {
      formattedValue = value ? convertToDateOnlyFormat(value) : '';
    }
    const updateData = { ...filterData, [fieldName]: formattedValue }
    setFilterData(updateData)

    if (fieldName === "lotNo" || fieldName === "bidderNumber") {
      const isValidNumber = /^[0-9]*$/.test(value) && value.length <= 20;
      const hasLeadingSpace = /^\s/.test(value);
      if (hasLeadingSpace) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else if (!isValidNumber) {
        setError({ ...error, [fieldName]: 'Please enter numbers only up to 20 digits' });
        return;
      } else {
        setError({ ...error, [fieldName]: '' });
      }
    }
    if (fieldName === "lotTitle") {
      const isValidTitle = !/^\s/.test(value);
      if (!isValidTitle) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else {
        setError({ ...error, [fieldName]: '' });
      }
    }

  }

  let filterActivationStatus =
    activationStatus.map((data: any) => {
      return {
        label: data.label, value: data.value
      }
    })

  const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
    setAlreadyFetchedDataPageNumber({})
    currentPage !== 1 && setCurrentPage(1);
    setError({ lotNo: '', bidderNumber: '', lotTitle: '' });
    setResetDate(false)
    if (data == null) {
      setIsFiltered(false);
      const resetFilter: any = {
        lotTitle: "",
        bidderNumber: "",
        lotNo: "",
        AuctionType: "",
        status: "",
        isActive: -1,
        auctionDate: "",
        keyword: "",
        auctionIds: isLocalClear ? "" : hammerAuctionId,
        invoiceNumber: "",
      }
      setFilterData(resetFilter);
      filterReset(resetFilter)
      handleAdvanceFilter(resetFilter, 1);
      clearSearchBar()
    }
    else if (!isLocalClear) {
      handleAdvanceFilter(data, 1);
      setFilterData(data);
    }

  }

  const onFilterSubmit = (pageNum: any) => {
    setLoading(true);
    const data: any = filterGetValues();
    const filterData1 = trimObjectValues(data);
    axios.get(ADVANCEFILTERSALESRECORD, {
      ...config,
      params: {
        salesRecordId: filterData1?.salesRecordId ?? "",
        AuctionType: filterData1?.AuctionType ?? "",
        title: filterData1?.lotTitle ?? "",
        bidderNumber: filterData1?.bidderNumber ?? "",
        statusIds: filterData1?.status ? Number(filterData1?.status) : -1,
        auctionIds: filterData1?.auctionIds ?? "",
        lotNo: filterData1?.lotNo ?? "",
        isActive: filterData1?.isActive ?? -1,
        auctiondate: filterData1?.auctionDate ? moment(filterData1?.auctionDate).format('YYYY-MM-DD') : "",
        pageNumber: pageNum,
        keyword: filterData1?.keyword,
        invoiceNumber: filterData1?.invoiceNumber,
        pageSize: paginationSize
      },
    }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        /* toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }); */
        setFilterSalesCosts([]);
        return;
      } else {
        const salesRecata = response.data.data.data;
        setTotalRecords(response.data.data.totalRecords);
        updateData(
          response?.data?.data?.currentPage,
          response?.data?.data?.data
        );
        setOriginalSalesCosts(salesRecata);
        paginateData(salesRecata);
        setCurrentPage(pageNum);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });;
    return;
  };

  const debouncedRequest = useDebounce(() => {
    handleAdvanceFilter(filterData);
  });

  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(newPageNumber);
  };


  const handleEditModal = (salesRecordId: string) => {
    if (!permission?.isAllowEdit) {
      Helper.unAuthorized();
      return;
    } else {
      navigate(`${routeConstant?.SALESMOREINFO}/${encryptId(salesRecordId)}`);

    }
  }

  const handleDeleteModal = (salesRecordId: any, type: string) => {
    if (!permission?.isAllowEdit) {
      Helper.unAuthorized();
      return;
    }
    else {
      if (salesRecordId?.invoiceAttach == true) {
        Swal.fire({
          title: 'Cannot Delete this',
          text: "Invoice is tied to this record it can't be deleted",
          icon: 'error',
          confirmButtonColor: '#006800',
        });
      } else {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to delete this record!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#006800',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            //setLoading(true);
            if (type === 'salesWithCosts') {
              axios.post(`${DELETESALESRECORD}?salesRecordId=${salesRecordId?.salesRecordId}`, {}, config).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                  toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  return;
                }
                else {
                  toast.success(SalesRecMessages.salesRecDeleted, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  clearAdvanceFilterData(false, filterData);
                  setSearchText('');
                }
              }).catch((error) => {
                setLoading(false);
                handleError(error);
              });;
            }
          }
        });
      }
    }
  }

  const handleAdvanceFilter = (filterData: any, pageNum?: any) => {
    setLoading(true);
    const data = trimObjectValues(filterData);
    axios.get(ADVANCEFILTERSALESRECORD, {
      ...config,
      params: {
        salesRecordId: data?.salesRecordId ?? "",
        lotTitle: data?.lotTitle ?? "",
        bidderNumber: data?.bidderNumber ?? "",
        statusIds: data?.status ? Number(data?.status) : -1,
        auctionIds: data?.auctionIds,
        AuctionType: data?.AuctionType ?? "",
        lotNo: data?.lotNo ?? "",
        isActive: data?.isActive ?? -1,
        auctiondate: filterData?.auctionDate,
        invoiceNumber: data?.invoiceNumber ? data?.invoiceNumber : "",
        keyword: data?.keyword,
        pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
        pageSize: paginationSize
      },
    }).then((response) => {
      setLoading(false);
      const data = response?.data?.data;
      if (response?.data?.success === "Fail") {
        /* toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }); */
        setFilterSalesCosts([]);
      } else if (data) {
        const salesRecData = response.data.data.data;
        setTotalRecords(response.data.data.totalRecords);
        updateData(
          response?.data?.data?.currentPage,
          response?.data?.data?.data
        );
        setOriginalSalesCosts(salesRecData);
        paginateData(salesRecData, pageNum ? pageNum : currentPage);
      }
    }).catch((error) => {
      setLoading(false);
      setFilterSalesCosts([]);
      handleError(error);
    });
    return;
  };

  const dateRangehandler = () => {
    handleAdvanceFilter({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
      isActive: -1,
      pageNumber: currentPage == 0 ? currentPage + 1 : currentPage,
    });
  }

  const searchHandler = () => {
    handleAdvanceFilter({
      lotNumber: searchData?.lotNumber,
      lotTitle: searchData?.name,
      salesRecordId: searchData?.id,
      startDateTime: searchData?.date,
      endDateTime: searchData?.date,
      bidderNumber: searchData?.bidderNumber,
      isActive: -1,
      pageNumber: currentPage == 0 ? currentPage + 1 : currentPage,
    });
    setFilterData({ ...filterData, auctionId: searchData?.id })
  }

  const generateExcelWithTitle = () => {
    if (!permission?.isAllowExport) {
      Helper.unAuthorized();
      return;
    }
    else {
      setLoading(true);
      axios.get(`${GETALLSALESRECORDS}?pageNumber=${-1}`, config).then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            // toast.error(response.data.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
            setFilterSalesCosts([]);
            return;
          } else {
            const SalesRecData = response?.data?.data?.data;

            let data = SalesRecData.map((data: any) => {
              const activeStatus = data.isActive ? 'Active' : 'Inactive';
              const status = data?.statusnew === "True" ? "Posted" : data?.statusnew === "False" ? "Not Posted" : data?.statusnew;
              return {
                salesRecordId: data?.salesRecordId,
                lotNumber: data?.lotNumber,
                lotTitle: removeHtmlTags(data.lotTitle),
                bidderNumber: data.bidderNumber,
                statusnew: status,
                auctionTitle: data.auctionTitle,
                auctionIds: data.strAuctionType,
                price: formatCurrency(data.price),
                quantity: formatNumber(data.quantity),
                isActive: activeStatus,
              }
            })
            const wb = XLSX.utils.book_new();
            const wsData = [
              ['Sales Record Data'],
              [`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`],
              [],
              ['Sales Record Id', 'Lot #', 'Title', 'Bidder Number', 'Status', 'Auction Title', 'Auction Type', 'Price', 'Quantity', 'Active Status'],
              ...data.map((item: any) => Object.values(item))
            ];
            const ws = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Sales_Record.xlsx';
            a.click();
            URL.revokeObjectURL(url);

          }
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }


  };

  const handleRadioChange = (value: any) => {
    setSelectedOption(value);
    // setDefaultBidder(value);
  };

  const getBidder = (customerID: any, index: any, selectedCustomerId?: number) => {
    setLoading(true);
    setIndex(index);
    axios.get(`${GETBIDDERSBYCUSTOMERID}?customerId=${selectedCustomerId ? selectedCustomerId : customerID.customerId}`).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          // toast.error(response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          return;
        }
        else {
          const data: any = response.data.data;
          setBidderNumber(data);
          const activeBidder = data.find((item: any) => {
            return (item.bidderNumber == customerID.bidderNumber)
          });
          if (activeBidder) {
            setDefaultBidder(activeBidder.bidderNumber);
            setSelectedOption(activeBidder.bidderNumber);
          }
          const salesRecordIds: any = filterSalesCosts[index]?.salesRecordId;
          setSalesRecordId(salesRecordIds);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  const getBidderNumber = (customerID: any, index: any) => {
    setIndex(index);
    setLoading(true);
    axios.get(GETALLACTIVECUSTOMER).then(
      // axios.get(`${GETALLCUSTOMERS}?pageNumber=-1`).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        else {
          const data: any = response?.data?.data;
          const getCustomers = data.map((i: any) => {
            // return { value: i.customerId, label: `${i.customerId} - ${i.firstName}` }
            return { value: i.customerId, label: `${i.customerId} - ${i.customerName}` }
          });
          setToggleValueBidder(!toggleModalBidder);
          setCustomerNumber(getCustomers);
          const activeBidder = data.find((item: any) => item.customerId == customerID.customerId);
          if (activeBidder) {
            setDefaultCustomerId(activeBidder.customerId);
          }
          getBidder(customerID, index);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;

  };

  const updateBidderNumber = (salesRecordId: any, bidderNumber: any) => {
    //setLoading(true);
    axios.post(`${UPDATEBIDDERNUMBERINSALES}?salesRecordId=${salesRecordId}&bidderNumber=${selectedOption}&isActiveforbidding=true`, config).then(
      (response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        else {
          const data = response.data.data;
          setLoading(false);
          setFormValue(data)
          toast.success(SalesRecMessages.bidderNumUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseBidder();
          clearAdvanceFilterData(false, filterData);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  // this is for reset date in filter section.
  useEffect(() => {
    if (resetDate == false) {
      setTimeout(() => { setResetDate(true) }, 50)
    }
  }, [filterData])

  // useEffect(() => {
  //   setActiveTab('salesWithCosts')
  //   if (searchData?.id || searchData?.name || searchData?.date) {
  //     searchHandler()
  //   }
  //   else {
  //     if (dateRangeData.default) {
  //       // getAllSalesConstsData();
  //     }
  //     else {
  //       dateRangehandler();
  //     }
  //   }
  //   dispatch({ type: actions.RESETSEARCHDATA })
  //   dispatch({ type: actions.RESETDATERANGE })
  // }, [])

  // this useEffect called when click on pageNumber
  useEffect(() => {
    // debugger
    //when redirected from dashboard with global search.
    if (searchData?.id || searchData?.name || searchData?.date) {
      searchHandler();
      dispatch({ type: actions.RESETSEARCHDATA });
      return;
    }
    //when global search applied
    if (filterData?.keyword) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterSalesCosts(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      handleAdvanceFilter({ ...filterData });
      return;
    }
    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterSalesCosts(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      handleAdvanceFilter(filterData);
      return;
    }
    //when redirected from dashboard with countbox click.
    else if (dateRangeData.default == false) {
      dateRangehandler();
      return;
    }
    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterSalesCosts(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      getAllSalesConstsData(paginationSize, currentPage);
    }
  }, [currentPage]);

  const handleCustomerChange = (customerID: any) => {
    setDefaultCustomerId(customerID)
    getBidder(customerID, index, customerID)
  }

  const getAllAuctions = () => {

    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      let auctionData = []

      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
          };
        });
      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    }).catch((error) => {
      setLoading(false);
      handleError(error);
      setAuctionList([]);
    });;
  };

  useEffect(() => {
    getAllAuctions()
  }, [])

  return (
    <>
      <CommonHelmet title={"Sales - "} name={"sales"} content={"Sales"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      {/*Modal for change bidder*/}
      <Modal show={toggleModalBidder} className="md-modal" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            Change Bidder #
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseBidder}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-3 mb-3">
            <div>
              <label className="form-label">
                Buyers<sup className="text-danger">*</sup>
              </label>
              {/* <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleCustomerChange(e.target.value)}
              >
                {customerNumber?.map((initialData: any, index: any) => {
                  return (
                    <option
                      value={initialData.customerId}
                      key={index}
                      selected={initialData.customerId === defaultCustomerId}
                    >
                      {initialData.customerId} - {initialData.firstName}{" "}
                      {initialData.lastName}
                      // {initialData.customerId} - {initialData.customerName}
                    </option>
                  );
                })}
              </select> */}
              <Select
                classNamePrefix="dpaSelect"
                placeholder="Select Buyer"
                className="isActive"
                options={customerNumber}
                value={customerNumber?.find((i: any) => i.value == defaultCustomerId)}
                onChange={(e: any) =>
                  handleCustomerChange(e?.value)
                }
              />
            </div>
            <div className="bidder_list scrollbar">
              <ul className="m-0 p-0">
                {bidderNumber?.length > 0 ? (
                  bidderNumber.map((initialData: any, index: any) => {
                    return (
                      <li key={index}>
                        <div className="form-check">
                          <label
                            className={
                              initialData.bidderNumber == defaultBidder
                                ? "form-check-label checked"
                                : "form-check-label"
                            }
                          >
                            <span>{initialData.bidderNumber}</span>
                            <input
                              id={`type_${initialData.value}`}
                              className="form-check-input"
                              type="radio"
                              name="bidderNumber"
                              value={initialData.bidderNumber}
                              checked={
                                initialData.bidderNumber == selectedOption
                              }
                              // defaultChecked={initialData.bidderNumber == defaultBidder ? true : false}
                              onChange={() =>
                                handleRadioChange(initialData.bidderNumber)
                              }
                            />
                          </label>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <>
                    <p className="text-center">No Bidder Number Found</p>
                  </>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            data-bs-dismiss="modal"
            onClick={handleCloseBidder}
          >
            {CommonMessages.cancelButton}
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => updateBidderNumber(salesRecordId, bidderNumber)}
            disabled={selectedOption == defaultBidder}
          >
            {CommonMessages.updateButton}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="content pt-0">
        <InnerHeaderTab breadCrumb={breadcrumbData} title="Sales Records">
          <div className="search-box mt-2 mb-3">
            <div className="d-flex align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                {/* <input className="form-control search-input search form-control-sm" type="text" id="search-input" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} /> */}
                <input
                  id="searchBar"
                  className="form-control search-input search form-control-sm"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e: any) => {
                    setFilterData({ ...filterData, keyword: e.target.value })
                    if (e.target.value) {
                      filterSetValue("keyword", e.target.value);
                      setAlreadyFetchedDataPageNumber({});
                      setCurrentPage(1);
                      debouncedRequest();
                    } else {
                      clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                      setFilterData({ ...filterData, keyword: '' })
                    }
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
                <button
                  type="button"
                  className="p-0 btn ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </button>
              </div>
            </div>
          </div>
          {originalSalesCosts?.length > 0 && (
            <div className="col-xxl-2 d-flex flex-wrap justify-content-end mb-2">
              <button
                className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                onClick={generateExcelWithTitle}
              >
                <i className="fas fa-download"></i>
                <span>{CommonMessages.exportExcel}</span>
              </button>
            </div>
          )}
          {/* Show filter */}
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter"}
            >
              <div className="card bg-transparent position-relative p-3 mt-2 mb-3">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row g-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Select Auction
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Title"
                            className="isActive clone-inventory"
                            {...filterRegister("auctionIds")}
                            name="auctionIds"
                            options={auctionList}
                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                            value={auctionList?.find(
                              (option: any) =>
                                option?.value == filterData?.auctionIds
                            )}
                            onChange={(e) => {
                              changeHandler("auctionIds", e.value);
                              filterSetValue("auctionIds", e.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Lot #
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...filterRegister("lotNo")}
                            name="lotNo"
                            placeholder="Lot Number"
                            value={filterData?.lotNo}
                            maxLength={15}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              changeHandler("lotNo", e.target.value);
                              filterSetValue("lotNo", e.target.value);
                            }
                            }
                          />
                          {/* {error.lotNo && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.lotNo}
                            </span>
                          )} */}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Lot Title
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...filterRegister("lotTitle")}
                            name="lotTitle"
                            placeholder="lot Title"
                            value={filterData?.lotTitle}
                            onChange={(e) => {
                              changeHandler("lotTitle", e.target.value)
                              filterSetValue("lotTitle", e.target.value);
                            }
                            }
                          />
                          {error.lotTitle && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.lotTitle}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Auction Type
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Type"
                            className="isActive"
                            {...filterRegister("AuctionType")}
                            name="AuctionType"
                            options={auctionTypes}
                            value={auctionTypes?.find(
                              (option: any) =>
                                option?.value == filterData?.AuctionType
                            )}
                            onChange={(e: any) => {
                              changeHandler("AuctionType", e.value);
                              filterSetValue("AuctionType", e.value);
                            }
                            }
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Bidder #
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...filterRegister("bidderNumber")}
                            name="bidderNumber"
                            placeholder="Bidder #"
                            value={filterData?.bidderNumber}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              changeHandler("bidderNumber", e.target.value);
                              filterSetValue("bidderNumber", e.target.value);
                            }
                            }
                          />
                          {error.bidderNumber && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.bidderNumber}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Invoice #
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="Number"
                            placeholder="Invoice #"
                            value={invoiceNumber ? `${Math.max(0, parseInt(invoiceNumber))}` : ''}
                            {...filterRegister("invoiceNumber", {
                              onChange: (e) => {
                                const data = e.target.value ? `${Number(e.target.value.slice(0, 8))}` : ''
                                filterSetValue("invoiceNumber", data)
                                changeHandler("invoiceNumber", data);
                              }
                            })}
                          />
                        </div>
                        {/* <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Status"
                            {...filterRegister("status")}
                            className="status"
                            name="status"
                            options={statusTypes}
                            value={statusTypes?.find(
                              (option: any) =>
                                option?.value == filterData.status
                            )}
                            onChange={(e: any) => { changeHandler("status", e.value); filterSetValue("status", e.value); }}
                          />
                        </div> */}
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Activation Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Activation Status"
                            {...filterRegister("isActive")}
                            className="isActive"
                            name="isActive"
                            options={filterActivationStatus}
                            value={filterActivationStatus?.find(
                              (option: any) =>
                                option?.value == filterData?.isActive
                            )}
                            onChange={(e: any) => { changeHandler("isActive", e.value); filterSetValue("isActive", e.value); }}
                          />
                        </div>
                        {resetDate ? (
                          <div className="col-lg-3">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              Date
                            </label>
                            <div className="date_icon">
                              <DateTime
                                inputProps={{
                                  className: "form-control form-icon-input",
                                  placeholder: "Date (MM/DD/YYYY)",
                                  name: "auctionDate",
                                }}
                                {...filterRegister("auctionDate")}
                                closeOnSelect={true}
                                dateFormat="MM/DD/y"
                                timeFormat={false}
                                value={filterData?.auctionDate}
                                onChange={(value: any) => {
                                  changeHandler("auctionDate", value);
                                  filterSetValue(
                                    "auctionDate",
                                    value
                                  );
                                }
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end mt-3 ms-3">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={() => { clearAdvanceFilterData(true) }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          onClick={() => {
                            currentPage !== 1 && setCurrentPage(1);
                            setAlreadyFetchedDataPageNumber({});
                            setIsFiltered(true);
                            currentPage == 1 && handleAdvanceFilter(filterData, 1);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeaderTab>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-lg-12">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="tab-Seller_Commission_Scale"
                  role="tabpanel"
                  aria-labelledby="Seller_Commission_Scale-tab"
                >
                  <div className="border-300">
                    <div className="">
                      <SalesWithCosts
                        handleSorting={handleSorting}
                        setCurrentPage={setCurrentPage}
                        salesCosts={salesCosts}
                        filterSalesCosts={filterSalesCosts}
                        handleDeleteModal={handleDeleteModal}
                        handleEditModal={handleEditModal}
                        getBidderNumber={getBidderNumber}
                        selectedCustomerId={76}
                        totalRecords={totalRecords}
                        paginationSize={paginationSize}
                        onHandleFetchMore={handlePageClick}
                        pageNumber={currentPage}
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesRecordListing;