import axios from "axios";
import "jspdf-autotable";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  GETALLAUCTIONLIST, GETSELLERFORPICKUP, SELLERPICKUPSUMMARYBYAUCTIONID
} from "../../common/apiConstatnts";
import { Breadcrumb } from "../../common/interface";
import { errorMessages } from "../../common/messageConstants";
import * as routeConstant from "../../common/routeConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
import MomentUtil from "../../Utils/MomentUtil";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import { generateSellerPickupSummaryCsv, generateSellerPickupSummaryPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConstant.DASHBOARD },
  { label: "Reports", url: routeConstant.REPORTS },
  {
    label: "Seller Pickup Summary Report",
    url: routeConstant.SELLERPICKUPSUMMARY,
  },
];

const SellerPickupSummaryReport = () => {
  const momentUtil = MomentUtil.getInstance();
  const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
  const [loading, setLoading] = useState(false);
  const [sellerList, setSellerList] = useState<[]>([]);
  const [selectedSeller, setSelectedSeller] = useState<any>({});
  const [sellerPickupSummary, setAllSellerPickupSummary] = useState<any[]>([]);
  const [filterSellerCommission, setFilterSellerCommission] = useState<any>([]);
  const [auctionList, setAuctionList] = useState<[]>([]);
  const [selectedAuction, setSelectedAuction] = useState<any>({});
  const [auctionCode, setAuctionCode] = useState('')

  const tableRef: any = useRef(null);
  const PER_PAGE = 3;

  const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
  const hammerAuction: any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

  let AuctionStartDate =
    sellerPickupSummary?.length > 0
      ? sellerPickupSummary[0].auctionStartDate
      : null;

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const getAllSellers = () => {
    setLoading(true);
    axios.get(`${GETSELLERFORPICKUP}`, config).then((response: any) => {
      setLoading(false);
      const auctionData = response?.data?.data?.map((lData: any) => {
        return {
          label: `${lData.sellerId} - ${lData.sellerName}`,
          value: lData.sellerId,
        };
      });
      // setSelectedSeller(auctionData[0]);
      setSellerList(auctionData);
    });
  };

  const getSellerPickupSummaryByAuction = (auctionId?: any, sellerId?: any) => {
    setLoading(true);
    if (auctionId && sellerId) {
      axios.get(`${SELLERPICKUPSUMMARYBYAUCTIONID}?auctionId=${auctionId}&sellerId=${sellerId}`, config).then(
        (response: any) => {
          setLoading(false);
          const sellerSumResp = response.data.data;
          if (response?.data?.success === "Fail") {
            setAllSellerPickupSummary([]);
            return;
          } else {
            setAllSellerPickupSummary(sellerSumResp);
            setLoading(false);
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setAllSellerPickupSummary([]);
    }
  };

  useEffect(() => {
    if (sellerList.length == 0) {
      setAllSellerPickupSummary([])
    }
  }, [sellerList])

  const getAllAuctions = () => {
    setLoading(true);
    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      setLoading(false);
      const auctionData = response?.data?.data?.map((lData: any) => {
        return {
          label: `${lData.auctionCode} - ${lData.auctionTitle}`,
          value: lData.auctionId,
        };
      });
      if (getGlobalAuction?.value) {
        setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
      } else {
          setSelectedAuction(auctionData[0]);
      }
      setAuctionList(auctionData);
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  const getSellerByAuctionId = (auctionId?: any) => {
    setLoading(true)
    if (auctionId) {
      axios.get(`${GETSELLERFORPICKUP}?auctionId=${auctionId}`).then((response: any) => {
        setLoading(false)
        if (response?.data?.success === "Fail") {
          // toast.error(response.data.message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 2000,
          // });
          setSellerList([]);
          return;
        } else {
          const sellerData = response.data.data.map((i: any) => {
            return { label: `${i.sellerId} - ${i.sellerName}`, value: i.sellerId }
          })
          setSellerList(sellerData)
        }
      }, (error) => {
        setLoading(false)
        if (error.response.status !== 401) {
          toast.error(errorMessages.error401, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
    }
  }

  useEffect(() => {
    // getAllSellers();
    getAllAuctions();
  }, []);

  useEffect(() => {
    getSellerByAuctionId(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value)
  }, [selectedAuction, hammerAuction])

  useEffect(() => {
    if (hammerAuction) {
      setSelectedAuction(hammerAuction)
    } else {
      getAllAuctions();
    }
  }, [hammerAuction])

  const handleSellerChange = (e: any) => {
    setSelectedSeller(e);
    getSellerPickupSummaryByAuction(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value, e?.value)
  };

  useEffect(() => {
    getSellerByAuctionId(selectedAuction?.value)
  }, [selectedAuction]);

  const getNoDataView = () => {
    return (
      <div className="text-center mt-5">
        <span className="p-4">No records found!</span>
      </div>
    );
  };

  const formatAmount = (number: any) => {
    const absoluteValue = Math.abs(number);
    const formattedNumber = absoluteValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return number < 0 ? `(${formattedNumber})` : formattedNumber;
  };

  const printTable = () => {
    const printWindow: any = window.open('', '_blank');
    printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
    printWindow.document.close();
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
        printTable();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleAuctionChange = (e: any) => {
    const auctionCode = e.label.split('-')
    setAuctionCode(auctionCode[0])
    setSelectedAuction(e);
    getSellerByAuctionId(e?.value)
    setAllSellerPickupSummary([]);
  };

  const formatPhoneNumber = (phoneNumber: any) => {
    try {
      const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
      const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
      if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
        const countryCode = parsedPhoneNumber.countryCallingCode;
        const nationalNumber = parsedPhoneNumber.nationalNumber;
        if (countryCode === '1') {
          const areaCode = nationalNumber.slice(0, 3);
          const restOfNumber = nationalNumber.slice(3);
          return `+1 (${areaCode}) ${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3)}`;
        } else {
          return `+${countryCode}-${nationalNumber.slice(0, 5)}-${nationalNumber.slice(5)}`;
        }
      }
      return phoneNumber;
    } catch (error) {
      console.error('Error formatting phone number:', error);
      return phoneNumber;
    }
  };

  const formatNumber = (number: any) => {
    const absoluteValue = Math.abs(number);
    const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return number < 0 ? `(${formattedNumber})` : formattedNumber;
  };

  let previousSellerId: any = null;

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title='Seller Pickup Summary Report'>
          <div className="col-12">
            <div className="row justify-content-between g-2 mt-1 align-items-end">
              <div className="col-md-4 col-lg-3 mt-2">
                <label className="form-label ms-1 mb-1"> Select Auction</label>
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select Auction"
                  className="isActive swap-to-lot-menu clone-inventory"
                  options={auctionList}
                  filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                  value={selectedAuction.value ? auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                  onChange={(e: any) => {
                    handleAuctionChange(e);
                  }}
                />
              </div>
              <div className="col-md-4 col-lg-3 mt-2">
                <label className="form-label ms-1 mb-1"> Select Seller</label>
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select Sellers"
                  className="isActive swap-to-lot-menu clone-inventory"
                  options={sellerList}
                  value={sellerList.filter((option: any) => option.value === selectedSeller.value)}
                  onChange={(e: any) => {
                    handleSellerChange(e)
                  }}
                />
              </div>
              <div className="col-md-4 col-lg-3 mt-2 ms-auto">
                <div className="d-flex flex-wrap justify-content-end">
                  <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => generateSellerPickupSummaryCsv(sellerPickupSummary)}
                    disabled={sellerPickupSummary.length === 0}>
                    <i className="fas fa-download"></i>
                    <span>Excel</span>
                  </button>
                  <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateSellerPickupSummaryPdf(sellerPickupSummary)}
                    disabled={sellerPickupSummary.length === 0}
                  >
                    <i className="fas fa-download"></i>
                    <span>PDF</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12 mt-4 mb-4 forpdf_only_view">
              {sellerPickupSummary.length === 0 ? (
                getNoDataView()
              ) : (
                <>
                  <table
                    className="w-100"
                    style={{ border: 0, verticalAlign: "top" }}
                  >
                    <tbody>
                      <tr>
                        <td valign="top" style={{ border: 0, width: "100%" }}>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: 0,
                            }}
                          >
                            Seller Pickup Summary Report
                          </p>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bold",
                              margin: 0,
                            }}
                          >
                            <span>{`Auction #${selectedAuction
                              ? selectedAuction?.label?.split('-')[0]
                              : auctionCode
                                ? hammerAuction
                                : hammerAuction?.label?.split('-')[0]
                              }`}</span><br /><span>{momentUtil
                                .parseTimeInUtc({
                                  dateTimeString: AuctionStartDate,
                                })
                                .format("MM/DD/YYYY")}</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {sellerPickupSummary && sellerPickupSummary.map((item: any, index) => {
                    return (
                      <React.Fragment key={index} >
                        <div className="table-responsive mx-n1 px-1">
                          <table width="100%" cellSpacing="0" cellPadding="0" style={{ borderCollapse: "collapse", marginBottom: "15px" }} >
                            <tbody>
                              <tr>
                                <td valign="top" style={{ width: "40%" }}>
                                  <p style={{ fontSize: "13px", margin: 0, fontWeight: 'bold', display: 'inline' }}>To: </p>
                                  <p style={{ fontSize: "12px", margin: 0, display: 'inline' }}>{item.sellerName}</p>
                                  <p style={{ fontSize: "12px", margin: '0 20px' }}>{item.company}</p>
                                  <p style={{ fontSize: "12px", margin: '0 20px' }}>{item.sellerCityName} {item.sellerStateName}</p>
                                  <p style={{ fontSize: "12px", margin: '0 20px' }}>{item.sellerZipCode}</p>
                                  <p style={{ fontSize: "12px", margin: '0 20px' }}>C: {formatPhoneNumber(item.sellerMobile)}</p>
                                  <br />
                                  <p style={{ fontSize: "13px", margin: '0 20px', fontWeight: 'bold', display: 'inline' }}>Regarding: </p>
                                  <p style={{ fontSize: "12px", margin: '0 -20px', display: 'inline' }}>{item.auctionTitle} (Auction: {item.auctionCode})</p>
                                </td>
                                <td valign="top" style={{ width: "20%" }}></td>
                                <td valign="top" style={{ width: "40%" }}>
                                  <p style={{ fontSize: "12px", margin: 0, fontWeight: 'bold' }}>Auction Summary- Commission Based per item</p>
                                  <p style={{ fontSize: "12px", margin: 0 }}>Total Sales....................................................................${formatNumber(item.totalSales)}</p>
                                  <p style={{ fontSize: "12px", margin: 0 }}>Total Passed...............................................................${formatNumber(item.totalPassed)}</p>
                                  <p style={{ fontSize: "12px", margin: 0 }}>Total Buyback...........................................................${formatNumber(item.totalBuyBack)}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="table-responsive mx-n1 px-1">
                          <table cellPadding={0} cellSpacing={0} width="100%" style={{ borderCollapse: "collapse", marginBottom: "15px" }} >
                            <thead>
                              <tr>
                                <th style={{ fontSize: "12px", width: '5%', padding: "5px", whiteSpace: "pre", }}><div>Lot #</div></th>
                                <th style={{ fontSize: "12px", whiteSpace: "pre", }}><div>Lot Title</div></th>
                                <th style={{ fontSize: "12px", textAlign: 'right', padding: "5px", whiteSpace: "pre", }}><div>Sale Price</div></th>
                              </tr>
                            </thead>
                            {item.lstinvoicepickUpReport && item.lstinvoicepickUpReport.length > 0 && item.lstinvoicepickUpReport.map((lstData: any, key: any) => {
                              return (
                                <>
                                  <tbody>
                                    <tr>
                                      <td colSpan={3} style={{ border: 0, padding: 0, }}>
                                        <table cellPadding={0} cellSpacing={0} width="100%" className="border-top" style={{ borderCollapse: "collapse", marginBottom: "0px", }} >
                                          <tr style={{ backgroundColor: '#ECEFF7' }}>
                                            <td colSpan={2} style={{ fontSize: "11px", padding: "5px", whiteSpace: "pre", }}><div><b>Invoice: {lstData.invoiceNumber}</b> - Bidder Invoice Verification Code: {lstData.verificationCode}<br />
                                              <div style={{ paddingLeft: '4rem' }}>{lstData.customerName} | #{lstData.bidderNumber} | c: {formatPhoneNumber(lstData.customerMobile)} | {lstData.customerEmail}</div>
                                            </div></td>
                                            <td style={{ fontSize: "11px", textAlign: 'right', padding: "5px", whiteSpace: "pre", }}><div>${lstData.totalSalesPrice ? formatNumber(lstData.totalSalesPrice) : '0.00'}</div></td>
                                          </tr>
                                          {lstData.lstLotDetails && lstData.lstLotDetails.length > 0 && lstData.lstLotDetails.map((lot: any, key: any) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td style={{ fontSize: "11px", padding: "5px", whiteSpace: "pre", }}><div>{lot.lotNo}</div></td>
                                                  <td style={{ fontSize: "11px", padding: "5px", whiteSpace: "pre", }}><div>{lot.lotTitle.replace(/<[^>]*>?/gm, "")}</div></td>
                                                  <td style={{ fontSize: "11px", textAlign: 'right', padding: "2px", whiteSpace: "pre", }}><div>$ {formatNumber(lot.lotPrice)}</div></td>
                                                </tr>
                                              </>
                                            )
                                          })}
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )

                            })}
                          </table>
                        </div>
                        <span style={{ fontSize: "12px", padding: "5px", whiteSpace: "pre", fontWeight: 'bold' }}>
                          Seller Number: {item.sellerId}
                        </span>
                      </React.Fragment>
                    )
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerPickupSummaryReport;
