import React, { useEffect, useState } from 'react';
import * as routeConsant from '../../common/routeConstants'
import { Breadcrumb } from '../../common/interface';
import Sidebar from '../Sidebar/Sidebar';
import InnerHeader from '../InnerHeader/InnerHeader/InnerHeader';
import { isBuyerStatus, isSellerStatus } from '../../common/constants';
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Buyer Seller', url: '' }
]
const BuyerSeller = () => {

    const [loading, setLoading] = useState(false);
    const [buyerSellerData, setBuyerSellerData] = useState<[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [filterBuyer, setFilterBuyer] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('')

    const PER_PAGE = 10;
    const pageCount = Math.ceil(buyerSellerData.length / PER_PAGE);


    const paginateData = (data: any) => {
        setBuyerSellerData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterBuyer(firstSet)
    }
    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const filterSellerStatus = isSellerStatus?.map((data: any) => {
        return { label: data.label, value: data.value }
    });
    const filterBuyerStatus = isBuyerStatus?.map((data: any) => {
        return { label: data.label, value: data.value }
    });


    const handleSorting = (type: string, fieldName: string) => {

    }
    const changeHandler = (fieldName: string, value: any) => {

    }

    const clearAdvanceFilterData = () => {

    }
    const handleAdvanceFilter = () => {

    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = buyerSellerData?.filter((x: any) => x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) || x.email?.toLocaleLowerCase()?.includes(keyword))

        paginateData(filterData)
        setCurrentPage(0)
    }


    const handleUpdateSeller = (e: any ,elemId: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Status of seller will get changed!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            return
            if (result.isConfirmed) {
            
            }
        })
    }

    const  handleUpdateBuyer= (e: any ,elemId: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Status of seller will get changed!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            return
            
        })
    }
    useEffect(() => {
        handleSearch(searchText);
    }, [searchText])
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Buyer Seller User'>
                    <div className="search-box">
                        <div className="d-flex mt-2 align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" id="search" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })}><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-2">
                                <button className="btn p-1 p-a" type="button" onClick={() => setShowFilter(!showFilter)}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form >
                                            <div className="row g-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="userName"
                                                        placeholder="Username"
                                                        value={filterData.userName}
                                                        onChange={((e) => changeHandler("userName", e.target.value))}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">Email</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type='email'
                                                        name="email"
                                                        placeholder="Email"
                                                        value={filterData.email}
                                                        onChange={((e) => changeHandler("email", e.target.value))}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">is Seller</label>
                                                    <select className="form-select">
                                                        <option value="">Select Status</option>
                                                        {
                                                            filterSellerStatus.map((sData: any, index: any) => {
                                                                return (
                                                                    < option key={index} value={sData.value}> {sData.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">is Buyer</label>
                                                    <select className="form-select">
                                                        <option value="">Select Status</option>
                                                        {
                                                            filterBuyerStatus.map((sData: any, index: any) => {
                                                                return (
                                                                    < option key={index} value={sData.value}> {sData.label}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end mt-3 ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="submit" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="submit" onClick={handleAdvanceFilter}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </InnerHeader >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="row top_border">
                                    <div className="col-12">
                                        <div className="mb-5 border-300">
                                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant" scope="col">Sr No</th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Initials">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Name</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'name')}></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'name')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Email">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Email</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')}></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="phoneNum">Phone Number</th>
                                                            <th className="bg-gradiant" scope="col" data-sort="isSeller">is Seller</th>
                                                            <th className="bg-gradiant" scope="col" data-sort="isBuyer">is Buyer</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="table-latest-review-body">
                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                            <td className="align-middle p-2">1</td>
                                                            <td className="align-middle p-2">Leena dillon</td>
                                                            <td className="align-middle p-2">leena@dpa.com</td>
                                                            <td className="align-middle p-2">+91 626821995</td>
                                                            <td className="align-middle  p-2">
                                                                <div className="form-check form-switch m-0">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                    onChange={(e) => handleUpdateSeller(e,"")}
                                                                    // id={`flexSwitchCheckDefault_${bidderNum.bidderNumber}`}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="align-middle  p-2">
                                                                <div className="form-check form-switch m-0">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        onChange={(e) => handleUpdateBuyer(e,"")}
                                                                     // id={`flexSwitchCheckDefault_${bidderNum.bidderNumber}`}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterBuyer.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default BuyerSeller;