import React, { useState } from 'react';
import placeholderImage from "../assets/userProfile.svg";

interface CustomImageProps {
    src: string | null;
    alt: string;
}

type ImageWithFallbackProps = CustomImageProps & React.ImgHTMLAttributes<HTMLImageElement>;

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, style, ...props }) => {
    const [imageSrc, setImageSrc] = useState<string>(src || placeholderImage);
    const [isError, setIsError] = useState<boolean>(!src);

    const handleError = () => {
        setImageSrc(placeholderImage);
        setIsError(true);
    };

    return (
        <img
            src={imageSrc}
            alt={alt}
            onError={handleError}
            {...props}
        />
    );
};

export default ImageWithFallback;
