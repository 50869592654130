import { yupResolver } from "@hookform/resolvers/yup";
import Tippy from "@tippyjs/react";
import axios from "axios";
import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from 'yup';
import mapIcon from "../../../../assets/map.svg";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import { GETCOMMONVALUE, GETSALESREPRESENTATIVE, GETSELLERCONTACTINFOBYAUCTIONID, UPDATESELLERCONTACTINFO } from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { wordCounter } from "../../../../common/constants";
import { CommonMessages, errorMessages, SellerMessages } from "../../../../common/messageConstants";
import { COMPANYNAMEREGEX, EMAIL, EMAIL_MESSAGE, FIRSTANDLASTNAMEREGEX, INTEGER, INTEGER_MESSAGE, SPECIALCHARS } from "../../../../common/regexConstants";
import { decryptId } from "../../../../Encryption/Encryption";
import { setAddressTypes } from "../../../../store/actions/CommonAction";
import { setActiveTab, setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import { ERROR_MESSAGES, handleKeyDown } from "../../../../Utils/Utils";

const AuctionSellerInfo = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const dispatch = useDispatch()
    const companyId: any = localStorage?.getItem('companyId')
    const sellerData = useSelector((state: any) => state?.seller);
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);

    const selectedAuction = useSelector((state: any) => state?.seller?.selectedAuction);
    const modalData = useSelector((state: any) => state.seller?.contact);
    const addressTypes = useSelector((state: any) => state.global.addressTypes);
    const [country, setCountry] = useState([]);
    const [addressType, setAddressType] = useState<any>([]);
    const [salesPerson, setSalesPerson] = useState<any>([]);
    const [taxVal, setTaxVal] = useState(sellerData?.exempt ?? false);
    const [loading, setLoading] = useState(false);
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locations, setLocations] = useState('');
    const [latLog, setlatLog] = useState<any>(null);
    const [address, setAddress] = useState('');
    const [isAddressFilled, setIsAddressFilled] = useState(false);
    const [remainingCountAdd, setRemainingCountAdd] = useState(250);
    const [remainingCountAdd2, setRemainingCountAdd2] = useState(250);
    const [location, setLocation] = useState('');
    const [reportingIsSame, setReportingIsSame] = useState(false);
    const maxWordCount2 = 250;
    const [remainingCountForNote, setRemainingCountForNote] = useState(maxWordCount2);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount2);
    const [countryCode, setCountryCode] = useState('us');
    const [prevCountryCode, setPrevCountryCode] = useState('us');
    const [isUpdateDefault, setIsUpdateDefault] = useState(false);


    useEffect(() => {
        if (prevCountryCode !== countryCode) {
            setValue('mobile', '');
            setValue('phone', '');
        }
    }, [address, prevCountryCode, countryCode])


    const initialData = {
        companyId: parseInt(companyId),
        initials: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        title: "",
        add1: "",
        add2: "",
        city1: "",
        state1: "",
        country1: "",
        county1: '',
        zip: "",
        email: "",
        email2: "",
        phone: "",
        mobile: "",
        fax: "",
        taxId: "",
        codes: "",
        authority: "",
        salesPerson: [],
        // reason: '',
        equipment: '',
        notes: '',
        reportingEmail: '',
        reportingEmail2: '',
        isUpdateDefault: false,
        isActive: false
    }

    const [initialFormValue, setInitialFormValue] = useState<any>(initialData)

    const taxExemptType = [
        { label: 'Yes', value: true, selected: false },
        { label: 'No', value: false, selected: true }
    ]
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };


    const generatePhoneValidationSchema = () => {
        return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
            if (!value) return true;
            try {
                const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false; // Invalid phone number format
                }
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
                const expectedLength = value.length - countryCodeLength;
                return nationalNumber.toString().length === expectedLength;
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return false; // Return false if an error occurs during parsing
            }
        });
    };

    const schema: any = yup.object().shape({
        initials: yup.string().notRequired(),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),

        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),
        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        company: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name.').max(100, "Please enter upto 100 characters."),
        mobile: generatePhoneValidationSchema().required('Phone number is required.'),
        fax: yup.string().nullable().matches(/^(\d{10})*$/, 'Please enter number with 10 digits'),
        title: yup.string().nullable().matches(SPECIALCHARS, 'Please enter valid Title').max(150, "Please enter upto 150 characters."),
        add1: yup.string().required("Address is required.").matches(SPECIALCHARS, 'Please enter valid address'),
        city1: yup.string().required('City is required.'),
        state1: yup.string().required('State is required.'),
        country1: yup.string().required('Country is required.'),
        zip: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        reportingEmail: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        reportingEmail2: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        isExempt: yup.boolean(),
        taxId: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE).max(9, "Please enter upto 9 characters."),
        codes: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE).max(9, "Please enter upto 9 characters."),
        authority: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).max(20, "Please enter upto 20 characters."),
        salesPerson: yup.array().required("Select at least one option").min(1, 'Select at least one option'),
    });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        trigger,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const reportingEmail = watch("reportingEmail")
    const reportingEmail2 = watch("reportingEmail2")

    useEffect(() => {
        if (reportingEmail && reportingEmail == reportingEmail2) {
            setReportingIsSame(true);
        } else {
            setReportingIsSame(false);
        }

    }, [reportingEmail, reportingEmail2]);

    const updateCountryCode = (newAddress: any, code: any) => {
        // if (initialCountryName && newAddress !== initialCountryName) {
        //     setCountryCode(code);
        //     setValue('mobile', '');
        // } else {
        //     return;
        // }
    }

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e?.target?.value?.length)
        setAddress(e?.target?.value)
        setValue('add1', e?.target?.value)
        setIsAddressFilled(true);
        const inputText = e?.target?.value;
        const currentCount = inputText?.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText?.length)
            setRemainingCountAdd(remaining);
        } else if (e.target.name === 'addressLine2') {
            setRemainingCountAdd2(remaining);

        } else if ((e.target.name === 'location')) {
            setLocation(inputText)
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        }
    }

    const handlePrevious = () => {
        dispatch(setActiveTab('contact'))
    }
    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('inventory'));
    }


    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=addressType&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data
                let addressTypes: any = {}
                for (let i = 0; i < data.length; i++) {
                    let adr = data[i]
                    addressTypes[`${adr.objectTypeValue.split(" ")[0]}`] = { sellerAddressType: adr.objectValueId, strSellerAddressType: adr.objectTypeValue }
                }
                dispatch(setAddressTypes(addressTypes))
                setAddressType(response.data.data)
            }
        }, (error) => {
            if (error.response.status !== 401) {
                toast.error(errorMessages.error401, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }, [])

    const handleRadio = (rValue: boolean) => {
        if (rValue === false) {
            setValue('taxId', null)
            setValue('codes', null)
            setValue('authority', null)
        } else {
            setValue('taxId', sellerData?.taxId ?? '0')
            setValue('codes', sellerData?.codes ?? '0')
            setValue('authority', sellerData?.authority ?? '')
        }
        setTaxVal(rValue)
        setValue('exempt', rValue)
        clearErrors(['taxId', 'codes', 'authority', 'salesPerson']);
    }

    const getSalesRep = () => {
        axios.get(`${GETSALESREPRESENTATIVE}?pageNumber=-1`, config).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                /* toast.error(response.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                }); */
                return;
            }
            else {
                const salesRepData = response?.data?.data?.data
                const filterData = salesRepData?.map((sales: any) => {
                    return { value: sales.saleRepId, label: `${sales.saleRepId} - ${sales.firstName} ${sales.lastName}` }
                })
                const existingValue = getValues('salesPerson')
                if (typeof existingValue === "string") {
                    const salesDataArr = existingValue.split(',')
                    const salesValueForDD = salesRepData?.map((sData: any) => {
                        if (salesDataArr.includes(sData.saleRepId.toString())) {
                            return sData
                        }
                    })?.filter((data: any) => data !== undefined)
                    setValue('salesPerson', salesValueForDD?.map((sData: any) => { return { value: sData.saleRepId, label: `${sData.saleRepId} - ${sData.firstName} ${sData.lastName}` } }))
                }
                setSalesPerson(filterData);
            }
        }, (error) => {
            if (error.response.status !== 401) {
                toast.error(errorMessages.error401, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }
    const handleButtonClick = () => {
        const e = getValues();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to update this information in the master contact & default info as well',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                onSubmit(e, true);
            }
        });
    };

    const onSubmit = (data: any, isUpdateDefault: boolean = false) => {
        if (reportingIsSame) {
            return
        }
        setLoading(true);
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const formData: any = {
                sellerId: data?.sellerId,
                companyId: parseInt(companyId),
                auctionId: data?.auctionId,
                initials: parseInt(data.initials),
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                company: data.company,
                title: data.title,
                email: data.email,
                email2: data.email2,
                equipment: data.equipment,
                notes: data.notes,
                phone: data.phone?.toString(),
                mobile: data.mobile?.toString(),
                fax: data.fax?.toString(),
                exempt: data.exempt ?? false,
                taxId: data.taxId ? data.taxId : 0,
                codes: data.codes ? data.codes : 0,
                authority: data.authority ? data.authority : "",
                salesPerson: data.salesPerson?.map(((sData: any) => sData.value)).join(','),
                latitude: data?.latitude?.toString(),
                longitude: data?.longitude?.toString(),
                isActive: data?.isActive === true ? true : false,
                reportingEmail: data?.reportingEmail ? data?.reportingEmail : '',
                reportingEmail2: data?.reportingEmail2 ? data?.reportingEmail2 : '',
                mode: 1,
                addressLine1: data.add1,
                addressLine2: data.address2,
                country: parseInt(countryId),
                state: parseInt(stateId),
                city: parseInt(cityId),
                county: parseInt(countyId),
                countyName: data?.county1,
                zipCode: data.zip,
                sellerAddressType: addressTypes?.Billing?.sellerAddressType,
                strSellerAddressType: addressTypes?.Billing?.strSellerAddressType,
                sellerAddressId: sellerData?.addresses?.length > 0 ? sellerData?.addresses[0]?.sellerAddressId : 0,
                isUpdateDefault: isUpdateDefault
            }
            //this is for trim all string type value in object
            Object.keys(formData).forEach(k => formData[k] = typeof formData[k] == 'string' ? formData[k].trim() : formData[k]);
            axios.post(UPDATESELLERCONTACTINFO, formData, { ...config, 'ContentType': 'application/json' })
                .then((response) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(SellerMessages.sellerContactUpdate, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        })
                    }
                    // dispatch(updateSeller(formData));
                    reset(initialData);
                    if (isUpdateDefault === true) {
                        dispatch(updateSeller(formData));
                    }
                    handleNext();
                    setLoading(false);
                }, (error) => {
                    setLoading(false);
                    if (error.response.data?.code !== 500) {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                })

        })
    }

    // api for getting seller from selecting auction

    const getSellerByAuctionId = (auctionId: any) => {
        setLoading(true);
        axios.get(`${GETSELLERCONTACTINFOBYAUCTIONID}?sellerId=${decryptId(ID)}&auctionId=${auctionId}`).then((response) => {
            setLoading(false);
            const data = response?.data?.data;
            const newData = {
                ...data,
                isActive: data?.isActive,
                exempt: data?.exempt ?? false,
                phone: data?.phone || "",
            }
            if (data) {
                reset(newData);
            }
            else {
                reset(initialData);
                setAddress("");
                setCountryCode('us');
                setPrevCountryCode('us');
            }
            getSalesRep();
            const parsedNumber: any = newData?.phone !== "" || newData?.phone !== null || newData?.phone !== undefined && parsePhoneNumber(`+${newData?.mobile}`)
            const respCountryCode = parsedNumber ? parsedNumber?.country?.toLowerCase() : "";
            setCountryCode(respCountryCode);
            setPrevCountryCode(respCountryCode);
            setAddress(newData?.addressLine1);
            setValue('mobile', newData?.mobile);
            setValue('phone', newData?.phone);
            setValue('add1', newData?.addressLine1);
            setValue('city1', newData?.cityName);
            setValue('state1', newData?.stateName);
            setValue('country1', newData?.countryName);
            setValue('county1', newData?.countyName);
            setValue('zip', newData?.zipCode);
            setValue('address2', newData?.addressLine2);
            setLatitude(newData?.latitude);
            setLongitude(newData?.longitude);
            setValue('equipment', newData?.equipment);
            setValue('notes', newData?.notes);
            setTaxVal(newData?.exempt);
            setLoading(false);
        }, (error) => {
            setLoading(false);
            // if (error.response.status !== 401) {
            //     toast.error(error?.response?.data?.message, {
            //         position: toast.POSITION.TOP_RIGHT,
            //         autoClose: 2000,
            //     });
            // }
        })
    }

    useEffect(() => {
        if (selectedAuction?.value ? selectedAuction?.value : getGlobalAuctionIds) {
            const auctionIdToUse = selectedAuction?.value ? selectedAuction?.value : getGlobalAuctionIds;
            getSellerByAuctionId(auctionIdToUse);
        }
        else {
            getSalesRep();
            if (Object.keys(modalData).length !== 0) {
                reset(modalData)
            }
        }
    }, [selectedAuction, getGlobalAuctionIds]);


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <form onSubmit={handleSubmit((e: any) => onSubmit(e, false))}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10 mt-3">
                            <div className="mt-2 mb-2 border-300">
                                <h4>Contact Info</h4>
                            </div>
                            <div className="row g-3 mb-4">

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                    <input type="text" {...register('firstName')} className="form-control" placeholder="First Name" maxLength={20} />
                                    {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input type="text" {...register('middleName')} className="form-control" placeholder="Middle Name" maxLength={20} />
                                    {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('lastName')} placeholder="Last Name" maxLength={20} />
                                    {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Company</label>
                                    <input type="text" className="form-control" {...register('company')} placeholder="Company" maxLength={100} />
                                    {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" {...register('title')} placeholder="Title" maxLength={151} />
                                    {errors.title && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.title.message}</span>}
                                </div>

                                {/* <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" placeholder="Email" maxLength={51}
                                        // {...register('email', { onChange: handleEmailChange })} 
                                        {...register('email')}
                                        disabled={!!sellerData?.sellerId}
                                         />
                                    {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                                </div> */}

                                <div className="col-lg-6">
                                    <label className="form-label">Equipment</label>

                                    <textarea
                                        className="form-control form-icon-input"
                                        placeholder="Equipment"
                                        rows={2}
                                        maxLength={maxWordCount2}
                                        {...register("equipment", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount2)) })}
                                    />
                                    {remainingCount2 != maxWordCount2 && <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                                    </div>}
                                    {errors.equipment && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.equipment.message}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label">Notes</label>

                                    <textarea
                                        className="form-control form-icon-input"
                                        placeholder="Notes"
                                        rows={2}
                                        maxLength={maxWordCount2}
                                        {...register("notes", { onChange: (e) => setRemainingCountForNote(wordCounter(e.target.value, maxWordCount2)) })}
                                    />
                                    {remainingCountForNote != maxWordCount2 && <div className={remainingCountForNote <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCountForNote <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCountForNote}`}
                                    </div>}
                                </div>

                                <AddressForm
                                    errors={errors}
                                    onHandleChangeAddressLine={handleChangeAddressLine}
                                    register={register}
                                    setValue={setValue}
                                    setCountryCode={setCountryCode}
                                    onAddressChange={updateCountryCode}
                                    onSetAddress={setAddress}
                                    watch={watch}
                                    trigger={trigger}
                                />

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                    <Controller
                                        // name="mobile"
                                        {...register('mobile')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Alternate Number </label>
                                    <Controller
                                        // name="phone"
                                        {...register('phone')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Fax</label>
                                    <input type="text" className="form-control" {...register('fax')} placeholder="Fax" maxLength={11} />
                                    {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                </div>

                                <div className="col-lg-12">
                                    <label className="form-label">Sales Person <sup className="text-danger">*</sup></label>
                                    <Controller
                                        name="salesPerson"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix="dpaSelect"
                                                isMulti
                                                placeholder="Select"
                                                options={salesPerson}
                                            />
                                        )}
                                    />
                                    {errors.salesPerson && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.salesPerson.message}</span>}
                                </div>

                                <div className="col-lg-5">
                                    <label className="form-label">Seller Summary/Pickup Summary Report Email</label>
                                    <input type="text" className="form-control" placeholder="Seller Summary/Pickup Summary Report Email" maxLength={51} {...register('reportingEmail')} />
                                    {errors.reportingEmail && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.reportingEmail.message}</span>}
                                    {reportingIsSame && <span className='text-danger' style={{ fontSize: "12px" }}> Reporting Emails must not be same.</span>}
                                </div>
                                <div className="col-lg-5">
                                    <label className="form-label">Seller Pickup Summary Report Email</label>
                                    <input type="text" className="form-control" placeholder="Seller Pickup Summary Report Email" maxLength={51} {...register('reportingEmail2')} />
                                    {errors.reportingEmail2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.reportingEmail2.message}</span>}
                                    {reportingIsSame && <span className='text-danger' style={{ fontSize: "12px" }}> Reporting Emails must not be same.</span>}
                                </div>

                                {/* Is Active */}
                                {/* {ID && <div className="col-lg-2 pl-2">
                                    <label className="form-label">Status</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            {...register('isActive')}
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                    </div>
                                </div>} */}
                                <div className="col-12">
                                    <hr />
                                </div>

                                <div className="col-12">
                                    <h5 className="fw-400">Tax Exempt <sup className="text-danger">*</sup></h5>
                                    {taxExemptType?.map((taxType: any, index: number) => {
                                        return (
                                            <div className="form-check form-check-inline mb-3">
                                                <input
                                                    type="radio"
                                                    id={`contactExempt_${index}`}
                                                    className="form-check-input"
                                                    onClick={() => handleRadio(taxType?.value)}
                                                    checked={taxVal === taxType?.value}
                                                />
                                                <label className="form-check-label" htmlFor={`contactExempt_${index}`} >{taxType.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Tax ID</label>
                                    <input type="text" className="form-control" {...register('taxId')} disabled={!taxVal} placeholder="Tax ID" maxLength={10} onKeyDown={handleKeyDown} />
                                    {errors.taxId && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxId.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Codes</label>
                                    <input type="text" className="form-control" {...register('codes')} disabled={!taxVal} placeholder="Code" maxLength={10} onKeyDown={handleKeyDown} />
                                    {errors.codes && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.codes.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Authority</label>
                                    <input type="text" className="form-control" {...register('authority')} disabled={!taxVal} placeholder="Authority" maxLength={21} onKeyDown={handleKeyDown} />
                                    {errors.authority && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.authority.message}</span>}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-4 col-sm-4">
                                    <label className="form-label">Latitude</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="latitude"
                                        value={latitude}
                                        readOnly
                                    />
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <label className="form-label">Longitude</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="longitude"
                                        value={longitude}
                                        readOnly
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <label className="form-label">Select Buyer location
                                        <Tippy content="Click on the map to select location">
                                            <i className="eye_icons fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                                        </Tippy></label>
                                    <div className="">
                                        <button className="btn btn-light  p-0" type="button" data-bs-toggle="modal"
                                            onClick={() => {
                                                setToggleValue(!toggleModal)
                                            }}>
                                            <span className="">
                                                <img src={mapIcon} title="Click on the map to select location" />
                                            </span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                        <div>
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        </div>
                        <div className='d-flex mx-2'>
                            <Link to="#" className="btn btn-outline-primary out-line-focus-none ps-3 pe-3" id="submit-button" type="submit" onClick={handleSubmit(handleButtonClick)} >Update Default Info</Link>
                            <button className="btn btn-primary ms-2" type="submit">{ID && (CommonMessages.updatedNext)}</button>
                            <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AuctionSellerInfo;

