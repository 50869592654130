import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ERROR_MESSAGES, trimObjectValues } from "../../../../Utils/Utils";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import {
  GETLOTREALESTATEAGENTBYID,
  UPDATELOTREALESTATEAGENT
} from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { wordCounter } from "../../../../common/constants";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  AlPHANUMERIC,
  EMAIL,
  EMAIL_MESSAGE
} from "../../../../common/regexConstants";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../../store/actions/Inventory";
import * as routeConstant from "../../../././../common/routeConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { LOTAGENTS } from "../../InventoryConstants";

const Agents = () => {
  const dispatch = useDispatch();
  const lotId = useSelector((state: any) => state.inventory?.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const mode = useSelector((state: any) => state.inventory.mode)
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    isActive: true,
    isDelete: false,
    lotRealEstateAgentId: 0,
    lotId: lotId,
    agentName: "",
    agentCompany: "",
    add1: "",
    city1: "",
    state1: "",
    country1: "",
    zip: "",
    county1: '',
    agentPhone: "",
    agentEmail: "",
    openHouse: "",
    disclaimertext: "",
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState<any>("us");
  const [remainingCountAdd, setRemainingCountAdd] = useState(250);
  const maxWordCount = 250;
  const [remainingCount, setRemainingCount] = useState(maxWordCount);
  const [address, setAddress] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [btnText, setBtnTxt] = useState<any>("")

  const updateCountryCode = (newAddress: any, code: any) => {
    /* if (initialCountryName && newAddress !== initialCountryName) {
        setCountryCode(code);
        setValue('mobile', '');
    } else {
        return;
    } */
  }

  const schema: any = yup.object().shape({
    agentName: yup
      .string()
      .required("Agent Name is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Name.")
      .max(49, "Please enter upto 50 characters."),
    agentCompany: yup
      .string()
      // .required("Agent Company is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Company Name.")
      .max(49, "Please enter upto 50 characters."),
    add1: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true; // If value is empty, it's handled by the .required() validation
      return !/^\s/.test(value); // Check if value doesn't start with a space
    }),
    city1: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true; // If value is empty, it's handled by the .required() validation
      return !/^\s/.test(value); // Check if value doesn't start with a space
    }),
    state1: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true; // If value is empty, it's handled by the .required() validation
      return !/^\s/.test(value); // Check if value doesn't start with a space
    }),
    country1: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true; // If value is empty, it's handled by the .required() validation
      return !/^\s/.test(value); // Check if value doesn't start with a space
    }),
    zip: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s/.test(value) : true;
      })
      .matches(/^[0-9a-zA-Z\s-]*$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
      .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
      // .min(3, 'Zip code must be at least 3 characters.')
      .max(12, 'Zip code cannot be longer than 12 characters.'),
    agentPhone: yup
      .string()
      .required("Agent Phone Number is required. ")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .min(10, "The number must be 10 digits")
      .matches(/^[0-9 ]+$/i, "Invalid agent phone"),
    agentEmail: yup
      .string()
      // .required("Email is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE)
      .max(49, "Please enter upto 50 characters."),
    openHouse: yup
      .string()
      .matches(AlPHANUMERIC, "Please enter valid Book")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .max(49, "Please enter upto 50 characters."),
    disclaimertext: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true; // If value is empty, it's handled by the .required() validation
      return !/^\s/.test(value); // Check if value doesn't start with a space
    })
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    clearErrors,
    control,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  useEffect(() => {
    clearErrors(["city1", "country1", "state1", "zip", "add1"]);
  }, [address]);

  const onSubmit = (data: any) => {
    setLoading(true);
    onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
      const dataOriginal = getValues();
      const agentData = trimObjectValues(dataOriginal);

      const payload = {
        lotRealEstateAgentId: agentData.lotRealEstateAgentId,
        lotId: mode === "clone" ? cloneLotId : lotId,
        agentName: agentData.agentName,
        agentCompany: agentData.agentCompany,
        agentPhone: agentData.agentPhone,
        agentEmail: agentData.agentEmail,
        openHouse: agentData.openHouse,
        disclaimertext: agentData.disclaimertext,
        agentAddress: data.add1,
        country: parseInt(countryId),
        state: parseInt(stateId),
        city: parseInt(cityId),
        county: parseInt(countyId),
        countyName: data?.county1,
        zipCode: data.zip,
        isActive: true,
        isDelete: false
      }

      axios.post(UPDATELOTREALESTATEAGENT, payload, config).then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          if (mode === "clone") {
            dispatch(setCloneTab([...clonedTabs, LOTAGENTS]))
          }
          if (mode === "create") {
            dispatch(setCreateTab([...createTabs, LOTAGENTS]))
          }
          nextStep();
          toast.success(InventoryMessages.agentUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }).catch((error) => {
        setLoading(error);
        handleError(error);
      });
    })
  };

  useEffect(() => {
    setLoading(true);
    const id = mode === "clone" ? cloneLotId : lotId
    axios.get(`${GETLOTREALESTATEAGENTBYID}/?lotId=${id}`, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        return;
      } else {
        const data = response.data.data;
        setAddress(data.agentAddress)
        reset({
          ...data,
          add1: data.agentAddress,
          city1: data.cityName,
          state1: data.stateName,
          country1: data.countryName,
          zip: data.zipCode,
          county1: data?.countyName
        })
      }
    })
  }, []);

  const prevStep = () => {
    setLoading(true);
    dispatch(setInventoryInfoActive({ activeTab: "deed/location" }));
    setLoading(false);
  };

  const nextStep = () => {
    // dispatch(setStep('silent'));
    dispatch(setInventoryInfoActive({ activeTab: "taxes" }));
  };

  const handleChangeAddressLine = (e: any) => {
    charCountForAddress(e.target.value.length)
    setAddress(e.target.value)
    setValue('add1', e.target.value)
    const inputText = e.target.value;
    const currentCount = inputText.length;
    const remaining = 250 - currentCount;

    if (remaining < 0) {
      return;
    }

    if (e.target.name === 'addressLine1') {
      setValue('add1', inputText)
      setAddress(inputText)
      charCountForAddress(inputText.length)
      setRemainingCountAdd(remaining);
    } else if (e.target.name === 'addressLine2') {
      setRemainingCountAdd(remaining);

    } else if ((e.target.name === 'location')) {
      setAddress(inputText)
      setValue('add1', inputText)
      charCountForAddress(inputText.length)
      setRemainingCountAdd(remaining);
    }
  }

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTAGENTS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTAGENTS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="loader-wrap">
              <img src={`${localStorage.getItem("globalLoader")}`} />
            </div>
          )}
          <div className="row g-3 mb-4">
            <div className="col-lg-4">
              <label className="form-label">Agent Name <sup className="text-danger">*</sup></label>
              <input
                className="form-control form-icon-input"
                placeholder="Agent Name"
                maxLength={50}
                type="text"
                {...register("agentName")}
              />
              {errors.agentName && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.agentName.message}
                </span>
              )}
            </div>
            <div className="col-lg-4 form-icon-container for_password">
              <label className="form-label">Agent Phone <sup className="text-danger">*</sup></label>
              <Controller
                name="agentPhone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    country={flag}
                    priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                  />
                )}
              />
              {errors.agentPhone && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.agentPhone.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Agent Company</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Agent Company"
                {...register("agentCompany")}
              />
              {errors.agentCompany && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.agentCompany.message}
                </span>
              )}
            </div>

            <AddressForm
              errors={errors}
              onHandleChangeAddressLine={handleChangeAddressLine}
              register={register}
              setValue={setValue}
              setCountryCode={setCountryCode}
              onAddressChange={updateCountryCode}
              onSetAddress={setAddress}
              addressLine2={true}
              addressFull={true}
              agentAddress={true}
              required={false}
              watch={watch}
              latLongMap={false}
              trigger={trigger}
            />

            <div className="col-lg-6">
              <label className="form-label">Agent Email</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Agent Email"
                {...register("agentEmail")}
              />
              {errors.agentEmail && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.agentEmail.message}
                </span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">Open House</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Open House"
                {...register("openHouse")}
              />
              {errors.openHouse && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.openHouse.message}
                </span>
              )}
            </div>
            <div className="col-lg-12">
              <label className="form-label">Disclaim Text</label>
              <textarea
                className="form-control form-icon-input"
                maxLength={maxWordCount}
                placeholder="Disclaim Text"
                {...register("disclaimertext", {
                  onChange: (e) =>
                    setRemainingCount(
                      wordCounter(e.target.value, maxWordCount)
                    ),
                })}
              />
              {remainingCount != maxWordCount && (
                <div
                  className={remainingCount <= 0 ? "text-danger" : ""}
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    width: "100%",
                    fontSize: "12px",
                  }}
                >
                  {remainingCount <= 0
                    ? "Maximum word limit reached"
                    : `Remaining words: ${remainingCount}`}
                </div>
              )}
              {errors.disclaimertext && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.disclaimertext.message}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
            <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
            <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
            <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
          </div>
        </form>
      </div>
    </>
  );
};
export default Agents;
