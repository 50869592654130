import { useEffect, useState, useRef } from "react"
import axios from "axios"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import { GETSELLERCOMMISSIONSUMMARY, GETALLAUCTIONLIST } from "../../common/apiConstatnts";
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import MomentUtil from "../../Utils/MomentUtil"
import jsPDF from "jspdf";
import 'jspdf-autotable';
import handleError from "../../Utils/ApiCommonErrorMessages"
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { generateSellerCommissionSummaryCsv, generateSellerCommissionSummaryPdf } from "./ExportReportsPdfCsvFile"
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align"
import { Bold } from "ckeditor5"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Commission Summary Report', url: routeConstant.SELLERCOMMISSIONSUMMARY }
]

const SellerCommissionSummaryReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
    const [loading, setLoading] = useState(false);
    const [sellerCommissionSummary, setSellerCommissionSummary] = useState<any[]>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [auctionCode, setAuctionCode] = useState('')
    const tableRef:any = useRef(null);
    let AuctionStartDate = sellerCommissionSummary?.length > 0 ? sellerCommissionSummary[0].auctionStartDate : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');
    
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const [selectedAuction, setSelectedAuction] = useState<any>({});


    const getSellerSummaryData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${GETSELLERCOMMISSIONSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumResp = response.data.data
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    setSellerCommissionSummary([])
                    return;
                } else {
                    setSellerCommissionSummary(sellerSumResp);
                    setLoading(false)
                }
            }).catch((error)=>{
                setLoading(false)
                handleError(error);
            })
        }       
    }

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerSummaryData(selectedAuction.value)
        }
    }, [selectedAuction])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const totals = {
        totalExtPrice: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.totalExtPrice), 0),
        sold: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.sold), 0),
        passed: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.passed), 0),
        buyBack: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.buyBack), 0),
        unSold: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.unSold), 0),
        commTotal: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.commTotal), 0),
        adjust: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.adjust), 0),
        payments: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.payments), 0),
        net: sellerCommissionSummary.reduce((acc, item) => acc + parseFloat(item.net), 0),
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const formatAmount1 = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? formattedNumber : `(${formattedNumber})`;
    };
    
    
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Commission Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                    <div className="col-lg-8">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" onClick={()=> generateSellerCommissionSummaryCsv(sellerCommissionSummary)}
                                        disabled={sellerCommissionSummary.length == 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3 ms-2" onClick={()=> generateSellerCommissionSummaryPdf(sellerCommissionSummary)}
                                        disabled={sellerCommissionSummary.length == 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                            {sellerCommissionSummary.length === 0 ?
                            getNoDataView() : 
                            <>
                            <div className="table-responsive">
                                <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                    <tbody>
                                        <tr>
                                            <td valign="top" style={{ border: 0, width: '100%' }}>
                                            <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Seller Commission Summary Report</p>
                                            <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                            <span>  {`Auction #${
                                                selectedAuction 
                                                ? selectedAuction?.label?.split('-')[0]
                                                : auctionCode 
                                                    ? hammerAuction 
                                                    : hammerAuction?.label?.split('-')[0]
                                            }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                            </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                                <table className="w-100" ref={tableRef} style={{ borderCollapse: 'collapse' }}>
                                    <thead>
                                        <td colSpan={11} style={{ fontSize: '10px', borderTop: "0",  padding: "0",}}>
                                            <table className="w-100" style={{ borderCollapse: 'collapse' }}>
                                                <tbody>
                                                    <tr>
                                                        <th style={{ fontSize: '10px', width: "5%", }}><div></div></th>
                                                        <th style={{ fontSize: '10px', width: "9%", }}><div></div></th>
                                                        <th style={{ fontSize: '10px', width: "9%", textAlign: 'right' }}><div></div></th>
                                                        <th className="border" style={{ fontSize: '12px', textAlign: 'center', width: "36%", whiteSpace: "nowrap", }} colSpan={4}><div>{"<-Commissions->"}</div></th>
                                                        <th style={{ fontSize: '10px', width: "9%", textAlign: 'right' }}><div></div></th>
                                                        <th style={{ fontSize: '10px', width: "9%", textAlign: 'right' }}><div></div></th>
                                                        <th style={{ fontSize: '10px', width: "9%", textAlign: 'right' }}><div></div></th>
                                                        <th style={{ fontSize: '10px', width: "14%", textAlign: 'right' }}><div></div></th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <td colSpan={11} style={{ fontSize: '10px', borderTop: "0",  padding: "0",}}>
                                                <table className="w-100" style={{ borderCollapse: 'collapse' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ fontSize: '12px', backgroundColor: '#e1e1e1', width: "5%", whiteSpace: "nowrap",  }}>#</th>
                                                            <th style={{ fontSize: '12px', backgroundColor: '#e1e1e1', width: "9%", }}>Seller Name</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#e1e1e1'  }}>Total Ext Price</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#efefef'  }}>Sold</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#efefef'  }}>Passed</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#efefef'  }}>Buyback</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#efefef'  }}>Non-Payer</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#e1e1e1'  }}>Comm Total</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#e1e1e1' }}>Adjusts</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", backgroundColor: '#e1e1e1'  }}>Payments</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", backgroundColor: '#e1e1e1'  }}>NET</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={11} style={{ fontSize: '10px', borderTop: "0",  padding: "0",}}>
                                                <table className="w-100" style={{ borderCollapse: 'collapse' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th colSpan={2} style={{ fontSize: '12px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", }}>Totals</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount(totals.totalExtPrice)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.sold)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.passed)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.buyBack)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.unSold)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.commTotal)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.adjust)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.payments)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", }}>${formatAmount(totals.net)}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { sellerCommissionSummary?.map((item:any, index:any) => {
                                            return (                                   
                                                <tr key={index}>
                                                    <td colSpan={11} style={{ fontSize: '10px', borderTop: "0",  padding: "0",}}>
                                                        <table className="w-100" style={{ borderCollapse: 'collapse' }}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "5%", whiteSpace: "nowrap", }}>{item.sellerId}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%",}}>{item.sellerName}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount(item.totalExtPrice)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.sold)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.passed)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.buyBack)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.unSold)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.commTotal)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.adjust)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "9%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount1(item.payments)}</td>
                                                                    <td style={{ fontSize: '11px', border: "0", width: "14%", whiteSpace: "nowrap", textAlign: 'right' }}>${formatAmount(item.net)}</td>
                                                                </tr>
                                                                <tr style={{backgroundColor: "#fff", padding: "0", border: "0"}}>
                                                                    <td style={{ fontSize: '11px', borderTop: "0",  padding: "0",}}></td>
                                                                    <td colSpan={10} style={{ fontSize: '11px', borderTop: "0",  padding: "0", whiteSpace: "nowrap", paddingLeft:'12px'}}>
                                                                        {item.company}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={11} style={{borderTop: "0",  padding: "0",}}>
                                                <table className="w-100" style={{ borderCollapse: 'collapse' }}>
                                                    <tbody>
                                                        <tr>
                                                            {/* <th colSpan={2} style={{ fontSize: '10px', textAlign: 'right' }}></th> */}
                                                            <th colSpan={3} style={{ fontSize: '12px', textAlign: 'right', width: "23%", whiteSpace: "nowrap", }}>Total Ext Price</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Sold</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Passed</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Buyback</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Non-Payer</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Comm Total</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Adjusts</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>Payments</th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", }}>NET</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={2} style={{ fontSize: '12px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", }}>Totals</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount(totals.totalExtPrice)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.sold)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.passed)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.buyBack)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.unSold)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.commTotal)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.adjust)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "9%", whiteSpace: "nowrap", }}>${formatAmount1(totals.payments)}</th>
                                                            <th style={{ fontSize: '11px', textAlign: 'right', width: "14%", whiteSpace: "nowrap", }}>${formatAmount(totals.net)}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            </>
                            }                
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerCommissionSummaryReport