import axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { GETINVOICEFOOTERBYAUCTIONID, UPDATEINVOICEFOOTER } from "../../../../common/apiConstatnts";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInvoiceActive, setAuctionInvoiceSettingActive, setCloneTab, setCreateTab } from "../../../../store/actions/AuctionAction";
import { loader } from "../../../../store/actions/CommonAction";
import { INVOICEFOOTER } from "../../AuctionConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../../../Utils/CustomCkEditor";

const InvoiceFooter = () => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const dispatch = useDispatch()
    const auctionId: any = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const [isInvoice, setIsInvoice] = useState<boolean>(false)
    const [invoiceFooter, setInvoiceFooter] = useState<any>({})
    const [editor, setEditor] = useState<any>()
    const [ckData, setCkData] = useState<any>("")
    const [btnTxt, setBtnTxt] = useState<any>("")
    const [isDescError, setDescError] = useState<boolean>(false)

    // const onEditorChange = (e: any) => {
    //     setDescError(false)
    //     setCkData(e.editor.getData())
    // }

    // const setCkEditor = () => {
    //     setEditor(
    //         <CKEditor
    //             name="description"
    //             initData={invoiceFooter?.description}
    //             onChange={(e: any) => onEditorChange(e)}
    //             config={{
    //                 extraPlugins: 'scayt',
    //                 scayt_autoStartup: true,
    //                 toolbar: [
    //                     { name: 'clipboard', items: ['Cut', 'Copy', 'Link'] },
    //                     { name: 'document', items: ['Undo', 'Redo',] },
    //                     { name: 'styles', items: ['Bold', 'Italic'] },
    //                     { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Blockquote', 'Indent', 'Outdent', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'HorizontalRule'] },
    //                     { name: 'styles', items: ['Styles', 'Format'] },
    //                 ]
    //             }}
    //             onInstanceReady={(event) => {
    //                 event.editor.on("beforeCommandExec", function (event: any) {
    //                     // Show the paste dialog for the paste buttons and right-click paste
    //                     if (event.data.name == "paste") {
    //                         event.editor._.forcePasteDialog = true;
    //                     }
    //                     // Don't show the paste dialog for Ctrl+Shift+V
    //                     if (event.data.name == "pastetext" && event.data.commandData.from == "keystrokeHandler") {
    //                         event.cancel();
    //                     }
    //                 })
    //             }}
    //         />
    //     )
    // }

    const handlePrev = () => {
        dispatch(setAuctionInvoiceActive({ activeStep: 1, activeLabel: "Auto Invoicing" }))
    }

    const handleNext = () => {
        dispatch(setAuctionInvoiceSettingActive({ activeStep: 2, activeLabel: "Seller Report Email Setup" }))
    }

    const hadleSave = () => {
        if (ckData === "") {
            setDescError(true)
            return
        }
        dispatch(loader(true))
        const payload = {
            companyId: localStorage.getItem('companyId'),
            invoiceFooterId: invoiceFooter?.invoiceFooterId ? invoiceFooter?.invoiceFooterId : 0,
            isActive: true,
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            description: ckData,
        }
        axios.post(UPDATEINVOICEFOOTER, payload, config).then((response) => {
            dispatch(loader(false))
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, INVOICEFOOTER]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, INVOICEFOOTER]))
                }
                toast.success(AuctionMessages.invoiceMessages.invoiceFooterUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            handleNext()
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    // useEffect(() => {
    //     if (isInvoice) {
    //         setCkEditor()
    //     }
    // }, [isInvoice])

    useEffect(() => {
        dispatch(loader(true));
        const aId = clonedTabs.includes(INVOICEFOOTER) ? cloneAuctionId : auctionId
        axios.get(`${GETINVOICEFOOTERBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                setIsInvoice(true)
                dispatch(loader(false))
                return;
            }
            else {
                dispatch(loader(false));
                let respData = response.data.data
                if (!clonedTabs.includes(INVOICEFOOTER) && cloneAuctionId > 0) {
                    respData = { ...respData, invoiceFooterId: 0 }
                }
                setInvoiceFooter(respData)
                setCkData(respData.description)
                setIsInvoice(true)
            }
        }).catch((error) => {
            setIsInvoice(false);
            dispatch(loader(false));
            handleError(error);
        })
    }, [])

    // to render btn txt
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(INVOICEFOOTER)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(INVOICEFOOTER)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    const onEditorckDataChange = (value: any) => {
        setDescError(false)
        setCkData(value)
    }

    return (
        <div className="mb-5">
            <h5 className="mb-2 mt-3">Description <sup className="text-danger">*</sup></h5>
            <Tabs
                defaultActiveKey="source"
                className="mb-3 nav-underline border-bottom "
            >
                <Tab eventKey="source" title="Source">
                    {/* {editor} */}
                    {/* Add custom ckeditor */}
                    <CustomCkEditor
                        data={ckData}
                        onTextChanged={onEditorckDataChange} />
                </Tab>
                <Tab eventKey="preview" title="Preview">
                    <div className="card box-shadow">
                        <div className="card-body mb-5">
                            {parse(ckData)}
                        </div>
                    </div>
                </Tab>
            </Tabs>
            {isDescError && <span className='text-danger' style={{ fontSize: "12px" }}>{"Description is required."}</span>}
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button className="btn btn-primary ms-2" type="button" onClick={hadleSave}>{btnTxt}</button>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div>
        </div>
    )
}

export default InvoiceFooter