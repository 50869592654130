import axios from 'axios';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './assets/css/theme.scss';
import './assets/css/custom.scss';
import { errorMessages } from './common/messageConstants';
import * as routeConstant from './common/routeConstants';
import AddNewLot from './components/AddNewLots/AddNewLots';
import AuctionListing from './components/Auction/index';
import NewAuction from './components/Auction/NewAuction/index';
import AuctionBidListing from './components/AuctionBid/AuctionBid';
import CreateAuctionBid from './components/AuctionBid/CreateAuctionBid';
import AuctionDefault from './components/AuctionDefault/AuctionDefault';
import AutomateEmail from './components/AutomateEmail/AutomateEmail';
import BidderConfig from './components/BidderConfiguration/BidderConfig';
import BidIncrement from './components/BidIncrement/BidIncrement';
import BuyerPremiumSalesTax from './components/BuyerPremiumSalesTax/BuyerPremiumSalesTax.';
import BuyerSeller from './components/BuyerSeller/BuyerSellerPage';
import BuyersPremiumByScales from './components/BuyersPremiumByScales/BuyersPremiumByScales';
import Categories from './components/Categories/Categories';
import CompanyProfile from './components/Company/Companyprofile';
import CustomerListing from './components/Customers/CustomerListing';
import MoreInfoCustomers from './components/Customers/MoreInfo/MoreInfo';
import Feature from "./components/Dashboard/Dashboard";
import EInvoiceSetting from './components/EInvoiceSetting/EinvoiceSetting';
import EmailHeaderFooter from './components/Email-Header-Footer/EmailHeaderFooter';
import EmailSetting from './components/Email-Setting/EmailSetting';
import ImageConfig from './components/ImageConfiguration/ImageConfig';
import Inventory from './components/Inventory';
import NewInventory from './components/Inventory/NewInventory';
import RenumberLots from './components/Inventory/RenumberLots';
import CreateInvoice from './components/Invoice/CreateInvoice';
import Invoice from './components/Invoice/index';
import LotTempPreview from './components/LotTempDemo/LotTempPreview';
import CloneLotTemplate from './components/LotTemplates/CloneLotTemplate';
import CreateLotTemplate from './components/LotTemplates/CreateLotTemplate';
import EditLotTemplate from './components/LotTemplates/EditLotTemplate';
import LotTemplates from './components/LotTemplates/LotTemplates';
import NotFound from './components/NotFound/NotFound';
import PrivacyPolicy from './components/PrivacyPolicies/PrivacyPolicies';
import AllSellerSummaryReport from './components/Reports/AllSellerSummaryReport';
import CommsionSummaryReport from './components/Reports/CommissionSummaryReport';
import ImportLogs from './components/Reports/ImportLogs';
import LienholderReport from './components/Reports/LienholderReport';
import SalesRepCommissionReport from './components/Reports/SalesRepCommissionReport';
import SellerAdjustmentReport from './components/Reports/SellerAdjustmentReport';
import SellerAuctionSummaryReport from './components/Reports/SellerAuctionSummaryReport';
import SellerCommissionReport from './components/Reports/SellerCommissionReport';
import SellerCommissionSummaryReport from './components/Reports/SellerCommissionSummaryReport';
import SellerOverAllReport from './components/Reports/SellerOverAllReport';
import SellerPaymentsSummaryReport from './components/Reports/SellerPaymentsSummaryReport';
import SellerSummaryReport from './components/Reports/SellerSummaryReport';
import SellerPickupSummaryReport from './components/Reports/SellerPickupSummaryReport';
import SuspendReport from './components/Reports/SuspendReport';
import TitleReport from './components/Reports/TitleReport';
import TransportListing from './components/Reports/TransportListing';
import Role from './components/Role/Role';
import SalesRecordListing from './components/SalesRecords/index';
import MoreSalesInfo from './components/SalesRecords/MoreInfo/index';
import MoreInfo from './components/SalesRepresentative/MoreInfo';
import SalesRepresentative from './components/SalesRepresentative/SalesRepresentative';
import Seller from './components/Seller/index';
import MoreSellerDetails from './components/Seller/MoreDetails';
import SellerCommission from './components/SellerCommission/SellerCommission';
import SellerReport from './components/SellerReport/SellerReport';
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";
import SMSTemplatesListing from './components/SMSTemplate';
import AddEditSMSTemplate from './components/SMSTemplate/AddEditSMSTemplate';
import Avalara from './components/TaxCurrency/Avalara';
import TaxRate from './components/TaxCurrency/TaxRate';
import Terms from './components/Terms/Terms';
import TimeZoneIdentifier from './components/TimeZoneIdentifier/TimeZoneIdentifier';
import UnderMaintananace from './components/UnderMaintanance/UnderMaintananace';
import User from './components/User/User';
import ValueDefault from './components/ValueDefault/ValueDefault';
import { persistor, store } from './store/store';
import ScrollToTop from './Utils/ScrollToTop';
import { DateTimeProvider } from './Utils/useDateTime';
import FictitiousBidderReport from './components/Reports/FictitiousBidderReport';
import Reports from './components/Reports';
import InventoryBySellerReport from './components/Reports/InventoryBySellerReport';
import AutoTitleReport from './components/Reports/AutoTitleReport';
import handleError from './Utils/ApiCommonErrorMessages';
import TaxSummaryByJurisdiction from './components/Reports/TaxSummaryByJurisdiction';
import BalanceDueByBidder from './components/Reports/BalanceDueByBidder';
// import { TourProvider } from '@reactour/tour';
// import { tourSteps } from './common/constants';
import CoreTemplate from './components/Templates/CoreTemplate';
import SummaryOfSellerBySalesRep from './components/Reports/SummaryOfSellerBySalesRep';
import InvoiceSummaryReport from './components/Reports/InvoiceSummaryReport';
import TaxExemptSummary from './components/Reports/TaxExemptSummaryReport';

function PrivateRoutes() {
    const location = useLocation();
    const authLogin = localStorage.getItem('token');
    const navigate = useNavigate();

    const handleSessionTimeOut = () => {
        localStorage.removeItem("sidebarOpen")
        localStorage.removeItem("logo")
        localStorage.removeItem("token")
        localStorage.removeItem("companyId")
        if (isMaintenanceMode) {
            navigate('/maintananace');
        } else {
            navigate(routeConstant.SIGNIN);
            toast.error(errorMessages?.sessionTimeOut, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

    if (isMaintenanceMode) {
        return <Navigate to="/maintananace" replace state={{ from: location }} />;
    }

    axios.interceptors.request.use(
        (req) => {
            if (localStorage.getItem('token')) {
                req.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            }
            req.headers['Access-Control-Allow-Origin'] = `*`
            req.headers['Access-Control-Allow-Headers'] = `*`
            req.headers['Access-Control-Allow-Credentials'] = `true`
            return req;
        }, (error) => {
            if (error?.response?.status === 401 && window.location.pathname !== routeConstant.SIGNIN) {
                handleSessionTimeOut()
            }
            return Promise.reject(error);
        }

    );

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error?.response?.status === 401 && window.location.pathname !== routeConstant.SIGNIN) {
                handleSessionTimeOut()
            }
            return Promise.reject(error);
        }
    );

    return (
        isMaintenanceMode ? <Navigate to="/maintananace" replace state={{ from: location }} /> :
            authLogin ? <Outlet /> : <Navigate to="/signin" replace state={{ from: location }} />
    );
}

ReactDOM.render(
    <>

        {/* <TourProvider steps={tourSteps} showBadge={false}
            showNavigation={false}
            showPrevNextButtons={false}
            showDots={false}
            styles={{
                popover: (base) => ({
                    ...base,
                    borderRadius: 10,
                }),
                close: (base) => ({ ...base, top: 10, right: 11, left: 'auto', }),
                maskArea: (base) => ({ ...base, rx: 24 }),
            }}
            disableInteraction> */}
        <App>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ToastContainer />
                    <Router>
                        <DateTimeProvider>
                            <ScrollToTop />
                            <Routes>
                                <Route path='/maintananace' element={<UnderMaintananace />} />
                                <Route path="*" element={<NotFound />} />
                                <Route path={'/'} element={<Signin />} />
                                <Route path={routeConstant.SIGNIN} element={<Signin />} />
                                <Route path={routeConstant.SIGNUP} element={<Signup />} />
                                <Route path={routeConstant.FORGOTPASSWORD} element={<Signin />} />
                                <Route path={routeConstant.RESETPASSWORD} element={<Signin />} />
                                <Route element={<PrivateRoutes />}>
                                    <Route path={routeConstant.DASHBOARD} element={<Feature />} />
                                    <Route path={routeConstant.USER} element={<User />} />
                                    <Route path={routeConstant.ROLE} element={<Role />} />
                                    <Route path={routeConstant.COMPANYPROFILE} element={<CompanyProfile />} />
                                    <Route path={routeConstant.CATEGORIES} element={<Categories />} />
                                    <Route path={routeConstant.EMAILSET} element={<EmailSetting />} />
                                    <Route path={routeConstant.AUTOEMAIL} element={<AutomateEmail />} />
                                    <Route path={routeConstant.EMAILHEADERFOOTER} element={<EmailHeaderFooter />} />
                                    <Route path={routeConstant.SELLERREPORT} element={<SellerReport />} />
                                    <Route path={routeConstant.IMAGECONFIG} element={<ImageConfig />} />
                                    <Route path={routeConstant.BIDDERCONFIG} element={<BidderConfig />} />
                                    <Route path={routeConstant.LOTTEMPLATES} element={<LotTemplates />} />
                                    <Route path={routeConstant.CREATELOTTEMPLATES} element={<CreateLotTemplate />} />
                                    <Route path={`${routeConstant.EDITLOTTEMPLATES}/:id?`} element={<EditLotTemplate />} />
                                    <Route path={`${routeConstant.CLONELOTTEMPLATES}/:id?`} element={<CloneLotTemplate />} />
                                    <Route path={routeConstant.EINVOICESETTING} element={<EInvoiceSetting />} />
                                    <Route path={routeConstant.AUCTIONDEFAULT} element={<AuctionDefault />} />
                                    <Route path={routeConstant.SALESREPRESENTATIVE} element={<SalesRepresentative />} />
                                    <Route path={`${routeConstant.SALESREPRESENTATIVEMOREINFO}/:id?`} element={<MoreInfo />} />
                                    <Route path={routeConstant.BIDINCREMENT} element={<BidIncrement />} />
                                    <Route path={routeConstant.VALUEDEFAULT} element={<ValueDefault />} />
                                    <Route path={routeConstant.BPSALESTAX} element={<BuyerPremiumSalesTax />} />
                                    <Route path={routeConstant.COMMISSION} element={<SellerCommission />} />
                                    <Route path={`${routeConstant.LOTTEMPLATESPREVIEWPAGE}/:id?`} element={<LotTempPreview />} />
                                    <Route path={routeConstant.SELLER} element={<Seller />} />
                                    <Route path={`${routeConstant.SELLERMOREINFO}/:id?`} element={<MoreSellerDetails />} />
                                    <Route path={routeConstant.BUYERSPREMIUMBYSCALES} element={<BuyersPremiumByScales />} />
                                    <Route path={routeConstant.CUSTOMERS} element={<CustomerListing />} />
                                    <Route path={`${routeConstant.CUSTOMERSMOREINFO}/:id?`} element={<MoreInfoCustomers />} />
                                    <Route path={routeConstant.SALESRECORDS} element={<SalesRecordListing />} />
                                    <Route path={`${routeConstant.SALESMOREINFO}/:id?`} element={<MoreSalesInfo />} />
                                    <Route path={`${routeConstant.AUCTION}`} element={<AuctionListing />} />
                                    <Route path={`${routeConstant.NEWAUCTION}`} element={<NewAuction />} />
                                    <Route path={`${routeConstant.EDITAUCTION}/:id?`} element={<NewAuction />} />
                                    <Route path={`${routeConstant.CLONEAUCTION}/:id?`} element={<NewAuction />} />
                                    <Route path={`${routeConstant.INVENTORY}`} element={<Inventory />} />
                                    <Route path={`${routeConstant.RENUMBERLOTS}`} element={<RenumberLots />} />
                                    <Route path={`${routeConstant.ADDNEWINVENTORY}`} element={<NewInventory />} />
                                    <Route path={`${routeConstant.UPDATEINVENTORY}/:id?`} element={<NewInventory />} />
                                    <Route path={`${routeConstant.CLONEINVENTORY}/:id?`} element={<NewInventory />} />
                                    <Route path={`${routeConstant.ADDNEWLOT}`} element={<AddNewLot />} />
                                    <Route path={`${routeConstant.TRANSPORT}`} element={<TransportListing />} />
                                    <Route path={`${routeConstant.INVOICE}`} element={<Invoice />} />
                                    <Route path={`${routeConstant.CREATEINVOICE}`} element={<CreateInvoice />} />
                                    <Route path={`${routeConstant.UPDATEINVOICE}/:id?`} element={<CreateInvoice />} />
                                    <Route path={`${routeConstant.AVALARA}`} element={<Avalara />} />
                                    <Route path={`${routeConstant.TAXRATE}`} element={<TaxRate />} />
                                    <Route path={routeConstant.AUCTIONTEMPLATES} element={<CoreTemplate templateTypeName="Auction" />} />
                                    <Route path={routeConstant.REPORTTEMPLATES} element={<CoreTemplate templateTypeName="Report" />} />
                                    <Route path={routeConstant.INVOICETEMPLATES} element={<CoreTemplate templateTypeName="Invoice" />} />
                                    <Route path={routeConstant.SMSTEMPLATES} element={<CoreTemplate templateTypeName="SMS" />} />
                                    <Route path={routeConstant.SELLERTEMPLATES} element={<CoreTemplate templateTypeName="Seller" />} />
                                    <Route path={routeConstant.SALESREPTEMPLATES} element={<CoreTemplate templateTypeName="SalesRep" />} />
                                    <Route path={routeConstant.INVENTORYTEMPLATES} element={<CoreTemplate templateTypeName="Inventory" />} />
                                    <Route path={routeConstant.SALESRECORDTEMPLATES} element={<CoreTemplate templateTypeName="SalesRecord" />} />
                                    <Route path={routeConstant.CUSTOMERTEMPLATES} element={<CoreTemplate templateTypeName="Buyer" />} />
                                    <Route path={routeConstant.CONTACTUSTEMPLATES} element={<CoreTemplate templateTypeName="ContactUs" />} />
                                    <Route path={routeConstant.APPRAISALTEMPLATES} element={<CoreTemplate templateTypeName="Appraisal" />} />
                                    <Route path={routeConstant.PARTNERTEMPLATES} element={<CoreTemplate templateTypeName="Partners" />} />
                                    <Route path={routeConstant.AUTHORIZATIONTEMPLATES} element={<CoreTemplate templateTypeName="Authorization" />} />
                                    <Route path={routeConstant.EMAILTEMPLATES} element={<CoreTemplate templateTypeName="Email" />} />

                                    <Route path={routeConstant.ADDEMAILTEMPLATES} element={<CoreTemplate templateTypeName="Email" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITEMAILTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Email" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDAUTHORIZATIONTEMPLATES} element={<CoreTemplate templateTypeName="Authorization" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITAUTHORIZATIONTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Authorization" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDSMSTEMPLATES} element={<CoreTemplate templateTypeName="SMS" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITSMSTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="SMS" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDSELLERTEMPLATES} element={<CoreTemplate templateTypeName="Seller" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITSELLERTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Seller" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDSALESREPTEMPLATES} element={<CoreTemplate templateTypeName="SalesRep" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITSALESREPTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="SalesRep" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDINVENTORYTEMPLATES} element={<CoreTemplate templateTypeName="Inventory" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITINVENTORYTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Inventory" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDSALESRECORDTEMPLATES} element={<CoreTemplate templateTypeName="SalesRecord" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITSALESRECORDTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="SalesRecord" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDCUSTOMERTEMPLATES} element={<CoreTemplate templateTypeName="Buyer" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITCUSTOMERTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Buyer" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDCONTACTUSTEMPLATES} element={<CoreTemplate templateTypeName="ContactUs" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITCONTACTUSTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="ContactUs" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDAPPRAISALTEMPLATES} element={<CoreTemplate templateTypeName="Appraisal" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITAPPRAISALTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Appraisal" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDPARTNERSTEMPLATES} element={<CoreTemplate templateTypeName="Partners" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITPARTNERSTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Partners" isAddEditMode={true} />} />

                                    <Route path={routeConstant.ADDAUCTIONTEMPLATES} element={<CoreTemplate templateTypeName="Auction" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITAUCTIONTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Auction" isAddEditMode={true} />} />
                                    <Route path={routeConstant.ADDREPORTTEMPLATES} element={<CoreTemplate templateTypeName="Report" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITREPORTTEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Report" isAddEditMode={true} />} />
                                    <Route path={routeConstant.ADDINVOICETEMPLATES} element={<CoreTemplate templateTypeName="Invoice" isAddEditMode={true} />} />
                                    <Route path={`${routeConstant.EDITINVOICETEMPLATES}/:id`} element={<CoreTemplate templateTypeName="Invoice" isAddEditMode={true} />} />
                                    <Route path={routeConstant.IMPORTLOGS} element={<ImportLogs />} />
                                    <Route path={routeConstant.SUSPENDREPORT} element={<SuspendReport />} />
                                    <Route path={routeConstant.LIENHOLDERREPORT} element={<LienholderReport />} />
                                    <Route path={routeConstant.TITLEREPORT} element={<TitleReport />} />
                                    <Route path={routeConstant.AUCTIONBID} element={<AuctionBidListing />} />
                                    <Route path={routeConstant.NEWAUCTIONBID} element={<CreateAuctionBid />} />
                                    <Route path={routeConstant.SELLERSUMMARYREPORT} element={<SellerSummaryReport />} />
                                    <Route path={routeConstant.SELLERAUCTIONSUMMARYREPORT} element={<SellerAuctionSummaryReport />} />
                                    <Route path={routeConstant.SELLERCOMMISSIONREPORT} element={<SellerCommissionReport />} />
                                    <Route path={routeConstant.COMMISSIONSUMMARYREPORT} element={<CommsionSummaryReport />} />
                                    <Route path={routeConstant.SALESREPCOMMISSIONREPORT} element={<SalesRepCommissionReport />} />
                                    <Route path={routeConstant.SALLERADJUSTREPORT} element={<SellerAdjustmentReport />} />
                                    <Route path={routeConstant.ALLSELLERSUMMARY} element={<AllSellerSummaryReport />} />
                                    <Route path={routeConstant.SELLERCOMMISSIONSUMMARY} element={<SellerCommissionSummaryReport />} />
                                    <Route path={routeConstant.SELLEROVERALLSUMMARY} element={<SellerOverAllReport />} />
                                    <Route path={routeConstant.SELLERPAYMENTSUMMARY} element={<SellerPaymentsSummaryReport />} />
                                    <Route path={routeConstant.SELLERPICKUPSUMMARY} element={<SellerPickupSummaryReport />} />
                                    <Route path={routeConstant.FICTITIOUSBIDDEREPORT} element={<FictitiousBidderReport />} />
                                    <Route path={routeConstant.INVENTORYBYSELLER} element={<InventoryBySellerReport />} />
                                    <Route path={routeConstant.TAXSUMMARYBYJURISDICTION} element={<TaxSummaryByJurisdiction />} />
                                    <Route path={routeConstant.INVOICESUMMARY} element={<InvoiceSummaryReport />} />
                                    <Route path={routeConstant.TAXEXEMPTSUMMARY} element={<TaxExemptSummary />} />
                                    <Route path={routeConstant.POLICIES} element={<PrivacyPolicy />} />
                                    <Route path={routeConstant.TERMS} element={<Terms />} />
                                    <Route path={routeConstant.BUYERSELLER} element={<BuyerSeller />} />
                                    <Route path={routeConstant.TIMEZONEIDENTIFIER} element={<TimeZoneIdentifier />} />
                                    <Route path={routeConstant.REPORTS} element={<Reports />} />
                                    <Route path={routeConstant.AUTOTITLEREOPRT} element={<AutoTitleReport />} />
                                    <Route path={routeConstant.BALANCEDUEBYBIDDER} element={<BalanceDueByBidder />} />
                                    <Route path={routeConstant.SUMMARYOFSELLERBYSALESREP} element={<SummaryOfSellerBySalesRep />} />
                                </Route>
                            </Routes>
                        </DateTimeProvider>
                    </Router>
                </PersistGate>
            </Provider>
        </App>
        {/* </TourProvider> */}
    </>
    , document.getElementById('root'));


