import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { trimObjectValues } from '../../../../Utils/Utils';
import { GETALLSALESREPCOMMISSIONSCALE, GETALLSELLERCOMMISSIONSCALE, GETCOMMCATBYAUCTIONID, UPDATECOMMISSIONCATEGORY } from '../../../../common/apiConstatnts';
import { AuctionMessages, CommonMessages, errorMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionValueActive, setCloneTab, setCreateTab, setStep } from '../../../../store/actions/AuctionAction';
import { loader } from '../../../../store/actions/CommonAction';
import { COMMISSIONSANDCATEGORIES } from '../../AuctionConstants';
import handleError from '../../../../Utils/ApiCommonErrorMessages';

const ComissionsCategories = () => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  };

  const initialData = {
    valuesCommissionCategoriesId: 0,
    sellerCommissionBasis: 1,
    salesRepresentativeCommissionBasis: 1,
    commissiontaxable: false,
    calculateSellerBuyBackCommision: false,
    sellerCommissionScaleId: 0,
    categoryId: "3",
    taxRateById: 0,
    taxRateOld: "string",
    taxByLocation: false,
    sellerCommissionSoldId: null,
    sellerCommissionPassId: null,
    sellerCommissionBuyBackId: null,
    sellerCommissionNonPayerId: null,
    isActive: true,
    isDelete: false,
    companyId: localStorage.getItem('companyId'),
    salesRepCommissionTaxable: false,
    calculateSalesRepBuyBackCommision: false,
    salesRepCommissionSoldId: null,
    salesRepCommissionPassId: null,
    salesRepCommissionBuyBackId: null,
    salesRepCommissionNonPayerId: null
  }

  const dispatch = useDispatch()
  const auctionId = useSelector((state: any) => state.auction.auctionId)
  const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
  const createTabs = useSelector((state: any) => state.auction.createTabs)
  const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
  const mode = useSelector((state: any) => state.auction.mode)
  const [commissionData, setCommissionData] = useState<any>({});
  const [commissionOptions, setCommissionOptions] = useState<any>({});
  const [srCommissionOptions, setSRCommissionOptions] = useState<any>({});
  const [formValues, setFormValues] = useState<any>(initialData)
  const [isCommResp, setIsCommResp] = useState<boolean>(false)
  const [btnTxt, setBtnTxt] = useState<any>("")

  const schema: any = yup.object().shape({
    sellerCommissionSoldId: yup.string(),
    sellerCommissionPassId: yup.string(),
    sellerCommissionBuyBackId: yup.string(),
    sellerCommissionNonPayerId: yup.string(),
    salesRepCommissionSoldId: yup.string(),
    salesRepCommissionPassId: yup.string(),
    salesRepCommissionBuyBackId: yup.string(),
    salesRepCommissionNonPayerId: yup.string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })

  const sellerCommissionSoldId = watch('sellerCommissionSoldId')
  const sellerCommissionPassId = watch('sellerCommissionPassId')
  const sellerCommissionBuyBackId = watch('sellerCommissionBuyBackId')
  const sellerCommissionNonPayerId = watch('sellerCommissionNonPayerId')
  const salesRepCommissionSoldId = watch('salesRepCommissionSoldId')
  const salesRepCommissionPassId = watch('salesRepCommissionPassId')
  const salesRepCommissionBuyBackId = watch('salesRepCommissionBuyBackId')
  const salesRepCommissionNonPayerId = watch('salesRepCommissionNonPayerId')

  const nextStep = () => {
    // dispatch(setAuctionValueActive({ activeStep: 3, activeLabel: "Action Results Config" }))
    dispatch(setAuctionValueActive({ activeStep: 2, activeLabel: "Action Results Config" }))
  }

  const handlePrevious = () => {
    // dispatch(setAuctionValueActive({ activeStep: 1, activeLabel: "Buyers Premium" }))
    dispatch(setStep(5));
  }

  const onSubmit = (dataOriginal: any) => {
    dispatch(loader(true))
    const data = trimObjectValues(dataOriginal)

    const payload = [
      {
        ...initialData,
        ...data,
        categoryId: data?.categoryId?.toString(),
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
        valuesCommissionCategoriesId: data?.valuesCommissionCategoriesId ?? 0,
        sellerCommissionSoldId: data?.sellerCommissionSoldId || null,
        sellerCommissionPassId: data?.sellerCommissionPassId || null,
        sellerCommissionBuyBackId: data?.sellerCommissionBuyBackId || null,
        sellerCommissionNonPayerId: data?.sellerCommissionNonPayerId || null,
        salesRepCommissionSoldId: data?.salesRepCommissionSoldId || null,
        salesRepCommissionPassId: data?.salesRepCommissionPassId || null,
        salesRepCommissionBuyBackId: data?.salesRepCommissionBuyBackId || null,
        salesRepCommissionNonPayerId: data?.salesRepCommissionNonPayerId || null,
      },
    ];

    axios.post(UPDATECOMMISSIONCATEGORY, payload, config).then((response) => {
      dispatch(loader(false));
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, COMMISSIONSANDCATEGORIES]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, COMMISSIONSANDCATEGORIES]))
        }
        toast.success(AuctionMessages.valuesMessages.commCatUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        })
      }
      nextStep();
    }).catch((error) => {
      dispatch(loader(false));
      handleError(error);
    })
  }

  useEffect(() => {
    if (Object.keys(commissionOptions)?.length) {
      if (Object.keys(commissionData)?.length === 0) {
        setValue("sellerCommissionSoldId", commissionOptions?.sold)
        setValue("sellerCommissionPassId", commissionOptions?.unsold)
        setValue("sellerCommissionBuyBackId", commissionOptions?.buyBack)
        setValue("sellerCommissionNonPayerId", commissionOptions?.nonPayer)
      } else {
        setValue("sellerCommissionSoldId", commissionData?.sellerCommissionSoldId)
        setValue("sellerCommissionPassId", commissionData?.sellerCommissionPassId)
        setValue("sellerCommissionBuyBackId", commissionData?.sellerCommissionBuyBackId)
        setValue("sellerCommissionNonPayerId", commissionData?.sellerCommissionNonPayerId)
      }
    }
  }, [commissionOptions, commissionData])

  useEffect(() => {
    if (Object.keys(srCommissionOptions)?.length) {
      if (Object.keys(commissionData)?.length === 0) {
        setValue("salesRepCommissionSoldId", srCommissionOptions?.sold)
        setValue("salesRepCommissionPassId", srCommissionOptions?.unsold)
        setValue("salesRepCommissionBuyBackId", srCommissionOptions?.buyBack)
        setValue("salesRepCommissionNonPayerId", srCommissionOptions?.nonPayer)
      } else {
        setValue("salesRepCommissionSoldId", commissionData?.salesRepCommissionSoldId)
        setValue("salesRepCommissionPassId", commissionData?.salesRepCommissionPassId)
        setValue("salesRepCommissionBuyBackId", commissionData?.salesRepCommissionBuyBackId)
        setValue("salesRepCommissionNonPayerId", commissionData?.salesRepCommissionNonPayerId)
      }
    }
  }, [srCommissionOptions, commissionData])

  useEffect(() => {
    if (isCommResp) {
      dispatch(loader(true))
      axios.get(GETALLSELLERCOMMISSIONSCALE, config).then((response) => {
        dispatch(loader(false));
        if (response?.data?.success === "Fail") {
          return;
        }
        else {
          const mainData = response?.data?.data?.lstSellerCommissionScale
          const data = response?.data?.data?.lstSellerCommissionScale?.map((item: any) => ({ label: item.taxRegionName, value: item.sellerCommissionScaleId }));
          const sold = mainData?.find((item: any) => item.sellerCommissionSold === true)?.sellerCommissionScaleId;
          const unsold = mainData?.find((item: any) => item.sellerCommissionUnSold === true)?.sellerCommissionScaleId;
          const buyBack = mainData?.find((item: any) => item.sellerCommissionBuyBack === true)?.sellerCommissionScaleId;
          const nonPayer = mainData?.find((item: any) => item.sellerCommissionNonPayer === true)?.sellerCommissionScaleId;
          setCommissionOptions({ list: data, sold: sold, unsold: unsold, buyBack: buyBack, nonPayer: nonPayer });

        }
        dispatch(loader(false));
      }).catch((error) => {
        dispatch(loader(false));
        handleError(error)
      })

      axios.get(GETALLSALESREPCOMMISSIONSCALE, config).then((response) => {
        dispatch(loader(false));
        if (response?.data?.success === "Fail") {
          return;
        }
        else {
          const mainData = response?.data?.data?.lstSalesRepCommissionScale
          const data = response?.data?.data?.lstSalesRepCommissionScale?.map((item: any) => ({ label: item.label, value: item.salesRepCommissionScaleId }));
          const sold = mainData?.find((item: any) => item.salesRepCommissionSold === true)?.salesRepCommissionScaleId;
          const unsold = mainData?.find((item: any) => item.salesRepCommissionUnSold === true)?.salesRepCommissionScaleId;
          const buyBack = mainData?.find((item: any) => item.salesRepCommissionBuyBack === true)?.salesRepCommissionScaleId;
          const nonPayer = mainData?.find((item: any) => item.salesRepCommissionNonPayer === true)?.salesRepCommissionScaleId;

          setSRCommissionOptions({ list: data, sold: sold, unsold: unsold, buyBack: buyBack, nonPayer: nonPayer });
        }
        dispatch(loader(false));
      }).catch((error) => {
        dispatch(loader(false));
        handleError(error);
      })
      reset(commissionData);
      setFormValues(commissionData);
    }
  }, [isCommResp])

  useEffect(() => {
    dispatch(loader(true))
    const aId = clonedTabs.includes(COMMISSIONSANDCATEGORIES) ? cloneAuctionId : auctionId
    axios.get(`${GETCOMMCATBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
      if (response?.data?.success === "Fail") {
        setIsCommResp(true)
        dispatch(loader(false))
        return;
      } else {
        dispatch(loader(false));
        let respData = response.data.data
        if (respData.length > 0) {
          respData = respData[0]
          if (!clonedTabs.includes(COMMISSIONSANDCATEGORIES) && cloneAuctionId > 0) {
            respData = { ...respData, valuesCommissionCategoriesId: 0 }
          }
          setCommissionData(respData)
        }
        setIsCommResp(true)
      }
    }).catch((error) => {
      dispatch(loader(false));
      handleError(error);
    })
  }, [])

  // to render btn txt
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (auctionId > 0 && createTabs.includes(COMMISSIONSANDCATEGORIES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneAuctionId > 0 && clonedTabs.includes(COMMISSIONSANDCATEGORIES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-3">
          <div className="col-lg-12">
            <h4 className='mb-2'>Seller Commissions</h4>
          </div>
          <div className="col-lg-6">
            <label className="form-label">Commission Taxable</label>
            <div className="form-check mt-0">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input"
                  {...register('commissiontaxable')}
                /> Yes</label>

            </div>
          </div>
          <div className="col-lg-6">
            <label className="form-label">Calculate Seller Buy Back Commission on Reserve Price</label>
            <div className="form-check mt-0">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input"
                  {...register('calculateSellerBuyBackCommision')}
                /> Yes</label>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-lg-12">
            <div className="table-responsive scrollbar">
              <table className='table table-bordered mb-0 fs--1'>
                <thead>
                  <tr>
                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Sold</th>
                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Passed/Unsold</th>
                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Buy Back</th>
                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Non Payer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle ps-3 pe-3 pt-2 pb-2">{commissionOptions?.list?.find((option: any) => option?.value === sellerCommissionSoldId)?.label}</td>
                    <td className="align-middle ps-3 pe-3 pt-2 pb-2">{commissionOptions?.list?.find((option: any) => option?.value === sellerCommissionPassId)?.label}</td>
                    <td className="align-middle ps-3 pe-3 pt-2 pb-2">{commissionOptions?.list?.find((option: any) => option?.value === sellerCommissionBuyBackId)?.label}</td>
                    <td className="align-middle ps-3 pe-3 pt-2 pb-2">{commissionOptions?.list?.find((option: any) => option?.value === sellerCommissionNonPayerId)?.label}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className='col-12'>
          <hr></hr>
        </div>
        <div className='my-3'>
          <h4 className='mt-3 mb-2'>Sales Rep Commissions</h4>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label className="form-label">Commission Taxable</label>
              <div className="form-check mt-0">
                <label className="form-check-label"><input type="checkbox" className="form-check-input" {...register('salesRepCommissionTaxable')}
                /> Yes</label>

              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form-label">Calculate Sales Rep Buy Back Commission</label>
              <div className="form-check mt-0">
                <label className="form-check-label"><input type="checkbox" className="form-check-input"  {...register('calculateSalesRepBuyBackCommision')}
                /> Yes</label>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <div className="table-responsive scrollbar">
                <table className='table table-bordered mb-0 fs--1'>
                  <thead>
                    <tr>
                      <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Sold</th>
                      <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Passed/Unsold</th>
                      <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Buy Back</th>
                      <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Non Payer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle ps-3 pe-3 pt-2 pb-2">{srCommissionOptions?.list?.find((option: any) => option?.value === salesRepCommissionSoldId)?.label}</td>
                      <td className="align-middle ps-3 pe-3 pt-2 pb-2">{srCommissionOptions?.list?.find((option: any) => option?.value === salesRepCommissionPassId)?.label}</td>
                      <td className="align-middle ps-3 pe-3 pt-2 pb-2">{srCommissionOptions?.list?.find((option: any) => option?.value === salesRepCommissionBuyBackId)?.label}</td>
                      <td className="align-middle ps-3 pe-3 pt-2 pb-2">{srCommissionOptions?.list?.find((option: any) => option?.value === salesRepCommissionNonPayerId)?.label}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
        <div className='col-12'>
          {/* <hr></hr> */}
        </div>
        {/* <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="mb-3 mt-2">
                <h4>Categories</h4>
              </div>
              <div className="col-md-12 mb-3">
                <div className="mt-2 mb-2">
                  <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Category</label>
                  <Select classNamePrefix="dpaSelect"
                    isMulti
                    placeholder="Select"
                    className=''
                    {...register('categoryId')}
                    name='categoryId'
                    options={filterRoleData}
                    value={filterRoleData.value}
                    onChange={handleSelection}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="table-responsive mx-n1 px-1 scrollbar">
                <table className="table table-bordered table-sm mb-0 fs--1">
                  <thead>
                    <tr>
                      <th className="bg-gradiant " scope="col" data-sort="Status">
                        <div className='d-flex align-items-center'>
                          <span>Tax Rate By ID</span>

                        </div>
                      </th>
                      <th className="bg-gradiant " scope="col" data-sort="Status">
                        <div className='d-flex align-items-center'>
                          <span>Tax Rate (Old)</span>

                        </div>
                      </th>
                      <th className="bg-gradiant " scope="col" data-sort="Status">
                        <div className='d-flex align-items-center'>
                          <span>Tax By Location</span>
                        </div>
                      </th>
                      <th className="bg-gradiant border-bottom text-end align-middle pe-0 border-top" style={{ width: "30px" }} scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list" id="table-latest-review-body">
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="align-middle p-2">test
                      </td>
                      <td className="align-middle p-2">test
                      </td>
                      <td className="align-middle p-2">
                        <input
                          className="form-check-input form-check-states"
                          type="checkbox"
                          name="location"
                        />
                      </td>
                      <td className="align-middle p-2 delete" style={{ width: "30px" }}>
                        <button
                          type="button"
                          className="btn p-0 d-flex align-items-center"

                        ><img src={deleteIcon} height={12} /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
          <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
          <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
          <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
          {/* <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>Skip</button> */}
          <button className="btn btn-secondary ms-2" type="button" onClick={nextStep}>{CommonMessages.skipButton}</button>
        </div>
      </form >
    </>
  )
}

export default ComissionsCategories