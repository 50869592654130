import axios from "axios"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import {  GETSELLERCOMMISSIONDATA, GETSELLERSUMMARYCOMMISSIONREPORT,GETALLAUCTIONLIST  } from "../../common/apiConstatnts"
import { UTCdateConverter } from "../../common/constants"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import MomentUtil from "../../Utils/MomentUtil"
import handleError from "../../Utils/ApiCommonErrorMessages";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { generateSellerCommissionCsv, generateSellerCommissionPdf } from "./ExportReportsPdfCsvFile"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Commission Report', url: routeConstant.SELLERCOMMISSIONREPORT }
]

const SellerCommissionReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
    const [auctionCode, setAuctionCode] = useState('')
    const [loading, setLoading] = useState(false);
    const [sellerCommissionSummaryData, setSellerCommissionSummaryData] = useState<any[]>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    let AuctionStartDate = sellerCommissionSummaryData?.length > 0 ? sellerCommissionSummaryData[0].auctionStartDate : null;
    // AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value == getGlobalAuction.value)
    
    const tableRef:any = useRef(null);

    const getSellerCommissionSummaryData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${GETSELLERSUMMARYCOMMISSIONREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumResp = response?.data
                if (response?.data?.success === "Fail") {
                    setSellerCommissionSummaryData([]);
                    return;
                } else {
                    setLoading(false)
                    setSellerCommissionSummaryData(sellerSumResp?.data)
                }
            }).catch((error)=>{
                setLoading(false)
                handleError(error);
            })
        }     
    }

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerCommissionSummaryData(selectedAuction.value)
        }
    }, [selectedAuction])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const totals = {
        sold: sellerCommissionSummaryData.reduce((acc, item) => acc + (item.sold || 0), 0),
        passed: sellerCommissionSummaryData.reduce((acc, item) => acc + (item.passed || 0), 0),
        buyBack: sellerCommissionSummaryData.reduce((acc, item) => acc + (item.buyback || 0), 0),
        unSold: sellerCommissionSummaryData.reduce((acc, item) => acc + (item.nonPayer || 0), 0),
        commTotal: sellerCommissionSummaryData.reduce((acc, item) => acc + (parseFloat(item.commTotal) || 0), 0),
        adjust: sellerCommissionSummaryData.reduce((acc, item) => acc + (parseFloat(item.adjustTotal) || 0), 0),
        sellerLotCount : sellerCommissionSummaryData.reduce((acc, item) => acc + (item.sellerLotCount || 0), 0),
    };

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Commission Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                    <div className="col-lg-8">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" onClick={()=>generateSellerCommissionCsv(sellerCommissionSummaryData)}
                                        disabled={sellerCommissionSummaryData.length == 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3 ms-2" onClick={()=> generateSellerCommissionPdf(sellerCommissionSummaryData)}
                                       disabled={sellerCommissionSummaryData.length == 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                            {sellerCommissionSummaryData.length === 0 ?
                            getNoDataView() : 
                            <>
                            <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ border: 0, width: '100%' }}>
                                        <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Seller Commission Summary</p>
                                        <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                            <span>  {`Auction #${
                                                selectedAuction 
                                                ? selectedAuction?.label?.split('-')[0]
                                                : auctionCode 
                                                    ? hammerAuction 
                                                    : hammerAuction?.label?.split('-')[0]
                                            }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                        </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                            <table className="w-100 mt-4" ref={tableRef} style={{ borderCollapse: 'collapse'}}>
                            <thead>
                                <tr>
                                <th style={{ fontSize: '12px', backgroundColor: '#e1e1e1'  }}>#</th>
                                <th style={{ fontSize: '12px', backgroundColor: '#e1e1e1'  }}>Seller Name</th>
                                
                                <th style={{ fontSize: '12px', textAlign: 'right',backgroundColor: '#efefef' }}>Sold Count</th>
                                <th style={{ fontSize: '12px', textAlign: 'right',backgroundColor: '#efefef' }}>Passed Count</th>
                                <th style={{ fontSize: '12px', textAlign: 'right',backgroundColor: '#efefef' }}>Buyback Count</th>
                                <th style={{ fontSize: '12px', textAlign: 'right',backgroundColor: '#efefef' }}>Non-Payer Count</th>
                                <th style={{ fontSize: '12px', textAlign: 'right', backgroundColor: '#e1e1e1' }}>Comm Total</th>
                                <th style={{ fontSize: '12px', textAlign: 'right', backgroundColor: '#e1e1e1'  }}>Adjust Total</th>
                                <th style={{ fontSize: '12px', textAlign: 'right', backgroundColor: '#e1e1e1' }}>Seller Lot Count</th>
                                <th style={{ fontSize: '12px', textAlign: 'left', backgroundColor: '#e1e1e1' }}>
                                    Notes
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                { sellerCommissionSummaryData?.map((item:any, index:any) => {
                                    return (                                   
                                        <tr key={index}>
                                            <td style={{ fontSize: '11px' }}>{item.sellerId}</td>
                                            <td style={{ fontSize: '11px' }}>{item.sellerName}</td>
                                            
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>{item.sold}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>{item.passed}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>{item.buyback}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>{item.nonPayer}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>${item.commTotal ? `${formatAmount(item.commTotal)}` : '0.00'}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>${item.adjustTotal ? `${formatAmount(item.adjustTotal)}` : '0.00'}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>{item.sellerLotCount}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'left' }}>{item.notes ? item.notes : '-'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2} style={{ fontSize: '12px', textAlign: 'right',fontWeight: 'bold'  }}> </th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold' }}>Sold Count</th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold' }}>Passed Count</th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold' }}>BuyBack Count</th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold' }}>Non-Payer Count</th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold' }}>Comm Total </th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold'  }}>Adjust Total</th>
                                    <th style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold'  }}>Seller Lot Count</th>
                                    <th style={{ fontSize: '12px', textAlign: 'left', fontWeight: 'bold'  }}>
                                        
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan={2} style={{ fontSize: '12px', textAlign: 'right', fontWeight: 'bold'  }}>Totals</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>{totals.sold}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>{totals.passed}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>{totals.buyBack}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>{totals.unSold}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>${totals.commTotal ? formatAmount(totals.commTotal) : '0.00'}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>${totals.adjust ? formatAmount(totals.adjust) : '0.00'}</th>
                                    <th style={{ fontSize: '11px', textAlign: 'right'}}>{totals.sellerLotCount }</th>
                                    <th style={{ fontSize: '11px', textAlign: 'left'}}>
                                    </th>
                                </tr>
                            </tfoot>
                            </table>
                            </>
                            }                
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerCommissionReport