import axios from "axios";
import "jspdf-autotable";
import moment from "moment";
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { GETALLAUCTIONLIST, GETAUTOTITLEREPORT } from "../../common/apiConstatnts";
import { formatPhoneNumber } from '../../common/constants';
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import handleError from '../../Utils/ApiCommonErrorMessages';
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import { generateAutoTitleCsv, generateAutoTitlePdf } from './ExportReportsPdfCsvFile';

const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Reports", url: routeConstant.REPORTS },
    {
        label: "Auto Title Summary Report",
        url: routeConstant.AUTOTITLEREOPRT,
    },
];

const AutoTitleReport = () => {
    const [loading, setLoading] = useState(false);
    const [auctionList, setAuctionList] = useState<any[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [auctionCode, setAuctionCode] = useState('')
    const [autoTitleReportData, setAutoTitleReportData] = useState<any[]>([]);
    let AuctionStartDate = autoTitleReportData?.length > 0 ? autoTitleReportData[0].auctionDate
        : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');
    const tableRef: any = useRef(null);

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction: any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAutoTitleReportData = (auctionId?: any) => {
        setLoading(true)
        if (auctionId) {
            axios.get(`${GETAUTOTITLEREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const autoTitleReportData = response.data.data;
                if (response?.data?.success === "Fail") {
                    setAutoTitleReportData([]);
                    return;
                } else {
                    setAutoTitleReportData(autoTitleReportData);
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }
    }

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST, config).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        });
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getAutoTitleReportData(selectedAuction.value);
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (hammerAuction) {
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        } 
    }, [hammerAuction])

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatAmount = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const printTable = () => {
        const printWindow: any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const truncateText = (text: any, wordLimit: any) => {
        const plainText = text.replace(/<[^>]*>?/gm, '');
        if (plainText.length > wordLimit) {
            return plainText.slice(0, wordLimit) + '...';
        }
        return plainText;
    }

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Auto Title Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between g-2 mt-1 align-items-end">
                            <div className="col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="col-lg-8">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Auction"
                                        className="isActive swap-to-lot-menu clone-inventory"

                                        options={auctionList}
                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                        value={selectedAuction.value ? auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                        onChange={(e: any) => {
                                            handleAuctionChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 mt-2 ms-auto">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => generateAutoTitleCsv(autoTitleReportData)}
                                        disabled={autoTitleReportData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateAutoTitlePdf(autoTitleReportData)}
                                        disabled={autoTitleReportData.length === 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                            {autoTitleReportData.length === 0 ?
                                getNoDataView() :
                                <>
                                    <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                                    <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Auto Title Summary Report</p>
                                                    <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                        <span>{`Auction #${selectedAuction
                                                            ? selectedAuction?.label?.split('-')[0]
                                                            : auctionCode
                                                                ? hammerAuction
                                                                : hammerAuction?.label?.split('-')[0]
                                                            }`}</span><br /><span>{moment(AuctionStartDate, 'YYYY/MM/DD').format('MM/DD/YYYY')}</span>

                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="table-responsive align-top">                           
                                        <table ref={tableRef} className="w-100 mt-4"
                                            cellPadding={0}
                                            cellSpacing={0}
                                            style={{
                                            borderCollapse: 'collapse'
                                            }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="bg-gradiant p-1" style={{ fontSize: '12px', width:'16%' }}>
                                                        <div>Buyer</div>
                                                    </th>
                                                    <th className="bg-gradiant p-1" style={{ fontSize: '12px',width: "16%" }}>
                                                        <div>Seller</div>
                                                    </th>
                                                    <th className="bg-gradiant p-1"style={{ fontSize: '12px',width: "16%" }}>
                                                        <div>Title Assignment</div>
                                                    </th>
                                                    <th className="bg-gradiant p-1" style={{ fontSize: '12px',width: "10%" }}>
                                                        <div>Lot/Ctrl #</div>
                                                    </th>
                                                    <th className="bg-gradiant p-1" style={{ fontSize: '12px' , width: "10%"}}>
                                                        <div>Status</div>
                                                    </th>
                                                    <th className="bg-gradiant p-1" colSpan={2} style={{ fontSize: '12px',width: "20%" }}>
                                                        <div>Lot Title</div>
                                                    </th>   
                                                    <th className="bg-gradiant p-1" style={{ fontSize: '12px',width: "15%" }}>
                                                        <div></div>
                                                    </th>                  
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {autoTitleReportData?.map((item: any, index: any) => (
                                                <tr>
                                                    <td className="p-1" style={{ fontSize: '11px', width: "16%"}}>
                                                        <div>
                                                            <p style={{margin: 0}}>Bidder: {item.bidderNumber ?? 'N/A'}</p>
                                                            <p style={{margin: 0}}>{item.bidderCompany}</p>
                                                            <p style={{margin: 0}}>{item.bidderName}</p>
                                                            <p style={{margin: 0}}>{item.bidderAddress}</p>
                                                            <p style={{margin: 0, paddingLeft:'8px'}}>W: {item.bidderPhone ? formatPhoneNumber(item.bidderPhone) : 'N/A'}</p>
                                                            <p style={{margin: 0, paddingLeft:'8px'}}>C: {item.bidderPhone2 ? formatPhoneNumber(item.bidderPhone2) : 'N/A'}</p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px',width: "16%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}>Seller: {item.sellerId}</p>
                                                            <p style={{margin: 0}}>{item.sellerCompany}</p>
                                                            <p style={{margin: 0}}>{item.sellerName}</p>
                                                            <p style={{margin: 0}}>{item.sellerAddress}</p>
                                                            <p style={{margin: 0, paddingLeft:'8px'}}>W: {item.sellerPhone ? formatPhoneNumber(item.sellerPhone) : 'N/A'}</p>
                                                            <p style={{margin: 0, paddingLeft:'8px'}}>C: {item.sellerPhone2 ? formatPhoneNumber(item.sellerPhone2) : 'N/A'}</p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px',width: "16%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}>{item.lotTitleAssignmentName ? item.lotTitleAssignmentName : 'N/A'}</p>
                                                            <p style={{margin: 0}}>{item.lotTitleAssignmentCompany ? item.lotTitleAssignmentCompany : ''}</p>
                                                            <p style={{margin: 0}}>{item.lotTitleAssignmentAddress ? item.lotTitleAssignmentAddress : ''}</p>
                                                            <p style={{margin: 0}}>{item.lotTitleAssignmentCityName ? `${item.lotTitleAssignmentCityName} ${item.lotTitleAssignmentStateName} ${item.lotTitleAssignmentCountryName} ${item.lotTitleAssignmentZipCode}` : '' }</p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px',width: "10%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}><b>{item.lotNo}</b></p>
                                                            <p style={{margin: 0}}>{item.ctrlNo}</p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px', width: "10%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}><b>{item.inventoryStatus ? item.inventoryStatus : '-'}</b></p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px',width: "20%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}>{item.lotTitle.replace(/<[^>]*>?/gm, "")}</p><br/><br/>
                                                            <p style={{margin: 0}}>VIN {item.vin1? item.vin1 : '-'}</p>
                                                        </div>
                                                    </td>
                                                    <td className="p-1" style={{ fontSize: '11px',width: "15%" }}>
                                                        <div>
                                                            <p style={{margin: 0}}>Received</p>
                                                            <p style={{margin: 0}}>{item.titleRecived ? moment(item.titleRecived).format("MM/DD/YYYY hh:mm A") : '-'}</p><br/>
                                                            <p style={{margin: 0}}>Sent</p>
                                                            <p style={{margin: 0}}>{item.titleSent ? moment(item.titleSent).format("MM/DD/YYYY hh:mm A") : '-'}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                           </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AutoTitleReport;