import React, { useEffect, useState } from "react";
import ModifiedBy from "../../LastModifiedDateTime/ModifiedBy";
import LastModified from "../../LastModifiedDateTime/LastModifiedDateTime";
import { Link, useParams } from "react-router-dom";
import { CommonMessages, errorMessages } from "../../../common/messageConstants";
import Select from 'react-select'
import { ERROR_MESSAGES, handleKeyDown, isEmptyObject } from "../../../Utils/Utils";
import * as routeConstant from '../../../common/routeConstants';
import axios from "axios";
import parsePhoneNumberFromString from "libphonenumber-js";
import { AddressForm, onGetDefaultValues } from "../../../common/AddressForm"
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { charCountForAddress } from "../../../common/commonFunctions"
import { wordCounter } from "../../../common/constants"
import {
    FIRSTANDLASTNAMEREGEX
} from "../../../common/regexConstants";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { AUCTIONSBYCUSTOMERID, GETCOMMONVALUE, GETSELLER, UPDATECUSTOMERINFOBYAUCTION, GETCUSTOMERINFOBYAUCTION } from "../../../common/apiConstatnts";
import CustomCheckbox from "./MultipleSelectCheckbox";
import auctionImage from "../../../assets/Group 70.svg";
import Swal from "sweetalert2";
import { decryptId } from "../../../Encryption/Encryption";

const AuctionTab = () => {

    const initalData = {
        companyId: localStorage.getItem("companyId"),
        isActive: true,
        isDelete: false,
        customerAuctionInfoId: 0,
        customerId: 0,
        auctionId: 801,
        initialId: 0,
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        phone: "",
        mobile: "",
        fax: "",
        notes: "",
        addressLine1: "",
        addressLine2: "",
        sellerId: "",
        city: 0,
        state: 0,
        country: 0,
        county1: '',
        zipCode: "",
        buyBackBidderOnly: false,
        add1: "",
        zip: ""
    };

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const globalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const [sellerList, setSellerList] = useState<any>([]);
    const customerID = useSelector(
        (state: any) => state.customer.contactInfo?.customerId
    );
    const params = useParams()
    const ID = params.id ? decryptId(params.id) : "";
    const [initialFormValue, setFormValue] = useState<any>(initalData);
    const [loading, setLoading] = useState<boolean>(true);
    const [isSeller, setIsSeller] = useState<boolean>(false);
    const maxWordCount = 150
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [countryCode, setCountryCode] = useState("us");
    const [address, setAddress] = useState("");
    const [selectedSellers, setSelectedSellerList] = useState<any>([]);
    const [auctionsList, setAuctionsList] = useState<any>([]);
    const [selectedAuction, setSelectedAuction] = useState(globalAuction ? globalAuction : {});
    const [initials, setIntials] = useState<[]>([]);
    const auctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);
    const [customerId, setCustomerId] = useState()

    const generatePhoneValidationSchema = () => {
        return yup
            .string()
            .nullable()
            .test("phone-validation", "Invalid phone number", (value) => {
                if (!value) return true;
                try {
                    const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                    if (!phoneNumber || !phoneNumber.isValid()) {
                        return false; // Invalid phone number format
                    }
                    const nationalNumber = phoneNumber.nationalNumber;
                    const countryCodeLength =
                        phoneNumber.countryCallingCode.toString().length;
                    const expectedLength = value.length - countryCodeLength;
                    return nationalNumber.toString().length === expectedLength;
                    return true; // Valid phone number
                } catch (error) {
                    console.error("Error parsing phone number:", error);
                    return false; // Return false if an error occurs during parsing
                }
            });
    };

    const schema: any = yup.object().shape({
        initialId: yup.string(),
        firstName: yup
            .string()
            .required("First name is required")
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, "Please enter valid first name")
            .max(20, "Please enter upto 20 characters"),
        lastName: yup
            .string()
            .required("Last name is required")
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, "Please enter valid last name")
            .max(20, "Please enter upto 20 characters"),
        middleName: yup
            .string()
            .notRequired()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test("alphanumeric-dot", "Please enter valid middle name", (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),

        company: yup
            .string()
            .nullable()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        mobile: generatePhoneValidationSchema().required("Phone number is required."),
        phone: generatePhoneValidationSchema().nullable(),
        fax: yup
            .number()
            .nullable()
            .typeError("Fax must be a number")
            .transform((value, originalValue) => {
                return originalValue === "" ? undefined : value;
            })
            .nullable(),
        country1: yup.string().required("Country is required"),
        state1: yup.string().required("State is required"),
        city1: yup.string().required("City is required"),
        add1: yup.string().required("Address Line 1 is required"),
        zip: yup
            .string()
            .required("Zip code is required")
            .test("no-blank-space", "Blank spaces are not allowed.", (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(
                /^[0-9a-zA-Z\s-]+$/,
                "Zip code must contain only numbers, alphabets, hyphens, and spaces."
            )
            .matches(
                /^(?!-)[\w\s-]*(?<!-)$/,
                "Hyphens are not allowed at the beginning or end of the zip code."
            )
            .min(3, "Zip code must be at least 3 characters.")
            .max(12, "Zip code cannot be longer than 12 characters."),
    });

    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        reset,
        watch,
        clearErrors,
        trigger,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialFormValue,
        mode: "onChange",
    });


    const seller = watch("sellerId");
    const initialId = watch("initialId");
    const auctionId = watch("auctionId");

    useEffect(() => {
        if (ID) {
            setCustomerId(ID);
        }

        return () => {

        }
    }, [ID])


    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        // setIsAddressFilled(true);
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
        } else if (e.target.name === 'addressLine2') {
            setValue('address2', inputText)
        } else if ((e.target.name === 'location')) {
            // setLocation(inputText)
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
        }
    }

    const getAllSellers = () => {
        setLoading(true);
        axios.get(`${GETSELLER}?pageNumber=-1`).then(
            (response) => {
                setLoading(false);
                if (response?.data?.data?.success === "Fail") {
                    toast.error(response?.data?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const sellerData = response?.data?.data?.data;

                    let formateSeller = sellerData?.map((seller: any, index: any) => {
                        return {
                            id: seller.sellerId,
                            name: `${seller.sellerId}- ${seller.firstName} ${seller.lastName}`,
                        };
                    });
                    setSellerList(formateSeller);
                }
            },
            (error) => {
                if (error.response.status !== 401) {
                    toast.error(errorMessages.error401, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }
        );
    };

    const getAuctionsList = () => {
        setLoading(true);
        const customersId = customerId ? customerId: customerID;
        axios.get(`${AUCTIONSBYCUSTOMERID}?customerId=${customersId}`).then((response) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                // toast.error(response?.data?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const lotsData = response?.data?.data;
                let auctionlistOptions: any = lotsData?.map((i: any) => { return { label: `${i?.auctionCode} - ${i?.auctionTitle}`, value: i?.auctionId } })
                setAuctionsList(auctionlistOptions);

                // if (!isEmptyObject(auctionDetail)) {
                //     const valueFound = lotsData?.find((i: any) => i?.auctionId == auctionDetail?.auctionId)
                //     const selectedValue = { label: `${valueFound?.auctionCode} - ${valueFound?.auctionTitle}`, value: valueFound?.auctionId }
                //     setSelectedAuction(selectedValue)
                // } else if (!globalAuction?.value) {
                //     setSelectedAuction(auctionlistOptions?.length ? auctionlistOptions[0] : {})
                // }
            }
        }, (error) => {
            setLoading(false);
            if (error.response.status !== 401) {
                toast.error(errorMessages.error401, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })


    }

    const getInitails = () => {
        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setIntials(formateData);
            }
        }, (error) => {
            if (error.response.status !== 401) {
                toast.error(errorMessages.error401, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }

    const getContactDetails = (customerId: any, auctionId: any) => {
        setLoading(true);
        const customersId = customerId ? customerId: customerID;
        axios.get(`${GETCUSTOMERINFOBYAUCTION}?customerId=${customersId}&auctionId=${auctionId}`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response?.data?.data
                reset(data)
                setValue('sellerId', data?.sellerId)
                setValue('add1', data?.addressLine1)
                setValue('address2', data?.addressLine2);
                setValue('country1', data?.countryName);
                setValue('state1', data?.stateName);
                setValue('zip', data?.zipCode);
                setValue('county1', data?.countyName);
                setValue('city1', data?.cityName);
                setValue('add1', data?.addressLine1);
                setAddress(data?.addressLine1)
                setIsSeller(true)
            }
        }, (error) => {
            setLoading(false);
            if (error.response.status !== 401) {
                toast.error(errorMessages.error401, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }

    const handleChange = (selectedOption: string[]) => {
        const filteredOptions = selectedOption?.filter((option: any) => option !== "select_all");
        setSelectedSellerList(filteredOptions);
    };

    const onSubmitform = (data: any) => {
        setLoading(true);
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const payload = {
                ...data,
                country: parseInt(countryId),
                state: parseInt(stateId),
                city: parseInt(cityId),
                zipCode: data?.zip,
                addressLine1: data?.add1,
                sellerId: selectedSellers.join(','),
                fax: `${data?.fax ? data?.fax : ''}`,
                addressLine2: data?.address2,
                county: parseInt(countyId),
                countyName: data?.county1

            }
            axios.post(UPDATECUSTOMERINFOBYAUCTION, payload, config).then((response) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    toast.success('Buyer contact info updated successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }, (error) => {
                setLoading(false)
                if (error?.response?.status !== 401) {
                    toast.error(errorMessages.error401, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            })
        })
    }

    useEffect(() => {
        if (customerId) {
            getAuctionsList()
            getAllSellers()
            getInitails()
        }

    }, [customerId])

    useEffect(() => {
        if (selectedAuction?.value && customerId) {
            getContactDetails(customerId, selectedAuction?.value)
        }
    }, [selectedAuction, customerId])

    useEffect(() => {
        if (!isEmptyObject(auctionDetail)) {
            const valueFound = auctionsList?.find((i: any) => i?.value == auctionDetail?.auctionId)

            if (valueFound) {
                setSelectedAuction(valueFound)
            } else {
                if (auctionsList?.length) {
                    Swal.fire({
                        text: 'Sorry, this Buyer is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }

        } else if (!isEmptyObject(globalAuction)) {
            const matches = auctionsList?.filter((option: any) => {
                return option?.value == globalAuction?.value;
            });
            //If the auction is in the - Auction dropdown, it will be selected by default. 
            //else show swal msg if there's any option in auction list
            if (matches?.length > 0) {
                setSelectedAuction(globalAuction);
            } else {
                if (auctionsList?.length) {
                    Swal.fire({
                        text: 'Sorry, this Buyer is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }
        } else {
            if (auctionsList) {
                const valueFound = auctionsList[0]
                setSelectedAuction(valueFound)
            }
        }
    }, [globalAuction, auctionsList, auctionDetail]);

    useEffect(() => {
        if (seller) {
            const filteredOptions = seller.split(',').filter((option: any) => option !== "select_all");
            setSelectedSellerList(filteredOptions);
        }
    }, [sellerList, seller])

    useEffect(() => {
        if (isSeller) {
            setTimeout(() => setIsSeller(false), 50)
        }
    }, [isSeller])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            {(auctionsList == undefined || auctionsList?.length == 0 && !loading) ? <>
                <div className="d-flex align-items-center top_border justify-content-center flex-wrap h-61vh verti_center">
                    <div className="auvtoin_immg">
                        <img src={auctionImage} />
                    </div>
                    <span className="d-block w-100 text-center mt-4 fs-20">Oops! This Buyer has no auction data.</span>
                </div>
            </> :
                <div className="container-fluid">
                    <div className="row justify-content-center top_border mb-2">
                        <div className="mx-2 border-300 px-10">
                            <div className="col-12 mt-2" >
                                <div className="row g-3">
                                    <div className="col-lg-4 col-md-5">
                                        <label className="form-label ms-1 mb-1">Select Auction</label>
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionsList}
                                            value={selectedAuction}
                                            onChange={(e) => setSelectedAuction(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-12">
                            <hr />
                        </div>
                        <div className="col-xxl-10">
                            <form>
                                <div className="row g-3">
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Initials</label>
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select"
                                            options={initials}
                                            {...register('initialId')}
                                            value={initials?.find((option: any) => option?.value == initialId)}
                                            onChange={(e: any) => setValue('initialId', e.value)}
                                            className='isActive'
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                        <input type="text"
                                            {...register('firstName')}
                                            className="form-control" placeholder="First Name" maxLength={21} />
                                        {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Middle Name</label>
                                        <input type="text"
                                            {...register('middleName')}
                                            className="form-control" placeholder="Middle Name" maxLength={21} />
                                        {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control"
                                            {...register('lastName')}
                                            placeholder="Last Name" maxLength={21} />
                                        {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Company Name</label>
                                        <input type="text" className="form-control"
                                            {...register('company')}
                                            placeholder="Company Name" maxLength={51} />
                                        {/* {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>} */}
                                    </div>
                                    {!isSeller && <div className="col-lg-9">
                                        <label className="form-label">
                                            Seller
                                        </label>
                                        <div className="multiselect-count">
                                            <CustomCheckbox options={sellerList}
                                                onChange={handleChange} defaultSelected={seller} />
                                            {errors.sellerId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.sellerId.message}</span>}
                                        </div>
                                    </div>}
                                    <AddressForm
                                        errors={errors}
                                        onHandleChangeAddressLine={handleChangeAddressLine}
                                        register={register}
                                        setValue={setValue}
                                        setCountryCode={setCountryCode}
                                        onSetAddress={setAddress}
                                        onAddressChange={() => { }}
                                        watch={watch}
                                        latLongMap={false}
                                        trigger={trigger}
                                    />
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                        <Controller
                                            // name="mobile"
                                            {...register('mobile')}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <PhoneInput
                                                    value={value}
                                                    onChange={onChange}
                                                    country={countryCode}
                                                    priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                                />
                                            )}
                                        />
                                        {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Alternate Number </label>
                                        <Controller
                                            //  name="phone"
                                            {...register('phone')}
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <PhoneInput
                                                    value={value}
                                                    onChange={onChange}
                                                    country={countryCode}
                                                    priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                                />
                                            )}
                                        />

                                        {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <label className="form-label">Fax</label>
                                        <input type="text" className="form-control" maxLength={12}
                                            {...register('fax')}
                                            placeholder="Fax" onKeyDown={handleKeyDown} />
                                        {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                    </div>
                                    <div className="col-lg-3 col-md-6 p-4">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            {...register('buyBackBidderOnly')}
                                        />
                                        <label className="form-label mx-2">Buyback Bidder Only</label>
                                        {errors.taxExempt && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxExempt.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label className="form-label">Notes</label>
                                        <textarea
                                            className="form-control"
                                            cols={5}
                                            rows={4}
                                            maxLength={maxWordCount}
                                            placeholder="Notes"
                                            {...register('notes', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                        ></textarea>

                                        {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                            {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                        </div>}

                                        {errors?.notes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.notes.message}</span>}


                                    </div>

                                </div>
                                <div className="col-xxl-10">
                                    <div className="container-fluid for-fix-bottom-space">
                                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                            <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS} >{CommonMessages.cancelButton}</Link>
                                            <ModifiedBy data={initialFormValue} />
                                            <LastModified data={initialFormValue} />
                                            <span className="btn btn-primary ms-2"
                                                onClick={handleSubmit(onSubmitform)}
                                            >Update
                                                {/* {ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)} */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default AuctionTab;




