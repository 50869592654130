import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  base, GETCOMPANYBYIDUSINGBLOB, GETDEFAULTLOGO, GETDEFAULTLOGOBLOB, GETPROFILE,
  UPDATECOMPANYUSINGBLOB,
  UPDATEPROFILE
} from "../../common/apiConstatnts";
import { charCountForAddress } from "../../common/commonFunctions";
import { Breadcrumb } from "../../common/interface";
import { CommonMessages, CompanyMessage, errorMessages } from "../../common/messageConstants";
import { COMPANYNAMEREGEX, EMAIL, EMAIL_MESSAGE, FAX, FAX_MESSAGE, FIRSTANDLASTNAMEREGEX } from "../../common/regexConstants";
import * as routeConsant from "../../common/routeConstants";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../Utils/Utils";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import Sidebar from "../Sidebar/Sidebar";
import Tippy from "@tippyjs/react";
import { AddressForm, onGetDefaultValues } from "../../common/AddressForm";
import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
  { label: 'Home', url: routeConsant.DASHBOARD },
  { label: "Company Info", url: routeConsant.ROLE },
];

const CompanyProfile = () => {
  const initialData = {
    companyId: "",
    companyName: "",
    companyLoader: "",
    companyLogo: "",
    watermarkImage: "",
    firstName: "",
    lastName: "",
    email: "",
    fax: "",
    add1: "",
    add2: "",
    city1: "",
    state1: "",
    country1: "",
    zip: "",
    county1:''
  };

  const navigate = useNavigate();
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;
  const [profile, setProfile] = useState<any>(initialData);
  const [formValues, setFormValues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState("");
  const [companyLogo, setCompanyLogo]: any = useState(null)
  const [watermarkImage, setWatermarkImage]: any = useState(null)
  const [loaderPreview, setLoaderPreview] = useState("");
  const [watermarkPreview, setWatermarkPreview] = useState("")
  const [companyLoader, setCompanyLoader]: any = useState(null)
  const [modifiedBy, setModifiedBy] = useState('')
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');

  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const allowedTypesCompanyLogos = ["image/jpeg", "image/svg+xml"];
  const allowedTypesWatermarkImage = ["image/svg+xml"];

  const [address, setAddress] = useState('');
  const [countryCode, setCountryCode] = useState('us');
  const [prevCountryCode, setPrevCountryCode] = useState('us');
  const [isAddressFilled, setIsAddressFilled] = useState(false);

  useEffect(() => {
    if (prevCountryCode !== countryCode) {
      setValue('phone1', '');
      setValue('phone2', '');
    }
  }, [address, prevCountryCode, countryCode])


  const generatePhoneValidationSchema = () => {
    return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
      if (!value) return true;
      try {
        const phoneNumber = parsePhoneNumberFromString(`+${value}`);
        if (!phoneNumber || !phoneNumber.isValid()) {
          return false; // Invalid phone number format
        }
        const nationalNumber = phoneNumber.nationalNumber;
        const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
        const expectedLength = value.length - countryCodeLength;
        return nationalNumber.toString().length === expectedLength;
      } catch (error) {
        console.error('Error parsing phone number:', error);
        return false; // Return false if an error occurs during parsing
      }
    });
  };

  const handleChangeAddressLine = (e: any) => {
    charCountForAddress(e.target.value.length)
    setAddress(e.target.value)
    setValue('address', e.target.value)
    setValue('add1', e.target.value)
    setIsAddressFilled(true);
  }

  const handleLogoChange = (e: any) => {
    const file = e.target.files[0];

    if ((file.size / 1024) > 2048) {
      toast.error(errorMessages.fileSizeError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
      e.target.value = '';
      return;
    }
    if (!allowedTypesCompanyLogos.includes(file?.type)) {
      e.target.value = '';
      toast.warning(errorMessages.formatNotAllowed, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setCompanyLogo(file);
    if (file) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogoPreview("");
    }
  };

  const handleLoaderChange = (e: any) => {
    const file = e.target.files[0];

    if ((file.size / 1024) > 2048) {
      toast.error(errorMessages.fileSizeError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
      e.target.value = '';
      return;
    }
    if (!allowedTypes.includes(file?.type)) {
      e.target.value = '';
      toast.warning(errorMessages.formatNotAllowed, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    setCompanyLoader(file);

    if (file) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setLoaderPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLoaderPreview("");
    }
  };

  const handleWatermarkChange = (e: any) => {
    const file = e.target.files[0];

    if ((file.size / 1024) > 2048) {
      toast.error(errorMessages.fileSizeError, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
      e.target.value = '';
      return;
    }
    if (!allowedTypesWatermarkImage.includes(file?.type)) {
      e.target.value = '';
      toast.warning(errorMessages.formatNotAllowed, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setWatermarkImage(file);
    setValue('watermarkImage', file)
    if (file) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setWatermarkPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setWatermarkPreview("");
    }
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      enctype: "multipart/form-data",
    },
    params: {
      id: localStorage.getItem("companyId"),
    },
  };

  const schema: any = yup.object().shape({
    companyName: yup.string()
      .required("Please Enter Company Name")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(COMPANYNAMEREGEX, 'Please enter valid company name')
      // .matches(SPECIALCHARS, "Please enter Valid Company Name")
      .test('len', 'Please enter upto 100 characters', val => val.length < 100),
    firstName: yup.string()
      .required("Please Enter First Name")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(FIRSTANDLASTNAMEREGEX, "Please enter Valid FirstName")
      .test('len', 'Please enter upto 20 characters', val => val.length < 20),
    lastName: yup.string()
      .required("Please Enter Last Name").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(FIRSTANDLASTNAMEREGEX, "Please enter Valid LastName")
      .test('len', 'Please enter upto 20 characters', val => val.length < 20),
    email: yup.string()
      .required('Email is required').test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE)
      .test('len', 'Please enter upto 50 characters', val => val.length < 50)
    ,
    add1: yup.string().required("Address is required").test('no-blank-space', 'Blank spaces are not allowed', (value) => {
      return value ? !/^\s/.test(value) : true;
    }),
    zip: yup
      .string().required('Zip code is required')
      .test('no-blank-space', 'Blank spaces are not allowed.', (value) => {
        return value ? !/^\s/.test(value) : true;
      })
      .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
      .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
      .min(3, 'Zip code must be at least 3 characters.')
      .max(12, 'Zip code cannot be longer than 12 characters.'),
    state1: yup.string().required("State is required"),
    city1: yup.string().required("City is required"),
    phone1: generatePhoneValidationSchema().required('Phone number is required.'),
    phone2: generatePhoneValidationSchema().nullable(),
    fax: yup.string().nullable().matches(FAX, FAX_MESSAGE),
    // license: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
    //   if (!value) return true; // If value is empty, it's handled by the .required() validation
    //   return !/^\s/.test(value); // Check if value doesn't start with a space
    // }).max(50, "Please enter upto 50 characters"),
    companyLogo: yup
      .mixed()
      .test("fileSize", "The file is too large", (value: any) => {
        if (!value.length) return true; // attachment is optional
        if (value[0].size) {
          return value[0].size <= 2000000;
        } else {
          return true;
        }
      })
      .test(
        "fileType",
        "Only the following formats are accepted: .jpeg, .jpg, .png, .svg and .gif",
        (value: any) => {
          if (!value.length) return true;
          if (value[0].type) {
            return (
              value &&
              (value[0].type === "image/jpeg" ||
                value[0].type === "image/bmp" ||
                value[0].type === "image/png" ||
                value[0].type === "image/gif" ||
                value[0].type === "image/svg+xml")
            );
          } else {
            return true;
          }
        }
      ),
    companyLoader: yup
      .mixed()
      .test("fileSize", "The file is too large", (value: any) => {
        if (!value.length) return true; // attachment is optional
        if (value[0].size) {
          return value[0].size <= 2000000;
        } else {
          return true;
        }
      })
      .test(
        "fileType",
        "Only the following formats are accepted: .jpeg, .jpg, .png and .gif",
        (value: any) => {
          if (!value.length) return true;
          if (value[0].type) {
            return (
              value &&
              (value[0].type === "image/jpeg" ||
                value[0].type === "image/bmp" ||
                value[0].type === "image/png" ||
                value[0].type === "image/gif" ||
                value[0].type === "image/svg+xml")
            );
          } else {
            return true;
          }
        }
      ),
    watermarkImage: yup
      .mixed()
      .test("fileSize", "The file is too large", (value: any) => {
        if (!value?.length) return true; // attachment is optional
        if (value[0]?.size) {
          return value[0]?.size <= 2000000;
        } else {
          return true;
        }
      })
      .test(
        "fileType",
        "Only the following format is accepted: .svg",
        (value: any) => {
          if (!value?.length) return true;
          if (value[0]?.type) {
            return (
              value &&
              (value[0].type === "image/svg+xml")
            );
          } else {
            return true;
          }
        }
      ).nullable(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: profile,
    mode: "onChange",
  });


  const getCompanyProfile = () => {
    axios
      .get(GETCOMPANYBYIDUSINGBLOB, config)
      .then((response) => {
        setLoading(false);
        setAddressLine2(response.data.data.addressLine2 == 'null' ? '' : response.data.data.addressLine2)
        setAddressLine1(response.data.data.addressLine1)
        setProfile(response.data.data);
        setModifiedBy(response.data.data.modifiedBy)
        setValue('fax', response.data.data.fax == 'null' ? '' : response.data.data.fax)
        reset(response.data.data)
        const parsedNumber: any = parsePhoneNumber(`+${response.data.data?.phone1}`);
        const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
        setCountryCode(respCountryCode)
        setPrevCountryCode(respCountryCode)
        reset({
          ...response.data.data,
          add1: response?.data?.data?.addressLine1,
          add2: response?.data?.data?.addressLine2,
          city1: response?.data?.data?.cityName,
          state1: response?.data?.data?.stateName,
          country1: response?.data?.data?.countryName,
          zip: response?.data?.data?.zipCode,
        })
        setAddress(response.data.data.addressLine1)
        setValue('city1', response?.data?.data?.cityName);
        setValue('state1', response?.data?.data?.stateName);
        setValue('country1', response?.data?.data?.countryName);
        setValue('address2', response?.data?.data?.addressLine2)
        setValue('zip', response?.data?.data?.zipCode)
        setValue('county1',response?.data?.data?.countyName)
      })
      .catch((error) => {
        setLoading(false);
        handleError(error)
      });;
  }

  useEffect(() => {
    setLoading(true);
    reset(profile)
    getCompanyProfile();
  }, [])

  const onSubmit = (e: any) => {
    // e.preventDefault()
    const data = trimObjectValues(e);

    if (superAdminRole === "True") {
      setLoading(true);
      onGetDefaultValues(e, (countryId: any, stateId: any, cityId: any,countyId:any) => {
        const formD: any = new FormData();
        formD.append("companyName", data.companyName);
        formD.append("companyId", data.companyId);
        formD.append("companyName", data.companyName);
        formD.append("firstName", data.firstName);
        formD.append("lastName", data.lastName);
        formD.append("email", data.email);
        formD.append("addressLine1", data.add1);
        formD.append("addressLine2", data.address2 ?? "");
        formD.append("city", cityId);
        formD.append("state", stateId);
        formD.append("country", countryId);
        formD.append("county",countyId);
        formD.append("countyName",data?.county1);
        formD.append("zipCode", data.zip);
        formD.append("phone1", data.phone1);
        formD.append("phone2", data.phone2);
        formD.append("fax", data.fax ?? "");
        formD.append("license", data.license ?? "");
        formD.append("isActive", "true");
        formD.append("isDelete", "false");
        formD.append("companyLogo", companyLogo);
        formD.append("companyLoader", companyLoader);
        formD.append("watermarkImage", watermarkImage);

        axios.post(UPDATECOMPANYUSINGBLOB, formD, config).then(async (response) => {
          setLoading(false);
          toast.success(CompanyMessage.companyUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          // Retrieve the updated logo after saving the data
          const companyId = localStorage.getItem("companyId");
          await axios
            .get(`${GETDEFAULTLOGOBLOB}?companyId=${companyId}`)
            .then((response) => {
              const logoURL = response.data.data;
              localStorage.setItem("logo", logoURL);
            }).catch((error) => {
              setLoading(false);
              handleError(error);
            });
          getCompanyProfile();
        }).catch((error) => {
          setLoading(false);
          handleError(error)
        });;
      })
    }
    else {
      toast.error("Only super admin has permission to perform this operation on the page.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const updateCountryCode = (newAddress: any, code: any) => {
  };

  return (
    <>
    <CommonHelmet title={"Company Info - "} name={"company"} content={"Company Info"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
        <div className="container-fluid for-fix-bottom-space">
          <div className="row justify-content-center border-top pt-4">
            <div className="col-xxl-10">
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="companyProfile"
                noValidate
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 mb-2">
                      <input type="hidden" value={12} name="companyId" />
                      <h4 className="fw-bold d-block">Company Info</h4>
                      <small>This Information will appear on invoices and reports. It is universal to all auctions, but may be edited as required. </small>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Company<sup className="text-danger">*</sup></label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Company Name"
                          maxLength={100}
                          {...register("companyName")}
                        />
                        {errors?.companyName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.companyName.message}</span>}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          First Name<sup className="text-danger">*</sup>{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={20}
                          placeholder="First Name"
                          {...register("firstName")}
                        />
                        {errors?.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.firstName.message}</span>}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Last Name<sup className="text-danger">*</sup>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          maxLength={20}
                          placeholder="Last Name"
                          {...register("lastName")}
                        />
                        {errors?.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.lastName.message}</span>}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Email<sup className="text-danger">*</sup>
                          <Tippy content="The email address you’ve provided will be
                                  associated with the Company’s contact information
                                  in the Customer portal lot detail">
                            <i className="eye_icons position-absolute fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                          </Tippy>
                        </label>
                        <input
                          className="form-control"
                          {...register("email")}
                          maxLength={50}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <AddressForm
                        errors={errors}
                        onHandleChangeAddressLine={handleChangeAddressLine}
                        register={register}
                        setValue={setValue}
                        setCountryCode={setCountryCode}
                        onSetAddress={setAddress}
                        onAddressChange={updateCountryCode}
                        watch={watch}
                        latLongMap={false}
                        trigger={trigger}
                      />
                    </div>
                    <div className="col-sm-3">
                      <div className="mb-3">
                        <label className="form-label">
                          Phone Number<sup className="text-danger">*</sup>
                        </label>
                        <Controller
                          // name="phone1"
                          {...register('phone1')}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              country={countryCode}
                              priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                            />
                          )}
                        />
                        {errors.phone1 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone1.message}</span>}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="mb-3">
                        <label className="form-label">Alternate Number</label>
                        <Controller
                          // name="phone1"
                          {...register('phone2')}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              country={countryCode}
                              priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                            />
                          )}
                        />
                        {errors.phone2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone2.message}</span>}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="mb-3">
                        <label className="form-label">Fax </label>
                        <input
                          className="form-control"
                          {...register("fax")}
                          type="text"
                          placeholder="Fax"
                          onKeyDown={handleKeyDown}
                        />
                        {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="mb-3">
                        <label className="form-label">License #</label>
                        <input
                          className="form-control"
                          maxLength={51}
                          {...register("license")}
                          placeholder="License"
                        />
                        {/* {errors.license && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.license.message}</span>} */}
                      </div>
                    </div>
                    {superAdminRole === "True" &&
                      <div className="row">
                        {/* Company logo */}
                        <div className="col-lg-4 col-sm-6">
                          <label className="form-label text-900">
                            Company Logo
                          </label>
                          <div className="uploadedImage">
                            {logoPreview ? ( // Display the logo preview if available
                              <img
                                src={logoPreview}
                                width="150px"
                                alt=""
                              />) : (<img
                                src={`${profile.companyLogo}?${profile.companyLogosastoken}`}
                                width="150px"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="col-xxl-10">
                              <div
                                className="dz-message dropzone-area px-2 py-3"
                                data-dz-message="data-dz-message"
                              >
                                <div className="text-center text-1100">
                                  <h5 className="mb-2">
                                    <span className="fa-solid fa-upload me-2"></span>
                                    Upload
                                  </h5>
                                  <p className="mb-0 fs--1 text-600 lh-sm">
                                    Upload a jpg, png, gif image with <br />a
                                    maximum size of 2MB

                                  </p>
                                  <input
                                    type="file"
                                    {...register("companyLogo")}
                                    name="companyLogo"
                                    onChange={handleLogoChange}
                                    className="companyLoader"
                                  />
                                </div>
                              </div>
                              {errors.companyLogo && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {errors.companyLogo.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Company loader */}
                        <div className="col-lg-4 col-sm-6">
                          <label className="form-label text-900">
                            Company Loader
                          </label>
                          <div className="uploadedImage">
                            {loaderPreview ? ( // Display the logo preview if available
                              <img
                                src={loaderPreview}
                                width="150px"
                                alt=""
                              />) : (<img
                                // src={`${base}${profile.companyLoader}`}
                                src={`${profile.companyLoader}?${profile.companyLoadersastoken}`}
                                width="150px"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="">
                            <div className="col-xxl-10">
                              <div
                                className="dz-message dropzone-area px-2 py-3"
                                data-dz-message="data-dz-message"
                              >
                                <div className="text-center text-1100">
                                  <h5 className="mb-2">
                                    <span className="fa-solid fa-upload me-2"></span>
                                    Upload
                                  </h5>
                                  <label className="mb-0 fs--1 text-600 lh-sm">
                                    Upload a jpg, png, gif image with <br />a
                                    maximum size of 2MB
                                  </label>
                                  <input
                                    type="file"
                                    {...register("companyLoader")}
                                    name="companyLoader"
                                    onChange={handleLoaderChange}
                                    className="companyLoader"
                                  />
                                </div>
                              </div>
                              {errors.companyLoader && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {errors.companyLoader.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* watermark */}
                        <div className="col-lg-4 col-sm-6">
                          <label className="form-label text-900">
                            Inventory Watermark Image
                          </label>
                          <div className="uploadedImage svg_white_img_upload">
                            {watermarkPreview ? (
                              <img
                                src={watermarkPreview}
                                width="150px"
                                alt=""
                              />) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <image
                                  href={`${profile?.watermarkImage}?${profile?.watermarkImagesastoken}`}
                                  width="150px"
                                />
                              </svg>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="col-xxl-10">
                              <div
                                className="dz-message dropzone-area px-2 py-3"
                                data-dz-message="data-dz-message"
                              >
                                <div className="text-center text-1100">
                                  <h5 className="mb-2">
                                    <span className="fa-solid fa-upload me-2"></span>
                                    Upload
                                  </h5>
                                  <p className="mb-0 fs--1 text-600 lh-sm">
                                    Upload a svg image with <br />a
                                    maximum size of 2MB
                                  </p>
                                  <input
                                    type="file"
                                    name="watermarkImage"
                                    onChange={handleWatermarkChange}
                                    className="companyLoader"
                                  />
                                </div>
                              </div>
                              {errors.watermarkImage && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {errors.watermarkImage.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                  <Link
                    to={routeConsant.DASHBOARD}
                    className="btn btn-outline-danger me-auto"
                    type="button"
                    data-wizard-prev-btn="data-wizard-prev-btn"
                  >
                    {CommonMessages.cancelButton}
                  </Link>
                  <ModifiedBy data={formValues} />
                  <LastModified data={formValues} />
                  <button
                    className="btn btn-primary mx-2"
                    type="submit"
                    data-wizard-next-btn="data-wizard-next-btn"
                  >
                    {CommonMessages.saveButton}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
