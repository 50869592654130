import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import * as yup from "yup";
import {
  CHECKDUPLICATELOTTILE,
  CREATENEWLOT,
  GENERATELOTCTRLREC,
  GETAUCTIONSELLERMAPPING,
  GETLOTBYID,
  GETSALESREPBYSELLERID,
  GETSELLERASSOCIATEDTOSALESREP, UPDATENEWLOT
} from "../../../../common/apiConstatnts";
import { taxExemptType } from "../../../../common/constants";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  AlPHANUMERIC,
  DECIMAL32,
  DECIMAL32_MESSAGE,
  DECIMAL5,
  DECIMAL5_MESSAGE,
  DECIMAL73,
  DECIMAL73_MESSAGE,
  DECIMAL82,
  DECIMAL82_MESSAGE,
  INTEGER,
  INTEGER_MESSAGE, YEAR,
  YEAR_MESSAGE,
  ZIP
} from "../../../../common/regexConstants";
import * as routeConstant from "../../../../common/routeConstants";
import { decryptId, encryptId } from "../../../../Encryption/Encryption";
import { loader } from "../../../../store/actions/CommonAction";
import {
  setAuctionDataToStore,
  setCloneLotId,
  setCloneTab,
  setGeneratedLotCtrlRec,
  setIndex,
  setInventoryInfoActive,
  setLotId,
  setNextLotId,
  setPrevLotId,
  setSellerIdForComm,
  setStep,
  setTotalLots
} from "../../../../store/actions/Inventory";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from '../../../../Utils/CustomCkEditor';
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../../../Utils/Utils";
import CustomCheckbox from "../../../Customers/MoreInfo/MultipleSelectCheckbox";
import { LOTADDRESS, LOTSPAGE } from "../../InventoryConstants";

const LotsPage = ({ cloneEnv }: any) => {
  const lotPage = useSelector((state: any) => state.inventory.lotPage);
  const reGolbalCate = useSelector((state: any) => state?.global?.globalCategories) || [];
  const getLotCtrlRec = useSelector((state: any) => state?.inventory?.generatedLotCtrlRec);
  const auctionId = getLotCtrlRec.auctionId;
  const mode = useSelector((state: any) => state.inventory.mode);
  const lotId = useSelector((state: any) => state.inventory.lotId)
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
  const createTabs = useSelector((state: any) => state.inventory.createTabs)
  const auctionData = useSelector((state: any) => state.inventory.auctionData)
  const indexFromStore = useSelector((state: any) => state.inventory.index)
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (auctionId > 0) {
      getAllSellers(auctionId);
    }
    else {
      getAllSellers(0)
    }
  }, [getLotCtrlRec.isMaster, auctionId]);

  const getUrl = window.location.pathname;
  const modeType = getUrl.split("/")[2];
  const dispatch = useDispatch();

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    lotId: params.id ? decryptId(params.id) : 0,
    lotNo: getLotCtrlRec?.lotNo,
    displayLotNo: String(getLotCtrlRec?.displayLotNo),
    ctrlNo: getLotCtrlRec.ctrlNo,
    auctionId: getLotCtrlRec.auctionId,
    recId: getLotCtrlRec?.recId,
    ring: "",
    title: "",
    quantity: "1",
    lotDescriptiopn: "",
    country: 0,
    state: 0,
    city: 0,
    auctionType: auctionData.auctionType,
    inventoryStatus: 0,
    category: "",
    seller: "",
    salesRep: "",
    startBid: "",
    reserve: "",
    lienholderName: "",
    netProceeds: "",
    payoff: "",
    taxExempt: false,
    status: true,
    isLienholder: false,
    isCloned: false,
    isActive: true,
    isDelete: false,
    /* minimumQuantity: 1,
    taxRate: 0,
    autoSelectTaxRate: true,
    acceptOffers: false,
    postToInterner: false,
    forSale: false,
    bold: false,
    noMerge: false,
    arrow: false,
    approvalAbsBids: false,
    feature: false,
    disableAbsBids: false,
    dealerOnly: false,
    deleteAbsBids: false,
    outOfState: false,
    absBidCt: "",
    reserveDisplay: false,
    approvalBuyItNow: false,
    disableBuyItNow: false,
    notifyTrackingMembers: false,
    posted: false,
    enrouteOnSite: 0,
    stamped: false,
    check: false,
    hpi: false,
    internally: false,
    unitCost: "",
    listLow: "",
    listHigh: "",
    year: "",
    manufacturer: "",
    model: "",
    engineHours: "",
    idNum: "",
    externalId: "", */
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [loading, setLoading] = useState(false);
  const [sellerList, setSellerList] = useState<any>([]);
  const [initialFormValues, setInitialFormValues] = useState<any>(initialData);
  const [salesRepList, setSalesRepList] = useState<any>([]);
  const [enablePayoff, setEnablePayoff] = useState(false);
  const [title, setTitle] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [error, setError] = useState(false);
  const [selectedSalesRep, setSelectedSalesRep] = useState<string[]>([]);
  const [reRender, setReRender] = useState<any>(false);
  const [btnText, setBtnTxt] = useState<any>("")
  const [errorDisplayLotNo, setErrorDisplayLotNo] = useState(false)
  const errorTitleMsg = useRef("")

  const auctionTypes = [
    { value: "1", label: "Land Auction" },
    { value: "2", label: "Equipment Auction" },
    { value: "3", label: "Business Auction" },
  ];

  const netProceed = [
    { value: "1", label: "Yes" },
    { value: "2", label: "No" },
  ];

  const extractTextFromHTML = (html: string): string => {
    // Replace non-breaking spaces with regular spaces
    const normalizedHTML = html?.replace(/&nbsp;/g, ' ');

    const doc = new DOMParser().parseFromString(normalizedHTML, 'text/html');
    const textContent = doc.body.textContent || '';

    // Remove consecutive spaces and trim the text
    const trimmedTextContent = textContent?.replace(/\s+/g, ' ').trim();

    return trimmedTextContent;
  };

  const schema: any = yup.object().shape({
    displayLotNo: (getLotCtrlRec.auctionId || lotPage.auctionId) ?
      yup.string().test('conditional-validation', 'Valid Lot no is required', function (value): any {
        return yup.string()
          .required("Lot number is required")
          .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
          })
          .matches(ZIP, "Please enter a valid Lot number")
          .isValidSync(value);
      }) : yup.string().notRequired().nullable(),
    title: yup
      .string()
      .required("Lot Title is required")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test('custom-validation', 'Please enter up to 100 characters for the title', value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        const textContent = extractTextFromHTML(value); // Extract text content without HTML tags
        return textContent.length <= 100; // Check if text content length is within the limit
      }),
    lotDescriptiopn: yup
      .string()
      .required("Lot Description is required")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
    taxExempt: yup.boolean(),
    startBid: yup.string().nullable()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL82, DECIMAL82_MESSAGE),
    reserve: yup.string().when("reserveDisplay", {
      is: (reserveDisplay: any, value: boolean) => reserveDisplay === true,
      then: () =>
        yup
          .string()
          .required("Reserve amount is required")
          .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
          })
          .matches(/^\d+(\.\d*)?$/, "Only digits are allowed")
          .test("max-10-digits", "Maximum 10 digits allowed", (value) => {
            return value ? value.length <= 10 : true;
          }),
      otherwise: () => yup.string(),
    }),
    seller: yup.string().required("Seller is required"),
    salesRep: yup.string().required("Sales Rep is required"),
    category: yup.string().required("Category is required"),
    auctionType: yup.string().required("Auction type is required"),
    isLienholder: yup.boolean().nullable(),
    lienholderName: yup.string().when("isLienholder", {
      is: (value: any) => value === true,
      then: () =>
        yup
          .string()
          .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
          })
          .required("Lien holder name is required"),
      otherwise: () => yup.string().nullable(),
    }),
    netProceeds: yup.string().when("isLienholder", {
      is: (value: any) => value === true,
      then: () =>
        yup
          .string()
          .required("Net proceeds is required")
          .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
          }).matches(DECIMAL73, DECIMAL73_MESSAGE),
      otherwise: () => yup.string().nullable(),
    }),
    payoff: yup.string().when("isLienholder", {
      is: (value: any) => value === true,
      then: () =>
        yup
          .string()
          .required("Payoff is required")
          .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
          })
          .matches(DECIMAL5, DECIMAL5_MESSAGE),
      otherwise: () => yup.string().nullable(),
    }),
    /* reserveDisplay: yup.boolean(),
    surcharge: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL73, DECIMAL73_MESSAGE),
    unitCost: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL73, DECIMAL73_MESSAGE),
    listLow: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL73, DECIMAL73_MESSAGE),
    listHigh: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL73, DECIMAL73_MESSAGE),
    year: yup.string().nullable().matches(YEAR, YEAR_MESSAGE),
    manufacturer: yup.string().nullable().matches(AlPHANUMERIC, "Please enter valid Manufacturer").max(100, "Please Enter upto 100 Characters"),
    model: yup.string().nullable().matches(AlPHANUMERIC, "Please enter valid Model").max(100, "Please Enter upto 100 Characters"),
    engineHours: yup.string().nullable().matches(INTEGER, INTEGER_MESSAGE).test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }),
    idNum: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(INTEGER, INTEGER_MESSAGE),
    externalId: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(INTEGER, INTEGER_MESSAGE),
    ring: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(INTEGER, INTEGER_MESSAGE),
    minimumQuantity: yup.string().nullable()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(/^\d+$/, "Only digits are allowed")
      .test("max-6-digits", "Maximum 6 digits allowed", (value) => {
        return value ? value.length <= 6 : true;
      }),
    quantity: yup.string().required("Quantity is required")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(/^\d+$/, "Only digits are allowed")
      .test("max-6-digits", "Maximum 6 digits allowed", (value) => {
        return value ? value.length <= 6 : true;
      }),
    taxRate: yup.string().when("taxExempt", {
      is: (taxExempt: any, value: boolean) => taxExempt === true,
      then: () =>
        yup
          .string()
          .required("Tax Rate is required")
          .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
          })
          .matches(DECIMAL32, DECIMAL32_MESSAGE),
      otherwise: () => yup.string(),
    }),
    city: yup.string().required('City is required'),
    location: yup.string().required('Location is required'), */
  });

  useEffect(() => {
    const titleLength = extractTextFromHTML(title).length
    if (title == 0) {
      setError(true);
    } else if (titleLength > 100) {
      setError(true);
      errorTitleMsg.current = "Please enter up to 100 characters for the title"
    } else {
      setError(false);
      errorTitleMsg.current = ""
    }
  }, [title]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
    mode: "onChange",
  });

  const category = watch('category');
  const watchTaxExemptCheck = watch("taxExempt");
  const watchReserveDisplay = watch("reserveDisplay");
  const watchReserve = watch("reserve");
  const watchLien = watch("isLienholder");
  const watchNetProceeds = watch("netProceeds");
  const watchAuctionType = watch('auctionType')
  const taxRate = watch("taxRate");
  const seller = watch("seller");
  const salesRep = `${watch("salesRep")}`;
  const quantity = watch('quantity')
  const startBid = watch('startBid')
  const minimumQuantity = watch('minimumQuantity')

  const handleRadioChange = (data: any) => {
    setValue("isLienholder", data);
  };

  const handleSelectChange = (e: any) => {
    const selectedValue = e.value;
    setValue("netProceeds", selectedValue);
    const netProceedsValue = parseInt(selectedValue, 2);
    setEnablePayoff(netProceedsValue === 1);
  };

  useEffect(() => {
    if (!watchLien) {
      setValue("lienholderName", "");
      watchLien == false
        ? setValue("netProceeds", "2")
        : setValue("netProceeds", "");
      watchNetProceeds == "1" && setEnablePayoff(true);
      setValue("payoff", "");
      clearErrors(["lienholderName", "payoff", "netProceeds"]);
    }
  }, [watchLien]);

  useEffect(() => {
    watchNetProceeds == "1" ? setEnablePayoff(true) : setEnablePayoff(false);
  }, [watchNetProceeds]);

  useEffect(() => {
    if (!watchNetProceeds) {
      setValue("payoff", "");
      clearErrors(["payoff"]);
    }
  }, [watchNetProceeds]);

  let filterCategory = reGolbalCate?.map((aData: any, index: any) => {
    return {
      label: aData.categoryTitle, value: aData.categoryId
    }
  })

  const getAllSellers = (id: any) => {
    setLoading(true);
    let url = '';
    const pageNumber = -1

    if (id > 0) {
      url = `${GETAUCTIONSELLERMAPPING}?auctionId=${id}&pageNumber=${pageNumber}`
    } else if (getLotCtrlRec.isMaster) {
      url = `${GETSELLERASSOCIATEDTOSALESREP}`
    }

    if (url.length > 0) {
      axios.get(url, config).then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          setSellerList([]);
          setSalesRepList([]);
          setValue('salesRep', '')
          return;
        } else {
          const sellers = response?.data?.data?.data;
          const newData = id > 0 ? sellers : response?.data?.data;
          const sellerData = newData?.map((lData: any) => {
            return {
              value: lData.sellerId,
              label: `${lData.sellerId} - ${lData.firstName} ${lData.lastName}`
            };
          })
          setValue("seller ", seller)
          setSellerList(sellerData);
        }
      }).catch((error: any) => {
        setLoading(false);
        handleError(error);
      });
    }
  };

  const getAssociatedSalesRep = () => {
    setLoading(true);
    axios.get(`${GETSALESREPBYSELLERID}?sellerId=${seller}`, config).then(
      (response: any) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          setSalesRepList([]);
          return;
        } else {
          const salesRepDatas = response?.data?.data;
          const salesrepData = salesRepDatas?.map((lData: any) => {
            return {
              id: `${lData.salesRepId}`,
              name: `${lData.salesRepId} - ${lData.salesRepName}`,
            };
          })
          setSalesRepList(salesrepData);
        }
      }).catch((error: any) => {
        setLoading(false);
        if (error?.response?.status !== 401) {
          setSalesRepList([]);
        }
        handleError(error);
      }
      );
  };

  const handleChange = (selectedOption: string[]) => {
    const filteredOptions = selectedOption?.filter((option: any) => option !== "select_all");
    setSelectedSalesRep(filteredOptions);
    setValue("salesRep", filteredOptions?.join(','));
  };

  const createCloneLot = (data: any, type: any) => {
    const payload = {
      ...data,
      lotId: 0,
      title: data.trimmedTitle,
      lotDescriptiopn: data.lotDescriptiopn,
      auctionId: data.auctionId,
      reserve: data.reserve === "" ? 0 : data.reserve,
      payoff: data.payoff ? parseFloat(data.payoff) : 0,
      netProceeds: data.netProceeds ? parseFloat(data.netProceeds) : 0,
      startBid: data.startBid === "" ? 0 : data.startBid
    }

    axios.post(`${CHECKDUPLICATELOTTILE}?lotTitle=${encodeURIComponent(payload.title)}&auctionId=${payload.auctionId || 0}`).then((response: any) => {
      if (response.data.data) {
        setLoading(false);
        toast.error("Lot title is already exists in this auction", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
        return
      }
      axios.post(CREATENEWLOT, payload, { ...config, ContentType: "application/json" }).then((response) => {
        if (response?.data?.success === "Fail") {
          setLoading(false);
          if (response?.data?.message === "Lot no already exists") {
            setErrorDisplayLotNo(true)
            return
          } else {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        } else {
          setLoading(false);
          const newLotId = response.data.data;
          if (type === "create") {
            dispatch(setLotId(newLotId))
          } else {
            dispatch(setCloneLotId(newLotId))
            dispatch(setCloneTab([...clonedTabs, LOTSPAGE]))
          }
          toast.success("Lot created successfull", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          dispatch(setSellerIdForComm({ sellerId: payload.seller }))
          /* dispatch(setInventoryInfoActive({ activeTab: "address" })); */
          dispatch(setStep('address'))
          dispatch(loader(false));
        }
      }).catch((error) => {
        setLoading(false);
        toast.error("Error occured while saving lot!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
    }, (error) => {
      setLoading(false);
    })
  }

  const updateLot = (data: any, type: any) => {
    const payload = {
      ...data,
      title: data.trimmedTitle,
      lotDescriptiopn: data.lotDescriptiopn,
      salesRep: selectedSalesRep?.join(','),
      reserve: data.reserve === "" ? 0 : data.reserve,
      payoff: data.payoff ? parseFloat(data.payoff) : 0,
      netProceeds: data.netProceeds ? parseFloat(data.netProceeds) : 0,
    }
    axios.post(UPDATENEWLOT,
      payload, { ...config, ContentType: "application/json" }).then((response: any) => {
        setLoading(false);
        if (response?.data?.success == "Success") {
          setLoading(false);
          dispatch(setSellerIdForComm({ sellerId: data.seller }))
          /* dispatch(setInventoryInfoActive({ activeTab: "address" })); */
          dispatch(setStep('address'))
          toast.success(InventoryMessages.lotUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else if (response?.data?.success === "Fail") {
          setLoading(false);
          if (response?.data?.message === "Lot no already exists") {
            setErrorDisplayLotNo(true)
            return
          } else {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }
      }).catch((error: any) => {
        setLoading(false);
        handleError(error);
      });
  }

  const onSubmit = (data: any) => {
    if (title == "") {
      errorTitleMsg.current = "Lot Title is required"
      return;
    }
    setValue('isCloned', true)
    setLoading(true);
    const dataOriginal = getValues();
    let lotDataSubmit = trimObjectValues(dataOriginal);
    const trimmedTitle = title?.replace(/\s+/g, ' ').replace(/&nbsp;/g, ' ').trim();
    const trimmedDesc = lotDataSubmit?.lotDescriptiopn?.replace(/\s+/g, ' ').replace(/&nbsp;/g, ' ').trim();

    if (Number(getLotCtrlRec.auctionId) === 0) {
      delete lotDataSubmit.lotNo;
    } else {
      // lotDataSubmit = { ...lotDataSubmit, lotNo: getLotCtrlRec.lotNo }
      delete lotDataSubmit.recId;
    }

    lotDataSubmit = { ...lotDataSubmit, trimmedTitle: trimmedTitle, lotDescriptiopn: trimmedDesc }

    if (mode === "create") {
      if (lotId === 0) {
        createCloneLot(lotDataSubmit, "create")
      } else {
        updateLot(lotDataSubmit, "edit")
      }
    } else if (mode === "edit") {
      updateLot(lotDataSubmit, "edit")
    } else if (mode === "clone") {
      if (cloneLotId === 0) {
        createCloneLot(lotDataSubmit, "clone")
      } else {
        updateLot(lotDataSubmit, "edit")
      }
    }
  };

  const getLotbyId = (lotId: any) => {
    setLoading(true);
    axios.get(`${GETLOTBYID}?lotId=${lotId}`, { ...config }).then((response: any) => {
      if (response?.data?.success === "Fail") {
        setLoading(false);
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        let data = response.data.data;
        data = { ...data, startBid: Number(data?.startBid)?.toFixed(2), reserve: Number(data?.reserve)?.toFixed(2) }
        if (mode !== "clone") {
          dispatch(setLotId(data?.lotId))
        }
        dispatch(setAuctionDataToStore({ auctionId: data.auctionId, auctionTitle: data.auctionTitle, auctionType: data.auctionType, auctionCode: data.auctionCode }))
        dispatch(setSellerIdForComm({ sellerId: data.seller }))
        dispatch(setPrevLotId(data?.prevLotId))
        dispatch(setNextLotId(data?.nextLotId))
        dispatch(setTotalLots(data?.totalLots))
        dispatch(setIndex(data?.index))
        dispatch(setGeneratedLotCtrlRec({ ...getLotCtrlRec, auctionId: data.auctionId, displayLotNo: data.displayLotNo, isMaster: data?.auctionId > 0 ? false : true }))
        setTimeout(() => {
          if (mode === "clone" && cloneLotId === 0) {
            ckEditorRefreshAndLoadTitle(data?.incLotTitle);
          } else {
            ckEditorRefreshAndLoadTitle(data?.title);
          }
          ckEditorRefreshAndLoadDesc(data?.lotDescriptiopn);
        }, 500)

        setDesc(data?.lotDescriptiopn)
        if (mode === "clone" && cloneLotId === 0) {
          const auctionId = data.auctionId
          axios.get(`${GENERATELOTCTRLREC}?auctionId=${auctionId}`).then((response: any) => {
            const respData = response.data.data;
            const updateRecCtrlLot = { ...getLotCtrlRec, ...respData, auctionId: auctionId, isMaster: auctionId > 0 ? false : true }
            dispatch(setGeneratedLotCtrlRec(updateRecCtrlLot))
            data = { ...data, ...updateRecCtrlLot, displayLotNo: auctionId === 0 ? "" : String(respData.displayLotNo) }
            reset({ ...data, reserveDisplay: false })
            setInitialFormValues(data)
            setLoading(false);
          })
        } else {
          reset({ ...data, reserveDisplay: false })
          setInitialFormValues(data)
        }
        setLoading(false)
      }
    }, (error) => {
      setLoading(false);
    })
  };

  const onEditorTitleChange = (value: any) => {
    setTitle(value)
  }

  const onEditorDescChange = (value: any) => {
    setDesc(value)
  }

  const ckEditorRefreshAndLoadTitle = (text: any, cloned: boolean = true) => {
    setTitle(text)
  }

  const ckEditorRefreshAndLoadDesc = (text: any) => {
    setDesc(text);
  };

  const handleClone = () => {
    const id = cloneLotId > 0 ? cloneLotId : lotId
    navigate(routeConstant.INVENTORY)
    setTimeout(() => {
      navigate(`${routeConstant.CLONEINVENTORY}/${encryptId(id)}`, { state: { type: "clone" } })
    }, 100);
  }

  useEffect(() => {
    const titleLength = extractTextFromHTML(title).length;
    if (titleLength > 0) {
      setValue('title', title);
      clearErrors(['title']);
    } else {
      setValue('title', '');
    }
  }, [title])

  useEffect(() => {
    const descLength = extractTextFromHTML(desc).length
    if (descLength > 0) {
      setValue('lotDescriptiopn', desc);
      clearErrors(['lotDescriptiopn']);
    } else {
      setValue('lotDescriptiopn', '');
    }
  }, [desc])

  /* useEffect(() => {
    if (watchReserveDisplay === false) {
      setValue("reserve", "");
    } else {
      setValue("reserve", watchReserve);
    }
  }, [watchReserveDisplay]); */

  useEffect(() => {
    if (watchTaxExemptCheck === false) {
      setValue("taxRate", 0);
    } else {
      setValue("taxRate", taxRate);
    }
  }, [watchTaxExemptCheck]);

  useEffect(() => {
    setValue("seller", seller);
    if (seller) {
      setValue('salesRep', '')
      getAssociatedSalesRep();
    }
  }, [sellerList, seller]);

  useEffect(() => {
    if (salesRep && salesRep != 'undefined') {
      const filteredOptions = salesRep?.split(',').filter((option: any) => option !== "select_all");
      setSelectedSalesRep(filteredOptions);
      setValue("salesRep", filteredOptions?.join(','));
    } else {
      if (seller == initialFormValues?.seller) {
        const filteredOptions = initialFormValues?.salesRep?.split(',').filter((option: any) => option !== "select_all")
        setSelectedSalesRep(filteredOptions)
        setValue("salesRep", filteredOptions?.join(','));
      } else {
        if (seller) {
          const filteredOptions = salesRepList?.map((option: any) => option.id)
          setSelectedSalesRep(filteredOptions)
          setValue("salesRep", filteredOptions?.join(','));
        }
      }
    }
  }, [salesRepList]);

  //this is for rerending issue of multiselect dropdown.
  useEffect(() => {
    setReRender(true)
    setTimeout(() => { setReRender(false) }, 50)
  }, [salesRepList])

  useEffect(() => {
    // to render btn text
    let text = ""
    if (mode !== "clone") {
      text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
    } else {
      text = cloneLotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)

    if (params.id && (cloneLotId > 0 || lotId > 0)) {
      if ((cloneLotId > 0 && cloneLotId === decryptId(params.id)) || (lotId > 0 && lotId === decryptId(params.id))) {
        const idToFetchLot = cloneLotId > 0 && clonedTabs.includes(LOTSPAGE) ? cloneLotId : lotId
        getLotbyId(idToFetchLot)
        return
      } else {
        ckEditorRefreshAndLoadTitle("");
        ckEditorRefreshAndLoadDesc("");
        setDesc("")
      }
    } else {
      const idToFetchLot = cloneLotId > 0 && clonedTabs.includes(LOTSPAGE) ? cloneLotId : lotId
      if (idToFetchLot > 0) {
        getLotbyId(idToFetchLot)
        return
      }
    }
  }, [cloneLotId, lotId])

  const handleDisplayLotNo = () => {
    setErrorDisplayLotNo(false)
  }

  const navigateToSeller = () => {
    const id = getValues("seller")
    window.open(`${routeConstant.SELLERMOREINFO}/${encryptId(id)}`, "_blank")
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="loader-wrap">
              <img src={`${localStorage.getItem("globalLoader")}`} />
            </div>
          )}
          <div className="row g-3 mb-3">
            {(initialFormValues.auctionId > 0) ? (
              <>
                <div className="col-lg-4">
                  <label className="form-label">Lot # <sup className="text-danger">*</sup></label>
                  <input
                    className="form-control form-icon-input"
                    placeholder="Lot #"
                    {...register("displayLotNo", {
                      onChange: handleDisplayLotNo
                    })}
                    maxLength={5}
                  />
                  {errors.displayLotNo && (<span className="text-danger" style={{ fontSize: "12px" }}>{errors.displayLotNo.message}</span>)}
                  {errorDisplayLotNo === true && <span className='text-danger' style={{ fontSize: "12px", padding: 0 }}>Lot no already exists</span>}
                </div>
              </>
            ) : (
              <></>
            )}
            {/* <div className={`col-lg-${initialFormValues.auctionId > 0 ? '2' : '3'}`}>
            <div className="col-lg-3">
              <label className="form-label">Ring</label>
              <input
                className="form-control form-icon-input"
                placeholder="Ring"
                maxLength={9}
                {...register("ring")}
              />
              {errors.ring && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.ring.message}
                </span>
              )}
            </div>
            <div className={`col-lg-${initialFormValues.auctionId > 0 ? '2' : '3'}`}>
            <div className={`col-lg-3`}>
              <label className="form-label">Quantity <sup className="text-danger">*</sup></label>
              <CurrencyInput
                {...register("quantity")}
                name="quantity"
                placeholder="Enter quantity"
                // prefix="$"
                allowDecimals={false}
                onKeyDown={handleKeyDown}
                decimalsLimit={6}
                value={quantity}
                onValueChange={(value: any) => setValue("quantity", value)}
                className="form-control form-icon-input"
                required
              />
              {errors.quantity && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.quantity.message}
                </span>
              )}
            </div>
            {initialFormValues.auctionId > 0 && <div className="col-lg-3">
              <label className="form-label">Auction Name</label>
              <div className="mt-1">
                <span
                  className={`badge badge-dpa status-notposted`}
                >
                  {auctionData.auctionTitle}
                </span>
              </div>
            </div>}
            <div className={`${auctionData.auctionId > 0 ? 'col-lg-2' : 'col-lg-3'}`}>
              <label className="form-label">
                Lot Status
              </label>
              <div className="mt-1">
                <span
                  className={`badge badge-dpa status-${initialFormValues?.statusNew ? initialFormValues?.statusNew
                    ?.replace(/ /g, "")
                    .toLowerCase() : 'pending'}`}
                >
                  {initialFormValues?.statusNew ? initialFormValues?.statusNew : 'Pending'}
                </span>
              </div>
            </div> */}
            <div className="col-xxl-12 position-relative">
              <div className="mx-4 position-absolute" style={{ right: "0", top: "-6px" }}>
                <label className="form-label">
                  Lot Status
                </label>
                <div className="mt-1">
                  <span
                    className={`badge badge-dpa status-${initialFormValues?.statusNew ? initialFormValues?.statusNew
                      ?.replace(/ /g, "")
                      .toLowerCase() : 'pending'}`}
                  >
                    {initialFormValues?.statusNew ? initialFormValues?.statusNew : 'Pending'}
                  </span>
                </div>
              </div>
              <label className="form-label">Lot Title <sup className="text-danger">*</sup></label>
              <Tabs defaultActiveKey="titleSource" className="mb-3 nav-underline border-bottom">
                <Tab eventKey="titleSource" title="Source" className="title_ckedit">
                  <CustomCkEditor data={title} onTextChanged={onEditorTitleChange} />
                  {errors?.title ? (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors?.title?.message}
                    </span>
                  ) : error ? (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errorTitleMsg.current}
                    </span>
                  ) : (
                    ""
                  )}
                </Tab>
                <Tab eventKey="titlePreview" title="Preview">
                  <div className="card box-shadow">
                    <div className="card-body ck_editor">{title && parse(title)}</div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="col-lg-12 lotDescriptionCk">
              <label className="form-label">Lot Description <sup className="text-danger">*</sup></label>
              <Tabs defaultActiveKey="descriptionSource" className="mb-3 nav-underline border-bottom">
                <Tab eventKey="descriptionSource" title="Source">
                  <CustomCkEditor data={desc} onTextChanged={onEditorDescChange} />
                  {errors.lotDescriptiopn ? (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.lotDescriptiopn.message}
                    </span>
                  ) : extractTextFromHTML(desc).length == 0 && mode === "edit" ? (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      Lot Description is required
                    </span>
                  ) : (
                    ""
                  )}
                </Tab>
                <Tab eventKey="descriptionPreview" title="Preview">
                  <div className="card box-shadow">
                    <div className="card-body ck_editor">{desc && parse(desc)}</div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <label className="form-label">Minimum Quantity</label>
              <CurrencyInput
                {...register("minimumQuantity")}
                name="minimumQuantity"
                placeholder="Minimum Quantity"
                // prefix="$"
                allowDecimals={false}
                decimalsLimit={6}
                onKeyDown={handleKeyDown}
                value={minimumQuantity}
                onValueChange={(value: any) =>
                  setValue("minimumQuantity", value)
                }
                className="form-control form-icon-input"
              />
              {errors.minimumQuantity && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.minimumQuantity.message}
                </span>
              )}
            </div> */}
            <div className="row g-3">
              <div className="col-lg-3 col-md-6">
                <label className="form-label">Is Lienholder ?</label>
                <div className="d-flex gap-3">
                  {taxExemptType?.map((tData: any, tIndex: any) => {
                    return (
                      <div key={tIndex}>
                        <input
                          id={`type_${tData.value}`}
                          type="radio"
                          className="form-check-input me-2"
                          name="isLienholder"
                          value={tData.value}
                          checked={tData.value === watchLien}
                          onChange={() => handleRadioChange(tData.value)}
                        />
                        <label htmlFor={`type_${tData.value}`}>
                          {tData.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <label className="form-label">
                  Lienholder Name
                  {watchLien && <sup className="text-danger">*</sup>}
                </label>
                <input
                  className="form-control form-icon-input"
                  disabled={!watchLien}
                  placeholder="Lienholder Name"
                  type="text"
                  {...register("lienholderName")}
                />
                {watchLien && errors.lienholderName && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.lienholderName.message}
                  </span>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label className="form-label">
                  Net Proceeds{" "}
                  {watchLien && <sup className="text-danger">*</sup>}
                </label>
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select"
                  options={netProceed}
                  {...register("netProceeds")}
                  value={netProceed?.find(
                    (option: any) => option?.value == watchNetProceeds
                  )}
                  onChange={(e: any) => {
                    setValue("netProceeds", e.value);
                    handleSelectChange(e);
                  }}
                  className="isActive"
                />
                {watchLien && errors.netProceeds && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.netProceeds.message}
                  </span>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label className="form-label">
                  Pay Off{" "}
                  {watchLien && enablePayoff && (
                    <sup className="text-danger">*</sup>
                  )}
                </label>
                <input
                  className="form-control form-icon-input"
                  disabled={!enablePayoff}
                  placeholder="Pay Off"
                  type="text"
                  maxLength={8}
                  {...register("payoff")}
                  onKeyDown={handleKeyDown}
                />
                {watchLien && enablePayoff && errors.payoff && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.payoff.message}
                  </span>
                )}
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-4 col-md-6">
                <label className="form-label">Auction Type <sup className="text-danger">*</sup></label>
                <div className="code-to-copy">
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select"
                    options={auctionTypes}
                    {...register("auctionType")}
                    value={auctionTypes?.find(
                      (option: any) => option?.value == watchAuctionType
                    )}
                    onChange={(e: any) => {
                      setValue("auctionType", e.value);
                    }}
                    className="isActive"
                  />
                  {errors.auctionType && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.auctionType.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <label className="form-label">Category <sup className="text-danger">*</sup></label>
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select Category"
                  options={filterCategory}
                  {...register("category")}
                  value={filterCategory?.find(
                    (option: any) => option?.value == category
                  )}
                  onChange={(e: any) => {
                    setValue("category", e.value);
                  }}
                  className="isActive"
                />
                {errors.category && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.category.message}
                  </span>
                )}
              </div>
              <div className="col-lg-4">
                <label className="form-label">Ctrl No. <sup className="text-danger">*</sup></label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Ctrl No."
                  {...register("ctrlNo")}
                  disabled
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-lg-3 col-md-6">
                <label className="form-label">Start Bid</label>
                <CurrencyInput
                  {...register("startBid")}
                  name="startBid"
                  placeholder="Start Bid"
                  prefix="$"
                  onKeyDown={handleKeyDown}
                  value={startBid}
                  onValueChange={(value: any) => setValue("startBid", value)}
                  className="form-control form-icon-input"
                />
                {errors.startBid && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.startBid.message}
                  </span>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label className="form-label">Reserve Amount</label>
                <CurrencyInput
                  // name="reserve"
                  placeholder="Reserve Amount"
                  {...register("reserve")}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                  value={watchReserve}
                  onValueChange={(value: any) => setValue("reserve", value)}
                  className="form-control form-icon-input"
                // required
                />
                {watchReserveDisplay && errors.reserve && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.reserve.message}
                  </span>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <label className="form-label">Seller <sup className="text-danger">*</sup></label>
                <Select
                  classNamePrefix="dpaSelect"
                  aria-label="Default select example"
                  options={sellerList}
                  name="seller"
                  noOptionsMessage={() => "No record found"}
                  value={sellerList?.find(
                    (option: any) => option?.value == seller)}
                  onChange={(selectedOption: any) => {
                    setValue("seller", selectedOption?.value);
                  }}
                  onMenuOpen={() => {
                    if (sellerList?.length === 0) {
                      Swal.fire({
                        icon: "warning",
                        title: "Oops!, No sellers found",
                        text: "Please select sellers from this auction first ",
                        confirmButtonColor: "#006800",
                        confirmButtonText: "ok",
                      });
                    }
                  }}
                />
                {errors.seller && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.seller.message}
                  </span>
                )}
              </div>
              <div className="col-lg-3 col-md-6">
                <button
                  className="btn btn-primary mt-4"
                  type="button"
                  disabled={getValues("seller") === ""}
                  onClick={navigateToSeller}
                >View Seller</button>
                {/* <label className="form-label">Sales Rep <sup className="text-danger">*</sup></label>
                {!reRender &&
                  <div className="multiselect-count">
                    <CustomCheckbox
                      options={salesRepList}
                      onChange={handleChange}
                      defaultSelected={salesRep}
                    />
                    {errors.salesRep && (
                      <span className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.salesRep.message}
                      </span>
                    )}
                  </div>
                } */}
              </div>
            </div>
            <div className="col-lg-2">
              <label className="form-label ">Online</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={params.id == undefined}
                  {...register("status")}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <label className="form-label ">Tax Exempt</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register("taxExempt")}
                />
              </div>
            </div>
            {/* <div className="col-lg-1">
              <label className="form-label ">Posted</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  {...register("posted")}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Reserve Display</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register("reserveDisplay")}
                  id="reserve-check"
                />
                <label className="" htmlFor="reserve-check"> Yes </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">ID Num</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="ID Num"
                maxLength={10}
                {...register("idNum")}
                onKeyDown={handleKeyDown}
              />
              {errors.idNum && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.idNum.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">External ID</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="External Id"
                maxLength={10}
                {...register("externalId")}
                onKeyDown={handleKeyDown}
              />
              {errors.externalId && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.externalId.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Unit Cost</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={11}
                placeholder="Unit Cost"
                {...register("unitCost")}
                onKeyDown={handleKeyDown}
              />
              {errors.unitCost && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.unitCost.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">List-Low</label>
              <input
                className="form-control form-icon-input"
                placeholder="List-Low"
                type="text"
                maxLength={11}
                {...register("listLow")}
                onKeyDown={handleKeyDown}
              />
              {errors.listLow && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.listLow.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">List-High</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={11}
                placeholder="List-High"
                {...register("listHigh")}
                onKeyDown={handleKeyDown}
              />
              {errors.listHigh && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.listHigh.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Year</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={4}
                placeholder="Year"
                {...register("year")}
                onKeyDown={handleKeyDown}
              />
              {errors.year && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.year.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Manufacturer</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Manufacturer"
                maxLength={101}
                {...register("manufacturer")}
              />
              {errors.manufacturer && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.manufacturer.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Model</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Model"
                maxLength={101}
                {...register("model")}
              />
              {errors.model && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.model.message}
                </span>
              )}
            </div>
            <div className="col-lg-3 col-md-6">
              <label className="form-label">Engine Hours</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Engine Hours"
                maxLength={8}
                {...register("engineHours")}
                onKeyDown={handleKeyDown}
              />
              {errors.engineHours && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.engineHours.message}
                </span>
              )}
            </div> */}
          </div>
          <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom">
            <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger mx-2">{CommonMessages.cancelButton}</Link>
            <div className="ms-auto">
              {/* {(mode === "edit" || (mode === "create" && lotId > 0) || (mode === "clone" && cloneLotId > 0)) && ( */}
              {(mode === "edit" || (createTabs.includes(LOTADDRESS)) || (clonedTabs.includes(LOTADDRESS))) && (
                <span className="btn btn-outline-primary me-2" onClick={handleClone}><i className="fas fa-clone dropdown-icon"></i> Clone Inventory</span>
              )}
              <button className="btn btn-primary me-2" type="submit">{btnText}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default LotsPage;