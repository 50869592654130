import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import * as routeConstant from '../../../common/routeConstants'
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { decryptId } from "../../../Encryption/Encryption";
import { ADDBLOBBIDDERDOCUMENT, DELETEBIDDERDOCUMENT, GETCUSTOMERBLOBDOCUMENTSBYCUSTOMERID } from "../../../common/apiConstatnts";
import axios from "axios";
import Swal from "sweetalert2";
import { setActiveTabCustomer, setBidderDocumentData } from "../../../store/actions/CustomerAction";
import { CommonMessages, CustomerMessages, errorMessages } from "../../../common/messageConstants";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const BidderDocuments = () => {
    const dispatch = useDispatch()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/pdf"];
    const [docsData, setdocsData] = useState<any>([]);
    const [OriginaldocsData, setOriginaldocsData] = useState<any>([]);
    const [filterDocs, setFilterDocs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [docUpload, setDocUpload] = useState<boolean>(false)
    const [documents, setDocuments] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    // Get by customer ID documents
    const getBidderDocument = () => {
        setLoading(true);
        axios
            .get(`${GETCUSTOMERBLOBDOCUMENTSBYCUSTOMERID}?CustomerId=${decryptId(ID)}`)
            .then(
                (response: any) => {
                    if (response?.data?.success === "Fail") {
                        setLoading(false);
                        // toast.error(response?.data?.message, {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     autoClose: 2000,
                        // });
                        return;
                    } else {
                        const data = response?.data?.data;
                        setLoading(false);

                        if(data){
                            setOriginaldocsData(data)
                            paginateData(data)
                            dispatch(setBidderDocumentData(data))
                        }else{
                            setOriginaldocsData([])
                            setFilterDocs([])
                        }
                    }
                },
                (error) => {
                    setLoading(false);
                    if (error.response.status !== 401) {
                        toast.error(errorMessages.error401, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }
            );
    }

    useEffect(() => {
        getBidderDocument()
    }, [])

    const PER_PAGE = 10;
    const pageCount = Math.ceil(docsData?.length / PER_PAGE);

    const paginateData = (data:any) => {
        setdocsData(data);
        if (data.length === 0) {
            setFilterDocs([]);
        } else {
            const firstSet = data.slice(0, PER_PAGE);
            setFilterDocs(firstSet);
        }
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSearch = (keyword: any) => {
        setSearchText(keyword);
        if (keyword !== '') {
            const key = keyword?.toLowerCase();
            const filterData: any = OriginaldocsData?.filter((x: any) => x.documentName?.toLocaleLowerCase()?.includes(key))
            paginateData(filterData)
            setCurrentPage(0)
        } else {
            getBidderDocument();
        }
    }

    const handleUpload = (e: any) => {
        const filesLength = e.target.files?.length;
        // Checking if the file type is allowed or not
        if (!allowedTypes.includes(e.target.files[0]?.type)) {
            toast.warning(errorMessages.formatNotAllowed, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            e.target.value = null;
            return

        } else {
            let uploadedData: any = []
            for (let i = 0; i < filesLength; i++) {
                const file = e.target.files[i]
                if (!allowedTypes.includes(file?.type)) continue
                const docObj = {
                    bidderDocumentId: 0,
                    customerId: parseInt(decryptId(ID)),
                    documents: file,
                    isActive: true,
                    isDelete: false,
                    uniqueId: uuidv4()
                }

                uploadedData.push(docObj)
            }

            setDocuments([...documents, ...uploadedData])
        }
    }

    const handleSubmit = (e: any) => {
        setLoading(true);
        e.preventDefault();
        const formData: any = new FormData();
        const finalData = documents.filter((data: any) => Object.keys(data)?.length > 0)

        if (finalData.length > 0) {
            if (finalData.length > 0) {
                let i = 0;
                finalData.forEach((doc: any) => {
                    formData.append(`doc[${i}].bidderDocumentId`, doc?.bidderDocumentId);
                    formData.append(`doc[${i}].customerId`, doc?.customerId);
                    formData.append(`doc[${i}].documents`, doc?.documents);
                    formData.append(`doc[${i}].documentName`, doc?.documents?.name);
                    formData.append(`doc[${i}].isActive`, doc?.isActive)
                    formData.append(`doc[${i}].isDelete`, false)
                    i++
                });
                axios.post(ADDBLOBBIDDERDOCUMENT, formData).then(async (response) => {
                    setLoading(false);
                    const data = response?.data?.data
                    getBidderDocument();
                    setdocsData([...docsData, ...data])
                    setDocuments([])
                    setDocUpload(false)
                    setSearchText('');
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(CustomerMessages.bidDocCreated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }).catch((error)=>{
handleError(error);
                });
            } else {
                setLoading(false);
                toast.error(errorMessages.nothingToSave, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        } else {
            setLoading(false);

            toast.success('Please select bidder document !', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    // const deleteDocument = (id: any) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You want to delete this document!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#006800',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, Delete it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             setLoading(true)
    //             axios.post(`${DELETEBIDDERDOCUMENT}?bidderDocumentId=${id}`).then((response) => {
    //                 if (response?.data?.success === "Fail") {
    //                     toast.error(response?.data?.message, {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                         autoClose: 2000,
    //                     });
    //                     return;
    //                 }
    //                 else {
    //                     // let updatedData = OriginaldocsData.filter((i: any) => id !== i.bidderDocumentId)
    //                     // setdocsData(updatedData)
    //                     // dispatch(setBidderDocumentData(updatedData))
    //                     getBidderDocument();
    //                     setLoading(false);
    //                     setSearchText('')
    //                     toast.success(CustomerMessages.bidDocDeleted, {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                         autoClose: 1500,
    //                     });
    //                 }
    //             }, (error) => {
    //                 setLoading(false);
    //                 toast.warning("Some error occured!", {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                     autoClose: 1500,
    //                 });
    //             });
    //         }
    //     })

    // }
    const deleteDocument = (id:any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this document!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(`${DELETEBIDDERDOCUMENT}?bidderDocumentId=${id}`).then((response) => {
                    if (response?.data?.success === "Fail") {
                        setLoading(false);
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    } else {
                        const updatedData = OriginaldocsData.filter((i:any) => id !== i.bidderDocumentId);
                        setOriginaldocsData(updatedData);
                        paginateData(updatedData);
                        setLoading(false);
                        setSearchText('');
                        toast.success(CustomerMessages.bidDocDeleted, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1500,
                        });

                        if (updatedData.length === 0) {
                            setCurrentPage(0); // Reset to the first page if all records are deleted
                        }
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
            }
        });
    }

    const handlePrevious = () => {
        dispatch(setActiveTabCustomer('uploadPhoto'));
    }

    const handleDownload = (docs: any, token: any) => {
        const downloadUrl = `${docs}?${token}`;
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = docs;
        anchor.target = '_blank'
        anchor.click();
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = docsData?.slice(offset, offset + PER_PAGE)
        setFilterDocs(nextSet)
    }, [currentPage])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={docUpload} className='md-modal' centered>
                <form noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Upload Document</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setDocUpload(false); setDocuments([]) }}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <p className='form-label mb-2 fs--1'>Upload documents : PDF or Docs File</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-12'>
                                <label className="form-label mb-2">Upload File</label>
                                <input type='file' className='form-control' onChange={handleUpload} multiple />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={() => { setDocUpload(false); setDocuments([]) }}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="button" onClick={handleSubmit} disabled={documents.length == 0}>Upload</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10">
                        <div className="mt-2 mb-4 border-300">
                            <div className="row">
                                <div className="col-12 mt-3 d-flex flex-column justify-content-start">
                                    <h4>Bidder Documents</h4>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <div className="search-box mt-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                                    <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" maxLength={200} value={searchText} onChange={(e: any) => handleSearch(e.target.value)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-1">
                                            <button type="button" className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => setDocUpload(true)}>
                                                <i className="fas fa-plus"></i>
                                                <span>Add Document</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Action</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Sr No.</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Ctrl">
                                                                <div className='d-flex'>
                                                                    <span>Document Name</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list scrollbar" id="table-latest-review-body">
                                                        {filterDocs?.length > 0 ?
                                                            filterDocs?.map((doc: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant="none">
                                                                                    <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                    <Dropdown.Item onClick={() => handleDownload(doc?.documents, doc?.sasToken)} as='button' type="button" className="dropdown-item d-flex align-items-center justify-content-between">Download  <i className="fas fa-download"></i></Dropdown.Item>
                                                                                    <Dropdown.Item role='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => deleteDocument(doc?.bidderDocumentId)} >Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td className="p-2">{`${PER_PAGE * currentPage + index + 1}`}</td>
                                                                        <td className="align-middle  p-2">
                                                                            <Link
                                                                                target="_blank"
                                                                                to={`${doc?.documents}?${doc?.sasToken}`}
                                                                            >{doc?.documentName ?? "-"}</Link>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={5}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterDocs?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS}>{CommonMessages.cancelButton}</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BidderDocuments