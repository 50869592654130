// export const AlPHANUMERIC = /^(?!^\d+(\s\d+)*$)[a-zA-Z0-9]*(?:\s[a-zA-Z0-9]+)*$/

export const AlPHANUMERIC = /^$|^(?!^\d+(\s\d+)*$)[a-zA-Z0-9\s]*(?:\s[a-zA-Z0-9\s]+)*\s*$/
export const STRINGSPACE = /^(?!^(\s)*$)[a-zA-Z]*(?:\s[a-zA-Z]+)*$/
export const AlPHANUMERICTYPETWO = /^(?!^\s+$)[\s a-zA-Z0-9]*$/
export const AlPHANUMERICLIMIT50 = /^[\s a-zA-Z0-9]{0,50}$/
export const AlPHATYPETWO = /^(?!^\s+$)[\s a-zA-Z]*$/

// export const INTEGER = /^[0-9]{0,8}$/
export const INTEGER = /^\s*(?:\d{0,9})?\s*$/
export const INTEGER_MESSAGE = 'Please enter valid number upto 9 digits.'

//allows float number with five digits after decimal.
export const DECIMAL = /^(?!.*\s)(?:[0-9]\d*|0)?(?:\.\d{1,5})?$/
export const DECIMAL_MESSAGE = 'Please Enter Valid Number.'

//allows float number with 3 digits, 2 digits after decimal.
export const DECIMAL32 = /^(?!.*\s)(?:[0-9]\d{0,2}|0)?(?:\.\d{1,2})?$/
export const DECIMAL32_MESSAGE = 'Please Enter Valid Number upto 3 digits & 2 digit after dot.'

//allows float number with 5 digits, 2 digits after decimal.
export const DECIMAL5 = /^(?!.*\s)(?:[0-9]\d{0,4}|0)?(?:\.\d{1,2})?$/
export const DECIMAL5_MESSAGE = 'Please Enter Valid Number upto 5 digits & 2 digit after dot.'

//allows float number with 6 digits, 2 digits after decimal.
// export const DECIMAL82 = /^(?:[0-9]\d{0,5}|0)?(?:\.\d{1,2})?$/
export const DECIMAL82 = /^(?!.*\s)(?:[0-9]\d{0,5}|0)?(?:\.\d{1,2})?$/
export const DECIMAL82_MESSAGE = 'Please Enter Valid Number upto 6 digits & 2 digit after dot.'

//allows float number with 7 digits, 3 digits after decimal.
// export const DECIMAL73 = /^(?:[0-9]\d{0,6}|0)?(?:\.\d{1,3})?$/

// ^\d{1,7}(?:\.\d{1,3})?$

export const DECIMAL73 = /^(?!.*\s)(?:[0-9]\d{0,6}|0)?(?:\.\d{1,3})?$/
export const DECIMAL73_MESSAGE = 'Please Enter Valid Number upto 7 digits and 3 digit after dot.'

//allows float number with 6 digits, 3 digits after decimal.
export const DECIMAL63 = /^(?!.*\s)(?:[0-9]\d{0,8}|0)?(?:\.\d{1,3})?$/
export const DECIMAL63_MESSAGE = 'Please Enter Valid Number upto 9 digits and 3 digit after dot.'

export const DECIMAL83 = /^(?!.*\s)(?:[0-9]\d{0,7}|0)?(?:\.\d{1,2})?$/
export const DECIMAL83_MESSAGE = 'Please Enter Valid Number upto 8 digits and 2 digit after dot.'

//allows float number with 9 digits, 3 digits after decimal.
export const DECIMAL93 = /^(?!.*\s)(?:[0-9]\d{0,8}|0)?(?:\.\d{1,3})?$/
export const DECIMAL93_MESSAGE = 'Please Enter Valid Number upto 9 digits and 3 digit after dot.'

//allows float number with 9 digits, 9 digits after decimal.
export const DECIMAL99 = /^(?!.*\s)(?:[0-9]\d{0,8}|0)?(?:\.\d{1,9})?$/
export const DECIMAL99_MESSAGE = 'Please Enter Valid Number upto 9 digits and 3 digit after dot.'

// allow percentage upto 3 digits 
export const PERCENTAGE = /^(?!.*\s)(?:[0-9]\d{0,1}|0)?(?:\.\d{1,2})?$/
export const PERCENTAGE_MESSAGE = 'Please Enter Number between 0-99 upto 2 decimal digit.'

//allows special char with string, not allows only number/special char, ending/leading space.
// export const SPECIALCHARS = /^((?!^\s)(?=.*[a-zA-Z])[\w\W]*(?<!\s\s)[\w\W]*)*$/
export const SPECIALCHARS = /^(?!^\s+$)[\w\W\s]*$/

//only 4 digits allow
export const YEAR = /^(?:\d{4}|\d{0})$/
export const YEAR_MESSAGE = 'Please enter valid number with exact 4 digits'

//only 6 digits allow
export const ZIP = /^(?!\s+$)(?!^[a-zA-Z]+$)[a-zA-Z0-9\s]{0,9}$/
export const ZIP_MESSAGE = 'Please enter valid Zip code upto 9 characters.'

//only 10 digits allow
export const FAX = /^(\d{10})*$/
export const FAX_MESSAGE = 'Please enter valid number with 10 digits'

//only 6 digits allow
export const PHONE = /^(\d{10,12})*$/
export const PHONE_MESSAGE = 'Please enter valid number'

export const PHONEFILTER = /^(\d{4,12})*$/
export const PHONE_MESSAGEFILTER = 'Please enter 4 to 12 digits for search'

// export const EMAIL = /^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)+)\s*$/
// /^\s*(?:([\w\.-]+)@([\w-]+(?:\.[^\W\d_]+)*)\s*)?$/;
// export const EMAIL =  /^\s*(?:([\w\.-]+)@([\w-]+(?:\.[^\W\d_]+)*)\s*)?$/;


export const EMAIL = /^(?:\s*(?:(?!\s+$)([\w.-]+(?:\s+[\w.-]+)*)@[a-zA-Z\d.-]+\.[a-zA-Z]{2,})?\s*)?$/;
export const EMAIL_MESSAGE = 'Please enter valid email';

export const COMPANYNAMEREGEX = /^$|^(?!^\d+(\s\d+)*$)[\w\d ,.&'"/s-]*$/
export const FIRSTANDLASTNAMEREGEX = /^(?!^\d+(\s\d+)*$)(?=.*[a-zA-Z])[a-zA-Z0-9\-!@#$%^&*()+={}[\]:;\"'<>,.?\\/]+(?:\s[a-zA-Z0-9\-!@#$%^&*()+={}[\]:;\"'<>,.?\\/]+)*\s*$/

// export const URL = /^\s*(?!https:\/\/www$)((https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,6})(?:\/[^\s]*)?)?\s*$/;
// export const URL = /^(?!https:\/\/www$)(https?:\/\/\S+)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?)$/i ;
// export const URL = /^(https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
export const URL = /^(?!https:\/\/www$)((https?:\/\/)?([\d\w\W a-z.-]+)\.([a-z.\w\W]{2,6})([/\w\W.-]*)*\/?)?$/;
// export const URL = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
export const URL_MESSAGE = 'Please enter valid URL'
