import Tippy from "@tippyjs/react"
import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import "react-datetime/css/react-datetime.css"
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Select from 'react-select'
import { toast } from "react-toastify"
import mapIcon from '../../../assets/map.svg'
import { AddressForm } from "../../../common/AddressForm"
import { GETALLBIDDERCONFIG, GETCOMMONVALUE, GETTIMEZONEIDENTIFIER } from "../../../common/apiConstatnts"
import { charCountForAddress } from "../../../common/commonFunctions"
import {  wordCounter } from "../../../common/constants"
import { CommonMessages } from "../../../common/messageConstants"
import * as routeConstant from '../../../common/routeConstants'
import { loader, setAddressTypes } from "../../../store/actions/CommonAction"
import DateTime from "../../../Utils/DateTime"
import { handleKeyDown } from "../../../Utils/Utils"
import MapContainer from "../../Auction/NewAuction/AuctionInfo/MapContainer"
import GooglePlaces from "../../Company/GooglePlaces"
import LastModified from "../../LastModifiedDateTime/LastModifiedDateTime"
import ModifiedBy from "../../LastModifiedDateTime/ModifiedBy"
import CustomCheckbox from "./MultipleSelectCheckbox"
import handleError from "../../../Utils/ApiCommonErrorMessages"

const ContactInfo = (
    {
        watch,
        register,
        control,
        errors,
        trigger,
        setValue,
        setData,
        reset,
        mode,
        longitude,
        setLongitude,
        latitude,
        setLatitude,
        latLog,
        setlatLog,
        locations,
        setLocations,
        isValid,
        initialFormValue,
        reasonForSuspend,
        suspendStatus,
        sellerList,
        cnfError,
        customerTimeZone,
        active,
        modifiedBy,
        setCountryCode,
        setPrevCountryCode,
        countryCode,
        generatePhoneValidationSchema,
        prevCountryCode,
        countryCodeNew,
        address1,
        initialsId,
        seller,
        suspendedReason,
        level,
        setSelectedSellerList,
        onSubmit
    }: any) => {

    const [initials, setIntials] = useState<[]>([]);
    const [timeZones, setTimeZone] = useState<[]>([]);
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const getContactInfo = useSelector((state: any) => state?.customer?.contactInfo);
    const addressData = useSelector((state: any) => state.customer?.addressInfo);
    const [address, setAddress] = useState('');
    const [isAddressFilled, setIsAddressFilled] = useState(false);
    const [location, setLocation] = useState('');
    const maxWordCount = 150
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [flag, setFlag] = useState<any>('us');
    const [remainingCountAdd, setRemainingCountAdd] = useState(250);
    const [remainingCountAdd2, setRemainingCountAdd2] = useState(250);
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const dispatch = useDispatch();
    const add1 = watch('add1')
    const wlatitude = watch('latitude')
    const wlongitude = watch('longitude')
    const isActive = watch('isActive');
    const [age, setAge] = useState(0);

    const [defaultSelected, setDefaultSelected] = useState('');

    useEffect(() => {
        if (address1) {
            setAddress(address1);
        }
    }, [address1]);

    useEffect(() => {
        setDefaultSelected(getContactInfo?.levelType || getContactInfo?.level);
    }, [getContactInfo]);

    const DOB = watch('dateOfBirth')
    const age1 = watch('age')

    const check = () => {
        if (suspendStatus != getContactInfo?.suspendStatus || suspendedReason) {
            return true
        }
        return false
    };

    useEffect(() => {
        generatePhoneValidationSchema(countryCode)
    }, [countryCode])

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const contactInfoCheckBocx = [
        { id: 1, key: 'taxExempt', name: "Tax Exempt" },
        { id: 2, key: 'reseller', name: "Reseller" },
        { id: 3, key: 'taxFromOnFile', name: "Tax From On File" },
        { id: 4, key: 'shippingOutOfState', name: "Shipping Out Of State" },
        { id: 5, key: 'buyBackBidderOnly', name: "Buyback Bidder Only" },
        { id: 6, key: 'shippingInstate', name: "Shipping Instate" }
    ]

    const levelTypes = [
        { value: "1", label: "Standard" },
        { value: "2", label: "Gold" },
    ];
    const defaultTimezoneData: any = [{
        label: '(UTC) America/ Chicago', value: 'UTC'
    }]
    useEffect(() => {
        setValue('levelType', defaultSelected);
    }, [defaultSelected]);

    useEffect(() => {
        if (Object.keys(getContactInfo).length === 0) {
            reset(setData)
            setValue('country1', getContactInfo?.country1 || getContactInfo?.lstCustomerAddress?.[0]?.countryName);
            setValue('state1', getContactInfo?.state1 || getContactInfo?.lstCustomerAddress?.[0]?.stateName);
            setValue('zip', getContactInfo?.zip || getContactInfo?.lstCustomerAddress?.[0]?.zipCode);
            setValue('city1', getContactInfo?.city1 || getContactInfo?.lstCustomerAddress?.[0]?.cityName);
            setValue('county1', getContactInfo?.county1 || getContactInfo?.lstCustomerAddress?.[0]?.countyName);
            setValue('address2', getContactInfo?.address2 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine2);
            setValue('add1', getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setAddress(getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setValue('levelType', getContactInfo?.levelType || getContactInfo?.level);
        }
        else {
            reset(getContactInfo)
            setValue('country1', getContactInfo?.country1 || getContactInfo?.lstCustomerAddress?.[0]?.countryName);
            setValue('state1', getContactInfo?.state1 || getContactInfo?.lstCustomerAddress?.[0]?.stateName);
            setValue('zip', getContactInfo?.zip || getContactInfo?.lstCustomerAddress?.[0]?.zipCode);
            setValue('city1', getContactInfo?.city1 || getContactInfo?.lstCustomerAddress?.[0]?.cityName);
            setValue('county1', getContactInfo?.county1 || getContactInfo?.lstCustomerAddress?.[0]?.countyName);
            setValue('address2', getContactInfo?.address2 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine2);
            setValue('add1', getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setAddress(getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setValue('levelType', getContactInfo?.levelType || getContactInfo?.level);
        }

        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setIntials(formateData);
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })

        axios.get(GETTIMEZONEIDENTIFIER, config).then((response: any) => {
            if (response?.data?.data?.length > 0) {
                // Set response in time zone state
                let formateData = response.data.data?.map((data: any, index: any) => {
                    //Format response in label and value format for select dropdown
                    return {
                        label: data?.label,
                        value: data?.timeZoneIdentifierId,
                    };
                })
                setTimeZone(formateData);
            } else {
                setTimeZone(defaultTimezoneData);
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })

        axios.get(`${GETCOMMONVALUE}?filter=AddressType&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const data = response.data.data
                let addressTypes: any = {}
                for (let i = 0; i < data.length; i++) {
                    let adr = data[i]
                    addressTypes[`${adr.objectTypeValue.split(" ")[0]}`] = { customerAddressType: adr.objectValueId, strCustomerAddressType: adr.objectTypeValue }
                }
                dispatch(setAddressTypes(addressTypes))
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }, []);

    useEffect(() => {
        if (getContactInfo) {
            reset(getContactInfo);
            setValue('country1', getContactInfo?.country1 || getContactInfo?.lstCustomerAddress?.[0]?.countryName);
            setValue('state1', getContactInfo?.state1 || getContactInfo?.lstCustomerAddress?.[0]?.stateName);
            setValue('zip', getContactInfo?.zip || getContactInfo?.lstCustomerAddress?.[0]?.zipCode);
            setValue('city1', getContactInfo?.city1 || getContactInfo?.lstCustomerAddress?.[0]?.cityName);
            setValue('county1', getContactInfo?.county1  || getContactInfo?.lstCustomerAddress?.[0]?.countyName);
            setValue('address2', getContactInfo?.address2 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine2);
            setValue('add1', getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setAddress(getContactInfo?.add1 || getContactInfo?.lstCustomerAddress?.[0]?.addressLine1);
            setValue('mobile', getContactInfo?.mobile);
            setValue('phone', getContactInfo?.phone);
            setValue('levelType', getContactInfo?.levelType || getContactInfo?.level);
            setValue('dateOfBirth', moment(getContactInfo?.dateOfBirth).format('MM/DD/yyyy'));
            if (getContactInfo?.dateOfBirth == '') {
                setValue('age', 0)
                setAge(0)
            } else {
                calculateAge(getContactInfo?.dateOfBirth)
            }
        }
    }, [addressData, getContactInfo]);

    const calculateAge = (dateTimeString: any) => {
        const birthdate = new Date(dateTimeString);
        const today = new Date()
        let age = today.getFullYear() - birthdate.getFullYear()

        // Check if the birthday hasn't occurred yet this year
        if (
            today.getMonth() < birthdate.getMonth() ||
            (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate())
        ) {
            age--; // Subtract 1 from the age if the birthday hasn't occurred yet
        }
        setValue('age', age)
        setAge(age)
    }

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        setIsAddressFilled(true);
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        } else if (e.target.name === 'addressLine2') {
            setRemainingCountAdd2(remaining);
        } else if ((e.target.name === 'locations')) {
            setLocation(inputText)
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        }
    }

    const handleClose = () => {
        setToggleValue(!toggleModal);
        setLocations('');
    }

    const handleSubmitMap = () => {
        setLatitude(latLog?.lat());
        setLongitude(latLog?.lng());
        setToggleValue(!toggleModal);
    }

    const togglePassword = (field: any) => {
        if (field === "password") {
            setPasswordType(passwordType === "password" ? "text" : "password");
        } else if (field === "confirmPassword") {
            setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
        }
    };

    const handleRadioChange = (data: any) => {
        setValue('suspendStatus', data)
    }

    useEffect(() => {
        if (suspendStatus == true) {
            setValue('isActive', false)
        }
        if (getContactInfo.suspendStatus != suspendStatus) {
            setValue('suspendedReason', '')
            return
        }
        setValue('suspendedReason', getContactInfo?.suspendedReason)
    }, [suspendStatus])

    useEffect(() => {
        if (isActive == true) {
            setValue('suspendStatus', false)
        }
    }, [isActive]);

    const [defaultTimezone, setDefaultTimezone] = useState(null)
    useEffect(() => {
        axios.get(GETALLBIDDERCONFIG).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                response?.data?.data?.filter((item: any) => item.settingKey.includes('liveBoardTimeZone')).map((data: any) => (
                    setDefaultTimezone(data.settingValue)
                ))
            };
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
    }, [defaultTimezone])
   
    const updateCountryCode = (newAddress: any, code: any) => {
    };
    useEffect(() => {
        if (DOB == '') {
            setValue('age', 0)
            setAge(0)
        }
    }, [DOB])

    useEffect(() => {
        if (seller) {
            const filteredOptions = seller.split(',').filter((option: any) => option !== "select_all");
            setSelectedSellerList(filteredOptions);
        }
    }, [seller])

    const handleChange = (selectedOption: string[]) => {
        const filteredOptions = selectedOption?.filter((option: any) => option !== "select_all");
        setSelectedSellerList(filteredOptions);
    };

    return (
        <>
            <div className="container-fluid">
                <form>
                    <div className="row justify-content-center top_border">
                        <div className="col-xxl-10">
                            <div className="mt-4 mb-2 border-300">
                                <h4>Contact Info</h4>
                            </div>
                            <div className="row g-3">
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Initials</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        options={initials}
                                        {...register('initialsId')}
                                        value={initials?.find((option: any) => option?.value == initialsId)}
                                        onChange={(e: any) => setValue('initialsId', e.value)}
                                        className='isActive'
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                    <input type="text" {...register('firstName')} className="form-control" placeholder="First Name" maxLength={21} />
                                    {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input type="text" {...register('middleName')} className="form-control" placeholder="Middle Name" maxLength={21} />
                                    {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('lastName')} placeholder="Last Name" maxLength={21} />
                                    {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label">Company Name</label>
                                    <input type="text" className="form-control" {...register('company')} placeholder="Company Name" maxLength={51} />
                                    {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Time Zone (In Customer Portal)
                                    </label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        id="timeZoneOptions"
                                        options={timeZones}
                                        {...register('customerTimeZone')}
                                        value={timeZones?.find((option: any) => option?.value == customerTimeZone)}
                                        onChange={(e: any) => setValue('customerTimeZone', e.value)}
                                        className='isActive'
                                    />

                                    <span className='mt-2 mb-0 col-lg-5' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: This time zone will be used in the live auction board.</i></span>
                                    {/* {errors.customerTimeZone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.customerTimeZone.message}</span>} */}
                                </div>
                                <AddressForm
                                    errors={errors}
                                    onHandleChangeAddressLine={handleChangeAddressLine}
                                    register={register}
                                    setValue={setValue}
                                    setCountryCode={setCountryCode}
                                    onSetAddress={setAddress}
                                    onAddressChange={updateCountryCode}
                                    watch={watch}
                                    trigger={trigger}
                                    address1={address}
                                />
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                    <Controller
                                        // name="mobile"
                                        {...register('mobile')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Alternate Number </label>
                                    <Controller
                                        // name="phone"
                                        {...register('phone')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Fax</label>
                                    <input type="text" className="form-control" {...register('fax')} placeholder="Fax" onKeyDown={handleKeyDown} />
                                    {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-4 ">
                                    <label className="form-label">
                                        Level
                                    </label>
                                    <div className="code-to-copy">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select"
                                            options={levelTypes}
                                            {...register('levelType')}
                                            value={defaultSelected !== '' ? levelTypes.find((option) => option.value == defaultSelected) : levelTypes[0]}
                                            onChange={(e: any) => {
                                                setValue('levelType', e.value);
                                                setDefaultSelected(e.value);
                                            }}
                                            className='isActive'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-label">Notes</label>
                                    <textarea
                                        className="form-control"
                                        name=""
                                        cols={5}
                                        rows={5}
                                        maxLength={maxWordCount}
                                        placeholder="Notes"
                                        {...register('notes', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}></textarea>

                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}

                                    {errors?.notes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.notes.message}</span>}


                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-10">
                            <hr />
                        </div>

                        <div className="col-xxl-10">
                            <div className="mt-2 mb-2 border-300">
                                <h4>User Credentials</h4>
                            </div>

                            <div className="row g-3">
                                <div className="col-lg-4 col-md-4">
                                    <label className="form-label">User Email <sup className="text-danger">*</sup></label>
                                    <input type="text" name='userName' className="form-control" placeholder="User Email" {...register('userName')} />
                                    {errors.userName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.userName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <label className="form-label">Password <sup className="text-danger">*</sup></label>
                                    <div className='position-relative'>
                                        <input type={passwordType} className="form-control" placeholder="Password" {...register('password')} maxLength={20} />
                                        <span className='hide_show_pass'>
                                            <i
                                                onClick={() => togglePassword("password")}
                                                className={
                                                    passwordType === "password"
                                                        ? "fas fa-eye-slash"
                                                        : "fas fa-eye"
                                                }
                                            ></i>
                                        </span>
                                    </div>
                                    {errors.password && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.password.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <label className='form-label'>Confirm Password <sup className="text-danger">*</sup></label>
                                    <div className='position-relative'>
                                        <input
                                            type={confirmPasswordType}
                                            placeholder='Confirm Password'
                                            className='w-100 form-control'
                                            {...register('confirmPassword')}
                                            maxLength={20}
                                        />
                                        <span className='hide_show_pass'>
                                            <i
                                                onClick={() => togglePassword("confirmPassword")}
                                                className={
                                                    confirmPasswordType === "password"
                                                        ? "fas fa-eye-slash"
                                                        : "fas fa-eye"
                                                }
                                            ></i>
                                        </span>
                                    </div>
                                    {errors.confirmPassword && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.confirmPassword.message}</span>}
                                    {cnfError && <span className='text-danger' style={{ fontSize: "12px" }}>Password must match with confirm password</span>}
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-10">
                            <hr />
                        </div>

                        <div className="col-xxl-10">
                            <div className="mt-2 mb-2 border-300">
                                <h4>Contact and Default Info</h4>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label">Date Of Birth</label>
                                    {/* <input type="date" name='dateOfBirth' className="form-control" {...register('dateOfBirth', { onChange: (e: any) => { calculateAge(e) } })} /> */}
                                    <div className="date_icon">
                                        <Controller
                                            name="dateOfBirth"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <DateTime
                                                    onChange={(selectedDate) => {
                                                        onChange(selectedDate);
                                                        calculateAge(selectedDate);
                                                    }}
                                                    value={value == 'Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)' ? null : value}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    dateFormat="MM/DD/y"
                                                    inputProps={{
                                                        placeholder: "mm/dd/yyyy",
                                                        className: "form-control form-icon-input only_date_icon",
                                                        name: "dateOfBirth"
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.dateOfBirth && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.dateOfBirth.message}</span>}
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Age <small className="fw-bold text-primary fs--2">(Select DOB to auto calculate)</small></label>
                                    <input type="text" className="form-control" placeholder="Age" {...register('age')} value={DOB !== null ? age : 0} disabled={age} readOnly />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Tax ID </label>
                                    <input type="text" className="form-control" placeholder="Tax ID" {...register('taxId')} onKeyDown={handleKeyDown} />
                                    {errors.taxId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxId.message}</span>}
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">Account Code</label>
                                    <input type="text" className="form-control" placeholder="Account Code" {...register('accountCode')} maxLength={11} onKeyDown={handleKeyDown} />
                                    {errors.accountCode && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.accountCode.message}</span>}
                                </div>

                                <div className="col-lg-12">
                                    <label className="form-label">
                                        Seller
                                    </label>
                                    {/* <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        options={sellerList}
                                        {...register('sellerId')}
                                        value={sellerList?.find((option: any) => option?.value == seller)}
                                        onChange={(e: any) => setValue('sellerId', e.value)}
                                        className='isActive'
                                    /> */}
                                    {/* <AsyncPagination
                                        register={register}
                                        name="sellerId"
                                        setValue={setValue}
                                        APIEndpoint={GETSELLER}
                                        label="sellerId-firstName lastName"
                                        value="sellerId"
                                    />
                                    {errors.sellerId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.sellerId.message}</span>} */}
                                    <div className="multiselect-count">
                                        <CustomCheckbox options={sellerList}
                                            onChange={handleChange} defaultSelected={seller} />
                                        {errors.sellerId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.sellerId.message}</span>}
                                    </div>
                                </div>

                                {contactInfoCheckBocx?.map((data: any) => {
                                    return (
                                        <>
                                            <div className="col-lg-4 col-md-6">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={data.id}
                                                    {...register(data.key)}

                                                />
                                                <label className="form-label mx-2" htmlFor={data.id}>{data.name}</label>
                                                {errors.taxExempt && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxExempt.message}</span>}
                                            </div>
                                        </>
                                    )
                                })}
                                <div className="col-12">
                                    <div className="row g-3 mb-4">
                                        {mode === 'edit' && (
                                            <>
                                                <div className="col-lg-4">
                                                    <label>Status</label>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckDefault"
                                                            {...register('isActive')}
                                                        // checked={suspend ? false : true}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Suspend</label>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckDefault2"
                                                            {...register('suspendStatus')}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault2">Suspend</label>
                                                    </div>
                                                </div>
                                                {check() &&
                                                    <div className="col-lg-4">
                                                        <label> {suspendStatus ? 'Suspend Reason' : 'Active Reason'}{suspendStatus && <sup className="text-danger">*</sup>}</label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder={'Enter Reason'}
                                                            {...register('suspendedReason')}
                                                            cols="5"
                                                            rows="5"
                                                        ></textarea>
                                                        {errors.suspendedReason && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.suspendedReason.message}</span>}
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="container-fluid for-fix-bottom-space">
                                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS} >{CommonMessages.cancelButton}</Link>
                                        <ModifiedBy data={initialFormValue} />
                                        <LastModified data={initialFormValue} />
                                        <span className="btn btn-primary ms-2"
                                            onClick={onSubmit}
                                        > {ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default ContactInfo;
