import axios from "axios";
import 'jspdf-autotable';
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BALANCEDUEBYBIDDERREPORT, FICTITIOUSBIDDER, GETALLSELLERSUMMARY, GETALLTRANSPORTERS, GETAUTOTITLEREPORT, GETCUSTOMERSUSPENDREPORT, GETINVENTORYBYSELLER, GETINVOICESUMMARYREPORT, GETLISTINGNOTIFICATIONS, GETSALESREPCOMMISSIONSREPORT, GETSELLERADJUSTSUMMARY, GETSELLERCOMMISSIONSUMMARY, GETSELLERSUMMARYCOMMISSIONREPORT, GETTAXEXEMPTSUMMARY, GETTAXSUMMARYBYJURISDICTION, INVENTORYTITLEREPORT, OVERALLSELLERSUMMARY, SELLERBYSALESREPREPORT, SELLERPAYMENTSUMMARY } from "../../common/apiConstatnts";
import { errorMessages } from "../../common/messageConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { generateAllSellerSummaryCsv, generateAllSellerSummaryPdf, generateAutoTitleCsv, generateAutoTitlePdf, generateBalanceDueByBidderCsv, generateBalanceDueByBidderPdf, generateFictitiousBidderCsv, generateFictitiousBidderPdf, generateInventoryBySellerCsv, generateInventoryBySellerPdf, generateInventoryImportLogsCsv, generateInventoryImportLogsPdf, generateInvoiceSummaryCsv, generateInvoiceSummaryPdf, generateSalesRepCommReportCsv, generateSalesRepCommReportPdf, generateSellerAdjustmentCsv, generateSellerAdjustmentPdf, generateSellerCommissionCsv, generateSellerCommissionPdf, generateSellerCommissionSummaryCsv, generateSellerCommissionSummaryPdf, generateSellerOverallSummaryPdf, generateSellerPaymentSummaryCsv, generateSellerPaymentSummaryPdf, generateSummaryOfSellerBySalesRepCsv, generateSummaryOfSellerBySalesRepPdf, generateSuspendCustomerHistoryCsv, generateSuspendCustomerHistoryPdf, generateTaxExemptSummaryCsv, generateTaxExemptSummaryPdf, generateTaxSummaryByJurisdictionCsv, generateTaxSummaryByJurisdictionPdf, generateTitledReportCsv, generateTitledReportPdf, generateTransportationMessageCsv, generateTransportationMessagePdf } from './ExportReportsPdfCsvFile';

const SellerReports = ({ data, searchText, setSearchText }: any) => {
    const dispatch = useDispatch()
    const query = window.location.href;
    const vars = query.split("/");
    const [docsData, setdocsData] = useState<any>(data?.child);
    const [OriginalReportData, setOriginalReportData] = useState<any>(data?.child);
    const [filterReports, setFilterReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState<boolean>(false)
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const PER_PAGE = 10;
    const pageCount = Math.ceil(docsData?.length / PER_PAGE);
    const basePath = `${process.env.REACT_APP_API_URL}\\`

    const paginateData = (data: any) => {
        setdocsData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterReports(firstSet)
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = OriginalReportData?.filter((x: any) =>
            x?.label?.toLocaleLowerCase()?.includes(keyword)
        )
        paginateData(filterData)
        setCurrentPage(0)
    }

    const setDefaultData = () => {
        if (data?.child?.length) {
            setdocsData(data?.child)
            paginateData(data?.child)
        }
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = docsData?.slice(offset, offset + PER_PAGE)
        setFilterReports(nextSet)
    }, [currentPage])

    useEffect(() => {
        paginateData(docsData)
    }, [docsData])

    useEffect(() => {
        setDefaultData()
        setOriginalReportData(data?.child)
    }, [data?.child])

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    const getAllSellerSummaryData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETALLSELLERSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumResp = response.data.data;
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateAllSellerSummaryPdf(sellerSumResp);
                    } else {
                        generateAllSellerSummaryCsv(sellerSumResp);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false)
                handleError(error);
            })
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSellerCommissionData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETSELLERSUMMARYCOMMISSIONREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerCommData = response?.data?.data
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateSellerCommissionPdf(sellerCommData);
                    } else {
                        generateSellerCommissionCsv(sellerCommData)
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false)
                handleError(error);
            })
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSellerCommissionSummaryData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETSELLERCOMMISSIONSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumComData = response.data.data
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateSellerCommissionSummaryPdf(sellerSumComData);
                    } else {
                        generateSellerCommissionSummaryCsv(sellerSumComData);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false)
                handleError(error);
            })
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSellerAdjustData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETSELLERADJUSTSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const sellerAdjustData = response.data.data;
                    if (pdf) {
                        generateSellerAdjustmentPdf(sellerAdjustData);
                    } else {
                        generateSellerAdjustmentCsv(sellerAdjustData);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSellerOverAllData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${OVERALLSELLERSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const sellerOverallData = response.data.data;
                    if (pdf) {
                        generateSellerOverallSummaryPdf(sellerOverallData);
                    } else {

                    }
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSellerPaymentData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${SELLERPAYMENTSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const paymentData = response.data.data;
                    if (pdf) {
                        generateSellerPaymentSummaryPdf(paymentData);
                    } else {
                        generateSellerPaymentSummaryCsv(paymentData);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getAutoTitleReportData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETAUTOTITLEREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const autoTitleReportData = response.data.data;
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateAutoTitlePdf(autoTitleReportData);
                    } else {
                        generateAutoTitleCsv(autoTitleReportData);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSalesRepCommReport = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETSALESREPCOMMISSIONSREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumResp = response?.data?.data
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateSalesRepCommReportPdf(sellerSumResp);
                    } else {
                        generateSalesRepCommReportCsv(sellerSumResp);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSummaryOfSellerBySalesRepData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${SELLERBYSALESREPREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const SellerBySalesRepData = response.data.data;
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateSummaryOfSellerBySalesRepPdf(SellerBySalesRepData?.lstSummaryForSeller, SellerBySalesRepData);
                    } else {
                        generateSummaryOfSellerBySalesRepCsv(SellerBySalesRepData?.lstSummaryForSeller, SellerBySalesRepData);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getInventoryImportLogs = (auctionId?: any, pdf?: any, csv?: any) => {
        setLoading(true)
        axios.get(GETLISTINGNOTIFICATIONS).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                setLoading(false)
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            let data = response.data.data;
            data = data.map((i: any, index: any) => {
                return {
                    ...i,
                    srNo: index + 1,
                    responseFilePath: i.responseFilePath ? `${basePath}${i.responseFilePath.split('AdminAPI\\')[1]}` : '',
                    sourceFilePath: i.sourceFilePath ? `${basePath}${i.sourceFilePath.split('AdminAPI\\')[1]}` : '',
                }
            });
            if (pdf) {
                generateInventoryImportLogsPdf(data);
            } else {
                generateInventoryImportLogsCsv(data);
            }
        })
    }

    const getInventoryBySellerData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETINVENTORYBYSELLER}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerData = response?.data?.data;
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateInventoryBySellerPdf(sellerData, getGlobalAuction);
                    } else {
                        generateInventoryBySellerCsv(sellerData, getGlobalAuction);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getTaxSummaryByJurisdictionData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETTAXSUMMARYBYJURISDICTION}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const taxData = response?.data?.result?.data;
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (pdf) {
                        generateTaxSummaryByJurisdictionPdf(taxData, getGlobalAuction);
                    } else {
                        generateTaxSummaryByJurisdictionCsv(taxData, getGlobalAuction);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getSuspendCustomerHistoryData = (auctionId?: any, pdf?: any, csv?: any) => {
        setLoading(true)
        axios.get(GETCUSTOMERSUSPENDREPORT).then((response: any) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const suspData = response?.data?.data;
                if (pdf) {
                    generateSuspendCustomerHistoryPdf(suspData);
                } else {
                    generateSuspendCustomerHistoryCsv(suspData);
                }
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const getTitledReportData = (auctionId?: any, pdf?: any, csv?: any) => {
        setLoading(true);
        if (auctionId) {
            axios.get(`${INVENTORYTITLEREPORT}?AuctionId=${auctionId}`)
                .then((response) => {
                    setLoading(false);
                    if (response?.data?.data?.success === "Fail") {
                        setLoading(false);
                        toast.error(response?.data?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    } else {
                        const data: any = response.data.data;
                        if (pdf) {
                            generateTitledReportPdf(data, getGlobalAuction);
                        } else {
                            generateTitledReportCsv(data, getGlobalAuction);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getTransportationMessageData = (auctionId?: any, pdf?: any, csv?: any) => {
        setLoading(true);
        axios.get(GETALLTRANSPORTERS, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const TransporterData = response?.data?.data
                if (pdf) {
                    generateTransportationMessagePdf(TransporterData);
                } else {
                    generateTransportationMessageCsv(TransporterData);
                }
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const getFictitiousBidderData = (auctionId?: any, pdf?: any, csv?: any) => {
        setLoading(true);
        axios.post(FICTITIOUSBIDDER).then((response: any) => {
            if (response?.data?.success === "Fail") {
                setLoading(false)
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            } else {
                setLoading(false)
                const resData = response?.data?.data
                if (pdf) {
                    generateFictitiousBidderPdf(resData);
                } else {
                    generateFictitiousBidderCsv(resData);
                }
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const getBalanceDueByBidderReportData = (auctionId?: any, pdf?: any, csv?: any) => {
        if (auctionId) {
            setLoading(true)
            axios.get(`${BALANCEDUEBYBIDDERREPORT}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    toast.error(response?.data?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const balanceDueData = response.data.data;

                    const totals = {
                        totalInvoice: balanceDueData.reduce((acc: any, item: any) => acc + parseFloat(item.invoiceTotal), 0),
                        totalPaid: balanceDueData.reduce((acc: any, item: any) => acc + parseFloat(item.paidToDate), 0),
                        totalBalanceDue: balanceDueData.reduce((acc: any, item: any) => acc + parseFloat(item.balDue), 0),
                    };

                    const totalLotLength = balanceDueData?.reduce((acc: any, data: any) => {
                        return acc + (data?.lstLotInvoice?.length || 0);
                    }, 0);

                    if (pdf) {
                        generateBalanceDueByBidderPdf(balanceDueData, totals, totalLotLength);
                    } else {
                        generateBalanceDueByBidderCsv(balanceDueData, totals, totalLotLength);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }
    const getTaxExemptSummaryReportData = (auctionId?: any, pdf?: any, csv?: any) =>{
        if (auctionId) {
            setLoading(true)
            axios.get(`${GETTAXEXEMPTSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    toast.error(response?.data?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const data =  response?.data?.data;
                    if (pdf) {
                        generateTaxExemptSummaryPdf(data, getGlobalAuction);
                    } else {
                        generateTaxExemptSummaryCsv(data, getGlobalAuction);
                    }
                    setLoading(false)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const getInvoiceSummaryReportData = (auctionId?: any, pdf?: any, csv?: any) =>{
        if (auctionId) {
            axios.get(`${GETINVOICESUMMARYREPORT}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    setLoading(false)
                    const resData = response?.data?.data
                    if (pdf) {
                        generateInvoiceSummaryPdf(resData,getGlobalAuction);
                    } else {
                        generateInvoiceSummaryCsv(resData,getGlobalAuction);
                    }
                }
            }).catch((error: any) => {
                setLoading(false);
                handleError(error);
            });
        }
        else {
            setLoading(false)
            toast.warning(errorMessages.auctionSelectMsg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const handleDownloadPdf = (doc: any, pdf: boolean, csv: boolean) => {
        if (doc.label == 'All Seller Summary Report') {
            getAllSellerSummaryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Commission Report') {
            getSellerCommissionData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Commission Summary Report') {
            getSellerCommissionSummaryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Adjustment Report') {
            getSellerAdjustData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Summary Report') {
            getSellerOverAllData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Payment Summary Report') {
            getSellerPaymentData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Summary of Sellers by Sales Rep') {
            getSummaryOfSellerBySalesRepData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Sales Rep Commission Report') {
            getSalesRepCommReport(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Inventory Import Logs') {
            getInventoryImportLogs(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Inventory By Seller') {
            getInventoryBySellerData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Tax Summary By Jurisdiction') {
            getTaxSummaryByJurisdictionData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Suspend Buyers History') {
            getSuspendCustomerHistoryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Titled Report') {
            getTitledReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Transportation Message') {
            getTransportationMessageData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Fictitious Bidder Report') {
            getFictitiousBidderData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Auto Title Summary Report') {
            getAutoTitleReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Balance Due By Bidder') {
            getBalanceDueByBidderReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Invoice Summary'){
            getInvoiceSummaryReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Tax Exempt Summary'){
            getTaxExemptSummaryReportData(getGlobalAuction?.value, pdf, csv)
        }
    }

    const handleDownloadCsv = (doc: any, pdf: boolean, csv: boolean) => {
        if (doc.label == 'All Seller Summary Report') {
            getAllSellerSummaryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Commission Report') {
            getSellerCommissionData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Commission Summary Report') {
            getSellerCommissionSummaryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Adjustment Report') {
            getSellerAdjustData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Summary Report') {
            getSellerOverAllData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Seller Payment Summary Report') {
            getSellerPaymentData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Summary of Sellers by Sales Rep') {
            getSummaryOfSellerBySalesRepData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Sales Rep Commission Report') {
            getSalesRepCommReport(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Inventory Import Logs') {
            getInventoryImportLogs(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Inventory By Seller') {
            getInventoryBySellerData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Tax Summary By Jurisdiction') {
            getTaxSummaryByJurisdictionData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Suspend Buyers History') {
            getSuspendCustomerHistoryData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Titled Report') {
            getTitledReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Transportation Message') {
            getTransportationMessageData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Fictitious Bidder Report') {
            getFictitiousBidderData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Auto Title Summary Report') {
            getAutoTitleReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Balance Due By Bidder') {
            getBalanceDueByBidderReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Invoice Summary'){
            getInvoiceSummaryReportData(getGlobalAuction?.value, pdf, csv)
        }
        if (doc.label == 'Tax Exempt Summary'){
            getTaxExemptSummaryReportData(getGlobalAuction?.value, pdf, csv)
        }
    }

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10">
                        <div className="mt-2 mb-4 border-300">
                            <div className="row">
                                <div className="col-12 mt-3 d-flex flex-column justify-content-start">
                                    <h4>{data?.label}</h4>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <div className="search-box mt-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                                    <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" maxLength={200} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-1">
                                            {/* <button type="button" className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => setDocUpload(true)}>
                                                <i className="fas fa-plus"></i>
                                                <span>Add Document</span>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Action</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Report Name</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant w-60" scope="col" data-sort="Ctrl">
                                                                <div className='d-flex'>
                                                                    <span>Report Description</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list scrollbar" id="table-latest-review-body">
                                                        {filterReports?.length > 0 ?
                                                            filterReports?.map((doc: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant="none">
                                                                                    <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                    <Link
                                                                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                                                                        to={`${doc?.url}`}
                                                                                    >
                                                                                        View Report
                                                                                        <i className="fas fa-eye"></i>
                                                                                    </Link>
                                                                                    {doc.label !== 'Seller Summary Report' && doc.label !== 'Seller Pickup Summary Report' && doc.label !== 'Lienholder Report' && (
                                                                                        <Dropdown.Item
                                                                                            as='button'
                                                                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                                                                            onClick={() => handleDownloadPdf(doc, true, false)}
                                                                                        >
                                                                                            Export as PDF <i className="fa-solid fa-file-pdf"></i>
                                                                                        </Dropdown.Item>
                                                                                    )}
                                                                                    {doc.label !== 'Seller Summary Report' && doc.label !== 'Seller Pickup Summary Report' && doc.label !== 'Lienholder Report' && (
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleDownloadCsv(doc, false, true)}>Export as CSV <i className="fa-solid fa-file-excel">‌</i></Dropdown.Item>
                                                                                    )}
                                                                                    {/** Disable for now */}
                                                                                    <Dropdown.Item as='button' disabled className="dropdown-item d-flex align-items-center justify-content-between">Email as PDF <i className="fa-solid fa-envelope"></i></Dropdown.Item>
                                                                                    <Dropdown.Item as='button' disabled className="dropdown-item d-flex align-items-center justify-content-between">Email as CSV <i className="fa-solid fa-envelope"></i></Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td className="p-2">
                                                                            <Link
                                                                                target="_blank"
                                                                                to={`${doc?.url}`}>
                                                                                {doc?.label ?? "-"}
                                                                            </Link>
                                                                            {/* {`${doc?.label}`} */}
                                                                        </td>
                                                                        <td className="align-middle  p-2 word-break-word">
                                                                            {doc?.description}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={5}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterReports?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerReports