import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk"
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SalesRepReducer } from "./reducers/SalesRepReducer";
import { CommissionReducer } from "./reducers/CommissionReducer";
import { SellerReducer } from "./reducers/SellerReducer";
import { composeWithDevTools } from '@redux-devtools/extension';
import { AuctionReducer } from "./reducers/AuctionReducer";
import { CustomerReducer } from "./reducers/CustomerReducer";
import { SalesRecordReducer } from "./reducers/SalesRecordReducer";
import { DashboardReducer } from "./reducers/DashboardReducer";
import { InventoryReducer } from "./reducers/InventoryReducer";
import { CommonReducer } from "./reducers/CommonReducer";
import { InvoiceReducer } from "./reducers/InvoiceReducer";
import { ThemeReducer } from "./reducers/ThemeReducer";

const rootReducer = combineReducers({
    global: CommonReducer,
    salesRep: SalesRepReducer,
    commission: CommissionReducer,
    auction: AuctionReducer,
    seller: SellerReducer,
    customer: CustomerReducer,
    salesRecord: SalesRecordReducer,
    dashboard: DashboardReducer,
    inventory: InventoryReducer,
    inovice: InvoiceReducer,
    theme: ThemeReducer
});

const persistConfig = {
    key: 'root',
    storage: storage
};

const pReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk]

const store = createStore(pReducer, composeWithDevTools(
    applyMiddleware(...middleware)
));

const persistor = persistStore(store);

export { persistor, store }