export const ADMINCONFIGSLUG = 'admin-settings'
export const SALESREPSLUG = 'sales-reps'
export const SELLERSLUG = 'sellers'
export const CUSTOMERSLUG = 'buyers'
export const AUCTIONSLUG = 'auctions'
export const SALESSLUG = 'sales-records'
export const INVENTORYSLUG = 'inventory'
export const INVOICESLUG = 'invoices'
export const REPORTSLUG = 'reports'
export const AUCTIONBIDSLUG = 'bid-management'

export const SIGNIN = '/signin'
export const SIGNUP = '/signup'
export const SIGNOUT = '/signout'
export const FORGOTPASSWORD = '/forgot-password'
export const RESETPASSWORD = '/reset-password'

export const DASHBOARD = '/dashboard'

export const INVENTORY = '/inventory';
export const RENUMBERLOTS ='/inventory/renumber-lots'
export const ADDNEWINVENTORY = `${INVENTORY}/add-new-inventory`;
export const NEWINVENTORY = `${INVENTORY}/create-inventory`;
export const UPDATEINVENTORY = `${INVENTORY}/update-inventory`;
export const CLONEINVENTORY = `${INVENTORY}/clone-inventory`;

export const ADDNEWLOT =  `${INVENTORY}/add-new-lot`;

export const ADDRESS = '/address';

export const INVOICE = `/${INVOICESLUG}`
export const CREATEINVOICE = `${INVOICE}/create-invoice`;
export const UPDATEINVOICE = `${INVOICE}/update-invoice`;

export const USER = `/${ADMINCONFIGSLUG}/users`
export const ROLE = `/${ADMINCONFIGSLUG}/roles`
export const BUYERSELLER = `/${ADMINCONFIGSLUG}/buyer-seller`
export const COMPANYPROFILE = `/${ADMINCONFIGSLUG}/profile`
export const CATEGORIES = `/${ADMINCONFIGSLUG}/categories`
export const EMAILSET = `/${ADMINCONFIGSLUG}/email-settings`
export const AUTOEMAIL = `/${ADMINCONFIGSLUG}/automate-email`
export const EMAILHEADERFOOTER = `/${ADMINCONFIGSLUG}/email-header-footer`
export const SELLERREPORT = `/${ADMINCONFIGSLUG}/seller-report`
export const AUCTIONDEFAULT = `/${ADMINCONFIGSLUG}/auction-default`
export const EINVOICESETTING = `/${ADMINCONFIGSLUG}/einvoice-settings`;
export const IMAGECONFIG = `/${ADMINCONFIGSLUG}/image-config`;
export const BIDDERCONFIG = `/${ADMINCONFIGSLUG}/admin-config`;
export const VALUEDEFAULT = `/${ADMINCONFIGSLUG}/value-default`
export const BIDINCREMENT = `/${ADMINCONFIGSLUG}/bid-increment`;
export const LOTTEMPLATES = `/${ADMINCONFIGSLUG}/lot-template`
export const CREATELOTTEMPLATES = `/${ADMINCONFIGSLUG}/create-lot-template`
export const EDITLOTTEMPLATES = `/${ADMINCONFIGSLUG}/edit-lot-template`
export const CLONELOTTEMPLATES = `/${ADMINCONFIGSLUG}/clone-lot-template`
export const LOTTEMPLATESPREVIEWPAGE = `/${ADMINCONFIGSLUG}/lot-template-preview-page`
export const AUCTIONTEMPLATES = `/${ADMINCONFIGSLUG}/auction-templates`
export const EMAILTEMPLATES = `/${ADMINCONFIGSLUG}/email-templates`
export const SMSTEMPLATES = `/${ADMINCONFIGSLUG}/sms-templates`
export const PARTNERTEMPLATES = `/${ADMINCONFIGSLUG}/partners-templates`
export const SELLERTEMPLATES = `/${ADMINCONFIGSLUG}/seller-templates`
export const SALESREPTEMPLATES = `/${ADMINCONFIGSLUG}/salesRep-templates`
export const INVENTORYTEMPLATES = `/${ADMINCONFIGSLUG}/inventory-templates`
export const SALESRECORDTEMPLATES = `/${ADMINCONFIGSLUG}/salesRecord-templates`
export const CUSTOMERTEMPLATES = `/${ADMINCONFIGSLUG}/customer-templates`
export const CONTACTUSTEMPLATES = `/${ADMINCONFIGSLUG}/contact-us-templates`
export const APPRAISALTEMPLATES = `/${ADMINCONFIGSLUG}/appraisal-templates`
export const AUTHORIZATIONTEMPLATES = `/${ADMINCONFIGSLUG}/authorization-templates`
export const REPORTTEMPLATES = `/${ADMINCONFIGSLUG}/report-templates`
export const INVOICETEMPLATES = `/${ADMINCONFIGSLUG}/invoice-templates`
export const ADDAUCTIONTEMPLATES = `/${ADMINCONFIGSLUG}/add-auction-templates`
export const EDITAUCTIONTEMPLATES = `/${ADMINCONFIGSLUG}/edit-auction-templates`
export const ADDEMAILTEMPLATES = `/${ADMINCONFIGSLUG}/add-email-templates`;
export const EDITEMAILTEMPLATES = `/${ADMINCONFIGSLUG}/edit-email-templates`;
export const ADDREPORTTEMPLATES = `/${ADMINCONFIGSLUG}/add-report-templates`;
export const EDITREPORTTEMPLATES = `/${ADMINCONFIGSLUG}/edit-report-templates`;
export const ADDINVOICETEMPLATES = `/${ADMINCONFIGSLUG}/add-invoice-templates`;
export const EDITINVOICETEMPLATES = `/${ADMINCONFIGSLUG}/edit-invoice-templates`;

export const ADDSELLERTEMPLATES = `/${ADMINCONFIGSLUG}/add-seller-templates`;
export const EDITSELLERTEMPLATES = `/${ADMINCONFIGSLUG}/edit-seller-templates`;

export const ADDSALESREPTEMPLATES = `/${ADMINCONFIGSLUG}/add-sales-rep-templates`;
export const EDITSALESREPTEMPLATES = `/${ADMINCONFIGSLUG}/edit-sales-rep-templates`;

export const ADDINVENTORYTEMPLATES = `/${ADMINCONFIGSLUG}/add-inventory-templates`;
export const EDITINVENTORYTEMPLATES = `/${ADMINCONFIGSLUG}/edit-inventory-templates`;

export const ADDSALESRECORDTEMPLATES = `/${ADMINCONFIGSLUG}/add-sales-record-templates`;
export const EDITSALESRECORDTEMPLATES = `/${ADMINCONFIGSLUG}/edit-sales-record-templates`;

export const ADDCUSTOMERTEMPLATES = `/${ADMINCONFIGSLUG}/add-customer-templates`;
export const EDITCUSTOMERTEMPLATES = `/${ADMINCONFIGSLUG}/edit-customer-templates`;

export const ADDCONTACTUSTEMPLATES = `/${ADMINCONFIGSLUG}/add-contact-us-templates`;
export const EDITCONTACTUSTEMPLATES = `/${ADMINCONFIGSLUG}/edit-contact-us-templates`;

export const ADDAPPRAISALTEMPLATES = `/${ADMINCONFIGSLUG}/add-appraisal-templates`;
export const EDITAPPRAISALTEMPLATES = `/${ADMINCONFIGSLUG}/edit-appraisal-templates`;

export const ADDPARTNERSTEMPLATES = `/${ADMINCONFIGSLUG}/add-partners-templates`;
export const EDITPARTNERSTEMPLATES = `/${ADMINCONFIGSLUG}/edit-partners-templates`;

export const ADDSMSTEMPLATES = `/${ADMINCONFIGSLUG}/add-sms-templates`;
export const EDITSMSTEMPLATES = `/${ADMINCONFIGSLUG}/edit-sms-templates`;

export const ADDAUTHORIZATIONTEMPLATES = `/${ADMINCONFIGSLUG}/add-authorization-templates`;
export const EDITAUTHORIZATIONTEMPLATES = `/${ADMINCONFIGSLUG}/edit-authorization-templates`;

export const BUYERSPREMIUMBYSCALES = `/${ADMINCONFIGSLUG}/buyer-premium-by-scales`
export const BPSALESTAX = `/${ADMINCONFIGSLUG}/bp-sales-tax`
export const COMMISSION = `/${ADMINCONFIGSLUG}/commissions`
export const AVALARA = `/${ADMINCONFIGSLUG}/tax-currency-avalara`;
export const TAXRATE = `/${ADMINCONFIGSLUG}/tax-currency-tax-rate`;
export const SALESREPRESENTATIVE = `/${SALESREPSLUG}`;
export const SALESREPRESENTATIVEMOREINFO = `/${SALESREPSLUG}/contact-info`;
export const TIMEZONEIDENTIFIER = `/${ADMINCONFIGSLUG}/time-zone-identifier`

export const SELLER = `/${SELLERSLUG}`
export const SELLERMOREINFO = `/${SELLERSLUG}/seller-info`

export const CUSTOMERS = `/${CUSTOMERSLUG}`
export const CUSTOMERSMOREINFO = `/${CUSTOMERSLUG}/contact-info`

export const AUCTION = `/${AUCTIONSLUG}`
export const NEWAUCTION = `/${AUCTIONSLUG}/new-auction`
export const EDITAUCTION = `/${AUCTIONSLUG}/edit-auction`
export const CLONEAUCTION = `/${AUCTIONSLUG}/clone-auction`
export const AUCTIONINFO = `/${AUCTIONSLUG}/auction-info`

export const SALESRECORDS = `/${SALESSLUG}`
export const SALESMOREINFO = `/${SALESSLUG}/more-info`

export const TRANSPORT = `/${REPORTSLUG}/transport`
export const IMPORTLOGS = `/${REPORTSLUG}/import-lot-logs`
export const SUSPENDREPORT = `/${REPORTSLUG}/suspend-report`
export const LIENHOLDERREPORT = `/${REPORTSLUG}/lienholder-report`
export const TITLEREPORT = `/${REPORTSLUG}/title-report`

export const AUCTIONBID = `/${AUCTIONBIDSLUG}`
export const NEWAUCTIONBID = `/${AUCTIONBIDSLUG}/new-auction-bid`
export const REPORTS = `/${REPORTSLUG}`
export const SELLERSUMMARYREPORT = `/${REPORTSLUG}/seller-report`
export const SELLERAUCTIONSUMMARYREPORT = `/${REPORTSLUG}/seller-auction-report`
export const SELLERCOMMISSIONREPORT = `/${REPORTSLUG}/seller-commission-report`
export const COMMISSIONSUMMARYREPORT = `/${REPORTSLUG}/commission-summary-report`
export const SALESREPCOMMISSIONREPORT = `/${REPORTSLUG}/salesRep-commission-report`
export const SALLERADJUSTREPORT = `/${REPORTSLUG}/seller-adjustments-report`
export const ALLSELLERSUMMARY = `/${REPORTSLUG}/all-seller-summary-report`
export const SELLERCOMMISSIONSUMMARY = `/${REPORTSLUG}/seller-commission-summary-report`
export const SELLEROVERALLSUMMARY = `/${REPORTSLUG}/seller-summary-report`
export const SELLERPAYMENTSUMMARY = `/${REPORTSLUG}/seller-payment-summary-report`
export const SELLERPICKUPSUMMARY = `/${REPORTSLUG}/seller-pickup-summary-report`
export const FICTITIOUSBIDDEREPORT = `/${REPORTSLUG}/fictitious-bidder-report`
export const INVENTORYBYSELLER = `/${REPORTSLUG}/inventory-by-seller-report`
export const AUTOTITLEREOPRT = `/${REPORTSLUG}/auto-title-summary-report`
export const TAXSUMMARYBYJURISDICTION = `/${REPORTSLUG}/tax-summary-jurisdiction`
export const INVOICESUMMARY = `/${REPORTSLUG}/invoice-summary`
export const TAXEXEMPTSUMMARY = `/${REPORTSLUG}/tax-exempt-summary`
export const BALANCEDUEBYBIDDER = `/${REPORTSLUG}/balance-due-by-bidder`
export const SUMMARYOFSELLERBYSALESREP = `/${REPORTSLUG}/summary-of-seller-by-sales-rep`

export const TERMS = `/terms`
export const POLICIES = `/privacy-policy`
