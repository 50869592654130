import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  GETLOTREALESTATEEXTERIORBYID,
  UPDATELOTREALESTATEEXTERIOR
} from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import * as routeConstant from "../../../../common/routeConstants";
import { setStep } from "../../../../store/actions/AuctionAction";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../../store/actions/Inventory";
import { trimObjectValues } from "../../../../Utils/Utils";
import { ExteriorChecks, LOTEXTERIOR } from "../../InventoryConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const Exterior = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnTxt] = useState<any>("")
  const lotId = useSelector((state: any) => state.inventory?.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const mode = useSelector((state: any) => state.inventory.mode)
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)

  const initialData = {
    lotId: mode === "clone" ? cloneLotId : lotId,
    realEstateType: 2,
    alarmSystem: false,
    balconey: false,
    barWet: false,
    barbeque: false,
    barn: false,
    boatFacilities: false,
    carpot: false,
    carriageHouse: false,
    cornerLot: false,
    culDeSac: false,
    curbs: false,
    deck: false,
    dock: false,
    dogRun: false,
    doorman: false,
    enclosedPorch: false,
    grrenhouseRoom: false,
    handcapEquipped: false,
    horseFacilities: false,
    hotTub: false,
    lakePrivileges: false,
    metalfence: false,
    mountainView: false,
    notPrecTested: false,
    openPorch: false,
    outBuildings: false,
    partiallyFenced: false,
    patio: false,
    poolInGround: false,
    privancyFence: false,
    satelliteDish: false,
    sauna: false,
    screenedporch: false,
    sidewalk: false,
    siteSecurity: false,
    stateRoof: false,
    storageShed: false,
    stormDoor: false,
    stromWindows: false,
    tennisCourt: false,
    thermalWin: false,
    tileRoof: false,
    undergroundSprinkler: false,
    woodFloors: false,
    woodedLot: false,
    workshop: false,
    yard: false,
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const schema: any = yup.object().shape({});

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  const onSubmit = () => {
    setLoading(true);
    const dataOriginal = getValues();
    const exteriorData = trimObjectValues(dataOriginal);

    axios.post(UPDATELOTREALESTATEEXTERIOR, {
      ...exteriorData,
      lotId: mode === "clone" ? cloneLotId : lotId,
      realEstateType: 2
    }, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        toast.success(InventoryMessages.exteriorUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, LOTEXTERIOR]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, LOTEXTERIOR]))
        }
        nextStep()
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  const prevStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "interior" }));
  };

  const nextStep = () => {
    dispatch(setStep('silent'));
  };

  useEffect(() => {
    setLoading(true);
    const id = mode === "clone" ? cloneLotId : lotId
    axios.get(`${GETLOTREALESTATEEXTERIORBYID}?lotId=${id}`, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        const data = response.data.data;
        reset(data)
      }
    }).catch((error) => {
      setLoading(false);
    });
  }, []);

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTEXTERIOR)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTEXTERIOR)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div>
        <div className="mt-3">
          <h4>Exterior Feature</h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-3 mb-3">
            {
              ExteriorChecks.map((eData: any, eIndex: any) => {
                return (
                  <div className="col-lg-4" key={eIndex}>
                    <label className="form-label">{eData.label}</label>
                    <div className="form-check">
                      <input
                        className="form-check-input form-check-states"
                        type="checkbox"
                        {...register(eData.key)}
                        id={eData.key}
                      />
                      <label htmlFor={eData.key} className="form-check-label text-900 fw-400">Yes</label>
                    </div>
                  </div>
                )
              })
            }
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
              <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
              <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
              <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
              <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Exterior;
