import Sidebar from "../Sidebar/Sidebar"
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader"
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants'
import { Link } from "react-router-dom";
import editIcon from "../../assets/edit_icon_tab.svg"
import deleteIcon from "../../assets/delet_icon_tab.svg"
import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import axios from "axios"
import { CREATEBPBYSCALES, GETBPBYSCALES } from "../../common/apiConstatnts"
import { v4 as uuidv4 } from 'uuid';
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify"
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { wordCounter } from "../../common/constants"
import { AlPHANUMERIC, DECIMAL5, DECIMAL5_MESSAGE, DECIMAL63, DECIMAL63_MESSAGE, DECIMAL83, DECIMAL83_MESSAGE, PERCENTAGE, PERCENTAGE_MESSAGE } from "../../common/regexConstants";
import { BuyerPremiumScaleMessage, CommonMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, formatCurrency, formatNumber, handleKeyDown, onlyAllowAlfanumeric, trimObjectValues } from "../../Utils/Utils";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const BuyersPremiumByScales = () => {
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Buyers Premium By Scales', url: '' }
    ]
    const radioSelection = [
        { label: '%', value: '1', selected: true, key: 'isPercentage', listingLabel: 'Percentage', clearLabel: 'fixedAmount' },
        { label: 'Fixed', value: '2', selected: false, key: 'isFixed', listingLabel: 'Fixed', clearLabel: 'percentage' },
        { label: 'Both', value: '3', selected: false, key: 'isBoth', listingLabel: 'Both', clearLabel: '' }
    ]
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState('add');
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const superAdminRole = decodedToken.isDefaultRole;
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleModalRange, setToggleValueRange] = useState<boolean>(false)
    const [radioValue, setRadioValue] = useState<any>("1")
    const [activeScaleId, setActiveScaleId] = useState<any>();
    const [lastUptoValue, setLastUptoValue] = useState<any>()
    const [scaleIncrementData, setScaleIncrementData] = useState([]);
    const [scaleRangeData, setScaleRangeData] = useState([]);
    const [activeUniqueId, setActiveUniqueId] = useState();
    const [deleteDisable, setDeleteDisable] = useState<any>('')
    const [activeScaleIndex, setActiveScaleIndex] = useState(0);
    const [isLast, setIsLast] = useState<boolean>(false)
    const [isScaleValid, setIsScaleValid] = useState<boolean>(false);
    const [labelMessage, setLabelMessage] = useState("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [isLimitReach, setIsLimitReach] = useState<boolean>(false)
    const [uptoError, setUptoError] = useState<boolean>(false)
    const maxWordCount = 50
    const maxTaxWord = 20
    const [remainingCount, setRemainingCount] = useState(maxTaxWord);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount)
    const [taxCode, setTaxCode] = useState<string>('S0000001')


    const initialbpBySalesData = {
        bpBySalesScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        label: "",
        taxRegionName: "",
        isActive: true,
        mode: mode,
    }

    const initialRangeData = {
        bpBySalesRangeId: 0,
        bpBySalesScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        from: "",
        upTo: "",
        percentage: "",
        fixedAmount: "",
        // levelMin: "",
        // levelCap: "",
        fixedPercentageType: "1",
        isActive: true,
        mode: "add"
    }
    const [initialbpBySalesFormState, setInitialbpBySalesFormState] = useState<any>(initialbpBySalesData)
    const [initialRangeFormState, setInitialRangeFormState] = useState<any>(initialRangeData)
    const decimalValForNxt = 0.01


    const schema: any = yup.object().shape({
        label: yup.string().required("Label is required").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(AlPHANUMERIC, 'please enter valid Label').max(50, 'please enter upto 50 characters'),
        taxRegionName: yup.string().required("Tax Region Name is required").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(AlPHANUMERIC, 'please enter valid Tax Region Name').max(50, 'please enter upto 50 characters')
    });

    const rangeSchema: any = yup.object().shape({
        from: yup.string().required('From is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL83, DECIMAL83_MESSAGE).test('is-less-from', `Please enter valid number greater than ${parseFloat(lastUptoValue) - decimalValForNxt}`, val => parseFloat(val) > parseFloat(`${lastUptoValue - decimalValForNxt}`)),
        upTo: yup.string().required("Upto is required.").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL83, DECIMAL83_MESSAGE).test("is-less-from", 'Please enter valid number greater than From value', val => parseFloat(val) > from),
        fixedPercentageType: yup.string().required("Fixed/Percentage/Type is required.").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        percentage: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "1" || value === "3",
            then: () => yup.string().matches(PERCENTAGE, PERCENTAGE_MESSAGE).required('Percentage is required').test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
        }),
        fixedAmount: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "2" || value === "3",
            then: () => yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL5, DECIMAL5_MESSAGE).required('Fix amount is required')
        }),
        // levelMin: yup.string().required('Level Min is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),
        // levelCap: yup.string().required('Level Cap is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialbpBySalesFormState, mode: 'onChange' })

    const {
        register: rangeRegister,
        handleSubmit: rangeHandleSubmit,
        reset: rangeReset,
        setValue: rangeSetValue,
        clearErrors: rangeClearErrors,
        watch: rangeWatch,
        getValues,
        formState: { errors: rangeErrors, isValid: rangeIsValid },
    } = useForm({ resolver: yupResolver(rangeSchema), defaultValues: initialRangeFormState, mode: 'onChange' })

    const from = parseFloat(rangeWatch('from'));
    const upto = parseFloat(rangeWatch('upTo'));
    const fixedAmount = rangeWatch('fixedAmount')
    useEffect(() => {
        const upto = getValues('upto');
        const errorKeys = Object.keys(rangeErrors);
        if (from >= upto && upto !== '' && !errorKeys.includes('upto')) {
            setUptoError(true);
            return;
        }
        setUptoError(false);
        rangeClearErrors('from');
    }, [from])

    useEffect(() => {
        setUptoError(false);
    }, [upto])



    const handleSorting = (type: string, fieldName: string) => {
        let sortedData: any = [...scaleIncrementData];

        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        setScaleIncrementData(sortedData)
        const firstIndexRange: any = sortedData[0]?.lstBPBySalesRange ?? [];
        const firstIndexScale: any = sortedData[0] ?? [];
        setActiveScaleId(firstIndexScale?.bpBySalesScaleId);
        setActiveUniqueId(firstIndexScale?.uniqueId);
        setStartingFrom(firstIndexRange);
        setScaleRangeData(firstIndexRange);
        // paginateData(sortedData);
        // setCurrentPage(1);
    };
    const handleClose = () => {
        setLabelMessage("");
        reset(initialbpBySalesFormState);
        setToggleValue(!toggleModal);
    }

    const handleCloseRangeModel = () => {
        rangeReset(initialRangeFormState);
        setToggleValueRange(!toggleModalRange);
    }

    const getAllScaleData = () => {
        setLoading(true);
        axios.get(GETBPBYSCALES, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const scaleData = response.data.data;
                const updatedScaleData = scaleData.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstBPBySalesRange: data.lstBPBySalesRange.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })

                const firstIndexRange = updatedScaleData[0]?.lstBPBySalesRange ?? []
                setActiveScaleId(updatedScaleData[0]?.bpBySalesScaleId)
                setStartingFrom(firstIndexRange)
                setScaleIncrementData(updatedScaleData);
                setScaleRangeData(firstIndexRange)
                setActiveUniqueId(updatedScaleData[0]?.uniqueId)
                setTaxCode(updatedScaleData[0]?.taxCode || 'S0000001')
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }

    const setStartingFrom = (rangeData: any) => {
        const getActiveRange = rangeData.filter((data: any) => data.mode !== 'delete')
        const lastIndexRange = getActiveRange?.length > 0 ? getActiveRange[getActiveRange?.length - 1] : 0
        const setUpto = lastIndexRange.upTo ? lastIndexRange.upTo + decimalValForNxt : 0
        setLastUptoValue(setUpto)
        rangeSetValue('from', parseFloat(parseFloat(setUpto)?.toFixed(2)))

    }

    const handleFormRadio = (value: string, clearLabel: string) => {
        if (clearLabel !== '') {
            rangeSetValue(`${clearLabel}`, '')
            rangeClearErrors(`${clearLabel}`)
        }
        setRadioValue(value)
        rangeSetValue('fixedPercentageType', value)
    }
    const handleNewScale = () => {
        if (superAdminRole === "True") {
            setMode('add');
            setToggleValue(!toggleModal);
        }
        else {
            setLoading(false);
            toast.error("Only super admin can add new scale", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }
    const handleNewRange = () => {
        if (superAdminRole === "True") {
            setRadioValue('1');
            setToggleValueRange(!toggleModalRange);
            rangeSetValue('from', parseFloat(lastUptoValue?.toFixed(2)) ?? 0);
        }
        else {
            setLoading(false);
            toast.error("Only super admin can add new range", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }
    const onSubmitScaleData = (data: any) => {
        const trimData = trimObjectValues(data);
        // Find if the label already exists in the data
        const isLabelExist = scaleIncrementData.some((d: any) => d.label === trimData.label && d.uniqueId !== trimData.uniqueId);
        if (isLabelExist) {
            setLabelMessage("Label Already Exists");
            return; // Exit the function if label exists
        }
        setLabelMessage(""); // Clear any previous label messages
        let updatedData: any;
        if (trimData.bpBySalesScaleId === 0 && trimData.uniqueId === undefined) {
            // Adding new entry
            const newScale = { ...trimData, lstBPBySalesRange: [], mode: "add", uniqueId: uuidv4() };
            updatedData = [...scaleIncrementData, newScale];
        } else {
            // Updating existing entry
            updatedData = scaleIncrementData.map((sData: any) => {
                if (sData.uniqueId === trimData.uniqueId) {
                    return { ...sData, ...trimData };
                }
                return sData;
            });
        }
        // Ensure there's at least one default entry
        const hasDefault = updatedData.some((data: any) => data.isDefault === true);
        if (!hasDefault && updatedData.length > 0) {
            updatedData[0].isDefault = true;
        }
        // Update state
        setScaleIncrementData(updatedData);
        setActiveUniqueId(updatedData[0].uniqueId);
        setActiveScaleId(updatedData[0].bpBySalesScaleId);
        setToggleValue(!toggleModal);
        reset(initialbpBySalesData);
    };
    

    const getScaleId = (scalerId: number, uniqueId: string, index: number) => {
        const found: any = scaleIncrementData.find((obj: any) => {
            return obj.uniqueId === uniqueId;
        });
        const range = found.lstBPBySalesRange
        setActiveScaleId(scalerId);
        setStartingFrom(range)
        setActiveScaleIndex(index)
        setActiveUniqueId(found.uniqueId)
        setScaleRangeData(range.filter((data: any) => data.mode !== 'delete'))
    }

    const handleEditModal = (e: any, uniqueId: number) => {
        if (superAdminRole === "True") {
            e.stopPropagation()
            setMode('edit')
            setToggleValue(!toggleModal)
            const getData = scaleIncrementData.find((data: any) => data.uniqueId === uniqueId)
            reset(getData)
        }
        else {
            setLoading(false);
            toast.error("Only super admin can edit scales.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const handleScaleDelete = (e: any, uniqueId: number) => {
        if (superAdminRole === "True") {
            e.stopPropagation()
            const checkDefault: any = scaleIncrementData.find((data: any) => data.uniqueId === uniqueId)
            if (checkDefault.isDefault) {
                setDeleteDisable(uniqueId)
                toast.error(errorMessages.defaultScale, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
                setTimeout(() => {
                    setDeleteDisable('')
                }, 3000)
                return
            }

            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this scale!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const updateScale: any = scaleIncrementData.map((data: any) => {
                        if (uniqueId === data.uniqueId) {
                            return { ...data, mode: 'delete' }
                        }
                        return data
                    })
                    const getActive = updateScale.filter((data: any) => data.mode !== 'delete')
                    const firstIndexRange = getActive[0]?.lstBPBySalesRange ?? []
                    setScaleIncrementData(updateScale)
                    setActiveScaleIndex(0)
                    setActiveScaleId(getActive[0]?.bpBySalesScaleId)
                    setStartingFrom(firstIndexRange)
                    setScaleRangeData(firstIndexRange)
                    setActiveUniqueId(getActive[0]?.uniqueId)
                }
            })
            return
        }
        else {
            setLoading(false);
            toast.error("Only super admin can delete scales.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const handleDefault = (e: any, scaleUnique: any) => {
        e.stopPropagation();
        const chkEvent = e.target;
        const isChecked = chkEvent.checked;
        if (isChecked) {
            chkEvent.checked = false
            const elem: any = document.getElementsByClassName('form-check-input')
            const oldData: any = scaleIncrementData.find((data: any) => data.isDefault === true)
            const newData: any = scaleIncrementData.find((data: any) => data.uniqueId === scaleUnique)
            Swal.fire({
                title: 'Are you sure?',
                text: `Scale "${oldData.label}" is already Set as Default. Are you sure you want to Set Scale "${newData.label}" as Default!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (let i = 0; i < elem.length; i++) {
                        elem[i].checked = chkEvent === elem[i]
                    }
                    const updateDefault: any = scaleIncrementData.map((data: any) => {
                        if (data.uniqueId === scaleUnique) {
                            return { ...data, isDefault: chkEvent.checked }
                        }
                        return { ...data, isDefault: false }
                    })
                    setScaleIncrementData(updateDefault)
                }
            })
        } else {
            chkEvent.checked = true
        }
    }

    const onSubmitRange = (data: any) => {
        if (!(data.from >= lastUptoValue)) {
            rangeSetValue('from', lastUptoValue)
            toast.error(`From value should be greater than last upTo value ${lastUptoValue - decimalValForNxt}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return
        }
        const updatedScaleData: any = scaleIncrementData.map((bData: any) => {
            if (bData.uniqueId === activeUniqueId) {
                const updateMode = bData.lstBPBySalesRange.map((item: any) => (
                    {
                        ...item,
                        mode: (item.bpBySalesRangeId === 0 && item.mode !== 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                    }

                ))
                const newScaleData = { ...bData, lstBPBySalesRange: updateMode }

                return {
                    ...newScaleData,
                    lstBPBySalesRange: [...newScaleData.lstBPBySalesRange,
                    {
                        ...data,
                        percentage: (data.percentage) ? data.percentage : 0,
                        fixedAmount: (data.fixedAmount) ? data.fixedAmount : 0,
                        bpBySalesScaleId: activeScaleId,
                        mode: "add",
                        uniqueId: uuidv4()
                    }
                    ]
                }
            }
            const updateMode = bData.lstBPBySalesRange.map((item: any) => (
                {
                    ...item,
                    mode: (item.bpBySalesRangeId === 0 && item.mode !== 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                }
            ))

            return { ...bData, lstBPBySalesRange: updateMode }
        })


        const updateRange: any = updatedScaleData.find((data: any) => data.uniqueId === activeUniqueId)?.lstBPBySalesRange;
        setScaleRangeData(updateRange);
        setScaleIncrementData(updatedScaleData);
        setLastUptoValue(parseFloat(data.upTo) + decimalValForNxt);
        rangeReset(initialRangeFormState);
        setToggleValueRange(!toggleModalRange);
    }

    const handleRangeDelete = (scaleUniqueId: any, rangeUniqueId: string) => {
        if (superAdminRole === "True") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this range!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const modifyScale: any = scaleIncrementData.map((data: any) => {
                        const modifyRange = data.lstBPBySalesRange.map((rg: any) => {
                            if (rg.uniqueId === rangeUniqueId) {
                                return { ...rg, mode: "delete" }
                            }
                            return rg
                        })
                        return { ...data, lstBPBySalesRange: modifyRange }
                    })
                    const getData = modifyScale.find((data: any) => data.uniqueId === scaleUniqueId)?.lstBPBySalesRange
                    let getLastUpto = getData.filter((data: any) => data.mode !== "delete")
                    // setLastUptoValue(getLastUpto[getLastUpto?.length - 1]?.upTo + decimalValForNxt)
                    setLastUptoValue(parseFloat(getLastUpto[getLastUpto?.length - 1]?.upTo) + decimalValForNxt)
                    setScaleRangeData(getLastUpto)
                    setScaleIncrementData(modifyScale)
                }
            })
            return
        } else {
            setLoading(false);
            toast.error("Only super admin can delete range.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    useEffect(() => {
        setLabelMessage("");
        getAllScaleData()
    }, [])

    useEffect(() => {
        const checkLast = scaleRangeData.length ? scaleRangeData.filter((data: any) => data.mode !== 'delete') : []
        const getBool = checkLast?.length === 1 ? true : false
        setIsLast(getBool)
    }, [scaleRangeData])

    useEffect(() => {
        const filterDummy = scaleIncrementData.filter((data: any) => data.mode !== 'delete')
        const checkRange = filterDummy.every((data: any) => data.lstBPBySalesRange.length > 0)
        setIsScaleValid(checkRange)
    }, [scaleIncrementData])

    const handleSave = () => {
        setLoading(true);
        if (superAdminRole === "True") {
            const filterDummy = scaleIncrementData.map((data: any) => {
                if (data.bpBySalesScaleId === 0 && data.mode === 'delete') {
                    return {}
                }
                return {
                    ...data,
                    lstBPBySalesRange: data?.lstBPBySalesRange?.filter((i: any) => !(i?.bpBySalesRangeId == 0 && i?.mode === 'delete')),
                    taxCode: taxCode
                }
            }).filter((bData: any) => Object.keys(bData).length > 0);
            axios.post(CREATEBPBYSCALES, filterDummy, config)
                .then((response) => {
                    setLoading(false);
                    getAllScaleData();
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(BuyerPremiumScaleMessage.bpScaleCreated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
        }
        else {
            setLoading(false);
            toast.error("Only super admin has permission to perform this operation on the page.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    useEffect(() => {
        const lastUpto: any = parseFloat(lastUptoValue) - decimalValForNxt
        const CommissionScaleLimit: any = process.env.REACT_APP_COMMISSION_FROM_LIMIT
        if (lastUpto >= parseFloat(CommissionScaleLimit)) {
            setIsLimitReach(true)
            return
        }
        setIsLimitReach(false)
    }, [lastUptoValue])

    const handleLabelChange  =(e:any)=>{
        setRemainingCount(wordCounter(e.target.value, maxTaxWord));
        setLabelMessage("")
    }

    return (
        <>
              <CommonHelmet title={"BP By Scales - "} name={"buyersPremiumByScales"} content={"BP By Scales"} />

            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            {/* Add Scale */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmitScaleData)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{mode === 'add' ? 'Add New Scale' : 'Update Scale'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Label <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Label"
                                    maxLength={maxTaxWord}
                                    {...register("label", { onChange: (e) => {handleLabelChange(e)}})}
                                />
                                {remainingCount != maxTaxWord && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                    {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                </div>}
                                {errors.label && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.label.message}</span>}
                                {labelMessage && (
                                    <div className='text-danger'>
                                        <span className={
                                            successful
                                                ? "alert alert-success"
                                                : "text-danger"
                                        }
                                            style={{ fontSize: "12px" }}>
                                            {labelMessage}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Tax Region Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Tax Region Name"
                                    maxLength={maxWordCount}
                                    {...register("taxRegionName", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount)) })}

                                />
                                {remainingCount2 != maxWordCount &&
                                    <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                                    </div>}
                                {errors.taxRegionName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRegionName.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit">{mode === 'add' ? (CommonMessages.saveButton) : (CommonMessages.updateButton)}</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Add Range */}
            {/* {limitFull ?<>
                <Modal show={toggleModalRange} size='xl' centered>
                    <h5>You can't add new range now</h5>
            </Modal>
            </>:<> */}
            <Modal show={toggleModalRange} size={isLimitReach ? 'sm' : 'xl'} centered>
                <form onSubmit={rangeHandleSubmit(onSubmitRange)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Add New Range</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseRangeModel} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {!isLimitReach ?
                            <div className="row g-3 mb-3">
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">From ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={13}
                                        {...rangeRegister('from')}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.from && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.from.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Upto ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="Upto"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={13}
                                        {...rangeRegister('upTo')}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.upTo && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.upTo.message}</span>}
                                    {uptoError && <span className='text-danger' style={{ fontSize: "12px" }}>Upto must greater than From</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label" htmlFor="text">Percentage/Fixed/Both<sup className="text-danger">*</sup></label>
                                    <div className="code-to-copy">
                                        {radioSelection.map((selection: any, index: any) => {
                                            return (
                                                <div className="form-check form-check-inline mt-2" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        name="commType"
                                                        id={`commType_${index}`}
                                                        type="radio"
                                                        defaultChecked={selection.selected}
                                                        onChange={() => handleFormRadio(selection.value, selection.clearLabel)}
                                                    />
                                                    <label className="form-label" htmlFor={`commType_${index}`}>{selection.label}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Percentage (%)<sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        {...rangeRegister('percentage')}
                                        maxLength={5}
                                        disabled={radioValue === "2"}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.percentage && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.percentage.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Fixed Amount ($)<sup className="text-danger">*</sup></label>
                                    <CurrencyInput
                                        type="text"
                                        placeholder="Tax Amount"
                                        {...rangeRegister('fixedAmount')}
                                        prefix="$"
                                        onKeyDown={handleKeyDown}
                                        value={fixedAmount}
                                        onValueChange={(value: any) => rangeSetValue("fixedAmount", value)}
                                        className="form-control form-icon-input"
                                        disabled={radioValue === "1"}

                                    />
                                    {rangeErrors.fixedAmount && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.fixedAmount.message}</span>}
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Level Min <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={20}
                                        {...rangeRegister('levelMin')}
                                    />
                                    {rangeErrors.levelMin && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.levelMin.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Level Cap <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={20}
                                        {...rangeRegister('levelCap')}
                                    />
                                    {rangeErrors.levelCap && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.levelCap.message}</span>}
                                </div> */}

                            </div>
                            :
                            <span><span className="text-danger">**</span>You have exceeded the range limit. So you can't add new range.</span>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={!isLimitReach ? "btn btn-outline-danger" : "btn btn-primary"} type="button" data-bs-dismiss="modal" onClick={handleCloseRangeModel}>{!isLimitReach ? CommonMessages.cancelButton : "Ok"}</button>
                        {!isLimitReach && <button className="btn btn-primary" type="submit">{CommonMessages.createButton}</button>}
                    </Modal.Footer>
                </form>
            </Modal>
            {/* </>
            } */}

            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Buyers Premium By Scales'>
                </InnerHeader>
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row top_border">
                        <div className="col-lg-12">
                            <div className="tab-content mt-3" id="myTabContent">
                                <div className="border-300">
                                    <div className="mb-3 mt-3">
                                        <div className="row">
                                            <div className="col-12 flex-grow-1">
                                                <p className="mb-0 fs-1">Scale</p>
                                                <p className="mb-0 fs-0">Scale Entry Notes :</p>
                                                <p className="mb-0 fs--1">1) Do Not enter a value for the highest level to amount.</p>
                                                <p className="mb-0 fs--1">2) The From value should be exactly the same as the previous To value, e.g. 0 to 100, 100 to 500, 500 to 1000. Do not enter 99.99 or 99 or similar this is handled internally.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-4 mb-4">
                            <div className="d-flex align-items-center justify-content-end mb-3">
                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                    onClick={handleNewScale}
                                >
                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Scale </span>
                                </button>
                            </div>
                            <div id="tableExample4">
                                <div className="table-responsive tbody_scroll_cus">
                                    <table className="table border-radius border td_bottom_border_0 fs--1 table-sm mb-0">
                                        <thead>
                                            <tr className="bg-gradiant">
                                                <th className="border-bottom ps-1 pe-1 pt-2 pb-2 pe-2">
                                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "30%" }}>
                                                    <span>Label</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'label')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'label')}></button>
                                                    </div>
                                                </div>
                                                <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "50%" }}>
                                                    <span>Tax Region Name</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'taxRegionName')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'taxRegionName')}></button>
                                                    </div>
                                                </div>
                                                <div className="align-middle payment text-end" style={{ width: "20%" }}>Action</div>
                                            </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list scrollbar">
                                            {scaleIncrementData.length > 0 && scaleIncrementData.filter((data: any) => data.mode !== 'delete')?.length > 0 ?
                                                scaleIncrementData?.map((scaleData: any, index: any) => {
                                                    return (
                                                        <>
                                                            {(scaleData.mode !== 'delete') && <tr onClick={() => { getScaleId(scaleData.bpBySalesScaleId, scaleData.uniqueId, index) }} key={index}>
                                                                <td className="p-2">
                                                                    <div className={`d-flex flex-wrap border_ac ${activeUniqueId === scaleData.uniqueId ? 'active' : ''}`}>
                                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between position-relative">
                                                                            <div style={{ width: "20%" }} className="d-flex pe-3 flex-wrap align-items-center" >
                                                                                <span className="ellipsis">{scaleData.label}</span>
                                                                            </div>
                                                                            <div style={{ width: "65%" }} className="me-auto align-middle tax pe-3">
                                                                                <span className="ellipsis">{scaleData.taxRegionName}</span>
                                                                            </div>
                                                                            <div style={{ width: "15%" }} className="align-middle payment text-end">
                                                                                <div className="action_btn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="action_icons text-decoration-none btn p-0"
                                                                                        onClick={(e) => handleEditModal(e, scaleData.uniqueId)}
                                                                                    > <img src={editIcon} /> </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="action_icons text-decoration-none ms-2 btn p-0"
                                                                                        disabled={deleteDisable == scaleData.uniqueId}
                                                                                        onClick={(e) => handleScaleDelete(e, scaleData.uniqueId)}
                                                                                    >
                                                                                        <img src={deleteIcon} />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-check w-100 ms-auto mt-1">
                                                                            <input className="form-check-input" id={`Default${index}`} type="checkbox" onChange={(e) => handleDefault(e, scaleData.uniqueId)} checked={scaleData.isDefault === true} />
                                                                            <label className={`form-check-label ${(scaleData.isDefault) ? 'text-danger' : ''}`} htmlFor={`Default${index}`}>{scaleData.isDefault ? 'Default' : 'Set as Default'}</label>
                                                                        </div>
                                                                    </div>
                                                                </td >
                                                            </tr>}
                                                        </>
                                                    )
                                                })
                                                :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={6}>No Scale found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-7 mt-3">
                                    <label className="form-label fw-bold" htmlFor="text">BP Tax Code</label>
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        placeholder="BP Tax Code"
                                        value={taxCode}
                                        onChange={(e) => setTaxCode(e.target.value)}
                                        onKeyDown={onlyAllowAlfanumeric}
                                        maxLength={20}
                                    />
                                    {errors.objectType && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.objectType.message}</span>}
                                </div>
                                <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Only allow alphanumeric value (Ex: AB.123).</i></span>
                            </div>

                        </div>
                        <div className="col-lg-8 mb-4">
                            <div className="d-flex align-items-center justify-content-end mb-3">
                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                    onClick={handleNewRange}
                                >
                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Range</span>
                                </button>
                            </div>
                            <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                                <div className="table-responsive scrollbar border-top border-bottom">
                                    <table className="table table-bordered mb-0 fs--1">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >From ($)</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >Upto ($)</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >Percentage (%)</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >Fixed Amount ($)</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >%/Fixed/Both</th>
                                                {/* <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >Level Min</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2" >Level Cap</th> */}
                                                <th className="bg-gradiant border-bottom text-end align-middle pe-0 border-top p-1" style={{ width: "30px" }} scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="list">
                                            {scaleRangeData.length > 0 ?
                                                scaleRangeData?.map((rangeData: any, index: any) => {
                                                    return (
                                                        <>
                                                            {rangeData.mode !== "delete" && <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 name">{formatNumber(rangeData.from)}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{formatNumber(rangeData.upTo)}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{rangeData.percentage}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{formatCurrency(rangeData.fixedAmount)}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{radioSelection.find((data) => data.value == rangeData.fixedPercentageType)?.listingLabel}</td>
                                                                {/* <td className="align-middle ps-3 pe-3 pt-2 pb-2 Percentage">{rangeData.levelMin}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 Percentage">{rangeData.levelCap}</td> */}
                                                                <td className="align-middle p-1 text-center delete" style={{ width: "30px" }}>
                                                                    {(scaleRangeData?.length === index + 1 && !isLast) &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn p-0 d-flex align-items-center justify-content-center m-auto"
                                                                            onClick={() => handleRangeDelete(activeUniqueId, rangeData.uniqueId)}
                                                                        ><img src={deleteIcon} height={12} /></button>
                                                                    }
                                                                </td>
                                                            </tr >}
                                                        </>
                                                    )
                                                })
                                                :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={8}>No Range found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                <Link
                                    to={routeConstant.DASHBOARD}
                                    className="btn btn-outline-danger me-auto"
                                    type="button"
                                    data-wizard-prev-btn="data-wizard-prev-btn"
                                >
                                    {CommonMessages.cancelButton}
                                </Link>
                                <button className="btn btn-primary" type="button" onClick={handleSave}>{CommonMessages.saveButton}</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default BuyersPremiumByScales;