import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import ReactPaginate from "react-paginate";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants'

import axios from "axios";
import { ADVANCEFILTERSALESREPRESENTATIVE, DELETESALESREPRESENTATIVE, GETALLAUCTIONLIST, GETCOMMONVALUE, GETSALESREPID, GETSALESREPRESENTATIVE } from "../../common/apiConstatnts";
import { useForm } from "react-hook-form";
import { Dropdown } from "react-bootstrap";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { activationStatus } from "../../common/constants";
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
import { encryptId } from "../../Encryption/Encryption";
import { formatPhoneNumber } from "../../common/constants";
import Helper from "../../common/helpers";
import { useDispatch, useSelector } from "react-redux";
import * as action from '../../common/types'
import { ActiveStep, SalesRepContact } from "../../store/actions/SalesRepAction";
import { COMPANYNAMEREGEX, EMAIL, EMAIL_MESSAGE, FIRSTANDLASTNAMEREGEX } from "../../common/regexConstants";
import { SalesRepMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, trimObjectValues, useDebounce } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Sales Representatives', url: routeConstant.SALESREPRESENTATIVE }
]
const SalesRepresentative = () => {
    const initialData = {
        saleRepId: 0,
        companyId: localStorage.getItem('companyId'),
        initialsId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        phone: "",
        mobile: "",
        fax: "",
        notes: "",
        email: ""
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const searchData = useSelector((state: any) => state.dashboard.searchData);
    const getPermission: any = Helper.decodePermissions()?.find((data: any) => data.moduleName === 'Sales Reps')
    const [permission] = useState<any>(getPermission);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterSalesRep, setFilterSalesRep] = useState([]);
    const [salesRep, setSalesRep] = useState<[]>([]);
    const [originalSalesRep, setOriginalSalesRep] = useState<[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [mode, setMode] = useState<string>('create');
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [initialFormValue, setFormValue] = useState<any>(initialData);
    const [initial, setInitial] = useState<[]>([]);
    // const [searchText, setSearchText] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [auctionList, setAuctionList] = useState<any>([]);
    const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<any>({});
    const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE)
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        isActive: -1,
        auctionId: hammerAuctionId ? hammerAuctionId : ''
    });
    useEffect(() => {
        if (hammerAuctionId) {
            setAlreadyFetchedDataPageNumber({})
            const resetFilter = {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                isActive: -1,
                auctionId: hammerAuctionId ? hammerAuctionId : ''
            }
            setFilterData(resetFilter);
            setShowFilter(true);
            clearSearchBar()
            currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)
        }
        else {
            clearSearchBar()
            clearAdvanceFilterData();
            setShowFilter(false);
        }

    }, [hammerAuctionId]);

    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        email: ''
    });
    const PER_PAGE = 10;

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const schema: any = yup.object().shape({
        initialsId: yup.string().required("Please select inital."),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),
        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),
        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        email: yup.string()
            .required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        company: yup.string().required("Company name is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name'),
        mobile: yup
            .string()
            .trim()
            .transform((value, originalValue) => {
                return originalValue === '' ? undefined : value;
            })
            .required('Phone number is required.')
            .test('valid-mobile', 'Please enter a valid phone number.', (value) => {
                if (!value) {
                    return true;
                }
                const mobileRegex = /^(\d{1,2})?\s?\d{10}$/;
                return mobileRegex.test(value);
            }),
        phone: yup.number().typeError('Phone must be a number.')
            .transform((value, originalValue) => {
                return (originalValue === '') ? undefined : value
            }).test('valid-mobile', 'Please enter a valid phone number.', (value: any) => {
                if (!value) {
                    return true;
                }
                const mobileRegex = /^(\d{1,2})?\s?\d{10}$/;
                return mobileRegex.test(value);
            }),
        fax: yup.string().nullable().matches(/^(\d{10})*$/, 'Please enter number with 10 digits'),
    });

    const {
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = filterSalesRep.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = filterSalesRep.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName,currentPage);
        // setCurrentPage(1);
    }

    let filterActivationStatus =
        activationStatus.map((data: any) => {
            return {
                label: data.label, value: data.value
            }
        })

    const changeHandler = (fieldName: string, value: any) => {
        const updateData = { ...filterData, [fieldName]: value }
        setFilterData(updateData);
        if (fieldName === "companyName" || fieldName === "firstName" || fieldName === "lastName") {
            const isValidTitle = !/^\s/.test(value);
            if (!isValidTitle) {
                setError({ ...error, [fieldName]: 'Leading spaces are not allowed' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
        if (fieldName === "email") {
            const isValidemail = /^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)*)?\s*$/.test(value);
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else if (!isValidemail) {
                setError({ ...error, [fieldName]: 'Please enter valid email only' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const handleAdvanceFilter = (filterData: any, pageNum?: any) => {
        setLoading(true);
        const data = trimObjectValues(filterData);
        axios.get(ADVANCEFILTERSALESREPRESENTATIVE, {
            ...config,
            params: {
                auctionId: data?.auctionId ?? "",
                firstName: data?.firstName ?? "",
                lastName: data?.lastName ?? "",
                email: data?.email ?? "",
                companyName: data?.companyName ?? "",
                isActive: data?.isActive?.value ?? -1,
                keyword: data?.keyword ?? '',
                pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
                pageSize: paginationSize
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterSalesRep([]);
            }
            else {
                paginateData(response?.data?.data?.data, pageNum ? pageNum : currentPage)
                setTotalRecords(response?.data?.data?.totalRecords)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
        return
    }


    const handleDeleteModal = (saleRepId: string, type: string) => {
        if (!permission?.isAllowDelete) {
            Helper.unAuthorized()
            return
        }
        else{

            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    axios.post(`${DELETESALESREPRESENTATIVE}?saleRepId=${saleRepId}`, {}, config).then((response) => {
                        setLoading(false);
                        if (response?.data?.success === "Fail") {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        }
                        else {
                            toast.success(SalesRepMessages.salesRepDeleted, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            getAllSalesRep();
                            setCurrentPage(1);
                            clearAdvanceFilterData(false, filterData);
                        }
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
                }
            })
        }
    }

    const clearSearchBar = () => {
        const searchbar: any = document.getElementById('searchBar')
        searchbar.value = ''
    }

    const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
        setAlreadyFetchedDataPageNumber({});
        currentPage !== 1 && setCurrentPage(1);
        setError({ firstName: '', lastName: '', companyName: '', email: '' });
        if (data == null) {
            setIsFiltered(false);
            const resetFilter = {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                isActive: -1,
                auctionId: isLocalClear ? '' : hammerAuctionId
            }
            setFilterData(resetFilter);
            handleAdvanceFilter(resetFilter, 1);
            clearSearchBar()

        }
        else if (!isLocalClear) {
            handleAdvanceFilter(data, 1)
            setFilterData(data)
        }
    }

    const handleEdit = (salesRepId: any) => {
        if (!permission?.isAllowEdit) {
            Helper.unAuthorized();
            return;
        }
        else{
            navigate(`${routeConstant.SALESREPRESENTATIVEMOREINFO}/${encryptId(salesRepId)}`);
        }
    }

    // new pagination related changes 

    const getAllSalesRep = (pageSize: any = 10, pageNum: any = 1) => {
        if (hammerAuctionId && hammerAuctionId?.length !== 0) {
            setShowFilter(true);
            handleAdvanceFilter(filterData);
            return;
        } 
        setLoading(true)
        axios.get(`${GETSALESREPRESENTATIVE}?pageNumber=${pageNum}&pageSize=${paginationSize}`, config).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterSalesRep([]);
                return;
            }
            else {
                const sellerdata = response?.data?.data?.data
                setTotalRecords(response.data.data.totalRecords)
                paginateData(sellerdata, pageNum);
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const handlePageClick = (selected: any) => {
        const newPageNumber = selected + 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(newPageNumber)
    }

    const updateData = (pageNumber: number, newData: any) => {
        setAlreadyFetchedDataPageNumber((prevState: any) => ({
            ...prevState,
            [pageNumber]: newData
        }));
    };

    const paginateData = (data: any, pageNum: any = 1) => {
        let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
        updateData(page, data);
        setFilterSalesRep(data);
    }

    const debouncedRequest = useDebounce(() => {
        handleAdvanceFilter(filterData)
    });

    // this useEffect called when click on pageNumber
    useEffect(() => {
        //when global search applied
        if (filterData?.keyword) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSalesRep(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            handleAdvanceFilter(filterData)
            return
        }

        //when advanced search filter applied
        else if (isFiltered || filterData?.auctionId) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSalesRep(alreadyFetchedDataPageNumber[currentPage])
                return
            }

            handleAdvanceFilter(filterData)
            return
        }

        //when clicked on already fetched page
        else {
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSalesRep(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            getAllSalesRep(paginationSize, currentPage)
        }

    }, [currentPage, filterData?.auctionId])



    const getAllAuctions = () => {
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            let auctionData = [];
            if (response?.data?.data) {
                auctionData = response?.data?.data?.map((lData: any) => {
                    return {
                        label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                        value: lData.auctionId,
                    };
                });
            }
            setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
            setAuctionList([]);
        });;
    };

    useEffect(() => {
        getAllAuctions();
        dispatch({ type: action.RESETRESP })
        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setInitial(response.data.data);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }, [])

    return (
        <>
            <CommonHelmet title={"Sales Rep - "} name={"salesRep"} content={"Sales Rep"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Sales Representatives'>
                    <div className="search-box mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input id='searchBar' className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search"
                                    onChange={(e: any) => {
                                        setFilterData({ ...filterData, keyword: e.target.value })
                                        if (e.target.value.length) {
                                            setAlreadyFetchedDataPageNumber({});
                                            setCurrentPage(1);
                                            debouncedRequest();
                                            return;
                                        } else {
                                            clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                                        }
                                    }}
                                />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>

                    </div>
                    <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                        <button className="btn btn-primary ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                            onClick={() => {
                                if (!permission?.isAllowAdd) {
                                    Helper.unAuthorized();
                                    return;
                                }
                                else{
                                    setMode('create')
                                    navigate(routeConstant.SALESREPRESENTATIVEMOREINFO)
                                }
                                // reset(initialData)
                                // setToggleValue(!toggleModal)
                            }}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Sales Rep </span>
                        </button>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-2">
                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter) })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form >
                                            <div className="row g-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label">Select Auction</label>
                                                    <Select
                                                        classNamePrefix="dpaSelect"
                                                        placeholder="Select Auction"
                                                        className="isActive swap-to-lot-menu clone-inventory"
                                                        options={auctionList}
                                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                                        value={auctionList.find((option: any) => option.value == filterData.auctionId)}
                                                        onChange={(e: any) => {
                                                            changeHandler("auctionId", e?.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">First Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="Firstname"
                                                        value={filterData.firstName}
                                                        onChange={((e) => changeHandler("firstName", e.target.value))}
                                                    />
                                                    {error.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.firstName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Last Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Lastname"
                                                        value={filterData.lastName}
                                                        onChange={((e) => changeHandler("lastName", e.target.value))}
                                                    />
                                                    {error.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.lastName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Email</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={filterData.email}
                                                        onChange={((e) => changeHandler("email", e.target.value))}
                                                    />
                                                    {error.email && <span className='text-danger' style={{ fontSize: "12px" }}>{error.email}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Company Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="companyName"
                                                        placeholder="Company Name"
                                                        value={filterData.companyName}
                                                        onChange={((e) => changeHandler("companyName", e.target.value))}
                                                    />
                                                    {error.companyName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.companyName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Activation Status</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select"
                                                        className='isActive'
                                                        name='isActive'
                                                        options={filterActivationStatus}
                                                        value={filterData.isActive}
                                                        onChange={(e) => changeHandler("isActive", e)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end mt-3 ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="submit"
                                                    onClick={() => {
                                                        clearAdvanceFilterData();
                                                    }}>
                                                    Clear
                                                </button>
                                                <button className="btn btn-primary mb-1" type="submit"
                                                    onClick={() => {
                                                        setAlreadyFetchedDataPageNumber({});
                                                        currentPage !== 1 && setCurrentPage(1);
                                                        setIsFiltered(true);
                                                        currentPage == 1 && handleAdvanceFilter(filterData, 1);
                                                    }}
                                                >Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-5 border-300">
                                                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                                    <div className="table-responsive mx-n1 px-1">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant" scope="col">Action</th>
                                                                    <th className="bg-gradiant" scope="col">Sales Rep Id</th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Sales Rep Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="lastname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Last Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'lastName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'lastName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="lastname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Email</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="company">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Company</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'company')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'company')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="phoneNo">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Phone No</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="Privillege">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Activation Status</span>
                                                                            <div className="sorting_group">
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterSalesRep?.length > 0 ? filterSalesRep?.map((salesRep: any, index: any) => {
                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                    <Dropdown.Toggle className="m-0 p-0" variant="none">
                                                                                        <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                        </button>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item
                                                                                            as='button'
                                                                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                                                                            onClick={() => handleEdit(salesRep.saleRepId)}
                                                                                        >
                                                                                            Edit <i className="fas fa-pen dropdown-icon"></i>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                            as='button'
                                                                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                                                                            onClick={() => handleDeleteModal(salesRep.saleRepId, 'delete')} >
                                                                                            Delete <i className="fas fa-trash dropdown-icon"></i>
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="align-middle  p-2" onClick={() => dispatch(ActiveStep("contact"))}>
                                                                                <Link className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                                    target="_blank"
                                                                                    to={`${routeConstant.SALESREPRESENTATIVEMOREINFO}/${encryptId(salesRep?.saleRepId)}`}
                                                                                >
                                                                                    {salesRep?.saleRepId}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="align-middle  p-2">{salesRep.firstName ? salesRep.firstName : "-"}</td>
                                                                            <td className="align-middle  p-2">{salesRep.lastName ? salesRep.lastName : "-"}</td>
                                                                            <td className="align-middle  p-2">{salesRep.email ? salesRep.email : "-"}</td>
                                                                            <td className="align-middle review p-2">{salesRep.company ? salesRep.company : "-"}</td>
                                                                            <td className="align-middle  p-2">{salesRep.mobile ? formatPhoneNumber(salesRep.mobile) : "-"}</td>
                                                                            <td className="align-middle text-start status p-2">
                                                                                {salesRep.isActive == true ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                                                    <span className="badge-label">Active</span>

                                                                                </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                                                    <span className="badge-label">Inactive</span>

                                                                                </span>}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={8}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterSalesRep.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={Math.ceil(totalRecords / paginationSize)}
                                            onPageChange={({ selected }) => handlePageClick(selected)}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}
export default SalesRepresentative;
