import * as action from '../../common/types'

export type Action = {
    type: string
    payload: string
}

export const setAuctionType = (data: any): Action => ({
    type: action.AUCTIONTYPE,
    payload: data
})

export const setCloneAuctionId = (data: any): Action => ({
    type: action.SETCLONEAUCTIONID,
    payload: data
})

export const setAuctionCodeAndTitle = (data: any): Action => ({
    type: action.SETAUCTIONCODEANDTITLE,
    payload: data
})

export const setCloneTab = (data: any): Action => ({
    type: action.SETCLONETABS,
    payload: data
})

export const setCreateTab = (data: any): Action => ({
    type: action.SETCREATETABS,
    payload: data
})

export const setStep = (data: any): Action => ({
    type: action.ACTIVESTEP,
    payload: data
})

export const setAuctionInfoActive = (data: any): Action => ({
    type: action.AUCTIONNFOACTIVE,
    payload: data
})

export const setAuctionId = (data: any): Action => ({
    type: action.AUCTIONID,
    payload: data
})

export const setMode = (data: any): Action => ({
    type: action.SETMODE,
    payload: data
})

export const setCheckList = (data: any): Action => ({
    type: action.CHECKLIST,
    payload: data
})

export const setSellerReport = (data: any): Action => ({
    type: action.SELLERREPORT,
    payload: data
})

export const setAuctionInfo = (data: any): Action => ({
    type: action.AUCTIONINFO,
    payload: data
})

export const setValuesData = (data: any): Action => ({
    type: action.VALUEDATA,
    payload: data
})

export const setInvoiceData = (data: any): Action => ({
    type: action.AUCTIONINVOICEDATA,
    payload: data
})

export const setAuctionInvoiceActive = (data: any): Action => ({
    type: action.AUCTIONINVOICE,
    payload: data
})

export const setAuctionValueActive = (data: any): Action => ({
    type: action.AUCTIONVALUE,
    payload: data
})

export const setAuctionDetailActive = (data: any): Action => ({
    type: action.AUCTIONDETAILS,
    payload: data
})

export const setEinvoiceData = (data: any): Action => ({
    type: action.EINVOICEDATA,
    payload: data
})

export const addBidderRange = (data: any): Action => ({
    type: action.ADDBIDDERRANGE,
    payload: data,
});

export const deleteBidderRange = (uniqueId: any): Action => ({
    type: action.DELETEBIDDERRANGE,
    payload: uniqueId,
});

export const setAuctionInvoiceSettingActive = (data: any): Action => ({
    type: action.AUCTIONINVOICESETTING,
    payload: data
});

// Email reports
export const setAuctionEmailReportsActive = (data: any): Action => ({
    type: action.AUCTIONEMAILREPORTS,
    payload: data
})

export const setInvoiceTemplateActive = (data: any): Action => ({
    type: action.INVOICETEMPLATE,
    payload: data
});

export const setSellerTemplateActive = (data: any): Action => ({
    type: action.SELLERTEMPLATE,
    payload: data
})