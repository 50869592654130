import parse from 'html-react-parser';
import jsPDF from 'jspdf';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';
import waterMark from "../assets/bill_for_sale.png";
import dpa_logo from '../assets/img/dpa-logo.jpg';
import { formatPhoneNumber } from '../common/constants';

export const trimObjectValues = (data: Record<string, any>): Record<string, any> => {
  const trimmedData: Record<string, any> = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (Array.isArray(data[key])) {
        // If the value is an array, recursively call trimObjectValues for each element
        trimmedData[key] = data[key].map((item: any) => {
          if (typeof item === 'object' && item !== null) {
            if (moment.isMoment(item)) {
              return item; // Return Moment objects as is
            } else {
              return trimObjectValues(item);
            }
          } else if (typeof item === 'string') {
            return item.trim();
          } else {
            return item; // Non-string values, such as objects, are left unchanged
          }
        });
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        // If the value is an object, recursively call trimObjectValues
        if (moment.isMoment(data[key])) {
          trimmedData[key] = data[key]; // Return Moment objects as is
        } else {
          trimmedData[key] = trimObjectValues(data[key]);
        }
      } else if (typeof data[key] === 'string') {
        // If the value is a string, trim it
        trimmedData[key] = data[key].trim();
      } else {
        // For other types, just assign the value directly
        trimmedData[key] = data[key];
      }
    }
  }
  return trimmedData;
};

export const getTimeZoneIdentifier = (timezone: string): string => {
  switch (timezone) {
    case "UTC":
      return "Atlantic/St_Helena";
    case "CDT":
      return "America/Chicago";
    case "GMT":
      return "Atlantic/St_Helena";
    case "IST":
      return "Asia/Kolkata";
    default:
      return "America/Chicago";
  }
}

export const ERROR_MESSAGES = {
  NO_LEADING_SPACES: "Leading spaces are not allowed",
  BLANK_SPACES_NOT_ALLOWED: "Blank spaces are not allowed"
};

export const handleKeyDown = (event: any) => {
  if (event.key === ' ') {
    event.preventDefault();
  }
};

export const onlyAllowAlfanumeric = (event: any) => {
  if ('@!$%^&*~`{}[]()#\'\/"?<>;:+-, '.includes(event.key)) {
    event.preventDefault();
  }
};

export const removeHtmlTags = (html: string): string => html?.replace(/<[^>]*>/g, '').trim();

// Format currency
export const formatCurrency = (amount: number = 0): string => {
  let formatted_amount: any = parseFloat(`${amount}`) < 0 ? Math.abs(amount) : amount
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // Change this to your desired currency code
    minimumFractionDigits: 2, // Set minimumFractionDigits to 2 to hide decimals
  });
  formatted_amount = parseFloat(`${amount}`) < 0 ? `(${formatter.format(formatted_amount)})` : formatter.format(formatted_amount);
  return formatted_amount;
};
export const formatNumber = (value: number): string => {
  var nf = new Intl.NumberFormat();
  return nf.format(value);
};

//this custom hook is used for API Debounce functionality
export const useDebounce = (callback: any) => {
  const ref: any = useRef<() => void | null>(null);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};

//this is use to convert seconds in minutes and seconds format
export const convertSecondsToMinutesAndSeconds = (seconds: any, setValue: any, minName: any, secName: any) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  setValue(minName, minutes)
  setValue(secName, remainingSeconds)
  return null;
}

export const parseHtml = (html: string) => {
  return parse(html ? html : "")
}

export const generatePDF = (dataInvoice: any, footerData: any, currentUTCDate: any, paymentTypeData: any, mode: any): any => {
  const doc: any = new jsPDF("p", "pt");
  let margin = 20;
  const tableWidth = doc.internal.pageSize.width * 0.3;
  // Function to render HTML in jsPDF
  const renderHTML = (htmlContent: string, startY: number, isNotes: boolean) => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(htmlContent, 'text/html');
    const body = htmlDocument.body;
    const pageWidth = doc.internal.pageSize.width;
    const availableWidth = isNotes ? pageWidth - tableWidth - 20 - margin * 2 : pageWidth - margin * 2;
    // Reset styles when starting a new page
    // reset all the default styles when add new page
    const resetStyles = () => {
      doc.setFillColor('white');
      doc.setDrawColor('black');
      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal');
      doc.setTextColor('black');
    };

    const traverseElements = (element: any, startY: number, accumulatedStyles: any = {}) => {
      if (element.nodeType === Node.TEXT_NODE) {
        let fontSize = accumulatedStyles.fontSize || 10;
        let fontColor = accumulatedStyles.fontColor || 'black';
        let fontFamily = accumulatedStyles.fontFamily || 'helvetica';
        let bgColor = accumulatedStyles.bgColor || 'white';
        let isBold = accumulatedStyles.isBold || false;
        let isItalic = accumulatedStyles.isItalic || false;
        let isUnderline = accumulatedStyles.isUnderline || false;
        let textAlign = accumulatedStyles.textAlign || 'left';
        let isBullet = accumulatedStyles.isBullet || false;
        let bullet = '• ';
        doc.setFont(fontFamily, (isBold ? 'bold' : 'normal') + (isItalic ? 'italic' : ''));
        doc.setFontSize(fontSize);
        doc.setTextColor(fontColor);
        // Add new page 
        if (startY + 70 > doc.internal.pageSize.height) {
          resetStyles();
          footer();
          doc.addPage();
          header();
          startY = 220;
          doc.setFont(fontFamily, (isBold ? 'bold' : 'normal') + (isItalic ? 'italic' : ''));
          doc.setFontSize(fontSize);
          doc.setTextColor(fontColor);
        }

        let textContent = element.textContent.trim();
        textContent = isBullet ? bullet + element.textContent.trim() : element.textContent.trim();
        const lines = doc.splitTextToSize(textContent, availableWidth);

        lines.forEach((line: any) => {
          if (startY + fontSize + 6 + margin > doc.internal.pageSize.height) {
            resetStyles();
            footer();
            doc.addPage();
            header();
            startY = 220; // Reset starting position for the new page
            doc.setFont(fontFamily, (isBold ? 'bold' : 'normal') + (isItalic ? 'italic' : ''));
            doc.setFontSize(fontSize);
            doc.setTextColor(fontColor);
          }
          doc.setFillColor(bgColor);
          let xPos = margin;
          if (textAlign === 'center') {
            xPos = (pageWidth - doc.getTextWidth(line)) / 2; // Center align
          } else if (textAlign === 'right') {
            xPos = pageWidth - margin - doc.getTextWidth(line) - 5; // Right align
          }
          doc.rect(xPos, startY - fontSize + 5, doc.getTextWidth(line + 5), fontSize + 2, 'F'); // (x, y, width, height, 'F' for fill)
          // Adjust X position based on alignment
          doc.text(line, xPos, startY + 5);
          if (isUnderline) {
            doc.setDrawColor(fontColor || 'black');
            doc.line(xPos, startY + 6, xPos + doc.getTextWidth(line), startY + 6);
          }
          startY += fontSize + 4;
        });
      }

      if (element.nodeType === Node.ELEMENT_NODE) {
        let fontSize = element.style.fontSize ? parseInt(element.style.fontSize) : accumulatedStyles.fontSize || 10;
        let fontColor = element.style.color || accumulatedStyles.fontColor || 'black';
        let fontFamily = element.style.fontFamily || accumulatedStyles.fontFamily || 'helvetica';
        let bgColor = element.style.backgroundColor || accumulatedStyles.bgColor || 'white';
        let isBold = accumulatedStyles.isBold || false;
        let isItalic = accumulatedStyles.isItalic || false;
        let isUnderline = accumulatedStyles.isUnderline || false;
        let isBullet = accumulatedStyles.isBullet || false;
        let textAlign = element.style.textAlign ? element.style.textAlign : accumulatedStyles.textAlign ? accumulatedStyles.textAlign : 'left';
        
        if (element.tagName.toLowerCase() === 'mark') {
          const classNames = element.classList;
          // Iterate through class names and find a matching color
          classNames.forEach((className: string) => {
            bgColor = className.split('-')[1];
          });
        }
        if (element.tagName.toLowerCase() === 'ul') {
          Array.from(element.childNodes).forEach((child) => {
            isBullet = true;
          })
        }
        // Update bold and italic flags
        if (element.tagName.toLowerCase() === 'strong' || element.tagName.toLowerCase() === 'b') {
          isBold = true;
        }
        if (element.tagName.toLowerCase() === 'i' || element.tagName.toLowerCase() === 'em') {
          isItalic = true;
        }
        if (element.tagName.toLowerCase() === 'u') {
          isUnderline = true;
        }
        if (element.tagName.toLowerCase() === 'a') {
          isUnderline = true;
          fontColor = 'blue';
        }
        Array.from(element.childNodes).forEach((child) => {
          startY = traverseElements(child, startY, {
            fontSize,
            fontColor,
            fontFamily,
            bgColor,
            isBold,
            isItalic,
            isUnderline,
            textAlign,
            isBullet
          });
        });
      }

      return startY;
    }

    traverseElements(body, startY);
    resetStyles();

  };

  // common Header details
  const headers = [
    { header: "Lot #", dataKey: "lotId" },
    { header: "Type", dataKey: "type", styles: { halign: 'left' } },
    { header: "Price", dataKey: "price", styles: { halign: 'right' } },
    { header: "Qty", dataKey: "qty", styles: { halign: 'right' } },
    { header: "Extended Amt", dataKey: "extended", styles: { halign: 'right' } },
    { header: "Inv.Adjust", dataKey: "adjust", styles: { halign: 'right' } },
    { header: "Buyers Prem", dataKey: "buyerprem", styles: { halign: 'right' } },
    { header: "Totals", dataKey: "totals", styles: { halign: 'right' } }
  ];

  //common Footer
  const footer = () => {
    let pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(8);
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    doc.text(`Clerk: ${dataInvoice?.modifiedBy} ${moment(dataInvoice?.dateOfInvoice).format('MM/DD/YYYY  H:mm:ss')}`, 10, pageHeight - 10);
    doc.text(`Invoice ${dataInvoice?.invoiceNumber} - Bidder: ${dataInvoice?.bidderNumber}`, pageSize.width / 2, pageHeight - 10, { align: 'center' });
    doc.text(`Page ${pageCount}`, pageSize.width - 15, pageHeight - 10, { align: 'right' });
  };

  const footerRows = [
    [{ content: `Clerk: ${dataInvoice?.modifiedBy} ${moment(dataInvoice?.dateOfInvoice).format('MM/DD/YYYY  h:mm:ss a')}`, colSpan: 3, styles: { halign: 'left', fontStyle: 'bold' } },
    { content: `Invoice ${dataInvoice?.invoiceNumber} - Bidder: ${dataInvoice?.bidderNumber}`, styles: { halign: 'center' } },
    { content: currentUTCDate, styles: { halign: 'right' } }]
  ];

  const drawWatermark = () => {
    const watermarkImg = new Image();
    watermarkImg.src = waterMark;
    const imgWidth = 317;
    const imgHeight = 85;
    doc.addImage(watermarkImg, 'PNG', 140, 115, imgWidth, imgHeight, '', 'FAST');
  };

  // Header 
  const header = () => {
    const pageWidth = doc.internal.pageSize.width;
    const img = new Image();
    img.src = dpa_logo;
    doc.addImage(img, "SVG", 10, 30, 75, 30);
    if (dataInvoice?.invoicePaid == '1') {
      drawWatermark();
    }

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`${dataInvoice?.auctionTitle}`, 100, 30, { fontStyle: 'bold' });
    doc.text(`${dataInvoice?.companyName}`, 100, 45, { fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${dataInvoice?.auctionAddress}`, 100, 60);
    doc.text(`${dataInvoice?.auctionCity}, ${dataInvoice?.auctionState} ${dataInvoice?.zipCode ?? ''}`, 100, 75);

    const rightAlignX = pageWidth - 20;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`Invoice #: ${dataInvoice?.invoiceNumber}`, rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(` ${moment(dataInvoice?.dateOfInvoice).format('MMM DD, YYYY')}`, rightAlignX, 45, { align: 'right' });

    doc.setFont("helvetica", "bold");
    doc.text(`Auction #: ${dataInvoice?.auctionId}`, rightAlignX, 60, { align: 'right' });

    doc.setFont("helvetica", "bold");
    doc.text(`Bidder #: ${dataInvoice?.bidderNumber}`, rightAlignX, 75, { align: 'right' });

    doc.setFont("helvetica", "bold");
    doc.text('Verification Code:', rightAlignX, 90, { align: 'right' });

    doc.setFont("helvetica", "bold");
    if (dataInvoice?.invoicePaid == '1') {
      doc.text(dataInvoice?.verificationCode ? dataInvoice?.verificationCode : "-", rightAlignX, 105, { align: 'right' });
    } else {
      doc.setFontSize(15);
      doc.setTextColor("#8B0000"); // Dark red color
      doc.text('Not Paid', rightAlignX, 105, { align: 'right' });
      doc.text('in Full', rightAlignX, 120, { align: 'right' });
    }

    const addressX = 20;
    const addressY = 105;
    const maxWidth = pageWidth - 200;

    // Customer address table
    doc.setFontSize(10);
    doc.setTextColor("#000000");
    doc.setFont("helvetica", "normal");

    const customerNameText = `${dataInvoice?.customerName} (Bidder #: ${dataInvoice?.bidderNumber})`;
    const customerAddressText = doc.splitTextToSize(`${dataInvoice?.customerAddress}`, maxWidth);
    const customerCityStateText = doc.splitTextToSize(`${dataInvoice?.customerCity} ${dataInvoice?.customerState}`, maxWidth);
    const customerCountryStateText = doc.splitTextToSize(`${dataInvoice?.customerCountry} ${dataInvoice?.customerState}`, maxWidth);

    // Adjusting the Y positions
    let currentY = addressY + 15;
    doc.text(customerNameText, addressX, currentY);
    currentY += 15;
    doc.text(customerAddressText, addressX, currentY);
    currentY += customerAddressText.length * 12; // Adjust for multiple lines
    doc.text(customerCityStateText, addressX, currentY);
    currentY += customerCityStateText.length * 12; // Adjust for multiple lines
    doc.text(customerCountryStateText, addressX, currentY);

    // Contact information
    let contactY = currentY + 15; // Adjusted to start after the last address text
    if (dataInvoice?.customerMobile) {
      const customerMobileText = `${formatPhoneNumber(dataInvoice?.customerMobile)}`;
      doc.text(customerMobileText, addressX, contactY);
      contactY += 15;
    }
    let pageCount = doc.internal.getNumberOfPages();
    
    doc.text(`${dataInvoice?.customerEmail}`, addressX, contactY);
    doc.text(`Page ${pageCount}`, pageWidth-margin*3, contactY);

  };
  const invoiceDetailsTable = () => {
    const startY = 210;
    const startX = 10;
    // Display 6 records paer page
    const recordsPerPage = 6;

    // Body details
    const rows: any = [];
    let lotId = '';
    let type = '';
    let price: any = '';
    let qty = '';
    // let extended = '';
    let adjust = '';
    let buyerprem = '';
    let totals = '';
    let taxRate: any = '';
    let title: any = '';
    let itemLocation = '';
    let itemContact: any = '';
    let taxamount: any = '';
    let bpamount: any = '';
    let taxTotal: any = '';

    const invoiceDetails = dataInvoice?.lstInvoiceDeatils || [];

    // Split the invoice details into chunks of 6
    const chunkedInvoiceDetails: any = [];
    for (let i = 0; i < invoiceDetails.length; i += recordsPerPage) {
      chunkedInvoiceDetails.push(invoiceDetails.slice(i, i + recordsPerPage));
    }
    chunkedInvoiceDetails?.forEach((chunk: any, index: any) => {
      chunk.forEach((item: any) => {
        lotId = item.lotNo;
        type = item.type;
        price = formatCurrency(item.price || 0);
        qty = '1';
        // extended = item.extended || 0;
        adjust = item.adjust || 0;
        buyerprem = formatCurrency(item.buyersPrice || 0);
        totals = formatCurrency(item.price + item.buyersPrice);
        taxRate = `${item?.taxrate}%`;
        title = removeHtmlTags(item.title);
        itemLocation = `${item?.strCity || ''} ${item?.strState || ''}`;
        taxamount = formatCurrency((item.price * item?.taxrate) / 100);
        bpamount = formatCurrency((item.buyersPrice * item?.taxrate) / 100);
        taxTotal = formatCurrency((item.price + item.buyersPrice) * item?.taxrate / 100);

        itemContact = item.itemContact ?? '';
        const trimmedContact = itemContact.trim();
        const [name, phoneNumber] = trimmedContact.split(',').map((part: any) => part.trim());

        const formattedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : '';

        const displayContact = phoneNumber
          ? (name ? `${name}, ${formattedPhoneNumber}` : formattedPhoneNumber)
          : itemContact;

        rows.push([
          { content: lotId, styles: { halign: 'left' } },
          { content: type, styles: { halign: 'left' } },
          { content: price, styles: { halign: 'right' } },
          { content: qty, styles: { halign: 'right' } },
          { content: price, styles: { halign: 'right' } },
          { content: adjust, styles: { halign: 'right' } },
          { content: buyerprem, styles: { halign: 'right' } },
          { content: totals, styles: { halign: 'right' } }
        ]);

        rows.push([
          { content: 'Taxes & Rate', colSpan: 3, styles: { halign: 'right' } },
          { content: taxRate, styles: { halign: 'right' } },
          { content: taxamount, styles: { halign: 'right' } },
          { content: '', styles: { halign: 'right' } },
          { content: bpamount, styles: { halign: 'right' } },
          { content: taxTotal, styles: { halign: 'right' } }
        ]);

        rows.push([
          { content: title, colSpan: 8, styles: { halign: 'left', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 1, right: 1, top: 1 }, cellPadding: { top: 5, right: 3, bottom: 0, left: 3 }, cellspacing: 0, } },
        ]);

        rows.push([
          {
            content: `Item Contact: ${displayContact} `, colSpan: 8, styles: { halign: 'left', cellWidth: 'wrap', lineWidth: { bottom: 0, left: 1, right: 1, }, cellPadding: { top: 0, right: 3, left: 3 }, cellspacing: 0 }
          },
        ]);

        rows.push([
          {
            content: `Item Location: ${itemLocation}`, colSpan: 8, styles: { halign: 'left', cellWidth: 'wrap', lineWidth: { left: 1, right: 1, }, cellPadding: { top: 1, bottom: 5, right: 3, left: 3 } }
          },
        ]);

        rows.push([
          { content: '', colSpan: 8, styles: { halign: 'left', lineWidth: { bottom: 0, left: 0, right: 0, top: 1 } } },
        ]);
      });

      // Table for invoice detail
      doc.autoTable({
        startY: startY,
        startX: startX,
        head: [headers.map(col => col.header)],
        body: rows,
        showHead: 'everyPage',
        margin: { left: margin, right: margin },
        tableWidth: '100%',
        theme: 'grid',
        pageBreak: 'auto',
        rowPageBreak: 'auto',
        styles: {
          fontSize: 8,
          cellPadding: 3,
          overflow: 'linebreak',
          halign: 'left',
          textColor: "#000000"
        },
        headStyles: {
          halign: 'right',
          cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
          fillColor: "#ffffff",
          textColor: "#000000",
        },
      });
      // Add a new page after every chunk except the last one
      if (index < chunkedInvoiceDetails.length - 1) {
        footer();
        doc.addPage();
        header();
      }

      // Clear the rows for the next page
      rows.length = 0;
    });
    // Table height for invoice detail table
    const invoiceTableDetail = doc.lastAutoTable;
    const invoiceDetailHeight = invoiceTableDetail.finalY

    // Adjust table start
    let startYForbpTable = invoiceDetailHeight + 5;
    chunkedInvoiceDetails.slice(-1).forEach((element: any) => {
      // Check if chunk data length is greater than 3 then only display bp table in new page 
      if (element.length > 3) {
        footer();
        doc.addPage();
        header();
        startYForbpTable = 210;
      }
    });

    const bpRows: any = [];
    bpRows.push([
      { content: "* Buyer's Premium Applied", styles: { halign: 'left', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115], cellPadding: { top: 5, right: 1, bottom: 1, left: 1 }, } },
      { content: "", styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115] } },
      { content: "* Extended Amt", styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115] } },
      { content: "Inv.Adjust", styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115] } },
      { content: "Buyers Prem", styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115] } },
      { content: "Totals", styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { left: 0, right: 0, top: 2 }, lineColor: [115, 115, 115] } }
    ]);

    bpRows.push([
      { content: 'Invoice Totals', rowSpan: 2, styles: { halign: 'center', fontWeight: 600, fontSize: '12', fillColor: '#dddddd', valign: 'middle', lineWidth: { top: 0, bottom: 2 }, lineColor: [115, 115, 115] } },
      { content: "Items", styles: { halign: 'left', fillColor: '#dddddd' } },
      { content: formatCurrency(dataInvoice?.itemTotal || 0), styles: { halign: 'right', fillColor: '#dddddd' } },
      { content: "", styles: { halign: 'right', fillColor: '#dddddd' } },
      { content: formatCurrency(dataInvoice?.totalBuyerPremium || 0), styles: { halign: 'right', fillColor: '#dddddd' } },
      { content: formatCurrency(dataInvoice?.subTotal || 0), styles: { halign: 'right', fillColor: '#dddddd' } }
    ]);

    bpRows.push([
      { content: 'Taxes', styles: { halign: 'left', fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115] } },
      { content: formatCurrency(dataInvoice?.taxableItemTotal || 0), styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115] } },
      { content: '', styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115] } },
      { content: formatCurrency(dataInvoice?.taxablebuyersPremium || 0), styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115] } },
      { content: formatCurrency(dataInvoice?.taxabletotalTax || 0), styles: { halign: 'right', fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115] } }
    ]);

    doc.autoTable({
      startY: startYForbpTable,
      startX: 10,
      body: bpRows,
      tableWidth: '100%',
      margin: { left: margin, right: margin, top: 10 },
      pageBreak: 'auto',
      rowPageBreak: 'auto',
      showHead: 'everyPage',
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak',
        halign: 'left',
        textColor: "#000000" // Black text color
      },
      headStyles: {
        halign: 'left',
        cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
        fillColor: "#ffffff", // White fill color
        textColor: "#000000", // Black font color
      },
    });

    const bpTableDetail = doc.lastAutoTable;
    const bpDetailHeight = bpTableDetail.finalY

    // Adjust table start
    let startYForTable2 = bpDetailHeight + 5;
    const startXForTable2 = 10;
    let amount = '';
    let description = '';
    const rowsForAdjust: any = [];

    rowsForAdjust.push([
      { content: "Invoice  Adjustments", colSpan: 2 },
    ]);

    dataInvoice?.lstInvoiceAdjust?.length > 0 ? dataInvoice?.lstInvoiceAdjust?.forEach((item: any, index: any) => {
      amount = item.chgCr == 1 ? `(${formatCurrency(item.amount)})` : formatCurrency(item.amount);
      description = item.description;

      rowsForAdjust.push([
        { content: amount, styles: { halign: 'left' } },
        { content: description, styles: { halign: 'left' } }
      ]);
    })
      : rowsForAdjust.push([
        { content: '', colSpan: 2, styles: { halign: 'left' } },
      ]);

    doc.autoTable({
      startY: startYForTable2,
      startX: startXForTable2,
      body: rowsForAdjust,
      tableWidth: tableWidth,
      margin: { left: margin, right: margin, top: 10 },
      pageBreak: 'auto',
      rowPageBreak: 'auto',
      showHead: 'everyPage',
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak',
        halign: 'left',
        textColor: "#000000" // Black text color
      },
      headStyles: {
        halign: 'left',
        cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
        fillColor: "#ffffff", // White fill color
        textColor: "#000000", // Black font color
      },
    });
    // Table height for invoice detail table
    const invoiceAdjust = doc.lastAutoTable;
    const invoiceAdjustHeight = invoiceAdjust.finalY
    // Payment table 
    let date = '';
    let payments = '';
    let payType = '';
    let bp = '';
    let convFee = '';
    // Body details
    const rowsForPayment: any = [];

    rowsForPayment.push([
      { content: "Date", dataKey: "date" },
      { content: "Payments", dataKey: "payments" },
      { content: "Type", dataKey: "payType" },
      { content: "BP %", dataKey: "bp" },
      { content: " Conv. Fee", dataKey: "convFee" },
    ]);

    dataInvoice?.lstInvoicePayment.length > 0 ? dataInvoice?.lstInvoicePayment?.forEach((item: any, index: any) => {
      date = moment(
        item?.date,
      ).format("MM/DD/YYYY");
      payments = formatCurrency(item?.amountPaid || 0);
      payType = paymentTypeData.find(
        (type: any) => type?.objectValueId === item?.payType
      )?.objectTypeValue || "-";
      bp = formatCurrency(item?.buyerPremium || 0);
      convFee = formatCurrency(item?.convenienceCharge || 0);

      rowsForPayment.push([
        { content: date, styles: { halign: 'left' } },
        { content: payments, styles: { halign: 'left' } },
        { content: payType, styles: { halign: 'left' } },
        { content: bp, styles: { halign: 'left' } },
        { content: convFee, styles: { halign: 'left' } }
      ]);
    }) : rowsForPayment.push([
      { content: '', colSpan: 5, }
    ]);

    doc.autoTable({
      startY: startYForTable2,
      startX: startXForTable2,
      body: rowsForPayment,
      tableWidth: tableWidth + 40,
      margin: { left: tableWidth + 30, right: margin, top: 10 },
      theme: 'grid',
      showHead: 'everyPage',
      pageBreak: 'auto',
      rowPageBreak: 'auto',
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak',
        halign: 'left',
        textColor: "#000000" // Black text color
      },
      headStyles: {
        halign: 'left',
        cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
        fillColor: "#ffffff",
        textColor: "#000000",
      },
    });

    // Table height for invoice detail table
    const invoicePayment = doc.lastAutoTable;
    const invoicePaymentHeight = invoicePayment.finalY
    const maxInvoiceHeight = Math.max(invoiceAdjustHeight, invoicePaymentHeight);
    // Invoice total calculation table
    // Body details
    const rowsForTotal: any = [];

    rowsForTotal.push([
      { content: "Adjustments", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: dataInvoice?.invoiceAdjustment < 0 ? `(${formatCurrency(Math.abs(dataInvoice?.invoiceAdjustment))})` : formatCurrency(dataInvoice?.invoiceAdjustment), styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Shipping", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: "", styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Packing", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: "", styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: " Invoice Total", styles: { fontStyle: 'bold', fillColor: '#dddddd', lineWidth: { bottom: 1, left: 0, right: 1, top: 1 } } },
      { content: formatCurrency(dataInvoice?.invoiceTotal || 0), styles: { fontStyle: 'bold', fillColor: '#dddddd', lineWidth: { bottom: 1, left: 0, right: 1, top: 1 }, halign: 'right' } },
    ]);

    rowsForTotal.push([
      { content: "Deposit", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: formatCurrency(dataInvoice?.depositePaid || 0), styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Payments", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: formatCurrency(dataInvoice?.invoicePayment || 0), styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Balance Due", styles: { fontStyle: 'bold', fillColor: '#dddddd', lineWidth: { bottom: 1, left: 0, right: 1, top: 1 } } },
      { content: dataInvoice?.balanceDue < 0 ? `(${formatCurrency(Math.abs(dataInvoice?.balanceDue))})` : formatCurrency(dataInvoice?.balanceDue), styles: { fontStyle: 'bold', fillColor: '#dddddd', halign: 'right', lineWidth: { bottom: 1, left: 0, right: 1, top: 1 } } },
    ]);
    rowsForTotal.push([
      { content: "Conv Fee", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: '', styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Bal Due+Conv Fee", styles: { fillColor: '#dddddd', lineWidth: { bottom: 0, left: 0, right: 0, } } },
      { content: '', styles: { fillColor: '#dddddd', halign: 'right' } },
    ]);
    rowsForTotal.push([
      { content: "Change", styles: { fillColor: '#dddddd', lineWidth: { bottom: 2, left: 0, right: 0 }, lineColor: [115, 115, 115], } },
      { content: '', styles: { fillColor: '#dddddd', lineWidth: { bottom: 2 }, lineColor: [115, 115, 115], halign: 'right' } },
    ]);
    doc.autoTable({
      startY: startYForTable2,
      startX: startXForTable2,
      body: rowsForTotal,
      tableWidth: tableWidth - 40,
      margin: { left: tableWidth * 2 + 80, right: margin },
      theme: 'grid',
      showHead: 'everyPage',
      pageBreak: 'auto',
      rowPageBreak: 'auto',
      styles: {
        fontSize: 8,
        cellPadding: 3,
        overflow: 'linebreak',
        halign: 'left',
        textColor: "#000000"
      },
      headStyles: {
        halign: 'left',
        cellPadding: { top: 10, right: 5, bottom: 6, left: 5 },
        fillColor: "#ffffff",
        textColor: "#000000", 
      },
    });


    const startNotesY = maxInvoiceHeight + 20;
    const maxWidth = doc.internal.pageSize.width - margin;
    const notes = dataInvoice?.notes ? dataInvoice?.notes : '';
    const textLines = doc.splitTextToSize(notes, maxWidth - startX);
    renderHTML(notes, startNotesY, true);
    
    const totalTableDetail = doc.lastAutoTable;
    const totalHeight = totalTableDetail.finalY;
    const maxFooterDataHeight = Math.max(maxInvoiceHeight  + textLines.length * 100, totalHeight);
    const htmlContent = footerData ? footerData : '';
    renderHTML(htmlContent, maxFooterDataHeight+10  , false);
  };

  doc.autoTable({
    startY: 110,
    foot: footerRows,
    showHead: 'everyPage',
    didDrawPage: function () {
      header();
      invoiceDetailsTable();
      footer();
    },
    pageBreak: 'auto',
    rowPageBreak: 'auto',
    styles: {
      fontSize: 8,
      cellPadding: { top: 5, right: 5, bottom: 10, left: 5 },
      backgroundSize: '90mm',
      backgroundPosition: 'center',
    },
    footStyles: {
      halign: 'right',
      fontStyle: 'normal',
      cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
      fillColor: "#ffffff"
    }
  });

  // for PREVIEW uncomment this line
  if (mode == "download") {
    doc.save(`Invoice_No_${dataInvoice?.invoiceNumber}.pdf`);
  } else if (mode == "print") {
    window.open(doc.output('bloburl'), '_blank');
  } else if (mode == "blob") {
    const pdfBlob = doc.output('blob');
    return pdfBlob;
  }
}
// Add condition for invoice status
export const onGetInvoicePaid = (isInvoicePaid: string): string => {
  // Pending = 1,
  // PartialPaid = 2,
  // Paid = 3
  switch (isInvoicePaid) {
    case "1":
      return "Paid";
    case "2":
      return "Partial Paid";
    case "3":
      return "Balance Due";
    default:
      return "Balance Due";
  }
};

// This code is to set css class which will use to change colors according to status
export const onGetInvoicePaidCSS = (isInvoicePaid: string): string => {
  switch (isInvoicePaid) {
    case "1":
      return "badge badge-dpa badge-dpa-success";
    case "2":
      return "partialpaid";
    case "3":
      return "badge badge-dpa status-pending";
    default:
      return "badge badge-dpa status-pending";
  }
};

export const isEmptyObject = (obj: any): boolean => {
  return obj === null || obj === undefined ||
    (typeof obj === 'object' && Object.keys(obj).length === 0);
};