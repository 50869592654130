import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { GETSALESREPLOG } from "../../../../common/apiConstatnts";
import axios from "axios";
import { toast } from "react-toastify";
import { ActiveStep, loader } from "../../../../store/actions/SalesRepAction";
import moment from "moment";
import * as routeConstant from '../../../../common/routeConstants'
import { Link } from "react-router-dom";
import { CommonMessages } from "../../../../common/messageConstants";
import { formatCurrency } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
const Logs = () => {
    const dispatch = useDispatch()
    const saleRepId = useSelector((state: any) => state.salesRep.saleRepId)
    const selectedAuction = useSelector((state: any) => state.salesRep.selectedAuction);
    const auctionId = selectedAuction.value
    const [adjustData, setAdjustData] = useState([])
    const [filterAdj, setFilterAdj] = useState([])
    const [adjCurrentPage, setAdjCurrentPage] = useState(0);
    const [paymentData, setPaymentData] = useState([])
    const [filterPay, setFilterPay] = useState([])
    const [payCurrentPage, setPayCurrentPage] = useState(0);

    const PER_PAGE = 5;
    const adjPageCount = Math.ceil(adjustData?.length / PER_PAGE);
    const payPageCount = Math.ceil(paymentData?.length / PER_PAGE);

    const adjPaginateData = (data: any) => {
        setAdjustData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterAdj(firstSet)
    }

    const payPaginateData = (data: any) => {
        setPaymentData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterPay(firstSet)
    }

    const getSalesRepAdjustPaymentLog = () => {
        dispatch(loader(true))
        axios.get(`${GETSALESREPLOG}?salesRepId=${saleRepId}&auctionId=${auctionId}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const respData = response?.data?.data
                adjPaginateData(respData?.lstSalesRepAdjustLog)
                payPaginateData(respData?.lstSalesRepPaytmentLog)
            }
            dispatch(loader(false))
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });
    }

    const handleAdjPageClick = ({ selected: selectedPage }: any) => {
        setAdjCurrentPage(selectedPage);
    }

    const handlePayPageClick = ({ selected: selectedPage }: any) => {
        setPayCurrentPage(selectedPage);
    }

    const handlePrevious = () => {
        dispatch(ActiveStep('payment'))
    }
    const handleNext = () => {
        dispatch(ActiveStep('sellers'))
    }

    useEffect(() => {
        const offset = adjCurrentPage * PER_PAGE
        const nextSet = adjustData?.slice(offset, offset + PER_PAGE)
        setFilterAdj(nextSet)
    }, [adjCurrentPage]);

    useEffect(() => {
        const offset = payCurrentPage * PER_PAGE
        const nextSet = paymentData?.slice(offset, offset + PER_PAGE)
        setFilterPay(nextSet)
    }, [payCurrentPage]);

    useEffect(() => {
        getSalesRepAdjustPaymentLog()
    }, [auctionId])

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-xxl-10">
                    <div className="mt-3 mb-3 border-300">
                        <div className="mb-3">
                            <h4>Adjusts Logs</h4>
                        </div>
                        <div className="mb-5 border-300">
                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                <table className="table fs--1 mb-0 border-200">
                                    <thead>
                                        <tr>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Audit Date/Time</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Audit By</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Description</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Amount ($)</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Chg/CR ($)</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Tax</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <span>Tax Amt ($)</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-latest-review-body">
                                        {filterAdj.length > 0 ? filterAdj?.map((data: any, index: any) => {
                                            return (
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0">{moment(data.auditOn).format('MM/DD/YYYY hh:mm A')}</p>
                                                    </td>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0 text-truncate">{data.auditBy}</p>
                                                    </td>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0 text-truncate">{data?.description}</p>
                                                    </td>
                                                    <td className="align-middle review p-2">
                                                        <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amount)}</p>
                                                    </td>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.chgCr)}</p>
                                                    </td>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.tax)}</p>
                                                    </td>
                                                    <td className="align-middle p-2">
                                                        <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.taxAmount)}</p>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                <td className="align-middle p-2 text-center" colSpan={7}>No Record Found</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            {filterAdj?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={adjPageCount}
                                    onPageChange={handleAdjPageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={adjCurrentPage}
                                />)}
                        </div>
                    </div>
                    <div className="mt-3 mb-3 border-300">
                        <div className="mb-3">
                            <h4>Payments Logs</h4>
                        </div>
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit Date/Time</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit By</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Amount Paid ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Amount Recd ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Pay Type</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Check</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Date</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterPay.length > 0 ? filterPay?.map((data: any, index: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0">{moment(data.auditOn).format('MM/DD/YYYY hh:mm A')}</p>
                                                        </td>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0 text-truncate">{data.auditBy}</p>
                                                        </td>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amountPaid)}</p>
                                                        </td>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amountRecd)}</p>
                                                        </td>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0">{data?.payType}</p>
                                                        </td>
                                                        <td className="align-middle p-2">
                                                            <p className="fs--1 text-1000 mb-0">{data?.check}</p>
                                                        </td>
                                                        <td className="align-middle customer white-space-nowrap p-2">
                                                            <p className="fs--1 text-1000 mb-0">{data?.date ? moment(data?.date).format('MM/DD/YYYY').toString() : '-'}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className="align-middle p-2 text-center" colSpan={7}>No Record Found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            {filterPay?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={payPageCount}
                                    onPageChange={handlePayPageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={payCurrentPage}
                                />)}
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>
                    {saleRepId && <button className="btn btn-primary ms-2" type="button" onClick={handleNext}>{CommonMessages.nextButton}</button>}
                </div>
            </div>
        </div>
    )
}

export default Logs