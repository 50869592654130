import React from 'react';

const TimeSelector = ({
  label,
  registerMin,
  registerSec,
  errorsMin,
  errorsSec,
  numbers = Array.from({ length: 59 }, (_, index) => index + 1),
  isRequired = false,
}: any) => {
  // Fallback to "0" if registerMin or registerSec is null/undefined or equal to 0
  const selectedMin = registerMin?.value || 0;
  const selectedSec = registerSec?.value || 0;

  return (
    <div className="col-lg-6 mb-3">
      <label className="form-label mb-2">
        {label}
        {isRequired && <sup className="text-danger">*</sup>}
      </label>
      <div className="d-flex dur">
        {/* For Minutes */}
        <select {...registerMin} defaultValue={selectedMin} className="form-select scrollbar">
          <option value="0">0 mins</option>
          {numbers.map((num: any, index: any) => (
            <option value={num} key={index}>
              {num} mins
            </option>
          ))}
        </select>

        <span className="ms-2 me-2">:</span>

        {/* For Seconds */}
        <select {...registerSec} defaultValue={selectedSec} className="form-select scrollbar">
          <option value="0">0 secs</option>
          {numbers.map((num: any, index: any) => (
            <option value={num} key={index}>
              {num} secs
            </option>
          ))}
        </select>
      </div>

      {/* Error handling */}
      {errorsMin ? (
        <span className="text-danger" style={{ fontSize: '12px' }}>
          {errorsMin.message}
        </span>
      ) : (
        errorsSec && (
          <span className="text-danger" style={{ fontSize: '12px' }}>
            {errorsSec.message}
          </span>
        )
      )}
    </div>
  );
};

export default TimeSelector;
