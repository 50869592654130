import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { GETALLAUCTIONLIST, GETINVENTORYBYSELLER, SENDREPORTEMAIL } from "../../common/apiConstatnts";
import { UTCdateConverter } from "../../common/constants";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import handleError from "../../Utils/ApiCommonErrorMessages";
import MomentUtil from "../../Utils/MomentUtil";
import { formatCurrency, formatNumber } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import { generateInventoryBySellerCsv, generateInventoryBySellerPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Inventory by Seller Report', url: routeConstant.INVENTORYBYSELLER }
]

const InventoryBySellerReport = () => {
    const momentUtil = MomentUtil.getInstance();
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
    const [loading, setLoading] = useState(false);
    const [filterSeller, setFilterSeller] = useState<any>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [originalSeller, setOriginalSeller] = useState<any>([]);
    const [inventoryBySellerList, setinventoryBySellerList] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<any>({});
    const [globalAuction, setGlobalAuction] = useState<any>('')
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const PER_PAGE = 10;
    const pageCount = Math.ceil(inventoryBySellerList?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setinventoryBySellerList(data);
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSeller(firstSet);
    }

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = originalSeller?.filter((x: any) => x.company?.toLocaleLowerCase()?.includes(keyword)
            || x.sellerName?.toLocaleLowerCase()?.includes(keyword)
            || x.inventoryLots?.toString()?.includes(keyword)
            || x.salesLots?.toString()?.includes(keyword)
            || x.soldLots?.toString()?.includes(keyword)
            || x.sellerId?.toString()?.includes(keyword));
        paginateData(filterData);
        setCurrentPage(0);
    }
    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...inventoryBySellerList];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
        setGlobalAuction(e);
        setIsChanged(false);
        setSelectedCheckboxes({});
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const exportPDF = () => {
        const doc: any = new jsPDF();
        const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
        const tableColumn = [
            'Seller Id', 'Company Name ', 'Seller Name', 'Inventory Lots', 'Sales Lots', 'Sold Lots'
        ];
        const tableRows: any = [];
        let auctionId = '';
        let auctionDate = ''

        inventoryBySellerList?.forEach((data: any, index: any) => {
            const rowData = [
                data.sellerId || "-",
                data.compnay || "-",
                data.sellerName || "-",
                data.inventoryLots || "-",
                formatNumber(data.salesLots) || "0",
                formatCurrency(data.soldLots) || "0",
            ];
            tableRows.push(rowData);
        });

        const header = (doc: any, auctionId: any, auctionDate: any) => {
            const pageWidth = doc.internal.pageSize.width;
            const img = new Image();
            img.src = dpa_logo;
            doc.addImage(img, "SVG", 7, 9, 30, 12);
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold", });
            doc.text(`${selectedAuction?.label}`, 40, 17, { fontStyle: "bold" });
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const rightAlignX = pageWidth - 10;
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text("Inventory By Seller Report", rightAlignX, 12, { align: "right", fontStyle: "bold", });
            doc.setFont("helvetica", "bold");
            doc.text(`Auction #${selectedAuction?.value}`, rightAlignX, 17, { align: "right" });
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, rightAlignX, 22, {
                align: "right",
            });
        };

        const footer = (doc: any, auctionId: any) => {
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFontSize(8);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
            doc.text(
                `Inventory By Seller Report ${selectedAuction?.value}`,
                pageSize.width / 2,
                pageHeight - 10,
                { align: "center" }
            );
            const todayTime = new Date().toLocaleString();
            doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
                align: "right",
            });
        };

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 40,
            theme: "striped",
            margin: { top: 50, left: 8, right: 8 },
            didDrawPage: () => {
                header(doc, auctionId, auctionDate);
                footer(doc, auctionId);
            },
            styles: {
                fontSize: 8,
                cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
            },
            headStyles: {
                halign: "left",
                cellPadding: { top: 2, right: 1, bottom: 2, left: 1 },
                fillColor: null,
                textColor: '#666666',
            },
            columnStyles: {
                3: { cellWidth: 50 }
            },
        });
        doc.save('Inventory_By_Seller.pdf');
    };

    const generateExcelWithTitle = () => {
        let data = inventoryBySellerList?.map((data: any) => {
            return {
                sellerId: data?.sellerId || "-",
                company: data?.compnay || "-",
                sellerName: data?.sellerName || "-",
                inventoryLots: data?.inventoryLots || "-",
                salesLots: formatNumber(data?.salesLots) || "0",
                soldLots: formatCurrency(data?.soldLots) || "0",
            }
        });

        const wb = XLSX?.utils?.book_new();
        const wsData = [
            ['Inventory by Seller-Report'],
            [`Printed On (in UTC): ${moment(UTCdateConverter(new Date())).format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Seller Id', 'Company Name ', 'Seller Name', 'Inventory Lots', 'Sales Lots', 'Sold Lots'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Inventory by Seller-Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    // Function to fetch InventoryBySellerData  based on the selected auction ID
    const fetchInventoryBySellerData = (auctionId: any) => {
        setLoading(true);
        if (auctionId) {
            setinventoryBySellerList([]);
            axios.get(`${GETINVENTORYBYSELLER}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                const sellerData = response?.data?.data;
                paginateData(sellerData);
                setOriginalSeller(sellerData);
                if (sellerData?.length === 0) {
                    setinventoryBySellerList([]);
                    setOriginalSeller([]);
                }
            });
        }
        // Set loading state to true to indicate data fetching in progress
    };

    const fileConfig: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // handle select all seller
    const handleAllChecked = (e: any) => {
        const isChecked = e?.target?.checked;
        setIsChanged(isChecked);
        if (isChecked === true) {
            // Select all seller IDs
            const allSellerIds: any = {};
            inventoryBySellerList?.forEach((item: any) => {
                allSellerIds[item?.sellerId] = true;
            });
            setSelectedCheckboxes(allSellerIds);
        } else {
            // Deselect all seller IDs
            setSelectedCheckboxes({});
        }
    }
    //handle checkbox for sending mail 
    const handleCheckboxChange = (fData: any, e: any) => {
        const isChecked = e?.target?.checked;

        if (!isChecked) {
            setSelectedCheckboxes((prevSelected: any) => {
                const { [fData.sellerId]: removedId, ...rest } = prevSelected;
                const allSelected = Object.keys(rest).length === inventoryBySellerList?.length;
                setIsChanged(allSelected);
                return { ...rest };
            });
        } else {
            setSelectedCheckboxes((prevSelected: any) => {
                const newSelected = {
                    ...prevSelected,
                    [fData?.sellerId]: true,
                };
                const allSelected = Object.keys(newSelected).length == inventoryBySellerList.length;
                setIsChanged(allSelected);
                return newSelected;
            });
        }
    };


    //function to send email on click of Continue button
    const handleSendEmail = () => {
        const isSelectedCheckbox = Object.keys(selectedCheckboxes)?.length;
        if (isSelectedCheckbox == 0) {
            Swal.fire({
                title: "Please select at least one Seller",
                icon: "warning",
                confirmButtonColor: "#006800",
                confirmButtonText: "OK",
            });
        }
        else {
            const objectKeys = Object?.keys(selectedCheckboxes);
            setLoading(true);
            const payload = {
                auctionID: parseInt(selectedAuction?.value ? selectedAuction?.value : getGlobalAuctionIds),
                sellerId: isChanged ? [] : objectKeys.map((item: any) => parseInt(item)),
                isAllSeller: isChanged
            }
            axios.post(SENDREPORTEMAIL, payload, fileConfig).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    Swal.fire({
                        text: "We are currently in the process of sending  email. Once it has been sent, you will be notified!",
                        icon: "success",
                        confirmButtonColor: "#006800",
                        confirmButtonText: "ok",
                    });
                    setLoading(false);
                    fetchInventoryBySellerData(selectedAuction?.value ? selectedAuction?.value : getGlobalAuctionIds);
                    setSelectedCheckboxes({});
                    setIsChanged(false);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error)
            });;
        }
    }
    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = inventoryBySellerList?.slice(offset, offset + PER_PAGE)
        setFilterSeller(nextSet);
    }, [currentPage]);

    useEffect(() => {
        getAllAuctions();
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            fetchInventoryBySellerData(selectedAuction.value);
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (getGlobalAuctionIds) {
            setSelectedAuction(getGlobalAuction);
            setGlobalAuction(getGlobalAuction)
        }
        else {
            getAllAuctions();
        }
    }, [getGlobalAuction])
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Inventory By Seller Report'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2">
                            {/* <button className="btn  border btn-outline-primary  d-flex align-items-center gap-3" */}

                            <button className="btn btn-primary  d-flex aling-items-center gap-3"
                                onClick={() => {
                                    handleSendEmail();
                                }}
                                disabled={filterSeller?.length == 0 || filterSeller == undefined}>
                                <span>Send Email to Seller</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                                onClick={() => generateInventoryBySellerCsv(inventoryBySellerList, globalAuction)}
                                disabled={filterSeller?.length == 0 || filterSeller == undefined}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2"
                                onClick={() => generateInventoryBySellerPdf(inventoryBySellerList, globalAuction)}
                                disabled={filterSeller?.length == 0 || filterSeller == undefined}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>

                        </div>

                    </div>
                    <div className="col-12 mt-2">
                        <div className="row g-3">
                            <div className=" col-lg-4 col-md-5">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Auction"
                                    className="isActive swap-to-lot-menu clone-inventory"
                                    options={auctionList}
                                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                    value={selectedAuction?.value ? auctionList.find((option: any) => option?.value === selectedAuction.value) : getGlobalAuction}
                                    onChange={(e: any) => {
                                        handleAuctionChange(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                {/* <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                    <input 
                                                        className="form-check-input mt-0 me-1"
                                                        id="selectAllAdd"
                                                        type="checkbox"
                                                        onClick={handleAllChecked}
                                                        checked ={isChanged}
                                                        />
                                                        <span> Select</span>
                                                    </div>
                                                </th> */}
                                                <th className="align-middle bg-gradiant">
                                                    <input
                                                        onClick={handleAllChecked}
                                                        className="form-check-input mt-0 me-1"
                                                        id="selectAllAdd"
                                                        type="checkbox"
                                                        checked={isChanged}
                                                    />Select
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="sellerId">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Seller Id #</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="company">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Company Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'company')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'company')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="sellerName">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Seller Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="inventoryLots">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Inventory Lots</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'inventoryLots')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'inventoryLots')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="salesLots">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Sales Lots</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesLots')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesLots')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="soldLots">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Sold Lots</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'soldLots')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'soldLots')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterSeller?.length > 0 ? filterSeller?.map((fData: any, fIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                        <td className="text-start p-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`checkbox-${fData?.sellerId}`}
                                                                checked={selectedCheckboxes[fData?.sellerId] || false || isChanged}
                                                                onChange={(e: any) =>
                                                                    handleCheckboxChange(fData, e)
                                                                }
                                                            />
                                                        </td>
                                                        <td className="align-middle  p-2">{fData?.sellerId}</td>
                                                        <td className="align-middle  p-2">{fData?.company}</td>
                                                        <td className="align-middle  p-2">  {fData?.sellerName?.charAt(0).toUpperCase() + fData?.sellerName?.slice(1)}
                                                        </td>
                                                        <td className="align-middle  p-2">{fData?.inventoryLots}</td>
                                                        <td className="align-middle  p-2">{fData?.salesLots}</td>
                                                        <td className="align-middle  p-2">{fData?.soldLots}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={10}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterSeller?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventoryBySellerReport;