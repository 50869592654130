import React from "react";
import { Helmet } from "react-helmet";

const CommonHelmet=({ title, name , content }:any)=>{

return(
    <>
    <Helmet>
        <title>{title}DPA Auction</title>
        <meta name={name} content={content} />
    </Helmet></>
)

}
export default CommonHelmet;