import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import Agents from "./Agents";
import DeedLocation from "./DeedLocation";
import Exterior from "./Exterior";
import Interior from "./Interior";
import PropertyDetails from "./PropertyDetails";
import Records from "./Records";
import Taxes from "./Taxes";

const RealEstate = () => {
    const [activeTab, setActiveTab] = useState<string>('records');
    const [loading, setLoading] = useState(false);
    const ActiveT = useSelector((state: any) => state.inventory.activePage);

    const handleChange = (key: string) => {
        setActiveTab(key);
    }

    useEffect(() => {
        setActiveTab(ActiveT.activeTab);
    }, [ActiveT]);

    return (
        <>

            <div className="row top_border justify-content-center">
                {loading && <div className="loader-wrap">
                    <img src={`${localStorage.getItem('globalLoader')}`} />
                </div>}
                <div className="col-xxl-10 mt-3">

                    <Tabs
                        activeKey={activeTab}
                        className="nav-underline border-bottom"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey='records' title='Records'></Tab>
                        <Tab eventKey='deed/location' title='Deed Location'></Tab>
                        <Tab eventKey='agents' title='Agents'></Tab>
                        <Tab eventKey='taxes' title='Taxes'></Tab>
                        <Tab eventKey='propertyDetails' title='Property Details'></Tab>
                        <Tab eventKey='interior' title='Interior'></Tab>
                        <Tab eventKey='exterior' title='Exterior'></Tab>
                        {/* <Tab eventKey='userDefinedFields' title='User Defined Fields'></Tab> */}
                    </Tabs>
                    <div className="mt-3">
                        {
                            activeTab === 'records' ? (
                                <Records />
                            ) : activeTab === 'deed/location' ? (
                                <DeedLocation />
                            ) : activeTab === 'agents' ? (
                                <Agents />
                            ) : activeTab === 'taxes' ? (
                                <Taxes />
                            ) : activeTab === 'propertyDetails' ? (
                                <PropertyDetails />
                            ) : activeTab === 'interior' ? (
                                <Interior />
                            ) : activeTab === 'exterior' ? (
                                <Exterior />
                            ) : (
                                <Records />
                            )

                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default RealEstate;