import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface DashboardState {
    dateRange: {},
    searchData: {}
}

const initialState: DashboardState = {
    dateRange: { fromDate: new Date(new Date().getFullYear(), 0, 1), toDate: new Date(), default: true },
    searchData: { id: '', name: '', date: '' }
};

export const DashboardReducer = (
    state: DashboardState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.SETDATERANGE:
            return {
                ...state,
                dateRange: { ...state.dateRange, ...action.payload },
            };
        case actions.RESETDATERANGE:
            return {
                ...state,
                dateRange: { ...state.dateRange, default: true },
            };
        case actions.SETSEARCHDATA:
            return {
                ...state,
                searchData: { ...state.searchData, ...action.payload },
            };
        case actions.RESETSEARCHDATA:
            return {
                ...state,
                searchData: { id: '', name: '', date: '' },
            };
        default:
            return state;
    }
};