import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { Breadcrumb } from "../../common/interface"
import { DELETETEMPLATE } from '../../common/apiConstatnts';
import { encryptId } from "../../Encryption/Encryption";
import * as routeConstant from '../../common/routeConstants'
import * as types from '../../common/permissionTypes';
import handleError from "../../Utils/ApiCommonErrorMessages";
import Helper from '../../common/helpers';
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import CommonHelmet from "../../common/HelmetWrapper";

type propsType = {
    templateTypeName: 'Auction' | 'SMS' | 'Report' | 'Invoice' | 'Seller' | 'SalesRep' | 'Inventory' | 'SalesRecord' | 'Buyer' | 'ContactUs' | 'Appraisal' | 'Partners'|'Authorization'|'Email';
    apiEndPoint: string;
    addActionRedirectUrl: string;
    editActionRedirectUrl: string;
    templateTypeId:number;
}

const TemplatesListing = ({ templateTypeName, apiEndPoint, addActionRedirectUrl, editActionRedirectUrl,templateTypeId }: propsType) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<any[]>([]);
    const [originalTemplateData, setOriginalTemplateData] = useState<any[]>([]);
    const [filterTemplate, setFilterTemplate] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: "Templates", url: "" },
        { label: `${templateTypeName} Templates`, url: "" },
    ];

    const PER_PAGE = 10;
    const pageCount = Math.ceil(templateData.length / PER_PAGE);

    const handleAdd = () => {
        const checkPer = Helper.checkAdminPermission(types.ADD);
        if (checkPer) {
            navigate(addActionRedirectUrl);
        }
    };

    const handleAction = (id: any, type: string) => {
        if (type === 'edit') {
            const checkPer = Helper.checkAdminPermission(types.EDIT);
            if (checkPer) {
                navigate(`${editActionRedirectUrl}/${id}`);
            }
            return;
        }
        const checkDelPer = Helper.checkAdminPermission(types.DELETE);
        if (checkDelPer) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    let url = '';
                    let toastMessage = `${templateTypeName} template deleted successfully!`;
                    
                    axios.post(`${DELETETEMPLATE}?templateMasterId=${id}`).then((response) => {
                        setLoading(false);
                        if (response?.data?.success === "Fail") {
                            toast.error(response?.data?.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        }
                        else {
                            toast.success(toastMessage, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        getAllTemplates();
                        setCurrentPage(0);
                        setSearchText('');
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
                }
            })
        }
    };

    const handleSearch = (e: any) => {
        setSearchText(e)
        const keyword = e?.toLocaleLowerCase();
        const filterData: any = originalTemplateData?.filter((x: any) => x.templateName?.toLocaleLowerCase()?.includes(keyword) ||
        x?.strTemplateFor?.toString().includes(keyword));
        paginateData(filterData);
        setCurrentPage(0);
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    };

    const paginateData = (data: any) => {
        setTemplateData(data);
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterTemplate(firstSet);
    };

    const getAllTemplates = () => {
        setLoading(true);
        axios.get(`${apiEndPoint}?templateType=${templateTypeId}`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterTemplate([]);
                return;
            }
            else {
                const respData = response.data.data;
                paginateData(respData);
                setOriginalTemplateData(respData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = templateData?.slice(offset, offset + PER_PAGE);
        setFilterTemplate(nextSet);
    }, [currentPage]);

    useEffect(() => {
        getAllTemplates();
    }, []);


    return (
        <>
         <CommonHelmet title={`${templateTypeName} Templates - `} name={"templates"} content={"Templates"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} alt='global-loader'/>
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title={`${templateTypeName} Templates`}>
                    <div className="search-box mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input
                                    className="form-control search-input search form-control-sm"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchText} onChange={(e: any) => handleSearch(e.target.value)}
                                />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                        <button onClick={handleAdd} className="btn btn-primary ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal">
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Create {templateTypeName} Template </span>
                        </button>
                    </div>
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-4 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant" scope="col">Action</th>
                                                <th className="bg-gradiant" scope="col" data-sort="lastname">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Template For</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant" scope="col" data-sort="auctionId">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Template Name</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant" scope="col" data-sort="auctionTitle">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Activation Status</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterTemplate.length > 0 ? filterTemplate.map((data, index) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                    <button
                                                                        className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2">
                                                                        <img src="/assets/img/ellipsis-solid.svg" alt='ellipse-solide' style={{ width: "20px" }} />
                                                                    </button>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                    <Dropdown.Item
                                                                        as='button'
                                                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                                                        onClick={() => handleAction(encryptId(data?.templatemasterId), 'edit')} >
                                                                        Edit <i className="fas fa-pen dropdown-icon"></i>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        as='button'
                                                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                                                        onClick={() => handleAction(data?.templatemasterId, 'delete')} >
                                                                        Delete <i className="fas fa-trash dropdown-icon"></i>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                        <td className="align-middle p-2">{data?.strTemplateFor}</td>
                                                        <td className="align-middle p-2">{data?.templateName}</td>
                                                        <td className="align-middle p-2">
                                                            <span className={`badge badge-dpa fs--2 ${data?.isActive ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                                <span className="badge-label">{data?.isActive ? 'ACTIVE' : 'INACTIVE'}</span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={4}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {filterTemplate.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div >
        </>
    )
}

export default TemplatesListing;
