import React, { FC, useEffect, useState } from "react";
import GooglePlacesWithCSCNew from "./GooglePlacesWithCSCNew";
import { GETALLVALUEDEFAULT, UPDATEVALUEDEFAULT } from "./apiConstatnts";
import axios from "axios";
import Tippy from "@tippyjs/react";
import mapIcon from '../assets/map.svg'
import GoogleMap from "../Utils/GoogleMap";

type AddressForm = {
  errors: any;
  trigger?: any;
  register: any;
  setValue: any;
  onSetAddress: any;
  onHandleChangeAddressLine: any;
  isFull?: boolean;
  setCountryCode?: any;
  taxRate?: boolean;
  addressLine2?: boolean;
  addressFull?: boolean;
  onAddressChange?: any
  agentAddress?: boolean;
  latLog?: boolean;
  watch?: any;
  latLongMap?: boolean;
  required?: boolean
  sameAsBuyer?: boolean
  onAddressSelected?: () => void
  address1?: any
};

export const AddressForm: FC<AddressForm> = ({
  errors,
  trigger,
  register,
  setValue,
  onSetAddress,
  onHandleChangeAddressLine,
  isFull = false,
  setCountryCode,
  taxRate,
  addressLine2,
  addressFull,
  onAddressChange,
  agentAddress = false,
  latLog,
  watch,
  latLongMap = true,
  required = true,
  sameAsBuyer = false,
  onAddressSelected,
  address1
}) => {
  const updateCountryCode = async (newAddress: any, code: any) => {
    if (trigger) {
      await trigger('add1');
      await trigger('city1');
      await trigger('country1');
      await trigger('state1');
      await trigger('zip');
    }
    onAddressChange(newAddress, code)
  };

  const [toggleModal, setToggleValue] = useState<boolean>(false);

  const add1 = watch('add1')

  useEffect(() => {
    setValue("add1", add1 || address1 || '')
  }, [add1])

  const wlatitude = watch('latitude')
  const wlongitude = watch('longitude')

  const handleClose = () => {
    setToggleValue(false)
    // setLocation('')
  }

  const handlePlaceSelect = (place: any, ftype: any) => {
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";
    let address1 = "";
    let county1 = "";
    const formattedAddress = place.formatted_address;
    const addressComponents = place.address_components;
    // if (latLog) {
    const latitudeValue = place?.geometry?.location?.lat();
    const longitudeValue = place?.geometry?.location?.lng();
    setValue('latitude', latitudeValue)
    setValue('longitude', longitudeValue)
    // }

    setValue('address2', "")
    setValue('county1', "")

    for (const component of addressComponents) {
      const types = component.types;
      if (types.includes("country")) {
        country = component.long_name;
        setValue('country1', country)
        const countryCode = component.short_name.toLowerCase();
        onAddressChange(country, countryCode);
        // setCountryCode && setCountryCode(component.short_name.toLowerCase())
        if (setCountryCode) {
          setValue('phone', '');
          setValue('mobile', '');
          setCountryCode(component.short_name.toLowerCase())
        }
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
        setValue('state1', state)
      } else if (types.includes("locality")) {
        city = component.long_name;
        setValue('city1', city)

        if (formattedAddress.startsWith(component.long_name)) {
          address1 = component.long_name;
          // setAddress(address1)
          setValue('add1', address1)
        } else {
          const startIndex = formattedAddress.indexOf(component.long_name);
          address1 = formattedAddress.substring(0, startIndex).trim();
          if (address1.endsWith(",")) {
            address1 = address1.substring(0, address1.length - 1).trim();
          }
          setValue('add1', address1)
          // setAddress(address1)
        }
      } else if (types.includes("administrative_area_level_2")) {
        county1 = component.long_name;
        setValue('county1', county1);
      } else {
        setValue('city1', city);
      }
      if (types.includes("postal_code")) {
        zipCode = component.long_name;
      }
    }
    // setValue(zipCodeType, zipCode)


  };

  const handleSubmitMap = (address: any) => {
    handlePlaceSelect(address, "location")
    setToggleValue(false)
  }

  const onHandleChangeAddress = async (e: any) => {
    onHandleChangeAddressLine(e);
    if (trigger) {
      await trigger('add1');
    }
  }

  return (
    <>
      {latLongMap && <GoogleMap
        isShow={toggleModal}
        lat={wlatitude}
        latLog={latLog}
        long={wlongitude}
        onHandleClose={handleClose}
        onHandleSubmitMap={handleSubmitMap}
        defaultValue={add1}
      />}
      <div className="col-lg-12">
        <div className="row g-3">
          <div className={isFull || addressFull ? "col-12" : "col-lg-6"}>
            <div className="form-group">
              <label className="form-label mb-2">{agentAddress ? 'Agent Address' : 'Address Line 1'} </label>
              {required && <sup className="text-danger">*</sup>}
              <fieldset disabled={sameAsBuyer} style={{ border: 'none', padding: 0 }}>
                <GooglePlacesWithCSCNew
                  value={add1}
                  handleChangeAddressLine={onHandleChangeAddress}
                  setValue={setValue}
                  zipCodeType="zip"
                  setAddress={onSetAddress}
                  setCountryCode={setCountryCode}
                  onAddressChange={updateCountryCode}
                  latLog={latLog}
                  onAddressSelected={onAddressSelected}
                />
              </fieldset>
              {errors.add1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.add1?.message?.toString()}
                </span>
              )}
            </div>
          </div>
          {taxRate || addressLine2 ? "" : <div className={isFull ? "col-12" : "col-lg-6"}>
            <div className="form-group">
              <label className="form-label mb-2">Address Line 2</label>
              <input
                type="text"
                placeholder="Address Line 2"
                className="w-100 form-control"
                {...register("address2")}
                disabled={sameAsBuyer ? true : false}
              />
            </div>
          </div>}
          <div className={isFull ? "col-6" : "col-lg-2"}>
            <div className="form-group">
              <label className="form-label mb-2">City</label>
              {required && <sup className="text-danger">*</sup>}
              <input
                type="text"
                placeholder="City"
                className="w-100 form-control"
                {...register("city1")}
                disabled={sameAsBuyer ? true : false}
              />
              {errors.city1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.city1?.message?.toString()}
                </span>
              )}{" "}
            </div>
          </div>
          <div className={isFull ? "col-6" : "col-lg-2"}>
            <div className="form-group">
              <label className="form-label mb-2">State</label>
              {required && <sup className="text-danger">*</sup>}
              <input
                type="text"
                placeholder="State"
                className="w-100 form-control"
                {...register("state1")}
                disabled={sameAsBuyer ? true : false}
              />
              {errors.state1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.state1?.message?.toString()}
                </span>
              )}
            </div>
          </div>
          <div className={isFull ? "col-6" : "col-lg-2"}>
            <div className="form-group">
              <label className="form-label mb-2">Zip Code</label>
              {required && <sup className="text-danger">*</sup>}
              <input
                type="text"
                placeholder="Zip Code"
                maxLength={12}
                className="w-100 form-control"
                {...register("zip")}
                disabled={sameAsBuyer ? true : false}
              />
              {errors.zip && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.zip?.message?.toString()}
                </span>
              )}
            </div>
          </div>
          <div className={isFull ? "col-6" : "col-lg-3"}>
            <div className="form-group">
              <label className="form-label mb-2">Country</label>
              {required && <sup className="text-danger">*</sup>}
              <input
                type="text"
                placeholder="Country"
                className="w-100 form-control"
                {...register("country1")}
                disabled={sameAsBuyer ? true : false}
              />
              {errors.country1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.country1?.message?.toString()}
                </span>
              )}
            </div>
          </div>
          <div className={isFull ? "col-6" : "col-lg-3"}>
            <div className="form-group">
              <label className="form-label mb-2">County</label>
              {!agentAddress}
              <input
                type="text"
                placeholder="County"
                className="w-100 form-control"
                {...register("county1")}
                disabled={sameAsBuyer ? true : false}
              />
              {errors.county1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors?.county1?.message?.toString()}
                </span>
              )}
            </div>
          </div>
          {latLongMap &&
            <>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label">Latitude</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Latitude"
                    name="latitude"
                    value={wlatitude}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label className="form-label">Longitude</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Longitude"
                    name="longitude"
                    value={wlongitude}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label className="form-label">Show Map
                  <Tippy content="Click on the map to select location">
                    <i className="eye_icons fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                  </Tippy>
                </label>
                <div className="">
                  <button className="btn btn-light  p-0" type="button" data-bs-toggle="modal"
                    onClick={() => {
                      setToggleValue(true)
                    }}>
                    <span className="">
                      <img src={mapIcon} title="Click on the map to select location" />
                    </span>
                  </button>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export const onGetDefaultValues = async (
  e: any,
  onDataRecieved: (countryId: any, stateId: any, cityId: any, countyId: any) => void
) => {
  axios.get(GETALLVALUEDEFAULT).then((response: any) => {
    const jsonData = response?.data?.data;
    const foundCountry = jsonData?.find(
      (item: any) => item.objectType === "Country"
    );
    const matchedCountry = foundCountry?.lstObjectValue?.find(
      (item: any) => item?.objectTypeValue.toLowerCase() === e.country1.toLowerCase()
    );
    const foundState = jsonData?.find(
      (item: any) => item.objectType === "State"
    );
    const matchedState = foundState?.lstObjectValue?.find(
      (item: any) => item.objectTypeValue.toLowerCase() === e.state1.toLowerCase()
    );

    const foundCity = jsonData?.find((item: any) => item.objectType === "City");
    const matchedCity = foundCity?.lstObjectValue?.find(
      (item: any) => item.objectTypeValue.toLowerCase() === e.city1.toLowerCase()
    );

    const foundCounty = jsonData?.find((item: any) =>
      item?.objectType === "County"
    );
    const matchedCounty = foundCounty?.lstObjectValue?.find(
      (item: any) => item.objectTypeValue.toLowerCase() === e?.county1?.toLowerCase()
    );
    const stateData = matchedState === undefined ? e.state1 : matchedState;
    const cityData = matchedCity === undefined ? e.city1 : matchedCity;
    const countyData = matchedCounty === undefined ? e?.county1 : matchedCounty;

    if (matchedCountry === undefined) {
      const payload = [
        {
          objectTypeId: foundCountry?.objectTypeId,
          objectType: foundCountry?.objectType,
          isActive: true,
          isDelete: false,
          mode: "update",
          lstObjectValue: [
            {
              objectTypeId: foundCountry?.objectTypeId,
              objectValueId: 0,
              objectTypeValue: e.country1,
              companyId: localStorage.getItem('companyId'),
              parentId: 0,
              mode: "add",
              isActive: true,
              isDelete: false,
            },
          ],
        },
      ];

      axios.post(UPDATEVALUEDEFAULT, payload).then((response: any) => {
        const res = response?.data?.data[0];
        const countryId =
          res?.lstObjectResponse && res?.lstObjectResponse[0]?.objectValueId;
        onProcessState(
          countryId,
          stateData,
          foundState,
          foundCity,
          cityData,
          foundCounty,
          countyData,
          onDataRecieved
        );
      });

      return;
    }

    onProcessState(
      matchedCountry.objectValueId,
      stateData,
      foundState,
      foundCity,
      cityData,
      foundCounty,
      countyData,
      onDataRecieved
    );
  });
};

export const onGetDefaultOwnerValues = async (
  e: any,
  onDataRecieved: (countryId: any, stateId: any, cityId: any, countyId: any) => void
) => {
  axios.get(GETALLVALUEDEFAULT).then((response: any) => {
    const jsonData = response?.data?.data;
    const foundCountry = jsonData?.find(
      (item: any) => item.objectType === "Country"
    );
    const matchedCountry = foundCountry?.lstObjectValue?.find(
      (item: any) => item?.objectTypeValue.toLowerCase() === e.ownerCountry.toLowerCase()
    );
    const foundState = jsonData?.find(
      (item: any) => item.objectType === "State"
    );
    const matchedState = foundState?.lstObjectValue?.find(
      (item: any) => item.objectTypeValue.toLowerCase() === e.ownerState.toLowerCase()
    );
    const foundCity = jsonData?.find((item: any) => item.objectType === "City");
    const matchedCity = foundCity?.lstObjectValue?.find(
      (item: any) => item.objectTypeValue.toLowerCase() === e.ownerCity.toLowerCase()
    );
    const stateData = matchedState === undefined ? e.state1 : matchedState;
    const cityData = matchedCity === undefined ? e.city1 : matchedCity;

    const foundCounty = jsonData?.find((item: any) => item.objectType === "County");
    const matchedCounty = foundCounty?.lstObjectValue?.find(
      (item: any) => item?.objectTypeValue?.toLowerCase() === e?.county1.toLowerCase()
    );
    const countyData = matchedCounty === undefined ? e?.county1 : matchedCounty;

    if (matchedCountry === undefined) {
      const payload = [
        {
          objectTypeId: foundCountry?.objectTypeId,
          objectType: foundCountry?.objectType,
          isActive: true,
          isDelete: false,
          mode: "update",
          lstObjectValue: [
            {
              objectTypeId: foundCountry?.objectTypeId,
              objectValueId: 0,
              objectTypeValue: e.country1,
              companyId: localStorage.getItem('companyId'),
              parentId: 0,
              mode: "add",
              isActive: true,
              isDelete: false,
            },
          ],
        },
      ];

      axios.post(UPDATEVALUEDEFAULT, payload).then((response: any) => {
        const res = response?.data?.data[0];
        const countryId =
          res?.lstObjectResponse && res?.lstObjectResponse[0]?.objectValueId;
        onProcessState(
          countryId,
          stateData,
          foundState,
          foundCity,
          foundCounty,
          cityData,
          countyData,
          onDataRecieved
        );
      });
      return;
    }

    onProcessState(
      matchedCountry.objectValueId,
      stateData,
      foundState,
      foundCity,
      cityData,
      foundCounty,
      countyData,
      onDataRecieved
    );
  });

};



const onProcessState = (
  countryId: any,
  state: any,
  foundState: any,
  foundCity: any,
  cityData: any,
  foundCounty: any,
  countyData: any,
  onDataRecieved: (countryId: any, stateId: any, cityId: any, countyId: any) => void
) => {
  const checkStateType = typeof state;
  if (checkStateType === "string") {
    const payload = [
      {
        objectTypeId: foundState?.objectTypeId,
        objectType: foundState?.objectType,
        isActive: true,
        isDelete: false,
        mode: "update",
        lstObjectValue: [
          {
            objectTypeId: foundState?.objectTypeId,
            objectValueId: 0,
            objectTypeValue: state,
            companyId: localStorage.getItem('companyId'),
            parentId: 0,
            mode: "add",
            isActive: true,
            isDelete: false,
          },
        ],
      },
    ];

    axios.post(UPDATEVALUEDEFAULT, payload).then((response: any) => {
      const res = response?.data?.data[0];
      const stateId = res?.lstObjectResponse && res?.lstObjectResponse[0]?.objectValueId;
      onProcessCity(countryId, stateId, foundCity, cityData, foundCounty, countyData, onDataRecieved);
      // onProccessCounty(
      //   countryId,
      //   state.objectValueId,
      //   foundCounty,
      //   cityData,
      //   countyData,
      //   onDataRecieved
      // )
    });

    return;
  }

  onProcessCity(
    countryId,
    state.objectValueId,
    foundCity,
    cityData,
    foundCounty,
    countyData,
    onDataRecieved
  );

  // onProccessCounty(
  //   countryId,
  //   state.objectValueId,
  //   foundCounty,
  //   cityData,
  //   countyData,
  //   onDataRecieved
  // )
};

const onProcessCity = (
  countryId: any,
  stateId: any,
  foundCity: any,
  city: any,
  foundCounty: any,
  countyData: any,
  onDataRecieved: (countryId: any, stateId: any, cityId: any, countyId: any) => void
) => {
  const checkCityType = typeof city;
  let cityId = city.objectValueId;
  if (checkCityType === "string") {
    const payload = [
      {
        objectTypeId: foundCity?.objectTypeId,
        objectType: foundCity?.objectType,
        isActive: true,
        isDelete: false,
        mode: "update",
        lstObjectValue: [
          {
            objectTypeId: foundCity?.objectTypeId,
            objectValueId: 0,
            objectTypeValue: city,
            companyId: localStorage.getItem('companyId'),
            parentId: 0,
            mode: "add",
            isActive: true,
            isDelete: false,
          },
        ],
      },
    ];
    axios.post(UPDATEVALUEDEFAULT, payload).then((response: any) => {
      const res = response?.data?.data[0];
      cityId = res?.lstObjectResponse && res?.lstObjectResponse[0]?.objectValueId;
      // onDataRecieved(countryId, stateId, cityId, countyId);
      onProccessCounty(
        countryId, stateId, cityId, foundCounty, countyData, onDataRecieved
      )

    });

    return
  }
  onProccessCounty(
    countryId,
    stateId,
    city.objectValueId,
    foundCounty,
    countyData,
    onDataRecieved
  )

  // if (checkCityType === "object") {
  //   onDataRecieved(countryId, stateId, cityId, countyId);
  //   return;
  // }
};


const onProccessCounty = (
  countryId: any,
  stateId: any,
  cityId: any,
  foundCounty: any,
  county: any,
  onDataRecieved: (countryId: any, stateId: any, cityId: any, countyId: any) => void
) => {
  const checkCountyType = typeof county;
  let countyId = county?.objectValueId;
  const isCounty = county?.objectTypeValue?.length > 0;

  if (checkCountyType === "string" && county?.length > 0) {
    const payload = [
      {
        objectTypeId: foundCounty?.objectTypeId,
        objectType: foundCounty?.objectType,
        isActive: true,
        isDelete: false,
        mode: "update",
        lstObjectValue: [
          {
            objectTypeId: foundCounty?.objectTypeId,
            objectValueId: 0,
            objectTypeValue: county,
            companyId: localStorage.getItem('companyId'),
            mode: "add",
            isActive: true,
            isDelete: false,
            parentId: 0,
          },
        ],
      },
    ];

    axios.post(UPDATEVALUEDEFAULT, payload).then((response: any) => {
      const res = response?.data?.data[0];
      countyId = res?.lstObjectResponse && res?.lstObjectResponse[0]?.objectValueId;
      onDataRecieved(countryId, stateId, cityId, countyId);
    });

    return;
  }

  // if (checkCountyType === "object") {
  onDataRecieved(countryId, stateId, cityId, isCounty ? countyId : 0);
  return;
  // }
}