import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import GooglePlaces from '../components/Company/GooglePlaces'
// import MapContainer from '../components/Auction/NewAuction/AuctionInfo/MapContainer'
import GoogleMapContainer from './GoogleMapContainer'
import { CommonMessages } from '../common/messageConstants'

interface GoogleMapProps {
    isShow: boolean
    defaultValue: any
    onHandleClose: () => void
    onHandleSubmitMap: (updatedAddress: any) => void
    latLog: any
    lat: any
    long: any
}

const GoogleMap: FC<GoogleMapProps> = ({ isShow = false, defaultValue, onHandleClose, onHandleSubmitMap, lat, long, latLog }) => {
    const [updatedAddress, setUpdatedAddress] = useState<any>(null)
    const [latLong, setLatLong] = useState(null)
    const [value, setValue] = useState(null)

    const onHandleChangeAddressLine = (e: any) => {
        setValue(e.target.value)
    }

    const onHandlePlaceSelect = (place: any) => {
        setUpdatedAddress(place);
        setValue(place?.formatted_address)
        setLatLong(place?.geometry?.location)
    };

    const onSumitClicked = () => {
        onHandleSubmitMap(updatedAddress)
        onHandleClose()
    }

    const onCloseClick = () => {
        onHandleClose()
        setValue(defaultValue)
        setUpdatedAddress(defaultValue)
        setLatLong(null)

    }

    useEffect(() => {
        setValue(defaultValue)
        return () => { }
    }, [defaultValue])

    useEffect(() => {
        setLatLong(latLog)
        return () => { }
    }, [latLog])

    return (
        <Modal show={isShow} size='xl' centered>
            <Modal.Header>
                <GooglePlaces
                    value={value}
                    handleChangeAddressLine={onHandleChangeAddressLine}
                    type="locations"
                    onPlaceSelected={(place: any, type: any) => onHandlePlaceSelect(place)}
                    name="locations"
                />
                {/* Select Location</h5> */}
                <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseClick}>
                    <span className="fas fa-times text-danger fs-20"></span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <GoogleMapContainer location={updatedAddress} latLog={latLong} lat={lat} long={long} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={onCloseClick}>{CommonMessages.cancelButton}</button>
                <button className="btn btn-primary" type="submit" disabled={!latLong} onClick={onSumitClicked}>Update</button>
            </Modal.Footer>
        </Modal>)
}

export default GoogleMap