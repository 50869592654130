import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { GETAUCTIONLOTS } from '../../../../common/apiConstatnts';
import { auctionType } from '../../../../common/constants';
import { CommonMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { encryptId } from '../../../../Encryption/Encryption';
import { setAuctionDetailActive } from '../../../../store/actions/AuctionAction';
import { loader } from '../../../../store/actions/CommonAction';
import handleError from '../../../../Utils/ApiCommonErrorMessages';
import { removeHtmlTags } from '../../../../Utils/Utils';

const Lots = () => {
  const dispatch = useDispatch();
  const getUrl = window.location.pathname;
  const IsCloned = getUrl.split('/')[2].includes('clone') ? true : false;
  const auctionInfoData = useSelector((state: any) => state.auction.auctionInfoData);
  const auctionLotsSelected = IsCloned ? [] : auctionInfoData?.auctionLots;
  const auctionId: any = useSelector((state: any) => state.auction.auctionId)
  const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
  const [currentPage, setCurrentPage] = useState(0);
  const [lots, setLots] = useState<any>([]);
  const [filterLots, setFilterLots] = useState([]);
  const PER_PAGE = 10;
  const pageCount = Math.ceil(lots.length / PER_PAGE);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }

  const handleSorting = (type: string, fieldName: string) => {
    let sortedData = [...lots];
    if (type === "ASC") {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData.sort((a: any, b: any) =>
          a[fieldName].localeCompare(b[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
      }
    } else {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData.sort((a: any, b: any) =>
          b[fieldName].localeCompare(a[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
      }
    }
    paginateData(sortedData);
    setCurrentPage(0);
  }

  const paginateData = (data: any) => {
    setLots(data);
    const firstSet = data?.slice(0, PER_PAGE);
    setFilterLots(firstSet);
  }

  const handlePageClick = ({ selected: selectedPage }: any) => {
    setCurrentPage(selectedPage);
  }

  const getAllLots = () => {
    dispatch(loader(true));
    const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
    axios.get(`${GETAUCTIONLOTS}?AuctionId=${aId}`, config).then((response: any) => {
      if (response?.data?.success === "Fail") {
        dispatch(loader(false))
        return;
      }
      else {
        const respData = response.data.data
        paginateData(respData);
        dispatch(loader(false));
      }
    }).catch((error) => {
      dispatch(loader(false));
      handleError(error);
    })
  };

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const nextSet = lots.slice(offset, offset + PER_PAGE);
    setFilterLots(nextSet)
}, [currentPage]);

  useEffect(() => {
    getAllLots();
  }, []);

  const handleSearch = (e: any) => {
    const keyword = e.target.value?.toLocaleLowerCase()
    if (keyword === '') {
      getAllLots();
      return
    }
    const filterData: any = lots?.filter((item: any) => item.userName?.toLocaleLowerCase()?.includes(keyword) ||
      item.title?.toLocaleLowerCase()?.includes(keyword)
      ||item.statusnew?.toLocaleLowerCase()?.includes(keyword)
      ||item.ctrlNo?.toString()?.includes(keyword)
      ||item.lotNo?.toString()?.includes(keyword)
      ||item.saleRepName?.toLocaleLowerCase()?.includes(keyword)
      ||item.taxExempt?.toLocaleLowerCase()?.includes(keyword)
    )

    paginateData(filterData);
    setCurrentPage(0)
  }

  const generateExcelWithTitle = () => {
    const auctionName = lots[0].auctionTitle
    const data = lots.map((data: any) => {
      return {
        lotNo: data.lotNo,
        ctrlNo: data.ctrlNo,
        title: data?.title ? removeHtmlTags(data?.title) : '',
        statusnew: data.statusnew,
        auctionType: auctionType.find((aData) => aData.value == data.auctionType)?.label,
        exempt: data.taxExempt ? 'Yes' : 'No',
        startDateTime: moment(data.startdateTime).format('MM/DD/YYYY hh:mm A'),
        endDateTime: moment(data.endDateTime).format('MM/DD/YYYY hh:mm A'),
        salesRep: 'Nick'
      }
    })
    const wb = XLSX.utils.book_new();
    const wsData = [
      [`Auction Name: ${auctionName}`],
      [`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`],
      [],
      ['Lots #', 'Ctrl No', 'Title', 'Status', 'Auction Type', 'Tax Exempt', 'Auction Start Date Time', 'Auction End Date Time', 'Sales Rep'],
      ...data.map((item: any) => Object.values(item))
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Lot_In_Auction.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handlePrev = () => {
    dispatch(setAuctionDetailActive({ activeLabel: "AuctionCustomer" }));
  }

  const handleNext = () => {
    dispatch(setAuctionDetailActive({ activeLabel: "AuctionInvoice" }));
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h5 className='mt-4'>Lots In Auction</h5>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <div className="search-box mt-2 p-2 ">
            <div className="d-flex align-items-center justify-content-end">
              <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                <span className="fas fa-search search-box-icon"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4 border-300">
            <div className="table-responsive mx-n1 px-1 scrollbar">
              <table className="table fs--1 mb-0 border-top border-200">
                <thead>
                  <tr>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Display Lot No"
                    >
                      <div className="d-flex align-items-center">
                        <span>Lot #</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Ctrl"
                    >
                      <div className="d-flex align-items-center">
                        <span>Ctrl</span>
                        <div className="sorting_group">
                          <button
                            className="arrow-up"
                            onClick={() =>
                              handleSorting("ASC", "ctrlNo")
                            }
                          ></button>
                          <button
                            className="arrow-down"
                            onClick={() =>
                              handleSorting("DESC", "ctrlNo")
                            }
                          ></button>
                        </div>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant white-space-pre p-2"
                      scope="col"
                      data-sort="firstname"
                    >
                      <div className="d-flex align-items-center">
                        <span>Auction Code - Title</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Title"
                    >
                      <div className="d-flex align-items-center">
                        <span>Title</span>
                        <div className="sorting_group">
                          <button
                            className="arrow-up"
                            onClick={() =>
                              handleSorting("ASC", "title")
                            }
                          ></button>
                          <button
                            className="arrow-down"
                            onClick={() =>
                              handleSorting("DESC", "title")
                            }
                          ></button>
                        </div>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="statusnew"
                    >
                      <div className="d-flex align-items-center">
                        <span>Status</span>
                        <div className="sorting_group">
                          <button
                            className="arrow-up"
                            onClick={() =>
                              handleSorting("ASC", "statusnew")
                            }
                          ></button>
                          <button
                            className="arrow-down"
                            onClick={() =>
                              handleSorting("DESC", "statusnew")
                            }
                          ></button>
                        </div>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Auction Type</span>
                        <div className="sorting_group">
                          <button
                            className="arrow-up"
                            onClick={() =>
                              handleSorting(
                                "ASC",
                                "strAuctionType"
                              )
                            }
                          ></button>
                          <button
                            className="arrow-down"
                            onClick={() =>
                              handleSorting(
                                "DESC",
                                "strAuctionType"
                              )
                            }
                          ></button>
                        </div>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Status"
                    >
                      <div className="d-flex align-items-center">
                        <span>Tax Exempt</span>
                      </div>
                    </th>
                    <th
                      className="bg-gradiant p-2 white-space-pre"
                      scope="col"
                      data-sort="Sales Rep"
                    >
                      <div className="d-flex align-items-center">
                        <span>Sales Rep</span>
                      </div>
                    </th>


                  </tr>
                </thead>
                <tbody className="list" id="table-latest-review-body">
                  {filterLots.length > 0 ?
                    filterLots.map((data: any, index: any) => {
                      const lotId = data.lotId;
                      return (
                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                          <td className="align-middle white-space-nowrap p-2">{data.displayLotNo ? data.displayLotNo : data.lotNo}</td>
                          {/* <td className="align-middle white-space-nowrap p-2">{data.displayLotNo ?? '-'}</td> */}
                          <td className="align-middle white-space-nowrap p-2">{data.ctrlNo}</td>
                          <td className="align-middle white-space-nowrap p-2">{data.auctionCode}-{data.auctionTitle}</td>
                          <td
                            className="align-middle p-2"
                          >
                            <Link className="btn text-decoration-underline m-0 p-0 fw-normal text-start" data-bs-dismiss="modal"
                              to={`${routeConstant.UPDATEINVENTORY}/${encryptId(data?.lotId)}`}
                              target="_blank"
                            >
                              {data?.title ? removeHtmlTags(data?.title) : '-'}
                            </Link>
                          </td>
                          <td className="align-middle white-space-pre p-2">
                            {" "}
                            <span
                              className={`badge badge-dpa status-${data.statusnew
                                ?.replace(/ /g, "")
                                .toLowerCase()}`}
                            >
                              {data.statusnew}
                            </span>
                          </td>
                          <td className="align-middle white-space-nowrap p-2">{auctionType.find((aData) => aData.value == data.auctionType)?.label}</td>
                          <td className="align-middle white-space-nowrap p-2">{data.taxExempt ? 'Yes' : 'No'}</td>
                          <td className="align-middle white-space-nowrap p-2">{data.saleRepName}</td>
                        </tr >
                      )
                    }) :
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className='text-center p-4' colSpan={10}>No records found!</td>
                    </tr>
                  }
                </tbody >
              </table >
            </div >
          </div >
        </div >
        <div className='d-flex align-items-center justify-content-end mt-4'>
          {filterLots.length > 0 && (
            <ReactPaginate
              previousLabel={"←"}
              nextLabel={"→"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              forcePage={currentPage}
            />
          )}
        </div>
      </div >
      <div className="d-flex justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
        <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" disabled={lots.length === 0} onClick={generateExcelWithTitle}>{CommonMessages.exportExcel} <i className="fas fa-download"></i></button>
        <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
      </div>
    </>
  )
}

export default Lots;