import React from "react";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import { Breadcrumb } from "../../../common/interface";
import Headers from "../../Header/Header";

const InnerHeaderBreadCrumb = ({ breadCrumb }: { breadCrumb: Breadcrumb[] }) => {
    return (
        <>
            <Headers />
            <nav aria-label="breadcrumb" className="navbar navbar-top border-bottom-0 navbar-expand bg-gradiant pt-2 pb-2" >
                <BreadCrumbs data={breadCrumb} />
            </nav>
        </>
    )
}

export default InnerHeaderBreadCrumb;
