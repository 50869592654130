import React, { useState, useEffect, useRef } from "react"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import axios from "axios"
import { toast } from "react-toastify"
import {  OVERALLSELLERSUMMARY, GETSELLERBYAUCTIONID, GETALLAUCTIONLIST } from "../../common/apiConstatnts"
import Sidebar from "../Sidebar/Sidebar"
import InnerHeader from "../InnerHeader/InnerHeader"
import { errorMessages } from "../../common/messageConstants"
import moment from "moment"
import Select from 'react-select';
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import 'jspdf-autotable';
import scissor from '../../assets/img/scissor.svg'
import { generateSellerOverallSummaryPdf } from "./ExportReportsPdfCsvFile"
import handleError from "../../Utils/ApiCommonErrorMessages"
import { formatPhoneNumber } from "../../common/constants"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Summary Report', url: routeConstant.SELLEROVERALLSUMMARY }
]

const SellerOverAllReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [sellerOverallData, setSellerOverallData] = useState<any[]>([]);
    const [filterSellerCommission, setFilterSellerCommission] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [childCurrentPage, setChildCurrentPage] = useState(0);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [sellerList, setSellerList] = useState<any>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [finalData, setFinalData] = useState<any>([]);
    const [selectedSellers, setSelectedSellers] = useState<any>({});
    const [filterSelectedSellers, setFilterSelectedSellers] = useState<any>([]);
    const [singleSellerData, setSingleSellerData] = useState<any>({});
    const [sellerChildData, setSellerChildData] = useState<any>([]);
    const [filterChildData, setFilterChildData] = useState<any>([]);

    const [displayContent, setDisplayContent] = useState('');
    const [printContent, setPrintContent] = useState('');
    const [auctionCode, setAuctionCode] = useState('')
    const tableRef:any = useRef(null);
    const PER_PAGE = 3;

    let AuctionStartDate = sellerOverallData?.length > 0 ? sellerOverallData[0].auctionStartDate : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
        getSellerByAuctionId(e?.value)
        setSellerOverallData([])
        setSellerList([]);
        setSelectedSellers('')
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    const getSellerOverAllData = (auctionId?: any, sellerId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${OVERALLSELLERSUMMARY}?auctionId=${auctionId}&sellerId=${sellerId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setSellerOverallData([])
                    return;
                } else {
                    const sellerData = response.data.data;
                    setSellerOverallData(sellerData)
                    const printHtml = sellerData.find((item:any) => item.isPrintView)?.html || '';
                    const displayHtml = sellerData.find((item:any) => !item.isPrintView)?.html || '';            
                    setDisplayContent(displayHtml);
                    setPrintContent(printHtml);
                    // setSellerOverallData(response.data.data)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }
    }

    const generateExcelFromHtml = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(printContent, 'text/html');
        const tables = doc.querySelectorAll('table');
        if (!tables || tables.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'No Table Found',
                text: 'No tables found in the HTML content to generate Excel.',
                confirmButtonColor: '#006800',
            });
            return;
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([]);
    
        tables.forEach((table, index) => {
            const data = XLSX.utils.table_to_sheet(table, { raw: true });
            const dataArray: any[][] = XLSX.utils.sheet_to_json(data, { header: 1 });
            if (index !== 0) {
                XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });
            }
            XLSX.utils.sheet_add_aoa(ws, dataArray, { origin: -1 });
        });
        XLSX.utils.book_append_sheet(wb, ws, 'Seller Overall Summary Summary');
        XLSX.writeFile(wb, 'Seller-Summary-Report.xlsx');
    };

    const paginateData = (e: any) => {   
        setSelectedSellers(e);  
        getSellerOverAllData(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value, e.value)
        // const selectedSellerId = data.value;        
        // if (selectedSellerId) {
        //     const filteredSales = sellerOverallData.filter((item: any) => {
        //         return item.sellerId === selectedSellerId; 
        //     }); 
        //     setSellerOverallDataNew(filteredSales);
        // } else {
        //     getSellerOverAllData(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value);
        // }
    };
    

    const getSellerByAuctionId = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${GETSELLERBYAUCTIONID}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    setFilterSellerCommission([]);
                    return;
                } else {
                    const sellerData = response.data.data.map((i: any) => {
                        return { label: `${i.sellerId} - ${i.firstName} ${i.lastName}`, value: i.sellerId }
                    })
                    setSellerList(sellerData)
                }
            }, (error) => {
                setLoading(false)
                if (error.response.status !== 401) {
                    toast.error(errorMessages.error401, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            })
        }       
    }

    const getSingleSellerData = (sellerId: any) => {
        const seller: any = sellerOverallData?.find((i: any) => i.sellerId == sellerId)
        for (let i = 0; i < seller?.lstSellerSummary?.length; i++) {
            const data: any = seller.lstSellerSummary[i]
            if (data.status == 'Sold') {
                seller['sold'] = data
                continue
            }
            if (data.status == 'Passed') {
                seller['pass'] = data
                continue
            }
            if (data.status == 'Buyback') {
                seller['buyback'] = data
                continue
            }
            if (data.status == 'Unsold') {
                seller['unsold'] = data
                continue
            }
        }
        setSingleSellerData(seller)
    }

    useEffect(() => {
        getAllAuctions()
    }, [])

    // useEffect(() => {
    //     // if (selectedAuction?.value) {
    //         // getSellerOverAllData(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value)
    //         getSellerByAuctionId(selectedAuction?.value)
    //         // paginateData([])
    //     // }
    // }, [selectedAuction])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerByAuctionId(selectedAuction?.value)
        }
    }, [selectedAuction]);

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
            setSellerOverallData([]);
            setSellerList([]);
            setSelectedSellers('')
        }
    },[hammerAuction])

    useEffect(() => {
        const handleKeyDown = (event:any) => {
          if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
            callApi();
          }
        };
    
        const callApi = async () => {
          try {
            setTimeout(() => {
              const printWindow:any = window.open('', '_blank');
              printWindow.document.write(printContent);
              printWindow.document.close();
              printWindow.print();
            }, 0);
          } catch (error) {
            console.error('Error calling API:', error);
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [printContent]);

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatNumber = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const exportToExcel = () =>{
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([]);
        const container:any = document.getElementById('pdfContent');

        function tableToSheet(table:any) {
            const headers = [];
            const data = [];

            const headerCells = table.rows[0].cells;
            for (let i = 0; i < headerCells.length; i++) {
                headers.push(headerCells[i].textContent.trim());
            }

            for (let i = 1; i < table.rows.length; i++) {
                const row = table.rows[i];
                const rowData = [];
                for (let j = 0; j < row.cells.length; j++) {
                    rowData.push(row.cells[j].textContent.trim());
                }
                data.push(rowData);
            }

            return [headers, ...data];
        }
        const sellerSummaries = container.querySelectorAll('table');
        sellerSummaries.forEach((table:any) => {
            if (table) {
                const sheetData = tableToSheet(table);
                XLSX.utils.sheet_add_aoa(ws, sheetData, { origin: -1 });
            }
            const nestedTables = table.querySelectorAll('table');
            nestedTables.forEach((nestedTable:any) => {
                if (nestedTable) {
                    const nestedSheetData = tableToSheet(nestedTable);
                    XLSX.utils.sheet_add_aoa(ws, nestedSheetData, { origin: -1 });
                }
            });
        });
        XLSX.utils.book_append_sheet(wb, ws, 'All Data');
        XLSX.writeFile(wb, 'seller_summary.xlsx');
    }
      
    let previousSellerId:any = null;

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between g-2 mt-1 align-items-end">
                            <div className="col-md-4 col-lg-3 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Auction"
                                    className="isActive swap-to-lot-menu clone-inventory"
                                    options={auctionList}
                                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                    value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                    onChange={(e: any) => {
                                        handleAuctionChange(e);
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Seller</label>
                                <Select
                                    // isMulti
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Sellers"
                                    className="isActive swap-to-lot-menu clone-inventory"
                                    options={sellerList}
                                    // value={sellerList.filter((option: any) => selectedSellers?.map((i: any) => { return i.value }).includes(option.value))}
                                    value={sellerList?.filter((option: any) => option.value == selectedSellers?.value)}
                                    onChange={(e: any) => {
                                        paginateData(e);
                                    }}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3 mt-2 ms-auto">
                                <div className="d-flex flex-wrap justify-content-end">
                                    {/* <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={exportToExcel}
                                        disabled={sellerOverallData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button> */}
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={()=> generateSellerOverallSummaryPdf(sellerOverallData)}
                                      disabled={sellerOverallData.length === 0}  
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4">
                            {
                                sellerOverallData.length === 0 ? 
                                getNoDataView():
                                <>
                                    <table className="w-100 mb-2" style={{ border: 0, verticalAlign: 'top' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                                <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Seller Summary Report</p>
                                                <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                <span>  {`Auction #${
                                                    selectedAuction 
                                                    ? selectedAuction?.label?.split('-')[0]
                                                    : auctionCode 
                                                        ? hammerAuction 
                                                        : hammerAuction?.label?.split('-')[0]
                                                }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                                </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                    <div id="pdfContent" ref={tableRef}>
                                    {sellerOverallData && sellerOverallData?.length > 0 && sellerOverallData?.map((item:any, index:any)=> {                                     
                                        const isPageBreak = previousSellerId !== null && previousSellerId !== item.sellerId;
                                        previousSellerId = item.sellerId;
                                        return (
                                            <React.Fragment key={index} >  
                                                {isPageBreak && (
                                                    <div className="pagebreak">
                                                        <img src={scissor} alt="" />
                                                    </div>
                                                )}
                                                <table width="100%" cellSpacing="0" cellPadding="0" style={{borderCollapse: "collapse",  marginBottom: "15px"}} >
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top" style={{width: "50%"}}>
                                                            <p style={{ fontSize: '12px', margin: 0, fontWeight: 'bold' }}>Auction Summary - Commission: Based per Item</p>
                                                            <p style={{ fontSize: '12px', margin: 0, fontWeight: 'bold' }}>Total Sales:...................................................${formatNumber(item.totalSoldPrice)}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Total Commission:....................................................${formatNumber(item.commTotal)}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Net Inventory Adjustments:........................................</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Net Seller Adjustments:.......................................${formatNumber(item.adjusts)}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Payments:.................................................................${formatNumber(item.payments)}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Sales Tax:...................................................................</p>
                                                            <p style={{ fontSize: '12px', margin: 0, fontWeight: 'bold' }}>Net Balance:.................................................${formatNumber(item.net)}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Total Passed:.............................................................${formatNumber(item.totalPassedPrice)}</p>
                                                            </td>
                                                            <td valign="top" style={{width: "20%"}}></td>
                                                            <td valign="top" style={{width: "30%"}}>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>Seller Number: {item.sellerId}</p>
                                                            <p style={{ fontSize: '12px', margin: 0, fontWeight:'bold' }}>{item.sellerName}</p>
                                                            <p style={{ fontSize: '12px', margin: 0,fontWeight:'bold' }}>{item.company}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>{item.addressLine1}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>{item.cityName} {item.stateName} {item.zipCode}</p>
                                                            <p style={{ fontSize: '12px', margin: 0 }}>{formatPhoneNumber(item.mobile)}</p>
                                                            {/* <p style={{ fontSize: 9, margin: 0 }}>Commission: 0 or more @ 0.0%</p> */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <table cellPadding={0} cellSpacing={0}  width="100%" style={{borderCollapse: "collapse", marginBottom: "15px"}} >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: "12px", width: '5%', padding: "5px" }}><div>Lot #</div></th>
                                                            <th style={{ fontSize: '12px' }}><div>LotTitle</div></th>
                                                            <th style={{ fontSize: '12px', textAlign: 'center' ,padding: "5px"}}><div>Qty</div></th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', padding: "5px" }}><div>Price</div></th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', padding: "5px" }}><div>Ext Price</div></th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', padding: "5px" }}><div>Commission Rate</div></th>
                                                            <th style={{ fontSize: '12px', textAlign: 'right', padding: "5px" }}><div>Commission</div></th>
                                                        </tr>
                                                    </thead>

                                                    {item.soldCount > 0 &&      
                                                      <>
                                                       <thead>
                                                            <tr>
                                                                <th colSpan={2} style={{ fontSize: 11, backgroundColor: '#e1e1e1', fontWeight: 'bold', padding: "5px" }}><div>Sold ({item.soldCount})</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'center', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalSoldPrice)}</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalSoldCommission)}</div></th>
                                                            </tr>
                                                        </thead>
                                                        {item?.lstSellerSummary != null && item?.lstSellerSummary?.length > 0 &&
                                                        item.lstSellerSummary
                                                            .filter((data: any) => data.status === "Sold")
                                                            .map((data: any, key: any) => (
                                                                <React.Fragment key={key}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ fontSize: 11, padding: "5px" }}>
                                                                                <div>{data.lotNo}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, padding: "5px" }}>
                                                                                <div>{data.lotTitle.replace(/<[^>]*>?/gm, "")}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, textAlign: 'center', padding: "5px" }}>
                                                                                <div>{data.qty}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                                <div>${formatNumber(data.price)}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                                <div>${formatNumber(data.extPrice)}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                                <div>{data.commissionRate ? data.commissionRate : '-'}</div>
                                                                            </td>
                                                                            <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                                <div>${formatNumber(data.commission)}</div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontSize: 11, padding: "5px" }}>
                                                                                <div></div>
                                                                            </td>
                                                                            <td colSpan={5} style={{ fontSize: 11, padding: "5px" }}>
                                                                                Description: {data.lotDescription.replace(/<[^>]*>?/gm, "")} {data.vin1 ? `Vin1: ${data.vin1}` : ''}
                                                                            </td>
                                                                            <td style={{ fontSize: 11, padding: "5px" }}>
                                                                                <div></div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </React.Fragment>
                                                            ))
                                                    }
                                                      </>                                                                   
                                                    }                                                   

                                                    {item.passsCount > 0 &&  
                                                      <>                                                                          
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} style={{ fontSize: 11, backgroundColor: '#e1e1e1', fontWeight: 'bold', padding: "5px" }}><div>Passed ({item.passsCount})</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'center', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalPassedPrice)}</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalPassedCommission)}</div></th>
                                                            </tr>
                                                        </thead>
                                                        {item?.lstSellerSummary != null && item?.lstSellerSummary?.length>0 && item.lstSellerSummary .filter((data: any) => data.status === "Passed")
                                                            .map((data: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotNo}</div></td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotTitle.replace(/<[^>]*>?/gm, "")}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'center', padding: "5px" }}><div>{data.qty}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.price)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.extPrice)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                        <div>{data.commissionRate ? data.commissionRate : '-'}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.commission)}</div></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div></div></td>
                                                                    <td colSpan={5} style={{ fontSize: 11, padding: "5px" }}>Description: {data.lotDescription.replace(/<[^>]*>?/gm, "")} {data.vin1 ? `Vin1: ${data.vin1}` : ''}</td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div></div></td>
                                                                </tr>
                                                            </tbody>
                                                            </React.Fragment>
                                                        ))}
                                                     </>  
                                                    }                                                   

                                                    {item.buyBackCount > 0 &&     
                                                      <>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} style={{ fontSize: 11, backgroundColor: '#e1e1e1', fontWeight: 'bold', padding: "5px" }}><div>BuyBack ({item.buyBackCount})</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'center', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalBuyBackPrice)}</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalBuyBackCommission)}</div></th>
                                                            </tr>
                                                        </thead>                                                       
                                                        {item?.lstSellerSummary != null && item?.lstSellerSummary?.length>0 && item?.lstSellerSummary?.filter((data: any) => data.status === "BuyBack").map((data: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotNo}</div></td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotTitle.replace(/<[^>]*>?/gm, "")}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'center', padding: "5px" }}><div>{data.qty}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.price)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.extPrice)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                        <div>{data.commissionRate ? data.commissionRate : '-'}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.commission)}</div></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: 11 }}><div></div></td>
                                                                    <td colSpan={5} style={{ fontSize: 11 }}>Description: {data.lotDescription.replace(/<[^>]*>?/gm, "")} {data.vin1 ? `Vin1: ${data.vin1}` : ''}</td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div></div></td>
                                                                </tr>
                                                            </tbody>
                                                            </React.Fragment>
                                                        ))}
                                                      </>                                                                 
                                                    }

                                                    {item.nonPayerCount > 0 &&     
                                                      <>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} style={{ fontSize: 11, backgroundColor: '#e1e1e1', fontWeight: 'bold', padding: "5px" }}><div>Non-Payer ({item.nonPayerCount})</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'center', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalNonPayerPrice)}</div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div></div></th>
                                                                <th style={{ fontSize: 11, backgroundColor: '#e1e1e1', textAlign: 'right', padding: "5px" }}><div>${formatNumber(item.totalNonPayerCommission)}</div></th>
                                                            </tr>
                                                        </thead>                                                       
                                                        {item?.lstSellerSummary != null && item?.lstSellerSummary?.length>0 && item?.lstSellerSummary?.filter((data: any) => data.status === "NonPayer").map((data: any, key: any) =>(
                                                            <React.Fragment key={key}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotNo}</div></td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div>{data.lotTitle.replace(/<[^>]*>?/gm, "")}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'center', padding: "5px" }}><div>{data.qty}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.price)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.extPrice)}</div></td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}>
                                                                        <div>{data.commissionRate ? data.commissionRate : '-'}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: 11, textAlign: 'right', padding: "5px" }}><div>${formatNumber(data.commission)}</div></td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontSize: 11 ,padding: "5px"}}><div></div></td>
                                                                    <td colSpan={5} style={{ fontSize: 11,padding: "5px" }}>Description: {data.lotDescription.replace(/<[^>]*>?/gm, "")} {data.vin1 ? `Vin1: ${data.vin1}` : ''}</td>
                                                                    <td style={{ fontSize: 11, padding: "5px" }}><div></div></td>
                                                                </tr>
                                                            </tbody>
                                                            </React.Fragment>
                                                        ))}
                                                      </>                                                                 
                                                    }                                                    
                                                </table>

                                                <table width="100%" cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse',  marginBottom: "15px" }}>
                                                <tbody>
                                                <tr>
                                                    <td valign="top" width="50%">
                                                    <table width={"100%"} cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse', border: '1px solid #e1e1e1', marginBottom: '30px' }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold', padding: "5px" }} colSpan={2}>Seller Charges/Credits/Adjustments</th>
                                                                <th style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', padding: "5px" }}>Amount</th>
                                                                <th style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', padding: "5px" }}>Tax</th>
                                                            </tr>
                                                        </thead>
                                                        {item?.lstSellerSummaryAdjust != null && item?.lstSellerSummaryAdjust?.length>0 && item?.lstSellerSummaryAdjust?.map((adjust:any, key:any)=>(
                                                            <React.Fragment key={key}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'left', fontSize: '11px',padding: "5px" }}>{adjust.description}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'center', fontSize: '11px', padding: "5px" }}>{adjust.stringchgCr}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(adjust.amount)}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(adjust.tax)}</td>
                                                                </tr>
                                                            </tbody>
                                                            </React.Fragment>
                                                        ))}
                                                    </table>

                                                    <table width="100%" cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse', border: '1px solid #e1e1e1', marginBottom: '30px' }}>
                                                        <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'bold', padding: "5px" }} colSpan={2}>Seller Payments Paid or Rec'd</th>
                                                            <th style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', padding: "5px" }}>Paid</th>
                                                            <th style={{ textAlign: 'right', fontSize: '12px', fontWeight: 'bold', padding: "5px" }}>Received</th>
                                                        </tr>
                                                        </thead>
                                                        {item?.lstSellerSummaryPayment !== null && item?.lstSellerSummaryPayment?.length > 0 && item?.lstSellerSummaryPayment?.map((payment:any, key:any)=>(
                                                            <React.Fragment key={key}>
                                                            <tbody>
                                                                 <tr>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'left', fontSize: '11px',padding: "5px" }}>{payment.payType}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'center', fontSize: '11px',padding: "5px" }}>{moment(payment.date).format('MM/DD/YYYY')}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'right', fontSize: '11px',padding: "5px" }}>${formatNumber(payment.amtPaid)}</td>
                                                                    <td style={{ border: '1px solid #e1e1e1', textAlign: 'right', fontSize: '11px',padding: "5px" }}>${formatNumber(payment.amtReceived)}</td>
                                                                </tr>
                                                            </tbody>
                                                            </React.Fragment>
                                                        ))}
                                                    </table>
                                                    <table width="100%" cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse', marginBottom: '30px' }}>
                                                    <tbody>
                                                            {item?.lstSellerNotes?.length > 0 && (
                                                                <tr>
                                                                    <td style={{ border: '0', padding: '5px' }}>
                                                                        <p style={{ 
                                                                            // backgroundColor: 'yellow', 
                                                                            fontSize: '11px', 
                                                                            padding: '10px', 
                                                                            margin: '0', 
                                                                            whiteSpace: 'pre-line',
                                                                            display: 'inline-block', 
                                                                            textIndent: '0', 
                                                                            paddingLeft: '0',
                                                                        }}>
                                                                            {item?.lstSellerNotes?.map((note: any, index: number) => note?.description).join('\n')}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>    
                                                    <table width="100%" cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse', marginBottom: '30px' }}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{ border: '0', padding: "5px" }}>
                                                            {/* <p style={{ fontSize: '11px' }}>Sellers Insurance changed from 3% to 0%, due to description not getting changed, per Jamie.</p> */}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                    <td valign="top" width="20%"></td>
                                                    <td valign="top" width="30%">
                                                    <table cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
                                                        <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Total Sales</td>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(item.totalSoldPrice)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Total Commissions</td>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(item.commTotal)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Sales Tax - Commissions</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Net Inventory Adjustments</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Sales Tax - Inv Adjusts</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Net Seller Adjustments</td>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(item.adjusts)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Sales Tax - Seller Adjusts</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '12px' }}>Sales Tax Total</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontSize: '12px', padding: "5px" }}>Payments to/from Seller</td>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(item.payments)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '12px' }}>Amount Due from Seller</td>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', padding: "5px" }}>${formatNumber(item.net)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                </table> 
                                            </React.Fragment>
                                        )
                                    })}
                                    </div>
                                </>
                            }                          
                        </div>                           
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerOverAllReport