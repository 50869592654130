import React, { useEffect, useState } from "react"
import Sidebar from "../Sidebar/Sidebar"
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import jwt_decode from "jwt-decode";
import { GETBPSALESTAX, CREATEBPSALESTAX, UPDATEBPSALESTAX, GETSTATECOUNTRYWISE } from "../../common/apiConstatnts"
import { toast } from "react-toastify";
import axios from "axios"
import { Link } from "react-router-dom"
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy"
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime"
import { BuyerPremiumSalesTaxMessage, CommonMessages, errorMessages } from "../../common/messageConstants"
import Accordion from 'react-bootstrap/Accordion';
import handleError from "../../Utils/ApiCommonErrorMessages"
import CommonHelmet from "../../common/HelmetWrapper"


const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'BP Sales tax', url: '' }
]

const BuyerPremiumSalesTax = () => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const superAdminRole = decodedToken.isDefaultRole;
    const [loading, setLoading] = useState<boolean>(false)
    const [isValid, setIsValid] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('')
    const [selectedStates, setSelectedStates] = useState<any>({})
    const [allTestStates, setAllTestStates] = useState<any>([])
    const [originalData, setOriginalData] = useState<any>([])
    const [newChecks, setNewChecks] = useState<any[]>([])
    const [globalChecks, setGlobalChecks] = useState<any>({ isBuyerPremium: false, isApplyOnTaxExempt: false, isApplyToAllStates: false })
    const [modifiedBy, setModifiedBy] = useState('')
    const [searchText, setSearchText] = useState('')
    const [selectedCountry, setSelectedCountry] = useState<any>({})
    const [countrySate, setCountryState]= useState<any>(false);


    // const handleChange = (e: any, stateId: any, countryId?: number) => {
    //     if (stateId == 'select_all') {
    //         const checked = e.target.checked
    //         let updatedCheck: any = [...newChecks]
    //         let countryState: any
    //         const updateNewChecks = allTestStates.map((cData: any) => {
    //             if (cData.objectValueId == countryId) {
    //                 countryState = cData?.lstStateName?.map((i: any) => i.objectValueId.toString())
    //                 if (checked == true) {
    //                     return {
    //                         ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                             if (!(updatedCheck.includes(sData.objectValueId.toString()))) {
    //                                 updatedCheck.push(sData.objectValueId.toString())
    //                             }
    //                             return sData.objectValueId.toString()
    //                         })
    //                     }
    //                 } else {
    //                     return {
    //                         ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                             updatedCheck = updatedCheck.filter((i: any) => i != sData.objectValueId.toString())
    //                             return sData.objectValueId.toString()
    //                         })
    //                     }
    //                 }
    //             }
    //             return cData
    //         })
    //         setNewChecks(updatedCheck)
    //         const updateStates = allTestStates?.map((cData: any) => {
    //             if (cData.objectValueId == countryId) {
    //                 return {
    //                     ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {

    //                         return { ...sData, isChecked: (checked === true) ? true : false }
    //                     })
    //                 }
    //             }
    //             return cData
    //         })

    //         const updateOriginal = originalData?.map((cData: any) => {
    //             if (cData.objectValueId == countryId) {
    //                 return {
    //                     ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {

    //                         return { ...sData, isChecked: (checked === true) ? true : false }
    //                     })
    //                 }
    //             }
    //             return cData
    //         })
    //         setAllTestStates(updateStates)
    //         setOriginalData(updateOriginal)
    //     }
    //     else {
    //         let newData: any = []
    //     if (e.target.checked) {
    //         newData = [...newChecks, stateId.toString()]
    //         setNewChecks(newData)
    //     } else if (newChecks.includes(stateId.toString())) {
    //         newData = newChecks.filter((data) => data !== stateId.toString())
    //         setNewChecks(newData)
    //     }
    //         let countryState: any = []
    //     const updateState = allTestStates.map((cData: any) => {
    //         setSelectedCountry(cData)
    //         if (cData.objectValueId == countryId) {
    //             countryState = cData?.lstStateName?.map((i: any) => i.objectValueId.toString()).filter((i: any) => i != 'select_all')
    //             const upData = {
    //                 ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                     if (sData.objectValueId === stateId) {
    //                         return { ...sData, isChecked: e.target.checked }
    //                     }
    //                     return sData
    //                 })
    //             }
    //             if (countryState.every((el: any) => newData.includes(el))) {
    //                 return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: true }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //             }
    //             return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: false }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //         }
    //         return cData
    //     })

    //     const updateOriginal = originalData.map((cData: any) => {
    //         if (cData.objectValueId == countryId) {
    //             countryState = cData?.lstStateName?.map((i: any) => i.objectValueId.toString()).filter((i: any) => i != 'select_all')
    //             const upData = {
    //                 ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                     if (sData.objectValueId === stateId) {
    //                         return { ...sData, isChecked: e.target.checked }
    //                     }
    //                     return sData
    //                 })
    //             }
    //             if (countryState.every((el: any) => newData.includes(el))) {
    //                 return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: true }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //             }
    //             return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: false }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //         }
    //         return cData
    //     })
    //     setAllTestStates(updateState)
    //     setOriginalData(updateOriginal)
    //     }
    // }

    // const handleSelectDeselectAll = (type: string) => {
    //     let updatedCheck: any = []
    //     // const updateNewChecks = (type === 'all') ? allStates.map((data: any) => { return data.objectValueId.toString() }) : []
    //     const updateNewChecks = (type === 'all') ? allTestStates.map((cData: any) => {
    //         return {
    //             ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                 updatedCheck.push(sData.objectValueId.toString())
    //                 return sData.objectValueId.toString()
    //             })
    //         }
    //     }) : []
    //     setNewChecks(updatedCheck)

    //     const updateStates = allTestStates?.map((cData: any) => {
    //         return {
    //             ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {

    //                 return { ...sData, isChecked: (type === 'all') ? true : false }
    //             })
    //         }
    //     })

    //     setAllTestStates(updateStates)
    //     setOriginalData(updateStates)
    // }

    const handleGlobalChecks = (e: any, type: string) => {
        const updateGlobalChecks = { ...globalChecks, [type]: e.target.checked }
        setGlobalChecks(updateGlobalChecks)
    }

    // const handleSearch = (e: any) => {
    //     const keyword = e?.toLocaleLowerCase()
    //     if (keyword === '') {
    //         setAllTestStates(originalData)
    //         return
    //     }
    //     let countryState: any
    //     const filterData = originalData.filter((cData: any) => {
    //         if (cData?.lstStateName?.filter((i: any) => i?.stateName !== 'Select All').map((state: any) => { return state?.stateName?.toLocaleLowerCase()?.includes(keyword) }).includes(true)) {
    //             return cData
    //         }
    //         return null
    //     }).map((fdata: any) => {
    //         countryState = fdata?.lstStateName?.map((i: any) => i.objectValueId.toString()).filter((i: any) => i != 'select_all')
    //         let upData = {
    //             ...fdata, lstStateName: fdata.lstStateName?.map((state: any) => {
    //                 if (state?.stateName?.toLocaleLowerCase()?.includes(keyword)) {
    //                     return state
    //                 }
    //             }).filter((i: any) => i)
    //         }

    //         if (countryState?.every((el: any) => newChecks.includes(el))) {
    //             return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: true }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //         }
    //         return { ...upData, lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: false }, ...upData?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')] }
    //     })

    //     setAllTestStates(filterData)
    // }   

    const onSubmit = () => {
        setCountryState(true);
        // if (isValid === false) {
        //     toast.error(errorMessages.selectOneState, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 2000,
        //     })
        //     return
        // }
    setLoading(true);
    if(superAdminRole==="True"){
        const url = (mode === 'create') ? CREATEBPSALESTAX : UPDATEBPSALESTAX
        const messsage = (mode === 'create') ? BuyerPremiumSalesTaxMessage.bpSaleCreated : BuyerPremiumSalesTaxMessage.bpSaleUpdated;
        let getIds: any = []
        const updateNewChecks = originalData.map((cData: any) => {
            return {
                ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
                    if (sData.isChecked === true) {
                        getIds.push(sData.objectValueId.toString())
                    }
                    return sData.objectValueId.toString()
                })
            }
        })
        getIds = getIds.filter((i: any) => i != 'select_all').join(',')
        const payload = { ...globalChecks, 
            stateTaxCodeId: 0, 
            isActive: true, companyId: localStorage.getItem('companyId') }
        axios.post(url, payload, config).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(messsage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
            setSearchText('')
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }
    else{
        setLoading(false);
        toast.error("Only super admin has permission to perform this operation on the page.",{
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
      });
      }
    }

    // const getStatesCountryWise = () => {
    //     axios.get(`${GETSTATECOUNTRYWISE}?companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
    //         setLoading(false);
    //         if (response?.data?.success === "Fail") {
    //             toast.error(response?.data?.message, {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //             });
    //             return;
    //         }
    //         else {
    //             const data = response.data.data;
    //             let getSelectedState: any
    //             if (newChecks?.length === 0) {
    //                 getSelectedState = selectedStates.stateTaxCodeId?.split(',') ?? []
    //                 setNewChecks(getSelectedState)
    //             } else {
    //                 getSelectedState = newChecks
    //             }                

    //             const modifyData = data.map((cData: any) => {
    //                 let countryState = cData?.lstStateName?.map((i: any) => i.objectValueId.toString()).filter((i: any) => i != 'select_all')
    //                 let upCdata: any = {
    //                     ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {

    //                         if (getSelectedState?.includes(sData.objectValueId.toString())) {
    //                             return { ...sData, isChecked: true }
    //                         }
    //                         return { ...sData, isChecked: false }
    //                     })
    //                 }

    //                 if (countryState.every((el: any) => getSelectedState.includes(el))) {
    //                     return {
    //                         ...upCdata,
    //                         lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: true }, ...upCdata?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')]
    //                     }
    //                 }
    //                 return {
    //                     ...upCdata,
    //                     lstStateName: [{ stateName: 'Select All', objectValueId: 'select_all', isChecked: false }, ...upCdata?.lstStateName?.filter((i: any) => i?.objectValueId != 'select_all')]
    //                 }
    //             })

    //             setAllTestStates(modifyData)
    //             setOriginalData(modifyData)
    //         }
    //     }, (error) => {
    //         setLoading(false)
    //         if (error.response.status !== 401) {
    //             toast.error(errorMessages.error401, {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //             });
    //         }
    //     })
    // }

   const newdata = () => {
        setLoading(true)
        axios.get(GETBPSALESTAX, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data;
                setModifiedBy(response.data.data.modifiedBy);
                const mode = (data.bpSalesTaxId === 0) ? 'create' : 'update';
                setMode(mode);
                setGlobalChecks({ bpSalesTaxId: data.bpSalesTaxId ,isBuyerPremium: data.isBuyerPremium, isApplyOnTaxExempt: data.isApplyOnTaxExempt, isApplyToAllStates: data.isApplyToAllStates })
                setSelectedStates(data);
                setCountryState(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }

    // useEffect(() => {
    //     if (Object.keys(selectedStates)?.length > 0) {
    //         getStatesCountryWise()
    //         // newdata()
    //     }
    // }, [selectedStates])

    useEffect(() => {
       newdata()        
    }, [countrySate])


    // useEffect(() => {
    //     let checkValid: any = []
    //     const updateNewChecks = originalData.map((cData: any) => {
    //         return {
    //             ...cData, lstStateName: cData?.lstStateName.map((sData: any) => {
    //                 if (sData.isChecked === true) {
    //                     checkValid.push(sData.objectValueId.toString())
    //                 }
    //                 return sData.objectValueId.toString()
    //             })
    //         }
    //     })
    //     // const checkValid = originalData.filter((data: any) => data.isChecked === true);
    //     const setBool = checkValid?.length > 0 ? true : false;
    //     setIsValid(setBool);
    // }, [originalData])

    // useEffect(() => {
    //     handleSearch(searchText);
    // }, [searchText])

    return (
        <>
        <CommonHelmet title={"BP Sales Tax - "} name={"bpSalesTax"} content={"BP Sales Tax"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row g-0">
                        <div className="col-12">
                            <div className="  bg-gradiant align-items-center justify-content-between ps-3 pe-3 pt-3 pb-3 custom_border">
                                <div className="col-auto">
                                    <h4 className="mb-0">BP Sales Tax</h4>
                                </div>
                            </div>
                            <div className="row top_border justify-content-center">
                                <div className="col-lg-12 pt-4 pb-4">
                                    <fieldset>
                                        <h5>Buyers Premium Sales Tax Defaults - Applied to New Auctions</h5>
                                        <div className="row mt-4 mb-1">
                                            <label className="col-form-label fw-400 col-12 pt-0">Select states to apply Sales Tax on Buyers Premium</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="form-check mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        id="isBuyerPremium"
                                                        type="checkbox"
                                                        name="isBuyerPremium"
                                                        checked={globalChecks.isBuyerPremium === true}
                                                        onChange={(e) => handleGlobalChecks(e, 'isBuyerPremium')}
                                                    />
                                                    <label className="form-check-label" htmlFor="isBuyerPremium">Buyers Premium is</label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        id="isApplyOnTaxExempt"
                                                        type="checkbox"
                                                        name="isApplyOnTaxExempt"
                                                        checked={globalChecks.isApplyOnTaxExempt === true}
                                                        onChange={(e) => handleGlobalChecks(e, 'isApplyOnTaxExempt')}
                                                    />
                                                    <label className="form-check-label" htmlFor="isApplyOnTaxExempt">Apply BP Tax on Tax Exempt</label>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="form-check mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        id="isApplyToAllStates"
                                                        type="checkbox"
                                                        name="isApplyToAllStates"
                                                        checked={globalChecks.isApplyToAllStates === true}
                                                        onChange={(e) => handleGlobalChecks(e, 'isApplyToAllStates')}
                                                    />
                                                    <label className="form-check-label" htmlFor="isApplyToAllStates">Apply BP Tax to ALL States (overrides selections)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {/* country state logic field remove. */}
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <div className="d-flex flex-wrap align-items-center justify-content-start">
                                                <div className="col-auto mt-2 mb-2">
                                                    <div className="search-box d-flex align-items-center">
                                                        <div className="position-relative" data-bs-toggle="search" data-bs-display="static">
                                                            <input
                                                                className="form-control search-input search form-control-sm"
                                                                type="search"
                                                                placeholder="Search State"
                                                                aria-label="Search"
                                                                value={searchText} onChange={(e: any) => setSearchText(e.target.value)}
                                                            />
                                                            <span className="fas fa-search search-box-icon"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto mt-2 mb-2">
                                                    <div className="buttons ms-3">
                                                        <button type="button" className="btn btn-outline-secondary me-2" onClick={() => handleSelectDeselectAll('all')}>Select All</button>
                                                        <button type="button" className="btn btn-outline-secondary" onClick={() => handleSelectDeselectAll('clear')}>Deselect All</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <span style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please select atleast one state.</i></span>
                                    </div>
                                    <div className="row g-3 pb-5">
                                        {allTestStates.length > 0 ?
                                            allTestStates?.map((data: any, index: any) => (
                                                <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6'>
                                                    <div className={`card h-100 bg-light p-3 flag-icon border ${data?.countryName}`}>
                                                        <h5 className='d-flex align-items-center'>
                                                            {data?.countryName}
                                                        </h5>
                                                        <ul className="label_with_checkbox scrollbar">
                                                            {data?.lstStateName?.length > 0 ?
                                                                data?.lstStateName?.map((stateData: any, index: any) => (
                                                                    <li className="co-count">
                                                                        <div className="form-check d-flex align-items-center">
                                                                            <input className="form-check-input form-check-states me-2 mb-1" type="checkbox" name="states"
                                                                                id={`${stateData.objectValueId}_${data?.countryName}`}
                                                                                checked={stateData.isChecked === true}
                                                                                onChange={(e) => handleChange(e, stateData.objectValueId, data?.objectValueId)} />
                                                                            <label className="form-check-label text-900 fw-400" htmlFor={`${stateData.objectValueId}_${data?.countryName}`}>{stateData.stateName}</label>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                                : (
                                                                    <div className="col-12">
                                                                        <p className="text-center mt-3 mb-3">No state found</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            )) : (
                                                <div className="col-12">
                                                    <p className="text-center mt-3 mb-3">No state found</p>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                            </div> */}
                            <div className="col-12 ">
                                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                    <Link
                                        to={routeConstant.DASHBOARD}
                                        className="btn btn-outline-danger me-auto"
                                        type="button"
                                        data-wizard-prev-btn="data-wizard-prev-btn"
                                    >
                                        {CommonMessages.cancelButton}
                                    </Link>
                                    <ModifiedBy data={selectedStates} />
                                    <LastModified data={selectedStates} />
                                    <button
                                        className="btn btn-primary me-1 mb-1"
                                        onClick={onSubmit}
                                    >
                                        {CommonMessages.saveButton}
                                    </button>
                                </div>
                            </div>            
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default BuyerPremiumSalesTax
