import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as yup from 'yup';
import Swal from "sweetalert2";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import { GETSALESPERAUCTIONCONTACTINFO, GETCOMMONVALUE, UPDATESALESREPAUCTIONCONTACTINFO } from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { wordCounter } from "../../../../common/constants";
import { CommonMessages, SalesRepMessages } from "../../../../common/messageConstants";
import { AlPHANUMERIC, COMPANYNAMEREGEX, FIRSTANDLASTNAMEREGEX, SPECIALCHARS } from "../../../../common/regexConstants";
import { ActiveStep, loader, SalesRepContact, setAuctionInfoActiveTab } from "../../../../store/actions/SalesRepAction";
import { ERROR_MESSAGES } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const AuctionContactInfo = () => {
    const dispatch = useDispatch()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const initalData = {
        saleRepId: "",
        companyId: localStorage.getItem('companyId'),
        initialsId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        title: "",
        latitude: '',
        longitude: '',
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        codes: "",
        notes: "",
        saleRepTypeId: "",
        isActive: true,
        add1: "",
        add2: "",
        city1: "",
        state1: "",
        country1: "",
        zip: "",
        county1: '',
        isdefault: false,
    }
    const selectedAuction = useSelector((state: any) => state?.salesRep?.selectedAuction);
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const auctionId = selectedAuction?.value ? selectedAuction?.value : getGlobalAuctionIds
    const [initialFormValue, setFormValue] = useState<any>(initalData)
    const contactData = useSelector((state: any) => state?.salesRep?.contact);
    const saleRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const [salesRepType, setSalesRepType] = useState<[]>([])
    const maxWordCount = 150
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [address, setAddress] = useState('');
    // const [initials, setInitials] = useState([]);

    const [countryCode, setCountryCode] = useState('us');

    const generatePhoneValidationSchema = (countryCode: any) => {
        return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
            if (!value) return true;
            try {
                const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false; // Invalid phone number format
                }
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
                const expectedLength = value.length - countryCodeLength;
                return nationalNumber.toString().length === expectedLength;
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return false; // Return false if an error occurs during parsing
            }
        });
    };

    const schema: any = yup.object().shape({
        // initialsId: yup.string().required("Please select initials."),
        saleRepTypeId: yup.string().required("Please select Sales Rep Type."),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),
        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),

        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        company: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name'),
        title: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid Title.').max(150, "Please enter upto 150 characters."),
        zip: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        mobile: generatePhoneValidationSchema(countryCode).required('Phone number is required.'),
        phone: generatePhoneValidationSchema(countryCode).nullable(),
        fax: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(/^(\d{10})*$/, 'Please enter number with 10 digits'),
        email: yup.string().required("Email address is required.")
            .test("is-email", "Email must be a valid email.", (value) => {
                if (!value) {
                    return true;
                }
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
            }).max(50, "Please enter upto 50 characters."),
        add1: yup.string().required("Address is required.").matches(SPECIALCHARS, 'Please enter valid address'),
        paymentTaxExempt: yup.boolean(),
        city1: yup.string().required('City is required.'),
        state1: yup.string().required('State is required.'),
        country1: yup.string().required('Country is required.'),
        codes: yup.string().nullable().max(150, "Please enter upto 150 characters."),
        notes: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
    })

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        clearErrors,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' });

    const saleRepTypeId = watch('saleRepTypeId')

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
        } else if ((e.target.name === 'location')) {
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
        }
    }
    const handleUpdateDefault = () => {
        const e = getValues();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to update this information in the master contact & default info as well',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                onSubmit(e, true);
                // return
            }
        });
    };
    const onSubmit = (data: any, isdefault: boolean = false) => {
        dispatch(loader(true));
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const newData = { ...data, salesRepId: saleRepId, companyId: localStorage.getItem('companyId') }
            const payload = {
                ...newData,
                // saleRepId: saleRepId,
                addressLine1: data?.add1,
                address: data?.add1,
                addressLine2: data?.address2,
                country: parseInt(countryId),
                state: parseInt(stateId),
                city: parseInt(cityId),
                zipCode: data?.zip,
                fax: data?.fax?.toString(),
                latitude: data?.latitude?.toString(),
                longitude: data?.longitude?.toString(),
                isActive: data?.isActive,
                isDelete: false,
                county: parseInt(countyId),
                countyName: data?.county1,
                isdefault: isdefault
            }
            const Message = ID ? SalesRepMessages.salesRepUpdated : SalesRepMessages.salesRepCreated;
            axios.post(UPDATESALESREPAUCTIONCONTACTINFO, payload)
                .then((response) => {
                    dispatch(loader(false));
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return
                    }
                    else {
                        toast.success(Message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        })
                        handleNext()
                    }
                    if (isdefault === true) {
                        dispatch(SalesRepContact({ ...payload, saleRepId: payload?.salesRepId }))
                    }
                    dispatch(loader(false));
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error);
                });
        })
    }

    const handlePrevious = () => {
        dispatch(ActiveStep('contact'))
    }
    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('inventory'))
    }

    const getSalesRepAuctions = (aucId: any) => {
        axios.get(`${GETSALESPERAUCTIONCONTACTINFO}?salesRepId=${saleRepId}&auctionId=${aucId}`).then((response) => {
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                reset(initalData)
                return;
            }
            else {
                const contactData = response?.data?.data;
                reset(contactData);
                setValue('saleRepTypeId', contactData?.saleRepTypeId)
                setAddress(contactData?.address)
                setValue('add1', contactData?.address)
                setValue('city1', contactData?.cityName)
                setValue('state1', contactData?.stateName)
                setValue('country1', contactData?.countryName)
                setValue('zip', contactData?.zipCode)
                setValue('county1', contactData?.countyName)
                setValue('mobile', contactData?.mobile)
                setValue('phone', contactData?.phone)
            }
        }).catch((error) => {
            dispatch(loader(false))
            handleError(error);
        })
    }

    useEffect(() => {
        // axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`).then((response) => {
        //     if (response?.data?.success === "Fail") {
        //         toast.error(response.data.message, {
        //             position: toast.POSITION.TOP_RIGHT,
        //             autoClose: 2000,
        //         });
        //         return;
        //     }
        //     else {
        //         let formateData = response.data.data?.map((data: any, index: any) => {

        //             return {
        //                 label: data.objectTypeValue, value: data.objectValueId
        //             }
        //         })
        //         setInitials(formateData)

        //         if (contactData.initialsId !== "") {
        //             setValue('initialsId', contactData.initialsId)
        //         }
        //     }
        // }).catch((error) => {
        //     dispatch(loader(false));
        //     handleError(error);
        // });

        axios.get(`${GETCOMMONVALUE}?filter=SalesRepType&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setSalesRepType(formateData)
                if (contactData?.saleRepTypeId !== undefined || contactData?.saleRepTypeId !== "") {
                    setValue('saleRepTypeId', contactData.saleRepTypeId)
                    return
                }
                setValue('saleRepTypeId', "")
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
    }, [])

    useEffect(() => {
        if (auctionId) {
            getSalesRepAuctions(auctionId);
        }

    }, [auctionId, saleRepId]);



    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-xxl-10">
                        <div className="mt-4 mb-2 border-300">
                            <h4>Contact Info</h4>
                        </div>
                        <form onSubmit={handleSubmit((e: any) => onSubmit(e, false))}>
                            <div className="row g-3 mb-2">
                                {/* <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Initials</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Initials"
                                        options={initials}
                                        value={initials?.find((option: any) => option?.value == initialsId)}
                                        onChange={(e: any) => setValue('initialsId', e.value)}
                                        className='isActive'
                                    />
                                    {errors.initialsId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.initialsId.message}</span>}
                                </div> */}
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                    <input type="text" {...register('firstName')} className="form-control" maxLength={20} placeholder="First Name" />
                                    {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input type="text" {...register('middleName')} className="form-control" maxLength={20} placeholder="Middle Name" />
                                    {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('lastName')} maxLength={20} placeholder="Last Name" />
                                    {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Sales Representative Type <sup className="text-danger">*</sup></label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Sales Representative Type"
                                        options={salesRepType}
                                        value={salesRepType?.find((option: any) => option?.value == saleRepTypeId)}
                                        onChange={(e: any) => setValue('saleRepTypeId', e.value)}
                                        className='isActive'
                                    />

                                    {errors.saleRepTypeId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.saleRepTypeId.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Company</label>
                                    <input type="text" className="form-control" {...register('company')} maxLength={100} placeholder="Company" />
                                    {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Title</label>
                                    <input className="form-control" type="text" {...register('title')} maxLength={151} placeholder="Title" />
                                    {errors.title && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.title.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('email')} maxLength={51} placeholder="Email" />
                                    {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                                </div>
                                <AddressForm
                                    errors={errors}
                                    onHandleChangeAddressLine={handleChangeAddressLine}
                                    register={register}
                                    setValue={setValue}
                                    setCountryCode={setCountryCode}
                                    addressFull={true}
                                    addressLine2={true}
                                    onSetAddress={setAddress}
                                    onAddressChange={() => { }}
                                    watch={watch}
                                    trigger={trigger}
                                />
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>

                                    <Controller
                                        {...register('mobile')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />

                                    {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Alternate Number </label>
                                    <Controller
                                        {...register('phone')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                </div>

                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Fax</label>
                                    <input type="text" className="form-control" {...register('fax')} maxLength={10} placeholder="Fax" />
                                    {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Codes</label>
                                    <input type="text" className="form-control" {...register('codes')} maxLength={50} placeholder="Codes" />
                                    {errors.codes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.codes.message}</span>}
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-label">Note</label>
                                    <textarea rows={3} className="form-control"
                                        maxLength={maxWordCount}
                                        {...register('notes', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                        placeholder="Note"
                                    />
                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}

                                    {errors.notes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.notes.message}</span>}


                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                                <div>
                                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                                </div>
                                <div className='d-flex mx-2'>
                                    <Link to="#" className="btn btn-outline-primary out-line-focus-none ps-3 pe-3" type="submit" onClick={handleSubmit(handleUpdateDefault)}>
                                        Update Default Info</Link>
                                    <button className="btn btn-primary ms-2" type="submit">{ID && (CommonMessages.updatedNext)}</button>
                                    {ID && <button className="btn btn-secondary ms-2" type="button" onClick={handleNext} disabled={saleRepId === 0}>{CommonMessages.skipButton}</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuctionContactInfo;