import axios from "axios";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Swal from 'sweetalert2';
import auctionImage from "../../../../assets/Group 70.svg";
import { GETAUCTIONSBYSELLER, GETSELLERAUCTIONTALLY } from "../../../../common/apiConstatnts";
import { decryptId } from "../../../../Encryption/Encryption";
import { selectAuction, setAuctionInfoActiveTab } from "../../../../store/actions/Seller";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { formatCurrency, formatNumber, isEmptyObject } from "../../../../Utils/Utils";
import Adjust from "../AuctionInfo/Adjust";
import Commision from "../AuctionInfo/Commission";
import InventoryAssociated from "../AuctionInfo/Inventory";
import AdjustPayLog from "./AdjustPayLog";
import AuctionSellerInfo from "./AuctionSellerInfo";
import Docs from './Docs';
import LogNotes from "./LogNotes";
import Payment from "./Payment";
import SalesInAuction from "./SalesInAuction";
import SalesTax from "./SalesTax";
import SellerNotes from "./SellerNotes";

const AuctionInfoTabs = () => {
    const query = window?.location?.href;
    const vars = query?.split("/");
    const ID = vars[5];
    const dispatch = useDispatch()
    // const auctionlistOptions = useSelector((state: any) => state.seller.sellerAuctions);
    const [loading, setLoading] = useState<boolean>(true);
    const [auctionTallyData, setAuctionTallyData] = useState<any>({});
    const [isFirstTimeRender, setFirstTimeRender] = useState(true)
    //used for getting auctionid and data selected from hammer
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);

    const [auctionlistOptions, setAuctionlistOptions] = useState<any>([]);

    const [selectedAuction, setSelectedAuction] = useState(getGlobalAuctionIds ? getGlobalAuction : auctionlistOptions?.[0]);
    const sellerData = useSelector((state: any) => state?.seller);
    const activeTab = sellerData?.AuctionInfoActiveTab;
    const sellerId = sellerData?.sellerId;
    const { inventoryLots, salesLots, sold, passes, buyBack, totalAmount } = auctionTallyData;
    const auctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);

    const handleChange = (key: string) => {
        dispatch(setAuctionInfoActiveTab(key));
    }

    const auctionSelectHandler = (e: any) => {
        setSelectedAuction(e);
        dispatch(selectAuction(e));
        getAuctionTallyData(e?.value, sellerId);
    }

    const getSellerAuctions = () => {
        // return
        axios.get(`${GETAUCTIONSBYSELLER}?sellerId=${ID ? decryptId(ID) : sellerData?.sellerId}`).then((response) => {
            setLoading(false);
            setFirstTimeRender(false)
            if (response?.data?.data?.success === "Fail") {
                // toast.error(response?.data?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const lotsData = response?.data?.data;
                let auctionlistOptions = lotsData?.map((i: any) => { return { label: `${i?.auctionCode} - ${i?.auctionTitle}`, value: i?.auctionId } })
                // dispatch(sellerAuctions(auctionlistOptions))
                setAuctionlistOptions(auctionlistOptions)
            }
        }).catch((error) => {
            setFirstTimeRender(false)
            setLoading(false);
            handleError(error);
        });
    }

    useEffect(() => {
        if (ID) {
            // setLoading(false);
            getSellerAuctions();
            return () => { }
        }
    }, [])

    useEffect(() => {

        if (!isEmptyObject(auctionDetail)) {
            const valueFound = auctionlistOptions?.find((i: any) => i?.value == auctionDetail?.auctionId)

            if (valueFound) {
                setSelectedAuction(valueFound);
                dispatch(selectAuction(valueFound));
            } else {
                if (auctionlistOptions?.length) {
                    Swal.fire({
                        text: 'Sorry, this seller is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }

        } else if (getGlobalAuctionIds) {
            const matches = auctionlistOptions?.filter((option: any) => {
                return option?.value == getGlobalAuctionIds;
            });
            //If the auction is in the - Auction dropdown, it will be selected by default. 
            //else show swal msg if there's any option in auction list
            if (matches?.length > 0) {
                setSelectedAuction(getGlobalAuction);
                dispatch(selectAuction(getGlobalAuction))
            } else {
                if (auctionlistOptions?.length) {
                    Swal.fire({
                        text: 'Sorry, this seller is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }
        } else if (auctionlistOptions && auctionlistOptions.length > 0) {
            const valueFound = auctionlistOptions[0]
            setSelectedAuction(valueFound);
            dispatch(selectAuction(valueFound))
        }
    }, [getGlobalAuctionIds, getGlobalAuction, auctionlistOptions, auctionDetail]);

    const getAuctionTallyData = (aucId: any, selId: any) => {
        if (aucId && selId) {
            setLoading(true);
            axios.get(`${GETSELLERAUCTIONTALLY}?auctionId=${aucId}&sellerId=${selId}`).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    setAuctionTallyData({});
                    return;
                }
                else {
                    const data = response.data.data ? response.data.data : {};
                    setAuctionTallyData(data);
                }
                setLoading(false)
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }

    }

    useEffect(() => {
        if (selectedAuction) {
            getAuctionTallyData(selectedAuction?.value, sellerId);
        }
    }, [selectedAuction]);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            {!isFirstTimeRender ? <div className="pt-0 top_border">
                {auctionlistOptions === undefined || auctionlistOptions?.length == 0 ? <>
                    <div className="d-flex align-items-center justify-content-center flex-wrap h-61vh verti_center">
                        <div className="auvtoin_immg">
                            <img src={auctionImage} />
                        </div>
                        <span className="d-block w-100 text-center mt-4 fs-20">Oops! This Seller has no auction data.</span>
                    </div>
                </> :
                    <>
                        <div className="col-12">
                            <div className="row pt-4 m-0 justify-content-center">
                                <div className="col-xxl-10">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-5 mb-2">
                                                    <label className="">Select Auction</label>
                                                    <Select classNamePrefix="dpaSelect" placeholder="Select Auction" className="w-100"
                                                        options={auctionlistOptions}
                                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                                        value={selectedAuction}
                                                        onChange={(e) => auctionSelectHandler(e)}
                                                    />
                                                </div>
                                                <div className="col-lg-7 mb-2">
                                                    <div className="auction_tally_box">
                                                        <label>Auction Tally</label>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                                                    <table className="table fs--1 mb-0 border border-200">
                                                                        <tbody className="">
                                                                            <tr>
                                                                                <th className="ps-2 pe-2 border">Inventory Lots</th>
                                                                                <th className="ps-2 pe-2 border">Total Lots from this seller</th>
                                                                                <th className="ps-2 pe-2 border">Sold</th>
                                                                                <th className="ps-2 pe-2 border">Passed</th>
                                                                                <th className="ps-2 pe-2 border">Buyback</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="ps-2 pe-2 border">{inventoryLots ?? '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{salesLots ? formatNumber(salesLots) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{sold ? formatNumber(sold) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{passes ? formatNumber(passes) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{buyBack ? formatNumber(buyBack) : '0'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="ps-2 pe-2 border text-end" colSpan={5}>Sold USD : <strong>{totalAmount ? formatCurrency(totalAmount) : '$0.00'}</strong> </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <InnerHeaderTab breadCrumb={breadcrumbData} title={ID ? 'Edit Seller' : 'New Seller'}> */}
                                    <Tabs
                                        defaultActiveKey={activeTab}
                                        activeKey={activeTab}
                                        className="nav-underline border-bottom"
                                        onSelect={(key: any) => handleChange(key)}
                                    >
                                        {ID && <Tab eventKey="contactInfo" title="Contact Info" disabled={(sellerId == undefined)}></Tab>}
                                        {ID && <Tab eventKey="inventory" title="Inventory" disabled={(sellerId == undefined)}></Tab>}
                                        <Tab eventKey="sales" title="Sales in Auction" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="tax" title="Sales Tax" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="commision" title="Commission" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="notes" title="DPA Notes" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="sellerNotes" title="Seller Notes" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="docs" title="Documents" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="adjust" title="Adjusts" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="payment" title="Payments" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                        <Tab eventKey="adpaylog" title="Adjusts/Payments Logs" disabled={selectedAuction == undefined || (sellerId == undefined)}></Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {/* </InnerHeaderTab > */}
                        {
                            (activeTab === 'contactInfo') ?
                                <AuctionSellerInfo /> :
                                (activeTab === 'inventory') ?
                                    <InventoryAssociated /> :
                                    (activeTab === 'sales') ?
                                        <SalesInAuction /> :
                                        (activeTab === 'adpaylog') ?
                                            <AdjustPayLog /> :
                                            (activeTab === 'notes') ?
                                                <LogNotes /> :
                                                (activeTab === 'sellerNotes') ?
                                                    <SellerNotes /> :
                                                    (activeTab === 'tax') ?
                                                        <SalesTax /> :
                                                        (activeTab === 'commision') ?
                                                            <Commision /> :
                                                            (activeTab === 'adjust') ?
                                                                <Adjust /> :
                                                                (activeTab === 'docs') ?
                                                                    <Docs />
                                                                    :
                                                                    <Payment />
                        }
                    </>}
            </div > : <></>}
        </>
    )
}

export default AuctionInfoTabs;