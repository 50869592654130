import * as action from '../../common/types'

export type Action = {
    type: string
    payload: string
}

export const loader = (data: any): Action => ({
    type: action.LOADING,
    payload: data
})

export const country = (data: any): Action => ({
    type: action.COUNTRY,
    payload: data
})

export const state = (data: any): Action => ({
    type: action.STATE,
    payload: data
})

export const city = (data: any): Action => ({
    type: action.CITY,
    payload: data
})

export const globalCategories = (data: any): Action => ({
    type: action.GLOBALCATEGORIES,
    payload: data
})

export const setNotifications = (data: any): Action => ({
    type: action.NOTIFICATIONS,
    payload: data
})

export const setNotificationCount = (data: any): Action => ({
    type: action.NOTIFICATIONCOUNT,
    payload: data
})

export const setSidebarOpen = (data: any): Action => ({
    type: action.SIDEBAROPEN,
    payload: data
})

export const setAddressTypes = (data: any): Action => ({
    type: action.ADDRESSSTYPES,
    payload: data
})

export const setRedirectedtoHeader = (data: any): Action => ({
    type: action.REDIRECTEDUSER,
    payload: data
})

export const setLoggedUserData = (data: any): Action => ({
    type: action.LOGGEDUSERDATA,
    payload: data
})

export const setAuctionHammerData = (data: any): Action => ({
    type: action.AUCTIONHAMMERDATA,
    payload: data
})