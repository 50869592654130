import React from "react";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import { Breadcrumb } from "../../../common/interface";
import Headers from "../../Header/Header";

const InnerHeaderTab = ({ breadCrumb, children, title }: { breadCrumb: Breadcrumb[], children: React.ReactNode, title: string }) => {
    return (
        <>
            <Headers />
            <nav aria-label="breadcrumb" className="navbar navbar-top border-bottom-0 navbar-expand bg-gradiant pt-2 pb-2" >
                <BreadCrumbs data={breadCrumb} />
            </nav>
            <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-12 bg-gradiant align-items-center justify-content-between p-3 pb-0 custom_border z-index-1">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="mb-0">{title}</h4>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            {children}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default InnerHeaderTab
