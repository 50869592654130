import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MomentUtil from '../../../../Utils/MomentUtil';
import { formatCurrency } from '../../../../Utils/Utils';
import { GETINVOICEINFOBASEDONAUCTION } from '../../../../common/apiConstatnts';
import { CommonMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionDetailActive } from '../../../../store/actions/AuctionAction';
import handleError from '../../../../Utils/ApiCommonErrorMessages';

const AuctionInvoice = () => {
    const dispatch = useDispatch();
    const getUrl = window.location.pathname
    const modeType = getUrl.split('/')[2]
    const [loading, setLoading] = useState(false);
    const [filterInvoice, setFilterInvoice] = useState([]);
    const [originalInvoices, setOriginalInvoices] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [invoiceData, setInvoiceData] = useState<[]>([]);
    const [searchText, setSearchText] = useState('')
    const auctionId: any = useSelector((state: any) => state.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const PER_PAGE = 10;
    const pageCount = Math.ceil(invoiceData?.length / PER_PAGE);
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...invoiceData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setInvoiceData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterInvoice(firstSet);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalInvoices?.filter((x: any) => x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) || x.company?.toLocaleLowerCase()?.includes(keyword) || x.email?.toLocaleLowerCase()?.includes(keyword) ||
            x.customerId?.toString()?.includes(keyword))
        paginateData(filterData);
        setCurrentPage(0);
    }

    const handlePrev = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionInventory" }));
    }

    const handleNext = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionSales" }))
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    // Use 725 auctionId for testing 
    const getAuctionInvoice = () => {
        setLoading(true);
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(GETINVOICEINFOBASEDONAUCTION, { ...config, params: { auctionId: aId } }).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setFilterInvoice([]);
                return;
            } else {
                const invoiceDataData = response?.data?.data;
                setOriginalInvoices(invoiceDataData);
                paginateData(invoiceDataData);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
           
        });
    }

    useEffect(() => {
        getAuctionInvoice();
    }, []);

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = invoiceData?.slice(offset, offset + PER_PAGE);
        setFilterInvoice(nextSet);
    }, [currentPage]);

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText]);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="row">
                <div className="col-md-6">
                    <h5 className='mt-4'>Auction Invoices</h5>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="search-box mt-2 p-2 ">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 border-300">
                        <div className="table-responsive mx-n1 px-1 scrollbar">
                            <table className="table fs--1 mb-0 border-top border-200">
                                <thead>
                                    <tr>
                                        <th className="bg-gradiant" scope="col" data-sort="invoiceMasterId">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Invoice #</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'invoiceMasterId')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'invoiceMasterId')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="verificationCode">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Pickup Number</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="customerId">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Bidder#</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'customerId')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'customerId')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="bidderName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Buyer Name</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'bidderName')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'bidderName')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="bidderName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Void</span>

                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="companyName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Company</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'companyName')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'companyName')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="email">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Email</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="date">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Created Date ({defaultTimezone})</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="buyersPrice">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Buyer Price</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'buyersPrice')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'buyersPrice')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="buyersPrice">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Payment Status</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="Privillege">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Balance Due</span>

                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="total">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Total</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'total')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'total')}></button>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-latest-review-body">
                                    {filterInvoice.length > 0 ? filterInvoice?.map((invoiceData: any, index: any) => {
                                        const createdDate = moment(momentUtil.parseTimeInUtc({ dateTimeString: invoiceData?.createdOn }))
                                        return (
                                            <tr
                                                className="hover-actions-trigger btn-reveal-trigger position-static"
                                                key={index}
                                            >
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.invoiceMasterId}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.pickupNumber ?? '-'}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.bidderNumer}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.customerName}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.isVoid === true
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.companyName ?? '-'}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {invoiceData?.email}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {createdDate.format("MM/DD/YYYY")}
                                                </td>
                                                {/* <td className="p-2 white-space-pre">{moment(invoiceData?.createdOn).format('MM/DD/YYYY').toString()}</td> */}
                                                <td className="p-2 white-space-pre">
                                                    {formatCurrency(
                                                        invoiceData?.buyerPrice
                                                    )}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    <div
                                                        className={
                                                            invoiceData?.status === false
                                                                ? "red_dot"
                                                                : "green_dot"
                                                        }
                                                    >
                                                        {invoiceData?.status === false
                                                            ? "Not paid"
                                                            : "Paid"}
                                                    </div>
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {formatCurrency(
                                                        invoiceData?.balanceDue
                                                    )}
                                                </td>
                                                <td className="p-2 white-space-pre">
                                                    {formatCurrency(
                                                        invoiceData?.total
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }) :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static text-center">
                                            <td className=' py-4' colSpan={12}>No records found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {filterInvoice.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}

export default AuctionInvoice;