// const baseUrl = 'http://20.118.160.165:8080/api'
const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

// for check with QA db.
// const baseUrl = `http://20.55.236.206:91/api`;
// const baseUrlCustomers = "http://20.55.236.206:92/api";
// export const base = "http://20.55.236.206:91";
// export const profileBase = "http://20.55.236.206:94";
// const sellerBase = "http://20.55.236.206:93/api";
// const baseUrlReports = 'http://20.55.236.206:90/api';

const baseUrlCustomers = "http://20.55.236.206:81/api";
export const base = "http://20.55.236.206";
export const profileBase = "http://20.55.236.206:83";
const sellerBase = "http://20.55.236.206:82/api";
const baseUrlReports = 'http://20.55.236.206:84/api';

//this is only for test purpose to check with QA db. make sure to recomment this.
// const baseUrl = `http://20.55.236.206:91/api`;
// const baseUrlCustomers = "http://20.55.236.206:92/api";
// export const base = "http://20.55.236.206:91";
// export const profileBase = "http://20.55.236.206:81";
// const sellerBase = "http://20.55.236.206:93/api";



// Customer portal Url for Bid Management - as per the changes Bid management api is transfer to customer portal as per ticket 3740 - backend
const customerPortalUrl = "http://20.55.236.206:83/api";

//encryption
export const SECRETKEY = "dpa-auction";

//Login
export const LOGIN = `${baseUrl}/Login/verifyuser`;

//passwords
export const FORGOTPASSWORD = `${baseUrl}/User/ForgotPassword`;
export const RESETPASSWORD = `${baseUrl}/User/ChangePassword`;

// Users
export const GETALLUSERS = `${baseUrl}/User/GetAll`;
export const CREATEUSER = `${baseUrl}/User`;
export const GETUSERBYID = `${baseUrl}/User/GetById`;
export const UPDATEUSER = `${baseUrl}/User/Update`;
export const DELETEUSER = `${baseUrl}/User/DeleteUser`;
export const ADVANCEFILTER = `${baseUrl}/User/AdvancedSerachFilter`;
export const USERUSINGBLOB = `${baseUrl}/User/UserUsingBlob`;
export const UPDATEUSINGBLOB = `${baseUrl}/User/UpdateUsingBlob`;
export const GETBYIDUSINGBLOB = `${baseUrl}/User/GetByIdUsingBlob`
// Roles
export const GETALLROLES = `${baseUrl}/UserRole/GetAllRole`;
export const CREATEUSERROLE = `${baseUrl}/UserRole/CreateRole`;
export const UPDATEROLE = `${baseUrl}/UserRole/UpdateRole`;
export const GETROLEBYID = `${baseUrl}/UserRole/GetByRoleId`;
export const DELETEROLE = `${baseUrl}/UserRole/DeleteRole`;
export const GETUSERWISEROLE = `${baseUrl}/UserRole/GetUserWiseRole`;
export const SAVEUSERWISEROLE = `${baseUrl}/UserRole/SaveUserWiseRole`;
export const ADVANCEFILTERROLE = `${baseUrl}/UserRole/AdvancedSerachFilter`;

// Company
export const GETPROFILE = `${baseUrl}/Company/GetCompanyById`;
export const UPDATEPROFILE = `${baseUrl}/Company/UpdateCompany`;
export const CREATEPROFILE = `${baseUrl}/Company/CreateCompany`;
export const CREATECOMPANYUSINGBLOB = `${baseUrl}/Company/CreateCompanyUsingBlob`;
export const UPDATECOMPANYUSINGBLOB = `${baseUrl}/Company/UpdateCompanyUsingBlob`;
export const GETCOMPANYBYIDUSINGBLOB = `${baseUrl}/Company/GetCompanyByIdUsingBlob`;
// Common
export const GETCOMMONVALUE = `${baseUrl}/Common/GetCommonValuesByKey`;
export const GETMODULELIST = `${baseUrl}/Common/GetModuleList`;
export const GETMODULEWITHROLE = `${baseUrl}/Common/GetModuleWithRole`;
export const GETDEFAULTLOADER = `${baseUrl}/Common/GetDefaultLoader`;
export const GETDEFAULTLOGO = `${baseUrl}/Common/GetDefaultLogo`;
export const GETDEFAULTLOADERBLOB = `${baseUrl}/Common/GetBlobDefaultLoader`;
export const GETDEFAULTLOGOBLOB = `${baseUrl}/Common/GetblobDefaultLogo`;
export const GETTIMEZONEIDENTIFIER = `${baseUrl}/TimeZoneIdentifier/GetAllTimeZoneIdentifier`;
export const UPDATETIMEZONEIDENTIFIER = `${baseUrl}/TimeZoneIdentifier/UpdateimeZoneIdentifier`;
export const GETBYTIMEZONEIDENTIFIERID = `${baseUrl}/TimeZoneIdentifier/GetByTimeZoneIdentifierId`;
export const DELETETIMEZONEIDENTIFIER = `${baseUrl}/TimeZoneIdentifier/DeleteTimeZoneIdentifier`;
export const GETSTATECOUNTRYWISE = `${baseUrl}/Common/GetStateCountryWise`;

// Categories
export const GETALLCATEGORIES = `${baseUrl}/Category/GetCategoryAll`;
export const CREATECATEGORIES = `${baseUrl}/Category/CreateCategory`;
export const UPDATECATEGORIES = `${baseUrl}/Category/UpdateCategory`;
export const DELETECATEGORIES = `${baseUrl}/Category/DeleteCategory`;
export const GETCATEGORIESBYID = `${baseUrl}/Category/GetCategoryById`;
export const ADVANCEFILTERCATEGORY = `${baseUrl}/Category/AdvancedSerachFilter`;
export const CREATECATEGORYWITHTEXABLE = `${baseUrl}/Category/CreateCategorywithTaxable`;
export const UPDATECATEGORYWITHTEXABLE = `${baseUrl}/Category/UpdateCategorywithTaxable`;
export const GETCATEGORYWITHTEXABLE = `${baseUrl}/Category/GetByCategorywithTaxableId`;

//ImageConfig
export const IMAGECONFIGAPI = `${baseUrl}/ImageConfiguration/GetImageConfiguration`;
export const IMAGECONFIGAPIUPDATE = `${baseUrl}/ImageConfiguration/UpdateImageConfiguration`;
export const IMAGECONFIGAPICREATE = `${baseUrl}/ImageConfiguration/CreateImageConfiguration`;

//BidderConfig
export const BIDDERCONFIGCREATE = `${baseUrl}/AdminSettings/UpdateAdminSettings`;
export const GETALLBIDDERCONFIG = `${baseUrl}/AdminSettings/GetAllAdminSetting`;
export const UPDATEBLOBADMINSETTINGS = `${baseUrl}/AdminSettings/UpdateBlobAdminSettings`;
export const GETALLADMINSETTINGUSINGBLOB = `${baseUrl}/AdminSettings/GetAllAdminSettingUsingBlob`;

//Inventory
export const GETALLLOTS = `${baseUrl}/Inventory/GetLotAll`;
export const CREATENEWLOT = `${baseUrl}/Inventory/CreateLot`;
export const DELETEINVENTORY = `${baseUrl}/Inventory/DeleteInventory`;
export const DELETEINVENTORYUSINGBLOB = `${baseUrl}/Inventory/DeleteLotUsingBlob`;
export const DELETELOTNOTES = `${baseUrl}/Inventory/DeleteLotNotes`;
export const CREATELOTADDRESS = `${baseUrl}/Inventory/CreateLotAddress`;
export const CREATELOTAUTO = `${baseUrl}/Inventory/CreateLotAuto`;
export const CREATELOTSILENT = `${baseUrl}/Inventory/CreateLotSilent`;
export const CREATELOTDOCURL = `${baseUrl}/Inventory/CreateLotDocUrl`;
export const GETLOTDOCURLALL = `${baseUrl}/Inventory/GetLotDocUrlAll`;
export const GETALLLOTBYID = `${baseUrl}/Inventory/GetByIdLot`;
export const CREATELOTREALESTATERECORD = `${baseUrl}/Inventory/CreateLotRealEstateRecord`;
export const CREATELOTREALESTATEUSERDEFINEDFIELDS = `${baseUrl}/Inventory/CreateLotRealEstateDefinedFields`;
export const CREATELOTREALESTATELOCATION = `${baseUrl}/Inventory/CreateLotRealEstateLocation`;
export const CREATELOTREALESTATEAGENT = `${baseUrl}/Inventory/CreateLotRealEstateAgent`;
export const CREATELOTREALESTATETAXES = `${baseUrl}/Inventory/CreateLotRealEstateTaxes`;
export const CREATELOTREALESTATEEXTERIOR = `${baseUrl}/Inventory/CreateLotRealEstateExterior`;
export const CREATELOTREALESTATEINTERIOR = `${baseUrl}/Inventory/CreateLotRealEstateInterior`;
export const CREATELOTREALESTATEPROPERTY = `${baseUrl}/Inventory/CreateLotRealEstateProperty`;
export const UPDATENEWLOT = `${baseUrl}/Inventory/UpdateLot`;
export const UPDATELOTADDRESS = `${baseUrl}/Inventory/UpdateLotAddress`;
export const UPDATELOTNOTES = `${baseUrl}/Inventory/UpdateLotNotes`;
export const UPDATELOTAUTO = `${baseUrl}/Inventory/UpdateLotAuto`;
export const UPDATELOTTITLEASSIGNMENT = `${baseUrl}/Inventory/UpdateLotTitleAssignment`;
export const GETLOTTITLEASSIGNMENTBYLOTID = `${baseUrl}/Inventory/GetLotTitleAssignmentByLotId`;
export const GETWONTITLEDETAILSBYLOTID = `${baseUrl}/Inventory/GetWonBidderTitleAssignmentDetailsByLotId`;
export const UPDATELOTREALESTATERECORD = `${baseUrl}/Inventory/UpdateLotRealEstateRecord`;
export const UPDATELOTSILENT = `${baseUrl}/Inventory/UpdateLotSilent`;
export const UPDATELOTDOCURL = `${baseUrl}/Inventory/UpdateLotDocUrl`;
export const UPDATELOTDOCURLLIST = `${baseUrl}/Inventory/UpdateLotDocUrlList`;
export const UPDATELOTREALESTATEAGENT = `${baseUrl}/Inventory/UpdateLotRealEstateAgent`;
export const UPDATELOTREALESTATELOCATION = `${baseUrl}/Inventory/UpdateLotRealEstateLocation`;
export const UPDATELOTREALESTATEUSERDEFINEDFIELDS = `${baseUrl}/Inventory/UpdateLotRealEstateDefinedFields`;
export const UPDATELOTREALESTATETAXES = `${baseUrl}/Inventory/UpdateLotRealEstateTaxes`;
export const UPDATELOTREALESTATEEXTERIOR = `${baseUrl}/Inventory/UpdateLotRealEstateExterior`;
export const UPDATELOTREALESTATEINTERIORR = `${baseUrl}/Inventory/UpdateLotRealEstateInterior`;
export const UPDATELOTREALESTATEPROPERTY = `${baseUrl}/Inventory/UpdateLotRealEstateProperty`;
export const ADVANCESEARCHINVENTORY = `${baseUrl}/Inventory/AdvancedSerachFilter`;
export const CREATESILENTAUCTION = `${baseUrl}/Inventory/CreateSilentAuction`;
export const GENERATELOTCTRLREC = `${baseUrl}/Inventory/GenerateLotNoCtrlNo`;
export const IMPORTLOTEXCEL = `${baseUrl}/Inventory/GetExcelDataForImport`;
export const SWAPLOT = `${baseUrl}/Inventory/SaveLotSwapping`;
export const MOVELOTBETWEENAUCTION = `${baseUrl}/Inventory/MoveLotBetweenAuctionUsingBlob`;
export const REARRANGELOTFORAUCTION = `${baseUrl}/Inventory/ReArrangeLotsForAuction`;
export const REARRANGEMULTIPLELOTSFORAUCTION = `${baseUrl}/Inventory/ReArrangeMultipleLotsForAuction`;
export const TAGMASTERINVTOAUCTION = `${baseUrl}/Inventory/UpdateBlobAuctionIdTiedUpToLot`;
export const GETLIENHOLDERDATA = `${baseUrl}/Inventory/ReportLienholder`;
export const LIENHOLDERADVANCESEARCH = `${baseUrl}/Inventory/LienholderReportAdvancedSearchFilter`;
export const UPDATELOTDOCUMENT = `${baseUrl}/Inventory/UpdateBlobLotScannedDocument`;
export const GETLIENHOLDERNAME = `${baseUrl}/Inventory/GetLienholderName`;
export const DELETELOTDOCUMENT = `${baseUrl}/Inventory/DeleteScnnedDocument`;
export const DECODEVIN = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVINValues`;
export const INVENTORYTITLEREPORT = `${baseUrl}/Inventory/TitleReport`;
export const TITLEADVANCEFILTER = `${baseUrl}/Inventory/GetAdvancedSearchFilterFortitleReport`;
export const CHECKDUPLICATELOTTILE = `${baseUrl}/Inventory/CheckDuplicateLotTitle`;
export const UPDATELOTCOMMBIDDOWN = `${baseUrl}/Inventory/UpdatelotCommBidDown`;
export const GETALLMASTERINVENTORY = `${baseUrl}/Inventory/GetAllMasterInventory`;
export const GOTOLOTNOCTRLNO = `${baseUrl}/Inventory/GotoLotNoCtrlNo`;
export const GETINVENTORYBYSELLER = `${baseUrl}/Inventory/GetInventoryBySellerReport`;
export const SENDREPORTEMAIL = `${sellerBase}/Seller/SendMailToReportingEmail`;
// export const DECODEVIN = `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVINValuesBatch`;

// Inventory Clone
export const GETINVENTORYBYID = `${baseUrl}/Inventory/GetByIdLot`;


//Inventory seprate tabs API starts here

export const GETLOTBYID = `${baseUrl}/Inventory/GetLotById`;
export const GETLOTADDRESSBYID = `${baseUrl}/Inventory/GetLotAddressById`;
export const GETLOTCOMMCOSTBYID = `${baseUrl}/Inventory/GetCommCostBidDownByLotId`;
export const GETLOTNOTESBYID = `${baseUrl}/Inventory/GetNotesByLotId`;
export const GETLOTAUTOBYID = `${baseUrl}/Inventory/GetAutoByLotId`;
export const GETLOTREALESTATERECORDBYID = `${baseUrl}/Inventory/GetRealEstateRecordByLotId`;
export const GETLOTREALESTATELOCATIONBYID = `${baseUrl}/Inventory/GetRealEstateLocationByLotId`;
export const GETLOTREALESTATETAXESBYID = `${baseUrl}/Inventory/GetRealEstateTaxesByLotId`;
export const GETLOTREALESTATEPROPERTYDETAILSBYID = `${baseUrl}/Inventory/GetRealEstatepropertydetailsByLotId`;
export const GETLOTREALESTATEINTERIORBYID = `${baseUrl}/Inventory/GetRealEstateInteriorByLotId`;
export const GETLOTREALESTATEEXTERIORBYID = `${baseUrl}/Inventory/GetRealEstateExteriorByLotId`;
export const GETLOTREALESTATEAGENTBYID = `${baseUrl}/Inventory/GetRealEstateAgentByLotId`;
export const GETSILENTBYLOTID = `${baseUrl}/Inventory/GetSilentByLotId`;
export const GETDOCSURLBYLOTID = `${baseUrl}/Inventory/GetDocUrlByLotId`;
export const GETLOTDOCUMENTSBYLOTID = `${baseUrl}/Inventory/GetLotBlobDocumentsByLotId`

// Invoice
export const GETALLINVOICE = `${baseUrl}/Invoice/GetAllInvoice`;
export const ADDNEWINVOICE = `${baseUrl}/Invoice/CreateInvoice`;
export const ADVANCESEARCHINVOICE = `${baseUrl}/Invoice/GetAdvancedSearchFilter`;
export const UPDATEINVOICE = `${baseUrl}/Invoice/UpdateInvoice`;
export const GETINVOICEBYID = `${baseUrl}/Invoice/GetByInvoiceId`;
export const SENDEINVOICE = `${baseUrl}/Invoice/SendEInvoice`;
export const MULTIPLESENDEINVOICE = `${baseUrl}/Invoice/MultipleSendEInvoice`
export const CONFIRMINVOICE = `${baseUrl}/Invoice/ConfirmInvoice`;
export const UPDATEREVIEWEDINVOICE = `${baseUrl}/Invoice/UpdateReviewInvoice`;
export const DELETEINVOICE = `${baseUrl}/Invoice/DeleteInvoice`;
export const VOIDINVOICE = `${baseUrl}/Invoice/IsVoidStatusUpdate`;
export const GETCUSTOMERINVOICE = `${baseUrl}/Invoice/GetCustomerInvoice`;
export const GETAUCTIONBYCUSTOMER = `${baseUrl}/Invoice/GetAuctionByCustomerInvoice`;
export const GETAUCTIONBYBIDDER = `${baseUrl}/Invoice/GetAuctionByBidderNumberInvoice`;
export const GETLOTBYCUSTOMERANDAUCTION = `${baseUrl}/Invoice/GetLotsByCustomerAndAuctionInvoice`;
export const GETWONLOTSFORBIDDER = `${baseUrl}/Invoice/GetWonLotsForInvoice`;
export const GETWONLOTSFORCUSTOMER = `${baseUrl}/Invoice/GetWonLotsForInvoiceByCustomer`;
export const CHECKINVOICECUSTOMER = `${baseUrl}/Invoice/CheckInvoiceCustomer`;
export const MOVELOTTOANOTHERCUSTOMER = `${baseUrl}/Invoice/MoveLotInAnotherCustomer`;
export const GETINVOICECUSTOMER = `${baseUrl}/Invoice/GetInvoiceCustomer`;
export const GETCUSTOMERCCDETAILSBYCUSTOMER = `${baseUrl}/Invoice/GetCustomerCCDetailByCustomerId`;
export const GETDEFAULTCCDETAILBYCUSTOMER = `${baseUrl}/Invoice/GetDefaultCCDetailByCustomerId`;
export const GETCUSTOMERBYAUCTION = `${baseUrl}/Invoice/GetCustomerByAuction`;
export const GETLOTSBYBIDDERNUMBER = `${baseUrl}/Invoice/GetLotsByBidderNumber`;
export const GETBIDDERNUMBERBYCUSTOMER = `${baseUrl}/Invoice/GetBidderNumberByCustomer`;
export const GETINVOICEHISTORY = `${baseUrl}/Invoice/GetInvoiceHistory`;
export const GETCUSTOMERWONLOTWITHAUCTIONID = `${baseUrl}/Invoice/GetCustomerWonLotWithAuctionID`
export const GETINVOICERECORDBYSALESRECORD = `${baseUrl}/Invoice/GetInvoiceRecordbySalesRecord`
export const GETINVOICESUMMARYREPORT =  `${baseUrlReports}/OtherReports/InvoiceSummaryReport`

//EInvoiceSettings
export const GETEINVOICESETTINGS = `${baseUrl}/EInvoiceSettings/GetEInvoiceSettings`;
export const CREATEEINVOICESETTINGS = `${baseUrl}/EInvoiceSettings/CreateEInvoice`;
export const UPDATEEINVOICESETTINGS = `${baseUrl}/EInvoiceSettings/UpdateEInvoice`;

// Mail Server Settings
export const CREATEMAILSERVER = `${baseUrl}/MailServerSettings/CreateMailServer`;
export const UPDATEMAILSERVER = `${baseUrl}/MailServerSettings/UpdateMailServer`;
export const GETMAILSERVER = `${baseUrl}/MailServerSettings/GetMailServer`;
export const SENDTESTEMAIL = `${baseUrl}/MailServerSettings/SendTestEmail`;

// Email Template
export const GETEMAILTEMPLATE = `${baseUrl}/EmailTemplate/GetByEmailTemplate`;
export const GETALLEMAILTEMPLATE = `${baseUrl}/EmailTemplate/GetByEmailTemplateListing`;
export const GETEMAILTEMPLATEBYID = `${baseUrl}/EmailTemplate/GetByEmailTemplateId`;
export const UPDATEEMAILTEMPLATE = `${baseUrl}/EmailTemplate/UpdateEmailTemplate`;
export const SAVEEMAILTEMPLATE = `${baseUrl}/EmailTemplate/SaveEmailTemplate`;
export const DELETEEMAILTEMPLATE = `${baseUrl}/EmailTemplate/DeleteEmailTemplate`;

// Seller Report
export const GETSELLERREPORT = `${baseUrl}/SellerReport/GetBySellerReport`;
export const UPDATESELLERREPORT = `${baseUrl}/SellerReport/UpdateSellerReport`;
export const SAVESELLERREPORT = `${baseUrl}/SellerReport/CreateSellerReport`;
export const AUCTIONWISESELLER = `${baseUrl}/SellerReport/GetSellerAssociatedWithAuction`;
export const GETAUCTIONSELLERLOTS = `${baseUrl}/SellerReport/LotDetailsForSellerAuctionReport`;
export const GETSELLERAUCTIONS = `${baseUrl}/SellerReport/GetAuctionAsociatedWithSeller`;
export const GETCLOSEDAUCTOINS = `${baseUrl}/AuctionInfo/GetClosedAuctionInfo`;
export const GETPHOTOSBYAUCTIONID = `${baseUrl}/AuctionInfo/GetPhotosByAuctionId`;


// Auction Default
export const GETAUCTIONDEFAULT = `${baseUrl}/AuctionDefault/GetByAuctionDefaultId`;
export const UPDATEAUCTION = `${baseUrl}/AuctionDefault/UpdateAuction`;
export const CREATEAUCTION = `${baseUrl}/AuctionDefault/CreateAuctionDefault`;

// SalesRepresentative
export const GETSALESREPRESENTATIVE = `${baseUrl}/SalesRepContactInfo/GetAllSalesRep`;
export const CREATESALESREPRESENTATIVE = `${baseUrl}/SalesRepContactInfo/CreateSalesRepContactInfo`;
export const DELETESALESREPRESENTATIVE = `${baseUrl}/SalesRepContactInfo/DeleteSalesRep`;
export const ADVANCEFILTERSALESREPRESENTATIVE = `${baseUrl}/SalesRepContactInfo/AdvancedSerachFilter`;
export const CREATESALESREPP = `${baseUrl}/SalesRepContactInfo/CreateSalesRep`;
export const GETSALESREPID = `${baseUrl}/SalesRepContactInfo/GetSaleRepId`;
export const UPDATESALESREP = `${baseUrl}/SalesRepContactInfo/UpdateSalesRepContact`;
export const UPDATESALESREPCOMM = `${baseUrl}/SalesRepContactInfo/UpdateSalesRepCommission`;
export const UPDATESALESINAUCTIONS = `${baseUrl}/SalesRepContactInfo/UpdateSalesRepInAuction`;
export const UPDATESALESADJUST = `${baseUrl}/SalesRepContactInfo/UpdateAdjusts`;
export const UPDATESALESPAYMENT = `${baseUrl}/SalesRepContactInfo/UpdateSalesRepPayments`;
export const GETSALESREPLOG = `${baseUrl}/SalesRepContactInfo/SalesRepAdjustPaymentLog`;
export const GETASSOCIATEDSELLERS = `${baseUrl}/SalesRepContactInfo/GetSellerAssociatedToSalesRep`;
export const GETSALESREPBYSELLERID = `${baseUrl}/SalesRepContactInfo/GetSalesRepBySellerId`;
export const GETAUCTIONBYSALESREP = `${baseUrl}/SalesRepContactInfo/GetAuctionBySalesRepId`;
export const GETLOTBYSALESREP = `${baseUrl}/SalesRepContactInfo/GetSalesRepAuctionByInventory`;
export const GETAUCIONTALLYBYSALESREP = `${baseUrl}/SalesRepContactInfo/GetSalesRepsAuctionTally`;
export const GETSALESWITHCOST = `${baseUrl}/SalesRepContactInfo/GetSalesWithCostForSalesRep`;
export const GETSALESBYBIDDER = `${baseUrl}/SalesRepContactInfo/GetSalesByBidderForSalesRep`;
export const GETSALESREPCOMMISSIONSREPORT = `${baseUrl}/SalesRepContactInfo/GetSalesRepCommissionReport`;
export const GETSALESREPCOMMISSIONSCALCULATE = `${baseUrl}/SalesRepContactInfo/GetSalesRepCommissionCalculation`;
export const GETSALESREPCOMMISSIONS = `${baseUrl}/SalesRepContactInfo/GetSellesRepCommissionBySelsRepIdAuctionId`;
export const GETSALESPERAUCTIONCONTACTINFO = `${baseUrl}/SalesRepContactInfo/GetByIdsalesRepAuctionInfo`;
export const UPDATESALESREPAUCTIONCONTACTINFO = `${baseUrl}/SalesRepContactInfo/UpdatesalesRepAuctionInfo`;

export const GETSALESREPCOMMSALESINAUCTION = `${baseUrl}/SalesRepContactInfo/GetSalesRepCommissionForSellerInAuction`;

//Bid Increment
export const GETALLBIDINCREMENT = `${baseUrl}/BidIncrement/GetAllBidIncrement`;
export const UPDATEBIDINCREMENT = `${baseUrl}/BidIncrement/UpdateBidIncrement`;

// LotTemplates
export const GETALLLOTTEMPLATE = `${baseUrl}/LotTemplate/GetAllLotTemplate`;
export const GETALLCATEGORYTITLE = `${baseUrl}/LotTemplate/GetAllCategoryTitle`;
export const LOTTEMPLATEFILTER = `${baseUrl}/LotTemplate/AdvancedSerachFilter`;
export const CREATELOTTEMPLATE = `${baseUrl}/LotTemplate/CreateLotTemplate`;
export const GETLOTTEMPLATEID = `${baseUrl}/LotTemplate/GetLotTemplateId`;
export const UPDATELOTTEMPLATE = `${baseUrl}/LotTemplate/UpdateLotTemplate`;
export const DELETELOTTEMPLATE = `${baseUrl}/LotTemplate/DeleteLotTemplate`;

// admin Email Template
export const GETALLTEMPLATEEMAIL = `${baseUrl}/TemplateEmail/GetAllTemplateEmail`;
export const GETTEMPLATEEMAILBYID = `${baseUrl}/TemplateEmail/GetByTemplateEmailId`;
export const DELETETEMPLATEEMAILS = `${baseUrl}/TemplateEmail/DeleteTemplateEmail`;
export const UPDATETEMPLATEEMAIL = `${baseUrl}/TemplateEmail/UpdateTemplateEmail`;
export const CREATETEMPLATEEMAIL = `${baseUrl}/TemplateEmail/CreateTemplateEmail`;

// admin SMS Template
export const GETALLSMSTEMPLATE = `${baseUrl}/SMSTemplate/GetAllSMSTemplate`;
export const SAVESMSTEMPLATE = `${baseUrl}/SMSTemplate/SaveSMSTemplate`;
export const UPDATESMSTEMPLATE = `${baseUrl}/SMSTemplate/UpdateSMSTemplate`;
export const DELETESMSTEMPLATE = `${baseUrl}/SMSTemplate/DeleteSMSTemplate`;
export const GETBYSMSTEMPLATE = `${baseUrl}/SMSTemplate/GetBySMSTemplateId`;

// BP Sales Tax
export const GETBPSALESTAX = `${baseUrl}/BPSalesTax/GetByBPSalesTax`;
export const CREATEBPSALESTAX = `${baseUrl}/BPSalesTax/CreateBPSalesTax`;
export const UPDATEBPSALESTAX = `${baseUrl}/BPSalesTax/UpdateBPSalesTax`;

//SellerCommissionScale
export const GETALLSELLERCOMMISSIONSCALE = `${baseUrl}/SellerCommissionScale/GetAllSellerCommissionScale`;
export const GETALLSELLERCOMMISSIONTAXABLESTATE = `${baseUrl}/SellerCommissionScale/SellerCommissionTaxableState`;
export const SAVESELLERCOMMISSION = `${baseUrl}/SellerCommissionScale/SaveSellerCommissionScale`;
export const CREATETAXABLESTATE = `${baseUrl}/SellerCommissionScale/CreateTaxableStates`;
export const UPDATETAXABLESTATE = `${baseUrl}/SellerCommissionScale/UpdateTaxableStates`;

//salesRepCommission
export const GETALLSALESREPCOMMISSIONSCALE = `${baseUrl}/SalesRepCommissions/GetAllSalesRepCommissionScale`;
export const SAVESALESCOMMISSION = `${baseUrl}/SalesRepCommissions/SaveSalesRepCommissionScale`;

//Lot temp preview
export const GETLOTTEMPBYID = `${baseUrl}/LotTemplate/GetLotTemplateId`;

//seller
export const GETSELLER = `${sellerBase}/Seller/GetAllSellers`;
export const GETSELLERASSOCIATEDTOSALESREP = `${baseUrl}/SalesRepContactInfo/GetAllSellerAssociatedWithSalesRep`
export const CREATESELLERCONTACTINFO = `${sellerBase}/Seller/CreateSellerContactInfo`;
export const CREATESELLER = `${sellerBase}/Seller/CreateSeller`;
export const DELETESELLER = `${sellerBase}/Seller/DeleteBySellerId`;
export const GETBYSELLERID = `${sellerBase}/Seller/GetBySellerId`;
export const GETDEFAULTCOMMFORINV = `${sellerBase}/Seller/GetSellerCommissionBySellerIdAuctionId`;
export const UPDATESELLER = `${sellerBase}/Seller/UpdateSeller`;
export const SELLERADVANCEFILTER = `${sellerBase}/Seller/AdvancedSerachFilter`;
export const CREATESELLERCONTACT = `${sellerBase}/Seller/CreateSellerContact`;
export const CREATELOGNOTES = `${sellerBase}/Seller/CreateLotNotes`;
export const UPDATELOGNOTES = `${sellerBase}/Seller/UpdateNotes`;
export const UPDATESELLERCONTACT = `${sellerBase}/Seller/UpdateSellerContactInfo`;
export const CREATESALESINAUCTION = `${sellerBase}/Seller/CreateSalesInAuction`;
export const UPDATESALESINAUCTION = `${sellerBase}/Seller/UpdateSalesInAuction`;
export const CREATECOMMISION = `${sellerBase}/Seller/CreateCommission`;
export const UPDATECOMMISION = `${sellerBase}/Seller/UpdateCommission`;
export const CREATEADJUSTS = `${sellerBase}/Seller/CreateAdjusts`;
export const UPDATEADJUSTS = `${sellerBase}/Seller/UpdateAdjusts`;
export const CREATEPAYMENTS = `${sellerBase}/Seller/CreatePayments`;
export const UPDATEPAYMENTS = `${sellerBase}/Seller/UpdatePayments`;
export const SELLERADJUSTPAYLOGS = `${sellerBase}/Seller/SellerAdjustPaymentLog`;
export const SELLERLOTS = `${sellerBase}/Seller/GetSellerByAuction`;
export const UPDATESELLERADDRESS = `${sellerBase}/Seller/UpdateSellerAddress`;
export const GETSELLERAUCTIONTALLY = `${sellerBase}/Seller/GetSellerAuctionTally`;
export const ADDCONTRACTDOCUMENTS = `${sellerBase}/Seller/AddSellerContractDocument`;
export const ADDBLOBCONTRACTDOCUMENTS = `${sellerBase}/Seller/AddBlobSellerContractDocument`;
export const ADDSELLERAUCTIONBLOBDOCUMENT = `${sellerBase}/Seller/AddBlobSellerauctioncontractdocument`;
export const GETSELLERBLOBCONTRACTDOCUMENT = `${sellerBase}/Seller/GetSellerBlobDocumentsBySellerId`;
export const GETSELLERAUCTIONBLOBDOCUMENT = `${sellerBase}/Seller/GetSellerauctioncontractdocument`;
export const DELETECONTRACTDOCUMENT = `${sellerBase}/Seller/DeleteContractDocument`;
export const DELETEDOCUMENT = `${sellerBase}/Seller/DeleteDocument`;
export const GETSELLERSUMMARYREPORT = `${sellerBase}/Seller/GetAllSellerReport`;
export const SELLERREPORTADVANCEFILTER = `${sellerBase}/Seller/AdvancedSerachFilterSellerReport`;
export const GETSELLERBYAUCTIONID = `${sellerBase}/Seller/GetSellerByAuctionId`;
export const GETSELLERBYAUCTIONWISELOT = `${sellerBase}/Seller/GetSellerByAuctionWiseLots`;
export const GETSELLERCONTACTINFOBYAUCTIONID = `${sellerBase}/Seller/GetSellerContactAuctionInfoBySellerIdAndAuctionId`;
export const UPDATESELLERCONTACTINFO = `${sellerBase}/Seller/UpdateSellerContactAuctionInfo`;
export const GETSELLERCOMMISSIONDATA = `${sellerBase}/Seller/GetSellerCommissionReport`
export const GETALLSELLERSUMMARY = `${sellerBase}/Seller/GetSummaryOfSellersReport`;
export const GETSELLERCOMMISSIONSUMMARY = `${sellerBase}/Seller/GetCommissionSummaryReport`;
export const GETSELLERADJUSTSUMMARY = `${sellerBase}/Seller/GetSellerAdjustmentSummaryReport`;
export const OVERALLSELLERSUMMARY = `${sellerBase}/Seller/GetOverAllSellerSummaryReport`;
export const SELLERPAYMENTSUMMARY = `${sellerBase}/Seller/GetSellerPaymentSummaryReport`;
export const SELLERPICKUPSUMMARY = `${sellerBase}/Seller/GetSellerPickupSummaryReportBySellerId`;
export const SELLERPICKUPSUMMARYBYAUCTIONID = `${sellerBase}/Seller/GetSellerPickUpReportByAuctionID`
export const GETSELLERFORPICKUP = `${sellerBase}/Seller/GetSellerForPickUpReport`
export const GETAUTOTITLEREPORT = `${baseUrl}/Inventory/AutoTitleDetailReport`
export const GETSELLERSUMMARYCOMMISSIONREPORT = `${sellerBase}/Seller/GetSellerSummaryAndCommissoinSummaryByAuction`
export const BALANCEDUEBYBIDDERREPORT = `${baseUrl}/Invoice/BalDueByBidderReport`
export const SELLERBYSALESREPREPORT = `${baseUrlReports}/SalesRepReport/GetSalesRepBySellerSummaryReport`;
export const GETSELLERINFOFORAUCTION = `${sellerBase}/Seller/GetSellerInformationForAuction`;
export const GETSALESWITHCOSTSELLER = `${sellerBase}/Seller/GetSalesWithCostForSeller`;
export const GETSALESBYBIDDERSELLER = `${sellerBase}/Seller/GetSalesByBidderForSeller`;
export const GETAUCTIONSBYSELLER = `${sellerBase}/Seller/GetAuctionListBySellerId`;
export const SELLERCOMMISSIONRECALCUTE = `${sellerBase}/Seller/GetSellerCommissionCalculation`;
export const GETSELLERCOMMISSIONS = `${sellerBase}/Seller/GetSellerCommissionBySellerIdAuctionId`;
export const GETSELLERCOMMSALESINAUCTION = `${sellerBase}/Seller/GetSellerCommissionForSellerInAuction`;
export const UPDATESELLERNOTES = `${sellerBase}/Seller/UpdateSellerAuctionNotes`;
export const GETSELLERNOTES = `${sellerBase}/Seller/GetSellerAuctionNotesById`;

//customers
export const GETALLCUSTOMERS = `${baseUrlCustomers}/Customer/GetAllCustomers`;
export const CREATECUSTOMERCONTACTINFO = `${baseUrlCustomers}/Customer/CreateCustomerContactInfo`;
export const ADVANCEFILTERCUSTOMERS = `${baseUrlCustomers}/Customer/AdvancedSerachFilter`;
export const DELETECUSTOMER = `${baseUrlCustomers}/Customer/DeleteByCustomerId`;
export const CREATECUSTOMER = `${baseUrlCustomers}/Customer/CreateCustomer`;
export const UPDATECUSTOMER = `${baseUrlCustomers}/Customer/UpdateCustomer`;
export const GETCUSTOMERBYID = `${baseUrlCustomers}/Customer/GetByCustomerId`;
export const CREATEBIDDERNUMBER = `${baseUrlCustomers}/Customer/CreateBidderNumber`;
export const UPDATEBIDDERNUMBER = `${baseUrlCustomers}/Customer/UpdateBidderNumber`;
export const GETBIDDERSBYCUSTOMERID = `${baseUrlCustomers}/Customer/GetByBidderBYCustomerId`;
export const UPDATECUSTOMERPROFILE = `${baseUrlCustomers}/Customer/ProfileUpdate`;
export const GETALLLOTSBYBIDDERNUMBER = `${baseUrlCustomers}/Customer/GetAllLotsBybidderNumber`;
export const GETWONLOTSBYBIDDERNUMBER = `${baseUrlCustomers}/Customer/GetAllTotalWon`;
export const GETLOSSLOTSBYBIDDERNUMBER = `${baseUrlCustomers}/Customer/GetAllTotalLoss`;
export const GETWINLOTS = `${baseUrlCustomers}/Customer/GetWinLotByBidderNumberAuctionId`;
export const SAVELOTSSELLERMAP = `${baseUrlCustomers}/Customer/SaveLotNoSellerMapping`;
export const ADDBIDDERDOCUMENTS = `${baseUrlCustomers}/Customer/AddBidderDocument`;
export const ADDBLOBBIDDERDOCUMENT = `${baseUrlCustomers}/Customer/AddBlobBidderDocument`;
export const GETCUSTOMERBLOBDOCUMENTSBYCUSTOMERID = `${baseUrlCustomers}/Customer/GetCustomerBlobDocumentsByCustomerId`;
export const DELETEBIDDERDOCUMENT = `${baseUrlCustomers}/Customer/DeleteBidderDocument`;
export const UPDATECUSTOMERADDRESS = `${baseUrlCustomers}/Customer/UpdateCustomerAddress`;
export const GETCUSTOMERSUSPENDREPORT = `${baseUrlCustomers}/Customer/ReportCustomersSuspend`;
export const CUSTOMERSUSPENDADVANCESEARCH = `${baseUrlCustomers}/Customer/CustomerSuspendAdvancedSearchFilter`;
export const GETCUSTOMERBYEMAIL = `${baseUrlCustomers}/Customer/GetCustomerContactByEmail`;
export const BULKMAXAMOUNT = `${baseUrlCustomers}/Customer/BulkMaxAmountUpdate`;
export const BIDHISTORY = `${baseUrlCustomers}/Customer/GetBidHistory`;
export const GETALLACTIVECUSTOMER = `${baseUrlCustomers}/Customer/GetAllActiveCustomer`;
export const GETREGTOBIDCUSTOMERS = `${baseUrlCustomers}/Customer/GetAllRegisterToBidCustomer`;
export const GETCUSTOMERBLOBPROFILE = `${baseUrlCustomers}/Customer/GetBlobCustomerProfileByCustomerId`;

export const AUCTIONSBYCUSTOMERID = `${baseUrlCustomers}/Customer/GetAuctionInfoForCustomer`;
export const GETCUSTOMERINFOBYAUCTION = `${baseUrlCustomers}/Customer/GetCustomerAuctionInfoByCustomerId`;
export const UPDATECUSTOMERINFOBYAUCTION = `${baseUrlCustomers}/Customer/UpdateCustomerAuctionInfo`;
export const GOTOBIDDERNUMBER = `${baseUrlCustomers}/Customer/GotoBidderNumber`;
export const DELETEBIDDERNUMBER = `${baseUrlCustomers}/Customer/DeleteBidderNumber`;


// BP By Sales
export const GETBPBYSCALES = `${baseUrl}/BPBySales/GetAllBPBySales`;
export const CREATEBPBYSCALES = `${baseUrl}/BPBySales/CreateBPSales`;

// Sales Records
export const GETALLSALESRECORDS = `${baseUrl}/SalesRecord/GetAllSalesRevised`;
export const CREATESALESRECORD = `${baseUrl}/SalesRecord/CreateSalesRecord`;
export const DELETESALESRECORD = `${baseUrl}/SalesRecord/DeleteSalesRecord`;
export const CREATESALESRECORDINFO = `${baseUrl}/SalesRecord/CreateSalesRecordInformation`;
export const UPDATESALESRECORD = `${baseUrl}/SalesRecord/UpdateSalesRecord`;
export const GETBYSALESRECORDID = `${baseUrl}/SalesRecord/GetBySalesRecordId`;
export const ADVANCEFILTERSALESRECORD = `${baseUrl}/SalesRecord/AdvancedSerachFilter`;
export const UPDATEBIDDERNUMBERINSALES = `${baseUrl}/SalesRecord/UpdateSalesBidderNumber`;
export const GETTITLEREPORTBYSELLERID = `${baseUrl}/SalesRecord/TitleReport`;
export const UPDATELOTSTATUS = `${baseUrl}/SalesRecord/UpdateLotstatus`;
export const CHECKINVOICEREVIEW = `${baseUrl}/SalesRecord/CheckInvoiceReview`;
export const CHECKVOIDINVOICE = `${baseUrl}/SalesRecord/CheckVoidInvoice`;
export const RECALCULATESELLERCOMM = `${baseUrl}/SalesRecord/ResellerCalculationOfSalesRecord`;
export const RECALCULATESALESPERSONCOMM = `${baseUrl}/SalesRecord/ReSalesRepCalculationOfSalesRecord`;

// Auction
export const GETAUCTION = `${baseUrl}/Auction/GetAllAuction`;
export const GETAUCTIONINFO = `${baseUrl}/AuctionInfo/GetAllAuctionInfo`;
export const CREATEADDRESSDATES = `${baseUrl}/AuctionInfo/CreateAddressAndDates`;
export const UPDATEADDRESSDATES = `${baseUrl}/AuctionInfo/UpdateAddressAndDates`;
export const GETWEBTEXTBYAUCTIONID = `${baseUrl}/AuctionInfo/GetWebTextByAuctionId`;
export const CREATEWEBTEXT = `${baseUrl}/AuctionInfo/CreateWebText`;
export const UPDATEWEBTEXT = `${baseUrl}/AuctionInfo/UpdateWebText`;
export const GETAUCTOMATEDEMAILBYAUCTIONID = `${baseUrl}/AuctionInfo/GetAutomatedMailByAuctionId`;
export const CREATEAUTOMATEDEMAIL = `${baseUrl}/AuctionInfo/CreateAutomatedMail`;
export const UPDATEAUTOMATEDEMAIL = `${baseUrl}/AuctionInfo/UpdateAutomatedMail`;
export const CREATEAUCTIONCHECKLIST = `${baseUrl}/AuctionInfo/CreateAuctionCheckList`;
export const UPDATEAUCTIONCHECKLIST = `${baseUrl}/AuctionInfo/UpdateCheckList`;
export const GETLOGOPHOTOSBYAUCTIONID = `${baseUrl}/AuctionInfo/GetPhotosByAuctionId`;
export const LOGOPHOTOS = `${baseUrl}/AuctionInfo/CreateAuctionLogoPhotos`;
export const UPDATELOGOPHOTOS = `${baseUrl}/AuctionInfo/UpdateAuctionLogoPhotos`;
export const GETALLAUCTIONINFO = `${baseUrl}/AuctionInfo/GetAllAuctionInfo`;
export const DELETEAUCTION = `${baseUrl}/AuctionInfo/DeleteAuction`;
export const GETAUTOINVOICEBYAUCTIONID = `${baseUrl}/AuctionInfo/GetAutoInvoingByAuctionId`;
export const GETINVOICEFOOTERBYAUCTIONID = `${baseUrl}/AuctionInfo/GetInvoiceFooterByAuctionId`;
export const CREATEAUTOINVOICE = `${baseUrl}/AuctionInfo/CreateAutoInvoice`;
export const UPDATEAUTOINVOICE = `${baseUrl}/AuctionInfo/UpdateAutoInvoice`;
export const CREATEINVOICEFOOTER = `${baseUrl}/AuctionInfo/CreateInvoiceFooter`;
export const UPDATEINVOICEFOOTER = `${baseUrl}/AuctionInfo/UpdateInvoiceFooter`;
export const CREATEEINVOICE = `${baseUrl}/AuctionInfo/CreateEInvoice`;
export const GETSELLERREPORTBYAUCTIONID = `${baseUrl}/AuctionInfo/GetSellerReportByAuctionId`;
export const CREATESELLERREPORT = `${baseUrl}/AuctionInfo/CreateSellerReport`;
export const UPDATEAUCTIONSELLERREPORT = `${baseUrl}/AuctionInfo/UpdateSellerReport`;
export const CREATEBUYERSPREMIUM = `${baseUrl}/AuctionInfo/CreateBuyerPremium`;
export const UPDATEBUYERSPREMIUM = `${baseUrl}/AuctionInfo/UpdateValueBuyerPremium`;
export const GETBPBYAUCTIONID = `${baseUrl}/AuctionInfo/GetBuyerPremiumByAuctionId`;
export const CREATEBIDDERNUMBERRAGE = `${baseUrl}/AuctionInfo/CreateBidderNumberRange`;
export const UPDATEBIDDERNUMBERRAGE = `${baseUrl}/AuctionInfo/UpdateBidderNumber`;
export const GETCOMMCATBYAUCTIONID = `${baseUrl}/AuctionInfo/GetCommissionCategoriesByAuctionId`;
export const CREATECOMMISSIONCATEGORY = `${baseUrl}/AuctionInfo/CreateValueCommissionCategories`;
export const UPDATECOMMISSIONCATEGORY = `${baseUrl}/AuctionInfo/UpdateCommissionCategories`;
export const CREATEINCREMENT = `${baseUrl}/AuctionInfo/CreateValueIncrement`;
export const UPDATEINCREMENT = `${baseUrl}/AuctionInfo/UpdateValueIncrement`;
export const GETAUCTIONINFOBYID = `${baseUrl}/AuctionInfo/GetAuctionById`;
export const UPDATEAUCTIONINFO = `${baseUrl}/AuctionInfo/UpdateAuctionInfo`;
export const GETEINVOICEBYID = `${baseUrl}/AuctionInfo/GetByIdAuctionEInvoice`;
export const GETAUCTIONCHECKLIST = `${baseUrl}/AuctionInfo/GetByIdAuctionCheckList`;
export const GETEINVOICEBYAUCTIONID = `${baseUrl}/AuctionInfo/GetAuctionEInvoiceByAuctionId`;
export const UPDATEEINVOICE = `${baseUrl}/AuctionInfo/UpdateEInvoice`;
export const CREATEAUCTIONLOTS = `${baseUrl}/AuctionInfo/CreateAuctionLots`;
export const UPDATEAUCTIONLOTS = `${baseUrl}/AuctionInfo/UpdateAuctionLots`;
export const AUCTIONADVANCESEARCH = `${baseUrl}/AuctionInfo/GetAdvancedSearchFilter`;
export const GETALLFUTUREAUCTION = `${baseUrl}/AuctionInfo/GetAllFutureDateAuction`;
export const GENERATEAUCTIONCODE = `${baseUrl}/AuctionInfo/GenerateAuctionCode`;
export const CHECKAUCTIONCODEEXISTS = `${baseUrl}/AuctionInfo/IsAuctionCodeExists`;
export const SOLDAUCTIONLIST = `${baseUrl}/AuctionInfo/GetSoldAuctionDetail`;
export const GETAUCTIONSTATUS = `${baseUrl}/AuctionInfo/GetAuctionStatus`;
export const UPDATEAUCTIONRESULTSCONFIG = `${baseUrl}/AuctionCloseResultLotConfig/UpdateAuctionCloseResultLotConfig`;
export const GETAUCTIONRESULTCONFIG = `${baseUrl}/AuctionCloseResultLotConfig/GetByAuctionCloseResultLotConfigId`;
export const ISSELLERASSOCIATED = `${baseUrl}/AuctionInfo/IsSellerAssociatedWithLot`;
export const UPDATEAUCTIONSELLER = `${baseUrl}/AuctionInfo/UpdateAuctionSellerMapping`;
export const GETAUCTIONLOTS = `${baseUrl}/AuctionInfo/GetAuctionByLotsByAuctionId`;
export const GETSALESREPINFOBASEDONAUCTION = `${baseUrl}/AuctionInfo/GetSalesRepInfoBasedOnAuction`;
export const GETAUCTIONSELLERMAPPING = `${baseUrl}/AuctionInfo/GetAuctionSellerMapping`;
export const GETCUSTOMERBYAUCTIONID = `${baseUrl}/AuctionInfo/GetCustomerInfoBasedOnAuction`;
export const GETINVOICEINFOBASEDONAUCTION = `${baseUrl}/AuctionInfo/GetInvoiceInfoBasedOnAuction`;
export const GETALLAUCTIONLIST = `${baseUrl}/AuctionInfo/GetAuctionInfo`;
export const GETCHECKLISTBYAUCTIONID = `${baseUrl}/AuctionInfo/GetCheckListByAuctionId`;
export const GETSALESRECORDBASEDONAUCTION = `${baseUrl}/AuctionInfo/GetSalesRecordBasedOnAuction`;
// get Email reports
export const GETAUCTIONREPORTSBYAUCTIONID = `${baseUrl}/AuctionInfo/GetAuctionReportsByAuctionId`;

export const UPDATEAUCTIONBLOBLOGOPHOTO = `${baseUrl}/AuctionInfo/UpdateAuctionBlobLogoPhotos`
export const GETBLOBPHOTOSBYAUCTIONID = `${baseUrl}/AuctionInfo/GetBlobPhotosByAuctionId`;
export const SEARCHMASTERSELLERINAUCTION = `${baseUrl}/AuctionInfo/SearchMasterSellerInAuction`;
export const SEARCHAUCTIONSELLERINAUCTION = `${baseUrl}/AuctionInfo/SearchAuctionSellerInAuction`;

// API route for Auction close process 

export const GETAUCTIONDATESBYAUCTIONID = `${baseUrl}/AuctionInfo/GetAuctionDatesBasedOnAuctionId`
export const RUNAUCTIONSOLDPROCESS = `${baseUrl}/AuctionInfo/AuctionSoldProcessForInventory`

// Value Default
export const GETALLVALUEDEFAULT = `${baseUrl}/ValueDefault/GetAllObjectType`;
export const UPDATEVALUEDEFAULT = `${baseUrl}/ValueDefault/SaveValueDefault`;

// Dashboard
export const GETALLCOUNT = `${baseUrl}/Dashboard/GetAllCount`;
export const GETALLAUCTIONS = `${baseUrl}/Auction/GetAllAuction`;
export const GETALLTRANSPORTERS = `${baseUrl}/Transporter/GetAllTransporter`;
export const ADVANCEDFILTERTRANSPORTERS = `${baseUrl}/Transporter/AdvancedSerachFilter`;
export const GETAUCTIONCHARTDATA = `${baseUrl}/Dashboard/GetAllAuctionForChart`;
export const GETCUSTOMERCHARTDATA = `${baseUrl}/Dashboard/GetAllCustomerForChart`;
export const GETINVENTORYCHARTDATA = `${baseUrl}/Dashboard/GetAllInventoryForChart`;
export const GETSALESCHARTDATA = `${baseUrl}/Dashboard/GetAllSalesForChart`;
export const GETSELLINGCATEGORIES = `${baseUrl}/Dashboard/GetAllSellingCategories`;
export const UPDATEBLOBLOTLOGOPHOTOS = `${baseUrl}/Inventory/UpdateBlobLotLogoPhotos`;
export const GETBLOBPHOTOSBYLOTID = `${baseUrl}/Inventory/GetBlobPhotosByLotId`;
export const GETAUCTIONOVERVIEWREPORT = `${baseUrl}/Dashboard/AuctionOverviewReport`;

//Tax & Currency
export const CREATEAVALARA = `${baseUrl}/Avalara/CreateAvalara`;
export const UPDATEAVALARA = `${baseUrl}/Avalara/UpdateAvalara`;
export const GETAVALARABYID = `${baseUrl}/Avalara/GetAvalaraById`;
export const GETTAXRATEBYLOTADDRESS = `${baseUrl}/Avalara/GetTaxRateByLotAddress`;
export const GETTAXRATEBYLOTADDRESSID = `${baseUrl}/Avalara/GetTaxRate`;
export const CREATETAXRATE = `${baseUrl}/Avalara/CreateTaxRate`;
export const UPDATETAXRATE = `${baseUrl}/Avalara/UpdateTaxRAte`;
export const GETTAXRATEBYID = `${baseUrl}/Avalara/GetByTaxRateId`;
export const DELETETAXRATEBYID = `${baseUrl}/Avalara/DeleteTaxRate`
export const GETALLTAXRATE = `${baseUrl}/Avalara/GetAllTaxRate`;
export const GETLISTTAXRATEBYLOTADDRESS = `${baseUrl}/Avalara/GetListTaxRateByLotAddress`;
export const GETTAXSUMMARYBYJURISDICTION = `${baseUrl}/Avalara/GetTaxSummaryByJurisdiction`;
export const GETTAXEXEMPTSUMMARY = `${baseUrl}/Avalara/TaxExemptSummary`;

// Notifications
export const GETNOTIFICATIONS = `${baseUrl}/NotificationLogs/GetAllNotification`;
export const MARKASREADNOTIFCATION = `${baseUrl}/NotificationLogs/ReadNotification`;
export const MARKALLASREADNOTIFCATION = `${baseUrl}/NotificationLogs/ReadAllNotification`;

//Reports
export const GETLISTINGNOTIFICATIONS = `${baseUrl}/NotificationLogs/GetAllListingNotification`;

// credit card
export const GETALLCARDSBYCUSTOMERID = `${baseUrl}/CustomerCCInfo/GetCustomerAllCards`;

// Get Current Date and Time in UTC irrespective of system time zone
export const GETCURRENTDATETIME = `${baseUrl}/Common/GetCurrentDateTime`;

export const FICTITIOUSBIDDER = `${baseUrl}/FictitiousBidders/FictitiousBidderReport`;

// Email template new endpoint 
export const GETALLTEMPLATE = `${baseUrl}/EmailTemplate/GetAllTemplate`;
export const GETBYEMAILTEMPLATEID = `${baseUrl}/EmailTemplate/GetTemplateById`;
export const UPDATETEMPLATE = `${baseUrl}/EmailTemplate/UpdateTemplateMaster`;
export const DELETETEMPLATE = `${baseUrl}/EmailTemplate/DeleteTemplateMaster`;


//Bid management
export const GETALLBIDMANAGEMENT = `${customerPortalUrl}/AdminBidManagement/GetAllManagementBids`;
export const CREATEBIDMANAGEMENT = `${customerPortalUrl}/AdminBidManagement/CreateBidManagement`;
export const UPDATEBIDMANAGEMENT = `${customerPortalUrl}/AdminBidManagement/UpdateBidManagement`;
export const DELETEBIDMANAGEMENT = `${customerPortalUrl}/AdminBidManagement/DeleteBidManagement`;
export const BIDMANAGEMENTADVANCEFILTER = `${customerPortalUrl}/AdminBidManagement/GetAllBidsManagementSearchFilter`;
export const BIDMANAGEMENTGETBYID = `${customerPortalUrl}/AdminBidManagement/GetByBiddingManagementId`;
