import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const setTheme = (data: any): Action => ({
    type: action.SETTHEMECOLOR,
    payload: data
})

export const setColorMode = (data: any): Action => ({
    type: action.SETCOLORMODE,
    payload: data
})