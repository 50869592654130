import React from "react";
import { SortableElement } from "react-sortable-hoc";

const SingleElement = SortableElement(({ uniqueIndex, uniqueId, url, data, onCheckboxChange, imagesCheckedToDel }: any) => {
    return (
        <>
            <div className="img_checkbox_wrap">
                <input
                    type="checkbox" 
                    className="form-check-input"
                    name={`images_unique_${uniqueIndex}`}
                    onChange={() => onCheckboxChange(uniqueId)}
                    checked={imagesCheckedToDel.includes(data.uniqueId)}
                />
                <img src={url} width={100} />
            </div>
        </>
    )
})

export default SingleElement