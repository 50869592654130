import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from "axios";
import { useEffect, useState } from "react";
import { GETLOTTEMPBYID } from "../../common/apiConstatnts";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { CKEditor } from "ckeditor4-react";
import { decryptId } from "../../Encryption/Encryption";
import { toast } from "react-toastify";
import { CommonMessages, errorMessages } from "../../common/messageConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
// import { decryptId } from "../../Encryption/Encryption";

const LotTempPreview = () => {
    const [passwordType, setPasswordType] = useState("password");
    const [loading, setLoading] = useState(false);
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConsant.DASHBOARD },
        { label: 'Templates', url: routeConsant.LOTTEMPLATES },
        { label: 'Lot Template Preview', url: '' }
    ]

    const [tempFields, setTempFields] = useState([]);
    const [submitButton, setSubmitButton] = useState<any>({});
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const NAME = vars[6];

    const Name = localStorage.getItem('LotTempName')


    const getLotTempData = () => {
        axios.get(`${GETLOTTEMPBYID}?lotTemplateId=${decryptId(ID)}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const lotTempData = response?.data?.data?.lstLotTemplateFields;
                setSubmitButton(lotTempData.find(((field: any) => field.fieldType === 'button')))
                setTempFields(lotTempData)
            }
        }) .catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    useEffect(() => {
        setLoading(true);
        getLotTempData();
    }, []);

    return (
        <><Sidebar /><div className="content pt-0">
            <InnerHeader breadCrumb={breadcrumbData} title='Lot Template'>
            </InnerHeader>
            <div className='container-fluid for-fix-bottom-space'>
                <div className="row top_border justify-content-center">
                    <div className="col-xxl-10 pt-4 pb-4">
                        <div className="row g-3 mb-3">
                            <div className="col-12">
                                <div className="mb-3 border-bottom pb-3">Lot Template <b>{Name}</b> Preview</div>
                            </div>
                            {tempFields.map((fields: any) => {
                                return (
                                    <>
                                        {/* <div className="mt-0 mb-4">
                                        <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                        <input type={fields.fieldType} placeholder={fields.fieldLabel} />
                                    </div> */}
                                        {fields.fieldType === 'shorttext' || fields.fieldType === "textfield" || fields.fieldType === 'text' && (
                                            <div className="col-lg-6">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <input type="text" className="form-control" placeholder={fields.fieldLabel} />
                                                </>
                                            </div>
                                        )}

                                        {fields.fieldType == "textfield" && (
                                            <div className="col-lg-6">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <input type="text" className="form-control" placeholder={fields.fieldLabel} />
                                                </>
                                            </div>
                                        )}

                                        {fields.fieldType == "number" && (
                                            <div className="col-lg-6">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <input type="number" className="form-control" placeholder={fields.fieldLabel} />
                                                </>
                                            </div>
                                        )}

                                        {fields.fieldType == "password" && (

                                            <div className="col-lg-6">
                                                <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                <div className="form-icon-container for_password mt-0 mb-0">
                                                    <input
                                                        className="form-control form-icon-input pt-2 "
                                                        id="password"
                                                        type={passwordType}
                                                        placeholder="Password"
                                                    />
                                                    <i
                                                        onClick={togglePassword}
                                                        className={
                                                            passwordType == "password"
                                                                ? "fas fa-eye-slash"
                                                                : "fas fa-eye"
                                                        }
                                                    ></i>
                                                    <span className="fas fa-key text-900 d-flex align-items-center fs--1 pb-3 form-icon "></span>
                                                </div>
                                            </div>
                                        )}

                                        {fields.fieldType === 'content' && (
                                            <div className="col-lg-12">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <CKEditor
                                                        onInstanceReady={(event) => {
                                                            event.editor.on("beforeCommandExec", function (event: any) {
                                                                // Show the paste dialog for the paste buttons and right-click paste
                                                                if (event.data.name == "paste") {
                                                                    event.editor._.forcePasteDialog = true;
                                                                }
                                                                // Don't show the paste dialog for Ctrl+Shift+V
                                                                if (event.data.name == "pastetext" && event.data.commandData.from == "keystrokeHandler") {
                                                                    event.cancel();
                                                                }
                                                            })
                                                        }} />
                                                </>
                                            </div>
                                        )}

                                        {fields.fieldType === 'phoneNumber' && (
                                            <div className="col-lg-6">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <PhoneInput
                                                        specialLabel={""}
                                                        country={"usa"}
                                                        placeholder="+1(231)231-2341"
                                                    />
                                                </>
                                            </div>
                                        )}
                                        {fields.fieldType === 'datetime' && (
                                            <div className="col-lg-6">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="datetime-local"
                                                        placeholder="" />
                                                </>
                                            </div>
                                        )}
                                        {fields.fieldType === "selectboxes" && (
                                            <div className="col-sm-4">
                                                <>
                                                    <label className="form-label" htmlFor="text">{fields.fieldLabel}</label>
                                                    <select className="form-select" aria-label="Default select example">
                                                        {fields?.lstLotTemplateFieldValue?.map((value: any) => {
                                                            return (
                                                                <option value={1}>{value.fieldValue}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </>
                                            </div>
                                        )}
                                        {fields.fieldType === 'multipleChoice' && (
                                            <div className="col-sm-4">
                                                <>
                                                    {fields?.lstLotTemplateFieldValue?.map((value: any) => {
                                                        return (
                                                            <>
                                                                <div className="form-check">
                                                                    <input className="form-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">{value.fieldValue}</label>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                </>
                                            </div>
                                        )}
                                        {fields.fieldType === 'checkbox' && (
                                            <div className="col-sm-4">
                                                <>
                                                    <label className="form-label">{fields.fieldLabel}</label>
                                                    {fields?.lstLotTemplateFieldValue?.map((value: any) => {
                                                        return (
                                                            <>
                                                                <div className="form-check d-flex aling-items-center">
                                                                    <input className="form-check-input mt-0 me-1" type="checkbox" value="" id="flexCheckDefault" />
                                                                    <label className="form-label" htmlFor="flexCheckDefault">{fields.fieldLabel}</label>
                                                                </div>
                                                            </>
                                                        )
                                                    })}

                                                </>
                                            </div>
                                        )}
                                        {fields.fieldType === "textarea" && (
                                            <div className="col-lg-12">
                                                <>
                                                    <label className="form-label" htmlFor="flexCheckDefault">{fields.fieldLabel}</label>
                                                    <div className="">
                                                        <textarea
                                                            className="form-control form-icon-input"
                                                            rows={4}
                                                            cols={4}
                                                        ></textarea>
                                                    </div>
                                                </>

                                            </div >
                                        )}
                                        {fields.fieldType === "radio" && (
                                            <div className="col-sm-4">
                                                <>
                                                    <label className="form-label">{fields.fieldLabel}</label>
                                                    <div className="d-flex">
                                                        {fields?.lstLotTemplateFieldValue?.map((value: any) => {
                                                            return (
                                                                <div className="form-check me-3">
                                                                    <input type="radio" className="form-check-input " name={fields.fieldLabel} />
                                                                    <label className="form-check-label">{value.fieldValue}</label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            </div >
                                        )}
                                        {fields.fieldType === "email" && (
                                            <div className="col-lg-6">
                                                <>
                                                    {fields?.lstLotTemplateFieldValue?.map((value: any) => {
                                                        return (
                                                            <>
                                                                <label className="form-label" htmlFor="text">{value.fieldLabel}</label>
                                                                <input
                                                                    className="form-control form-icon-input"
                                                                    type="email"
                                                                    placeholder="" />
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            </div >
                                        )}
                                    </>
                                )
                            })}
                            <span>
                                {submitButton && submitButton?.fieldType === "button" && (
                                    <div className="col-lg-6">
                                        <button className="btn btn-primary" type='submit'>{submitButton.fieldLabel}</button>
                                    </div >
                                )}
                            </span>
                        </div>
                        <div className="pt-4 mt-4 border-top">
                            <div className="d-flex flex-wrap justify-content-end">
                            </div>
                        </div>

                        <div className="border-top p-3 bg-white d-flex flex-wrap justify-content-end fix-bottom" data-wizard-footer="data-wizard-footer">
                            <div className="d-flex align-items-center ms-auto">
                                <div className="d-flex pager wizard list-inline mb-0">
                                    <Link className="btn btn-primary me-1 mb-1" type="button" to={routeConsant.LOTTEMPLATES}>{CommonMessages.backButton}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div ></>
    )
}

export default LotTempPreview;