import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Breadcrumb } from "../../common/interface";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import * as routeConsant from "../../common/routeConstants";
import {
  IMAGECONFIGAPI,
  IMAGECONFIGAPICREATE,
  IMAGECONFIGAPIUPDATE,
} from "../../common/apiConstatnts";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { CommonMessages, ImageConfigurationMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

export interface ImageConfigProps { }

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConsant.DASHBOARD },
  { label: "Images & API", url: "" },
];

const ImageConfig = () => {
  const initialData: any = {
    companyId: 0,
    AddExternal: false,
    ImageSizeLarge: 0,
    ImageSizeMedium: 0,
    ImageSizeThumb: 0,
    KeepOriginal: false,
  };

  const [formValues, setFormValues] = useState<any>(initialData);
  const [loading, setLoading] = useState(false);

  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;
  const schema: any = yup.object().shape({
    ImageSizeLarge: yup
      .string()
      .required("Image size large is a required field")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(/^\d+$/, "Field must contain only numbers")
      .min(1)
      .max(4, 'Please enter upto 4 digits'),
    ImageSizeMedium: yup
      .string()
      .required("Image size medium is a required field")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(/^\d+$/, "Field must contain only numbers")
      .min(1)
      .max(4, 'Please enter upto 4 digits'),
    ImageSizeThumb: yup
      .string()
      .nullable()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(/^\d*$/, "Field must contain only numbers")
      .max(4, 'Please enter up to 4 digits'),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    setLoading(true);
    axios.get(IMAGECONFIGAPI, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return
      }
      else {
        let imageConfigData = response.data.data;
        if (imageConfigData.imageConfigurationId === null) {
          reset(initialData);
          return;
        }
        const initialFormDataSet = {
          companyId: localStorage.getItem("companyId"),
          id: imageConfigData.imageConfigurationId,
          AddExternal: imageConfigData.imagesInventoryToExternal,
          ImageSizeLarge:
            imageConfigData.largeImageSize == 0
              ? null
              : imageConfigData.largeImageSize,
          ImageSizeMedium:
            imageConfigData.mediumImageSize == 0
              ? null
              : imageConfigData.mediumImageSize,
          ImageSizeThumb: imageConfigData.thumbImageSize,
          KeepOriginal: imageConfigData.keepOriginalSize,
        };

        setFormValues(initialFormDataSet);
        reset(initialFormDataSet);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
  });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    if(superAdminRole==="True"){
      const dataOriginal = getValues();
      const data = trimObjectValues(dataOriginal);
      const imageConfigurationId = formValues.id;
      const AddExternal = data.AddExternal;
      const companyId = parseInt(data.companyId);
      const imageLarge = parseInt(data.ImageSizeLarge);
      const imageMid = parseInt(data.ImageSizeMedium);
      const imageThumb = parseInt(data.ImageSizeThumb);
      const KeepOriginal = data.KeepOriginal;
      const url =
        imageConfigurationId === 0 ? IMAGECONFIGAPICREATE : IMAGECONFIGAPIUPDATE;
      axios
        .post(
          url,
          {
            imageConfigurationId: imageConfigurationId,
            companyId: companyId,
            imagesInventoryToExternal: AddExternal,
            imageFor: "web",
            largeImageSize: imageLarge,
            mediumImageSize: imageMid,
            thumbImageSize: imageThumb,
            keepOriginalSize: KeepOriginal,
          },
          { ...config, ContentType: "application/json" }
        )
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return
            }
            else {
              if (imageConfigurationId === 0) {
                toast.success(ImageConfigurationMessages.imgConfCreated, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              } else {
                toast.success(ImageConfigurationMessages.imgConfUpdated, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            }
          }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    }
    else{
      setLoading(false);
      toast.error("Only super admin has permission to perform this operation on the page.",{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
    });
    }
  };
  return (
    <>
      <CommonHelmet title={"Image Config - "} name={"imageConfig"} content={"Image Config"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid for-fix-bottom-space">
            <div className="row top_border justify-content-center">
              <div className="col-xxl-10">
                <fieldset className="mb-3 mt-5">
                  <h4 className="mb-4">Image Configuration</h4>
                  <h5>Inventory Image Size Preferences</h5>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-check mb-2 mt-3">
                        <input
                          className="form-check-input"
                          id="Save"
                          type="checkbox"
                          {...register("AddExternal")}
                        />
                        <label
                          className="form-check-label fw-400 fs-14px"
                          htmlFor="Save"
                        >
                          Save Images Added In Inventory To External
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <p>Image Thumbnails Max Dimensions In Pixels</p>
                  <div className="row mb-3">
                    <div className="col-sm-4">
                      <label className="form-label" htmlFor="text">
                        Image Size Large <sup className="text-danger">*</sup>{" "}
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="Image Size Large "
                        maxLength={5}
                        {...register("ImageSizeLarge")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors?.ImageSizeLarge && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.ImageSizeLarge.message}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-4">
                      <label className="form-label" htmlFor="text">
                        Image Size Medium <sup className="text-danger">*</sup>{" "}
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        inputMode="numeric"
                        placeholder="Image Size Medium"
                        maxLength={5}
                        {...register("ImageSizeMedium")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors?.ImageSizeMedium && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.ImageSizeMedium.message}
                        </span>
                      )}
                    </div>
                    <div className="col-sm-4">
                      <label className="form-label" htmlFor="text">
                        Image Size Thumb
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        inputMode="numeric"
                        placeholder="Image Size Thumb"
                        maxLength={5}
                        {...register("ImageSizeThumb")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors?.ImageSizeThumb && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors?.ImageSizeThumb.message}
                        </span>
                      )}
                    </div>
                    <div className="mt-0 pl-2">
                      <div className="form-check mb-2 mt-3">
                        <input
                          className="form-check-input"
                          id="Resize"
                          type="checkbox"
                          {...register("KeepOriginal")}
                          
                        />
                        <label
                          className="form-check-label fw-400 fs-14px"
                          htmlFor="Resize"
                        >
                          Do Not Resize Large Image keep Original Size
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>

                {/* Static Footer */}
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                            <Link
                                                to={routeConsant.DASHBOARD}
                                                className="btn btn-outline-danger me-auto"
                                                type="button"
                                                data-wizard-prev-btn="data-wizard-prev-btn"
                                            >
                                                {CommonMessages.cancelButton}
                                            </Link>
                  <button
                    className="btn btn-primary mx-2"
                    type="submit"
                    data-wizard-next-btn="data-wizard-next-btn"
                  >
                   {CommonMessages.saveButton}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ImageConfig;
