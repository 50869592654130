// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from 'uuid';
import { decryptId } from "../../../../../Encryption/Encryption";
import defaultLotImg from "../../../../../assets/defaultLotImage.png";
import dpa_logo from "../../../../../assets/img/dpa-logo-watermark.svg";
import { GETBLOBPHOTOSBYLOTID, GETCOMPANYBYIDUSINGBLOB, GETPROFILE, UPDATEBLOBLOTLOGOPHOTOS, GETDOCSURLBYLOTID, UPDATELOTDOCURLLIST } from "../../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages, errorMessages } from "../../../../../common/messageConstants";
import * as actions from '../../../../../common/types';
import { setCloneTab, setCreateTab, setInventoryInfoActive, setStep } from "../../../../../store/actions/Inventory";

import ReOrder from '../../../../../assets/reorder.svg';
import Fancybox from '../../../../Auction/NewAuction/AuctionInfo/Fancybox';
import { LOTLOGOPHOTO } from "../../../InventoryConstants";
import SortableGrid from "./SortableGrid";
import { yupResolver } from "@hookform/resolvers/yup";
import Tippy from "@tippyjs/react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { ERROR_MESSAGES, trimObjectValues } from "../../../../../Utils/Utils";
import deleteIcon from '../../../../../assets/delet_icon.svg';
import urlUpload from '../../../../assets/img/url_upload.svg';
import videoUpload from '../../../../assets/img/video_upload.svg';
import { EMAIL, EMAIL_MESSAGE, URL as URL_REG, URL_MESSAGE } from "../../../../../common/regexConstants";
import * as routeConstant from '../../../../../common/routeConstants';
import handleError from "../../../../../Utils/ApiCommonErrorMessages";
import { LOTDOCURLS } from "../../../InventoryConstants";

const Media = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const ID = params.id ? decryptId(params.id) : 0;
    const getUrl = window.location.pathname;
    const [defaultImgFile, setDefaultImgFile] = useState(null)
    const IsCloned = getUrl.split('/')[2].includes('clone') ? true : false
    const inventoryInfoData = useSelector((state: any) => state.inventory?.lotPage)
    const mode = useSelector((state: any) => state.inventory.mode);
    const lotId = useSelector((state: any) => state.inventory.lotId)
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
    const createTabs = useSelector((state: any) => state.inventory.createTabs)

    const swiperRef: any = useRef(null);
    const [loading, setLoading] = useState(false);
    const [defaultSlide, setDefaultSlide] = useState(0);
    const [imagesData, setImagesData] = useState<any>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [logoPhotos, setlogoPhotos] = useState<any>([])
    const imgURL = logoPhotos.length == 1 && logoPhotos[0].photos.includes('defaultImg')
    const activeImagesData = imagesData?.filter((i: any) => i.isActive === true)
    const [imagesForSort, setImagesForSort] = useState<any[]>([])
    const sidebarOpen = useSelector((state: any) => state?.global.sidebarOpen)
    const [cmpLogo, setCmpLogo] = useState('');
    const [thumbsSwiper, setThumbsSwiper]: any = useState(null);
    const [imagesToDelId, setImagesToDelId] = useState<any>([])
    const [btnText, setBtnTxt] = useState<any>("")
    const [searchText, setSearchText] = useState<string>('')

    const initialData = {
        lotDocUrlId: 0,
        lotId: mode === "clone" ? cloneLotId : lotId,
        notificationEmail: "",
        isActive: true,
        isDelete: false,
    }

    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [initialFormValues, setInitialFormValues] = useState(initialData);
    const [getUrlData, setUrlData] = useState<any>([]);
    const [getVideoUrlData, setVideoUrlData] = useState<any>([]);
    const [urlOriginalData, setUrlOriginalData] = useState<any>([]);
    const [videoOriginialUrlData, setVideoOriginialUrlData] = useState<any>([]);
    const [modalTitle, setModalTitle] = useState<any>('');
    const [defaultVideoUrlId, setDefaultVideoUrlId] = useState<any>();
    const [type, setType] = useState<string>("")
    const [isData, setIsData] = useState<boolean>(false)

    const initialData2 = {
        lotDocUrlId: 0,
        lotId: mode === "clone" ? cloneLotId : lotId,
        inventoryUrl: "",
        isActive: true,
        isDelete: false,
        uniqueId: uuidv4(),
        isDefault: false
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            enctype: "multipart/form-data",
        }
    };

    const cmpConfig: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            enctype: "multipart/form-data",
        },
        params: {
            id: localStorage.getItem("companyId"),
        },
    };

    const getCompanyProfile = async () => {
        try {
            const response = await axios.get(GETCOMPANYBYIDUSINGBLOB, cmpConfig);
            const logoUrl = `${response?.data?.data?.watermarkImage}?${response?.data?.data?.watermarkImagesastoken}`
            setCmpLogo(logoUrl);
        } catch (error) {
            console.error('Error fetching company profile:', error);
        }
    };

    const handleUpload = async (e: any) => {
        const filesLength = e.target.files?.length || 0;
        // Checking if the file type is allowed or not
        const allowedTypes = ["image/jpg", "image/png", "image/jpeg", "image/gif"];
        if (!allowedTypes.includes(e.target.files[0]?.type)) {
            toast.warning(errorMessages.formatNotAllowed, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            let maxDisplayOrder = 0
            if (imagesData.length > 0) {
                maxDisplayOrder = imagesData.reduce((max: any, obj: any) => {
                    return obj.displayOrder > max ? obj.displayOrder : max;
                }, -Infinity);
            }
            let order = maxDisplayOrder
            setDefaultImgFile(null)

            let uploadedData: any = []
            for (let i = 0; i < filesLength; i++) {
                const file = e.target.files[i];
                if (!allowedTypes.includes(file?.type)) continue;
                const imgObj = {
                    lotLogoPhotosId: 0,
                    lotId: mode !== "clone" ? parseInt(lotId) : parseInt(cloneLotId),
                    photos: file,
                    displayOrder: order + 1,
                    inventoryOrDefaults: 0,
                    companyId: localStorage.getItem('companyId'),
                    isActive: true,
                    isDelete: false,
                    uniqueId: uuidv4()
                }
                uploadedData.push(imgObj);
                order++
            }

            uploadedData.forEach((image: any) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) return;

                const img = new Image();
                img.src = URL.createObjectURL(image.photos);

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;

                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    const watermarkImage = new Image();
                    watermarkImage.crossOrigin = "Anonymous";
                    const fileExtension = cmpLogo.split('.').pop()?.toLowerCase();
                    if (!cmpLogo || fileExtension === 'png') {
                        watermarkImage.src = dpa_logo;
                    } else {
                        watermarkImage.src = dpa_logo;
                        // watermarkImage.src = cmpLogo ? `${base}${cmpLogo}` : dpa_logo;
                        // watermarkImage.src = dpa_logo;
                    }

                    watermarkImage.onload = () => {
                        const watermarkWidth = canvas.width * 0.2; // Adjust watermark size as needed
                        const watermarkHeight = (watermarkWidth / watermarkImage.width) * watermarkImage.height;
                        const offsetX = canvas.width - watermarkWidth - 20; // Distance from the right edge of the canvas
                        const offsetY = canvas.height - watermarkHeight - 20; // Distance from the bottom edge of the canvas
                        ctx.drawImage(watermarkImage, offsetX, offsetY, watermarkWidth, watermarkHeight);

                        // Convert the canvas to a blob and create a file
                        canvas.toBlob((blob: any) => {
                            const watermarkedImage = new File([blob], image.photos.name, { type: image.photos.type });
                            setImagesData((prevData: any) => [...prevData, { ...image, photos: watermarkedImage }]);
                        }, image.photos.type);
                    };
                };
            });
        }
    };

    const handleDeleteImage = (uniqueId: any) => {
        // swiperRef.current.destroy();
        const delIndex = activeImagesData.indexOf(activeImagesData.find((data: any) => data.uniqueId === uniqueId))
        const updateImages = imagesData.map((data: any) => {
            if (data.uniqueId === uniqueId) {
                return { ...data, isActive: false, isDelete: true, inventoryOrDefaults: 0 }
            }
            return data
        })
        const allDeleted = updateImages.every((uData: any) => uData.isDelete === true)
        if (allDeleted) {
            setThumbsSwiper(null)
        }
        setImagesData(updateImages)
        setDefaultSlide(delIndex)
    };

    const getInventoryById = (id: any) => {
        setLoading(true)
        let imgData: any;
        axios.get(`${GETBLOBPHOTOSBYLOTID}/?lotId=${id}`, { ...config }).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                setImagesData([])
                setThumbsSwiper(null)
                setlogoPhotos([])
            }
            else {
                imgData = response.data.data;
                setlogoPhotos(imgData)
            }
        }).catch((error) => {
            handleError(error);
        })
    }
    const setDefaultImage = (id: any) => {
        const newData = imagesData.map((image: any) => {
            if (image.uniqueId == id) {
                return { ...image, inventoryOrDefaults: 1 }
            }
            return { ...image, inventoryOrDefaults: 0 }
        })?.filter((iData: any) => iData.isActive === true)
        const getIndex = newData.indexOf(newData.find((data: any) => data.inventoryOrDefaults === 1))
        setDefaultSlide(getIndex)
        setImagesData(newData)
        toast.success(InventoryMessages.logoPhotoSetdefault, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
    }

    const logoPhotosFormData = (data: any, id: any) => {
        const isDefaultImagePresent = logoPhotos.length == 1 && logoPhotos[0].photos.includes('defaultImg')
        const isDefaultImagePresentInExistingList = data?.find((data: any) => data.inventoryOrDefaults === 1)
        const formData: any = new FormData();
        let i = 0;
        if (isDefaultImagePresent) {
            formData.append(`photos[${0}].lotId`, parseInt(id));
            formData.append(`photos[${0}].lotLogoPhotosId`, logoPhotos[0]?.lotLogoPhotosId);
            formData.append(`photos[${0}].inventoryOrDefaults`, 0);
            formData.append(`photos[${0}].photos`, logoPhotos[0]?.photos);
            formData.append(`photos[${0}].displayOrder`, data.length > 0 ? 0 : logoPhotos[0]?.displayOrder);
            formData.append(`photos[${0}].isActive`, data.length > 0 ? false : true)
            formData.append(`photos[${0}].isDelete`, data.length > 0 ? true : false)
            i = 1
        }

        data.forEach((image: any, index: number) => {
            formData.append(`photos[${i}].lotId`, parseInt(id));
            formData.append(`photos[${i}].lotLogoPhotosId`, image.lotLogoPhotosId);
            formData.append(`photos[${i}].inventoryOrDefaults`, data?.length === 1 ? 1 : isDefaultImagePresentInExistingList ? image.inventoryOrDefaults : index === 0 ? 1 : 0);
            formData.append(`photos[${i}].photos`, image.photos);
            formData.append(`photos[${i}].displayOrder`, image?.displayOrder);
            formData.append(`photos[${i}].isActive`, image.isActive)
            formData.append(`photos[${i}].isDelete`, image.isDelete)
            i++
        });

        return formData
    }

    const handleSubmitPhotos = (e?: any) => {
        e && e.preventDefault();
        setLoading(true)
        const removeDummy = imagesData.map((image: any) => {
            if (image.lotLogoPhotosId === 0 && image.isActive === false) {
                return {}
            }
            return image
        })

        const finalData = removeDummy?.filter((data: any) => Object.keys(data)?.length > 0)
        let formData: any;
        const Id = mode === "clone" ? cloneLotId : lotId
        if (finalData.length > 0) {
            formData = logoPhotosFormData(finalData, Id)
            setDefaultImgFile(null)
            onHandleUdpateApiCall(formData, finalData)
        } else {
            formData = new FormData();
            let i = 0
            fetch(defaultLotImg)
                .then((res) => res.blob())
                .then((blob) => {
                    const defaultImgBlob: any = new File([blob], 'defaultImg.png', { type: 'image/png' })
                    // setDefaultImgFile(defaultImgBlob)
                    formData.append(`photos[${i}].photos`, defaultImgBlob);
                    formData.append(`photos[${i}].lotId`, parseInt(Id));
                    formData.append(`photos[${i}].lotLogoPhotosId`, logoPhotos[0]?.lotLogoPhotosId ?? 0);
                    formData.append(`photos[${i}].displayOrder`, 0);
                    formData.append(`photos[${i}].inventoryOrDefaults`, 1);
                    formData.append(`photos[${i}].displayOrder`, 1);
                    formData.append(`photos[${i}].isActive`, true)
                    formData.append(`photos[${i}].isDelete`, false)
                    onHandleUdpateApiCall(formData)
                })
                .catch((error) => {
                    console.error('Error fetching default image:', error);
                });
        }
    }

    async function onUpdateFormData(formData: FormData, key: any, newValue: any) {
        return new Promise((resolve, reject) => {
            // Extract existing keys
            var keys = Array.from(formData.keys());
            // Find keys that match the provided key
            var matchingKeys = keys.filter(function (formKey) {
                return formKey.startsWith(key);
            });
            // Update values for matching keys
            matchingKeys.forEach(function (matchingKey) {
                formData.set(matchingKey, newValue);
            });
            // Resolve the promise with the updated FormData object
            resolve(formData);
        });
    }

    const onHandleUdpateApiCall = (formDataActual: any, finalData: any = [], redirect: boolean = true) => {
        const Id = clonedTabs.includes(LOTLOGOPHOTO) ? cloneLotId : lotId
        if (Id > 0) {
            axios.post(UPDATEBLOBLOTLOGOPHOTOS, formDataActual, config).then(async (response) => {
                if (response?.data?.success === "Fail") {
                    setLoading(false)
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return
                } else {
                    const forFetchId = mode === "clone" ? cloneLotId : lotId
                    getInventoryById(forFetchId)
                    if (mode === "clone") {
                        dispatch(setCloneTab([...clonedTabs, LOTLOGOPHOTO]))
                    }
                    if (mode === "create") {
                        dispatch(setCreateTab([...createTabs, LOTLOGOPHOTO]))
                    }
                    setLoading(false);
                    if (redirect) {
                        toast.success(InventoryMessages.MediaUpdated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    } else {
                        swiperRef.current.swiper.slideTo(0);
                        setShowModal(false)
                        setImagesToDelId([])
                    }
                    redirect && nextStep()
                }
            }, (error) => {
                setLoading(false);
                toast.warning("Some error occured!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            });
        } else {
            let formData = logoPhotosFormData(finalData, Id)

            if (finalData?.length > 0) {
                onUploadInventoryPhotos(formData, Id)
            } else {
                onUpdateFormData(formDataActual, 'photos[0].lotId', Id).then((updatedFormData) => {
                    onUploadInventoryPhotos(updatedFormData, Id)
                });
            }
        }
    }

    /* const onHandleUdpateApiCall = (formDataActual: any, finalData: any = [], redirect: boolean = true) => {
        if (lotId) {
            axios.post(UPDATEBLOBLOTLOGOPHOTOS, formDataActual, config).then(async (response) => {
                getInventoryById(lotId)
                setLoading(false);
                if (redirect) {
                    toast.success(InventoryMessages.logoPhotosUpdated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return
                } else {
                    getInventoryById(lotId)
                    setLoading(false);
                    if (redirect) {
                        toast.success(InventoryMessages.logoPhotosUpdated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });

                    } else {
                        swiperRef.current.swiper.slideTo(0);
                        setShowModal(false)
                        setImagesToDelId([])
                    }
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });;
        } else {
            const { ...lotData } = inventoryInfoData;
            createNewLot(lotData, finalData, formDataActual)
        }
    } */

    const onUploadInventoryPhotos = (formData: any, newLotId: any) => {
        axios.post(UPDATEBLOBLOTLOGOPHOTOS, formData, config).then(async (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setLoading(false)
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return
            } else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, LOTLOGOPHOTO]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, LOTLOGOPHOTO]))
                }
                getInventoryById(newLotId);

            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const deleteLotIfNew = () => {
        dispatch({ type: actions.RESETINV })
        navigate(routeConstant.INVENTORY)
        /* if (params?.id) {
            return
        }
        Swal.fire({
            title: "Are you sure?",
            text: "Changes will be discarded!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#006800",
            cancelButtonColor: "#d33",
            confirmButtonText: "Discard",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({ type: actions.RESETINV })
                navigate(routeConstant.INVENTORY)
            }
        }); */

    }

    const handleClose = () => {
        setImagesToDelId([])
        setShowModal(false)
    }

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setImagesForSort((prevImages) => {
            const newImages = [...prevImages];
            const [removed] = newImages.splice(oldIndex, 1);
            newImages.splice(newIndex, 0, removed);
            return newImages;
        });
    };

    const getUniqueValues = (arr1: any, arr2: any) => {
        const idSet = new Set();
        const mergedArray = [];

        for (const obj of arr1) {
            if (!idSet.has(obj.uniqueId)) {
                idSet.add(obj.uniqueId);
                mergedArray.push(obj);
            }
        }

        for (const obj of arr2) {
            if (!idSet.has(obj.uniqueId)) {
                idSet.add(obj.uniqueId);
                mergedArray.push(obj);
            }
        }

        return mergedArray;
    }

    const handleReOrder = () => {
        setLoading(true)

        let changeOrder = imagesForSort.map((iData: any, iIndex: any) => {
            return { ...iData, displayOrder: iIndex + 1 }
        })
        changeOrder = getUniqueValues(changeOrder, imagesData) // get if images is deleted from slider as it's diff state

        const removeDummy = changeOrder.map((image: any) => {
            if (image.lotLogoPhotosId === 0 && image.isActive === false) {
                return {}
            }
            return image
        })

        const finalData = removeDummy?.filter((data: any) => Object.keys(data)?.length > 0)
        const Id = mode === "clone" ? cloneLotId : lotId
        let formData = logoPhotosFormData(finalData, Id)
        onHandleUdpateApiCall(formData, finalData, false)
    }

    const handleModalOpen = () => {
        const addOrder = activeImagesData.map((aData: any, aIndex: any) => {
            return { ...aData, order: aIndex + 1 }
        })
        setImagesForSort(addOrder)
        setShowModal(true)
    }

    const handleCheckboxChange = (id: any) => {
        let imagesIdx = imagesToDelId
        if (imagesToDelId.includes(id)) {
            imagesIdx = imagesIdx.filter((iData: any) => iData !== id)
        } else {
            imagesIdx = [...imagesIdx, id]
        }
        setImagesToDelId(imagesIdx)
    };

    const handleDelete = () => {
        setLoading(true)
        let dataForDel = imagesForSort // From modal
        let uniqueIds = imagesToDelId // from modal
        const deletedImageFromSlider = imagesData.filter((iData: any) => !iData.isActive) //for images deleted from slider
        const deletedUniqueIdFromSlider = deletedImageFromSlider.map((dData: any) => dData.uniqueId) //for images deleted from slider
        dataForDel = [...dataForDel, ...deletedImageFromSlider]
        uniqueIds = [...uniqueIds, ...deletedUniqueIdFromSlider]
        let modifyForDel = dataForDel.map((iData: any) => {
            if (uniqueIds.includes(iData.uniqueId)) {
                return { ...iData, isActive: false, isDelete: true }
            }
            return iData
        })

        let activeData = modifyForDel.filter((aData: any) => aData.isActive)
        let inActiveData = modifyForDel.filter((iaData: any) => !iaData.isActive)
        modifyForDel = [...activeData, ...inActiveData]
        modifyForDel = modifyForDel.map((mData: any, mIndex: any) => {
            return { ...mData, displayOrder: mData.isActive ? mIndex + 1 : "" }
        })

        const removeDummy = modifyForDel.map((image: any) => {
            if (image.lotLogoPhotosId === 0 && image.isActive === false) {
                return {}
            }
            return image
        })

        const finalData = removeDummy?.filter((data: any) => Object.keys(data)?.length > 0)
        if (finalData.length > 0) {
            const Id = mode === "clone" ? cloneLotId : lotId
            let formData = logoPhotosFormData(finalData, Id)
            onHandleUdpateApiCall(formData, finalData, false)
        } else {
            setLoading(false)
            setImagesForSort([])
            setShowModal(false)
            setImagesData([])
            setThumbsSwiper(null)
        }
    }

    const handleSelectUnSelect = (type: string) => {
        if (type === "selectAll") {
            let selectAllData: any = []
            imagesForSort.forEach((iData: any) => {
                selectAllData.push(iData.uniqueId)
            })
            setImagesToDelId(selectAllData)
        } else {
            setImagesToDelId([])
        }
    }

    useEffect(() => {
        if (!clonedTabs.includes(LOTLOGOPHOTO) && mode === "clone") {
            setImagesData([])
            setThumbsSwiper(null)
            setlogoPhotos([])
        } else {
            const Id = clonedTabs.includes(LOTLOGOPHOTO) ? cloneLotId : lotId
            getInventoryById(Id)
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (swiperRef.current) {
                swiperRef.current.update();
            }
        }, 350);
    }, [sidebarOpen])

    useEffect(() => {
        if (imgURL) {
            setDefaultImgFile(logoPhotos[0]?.photos)
            setImagesData([])
            // setImgUrl(`${base}${logoPhotos[0]?.photos}`)
        } else {
            setDefaultImgFile(null)
            setImagesData(logoPhotos?.map((data: any) => { return { ...data, uniqueId: uuidv4() } }))
        }
    }, [imgURL, logoPhotos])

    useEffect(() => {
        getCompanyProfile();
        // setImgUrl(defaultLotImg);
        fetch(defaultLotImg)
            .then((res) => res.blob())
            .then((blob) => {
                const defaultImgBlob: any = new File([blob], 'defaultImg.png', { type: 'image/png' })
                setDefaultImgFile(defaultImgBlob)

            })
            .catch((error) => {
                console.error('Error fetching default image:', error);
            });
    }, [])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTLOGOPHOTO)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTLOGOPHOTO)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])



    //==================================================================================================================
    //doc/ url section.

    const schema: any = yup.object().shape({
        notificationEmail: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        })
            .matches(EMAIL, EMAIL_MESSAGE).max(49, 'Please enter upto 50 characters.')
    })

    const schema2: any = yup.object().shape({
        inventoryUrl: yup.string().required("Blank URL not allow!")
            .test('url-unique', 'This URL already exists.', async (value, context) => {
                const isUrlUnique = (type === "url") ?
                    !getUrlData.some((item: any) => item.inventoryUrl === value) :
                    !getVideoUrlData.some((item: any) => item.inventoryUrl === value);
                return isUrlUnique;
            })
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(URL_REG, URL_MESSAGE),
    })

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialData, mode: 'onChange' })

    const {
        getValues: linkGetValue,
        register: linkRegister,
        handleSubmit: handleLink,
        reset: resetModal,
        formState: { errors: linkError, isValid: linkValid },
    } = useForm({ resolver: yupResolver(schema2), defaultValues: initialData2, mode: 'onChange' })

    const handleLinkModelClose = () => {
        resetModal(initialData2);
        setToggleValue(!toggleModal)
        setType("")
    }

    const handlePrevious = () => {
        dispatch(setStep('silent'));
    }

    const onSubmitURL = () => {
        const dataOriginal = getValues();
        const linkData = trimObjectValues(dataOriginal);
        let combineData = [...getUrlData, ...getVideoUrlData]
        if (!getVideoUrlData?.length && !getUrlData?.length && !linkData.notificationEmail) {
            // toast.warning('Nothing to Save for url Section!', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 2000,
            // })
            return
        }
        setLoading(true)

        const submitData = {
            lotDocUrlId: 0,
            lotId: mode === "clone" ? cloneLotId : lotId,
            notificationEmail: linkData.notificationEmail,
            isActive: true,
            isDelete: false,
            lstNotification: combineData.map((i: any) => {
                if (i.lotDocUrlId == 0 && i.isDelete == true) {
                    return null
                }
                return i
            }).filter((i: any) => i)
        }

        axios.post(UPDATELOTDOCURLLIST, submitData, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return
            } else {
                /*  toast.success('Docs & URL updated successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })*/
            }
            if (mode === "clone") {
                dispatch(setCloneTab([...clonedTabs, LOTDOCURLS]))
            }
            if (mode === "create") {
                dispatch(setCreateTab([...createTabs, LOTDOCURLS]))
            }
            nextStep()
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    }

    const linkSubmit = () => {
        const details = linkGetValue();
        if (type === "url") {
            setUrlData((remainingData: any) => [...remainingData, { ...details, uniqueId: uuidv4(), inventoryUrlMode: 1 }])
            setUrlOriginalData((remainingData: any) => [...remainingData, { ...details, uniqueId: uuidv4(), inventoryUrlMode: 1 }])
        } else {
            setVideoUrlData((remainingData: any) => [...remainingData, { ...details, uniqueId: uuidv4(), inventoryUrlMode: 2 }])
            setVideoOriginialUrlData((remainingData: any) => [...remainingData, { ...details, uniqueId: uuidv4(), inventoryUrlMode: 2 }])
        }
        setType("")
        setToggleValue(false)
        setSearchText('')
        resetModal()
    }

    const getDocVideoUrls = () => {
        setLoading(true);
        const id = mode === "clone" ? cloneLotId : lotId
        axios.get(`${GETDOCSURLBYLOTID}?lotId=${id}`, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setIsData(false)
                return;
            } else {
                setIsData(true)
                const docVideoUrlData = response.data.data;
                const docData = docVideoUrlData.filter((dData: any) => dData.inventoryUrlMode === 1)
                const videoData = docVideoUrlData.filter((dData: any) => dData.inventoryUrlMode === 2)
                const addDocUuid = docData.map((dData: any) => { return { ...dData, uniqueId: uuidv4() } })
                const addVideoUuid = videoData.map((vData: any) => { return { ...vData, uniqueId: uuidv4() } })
                const email = docVideoUrlData.length > 0 ? docVideoUrlData.find((dvData: any) => dvData.notificationEmail !== "")?.notificationEmail : ""
                reset({ notificationEmail: email })
                setUrlData(addDocUuid)
                setVideoUrlData(addVideoUuid)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const nextStep = () => {
        // dispatch(setStep("lotDoc"))
        dispatch(setStep("notes"))
    }

    const setDefaultUrl = (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to set this URL to default!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Set as Default'
        }).then((result) => {
            if (result.isConfirmed) {
                setDefaultVideoUrlId(id);
                const newData = getVideoUrlData.map((image: any) => {
                    if (image.uniqueId == id) {
                        return { ...image, inventoryUrlDefaults: true }
                    }
                    return { ...image, inventoryUrlDefaults: false }
                })?.filter((iData: any) => iData.isActive === true)

                setVideoUrlData(newData)
                setVideoOriginialUrlData(newData)
                toast.success("Inventory Document URL set as default", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        });
    }

    const handleDeleteVideoUrl = (uniqueId: any) => {
        if (defaultVideoUrlId === uniqueId) {
            Swal.fire({
                title: 'Sorry, Cannot Delete Default Video URL',
                icon: 'warning',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Ok'
            });
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this Video URL!",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const updatedData = getVideoUrlData.map((url: any) => {
                        if (url.uniqueId === uniqueId) {
                            url.isDelete = true;
                            url.isActive = false;
                            return url;
                        }
                        return url;
                    });
                    setVideoUrlData(updatedData);
                    setSearchText('')
                }
            });
        }
    };

    const handleDeleteUrl = (uniqueId: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this URL!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedData = getUrlData.map((url: any) => {
                    if (url.uniqueId === uniqueId) {
                        url.isDelete = true;
                        url.isActive = false;
                        return url;
                    }
                    return url;
                });
                setUrlData(updatedData);
                setSearchText('')
            }
        });
    }

    const handleMainSubmit = () => {
        onSubmitURL()
        handleSubmitPhotos()
        setSearchText('')
    }

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTDOCURLS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTDOCURLS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    useEffect(() => {
        getDocVideoUrls();
    }, []);

    //=======================================================



    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reorder Inventory images</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-hidden">
                    <div className="d-flex flex-wrap gap-2 mb-3 justify-content-center">
                        <button className="btn btn-outline-primary" onClick={() => handleSelectUnSelect("selectAll")}>Select All</button>
                        <button className="btn btn-outline-primary" onClick={() => handleSelectUnSelect("deselectAll")}>Deselect All</button>
                    </div>
                    <SortableGrid
                        axis={"xy"}
                        images={imagesForSort}
                        onSortEnd={onSortEnd}
                        lockAxis="xy"
                        helperClass="sortableHelper"
                        onCheckboxChange={handleCheckboxChange}
                        imagesCheckedToDel={imagesToDelId}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={handleDelete}
                        disabled={!(imagesToDelId.length > 0)}
                    >Delete Selected</button>
                    <button type="button" className="btn btn-outline-danger" onClick={handleClose}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={handleReOrder}>{lotId !== 0 ? `Change order` : `Save & Next`}</button>
                </Modal.Footer>
            </Modal>
            <Modal show={toggleModal} size='lg' className="modal-md" centered>
                <div
                // onSubmit={handleLink(linkSubmit)} 
                >
                    <ModalHeader>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{modalTitle}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleLinkModelClose}>
                            <span className="fas fa-times fs-20 text-danger"></span></button>
                    </ModalHeader>
                    <ModalBody>
                        <label className="form-label" >URL <sup className="text-danger">*</sup></label>
                        <input className="form-control form-icon-input" placeholder="URL"
                            {...linkRegister('inventoryUrl')}
                        />
                        {linkError?.inventoryUrl && <span className='text-danger' style={{ fontSize: "12px" }}>{linkError?.inventoryUrl.message}</span>}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleLinkModelClose}>Cancel</button>
                        <button className="btn btn-primary" type="submit" onClick={handleLink(linkSubmit)}>Add</button>
                    </ModalFooter>
                </div>
            </Modal>
            <div
            // onSubmit={handleSubmitPhotos}
            >
                {loading && <div className="loader-wrap">
                    <img src={`${localStorage.getItem('globalLoader')}`} />
                </div>}
                <div className="row top_border justify-content-center">
                    <div className="row align-items-end input-button ">
                        <div className="me-2 col-lg-12 mt-3 mb-2">
                            <label className="form-label" ><h5>Notification Email</h5></label>
                            <input className="form-control form-icon-input" type="text" maxLength={50} placeholder="Notification Email" {...register('notificationEmail')} />
                            {errors.notificationEmail && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.notificationEmail.message}</span>}
                        </div>
                    </div>
                    <div className="col-lg-7 col-xl-6 mb-4">
                        <div className="position-relative swiper-francy-slider">
                            {activeImagesData?.length == 0 ?
                                <div className="p-4 border border-radius text-center mb-4">
                                    <img className="max-width-170" src={defaultLotImg}
                                        alt='default Image' />
                                </div>
                                :
                                <>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        <Swiper
                                            pagination={{
                                                type: 'fraction',
                                            }}
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper }}
                                            modules={[FreeMode, Navigation, Thumbs, Pagination]}
                                            className="mySwiper2"
                                            ref={swiperRef}
                                        >
                                            {
                                                activeImagesData?.map((data: any, index: any) => {
                                                    const blob = new Blob([data.photos], { type: 'video/mp4' });
                                                    const objectURL = URL.createObjectURL(blob);

                                                    return (
                                                        <SwiperSlide>
                                                            {
                                                                data.inventoryOrDefaults === 1 ? <><span className="def_img badge">DEFAULT</span></> :
                                                                    <>
                                                                        <button className="del_img_btn" type="button" onClick={() => handleDeleteImage(data.uniqueId)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                        <button className="def_img_btn badge" type="button" onClick={() => setDefaultImage(data.uniqueId)}>
                                                                            Set as Default
                                                                        </button>
                                                                    </>
                                                            }
                                                            <a href={data.lotLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data.photos)} data-fancybox="gallery">
                                                                <img className="w-100" src={data.lotLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data.photos)}
                                                                    alt={`img_${index}`} />
                                                            </a>

                                                        </SwiperSlide>
                                                    )
                                                })}

                                        </Swiper>
                                    </Fancybox>
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        loop={true}
                                        spaceBetween={10}
                                        slidesPerView={6}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                    >
                                        {
                                            activeImagesData?.map((data: any, index: any) => {
                                                const blob = new Blob([data.photos], { type: 'video/mp4' });
                                                const objectURL = URL.createObjectURL(blob);
                                                return (
                                                    <SwiperSlide>
                                                        <img className="w-100" src={data.lotLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data.photos)}
                                                            alt={`img_${index}`} />
                                                    </SwiperSlide>
                                                )
                                            })}
                                    </Swiper>
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-6 mb-4">
                        <div className="ps-lg-5">
                            <div className="Neon Neon-theme-dragdropbox mb-3 h-100">
                                <input name="files[]" id="filer_input2" className="upload_img_box" type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleUpload} multiple />
                                <div className="Neon-input-dragDrop">
                                    <div className="Neon-input-inner">
                                        <div className="Neon-input-icon"><i className="fa fa-file-image-o"></i></div>
                                        <a className="Neon-input-choose-btn blue mb-3">Upload Images</a>
                                        <div className="Neon-input-text"><span className="mt-4"><i><span className="text-danger">**</span>Note: Accepted file formats - JPG, JPEG, PNG</i></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {activeImagesData.length > 1 && <div className="ps-lg-5 text-center mt-4">
                            <div className="reorder-img p-3">
                                <img src={ReOrder} />
                                <div className="w-100">
                                    <button type="button" className="btn btn-outline-primary ms-2 me-auto" onClick={handleModalOpen}>Reorder images</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom z-index-3">
                    <button className="btn btn-secondary " type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <span className="btn btn-outline-danger ms-2 me-auto" data-bs-dismiss="modal" onClick={deleteLotIfNew}>{CommonMessages.cancelButton}</span>
                    <button className="btn btn-primary ms-2 " type="submit">{btnText}</button>
                </div> */}
            </div>
            <div>
                <div className="row top_border justify-content-end g-3 mt-0">
                    <div className="col-xxl-5 mt-3">
                        <div
                        // onSubmit={handleSubmit(onSubmitURL)}
                        >
                            {loading && <div className="loader-wrap">
                                <img src={`${localStorage.getItem('globalLoader')}`} />
                            </div>}
                            <div className="search-box w-50 d-flex align-items-center mb-1 mt-3 mb-3 position-relative">
                                <div className="d-flex w-100 align-items-center position-relative d-flex align-items-center">
                                    <input
                                        type="text"
                                        className="form-control search-input search form-control-sm w-100"
                                        placeholder="Search Docs/Video URL"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e?.target?.value)}
                                    />
                                    <span className="fas fa-search search-box-icon"></span>
                                </div>
                                <Tippy content="Default URL will be shown in the lot listing page">
                                    <i className="eye_icons fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                                </Tippy>
                            </div>
                            <div className="mb-3">
                                <div className="col-md-12 mt-3">
                                    <div className="card bg-light w-100 p-2">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between w-100 border-bottom pb-2 mb-2">
                                            <h5 className="m-0">Add Docs URL</h5>
                                        </div>
                                        {/* <div className="vector_center_200">
                                        <img src={urlUpload} />
                                    </div> */}
                                        <div className="button-in text-center d-flex justify-content-center">
                                            <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                                onClick={() => {
                                                    setToggleValue(!toggleModal)
                                                    setModalTitle('Add Docs URL')
                                                    setType("url")
                                                }}>
                                                <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Docs URL</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        <div>URL</div>
                                        {
                                            getUrlData && getUrlData?.filter((i: any) => i.isActive && i?.inventoryUrl?.toLocaleLowerCase()?.includes(searchText))?.length > 0 ?
                                                (
                                                    <ul className="inner-div p-0 scroll overflow-auto default_btn_overlap mt-1">
                                                        {getUrlData.map((getUrl: any, index: any) => {
                                                            if (getUrl.isActive && getUrl?.inventoryUrl?.toLocaleLowerCase()?.includes(searchText)) {
                                                                return (
                                                                    <li className="d-flex align-items-center mb-2" key={index}>
                                                                        <input
                                                                            className="form-control form-icon-input inventoryUrl"
                                                                            type="text"
                                                                            name=''
                                                                            value={getUrl?.inventoryUrl}
                                                                            disabled
                                                                        />
                                                                        <button type="button" className="action_icons text-decoration-none ms-2 btn p-0" onClick={(uniqueId: any) => { handleDeleteUrl(getUrl.uniqueId) }}>
                                                                            <img src={deleteIcon} alt="Delete" />
                                                                        </button>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                ) :
                                                <p className='text-center p-3 bg-light border mt-1' style={{ width: "100%" }}>No records found</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 mt-1">
                                    <div className="card bg-light w-100 p-2">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between w-100 border-bottom pb-2 mb-2">
                                            <h5 className="m-0">Add Video URL</h5>
                                        </div>
                                        {/* <div className="vector_center_200">
                                        <img src={videoUpload} />
                                    </div> */}
                                        <div className="button-in text-center d-flex justify-content-center">

                                            <button className="btn btn-primary" type="button"
                                                onClick={() => {
                                                    setToggleValue(!toggleModal)
                                                    setModalTitle('Add Video URL')
                                                    setType("video")
                                                }}
                                            >
                                                <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Video URL</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        <div>Video URL</div>
                                        {
                                            getVideoUrlData && getVideoUrlData?.filter((i: any) => i.isActive && i?.inventoryUrl?.toLocaleLowerCase()?.includes(searchText))?.length > 0 ?
                                                (
                                                    <ul className="inner-div p-0 scroll overflow-auto default_btn_overlap mt-1">
                                                        {getVideoUrlData.map((getUrl: any, index: any) => {
                                                            if (getUrl.isActive && getUrl?.inventoryUrl?.toLocaleLowerCase()?.includes(searchText)) {
                                                                return (
                                                                    <li className="d-flex align-items-center mb-2" key={index}>
                                                                        <input
                                                                            className="form-control form-icon-input inventoryUrl"
                                                                            type="text"
                                                                            name=''
                                                                            value={getUrl?.inventoryUrl}
                                                                            disabled
                                                                        />
                                                                        {
                                                                            getUrl.inventoryUrlDefaults ?
                                                                                <span className="badge">Default</span> :
                                                                                <button className="badge btn-sm btn btn-primary " type="button" onClick={() => { setDefaultUrl(getUrl.uniqueId) }}>
                                                                                    Set as Default
                                                                                </button>
                                                                        }
                                                                        <button type="button" className="action_icons text-decoration-none ms-2 btn p-0" onClick={() => { handleDeleteVideoUrl(getUrl.uniqueId) }}>
                                                                            <img src={deleteIcon} alt="Delete" />
                                                                        </button>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                ) :
                                                <p className='text-center p-3 bg-light border mt-1' style={{ width: "100%" }}>No records found</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">


                            </div> */}
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom z-index-3">
                                <button className="btn btn-secondary " type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                                <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal">{CommonMessages.cancelButton}</Link>
                                <button className="btn btn-primary ms-2" onClick={handleMainSubmit}>{btnText}</button>
                                <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Media;