import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  GETALLBIDINCREMENT,
  GETALLSALESREPCOMMISSIONSCALE,
  GETALLSELLERCOMMISSIONSCALE,
  GETBPBYSCALES,
  GETDEFAULTCOMMFORINV,
  GETLOTCOMMCOSTBYID,
  UPDATELOTCOMMBIDDOWN
} from "../../../common/apiConstatnts";

import { yupResolver } from "@hookform/resolvers/yup";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import {
  CommonMessages,
  errorMessages,
} from "../../../common/messageConstants";
import { DECIMAL93, DECIMAL93_MESSAGE } from "../../../common/regexConstants";
import * as routeConstant from "../../../common/routeConstants";
import { setStep } from "../../../store/actions/AuctionAction";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../store/actions/Inventory";
import { LOTCOMMCOSTBIDOWN } from "../InventoryConstants";
import { error } from "console";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const CommCostBidDown = () => {
  const query = window.location.href;
  const vars = query.split("/");
  const dispatch = useDispatch();
  const companyId: any = localStorage.getItem("companyId");
  const mode = useSelector((state: any) => state.inventory.mode);
  const auctionData = useSelector((state: any) => state?.inventory?.auctionData);
  const lotData = useSelector((state: any) => state?.inventory?.lotPage);
  const lotId = useSelector((state: any) => state?.inventory.lotId);
  const cloneLotId = useSelector((state: any) => state?.inventory.clonedLotId);
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)
  const sellerId = useSelector((state: any) => state?.inventory.commission.sellerId);
  const [sellerCommissionData, setSellerCommissionData] = useState<any>([]);
  const [sellerDefaultValues, setSellerDefaultValues] = useState<any>({});
  const [salesRepDefaultValues, setSalesRepDefaultValues] = useState<any>({});
  const [salesRepCommissionData, setSalesRepCommissionData] = useState<any>([]);
  const [bpScalesData, setBPScalesData] = useState<any>([]);
  const [commissionData, setCommissionData] = useState<any>({});
  const [isCommResp, setIsCommResp] = useState<boolean>(false);
  const [bidIncrementOptions, setBidIncrementOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnTxt] = useState<any>("")
  const salesrepId = lotData?.salesRep && lotData?.salesRep.split(",")[0];

  const initialData = {
    companyId: parseInt(companyId),
    lotCommBidDownId: 0,
    lotId: lotId,
    sellerScaleSoldId: 0,
    sellerScalePassedId: 0,
    sellerScaleBuyBackId: 0,
    sellerScaleNonPayerId: 0,
    salesRepScaleSoldId: 0,
    salesRepScalePassedId: 0,
    salesRepScaleBuyBackId: 0,
    salesRepScaleNonPayerId: 0,
    bpScale: 0,
    msrp: 0,
    multiplier: 0,
    fixedPrice: 0,
    actualValue: 0,
    lowestBid: 0,
    isActive: true,
    isDelete: false,
  };

  const [initialFormValue, setInitialFormValue] = useState<any>(initialData);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const schema: any = yup.object().shape({
    msrp: yup.string().nullable().matches(DECIMAL93, DECIMAL93_MESSAGE),
    multiplier: yup.string().nullable().matches(DECIMAL93, DECIMAL93_MESSAGE),
    fixedPrice: yup.string().nullable().matches(DECIMAL93, DECIMAL93_MESSAGE),
    actualValue: yup.string().nullable().matches(DECIMAL93, DECIMAL93_MESSAGE),
    lowestBid: yup.string().nullable().matches(DECIMAL93, DECIMAL93_MESSAGE),
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValue,
    mode: "onChange",
  });

  const sellerScaleSoldId = watch("sellerScaleSoldId");
  const sellerScalePassedId = watch("sellerScalePassedId");
  const sellerScaleBuyBackId = watch("sellerScaleBuyBackId");
  const sellerScaleNonPayerId = watch("sellerScaleNonPayerId");
  const bpScale = watch("bpScale");
  const salesRepScaleSoldId = watch("salesRepScaleSoldId");
  const salesRepScalePassedId = watch("salesRepScalePassedId");
  const salesRepScaleBuyBackId = watch("salesRepScaleBuyBackId");
  const salesRepScaleNonPayerId = watch("salesRepScaleNonPayerId");
  const actualValue = watch("actualValue");
  const lowestBid = watch("lowestBid");
  const fixedPrice = watch("fixedPrice");
  const multiplier = watch("multiplier");
  const msrp = watch("msrp");

  const handleNext = () => {
    dispatch(setStep("auto"));
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    const formData: any = {
      companyId: parseInt(companyId),
      lotCommBidDownId: Object.keys(commissionData).length > 0 ? commissionData.lotCommBidDownId : 0,
      lotId: mode === "clone" ? cloneLotId : lotId,
      sellerScaleSoldId: data?.sellerScaleSoldId,
      sellerScalePassedId: data?.sellerScalePassedId,
      sellerScaleBuyBackId: data?.sellerScaleBuyBackId,
      sellerScaleNonPayerId: data?.sellerScaleNonPayerId,
      bpScale: data?.bpScale,
      /* salesRepScaleSoldId: data?.salesRepScaleSoldId,
      salesRepScalePassedId: data?.salesRepScalePassedId,
      salesRepScaleBuyBackId: data?.salesRepScaleBuyBackId,
      salesRepScaleNonPayerId: data?.salesRepScaleNonPayerId,
      msrp: data?.msrp,
      multiplier: data?.multiplier,
      fixedPrice: data?.fixedPrice,
      actualValue: data?.actualValue,
      lowestBid: data?.lowestBid, */
      isActive: true,
      isDelete: false,
    };
    //this is for trim all string type value in object
    Object.keys(formData).forEach((k) => (formData[k] = typeof formData[k] == "string" ? formData[k].trim() : formData[k]));

    axios.post(UPDATELOTCOMMBIDDOWN, formData, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, LOTCOMMCOSTBIDOWN]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, LOTCOMMCOSTBIDOWN]))
        }
        handleNext();
        toast.success("Commission Data saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      handleError(error)
    });
  };

  useEffect(() => {
    if (isCommResp) {
      let dataForReset = commissionData
      setLoading(true);
      axios.get(GETALLSELLERCOMMISSIONSCALE, config).then((response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          const scaleData = response.data?.data?.lstSellerCommissionScale ?? [];
          const sellerScaleData = scaleData.map((item: any) => ({ label: item.taxRegionName, value: item.sellerCommissionScaleId }));
          axios.get(GETDEFAULTCOMMFORINV, { ...config, params: { sellerId: sellerId, auctionId: auctionData.auctionId } }).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              const sold = scaleData?.find((item: any) => item.sellerCommissionSold === true)?.sellerCommissionScaleId;
              const unSold = scaleData?.find((item: any) => item.sellerCommissionUnSold === true)?.sellerCommissionScaleId;
              const buyBack = scaleData?.find((item: any) => item.sellerCommissionBuyBack === true)?.sellerCommissionScaleId;
              const nonPay = scaleData?.find((item: any) => item.sellerCommissionNonPayer === true)?.sellerCommissionScaleId;
              setSellerDefaultValues({ sold: sold, unsold: unSold, buyBack: buyBack, nonPay: nonPay });
              return;
            } else {
              const slrCommData = response?.data?.data;
              dataForReset = {
                ...commissionData,
                sellerScaleBuyBackId: commissionData?.sellerScaleBuyBackId ? commissionData.sellerScaleBuyBackId : slrCommData?.defaultScalesBuyback,
                sellerScaleNonPayerId: commissionData?.sellerScaleNonPayerId ? commissionData.sellerScaleNonPayerId : slrCommData?.defaultScalesNonPayer,
                sellerScalePassedId: commissionData?.sellerScalePassedId ? commissionData.sellerScalePassedId : slrCommData?.defaultScalesPassed,
                sellerScaleSoldId: commissionData?.sellerScaleSoldId ? commissionData.sellerScaleSoldId : slrCommData?.defaultScalesSold
              }
              reset(dataForReset)
              setInitialFormValue(dataForReset)
            }
          });
          setSellerCommissionData(sellerScaleData);
        }
      }, (error) => {
        setLoading(false);
        if (error.response.status !== 401) {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      });

      axios.get(GETALLSALESREPCOMMISSIONSCALE, config).then((response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          const scaleData = response.data?.data?.lstSalesRepCommissionScale ?? [];
          const salesRepScaleData = scaleData.map((item: any) => ({ label: item.label, value: item.salesRepCommissionScaleId }));
          /* axios.get(`${GETSALESREPID}?id=${salesrepId}`).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              const respData = response.data.data;
              const commissionData = respData.lstSalesRepCommission[0];
              setValue("salesRepScaleBuyBackId", commissionData?.defaultScalesBuyback);
              setValue("salesRepScaleNonPayerId", commissionData?.defaultScalesNonPayer);
              setValue("salesRepScalePassedId", commissionData?.defaultScalesPassed);
              setValue("salesRepScaleSoldId", commissionData?.defaultScalesSold);
            }
          }); */

          const filterData = scaleData?.map((scale: any) => {
            return {
              label: scale.label,
              salesRepCommissionBuyBack: scale.salesRepCommissionBuyBack,
              salesRepCommissionScaleId: scale.salesRepCommissionScaleId,
              salesRepCommissionNonPayer: scale.salesRepCommissionNonPayer,
              salesRepCommissionSold: scale.salesRepCommissionSold,
              salesRepCommissionUnSold: scale.salesRepCommissionUnSold,
            };
          });

          setSalesRepCommissionData(filterData?.map((i: any) => {
            return {
              label: i.label,
              value: i.salesRepCommissionScaleId,
            };
          }));

          let sold, unSold, buyBack, nonPay;
          for (let i = 0; i < filterData.length; i++) {
            const scale = filterData[i];
            if (scale.salesRepCommissionSold)
              sold = scale.salesRepCommissionScaleId;
            if (scale.salesRepCommissionUnSold)
              unSold = scale.salesRepCommissionScaleId;
            if (scale.salesRepCommissionBuyBack)
              buyBack = scale.salesRepCommissionScaleId;
            if (scale.salesRepCommissionNonPayer)
              nonPay = scale.salesRepCommissionScaleId;
          }

          setSalesRepDefaultValues({ sold: sold, unsold: unSold, buyBack: buyBack, nonPay: nonPay });
        }
      }).catch((error) => {
        handleError(error);
      });
      /* reset(dataForReset)
      setInitialFormValue(dataForReset) */
    }
  }, [isCommResp])

  const getBPScales = () => {
    setLoading(true);
    axios.get(`${GETBPBYSCALES}`).then((response) => {
      if (response?.data?.success === "Fail") {
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        const bpScalesData = response.data?.data;
        const bpScalesOption = bpScalesData?.map((i: any) => {
          return {
            label: i.label,
            value: i?.bpBySalesScaleId,
            isDefault: i.isDefault,
          };
        });
        setBPScalesData(bpScalesOption);
      }
    }, (error) => {
      if (error.response.status !== 401) {
        toast.error(errorMessages.error401, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    });
  };

  const getBidIncrementOptions = () => {
    setLoading(true);
    axios.get(GETALLBIDINCREMENT).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        return;
      } else {
        const data = response.data.data?.filter((i: any) => i?.bidIncrementFor == 1).map((i: any) => {
          return { label: i?.label, value: i?.bidIncrementId, isDefault: i?.isDefault, };
        });
        setBidIncrementOptions(data);
      }
    }).catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
  };

  const prevStep = () => {
    dispatch(setStep("address"));
    /* dispatch(setInventoryInfoActive({ activeTab: "logoPhotos" })); */
  };

  useEffect(() => {
    getBPScales();
    getBidIncrementOptions();
  }, []);

  useEffect(() => {
    if (Object.keys(sellerDefaultValues)?.length) {
      if (Object.keys(commissionData)?.length === 0) {
        setValue("sellerScaleSoldId", sellerDefaultValues.sold);
        setValue("sellerScalePassedId", sellerDefaultValues.unsold);
        setValue("sellerScaleBuyBackId", sellerDefaultValues.buyBack);
        setValue("sellerScaleNonPayerId", sellerDefaultValues.nonPay);
      } else {
        setValue("sellerScaleSoldId", commissionData?.sellerScaleSoldId);
        setValue("sellerScalePassedId", commissionData?.sellerScalePassedId);
        setValue("sellerScaleBuyBackId", commissionData?.sellerScaleBuyBackId);
        setValue("sellerScaleNonPayerId", commissionData?.sellerScaleNonPayerId);
      }
    }
  }, [sellerDefaultValues, commissionData]);

  useEffect(() => {
    if (Object.keys(salesRepDefaultValues)?.length) {
      if (Object.keys(commissionData)?.length === 0) {
        setValue("salesRepScaleSoldId", salesRepDefaultValues.sold);
        setValue("salesRepScalePassedId", salesRepDefaultValues.unsold);
        setValue("salesRepScaleBuyBackId", salesRepDefaultValues.buyBack);
        setValue("salesRepScaleNonPayerId", salesRepDefaultValues.nonPay);
      } else {
        setValue("salesRepScaleSoldId", commissionData?.salesRepScaleSoldId);
        setValue("salesRepScalePassedId", commissionData?.salesRepScalePassedId);
        setValue("salesRepScaleBuyBackId", commissionData?.salesRepScaleBuyBackId);
        setValue("salesRepScaleNonPayerId", commissionData?.salesRepScaleNonPayerId);
      }
    }
  }, [salesRepDefaultValues, commissionData]);

  useEffect(() => {
    if (Object.keys(bpScalesData)?.length) {
      if (Object.keys(commissionData)?.length === 0) {
        setValue("bpScale", bpScalesData.find((i: any) => i?.isDefault == true).value);
      } else {
        setValue("bpScale", commissionData?.bpScale);
      }
    }
  }, [bpScalesData]);

  useEffect(() => {
    setValue("actualValue", actualValue ?? 0)
  }, [actualValue])

  useEffect(() => {
    setValue("lowestBid", lowestBid ?? 0)
  }, [lowestBid])

  useEffect(() => {
    setValue("fixedPrice", fixedPrice ?? 0)
  }, [fixedPrice])

  useEffect(() => {
    setValue("multiplier", multiplier ?? 0)
  }, [multiplier])

  useEffect(() => {
    setValue("msrp", msrp ?? 0)
  }, [msrp])

  useEffect(() => {
    setLoading(true)
    const Id = clonedTabs.includes(LOTCOMMCOSTBIDOWN) ? cloneLotId : lotId
    axios.get(`${GETLOTCOMMCOSTBYID}?LotId=${Id}`, config).then((response: any) => {
      if (response?.data?.success === "Fail") {
        setIsCommResp(true)
        setLoading(false)
        return;
      } else {
        setLoading(false)
        let respData = response.data.data
        if (Object.keys(respData).length > 0) {
          if (!clonedTabs.includes(LOTCOMMCOSTBIDOWN) && cloneLotId > 0) {
            respData = { ...respData, lotCommBidDownId: 0 }
          }
          setCommissionData(respData)
        }
        setIsCommResp(true)
      }
    }, (error) => {
      setIsCommResp(true)
      setLoading(false)
      if (error?.response?.status !== 401) {
        toast.error("Some error occurred!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    })
  }, [])

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTCOMMCOSTBIDOWN)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTCOMMCOSTBIDOWN)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div className="container-fluid for-fix-bottom-space">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row justify-content-center top_border">
            <div className="col-xxl-10 mt-3">
              <div className="row g-3">
                <div className="col-12">
                  <div className="mb-2 border-300">
                    <h4>Seller Commissions</h4>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Sold</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("sellerScaleSoldId")}
                        name="sellerScaleSoldId"
                        options={sellerCommissionData}
                        value={sellerCommissionData?.find((option: any) => option?.value == sellerScaleSoldId)}
                        onChange={(e: any) => setValue("sellerScaleSoldId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Passed/Unsold</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("sellerScalePassedId")}
                        name="sellerScalePassedId"
                        options={sellerCommissionData}
                        value={sellerCommissionData?.find((option: any) => option?.value == sellerScalePassedId)}
                        onChange={(e: any) => setValue("sellerScalePassedId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Buyback</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("sellerScaleBuyBackId")}
                        name="sellerScaleBuyBackId"
                        options={sellerCommissionData}
                        value={sellerCommissionData?.find((option: any) => option?.value == sellerScaleBuyBackId)}
                        onChange={(e: any) => setValue("sellerScaleBuyBackId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Non-Payer</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("sellerScaleNonPayerId")}
                        name="sellerScaleNonPayerId"
                        options={sellerCommissionData}
                        value={sellerCommissionData?.find((option: any) => option?.value == sellerScaleNonPayerId)}
                        onChange={(e: any) => setValue("sellerScaleNonPayerId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">BP Scale</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("bpScale")}
                        name="bpScale"
                        options={bpScalesData}
                        value={bpScalesData?.find((option: any) => option?.value == bpScale)}
                        onChange={(e: any) => setValue("bpScale", e.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <div className="mb-2 border-300">
                    <h4>Sales Rep Commission</h4>
                  </div>
                  <div className="row g-3">
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Sold</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("salesRepScaleSoldId")}
                        name="salesRepScaleSoldId"
                        options={salesRepCommissionData}
                        value={salesRepCommissionData?.find((option: any) => option?.value == salesRepScaleSoldId)}
                        onChange={(e: any) => setValue("salesRepScaleSoldId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Passed/Unsold</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("salesRepScalePassedId")}
                        name="salesRepScalePassedId"
                        options={salesRepCommissionData}
                        value={salesRepCommissionData?.find((option: any) => option?.value == salesRepScalePassedId)}
                        onChange={(e: any) => setValue("salesRepScalePassedId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Buyback</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("salesRepScaleBuyBackId")}
                        name="salesRepScaleBuyBackId"
                        options={salesRepCommissionData}
                        value={salesRepCommissionData?.find((option: any) => option?.value == salesRepScaleBuyBackId)}
                        onChange={(e: any) => setValue("salesRepScaleBuyBackId", e.value)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label className="form-label">Non-Payer</label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select"
                        className="isActive"
                        {...register("salesRepScaleNonPayerId")}
                        name="salesRepScaleNonPayerId"
                        options={salesRepCommissionData}
                        value={salesRepCommissionData?.find((option: any) => option?.value == salesRepScaleNonPayerId)}
                        onChange={(e: any) => setValue("salesRepScaleNonPayerId", e.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-lg-3 col-md-6">
                  <label className="form-label">Actual Value</label>
                  <CurrencyInput
                    type="text"
                    {...register("actualValue")}
                    name="actualValue"
                    placeholder="Actual Value"
                    prefix="$"
                    allowDecimals={false}
                    decimalsLimit={8}
                    value={actualValue}
                    onValueChange={(value: any) =>
                      setValue("actualValue", value ?? 0)
                    }
                    className="form-control form-icon-input"
                    required
                  />
                  {errors.actualValue && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.actualValue.message}
                    </span>
                  )}
                </div>
                <div className="col-lg-3 col-md-6">
                  <label className="form-label">Fixed Price</label>
                  <CurrencyInput
                    type="text"
                    {...register("fixedPrice")}
                    name="fixedPrice"
                    placeholder="Fixed Price"
                    prefix="$"
                    allowDecimals={false}
                    decimalsLimit={8}
                    value={fixedPrice}
                    onValueChange={(value: any) =>
                      setValue("fixedPrice", value ?? 0)
                    }
                    className="form-control form-icon-input"
                    required
                  />
                  {errors.fixedPrice && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.fixedPrice.message}
                    </span>
                  )}
                </div>
                <div className="col-lg-3 col-md-6">
                  <label className="form-label">Multiplier</label>
                  <CurrencyInput
                    type="text"
                    {...register("multiplier")}
                    name="multiplier"
                    placeholder="Multiplier"
                    prefix="$"
                    allowDecimals={false}
                    decimalsLimit={8}
                    value={multiplier}
                    onValueChange={(value: any) =>
                      setValue("multiplier", value ?? 0)
                    }
                    className="form-control form-icon-input"
                    required
                  />
                  {errors.multiplier && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.multiplier.message}
                    </span>
                  )}
                </div>
                <div className="col-lg-3 col-md-6">
                  <label className="form-label">MSRP</label>
                  <CurrencyInput
                    type="text"
                    {...register("msrp")}
                    name="msrp"
                    placeholder="MSRP"
                    prefix="$"
                    allowDecimals={false}
                    decimalsLimit={8}
                    value={msrp}
                    onValueChange={(value: any) => setValue("msrp", value ?? 0)}
                    className="form-control form-icon-input"
                    required
                  />
                  {errors.msrp && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.msrp.message}
                    </span>
                  )}
                </div>
                <div className="col-lg-3 col-md-6">
                  <label className="form-label">Lowest Bid</label>
                  <CurrencyInput
                    type="text"
                    {...register("lowestBid")}
                    name="lowestBid"
                    placeholder="Lowest Bid"
                    prefix="$"
                    allowDecimals={false}
                    value={lowestBid}
                    onValueChange={(value: any) => setValue("lowestBid", value ?? 0)}
                    className="form-control form-icon-input"
                    required
                  />
                  {errors.lowestBid && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {errors.lowestBid.message}
                    </span>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom"> */}
          <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
            <button className="btn btn-secondary ms-2 me-2" type="submit" onClick={prevStep}>{CommonMessages.previousButton}</button>
            <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.INVENTORY}>{CommonMessages.cancelButton}</Link>
            <button className="btn btn-primary ms-2" type="submit">{btnText}</button>
            <button className="btn btn-secondary ms-2" onClick={handleNext}>{CommonMessages.skipButton}</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CommCostBidDown;
