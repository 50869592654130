import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GETCUSTOMERBYAUCTIONID } from '../../../../common/apiConstatnts';
import { formatPhoneNumber } from "../../../../common/constants";
import { CommonMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { encryptId } from '../../../../Encryption/Encryption';
import { setAuctionDetailActive } from '../../../../store/actions/AuctionAction';
import { setActiveTabCustomer } from '../../../../store/actions/CustomerAction';
import handleError from '../../../../Utils/ApiCommonErrorMessages';
import MomentUtil from '../../../../Utils/MomentUtil';

const AuctionCustomer = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filterCustomers, setFilterCustomers] = useState([]);
    const [originalCustomers, setOriginalCustomer] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [customerData, setCustomerData] = useState<[]>([]);
    const [searchText, setSearchText] = useState('')
    const auctionId = useSelector((state: any) => state?.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const PER_PAGE = 10;
    const pageCount = Math.ceil(customerData.length / PER_PAGE);
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const getUrl = window.location.pathname
    const modeType = getUrl.split('/')[2]

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...customerData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setCustomerData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterCustomers(firstSet);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalCustomers?.filter((x: any) => x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) || x.company?.toLocaleLowerCase()?.includes(keyword) || x.email?.toLocaleLowerCase()?.includes(keyword) ||
            x.customerId?.toString()?.includes(keyword))
        paginateData(filterData);
        setCurrentPage(0);
    }

    const handlePrev = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionSeller" }))
    }

    const handleNext = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionInventory" }))
    }

    const getCustomerBasedOnAuction = () => {
        setLoading(true);
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(`${GETCUSTOMERBYAUCTIONID}?auctionId=${aId}`).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setFilterCustomers([]);
                return;
            }
            else {
                const customerDataData = response.data.data;
                setOriginalCustomer(customerDataData);
                paginateData(customerDataData);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            handleError(error);

        });
    }

    useEffect(() => {
        getCustomerBasedOnAuction();
    }, []);

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = customerData?.slice(offset, offset + PER_PAGE);
        setFilterCustomers(nextSet);
    }, [currentPage]);

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText]);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="row">
                <div className="col-md-6">
                    <h5 className='mt-4'>Auction Buyers</h5>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="search-box mt-2 p-2 ">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="table-responsive mx-n1 px-1 scrollbar">
                        <table className="table fs--1 mb-0 border-top border-200">
                            <thead>
                                <tr>
                                    <th className="bg-gradiant" scope="col" data-sort="customerId">
                                        <div className='d-flex align-items-center'>
                                            <span>Buyer Id</span>
                                            <div className="sorting_group">
                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'customerId')} ></button>
                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'customerId')}></button>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                        <div className='d-flex align-items-center'>
                                            <span>First Name</span>
                                            <div className="sorting_group">
                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')}></button>
                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="lastName">
                                        <div className='d-flex align-items-center'>
                                            <span>Last Name</span>
                                            <div className="sorting_group">
                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'lastName')}></button>
                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'lastName')}></button>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="createdDate">
                                        <div className='d-flex align-items-center'>
                                            <span>Created Date ({defaultTimezone})</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="company">
                                        <div className='d-flex align-items-center'>
                                            <span>Company</span>
                                            <div className="sorting_group">
                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'company')}></button>
                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'company')}></button>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="phoneNumber">
                                        <div className='d-flex align-items-center'>
                                            <span>Phone No</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="activeStatus">
                                        <div className='d-flex align-items-center'>
                                            <span>Activation Status</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="list" id="table-latest-review-body">
                                {filterCustomers.length > 0 ? filterCustomers?.map((customer: any, index: any) => {
                                    const createdDate = moment(momentUtil.parseTimeInUtc({ dateTimeString: customer?.createdDate }))
                                    return (
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                            <td className="align-middle p-2">
                                                <Link className="btn text-decoration-underline m-0 p-0 fw-normal text-start" data-bs-dismiss="modal"
                                                    to={`${routeConstant.CUSTOMERSMOREINFO}/${encryptId(customer.customerId)}`}
                                                    target="_blank"
                                                    onClick={() => {
                                                        dispatch(setActiveTabCustomer("auctionTab"));
                                                    }}
                                                >
                                                    {customer.customerId ? customer.customerId : "-"}
                                                </Link>
                                            </td>
                                            <td className="align-middle p-2">{customer.firstName ?? '-'}</td>
                                            <td className="align-middle p-2">{customer.lastName ?? '-'}</td>
                                            <td className="align-middle p-2">
                                                {createdDate.format('MM/DD/YYYY')}
                                                {/* {moment(customer?.createdDate).format('MM/DD/YYYY').toString()} */}
                                            </td>
                                            <td className="align-middle p-2">{customer.company ?? '-'}</td>
                                            <td className="align-middle p-2">{customer.phoneNumber ? formatPhoneNumber(customer.phoneNumber) : '-'}</td>
                                            <td className="align-middle text-start status p-2">
                                                {customer.activeStatus == "True" ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                    <span className="badge-label">Active</span>

                                                </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                    <span className="badge-label">Inactive</span>

                                                </span>}
                                            </td>
                                        </tr>
                                    )
                                }) :
                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                        <td className='text-center p-4' colSpan={7}>No records found!</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex align-items-center justify-content-end mt-4'>
                        {filterCustomers.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}

export default AuctionCustomer;