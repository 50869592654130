import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GETSALESRECORDBASEDONAUCTION } from '../../../../common/apiConstatnts';
import { CommonMessages } from '../../../../common/messageConstants';
import { setAuctionDetailActive } from '../../../../store/actions/AuctionAction';
import { formatCurrency, formatNumber, removeHtmlTags } from '../../../../Utils/Utils';
import * as routeConstant from '../../../../common/routeConstants'
import ReactPaginate from 'react-paginate';
import handleError from '../../../../Utils/ApiCommonErrorMessages';

const AuctionSales = () => {
    const auctionId: any = useSelector((state: any) => state.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const dispatch = useDispatch();
    const [salesRecords, setSalesRecords] = useState<any>([])
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false);
    const [originalSalesRecord, setOriginalSalesRecord] = useState<[]>([]);
    const PER_PAGE = 10;
    const pageCount = Math.ceil(salesRecords?.length / PER_PAGE);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterSalesRecord, setFilterSalesRecord] = useState([]);

    useEffect(() => {
        onGetAuctionSales()
        return () => { }
    }, [])

    const onGetAuctionSales = () => {
        setLoading(true);
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(GETSALESRECORDBASEDONAUCTION, { params: { aId } }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setFilterSalesRecord([])
                return;
            }
            else {
                const salesRecords = response?.data?.data;
                setOriginalSalesRecord(salesRecords)
                paginateData(salesRecords)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setSalesRecords(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSalesRecord(firstSet);
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = salesRecords?.slice(offset, offset + PER_PAGE);
        setFilterSalesRecord(nextSet);
    }, [currentPage]);

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase();
        const filterData: any = originalSalesRecord?.filter((x: any) => {
            // Convert numeric fields to strings for comparison
            const lotNo = x.lotNo?.toString().toLocaleLowerCase();
            const ctrlNo = x.ctrlNo?.toString().toLocaleLowerCase();
            const quantity = x.quantity?.toString().toLocaleLowerCase();
            const tax = x.tax?.toString().toLocaleLowerCase();
            const bidderNumber = x.bidderNumber?.toString().toLocaleLowerCase();
            const price = x.price?.toString().toLocaleLowerCase();

            // Convert non-numeric fields to lowercase
            const lotTitle = x.lotTitle?.toLocaleLowerCase();
            const status = x.status?.toLocaleLowerCase();
            const auctionType = x.auctionType?.toLocaleLowerCase();

            return (
                lotNo?.includes(keyword) ||
                ctrlNo?.includes(keyword) ||
                lotTitle?.includes(keyword) ||
                status?.includes(keyword) ||
                auctionType?.includes(keyword) ||
                price?.includes(keyword) ||
                quantity?.includes(keyword) ||
                bidderNumber?.includes(keyword) ||
                tax?.includes(keyword)
            );
        });

        paginateData(filterData);
        setCurrentPage(0);
    }


    useEffect(() => {
        if (searchText.length > 0) {
            handleSearch(searchText);
        } else {
            setSalesRecords(originalSalesRecord)
        }
    }, [searchText]);

    const handlePrev = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionInvoice" }))
    }

    const handleNext = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionSalesRep" }))
    }

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...filterSalesRecord];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="row">
                <div className="col-md-6">
                    <h5 className='mt-4'>Auction Sales</h5>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="search-box mt-2 p-2 ">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 border-300">
                        <div className="table-responsive mx-n1 px-1 scrollbar">
                            <table className="table fs--1 mb-0 border-top border-200">
                                <thead>
                                    <tr>
                                        <th className="bg-gradiant" scope="col" data-sort="invoiceMasterId">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Lot #</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="verificationCode">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Ctrl No</span>
                                                 <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'ctrlNo')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'ctrlNo')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="customerId">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Title</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotTitle')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotTitle')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="bidderName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Status</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'status')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'status')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="bidderName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Auction Type</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'auctionType')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'auctionType')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="companyName">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Price</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'price')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'price')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="email">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Quantity</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'quantity')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'quantity')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        {/* <th className="bg-gradiant" scope="col" data-sort="date">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Extended</span>
                                            </div>
                                        </th> */}
                                        <th className="bg-gradiant" scope="col" data-sort="buyersPrice">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Tax</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'tax')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'tax')}></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="buyersPrice">
                                            <div className='d-flex align-items-center'>
                                                <span className='white-space-pre'>Bidder #</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'bidderNumber')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'bidderNumber')}></button>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-latest-review-body">
                                    {filterSalesRecord.length > 0 ? filterSalesRecord?.map((salesRecordData: any, index: any) => {
                                        return (

                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                <td className="p-2 white-space-pre">{salesRecordData?.lotNo}</td>
                                                <td className="p-2 white-space-pre">{salesRecordData?.ctrlNo}</td>
                                                <td className="p-2 white-space-pre">{removeHtmlTags(salesRecordData?.lotTitle)}</td>
                                                <td className={`p-2 white-space-pre`}>
                                                    <span className={`badge badge-dpa status-${salesRecordData?.status?.toLocaleLowerCase()}`}>{salesRecordData?.status}</span>
                                                </td>
                                                <td className="p-2 white-space-pre">{salesRecordData?.auctionType}</td>
                                                <td className="p-2 white-space-pre">{formatCurrency(salesRecordData?.price)}</td>
                                                <td className="p-2 white-space-pre">{formatNumber(salesRecordData?.quantity)}</td>
                                                {/* <td className="p-2 white-space-pre">{salesRecordData?.extended}</td> */}
                                                <td className="p-2 white-space-pre">{formatCurrency(salesRecordData?.tax)}</td>
                                                <td className="p-2 white-space-pre">{salesRecordData?.bidderNumber}</td>

                                            </tr>
                                        )
                                    }) :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static text-center">
                                            <td className=' py-4' colSpan={13}>No records found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {filterSalesRecord.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}

export default AuctionSales