import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { decryptId } from "../../Encryption/Encryption";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../Utils/CustomCkEditor";
import MomentUtil from "../../Utils/MomentUtil";
import { formatCurrency, generatePDF, removeHtmlTags, useDebounce } from "../../Utils/Utils";
import { useCurrentDateTime } from "../../Utils/useDateTime";
import { v4 as uuidv4 } from 'uuid';
import * as actions from "../../common/types";

import CommonHelmet from "../../common/HelmetWrapper";
import {
  ADDNEWINVOICE,
  CONFIRMINVOICE,
  GETBIDDERNUMBERBYCUSTOMER,
  GETCLOSEDAUCTOINS,
  GETCOMMONVALUE,
  GETCUSTOMERBYAUCTION,
  GETINVOICEBYID,
  GETINVOICEFOOTERBYAUCTIONID,
  GETLISTTAXRATEBYLOTADDRESS,
  GETLOTSBYBIDDERNUMBER,
  GETWONLOTSFORBIDDER,
  GETWONLOTSFORCUSTOMER,
  UPDATEINVOICE,
  UPDATEREVIEWEDINVOICE,
  VOIDINVOICE,
} from "../../common/apiConstatnts";
import { InvoiceSelect } from "../../common/constants";
import { Breadcrumb } from "../../common/interface";
import {
  errorMessages,
  InvoiceMessages,
} from "../../common/messageConstants";
import * as routeConstant from "../../common/routeConstants";
import {
  addInvoice,
  setInvoiceAdjust,
  setInvoicePayment,
} from "../../store/actions/InvoiceAction";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import AdjustModal from "./AdjustModal";
import LotsDataListing from "./LotsDataListing";
import PaymentModal from "./PaymentModal";
import ShowAdjustPaymentListing from "./ShowAdjustPaymentListing";

interface ActionType {
  // Define the properties of the action type object
  actionType?: string;
  customerId?: any;
  bidderNumber?: any;
  lotId?: any;
}

const CreateInvoice = () => {
  // Params to get data from url
  const params = useParams();
  const dispatch = useDispatch();
  const navigate: any = useNavigate();
  const location = useLocation();
  // Need to check if any state exist in navigate which comes from sales record 
  const type = location.state
  // Invoice ID which we get from params
  const ID = params.id ? decryptId(params.id) : "";

  // Breadcrumb data
  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Invoice", url: routeConstant.INVOICE },
    { label: params.id ? "Edit Invoice" : "Add New Invoice", url: "" },
  ];

  // Initial Data for form
  const initialData = {
    invoiceMasterId: 0,
    dateOfInvoice: "",
    isVoid: false,
    auctionId: 0,
    customerId: 0,
    bidderNumber: 0,
    lotId: 0,
    verificationCode: "",
    isActive: true,
    isDelete: false,
    itemTotal: 0,
    perLotInternetFeeTotal: 0,
    totalBuyerPremium: 0,
    subTotal: 0,
    lotTaxSubTotal: 0,
    invoiceAdjustment: 0,
    invoicePayment: 0,
    shippingCost: 0,
    packingCost: 0,
    invoiceTotal: 0,
    ccPayCharges: 0,
    depositePaid: 0,
    balanceDue: 0,
    payment: 0,
    bidExempt: false,
    paymentAmount: 0,
    convenienceCharge: 0,
    total: 0,
    accRec: 0,
    nameOfCard: "axis-card",
    chooseInvoiceType: "",
    printCustomReportOnClose: false,
    previewReportBeforePrinting: false,
    skipCustomPrintDialog: false,
    sendEInvoiceOnClose: false,
    dontPrintInvoiceOnClose: false,
    notes: "",
    isConfirm: false,
    isVerified: false,
    // Taxable initial states
    taxablePerLotInternetFeeTotal: 0,
    internetFeeTax: 0,
    taxableItemTotal: 0,
    taxablebuyersPremium: 0,
    itemTotalTax: 0,
    bpTax: 0,
    taxabletotalTax: 0,
    // Lot details array
    lstInvoiceDeatils: [
      {
        invoiceDetailId: 0,
        invoiceMasterId: "",
        lotId: 0,
        type: "",
        price: 0,
        qty: 0,
        exempt: false,
        taxrate: 0,
        extendedAmount: 0,
        invAdjust: "",
        surchages: "",
        salesRecordInternetFee: 0,
        salesrecordbidderNumber: 0,
        buyersPrice: "",
        total: 0,
        isActive: true,
        isDelete: false,
        // New fields for separate lot totals
        lotTaxableItemTotal: 0,
        lotTaxableBuyersPremium: 0,
        lotTaxableTotalTax: 0,
      },
    ],
  };

  // Loading state
  const [loading, setLoading] = useState(false);
  // Dynamic footer data
  const [footerData, setFooterData] = useState(null);
  // Set initial value in form value state
  const [formValues, setFormValues] = useState<any>(initialData);
  // Selected option for create invoice like customer - bidder number
  // Default selection is customer
  const [selectedOption, setSelectedOption] = useState<any>(1);
  // State to store customer list which we get from get customer by invoice ID
  const [customerList, setCustomerList] = useState([]);
  // Store selected customer
  const [selectedCustomer, setSelectedCustomer] = useState("");
  // state to set auction list
  const [auctionList, setAuctionList] = useState([]);
  // Store selected auction from dropdown
  const [selectedAuction, setSelectedAuction]: any = useState("");
  // State to store bidder number list by customer ID
  const [bidderList, setBidderList] = useState([]);
  // Store selected bidder number from dropdown
  const [selectedBidder, setSelectedBidder] = useState("");
  // State to store lot list by bidder number
  const [lotList, setLotList] = useState([]);
  // Store selected lot id from dropdown - Multiple lots will save
  const [selectedLot, setSelectedLot]: any = useState([]);
  // State to show/hide details after clicking on load won lots
  const [showDetails, setShowDetails] = useState(false);
  // State selected lot ids
  const [selectedLotIds, setSelectedLotIds] = useState<any>("");
  // Set won lots data
  const [wonLotsList, setWonLotsList] = useState<any>([]);
  // Set data from getbyid
  const [initailInvoiceData, setInitailInvoiceData] = useState<any>([]);
  // Counting words for ckeditor notes
  const maxWordCount = 300;
  const [remainingCount, setRemainingCount] = useState(maxWordCount);
  // State to set invoice detail
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  // data for adjust Edit
  const [ajustEditData, setAdjustEditData] = useState<any>({});
  // Edit data for payment
  const [paymentEditData, setPaymentEditData] = useState<any>({});
  // Adjust popup modal
  const [toggleAdjModal, setToggleAdjModal] = useState<boolean>(false);
  // Payment popup modal state
  const [togglePayModal, setTogglePayModal] = useState<boolean>(false);
  // Set mode for adjust and payment
  const [mode, setMode] = useState<string>("create");
  // Set payment status
  const [paymentTypeData, setPaymentTypeData] = useState<any>([]);
  // State for void
  const [voids, setVoids] = useState<any>(false);
  // Set listing to display
  const [showListing, setShowListing] = useState<any>([]);
  // New data to set create
  const [newDetails, setNewDetails] = useState<any>({});
  // Total payment
  const [netPayment, setNetPayment] = useState(0);
  const momentUtil = MomentUtil.getInstance();
  const dateTime = useCurrentDateTime();
  // const currentUTC = new Date();
  const currentDate: any = momentUtil.parseTimeInUtc({
    dateTimeString: dateTime?.currentDateTime,
  });
  const currentUTCDate = moment.utc(currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS");

  // Make a condition that if ID exist means edit mode then use initialInvoiceData and if create mode then wonlotist
  useEffect(() => {
    setShowListing(ID ? invoiceDetails : wonLotsList);
  }, [ID, invoiceDetails, wonLotsList]);

  // Get adjust data from redux store
  const showAdjustList = useSelector((state: any) => state?.inovice?.adjust);
  // Get payment data from redux store
  const showPaymentList = useSelector((state: any) => state?.inovice?.payment);
  // Form
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: formValues,
    mode: "onChange",
  });
  // Watch value of bidExempt
  const bidExempt = watch("bidExempt");
  // Item total
  const itemTotal = watch("itemTotal");
  //Buyers prem
  const totalBuyerPremium = watch("totalBuyerPremium");
  // Sub total
  const subTotal = watch("subTotal");
  // Total tax
  const taxabletotalTax = watch("taxabletotalTax");
  // sub total + total tax
  const lotTaxSubTotal = watch("lotTaxSubTotal");
  // Total adjust amount
  const invoiceAdjustment = watch("invoiceAdjustment");
  // Calculate total payment amount
  const invoicePayment = watch("invoicePayment");
  // Invoice total = total + payment+adjust
  const invoiceTotal = watch("invoiceTotal");
  // Item total taxable
  const taxableItemTotal = watch("taxableItemTotal");
  // depositePaid value
  const depositePaid = watch("depositePaid");
  const itemTotalTax = watch("itemTotalTax");
  const taxablebuyersPremium = watch("taxablebuyersPremium");
  const bpTax = watch("bpTax");
  // Check verified or not
  const isVerified = watch("isVerified");
  const balanceDue: any = watch("balanceDue");
  // Notes
  const notes = watch("notes");

  // Function will call for create and update
  useEffect(() => {
    const allZeroTaxRate =  showListing.filter(
      (item: any) => item?.isDelete === false
    ).every((item: any) => item.taxrate === 0);
    // If all the taxrate is 0 then taxable value display 0
    if (allZeroTaxRate) {
      setValue('taxablebuyersPremium', 0);
      setValue('taxableItemTotal', 0);
    }
  }, [showListing])

  // Auction type Submit
  const onSubmit = (actionType?: ActionType) => {
    setNewDetails(actionType);
    const data = getValues();
    handleApiAction(data, { actionType: actionType?.actionType });
  };
  // API actions and API call for create , update - review and update/create
  const handleApiAction = (data: any, actionType: ActionType) => {
    dispatch(addInvoice(data));
    // const invoiceTotal = data?.invoiceTotal ?? 0;
    const balanceDue = data?.balanceDue ?? 0;
    const invoicePayment = data?.invoicePayment ?? 0;
    let invoicePaid;
    // Balance due is 0 means - all payment done - means Paid - 1
    // Or when balance is minus then also it is paid
    if (balanceDue <= 0) {
      invoicePaid = 1; // Paid
    } else if (invoicePayment == 0) {
      invoicePaid = 3; //Pending
    } else if (invoicePayment > 0) {
      invoicePaid = 2; //Partial Paid
    }
    const currentDate = momentUtil.parseTimeInUtc({
      dateTimeString: dateTime?.currentDateTime,
    }).format("YYYY-MM-DDTHH:mm:ss.SSS");
    const bidder = selectedOption == 1 ? showListing[0].bidderNumber : type ? type.bidder.value : 0;
    const bidderNumber = ID ? data?.bidderNumber : bidder

    const payload = {
      invoiceMasterId: ID ? ID : 0,
      dateOfInvoice: data?.dateOfInvoice ? data?.dateOfInvoice : currentDate,
      auctionId: data?.auctionId,
      customerId: data?.customerId,
      bidderNumber: bidderNumber,
      invoiceTotal: invoiceTotal,
      verificationCode: data?.verificationCode ?? "",
      isActive: true,
      isDelete: false,
      isVerified: data.isVerified ? true : actionType?.actionType === "confirm" ? true : false,
      invoicePaid: invoicePaid,
      isVoid: false,
      invoiceNumber: 0,
      buyersPremium: data?.totalBuyerPremium ?? 0,
      subTotal: data?.subTotal ?? 0,
      lotTaxSubTotal: data?.lotTaxSubTotal ?? 0,
      balanceDue: balanceDue,
      payment: data?.payment ?? 0,
      itemTotal: data?.itemTotal ?? 0,
      bidExempt: data?.bidExempt ?? false,
      invoiceAdjustment: data?.invoiceAdjustment ?? 0,
      invoicePayment: data?.invoicePayment ?? 0,
      depositePaid: data?.depositePaid ?? 0,
      notes: formValues?.notes ?? "",
      perLotInternetFeeTotal: 0,
      taxableItemTotal: data?.taxableItemTotal ? data?.taxableItemTotal : 0,
      taxablePerLotInternetFeeTotal: 0,
      taxablebuyersPremium: data?.taxablebuyersPremium ?? 0,
      taxabletotalTax: data?.taxabletotalTax ?? 0,
      lstInvoiceDetailsRequests:
        showListing && showListing?.length > 0
          ? showListing.map((invoiceDetail: any) => ({
            invoiceDetailId: invoiceDetail?.invoiceDetailId ?? 0,
            invoiceMasterId: ID ? ID : 0,
            lotId: invoiceDetail?.lotId ?? 0,
            type: invoiceDetail?.type ?? 0,
            price: invoiceDetail?.price ?? 0,
            qty: invoiceDetail?.qty ?? 0,
            // This field is remove from frontend side
            extendedAmount: invoiceDetail?.extendedAmount ?? 0,
            invAdjust: "0",
            surchages: "0",
            internetFee: 0,
            buyersPrice: invoiceDetail?.buyersPrice ?? 0,
            total: invoiceDetail?.total ?? 0,
            taxRate: invoiceDetail?.taxrate ?? 0,
            exempt: invoiceDetail?.exempt ?? true,
            notes: invoiceDetail?.notes ?? "",
            isActive: invoiceDetail.isActive,
            isDelete: invoiceDetail.isDelete,
            lotTaxableItemTotal: invoiceDetail?.price * invoiceDetail?.taxrate / 100,
            lotTaxableBuyersPremium: invoiceDetail?.buyersPrice * invoiceDetail?.taxrate / 100,
            lotTaxableTotalTax: (invoiceDetail?.price + invoiceDetail?.buyersPrice) * invoiceDetail?.taxrate / 100,
          }))
          : [],
      lstInvoiceAdjustRequest:
        showAdjustList && showAdjustList.length > 0
          ? showAdjustList.map((adjust: any) => ({
            invoiceAdjustId: adjust?.invoiceAdjustId ?? 0,
            invoiceMasterId: ID ? ID : 0,
            description: adjust?.description ?? "",
            amount: adjust?.amount ?? 0,
            chgCr: adjust?.chgCr ?? 0,
            taxAmount: adjust?.taxAmount !== '' ? adjust?.taxAmount : 0,
            isActive: adjust?.isActive,
            isDelete: adjust?.isDelete,
          }))
          : [],
      lstInvoicePaymentRequest:
        showPaymentList && showPaymentList.length > 0
          ? showPaymentList.map((payment: any) => ({
            invoicePaymentId: payment?.invoicePaymentId ?? 0,
            invoiceMasterId: ID ? ID : 0,
            date: payment?.date,
            amountPaid: payment?.amountPaid ?? 0,
            amountRecd: 0,
            payType: payment?.payType ?? 0,
            buyerPremium: 0,
            convenienceCharge: payment?.convenienceCharge ? payment?.convenienceCharge : 0,
            checkNumber: payment?.check1 ?? "",
            isActive: payment?.isActive,
            isDelete: payment?.isDelete,
          }))
          : [],
    };

    let apiEndpoint: any;
    let successMessage: any;
    let errorMessage: any;
    // Auction type is confirm means call API review
    if (actionType?.actionType === "confirm") {
      apiEndpoint = ID ? UPDATEREVIEWEDINVOICE : CONFIRMINVOICE;
      successMessage = ID
        ? InvoiceMessages.invoiceRevUpdated
        : InvoiceMessages.invoiceRevCreated;
      errorMessage = errorMessages.error401;
      //  if submit then update or create API
    } else if (actionType?.actionType === "submit") {
      apiEndpoint = ID ? UPDATEINVOICE : ADDNEWINVOICE;
      successMessage = ID
        ? InvoiceMessages.invoiceUpdated
        : InvoiceMessages.invoiceCreated;
      errorMessage = "Sorry, but update confirmed invoices is not possible";
    }
    //  Use when delete
    if (actionType?.actionType === "createInvoice") {
      apiEndpoint = ADDNEWINVOICE;
      successMessage = InvoiceMessages.invoiceCreated;
    }
    setLoading(true);
    axios
      .post(apiEndpoint, payload, config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(
            `${response?.data?.message}, because its reviewed and verified`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          return;
        } else {
          toast.success(successMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          if (!ID) {
            navigate(routeConstant.INVOICE);
          } else {
            getInvoiceById();
          }
          setLoading(false);

        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  // Configuration for authorization
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // Handle radio button change for customer & auction
  const handleRadioChange = (value: any) => {
    setShowDetails(false);
    setSelectedOption(value);
    setValue("customerId", "");
    setValue("auctionId", "");
    setValue("lotId", "");
    setValue("bidderNumber", "");
    setSelectedCustomer("");
    setSelectedAuction("");
    setSelectedBidder("");
    setSelectedLot([]);
    setSelectedLotIds("");
    setCustomerList([]);
    setBidderList([]);
    setLotList([]);
  };

  // API for get auction listing  filtered by auction status is sold while create invoice
  const getSoldAuctions = () => {
    setLoading(true);
    axios.get(`${GETCLOSEDAUCTOINS}`, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          return;
        } else {
          const data: any = response?.data?.data.filter(
            (item: any) => item.auctionStatus == "Sold" || "Active"
          );
          const auctionData = data?.map((lData: any) => {
            return {
              label: `${lData.auctionCode} - ${lData?.auctionTitle}`,
              value: lData.auctionId,
            };
          });
          setAuctionList(auctionData);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  // Call function while page load
  useEffect(() => {
    getSoldAuctions();
  }, []);

  // Auction change function
  const handleAuctionChange = (e: any) => {
    const selectedAuction = e.value;
    // Set value of auction
    setSelectedAuction(selectedAuction);
    setValue("auctionId", selectedAuction);
    setValue("customerId", "");
    setValue("lotId", "");
    setValue("bidderNumber", "");
    setSelectedCustomer("");
    setSelectedBidder("");
    setSelectedLot([]);
    setSelectedLotIds("");
    setCustomerList([]);
    setBidderList([]);
    setLotList([]);
    // Load API to get customer listing by auction Id
    setLoading(true);
    //  Get customers by auction Id - Change endpoint once API get
    axios
      .get(`${GETCUSTOMERBYAUCTION}?AuctionId=${selectedAuction}`, config)
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            const data: any = response?.data?.data;
            const customerData = data?.map((lData: any) => {
              return {
                label: `${lData?.customerId} - ${lData?.customerName}`,
                value: lData?.customerId,
              };
            });
            setCustomerList(customerData);
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });;
  };

  // Customer change function
  const handleCustomerChange = (e: any) => {
    // Set value of customer
    const selectedCustomer = e.value;
    setSelectedCustomer(selectedCustomer);
    setValue("customerId", selectedCustomer);
    setValue("bidderNumber", "");
    setValue("lotId", "");
    setSelectedBidder("");
    setSelectedLot([]);
    setSelectedLotIds("");
    setBidderList([]);
    setLotList([]);

    // Add condition that if selected option is bidder number then only below API will call
    if (selectedOption == 2) {
      // Load API to get bidder number listing by customer Id
      setLoading(true);
      //  For testing use static Id = 448
      const auctionId = selectedAuction ? selectedAuction : type.auction.value
      axios
        .get(
          `${GETBIDDERNUMBERBYCUSTOMER}?AuctionId=${auctionId}&CustomerId=${selectedCustomer}`
        )
        .then((response) => {
          setLoading(false);
          if (response.data.success === "Fail") {
            Swal.fire({
              title: "No Bidder # available for the selected customer.",
              text: "Please select another customer.",
              icon: "warning",
              confirmButtonColor: "#006800",
              confirmButtonText: "Ok",
            });
            setBidderList([]);
          } else {
            const biddersData: any = response?.data?.data;
            const bidderData = biddersData?.map((lData: any) => {
              return {
                label: `${lData.bidderNumber}`,
                value: lData.bidderNumber,
              };
            });
            setBidderList(bidderData);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  };
  // Bidder number change function
  const handleBidderNumberChange = (e: any) => {
    // Set value of customer
    const selectedBidder = e.value;
    setSelectedBidder(selectedBidder);
    setValue("bidderNumber", selectedBidder);
    setValue("lotId", "");
    setSelectedLot([]);
    setSelectedLotIds("");
    setLotList([]);
    const auctionId = selectedAuction ? selectedAuction : type.auction.value
    // Call API for get lot listing by bidder number
    setLoading(true);
    axios
      .get(
        `${GETLOTSBYBIDDERNUMBER}?AuctionId=${auctionId}&bidderNumber=${selectedBidder}`
      )
      .then((response) => {
        setLoading(false);
        if (response.data.success === "Fail") {
          setLotList([]);
        } else {
          const lotsData: any = response?.data?.data;
          const lotData = lotsData?.map((lData: any) => {
            return {
              label: `${lData.lotNo} - ${removeHtmlTags(lData.title)}`,
              value: lData.lotId,
            };
          });
          setLotList(lotData);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  // Handle lot change function
  const handleLotChanges = (e: any) => {
    const selectedLots: any = e;
    setValue("lotId", e?.value);
    setSelectedLot(selectedLots);
    const ids = selectedLots?.map((item: any) => item?.value);
    setSelectedLotIds(ids);
  };


  // Call API for handle load lots
  const handleLoadlots = () => {
    setLoading(true);
    setShowDetails(true);
    // If selected option is 1 means customer is selected
    if (selectedOption == 1) {
      // Else Bidder is selected
      const url = `${GETWONLOTSFORCUSTOMER}?auctionId=${selectedAuction}&customerId=${selectedCustomer}`;
      axios
        .get(url)
        .then((response) => {
          setLoading(false);
          if (response.data.success === "Fail") {
            Swal.fire({
              text: "No Won lots found for this Invoice",
              icon: "warning",
              confirmButtonColor: "#006800",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setShowDetails(false);
                setWonLotsList([]);
              }
            });
          } else {
            const data: any = response?.data?.data;
            const updatedList = data.map((item: any) => ({
              ...item,
              buyersPrice: item?.lotbuyerPremium,
              taxrate: item?.lotAddresstaxRate,
              exempt: item?.lotAddresstaxExempt
            }));

            setWonLotsList(updatedList);
            setShowDetails(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      // Else Bidder is selected
      const lotIdsString =
        (selectedLotIds.length > 0 && selectedLotIds?.join(",")) || 0;
      // Make a query params for which value is not blank only that param will be send as payload
      const queryParams = [
        selectedAuction !== "" ? `auctionId=${selectedAuction}` : "",
        selectedBidder !== "" ? `bidderNumber=${selectedBidder}` : "",
        selectedLotIds.length !== 0 ? `lotIds=${lotIdsString}` : "",
      ];

      const url = `${GETWONLOTSFORBIDDER}?${queryParams.filter(Boolean).join("&")}`;
      axios
        .get(url)
        .then((response) => {
          setLoading(false);
          if (response.data.success === "Fail") {
            Swal.fire({
              text: "No Won lots found for this Invoice",
              icon: "warning",
              confirmButtonColor: "#006800",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setShowDetails(false);
                setWonLotsList([]);
              }
            });
          } else {
            const data: any = response?.data?.data;
            const updatedList = data.map((item: any) => ({
              ...item,
              buyersPrice: item.lotbuyerPremium,
              taxrate: item?.lotAddresstaxRate,
              exempt: item?.lotAddresstaxExempt
            }));
            setWonLotsList(updatedList);
            setShowDetails(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  };

  const debouncedRequest = useDebounce(() => {
    handleLoadlots();
  });

  useEffect(() => {
    // If type is coming from sales record then auctomatically call load won API 
    if (type) {
      setSelectedAuction(type.auction.value)
      if (selectedOption == 1) {
        handleAuctionChange(type.auction);
        handleCustomerChange(type.customer);
      } else if (selectedOption == 2) {
        handleAuctionChange(type.auction);
        handleCustomerChange(type.customer);
        handleBidderNumberChange(type.bidder);
        setSelectedLot(type.lotData)
        // handleLotChanges(type.lot);  
      }
      debouncedRequest();
    }
  }, [type, selectedOption])

  // GETINVOICEBYID API call
  const getInvoiceById = () => {
    setLoading(true);
    axios
      .get(`${GETINVOICEBYID}?invoiceMasterId=${ID}`, { ...config })
      .then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          const dataInvoice = response?.data?.data;
          if (dataInvoice) {
            // Set invoice data
            setInitailInvoiceData(dataInvoice);
            // set lstInvoiceDeatils in new state
            const invoiceDetail = dataInvoice?.lstInvoiceDeatils;
            const updatedData = calculateItemTotal(invoiceDetail);
            setInvoiceDetails(updatedData);
            // Dispatch adjust and payment data
            // payment data with unique Id 
            const updatedPayments = dataInvoice?.lstInvoicePayment.map((payment: any) => ({
              ...payment,
              paymentUniqueId: uuidv4()
            }));
            const updatedAdjusts = dataInvoice?.lstInvoiceAdjust.map((payment: any) => ({
              ...payment,
              adjustUniqueId: uuidv4()
            }));
            dispatch(setInvoicePayment(updatedPayments));
            dispatch(setInvoiceAdjust(updatedAdjusts));
            axios.get(`${GETINVOICEFOOTERBYAUCTIONID}?AuctionId=${dataInvoice?.auctionId}`, config).then(
              (response: any) => {
                if (response?.data?.success === "Fail") {
                  setLoading(false);
                  return;
                }
                else {
                  let footerData = response?.data?.data?.description;
                  setFooterData(footerData);
                  setLoading(false);
                }
              });
              // Calculate item total tax and bpTax
              const itemTotalTax = dataInvoice?.lstInvoiceDeatils?.reduce((total: any, item: any) => {
                if (!item?.exempt && item?.taxrate > 0) {
                  // Check if the item is not exempt
                  const amount = parseFloat(item?.price || 0);
                  return total + amount;
                }
                return total;
              }, 0);
          
              // BP taxable
              const bpTax = dataInvoice?.lstInvoiceDeatils?.reduce(
                (total: any, item: any) => {
                  if (!item?.exempt && item?.taxrate > 0) {
                    // Check if the item is not exempt
                    const amount = parseFloat(item?.buyersPrice || 0);
                    return total + amount;
                  }
                  return total;
                },
                0
              );
            setFormValues({ ...dataInvoice, depositePaid: Number(dataInvoice?.depositePaid)?.toFixed(2) });
            reset({ ...dataInvoice, depositePaid: Number(dataInvoice?.depositePaid)?.toFixed(2),itemTotalTax:itemTotalTax,bpTax:bpTax });
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  //  Call Invoice by id API
  useEffect(() => {
    if (ID) {
      getInvoiceById();
    }
    return () => {
      dispatch({ type: actions.RESETINVOICE });
    }
  }, []);

  // CKEditor change
  const handleEditorChange = (value: any) => {
    setFormValues({ ...formValues, notes: value });
  };

  const calculateItemTotal = (items: any) => {
    return items?.map((item:any) => {
      const pricePlusBuyerPrice:any = item?.price + item?.buyersPrice;
      if (!item?.exempt) {
        const taxAmount = (pricePlusBuyerPrice * item?.taxrate) / 100;
        const itemTotal = pricePlusBuyerPrice + taxAmount;
        return {
          ...item,
          total: itemTotal?.toFixed(2), // formatting to 2 decimal places
        };
      }
      return { ...item, total: pricePlusBuyerPrice }; // If exempt, no calculation
    });
  };
   
    // Bid exempt radio change value
  const handleRadioChangebid = (value: any) => {
    setValue("bidExempt", value);
    // If value true then set exempt true in show listing 
    if (value) {
      const newShowListing =  showListing.filter(
        (item: any) => item?.isDelete === false
      ).map((item: any) => (
        {
          ...item,
          exempt: true,
          taxrate: 0,
          total: Number(item?.price) + Number(item?.buyersPrice)
        }))
      setShowListing(newShowListing);
      setValue("taxableItemTotal", 0);
      setValue("taxablebuyersPremium", 0);
      // Else call API to get new tax rate
    } else {
      // Payload
      const payload =
        showListing &&  showListing.filter(
      (item: any) => item?.isDelete === false
    ).length > 0 ?  showListing.filter(
      (item: any) => item?.isDelete === false
    ).map((data: any) => ({
          lotId: data?.lotId,
          addressLine1: data?.addressLine1 !== null ? data?.addressLine1 : data?.lotaddressline1,
          city: data?.strCity,
          state: data?.strState,
          country: data?.strCountry,
          zipCode: data?.zipCode,
          taxExempt: false
        })) : []

      setLoading(true);
      axios
        .post(
          GETLISTTAXRATEBYLOTADDRESS,
          payload,
          config
        )
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              const data: any = response?.data?.data;
              const newShowListing =  showListing.filter(
                (item: any) => item?.isDelete === false
              )?.map((item: any) => {
                const newTaxRate = data?.find((tax: any) => tax?.lotid == item?.lotId)
                if (newTaxRate) {
                  return {
                    ...item,
                    exempt: false,
                    taxrate: newTaxRate?.taxRate,
                    total: item.total + ((Number(item?.price) + Number(item?.buyersPrice)) * newTaxRate?.taxRate / 100)
                  }
                }
                return item;
              });
              setShowListing(newShowListing);
              setLoading(false)
            }
          }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }
  };

  // Adjust popup edit
  const handleEditAdjust = (id: any, data: any) => {
    setLoading(true);
    const newData = {
      ...data,
      id,
    };
    setAdjustEditData(newData);
    setToggleAdjModal(true);
    setMode("edit");
  };

  // Payment edit popup
  const handleEditPayment = (id: any, data: any) => {
    const newData = {
      ...data,
    };
    setPaymentEditData(newData);
    setTogglePayModal(true);
    setMode("edit");
    return;
  };

  const closeModal = () => {
    setToggleAdjModal(false);
    setAdjustEditData({});
  };

  const handlePayClick = () => {
    setTogglePayModal(true);
    setMode("create");
  };

  const handleClick = () => {
    setLoading(true);
    setToggleAdjModal(true);
    setMode("create");
  };

  const closeModalPay = () => {
    setTogglePayModal(false);
    setPaymentEditData({});
  };

  const handleDeleteAdjust = (id: any, data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(
      (result) => {
        if (result?.isConfirmed) {
          let updatedData = [...showAdjustList];
          if (data?.invoiceAdjustId == 0) {
            updatedData = showAdjustList.filter((item: any) => item.adjustUniqueId !== data?.adjustUniqueId);
            dispatch(setInvoiceAdjust(updatedData));
          } else {
            updatedData = showAdjustList.map((item: any) => {
              if (item.adjustUniqueId === data?.adjustUniqueId) {
                return {
                  ...item,
                  isActive: false,
                  isDelete: true,
                };
              }
              return item;
            });
            dispatch(setInvoiceAdjust(updatedData));
          }
        }
      },
      (error) => { }
    );
  };

  const handleDeletePayment = (data: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(
      (result) => {
        if (result?.isConfirmed) {
          let updatedData = [...showPaymentList];
          if (data?.invoicePaymentId == 0) {
            updatedData = showPaymentList.filter((item: any) => item.paymentUniqueId !== data?.paymentUniqueId);
            dispatch(setInvoicePayment(updatedData));
          } else {
            updatedData = showPaymentList.map((item: any) => {
              if (item.paymentUniqueId === data?.paymentUniqueId) {
                return {
                  ...item,
                  isActive: false,
                  isDelete: true,
                };
              }
              const totalPaymentAmounts = updatedData?.filter((item: any) => item.isDelete == false).reduce(
                (total: any, payment: any) => {
                  const amount = parseFloat(payment?.amountPaid || 0);
                  return total + amount;
                },
                0
              );
              setNetPayment(totalPaymentAmounts);
              setValue('invoicePayment', totalPaymentAmounts)
              return item;
            });
            dispatch(setInvoicePayment(updatedData));
          }
        }
      },
      (error) => { }
    );
  };

  useEffect(() => {
    // Calculate Item total if ID is not exist
    if ( showListing.filter(
      (item: any) => item?.isDelete === false
    ).length > 0) {
      const itemTotal = showListing.filter( 
        (item: any) => item?.isDelete === false
      )?.reduce((total: any, item: any) => {
        const amount = parseFloat(item?.price || 0);
        return total + amount;
      }, 0);
      setValue("itemTotal", itemTotal);
      //Buyers premium total
      const totalBuyerPremium = showListing.filter(
        (item: any) => item?.isDelete === false
      )?.reduce(
        (total: any, lot: any) => total + lot?.buyersPrice,
        0
      );
      setValue("totalBuyerPremium", totalBuyerPremium);
      // Sub total count
      const subTotal = itemTotal + totalBuyerPremium;
      setValue("subTotal", subTotal);

    }
  }, [showListing]);

  useEffect(() => {
    // Calculate Item total if ID is not exist
    if (wonLotsList.length > 0) {
      const itemTotal = wonLotsList?.reduce((total: any, item: any) => {
        const amount = parseFloat(item?.price || 0);
        return total + amount;
      }, 0);
      setValue("itemTotal", itemTotal);
      //Buyers premium total
      const totalBuyerPremium = wonLotsList?.reduce(
        (total: any, lot: any) => total + lot?.buyersPrice,
        0
      );
      setValue("totalBuyerPremium", totalBuyerPremium);
      // Sub total count
      const subTotal = itemTotal + totalBuyerPremium;
      setValue("subTotal", subTotal);

    }
  }, [wonLotsList]);

  useEffect(() => {
    // Calculate the total adjustment amount
    const totalAdjustAmounts = showAdjustList?.filter((item: any) => item.isDelete == false)?.reduce(
      (total: number, adjust: any) => {
        // Parse the amount and taxAmount
        const amount = parseFloat(adjust?.amount || 0);
        const taxAmount = parseFloat(adjust?.taxAmount || 0);
        // Add taxAmount to amount
        const adjustedAmount = amount + taxAmount;
        // Apply chgCr logic - if chgCr is credit then value should be minus or if charge then value is plus
        const finalAmount =
          adjust?.chgCr === 1 ? -adjustedAmount : adjustedAmount;
        // Sum the adjusted amounts
        return total + finalAmount;
      },
      0
    );

    // Update the state with the total adjustment amount
    setValue("invoiceAdjustment", totalAdjustAmounts);
  }, [showAdjustList]);

  // Calculate total payment amount
  useEffect(() => {
    const totalPaymentAmounts = showPaymentList?.filter((item: any) => item.isDelete == false).reduce(
      (total: any, payment: any) => {
        const amount = parseFloat(payment?.amountPaid || 0);
        return total + amount;
      },
      0
    );
    setValue("invoicePayment", totalPaymentAmounts);
    setNetPayment(totalPaymentAmounts);
  }, [showPaymentList, netPayment]);

  // Calculate taxable value
  useEffect(() => {
    const itemTotalTax = showListing.filter(
      (item: any) => item?.isDelete === false
    ).reduce((total: any, item: any) => {
      if (!item?.exempt && item?.taxrate > 0) {
        // Check if the item is not exempt
        const amount = parseFloat(item?.price || 0);
        return total + amount;
      }
      return total;
    }, 0);
    setValue("itemTotalTax", itemTotalTax);

    // BP taxable
    const bpTax =  showListing.filter(
      (item: any) => item?.isDelete === false
    )?.reduce(
      (total: any, item: any) => {
        if (!item?.exempt && item?.taxrate > 0) {
          // Check if the item is not exempt
          const amount = parseFloat(item?.buyersPrice || 0);
          return total + amount;
        }
        return total;
      },
      0
    );
    setValue("bpTax", bpTax);

    let taxableItemTotal: any = 0;
    let taxablebuyersPremium = 0;
    let totalBuyerPremium = 0;
    let subTotal = 0;

    // Calculate tax
    showListing.filter(
      (item: any) => item?.isDelete === false
    ).map((item: any) => {
      const price = Number(item?.price);
      const lotbuyerPremium = Number(item?.buyersPrice);
      const taxRate = item?.taxrate;
      if (!item?.exempt && item?.taxrate > 0) {
        // Total tax amount 
        // Check if the item is not exempt
        const taxTotal = price * taxRate / 100;
        // Accumulate the total tax amount
        taxableItemTotal = taxableItemTotal + taxTotal;
        setValue("taxableItemTotal", taxableItemTotal);
        // BP tax
        const bpTaxTotal = lotbuyerPremium * taxRate / 100;
        // Accumulate the total bp amount
        taxablebuyersPremium += bpTaxTotal;
        setValue("taxablebuyersPremium", taxablebuyersPremium);
      }
      // Total buyers premium
      totalBuyerPremium += lotbuyerPremium
      setValue('totalBuyerPremium', totalBuyerPremium)

      // Total subTotal
      subTotal = itemTotal + totalBuyerPremium;
      setValue('subTotal', subTotal)
    });
  }, [showListing, itemTotal]);

  // Calculate total tax
  useEffect(() => {
    const taxabletotalTax =
      Number(taxableItemTotal || 0) +
      Number(taxablebuyersPremium || 0);
    setValue("taxabletotalTax", taxabletotalTax);
  }, [taxableItemTotal, taxablebuyersPremium]);

  // Calculate Lot + Tax Sub Total
  useEffect(() => {
    // total tax + subTotal
    const lotTaxSubTotal = Number(subTotal?.toFixed(2)) + Number(taxabletotalTax?.toFixed(2));
    setValue("lotTaxSubTotal", lotTaxSubTotal);
  }, [subTotal, taxabletotalTax])
  // Calculate invoice total
  useEffect(() => {
    //invoiceTotal =  lotTaxSubTotal + invoice adjust
    const invoiceTotal =
      Number(lotTaxSubTotal) +
      Number(invoiceAdjustment);
    setValue("invoiceTotal", invoiceTotal ?? 0);
  }, [lotTaxSubTotal, invoicePayment, invoiceAdjustment]);

  // Balance due
  // Formula : Balance Due=Invoice Total+Invoice Adjust−Invoice Payment−Deposit Paid
  useEffect(() => {
    // balanceDue = Invoice total - payment
    const balanceDue =
    Number(invoiceTotal?.toFixed(2)) - Number(invoicePayment?.toFixed(2));
    setValue("balanceDue", balanceDue ? balanceDue : 0);
  }, [invoiceTotal, invoicePayment, invoiceAdjustment, depositePaid]);

  // Void invoice
  const handleVoidConfirmation = () => {
    Swal.fire({
      title: "Are you sure you want to proceed?",
      text: "This action cannot be undo.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(`${VOIDINVOICE}?invoiceMasterId=${ID}&isVoid=${true}`, config)
          .then(
            (response: any) => {
              if ((response.data.data = true)) {
                setVoids(true);
                toast.success(InvoiceMessages.voidInvoice, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                getInvoiceById();
              }
              setLoading(false);
            }).catch((error) => {
              setLoading(false);
              handleError(error);
            });
      }
    });
  };

  // Invoice confirmation while submit
  const handleConfirm = (data: any) => {
    Swal.fire({
      title: "Do you publish the invoice?",
      text: "",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleApiAction(data, {
          actionType: "confirm",
        });
      } else {
        return;
      }
    });
  };

  // Enable button only when all required conditions are met - Load won lots buttons
  const isButtonDisabled = () => {
    if (selectedOption === 1) {
      return !(selectedAuction && selectedCustomer);
    } else if (selectedOption === 2) {
      return !(
        selectedAuction &&
        selectedCustomer &&
        selectedBidder &&
        selectedLot.length > 0
      );
    }
    return true;
  };

  // Get payment types to display paytype
  useEffect(() => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem(
          "companyId"
        )}`
      )
      .then(
        (response) => {
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setPaymentTypeData(response?.data?.data);
          }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error);
      });

  }, []);

  const handlePrint = () => {
    const data = getValues();
    //  Creating payload for generate PDF as it is not getting directly from 
    const balanceDue = data?.balanceDue ?? 0;
    const invoicePayment = data?.invoicePayment ?? 0;
    let invoicePaid;
    // Balance due is 0 means - all payment done - means Paid - 1
    if (balanceDue <= 0) {
      invoicePaid = 1; // Paid
    } else if (invoicePayment == 0) {
      invoicePaid = 3; //Pending
    } else if (invoicePayment > 0) {
      invoicePaid = 2; //Partial Paid
    }
    const pdfData = {
      invoiceMasterId: initailInvoiceData?.invoiceMasterId,
      dateOfInvoice: initailInvoiceData?.dateOfInvoice,
      auctionId: initailInvoiceData?.auctionId,
      customerId: initailInvoiceData?.customerId,
      verificationCode: initailInvoiceData?.verificationCode,
      isVerified: initailInvoiceData?.isVerified,
      isVoid: initailInvoiceData?.isVoid,
      invoiceNumber: initailInvoiceData?.invoiceNumber,
      companyId: initailInvoiceData?.companyId,
      isActive: initailInvoiceData?.isActive,
      isDelete: initailInvoiceData?.isDelete,
      invoicePaid: invoicePaid,
      itemTotal: data?.itemTotal,
      totalBuyerPremium: data?.totalBuyerPremium,
      subTotal: data?.subTotal,
      lotTaxSubTotal: data?.lotTaxSubTotal,
      totalDue: data?.totalDue,
      invoiceTotal: data?.invoiceTotal,
      balanceDue: data?.balanceDue,
      payment: 0,
      customerName: initailInvoiceData?.customerName,
      customerAddress: initailInvoiceData?.customerAddress,
      customerMobile: initailInvoiceData?.customerMobile,
      customerMobile2: initailInvoiceData?.customerMobile2,
      customerCity: initailInvoiceData?.customerCity,
      customerState: initailInvoiceData?.customerState,
      customerCountry: initailInvoiceData?.customerCountry,
      customerEmail: initailInvoiceData?.customerEmail,
      auctionCode: initailInvoiceData?.auctionCode,
      auctionTitle: initailInvoiceData?.auctionTitle,
      auctionAddress: initailInvoiceData?.auctionAddress,
      auctionCity: initailInvoiceData?.auctionCity,
      auctionState: initailInvoiceData?.auctionState,
      auctionCountry: initailInvoiceData?.auctionCountry,
      bidderNumber: type ? type.bidder.value : initailInvoiceData?.bidderNumber,
      companyName: initailInvoiceData?.companyName,
      bidExempt: data?.bidExempt ?? false,
      invoiceAdjustment: data?.invoiceAdjustment ?? 0,
      invoicePayment: data?.invoicePayment ?? 0,
      depositePaid: data?.depositePaid ?? 0,
      notes: formValues?.notes ?? "",
      pickupNumber: initailInvoiceData?.pickupNumber,
      isLateFeeCalculate: false,
      perLotInternetFeeTotal: 0,
      taxableItemTotal: data?.taxableItemTotal ?? 0,
      taxablePerLotInternetFeeTotal: 0,
      taxablebuyersPremium: data?.taxablebuyersPremium ?? 0,
      taxabletotalTax: data?.taxabletotalTax ?? 0,
      modifiedBy: data?.modifiedBy,
      lstInvoiceDeatils:
        showListing && showListing?.length > 0
          ? showListing.map((invoiceDetail: any) => ({
            invoiceDetailId: invoiceDetail?.invoiceDetailId,
            invoiceMasterId: invoiceDetail?.invoiceMasterId,
            customerId: invoiceDetail?.customerId,
            lotId: invoiceDetail?.lotId,
            type: invoiceDetail?.type,
            price: invoiceDetail?.price,
            qty: invoiceDetail?.qty,
            buyersPrice: invoiceDetail?.buyersPrice,
            bidderName: invoiceDetail?.bidderName,
            lotNo: invoiceDetail?.lotNo,
            taxrate: invoiceDetail?.taxrate,
            exempt: invoiceDetail?.exempt,
            title: invoiceDetail?.title,
            total: invoiceDetail?.total,
            notes: invoiceDetail?.notes,
            itemContact: invoiceDetail?.itemContact,
            lotAddresstaxRate: invoiceDetail?.lotAddresstaxRate,
            extendedAmount: invoiceDetail?.extendedAmount ?? 0,
            strCity: invoiceDetail?.strCity,
            strState: invoiceDetail?.strState,
            strCountry: invoiceDetail?.strCountry,
            itemLocation: invoiceDetail?.itemLocation,
            addressLine1: invoiceDetail?.addressLine1,
            zipCode: invoiceDetail?.zipCode,
            lotTaxableItemTotal: invoiceDetail?.price * invoiceDetail?.taxrate / 100,
            lotTaxableBuyersPremium: invoiceDetail?.buyersPrice * invoiceDetail?.taxrate / 100,
            lotTaxableTotalTax: (invoiceDetail?.price + invoiceDetail?.buyersPrice) * invoiceDetail?.taxrate / 100,
          }))
          : [],
      lstInvoiceAdjust:
        showAdjustList && showAdjustList.length > 0
          ? showAdjustList.filter((item: any) => item?.isDelete == false).map((adjust: any) => ({
            invoiceAdjustId: adjust?.invoiceAdjustId ?? 0,
            invoiceMasterId: ID ? ID : 0,
            description: adjust?.description ?? "",
            amount: adjust?.amount ?? 0,
            chgCr: adjust?.chgCr ?? 0,
            taxAmount: adjust?.taxAmount !== '' ? adjust?.taxAmount : 0,
            isActive: adjust?.isActive,
            isDelete: adjust?.isDelete,
          }))
          : [],
      lstInvoicePayment:
        showPaymentList && showPaymentList.length > 0
          ? showPaymentList.filter((item: any) => item?.isDelete == false).map((payment: any) => ({
            invoicePaymentId: payment?.invoicePaymentId ?? 0,
            invoiceMasterId: ID ? ID : 0,
            date: "2024-06-13T03:54:19.398Z",
            amountPaid: payment?.amountPaid ?? 0,
            amountRecd: 0,
            payType: payment?.payType ?? 0,
            buyerPremium: 0,
            convenienceCharge: payment?.convenienceCharge ?? 0,
          }))
          : [],
    }
    onSubmit({ actionType: "submit" })
    generatePDF(pdfData, footerData, currentUTCDate, paymentTypeData, "print");
  }

  return (
    <>
      <CommonHelmet title={"Invoice - "} name={"invoice"} content={"Invoice"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <div className="">
          <InnerHeader
            breadCrumb={breadcrumbData}
            title="Invoices"
          ></InnerHeader>
        </div>
        <form onSubmit={handleSubmit(() => onSubmit({ actionType: "submit" }))}>
          <div className="container-fluid">
            <div className="row top_border">
              <div className="col-12">
                {/* If ID is not exist in URL means we are in edit mode */}
                {ID ? (
                  <></>
                ) : (
                  <>
                    {/* Create mode */}
                    <div className="row g-2">
                      <div className="d-flex flex-wrap justify-content-start align-items-center mb-1 mt-3 pt-3">
                        {/* Title */}
                        <div>
                          <label>Generate Invoice By :</label>
                        </div>
                        {/* We have 2 options bidder number and customer selection from checkbox */}
                        {InvoiceSelect.map((configType, index) => {
                          return (
                            <div
                              className="d-flex form-check form-check-inline align-items-center m-0"
                              key={index}
                            >
                              <input
                                name="chooseInvoiceType"
                                type="radio"
                                id={`inv_${index}`}
                                onChange={() =>
                                  handleRadioChange(configType?.value)
                                }
                                className="form-check-input m-2"
                                defaultChecked={
                                  selectedOption == configType?.value
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`inv_${index}`}
                              >
                                {configType.lable}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                      {/* Display Auction listing and customer listing according to selection of customer */}
                      <div className="col-xl-3 col-lg-4 col-md-6">
                        <label className="form-check-label">Auction</label>
                        {auctionList?.length > 0 ? (
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive swap-to-lot-menu clone-inventory"
                            name="auctionId"
                            options={auctionList}
                            value={
                              selectedAuction
                                ? auctionList.find(
                                  (option: any) =>
                                    option?.value === selectedAuction
                                )
                                : null
                            }
                            onChange={(e: any) => {
                              handleAuctionChange(e);
                            }}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder={"No auction exist"}
                            disabled
                          />
                        )}
                      </div>

                      {/* Customer listing according to auction id */}
                      <div className="col-xl-3 col-lg-4 col-md-6">
                        <label className="form-check-label">Buyer</label>
                        {customerList?.length > 0 ? (
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive swap-to-lot-menu clone-inventory"
                            name="customerId"
                            options={customerList}
                            value={
                              selectedCustomer
                                ? customerList.find(
                                  (option: any) =>
                                    option?.value === selectedCustomer
                                )
                                : null
                            }
                            onChange={(e: any) => {
                              handleCustomerChange(e);
                            }}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              !selectedCustomer || selectedCustomer == ""
                                ? "Select an auction first"
                                : "No customer exist"
                            }
                            disabled
                          />
                        )}
                      </div>
                      {/* Add a condition that if  we select bidder number from customer and bidder number option then below bidder number and lots dropdown will display*/}
                      {selectedOption == 2 && (
                        <>
                          {/* Bidder number listing according to customer id */}
                          <div className="col-xl-3 col-lg-4 col-md-6">
                            <label className="form-check-label">Bidder #</label>
                            {bidderList?.length > 0 ? (
                              <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select"
                                className="isActive swap-to-lot-menu clone-inventory"
                                name="bidderNumber"
                                options={bidderList}
                                value={
                                  selectedBidder
                                    ? bidderList.find(
                                      (option: any) =>
                                        option?.value === selectedBidder
                                    )
                                    : null
                                }
                                onChange={(e: any) => {
                                  handleBidderNumberChange(e);
                                }}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                placeholder={
                                  !selectedCustomer || selectedCustomer == ""
                                    ? "Select a customer first"
                                    : "No Bidder # exist"
                                }
                                disabled
                              />
                            )}
                          </div>

                          {/* Lot listing according to bidder number */}
                          <div className="col-xl-3 col-lg-8">
                            <label className="form-check-label">Lot</label>
                            {lotList?.length > 0 ? (
                              <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select"
                                isMulti
                                className="isActive swap-to-lot-menu clone-inventory"
                                name="lotNo"
                                options={lotList}
                                value={lotList.filter((option: any) =>
                                  selectedLot
                                    ?.map((i: any) => {
                                      return i?.value;
                                    })
                                    .includes(option?.value)
                                )}
                                onChange={(e: any) => {
                                  handleLotChanges(e);
                                }}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control "
                                placeholder={
                                  !selectedAuction ||
                                    selectedAuction === "" ||
                                    selectedAuction?.length === 0
                                    ? "Select a bidder # first"
                                    : "No Lot number exist"
                                }
                                disabled
                              />
                            )}
                          </div>
                        </>
                      )}
                      {/* Button to load won lots */}
                      <div className="col-xl-3 col-lg-3 col-md-6">
                        <button
                          type="button"
                          className="btn mt-4 btn-outline-primary position-relative d-flex aling-items-center"
                          onClick={handleLoadlots}
                          disabled={isButtonDisabled()}
                        >
                          Load Won Lots
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {(showDetails === true && wonLotsList?.length > 0) || ID ? (
                  <>
                    {/* UI for edit screen and after getting won lots screen  */}
                    <div className="bg-white mb-5">
                      <LotsDataListing
                        // Lots total
                        showListing={showListing}
                        //Set state for listing
                        setShowListing={setShowListing}
                        // We get wonLotsList when create
                        wonLotsList={wonLotsList}
                        //Invoice ID
                        ID={ID}
                        // On submit function
                        onSubmit={onSubmit}
                        // Invoice details from get by ID API
                        invoiceDetails={invoiceDetails}
                        // Initial data from get by invoice data
                        initailInvoiceData={initailInvoiceData}
                        // Bid exempt change
                        handleRadioChangebid={handleRadioChangebid}
                        // Bid exempt watch value
                        bidExempt={bidExempt}
                        // config
                        config={config}
                        // To check if verified or not
                        isVerified={isVerified}
                      />
                    </div>
                    {/* Adjust and payment listing table */}
                    <div className="row pb-5">
                      <div className="col-lg-6">
                        <ShowAdjustPaymentListing
                          handleEditAdjust={handleEditAdjust}
                          showPaymentList={showPaymentList}
                          showAdjustList={showAdjustList}
                          handleEditPayment={handleEditPayment}
                          handleDeleteAdjust={handleDeleteAdjust}
                          handleDeletePayment={handleDeletePayment}
                          setNetPayment={setNetPayment}
                          netPayment={netPayment}
                        />
                        {/* Notes ckeditor */}
                        <div className="col-xl-12 mb-3 mt-0 editor-height">
                          <div className="mb-1">
                            <h5>Invoice Notes</h5>
                          </div>
                          <CustomCkEditor data={notes ?? ''} onTextChanged={handleEditorChange} />
                          {remainingCount != maxWordCount && (
                            <div
                              className={
                                remainingCount <= 0 ? "text-danger" : ""
                              }
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                width: "100%",
                                fontSize: "12px",
                              }}
                            >
                              {remainingCount <= 0
                                ? "Maximum word limit reached"
                                : `Remaining words: ${remainingCount}`}
                            </div>
                          )}
                        </div>
                        {/* Checkboxes */}
                        <div className="col-12 mt-2">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-check form-check-inline mt-0 mb-0">
                                <label className="form-check-label d-flex align-items-center ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    {...register("previewReportBeforePrinting")}
                                  />
                                  <span className="ms-1 ps-1 mt-1 fs-12">
                                    Preview Report Before Printing
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-check form-check-inline mt-0 mb-0">
                                <label className="form-check-label d-flex align-items-center ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    {...register("skipCustomPrintDialog")}
                                  />
                                  <span className="ms-1 ps-1 mt-1 fs-12">
                                    Skip Custom Report Print Dialog
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-check form-check-inline mt-0 mb-0">
                                <label className="form-check-label d-flex align-items-center ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    {...register("sendEInvoiceOnClose")}
                                  />
                                  <span className="ms-1 ps-1 mt-1 fs-12">
                                    Send E-Invoice On Close
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-check form-check-inline mt-0 mb-0">
                                <label className="form-check-label d-flex align-items-center ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    {...register("dontPrintInvoiceOnClose")}
                                  />
                                  <span className="ms-1 ps-1 mt-1 fs-12">
                                    Don't print Invoice On Close
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {/* Invoice title */}
                        <div className="mb-1">
                          <h5>Invoice Total</h5>
                        </div>
                        {/* Invoice fields */}
                        <div className="border p-3 mb-3">
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Item Total
                            </label>
                            {/* Total items lot price total */}
                            <div className="col-md-3">
                              <Controller
                                {...register("itemTotal")}
                                name="itemTotal"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="itemTotal"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={itemTotal?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm fs-10 text-ellipsis">
                              (
                              {formatCurrency(
                                itemTotalTax ? itemTotalTax : "0"
                              )} - Taxable)............................................................
                            </label>
                            {/* Display taxable value from backend */}
                            <div className="col-md-3">
                              <Controller
                                {...register("taxableItemTotal")}
                                name="taxableItemTotal"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="taxableItemTotal"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={taxableItemTotal.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Buyer's Premium
                            </label>
                            <div className="col-md-3">
                              <Controller
                                {...register("buyerPremium")}
                                name="buyerPremium"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="buyerPremium"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={totalBuyerPremium?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm fs-10 text-ellipsis">
                              (
                              {formatCurrency(
                                bpTax
                                  ? bpTax
                                  : "0"
                              )} - Taxable)............................................................
                            </label>
                            <div className="col-md-3">
                              <Controller
                                {...register("taxablebuyersPremium")}
                                name="taxablebuyersPremium"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="(Taxable)"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={taxablebuyersPremium?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Sub-total
                            </label>
                            <div className="col-md-3">
                              <Controller
                                {...register("subTotal")}
                                name="subTotal"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="subTotal"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={subTotal?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                            <label className="col-md-3 text-md-end col-form-label col-form-label-sm">
                              Total Tax
                            </label>
                            <div className="col-md-3 position-relative">
                              <Controller
                                {...register("taxabletotalTax")}
                                name="taxabletotalTax"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="taxabletotalTax"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={taxabletotalTax?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                              <span className="forLine"></span>
                            </div>
                          </div>
                        </div>
                        <div className="curly_line">
                          <div className=""></div>
                        </div>
                        {/* Calculations section */}
                        <div className="border bg-light pt-3 pb-3">
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm ps-3">
                              Lots + Tax Sub-total
                            </label>
                            <div className="col-md-3">
                              <Controller
                                name="taxAmount"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="taxAmount"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={lotTaxSubTotal?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Invoice Adjustments
                            </label>
                            <div className="col-md-3">
                              {/* When there is minus value use this input  */}
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                {...register("invoiceAdjustment")}
                                disabled
                                value={invoiceAdjustment < 0 ? `($${Math.abs(invoiceAdjustment)?.toFixed(2)})` : `$${invoiceAdjustment?.toFixed(2)}`}
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <button
                                  className="ps-2 pe-2 btn btn-outline-secondary position-relative d-flex aling-items-center justify-content-center min-width-150"
                                  type="button"
                                  onClick={handleClick}
                                  disabled={initailInvoiceData?.isVoid}
                                >
                                  Add Adjustment
                                </button>
                              </div>
                              {toggleAdjModal && (
                                <AdjustModal
                                  toggleAdjModalNew={toggleAdjModal}
                                  closeModal={closeModal}
                                  ajustEditData={ajustEditData}
                                  modes={mode}
                                  invoiceMasterID={ID}
                                  setLoading={setLoading}
                                  setValue={setValue}
                                />
                              )}
                            </div>
                          </div>

                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Invoice Total
                            </label>
                            <div className="col-md-3">
                              <Controller
                                {...register("invoiceTotal")}
                                name="invoiceTotal"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="invoiceTotal"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={invoiceTotal?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Invoice Payments
                            </label>
                            <div className="col-md-3">
                              <Controller
                                {...register("invoicePayment")}
                                name="invoicePayment"
                                control={control}
                                render={({ field }) => (
                                  <CurrencyInput
                                    type="text"
                                    placeholder="invoicePayment"
                                    prefix="$"
                                    allowDecimals={true}
                                    disabled
                                    value={invoicePayment?.toFixed(2)}
                                    className="form-control form-icon-input"
                                  />
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex align-items-center">
                                <button
                                  type="button"
                                  className="ps-2 pe-2 btn btn-outline-secondary position-relative d-flex aling-items-center justify-content-center min-width-150"
                                  onClick={handlePayClick}
                                  disabled={initailInvoiceData?.isVoid}
                                >
                                  Add Payment
                                </button>
                              </div>
                              {togglePayModal && (
                                <PaymentModal
                                  togglePayModalNew={togglePayModal}
                                  closeModalPay={closeModalPay}
                                  modes={mode}
                                  paymentEditData={paymentEditData}
                                  invoiceMasterID={ID}
                                  selectedCustomer={selectedCustomer}
                                  invoiceCustomerID={
                                    initailInvoiceData?.customerId
                                  }
                                  setNetPayment={setNetPayment}
                                />
                              )}
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">
                              Deposit Paid
                            </label>
                            <div className="col-md-3">
                              <CurrencyInput
                                type="text"
                                placeholder="Deposite Paid"
                                {...register("depositePaid")}
                                prefix="$"
                                value={depositePaid}
                                onValueChange={(value: any) => setValue("depositePaid", value)}
                                className="form-control form-icon-input"
                              />
                            </div>
                          </div>
                          <div className="row g-3 align-items-center mb-2">
                            <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm fs-16">
                              Balance Due
                            </label>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                {...register("balanceDue")}
                                disabled
                                value={balanceDue < 0 ? `($${Math.abs(balanceDue).toFixed(2)})` : `$${balanceDue.toFixed(2)}`}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Submit button */}
            <div className="container-fluid for-fix-bottom-space mt-2">
              <div
                className="d-flex align-items-center justify-content-end border-top bg-white gap-3 p-3 fix-bottom"
                data-wizard-footer="data-wizard-footer"
              >
                {/* Cancel and redirect to list */}
                <Link
                  className="btn btn-outline-danger btn-sm me-auto"
                  type="button"
                  to={routeConstant.INVOICE}
                >
                  Close
                </Link>
                {/* Print and close invoice page */}
                {ID ?
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={handlePrint}
                    disabled={initailInvoiceData?.isVoid === true}
                  >
                    Print Report
                  </button>
                  : (
                    <></>
                  )}
                {/* Update or create invoice */}
                <button
                  disabled={initailInvoiceData?.isVoid === true}
                  className="btn btn-primary btn-sm"
                  type="submit"
                  data-wizard-next-btn="data-wizard-next-btn"
                >
                  {ID
                    ? 'Update Invoice'
                    : 'Create Invoice'}
                </button>
                {/* Publish Invoice */}
                <button
                  className="btn btn-outline-primary btn-sm"
                  type="button"
                  disabled={initailInvoiceData?.isVoid === true}
                  data-wizard-prev-btn="data-wizard-prev-btn"
                  onClick={(e: any) => {
                    handleConfirm(getValues());
                  }}
                >
                  Publish Invoice
                </button>
                {/* Void Invoice */}
                {ID ? (
                  <button
                    className="btn btn-outline-danger btn-sm"
                    type="button"
                    data-wizard-prev-btn="data-wizard-prev-btn"
                    onClick={handleVoidConfirmation}
                    disabled={initailInvoiceData?.isVoid === true}
                  >
                    Void Invoice
                  </button>
                ) : (
                  <></>
                )}

              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateInvoice;
