import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface CustomerState {
    contactInfo: object
    addressInfo: object[]
    profileInfo: object
    interestAreas: object[]
    bidderDocument: object[]
    addBidderStatus: boolean
    loader: boolean,
    activeTab: {}
    customerId: number
    prevCustomerId: number
    nextCustomerId: number
    totalCustomers: number
    customerIndex: number
}

const initialState: CustomerState = {
    contactInfo: {},
    addressInfo: [],
    interestAreas: [],
    profileInfo:{},
    bidderDocument: [],
    addBidderStatus: false,
    activeTab: 'contact',
    loader: false,
    customerId: 0,
    prevCustomerId: 0,
    nextCustomerId: 0,
    totalCustomers: 0,
    customerIndex: 0,


};

export const CustomerReducer = (
    state: CustomerState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.CUSTOMERCONTACTINFO: {
            return { ...state, contactInfo: action.payload }
        }
        case actions.CUSTOMERADDRESSINFO: {
            return { ...state, addressInfo: action.payload }
        }
        case actions.CUSTOMERPROFILEINFO: {
            return { ...state, profileInfo: action.payload }
        }
        case actions.CUSTOMERINTEREST: {
            return { ...state, interestAreas: action.payload }
        }
        case actions.ADDBIDDERSTATUS: {
            return { ...state, addBidderStatus: action.payload }
        }
        case actions.CUSTOMERLOADER: {
            return { ...state, loader: action.payload }
        }
        case actions.RESETCUSTOMER: {
            return initialState
        }
        case actions.SETACTIVETABCUSTOMER:
            return {
                ...state,
                activeTab:  action.payload 
            };
        case actions.SETBIDDERDOCUMENT: {
            return { ...state, bidderDocument: action.payload }
        }
        case actions.SETCUSTOMERID: {
            return { ...state, customerId: action.payload }
        }
        case actions.SETPREVCUSTOMERID: {
            return { ...state, prevCustomerId: action.payload }
        }
        case actions.SETNEXTCUSTOMERID: {
            return { ...state, nextCustomerId: action.payload }
        }
        case actions.TOTALCUSTOMERS: {
            return { ...state, totalCustomers: action.payload }
        }
        case actions.CUSTOMERINDEX: {
            return { ...state, customerIndex: action.payload }
        }

        default:
            return state;
    }
};