export const SETSALESREPACTIVETAB = "SETSALESREPACTIVETAB"
export const SALESREPLOADER = "SALESREPLOADER"
export const SETSALEREPID = "SETSALEREPID"
export const SALESREPCONTACT = "SALESREPCONTACT"
export const SALESREPAUCTION = "SALESREPAUCTION"
export const SALESREPCOMM = "SALESREPCOMM"
export const SETADJUST = "SETADJUST"
export const SETPAYMENT = "SETPAYMENT"
export const SETADJUSTFROMRESP = "SETADJUSTFROMRESP"
export const SETPAYMENTRESP = "SETPAYMENTRESP"
export const RESETRESP = "RESETRESP"
export const SETCOMMISSIONOPTIONS = "SETCOMMISSIONOPTIONS"

export const LOADER = "LOADER"
export const SELLERDEFAULTS = "SELLERDEFAULTS"
export const SALESDEFAULTS = "SALESDEFAULTS"
export const SETSELLER = "SETSELLER"
export const SETSALESREP = "SETSALESREP"
export const SETSELLERCOMMSETTINGID = "SETSELLERCOMMSETTINGID"
export const SETSALESCOMMSETTINGID = "SETSALESCOMMSETTINGID"
export const SETAUCTIONINFOACTIVETAB_SALESREP = "SETAUCTIONINFOACTIVETAB_SALESREP"
export const SELECTAUCTION_SALESREP = "SELECTAUCTION_SALESREP"
export const SALESREPAUCTIONS = "SALESREPAUCTIONS"
export const SALESREPLOTS = "SALESREPLOTS"


export const SELLERSAVED = "SELLERSAVED"
export const SALESRSAVED = "SALESRSAVED"
export const SETSELLERLOTS = "SETSELLERLOTS"
export const SETSALESLOTS = "SETSALESLOTS"
export const RESETINTIAL = "RESETINTIAL"

export const UPDATESELLER = "UPDATESELLER"
export const SETSELLERADJUST = "SETSELLERADJUST"
export const SETSELLERADJUSTFROMRESP = "SETSELLERADJUSTFROMRESP"
export const DELETESELLERADJUST = 'DELETESELLERADJUST'
export const UPDATESELLERADJUST = 'UPDATESELLERADJUST'
export const SETSELLERPAYMENT = "SETSELLERPAYMENT"
export const SETSELLERPAYMENTRESP = "SETSELLERPAYMENTRESP"
export const SETSELLERRADIOVALUE = "SETSELLERRADIOVALUE"
export const DELETESELLERPAYMENT = 'DELETESELLERPAYMENT'
export const UPDATESELLERPAYMENT = 'UPDATESELLERPAYMENT'
export const RESETSELLER = "RESETSELLER"
export const SETACTIVETAB = "SETACTIVETAB"
export const SELLERCONTACT = "SELLERCONTACT"
export const SELLERLOTS = "SELLERLOTS"
export const SELLERAUCTIONS = "SELLERAUCTIONS"
export const SETAUCTIONINFOACTIVETAB = "SETAUCTIONINFOACTIVETAB"
export const SELECTAUCTION = "SELECTAUCTION"
export const SETCONTRACTDOCUMENT = "SETCONTRACTDOCUMENT"
export const SETAUCTIONCONTRACTDOCUMENT = "SETAUCTIONCONTRACTDOCUMENT"
export const SETSELLERID = "SETSELLERID"
export const TOTALSELLERS = "TOTALSELLERS"
export const SETNEXTSELLERID = "SETSELLERID"
export const SETPREVSELLERID = "SETPREVSELLERID"
export const SELLERINDEX = "SELLERINDEX"


export const ADDLOGNOTE = 'ADDLOGNOTE'
export const UPDATELOGNOTE = 'UPDATELOGNOTE'
export const DELETELOGNOTE = 'DELETELOGNOTE'

export const CUSTOMERCONTACTINFO = "CUSTOMERCONTACTINFO"
export const CUSTOMERPROFILEINFO = "CUSTOMERPROFILEINFO"
export const CUSTOMERINTEREST = "CUSTOMERINTEREST"
export const ADDBIDDERSTATUS = "ADDBIDDERSTATUS"
export const CUSTOMERLOADER = "CUSTOMERLOADER"
export const RESETCUSTOMER = "RESETCUSTOMER"
export const SETBIDDERDOCUMENT = "SETBIDDERDOCUMENT"
export const CUSTOMERADDRESSINFO = "CUSTOMERADDRESSINFO"
export const SETACTIVETABCUSTOMER = "SETACTIVETAB"
export const SETCUSTOMERID = "SETCUSTOMERID"
export const SETPREVCUSTOMERID = "SETPREVCUSTOMERID"
export const SETNEXTCUSTOMERID = "SETNEXTCUSTOMERID"
export const TOTALCUSTOMERS = "TOTALCUSTOMERS"
export const CUSTOMERINDEX = "CUSTOMERINDEX"

export const SETMODE = "SETMODE"
export const SETCLONEAUCTIONID = "SETCLONEAUCTIONID"
export const SETAUCTIONCODEANDTITLE = "SETAUCTIONCODEANDTITLE"
export const SETCLONETABS = "SETCLONETABS"
export const SETCREATETABS = "SETCREATETABS"
export const AUCTIONTYPE = "AUCTIONTYPE"
export const ACTIVESTEP = "ACTIVESTEP"
export const AUCTIONNFOACTIVE = "AUCTIONNFOACTIVE"
export const AUCTIONID = "AUCTIONID"
export const AUCTIONDETAILS = "AUCTIONDETAILS"
export const AUCTIONINFO = "AUCTIONINFO"
export const AUCTIONINVOICE = "AUCTIONINVOICE"
export const AUCTIONINVOICESETTING = "AUCTIONINVOICESETTING"
export const AUCTIONEMAILREPORTS = "AUCTIONEMAILREPORTS"
export const INVOICETEMPLATE = "INVOICETEMPLATE"
export const SELLERTEMPLATE = "SELLERTEMPLATE"
export const AUCTIONVALUE = "AUCTIONVALUE"
export const EINVOICEDATA = "EINVOICEDATA"
export const RESETAUCTION = "RESETAUCTION"
export const ADDBIDDERRANGE = "ADDBIDDERRANGE"
export const DELETEBIDDERRANGE = "DELETEBIDDERRANGE"
export const CHECKLIST = "CHECKLIST"
export const ADDADJBIDS = "ADDADJBIDS"
export const DELETEADJBIDS = "DELETEADJBIDS"
export const UPDATEADJBIDS = "UPDATEADJBIDS"
export const SETADJBIDSFROMRESP = "SETADJBIDSFROMRESP"
export const SHIPPINGDATA = "SHIPPINGDATA"
export const UPDATESHIPPINGDATA = "UPDATESHIPPINGDATA"
export const AUCTIONINVOICEDATA = "AUCTIONINVOICEDATA"
export const SETDATERANGE = "SETDATERANGE"
export const RESETDATERANGE = "RESETDATERANGE"

export const SETLOTCTRLREC = 'SETLOTCTRLREC'
export const RESETINV = 'RESETINV'
export const DELETINVENTORYNOTE = 'DELETINVENTORYNOTE'
export const LOGPAGEINVENTOY = 'LOGPAGEINVENTOY'
export const ADDRESSINVENTORY = 'ADDRESSINVENTORY'
export const DELETEINVENTORY = 'DELETEINVENTORY'
export const SELLERREPORT = "SELLERREPORT"
export const VALUEDATA = "VALUEDATA"

export const INVENTORYACTIVE = "INVENTORYACTIVE"

export const ADDINVOICE = 'ADDINVOICE'
export const SETINVOICERADIOVALUE = 'SETSELLERRADIOVALUE'
export const SETINVOICES = 'SETINVOICES'
export const SETINVOICESPAYMENT = 'SETINVOICESPAYMENT'
export const SETINVOICESUPDATE = 'SETINVOICESUPDATE'
export const SETINVOICEPAYMENTUPDATE = 'SETINVOICEPAYMENTUPDATE'
export const RESETINVOICE = 'RESETINVOICE'

export const LOADING = "LOADING"

export const SETSEARCHDATA = "SETSEARCHDATA"
export const RESETSEARCHDATA = "RESETSEARCHDATA"

export const LOTADDLOGNOTE = 'LOTADDLOGNOTE'

export const LOTUPDATELOGNOTE = 'LOTUPDATELOGNOTE'

export const LOTDELETELOGNOTE = 'LOTDELETELOGNOTE'

export const COUNTRY = "COUNTRY"
export const STATE = "STATE"
export const CITY = "CITY"
export const GLOBALCATEGORIES = "GLOBALCATEGORIES"
export const NOTIFICATIONS = "NOTIFICATIONS"
export const NOTIFICATIONCOUNT = "NOTIFICATIONCOUNT"
export const SIDEBAROPEN = "SIDEBAROPEN"
export const ADDRESSSTYPES = "ADDRESSSTYPES"
export const REDIRECTEDUSER = "REDIRECTEDUSER"
export const LOGGEDUSERDATA = "LOGGEDUSERDATA"
export const AUCTIONHAMMERDATA = "AUCTIONHAMMERDATA"

export const SETTHEMECOLOR = "SETTHEMECOLOR"
export const SETCOLORMODE = "SETCOLORMODE"

export const SETLOTID = "SETLOTID"
export const SETPREVLOTID = "SETPREVLOTID"
export const SETNEXTLOTID = "SETNEXTLOTID"
export const TOTALLOTS = "TOTALLOTS"
export const INDEX = "INDEX"
export const SETCLONELOTID = "SETCLONELOTID"
export const SETLOTCLONETABS = "SETLOTCLONETABS"
export const SETLOTCREATETABS = "SETLOTCREATETABS"
export const SETAUCTIONDATA = "SETAUCTIONDATA"
export const SETSELLERIDFORCOMM = "SETSELLERIDFORCOMM"
