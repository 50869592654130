import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface CommonState {
    loading: boolean
    notifications: object[]
    notificationCount: number
    sidebarOpen: boolean
    addressTypes: object[]
    redirectedToHeader: boolean
    loggedUserData: object
    auctionHammerData:object
}

const initialState: CommonState = {
    loading: false,
    notifications: [],
    notificationCount: 0,
    sidebarOpen: true,
    addressTypes: [],
    redirectedToHeader: false,
    loggedUserData: {},
    auctionHammerData:{}
};

export const CommonReducer = (
    state: CommonState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.LOADING: {
            return { ...state, loading: action.payload }
        }
        case actions.COUNTRY: {
            return { ...state, country: action.payload }
        }
        case actions.STATE: {
            return { ...state, state: action.payload }
        }
        case actions.CITY: {
            return { ...state, city: action.payload }
        }
        case actions.GLOBALCATEGORIES: {
            return { ...state, globalCategories: action.payload }
        }
        case actions.NOTIFICATIONS: {
            return { ...state, notifications: action.payload }
        }
        case actions.NOTIFICATIONCOUNT: {
            return { ...state, notificationCount: action.payload }
        }
        case actions.SIDEBAROPEN: {
            return { ...state, sidebarOpen: action.payload }
        }
        case actions.ADDRESSSTYPES: {
            return { ...state, addressTypes: action.payload }
        }
        case actions.REDIRECTEDUSER: {
            return { ...state, redirectedToHeader: action.payload }
        }
        case actions.LOGGEDUSERDATA: {
            return { ...state, loggedUserData: action.payload }
        }
        case actions.AUCTIONHAMMERDATA:{
            return {...state, auctionHammerData :action.payload}
        }
        default:
            return state;
    }
};
