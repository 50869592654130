import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATETAXABLESTATE, GETCOMMONVALUE, SAVESALESCOMMISSION, SAVESELLERCOMMISSION, UPDATETAXABLESTATE } from "../../common/apiConstatnts";
import CommonHelmet from "../../common/HelmetWrapper";
import { Breadcrumb } from "../../common/interface";
import { commissionMessages, CommonMessages, errorMessages } from "../../common/messageConstants";
import * as routeConstant from '../../common/routeConstants';
import * as actions from '../../common/types';
import { salesSaved, sellerSaved, setSalesLots, setSellerLots, showLoader } from "../../store/actions/CommissionAction";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { trimObjectValues } from "../../Utils/Utils";
import InnerHeaderTab from "../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../Sidebar/Sidebar";
import SalesRepCommission from "./SalesRepCommission";
import SellerCommissionScale from "./SellerCommissionScale";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Commission', url: '' }
]

const SellerCommission = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<string>()
    const [defaultValues, setDefaultValues] = useState<any>({})
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [allStates, setAllStates] = useState<any>([])
    const [newChecks, setNewChecks] = useState<any[]>([])
    const [selectedStates, setSelectedStates] = useState<any>({})
    const [mode, setMode] = useState<string>('')
    const loader = useSelector((state: any) => state.commission.showLoader)
    const sellerData = useSelector((state: any) => state.commission.sellerCommission)
    const salesData = useSelector((state: any) => state.commission.salesRepCommission)
    const sellerDefaults = useSelector((state: any) => state.commission.sellerDefaults)
    const saleDefaults = useSelector((state: any) => state.commission.salesDefaults)
    const sellerSettingId = useSelector((state: any) => state.commission.sellerCommissionScaleSettingId)
    const salesSettingId = useSelector((state: any) => state?.commission?.salesRepCommissionScaleSettingId)

    const {
        handleSubmit
    } = useForm({ mode: 'onChange' })


    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const handleChange = (key: string) => {
        setActiveTab(key)
    }

    const handleClose = () => {
        setToggleValue(!toggleModal)
    }

    const handleSelectDeselectAll = (type: string) => {
        const updateNewChecks = (type === 'all') ? allStates.map((data: any) => { return data.objectValueId.toString() }) : []
        setNewChecks(updateNewChecks)
        const updateStates = allStates.map((state: any) => {
            return { ...state, isChecked: (type === 'all') ? true : false }
        })
        setAllStates(updateStates)
    }

    const onSubmit = () => {
        setLoading(true);
        const url = (mode === 'create') ? CREATETAXABLESTATE : UPDATETAXABLESTATE;
        const message = (mode === 'create') ? commissionMessages.taxableCreated : commissionMessages.taxableUpdated;
        const filterSelected = allStates.filter((data: any) => data.isChecked === true);
        let getIds = filterSelected.map((data: any) => { return data.objectValueId })
        getIds = getIds.join(',');
        const payload = { stateTaxCodeId: getIds, isActive: true, companyId: localStorage.getItem('companyId') }
        axios.post(url, payload, config).then((response) => {
            setLoading(false);
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            setLoading(false)
            setToggleValue(!toggleModal)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const getStates = () => {
        setLoading(true)
        axios.get(`${GETCOMMONVALUE}?filter=State Code&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                // return;
            }
            else {
                const data = response.data.data;
                let getSelectedState: any
                if (newChecks?.length === 0) {
                    getSelectedState = selectedStates.stateTaxCodeId?.split(',')
                    setNewChecks(getSelectedState)
                } else {
                    getSelectedState = newChecks
                }
                setAllStates(data)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    // const getTaxableStateData = () => {
    //     setLoading(true)
    //     axios.get(GETALLSELLERCOMMISSIONTAXABLESTATE, config).then((response) => {
    //         setLoading(false);
    //         if (response?.data?.success === "Fail") {
    //             // toast.error(response.data.message, {
    //             //     position: toast.POSITION.TOP_RIGHT,
    //             //     autoClose: 2000,
    //             // });
    //             // return;
    //         }
    //         else {
    //             const data = response.data.data;
    //             const mode = (data.sellerCommissionTaxableStateId === 0) ? 'create' : 'update'
    //             if (response?.data?.success === "Fail") {
    //                 // toast.error(response?.data?.message, {
    //                 //   position: toast.POSITION.TOP_RIGHT,
    //                 //   autoClose: 2000,
    //                 // });
    //             }
    //             setMode(mode);
    //             setSelectedStates(data);
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         handleError(error);
    //       });
    // }

    const handleChangeState = (e: any, stateId: number) => {
        if (e.target.checked) {
            const newData = [newChecks, stateId.toString()]
            setNewChecks(newData)
        } else if (newChecks?.includes(stateId.toString())) {
            const newData = newChecks.filter((data: any) => data !== stateId.toString())
            setNewChecks(newData)
        }
        const updateState = allStates.map((state: any) => {
            if (state.objectValueId === stateId) {
                return { ...state, isChecked: e.target.checked }
            }
            return state
        })
        setAllStates(updateState);
    }

    const handleSave = () => {
        setLoading(true)
        const data = (activeTab === 'seller') ? sellerData : salesData;
        const defaultData = (activeTab === 'seller') ? sellerDefaults : saleDefaults;
        // const checkValidScale =(activeTab === 'seller') ? data.length > 0
        const checkValid = (activeTab === 'seller') ?
            data.filter((i: any) => i.mode != 'delete').every((cData: any) => cData.lstSellerCommissionScaleRange?.filter((i: any) => i.mode != 'delete').length > 0)
            :
            data.filter((i: any) => i.mode != 'delete').every((cData: any) => cData.lstSalesRepCommissionScaleRange?.filter((i: any) => i.mode != 'delete').length > 0);
        if (data.length == 0) {
            setLoading(false);
            toast.error(errorMessages.addOneScle, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return;
        }
        if (!checkValid) {
            setLoading(false);
            toast.error(errorMessages.addOneRange, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return;
        }
        const payloadSeller = {
            sellerCommissionScaleSettingId: sellerSettingId ? sellerSettingId : 0,
            companyId: localStorage.getItem('companyId'),
            strSellerCommissionSold: defaultData?.sold,
            strSellerCommissionUnSold: defaultData.passedUnsold,
            strSellerCommissionBuyBack: defaultData.buyBack,
            strSellerCommissionNonPayer: defaultData.nonPayer,
            sellerCommissionPassedLot: defaultData.passedLot ? defaultData.passedLot : '0',
            sellerCommissionUnPassedLot: defaultData.unPassedLot ? defaultData.unPassedLot : '0',
            isActive: true,
            lstSellerCommissionScale: data?.filter((i: any) => i.sellerCommissionScaleId != 0 || i.mode != 'delete')
        }

        const payloadSalesRep = {
            salesRepCommissionScaleSettingId: parseInt(salesSettingId || 0),
            companyId: localStorage.getItem('companyId'),
            strSalesRepCommissionSold: defaultData.sold,
            strSalesRepCommissionUnSold: defaultData.passedUnsold,
            strSalesRepCommissionBuyBack: defaultData.buyBack,
            strSalesRepCommissionNonPayer: defaultData.nonPayer,
            salesRepCommissionPassedLot: defaultData.passedLot ? defaultData.passedLot : '0',
            salesRepCommissionUnPassedLot: defaultData.unPassedLot ? defaultData.unPassedLot : '0',
            isActive: true,
            lstSalesRepCommissionScale: data?.filter((i: any) => i.salesRepCommissionScaleId != 0 || i.mode != 'delete')
        }

        const Url = (activeTab === 'seller') ? SAVESELLERCOMMISSION : SAVESALESCOMMISSION;
        const Payload = (activeTab === 'seller') ? payloadSeller : payloadSalesRep;
        const Message = (activeTab === 'seller') ? commissionMessages.SellerCommissionsCreated : commissionMessages.SalesRepCommissionsCreated;

        const Dispatch = (activeTab === 'seller') ? sellerSaved : salesSaved;

        const trimmedData = trimObjectValues(Payload);

        axios.post(Url, trimmedData, config).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.success(Message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
            dispatch(Dispatch(true));
        }).catch((error) => {
            dispatch(showLoader(false));
            setLoading(false);
            handleError(error);
        });
    }

    const handleLotsData = (e: any) => {
        const data = { [e.target.name]: e.target.value ? `${Number(e.target.value.slice(0, 8))}` : '0' };
        if (activeTab === 'seller') {
            dispatch(setSellerLots(data));
        } else {
            dispatch(setSalesLots(data));
        }
    }

    useEffect(() => {
        const getSelectedState = selectedStates?.stateTaxCodeId?.split(',');
        const modifyData = allStates?.map((sData: any) => {
            if (getSelectedState?.includes(sData.objectValueId.toString())) {
                return { ...sData, isChecked: true }
            }
            return { ...sData, isChecked: false }
        })
        setAllStates(modifyData);
    }, [selectedStates])

    useEffect(() => {
        setActiveTab('seller');
        getStates();
        return () => {
            dispatch({ type: actions.RESETINTIAL });
        }
    }, [])

    // useEffect(() => {
    //     setLoading(loader);
    // }, [loader])

    useEffect(() => {
        if (activeTab === 'seller') {
            setDefaultValues(sellerDefaults);
            return
        }
        setDefaultValues(saleDefaults);
    }, [activeTab, sellerDefaults, saleDefaults])

    return (
        <>
            {/* select states modal */}
            <CommonHelmet title={"Commission - "} name={"commission"} content={"Commission"} />
            <Modal show={toggleModal} size='lg' centered>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Taxable States</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12 mt-0">
                                <div className="col-auto mt-2 mb-2">
                                    <div className="buttons ms-3">
                                        <button type="button" className="btn btn-outline-secondary me-2" onClick={() => handleSelectDeselectAll('all')}>Select All</button>
                                        <button type="button" className="btn btn-outline-secondary" onClick={() => handleSelectDeselectAll('clear')}>Clear</button>
                                    </div>
                                </div>
                                <div className="box_checkbox d-flex flex-wrap">
                                    {allStates.length > 0 ?
                                        allStates.map((stateData: any, index: any) => {
                                            return (
                                                <div className="form-check me-3 mb-3" key={index}>
                                                    <input
                                                        className="form-check-input form-check-states"
                                                        type="checkbox"
                                                        name="states"
                                                        id={stateData.objectValueId}
                                                        checked={stateData.isChecked === true}
                                                        onChange={(e) => handleChangeState(e, stateData.objectValueId)}
                                                    />
                                                    <label className="form-check-label text-900 fw-400" htmlFor={stateData.objectValueId}>{stateData.objectTypeValue}</label>
                                                </div>
                                            )
                                        })
                                        :
                                        (
                                            <div className="col-12">
                                                <p className="text-center mt-3 mb-3">No state found</p>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal"
                            onClick={handleClose}
                        >{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit">{(mode === 'create') ? (CommonMessages.createButton) : (CommonMessages.updateButton)}</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderTab breadCrumb={breadcrumbData} title='Commission'>
                    {/* {(activeTab === 'seller') && <div className="d-flex flex-wrap justify-content-end custom_top_right_btn">
                        <button className="btn btn-primary"
                            onClick={() => {
                                setToggleValue(!toggleModal)
                                getTaxableStateData()
                            }}
                        >Taxable States</button>
                    </div>} */}
                    <Tabs
                        defaultActiveKey="seller"
                        className="nav-underline "
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="seller" title="Seller Commission"></Tab>
                        {/* <Tab eventKey="sales" title="Sales Rep Commission"></Tab> */}
                    </Tabs>
                </InnerHeaderTab >
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row top_border">
                        <div className="col-lg-12">
                            <div className="tab-content mt-3" id="myTabContent">
                                <div className="tab-pane fade active show" id="tab-Seller_Commission_Scale" role="tabpanel" aria-labelledby="Seller_Commission_Scale-tab">
                                    <div className="border-300">
                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-xxl-4">
                                                    <div className="row">
                                                        <div className="col-lg-6 mb-3">
                                                            <label className="fs--1 fw-bold text-primary">Sold</label>
                                                            <div className="form-control fw-bold min-height-38 opacity-40">
                                                                {defaultValues.sold ? defaultValues.sold : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-3">
                                                            <label className="fs--1 fw-bold text-primary">Passed/Unsold</label>
                                                            <div className="form-control fw-bold min-height-38 opacity-40">
                                                                {defaultValues.passedUnsold ? defaultValues.passedUnsold : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-3">
                                                            <label className="fs--1 fw-bold text-primary">Buyback</label>
                                                            <div className="form-control fw-bold min-height-38 opacity-40">
                                                                {defaultValues.buyBack ? defaultValues.buyBack : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 mb-3">
                                                            <label className="fs--1 fw-bold text-primary">Non-Payer</label>
                                                            <div className="form-control fw-bold min-height-38 opacity-40">
                                                                {defaultValues.nonPayer ? defaultValues.nonPayer : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-8">
                                                    {/* <div className="row mb-3">
                                                        <div className="col-lg-6">
                                                            <div className="mb-2">
                                                                <label className="form-label text-900" htmlFor="bootstrap-wizard-validation-wizard-name">Fixed Commission Passed Lots</label>
                                                                <input
                                                                    placeholder="Fixed Commission Passed Lots"
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="passedLot"
                                                                    id="bootstrap-wizard-validation-wizard-name"
                                                                    value={defaultValues.passedLot ? Math.max(0, defaultValues.passedLot) : 0}

                                                                    onChange={(e) => handleLotsData(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-2">
                                                                <label className="form-label text-900" htmlFor="bootstrap-wizard-validation-wizard-name">Fixed Commission Unsold Lots</label>
                                                                <input
                                                                    placeholder="Fixed Commission Unsold Lots"
                                                                    className="form-control"
                                                                    type="number"
                                                                    name="unPassedLot"
                                                                    id="bootstrap-wizard-validation-wizard-name"
                                                                    value={defaultValues.unPassedLot ? Math.max(0, defaultValues.unPassedLot) : 0}
                                                                    onChange={(e) => handleLotsData(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <h5 className="mb-3">Scale Entry Notes:</h5>
                                                    <p className="mb-0 fs--1">1) Do not enter a value for the highest level to amount.</p>
                                                    <p className="mb-0 fs--1">2) The From value should be exactly the same as the previous To value, e.g. 0 to 100, 100 to 500, 500 to 1000. Do not enter 99.99 or 99 or similar; this is handled internally.</p>
                                                </div>
                                            </div>
                                            <hr />
                                            {(activeTab === 'seller') ? <SellerCommissionScale /> : <SalesRepCommission />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-4">
                        <div className="col-lg-12">
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                <Link to={routeConstant.DASHBOARD} className="btn btn-outline-danger me-auto" type="button" data-wizard-prev-btn="data-wizard-prev-btn"  >
                                    {CommonMessages.cancelButton}
                                </Link>
                                <button className="btn btn-primary" type="button" onClick={handleSave}>{CommonMessages.saveButton}</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default SellerCommission;