import React from "react"
import { toast } from "react-toastify";
import { errorMessages } from "./messageConstants";

class Helper {
    static decodePermissions() {
        const decodePerm: any = localStorage.getItem('permission')
        return JSON.parse(decodePerm)
    }

    static unAuthorized() {
        toast.error(errorMessages.notAuthorize, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        })
    }

    static checkAdminPermission(type: any) {
        const permission = this.decodePermissions().find((data: any) => data.moduleName === 'Admin Setting')
        if(!permission[type]) {
            this.unAuthorized()
            return false
        }
        return true
    }
}

export default Helper