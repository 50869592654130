import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeader from '../InnerHeader/InnerHeader/InnerHeader';
import Sidebar from '../Sidebar/Sidebar';
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from 'axios';
import { GETALLROLES, CREATEUSERROLE, GETMODULELIST, GETROLEBYID, DELETEROLE, UPDATEROLE, GETUSERWISEROLE, SAVEUSERWISEROLE, ADVANCEFILTERROLE } from '../../common/apiConstatnts';
import { Dropdown, Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Select from 'react-select';
import { activationStatus } from "../../common/constants";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import Helper from "../../common/helpers";
import * as types from "../../common/permissionTypes";
// import loader from "../../assets/loader.gif";
import * as apiConstants from "../../common/apiConstatnts";
import { wordCounter } from "../../common/constants";
import { AlPHANUMERIC } from "../../common/regexConstants";
import moment from "moment";
import { CommonMessages, RoleMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, trimObjectValues, useDebounce } from "../../Utils/Utils";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";


const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Roles', url: routeConsant.ROLE }
]

const Role = () => {
    const initialData: any = {
        companyId: localStorage.getItem('companyId'),
        roleName: '',
        description: '',
        isActive: true,
        roleId: 0
    }
    const [loading, setLoading] = useState(false)
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [modules, setModules] = useState<any>([])
    const [initialModules, setInitialModules] = useState<any>([])
    const [initalRoleModule, setInitialRoleModule] = useState<any>([])
    const [formValues, setFormValues] = useState<any>(initialData)
    const [roleModuleMap, setRoleModuleMap] = useState<any>([])
    const [userRoles, setUsersRoles] = useState<[]>([])
    const [originalUserRoles, setOriginalUsersRoles] = useState<[]>([])
    const [allUsers, setAllUsers] = useState<[]>([])
    const [roleUserPayload, setRoleUserPayload] = useState<[]>([])
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleUserModal, setToggleUserModal] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [currentRole, setCurrentRole] = useState<any>({})
    const [filterRoles, setFilterRoles] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [roleOption, setRoleOption] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [modifiedBy, setModifiedBy] = useState('')
    const [searchText, setSearchText] = useState('')
    const [totalRecords, setTotalRecords] = useState(0)
    const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<any>({});
    const [paginationSize, setPaginationSize] = useState<number>(10)
    const [isFiltered, setIsFiltered] = useState<boolean>(false);

    const PER_PAGE = 10;
    const pageCount = Math.ceil(userRoles.length / PER_PAGE);
    const [filterData, setFilterData] = useState<any>({ role: "", isActive: false });
    const maxWordCount = 250;
    const [remainingCount, setRemainingCount] = useState(maxWordCount);


    const momentUtil = MomentUtil.getInstance();
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();

    const schema: any = yup.object().shape({
        roleName: yup.string()
            .required("Role name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid Role Name')
            .test('len', 'Please enter upto 100 Characters', val => val.length < 100),
        description: yup.string()
            .notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
    });

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    // let filterRoleData = roleOption?.map((roleData: any) => {
    //     return {
    //         label: roleData.roleName, value: roleData.userRoleId
    //     }
    // })
    const filterRoleData: any = [
        { label: 'Select Role', value: '' },
        ...Array.from(new Set(roleOption.map((roleData: any) => roleData.userRoleId)))
            .map((userRoleId: any) => {
                const role: any = roleOption.find((roleData: any) => roleData.userRoleId === userRoleId);
                return {
                    label: `${role.roleName}`,
                    value: role.userRoleId,
                };
            }),
    ];
    let filterActivationStatus = activationStatus?.map((data: any) => {
        return {
            label: data.label, value: data.value
        }
    })

    // const paginateData = (data: any) => {
    //     setUsersRoles(data)
    //     const firstSet = data?.slice(0, PER_PAGE);
    //     setFilterRoles(firstSet)
    // }

    const getAllUsersRoles = (pageSize: any = 10, pageNum: any = 1) => {
        setLoading(true)
        axios.get(`${GETALLROLES}?pageNumber=${pageNum}`, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterRoles([]);
                return;
            }
            else {
                const rolesData = response?.data?.data?.data;
                setRoleOption(rolesData);
                // paginateData(rolesData);
                // setOriginalUsersRoles(rolesData);
                setTotalRecords(response?.data?.data?.totalRecords)
                paginateData(rolesData, pageNum);

            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    useEffect(() => {
        axios.get(GETMODULELIST, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            else {
                const moduleData = response.data.data;
                const modifyRoleMap = moduleData?.map((roleMap: any, index: any) => {
                    return {
                        moduleId: roleMap.moduleId,
                        companyId: roleMap.companyId,
                        isAllowAdd: false,
                        isAllowEdit: false,
                        isAllowDelete: false,
                        isAllowPrint: false,
                        isAllowExport: false,
                    }
                })
                setInitialRoleModule(modifyRoleMap)
                setRoleModuleMap(modifyRoleMap)
                setInitialModules(moduleData)
                setModules(moduleData)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = userRoles.slice(offset, offset + PER_PAGE)
        setFilterRoles(nextSet)
    }, [currentPage])

    const handleAllCheck = (event: any, privilegeType: string, className: string) => {
        if (mode === 'edit') {
            setIsChanged(true)
        }
        let elem: any = document.getElementsByClassName(className)
        for (let i = 0; i < elem.length; i++) {
            elem[i].checked = event.target.checked
        }
        const data = roleModuleMap?.map((module: any) => {
            return { ...module, [privilegeType]: event.target.checked }
        })
        setRoleModuleMap(data)
    }

    const handleModules = (event: any, moduleId: number, privilegeType: string, className?: string, selectAllId?: string) => {
        const totalCheckbox = modules.length
        const checkedCheckBoxes = document.querySelectorAll(`.${className}:checked`)
        const elem: any = document.getElementById(`${selectAllId}`) as HTMLInputElement

        if (totalCheckbox === checkedCheckBoxes.length) {
            elem.checked = true
        } else {
            elem.checked = false
        }

        if (mode === 'edit') {
            setIsChanged(true)
        }

        const data = roleModuleMap?.map((module: any) => {
            if (module.moduleId === moduleId) {
                return { ...module, [privilegeType]: event.target.checked }
            }
            return module
        })
        setRoleModuleMap(data)
    }

    const testPermissionChecked = () => {
        const data = roleModuleMap?.map((i: any) => {
            return Object.values(i).includes(true)
        })
        return data.includes(true)
    }

    const onSubmit = () => {
        if (testPermissionChecked() === false) {
            toast.error(errorMessages.selectPermission, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            return
        }
        setLoading(true)
        const dataOriginal = getValues();
        let data = trimObjectValues(dataOriginal);
        if (mode === 'create') {
            const payload = { ...data, lstRoleModuleMapping: roleModuleMap }
            axios.post(CREATEUSERROLE, payload, config).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
                else {
                    toast.success(`${RoleMessages.roleCreated}`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setLoading(false)
                    setToggleValue(!toggleModal)
                    setRoleModuleMap(initalRoleModule)
                    setModules(initialModules)
                    reset(initialData)
                    setFormValues(initialData)
                    getAllUsersRoles()
                    setCurrentPage(0)
                    clearAdvanceFilterData()
                    setSearchText('')
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        } else {
            const deleteModuleName = roleModuleMap?.map((v: any) => { delete v.moduleName; return v })
            const payload = { ...data, lstRoleModuleMapping: deleteModuleName }
            // return
            axios.post(UPDATEROLE, payload, config).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    toast.success("Role updated successfully, Please log out and log back in to apply the new role permissions", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
                axios.get(apiConstants.GETMODULEWITHROLE, config).then((response) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        setLoading(false)
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        const permissionData = JSON.stringify(response.data.data)
                        const existingPermissionData = localStorage.getItem('permission');
                        if (existingPermissionData) {
                            localStorage.setItem('permission', permissionData);
                        }
                    }
                })
                setLoading(false);
                reset(initialData);
                setCurrentRole({});
                setToggleValue(!toggleModal);
                setRoleModuleMap(initalRoleModule);
                setModules(initialModules);
                getAllUsersRoles();
                setCurrentPage(0);
                clearAdvanceFilterData();
                setSearchText('');
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        }
    }

    const handleEditModal = (roleId: string, type: string) => {
        if (type === 'edit') {
            const checkEditPer = Helper.checkAdminPermission(types.EDIT)
            if (checkEditPer) {
                setLoading(true)
                setMode('edit')
                setToggleValue(!toggleModal)
                axios.get(GETROLEBYID, { ...config, params: { id: roleId } }).then((response) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        const rolesData = response.data.data;
                        setModifiedBy(response.data.data.modifiedBy);
                        const initialFormDataSet = {
                            roleName: rolesData.roleName,
                            description: rolesData.description,
                            roleId: rolesData.roleId,
                            isActive: rolesData.isActive,
                        }
                        setFormValues(initialFormDataSet);
                        reset(initialFormDataSet);
                        setCurrentRole({ ...rolesData });
                        const modifyModules = rolesData.lstModule?.map((v: any) => ({ ...v, companyId: localStorage.getItem('companyId') }));
                        setModules(modifyModules);
                        setRoleModuleMap(modifyModules);
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error);
                  });
                return
            }
            return
        }

        const checkDelPer = Helper.checkAdminPermission(types.DELETE)
        if (checkDelPer) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    axios.post(`${DELETEROLE}?roleId=${roleId}`, {}, config).then((response) => {
                        const data = document.getElementById('search') as HTMLInputElement
                        data.value = "";
                        setLoading(false);
                        if (response.status === 200) {
                            toast.success(RoleMessages.roleDeleted, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        } else if (response.status === 208) {
                            toast.error(errorMessages.roleAssigned, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                        }
                        getAllUsersRoles();
                        setCurrentPage(0);
                        clearAdvanceFilterData();
                        setSearchText('');
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                      });
                }
            })
        }
    }


    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalUserRoles?.filter((x: any) => x.roleName?.toLocaleLowerCase()?.includes(keyword) ||
            x.strUpdatedBy?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData);
        setCurrentPage(0)
    }

    const handleClose = () => {
        if (mode === 'create') {
            const checkedCheckBoxes = document.querySelectorAll('.checkBox:checked')
            if (isDirty || checkedCheckBoxes.length > 0) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Changes will be discarded!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#006800',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Discard'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsChanged(false)
                        reset(initialData)
                        setToggleValue(!toggleModal)
                        setRoleModuleMap(initalRoleModule)
                        setCurrentRole({})
                        setModules(initialModules)
                        setRemainingCount(maxWordCount)
                    }
                })
                return
            }
            reset(initialData)
            setToggleValue(!toggleModal)
            setRoleModuleMap(initalRoleModule)
            setCurrentRole({})
            setModules(initialModules)
            setRemainingCount(maxWordCount)
        } else {
            if (isChanged || isDirty) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "Changes will be discarded!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#006800',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Discard'
                }).then((result) => {
                    if (result.isConfirmed) {
                        reset(initialData)
                        setToggleValue(!toggleModal)
                        setRoleModuleMap(initalRoleModule)
                        setCurrentRole({})
                        setModules(initialModules)
                        setIsChanged(false)
                        setRemainingCount(maxWordCount)
                    }
                })
                return
            }
            reset(initialData)
            setRoleModuleMap(initalRoleModule)
            setCurrentRole({})
            setModules(initialModules)
            setToggleValue(!toggleModal)
            setRemainingCount(maxWordCount)
        }
    }

    // const handlePageClick = ({ selected: selectedPage }: any) => {
    //     setCurrentPage(selectedPage);
    // }

    const handleUserRoleModal = () => {
        setToggleUserModal(!toggleUserModal)
        setRemainingCount(maxWordCount)
        const roleId = currentRole.roleId
        setLoading(true)
        axios.post(`${GETUSERWISEROLE}?roleId=${roleId}`, {}, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const userRoles = response.data.data;
                setAllUsers(userRoles);
                const setPayload = userRoles?.map((roles: any) => {
                    return {
                        newRoleId: roles.roleId,
                        oldRoleId: roles.roleId,
                        userId: roles.userId,
                        isMappedWithRole: roles.isMappedWithRole === 1 ? true : false
                    }
                })
                setRoleUserPayload(setPayload);
            }
        }).catch((error: any) => {
            setLoading(false);
            handleError(error);
        });
    }

    const handleUserCheckbox = (e: any, userRoleData: any) => {
        const index = roleUserPayload.findIndex((x: any) => x.userId === userRoleData.userId);
        const newState: any = [...roleUserPayload];
        if (index > -1) {
            newState[index] = {
                ...newState[index],
                newRoleId: e.target.checked ? currentRole.roleId : 0,
                isMappedWithRole: e.target.checked
            }
        }
        setRoleUserPayload(newState);
    }

    const handleRoleUserSubmit = () => {
        setLoading(true)



        axios.post(SAVEUSERWISEROLE, roleUserPayload, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setToggleUserModal(!toggleUserModal);
                setRoleUserPayload([]);
                setAllUsers([]);
                clearAdvanceFilterData();
                setSearchText('');
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const handleViewUserClose = () => {
        setToggleUserModal(!toggleUserModal)
        setAllUsers([])
    }

    const changeHandler = (fieldName: string, value: any) => {
        const updateData = { ...filterData, [fieldName]: value }
        setFilterData(updateData)
    }

    const clearAdvanceFilterData = () => {
        setFilterData({ role: "", userName: '', email: '', isActive: '' })
        getAllUsersRoles();
        setAlreadyFetchedDataPageNumber({})
        setCurrentPage(1);
        setIsFiltered(false)
    }

    const handleAdvanceFilter = (filterData: any, pageNum?: any) => {
        setLoading(true)
        // const joinedStr = filterData.role?.map((list: any) => list.value).join(",");
        axios.get(ADVANCEFILTERROLE, {
            ...config, params: {
                roleIds: filterData?.role?.value,
                isActive: filterData?.isActive?.value ?? -1,
                keyword: filterData?.keyword ?? '',
                pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterRoles([]);
                return;
            }
            else {
                paginateData(response?.data?.data?.data, currentPage)
                setTotalRecords(response?.data?.data?.totalRecords)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
        return
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = userRoles.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = userRoles.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName,currentPage);
        // setCurrentPage(0);
    }

    // pagination related changes arranged in sequence for take easy reference.

    const handlePageClick = (selected: any) => {
        const newPageNumber = selected + 1;
        setCurrentPage(newPageNumber)
    }

    const updateData = (pageNumber: number, newData: any) => {
        setAlreadyFetchedDataPageNumber((prevState: any) => ({
            ...prevState,
            [pageNumber]: newData
        }));
    };

    const paginateData = (data: any, pageNum: any = 1) => {
        let page: any = pageNum == 0 ? pageNum + 1 : pageNum
        setUsersRoles(data);
        updateData(page, data)
        setFilterRoles(data);
    }

    const debouncedRequest = useDebounce(() => {
        handleAdvanceFilter({ keyword: searchText })
    });

    // this useEffect called when click on pageNumber
    useEffect(() => {
        //when global search applied
        if (searchText?.length > 2) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterRoles(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            handleAdvanceFilter({ keyword: searchText })
            return
        }

        //when advanced search filter applied
        else if (isFiltered) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterRoles(alreadyFetchedDataPageNumber[currentPage])
                return
            }

            handleAdvanceFilter(filterData)
            return
        }

        //when clicked on already fetched page
        else {
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterRoles(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            getAllUsersRoles(paginationSize, currentPage)
        }

    }, [currentPage])

    return (
        <>
        <CommonHelmet title={"Roles - "} name={"role"} content={"Roles"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            {/* create/edit role modal starts */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add New Role' : 'Edit Role'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label">Role Name <sup className="text-danger">*</sup> </label>
                                <input
                                    className="form-control form-icon-input"
                                    {...register('roleName')}
                                    type="text"
                                    placeholder="Role Name"
                                    maxLength={100}
                                    defaultValue={currentRole.roleName}
                                />
                                {errors.roleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.roleName.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Role Description</label>
                                <textarea
                                    className="form-control form-icon-input"
                                    placeholder="Role Description"
                                    defaultValue={currentRole.description}
                                    rows={4}
                                    maxLength={maxWordCount}
                                    {...register('description', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                />
                                {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                    {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                </div>}

                                {errors.description && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.description.message}</span>}

                            </div>
                            <div className="col-lg-12">
                                <hr />
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                                <h4 className="fw-600">Permissions</h4>
                                <span className='mt-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please select atleast one Permission.</i></span>
                            </div>
                            <div className="">
                                <div id="tableExample">
                                    <div className="table-responsive mx-n1 px-1">
                                        <table className="table fs--1 mb-0 border-top border-200">
                                            <thead>
                                                <tr>
                                                    <th className="align-middle bg-gradiant ps-3" data-sort="name">Module Name</th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowAdd", "addCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllAdd"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowAdd === true)}
                                                        />Add / Clone
                                                    </th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowEdit", "editCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllEdit"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowEdit === true)}
                                                        /> Edit
                                                    </th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowDelete", "deleteCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllDelete"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowDelete === true)}
                                                        /> Delete
                                                    </th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowPrint", "printCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllPrint"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowPrint === true)}
                                                        /> Print
                                                    </th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowExport", "exportCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllExport"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowExport === true) === true ? true : false}
                                                        /> Export
                                                    </th>
                                                    <th className="align-middle bg-gradiant">
                                                        <input
                                                            onClick={(e) => handleAllCheck(e, "isAllowImport", "importCheck")}
                                                            className="form-check-input mt-0 me-1"
                                                            id="selectAllImport"
                                                            type="checkbox"
                                                            checked={roleModuleMap.every((mod: any) => mod.isAllowImport === true) === true ? true : false}
                                                        /> Import
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list" id="bulk-select-body">
                                                {
                                                    modules?.map((module: any, index: any) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="align-middle ps-3 name">{module.moduleName}</td>
                                                                <td className="fs--1 align-middle">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox addCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowAdd", "addCheck", "selectAllAdd")}
                                                                            defaultChecked={module.isAllowAdd}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="fs--1 align-middle">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox editCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowEdit", "editCheck", "selectAllEdit")}
                                                                            defaultChecked={module.isAllowEdit}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle age">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox deleteCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowDelete", "deleteCheck", "selectAllDelete")}
                                                                            defaultChecked={module.isAllowDelete}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle age">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox printCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowPrint", "printCheck", "selectAllPrint")}
                                                                            defaultChecked={module.isAllowPrint}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle age">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox exportCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowExport", "exportCheck", "selectAllExport")}
                                                                            defaultChecked={module.isAllowExport}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="align-middle age">
                                                                    <div className="form-check mb-0 fs-0">
                                                                        <input
                                                                            className="form-check-input checkBox importCheck"
                                                                            type="checkbox"
                                                                            onClick={(e) => handleModules(e, module.moduleId, "isAllowImport", "importCheck", "selectAllImport")}
                                                                            defaultChecked={module.isAllowImport}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {(mode === 'edit') && <div className="mt-4 pl-2">
                                        <label>Status</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                                {...register('isActive')}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <ModifiedBy data={formValues} />
                        <LastModified data={formValues} />
                        {(mode === 'edit') && <button className="btn btn-outline-secondary" type="button" data-bs-dismiss="modal" onClick={handleUserRoleModal}>View users</button>}
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >{(mode === 'create') ? (CommonMessages.saveButton) : (CommonMessages.updateButton)}</button>
                    </Modal.Footer>
                </form>
            </Modal >
            {/* create/edit role modal ends */}

            {/* view users role modal starts */}
            <Modal show={toggleUserModal} size='lg' centered className="overlay">
                <Modal.Header>
                    <h5 className="modal-title" id="verticallyCenteredModalLabel">View users</h5>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleViewUserClose}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row g-3 mb-3">
                        <div className="col-md-12">
                            <label className="form-label">Role Name <sup className="text-danger">*</sup> </label>
                            <input
                                className="form-control form-icon-input"
                                name="roleName"
                                type="text"
                                placeholder="Role Name"
                                defaultValue={currentRole.roleName}
                                disabled
                            />
                        </div>
                        <div className="col-md-12">
                            <label className="form-label">Role Description</label>
                            <textarea
                                className="form-control form-icon-input"
                                name="description"
                                placeholder="Role Description"
                                defaultValue={currentRole.description}
                                rows={3}
                                disabled
                            />
                        </div>
                        <div className="col-lg-12">
                            <hr />
                        </div>
                        <div>
                            <h4 className="fw-600">Permissions</h4>
                        </div>
                        <div className="">
                            <div id="tableExample">
                                <div className="table-responsive mx-n1 px-1 scroll scrollbar h-200">
                                    <table className="table border-rounded table-md border-top border-bottom td-border-bottom-0 border-200 fs--1 mb-2 ">
                                        <thead>
                                            <tr>
                                                <th className="align-middle bg-gradiant top-fix ps-3" data-sort="name">Users</th>
                                                <th className="align-middle bg-gradiant top-fix">Current role</th>
                                                <th className="align-middle bg-gradiant top-fix">Mapped With Role?</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="bulk-select-body">
                                            {
                                                allUsers?.map((user: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="align-middle ps-3 name">{user.userName}</td>
                                                            <td>{user.roleName}</td>
                                                            <td className="fs--1 align-middle">
                                                                <div className="form-check mb-0 fs-0">
                                                                    <input
                                                                        className="form-check-input checkBox addCheck"
                                                                        type="checkbox"
                                                                        onClick={(e) => handleUserCheckbox(e, user)}
                                                                        defaultChecked={(user.isMappedWithRole == 1) ? true : false}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer d-flex justify-content-between">
                    <button className="btn btn-outline-danger me-auto " type="button" data-bs-dismiss="modal" onClick={handleViewUserClose}>{CommonMessages.cancelButton}</button>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleRoleUserSubmit}
                    >{CommonMessages.saveButton}</button>

                </Modal.Footer>
            </Modal>
            {/* view users role modal end */}

            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Roles'>
                    <div className="search-box">
                        <div className="d-flex mt-2 align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" id="search" placeholder="Search" aria-label="Search" value={searchText}
                                    onChange={(e: any) => {
                                        setSearchText(e.target.value)
                                        if (e.target.value.length != 0) {
                                            if (e.target.value.length > 2) {
                                                setAlreadyFetchedDataPageNumber({})
                                                setCurrentPage(1)
                                                debouncedRequest();
                                                return
                                            }
                                        } else {
                                            clearAdvanceFilterData()
                                        }

                                    }
                                    }
                                />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })}><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                        <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                            onClick={() => {
                                const checkAddPer = Helper.checkAdminPermission(types.ADD)
                                if (checkAddPer) {
                                    setToggleValue(!toggleModal)
                                    setMode('create')
                                }
                            }}>
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Role </span>
                        </button>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-2">
                                <button className="btn p-1 p-a" type="button" onClick={() => setShowFilter(!showFilter)}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form >
                                            <div className="row g-3">
                                                <div className="col-xl-3 col-lg-5 col-md-6">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Role</label>
                                                    {/* <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select"
                                                        className='role'
                                                        name='role'
                                                        // options={filterRoleData}
                                                        options={[
                                                            { label: 'Select roles', value:"" },
                                                            ...filterRoleData 
                                                        ]}
                                                        value={filterData.role}
                                                        onChange={(e) => changeHandler("role", e)}
                                                    /> */}
                                                    <Select
                                                        classNamePrefix="dpaSelect"
                                                        placeholder="Select Role"
                                                        className="isActive clone-inventory"
                                                        name="role"
                                                        options={filterRoleData}
                                                        value={filterData.role}
                                                        onChange={(e) => changeHandler("role", e)}
                                                    />
                                                </div>
                                                <div className="col-xl-3 col-lg-5 col-md-6">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Activation Status</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select Activation Status"
                                                        className='isActive'
                                                        name='isActive'
                                                        options={filterActivationStatus}
                                                        value={filterData.isActive}
                                                        onChange={(e) => changeHandler("isActive", e)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end mt-3 ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="submit" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="submit"
                                                    onClick={() => {
                                                        setSearchText('')
                                                        setCurrentPage(1)
                                                        setAlreadyFetchedDataPageNumber({})
                                                        setIsFiltered(true)
                                                        handleAdvanceFilter(filterData, 1)
                                                    }}
                                                >Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </InnerHeader >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div data-list='{"valueNames":["product",","rating","review","time"],"page":6}'>
                                    <div className="row top_border">
                                        <div className="col-12">
                                            <div className="mb-3 border-300">
                                                <div data-list='{"valueNames":["product",","rating","review","time"],"page":6}'>
                                                    <div className="table-responsive mx-n1 px-1 scrollbar">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant" scope="col">Action</th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="Initials">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Role Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'roleName')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'roleName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="User">Description</th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="Email">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Last Modified By</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'strUpdatedBy')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'strUpdatedBy')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="Privillege">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Date Modified ({defaultTimezone}) </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="Activation">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterRoles?.length > 0 ? filterRoles?.map((role: any, index: any) => {
                                                                    const updatedTime = momentUtil.parseTimeInUtc({
                                                                        dateTimeString: role?.updatedOn,
                                                                    });
                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                    <Dropdown.Toggle className="m-0 p-0" variant="none" >
                                                                                        <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                        </button>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(role.userRoleId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(role.userRoleId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="align-middle p-2">{role.roleName ?? '-'}</td>
                                                                            <td className="align-middle p-2">{role.roleDescription ?? '-'}</td>
                                                                            <td className="align-middle p-2">{role.strUpdatedBy ?? '-'}</td>
                                                                            {/* <td className="align-middle p-2">{role?.updatedOn == "--" ? "--" : moment(role?.updatedOn)?.format('MM/DD/YYYY hh:mm A')}</td> */}
                                                                            <td className="align-middle p-2">{updatedTime.format("MM/DD/YYYY hh:mm A")}</td>
                                                                            {/* <td className="align-middle p-2">{role?.strUpdatedOn == "--" ? "--" : updatedTime?.format("MM/DD/YYYY HH:mm")}</td> */}
                                                                            <td className="align-middle text-start status p-2">
                                                                                <span className={`badge badge-dpa fs--2 ${role.isActive === true ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                                                    <span className="badge-label">{role.isActive == true ? 'Active' : 'Inactive'}</span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterRoles?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={Math.ceil(totalRecords / paginationSize)}
                                            onPageChange={({ selected }) => handlePageClick(selected)}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                                        />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Role;
