import React from 'react'

const ModifiedBy = ({ data }: any) => {
    return (
        <>
            {data?.modifiedBy ? <div className=" d-flex align-items-center px-2 mt-1">
                <h6>{`${data?.updatedOn ? 'Modified By' : 'Created By'}: ${data?.modifiedBy}`}</h6>
            </div> : ""}

        </>
    )
}

export default ModifiedBy