import * as routeConstant from "../common/routeConstants";
import { AllRoleResponse, SideBar } from "./interface";
import auctionStepTwo from "../assets/step-wizard/Auction Info 1.svg";
import auctionStepThree from "../assets/step-wizard/auction_step_3.svg";
import auctionStepFour from "../assets/step-wizard/auction_step_4.svg";
import auctionStepFive from "../assets/step-wizard/Values 1.svg";
import auctionStepSix from "../assets/step-wizard/E-Invoices 1.svg";
import auctionStepSeven from "../assets/step-wizard/Auction_Close.svg";
import auctionEinvoices from "../assets/step-wizard/Invoices_Settings.svg";
import auctionStepTwoActive from "../assets/step-wizard/Auction InfoActive.svg";
import auctionStepThreeActive from "../assets/step-wizard/auction_step_3_active.svg";
import auctionStepFourActive from "../assets/step-wizard/auction_step_4_active.svg";
import auctionStepFiveActive from "../assets/step-wizard/ValuesActive.svg";
import auctionStepSixActive from "../assets/step-wizard/E-InvoicesActive.svg";
import auctionStepSevenActive from "../assets/step-wizard/Auction_Close_white.svg";
import auctionEinvoicesActive from "../assets/step-wizard/Invoices_Settings_white.svg";
import IconVisa from "../assets/cc-icons/icon_visa.svg";
import IconAmericanExpress from "../assets/cc-icons/icon_americanexpress.svg";
import IconUnionpay from "../assets/cc-icons/icon_unionpay.svg";
import IconMastercard from "../assets/cc-icons/icon_mastercard.svg";
import IconJCB from "../assets/cc-icons/icon_jcb.svg";
import IconEftpos from "../assets/cc-icons/icon_eftpos_white.svg";
import IconDiscover from "../assets/cc-icons/icon_discover.svg";
import IconDinersClub from "../assets/cc-icons/icon_dinersclub.svg";
import * as AuctionConstants from "../components/Auction/AuctionConstants"

import {
  IconAdminSettings,
  IconAuction,
  IconCustomer,
  IconHome,
  IconInventory,
  IconInvoice,
  IconReport,
  IconSales,
  IconSalesReps,
  IconSellers,
  IconBidManagement,
} from "../components/SvgIcons/SvgIcons";
import { url } from "inspector";

export const sidebarData: SideBar[] = [
  {
    label: "Home",
    slug: "dashboard",
    icon: IconHome,
    url: routeConstant.DASHBOARD,
    childData: [],
  },
  {
    label: "Auctions",
    slug: routeConstant.AUCTIONSLUG,
    icon: IconAuction,
    url: routeConstant.AUCTION,
    childTitle: "Auction",
    childData: [],
  },
  {
    label: "Buyers",
    slug: routeConstant.CUSTOMERSLUG,
    icon: IconCustomer,
    url: routeConstant.CUSTOMERS,
    childTitle: "New Buyers",
    childData: [],
  },
  {
    label: "Inventory",
    slug: routeConstant.INVENTORYSLUG,
    icon: IconInventory,
    url: routeConstant.INVENTORY,
    childTitle: "Inventory",
    childData: [],
  },
  {
    label: "Sales Record",
    slug: routeConstant.SALESSLUG,
    icon: IconSales,
    url: routeConstant.SALESRECORDS,
    childTitle: "Sales Record",
    childData: [],
  },
  {
    label: "Invoices",
    slug: routeConstant.INVOICESLUG,
    icon: IconInvoice,
    url: routeConstant.INVOICE,
    childTitle: "Invoices",
    childData: [
      // { label: 'New Invoices', url: '/' },
      // { label: 'All Invoices', url: '/' }
    ],
  },
  {
    label: "Sellers",
    slug: routeConstant.SELLERSLUG,
    icon: IconSellers,
    url: routeConstant.SELLER,
    childTitle: "Sellers",
    childData: [],
  },
  {
    label: "Sales Reps",
    slug: routeConstant.SALESREPSLUG,
    icon: IconSalesReps,
    url: routeConstant.SALESREPRESENTATIVE,
    childTitle: "Sales Reps",
    childData: [],
  },
  {
    label: "Bid Management",
    slug: routeConstant.AUCTIONBIDSLUG,
    icon: IconBidManagement,
    url: routeConstant.AUCTIONBID,
    childTitle: "Bid Management",
    childData: [],
  },
  {
    label: "Reports",
    slug: routeConstant.REPORTSLUG,
    icon: IconReport,
    url: routeConstant.REPORTS,
    childTitle: "Reports",
    childData: [],
  },

  {
    label: "Admin Settings",
    icon: IconAdminSettings,
    slug: routeConstant.ADMINCONFIGSLUG,
    url: "#",
    childTitle: "Admin Settings",
    childData: [
      {
        label: "User Management",
        subChild: [
          { childLabel: "Users", url: routeConstant.USER },
          { childLabel: "Roles", url: routeConstant.ROLE },
          // { childLabel: "Buyer Seller ", url: routeConstant.BUYERSELLER },
        ],
      },
      {
        label: "Defaults",
        subChild: [
          { childLabel: "Company Info", url: routeConstant.COMPANYPROFILE },
          { childLabel: "Auction Defaults", url: routeConstant.AUCTIONDEFAULT },
          {
            childLabel: "E-Invoice Settings",
            url: routeConstant.EINVOICESETTING,
          },
          {
            childLabel: "Buyer Premium by Scales",
            url: routeConstant.BUYERSPREMIUMBYSCALES,
          },
          { childLabel: "BP Sales Tax", url: routeConstant.BPSALESTAX },
          // { childLabel: "Default State", url: "#" },
          { childLabel: "Bid Increment", url: routeConstant.BIDINCREMENT },
          { childLabel: "Value Defaults", url: routeConstant.VALUEDEFAULT },
          { childLabel: "Image Configuration", url: routeConstant.IMAGECONFIG },
          {
            childLabel: "Admin Configuration",
            url: routeConstant.BIDDERCONFIG,
          },
          {
            childLabel: "Time Zone Identifier",
            url: routeConstant.TIMEZONEIDENTIFIER,
          },
        ],
      },
      {
        label: "Categories & Commission",
        subChild: [
          { childLabel: "Master Categories", url: routeConstant.CATEGORIES },
          { childLabel: "Commission", url: routeConstant.COMMISSION },
          // { childLabel: 'Sales Rep Commision', url: '#' },
        ],
      },
      {
        label: "Email Settings",
        subChild: [
          { childLabel: "Email Send Options", url: routeConstant.EMAILSET },
          { childLabel: "Automated Web Emails", url: routeConstant.AUTOEMAIL },
          {
            childLabel: "Email Header & Footer",
            url: routeConstant.EMAILHEADERFOOTER,
          },
          { childLabel: "Seller Reports", url: routeConstant.SELLERREPORT },
        ],
      },
      {
        label: "Tax & Currency",
        subChild: [
          { childLabel: "Tax Rates", url: routeConstant.TAXRATE },
          { childLabel: "Avalara", url: routeConstant.AVALARA },

        ],
      },
      {
        label: "Templates",
        subChild: [
          // { childLabel: "Lot Templates", url: routeConstant.LOTTEMPLATES },
          {
            childLabel: "Auction Templates",
            url: routeConstant.AUCTIONTEMPLATES,
          },
          { childLabel: "Inventory Templates", url: routeConstant.INVENTORYTEMPLATES },
          { childLabel: "Buyer Templates", url: routeConstant.CUSTOMERTEMPLATES },
          { childLabel: "Report Templates", url: routeConstant.REPORTTEMPLATES },
          { childLabel: "Seller Templates", url: routeConstant.SELLERTEMPLATES },
          { childLabel: "Sales Rep Templates", url: routeConstant.SALESREPTEMPLATES },
          { childLabel: "Invoice Templates", url: routeConstant.INVOICETEMPLATES },
          { childLabel: "Authorization Templates", url: routeConstant.AUTHORIZATIONTEMPLATES },
          { childLabel: "SMS Templates", url: routeConstant.SMSTEMPLATES },
          { childLabel: "Email Templates", url: routeConstant.EMAILTEMPLATES },
          // { childLabel: "Sales Record Templates", url: routeConstant.SALESRECORDTEMPLATES },
          // { childLabel: "ContactUs Templates", url: routeConstant.CONTACTUSTEMPLATES },
          // { childLabel: "Appraisal Templates", url: routeConstant.APPRAISALTEMPLATES },
          // { childLabel: "Partners Templates", url: routeConstant.PARTNERTEMPLATES },
        ],
      },
    ],
  },
  /* {
        label: 'Reports',
        icon: 'assets/img/sidebar-icons/reports.svg',
        url: '#',
        childData: []
    } */
];

export const SearchReportsData: any = [
  { label: "Transportation Message", url: routeConstant.TRANSPORT },
  { label: "Inventory Import Logs", url: routeConstant.IMPORTLOGS },
  { label: "Suspend Buyers History", url: routeConstant.SUSPENDREPORT },
  { label: "Lienholder Report", url: routeConstant.LIENHOLDERREPORT },
  { label: "Seller Summary Report", url: routeConstant.SELLERSUMMARYREPORT },
  { label: "Titled Report", url: routeConstant.TITLEREPORT },
  { label: "Seller Auction Report", url: routeConstant.SELLERAUCTIONSUMMARYREPORT },
  { label: "Seller Commission Report", url: routeConstant.SELLERCOMMISSIONREPORT },
  { label: "Commission Summary Report", url: routeConstant.COMMISSIONSUMMARYREPORT },
  { label: "Sales Rep Commission Report", url: routeConstant.SALESREPCOMMISSIONREPORT },
  { label: "Seller Adjustment Report", url: routeConstant.SALLERADJUSTREPORT },
  { label: "All Seller Summary Report", url: routeConstant.ALLSELLERSUMMARY },
  { label: "Seller Commission Summary Report", url: routeConstant.SELLERCOMMISSIONSUMMARY },
  { label: "Seller Overall Summary Report", url: routeConstant.SELLEROVERALLSUMMARY },
  { label: "Seller Payments Summary Report", url: routeConstant.SELLERPAYMENTSUMMARY },
  { label: "Fictitious Bidder Report", url: routeConstant.FICTITIOUSBIDDEREPORT },
  { label: "Inventory By Seller Report", url: routeConstant.INVENTORYBYSELLER },
  { lable : "Tax Summmary by Jurisdiction", url: routeConstant.TAXSUMMARYBYJURISDICTION},
  { lable : "Invoice Summary", url: routeConstant.INVOICESUMMARY},
  { lable : "Tax Exempt Summary", url: routeConstant.TAXEXEMPTSUMMARY},
]

//when add new data in ReportsLinkData , always add same data in SearchReportsData.

export const ReportsLinkData: any = [
  {
    label: "Seller Reports", child: [
      // { label: "Seller Summary Report", url: routeConstant.SELLERSUMMARYREPORT, description: 'Report of Seller Summaries' },
      // { label: "Seller Auction Report", url: routeConstant.SELLERAUCTIONSUMMARYREPORT },
      { label: "All Seller Summary Report", url: routeConstant.ALLSELLERSUMMARY, 
        description: 'The report provides essential details about sellers participating in the selected auction. It includes their email, phone number, company, the number of lots sold by each seller, and the total sales amount.' },
      { label: "Seller Commission Report", url: routeConstant.SELLERCOMMISSIONREPORT, 
        description: 'A seller commission report provides an overview of a sellers activity in an auction and track their performance and understand their auction results' },
      { label: "Seller Commission Summary Report", url: routeConstant.SELLERCOMMISSIONSUMMARY, description: 'The Seller Commission Summary Report provides an overview of commissions for all sellers in a specific auction' },
      { label: "Seller Adjustment Report", url: routeConstant.SALLERADJUSTREPORT, 
        description: 'The Seller Adjustment Report provides a summary of adjustments made by sellers in an auction. The report is organized as Seller-wise Adjustments, Group Totals, and Final Totals' },
      { label: "Seller Summary Report", url: routeConstant.SELLEROVERALLSUMMARY, description: 'Report of Seller Summary' },
      { label: "Seller Payment Summary Report", url: routeConstant.SELLERPAYMENTSUMMARY, 
        description: 'Seller Payment Summary Report provides a summary of payments received from sellers for a selected auction. It includes details on credit charge entries and groups the information by individual sellers' },
      { label: "Seller Pickup Summary Report", url: routeConstant.SELLERPICKUPSUMMARY, 
        description: 'The Seller Pickup Summary Report provides a concise overview of invoices associated with specific auctions. It includes details such as invoice numbers, lot information, customer data, and the corresponding pickup verification codes' },
      { label: "Summary of Sellers by Sales Rep", url: routeConstant.SUMMARYOFSELLERBYSALESREP, 
        description: 'Summary of Sellers by Sales Rep' },
    ]
  },
  {
    label: "Sales Rep Reports", child: [
      { label: "Sales Rep Commission Report", url: routeConstant.SALESREPCOMMISSIONREPORT, description: 'Sales Rep Commission' },
    ]
  },
  {
    label: "Inventory Reports", child: [
      { label: "Inventory Import Logs", url: routeConstant.IMPORTLOGS, description: 'Report of Inventory Import Logs' },
      { label: "Inventory By Seller", url: routeConstant.INVENTORYBYSELLER, description: 'Report of Inventory By Seller' },
      { label: "Tax Summary By Jurisdiction", url: routeConstant.TAXSUMMARYBYJURISDICTION, description: 'Report of Tax Summary By Jurisdiction' }
    ]
  },
  {
    label: "Buyer Reports", child: [
      { label: "Suspend Buyers History", url: routeConstant.SUSPENDREPORT, description: 'Report of Suspended Buyers History' },
      { label: "Invoice Summary", url: routeConstant.INVOICESUMMARY , description: 'Invoice Summary' },
    ]
  },
  {
    label: "Other Reports", child: [
      { label: "Titled Report", url: routeConstant.TITLEREPORT, description: 'Report of Titled data' },
      { label: "Lienholder Report", url: routeConstant.LIENHOLDERREPORT, description: 'Report of Lienholder Data.' },
      { label: "Transportation Message", url: routeConstant.TRANSPORT, description: 'Transportation Message' },
      { label: "Fictitious Bidder Report", url: routeConstant.FICTITIOUSBIDDEREPORT, description: 'Report of Fictitious Bidder' },
      { label: "Auto Title Summary Report", url: routeConstant.AUTOTITLEREOPRT , description: 'Auto Title Summary Report' },
      { label: "Balance Due By Bidder", url: routeConstant.BALANCEDUEBYBIDDER , description: 'Balance Due By Bidder + Invoice' },
      { label: "Tax Exempt Summary", url: routeConstant.TAXEXEMPTSUMMARY , description: 'Tax Exempt Summary' },
    ]
  },
]

export const roleData: AllRoleResponse[] = [
  {
    roleName: "Management",
    description: "Allows access to all areas",
    lastModifiedBy: "Adam Joe",
    dateMofied: "21/03/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Standard Users",
    description: "Allows access to all areas except admin",
    lastModifiedBy: "Adam Joe",
    dateMofied: "21/04/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Standard User-No Master Bidders",
    description: "Restricted access to masters bidders",
    lastModifiedBy: "John Joe",
    dateMofied: "21/05/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Standard User-No Delete",
    description: "Allows all access to areas except admin; No delete",
    lastModifiedBy: "Adam Joe",
    dateMofied: "21/06/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Agent",
    description: "Access only to auctions created or allowed access..",
    lastModifiedBy: "john Doe",
    dateMofied: "21/07/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Limited Edition",
    description: "Standard access but some functions limited",
    lastModifiedBy: "john Doe",
    dateMofied: "21/07/2023",
    status: "Industrial/Commercial",
  },
  {
    roleName: "Read Only",
    description: "Read only data",
    lastModifiedBy: "john Doe",
    dateMofied: "21/07/2023",
    status: "Industrial/Commercial",
  },
];

export const activationStatus = [
  {
    label: "All",
    value: -1,
  },
  {
    label: "Active",
    value: 1,
  },
  {
    label: "InActive",
    value: 0,
  },
];

export const isSellerStatus = [
  {
    label: "All",
    value: -1,
  },
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
]

export const isBuyerStatus = [
  {
    label: "All",
    value: -1,
  },
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
]

export type Action = {
  type: string;
  payload: any;
};

// Lot templates : edit Field popup with some custom fields
export const formOptions = {
  noDefaultSubmitButton: true,
  editForm: {
    textfield: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "api",
        ignore: true,
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    textarea: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],
    number: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],
    password: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],
    checkbox: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
          {
            key: "labelWidth",
            ignore: true,
          },
          {
            key: "labelMargin",
            ignore: true,
          },
        ],
      },
    ],
    selectboxes: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
          {
            key: "inlineayout",
            ignore: true,
          },
        ],
      },
    ],
    select: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
          {
            key: "uniqueOptions",
            ignore: true,
          },
        ],
      },
    ],
    radio: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
          {
            key: "inlinelayout",
            ignore: true,
          },
        ],
      },
    ],
    button: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
          {
            key: "leftIcon",
            ignore: true,
          },
          {
            key: "saveOnEnter",
            ignore: true,
          },
        ],
      },
    ],

    email: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],

    address: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],

    phoneNumber: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],

    datetime: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],

    url: [
      {
        key: "display",
        components: [
          {
            type: "checkbox",
            key: "validate.required",
            label: "Required",
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
    ],
  },
};

export const auctionCheckList = [
  { label: "Show Starting Bid", key: "showStartingBid" },
  { label: "Display Image Names", key: "displayImageName" },
  { label: "Feature Auction", key: "featureAuction" },
  { label: "Use Geocoding", key: "useGeocoding" },
  // { label: "Show Auction Map", key: "showAuctionMap" },
];

export const biddingTypes = [
  // { label: "Online Absentee Bidding", key: "onlineAbsenteeBidding" },
  { label: "Dynamic Extension (sec)", key: "dynamicExtension" },
  { label: "Silent Online Bidding", key: "silentOnlineBidding" },
];

export const silentAuctions = [
  { label: "Dynamic Opening", key: "dynamicOpening" },
  { label: "Race Horse Option", key: "raceHorseOption" },
];

export const buyersPremium = [
  { label: "Buyers Premium Taxable", key: "buyersPremiumTaxable" },
  { label: "BP Taxed on Tax Exempt Lots", key: "bpTaxedonTaxExemptLots" },
];


export const stepperData = [
  {
    icon: auctionStepTwo,
    activeIcon: auctionStepTwoActive,
    label: AuctionConstants.AUCTIONINFO,
    step: AuctionConstants.STEPONE,
  },
  {

    icon: auctionStepFour,
    activeIcon: auctionStepFourActive,
    label: AuctionConstants.AUCTIONDETAILS,
    step: AuctionConstants.STEPTWO,
  },
  {
    icon: auctionStepThree,
    activeIcon: auctionStepThreeActive,
    label: AuctionConstants.CHECKLIST,
    step: AuctionConstants.STEPTHREE,
  },
  {
    icon: auctionEinvoices,
    activeIcon: auctionEinvoicesActive,
    label: AuctionConstants.INVOICESETTINGS,
    step: AuctionConstants.STEPFOUR,
  },
  {
    icon: auctionStepSix,
    activeIcon: auctionStepSixActive,
    label: AuctionConstants.EINVOICES,
    step: AuctionConstants.STEPFIVE,
  },
  {
    icon: auctionStepFive,
    activeIcon: auctionStepFiveActive,
    label: AuctionConstants.VALUESETTINGS,
    step: AuctionConstants.STEPSIX,
  },
  {
    icon: auctionStepSeven,
    activeIcon: auctionStepSevenActive,
    label: AuctionConstants.AUCTIONCLOSE,
    step: 7,
  },
];



export const BPMethod = [
  { label: "Payment Type", value: 1, checked: true },
  { label: "Scale", value: 2, checked: false },
];

export const invoicePrintPdfPref = [
  {
    label: "Always Create PDF Invoice",
    value: "alwaysCreatePDFInvoice",
    type: "Auto",
  },
  {
    label: "Always Create Paid PDF Invoice",
    value: "alwaysCreatePaidPDFInvoice",
    type: "Auto",
  },
  { label: "Print Custom Reports", value: "printCustomReports", type: "Auto" },
  {
    label: "Auto One Seller Only Per Invoice",
    value: "autoOneSellerOnlyPerInvoice",
    type: "Auto",
  },
  {
    label: "Always Show Print Dialog",
    value: "alwaysShowPrintDialog",
    type: "Auto",
  },
  {
    label: "PDF Custom Reports",
    value: "pdfCustomReports",
    type: "All Invoicing",
  },
];

export const auctionType = [
  { value: 1, label: "Land Auction", content: "Land auctions involve the sale of real estate or property through a bidding process." },
  { value: 2, label: "Equipment Auction", content: "Equipment auctions involve the sale of various types of equipment, machinery, vehicles, or other assets through a bidding system." },
  { value: 3, label: "Business Auction", content: "Business: Business auctions involve the sale of entire businesses or specific assets." },
];

export const auctionStatus = [
  { value: 1, label: "In Configuration" },
  { value: 2, label: "Active" },
  { value: 3, label: "Sold" },
];

export const sellerStatusOptions: any = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Approved" },
  { value: 3, label: "Rejected" },
];

export const reDisplay = [
  { value: 1, label: "Single-Property", checked: true },
  { value: 2, label: "Multi-Property", checked: false },
];

export const formatPhoneNumber = (phoneno: any) => {
  if (phoneno){
  const digitsOnly = phoneno.replace(/\D/g, "");
  if (!digitsOnly) {
    return;
  }
  let format;
  let countryCode, phoneNumber;
  if (digitsOnly.length < 10) {
    countryCode = digitsOnly[0];
    phoneNumber = digitsOnly.substring(1);
    format = `+${digitsOnly
      .split("")
      .map((i: any, index: any) =>
        index > 1 && index % 2 === 0 ? "X " : "X"
      )}`.replaceAll(",", "");
  } else {
    if (digitsOnly.startsWith("1")) {
      countryCode = "1";
      phoneNumber = digitsOnly.substring(1);
    }
    else {
      countryCode = digitsOnly.slice(0, 2);
      phoneNumber = digitsOnly.substring(2);
    }

    switch (countryCode) {
      case "1": //
        format = "+1 (XXX) XXX-XXXX";
        break;
      case "44":
        format = "+44 (XX) XXXX-XXXX";
        break;
      case "61":
        format = "+61 (X) XXXX-XXXX";
        break;
      case "49":
        format = "+49 (XXX) XXXXXXX";
        break;
      case "33":
        format = "+33 (X) XX XX XX XX";
        break;
      case "81":
        format = "+81 (XX) XXXX-XXXX";
        break;
      case "91":
        format = "+91 XXXXX-XXXXX";
        break;
      case "45":
        format = "+45 XX XX XX XX";
        break;
      default:
        format = `+${countryCode} ${phoneNumber.split("").map((i: any, index: number) => {
          if (typeof i == 'undefined') return ''
          if (index > 1 && index % 2 == 0) {
            return "X ";
          } else {
            return "X";
          }
        })}`.replaceAll(",", "");
    }
  }

  let formattedPhoneNumber = "";
  let digitIndex = 0;
  for (let i = 0; i < format.length; i++) {
    if (typeof digitsOnly[digitIndex] == 'undefined' || typeof phoneNumber[digitIndex] == 'undefined') {
      continue
    }
    if (format[i] === "X") {
      countryCode == ""
        ? (formattedPhoneNumber += digitsOnly[digitIndex])
        : (formattedPhoneNumber += phoneNumber[digitIndex]);
      digitIndex++;
    } else {
      formattedPhoneNumber += format[i];
    }
  }

  return formattedPhoneNumber;
};
};

export const AuctionTemplatesFor = [
  { value: "auctionEinvoiceInvoice", label: "Auction E-Invoice Invoice" },
  { value: "auctionEinvoiceSeller", label: "Auction E-Invoice Seller" },
  { value: "auctionSellerBody", label: "Auction Seller Body" },
  { value: "auctionSellerFooter", label: "Auction Seller Footer" },
];

export const AdminConfigBidderOption = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const AdminConfigLastOption = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const AdminConfigVerificationOption = [
  { value: 1, label: "SMS" },
  { value: 2, label: "Email" },
  { value: 3, label: "Both SMS & Email" }
];

export const AdminConfigTimeZone = [
  { value: "CDT", label: "CDT" },
  { value: "UTC", label: "UTC" },
  { value: "GMT", label: "GMT" },
  { value: "IST", label: "IST" }
];

export const wordCounter = (value: any, maxWords: any) => {
  const wordCount = value?.split("").filter(Boolean).length;
  return maxWords - wordCount;
};

export const docsData = [
  { name: "Invocice", url: "Asset/DummyPDF/pdfInvoice.pdf" },
  {
    name: "2016 RoGator 1100B Dry Machine",
    url: "Asset/DummyPDF/2016 RoGator 1100B Dry Machine.jpg",
  },
  {
    name: "Blu Jet 3-Point Tool Bar",
    url: "Asset/DummyPDF/Blu Jet 3-Point Tool Bar.jpg",
  },
  { name: "Sample doc", url: "Asset/DummyPDF/sample.pdf" },
];

export const docUploadStatus = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Under Review" },
  { value: 3, label: "Approved" },
];

export const commissionType = [
  { label: "Based on Total", value: 1, selected: true },
  { label: "Based per Item", value: 2, selected: false },
];

export const InvoiceConfig = [
  { label: "Bidder Wise Separate Invoice", value: 1, selected: true },
  { label: "Bidder Wise Merge Invoice", value: 2, selected: false },
  { label: "Buyer Wise Merge Invoice", value: 3, selected: false },
];

export const InvoiceSelect = [
  { lable: "Buyer", value: 1, selected: true },
  { lable: "Bidder #", value: 2, selected: false }
]
export const taxExemptType = [
  { label: "Yes", value: true, selected: false },
  { label: "No", value: false, selected: true },
];
export const bidderExemptType = [
  { label: "Yes", value: true, selected: false },
  { label: "No", value: false, selected: true },
];


export const suspendRadio = [
  { label: "Suspend", value: true, selected: false },
  { label: "Activate", value: false, selected: true },
];

export const CustomerCardList = [
  {
    cardHolderName: "Junaid Shaikh",
    cardLogos: IconVisa,
    logoheights: 20,
    cardExpiry: "10/24",
  },
  {
    cardHolderName: "Aliasgar Mandviwala",
    cardLogos: IconUnionpay,
    logoheights: 30,
    cardExpiry: "01/30",
  },
  {
    cardHolderName: "Raj Shah",
    cardLogos: IconMastercard,
    logoheights: 30,
    cardExpiry: "09/35",
  },
  {
    cardHolderName: "Chirag Prajapati",
    cardLogos: IconJCB,
    logoheights: 30,
    cardExpiry: "12/30",
  },
  {
    cardHolderName: "Leena Dhillon",
    cardLogos: IconEftpos,
    logoheights: 30,
    cardExpiry: "01/25",
  },
  {
    cardHolderName: "Krunal Patel",
    cardLogos: IconDiscover,
    logoheights: 15,
    cardExpiry: "04/37",
  },
  {
    cardHolderName: "Vipul Donga",
    cardLogos: IconDinersClub,
    logoheights: 30,
    cardExpiry: "11/40",
  },
  {
    cardHolderName: "Devendra Gorana",
    cardLogos: IconAmericanExpress,
    logoheights: 30,
    cardExpiry: "07/47",
  },
];

export const cardBrandMapping = [
  { brand: "visa", cardLogos: IconVisa, logoheights: 20, gradient: 1 },
  { brand: "UnionPay", cardLogos: IconUnionpay, logoheights: 30, gradient: 2 },
  {
    brand: "mastercard",
    cardLogos: IconMastercard,
    logoheights: 30,
    gradient: 3,
  },
  { brand: "JCB", cardLogos: IconJCB, logoheights: 30, gradient: 4 },
  {
    brand: "EftposAustralia",
    cardLogos: IconEftpos,
    logoheights: 30,
    gradient: 5,
  },
  { brand: "Discover", cardLogos: IconDiscover, logoheights: 15, gradient: 6 },
  {
    brand: "DinersClub",
    cardLogos: IconDinersClub,
    logoheights: 30,
    gradient: 7,
  },
  {
    brand: "amex",
    cardLogos: IconAmericanExpress,
    logoheights: 30,
    gradient: 8,
  },
];

// export const lightMode = 'lightMode';
// export const darkMode = 'darkMode';
export const colorModes = [{ mode: "light" }, { mode: "dark" }];

export const themes = [
  {
    themeName: "theme1",
    themeColor: "#176707",
  },
  {
    themeName: "theme2",
    themeColor: "#d35f1f",
  },
  {
    themeName: "theme3",
    themeColor: "#ccab00",
  },
  {
    themeName: "theme4",
    themeColor: "#85310b",
  },
  {
    themeName: "theme5",
    themeColor: "#ac6c27",
  },
  {
    themeName: "theme6",
    themeColor: "#2a5f9d",
  },
  {
    themeName: "theme7",
    themeColor: "#681eb0",
  },
  {
    themeName: "theme8",
    themeColor: "#5e9695",
  },
  {
    themeName: "theme9",
    themeColor: "#2b5069",
  },
  {
    themeName: "theme10",
    themeColor: "#895d8c",
  },
];

export const UTCdateConverter = (date: any) => {
  const myDate: any = new Date(date)
  return new Date(
    myDate.getUTCFullYear(),
    myDate.getUTCMonth(),
    myDate.getUTCDate(),
    myDate.getUTCHours(),
    myDate.getUTCMinutes(),
    myDate.getUTCSeconds()
  );

}

export const UTCText = "(in UTC)";

export const lotStatus = [
  {
    label: "Sold",
    value: 1,
  },
  {
    label: "Passed",
    value: 2,
  },
  {
    label: "Buy Back",
    value: 3,
  },
  {
    label: "Non Payer",
    value: 4,
  },
  {
    label: "Pending",
    value: 5,
  }
]

// export const tourSteps = [
//   {
//     selector: ".first-step",
//     content: "We’re excited to introduce a new feature that allows you to select an AUCTION and have its effects reflected across all pages.",
//   },
// ];

// Mode for download custom report
export const customReports = [
  {
    label: "Paid Template",
    value: 1,
    selected:true
  },
  {
    label: "Balance Due Template",
    value: 2,
    selected:false
  },
  // Commented for now
  // {
  //   label: "Non - Paid Template",
  //   value: 3,
  //   selected:false
  // },
  // {
  //   label: "Late Fee Template",
  //   value: 4,
  //   selected:false
  // },
]

export const templateTypes = {
  auction:1,
  sms:2,
  report:3,
  invoice:4,
  seller:5,
  salesRep:6,
  inventory:7,
  salesRecord:8,
  customer:9,
  contactUs:10,
  appraisal:11,
  partners:12,
  authorization:13,
  email:14,
}