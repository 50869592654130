import Tippy from "@tippyjs/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GETALLCATEGORIES,
  GETCOMMONVALUE,
  GOTOLOTNOCTRLNO,
} from "../../../common/apiConstatnts";
import { Breadcrumb } from "../../../common/interface";
import * as routeConstant from "../../../common/routeConstants";
import { decryptId, encryptId } from "../../../Encryption/Encryption";
import { setStep } from "../../../store/actions/AuctionAction";
import {
  city,
  country,
  globalCategories,
  state
} from "../../../store/actions/CommonAction";
import {
  setInventoryInfoActive,
  setLotId,
  setMode
} from "../../../store/actions/Inventory";
import InnerHeaderTab from "../../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../../Sidebar/Sidebar";
import Auto from "./Auto";
import CommCostBidDown from "./CommCostBidDown";
import DocUrl from "./DocUrl";
import LotDocument from "./LotDocument/LotDocument";
import Lots from "./Lots";
import Notes from "./Notes";
import RealEstate from "./RealEstate";
import Silent from "./Silent";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../../common/HelmetWrapper";
import Address from "./Lots/Address";
import Media from "./Lots/LogosPhotos/Media";
import TitleAssignment from "./TitleAssignment";

const NewInventory = () => {
  const params = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const type = location.state?.type ?? null;
  const ID = params.id ? decryptId(params.id) : 0;
  const typeFromStore = useSelector((state: any) => state.inventory.mode);
  const [modeType] = useState<any>(type === null ? typeFromStore : type)
  const activeTab = useSelector((state: any) => state.inventory.activeTab);
  const lotId = useSelector((state: any) => state.inventory.lotId);
  const auctionData = useSelector((state: any) => state.inventory.auctionData);
  const dataForDisplayLotNo = useSelector((state: any) => state.inventory.generatedLotCtrlRec);
  const nextLotIdFromStore = useSelector((state: any) => state.inventory.nextLotId)
  const prevLotIdFromStore = useSelector((state: any) => state.inventory.prevLotId)
  const totalLotsFromStore = useSelector((state: any) => state.inventory.totalLots)
  const indexFromStore = useSelector((state: any) => state.inventory.index)
  // const auctionId = lotPage?.auctionId;
  const getUrl = window.location.pathname;
  const url = window.location.pathname;
  const checkMode = url.split("/")[2];
  const splitNewConst = routeConstant.ADDNEWINVENTORY.split("/")[2];
  const splitEditConst = routeConstant.UPDATEINVENTORY.split("/")[2];
  const InvTypeText = checkMode === splitNewConst ? "New Inventory" : checkMode === splitEditConst ? "Edit Inventory" : "Clone Inventory";
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = () => {
    let check: any = document.getElementById('type_true_0')
    if (check) {
      check.checked = true
    }
    setDropdownOpen(!dropdownOpen);
  };

  const radioKey = [
    { label: "Lot #", value: 1, selected: true },
    { label: "Ctrl #", value: 2, selected: false },
  ];

  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.INVENTORY },
    { label: "Inventory", url: routeConstant.INVENTORY },
    { label: InvTypeText, url: "" },
  ];

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleChange = (key: any) => {
    setLoading(true);
    dispatch(setStep(key));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }; 

  const getAllCategoriesList = () => {
    axios.get(`${GETALLCATEGORIES}?pageNumber=-1`, config).then(
      (response) => {
        dispatch(globalCategories(""));
        const data = response?.data?.data?.data;
        dispatch(globalCategories(data));
      }
    );
  }

  const CountryStateCity = () => {
    dispatch(country(""));
    dispatch(state(""));
    dispatch(city(""));

    axios.get(`${GETCOMMONVALUE}?filter=Country&companyId=${localStorage.getItem("companyId")}`, config).then((response) => {
      dispatch(country(response.data.data));
    }).catch((error) => {
      handleError(error)
    });

    axios.get(`${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem("companyId")}`, config).then((response) => {
      dispatch(state(response.data.data));
    }).catch((error) => {
      handleError(error)
    });

    axios.get(`${GETCOMMONVALUE}?filter=City&companyId=${localStorage.getItem("companyId")}`, config).then((response) => {
      dispatch(city(response.data.data));
    }).catch((error) => {
      handleError(error)
    });
  };

  useEffect(() => {
    CountryStateCity();
    getAllCategoriesList();
    // getLotbyId(params.id);
  }, []);

  useEffect(() => {
    if (modeType !== null) {
      dispatch(setMode(modeType))
    }
    if (ID) {
      dispatch(setLotId(ID))
    }
  }, [modeType])

  const handlePrevLot = () => {
    dispatch(setStep("lots"));
    dispatch(setInventoryInfoActive({ activeTab: "lotsPage" }));
    if (prevLotIdFromStore) {
      dispatch(setLotId(prevLotIdFromStore))
      navigate(`${routeConstant.UPDATEINVENTORY}/${encryptId(prevLotIdFromStore)}`, { state: { type: "edit" } });
    }
  };

  const handleNextLot = () => {
    dispatch(setStep("lots"));
    dispatch(setInventoryInfoActive({ activeTab: "lotsPage" }));
    if (nextLotIdFromStore) {
      dispatch(setLotId(nextLotIdFromStore))
      navigate(`${routeConstant.UPDATEINVENTORY}/${encryptId(nextLotIdFromStore)}`, { state: { type: "edit" } });
    }
  };

  // API call for redirect to lot no or ctrl no - lot page
  const handleLotPageChange = () => {
    setLoading(true)
    axios.get(`${GOTOLOTNOCTRLNO}?radioKey=${selectedRadio}&input=${inputValue}&auctionId=${auctionData.auctionId}`, config).then((response) => {
      setLoading(false)
      if (response.data.success === 'Success') {
        const lotId = response?.data?.data?.lotId;
        const encryptedId = encryptId(lotId);
        dispatch(setStep("lots"));
        dispatch(setInventoryInfoActive({ activeTab: "lotsPage" }));
        navigate(`${routeConstant.UPDATEINVENTORY}/${encryptedId}`, { state: { type: "edit" } });
        dispatch(setLotId(lotId))
        setDropdownOpen(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      handleError(error);
    });
  };

  const handleRadioChange = (value: any) => {
    setSelectedRadio(value)
  }

  return (
    <>
      <CommonHelmet title={"Inventory - "} name={"inventory"} content={"Inventory"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderTab
          breadCrumb={breadcrumbData}
          title={lotId > 0 ? "" : InvTypeText}
        >
          {lotId > 0 && (
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
              {auctionData.auctionId > 0 &&
                (InvTypeText == 'Edit Inventory' && (
                  <div className="edit-inv-goto w-100 right-0">
                    <div className="next_and_pre_arrow d-flex flex-wrap justify-content-end">
                      <button
                        className="btn btn-outline-primary p-0 ms-auto"
                        onClick={handlePrevLot}
                        disabled={prevLotIdFromStore === 0}
                      >
                        <Tippy
                          content={"Previous Lot"}
                          placement="bottom"
                          arrow={false}
                          offset={[0, 8]}
                        >
                          <i
                            className="fa-solid fa-arrow-left p-2"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </Tippy>
                      </button>
                      <button
                        className="btn btn-outline-primary p-0 ms-0"
                        onClick={handleNextLot}
                        disabled={nextLotIdFromStore === 0}
                      >
                        <Tippy
                          content={"Next Lot"}
                          placement="bottom"
                          arrow={false}
                          offset={[0, 8]}
                        >
                          <i
                            className="fa-solid fa-arrow-right p-2"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </Tippy>
                      </button>
                      <Dropdown
                        className="custom-dropdown ms-0"
                        show={dropdownOpen} // Pass the state to control visibility
                        onToggle={handleToggle} // Handle toggle event
                      >
                        <Dropdown.Toggle
                          className="px-1 dropdown-caret-none"
                          id="dropdown-autoclose-true"
                          variant="none"
                        >
                          <span
                            className="menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0"
                            onClick={() => {
                              setSelectedRadio(1);
                              setInputValue("");
                            }}
                          >
                            <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant="none"
                          className="p-0 dropdown-menu-end navbar-dropdown-caret shadow border border-300"
                        >
                          <div className="card position-relative border-0 top_arrow">
                            <Dropdown.Header className="border-bottom">
                              Go to
                            </Dropdown.Header>
                            <div className="pt-2 ps-3 pe-3 pb-3">
                              <div className="d-flex gap-3">
                                {radioKey.map((item: any, index: any) => {
                                  return (
                                    <div className="d-flex">
                                      <input
                                        type="radio"
                                        className="form-check-input me-2"
                                        name="radioKey"
                                        value={item.value}
                                        id={`type_true_${index}`}
                                        onClick={() => handleRadioChange(item.value)}
                                        defaultChecked={item.selected}
                                      />
                                      <label htmlFor={`type_true_${index}`}>
                                        {item.label}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="d-flex align-items-center mt-3">
                                <input
                                  className="form-control form-icon-input me-2"
                                  placeholder="Lot #/Ctrl #"
                                  name="inputValue"
                                  value={inputValue}
                                  onChange={(e: any) =>
                                    setInputValue(e.target.value)
                                  }
                                />
                                <button
                                  className="d-flex btn btn-primary ps-2 pe-2 align-items-center"
                                  onClick={handleLotPageChange}
                                  disabled={!inputValue}
                                >
                                  Go
                                  <i className="fa-solid fa-arrow-right mt-1 ms-1"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className="fs-12 fw-bold text-end me-2">
                        Lot #{dataForDisplayLotNo.displayLotNo} - Lot Index <span>{indexFromStore}</span> out of
                        <span>{totalLotsFromStore}</span>
                      </span>
                    </div>
                  </div>
                ))}
              <h4>{InvTypeText}</h4>
              {/* {auctionData?.auctionTitle && <span className="fw-bold text-primary badge badge-dpa status-sold">Auction: {auctionData.auctionCode} - {auctionData?.auctionTitle}</span>} */}
            </div>
          )}
          <Tabs defaultActiveKey={activeTab} className="nav-underline" activeKey={activeTab} onSelect={(key: any) => handleChange(key)}>
            <Tab eventKey="lots" title="Lot"></Tab>
            <Tab eventKey="address" title="Address" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="commCost" title="Commissions" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="auto" title="Auto" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="titleAssignment" title="Title Assignment" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="realEstate" title="Real Estate" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="silent" title="Silent" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="docURls" title="Media" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="notes" title="Notes" disabled={!(lotId > 0)}></Tab>
            <Tab eventKey="lotDoc" title="Internal Documents" disabled={!(lotId > 0)}></Tab>
          </Tabs>
        </InnerHeaderTab>

        <div className="container-fluid for-fix-bottom-space">
          {activeTab == "lots" || activeTab == "2" ? (
            <Lots InvTypeText={InvTypeText} />
          ) : activeTab == "address" ? (
            <Address />
          ) : activeTab == "auto" ? (
            <Auto />
          ) : activeTab == "realEstate" ? (
            <RealEstate />
          ) : activeTab == "silent" ? (
            <Silent />
          ) : activeTab == "docURls" ? (
                      <Media />
          ) : activeTab == "lotDoc" ? (
            <LotDocument />
          ) : activeTab === "notes" ? (
            <Notes />
          ) : activeTab === "titleAssignment" ? (
            <TitleAssignment />
          ) : activeTab === "commCost" ? (
            <CommCostBidDown />
          ) : (
            <></>
            // <Lots/>
          )}
        </div>
      </div>
    </>
  );
};
export default NewInventory;
