import React from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import * as routeConstants from '../../common/routeConstants'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import { Breadcrumb } from '../../common/interface'
import logo from '../../assets/img/dpa-logo.jpg'

const Terms = () => {
        const breadcrumbData: Breadcrumb[] = [
            { label: 'Home', url: routeConstants.DASHBOARD },
            { label: 'Terms', url: "" }
        ]
        
        const navigate = useNavigate()
        const goback =()=>{
            navigate(routeConstants.DASHBOARD)
        }

    return (
         <main className="">
            <section className="pt-4 pb-5 bg_light privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className='p-4 text-center'>
                                <img src={logo} className='p-logo' />
                            </div>
                        </div>
                        <div className='col-12 mb-5'>
                            <div className='p-3 bg-light'>
                                <BreadCrumbs data={breadcrumbData} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex flex-wrap content_pages">
                                <h2 className="text-dark fs-26 mb-4">Terms and Conditions</h2>
                                <p>By using this website, you agree to allow us to use the contact information you provide for SMS and email marketing purposes. We may send promotional messages, updates, and other marketing content to your phone number or email address, as provided by you. You can unsubscribe from our marketing communications at any time by following the instructions provided in the message.</p>
                                <p>
                                    <span className="location_in">DPA Auctions<br />PO Box 654<br />Fremont, NE 68025</span><br /><br />
                                    <Link to="mailto:marketing.info@dpaauctions.com">Marketing.info@dpaauctions.com</Link><br /><br />
                                    <Link to="tel: 4850-3638-7034">4850-3638-7034</Link>
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <button type="button" className="btn btn-outline-primary" onClick={goback} ><span> Go Back to Home </span></button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
export default Terms;