import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { ERROR_MESSAGES, trimObjectValues } from "../../../../Utils/Utils";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import { GETLOTREALESTATERECORDBYID, UPDATELOTREALESTATERECORD } from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setCloneTab, setCreateTab, setInventoryInfoActive, setStep } from "../../../../store/actions/Inventory";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { LOTRECORDS } from "../../InventoryConstants";

const Records = () => {
    const dispatch = useDispatch()
    const lotId = useSelector((state: any) => state.inventory?.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const mode = useSelector((state: any) => state.inventory.mode)
    const reCountry = useSelector((state: any) => state?.global?.country);
    const reState = useSelector((state: any) => state?.global?.state);
    const reCity = useSelector((state: any) => state?.global?.city);
    const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
    const createTabs = useSelector((state: any) => state.inventory.createTabs)

    const initialData = {
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        isDelete: false,
        lotRealEstateRecordId: 0,
        lotId: mode === "clone" ? cloneLotId : lotId,
        add1: '',
        address2: '',
        city1: '',
        country1: '',
        state1: '',
        zip: '',
        zipCode: '',
        county1: '',
        // owner: '',
        // ownerAddress: '',
        // ownerCity: '',
        // ownerState: '',
        // ownerCountry: '',
        // ownerZipCode: '',
        // startDateTime: '',
        // endDateTime: '',
        latitude: '',
        longitude: '',
    }

    const [country, setCountry] = useState([])
    const [state, setState] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [ownerState, setOwnerState] = useState([]);
    const [ownerCity, setOwnerCity] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [ErrorState, setErrorState] = useState<any>(false);
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [ownerAddress, setOwnerAddress] = useState('');
    const [remainingCountAdd, setRemainingCountAdd] = useState(250);
    const [remainingCountAdd2, setRemainingCountAdd2] = useState(250);
    const [isChanged, setIsChanged] = useState(false);
    const [address, setAddress] = useState('');
    const [countryCode, setCountryCode] = useState('us');
    const [prevCountryCode, setPrevCountryCode] = useState('us');
    const [isAddressFilled, setIsAddressFilled] = useState(false);
    const [btnText, setBtnTxt] = useState<any>("")

    const updateCountryCode = (newAddress: any, code: any) => {
        /* if (initialCountryName && newAddress !== initialCountryName) {
            setCountryCode(code);
            setValue('mobile', '');
        } else {
            return;
        } */
    }

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        setIsAddressFilled(true);
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        } else if (e.target.name === 'addressLine2') {
            setRemainingCountAdd2(remaining);

        }
    }

    /* const handleChangeAddressLineOwner = (e: any) => {
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        setOwnerAddress(inputText);
        setValue('ownerAddress', inputText)
    } */

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const schema: any = yup.object().shape({
        add1: yup.string().required('Address Line 1 is required.'),
        city1: yup.string().required("City is required."),
        state1: yup.string().required("State is required."),
        country1: yup.string().required('Country is required.'),
        zip: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),

        /* owner: yup.string()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid Name').max(49, 'Please enter upto 50 characters'),
        ownerAddress: yup.string()
            .required('Owner Addres is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        ownerCity: yup.string().required('Owner City is required.'),
        ownerState: yup.string().required('Owner State is required.'),
        ownerCountry: yup.string().required('Owner Country is required'),
        ownerZipCode: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        startDateTime: yup.string()
            .required('Start Date Time is required')
            .test('valid-datetime', 'Invalid datetime', function (value) {
                const parsedDate: any = new Date(value);
                const currentDate: any = new Date()
                if (parsedDate.getFullYear() < 2000 ||
                    parsedDate.getFullYear() > 2500 || !lotId && parsedDate - currentDate < 0) {
                    return this.createError({
                        message: 'Please Enter valid Date.',
                    });
                }
                return !isNaN(parsedDate);
            }),
        endDateTime: yup.string()
            .required('End Date Time is required')
            .test('valid-datetime', 'End Time must be greater than Start Time', function (value) {
                const parsedDate: any = new Date(value);
                if (parsedDate.getFullYear() > 2500) {
                    return this.createError({
                        message: 'Please Enter valid Date.',
                    });
                }
                const startDateTime = Date.parse(this.parent.startDateTime);
                const differenceInMinutes = (parsedDate - startDateTime) / (1000 * 60);
                if (!this.parent.startDateTime) {
                    return this.createError({
                        message: 'Please select Start Date Time first',
                    });
                }
                if (!isNaN(parsedDate) && parsedDate > startDateTime) {
                    if (differenceInMinutes >= 5) {
                        return true;
                    } else {
                        return this.createError({
                            message: 'Keep a maximum difference of 5 minutes',
                        });
                    }
                } else {
                    return this.createError({
                        message: 'End Time must be greater than Start Time',
                    });
                }
            }) */
    })

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        control,
        clearErrors,
        trigger,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialData, mode: 'onChange' });

    useEffect(() => {
        setLoading(true)
        setCountry(reCountry ?? [])
        setState(reState ?? [])
        setCity(reCity ?? [])
        setOwnerCity(reCity ?? [])
        setOwnerState(reState ?? [])
    }, [])


    const onSubmit = (data: any) => {
        setLoading(true)
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const dataOriginal: any = getValues()
            const recordData = trimObjectValues(dataOriginal);

            const payload = {
                lotId: mode === "clone" ? cloneLotId : lotId,
                lotRealEstateRecordId: recordData.lotRealEstateRecordId,
                latitude: recordData.latitude.toString(),
                longitude: recordData.longitude.toString(),
                companyId: localStorage.getItem('companyId'),
                addressLine1: recordData.add1,
                addressLine2: recordData.address2,
                city: parseInt(cityId),
                country: parseInt(countryId),
                state: parseInt(stateId),
                county: parseInt(countyId),
                countyName: data?.county1,
                zipCode: recordData.zip.toString(),
                isActive: true,
                isDelete: false,
                /* startDateTime: moment(recordData.startDateTime).format('YYYY-MM-DDTHH:mm'),
                endDateTime: moment(recordData.endDateTime).format('YYYY-MM-DDTHH:mm'),
                owner: recordData.owner,
                ownerAddress: recordData.ownerAddress,
                ownerCity: ownerCityId,
                ownerState: ownerStateId,
                ownerZipCode: recordData.ownerZipCode.toString(),
                ownerCountry: ownerCountryId,
                ownerCountryName: data.ownerCountry,
                ownerCityName: data.ownerCity,
                ownerStateName: data.ownerState, */
            }

            axios.post(UPDATELOTREALESTATERECORD, payload, { ...config }).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    if (mode === "clone") {
                        dispatch(setCloneTab([...clonedTabs, LOTRECORDS]))
                    }
                    if (mode === "create") {
                        dispatch(setCreateTab([...createTabs, LOTRECORDS]))
                    }
                    nextStep();
                    toast.success(InventoryMessages.recordUpdated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    })
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });

        })
    }

    useEffect(() => {
        const id = mode === "clone" ? cloneLotId : lotId
        axios.get(`${GETLOTREALESTATERECORDBYID}?lotId=${id}`, { ...config }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                return;
            } else {
                const data = response.data.data;
                setAddress(data.addressLine1)
                setAddressLine2(data.addressLine2)
                reset({
                    ...data,
                    add1: data?.addressLine1,
                    address2: data?.addressLine2,
                    city1: data?.cityName,
                    state1: data?.stateName,
                    country1: data?.countryName,
                    zip: data?.zipCode,
                    county1: data?.countyName
                })
            }
        })
    }, []);

    const nextStep = () => {
        // dispatch(setInventoryInfoActive({ activeTab: "agents" }))
        dispatch(setInventoryInfoActive({ activeTab: "deed/location" }))
    }

    const handlePrevious = () => {
        dispatch(setStep('titleAssignment'));
    }

    useEffect(() => {
        clearErrors(['city1', 'country1', 'state1', 'zipCode', 'add1'])
    }, [address])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTRECORDS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTRECORDS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {loading && <div className="loader-wrap">
                        <img src={`${localStorage.getItem('globalLoader')}`} />
                    </div>}
                    <div className="row g-3 mb-3">
                        {/* <div className="col-lg-6">
                            <label className="form-label">Start Date and Time <sup className="text-danger">*</sup></label>
                            <Controller
                                name="startDateTime"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="date_icon">
                                        <DateTime
                                            onChange={onChange}
                                            dateFormat="MM/DD/y"
                                            timeFormat="h:mm A"
                                            value={value}
                                            closeOnSelect={true}
                                            inputProps={
                                                {
                                                    placeholder: 'Select Date and Time',
                                                }} />
                                    </div>
                                )}
                            />
                            {errors.startDateTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.startDateTime.message}</span>}

                        </div>
                        <div className="col-lg-6">
                            <label className="form-label">End Date and Time<sup className="text-danger">*</sup></label>
                            <Controller
                                name="endDateTime"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="date_icon">
                                        <DateTime
                                            onChange={onChange}
                                            dateFormat="MM/DD/y"
                                            timeFormat="h:mm A"
                                            value={value}
                                            closeOnSelect={true}
                                            inputProps={
                                                {
                                                    placeholder: 'Select Date and Time',
                                                }} />
                                    </div>
                                )}
                            />
                            {errors.endDateTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.endDateTime.message}</span>}

                        </div> */}
                        {/* <div className="col-lg-6">
                            <label className="form-label">Address Line 1<sup className="text-danger">*</sup> </label>
                            <GooglePlacesWithCSC
                                value={addressLine1}
                                handleChangeAddressLine={handleChangeAddressLine}
                                type="addressLine1"
                                setValue={setValue}
                                setAddressLine1={setAddressLine1}
                                setIsChanged={setIsChanged}
                                setCountry={setCountry}
                                name="addressLine1"
                                zipCodeType="zipCode"
                                latLog={true}
                                remainingCountAdd={remainingCountAdd}
                            />
                            {errors.addressLine1 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.addressLine1.message}</span>}
                        </div>
                        <div className="col-lg-6">
                            <label className="form-label">Address Line 2 </label>
                            <GooglePlaces
                                value={addressLine2}
                                handleChangeAddressLine={handleChangeAddressLine}
                                type="addressLine2"
                                onPlaceSelected={(place: any, type: any) => handlePlaceSelect(place, "addressLine2")}
                                name="addressLine2"
                                remainingCountAdd2={remainingCountAdd2}
                            />
                            {errors.addressLine2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.addressLine2.message}</span>}

                        </div> */}
                        <AddressForm
                            // address={address}
                            errors={errors}
                            onHandleChangeAddressLine={handleChangeAddressLine}
                            register={register}
                            setValue={setValue}
                            setCountryCode={setCountryCode}
                            onAddressChange={updateCountryCode}
                            onSetAddress={setAddress}
                            latLog={true}
                            watch={watch}
                            latLongMap={false}
                            trigger={trigger}

                        />

                        {/* <div className="col-lg-3 col-md-6 mb-3">
                            <label className="form-label">City <sup className="text-danger">*</sup></label>
                            <select className="form-select"  {...register('city')}>
                                <option value="">Select City</option>
                                {city &&
                                    city.map((cityData: any, index: any) => {
                                        return (
                                            <option key={index} value={cityData.objectValueId}>{cityData.objectTypeValue}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.city && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.city?.message}</span>}
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <label className="form-label">State <sup className="text-danger">*</sup></label>
                            <select className="form-select" {...register('state')} >
                                <option value="">Select State</option>
                                {state &&
                                    state?.map((stateData: any, index: any) => {
                                        return (
                                            <option key={index} value={stateData.objectValueId}>{stateData.objectTypeValue}</option>
                                        )
                                    })
                                }
                            </select>
                            {(errors.state || ErrorState) && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.state?.message ?? 'State is required.'}</span>}
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <label className="form-label">Zip Code<sup className="text-danger">*</sup></label>
                            <input type="text" className="form-control" maxLength={12} placeholder="Zip Code" {...register('zipCode')} />
                            {errors.zipCode && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.zipCode.message}</span>}
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <label className="form-label">Country <sup className="text-danger">*</sup></label>
                            <select className="form-select" {...register('country')} >
                                <option value="">Select Country</option>
                                {
                                    country.map((countryData: any, index: any) => {
                                        return (
                                            <option key={index} value={countryData.objectValueId}>{countryData.objectTypeValue}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.country && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.country.message}</span>}
                        </div> */}

                        {/* remove fields as per mail attached file. */}
                        {/* <div className="col-lg-12">
                            <label className="form-label">Owner</label>
                            <input className="form-control form-icon-input"
                                type="text"
                                maxLength={50}
                                placeholder="Owner"
                                {...register('owner')}
                            />
                            {errors.owner && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.owner.message}</span>}
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label">Owner Address <sup className="text-danger">*</sup></label>
                            <GooglePlacesWithCSC
                                value={ownerAddress}
                                handleChangeAddressLine={handleChangeAddressLineOwner}
                                type="ownerAddress"
                                setValue={setValue}
                                // setOwnerAddress={setOwnerAddress}
                                setOwnerAddress={setOwnerAddress}
                                setIsChanged={setIsChanged}
                                setCountry={setCountry}
                                name="ownerAddress"
                                zipCodeType='ownerZipCode'
                                stateKey='ownerState'
                                cityKey='ownerCity'
                                countryKey='ownerCountry'
                            />
                            {errors.ownerAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ownerAddress.message}</span>}
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label" >City<sup className="text-danger">*</sup> </label>

                            <input
                                type="text"
                                placeholder="City"
                                className="w-100 form-control"
                                {...register("ownerCity")}
                            />
                            {errors.ownerCity && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ownerCity.message}</span>}

                        </div>
                        <div className="col-lg-3">
                            <label className="form-label" >State<sup className="text-danger">*</sup> </label>

                            <input
                                type="text"
                                placeholder="State"
                                className="w-100 form-control"
                                {...register("ownerState")}
                            />
                            {errors.ownerState && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ownerState.message}</span>}
                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Zip Code<sup className="text-danger">*</sup></label>
                            <input className="form-control form-icon-input"
                                type="text"
                                maxLength={12}
                                placeholder="Zip Code"
                                {...register('ownerZipCode')}
                            />
                            {errors.ownerZipCode && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ownerZipCode.message}</span>}

                        </div>
                        <div className="col-lg-3">
                            <label className="form-label">Country <sup className="text-danger">*</sup></label>

                            <input
                                type="text"
                                placeholder="Country"
                                className="w-100 form-control"
                                {...register("ownerCountry")}
                            />
                            {errors.ownerCountry && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ownerCountry.message}</span>}
                        </div> */}
                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="submit" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                            <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
                            <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Records;