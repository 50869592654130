import axios from "axios"
import jsPDF from "jspdf"
import moment from "moment"
import { useEffect, useState, useRef } from "react"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import { GETALLSELLERSUMMARY, GETALLAUCTIONLIST } from "../../common/apiConstatnts"
import { UTCdateConverter } from "../../common/constants"
import { Breadcrumb } from "../../common/interface"
import { errorMessages } from "../../common/messageConstants"
import * as routeConstant from '../../common/routeConstants'
import { encryptId } from "../../Encryption/Encryption"
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import { formatCurrency, formatNumber } from "../../Utils/Utils"
import MomentUtil from "../../Utils/MomentUtil"
import html2pdf from 'html2pdf.js';
import handleError from "../../Utils/ApiCommonErrorMessages"
import parsePhoneNumberFromString from 'libphonenumber-js';
import 'jspdf-autotable';
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import {generateAllSellerSummaryPdf, generateAllSellerSummaryCsv} from './ExportReportsPdfCsvFile';

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'All Seller Summary Report', url: routeConstant.ALLSELLERSUMMARY }
]

const AllSellerSummaryReport = () => {
    const momentUtil = MomentUtil.getInstance()
    const [loading, setLoading] = useState(false);
    const [allSellerSummary, setAllSellerSummary] = useState<any[]>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [auctionCode, setAuctionCode] = useState('')
    let AuctionStartDate = allSellerSummary?.length > 0 ? allSellerSummary[0].auctionStartDate : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value == getGlobalAuction.value)

    const tableRef:any = useRef(null);
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const getSellerSummaryData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${GETALLSELLERSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const sellerSumResp = response.data.data;
                if (response?.data?.success === "Fail") {
                    setAllSellerSummary([]);
                    return;
                } else {
                    setAllSellerSummary(sellerSumResp);
                    setLoading(false)
                }
            }).catch((error)=>{
                setLoading(false)
                handleError(error);
            })
        }        
    }

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            // const auction = response.data.map((d: any) => {
            //     const start: any = new Date(d.startDateTime);
            //     const current: any = UTCdateConverter(new Date());
            //     return { ...d, pendingTime: start - current }
            // })
            // const currentAuction = auction.find((obj: any) => obj.pendingTime == Math.min(...auction.filter((d: any) => d.pendingTime > 0).map((i: any) => i.pendingTime)));
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });;
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerSummaryData(selectedAuction.value);
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (hammerAuction) {
            setSelectedAuction(hammerAuction);
        } else {
            getAllAuctions();
        }
    }, [hammerAuction]);

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const formatPhoneNumber = (phoneNumber:any) => {
        try {
            const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
            const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
            if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                const countryCode = parsedPhoneNumber.countryCallingCode;
                const nationalNumber = parsedPhoneNumber.nationalNumber;                
                if (countryCode === '1') { 
                    const areaCode = nationalNumber.slice(0, 3);
                    const restOfNumber = nationalNumber.slice(3);
                    return `+1 (${areaCode}) ${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3)}`;
                } else {
                    return `+${countryCode}-${nationalNumber.slice(0, 5)}-${nationalNumber.slice(5)}`;
                }
            }
            return phoneNumber;
        } catch (error) {
            console.error('Error formatting phone number:', error);
            return phoneNumber;
        }
    };

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='All Seller Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                    <div className="col-lg-8">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ? auctionList.find((option: any) => option.value === selectedAuction.value): hammerAuction }
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" onClick={()=>generateAllSellerSummaryCsv(allSellerSummary)}
                                        disabled={allSellerSummary.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3 ms-2" onClick={()=> generateAllSellerSummaryPdf(allSellerSummary)}
                                        disabled={allSellerSummary.length === 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                        {allSellerSummary.length === 0 ?
                            getNoDataView() : 
                            <>
                            <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                <tbody>
                                    <tr>
                                        <td valign="top" style={{ border: 0, width: '100%' }}>
                                        <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>All Seller Summary Report</p>
                                        <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                            <span>  {`Auction #${
                                                selectedAuction 
                                                ? selectedAuction?.label?.split('-')[0] 
                                                : auctionCode 
                                                    ? hammerAuction 
                                                    : hammerAuction?.label?.split('-')[0]
                                            }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                        </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table ref={tableRef} className="w-100 mt-4" cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                    <th style={{ fontSize: '12px' }}><div>#</div></th>
                                    <th style={{ fontSize: '12px' }}><div>Seller Name</div></th>
                                    {/* <th style={{ fontSize: '12px' }}><div>Mobile</div></th> */}
                                    {/* <th style={{ fontSize: '12px' }}><div>Email</div></th> */}
                                    <th style={{ fontSize: '12px' }}><div>Company</div></th>
                                    <th style={{ fontSize: '12px' , textAlign:'center'}}><div>Items</div></th>
                                    <th style={{ fontSize: '12px', textAlign:'right' }}><div>Total Sold</div></th>
                                    </tr>  
                                </thead>
                                <tbody>
                                    {allSellerSummary.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ fontSize: '11px' }}><div>{item.sellerId}</div></td>
                                        <td style={{ fontSize: '11px' }}><div>(#{item.sellerId}) {item.sellerName}</div></td>
                                        {/* <td style={{ fontSize: '11px' }}><div>{formatPhoneNumber(item.mobile)}</div></td> */}
                                        {/* <td style={{ fontSize: '11px' }}><div>{item.email}</div></td> */}
                                        <td style={{ fontSize: '11px' }}><div>{item.company ? item.company : '-'}</div></td>
                                        <td style={{ fontSize: '11px', textAlign:'center' }}><div>{item.items ? item.items : '0'}</div></td>
                                        <td style={{ fontSize: '11px', textAlign:'right' }}><div>{item.items == 0 ? '$0.00' : `$${formatAmount(item.totalSold)}`}</div></td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            </>
                        }                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllSellerSummaryReport