import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import XLSX from 'xlsx-js-style'
import { FICTITIOUSBIDDER } from "../../common/apiConstatnts"
import { formatPhoneNumber } from "../../common/constants"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import handleError from "../../Utils/ApiCommonErrorMessages"
import MomentUtil from "../../Utils/MomentUtil"
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import { generateFictitiousBidderCsv, generateFictitiousBidderPdf } from "./ExportReportsPdfCsvFile"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Fictitious Bidders Report', url: routeConstant.FICTITIOUSBIDDEREPORT }
]

const FictitiousBidderReport = () => {
    const [loading, setLoading] = useState(false);
    const [originalBidderData, setOriginalBidderData] = useState<any>([])
    const [bidderData, setbidderData] = useState<any[]>([]);
    const [filterBidder, setFilterBidder] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const PER_PAGE = 10;
    const pageCount = Math.ceil(bidderData.length / PER_PAGE);

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = originalBidderData?.filter((x: any) =>
            x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) || x.email?.toLocaleLowerCase()?.toString()?.includes(keyword) ||
            x.cityName?.toLocaleLowerCase()?.toString()?.includes(keyword) || x.countryName?.toLocaleLowerCase()?.toString()?.includes(keyword) ||
            x.stateName?.toLocaleLowerCase()?.toString()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...bidderData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setbidderData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterBidder(firstSet)
    }

    const getBidderReportData = () => {
        setLoading(true)
        axios.post(FICTITIOUSBIDDER).then((response: any) => {
            const resData = response?.data?.data
            if (response?.data?.success === "Fail") {
                setFilterBidder([]);
                setLoading(false)
                return;
            } else {
                paginateData(resData)
                setOriginalBidderData(resData)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(13).text(`Fictitious-Bidder-Report`, 15, 20);
        doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Bidder Name', 'Email', 'Address', 'City', 'State', 'Country', 'Zip Code', 'Phone']]
        const data = bidderData.map((data: any) => {
            return {
                bidderName: `${data?.firstName} ${data?.lastName}`,
                email: data.email || "-",
                address: data.addressLine1 || "-",
                city: data.cityName || "-",
                state: data.stateName || "-",
                country: data.countryName || "-",
                zipCode: data.zipCode ? data.zipCode : '-',
                phone: data.mobile ? formatPhoneNumber(data.mobile) : '-',
            };
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Fictitious-Bidder-Report.pdf`);
    }

    const generateExcelWithTitle = () => {

        let data = bidderData?.map((data: any) => {
            return {
                bidderName: `${data?.firstName} ${data?.lastName}`,
                email: data.email || "-",
                address: data.addressLine1 || "-",
                city: data.cityName || "-",
                state: data.stateName || "-",
                country: data.countryName || "-",
                zipCode: data.zipCode ? data.zipCode : '-',
                phone: data.mobile ? formatPhoneNumber(data.mobile) : '-',
            };
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Fictitious-Bidder-Report'],
            [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Bidder Name', 'Email', 'Address', 'City', 'State', 'Country', 'Zip Code', 'Phone'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        ws['!cols'] = [
            { wpx: 120 },
            { wpx: 200 },
            { wpx: 270 },
            { wpx: 120 },
            { wpx: 100 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 150 },
        ];

        const headerStyle = {
            font: { bold: true, sz: 12 },
            alignment: { horizontal: 'center', vertical: 'center' },
        };

        const headerRowIndex = 3;
        const headerCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

        headerCells.forEach((cell) => {
            ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
        });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Fictitious_Bidder_Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = bidderData?.slice(offset, offset + PER_PAGE)
        setFilterBidder(nextSet)
    }, [currentPage])

    useEffect(() => {
        getBidderReportData()
    }, [])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Fictitious Bidders Report'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                                {/* <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button> */}
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => generateFictitiousBidderCsv(bidderData)} disabled={bidderData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateFictitiousBidderPdf(bidderData)} disabled={bidderData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Auction Code - Title</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Bidder Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Email</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                        </div>
                                                    </div>
                                                </th>

                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Address</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>City</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>State</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Country</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Zip Code</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Phone Number</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Alternate Number</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Notes</span>

                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterBidder?.length > 0 ? filterBidder.map((fData: any, fIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                        <td className="align-middle  p-2">
                                                            {fData?.auctionCode}-{fData?.auctionTitle}
                                                        </td>
                                                        <td className="align-middle  p-2">
                                                            {fData?.firstName} {fData?.lastName}
                                                        </td>
                                                        <td className="align-middle  p-2">{fData.email ? fData.email : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.addressLine1 ? fData.addressLine1 : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.cityName ? fData.cityName : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.stateName ? fData.stateName : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.countryName ? fData.countryName : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.zipCode ? fData.zipCode : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.mobile ? formatPhoneNumber(fData.mobile) : '-'}</td>
                                                        <td className="align-middle  p-2">{fData.phone ? formatPhoneNumber(fData.phone) : '-'}</td>

                                                        <td className="align-middle  p-2">{fData.notes ? fData.notes : '-'}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={13}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterBidder?.length > 0 && (

                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FictitiousBidderReport