import React, { useState, useEffect, useRef } from "react"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import axios from "axios"
import { toast } from "react-toastify"
import { SELLERPAYMENTSUMMARY, GETALLAUCTIONLIST } from "../../common/apiConstatnts"
import Sidebar from "../Sidebar/Sidebar"
import InnerHeader from "../InnerHeader/InnerHeader"
import { errorMessages } from "../../common/messageConstants"
import Select from 'react-select';
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { Link, useNavigate } from 'react-router-dom';
import { encryptId } from "../../Encryption/Encryption"
import { useDispatch, useSelector } from "react-redux"
import MomentUtil from "../../Utils/MomentUtil"
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment"
import handleError from "../../Utils/ApiCommonErrorMessages"
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { generateSellerPaymentSummaryCsv, generateSellerPaymentSummaryPdf } from "./ExportReportsPdfCsvFile"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Payment Summary Report', url: routeConstant.SELLERPAYMENTSUMMARY }
]

const SellerPaymentsSummaryReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
    const [loading, setLoading] = useState(false);
    const [sellerPaymentsData, setSellerPaymentsData] = useState<any[]>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [auctionCode, setAuctionCode] = useState('')
    let grandTotalAmountPaid = 0;
    let grandTotalAmountRecived = 0;

    const [grandTotalAmountPaid1, setGrandTotalAmountPaid] = useState(0);
    const [grandTotalAmountRecived1, setGrandTotalAmountRecived] = useState(0);
    const tableRef:any = useRef(null);
    let AuctionStartDate = sellerPaymentsData?.length > 0 ? sellerPaymentsData[0].auctionStartDate : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    useEffect(() => {
        let totalPaid = 0;
        let totalRecived = 0;

        sellerPaymentsData.forEach(item => {
            let itemTotalPaid = 0;
            let itemTotalRecived = 0;

            item.lstSellerAdjust.forEach((transaction:any) => {
                itemTotalPaid += transaction.amtPaid;
                itemTotalRecived += transaction.amtRecived;
            });

            totalPaid += itemTotalPaid;
            totalRecived += itemTotalRecived;
        });

        setGrandTotalAmountPaid(totalPaid);
        setGrandTotalAmountRecived(totalRecived);
    }, [sellerPaymentsData]);

    const getSellerAdjustData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){       
        axios.get(`${SELLERPAYMENTSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                setSellerPaymentsData([]);
                return;
            } else {
                const apiData = response.data.data;
                setSellerPaymentsData(apiData)
                
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }
    }
    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });;
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerAdjustData(selectedAuction?.value)
        }
    }, [selectedAuction])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatNumber = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };
    
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Payment Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                    <div className="col-lg-8">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" onClick={()=> generateSellerPaymentSummaryCsv(sellerPaymentsData)}
                                       disabled={sellerPaymentsData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3 ms-2" onClick={()=> generateSellerPaymentSummaryPdf(sellerPaymentsData)}
                                    disabled={sellerPaymentsData.length === 0 }>
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                    <div className="col-12 mt-4 mb-4 forpdf_only">
                        {sellerPaymentsData.length === 0 ? 
                        getNoDataView(): 
                        <>
                         <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                            <tbody>
                            <tr>
                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Seller Payment Summary Report</p>
                                <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                    <span>{`Auction #${
                                        selectedAuction 
                                        ? selectedAuction?.label?.split('-')[0]
                                        : auctionCode 
                                            ? hammerAuction 
                                            : hammerAuction?.label?.split('-')[0]
                                    }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                </p>
                                </td>
                            </tr>
                            </tbody>
                        </table> 
                        <table ref={tableRef} className="w-100 mt-4" cellPadding="0" cellSpacing="0" style={{ borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                            <th style={{ fontSize: '12px' }}>#</th>
                            <th style={{ fontSize: '12px' }}>Seller Name</th>
                            <th style={{ fontSize: '12px', textAlign: 'right' }}>Comm</th>
                            <th style={{ fontSize: '12px', textAlign: 'right' }}>Adjust</th>
                            <th style={{ fontSize: '12px', textAlign: 'right' }}>Date</th>
                            <th style={{ fontSize: '12px', textAlign: 'right' }}>Amt Paid</th>
                            <th style={{ fontSize: '12px', textAlign: 'right' }}>Amt Rec'd</th>
                            <th style={{ fontSize: '12px', textAlign: 'left' }}>Type</th>
                            <th style={{ fontSize: '12px', textAlign: 'left' }}>Check #</th>
                            <th style={{ fontSize: '12px', textAlign: 'left' }}>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sellerPaymentsData?.map((item:any, index:any) => {
                                let totalAmountPaid = 0;
                                let totalAmountRecived = 0;
                                item.lstSellerAdjust.forEach((transaction:any) => {
                                  totalAmountPaid += transaction.amtPaid;
                                  totalAmountRecived += transaction.amtRecived;
                                });
                                grandTotalAmountPaid += totalAmountPaid;
                                grandTotalAmountRecived += totalAmountRecived;
                                return(
                                    <React.Fragment key={index}>
                                        <tr >
                                            <td style={{ fontSize: '11px' }}>{item.sellerId}</td>
                                            <td style={{ fontSize: '11px' }}>{item.sellerName}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>${item.commTotal ? formatNumber(item.commTotal) : '0.00'}</td>
                                            <td style={{ fontSize: '11px', textAlign: 'right' }}>${item.adjust ? formatNumber(item.adjust) : '0.00'}</td>
                                            <td colSpan={6}></td>
                                        </tr>
                                        {item.lstSellerAdjust?.map((transaction:any, i:any) => {
                                            return (
                                            <tr key={i}>
                                                {i === 0 && (
                                                <td style={{ fontSize: '11px' , backgroundColor:'#ffff'}} colSpan={4} rowSpan={item.lstSellerAdjust.length}></td>
                                                )}
                                                <td style={{ fontSize: '11px', textAlign: 'right' }}>{moment(transaction.date).format('MM/DD/YYYY')}</td>
                                                <td style={{ fontSize: '11px', textAlign: 'right' }}>${formatNumber(transaction.amtPaid)}</td>
                                                <td style={{ fontSize: '11px', textAlign: 'right' }}>${formatNumber(transaction.amtRecived)}</td>
                                                <td style={{ fontSize: '11px', textAlign: 'left' }}>{transaction.typeString}</td>
                                                <td style={{ fontSize: '11px', textAlign: 'left' }}>{transaction.check === 0 ? '-' : transaction.check}</td>
                                                <td style={{ fontSize: '11px', textAlign: 'left' }}>{transaction.user == ''? transaction.user : '-'}</td>
                                            </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                            <th style={{ fontSize: '9px' }} colSpan={4}></th>
                            <th style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>Totals</th>
                            <th style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>${formatNumber(grandTotalAmountPaid)}</th>
                            <th style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'right' }}>${formatNumber(grandTotalAmountRecived)}</th>
                            <th colSpan={4}></th>
                            </tr>
                        </tfoot>
                        </table>
                        </>
                        }                      
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerPaymentsSummaryReport