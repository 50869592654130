import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import IconAuction from "../../../../assets/sidebar-icons/auction.svg";
import IconSeller from "../../../../assets/sidebar-icons/sellers.svg";
import { GETAUCTIONSELLERMAPPING, GETSELLERINFOFORAUCTION, ISSELLERASSOCIATED, UPDATEAUCTIONSELLER } from "../../../../common/apiConstatnts";
import { CommonMessages } from "../../../../common/messageConstants";
import { setAuctionDetailActive, setAuctionInfoActive, setCloneTab, setCreateTab, setStep } from "../../../../store/actions/AuctionAction";
import { AUCTIONSELLER } from "../../AuctionConstants";
import MasterTable from "./MasterTable";
import * as routeConstant from '../../../../common/routeConstants'
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { setActiveTab } from "../../../../store/actions/Seller";

const AuctionSeller = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [sellerTable1, setSellerTable1] = useState<any[]>([]);
    const [sellerTable2, setSellerTable2] = useState<any>([]);
    const auctionId = useSelector((state: any) => state?.auction.auctionId);
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState<any>(false);
    const [isSecondTableAnyCheckbox, setIsSecondTableAnyCheckbox] = useState<any>(false);
    const [allSelectedData, setAllSelectedData] = useState<any>([]);
    const [removedSellers, setRemovedSellers] = useState<any>([]);
    const masterTableRef = useRef<any>(null); // Create a ref for the MasterTable component
    const auctionSellerTableRef = useRef<any>(null); // Create a ref for the MasterTable component
    const [btnTxt, setBtnTxt] = useState<any>("")

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const getAllSellers = () => {
        setLoading(true);
        const pageNumber = -1;
        axios.get(`${GETSELLERINFOFORAUCTION}?auctionId=${auctionId}&pageNumber=${pageNumber}`, config).then((response: any) => {
            const sellerdata = response?.data?.data?.data;
            const temp = allSelectedData;
            const selectedSellerIds = temp?.map((selectedSeller: any) => selectedSeller?.sellerId);
            const filteredSellers = sellerdata?.filter((seller: any) => {
                return !selectedSellerIds?.includes(seller?.sellerId);
            });
            setSellerTable1(filteredSellers);
            setTimeout(() => {
                setLoading(false);
            }, 500)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    useEffect(() => {
        getAllSellers();
    }, []);

    useEffect(() => {
        setLoading(true)
        getAuctionBasedOnSaleRep();
    }, [])

    const moveItemsToAuctionSeller = () => {
        const selectedItems = masterTableRef?.current?.getSelectedItems(); // Access selected items using the ref
        const itemsToMove = Object.keys(selectedItems)
            .filter((itemId: any) => selectedItems[itemId])
            .map(itemId => sellerTable1?.find((item: any) => item.sellerId === Number(itemId)));

        setSellerTable2((prevItems: any) => [...(prevItems || []), ...itemsToMove]);
        setSellerTable1(prevItems => prevItems?.filter((item: any) => !itemsToMove?.includes(item)));

        masterTableRef?.current?.resetCheckedItems()
    };

    const moveItemsToMaster = () => {

        const selectedItems = auctionSellerTableRef?.current?.getSelectedItems();

        const itemsToMove = Object.keys(selectedItems)
            .filter((itemId: any) => selectedItems[itemId])
            .map(itemId => sellerTable2?.find((item: any) => item.sellerId === Number(itemId)));

        const itemsWithAuctionId: any[] = [];
        const itemsWithoutAuctionId: any[] = [];

        itemsToMove.forEach(item => {
            if (item && item.auctionId) {
                itemsWithAuctionId.push(item);
            } else {
                itemsWithoutAuctionId.push(item);
            }
        });

        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to remove this seller, from this auction',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove them!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (itemsWithAuctionId.length > 0) {
                    handleSellerRemoval(itemsWithAuctionId);
                }

                setSellerTable1((prevItems: any) => [...(prevItems || []), ...itemsWithoutAuctionId]);
                setSellerTable2((prevItems: any) => prevItems?.filter((item: any) => !itemsWithoutAuctionId.includes(item)));
            } else {
                auctionSellerTableRef?.current?.resetCheckedItems()
            }
        });

    };

    const handleSellerRemoval = (sellers: any[]) => {

        const itemsWithAuctionId: any[] = [];
        const itemsWithoutAuctionId: any[] = [];

        sellers?.forEach(item => {
            if (item && item.auctionId) {
                itemsWithAuctionId.push(item);
            } else {
                itemsWithoutAuctionId.push(item);
            }
        });

        setSellerTable1((prevItems: any) => [...(prevItems || []), ...itemsWithoutAuctionId]);
        setSellerTable2((prevItems: any) => prevItems?.filter((item: any) => !itemsWithoutAuctionId.includes(item)));

        if (itemsWithAuctionId?.length > 0) {
            setLoading(true);

            const payload = itemsWithAuctionId?.map((seller: any) => ({
                sellerId: seller.sellerId,
                auctionId: seller.auctionId
            }));

            axios.post(ISSELLERASSOCIATED, payload, config)
                .then((response) => {
                    setLoading(false);
                    if (response?.data?.data) {
                        const data = response.data.data;
                        const associatedSellerIds: string[] = [];
                        const remainingSellerIds: string[] = [];
                        data.forEach((sellerData: any) => {
                            if (sellerData.message === "This seller is associated with lot") {
                                associatedSellerIds.push(sellerData.sellerId);
                            } else {
                                remainingSellerIds.push(sellerData.sellerId);
                            }
                        });
                        if (associatedSellerIds.length > 0) {
                            const message = `Seller ID ${associatedSellerIds.join(', ')} are associated with a inventory and cannot be deleted`;
                            Swal.fire({
                                title: 'Associated Sellers',
                                text: message,
                                icon: 'warning',
                                confirmButtonColor: "#006800",
                                confirmButtonText: "ok",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    auctionSellerTableRef?.current?.resetCheckedItems()
                                }
                            });
                        }
                        if (remainingSellerIds.length > 0) {
                            removeSeller(remainingSellerIds);
                        }
                    } else {
                        auctionSellerTableRef?.current?.resetCheckedItems()
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error)
                });
        } else {
            auctionSellerTableRef?.current?.resetCheckedItems()
        }


    };

    const openCancelPopUp = (clickedSeller: any) => {

        const itemsToMove = [clickedSeller]

        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to remove this seller, from this auction',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove them!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSellerRemoval(itemsToMove);
            }
            // else {
            //     auctionSellerTableRef?.current?.resetCheckedItems()
            // }
        });
    };

    const removeSeller = (sellerIds: any) => {
        setLoading(true);
        const idsToRemove = Array.isArray(sellerIds) ? sellerIds : [sellerIds];
        setSellerTable2((prevSellerTable2: any) => {
            const updatedSellerTable2 = prevSellerTable2.filter((seller: any) => !idsToRemove.includes(seller.sellerId));
            setAllSelectedData(updatedSellerTable2);
            setLoading(false);
            return updatedSellerTable2;
        });
        const removedSellers = sellerTable2.filter((seller: any) => idsToRemove.includes(seller.sellerId))
            .map((seller: any) => ({ ...seller, isRemove: true }));
        const updatedSellerTable1 = [...sellerTable1, ...removedSellers];
        setSellerTable1(updatedSellerTable1);
        setRemovedSellers((prevRemovedSellers: any) => [...prevRemovedSellers, ...removedSellers]);
    };

    const updateSellers = () => {
        setLoading(true);
        const payload = [...sellerTable2, ...removedSellers]?.map((seller: any) => ({
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            sellerId: seller.sellerId,
            isRemove: seller.isRemove || false
        }));
        axios.post(UPDATEAUCTIONSELLER, payload, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUCTIONSELLER]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUCTIONSELLER]))
                }
                handleNext();
                toast.success(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    const getAuctionBasedOnSaleRep = () => {
        if (!clonedTabs.includes(AUCTIONSELLER) && mode === "clone") {
            setSellerTable2([])
            setAllSelectedData([])
        } else {
            setLoading(true);
            const pageNumber = -1;
            const aId = clonedTabs.includes(AUCTIONSELLER) ? cloneAuctionId : auctionId
            axios.get(`${GETAUCTIONSELLERMAPPING}?&auctionId=${aId}&pageNumber=${pageNumber}`).then((response: any) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    return;
                } else {
                    setSellerTable2(response?.data?.data?.data);
                    setAllSelectedData(response?.data?.data?.data);
                }
            }
            ).catch((error) => {
                setLoading(false);
                handleError(error)
            });
        }
    }

    const handlePrev = () => {
        dispatch(setStep(1));
        dispatch(setAuctionInfoActive({ activeStep: 1, activeLabel: "Address & Dates" }))
    }

    const handleNext = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionCustomer" }));
    }

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUCTIONSELLER)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUCTIONSELLER)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            <div className="row pt-4 justify-content-center">
                <MasterTable
                    ref={masterTableRef}
                    items={sellerTable1}
                    onMove={moveItemsToAuctionSeller}
                    icon={IconSeller}
                    tableLabel={`Master Sellers (${sellerTable1?.length})`}
                    selectedValueCount={(isAnyValueSelected) => {
                        setIsAnyCheckboxChecked(isAnyValueSelected)
                    }}
                    table='table1'
                    onClick={() => {
                        dispatch(setActiveTab('contact'))
                    }}
                />

                <div className="plus-arrow-size">
                    <div className="d-grid flex-wrap align-items-center h-100 justify-content-center">
                        <div className="mt-auto mb-3">
                            <button
                                className="btn btn-primary move-btn-right-side"
                                onClick={moveItemsToAuctionSeller}
                                disabled={!isAnyCheckboxChecked} >
                                <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                        <div className="mb-auto mt-3">
                            <button
                                className="btn btn-primary move-btn-left-side"
                                onClick={moveItemsToMaster}
                                disabled={!isSecondTableAnyCheckbox}
                            >
                                <i className="fa-solid fa-arrow-left"></i></button>
                        </div>
                    </div>
                </div>
                <MasterTable
                    ref={auctionSellerTableRef}
                    items={sellerTable2}
                    onMove={moveItemsToMaster}
                    icon={IconAuction}
                    tableLabel={`Auction Sellers (${sellerTable2?.length})`}
                    isCrossIconShow={true}
                    openCancelPopUp={openCancelPopUp}
                    selectedValueCount={(isAnyValueSelected) => {
                        setIsSecondTableAnyCheckbox(isAnyValueSelected)
                    }}
                    table='table2'
                    onClick={() => {
                        // dispatch(setActiveTab('auctionInfo'))
                        dispatch(setActiveTab('contact'))
                    }}
                />
            </div>

            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev} >{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION} >{CommonMessages.cancelButton}</Link>
                <button className="btn btn-primary ms-2" type="submit" onClick={updateSellers} >{btnTxt}</button>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div >
        </>
    )
}
export default AuctionSeller;