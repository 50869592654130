import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import deleteIcon from "../../assets/delet_icon_tab.svg";
import editIcon from "../../assets/edit_icon_tab.svg";
import { GETALLSELLERCOMMISSIONSCALE } from "../../common/apiConstatnts";
import { CommonMessages, errorMessages } from "../../common/messageConstants";
import { DECIMAL63, DECIMAL63_MESSAGE, DECIMAL83, DECIMAL83_MESSAGE, PERCENTAGE, PERCENTAGE_MESSAGE } from "../../common/regexConstants";
import { sellerDefaults, sellerSaved, setSellerCommSettingId, setSellerData, showLoader } from "../../store/actions/CommissionAction";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES, formatCurrency, formatNumber, handleKeyDown } from "../../Utils/Utils";

const SellerCommissionScale = () => {
    const initialRangeData = {
        sellerCommissionScaleRangeId: 0,
        sellerCommissionScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        from: 0,
        upto: "",
        percentage: "",
        fixedAmount: "",
        // levelMin: "",
        // levelCap: "",
        fixedPercentageType: "1",
        isActive: true,
        mode: "add"
    }

    const radioSelection = [
        { label: '%', value: '1', selected: true, key: 'isPercentage', listingLabel: 'Percentage', clearLabel: 'fixedAmount' },
        { label: 'Fixed', value: '2', selected: false, key: 'isFixed', listingLabel: 'Fixed', clearLabel: 'percentage' },
        { label: 'Both', value: '3', selected: false, key: 'isBoth', listingLabel: 'Both', clearLabel: '' }
    ]

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const sellerCommission = useSelector((data: any) => data.commission.sellerCommission);
    const sellerDefaultsData = useSelector((data: any) => data.commission.sellerDefaults);
    const isSaved = useSelector((data: any) => data.commission.isSellerSave);
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [toggleModalRange, setToggleValueRange] = useState<boolean>(false);
    const [sellerCommissionsScale, setSellerCommissionScale] = useState([]);
    const [sellerCommissionScaleRange, setSellerCommissionScaleRange] = useState<any>([]);
    const [activeScaleId, setActiveScaleId] = useState<any>();
    const [activeUniqueId, setActiveUniqueId] = useState<any>();
    const [isLast, setIsLast] = useState<boolean>(false);
    const [lastUptoValue, setLastUptoValue] = useState<any>(0);
    const [mode, setMode] = useState('add');
    const [rangeMode, setRangeMode] = useState('add');
    const [deleteDisable, setDeleteDisable] = useState<any>('');
    const [radioValue, setRadioValue] = useState<any>("1");
    const [uptoError, setUptoError] = useState<boolean>(false);
    const [isLimitReach, setIsLimitReach] = useState<boolean>(false);
    const maxWordCount = 50;
    const [remainingCount, setRemainingCount] = useState(maxWordCount);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount);

    const initialSellerScaleData = {
        sellerCommissionScaleId: 0,
        companyId: localStorage.getItem('companyId'),
        label: "",
        taxRegionName: "",
        isActive: true,
        mode: mode,
    }

    const [initialSellerFormState, setInitialSellerFormState] = useState<any>(initialSellerScaleData)
    const [initialRangeFormState, setInitialRangeFormState] = useState<any>(initialRangeData)
    const decimalValForNxt: any = 0.01

    const schema: any = yup.object().shape({
        label: yup.string().required("Label is required.").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).max(50, "Please enter upto 50 characters."),
        taxRegionName: yup.string().required('Tax Region Name is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).max(50, "Please enter upto 50 characters.")
    });

    const rangeSchema: any = yup.object().shape({
        from: yup.string().required('From is required.')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL83, DECIMAL83_MESSAGE)
            .test('is-greater-from',
                `Please enter valid number greater than ${parseFloat(lastUptoValue ? lastUptoValue : 0) - parseFloat(decimalValForNxt)}`,
                val => rangeMode == 'edit' || !lastUptoValue ? true : parseFloat(val) > parseFloat(`${lastUptoValue - decimalValForNxt}`
                )),
        upto: yup.string().required("Upto is required.").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL83, DECIMAL83_MESSAGE).test("is-less-from", 'Please enter valid number greater than From value', val => parseFloat(val) > from),
        fixedPercentageType: yup.string().required("Fixed/Percentage/Type is required."),
        percentage: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "1" || value === "3",
            then: () => yup.string().matches(PERCENTAGE, PERCENTAGE_MESSAGE).required('Percentage is required')
        }),
        fixedAmount: yup.string().when('fixedPercentageType', {
            is: (value: string) => value === "2" || value === "3",
            then: () => yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).required('Fix amount is required')
        }),
        // levelMin: yup.string().required('Level Min is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),
        // levelCap: yup.string().required('Level Cap is required').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL5, DECIMAL5_MESSAGE),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialSellerFormState, mode: 'onChange' })

    const {
        register: rangeRegister,
        handleSubmit: rangeHandleSubmit,
        reset: rangeReset,
        setValue: rangeSetValue,
        getValues,
        watch,
        setError,
        clearErrors: rangeClearErrors,
        formState: { errors: rangeErrors, isValid: rangeIsValid },
    } = useForm({ resolver: yupResolver(rangeSchema), defaultValues: initialRangeFormState, mode: 'onChange' })

    const from = parseFloat(watch('from'))
    const upto = parseFloat(watch('upto'))
    const fixedAmount = watch('fixedAmount')

    useEffect(() => {
        const upto = getValues('upto')
        const errorKeys = Object.keys(rangeErrors)
        if (from >= upto && upto !== '' && !errorKeys.includes('upto')) {
            setUptoError(true)
            return
        }
        setUptoError(false)
        rangeClearErrors('from')
    }, [from])

    useEffect(() => {
        setUptoError(false)
    }, [upto])

    const handleClose = () => {
        reset(initialSellerFormState)
        setToggleValue(!toggleModal)
    }

    const handleCloseRangeModel = () => {
        rangeReset(initialRangeFormState)
        setRangeMode('')
        setToggleValueRange(!toggleModalRange)
    }

    const getAllSellerCommissionScaleData = () => {
        dispatch(showLoader(true))
        axios.get(GETALLSELLERCOMMISSIONSCALE, config).then((response) => {
            dispatch(showLoader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const data = response.data.data;
                dispatch(sellerDefaults({
                    sold: data?.strSellerCommissionSold,
                    passedUnsold: data?.strSellerCommissionUnSold,
                    buyBack: data?.strSellerCommissionBuyPack,
                    nonPayer: data?.strSellerCommissionNonPayer,
                    passedLot: data?.sellerCommissionPassedLot,
                    unPassedLot: data?.sellerCommissionUnPassesLot
                }))
                const sellerCommissionScaleData = data?.lstSellerCommissionScale
                const updateSellerCommData = sellerCommissionScaleData?.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstSellerCommissionScaleRange: data?.lstSellerCommissionScaleRange?.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })

                const firstIndexScale = updateSellerCommData ? updateSellerCommData[0] : []
                const firstIndexRange = updateSellerCommData ? updateSellerCommData[0]?.lstSellerCommissionScaleRange : []
                setSellerCommissionScale(updateSellerCommData ?? []);
                setActiveScaleId(firstIndexScale?.sellerCommissionScaleId);
                setActiveUniqueId(firstIndexScale?.uniqueId);
                setStartingFrom(firstIndexRange);
                setSellerCommissionScaleRange(firstIndexRange);
                dispatch(setSellerData(updateSellerCommData));
                dispatch(setSellerCommSettingId(data?.sellerCommissionScaleSettingId));
            }
            dispatch(showLoader(false))
        }).catch((error) => {
            dispatch(showLoader(false));
            handleError(error);
        });
    }

    const getSellerId = (scalerId: number, uniqueId: string) => {
        const found: any = sellerCommissionsScale?.find((obj: any) => {
            return obj.uniqueId === uniqueId;
        });
        const range = found.lstSellerCommissionScaleRange
        setActiveScaleId(scalerId)
        setActiveUniqueId(found.uniqueId)
        setStartingFrom(range)
        setSellerCommissionScaleRange(range?.filter((data: any) => data.mode !== 'delete'))
    }

    const handleCheckClick = (e: any, className: string, uniqueId: string, keyName: string, defaultsKeyName: string, type: string) => {
        e.stopPropagation()
        const chkEvent = e.target
        const isChecked = chkEvent.checked

        if (isChecked) {
            chkEvent.checked = false
            const newData: any = sellerCommissionsScale?.find((data: any) => data.uniqueId === uniqueId)
            const checks: any = document.getElementsByClassName(className)
            Swal.fire({
                title: 'Are you sure?',
                text: `You want to set "${newData.label}" as default ${type}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (let i = 0; i < checks.length; i++) {
                        if (checks[i] === chkEvent) {
                            checks[i].checked = true
                        } else {
                            checks[i].checked = false
                        }
                    }

                    const updateComm = sellerCommission?.map((data: any) => {
                        if (data.uniqueId === uniqueId) {
                            return { ...data, [keyName]: true }
                        }
                        return { ...data, [keyName]: false }
                    })

                    const activceSellerRegion = sellerCommission?.find((data: any) => data.uniqueId === uniqueId).taxRegionName
                    const updateDefaults = { ...sellerDefaultsData, [defaultsKeyName]: activceSellerRegion }
                    setSellerCommissionScale(updateComm)
                    dispatch(setSellerData(updateComm))
                    dispatch(sellerDefaults(updateDefaults))
                }
            })

        } else {
            chkEvent.checked = true
        }
    }

    const handleFormRadio = (value: string, clearLabel: string) => {
        if (clearLabel !== '') {
            rangeSetValue(`${clearLabel}`, '')
            rangeClearErrors(`${clearLabel}`)
        }
        setRadioValue(value)
        rangeSetValue('fixedPercentageType', value)
    }

    const handleEditModal = (e: any, uniqId: number) => {
        e.stopPropagation()
        setMode('edit')
        setToggleValue(!toggleModal)
        const getData = sellerCommissionsScale?.find((data: any) => data.uniqueId === uniqId)
        reset(getData)
    }

    const handleSellerCommDelete = (e: any, uniqueId: number) => {
        e.stopPropagation()
        const checkDefault: any = sellerCommissionsScale?.find((data: any) => data.uniqueId === uniqueId)
        if (checkDefault.sellerCommissionBuyBack || checkDefault.sellerCommissionNonPayer || checkDefault.sellerCommissionSold || checkDefault.sellerCommissionUnSold) {
            setDeleteDisable(uniqueId)
            toast.error(errorMessages.defaultValueScale, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            setTimeout(() => {
                setDeleteDisable('')
            }, 3000)
            return
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this scale!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updateScale: any = sellerCommissionsScale?.map((data: any) => {
                    if (uniqueId === data.uniqueId) {
                        if (data?.sellerCommissionScaleId == 0) {
                            return null
                        }
                        return { ...data, mode: 'delete', isActive: false }
                    }
                    return data
                }).filter((i: any) => i)
                const getActive = updateScale?.filter((data: any) => data.mode !== 'delete')
                const firstIndexRange = getActive[0]?.lstSellerCommissionScaleRange ?? []
                setSellerCommissionScale(updateScale)
                dispatch(setSellerData(updateScale))
                setActiveScaleId(getActive[0]?.sellerCommissionScaleId)
                setStartingFrom(firstIndexRange)
                setSellerCommissionScaleRange(firstIndexRange)
                setActiveUniqueId(getActive[0]?.uniqueId)
            }
        })
        return
    }

    const handleRangeDelete = (scaleUniqueId: any, rangeUniqueId: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this range!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const modifyScale: any = sellerCommissionsScale?.map((data: any) => {
                    const modifyRange = data.lstSellerCommissionScaleRange?.map((rg: any) => {
                        if (rg.uniqueId === rangeUniqueId) {
                            if (rg?.sellerCommissionScaleRangeId == 0) {
                                return null
                            }
                            return { ...rg, mode: "delete", isActive: false }
                        }
                        return rg
                    }).filter((i: any) => i)
                    return { ...data, lstSellerCommissionScaleRange: modifyRange }
                })
                const getBid = modifyScale?.find((data: any) => data.uniqueId === scaleUniqueId)?.lstSellerCommissionScaleRange
                let getLastUpto = getBid?.filter((data: any) => data.mode !== "delete")
                setLastUptoValue(parseFloat(getLastUpto[getLastUpto?.length - 1]?.upto) + decimalValForNxt)
                setSellerCommissionScaleRange(getLastUpto)
                setSellerCommissionScale(modifyScale)
                dispatch(setSellerData(modifyScale))
            }
        })
        return
    }

    const handleRangeEditModal = (scaleUniqueId: any, rangeUniqueId: string) => {
        setRangeMode('edit')
        //   Find a record from range data which matches with the unique ID :
        const matchedRecord = sellerCommissionScaleRange.find((item: any) => item.uniqueId == rangeUniqueId)
        setToggleValueRange(true);
        rangeReset(matchedRecord)
    }

    const onSubmitScale = (data: any) => {
        let updatedData: any
        if (data.sellerCommissionScaleId === 0 && data.uniqueId === undefined) {
            const newScale = { ...data, lstSellerCommissionScaleRange: [], mode: "add", uniqueId: uuidv4() }
            updatedData = [...sellerCommissionsScale, newScale]
        } else {
            updatedData = sellerCommissionsScale?.map((sData: any) => {
                if (sData.uniqueId === data.uniqueId) {
                    return { ...sData, ...data }
                }
                return sData
            });
        }
        setSellerCommissionScale(updatedData);
        setActiveScaleId(updatedData[0]?.sellerCommissionScaleId);
        setActiveUniqueId(updatedData[0]?.uniqueId);
        dispatch(setSellerData(updatedData));
        dispatch(sellerDefaults({
            sold: data.sellerCommissionSold ? data.taxRegionName : sellerDefaultsData.sold,
            passedUnsold: data.sellerCommissionUnSold ? data.taxRegionName : sellerDefaultsData.passedUnsold,
            buyBack: data.sellerCommissionBuyBack ? data.taxRegionName : sellerDefaultsData.buyBack,
            nonPayer: data.sellerCommissionNonPayer ? data.taxRegionName : sellerDefaultsData.nonPayer,
            passedLot: sellerDefaultsData.passedLot,
            unPassedLot: sellerDefaultsData.unPassedLot
        }))
        setToggleValue(!toggleModal);
        reset(initialSellerFormState);
    }

    const onSubmitRange = (data: any) => {
        const updatedScaleData: any = sellerCommissionsScale?.map((bData: any) => {
            if (bData.uniqueId === activeUniqueId) {
                const updateMode = bData.lstSellerCommissionScaleRange?.map((item: any) => (
                    {
                        ...item,
                        mode: (item.sellerCommissionScaleRangeId === 0 && item.mode != 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                    }
                ))
                const newScaleData = { ...bData, lstSellerCommissionScaleRange: updateMode }

                let updatedRangeData: any
                if (rangeMode == 'edit') {
                    //first delete range which is edited then add new range. bcoz edit mode not working.
                    updatedRangeData = {
                        ...bData, lstSellerCommissionScaleRange: bData?.lstSellerCommissionScaleRange?.map((udata: any) => {
                            if (udata.uniqueId == data.uniqueId) {
                                if (data?.sellerCommissionScaleRangeId == 0) {
                                    return null
                                }
                                return {
                                    ...udata, ...data,
                                    percentage: (data.percentage) ? parseInt(data.percentage) : 0,
                                    fixedAmount: (data.fixedAmount) ? parseInt(data.fixedAmount) : 0,
                                    mode: 'delete',
                                    isActive: false
                                }
                            }
                            return udata
                        }).filter((i: any) => i)
                    }
                    //add new range here, which is edited.
                    updatedRangeData = {
                        ...updatedRangeData,
                        lstSellerCommissionScaleRange: [...updatedRangeData?.lstSellerCommissionScaleRange,
                        {
                            ...data,
                            percentage: (data.percentage) ? parseInt(data.percentage) : 0,
                            fixedAmount: (data.fixedAmount) ? parseInt(data.fixedAmount) : 0,
                            sellerCommissionScaleId: activeScaleId,
                            sellerCommissionScaleRangeId: 0,
                            mode: "add",
                            uniqueId: uuidv4()
                        }
                        ]
                    }
                } else {
                    updatedRangeData = {
                        ...newScaleData,
                        lstSellerCommissionScaleRange: [...newScaleData.lstSellerCommissionScaleRange,
                        {
                            ...data,
                            percentage: (data.percentage) ? parseInt(data.percentage) : 0,
                            fixedAmount: (data.fixedAmount) ? parseInt(data.fixedAmount) : 0,
                            sellerCommissionScaleId: activeScaleId,
                            mode: "add",
                            uniqueId: uuidv4()
                        }
                        ]
                    }
                }
                return updatedRangeData
            }
            const updateMode = bData.lstSellerCommissionScaleRange?.map((item: any) => (
                {
                    ...item,
                    mode: (item.sellerCommissionScaleRangeId === 0 && item.mode != 'delete') ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                }
            ))
            return { ...bData, lstSellerCommissionScaleRange: updateMode }
        })

        const updateBidRange: any = updatedScaleData?.find((data: any) => data.uniqueId === activeUniqueId)?.lstSellerCommissionScaleRange
        setSellerCommissionScaleRange(updateBidRange)
        setSellerCommissionScale(updatedScaleData)
        setLastUptoValue(parseFloat(data.upto) + decimalValForNxt)
        dispatch(setSellerData(updatedScaleData))
        rangeReset(initialRangeFormState)
        setToggleValueRange(!toggleModalRange)
    }

    const setStartingFrom = (rangeData: any) => {
        const getActiveRange = rangeData?.filter((data: any) => data.mode !== 'delete')
        const lastIndexRange = getActiveRange?.length > 0 ? getActiveRange[getActiveRange?.length - 1] : 0
        const setUpto = lastIndexRange.upto ? parseFloat(lastIndexRange.upto) + decimalValForNxt : 0
        setLastUptoValue(parseFloat(setUpto))
        rangeSetValue('from', parseFloat(parseFloat(setUpto)?.toFixed(2)))
    }

    useEffect(() => {
        const checkLast = sellerCommissionScaleRange?.length > 0 ? sellerCommissionScaleRange?.filter((data: any) => data.mode !== 'delete') : []
        const getBool: any = checkLast?.length === 1 ? true : false
        setIsLast(getBool)
    }, [sellerCommissionScaleRange])

    useEffect(() => {
        if (isSaved) {
            setSellerCommissionScale([])
            setSellerCommissionScaleRange([])
            getAllSellerCommissionScaleData();
            dispatch(sellerSaved(false))
        }
    }, [isSaved])

    useEffect(() => {
        if (sellerCommission && sellerCommission?.length > 0) {
            const firstIndexRange = sellerCommission[0]?.lstSellerCommissionScaleRange ?? []
            const firstIndexScale = sellerCommission[0] ?? []
            setSellerCommissionScale(sellerCommission)
            setActiveScaleId(firstIndexScale?.sellerCommissionScaleId)
            setActiveUniqueId(firstIndexScale?.uniqueId)
            setStartingFrom(firstIndexRange)
            setSellerCommissionScaleRange(firstIndexRange)
            return
        }
        getAllSellerCommissionScaleData();
    }, [])

    useEffect(() => {

        const lastUpto: any = parseFloat(lastUptoValue ? lastUptoValue : 0) - decimalValForNxt
        const CommissionScaleLimit: any = process.env.REACT_APP_COMMISSION_FROM_LIMIT
        if (lastUpto >= parseFloat(CommissionScaleLimit)) {

            setIsLimitReach(true)
            return
        }
        setIsLimitReach(false)
    }, [lastUptoValue])

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData: any = [...sellerCommissionsScale];

        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        setSellerCommissionScale(sortedData);
        const firstIndexRange: any = sortedData[0]?.lstSellerCommissionScaleRange ?? [];
        const firstIndexScale: any = sortedData[0] ?? [];
        setActiveScaleId(firstIndexScale?.sellerCommissionScaleId);
        setActiveUniqueId(firstIndexScale?.uniqueId);
        setStartingFrom(firstIndexRange);
        setSellerCommissionScaleRange(firstIndexRange);
    };

    return (
        <>
            {/* Add Scale */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmitScale)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{mode === 'add' ? 'Add New Scale' : 'Update Scale'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label">Label <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Label"
                                    maxLength={51}
                                    {...register("label")}
                                />
                                {errors.label && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.label.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Tax Region Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Tax Region Name"
                                    maxLength={51}
                                    {...register("taxRegionName")}
                                />
                                {errors.taxRegionName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRegionName.message}</span>}
                            </div>
                        </div >
                    </Modal.Body >
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit"
                        >{mode === 'add' ? (CommonMessages.saveButton) : (CommonMessages.updateButton)}</button>
                    </Modal.Footer>
                </form >
            </Modal >

            {/* Add Range */}
            < Modal show={toggleModalRange} size={isLimitReach && rangeMode != 'edit' ? 'sm' : 'xl'} centered >
                <form onSubmit={rangeHandleSubmit(onSubmitRange)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{rangeMode === 'add' ? 'Add New Range' : 'Update Range'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseRangeModel} >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {!isLimitReach || rangeMode == 'edit' ?
                            <div className="row g-3 mb-3">
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">From ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="From"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={13}
                                        {...rangeRegister('from')}
                                        onKeyDown={handleKeyDown}
                                        disabled={rangeMode == 'edit'}
                                    />
                                    {rangeErrors.from && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.from.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Upto ($)<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="Upto"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={11}
                                        {...rangeRegister('upto')}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.upto && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.upto.message}</span>}
                                    {uptoError && <span className='text-danger' style={{ fontSize: "12px" }}>Upto must greater than From</span>}
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label" htmlFor="text">Percentage/Fixed/Both<sup className="text-danger">*</sup></label>
                                    <div className="code-to-copy">
                                        {radioSelection?.map((selection: any, index: any) => {
                                            return (
                                                <div className="form-check form-check-inline mt-2" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        name="commType"
                                                        id={`commType_${index}`}
                                                        type="radio"
                                                        defaultChecked={selection.selected}
                                                        onChange={() => handleFormRadio(selection.value, selection.clearLabel)}
                                                    />
                                                    <label className="form-label" htmlFor={`commType_${index}`}>{selection.label}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Percentage (%){radioValue !== "2" && <sup className="text-danger">*</sup>}</label>
                                    <input
                                        placeholder="Percentage"
                                        className="form-control form-icon-input"
                                        type="text"
                                        {...rangeRegister('percentage')}
                                        maxLength={5}
                                        disabled={radioValue === "2"}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.percentage && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.percentage.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Fixed Amount ($){radioValue !== "1" && <sup className="text-danger">*</sup>}</label>
                                    <CurrencyInput
                                        placeholder="Fixed Amount"
                                        className="form-control form-icon-input"
                                        type="text"
                                        prefix="$"
                                        {...rangeRegister('fixedAmount')}
                                        value={fixedAmount}
                                        onValueChange={(value: any) => rangeSetValue("fixedAmount", value)}
                                        disabled={radioValue === "1"}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {rangeErrors.fixedAmount && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.fixedAmount.message}</span>}
                                </div>
                                {/* <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Level Min <sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="Level Min"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={8}
                                        {...rangeRegister('levelMin')}
                                    />
                                    {rangeErrors.levelMin && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.levelMin.message}</span>}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label" htmlFor="text">Level Cap<sup className="text-danger">*</sup></label>
                                    <input
                                        placeholder="Level Cap"
                                        className="form-control form-icon-input"
                                        type="text"
                                        maxLength={8}
                                        {...rangeRegister('levelCap')}
                                    />
                                    {rangeErrors.levelCap && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.levelCap.message}</span>}
                                </div> */}

                            </div>
                            :
                            <span><span className="text-danger">**</span>You have exceeded the range limit. So you can't add new range.</span>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={!isLimitReach || rangeMode == 'edit' ? "btn btn-outline-danger" : "btn btn-primary"} type="button" data-bs-dismiss="modal" onClick={handleCloseRangeModel}>{!isLimitReach || rangeMode == 'edit' ? CommonMessages.cancelButton : "Ok"}</button>
                        {/* <button className="btn btn-primary" type="submit" disabled={!rangeIsValid}>Create</button> */}
                        {(!isLimitReach || rangeMode == 'edit') && <button className="btn btn-primary" type="submit"
                        //  disabled={!rangeIsValid || uptoError}
                        >  {rangeMode === 'add' ? CommonMessages.createButton : 'Update'}</button>}
                    </Modal.Footer>
                </form>
            </Modal >

            <div className="row g-3">
                <div className="col-lg-4">
                    <div className="d-flex align-items-center justify-content-end mb-1">
                        <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                            onClick={() => {
                                setToggleValue(!toggleModal)
                                setMode('add')
                            }}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Scale </span>
                        </button>
                    </div>

                    <div id="tableExample4">
                        <div className="table-responsive tbody_scroll_cus">
                            <table className="table border-radius border td_bottom_border_0 fs--1 table-sm mb-0">
                                <thead>
                                    <tr className="bg-gradiant">
                                        <th className="border-bottom ps-1 pe-1 pt-2 pb-2">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "30%" }}>
                                                    <span>Label</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'label')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'label')}></button>
                                                    </div>
                                                </div>
                                                <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "50%" }}>
                                                    <span>Tax Region Name</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'taxRegionName')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'taxRegionName')}></button>
                                                    </div>
                                                </div>
                                                <div className="align-middle payment text-end" style={{ width: "20%" }}>Action</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list scrollbar">
                                    {sellerCommissionsScale?.length > 0 && sellerCommissionsScale?.filter((data: any) => data.mode !== 'delete')?.length > 0 ?
                                        sellerCommissionsScale?.map((scalerData: any, index: any) => {
                                            return (
                                                <>
                                                    {(scalerData.mode !== 'delete') && <tr onClick={() => { getSellerId(scalerData.sellerCommissionScaleId, scalerData.uniqueId) }} key={index}>
                                                        <td className="align-middle ps-1 pe-1 name pt-2 pb-2">
                                                            <div className={`d-flex flex-wrap border_ac ${(activeUniqueId == scalerData.uniqueId && activeScaleId === scalerData.sellerCommissionScaleId) ? 'active' : ''}`}>
                                                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between position-relative">
                                                                    <div style={{ width: "30%" }} className="d-flex pe-3 flex-wrap align-items-center" >
                                                                        <span className="ellipsis">{scalerData.label}</span>
                                                                    </div>
                                                                    <div style={{ width: "50%" }} className="me-auto align-middle tax pe-3">
                                                                        <span className="ellipsis">{scalerData.taxRegionName}</span>
                                                                    </div>
                                                                    <div style={{ width: "20%" }} className="align-middle payment text-end">
                                                                        <div className="action_btn">
                                                                            <button
                                                                                type="button"
                                                                                className="action_icons text-decoration-none btn p-0"
                                                                                onClick={(e) => handleEditModal(e, scalerData.uniqueId)}
                                                                            > <img src={editIcon} /> </button>
                                                                            <button
                                                                                type="button"
                                                                                className="action_icons text-decoration-none ms-2 btn p-0"
                                                                                disabled={deleteDisable == scalerData.uniqueId}
                                                                                onClick={(e) => handleSellerCommDelete(e, scalerData.uniqueId)}
                                                                            > <img src={deleteIcon} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-sold form-check-input-scale-table"
                                                                        id={`sold_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-sold', scalerData.uniqueId, 'sellerCommissionSold', 'sold', 'Sold')}
                                                                        checked={scalerData.sellerCommissionSold === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`sold_${index}`}>Sold</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-passUnsold form-check-input-scale-table"
                                                                        id={`passUnsold_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-passUnsold', scalerData.uniqueId, 'sellerCommissionUnSold', 'passedUnsold', 'Passed/Unsold')}
                                                                        checked={scalerData.sellerCommissionUnSold === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`passUnsold_${index}`}>Passed/Unsold</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-buyBack form-check-input-scale-table"
                                                                        id={`buyBack_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-buyBack', scalerData.uniqueId, 'sellerCommissionBuyBack', 'buyBack', 'Buyback')}
                                                                        checked={scalerData.sellerCommissionBuyBack === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`buyBack_${index}`}>Buyback</label>
                                                                </div>
                                                                <div className="form-check form-check-inline mt-2">
                                                                    <input
                                                                        className="form-check-input form-check-nonPayer form-check-input-scale-table"
                                                                        id={`nonPayer_${index}`}
                                                                        type="checkbox"
                                                                        onClick={(e) => handleCheckClick(e, 'form-check-nonPayer', scalerData.uniqueId, 'sellerCommissionNonPayer', 'nonPayer', 'Non-Payer')}
                                                                        checked={scalerData.sellerCommissionNonPayer === true}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`nonPayer_${index}`}>Non-Payer</label>
                                                                </div>
                                                            </div>
                                                        </td >
                                                    </tr>}
                                                </>
                                            )
                                        }) :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={6}>No Scale found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="col-lg-8">
                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                        <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please add atleast one range against each scale.</i></span>
                        <button className="btn btn-outline-secondary ms-auto Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                            onClick={() => {
                                setToggleValueRange(!toggleModalRange)
                                rangeSetValue('from', parseFloat(lastUptoValue ? lastUptoValue?.toFixed(2) : 0) ?? 0)
                                setRadioValue('1')
                                setRangeMode('add')
                            }}
                            disabled={sellerCommissionsScale?.length == 0}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Range </span>
                        </button>

                    </div>


                    <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                        <div className="table-responsive scrollbar border-top border-bottom">
                            <table className="table table-bordered table-sm mb-0 fs--1">
                                <thead>
                                    <tr>
                                        <th className="bg-gradiant border-bottom border-top p-2" >From ($)</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Upto ($)</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Percentage (%)</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Fixed Amount ($)</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >%/Fixed/Both</th>
                                        {/* <th className="bg-gradiant border-bottom border-top p-2" >Level Min</th>
                                        <th className="bg-gradiant border-bottom border-top p-2" >Level Cap</th> */}
                                        <th className="bg-gradiant border-bottom text-end align-middle pe-0 border-top" style={{ width: "30px" }} scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {sellerCommissionScaleRange?.length > 0 ?
                                        sellerCommissionScaleRange?.map((rangeData: any, index: any) => {
                                            return (
                                                <>
                                                    {rangeData.mode !== "delete" && <tr key={index}>
                                                        <td className="p-2">{formatNumber(rangeData.from) ?? '-'}</td>
                                                        <td className="p-2">{formatNumber(rangeData.upto) ?? '-'}</td>
                                                        <td className="p-2">{rangeData.percentage ?? '-'}</td>
                                                        <td className="p-2">{formatCurrency(rangeData.fixedAmount) ?? '-'}</td>
                                                        <td className="p-2">{radioSelection?.length ? radioSelection?.find((data) => data.value == rangeData.fixedPercentageType)?.listingLabel : '-'}</td>
                                                        {/* <td className="p-2">{rangeData.levelMin}</td>
                                                        <td className="p-2">{rangeData.levelCap}</td> */}

                                                        <td className="p-2" style={{ width: "50px" }}>

                                                            {(sellerCommissionScaleRange?.length === index + 1) &&
                                                                <div className="action_btn align-items-center">
                                                                    {/* Edit button */}
                                                                    <button
                                                                        type="button"
                                                                        className="action_icons text-decoration-none btn p-0"
                                                                        onClick={() => handleRangeEditModal(activeUniqueId, rangeData.uniqueId)}
                                                                    ><img src={editIcon} height={12} /></button>
                                                                    {/* Delete button */}
                                                                    <button
                                                                        type="button"
                                                                        className="action_icons text-decoration-none ms-2 btn p-0"
                                                                        onClick={() => handleRangeDelete(activeUniqueId, rangeData.uniqueId)}
                                                                    ><img src={deleteIcon} height={12} /></button>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>}
                                                </>
                                            )
                                        })
                                        :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={8}>No Range found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default SellerCommissionScale;