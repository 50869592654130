import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuctionEmailReportsActive } from '../../../../store/actions/AuctionAction'
import { Tab, Tabs } from 'react-bootstrap'
import InvoiceEmailTemplate from './InvoiceEmailTemplate'
import SellerEmailTemplate from './SellerEmailTemplate'

const EmailReports = () => {
    const activeEmailReportsSetting = useSelector((state: any) => state.auction.emailReport)
    const [activeStep, setActiveStep] = useState(1)
    const [activeLabel, setActiveLabel] = useState("Invoice Email Template")
    const dispatch = useDispatch();
    useEffect(() => {
      setActiveStep(activeEmailReportsSetting.activeStep)
  }, [activeEmailReportsSetting.activeStep])

  useEffect(() => {
      setActiveLabel(activeEmailReportsSetting.activeLabel)
  }, [activeEmailReportsSetting.activeLabel])

  const handleChange = (key: string) => {
      if (key === 'InvoiceEmailTemplate') {
          dispatch(setAuctionEmailReportsActive({ activeStep: 1, activeLabel: "Invoice Email Template" }))
      } else if (key === 'SellerEmailTemplate') {
          dispatch(setAuctionEmailReportsActive({ activeStep: 2, activeLabel: "Seller Email Template" }))
      };
  }
  return (
    <>
    <div className="container-fluid for-fix-bottom-space">
        <div className="row top_border justify-content-center">
            <div className="col-xxl-10 pt-4 pb-4">
            <h4 className="mb-3">Email Reports</h4>
                <Tabs
                    activeKey={activeStep === 1 ? "InvoiceEmailTemplate" : "SellerEmailTemplate"}
                    defaultActiveKey="InvoiceEmailTemplate"
                    className="nav-underline border-bottom  nav nav-tabs mb-3"
                    onSelect={(key: any) => handleChange(key)}
                >
                    <Tab eventKey="InvoiceEmailTemplate" title="Invoice Email Template"></Tab>
                    <Tab eventKey="SellerEmailTemplate" title="Seller Email Template"></Tab>
                </Tabs>
                {activeStep === 1 ? (
                  <InvoiceEmailTemplate />
                ) : activeStep === 2 ? (
                   <SellerEmailTemplate />
                ) : (
                    <></>
                )}
            </div>
        </div>
    </div>
</>
  )
}

export default EmailReports