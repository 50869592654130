import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from '../Sidebar/Sidebar';
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { GETSELLERREPORT, SAVESELLERREPORT, UPDATESELLERREPORT } from '../../common/apiConstatnts';
import { Tab, Tabs } from "react-bootstrap";
import parse from 'html-react-parser';
import { CKEditor } from "ckeditor4-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import { EMAIL, EMAIL_MESSAGE } from "../../common/regexConstants";
import { CommonMessages, SellerRepMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, trimObjectValues } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../Utils/CustomCkEditor";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Email Setting', url: "" },
    { label: 'Seller Reports', url: routeConsant.SELLERREPORT }
]

const SellerReport = () => {
    const initialData = {
        sellerReportId: "",
        ccAddress: "",
        bccAddress: "",
        replyToAddress: "",
        sendToSellerEmail2: true,
        includeSellerNumberInSubject: true,
        isActive: true,
        lstEmailTemplate: [
            {
                emailTemplateId: "",
                emailValue: "",
                subjectLine: "",
                emailType: ""
            }
        ]
    }

    const [initialFormValue, setFormValue] = useState<any>(initialData);
    const ID = initialFormValue?.sellerReportId;
    const [loading, setLoading] = useState(false);
    const [emailValue, setEmailValue] = useState<any>({});
    const [subjectLine, setSubjectLine] = useState<any>({});
    const [mod, setMod] = useState('SellerReportBody');
    const [modifiedBy, setModifiedBy] = useState('');
    const [sellerBody, setSellerBody] = useState('');
    const [sellerFooter, setSellerFooter] = useState('');

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const configToken: any = {
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        paras: {
            id: localStorage.getItem('companyId')
        }
    };

    const schema: any = yup.object().shape({
        ccAddress: yup.string()
            .required("CC Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })

            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        bccAddress: yup.string()
            .required("Bcc Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        replyToAddress: yup.string()
            .required("Reply to email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        subjectLine: yup.string().required("Email Subject is Required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .max(100, "Please enter upto 100 characters.")
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        watch,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const subjectLineW = watch('subjectLine')

    const getSellerReport = (e: any) => axios.get(GETSELLERREPORT, config).then((response) => {
        setLoading(false);
        const sellerData = response.data.data;
        if (sellerData) {
            setSubjectLine({ ...subjectLine, body: sellerData?.lstEmailTemplate[0].subjectLine });
            setValue('subjectLine', sellerData?.lstEmailTemplate[0].subjectLine);
            // Body value
            setSellerBody(sellerData?.lstEmailTemplate[0].emailValue)
            // Footer value
            setSellerFooter(sellerData?.lstEmailTemplate[1].emailValue)
        }
        setModifiedBy(sellerData?.modifiedBy);
        setFormValue(sellerData);
        reset(sellerData);
        setLoading(false);
    }).catch((error) => {
        setLoading(false);
        handleError(error);
    });;

    const getEmailContent = (e: any) => {
        const data = getValues()
        if (data) {
            if (e == 'SellerReportBody') {
                setSubjectLine({ ...subjectLine, body: subjectLine.body ? subjectLine.body : data.lstEmailTemplate[0].subjectLine })
                setValue('subjectLine', subjectLine.body ? subjectLine.body : data.lstEmailTemplate[0].subjectLine)
            }
            else {
                setSubjectLine({ ...subjectLine, footer: subjectLine.footer ? subjectLine.footer : data.lstEmailTemplate[1].subjectLine })
                setValue('subjectLine', subjectLine.footer ? subjectLine.footer : data.lstEmailTemplate[1].subjectLine)
            }
        }
    }

    const onsubmit = (data: any) => {
        setLoading(true);
        const trimData = trimObjectValues(data)
        // const data = getValues();
        reset(trimData);
        // if (mod == 'SellerReportBody') {
        var payload = {
            sellerReportId: trimData.sellerReportId ? parseInt(trimData.sellerReportId) : 0,
            ccAddress: trimData.ccAddress,
            bccAddress: trimData.bccAddress,
            replyToAddress: trimData.replyToAddress,
            sendToSellerEmail2: trimData.sendToSellerEmail2,
            includeSellerNumberInSubject: trimData.includeSellerNumberInSubject,
            isActive: true,
            lstEmailTemplate: [
                {
                    emailTemplateId: trimData.lstEmailTemplate[0].emailTemplateId ? parseInt(trimData.lstEmailTemplate[0].emailTemplateId) : 0,
                    emailValue: sellerBody ?? '',
                    subjectLine: subjectLine?.body ? subjectLine?.body : trimData.lstEmailTemplate[0].subjectLine,
                    emailType: mod,
                },
                {
                    emailTemplateId: trimData.lstEmailTemplate[1].emailTemplateId ? parseInt(trimData.lstEmailTemplate[1].emailTemplateId) : 0,
                    emailValue: sellerFooter ?? '',
                    subjectLine: subjectLine?.footer ? subjectLine?.footer : trimData.lstEmailTemplate[1].subjectLine,
                    emailType: mod,
                }
            ]
        }
        if (!ID) {
            axios.post(SAVESELLERREPORT, payload, configToken).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    toast.success(SellerRepMessages.sellerRepCreated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });;
        }
        else {
            axios.post(UPDATESELLERREPORT, payload, configToken).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    toast.success(SellerRepMessages.sellerRepUpdated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });;
        }
    }

    const handleClick = (e: any) => {
        clearErrors("subjectLine")
        getEmailContent(e)
        setMod(e)
    }

    useEffect(() => {
        setLoading(true);
        getSellerReport("SellerReportBody")
    }, [])

    useEffect(() => {
        if (mod.includes('Body')) {
            setSubjectLine({ ...subjectLine, body: subjectLineW })
        } else {
            setSubjectLine({ ...subjectLine, footer: subjectLineW })
        }
    }, [subjectLineW])

    // Body change
    const onBodyChange = (value: any) => {
        setSellerBody(value);
    }
    const onFooterChange = (value: any) => {
        setSellerFooter(value);
    }
    return (
        <>
            <CommonHelmet title={"Seller Reports- "} name={"sellerReport"} content={"Seller Reports"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row border-top justify-content-center pt-4">
                        <form onSubmit={handleSubmit(onsubmit)} noValidate className="col-12">
                            <div className="row  justify-content-center">
                                <div className="col-8 ">
                                    <div className="col-lg-12 mb-3">
                                        <h4>Seller Reports</h4>
                                        <label>These settings are use for various emailed summary reports for all auctions.</label>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-check-label">CC : Address<sup className="text-danger">*</sup></label>
                                        <input type="text" {...register('ccAddress')} className="form-control" placeholder="CC Address" maxLength={51} />
                                        {errors?.ccAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.ccAddress.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-check-label">BCC : Address<sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control" placeholder="BCC Address" {...register('bccAddress')} maxLength={51} />
                                        <label className="form-check-label-right mt-1">(Email address to CC & BCC seller summary - optional)</label>
                                        {errors?.bccAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.bccAddress.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-check-label">Reply To Address<sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control" placeholder="Reply To Address" {...register('replyToAddress')} maxLength={51} />
                                        <label className="form-check-label-right mt-1">(Reply To Email address for seller summary)</label>
                                        {errors?.replyToAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.replyToAddress.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <div className="d-flex align-items-center mt-3">
                                            <input
                                                className="form-check-input mt-0 me-1"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                                {...register('sendToSellerEmail2')}
                                                maxLength={101} />
                                            <label className="form-check-label">Also Send to Seller Email If available (applied to all Seller Reports)</label>
                                        </div>
                                    </div>
                                    <hr />
                                    <h4>Seller Summary Email Setup</h4>
                                    <hr />

                                    <div className="col-lg-12 mb-3">
                                        <Tabs
                                            defaultActiveKey="SellerReportBody"
                                            onSelect={(e) => handleClick(e)}
                                            id="uncontrolled-tab-example"
                                            className="mb-3 nav-underline border-bottom"
                                        >
                                            <Tab eventKey="SellerReportBody" title="Body Text">
                                                {/* Body */}
                                                <Tabs
                                                    defaultActiveKey="preview"
                                                    className="mb-3 nav-underline border-bottom"
                                                >
                                                    <Tab eventKey="preview" title="Editor">
                                                        <label className="form-check-label">Email Subject<sup className="text-danger">*</sup></label>
                                                        <label className="form-check-label-right">
                                                            <input
                                                                className="form-check-input mt-0"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckDefault"
                                                                {...register('includeSellerNumberInSubject')}
                                                                maxLength={101} /> Include Seller Number in Subject</label>
                                                        <input type="text" className="form-control" {...register("subjectLine")} placeholder="Email Subject" maxLength={101} />
                                                        {errors?.subjectLine && <span className='text-danger mb-3' style={{ fontSize: "12px" }}>{errors?.subjectLine.message}</span>}
                                                        <br />
                                                        <label className="form-check-label">Email Content</label>
                                                        {/* {ckEditorInit} */}
                                                        <CustomCkEditor data={sellerBody || ''} onTextChanged={onBodyChange} />
                                                    </Tab>
                                                    <Tab eventKey="source" title="Preview">
                                                        <div className="card box-shadow">
                                                            <div className="card-body ck_editor">
                                                                {sellerBody ? parse(sellerBody) : ''}
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </Tab>
                                            <Tab eventKey="SellerReportFooter" title="Footer">
                                                {/* Footer */}
                                                <Tabs
                                                    defaultActiveKey="preview"
                                                    className="mb-3 nav-underline border-bottom"
                                                >
                                                    <Tab eventKey="preview" title="Editor">
                                                        <label className="form-check-label">Email Subject<sup className="text-danger">*</sup></label>
                                                        <label className="form-check-label-right">
                                                            <input
                                                                className="form-check-input mt-0"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckDefault"
                                                                {...register('includeSellerNumberInSubject')}
                                                                maxLength={101} /> Include Seller Number in Subject</label>
                                                        <input type="text" className="form-control" {...register("subjectLine")} placeholder="Email Subject" maxLength={101} />
                                                        {errors?.subjectLine && <span className='text-danger mb-3' style={{ fontSize: "12px" }}>{errors?.subjectLine.message}</span>}
                                                        <br />
                                                        <label className="form-check-label">Email Content</label>
                                                        {/* {ckEditorInit} */}
                                                        <CustomCkEditor data={sellerFooter} onTextChanged={onFooterChange} />
                                                    </Tab>
                                                    <Tab eventKey="source" title="Preview">
                                                        <div className="card box-shadow">
                                                            <div className="card-body ck_editor">
                                                                {sellerFooter ? parse(String(sellerFooter)) : ''}
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </Tab>
                                        </Tabs>

                                    </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                <Link to={routeConsant.DASHBOARD} className="btn btn-outline-danger me-auto" type="button" data-wizard-prev-btn="data-wizard-prev-btn"  >
                                    {CommonMessages.cancelButton}
                                </Link>
                                <ModifiedBy data={initialFormValue} />
                                <button className="btn btn-primary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.saveButton}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SellerReport
