import CryptoJS, { enc } from 'crypto-js';
const SECRETKEY = 'your_secret_key_here';

export const encryptId = (userId: any) => {
  let code = CryptoJS.AES.encrypt(JSON.stringify(userId), SECRETKEY).toString();
  while (code.indexOf('/') > 0) {
    code = CryptoJS.AES.encrypt(JSON.stringify(userId), SECRETKEY).toString();
  }
  return code;
};

export const decryptId = (encryptedId: any) => {
  const bytes = CryptoJS.AES.decrypt(encryptedId, SECRETKEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
