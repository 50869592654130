import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react'
import { formatCurrency, removeHtmlTags } from '../../../../Utils/Utils';
import { formatPhoneNumber } from '../../../../common/constants';
import axios from "axios";
import { GETINVOICEBYID } from '../../../../common/apiConstatnts';
import { Link } from 'react-router-dom';
import { encryptId } from '../../../../Encryption/Encryption';
import * as routeConstant from "../../../../common/routeConstants";
import handleError from '../../../../Utils/ApiCommonErrorMessages';
const Invoice = ({ invoiceId }: any) => {

  // Set listing to display
  const [showListing, setShowListing] = useState<any>([]);
  // Loading state
  const [loading, setLoading] = useState(false);

  //get Id from props
  const ID = invoiceId

  // Configuration for authorization
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // GETINVOICEBYID API call
  const getInvoiceById = () => {
    setLoading(true);
    axios
      .get(`${GETINVOICEBYID}?invoiceMasterId=${ID}`, { ...config })
      .then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          // toast.error(response?.data?.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          return;
        } else {
          const dataInvoice = response?.data?.data;
          setShowListing(dataInvoice.lstInvoiceDeatils);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  //  Call Invoice by id API
  useEffect(() => {
    if (ID) {
      getInvoiceById();
    }
  }, [ID]);


  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div className="table-responsive scrollbar">
        <table className="table fs--1 mb-3 border-top border-200">
          {/* Table head for title */}
          <thead>
            <tr>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Lot #</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Title</span>
                </div>
              </th>
              {/* Bidder number */}
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Bidder #</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Type</span>
                </div>
              </th>

              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Lot Price</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Quantity</span>
                </div>
              </th>
              {/* <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Internet Fee </span>
                </div>
              </th> */}
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Item Contact </span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Item Location </span>
                </div>
              </th>

              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Buyer Premium</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Tax%</span>
                </div>
              </th>
              {/* <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Exempt</span>
                </div>
              </th> */}
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Total</span>
                </div>
              </th>

            </tr>
          </thead>
          {/* Table body */}
          <tbody className="list">
            {showListing.length > 0 ? (
              <>
                {showListing?.map((data: any, index: any) => {
                  return (
                    <tr
                      className="hover-actions-trigger btn-reveal-trigger position-static"
                      key={index}
                    >
                      <td className="align-middle white-space-nowrap text-start p-2">
                        <Link
                          className="btn text-decoration-underline m-0 p-0 fw-normal"
                          data-bs-dismiss="modal"
                          target="_blank"
                          to={`${routeConstant.UPDATEINVENTORY
                            }/${encryptId(data?.lotId)}`}>
                        {data?.lotNo}
                        </Link>
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {/* <Tippy
                      content={
                        <div style={{ fontSize: "small" }}>
                          <p>Lot ID: {data?.lotId}</p>
                          <p>Title: {removeHtmlTags(data?.title)}</p>
                          <p>Type: {data?.type}</p>
                          <p>Lot Price: {formatCurrency(data?.price)}</p>
                          <p>Quantity: 1</p>
                          <p>
                            Item Contact:
                            {formatPhoneNumber(data?.itemContact)}
                          </p>
                          <p>Item Location: {data?.itemLocation}</p>
                          <p>Buyer Premium: {data?.buyerPremium}</p>
                          <p>Taxrate: {data?.lotAddresstaxRate}</p>
                          <p>
                            Tax Exempt:
                            {data?.lotAddresstaxExempt == null || false
                              ? "No"
                              : "Yes"}
                          </p>
                          <p>Total: {formatCurrency(data?.total)}</p>
                        </div>
                      }
                      placement="bottom"
                    > */}

                        {data?.title ? removeHtmlTags(data?.title) : "-"}

                        {/* </Tippy> */}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {data?.salesrecordbidderNumber ?? "-"}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {data?.type}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {data?.price ? formatCurrency(data?.price) : "-"}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        1
                      </td>
                      {/* <td className="align-middle white-space-nowrap text-start p-2">
                        {formatCurrency(
                          data?.salesRecordInternetFee == null
                            ? "0"
                            : data?.salesRecordInternetFee
                        )}
                      </td> */}
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {data?.itemContact &&
                          data?.itemContact.trim().startsWith(",")
                          ? formatPhoneNumber(
                            data?.itemContact.trim().substring(1)
                          )
                          : formatPhoneNumber(data?.itemContact)}
                      </td>
                      {/* Item location has country and zipcode  */}
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {data?.itemLocation}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {formatCurrency(data?.buyersPrice)}
                      </td>
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {/* If exempt yea means tax will be 0 else false then tax count */}
                        {data?.lotAddresstaxExempt
                          ? 0
                          : data?.lotAddresstaxRate
                            ? data?.lotAddresstaxRate
                            : "-"}
                      </td>
                      {/* If exempt false means we have to count tax and if true then tax is 0 */}
                      {/*Toggle taxexempt to change new exempt from API */}
                        {/* If invoice is verified then display span or else clikable link */}
                      {/* <td className="align-middle white-space-nowrap text-start p-2">
                        {isVerified ? (
                      <span
                        className={`${
                          data.lotAddresstaxExempt
                            ? "m-0 p-0 fw-normal text-start"
                            : "m-0 p-0 fw-normal text-start text-danger"
                        }`}
                      >
                        {data.lotAddresstaxExempt ? "Yes" : "No"}
                      </span>
                    ) : (
                      <Link
                        className={`${
                          data.lotAddresstaxExempt
                            ? "text-decoration-underline m-0 p-0 fw-normal text-start"
                            : "text-decoration-underline m-0 p-0 fw-normal text-start text-danger"
                        }`}
                        to="#"
                        role="button"
                        onClick={() => handleTaxExemptChange(index)}
                      >
                        {data.lotAddresstaxExempt ? "Yes" : "No"}
                      </Link>
                    )} 
                      </td> */}
                      <td className="align-middle white-space-nowrap text-start p-2">
                        {formatCurrency(data?.total)}
                      </td>

                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                  <td className="text-center p-4" colSpan={14}>
                    No records found!
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Invoice;