import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import * as routeConstant from '../../../../common/routeConstants'
import { setActiveTab } from "../../../../store/actions/Seller";
import { decryptId } from "../../../../Encryption/Encryption";
import axios from "axios";
import { SELLERADJUSTPAYLOGS } from "../../../../common/apiConstatnts";
import { toast } from "react-toastify";
import { CommonMessages } from "../../../../common/messageConstants";
import { formatCurrency } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const AdjustPayLog = () => {

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const companyId: any = localStorage.getItem('companyId')

    const initialData = {
        date: '',
        amountPaid: "",
        amountRecd: "",
        payType: "",
        check: null,
        paymentUniqueId: 0,
        companyId: companyId,
        isActive: true,
        isDelete: false,
        sellerPaymentId: 0,
        sellerId: ID ? decryptId(ID) : 0,
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const dispatch = useDispatch()
    const sellerData = useSelector((state: any) => state.seller)
    const auctionId = sellerData.selectedAuction.value;
    const logPayment = sellerData.lstLogSellerPayment;
    const logAdjust = sellerData.lstLogSellerAdjust;
    const [currentPageAdjust, setCurrentPageAdjust] = useState(0);
    const [currentPagePay, setCurrentPagePay] = useState(0);
    const [loading, setLoading] = useState(false);

    const [logsPayment, setLogsPayment] = useState(logPayment)
    const [logsAdjust, setLogsAdjust] = useState(logAdjust)

    const PER_PAGE = 5;
    const payPageCount = Math.ceil(logPayment?.length / PER_PAGE);
    const adjustPageCount = Math.ceil(logAdjust?.length / PER_PAGE);

    const paginatePaymentData = (data: any) => {
        const firstSet = data?.slice(0, PER_PAGE);
        setLogsPayment(firstSet)
    }

    const paginateAdjustData = (data: any) => {

        const firstSet = data?.slice(0, PER_PAGE);
        setLogsAdjust(firstSet)
    }

    const handlePaymentPageClick = ({ selected: selectedPage }: any) => {
        setCurrentPagePay(selectedPage);
    }
    const handleAdjustPageClick = ({ selected: selectedPage }: any) => {
        setCurrentPageAdjust(selectedPage);
    }

    const adjustCurrentPageHandler = () => {
        const offset = currentPageAdjust * PER_PAGE
        const nextSet = logAdjust?.slice(offset, offset + PER_PAGE)
        setLogsAdjust(nextSet)
    }
    const paymentCurrentPageHandler = () => {
        const offset = currentPagePay * PER_PAGE
        const nextSet = logPayment?.slice(offset, offset + PER_PAGE)
        setLogsPayment(nextSet)
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('payment'))
    }
    const handleNext = () => {
        dispatch(setActiveTab('contractDocs'))
    }
    const getUpdatedLogData = () => {
        setLoading(true)
        axios.get(SELLERADJUSTPAYLOGS, { ...config, params: { salesRepId: sellerData?.sellerId, auctionId: auctionId } }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const adjustLogs = response.data.data.lstSellerAdjustLog;
                const paymentLogs = response.data.data.lstSellerPaytmentLog;
                dispatch(updateSeller({ ...sellerData, lstLogSellerAdjust: adjustLogs, lstLogSellerPayment: paymentLogs }))
                paginatePaymentData(paymentLogs);
                paginateAdjustData(adjustLogs);
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    useEffect(() => {
        getUpdatedLogData()
    }, [auctionId])

    useEffect(() => {
        adjustCurrentPageHandler()
    }, [currentPageAdjust])

    useEffect(() => {
        paymentCurrentPageHandler()
    }, [currentPagePay])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center">
                    <div className="col-xxl-10">
                        <div className="mt-3 mb-3 border-300">
                            <div className="mb-3">
                                <h4>Adjusts Logs</h4>
                            </div>
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit Date/Time</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit By</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant w-25" scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Description</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Amount ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Chg/CR ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Tax</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Tax Amt ($)</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {(logsAdjust?.length > 0) ?
                                                logsAdjust?.map((data: any, index: any) => {
                                                    return (
                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">
                                                                    {moment(data?.auditOn).format('MM/DD/YYYY hh:mm A').toString()}
                                                                </p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{data?.auditBy ?? '-'}</p>
                                                            </td>
                                                            <td className="align-middle p-2 w-25">
                                                                <p className="fs--1 text-1000 mb-0" data-toggle="tooltip" title={data?.description}>
                                                                    {data?.description && `${data?.description.substring(0, 33)}${data?.description.length > 33 ? '...' : ''}`}
                                                                </p>
                                                            </td>
                                                            <td className="align-middle review p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amount)}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.chgCr)}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.tax)}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.taxAmount)}</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                        <td className='text-center p-4' colSpan={7}>No records found!</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                {logsAdjust?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"←"}
                                        nextLabel={"→"}
                                        pageCount={adjustPageCount}
                                        onPageChange={handleAdjustPageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                        forcePage={currentPageAdjust}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="mt-3 mb-4 border-300">
                            <div className="mb-3">
                                <h4>Payments Logs</h4>
                            </div>
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="firstname">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit Date/Time</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="firstname">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Audit By</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="lastname">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Amount Paid ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="company">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Amount Recd ($)</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phoneNo">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Pay Type</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Check</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Date</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {logsPayment?.length > 0 ?
                                                logsPayment?.map((data: any, index: number) => {
                                                    return (
                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">
                                                                    {moment(data?.auditOn).format('MM/DD/YYYY hh:mm A').toString()}
                                                                    {/* {moment(data?.date).format('MM/DD/YYYY').toString()} */}
                                                                </p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{data?.auditBy ?? '-'}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amountPaid)}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{formatCurrency(data?.amountRecd)}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{data?.payType}</p>
                                                            </td>
                                                            <td className="align-middle p-2">
                                                                <p className="fs--1 text-1000 mb-0">{data?.check ?? '-'}</p>
                                                            </td>
                                                            <td className="align-middle customer white-space-nowrap p-2">
                                                                <p className="fs--1 text-1000 mb-0">{data?.date ? moment(data?.date).format('MM/DD/YYYY').toString() : '-'}</p>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                ) : (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                        <td className='text-center p-4' colSpan={7}>No records found!</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                {logsPayment?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"←"}
                                        nextLabel={"→"}
                                        pageCount={payPageCount}
                                        onPageChange={handlePaymentPageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                        forcePage={currentPagePay}
                                    />)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                </div>
            </div>
        </>
    )
}

export default AdjustPayLog