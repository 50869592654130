import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuctionEmailReportsActive, setCloneTab, setCreateTab, setInvoiceTemplateActive, setSellerTemplateActive, setStep } from '../../../../store/actions/AuctionAction'
import { AuctionMessages, CommonMessages } from '../../../../common/messageConstants'
import { AUCTIONEINVOICES } from '../../AuctionConstants'
import * as yup from "yup";
import { EMAIL, EMAIL_MESSAGE, SPECIALCHARS, URL, URL_MESSAGE } from "../../../../common/regexConstants";
import { ERROR_MESSAGES } from '../../../../Utils/Utils'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as routeConstant from '../../../../common/routeConstants';
import { toast } from 'react-toastify'
import { loader } from '../../../../store/actions/CommonAction'
import { GETALLTEMPLATE, GETAUCTIONREPORTSBYAUCTIONID, GETEINVOICESETTINGS, UPDATEEINVOICE } from '../../../../common/apiConstatnts'
import axios from 'axios'
import handleError from '../../../../Utils/ApiCommonErrorMessages'
import EmailTemplate from './EmailTemplate';

const InvoiceEmailTemplate = () => {
    const activeInvoiceTemplate = useSelector((state: any) => state.auction.invoiceTemplate)
    const [activeStep, setActiveStep] = useState(1)
    const [activeLabel, setActiveLabel] = useState("Invoice Balance Due")
    const [btnTxt, setBtnTxt] = useState<any>("")
    const [balanceDue, setBalanceDue] = useState('');
    const [balancePaid, setBalancePaid] = useState('');
    const dispatch = useDispatch();
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUCTIONEINVOICES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUCTIONEINVOICES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])
    // Active step
    useEffect(() => {
        setActiveStep(activeInvoiceTemplate.activeStep)
    }, [activeInvoiceTemplate.activeStep])

    useEffect(() => {
        setActiveLabel(activeInvoiceTemplate.activeLabel)
    }, [activeInvoiceTemplate.activeLabel])

    // Active step change 
    const handleChange = (key: string) => {
        if (key === 'BalanceDue') {
            dispatch(setInvoiceTemplateActive({ activeStep: 1, activeLabel: "Invoice Balance Due" }))
        } else if (key === 'BalancePaid') {
            dispatch(setInvoiceTemplateActive({ activeStep: 2, activeLabel: "Invoice Balance Paid" }))
        };
    }
    // Inititial data for balance due and paid 
    const initialData = {
        einvoiceID: 0,
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
        invoiceEmailTemplate: "",
        sellerEmailTemplate: "",
        invoiceEmailTemplateValue: "",
        sellerEmailTemplateValue: "",
        ccAddress: "",
        bccAddress: "",
        fromAddress: "",
        replyToAddress: "",
        emailInvoiceFooterText: "",
        correspondance: null,
        paymentURL: null,
        excludeTextofInvoiceInEmailBody: true,
        includePDFVersionWithBidderEInvoice: false,
        auctionReportTemplate: 1,
        subject: "",
        cc2Address: "",
        includeSellerNumberinSubject: false,
        sendPickupSummarytoSellerSummaryReportEmail: false,
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        isDelete: false,
    }

    const [formValues, setFormValues] = useState<any>(initialData);
    const [isDueError, setDueError] = useState(false)
    const [isPaidError, setPaidError] = useState(false)
    // Schema 
    const schema: any = yup.object().shape({
        subject: yup.string().required("Subject is required"),
        fromAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        replyToAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        ccAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        cc2Address: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        emailInvoiceFooterText: yup
            .string()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, 'Please enter valid string'),
    });

    // Form defination for balance due 
    const {
        register: registerBalanceDue,
        handleSubmit: handleSubmitBalanceDue,
        reset: resetBalanceDue,
        watch: watchBalanceDue,
        setValue: setValueBalanceDue,
        formState: { errors: errorsBalanceDue },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: "onChange" });

    // Form defination for balance Paid 
    const {
        register: registerBalancePaid,
        handleSubmit: handleSubmitBalancePaid,
        reset: resetBalancePaid,
        watch: watchBalancePaid,
        setValue: setValueBalancePaid,
        formState: { errors: errorsBalancePaid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: "onChange" });

    const onSubmitBalanceDue = (data: any) => {
        if (balanceDue === "") {
            setDueError(true)
            return
        }
        submitTemplate(data);
    }

    const onSubmitBalancePaid = (data: any) => {
        if (balancePaid === "") {
            setPaidError(true)
            return
        }
        submitTemplate(data);
    }

    const submitTemplate = (data: any) => {
        const payload = {
            companyId: localStorage.getItem('companyId'),
            einvoiceID: data.einvoiceID ? parseInt(data.einvoiceID) : 0,
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            invoiceEmailTemplate: data?.invoiceEmailTemplate.toString() ?? "",
            invoiceEmailTemplateValue: activeInvoiceTemplate.activeStep == 1 ? balanceDue : balancePaid,
            sellerEmailTemplate: "",
            sellerEmailTemplateValue: "",
            ccAddress: data?.ccAddress,
            bccAddress: data?.bccAddress,
            fromAddress: data?.fromAddress,
            replyToAddress: data?.replyToAddress,
            emailInvoiceFooterText: data?.emailInvoiceFooterText,
            excludeTextofInvoiceInEmailBody: data?.excludeTextofInvoiceInEmailBody,
            includePDFVersionWithBidderEInvoice: data?.includePDFVersionWithBidderEInvoice,
            auctionReportTemplate: activeInvoiceTemplate.activeStep,
            subject: data?.subject,
            cc2Address: data?.cc2Address,
            isActive: true,
            isDelete: false,
            includeSellerNumberinSubject: false,
            sendPickupSummarytoSellerSummaryReportEmail: false
        }
        // Update email reports 
        axios.post(UPDATEEINVOICE, payload, config).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUCTIONEINVOICES]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUCTIONEINVOICES]))
                }
                toast.success(AuctionMessages.eInvoiceMessages.eInvoiceUpdates, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                handleNext();

            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handlePrev = () => {
        // When tab is balance due then previous means
        if (activeInvoiceTemplate.activeStep == 1) {
            dispatch(setStep(4));
        } else {
            dispatch(setInvoiceTemplateActive({ activeStep: 1, activeLabel: "Invoice Balance Due" }))
        }
    }

    const handleNext = () => {
        // When tab is balance due then previous means
        if (activeInvoiceTemplate.activeStep == 1) {
            dispatch(setInvoiceTemplateActive({ activeStep: 2, activeLabel: "Invoice Balance Paid" }))
        } else {
            dispatch(setAuctionEmailReportsActive({ activeStep: 2, activeLabel: "Seller Email Template" }))
        }
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getTemplateData = () => {
        dispatch(loader(true));
        const aId = clonedTabs.includes(AUCTIONEINVOICES) ? cloneAuctionId : auctionId
        axios.get(`${GETALLTEMPLATE}?templateType=4`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                const templateContent = response.data.data;
                dispatch(loader(true));
                axios.get(`${GETAUCTIONREPORTSBYAUCTIONID}?AuctionId=${aId}&auctionReportTemplate=${activeInvoiceTemplate.activeStep}`, config).then((response: any) => {
                    if (response?.data?.success === "Fail") {
                        dispatch(loader(false));
                        if (templateContent?.length > 0) {
                            // Check if invoiceBalanceDue exists
                            const hasInvoiceBalanceDue = templateContent.some((item: any) => item?.strTemplateFor === "invoiceBalanceDue");
                            // Check if invoiceBalancePaid exists
                            const hasInvoiceBalancePaid = templateContent.some((item: any) => item?.strTemplateFor === "invoiceBalancePaid");

                            if (activeInvoiceTemplate.activeStep == 1) {
                                if (hasInvoiceBalanceDue) {
                                    const filteredData = templateContent.filter((item: any) => item?.strTemplateFor == "invoiceBalanceDue");
                                    setBalanceDue(filteredData[0]?.templateContent);
                                    setValueBalanceDue('invoiceEmailTemplate', filteredData[0]?.templatemasterId)
                                } else {
                                    setBalanceDue('');
                                }
                            } else {
                                if (hasInvoiceBalancePaid) {
                                    const filteredData = templateContent.filter((item: any) => item?.strTemplateFor == "invoiceBalancePaid");
                                    setBalancePaid(filteredData[0]?.templateContent);
                                    setValueBalancePaid('invoiceEmailTemplate', filteredData[0]?.templatemasterId)
                                } else {
                                    setBalancePaid('');
                                }
                            }
                        } else {
                            setBalanceDue('');
                            setBalancePaid('');
                        }

                    }
                    else {
                        dispatch(loader(false));
                        let respData = response.data.data;
                        if (!clonedTabs.includes(AUCTIONEINVOICES) && cloneAuctionId > 0) {
                            respData = { ...respData, einvoiceID: 0 }
                        }
                        if (activeInvoiceTemplate.activeStep == 1) {
                            resetBalanceDue(respData)
                            setBalanceDue(respData?.invoiceEmailTemplateValue)
                        } else {
                            resetBalancePaid(respData)
                            setBalancePaid(respData?.invoiceEmailTemplateValue)
                        }
                    }
                }, (error) => {
                    dispatch(loader(false))
                    if (error?.response?.status !== 401) {
                        toast.error("Some error occurred!", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error);
                })
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    // Get default data from einvoice setting
    useEffect(() => {
        getTemplateData();
    }, [activeInvoiceTemplate.activeStep])

    useEffect(() => {
        dispatch(loader(true))
        axios.get(GETEINVOICESETTINGS, config).then((response) => {
            dispatch(loader(false));
            const eInvoiceData = response.data.data;
            delete eInvoiceData.autoInvoiceCashDiscountText
            delete eInvoiceData.einvoicesettingsId
            delete eInvoiceData.modifiedBy
            delete eInvoiceData.updatedBy
            delete eInvoiceData.updatedOn
            delete eInvoiceData.createdBy
            delete eInvoiceData.createdOn
            delete eInvoiceData.isDelete
            const updatedData = { ...eInvoiceData, fromAddress: eInvoiceData.auctionEmail, replyToAddress: eInvoiceData.auctionEmail }
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                // reset(updatedData);
                const values = [
                    { key: 'ccAddress', value: eInvoiceData.ccAddress },
                    { key: 'bccAddress', value: eInvoiceData.bccAddress },
                    { key: 'fromAddress', value: eInvoiceData.auctionEmail },
                    { key: 'replyToAddress', value: eInvoiceData.auctionEmail },
                    { key: 'emailInvoiceFooterText', value: eInvoiceData.emailInvoiceFooterText }
                ];

                values.forEach(({ key, value }) => setValueBalanceDue(key, value));
                values.forEach(({ key, value }) => setValueBalancePaid(key, value));

            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
        return
        // }
    }, [])


    return (
        <div className="container-fluid for-fix-bottom-space">
            {/* 2 different submit for balanceDue and balancePaid */}
            <form onSubmit={activeInvoiceTemplate.activeStep == 1 ? handleSubmitBalanceDue(onSubmitBalanceDue) : handleSubmitBalancePaid(onSubmitBalancePaid)}>
                <div className="for-fix-bottom-space">
                    <Tabs
                        activeKey={activeStep === 1 ? "BalanceDue" : "BalancePaid"}
                        defaultActiveKey="BalanceDue"
                        className="nav-underline border-bottom  nav nav-tabs mb-3"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="BalanceDue" title="Invoice Balance Due">
                            <EmailTemplate register={registerBalanceDue} activeStep={activeInvoiceTemplate} ckData={balanceDue} setCkData={setBalanceDue} errors={errorsBalanceDue} isError={isDueError} />
                        </Tab>
                        <Tab eventKey="BalancePaid" title="Invoice Balance Paid">
                            <EmailTemplate register={registerBalancePaid} activeStep={activeInvoiceTemplate} ckData={balancePaid} setCkData={setBalancePaid} errors={errorsBalancePaid} isError={isPaidError} />
                        </Tab>
                    </Tabs>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
                    <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                </div>
            </form>
        </div>
    )
}

export default InvoiceEmailTemplate