import React, { useEffect, useState } from "react";
import InnerHeaderTab from "../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../Sidebar/Sidebar";
import * as routeConstant from '../../common/routeConstants'
import { Breadcrumb } from "../../common/interface";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ReportsIndex from "./ReportsIndex";
import { ReportsLinkData } from "../../common/constants";
import CommonHelmet from "../../common/HelmetWrapper";

const Reports = () => {

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Reports', url: '' }
    ]

    const [loading, setLoading] = useState<boolean>(false);
    const [reportsData, setReportsData] = useState<any>({});
    const [activeTab, setActiveTab] = useState<any>('Seller Reports');
    const commonData = useSelector((state: any) => state.global);
    const [searchText, setSearchText] = useState('')

    const handleChange = (key: string) => {
        setActiveTab(key)
        setSearchText('')
        setReportsData(ReportsLinkData?.find((i: any) => i.label == key))
    }

    useEffect(() => {
        setReportsData(ReportsLinkData?.find((i: any) => i.label == activeTab))
    }, []);


    return (
        <>
            <CommonHelmet title={"Reports - "} name={"reports"} content={"Reports"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderTab breadCrumb={breadcrumbData} title={'Reports'}>

                    <Tabs
                        defaultActiveKey={activeTab}
                        activeKey={activeTab}
                        className="nav-underline"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="Seller Reports" title="Seller Reports"></Tab>
                        <Tab eventKey="Sales Rep Reports" title="Sales Rep Reports"></Tab>
                        <Tab eventKey="Inventory Reports" title="Inventory Reports"></Tab>
                        <Tab eventKey="Buyer Reports" title="Buyer Reports"></Tab>
                        <Tab eventKey="Other Reports" title="Other Reports"></Tab>
                    </Tabs>
                </InnerHeaderTab >
                {
                    (activeTab === 'Seller Reports') ?
                        <ReportsIndex data={reportsData} setSearchText={setSearchText} searchText={searchText} />
                        :
                        (activeTab === 'Sales Rep Reports') ?
                            <ReportsIndex data={reportsData} setSearchText={setSearchText} searchText={searchText} />
                            :
                            (activeTab === 'Inventory Reports') ?
                                <ReportsIndex data={reportsData} setSearchText={setSearchText} searchText={searchText} />
                                :
                                (activeTab === 'Buyer Reports') ?
                                    <ReportsIndex data={reportsData} setSearchText={setSearchText} searchText={searchText} />
                                    :
                                    (activeTab === 'Other Reports') ?
                                        <ReportsIndex data={reportsData} setSearchText={setSearchText} searchText={searchText} />
                                        :
                                        <></>
                }
            </div >
        </>
    )
}

export default Reports;