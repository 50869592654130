import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddressDates from "./AddressDates";
import WebText from "./WebText";
import AutomatedEmail from "./AutomatedEmail";
import LogoPhotos from "./LogoPhotos";
import { Tab, Tabs } from "react-bootstrap";
import { setAuctionId, setAuctionInfoActive, setMode } from "../../../../store/actions/AuctionAction";
import { useNavigate, useParams, useLocation } from "react-router";
import * as routeConstant from '../../../../common/routeConstants'
import { decryptId } from "../../../../Encryption/Encryption";

const AuctionInfo = ({ selectedOption, setSelectedOption }: any) => {
    const dispatch = useDispatch();
    const params = useParams()
    const location = useLocation();
    const type = location.state?.type ?? null;
    const typeFromStore = useSelector((state: any) => state.auction.mode);
    const ID = params.id ? decryptId(params.id) : "";
    const [modeType] = useState<any>(type === null ? typeFromStore : type)
    const navigate = useNavigate()
    const getActive = useSelector((state: any) => state.auction.auctionInfo);
    const auctionId = useSelector((state: any) => state.auction.auctionId);
    const clonedAuctionId = useSelector((state: any) => state.auction.clonedAuctionId);
    const [active, setActive]: any = useState({});
    const [showEnabled, setShowEnabled] = useState<boolean>(false)

    const handleTabChange = (key: any) => {
        if (key === "Address&Dates") {
            dispatch(setAuctionInfoActive({ activeStep: 1, activeLabel: "Address & Dates" }));
        } else if (key === "Logo&Photos") {
            dispatch(setAuctionInfoActive({ activeStep: 2, activeLabel: "Logo & Photos" }));
        } else if (key === "AutomatedEmail") {
            dispatch(setAuctionInfoActive({ activeStep: 3, activeLabel: "Automated Email" }));
        } else if (key === "WebText") {
            dispatch(setAuctionInfoActive({ activeStep: 4, activeLabel: "Web Text" }));
        }
    };

    useEffect(() => {
        setActive(getActive);
    }, [getActive])

    useEffect(() => {
        setActive(getActive);
        if (!params) {
            navigate(routeConstant.NEWAUCTION);
        }
    }, [getActive, auctionId, params]);

    useEffect(() => {
        let checkCondition: boolean
        if (modeType === "create" || modeType === "edit") {
            checkCondition = auctionId > 0 ? true : false
        } else {
            checkCondition = clonedAuctionId > 0 ? true : false
        }
        setShowEnabled(checkCondition)
    }, [auctionId, clonedAuctionId])

    useEffect(() => {
        if (modeType !== null) {
            dispatch(setMode(modeType))
        }
        if (ID) {
            dispatch(setAuctionId(ID))
        }
    }, [modeType])

    return (
        <>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row top_border justify-content-center">
                    <div className="col-xxl-10 pt-4 pb-4">
                        <div className="col-12">
                            <h4 className="mb-2">Auction Info - {active.activeLabel}</h4>
                        </div>
                        <Tabs
                            activeKey={active.activeStep === 1 ? "Address&Dates" : active.activeStep === 2 ? "Logo&Photos" : active.activeStep === 3 ? "AutomatedEmail" : "WebText"}
                            className="nav-underline border-bottom  nav nav-tabs mb-3"
                            onSelect={(key) => handleTabChange(key)}
                        >
                            <Tab eventKey="Address&Dates" title="Address & Dates" ></Tab>
                            <Tab eventKey="Logo&Photos" title="Logo & Photos" disabled={!(showEnabled)}></Tab>
                            <Tab eventKey="AutomatedEmail" title="Automated Email" disabled={!(showEnabled)}></Tab>
                            <Tab eventKey="WebText" title="Web Text" disabled={!(showEnabled)}></Tab>
                        </Tabs>
                        {active.activeStep === 1 ? (
                            <AddressDates auctionId={auctionId} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                        ) : active.activeStep === 2 ? (
                            <LogoPhotos />
                        ) : active.activeStep === 3 ? (
                            <AutomatedEmail />
                        ) : active.activeStep === 4 ? (
                            <WebText />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuctionInfo;
