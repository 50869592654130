import React from "react";
import { Link } from "react-router-dom";
import * as routeConstant from '../../../../common/routeConstants'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, setAuctionInfoActiveTab } from "../../../../store/actions/Seller";
import { CommonMessages } from "../../../../common/messageConstants";

const SalesTax = () => {

    const dispatch = useDispatch();

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('commision'));
    }

    const handlePrevious = () => {
        // dispatch(setActiveTab('notes'))
        dispatch(setAuctionInfoActiveTab('sales'));
    }

    return (
        <>
            <div className="container-fluid for-fix-bottom-space">
              <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="mt-3 mb-4 border p-4 bg-light fs-16 border-radius text-center">
                            <span>Under Development</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                        {/* <button className="btn btn-primary ms-2" type="button" onClick={handleNext}>{CommonMessages.saveNext}</button> */}
                        <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                     </div>
            </div>
        </>
    )
}

export default SalesTax;