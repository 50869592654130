import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface InvoiceState {
    invoiceData: []
    radioValue: {}
    adjust: []
    payment: []
}

const initialState: InvoiceState = {
    invoiceData: [],
    radioValue: {},
    adjust: [],
    payment: []
};

export const InvoiceReducer = (
    state: InvoiceState = initialState,
    action: Action
) => {

    switch (action.type) {
        case actions.SETINVOICES: {
            return { ...state, adjust: action?.payload }
        };
        case actions.SETINVOICESUPDATE: {
            return { ...state, adjust: action?.payload }
        };
        case actions.SETINVOICESPAYMENT: {
            return { ...state, payment: action?.payload }
        };
        case actions.SETINVOICEPAYMENTUPDATE :{
            return{...state, payment :action?.payload}
        }
        case actions.ADDINVOICE:
            return {
                ...state,
                invoice: [...state?.invoiceData, action?.payload]
            };
        case actions.RESETINVOICE:
            return initialState
        default:
            return state;
    }
}