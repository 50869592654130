import React, { useCallback, useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
const containerStyle = {
    width: '1100px',
    height: '600px'
};

const GoogleMapContainer = ({ toggleModal, setToggleValue, setLatitude, setLongitude, location, latLog, lat, long }: any) => {
    const [center, setCenter] = useState({ lat: lat ? parseFloat(lat) : -3.745, lng: long ? parseFloat(long) : -38.523 });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCctFdaMeJboFN7ryzo3eApSZdZLakSUd4"
    });

    const [map, setMap] = useState(null);
    useEffect(() => {
        // Update center when location prop changes
        if (location && latLog?.lat() && latLog.lng()) {
            setCenter({ lat: latLog?.lat(), lng: latLog.lng() });
        }
    }, [location, latLog]);

    const onLoad = useCallback(function callback(mapInstance: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        mapInstance.fitBounds(bounds);
        setMap(mapInstance);
    }, []);

    return (

        isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                mapTypeId="satellite"
            >
                {location && center?.lat && (
                    <Marker position={{ lat: center?.lat, lng: center?.lng }} />
                )}
            </GoogleMap >
        ) : <></>
    )
}

export default GoogleMapContainer