import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import ReactPaginate from "react-paginate";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants'
import axios from "axios";
import { GETLISTINGNOTIFICATIONS } from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import { Link, } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import { UTCdateConverter } from "../../common/constants";
import { formatNumber } from "../../Utils/Utils";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { generateInventoryImportLogsCsv, generateInventoryImportLogsPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Inventory Import Logs', url: routeConstant.TRANSPORT }
]

const ImportLogs = () => {
    const basePath = `${process.env.REACT_APP_API_URL}\\`
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterNotification, setFilterNotification] = useState([]);
    const [notifications, setNotifications] = useState<[]>([]);
    const [originalNotifications, setOriginalNotifications] = useState<[]>([]);
    const [searchText, setSearchText] = useState('')

    const PER_PAGE = 10;
    const pageCount = Math.ceil(notifications.length / PER_PAGE);

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalNotifications?.filter((x: any) => x.module?.toLocaleLowerCase()?.includes(keyword) || x.fileName?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...notifications];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        paginateData(sortedData);
        setCurrentPage(0);
    };

    const paginateData = (data: any) => {
        setNotifications(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterNotification(firstSet)
    }

    const getNotifications = () => {
        // setLoading(true)
        axios.get(GETLISTINGNOTIFICATIONS).then((response) => {
            // setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            let data = response.data.data;
            data = data.map((i: any, index: any) => {
                return {
                    ...i,
                    srNo: index + 1,
                    responseFilePath: i.responseFilePath ? `${basePath}${i.responseFilePath.split('AdminAPI\\')[1]}` : '',
                    sourceFilePath: i.sourceFilePath ? `${basePath}${i.sourceFilePath.split('AdminAPI\\')[1]}` : '',
                }
            })
            paginateData(data)
            setOriginalNotifications(data)
        }).catch((error) => {
            // setLoading(false);
            handleError(error);
          });
    }

    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = notifications?.slice(offset, offset + PER_PAGE)
        setFilterNotification(nextSet)
    }, [currentPage])

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Inventory Import Logs'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=> generateInventoryImportLogsCsv(notifications)} disabled={filterNotification.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateInventoryImportLogsPdf(notifications)} disabled={filterNotification.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>
                    </div>
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-5 border-300">
                                                <div>
                                                    <div className="table-responsive mx-n1 px-1 ">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant " scope="col">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>#</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="module">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Module Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'module')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'module')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Source File Name</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Response File Name</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Total Count</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Success Count</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Failed Count</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="createdOn">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Import Date ({defaultTimezone})</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'createdOn')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'createdOn')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterNotification.length > 0 ? filterNotification.map((notification: any, index: any) => {
                                                                    const importDate = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{notification.srNo}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{notification.module ?? "-"}</p>
                                                                            </td>
                                                                            <td className="align-middle review p-2">
                                                                                <Link className="text-decoration-underline" data-toggle="tooltip" data-placement="top" title="Click to download" type="button" data-bs-dismiss="modal" to={notification.sourceFilePath}>
                                                                                    {notification.sourceFilePath ? notification.sourceFilePath.split('\\')[3] : ""}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="align-middle review p-2">
                                                                                <Link className="text-decoration-underline" data-toggle="tooltip" data-placement="top" title="Click to download" type="button" data-bs-dismiss="modal" to={notification.responseFilePath}>
                                                                                    {notification.responseFilePath ? notification.responseFilePath.split('\\')[3] : ""}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{formatNumber(notification.totalCount) ?? 0}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{formatNumber(notification.successCount) ?? 0}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{formatNumber(notification.failedCount) ?? 0}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="mb-0 text-900">{notification.createdOn ? importDate.format('MM/DD/YYYY hh:mm A').toString() : "-"}</p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={8}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterNotification.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default ImportLogs