import TemplatesListing from './TemplateListing';
import * as routeConstant from '../../common/routeConstants'
import AddEditTemplate from './AddEditTemplate';
import { GETALLTEMPLATE } from '../../common/apiConstatnts';
import { templateTypes } from '../../common/constants';

type propsType = {
    templateTypeName: 'Auction' | 'SMS' | 'Report' | 'Invoice' | 'Seller' | 'SalesRep' | 'Inventory' | 'SalesRecord' | 'Buyer' | 'ContactUs' | 'Appraisal' | 'Partners' | 'Authorization'| 'Email';
    isAddEditMode?: boolean;
}

const CoreTemplate = ({ templateTypeName, isAddEditMode = false }: propsType) => {
    
    return isAddEditMode ?
        <div>
            {templateTypeName === 'Auction' && <AddEditTemplate templateTypeName='Auction' objectType='AuctionTempOptions' templateTypeId={templateTypes.auction} templateList={routeConstant.AUCTIONTEMPLATES} />}
            {templateTypeName === 'SMS' && <AddEditTemplate templateTypeName='SMS' objectType='SMSType' templateTypeId={templateTypes.sms} templateList={routeConstant.SMSTEMPLATES}/>}
            {templateTypeName === 'Report' && <AddEditTemplate templateTypeName='Report' objectType='ReportTempOptions' templateTypeId={templateTypes.report} templateList={routeConstant.REPORTTEMPLATES}/>}
            {templateTypeName === 'Invoice' && <AddEditTemplate templateTypeName='Invoice' objectType='InvoiceTempOptions' templateTypeId={templateTypes.invoice} templateList={routeConstant.INVOICETEMPLATES}/>}
            {templateTypeName === 'Seller' && <AddEditTemplate templateTypeName='Seller' objectType='SellerTempOptions' templateTypeId={templateTypes.seller} templateList={routeConstant.SELLERREPORT}/>}
            {templateTypeName === 'SalesRep' && <AddEditTemplate templateTypeName='SalesRep' objectType='SalesRepTempOptions' templateTypeId={templateTypes.salesRep} templateList={routeConstant.SALESREPTEMPLATES}/>}
            {templateTypeName === 'Inventory' && <AddEditTemplate templateTypeName='Inventory' objectType='InventoryTempOptions' templateTypeId={templateTypes.inventory} templateList={routeConstant.INVENTORYTEMPLATES}/>}
            {templateTypeName === 'SalesRecord' && <AddEditTemplate templateTypeName='SalesRecord' objectType='SalesRecordOptions' templateTypeId={templateTypes.salesRecord} templateList={routeConstant.SALESRECORDTEMPLATES}/>}
            {templateTypeName === 'Buyer' && <AddEditTemplate templateTypeName='Buyer' objectType='CustomerTempOptions' templateTypeId={templateTypes.customer} templateList={routeConstant.CUSTOMERTEMPLATES}/>}
            {templateTypeName === 'ContactUs' && <AddEditTemplate templateTypeName='ContactUs' objectType='ContactUsTempOptions' templateTypeId={templateTypes.contactUs} templateList={routeConstant.CONTACTUSTEMPLATES}/>}
            {templateTypeName === 'Appraisal' && <AddEditTemplate templateTypeName='Appraisal' objectType='AppraisalTempOptions' templateTypeId={templateTypes.appraisal} templateList={routeConstant.APPRAISALTEMPLATES}/>}
            {templateTypeName === 'Partners' && <AddEditTemplate templateTypeName='Partners' objectType='PartnersTempOptions' templateTypeId={templateTypes.partners} templateList={routeConstant.PARTNERTEMPLATES}/>}
            {templateTypeName === 'Authorization' && <AddEditTemplate templateTypeName='Authorization' objectType='AuthorizationOptions' templateTypeId={templateTypes.authorization} templateList={routeConstant.AUTHORIZATIONTEMPLATES}/>}
            {templateTypeName === 'Email' && <AddEditTemplate templateTypeName='Email' objectType='EmailOptions' templateTypeId={templateTypes.email} templateList={routeConstant.EMAILTEMPLATES}/>}
        </div> :
        <div>
            {templateTypeName === 'Auction' && <TemplatesListing templateTypeName="Auction" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDAUCTIONTEMPLATES} editActionRedirectUrl={routeConstant.EDITAUCTIONTEMPLATES} templateTypeId={templateTypes.auction} />}

            {templateTypeName === 'SMS' && <TemplatesListing templateTypeName="SMS" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDSMSTEMPLATES} editActionRedirectUrl={routeConstant.EDITSMSTEMPLATES} templateTypeId={templateTypes.sms} />}

            {templateTypeName === 'Report' && <TemplatesListing templateTypeName="Report" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDREPORTTEMPLATES} editActionRedirectUrl={routeConstant.EDITREPORTTEMPLATES} templateTypeId={templateTypes.report} />}

            {templateTypeName === 'Invoice' && <TemplatesListing templateTypeName="Invoice" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDINVOICETEMPLATES} editActionRedirectUrl={routeConstant.EDITINVOICETEMPLATES} templateTypeId={templateTypes.invoice} />}

            {templateTypeName === 'Seller' && <TemplatesListing templateTypeName="Seller" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDSELLERTEMPLATES} editActionRedirectUrl={routeConstant.EDITSELLERTEMPLATES} templateTypeId={templateTypes.seller} />}

            {templateTypeName === 'SalesRep' && <TemplatesListing templateTypeName="SalesRep" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDSALESREPTEMPLATES} editActionRedirectUrl={routeConstant.EDITSALESREPTEMPLATES} templateTypeId={templateTypes.salesRep} />}

            {templateTypeName === 'Inventory' && <TemplatesListing templateTypeName="Inventory" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDINVENTORYTEMPLATES} editActionRedirectUrl={routeConstant.EDITINVENTORYTEMPLATES} templateTypeId={templateTypes.inventory} />}

            {templateTypeName === 'SalesRecord' && <TemplatesListing templateTypeName="SalesRecord" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDSALESRECORDTEMPLATES} editActionRedirectUrl={routeConstant.EDITSALESRECORDTEMPLATES} templateTypeId={templateTypes.salesRecord} />}

            {templateTypeName === 'Buyer' && <TemplatesListing templateTypeName="Buyer" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDCUSTOMERTEMPLATES} editActionRedirectUrl={routeConstant.EDITCUSTOMERTEMPLATES} templateTypeId={templateTypes.customer} />}

            {templateTypeName === 'ContactUs' && <TemplatesListing templateTypeName="ContactUs" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDCONTACTUSTEMPLATES} editActionRedirectUrl={routeConstant.EDITCONTACTUSTEMPLATES} templateTypeId={templateTypes.contactUs} />}

            {templateTypeName === 'Appraisal' && <TemplatesListing templateTypeName="Appraisal" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDAPPRAISALTEMPLATES} editActionRedirectUrl={routeConstant.EDITAPPRAISALTEMPLATES} templateTypeId={templateTypes.appraisal} />}

            {templateTypeName === 'Partners' && <TemplatesListing templateTypeName="Partners" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDPARTNERSTEMPLATES} editActionRedirectUrl={routeConstant.EDITPARTNERSTEMPLATES} templateTypeId={templateTypes.partners} />}

            {templateTypeName === 'Authorization' && <TemplatesListing templateTypeName="Authorization" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDAUTHORIZATIONTEMPLATES} editActionRedirectUrl={routeConstant.EDITAUTHORIZATIONTEMPLATES} templateTypeId={templateTypes.authorization} />}
            {templateTypeName === 'Email' && <TemplatesListing templateTypeName="Email" apiEndPoint={GETALLTEMPLATE} addActionRedirectUrl={routeConstant.ADDEMAILTEMPLATES} editActionRedirectUrl={routeConstant.EDITEMAILTEMPLATES} templateTypeId={templateTypes.email} />}
        </div>;
};
export default CoreTemplate;