import React, { useEffect, useRef, useState } from 'react'
import Datetime, { DatetimepickerProps } from "react-datetime";
import "react-datetime/css/react-datetime.css";

type DateTimeProps = DatetimepickerProps & {
    // Add any additional props specific to your DateTime component
};

const DateTime: React.FC<DateTimeProps> = (props) => {
    

    const inputElement = useRef<HTMLInputElement>(null);
    const [cursorPosition, setCursorPosition] = useState<number | null>(0);

    useEffect(() => {
        if (inputElement.current && cursorPosition) {
            inputElement.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [cursorPosition]);

    const onInput = (event: React.FormEvent<HTMLInputElement>) => {
        if (inputElement.current) {
            setCursorPosition(inputElement.current.selectionStart);
        }
        if (props.inputProps && props.inputProps.onInput) {
            props.inputProps.onInput(event);
        }
    };

    const inputProps = {
        ...props.inputProps,
        ref: inputElement,
        onInput: (event: React.FormEvent<HTMLInputElement>) => {
            if (props.inputProps && props.inputProps.onInput) {
                props.inputProps.onInput(event);
            }
            onInput(event);
        }
    };

    return (
        <div>
            <Datetime {...props} inputProps={inputProps} />
        </div>
    );
}


export default DateTime