import GooglePlacesAutocomplete from 'react-google-autocomplete';

interface GooglePlacesWithCSCProps {
    value: any;
    handleChangeAddressLine: any;
    setValue: any;
    zipCodeType: any;
    setAddress: any;
    setCountryCode: any;
    onAddressChange: any;
    latLog: any;
    onAddressSelected?: () => void
}

const GooglePlacesWithCSCNew = ({
    value,
    handleChangeAddressLine,
    setValue,
    zipCodeType,
    setAddress,
    setCountryCode,
    onAddressChange,
    latLog,
    onAddressSelected
}: GooglePlacesWithCSCProps) => {

    const handlePlaceSelect = (place: any, ftype: any) => {
        let country = "";
        let state = "";
        let city = "";
        let zipCode = "";
        let address1 = "";
        let county = "";
        const formattedAddress = place.formatted_address;
        const addressComponents = place.address_components;
        // if (latLog) {
        const latitudeValue = place?.geometry?.location?.lat();
        const longitudeValue = place?.geometry?.location?.lng();
        setValue('latitude', latitudeValue)
        setValue('longitude', longitudeValue)
        // }

        setValue('address2', "")
        setValue('county1', "")

        for (const component of addressComponents) {
            const types = component.types;
            if (types.includes("country")) {
                country = component.long_name;
                setValue('country1', country);
                const countryCode = component.short_name.toLowerCase();
                onAddressChange(country, countryCode);
                // setCountryCode && setCountryCode(component.short_name.toLowerCase())
                if (setCountryCode) {
                    setValue('phone', '');
                    setValue('mobile', '');
                    setCountryCode(component.short_name.toLowerCase())
                }
            } else if (types.includes("administrative_area_level_1")) {
                state = component.long_name;
                setValue('state1', state)
            } else if (types.includes("locality")) {
                city = component.long_name;
                setValue('city1', city)

                if (formattedAddress.startsWith(component.long_name)) {
                    address1 = component.long_name;
                    setAddress(address1)
                    setValue('add1', address1)
                } else {
                    const startIndex = formattedAddress.indexOf(component.long_name);
                    address1 = formattedAddress.substring(0, startIndex).trim();
                    if (address1.endsWith(",")) {
                        address1 = address1.substring(0, address1.length - 1).trim();
                    }
                    setValue('add1', address1)
                    setAddress(address1)
                }
            } else if (types.includes("administrative_area_level_2")) {
                county = component.long_name;
                const county1 = county.replace(/ County$/, '');
                setValue('county1', county1);
            } else {
                setValue('city1', city);
            }
            if (types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        setValue(zipCodeType, zipCode)


        if (country && state && city && address1 && zipCode) {
            onAddressSelected && onAddressSelected()
        }

    };


    // const handlePlaceSelect = (place: any, ftype: any) => {
    //     let country = "";
    //     let state = "";
    //     let city = "";
    //     let zipCode = "";
    //     let address1 = "";
    //     const addressComponents = place.address_components;
    //     setValue('address2', "");   
    //     addressComponents.forEach((component: any) => {
    //         const types = component.types;
    //         if (types.includes("country")) {
    //             country = component.long_name;
    //             setValue('country1', country);
    //             const countryCode = component.short_name.toLowerCase();
    //             onAddressChange(country, countryCode);
    //             setCountryCode && setCountryCode(component.short_name.toLowerCase())
    //         } else if (types.includes("administrative_area_level_1")) {
    //             state = component.long_name;
    //             setValue('state1', state);
    //         } else if (types.includes("locality")) {
    //             city = component.long_name;
    //             setValue('city1', city);
    //         } else if (types.includes("postal_code")) {
    //             zipCode = component.long_name;
    //         }
    //     });    
    //     // Set the full formatted address directly to the address input field
    //     setValue('add1', place.formatted_address);
    //     setAddress(place.formatted_address);    
    //     // Set the zip code
    //     setValue(zipCodeType, zipCode);
    // };

    return (
        <GooglePlacesAutocomplete
            apiKey="AIzaSyCctFdaMeJboFN7ryzo3eApSZdZLakSUd4"
            value={value}
            onChange={handleChangeAddressLine}
            className="form-control"
            onLoadFailed={(error: any) => console.error('Error loading Google Places Autocomplete: ', error)}
            onPlaceSelected={handlePlaceSelect}
            options={{
                types: ['geocode', 'establishment'],
            }}
        />
    )
}

export default GooglePlacesWithCSCNew;