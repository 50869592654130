import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import * as routeConsant from "../../common/routeConstants";
import { Breadcrumb } from "../../common/interface";
import {
  GETALLCATEGORIES,
  DELETECATEGORIES,
  ADVANCEFILTERCATEGORY,
  GETCOMMONVALUE,
  CREATECATEGORYWITHTEXABLE,
  UPDATECATEGORYWITHTEXABLE,
  GETCATEGORYWITHTEXABLE,
  GETCATEGORIESBYID,
} from "../../common/apiConstatnts";
import axios from "axios";
import { Dropdown, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Select from "react-select";
import { activationStatus } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import Helper from "../../common/helpers";
import * as types from "../../common/permissionTypes";
import { wordCounter } from "../../common/constants";
import { CategoryMessages, errorMessages } from "../../common/messageConstants";
import {
  ERROR_MESSAGES,
  trimObjectValues,
  useDebounce,
} from "../../Utils/Utils";
import AsyncPagination from "../../common/AsyncPagination";
import { setValuesData } from "../../store/actions/AuctionAction";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConsant.DASHBOARD },
  { label: "Master Categories", url: routeConsant.CATEGORIES },
];
interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const Categories = () => {
  const initialData = {
    categoryTitle: "",
    description: "",
    taxCode:"P0000000",
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<[]>([]);
  const [originalCategories, setOriginalCategories] = useState<[]>([]);
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [handleDirty, setHandleDirty] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(initialData);
  const [showFilter, setShowFilter] = useState(false);
  const [mode, setMode] = useState<string>("create");
  const [filterCategories, setFilterCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [categoryOption, setCategoryOption] = useState([]);
  const [paginationSize, setPaginationSize] = useState<number>(10);
  const [modifiedBy, setModifiedBy] = useState("");
  const [showStates, setShowStates] = useState(false);
  const [allStates, setAllStates] = useState<any>([]);
  const [selectedStates, setSelectedStates] = useState<any>({});
  const [newChecks, setNewChecks] = useState<any[]>([]);
  const [dataByID, setDatabyID] = useState<any>({});
  const [lstCategory, setLstCategory] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber]: any =
    useState<PageData>({});
  const [pageNumber, setPageNumber] = useState(0);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const PER_PAGE = 10;

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const maxWordCount = 200;
  const maxLetterCount = 50;
  const [remainingCount, setRemainingCount] = useState(maxWordCount);
  const [remainingCount2, setRemainingCount2] = useState(maxLetterCount);

  const initialFilterData = {
    categoryIds: "",
    isActive: -1,
    isState:-1,
    keyword: "",
  };
  const [filterData, setFilterData] = useState<any>({initialFilterData});
  const {
    register: filterRegister,
    handleSubmit: filterHandleSubmit,
    reset: filterReset,
    clearErrors: clearFilterError,
    setValue: filterSetValue,
    watch: filterWatch,
    getValues: filterGetValues,
  } = useForm({
    defaultValues: initialFilterData,
    mode: "onChange",
  });
  const searchText2 = filterWatch("keyword");

  let filterActivationStatus = activationStatus?.map((data: any) => {
    return {
      label: data.label,
      value: data.value,
      };
      });

  const taxableOptions = [
    { label: "All", value: -1 },
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const schema: any = yup.object().shape({
    categoryTitle: yup
      .string()
      .required("Category name is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test(
        "alphanumeric-dot",
        "Only dot (.), alphanumeric characters allowed",
        (value) => {
          return value ? /^[A-Za-z0-9. ]*$/.test(value) : true;
        }
      )
      .max(50, "Please enter upto 50 characters"),
    description: yup
      .string()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
      taxCode: yup
      .string()
      .required("taxCode is required")
  });

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
    mode: "onChange",
  });

  const paginateData = (data: any) => {
    setCategories(data);
    const firstSet = data?.slice(0, PER_PAGE);
    setFilterCategories(firstSet);
  };
  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };
  const getAllCategories = (
    pageSize = 10,
    pageNumber = 1,
    searchData: any = null
  ) => {
    setLoading(true);
    axios.get(`${GETALLCATEGORIES}?pageNumber=${pageNumber}`, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          // toast.error(response?.data?.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          setFilterCategories([]);
          return;
        } else {
          // const categoryData = response?.data?.data?.data;
          const categoryData = response?.data?.data?.data?.map((lData: any) => {
            return {
              label: `${lData.categoryId} - ${lData.categoryTitle}`,
              value: lData.categoryId,
            };
          });
          
          setCategoryOption(categoryData);
          paginateData(response?.data?.data?.data);
          setTotalRecords(response.data.data.totalRecords);
          updateData(pageNumber, response?.data?.data?.data);
          setOriginalCategories(categoryData);

        }
      }).catch((error)=>{
        setLoading(false);
        handleError(error)
    });
  };

  const handleChange = (e: any, stateId: number) => {
    let newData: any = [];
    let updatedState;
    if (lstCategory.length > 0) {
      updatedState = lstCategory.find((cat: any) => cat.state === stateId);
    }

    updatedState = {
      categoryTaxId: updatedState?.categoryTaxId
        ? updatedState?.categoryTaxId
        : 0,
      categoryId: updatedState?.categoryId ? updatedState?.categoryId : 0,
      state: stateId,
      isActive: e.target.checked === true ? true : false,
      isDelete: e.target.checked === true ? false : true,
    };

    if (newChecks.map((i: any) => i.state).includes(stateId)) {
      newData = newChecks.filter((j: any) => j.state !== stateId);
    } else {
      newData = [...newChecks, updatedState];
    }
    setNewChecks(newData);

    const updateState = allStates.map((state: any) => {
      if (state.objectValueId === stateId) {
        return { ...state, isChecked: e.target.checked };
      }
      return state;
    });
    setAllStates(updateState);
  };

  const handleSelectDeselectAll = (type: string) => {
    const updateNewChecks =
      type === "all"
        ? allStates
            .map(
              (data: any) =>
                !lstCategory
                  .map((i: any) => i.state)
                  .includes(data.objectValueId) && {
                  categoryTaxId: 0,
                  categoryId: 0,
                  state: data.objectValueId,
                  isActive: true,
                  isDelete: false,
                }
            )
            .filter((i: any) => i)
        : lstCategory.length > 0
        ? lstCategory.map((i: any) => {
            return {
              categoryTaxId: i?.categoryTaxId ? i?.categoryTaxId : 0,
              categoryId: i?.categoryId ? i?.categoryId : 0,
              state: i.state,
              isActive: false,
              isDelete: true,
            };
          })
        : [];

    setNewChecks(updateNewChecks);
    const updateStates = allStates.map((state: any) => {
      return { ...state, isChecked: type === "all" ? true : false };
    });
    setAllStates(updateStates);
  };

  const getStates = () => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=State Code&companyId=${localStorage.getItem(
          "companyId"
        )}`,
        config
      )
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            const data = response.data.data;
            let getSelectedState: any;
            if (newChecks?.length === 0) {
              getSelectedState = selectedStates.stateTaxCodeId?.split(",");
            } else {
              getSelectedState = newChecks;
            }
            const modifyData = data.map((sData: any) => {
              if (getSelectedState?.includes(sData.objectValueId.toString())) {
                return { ...sData, isChecked: true };
              }
              return { ...sData, isChecked: false };
            });
            setAllStates(modifyData);
          }
        } ).catch((error) => {
          setLoading(false);
          handleError(error)
      });;
  };

  useEffect(() => {
    getAllCategories();
    getStates();
  }, []);

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const nextSet = categories.slice(offset, offset + PER_PAGE);
    setFilterCategories(nextSet);
  }, [currentPage]);

  const onSubmit = () => {
    setLoading(true);
    const dataOriginal = getValues();

    const data = trimObjectValues(dataOriginal);
    let payload = {
      ...data,
      categoryId: dataByID?.categoryId ? dataByID?.categoryId : 0,
      isActive: mode === "create" ? true : data.isActive,
      isDelete: mode === "create" ? false : !data.isActive,
      lstCategoryTaxableRequests: newChecks,
      taxCode: data?.taxCode
    };

    if (mode === "create") {
      axios
        .post(CREATECATEGORYWITHTEXABLE, payload, {
          ...config,
          ContentType: "application/json",
        })
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              toast.success(CategoryMessages.categoryCreated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            reset(initialData);
            setToggleValue(!toggleModal);
            getAllCategories();
            setNewChecks([]);
            setShowStates(false);
            setDatabyID({});
            setCurrentPage(0);
            setPageNumber(1);
            filterSetValue('keyword', '')
            setAlreadyFetchedDataPageNumber({})
            setAllStates(
              allStates?.map((state: any) => {
                return { ...state, isChecked: false };
              })
            );
            setLstCategory([]);
            setRemainingCount(maxWordCount);
            setRemainingCount2(maxLetterCount);
            setFilterData({ categoryIds: "", isActive: -1, isState: -1, keyword: "" });
            setShowFilter(false);
            setIsFiltered(false)
            // clearAdvanceFilterData();
            filterSetValue('keyword', '')
          }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    } else {
      axios
        .post(UPDATECATEGORYWITHTEXABLE, payload, {
          ...config,
          ContentType: "application/json",
        })
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              toast.success(CategoryMessages.categoryUpdated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            reset(initialData);
            setToggleValue(!toggleModal);
            setCurrentPage(0);
            setPageNumber(1);
            setAlreadyFetchedDataPageNumber({})
            filterSetValue('keyword', '')
            getAllCategories();
            setIsFiltered(false)
            setShowStates(false);
            setDatabyID({});
            setNewChecks([]);
            setLstCategory([]);
            setRemainingCount(maxWordCount);
            setRemainingCount2(maxLetterCount);
            setAllStates(
              allStates?.map((state: any) => {
                return { ...state, isChecked: false };
              })
            );
            setFilterData({ categoryIds: "", isActive: -1, isState: -1, keyword: "" });
            setShowFilter(false);
            // clearAdvanceFilterData();
            filterSetValue('keyword', '')
          }
        ).catch((error) => {
          setLoading(false);
          handleError(error)
      });;
    }
  };

  const handleClose = () => {
    if (isDirty) {
      Swal.fire({
        title: "Are you sure?",
        text: "Changes will be discarded!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.isConfirmed) {
          reset(initialData);
          reset(initialData);
          setToggleValue(!toggleModal);
          setRemainingCount(maxWordCount);
          setRemainingCount2(maxLetterCount);
        }
      });
      setHandleDirty(!handleDirty);
      return;
    }
    setToggleValue(!toggleModal);
    setShowStates(false);
    setAllStates(
      allStates?.map((state: any) => {
        return { ...state, isChecked: false };
      })
    );
    setNewChecks([]);
    setDatabyID({});
    setLstCategory([]);
    setRemainingCount(maxWordCount);
    setRemainingCount2(maxLetterCount);
  };

  const handleEditModal = (categoryId: string, type: string) => {
    if (type === "edit") {
      const checkEditPer = Helper.checkAdminPermission(types.EDIT);
      if (checkEditPer) {
        setLoading(true);
        setMode("edit");
        setToggleValue(!toggleModal);
        axios
          .get(GETCATEGORIESBYID, {
            ...config,
            params: { categoryId: categoryId },
          })
          .then(
            (response) => {
              setLoading(false);
              if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                return;
              } else {
                const {
                  createdBy,
                  createdOn,
                  isDelete,
                  updatedBy,
                  updatedOn,
                  lstCategoryTaxable,
                  ...otherFormData
                } = response.data.data;
                setModifiedBy(response.data.data.modifiedBy);
                // Commeneted as of now 
                /* const activeStates = lstCategoryTaxable.map(
                  (stateData: any) => stateData.state
                );
                const getCatStates = allStates?.map((state: any) => {
                  if (activeStates.includes(state?.objectValueId)) {
                    return { ...state, isChecked: true };
                  }
                  return state;
                });
                setLstCategory(lstCategoryTaxable);
                setAllStates(getCatStates); */
                setDatabyID(otherFormData);
                reset(otherFormData);
              }
            }
          ).catch((error) => {
            setLoading(false);
            handleError(error)
        });
        return;
      }
      return;
    }
    const checkDelPer = Helper.checkAdminPermission(types.DELETE);
    if (checkDelPer) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios
            .post(`${DELETECATEGORIES}?categoryId=${categoryId}`, {}, config)
            .then(
              (response) => {
                const data = document.getElementById(
                  "search"
                ) as HTMLInputElement;
                data.value = "";
                setLoading(false);
                if (response?.data?.success === "Fail") {
                  toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  return;
                } else if (response?.data?.success === "Success") {
                  toast.success(CategoryMessages.categoryDeleted, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  getAllCategories();
                  setIsFiltered(false)
                  setCurrentPage(0);
                  setPageNumber(1);
                  setAlreadyFetchedDataPageNumber({})
                  // clearAdvanceFilterData();
                  filterSetValue('keyword', '')
                } else if (response.data.code == 400) {
                  toast.error(errorMessages.notDeleteCategory, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                }
               
              }).catch((error) => {
                setLoading(false);
                handleError(error)
            });
        }
      });
    }
  };

  const changeHandler = (fieldName: string, value: any) => {
    const updateData = { ...filterData, [fieldName]: value };
    setFilterData(updateData);
  };

  const clearAdvanceFilterData = () => {
    setFilterData({ categoryIds: "", isActive: -1, isState: -1, keyword: "" });
    getAllCategories();
    setShowFilter(!showFilter);
    filterReset(initialFilterData);
    setCurrentPage(1);
    setPageNumber(1);
    setAlreadyFetchedDataPageNumber({});
    filterSetValue('keyword', '')
    setIsFiltered(false);
  };

  const onFilterSubmit = (pageNum: any) => {
    setLoading(true);
    const data: any = filterGetValues();
    const filterData1 = trimObjectValues(data);
    axios
      .get(ADVANCEFILTERCATEGORY, {
        ...config,
        params: {
          categoryIds: filterData1.categoryIds.value??"",
          isActive: filterData1.isActive.value ?? -1,
          isState: filterData1.isState.value ?? -1,
          pageNumber: pageNum,
          keyword: filterData1?.keyword,
        },
      })
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            // toast.error(response?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
            setFilterCategories([]);
            return;
          } else {
            const categoryData = response.data.data.data;
            setTotalRecords(response.data.data.totalRecords);
            updateData(
              response?.data?.data?.currentPage,
              response?.data?.data?.data
            );
            setOriginalCategories(categoryData);
            paginateData(categoryData);
            setPageNumber(pageNum);
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error)
      });;
    return;
  };

  const handleSorting = (type: string, fieldName: string) => {
    let userName;
    if (type === "ASC") {
      userName = categories.sort((a: any, b: any) =>
        a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()
          ? 1
          : -1
      );
    } else {
      userName = categories.sort((a: any, b: any) =>
        a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    paginateData(userName);
    // setCurrentPage(0);
  };

  //debounce function for search functionality.
  const debouncedRequest = useDebounce(() => {
    onFilterSubmit(1);
  });

  const onHandleFetchMore = (selected: any) => {
    const newPageNumber = selected + 1;
    setPageNumber(newPageNumber);
    if (alreadyFetchedDataPageNumber.hasOwnProperty(newPageNumber)) {
      setFilterCategories(alreadyFetchedDataPageNumber[newPageNumber]);
    } else if (searchText2 || isFiltered) {
      onFilterSubmit(newPageNumber);
    } else {
      getAllCategories(paginationSize, newPageNumber);
    }
  };

  return (
    <>
      <CommonHelmet title={"Master Categories - "} name={"masterCategories"} content={"Master Categories"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}

      {/* create/update category modal starts */}
      <Modal show={toggleModal} size="lg" scrollable={true} centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            {mode === "create" ? "Add New Category" : "Edit Category"}
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body className="scroll">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row">
              <div className="col-md-12 mb-3">
                <label className="form-label" htmlFor="text">
                  Category Name <sup className="text-danger">*</sup>
                </label>
                <input
                  className="form-control form-icon-input"
                  type="text"
                  placeholder="Category Name"
                  maxLength={51}
                  {...register("categoryTitle")}
                />
                {errors?.categoryTitle && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.categoryTitle.message}
                  </span>
                )}
              </div>
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="text">
                  Category Description
                </label>
                <textarea
                  className="form-control form-icon-input"
                  rows={4}
                  maxLength={maxWordCount}
                  placeholder="Category Description"
                  {...register("description", {
                    onChange: (e) =>
                      setRemainingCount(
                        wordCounter(e.target.value, maxWordCount)
                      ),
                  })}
                ></textarea>
                {remainingCount != maxWordCount && (
                  <div
                    className={remainingCount <= 0 ? "text-danger" : ""}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  >
                    {remainingCount <= 0
                      ? "Maximum word limit reached"
                      : `Remaining words: ${remainingCount}`}
                  </div>
                )}

                {errors?.description && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.description.message}
                  </span>
                )}
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label" htmlFor="text">
                  Taxcode <sup className="text-danger">*</sup>
                </label>
                <input
                  className="form-control form-icon-input"
                  type="text"
                  placeholder="taxCode"
                  {...register("taxCode")}
                />
                {errors?.taxCode && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.taxCode.message}
                  </span>
                )}
                <p className="fs-12 fw-bold">*Tax details managed by Avalara</p>
              </div>
              <div className="col-12 mb-2">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    {mode !== "create" && (
                      <div className="">
                        <label>Status</label>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            {...register("isActive")}
                          />
                          <label className="form-check-label">Active</label>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Commented as of now */}
                 {/*  <div className="col-md-6">
                    <div className="hide_taxe_state justify-content-end d-flex">
                      <a
                        className="d-flex align-items-center"
                        type="button"
                        onClick={() => {
                          setShowStates(!showStates);
                          !allStates?.length && getStates();
                        }}
                      >
                        {showStates
                          ? "Hide Taxable States"
                          : "Show Taxable States"}{" "}
                        <span
                          className={
                            showStates
                              ? "fas fa-angle-up ms-2"
                              : "fas fa-angle-down ms-2"
                          }
                        >
                          {" "}
                        </span>
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
              {showStates && (
                <div className="col-lg-12">
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="d-flex flex-wrap align-items-center justify-content-start">
                        <div className="col-auto  mb-4">
                          <div className="buttons">
                            <button
                              type="button"
                              className="btn btn-outline-secondary me-2"
                              onClick={() => handleSelectDeselectAll("all")}
                            >
                              Select All States
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => handleSelectDeselectAll("clear")}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="label_with_checkbox scroll">
                      <div className="row">
                        {allStates?.length > 0 ? (
                          allStates?.map((stateData: any, index: any) => {
                            return (
                              <div
                                className="col-lg-2 col-md-3 col-sm-4 col-4 mb-2"
                                key={index}
                              >
                                <div className="form-check m-0">
                                  <input
                                    className="form-check-input form-check-states"
                                    type="checkbox"
                                    name="states"
                                    id={stateData.objectValueId}
                                    checked={stateData.isChecked === true}
                                    onChange={(e) =>
                                      handleChange(e, stateData.objectValueId)
                                    }
                                  />
                                  <label
                                    className="form-check-label text-900 fw-400"
                                    htmlFor={stateData.objectValueId}
                                  >
                                    {stateData.objectTypeValue}
                                  </label>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-12">
                            <p className="text-center mt-3 mb-3">
                              No state found
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className='d-flex flex-wrap align-items-center justify-content-end p-3 pb-0 border-top'> */}
              <div
                className="d-flex justify-content-end border-top bg-white p-3 pb-0 fix-bottom"
                data-wizard-footer="data-wizard-footer"
              >
                <ModifiedBy data={formValues} />
                {mode != "create" && <LastModified data={formValues} />}

                <button
                  className="btn btn-outline-danger ms-2 me-auto"
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button className="btn btn-primary ms-2" type="submit">
                  {mode != "create" ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* create/update category modal ends */}

      <Sidebar />
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Master Categories">
          <div className="search-box mt-2">
            <div className="d-flex align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  className="form-control search-input search form-control-sm"
                  type="text"
                  id="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchText2}
                  onChange={(e: any) => {
                    if (e.target.value) {
                      filterSetValue("keyword", e.target.value);
                      debouncedRequest();
                    } else {
                      filterSetValue("keyword", "");
                      setAlreadyFetchedDataPageNumber({});
                      setPageNumber(1);
                      setCurrentPage(1);
                      onFilterSubmit(1);
                    }
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
                <a
                  className="btn p-0 ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            <button
              className="btn btn-primary ms-2 position-relative d-flex aling-items-center"
              type="button"
              data-bs-toggle="modal"
              onClick={() => {
                const checkPer = Helper.checkAdminPermission(types.ADD);
                if (checkPer) {
                  reset(initialData);
                  setToggleValue(!toggleModal);
                  setMode("create");
                }
              }}
            >
              <span className="plus me-2">
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3"
                ></span>
              </span>
              <span> Add New Category </span>
            </button>
          </div>
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter"}
            >
              <div className="card bg-transparent position-relative p-3 mt-2">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                    filterReset(initialFilterData);
                  }}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row g-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Category
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Category"
                            className="category"
                            {...filterRegister("categoryIds")}
                            name="category"
                            options={categoryOption}
                            value={filterData.categoryIds}
                            onChange={(e) => {changeHandler("categoryIds", e);filterSetValue("categoryIds",e)}}
                          />
                          {/* Use common component for loading category data */}
                          {/* <AsyncPagination
                            register={filterRegister}
                            name="categoryIds"
                            setValue={filterSetValue}
                            APIEndpoint={GETALLCATEGORIES}
                            label='categoryTitle'
                            value='categoryId'
                          /> */}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Activation Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Activation Status"
                            className="isActive"
                            {...filterRegister("isActive")}
                            name="isActive"
                            options={filterActivationStatus}
                            value={filterData.value}
                            onChange={(e) => {changeHandler("isActive", e);filterSetValue("isActive",e)}}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Taxable
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Taxable"
                            {...filterRegister("isState")}
                            className="isState"
                            name="isState"
                            options={taxableOptions}
                            value={filterData.isState}
                            onChange={(e) => {changeHandler("isState", e);filterSetValue("isState",e)}}
                          />
                        </div>
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end mt-3 ms-3">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={clearAdvanceFilterData}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          onClick={() => {
                            setIsFiltered(true);
                            setCurrentPage(1);
                            setPageNumber(1);
                            setAlreadyFetchedDataPageNumber({})
                            onFilterSubmit(1);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-5 border-300">
                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                  <div className="table-responsive mx-n1 px-1 scrollbar">
                    <table className="table fs--1 mb-0 border-top border-200">
                      <thead>
                        <tr>
                          <th className="bg-gradiant sort" scope="col">
                            Action
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="Initials"
                          >
                            <div className="d-flex align-items-center">
                              <span>Category</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() =>
                                    handleSorting("ASC", "categoryTitle")
                                  }
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() =>
                                    handleSorting("DESC", "categoryTitle")
                                  }
                                ></button>
                              </div>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="Initials"
                          >
                            <div className="d-flex align-items-center">
                              <span>Description</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() =>
                                    handleSorting("ASC", "description")
                                  }
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() =>
                                    handleSorting("DESC", "description")
                                  }
                                ></button>
                              </div>
                            </div>
                          </th>

                          <th className="bg-gradiant" scope="col">
                            Taxable
                          </th>
                          <th className="bg-gradiant" scope="col">
                            Activation Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list" id="table-latest-review-body">
                        {filterCategories?.length > 0 ? (
                          filterCategories?.map((cate: any, index: any) => {
                            const descriptionToShow =
                              cate.description && cate.description.length > 50
                                ? `${cate.description.substring(0, 50)}...`
                                : cate.description ?? "-";
                            return (
                              <tr
                                className="hover-actions-trigger btn-reveal-trigger position-static"
                                key={index}
                              >
                                <td className="align-middle white-space-nowrap text-start p-2">
                                  <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                    <Dropdown.Toggle
                                      className="m-0 p-0"
                                      variant="none"
                                    >
                                      <button
                                        className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                        type="button"
                                      >
                                        {" "}
                                        <img
                                          src="/assets/img/ellipsis-solid.svg"
                                          style={{ width: "20px" }}
                                        />
                                      </button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      className={`dropdown-menu dropdown-menu-start py-2`}
                                    >
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                        onClick={() =>
                                          handleEditModal(
                                            cate.categoryId,
                                            "edit"
                                          )
                                        }
                                      >
                                        Edit{" "}
                                        <i className="fas fa-pen dropdown-icon"></i>{" "}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                        onClick={() =>
                                          handleEditModal(
                                            cate.categoryId,
                                            "delete"
                                          )
                                        }
                                      >
                                        Delete{" "}
                                        <i className="fas fa-trash dropdown-icon"></i>{" "}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td className="align-middle customer p-2">
                                  {cate.categoryTitle ?? "-"}
                                </td>
                                <td className="align-middle customer p-2">
                                  {descriptionToShow}
                                </td>
                                <td className="align-middle customer p-2">
                                  {cate.isState ? "Yes" : "No"}
                                </td>
                                <td className="align-middle text-start status p-2">
                                  {cate.isActive == true ? (
                                    <span className="badge badge-dpa fs--2 badge-dpa-success">
                                      <span className="badge-label">
                                        Active
                                      </span>
                                    </span>
                                  ) : (
                                    <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                      <span className="badge-label">
                                        Inactive
                                      </span>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                            <td className="text-center p-4" colSpan={6}>
                              No records found!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {filterCategories?.length > 0 && (
                  <ReactPaginate
                    pageCount={Math.ceil(totalRecords / paginationSize)}
                    previousLabel={"←"}
                    nextLabel={"→"}
                    onPageChange={({ selected }) => onHandleFetchMore(selected)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
