import { toast } from "react-toastify";

let isToastActive = false;

const showErrorToast = (message: string) => {
  if (!isToastActive) {
    isToastActive = true;
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      onClose: () => {
        isToastActive = false;  // Reset the flag when the toast is closed
      }
    });
  }
};

const formatErrorMessages = (response: any): string => {
  if (response?.message) {
    return response.message;
  } else if (response?.errors) {
    let messages: string[] = [];
    Object.values(response.errors).forEach((errors: any) => {
      if (Array.isArray(errors)) {
        messages = messages.concat(errors);
      } else {
        messages.push(errors);
      }
    });
    return messages.join(', ');
  }
  return 'An unknown error occurred';
};

const handleError = (error: any) => {
  if (error?.response) {
    // Server responded with a status code out of the range of 2xx
    switch (error.response.status) {
      case 401:
        break;
      case 400:
      showErrorToast(error.response.data?.message || 'Some error occured');
       break;
      case 500:
        showErrorToast(error.response.data?.message || 'Internal server error');
        break;
      default:
        showErrorToast(formatErrorMessages(error.response.data) || 'An error occurred');
    }
  } else if (error?.request) {
    // The request was made but no response was received
    showErrorToast('Network Error: Seems to be a network issue or CORS Policy issue.');
  } else {
    // Something happened in setting up the request that triggered an Error
    showErrorToast('Error: ' + error.message || "Something went wrong");
  }
};

export default handleError;