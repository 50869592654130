import React from 'react'
import site_maintance from "../../assets/site_maintance.svg"
import { Link } from 'react-router-dom'
import * as routeConstant from '../../common/routeConstants'
const NotFound = () => {
    return (
        <div className='undermaitainance'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="u-image">
                            <img src={site_maintance} className='w-100' title="DPA Auction Under" />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <article className='mt-0'>
                            <svg className='mt-0' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24">
                                <defs>
                                    <style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#F00;}" }} />
                                </defs>
                                <title>Asset 3</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Capa_1" data-name="Capa 1">
                                        <path
                                            className="cls-1"
                                            d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z"
                                        />
                                        <path
                                            className="cls-1"
                                            d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z"
                                        />
                                    </g>
                                </g>
                            </svg>
                            <h1 className='text-dark fw-bold mt-5 mb-4'>404</h1>
                            <h1 className='text-dark fw-bold mt-5 mb-4'>Page Not Found</h1>
                            <div className=' mt-2 mb-4'>
                                <p className='text-dark fw-bold'>
                                    <Link to={routeConstant.DASHBOARD} className='btn btn-primary'>Go To Home Page</Link>
                                </p>
                                <p className='text-dark fw-bold'>— DPA Auction </p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NotFound