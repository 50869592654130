import React, { useState, useEffect } from "react"
import { Breadcrumb } from "../../common/interface"
import * as routeConstant from '../../common/routeConstants'
import axios from "axios"
import { toast } from "react-toastify"
import { GETSELLER, GETSELLERSUMMARYREPORT, SELLERREPORTADVANCEFILTER } from "../../common/apiConstatnts"
import ReactPaginate from "react-paginate"
import Sidebar from "../Sidebar/Sidebar"
import InnerHeader from "../InnerHeader/InnerHeader"
import { errorMessages } from "../../common/messageConstants"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import moment from "moment"
import { UTCdateConverter } from "../../common/constants";
import * as XLSX from 'xlsx';
import { formatCurrency, formatNumber } from "../../Utils/Utils"
import { Link } from "react-router-dom"
import { encryptId } from "../../Encryption/Encryption"
import MomentUtil from "../../Utils/MomentUtil"
import handleError from "../../Utils/ApiCommonErrorMessages"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Summary Report', url: routeConstant.SELLERSUMMARYREPORT }
]

const SellerSummaryReport = () => {
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>({ SellerId: "", Name: "" });
    const [originalSellerSum, setOriginalSellerSum] = useState<any>([])
    const [sellerSummaryData, setSellerSummaryData] = useState<any[]>([]);
    const [filterSellerSum, setFilterSellerSum] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [seller, setSeller] = useState<any>([])
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const PER_PAGE = 10;
    const pageCount = Math.ceil(sellerSummaryData.length / PER_PAGE);

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = originalSellerSum?.filter((x: any) => x.lotTitle?.toLocaleLowerCase()?.includes(keyword) ||
            x.sellerName?.toLocaleLowerCase()?.includes(keyword) || x.sellerId?.toString()?.includes(keyword)) 
        paginateData(filterData)
        setCurrentPage(0)
    }

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...sellerSummaryData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const clearAdvanceFilterData = () => {
        setFilterData({ SellerId: "", Name: "" })
        getSellerSummaryData()
    }

    const paginateData = (data: any) => {
        setSellerSummaryData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSellerSum(firstSet)
    }

    const changeHandler = (fieldName: string, value: any) => {
        const updateData = { ...filterData, [fieldName]: value }
        setFilterData(updateData)
    }

    const handleAdvanceFilter = () => {
        setLoading(true)
        axios.get(`${SELLERREPORTADVANCEFILTER}?sellerId=${filterData.SellerId}`)
            .then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setFilterSellerSum([]);
                    return;
                } else {
                    paginateData(response.data.data);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        return
    }

    const getSellerSummaryData = () => {
        setLoading(true)
        axios.get(GETSELLERSUMMARYREPORT).then((response: any) => {

            const sellerSumResp = response.data.data
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterSellerSum([]);
                return;
            } else {
                paginateData(sellerSumResp)
                setOriginalSellerSum(sellerSumResp)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const getSellers = () => {
        setLoading(true)
        axios.get(`${GETSELLER}?pageNumber=-1`).then((response: any) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const sellerResp = response?.data?.data?.data;
                const formatSeller = sellerResp.map((sData: any) => { return { label: `${sData.sellerId} - ${sData.firstName} ${sData.lastName}`, value: sData.sellerId } })
                setSeller(formatSeller);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }


    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(13).text(`Seller-Summary-Report`, 15, 20);
        doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Seller Id', 'Seller Name', 'Notes', 'Count', 'Sold', 'Passed', 'BuyBack', 'Non Payer', 'Pending']]
        const data = sellerSummaryData.map((data: any) => {
            return {
                sellerId: data.sellerId,
                sellerName: data.sellerName || "-",
                notes: data.notes || "-",
                count: formatNumber(data.count) || "0",
                sold: formatNumber(data.sold) || "0",
                passed: formatNumber(data.passed) || "0",
                buyback: formatNumber(data.buyback) || "0",
                nonPayer: formatNumber(data.nonPayer) || "0",
                pending: data.pending || "0"
            };
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Seller-Summary-Report.pdf`);
    }

    const generateExcelWithTitle = () => {

        let data = sellerSummaryData?.map((data: any) => {
            return {
                sellerId: data.sellerId,
                sellerName: data.sellerName || "-",
                notes: data.notes || "-",
                count: formatNumber(data.count) || "0",
                sold: formatNumber(data.sold) || "0",
                passed: formatNumber(data.passed) || "0",
                buyback: formatNumber(data.buyback) || "0",
                nonPayer: formatNumber(data.nonPayer) || "0",
                pending: data.pending || "0"
            };
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Seller-Summary-Report'],
            [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Seller Id', 'Seller Name', 'Notes', 'Count', 'Sold', 'Passed', 'BuyBack', 'Non Payer', 'Pending'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Seller-Summary-Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = sellerSummaryData?.slice(offset, offset + PER_PAGE)
        setFilterSellerSum(nextSet)
    }, [currentPage])

    useEffect(() => {
        getSellerSummaryData()
        getSellers()
    }, [])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Summary Report'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                                {/* <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button> */}
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={generateExcelWithTitle} disabled={sellerSummaryData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => exportPDF()} disabled={sellerSummaryData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>
                    </div>
                    {/* {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-3">
                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter); getSellerSummaryData(); setFilterData({ SellerId: "", Name: "" }) })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Seller</label>
                                                    <select
                                                        name="SellerId"
                                                        className="form-select"
                                                        value={filterData.SellerId}
                                                        onChange={((e) => changeHandler("SellerId", e.target.value))}
                                                    >
                                                        <option value="">Select Seller</option>
                                                        {seller.map((sData: any, sIndex: any) => {
                                                            return (
                                                                <option value={sData.value} key={sIndex}>{sData.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end  ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="button" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="button" onClick={handleAdvanceFilter}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Seller Id</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerId')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerId')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Seller Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Notes</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Count</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'count')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'count')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Sold</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sold')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sold')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Passed</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'passed')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'passed')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Buyback</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'buyback')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'buyback')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Non Payer</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'nonPayer')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'nonPayer')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Pending</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'pending')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'pending')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterSellerSum.length > 0 ? filterSellerSum.map((fData: any, fIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                        <td className="align-middle  p-2">{fData.sellerId}</td>
                                                        <td className="align-middle  p-2">
                                                            <Link className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                target="_blank"
                                                                to={`${routeConstant.SELLERMOREINFO}/${encryptId(fData?.sellerId)}`}
                                                            >
                                                                {fData?.sellerName}
                                                            </Link>
                                                        </td>
                                                        <td className="align-middle  p-2">{fData.notes ?? '-'}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.count) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.sold) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.passed) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.buyback) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.nonPayer) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.pending) ?? 0}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={10}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterSellerSum.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerSummaryReport