import * as action from '../../common/types'
import { Action } from '../../common/constants'



export const addInvoice = (data: any): Action => ({
    type: action.ADDINVOICE,
    payload: data,
});

export const setInvoiceAdjust = (data: any): Action => ({
    type: action.SETINVOICES,
    payload: data
})

export const setInvoiceAdjustUpdate = (data: any): Action => ({
    type: action.SETINVOICESUPDATE,
    payload: data
})

export const setInvoicePayment = (data: any): Action => ({
    type: action.SETINVOICESPAYMENT,
    payload: data
})

export const setInvoicePaymentUpdate = (data: any): Action => ({
    type: action.SETINVOICEPAYMENTUPDATE,
    payload: data
})

export const setSellerRadioValue = (data: any): Action => ({
    type: action.SETINVOICERADIOVALUE,
    payload: data
})
