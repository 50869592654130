import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from "moment";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { GETALLAUCTIONLIST, GETTAXEXEMPTSUMMARY } from "../../common/apiConstatnts";
import { useSelector } from "react-redux";
import { generateTaxExemptSummaryCsv, generateTaxExemptSummaryPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Tax Exempt Summary Report', url: routeConstant.INVENTORYBYSELLER }
]

const TaxExemptSummary = () => {
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const [loading, setLoading] = useState(false);
    const [filterTaxExemptData, setFilterTaxExemptData] = useState<any>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [globalAuction, setGlobalAuction] = useState<any>('');
    const [auctionCode, setAuctionCode] = useState('');
    let AuctionStartDate = filterTaxExemptData?.length > 0 ? filterTaxExemptData[0]?.auctionDate	
        : null;

    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
        setGlobalAuction(e);
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction: any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    };

    // Function to fetch TaxSummaryData  based on the selected auction ID
    const fetchTaxExemptSummaryData = (auctionId: any) => {
        setLoading(true);
        if (auctionId) {
            // setTaxRateListing([]);
            axios.get(`${GETTAXEXEMPTSUMMARY}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                const taxData = response?.data.data;

                if (taxData?.length > 0) {
                    setFilterTaxExemptData(taxData);
                }
                else {
                    setFilterTaxExemptData([]);
                }
            }).catch((error: any) => {
                setLoading(false);
                handleError(error);
            });
        }
        // Set loading state to true to indicate data fetching in progress
    };

    const calculateTotalLotPrice = () => {
        let total = 0;
        filterTaxExemptData?.forEach((item: any) => {
            item?.subRes?.forEach((subItem: any) => {
                total += subItem.lotPrice;
            });
        });
        return total;
    };
    const totalLotPrice = calculateTotalLotPrice();

    const formatAmount = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };
    useEffect(() => {
        getAllAuctions();
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            fetchTaxExemptSummaryData(selectedAuction?.value)
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (getGlobalAuctionIds) {
            setSelectedAuction(getGlobalAuction);
            setGlobalAuction(getGlobalAuction)
        }
        else {
            getAllAuctions();
        }
    }, [getGlobalAuction])

    const formatNumber = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };


    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Tax Exempt Summary Report'>

                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                <div className="col-lg-8">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Auction"
                                        className="isActive swap-to-lot-menu clone-inventory"
                                        options={auctionList}
                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                        value={selectedAuction?.value ? auctionList.find((option: any) => option?.value === selectedAuction.value) : getGlobalAuction}
                                        onChange={(e: any) => {
                                            handleAuctionChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                                        onClick={() => generateTaxExemptSummaryCsv(filterTaxExemptData, globalAuction)}
                                        disabled={filterTaxExemptData?.length == 0 || filterTaxExemptData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2"
                                        onClick={() => generateTaxExemptSummaryPdf(filterTaxExemptData, globalAuction)}
                                        disabled={filterTaxExemptData?.length == 0 || filterTaxExemptData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 mt-4 border-300">
                                {
                                    filterTaxExemptData && filterTaxExemptData?.length > 0 ?
                                        <>
                                            <table className="w-100 mt-2" style={{ verticalAlign: 'top' }}>
                                                <tbody>
                                                    <tr>
                                                        <td valign="top" style={{ border: 0, width: '100%' }}>
                                                            <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Tax Exempt Summary Report</p>
                                                            <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                                <span>{`Auction #${selectedAuction
                                                                    ? selectedAuction?.label?.split('-')[0]
                                                                    : auctionCode
                                                                        ? getGlobalAuctionIds
                                                                        : getGlobalAuctionIds?.label?.split('-')[0]
                                                                    }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="table-responsive">
                                                <table className="w-100 mt-1 table tax-exp-table" style={{ borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={7} style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'end' }} >
                                                            Totals ${formatAmount(totalLotPrice)}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'left', width: "15%", alignItems: "center" }}>
                                                                <div>Auc</div>
                                                            </th>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'left', width: "15%", alignItems: "center" }}>
                                                                <div>Date</div>
                                                            </th>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'left', width: "15%", alignItems: "center" }}>
                                                                <div>Name</div>
                                                            </th>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'left', width: "10%" }}>
                                                                <div>Lot #</div>
                                                            </th>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'left', width: "20%" }}>
                                                                <div>Address</div>
                                                            </th>
                                                            <th style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'right', width: "15%", alignItems: "center" }}>
                                                                <div>Lot Price</div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterTaxExemptData?.map((data: any, index: any) => {
                                                            const totalLotPriceForCity = data?.subRes?.reduce((acc: number, subData: any) => acc + subData.lotPrice, 0);
                                                            return (
                                                                <>
                                                                    {/*State Row */}
                                                                    <tr>
                                                                        <th colSpan={7} style={{ fontSize: '12px', backgroundColor: '#c0c0c0', whiteSpace: "nowrap", textAlign: 'start' }} >
                                                                            {data?.state?.toUpperCase()}
                                                                        </th>
                                                                    </tr>
                                                                    {/*City and lot price row*/}
                                                                    <tr>
                                                                        <th style={{ backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'center', width: "15%", alignItems: "center" }} > </th>
                                                                        <th style={{ backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'center', width: "15%", alignItems: "center" }} > </th>
                                                                        <th style={{ fontSize: '12px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'start', width: "15%", alignItems: "center" }} >  {data?.lotCity} </th>

                                                                        <th style={{ backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'center', width: "15%", alignItems: "center" }} > </th>
                                                                        <th style={{ backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'center', width: "20%", alignItems: "center" }} > </th>
                                                                        <th style={{ fontSize: '12px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'end', width: "15%", alignItems: "center" }} >${formatNumber(totalLotPriceForCity)}</th>

                                                                    </tr>
                                                                    {/* Sub Rows */}
                                                                    {data?.subRes?.map((subData: any, subIndex: any) => (
                                                                        <tr key={subIndex}>
                                                                            <td style={{ fontSize: '11px', textAlign: 'left', alignItems: "center" }}>
                                                                                {data.auctionCode}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', textAlign: 'left', alignItems: "center" }}>
                                                                                {moment(data?.auctionDate).format('MM/DD/YYYY')}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', textAlign: 'left', alignItems: "center" }}>
                                                                                {data.lotCity}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', textAlign: 'left', alignItems: "center" }}>
                                                                                {subData.lotDisplayNo}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', textAlign: 'left', alignItems: "center" }}>
                                                                                {subData.lotAddress}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', textAlign: 'right', alignItems: "center" }}>
                                                                                {subData.lotPrice > 0 ? `$${formatNumber(subData.lotPrice)}` : "$0.00"}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan={7} style={{ fontSize: '12px', whiteSpace: "nowrap", textAlign: 'end', borderTop:'1px solid #0000000' }} >
                                                            Totals ${formatAmount(totalLotPrice)}
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                        : <>
                                            {getNoDataView()}
                                        </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxExemptSummary;


