import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeader from '../InnerHeader/InnerHeader/InnerHeader';
import Sidebar from '../Sidebar/Sidebar';
import * as routeConsant from '../../common/routeConstants'
import { Breadcrumb } from '../../common/interface';
import { GETALLUSERS, GETUSERBYID, DELETEUSER, GETCOMMONVALUE, GETALLROLES, ADVANCEFILTER, base, USERUSINGBLOB, UPDATEUSINGBLOB, GETBYIDUSINGBLOB } from '../../common/apiConstatnts';
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { activationStatus, wordCounter } from "../../common/constants";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import ModifiedBy from '../LastModifiedDateTime/ModifiedBy';
import LastModified from '../LastModifiedDateTime/LastModifiedDateTime';
import Helper from '../../common/helpers';
import * as types from '../../common/permissionTypes';
import { AlPHANUMERIC, EMAIL, EMAIL_MESSAGE } from '../../common/regexConstants';
import DefaultImage from '../../assets/userProfile.svg';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedUserData, setRedirectedtoHeader } from '../../store/actions/CommonAction';
import { CommonMessages, UserMessages, errorMessages } from '../../common/messageConstants';
import { ERROR_MESSAGES, trimObjectValues, useDebounce } from '../../Utils/Utils';
import handleError from '../../Utils/ApiCommonErrorMessages';
import CommonHelmet from '../../common/HelmetWrapper';

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Users', url: routeConsant.USER }
]

const User = () => {

    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const LoggedUserId: any = decodedToken?.sub;
    const dispatch = useDispatch()
    const redirectedToHeader = useSelector((state: any) => state.global?.redirectedToHeader);
    const Idd = useSelector((state: any) => state.global?.loggedUserData?.roleId);

    const initialData = {
        companyId: "",
        initials: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
        confirmPassword: "",
        gender: "",
        roleId: "",
        notes: "",
        isActive: true,
        profilePicture: '',
        isProfilePicture: 1
    }



    const [loading, setLoading] = useState(false)
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [gender, setGender] = useState<[]>([])
    const [initial, setInitial] = useState<[]>([])
    const [roles, setRoles] = useState<[]>([])
    const [users, setUsers] = useState<[]>([])
    const [originalUsers, setOriginalUsers] = useState<[]>([])
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<any>({});
    const [showFilter, setShowFilter] = useState(false)
    const [mode, setMode] = useState<string>('create')
    const [filterUsers, setFilterUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [filterData, setFilterData] = useState<any>({ role: "", userName: '', email: '', isActive: -1 })
    const [modifiedBy, setModifiedBy] = useState('')
    const [cnfError, setCnfError] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const [profilePicture, setProfilePicture] = useState<any>(null);
    const [error, setError] = useState({ email: "", userName: "" });
    const [totalRecords, setTotalRecords] = useState(0)
    const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<any>({});
    const [paginationSize, setPaginationSize] = useState<number>(10)
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const PER_PAGE = 10;
    const pageCount = Math.ceil(users?.length / PER_PAGE);
    const navigate = useNavigate();

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            enctype: "multipart/form-data",
        }
    };


    const maxWordCount = 150
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const filterRoleData: any = [
        { label: 'Select Role', value: '' },
        ...Array.from(new Set(roles?.map((roleData: any) => roleData.userRoleId)))
            .map((userRoleId: any) => {
                const role: any = roles?.find((roleData: any) => roleData.userRoleId === userRoleId);
                return {
                    label: `${role.roleName}`,
                    value: role.userRoleId,
                };
            }),
    ];
    let filterActivationStatus =
        activationStatus.map((data: any) => {
            return {
                label: data.label, value: data.value
            }
        })

    // const paginateData = (data: any) => {
    //     setUsers(data)
    //     const firstSet = data?.slice(0, PER_PAGE);
    //     setFilterUsers(firstSet)
    // }

    const getAllUsers = (pageSize: any = 10, pageNum: any = 1) => {
        setLoading(true)
        axios.get(`${GETALLUSERS}?pageNumber=${pageNum}`, config).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterData([]);
                return;
            }
            else {
                const userData = response?.data?.data?.data;
                setTotalRecords(response?.data?.data?.totalRecords)
                paginateData(userData, pageNum);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    const schema: any = yup.object().shape({
        initials: yup.string().required("Please select initials."),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid First name').test('len', 'Please enter upto 20 characters', val => val?.length < 20),
        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid Last name').test('len', 'Please enter upto 20 characters', val => val?.length < 20),
        emailAddress: yup.string()
            .required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })

            .matches(EMAIL, EMAIL_MESSAGE).test('len', 'Please enter upto 50 characters', val => val.length < 50),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters long")
            .max(20, "Please enter upto 20 characters")
            .test('no-blank-space', 'Blank spaces are not allowed',
                (value) => {
                    return value ? !/^\s/.test(value) : true;
                })
            .test(
                'uppercase',
                'At least one uppercase letter is required',
                (value) => /[A-Z]/.test(value)
            )
            .test(
                'lowercase',
                'At least one lowercase letter is required',
                (value) => /[a-z]/.test(value)
            )
            .test(
                'numbers',
                'At least one number is required',
                (value) => (value.match(/[0-9]/g) || []).length >= 1
            )
            .test(
                'specialCharacter',
                'At least one special character is required',
                (value) => /[!@#$%^&*()_+\-=\[\]{}|';:.<>?]/.test(value)
            ),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), ""], 'Password must match with confirm password.')
            .max(20, "Please enter upto 20 characters")
            .required("Confirm Password is required."),
        gender: yup.string().required("Please select gender."),
        roleId: yup.string().required("Please select role."),
        notes: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        })
    });

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        clearErrors,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const password = watch('password')
    const cnfpassword = watch('confirmPassword')
    const isActive = watch('isActive');
    const watchInitials = watch('initials')
    const watchGender = watch('gender')
    const watchRoleId = watch('roleId')
    const isProfilePicture = watch('isProfilePicture')

    useEffect(() => {
        const cnfPassword = getValues('confirmPassword')
        const errorKeys = Object.keys(errors)
        if (cnfPassword !== "" && (password !== cnfPassword) && !errorKeys.includes('confirmPassword')) {
            setCnfError(true)
            return
        }
        setCnfError(false)
        clearErrors('confirmPassword')
    }, [password])

    useEffect(() => {
        setCnfError(false)
    }, [cnfpassword])

    useEffect(() => {
        // getAllUsers()
        axios.get(`${GETCOMMONVALUE}?filter=Gender&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setGender(formateData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })

        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setInitial(formateData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })

        axios.get(`${GETALLROLES}?pageNumber=-1`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response?.data?.data?.data?.filter((data: any) => data.isActive === true)?.map((data: any, index: any) => {
                    return {
                        label: data.roleName, value: data.userRoleId
                    }
                })
                setRoles(formateData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }, [])

    // useEffect(() => {
    //     const offset = currentPage * PER_PAGE
    //     const nextSet = users.slice(offset, offset + PER_PAGE)
    //     setFilterUsers(nextSet)
    // }, [currentPage])

    const onSubmit = () => {
        setLoading(true)
        const dataOriginal = getValues();
        let data = trimObjectValues(dataOriginal);

        const formD: any = new FormData();
        formD.append("Initials", data.initials)
        formD.append("FirstName", data.firstName)
        formD.append("LastName", data.lastName)
        formD.append("EmailAddress", data.emailAddress)
        formD.append("Password", data.password)
        formD.append("Gender", data.gender)
        formD.append("RoleId", data.roleId)
        formD.append("Notes", data.notes)
        formD.append("CompanyId", localStorage.getItem('companyId'))
        // formD.append("profilePicture", profilePicture != DefaultImage ? profilePicture : null)
        formD.append("profilePicture", profilePicture != DefaultImage ? typeof profilePicture == 'string' ? data?.profilePicture : profilePicture : null)
        formD.append("isProfilePicture", data?.isProfilePicture)
        formD.append("isactive", isActive)
        if (mode === 'create') {
            axios.post(USERUSINGBLOB, formD, { ...config, 'ContentType': 'application/json' })
                .then((response: any) => {
                    setLoading(false)
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(UserMessages.userCreated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        setConfirmPasswordType("password");
                        setPasswordType("password");
                        reset(initialData);
                        setToggleValue(!toggleModal);
                        getAllUsers();
                        setCurrentPage(1);
                        setSearchText('');
                        clearAdvanceFilterData();
                        setProfilePicture(null);
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error)
                })
        } else {
            formD.append("UserId", currentUser?.id)
            axios.post(UPDATEUSINGBLOB, formD, { ...config, 'ContentType': 'application/json' })
                .then((response) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(UserMessages.userUpdated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });

                        setConfirmPasswordType("password");
                        setPasswordType("password");
                        setToggleValue(!toggleModal);
                        if (redirectedToHeader || currentUser?.id == LoggedUserId) {
                            redirectedAfterSubmit(currentUser?.id)
                        }
                        getAllUsers();
                        setCurrentPage(1);
                        setSearchText('');
                        clearAdvanceFilterData();
                        setProfilePicture(null);
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error)
                })
        }
    }

    const redirectedAfterSubmit = (userId: any) => {
        setLoading(true);
        axios.get(GETBYIDUSINGBLOB, { ...config, params: { id: userId } }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const data = response.data?.data;
                dispatch(setLoggedUserData({ ...data, profilePicture: `${data?.profilePicture ? `${data?.profilePicture}?${data?.photosWithSasToken}` : ""}` }));
                // dispatch(setLoggedUserData({ ...data, profilePicture: `${process.env.REACT_APP_API_URL}${response.data?.data?.profilePicture}` }))
                redirectedToHeader && navigate(-1)
                dispatch(setRedirectedtoHeader(false));
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    const handleAdvanceFilter = (filterData: any, pageNum?: any) => {

        setLoading(true)
        // const joinedStr = filterData.role.map((list: any) => list.value).join(",");
        axios.get(ADVANCEFILTER, {
            ...config,
            params: {
                userName: filterData?.userName,
                emailAddress: filterData?.email,
                roleIds: filterData?.role?.value,
                isActive: filterData?.isActive?.value ?? -1,
                keyword: filterData?.keyword ?? '',
                pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterData([]);
                setFilterUsers([]);
                return;
            }
            else {
                paginateData(response?.data?.data?.data, pageNum ? pageNum : currentPage)
                setTotalRecords(response?.data?.data?.totalRecords)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
        return
    }

    const clearAdvanceFilterData = (isLocalClear: boolean = true) => {
        // setActive(false)
        if (!isLocalClear) {
            handleAdvanceFilter(filterData)
        }
        setFilterData({ role: "", userName: '', email: '', isActive: -1, })
        setSearchText('')
        setError({ email: "", userName: "" })
        getAllUsers();
        setAlreadyFetchedDataPageNumber({})
        setCurrentPage(1);
        setIsFiltered(false)
    }

    const handleClose = () => {
        setCurrentUser({});
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    reset(initialData);
                    setToggleValue(!toggleModal);
                    setConfirmPasswordType("password");
                    setPasswordType("password");
                    setRemainingCount(maxWordCount);
                    setModifiedBy('');
                    setProfilePicture(null);
                    redirectedToHeader && navigate(-1)
                    redirectedToHeader && dispatch(setRedirectedtoHeader(false))
                }
            })
            return
        }
        setConfirmPasswordType("password");
        setPasswordType("password");
        setToggleValue(!toggleModal);
        setRemainingCount(maxWordCount);
        setModifiedBy('');
        setProfilePicture(null);
        redirectedToHeader && navigate(-1)
        redirectedToHeader && dispatch(setRedirectedtoHeader(false))
    }

    const handleEditModal = (userId: string, type: string) => {
        if (type === 'edit') {
            const checkEditPer = Helper.checkAdminPermission(types.EDIT);
            if (checkEditPer) {
                setMode('edit');
                setLoading(true);
                axios.get(GETBYIDUSINGBLOB, { ...config, params: { id: userId } }).then((response) => {
                    setToggleValue(!toggleModal);
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        response.data.data.modifiedBy ? setModifiedBy(response.data.data.modifiedBy) : setModifiedBy(response.data.data.createdBy);
                        const { strGender, strInitial, strRoleName, ...otherFormData } = response.data.data;
                        setCurrentUser(response.data.data);
                        const pp = response.data?.data?.profilePicture;
                        const token = response.data?.data?.photosWithSasToken;
                        const profilePicture = pp && pp.trim() !== "" ? `${pp}?${token}` : DefaultImage;
                        setProfilePicture(profilePicture);
                        setFormValue({ ...otherFormData, confirmPassword: response.data.data.password });
                        reset({ ...otherFormData, confirmPassword: response.data.data.password, isProfilePicture: 1 });
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error)
                })
                return;
            }
            return;
        }

        const checkDelPer = Helper.checkAdminPermission(types.DELETE);
        if (checkDelPer) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    axios.post(`${DELETEUSER}?userId=${userId}`, {}, config).then((response) => {
                        if (response?.data?.success === "Fail") {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        }
                        else {
                            const data = document.getElementById('search') as HTMLInputElement;
                            data.value = "";
                            setLoading(false)
                            toast.success(UserMessages.userDeleted, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            getAllUsers();
                            setCurrentPage(1);
                            setSearchText('');
                            clearAdvanceFilterData();
                        }
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error)
                    })
                }
            })
        }
    }

    // const handleSearch = (e: any) => {
    //     const keyword = e?.toLocaleLowerCase();
    //     const filterData: any = originalUsers?.filter((x: any) => x.userName?.toLocaleLowerCase()?.includes(keyword) ||
    //         x.emailAddress?.toLocaleLowerCase()?.includes(keyword));
    //     paginateData(filterData);
    //     setCurrentPage(0)
    //     // setFilterUsers(filterData)
    // }

    // const handlePageClick = ({ selected: selectedPage }: any) => {
    //     setCurrentPage(selectedPage);
    // }

    const changeHandler = (fieldName: string, value: any) => {
        const updateData = { ...filterData, [fieldName]: value }
        setFilterData(updateData);
        if (fieldName === "userName") {
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
        if (fieldName === "email") {
            const isValidNumber = /^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)*)?\s*$/.test(value);
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else if (!isValidNumber) {
                setError({ ...error, [fieldName]: 'Please enter valid email only' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const togglePasswordConfirm = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = users.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = users.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName,currentPage);
        // setCurrentPage(1);
    }

    const handleLogoChange = (e: any) => {
        const file = e.target.files[0];
        if ((file.size / 1024) > 2048) {
            toast.error(errorMessages.fileSizeError, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 4000,
            });
            e.target.value = '';
            return;
        }
        if (!allowedTypes.includes(e.target.files[0]?.type)) {
            toast.warning(errorMessages.formatNotAllowed, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            e.target.value = '';
            return
        }
        setProfilePicture(file);
    }

    const deleteProfilePic = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this profile Picture!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#006800",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                setValue("isProfilePicture", 2)
                setProfilePicture(DefaultImage)
            }
        })
    }

    // pagination related changes arranged in sequence for take easy reference.

    const handlePageClick = (selected: any) => {
        const newPageNumber = selected + 1;
        setCurrentPage(newPageNumber)
    }

    const updateData = (pageNumber: number, newData: any) => {
        setAlreadyFetchedDataPageNumber((prevState: any) => ({
            ...prevState,
            [pageNumber]: newData
        }));
    };

    const paginateData = (data: any, pageNum: any = 1) => {
        let page: any = pageNum == 0 ? pageNum + 1 : pageNum
        setUsers(data);
        updateData(page, data)
        setFilterUsers(data);
    }

    const debouncedRequest = useDebounce(() => {
        handleAdvanceFilter({ ...filterData, keyword: searchText })
    });

    // this useEffect called when click on pageNumber
    useEffect(() => {
        //when global search applied
        if (searchText?.length) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterUsers(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            handleAdvanceFilter({ ...filterData, keyword: searchText })
            return
        }

        //when advanced search filter applied
        else if (isFiltered) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterUsers(alreadyFetchedDataPageNumber[currentPage])
                return
            }

            handleAdvanceFilter(filterData)
            return
        }

        //when clicked on already fetched page
        else {
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterUsers(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            getAllUsers(paginationSize, currentPage)
        }
    }, [currentPage])


    //this is for open popup when redirected to header profile section.
    useEffect(() => {
        if (redirectedToHeader) {
            handleEditModal(LoggedUserId, 'edit')
            // dispatch(setRedirectedtoHeader(false))
        }
    }, [redirectedToHeader])


    return (
        <>
        <CommonHelmet title={"User - "} name={"user"} content={"User"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            {/* create user modal starts */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add New User' : 'Edit User'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6 col-lg-2">
                                <label className="form-label" htmlFor="text">Initials <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Initials"
                                        options={initial}
                                        {...register('initials')}
                                        value={initial?.find((option: any) => option?.value == watchInitials)}
                                        onChange={(e: any) => setValue('initials', e.value)}
                                        className='isActive'
                                    />
                                    {errors.initials && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.initials.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">First Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    maxLength={20}
                                    placeholder="First Name"
                                    {...register('firstName')}
                                />
                                {errors?.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.firstName.message}</span>}
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">Last Name <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    maxLength={20}
                                    placeholder="Last Name"
                                    {...register('lastName')}
                                />
                                {errors?.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.lastName.message}</span>}
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <label className="form-label" htmlFor="text">Email <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="email"
                                    maxLength={50}
                                    placeholder="Email Address"
                                    {...register('emailAddress')}
                                />
                                {errors?.emailAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.emailAddress.message}</span>}
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">Password <sup className="text-danger">*</sup></label>
                                <div className="form-icon-container for_password">
                                    <input
                                        className="form-control form-icon-input ps-3"
                                        type={passwordType}
                                        maxLength={20}
                                        placeholder="Password"
                                        {...register('password')}
                                    />
                                    <i onClick={togglePassword} className={passwordType == 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                </div>
                                {errors?.password && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.password.message}</span>}
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">Confirm Password <sup className="text-danger">*</sup></label>
                                <div className="form-icon-container for_password">
                                    <input
                                        className="form-control form-icon-input ps-3"
                                        type={confirmPasswordType}
                                        placeholder="Confirm Password"
                                        maxLength={20}
                                        {...register('confirmPassword')}
                                    />
                                    <i onClick={togglePasswordConfirm} className={confirmPasswordType == 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                                </div>
                                {errors?.confirmPassword && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.confirmPassword.message}</span>}
                                {cnfError && <span className='text-danger' style={{ fontSize: "12px" }}>Password must match with confirm password</span>}
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">Gender <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Gender"
                                        options={gender}
                                        {...register('gender')}
                                        value={gender?.find((option: any) => option?.value == watchGender)}
                                        onChange={(e: any) => setValue('gender', e.value)}
                                        className='isActive'
                                    />
                                    {errors?.gender && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.gender.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <label className="form-label" htmlFor="text">Role <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy z-index-9999">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Role"
                                        options={roles}
                                        {...register('roleId')}
                                        value={roles?.find((option: any) => option?.value == watchRoleId)}
                                        onChange={(e: any) => setValue('roleId', e.value)}
                                        className='isActive'
                                    />
                                    {errors?.roleId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.roleId.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Notes</label>
                                <textarea
                                    className="form-control form-icon-input min-height-90"
                                    rows={2}
                                    placeholder="Notes"
                                    maxLength={maxWordCount}
                                    {...register('notes', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                />
                                {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                    {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                </div>
                                }

                                {errors?.notes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.notes.message}</span>}

                            </div>

                            <div className="col-md-12 add_new_user_profile">
                                <label className="form-label text-900">Profile Picture</label>
                                {profilePicture !== DefaultImage && <i title='Delete Profile Picture'>
                                    <button className="del_img_btn_pro" type="button" onClick={() => deleteProfilePic()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </i>}
                                <div className="d-flex flex-wrap">
                                    <div className=' upload_img_wrap'>
                                        {profilePicture !== null ? (
                                            <>
                                                <div className={profilePicture == DefaultImage ? 'uploadedImage user-bg-var' : "uploadedImage default-image-size"}>
                                                    <img
                                                        src={typeof profilePicture === 'string' ? profilePicture : URL.createObjectURL(profilePicture)}
                                                        alt=""
                                                        onError={(e: any) => {
                                                            e.target.src = DefaultImage
                                                            e.target.className = 'uploadedImage user-bg-var'
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : (

                                            <div className="uploadedImage user-bg-var">
                                                <img src={DefaultImage} width="150px" alt="" />
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className=" dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message">
                                        <div className="text-center text-1100">
                                            <h5 className="mb-2">
                                                <span className="fa-solid fa-upload me-2"></span>
                                                Upload Profile Picture
                                            </h5>
                                            <p className="mb-0 fs--1 text-600 lh-sm">Upload a jpg, png, gif image with <br />a maximum size of 2MB</p>
                                            <input type="file" name="profilePicture" onChange={handleLogoChange} className="companyLoader" accept="image/jpeg, image/png, image/jpeg" />
                                        </div>
                                    </div> */}
                                    <div className="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message">
                                        <div className="text-center text-1100">
                                            <h5 className="mb-2">
                                                <span className="fa-solid fa-upload me-2"></span>
                                                Upload Profile Picture
                                            </h5>
                                            <p className="mb-0 fs--1 text-600 lh-sm">Upload a jpg, png, gif image with <br />a maximum size of 2MB</p>
                                            <input type="file" name="profilePicture" onChange={handleLogoChange} className="companyLoader col-lg-12 z-0" accept="image/jpeg, image/png, image/jpeg" />
                                        </div>
                                    </div>
                                    {errors.profilePicture && (<span className="text-danger" style={{ fontSize: "12px" }}>{errors.profilePicture.message}</span>)}
                                </div>
                            </div>
                            {redirectedToHeader === false ?
                                <>
                                    {
                                        mode === 'edit' && (
                                            <div className="mt-4 pl-2">
                                                <label>Status</label>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckDefault"
                                                        // onClick={(e:any)=>handleActive(e)}

                                                        {...register('isActive')} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                                </div>
                                            </div>
                                        )}
                                </>
                                :
                                <></>}
                        </div>
                        {/* {Idd !== currentUser?.roleId &&
                                mode === 'edit' && (
                                    <div className="mt-4 pl-2">
                                        <label>Status</label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                                {...register('isActive')} />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                        </div>
                                    </div>
                                )} */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <ModifiedBy data={initialFormValue} />
                        <LastModified data={initialFormValue} />
                        <button className="btn btn-primary" type="submit"
                        >{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* create user modal ends */}

            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Users'>
                    <div className="search-box">
                        <div className="d-flex mt-2 align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" id="search" placeholder="Search" aria-label="Search" value={searchText}
                                    onChange={(e: any) => {
                                        setSearchText(e.target.value)
                                        if (e.target.value.length != 0) {
                                                setAlreadyFetchedDataPageNumber({})
                                                setCurrentPage(1)
                                                debouncedRequest();
                                            return
                                        } else {
                                            clearAdvanceFilterData(false);
                                        }

                                    }
                                    }
                                />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })}><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                        <button className="btn btn-primary ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                            onClick={() => {
                                const checkPer = Helper.checkAdminPermission(types.ADD)
                                if (checkPer) {
                                    reset(initialData)
                                    setModifiedBy('')
                                    setToggleValue(!toggleModal)
                                    setMode('create')
                                    setFormValue(initialData)
                                    setProfilePicture(DefaultImage)
                                }
                            }}>
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New User </span>
                        </button>
                    </div>

                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-2">
                                <button className="btn p-a p-1" type="button" onClick={() => setShowFilter(!showFilter)}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form >
                                            <div className="row g-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">Username</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="userName"
                                                        placeholder="Username"
                                                        value={filterData.userName}
                                                        onChange={((e) => changeHandler("userName", e.target.value))}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="text">Email</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type='email'
                                                        name="email"
                                                        placeholder="Email"
                                                        value={filterData.email}
                                                        onChange={((e) => changeHandler("email", e.target.value))}
                                                    />
                                                    {error.email && <span className='text-danger' style={{ fontSize: "12px" }}>{error.email}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Role</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select Role"
                                                        className='role'
                                                        name='role'
                                                        options={roles}
                                                        value={filterData.role}
                                                        onChange={(e) => changeHandler("role", e)}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Activation Status</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select Activation Status"
                                                        className='isActive'
                                                        name='isActive'
                                                        options={filterActivationStatus}
                                                        value={filterData.isActive}
                                                        onChange={(e) => changeHandler("isActive", e)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end mt-3 ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="button" onClick={() => clearAdvanceFilterData()}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="button"
                                                    onClick={() => {
                                                        // setSearchText('')
                                                        setCurrentPage(1)
                                                        setAlreadyFetchedDataPageNumber({})
                                                        setIsFiltered(true)
                                                        handleAdvanceFilter({ ...filterData, keyword: searchText }, 1)
                                                    }}

                                                >Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                                    <div className="table-responsive mx-n1 px-1 scrollbar">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant " scope="col">Action</th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="Initials">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Initials</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="User">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>User Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'userName')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'userName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="Email">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Email Address</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'emailAddress')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'emailAddress')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Roles</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'roleName')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'roleName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="Activation">Activation Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterUsers?.length > 0 ? filterUsers.map((user: any, index: any) => {
                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className={`font-sans-serif btn-reveal-trigger position-static`}>
                                                                                    <Dropdown.Toggle className='btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2' variant='none'>
                                                                                        <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(user.userId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(user.userId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>

                                                                            </td>
                                                                            <td className="align-middle  p-2">{user.initial}</td>
                                                                            <td className="align-middle  p-2">{user.userName}</td>
                                                                            <td className="align-middle review p-2">{user.emailAddress}</td>
                                                                            <td className="align-middle  p-2">{user.roleName ?? "-"}</td>
                                                                            <td className="align-middle text-start status p-2">
                                                                                {user.isActive == true ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                                                    <span className="badge-label">Active</span>

                                                                                </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                                                    <span className="badge-label">Inactive</span>

                                                                                </span>}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterUsers?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={Math.ceil(totalRecords / paginationSize)}
                                            onPageChange={({ selected }) => handlePageClick(selected)}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                                        />)}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    );
};

export default User;

