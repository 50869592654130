import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as yup from "yup";
import DateTime from "../../../../Utils/DateTime";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../../../Utils/Utils";
import {
  GETLOTREALESTATELOCATIONBYID, UPDATELOTREALESTATELOCATION
} from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  AlPHANUMERIC,
  DECIMAL,
  DECIMAL_MESSAGE,
  INTEGER,
  INTEGER_MESSAGE,
  YEAR,
  YEAR_MESSAGE
} from "../../../../common/regexConstants";
import * as routeConstant from "../../../../common/routeConstants";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../../store/actions/Inventory";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { LOTDEEDLOCATION } from "../../InventoryConstants";

const DeedLocation = () => {
  const dispatch = useDispatch();
  const lotId = useSelector((state: any) => state.inventory.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const mode = useSelector((state: any) => state.inventory.mode)
  const reState = useSelector((state: any) => state?.global?.state) || [];
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)

  let filterState = reState?.map((aData: any, index: any) => {
    return {
      label: aData.objectTypeValue, value: aData.objectValueId
    }
  })

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    isActive: true,
    isDelete: false,
    lotRealEstateLocationId: 0,
    lotId: mode === "clone" ? cloneLotId : lotId,
    assessedValue: "",
    year: "",
    propertySize: "",
    state: "",
    zip: "",
    frontage: "",
    depth: "",
    book: "",
    page: "",
    block: "",
    taxYear: "",
    taxes: "",
    lot: "",
    yrFcBegan: "",
    dateListfield: "",
    dateOfDeed: "",
    judgementDate: "",
    dateDeedRec: "",
    currentZoning: "",
    restrictions: "",
    permittedUsers: "",
    taxmap1: "",
    taxmap2: "",
    taxmap3: "",
    taxmap4: "",
    municipality: "",
    foreClosure: false,
    leased: false,
    surveys: false,
    sitePlan: false,
    swisCode: "",
    year2: "",
    propClass: "",
    closingDate: "",
    index: "",
    village: "",
    schDistrict: "",
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [initialFormValues, setInitialFormValues] = useState(initialData);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnTxt] = useState<any>("")

  const schema: any = yup.object().shape({
    assessedValue: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(INTEGER, INTEGER_MESSAGE),
    year: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      .matches(YEAR, YEAR_MESSAGE),
    propertySize: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL, DECIMAL_MESSAGE)
      .max(10, "Please enter upto 10 characters."),
    zip: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s/.test(value) : true;
      })
      .matches(/^[0-9a-zA-Z\s-]*$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
      .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
      // .min(3, 'Zip code must be at least 3 characters.')
      .max(12, 'Zip code cannot be longer than 12 characters.'),
    frontage: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(AlPHANUMERIC, "Please enter valid Frontage")
      .max(49, "Please enter upto 50 characters."),
    depth: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Depth")
      .max(49, "Please enter upto 50 characters."),
    taxYear: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(YEAR, YEAR_MESSAGE),
    taxes: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(INTEGER, INTEGER_MESSAGE),
    lot: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(DECIMAL, DECIMAL_MESSAGE),
    page: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(INTEGER, INTEGER_MESSAGE),
    book: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Book")
      .max(49, "Please enter upto 50 characters."),
    block: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Block")
      .max(49, "Please enter upto 50 characters."),
    yrFcBegan: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid YrFC Began")
      .max(49, "Please enter upto 50 characters."),
    currentZoning: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Current Zoning")
      .max(49, "Please enter upto 50 characters."),
    restrictions: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Restrictions")
      .max(49, "Please enter upto 50 characters."),
    taxmap1: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid tax map 1")
      .max(49, "Please enter upto 50 characters."),
    taxmap2: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid tax map 2")
      .max(49, "Please enter upto 50 characters."),
    taxmap3: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid tax map 3")
      .max(49, "Please enter upto 50 characters."),
    taxmap4: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid tax map 4")
      .max(49, "Please enter upto 50 characters."),
    municipality: yup
      .string()
      .notRequired()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid Municipality")
      .max(49, "Please enter upto 50 characters."),
    index: yup
      .string()
      // .required("Index is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(INTEGER, INTEGER_MESSAGE),
    swisCode: yup
      .string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(INTEGER, INTEGER_MESSAGE),
    year2: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }).matches(YEAR, YEAR_MESSAGE),
    propClass: yup
      .string()
      .matches(AlPHANUMERIC, "Please enter valid Prop Class")
      .max(49, "Please enter upto 50 characters."),
    permittedUsers: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
      if (!value) return true;
      return !/^\s/.test(value);
    }).matches(INTEGER, INTEGER_MESSAGE),
    dateListfield: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    dateOfDeed: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    judgementDate: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    dateDeedRec: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    closingDate: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    village: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid village Name")
      .max(49, "Please enter upto 50 characters."),
    schDistrict: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(AlPHANUMERIC, "Please enter valid District")
      .max(49, "Please enter upto 50 characters."),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  const watchState = watch('state')

  useEffect(() => {
    setState(filterState);
  }, []);

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const onSubmit = () => {
    setLoading(true);
    const dataOriginal = getValues();
    const deedLocationData = trimObjectValues(dataOriginal);
    const payload: any = {
      ...deedLocationData,
      assessedValue: deedLocationData.assessedValue ? parseInt(deedLocationData.assessedValue) : 0,
      year: deedLocationData.year ? parseInt(deedLocationData.year) : 0,
      state: deedLocationData.state ? parseInt(deedLocationData.state) : 0,
      page: deedLocationData.page ? parseInt(deedLocationData.page) : 0,
      taxYear: deedLocationData.taxYear ? parseInt(deedLocationData.taxYear) : 0,
      taxes: deedLocationData.taxes ? parseInt(deedLocationData.taxes) : 0,
      lot: deedLocationData.lot ? parseInt(deedLocationData.lot) : 0,
      permittedUsers: deedLocationData.permittedUsers ? parseInt(deedLocationData.permittedUsers) : 0,
      swisCode: deedLocationData.swisCode ? parseInt(deedLocationData.swisCode) : 0,
      year2: deedLocationData.year2 ? parseInt(deedLocationData.year2) : 0,
      index: deedLocationData.index ? parseInt(deedLocationData.index) : 0,
      country: 0,
      dateListfield: deedLocationData.dateListfield ? moment(deedLocationData.dateListfield).format("YYYY-MM-DD") : null,
      dateOfDeed: deedLocationData.dateOfDeed ? moment(deedLocationData.dateOfDeed).format("YYYY-MM-DD") : null,
      judgementDate: deedLocationData.judgementDate ? moment(deedLocationData.judgementDate).format("YYYY-MM-DD") : null,
      dateDeedRec: deedLocationData.dateDeedRec ? moment(deedLocationData.dateDeedRec).format("YYYY-MM-DD") : null,
      closingDate: deedLocationData.closingDate ? moment(deedLocationData.closingDate).format("YYYY-MM-DD") : null,
      zipCode: deedLocationData.zip.toString(),
    }

    axios.post(UPDATELOTREALESTATELOCATION, payload, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, LOTDEEDLOCATION]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, LOTDEEDLOCATION]))
        }
        nextStep();
        toast.success(InventoryMessages.deedLocUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  useEffect(() => {
    setLoading(true);
    const id = mode === "clone" ? cloneLotId : lotId
    axios.get(`${GETLOTREALESTATELOCATIONBYID}/?lotId=${id}`, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        return;
      } else {
        let data = response.data.data;
        data = {
          ...data,
          assessedValue: data.assessedValue > 0 ? parseInt(data.assessedValue) : "",
          year: data.year > 0 ? parseInt(data.year) : "",
          state: data.state > 0 ? parseInt(data.state) : "",
          page: data.page > 0 ? parseInt(data.page) : "",
          taxYear: data.taxYear > 0 ? parseInt(data.taxYear) : "",
          taxes: data.taxes > 0 ? parseInt(data.taxes) : "",
          lot: data.lot > 0 ? parseInt(data.lot) : "",
          permittedUsers: data.permittedUsers > 0 ? parseInt(data.permittedUsers) : "",
          swisCode: data.swisCode > 0 ? parseInt(data.swisCode) : "",
          year2: data.year2 > 0 ? parseInt(data.year2) : "",
          index: data.index > 0 ? parseInt(data.index) : "",
          dateListfield: data.dateListfield ? moment(data.dateListfield).format("MM/DD/YYYY") : "",
          dateOfDeed: data.dateOfDeed ? moment(data.dateOfDeed).format("MM/DD/YYYY") : "",
          judgementDate: data.judgementDate ? moment(data.judgementDate).format("MM/DD/YYYY") : "",
          dateDeedRec: data.dateDeedRec ? moment(data.dateDeedRec).format("MM/DD/YYYY") : "",
          closingDate: data.closingDate ? moment(data.closingDate).format("MM/DD/YYYY") : "",
          zip: data.zipCode !== "" ? data.zipCode : ""
        }
        reset(data)
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }, []);

  const nextStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "agents" }));
  };

  const prevStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "records" }));
  };

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTDEEDLOCATION)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTDEEDLOCATION)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="loader-wrap">
              <img src={`${localStorage.getItem("globalLoader")}`} />
            </div>
          )}
          <div className="row g-3 mb-3">
            <div className="col-lg-4">
              <label className="form-label">Assessed Value</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Assessed Value"
                maxLength={9}
                {...register("assessedValue")}
                onKeyDown={handleKeyDown}
              />
              {errors.assessedValue && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.assessedValue.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Year</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Year"
                maxLength={4}
                {...register("year")}
                onKeyDown={handleKeyDown}
              />
              {errors.year && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.year.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Property Size</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="Property Size"
                {...register("propertySize")}
                onKeyDown={handleKeyDown}
              />
              {errors.propertySize && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.propertySize.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Zip</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Zip"
                maxLength={12}
                {...register("zip")}
              />
              {errors.zip && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.zip.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Frontage</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Frontage"
                maxLength={50}
                {...register("frontage")}
              />
              {errors.frontage && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.frontage.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Depth</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Depth"
                {...register("depth")}
              />
              {errors.depth && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.depth.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Book</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Book"
                {...register("book")}
              />
              {errors.book && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.book.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Page</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={9}
                placeholder="Page"
                {...register("page")}
                onKeyDown={handleKeyDown}
              />
              {errors.page && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.page.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Block</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Block"
                {...register("block")}
              />
              {errors.block && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.block.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Tax Year</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Year"
                maxLength={4}
                {...register("taxYear")}
                onKeyDown={handleKeyDown}
              />
              {errors.taxYear && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxYear.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Taxes</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={9}
                placeholder="Taxes"
                {...register("taxes")}
                onKeyDown={handleKeyDown}
              />
              {errors.taxes && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxes.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Lot</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Lot"
                {...register("lot")}
                onKeyDown={handleKeyDown}
              />
              {errors.lot && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.lot.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">State</label>
              <Select
                classNamePrefix="dpaSelect"
                placeholder="Select"
                className='isActive'
                {...register('state')}
                name='country'
                options={filterState}
                value={filterState?.find((option: any) => option?.value == watchState)}
                onChange={(e: any) => setValue('state', e.value)}
              />
              {errors.state && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.state.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Yr F/C Began</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Yr F/C Began"
                {...register("yrFcBegan")}
              />
              {errors.yrFcBegan && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.yrFcBegan.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Muncipality</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Muncipality"
                {...register("municipality")}
              />
              {errors.municipality && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.municipality.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Current zoning</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Current Zoning"
                {...register("currentZoning")}
              />
              {errors.currentZoning && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.currentZoning.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Restriction</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Restriction"
                {...register("restrictions")}
              />
              {errors.restrictions && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.restrictions.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Permitted users</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={9}
                placeholder="Permitted Users"
                {...register("permittedUsers")}
                onKeyDown={handleKeyDown}
              />
              {errors.permittedUsers && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.permittedUsers.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Date List field</label>
              <div className="date_icon">
                <Controller
                  name="dateListfield"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      onChange={onChange}
                      value={value}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/y"
                      inputProps={{
                        placeholder: "Date",
                        className: "form-control form-icon-input only_date_icon",
                      }}
                    />
                  )}
                />
              </div>
              {errors.dateListfield && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.dateListfield.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Date of Deed</label>
              <div className="date_icon">
                <Controller
                  name="dateOfDeed"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      onChange={onChange}
                      value={value}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/y"
                      inputProps={{
                        placeholder: "Date",
                        className: "form-control form-icon-input only_date_icon",
                      }}
                    />
                  )}
                />
              </div>
              {errors.dateOfDeed && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.dateOfDeed.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Judgement Deed</label>
              <div className="date_icon">
                <Controller
                  name="judgementDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      onChange={onChange}
                      value={value}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/y"
                      inputProps={{
                        placeholder: "Date",
                        className: "form-control form-icon-input only_date_icon",
                      }}
                    />
                  )}
                />
              </div>
              {errors.judgementDate && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.judgementDate.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Date Deed Rec.</label>
              <div className="date_icon">
                <Controller
                  name="dateDeedRec"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      onChange={onChange}
                      value={value}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/y"
                      inputProps={{
                        placeholder: "Date",
                        className: "form-control form-icon-input only_date_icon",
                      }}
                    />
                  )}
                />
              </div>
              {errors.dateDeedRec && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.dateDeedRec.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 1</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Tax Map 1"
                {...register("taxmap1")}
              />
              {errors.taxmap1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxmap1.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 2</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Tax Map 2"
                {...register("taxmap2")}
              />
              {errors.taxmap2 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxmap2.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 3</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Tax Map 3"
                {...register("taxmap3")}
              />
              {errors.taxmap3 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxmap3.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 4</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Tax Map 4"
                {...register("taxmap4")}
              />
              {errors.taxmap4 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxmap4.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Fore Closure</label>
              <div className="form-check">
                <input
                  className="form-check-input form-check-states"
                  type="checkbox"
                  {...register("foreClosure")}
                  id="foreClosure"
                />
                <label htmlFor="foreClosure" className="form-check-label text-900 fw-400">Yes</label>
              </div>
            </div>
            <div className="col-lg-3">
              <label className="form-label">Leased</label>
              <div className="form-check">
                <input
                  className="form-check-input form-check-states"
                  type="checkbox"
                  {...register("leased")}
                  id="leased"
                />
                <label htmlFor="leased" className="form-check-label text-900 fw-400">Yes</label>
              </div>
            </div>
            <div className="col-lg-3">
              <label className="form-label">Surveys</label>
              <div className="form-check">
                <input
                  className="form-check-input form-check-states"
                  type="checkbox"
                  {...register("surveys")}
                  id="surveys"
                />
                <label htmlFor="surveys" className="form-check-label text-900 fw-400">Yes</label>
              </div>
            </div>
            <div className="col-lg-3">
              <label className="form-label">Site Plan</label>
              <div className="form-check">
                <input
                  className="form-check-input form-check-states"
                  type="checkbox"
                  {...register("sitePlan")}
                  id="sitePlan"
                />
                <label htmlFor="sitePlan" className="form-check-label text-900 fw-400">Yes</label>
              </div>
            </div>
            <div className="col-lg-4">
              <label className="form-label">Swis code</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={9}
                placeholder="Swis Code"
                {...register("swisCode")}
                onKeyDown={handleKeyDown}
              />
              {errors.swisCode && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.swisCode.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Year</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Year"
                maxLength={4}
                {...register("year2")}
                onKeyDown={handleKeyDown}
              />
              {errors.year2 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.year2.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Prop Class</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Prop Class"
                {...register("propClass")}
              />
              {errors.propClass && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.propClass.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Closing Date</label>
              {/* <input
                className="form-control form-icon-input"
                type="date"
                {...register("closingDate")}
              /> */}
              <div className="date_icon">
                <Controller
                  name="closingDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      onChange={onChange}
                      value={value}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="MM/DD/y"
                      inputProps={{
                        placeholder: "Date",
                        className: "form-control form-icon-input only_date_icon",
                      }}
                    />
                  )}
                />
              </div>
              {errors.closingDate && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.closingDate.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Index</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={9}
                placeholder="Index"
                {...register("index")}
              />
              {errors.index && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.index.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Village</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Village"
                {...register("village")}
              />
              {errors.village && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.village.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Sch District</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Sch District"
                {...register("schDistrict")}
              />
              {errors.schDistrict && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.schDistrict.message}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
              <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
              <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
              <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
              <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default DeedLocation;
