import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface SalesRepState {
    contact: object
    commission: object
    auction: object
    adjust: object
    payment: object,
    saleRepId: any
    activeTab: string
    loading: boolean
    commissionOptions: {}
    AuctionInfoActiveTab: string
    selectedAuction: object
    salesRepAuctions: []
    salesRepLots: []
}

const initialState: SalesRepState = {
    contact: {},
    commission: {},
    auction: {},
    adjust: {},
    payment: {},
    commissionOptions: {},
    saleRepId: 0,
    activeTab: "contact",
    loading: false,
    AuctionInfoActiveTab: "inventory",
    selectedAuction: {},
    salesRepAuctions: [],
    salesRepLots: []
};

export const SalesRepReducer = (
    state: SalesRepState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.SALESREPLOADER: {
            return { ...state, loading: action.payload }
        }
        case actions.SETSALEREPID: {
            return { ...state, saleRepId: action.payload }
        }
        case actions.SETSALESREPACTIVETAB: {
            return { ...state, activeTab: action.payload }
        }
        case actions.SALESREPCONTACT: {
            return { ...state, contact: action.payload }
        }
        case actions.SALESREPCOMM: {
            return { ...state, commission: action.payload }
        }
        case actions.SALESREPAUCTION: {
            return { ...state, auction: action.payload }
        }
        case actions.SETADJUST: {
            return { ...state, adjust: { ...state.adjust, lstsalesRepAdjusts: action.payload } }
        }
        case actions.SETADJUSTFROMRESP: {
            return { ...state, adjust: action.payload }
        }
        case actions.SETPAYMENT: {
            return { ...state, payment: { ...state.payment, lstsalesRepPayments: action.payload } }
        }
        case actions.SETPAYMENTRESP: {
            return { ...state, payment: action.payload }
        }
        case actions.SETCOMMISSIONOPTIONS: {
            return { ...state, commissionOptions: action.payload }
        }
        case actions.RESETRESP: {
            return initialState
        }
        case actions.SALESREPAUCTIONS:
            return {
                ...state,
                salesRepAuctions: action.payload
            };
        case actions.SETAUCTIONINFOACTIVETAB_SALESREP:
            return {
                ...state,
                AuctionInfoActiveTab: action.payload
            };

        case actions.SELECTAUCTION_SALESREP:
            return {
                ...state,
                selectedAuction: action.payload
            };

        case actions.SALESREPLOTS:
            return {
                ...state,
                salesRepLots: action.payload
            };

        default:
            return state;
    }
};
