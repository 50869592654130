import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants'
import ReactPaginate from "react-paginate";
import axios from "axios";
import { CUSTOMERSUSPENDADVANCESEARCH, GETCUSTOMERSUSPENDREPORT } from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import moment from "moment";


import CalendarIcon from "../../assets/img/calendar.svg";
import { errorMessages } from "../../common/messageConstants";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from 'xlsx';
import { UTCdateConverter } from "../../common/constants"
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { generateSuspendCustomerHistoryCsv, generateSuspendCustomerHistoryPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Suspend Buyers History', url: routeConstant.SUSPENDREPORT }
]

const SuspendReport = () => {
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>({ firstName: "", lastName: "", startDateTime: "", endDateTime: "" });
    const [originalSuspend, setOriginalSuspend] = useState<any>([])
    const [suspendData, setSuspendData] = useState<any[]>([]);
    const [filterSuspend, setFilterSuspend] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetDate, setResetDate] = useState(true);
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
    });

    const PER_PAGE = 10;
    const pageCount = Math.ceil(suspendData.length / PER_PAGE);

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = originalSuspend?.filter((x: any) => x.customerName?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }
    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...suspendData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        paginateData(sortedData);
        setCurrentPage(0);
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const clearAdvanceFilterData = () => {
        setFilterData({ firstName: "", lastName: "", startDateTime: "", endDateTime: "" });
        setError({
            firstName: '',
            lastName: '',
        })
        getSuspendData()
        setResetDate(false)
    }

    const paginateData = (data: any) => {
        setSuspendData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSuspend(firstSet)
    }

    const convertToDateOnlyFormat = (dateTimeString: any) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const changeHandler = (fieldName: string, value: any) => {
        let formattedValue = value;
        if (fieldName == "startDateTime") {
            formattedValue = convertToDateOnlyFormat(value);
        }
        if (fieldName == "endDateTime") {
            formattedValue = convertToDateOnlyFormat(value);
        }
        const updateData = { ...filterData, [fieldName]: formattedValue }
        setFilterData(updateData);
        if (fieldName === "firstName" || fieldName === "lastName") {
            const isValidName = !/^\s/.test(value);
            if (!isValidName) {
                setError({ ...error, [fieldName]: 'Leading spaces are not allowed' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const handleAdvanceFilter = () => {
        setLoading(true)
        axios.get(`${CUSTOMERSUSPENDADVANCESEARCH}?firstName=${filterData.firstName}&lastName=${filterData.lastName}&startDateTime=${filterData.startDateTime}&endDateTime=${filterData.endDateTime}`)
            .then((response) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setFilterSuspend([]);
                    return;
                }
                else {
                    paginateData(response.data.data)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        return
    }

    const getSuspendData = () => {
        setLoading(true)
        axios.get(GETCUSTOMERSUSPENDREPORT).then((response: any) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterSuspend([]);
                return;
            }
            else {
                const suspData = response?.data?.data;
                paginateData(suspData);
                setOriginalSuspend(suspData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = suspendData?.slice(offset, offset + PER_PAGE)
        setFilterSuspend(nextSet)
    }, [currentPage])

    useEffect(() => {
        getSuspendData()
    }, [])

    // this is for reset date in filter section.
    useEffect(() => {
        if (resetDate == false) {
            setTimeout(() => { setResetDate(true) }, 50)
        }
    }, [filterData])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Suspend Buyers History'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=> generateSuspendCustomerHistoryCsv(suspendData)} disabled={suspendData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateSuspendCustomerHistoryPdf(suspendData)} disabled={suspendData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-3">
                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter); getSuspendData(); setFilterData({ firstName: "", lastName: "", startDateTime: "", endDateTime: "" }) })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">First name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="firstName"
                                                        maxLength={25}
                                                        placeholder="Enter First Name"
                                                        value={filterData.firstName}
                                                        onChange={((e) => changeHandler("firstName", e.target.value))}
                                                    />
                                                    {error?.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.firstName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Last name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lastName"
                                                        maxLength={25}
                                                        placeholder="Enter Last Name"
                                                        value={filterData.lastName}
                                                        onChange={((e) => changeHandler("lastName", e.target.value))}
                                                    />
                                                    {error?.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.lastName}</span>}
                                                </div>
                                            </div>
                                            {resetDate ?
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label">Suspended From Date
                                                            {/* ({defaultTimezone}) */}
                                                        </label>
                                                        {/* <input
                                                        className="form-control form-icon-input"
                                                        type="date"
                                                        name="startDateTime"
                                                        placeholder="Suspended From Date"
                                                        value={filterData.startDateTime}
                                                        onChange={((e) => changeHandler("startDateTime", e.target.value))}
                                                    /> */}
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input only_date_icon",
                                                                    placeholder: "Suspended From Date",
                                                                    name: "startDateTime",
                                                                }}
                                                                closeOnSelect={true}
                                                                value={filterData.startDateTime}
                                                                onChange={(date: any) => changeHandler("startDateTime", date)}
                                                                timeFormat={false}
                                                                dateFormat="MM/DD/y"
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                            {resetDate ?
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label">Suspended To Date
                                                            {/* ({defaultTimezone}) */}
                                                        </label>
                                                        {/* <input
                                                        className="form-control form-icon-input"
                                                        type="date"
                                                        name="endDateTime"
                                                        placeholder="Suspended To Date"
                                                        value={filterData.endDateTime}
                                                        onChange={((e) => changeHandler("endDateTime", e.target.value))}
                                                    /> */}
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input  only_date_icon",
                                                                    placeholder: "Suspended To Date",
                                                                    name: "endDateTime",
                                                                }}
                                                                closeOnSelect={true}
                                                                value={filterData.endDateTime}
                                                                onChange={(date: any) => changeHandler("endDateTime", date)}
                                                                timeFormat={false}
                                                                dateFormat="MM/DD/y"
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end  ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="button" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="button" onClick={handleAdvanceFilter}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Suspended Date ({defaultTimezone})</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'datetime')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'datetime')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Buyer Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'customerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'customerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="message">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Suspended Status</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="message">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Suspended Reason</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterSuspend.length > 0 ? filterSuspend.map((sData: any, sIndex: any) => {
                                                const datetime = momentUtil.parseTimeInUtc({ dateTimeString: sData?.datetime })

                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={sIndex}>
                                                        <td className="align-middle p-2">{datetime.format('MM/DD/YYYY hh:mm A')}</td>
                                                        <td className="align-middle  p-2">{sData.customerName}</td>
                                                        <td className="align-middle p-2">
                                                            <span className={`badge badge-dpa fs--2 ${sData.isSuspend ? 'badge-dpa-danger' : 'badge-dpa-success'}`}>
                                                                <span className="badge-label">{sData.isSuspend ? 'Suspended' : 'Activated'}</span>
                                                            </span>
                                                        </td>
                                                        <td className="align-middle p-2">{sData.suspendedReason ?? '-'}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={4}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterSuspend.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SuspendReport;
