import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import { Swiper, SwiperSlide } from "swiper/react"
import { v4 as uuidv4 } from 'uuid'
import { decryptId } from "../../../../Encryption/Encryption"
import { DELETEAUCTION,  UPDATEAUCTIONBLOBLOGOPHOTO, GETBLOBPHOTOSBYAUCTIONID} from "../../../../common/apiConstatnts"
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants"
import * as routeConstant from '../../../../common/routeConstants'
import * as actions from '../../../../common/types'
import { setAuctionInfoActive, setCloneTab, setCreateTab } from "../../../../store/actions/AuctionAction"
import { loader } from "../../../../store/actions/CommonAction"
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import { LOGOPHOTOS } from "../../AuctionConstants"
import Fancybox from './Fancybox'
import handleError from "../../../../Utils/ApiCommonErrorMessages"

const LogoPhotos = () => {
    const params = useParams()
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const ID = params.id ? decryptId(params.id) : "";
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const allowedTypes = ["image/jpg", "image/png", "image/jpeg", "image/gif"];
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const swiperRef: any = useRef(null);
    const [imagesData, setImagesData] = useState<any>([])
    const activeImagesData = imagesData.filter((i: any) => i.isActive === true)
    const sidebarOpen = useSelector((state: any) => state?.global.sidebarOpen)
    const [thumbsSwiper, setThumbsSwiper]: any = useState(null);
    const [btnTxt, setBtnTxt] = useState<any>("")

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            enctype: "multipart/form-data",
        }
    };

    const handleUpload = (e: any) => {
        const filesLength = e.target.files.length;
        if (!allowedTypes.includes(e.target.files[0]?.type)) {
            toast.warning(errorMessages.formatNotAllowed, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return
        }

        let uploadedData = []
        for (let i = 0; i < filesLength; i++) {
            const file = e.target.files[i]
            if (!allowedTypes.includes(file?.type)) continue
            const imgObj = {
                auctionLogoPhotosId: 0,
                auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
                photos: file,
                companyId: localStorage.getItem('companyId'),
                isActive: true,
                isDelete: false,
                auctionOrDefaults: 0,
                uniqueId: uuidv4(),
                mediaType: file.type === "video/mp4" ? 2 : 1
            }
            uploadedData.push(imgObj)
        }
        if (activeImagesData.length === 0) {
            uploadedData = uploadedData.map((i: any, index) => {
                if (index === 0) {
                    return { ...i, auctionOrDefaults: 1 }
                }
                return i
            })
        }
        setImagesData([...imagesData, ...uploadedData])
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActive({ activeStep: 1, activeLabel: "Address & Dates" }))
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActive({ activeStep: 3, activeLabel: "Automated Email" }))
        // dispatch(setStep(3))
    }

    const setDefaultImage = (id: any) => {
        const newData = imagesData.map((image: any) => {
            if (image.uniqueId == id) {
                return { ...image, auctionOrDefaults: 1 }
            }
            return { ...image, auctionOrDefaults: 0 }
        }).filter((iData: any) => iData.isActive === true)
        setImagesData(newData)
        toast.success(AuctionMessages.auctionInfoMessages.setDefaultlogoPhotos, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
    }

    const handleDeleteImage = (uniqueId: any) => {
        const updateImages = imagesData.map((data: any) => {
            if (data.uniqueId === uniqueId) {
                return { ...data, isActive: false, isDelete: true, auctionOrDefaults: 0 }
            }
            return data
        })
        setImagesData(updateImages)
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData: any = new FormData();
        const removeDummy = imagesData.map((image: any) => {
            if (image.auctionLogoPhotosId === 0 && image.isActive === false) {
                return {}
            }
            return image
        })
        const finalData = removeDummy.filter((data: any) => Object.keys(data).length > 0)
        if (finalData.length > 0) {
            let i = 0;
            finalData.forEach((image: any) => {
                formData.append(`photos[${i}].auctionId`, parseInt(image.auctionId));
                formData.append(`photos[${i}].auctionLogoPhotosId`, image.auctionLogoPhotosId);
                formData.append(`photos[${i}].auctionOrDefaults`, finalData?.length === 1 ? 1 : image.auctionOrDefaults);
                formData.append(`photos[${i}].photos`, image.photos);
                formData.append(`photos[${i}].isActive`, image.isActive)
                formData.append(`photos[${i}].isDelete`, image.isDelete)
                formData.append(`photos[${i}].mediaType`, image.mediaType)
                i++
            });

            dispatch(loader(true))
            axios.post(UPDATEAUCTIONBLOBLOGOPHOTO, formData, config).then(async (response: any) => {
                dispatch(loader(false))
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, LOGOPHOTOS]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, LOGOPHOTOS]))
                }
                handleNext();
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            });
        } else {
            toast.error(AuctionMessages.auctionInfoMessages.minOneImage, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const deleteAuction = () => {
        if (ID) {
            dispatch({ type: actions.RESETAUCTION })
            navigate(routeConstant.AUCTION)
            return
        }
        Swal.fire({
            title: "Are you sure?",
            text: "Changes will be discarded!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#006800",
            cancelButtonColor: "#d33",
            confirmButtonText: "Discard",
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${DELETEAUCTION}?auctionId=${auctionId}`).then((response) => {
                    dispatch({ type: actions.RESETAUCTION })
                    navigate(routeConstant.AUCTION)
                    toast.success(AuctionMessages.auctionInfoMessages.changeDiscard, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }).catch((error) => {
                    handleError(error);
                })
            }
        });

    }

    useEffect(() => {
        setTimeout(() => {
            if (swiperRef.current) {
                swiperRef.current.update();
            }
        }, 350);
    }, [sidebarOpen])

    useEffect(() => {
        if (!clonedTabs.includes(LOGOPHOTOS) && mode === "clone") {
            setImagesData([])
        } else {
            dispatch(loader(true));
            const aId = clonedTabs.includes(LOGOPHOTOS) ? cloneAuctionId : auctionId
            axios.get(`${GETBLOBPHOTOSBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
                if (response?.data?.success === "Fail") {
                    dispatch(loader(false))
                    return;
                }
                else {
                    dispatch(loader(false));
                    const data = response.data.data
                    setImagesData(data.map((data: any) => { return { ...data, uniqueId: uuidv4() } }))
                }
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            })
        }
    }, [])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(LOGOPHOTOS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(LOGOPHOTOS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (

        <>
            <form action="" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mb-4">
                        <div className="position-relative swiper-francy-slider">
                            {activeImagesData?.length > 0 &&
                                <>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        <Swiper
                                            pagination={{
                                                type: 'fraction',
                                            }}
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{ swiper: thumbsSwiper }}
                                            modules={[FreeMode, Navigation, Thumbs, Pagination]}
                                            className="mySwiper2"
                                        >
                                            {
                                                activeImagesData?.map((data: any, index: any) => {
                                                    const blob = new Blob([data.photos], { type: 'video/mp4' });
                                                    const objectURL = URL.createObjectURL(blob);                                                    
                                                    return (
                                                        <SwiperSlide>
                                                            {
                                                                data.auctionOrDefaults === 1 ? <><span className="def_img badge">DEFAULT</span></> :
                                                                    <>
                                                                        <button className="del_img_btn" type="button" onClick={() => handleDeleteImage(data.uniqueId)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                        <button className="def_img_btn badge" type="button" onClick={() => setDefaultImage(data.uniqueId)}>
                                                                            Set as Default
                                                                        </button>
                                                                    </>
                                                            }
                                                            <a href={data.auctionLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data.photos)} data-fancybox="gallery">
                                                                {data?.mediaType == 2 ?
                                                                    <video controls className='w-100' ><source src={data.auctionLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : `${objectURL}`} type="video/mp4" /></video>
                                                                    :
                                                                    <img className='w-100' src={data.auctionLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data?.photos)} alt={`img_${index}`} />
                                                                }
                                                            </a>

                                                        </SwiperSlide>
                                                    )
                                                })}

                                        </Swiper>
                                    </Fancybox>
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        loop={true}
                                        spaceBetween={10}
                                        slidesPerView={6}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                    >
                                        {
                                            activeImagesData?.map((data: any, index: any) => {
                                                const blob = new Blob([data.photos], { type: 'video/mp4' });
                                                const objectURL = URL.createObjectURL(blob);
                                                return (
                                                    <SwiperSlide>
                                                        {data?.mediaType == 2 ?
                                                            <video controls className='w-100' ><source src={data.auctionLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : `${objectURL}`} type="video/mp4" /></video>
                                                            : <img className='w-100' src={data.auctionLogoPhotosId !== 0 ? `${data.photos}?${data.photosWithSasToken}` : URL.createObjectURL(data.photos)} alt={`img_${index}`} />
                                                        }
                                                    </SwiperSlide>
                                                )
                                            })}
                                    </Swiper>
                                </>
                            }
                        </div>
                    </div>
                    <div className={`${activeImagesData.length > 0 ? "col-lg-5 col-xl-6 mb-4" : "col-lg-12 mb-4"}`}>
                        <div className="Neon Neon-theme-dragdropbox mb-4">
                            <input name="files[]" id="filer_input2" className="upload_img_box" type="file" onChange={handleUpload} multiple accept="image/jpg, image/png, image/jpeg" />
                            <div className="Neon-input-dragDrop">
                                <div className="Neon-input-inner">
                                    <div className="Neon-input-icon"><i className="fa fa-file-image-o"></i></div>
                                    <a className="Neon-input-choose-btn blue mb-4">Upload Images</a>
                                    <div className="Neon-input-text"><span className="mt-4"><i><span className="text-danger">**</span>Note: Accepted file formats - JPG, JPEG, PNG</i></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <span className="btn btn-outline-danger me-auto" data-bs-dismiss="modal" onClick={deleteAuction}>{CommonMessages.cancelButton}</span>
                        <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
                        {/* {imagesData?.length && <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>Skip</button>} */}
                    </div>
                </div>
            </form>
        </>
    )
}

export default LogoPhotos