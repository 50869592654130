import axios from "axios"
import { GETALLBIDDERCONFIG } from "./apiConstatnts"
import { toast } from "react-toastify"
import moment from "moment"
import 'moment-timezone';
import { errorMessages } from "./messageConstants";

export const charCountForAddress = (length: any) => {
    let container = document.getElementsByClassName('pac-container')
    if (length > 3) {
        container[0].className = 'pac-container pac-logo'
    } else {
        if (!container[0].className.includes('hideList')) {
            container[0].className += ' hideList'
        }
    }
}


