import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { GETCOMMONVALUE, UPDATESALESPAYMENT } from "../../../../common/apiConstatnts";
import { taxExemptType } from "../../../../common/constants";
import * as routeConstant from '../../../../common/routeConstants';
import { loader, setAuctionInfoActiveTab, setPayment, setPaymentResp } from "../../../../store/actions/SalesRepAction";
import { CommonMessages, errorMessages } from "../../../../common/messageConstants";
import DateTime from "../../../../Utils/DateTime";
import { ERROR_MESSAGES, formatCurrency, handleKeyDown } from "../../../../Utils/Utils";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const Payment = () => {
    const dispatch = useDispatch()
    const paymentData = useSelector((state: any) => state.salesRep.payment);
    const salesRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const selectedAuction = useSelector((state: any) => state.salesRep.selectedAuction);
    const auctionId = selectedAuction.value
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const initialPaymentData = {
        auctionId: auctionId,
        salesRepId: salesRepId,
        salesRepPaymentId: 0,
        date: "",
        amountPaid: "",
        amountRecd: "",
        payType: "",
        check: "",
        paymentUniqueId: "",
        isActive: true,
        isDelete: false,
        isChange: false,
        isSubmit: false
    }

    const mainFormInitial = {
        salesRepPaymentId: 0,
        salesRepId: salesRepId,
        paymentTaxExempt: false,
    }

    const [payments, setPayments] = useState<any>([]);
    const [filterPayments, setFilterPayments] = useState([])
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [paymentTypeData, setPaymentTypeData] = useState<any>([]);
    const [paymentFormValue] = useState<any>(initialPaymentData);
    const [mainFormValue] = useState<any>(mainFormInitial);
    const [mode, setMode] = useState<string>('create')
    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 10;
    const pageCount = Math.ceil(payments?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setPayments(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterPayments(firstSet)
    }

    const paymentSchema: any = yup.object().shape({
        date: yup
            .date()
            .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
            })
            .required("Date is required."),
        amountPaid: yup
            .string()
            .required("Amount Paid is required.")
            .test(
                "no-blank-space",
                ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
                (value) => {
                    return value ? /^\S*$/g.test(value) : true;
                }
            ),
        check: yup.string().when('isCheck', {
            is: (value: boolean) => value === true,
            then: () => yup.number().typeError('Check must be a number.')
                .test("greater-than-zero", "Amount must be greater than 0", (value: any) => {
                    // Check if value is numeric and greater than 0
                    return parseFloat(value) > 0;
                }).required('Check is required.'),
            otherwise: () => yup.string().nullable(),
        },
        ),
        isCheck: yup.boolean(),
        payType: yup.string().required('Pay type is required'),
    })

    const {
        register: registerPayment,
        handleSubmit: handleSubmitPayment,
        reset: resetPayment,
        watch: watchPayment,
        setValue: setValuePayment,
        getValues: getValuesPayment,
        clearErrors: clearErrorsPayment,
        control,
        formState: { errors: errorsPayment, isValid: isValidPayment }
    } = useForm({ resolver: yupResolver(paymentSchema), defaultValues: paymentFormValue, mode: 'onChange' });
    const watchPayType = watchPayment('payType')
    const watchIsCheck = watchPayment('isCheck')
    const amountPaid = watchPayment('amountPaid')
    const amountRecd = watchPayment('amountRecd')
    const mainSchema: any = yup.object().shape({
        paymentTaxExempt: yup.boolean(),
    })

    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
    } = useForm({ resolver: yupResolver(mainSchema), defaultValues: mainFormValue, mode: 'onChange' });

    const onSubmitPayment = (data: any) => {
        const checkValue = data.check === "" ? null : data.check;
        const checkamountRec = data.amountRecd === "" ? null : data.amountRecd;

        const checkamountPaid = data.amountPaid === "" ? null : data.amountPaid;
        if (mode === "create") {
            const newPaymentData = {
                ...paymentFormValue,
                ...data,
                date: moment(data.date).format('YYYY-MM-DDTHH:MM'),
                check: checkValue,
                amountPaid: checkamountPaid,
                amountRecd: checkamountRec,
                payType: parseInt(data.payType),
                paymentUniqueId: uuidv4(),
                isChange: true,
            }
            const updatedData =
                paymentData?.lstsalesRepPayments?.length ?
                    [...paymentData?.lstsalesRepPayments, newPaymentData]
                    :
                    [newPaymentData]
            dispatch(setPayment(updatedData))
            resetPayment(initialPaymentData)
            setCurrentPage(0)
            handleClose()
        } else {
            const updatePayment = paymentData?.lstsalesRepPayments?.map((pay: any) => {
                if (pay.paymentUniqueId === data.paymentUniqueId) {
                    return {
                        ...data,
                        date: moment(data.date).format('YYYY-MM-DDTHH:MM'),
                        payType: parseInt(data.payType), check: checkValue, isChange: true
                    }
                }
                return pay
            })
            dispatch(setPayment(updatePayment));
            resetPayment(initialPaymentData);
            setCurrentPage(0);
            handleClose();
        }
    }

    const onSave = () => {
        let data: any = getValues();
        dispatch(loader(true))
        const payListing = paymentData?.lstsalesRepPayments ?? [];
        const payload = { ...data, taxExempt: data.paymentTaxExempt, lstsalesRepPayments: payListing.filter((payData: any) => payData.isChange === true) }
        axios.post(UPDATESALESPAYMENT, payload).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const respData = response.data.data;
                const updatedPayments = {
                    ...payload,
                    lstsalesRepPayments: respData.map((pay: any) => {
                        return {
                            ...pay,
                            isChange: false,
                            isSubmit: true,
                            paymentUniqueId: uuidv4()
                        }
                    })
                }
                dispatch(setPaymentResp(updatedPayments))
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                handleNext()
            }
            dispatch(loader(false));
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });
    }

    const handleClose = () => {
        setToggleValue(!toggleModal);
        resetPayment(paymentFormValue);
    }

    const handleEditModal: any = (paymentUniqueId: any, type: string) => {
        if (type === 'edit') {
            setMode('edit')
            setToggleValue(!toggleModal)
            const getData = paymentData?.lstsalesRepPayments.find((data: any) => data.paymentUniqueId === paymentUniqueId)
            resetPayment({ ...getData, date: moment(getData?.date).format('MM/DD/YYYY'), amountPaid: Number(getData?.amountPaid)?.toFixed(2), amountRecd: Number(getData?.amountRecd)?.toFixed(2) })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updateData = paymentData?.lstsalesRepPayments.map((data: any) => {
                    if (data.paymentUniqueId === paymentUniqueId) {
                        return { ...data, isActive: false, isDelete: true, isChange: data.isSubmit }
                    }
                    return data
                })
                dispatch(setPayment(updateData));
            }
        }, (error) => {
            toast.error(errorMessages.error401, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        })
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('logs'))
    }


    const handleRadio = (rValue: any) => {
        setValue('paymentTaxExempt', rValue)
    }

    const handlePrevious = () => {
        dispatch(setPayment(paymentData?.lstsalesRepPayments.filter((pay: any) => pay.isSubmit === true)));
        dispatch(setAuctionInfoActiveTab('adjust'))
    }

    const filterPayDataByAuction = () => {
        let data = paymentData?.lstsalesRepPayments?.filter((payment: any) => payment?.auctionId === auctionId)
        paginateData(data)
    }

    useEffect(() => {
        if (auctionId && paymentData?.lstsalesRepPayments?.length) {
            filterPayDataByAuction()
        }
    }, [auctionId, paymentData])

    useEffect(() => {
        if (watchPayType == 77) {
            setValuePayment('isCheck', true)
            return
        }
        clearErrorsPayment(['check'])
        setValuePayment('isCheck', false)
        setValuePayment('check', "")
    }, [watchPayType])

    useEffect(() => {
        if (payments.length) {
            const offset = currentPage * PER_PAGE
            const nextSet = payments?.slice(offset, offset + PER_PAGE)
            setFilterPayments(nextSet)
        }
    }, [currentPage])

    useEffect(() => {
        dispatch(loader(true));
        axios.get(`${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setPaymentTypeData(response.data.data);
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });
        if (paymentData?.salesRepId) {
            reset(paymentData)
        }
    }, [])

    return (
        <>
            <Modal show={toggleModal} size='lg' centered>
                <form>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add Payments' : 'Edit Payments'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label className="form-label">Date <sup className="text-danger">*</sup></label>
                                {/* <input
                                    className="form-control form-icon-input"
                                    type="date"
                                    placeholder="Date"
                                    {...registerPayment('date')}
                                /> */}
                                <div className="date_icon">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <DateTime
                                                onChange={onChange}
                                                value={value}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                dateFormat="MM/DD/y"
                                                inputProps={{
                                                    placeholder: "Date",
                                                    className: "form-control form-icon-input only_date_icon",
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {errorsPayment.date && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.date.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount Paid ($)<sup className="text-danger">*</sup></label>

                                <Controller
                                    name="amountPaid"
                                    control={control}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount Paid"
                                            {...registerPayment('amountPaid')}
                                            prefix="$"
                                            value={amountPaid}
                                            allowDecimals
                                            onKeyDown={handleKeyDown}
                                            onValueChange={(value: any) => setValuePayment("amountPaid", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />

                                {errorsPayment.amountPaid && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.amountPaid.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount Received ($)</label>
                                <Controller
                                    name="amountRecd"
                                    control={control}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount Received"
                                            {...registerPayment('amountRecd')}
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={amountRecd}
                                            onValueChange={(value: any) => setValuePayment("amountRecd", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />

                                {errorsPayment.amountRecd && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.amountRecd.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Pay type <sup className="text-danger">*</sup></label>
                                <select className="form-select" {...registerPayment('payType')}>
                                    <option value="">Please select</option>
                                    {paymentTypeData.map((paymentType: any) => {
                                        return (
                                            <option value={parseInt(paymentType.objectValueId)}>{paymentType.objectTypeValue}</option>
                                        )
                                    })}
                                </select>
                                {errorsPayment.payType && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.payType.message}</span>}
                            </div>
                            {watchIsCheck && <div className="col-md-12">
                                <label className="form-label">Check <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Check"
                                    {...registerPayment('check')}
                                    onKeyDown={handleKeyDown}
                                />
                                {errorsPayment.check && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.check.message}</span>}
                            </div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit" onClick={handleSubmitPayment(onSubmitPayment)}>{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <div className="container-fluid">
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-3 mb-4 border-300">
                                <div className="row">
                                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between">
                                        <h4>Payments</h4>
                                        <button className="btn btn-primary mb-2 ms-2 position-relative d-flex align-items-center" type="button" data-bs-toggle="modal" disabled={auctionId ? false : true}
                                            onClick={() => {
                                                setMode('create')
                                                setToggleValue(!toggleModal)
                                                resetPayment(initialPaymentData)
                                            }}>
                                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Payments </span>
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-5 border-300">
                                            <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                                <div className="table-responsive mx-n1 px-1">
                                                    <table className="table fs--1 mb-0 border-top border-200">
                                                        <thead>
                                                            <tr>
                                                                <th className="bg-gradiant " scope="col">Action</th>
                                                                <th className="bg-gradiant " scope="col" data-sort="firstname">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Date</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant " scope="col" data-sort="lastname">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Amount Paid ($)</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant " scope="col" data-sort="company">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Amount Recd ($)</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant " scope="col" data-sort="phoneNo">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Pay Type</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Check</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list scrollbar" id="table-latest-review-body">
                                                            {payments?.filter((i: any) => i.isActive).length > 0 ?
                                                                payments?.map((data: any, index: number) => {
                                                                    return (
                                                                        data?.isActive && <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                    <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant='none'>
                                                                                        <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.paymentUniqueId, 'edit')}>Edit  <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                        <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.paymentUniqueId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>

                                                                            <td className="align-middle customer white-space-nowrap p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.date ? moment(data?.date).format('MM/DD/YYYY').toString() : '-'}</p>
                                                                            </td>
                                                                            <td className="align-middle customer white-space-nowrap p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.amountPaid == null ? '-' : formatCurrency(data?.amountPaid)}</p>
                                                                            </td>
                                                                            <td className="align-middle customer white-space-nowrap p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.amountRecd == null ? '-' : formatCurrency(data?.amountRecd)}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.payType && paymentTypeData?.find((pData: any) => pData.objectValueId === data.payType)?.objectTypeValue}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.check == null ? '-' : data?.check}</p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                ) : (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            {filterPayments?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"←"}
                                                    nextLabel={"→"}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}
                                                    forcePage={currentPage}
                                                />)}
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <h4>Tax Exempt</h4>
                                        {taxExemptType.map((taxType: any, index: number) => {
                                            return (
                                                <div className="form-check form-check-inline mb-3">
                                                    <input
                                                        type="radio"
                                                        id={`adj_${index}`}
                                                        className="form-check-input"
                                                        onChange={() => handleRadio(taxType.value)}
                                                        defaultChecked={paymentData.paymentTaxExempt === undefined ? taxType.selected : paymentData.paymentTaxExempt === taxType.value}
                                                        name="paymentTaxExempt"
                                                    />
                                                    <label className="form-check-label" htmlFor={`adj_${index}`} >{taxType.label}</label>
                                                </div>
                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                            <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary mx-2 ms-auto" type="submit">{ID == undefined ? (CommonMessages.saveNext) : (CommonMessages.updatedNext)}</button>
                            <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>Skip</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Payment