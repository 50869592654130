import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setAuctionInvoiceSettingActive } from "../../../../store/actions/AuctionAction";
import SellerReport from "../AuctionDetail/SellerReport";
import InvoiceSetting from "./InvoiceSetting";

const Invoices = () => {
    const activeInvoiceSetting = useSelector((state: any) => state.auction.invoiceSetting)
    const [activeStep, setActiveStep] = useState(1)
    const [activeLabel, setActiveLabel] = useState("Invoice Setting")
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveStep(activeInvoiceSetting.activeStep)
    }, [activeInvoiceSetting.activeStep])

    useEffect(() => {
        setActiveLabel(activeInvoiceSetting.activeLabel)
    }, [activeInvoiceSetting.activeLabel])

    const handleChange = (key: string) => {
        if (key === 'InvoiceSetting') {
            dispatch(setAuctionInvoiceSettingActive({ activeStep: 1, activeLabel: "Invoice Setting" }))
        } else if (key === 'SellerReportEmailSetup') {
            dispatch(setAuctionInvoiceSettingActive({ activeStep: 2, activeLabel: "Seller Report Email Setting" }))
        };
    }

    return (
        <>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row top_border justify-content-center">
                    <div className="col-xxl-10 pt-4 pb-4">
                        <Tabs
                            activeKey={activeStep === 1 ? "InvoiceSetting" : "SellerReportEmailSetup"}
                            defaultActiveKey="InvoiceSetting"
                            className="nav-underline border-bottom  nav nav-tabs mb-3"
                            onSelect={(key: any) => handleChange(key)}
                        >
                            <Tab eventKey="InvoiceSetting" title="Invoice Setting"></Tab>
                            <Tab eventKey="SellerReportEmailSetup" title="Seller Report Email Setting"></Tab>
                        </Tabs>
                        {activeStep === 1 ? (
                            <InvoiceSetting />
                        ) : activeStep === 2 ? (
                            <SellerReport />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invoices