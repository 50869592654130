import axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decryptId } from "../../../../Encryption/Encryption";
import { trimObjectValues } from "../../../../Utils/Utils";
import { GETWEBTEXTBYAUCTIONID, UPDATEWEBTEXT } from "../../../../common/apiConstatnts";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionDetailActive, setAuctionInfoActive, setCloneTab, setCreateTab, setStep } from "../../../../store/actions/AuctionAction";
import { loader } from "../../../../store/actions/CommonAction";
import { WEBTEXT } from "../../AuctionConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../../../Utils/CustomCkEditor";

const WebText = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const ID = params.id ? decryptId(params.id) : "";
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const [data, setData] = useState<any>({})
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activeKey, setActiveKey] = useState<string>("title")
    const [btnTxt, setBtnTxt] = useState<any>("")

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const handleSave = () => {
        dispatch(loader(true))
        const dataOriginal = trimObjectValues(data)

        const payload = {
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            title: dataOriginal.title ? dataOriginal.title : "",
            description: dataOriginal.description ? dataOriginal.description : "",
            terms: dataOriginal.terms ? dataOriginal.terms : "",
            auctionWebTextId: dataOriginal?.auctionWebTextId ?? 0,
            companyId: localStorage.getItem('companyId'),
            isActive: true,
            isDelete: false,
        }
        axios.post(UPDATEWEBTEXT, payload, config).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(AuctionMessages.auctionInfoMessages.webTextUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, WEBTEXT]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, WEBTEXT]))
                }
                nextStep()
            }
            dispatch(loader(false));
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const nextStep = () => {
        dispatch(setStep(2));
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionSeller" }));
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActive({ activeStep: 3, activeLabel: "Automated Email" }))
    }

    useEffect(() => {
        dispatch(loader(true));
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(`${GETWEBTEXTBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                dispatch(loader(false))
                return;
            }
            else {
                dispatch(loader(false));
                let respData = response.data.data?.length > 0 ? response.data.data[0] : {}
                setTitle(respData?.title)
                setDescription(respData?.description)
                setData(respData)

            }
        }, (error) => {
            dispatch(loader(false))
            if (error?.response?.status !== 401) {
                toast.error("Some error occurred!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }, [])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(WEBTEXT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(WEBTEXT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    // Ckeditor Title change
    const onTitleChange = (value: any) => {
        const updateData = { ...data, title: value }
        setTitle(value)
        setData(updateData)
    }

    const onDescriptionChange = (value: any) => {
        const updateData = { ...data, description: value }
        setDescription(value)
        setData(updateData)
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12 mb-3">
                    <Tabs
                        defaultActiveKey={activeKey}
                        onSelect={(key: any) => {
                            setActiveKey(key)
                        }}
                        id="uncontrolled-tab-example"
                        className="mb-3 nav-underline border-bottom"
                    >
                        <Tab eventKey="title" title="Title">
                            <Tabs
                                defaultActiveKey="source"
                                className="mb-3 nav-underline border-bottom "
                            >

                                <Tab eventKey="source" title="Source">
                                    {/* Add custom ckeditor */}
                                    <CustomCkEditor
                                        data={title || ''}
                                        onTextChanged={onTitleChange} />

                                </Tab>
                                <Tab eventKey="preview" title="Preview">
                                    <div className="card box-shadow">
                                        <div className="card-body ck_editor">
                                            {(title !== undefined) ? parse(title) : ""}
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                        <Tab eventKey="description" title="Description">
                            <Tabs
                                defaultActiveKey="source"
                                className="mb-3 nav-underline border-bottom "
                            >
                                <Tab eventKey="source" title="Source">
                                    {/* Add custom ckeditor */}
                                    <CustomCkEditor
                                        data={description || ''}
                                        onTextChanged={onDescriptionChange} />
                                </Tab>
                                <Tab eventKey="preview" title="Preview">
                                    <div className="card box-shadow">
                                        <div className="card-body ck_editor">
                                            {(description !== undefined) ? parse(description) : ""}
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                    </Tabs>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                    <button
                        className="btn btn-primary ms-2"
                        type="button"
                        onClick={handleSave}
                    >
                        {btnTxt}
                    </button>
                    <button className="btn btn-secondary ms-2" type="button" onClick={nextStep}>{CommonMessages.skipButton}</button>
                </div>
            </div>
        </>
    )
}

export default WebText