import axios from 'axios';
import moment from "moment";
import { useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { GETCUSTOMERWONLOTWITHAUCTIONID, GETINVOICERECORDBYSALESRECORD } from '../../../common/apiConstatnts';
import { CommonMessages } from '../../../common/messageConstants';
import * as routeConstant from '../../../common/routeConstants';
import { formatCurrency, formatNumber, onGetInvoicePaid, onGetInvoicePaidCSS, removeHtmlTags } from '../../../Utils/Utils';

const SalesWithCosts = ({
  filterSalesCosts,
  handleDeleteModal,
  handleEditModal,
  getBidderNumber,
  setCurrentPage,
  handleSorting,
  totalRecords,
  paginationSize,
  onHandleFetchMore,
  pageNumber,
  setLoading
}: any) => {


  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [wonLots, setWonLots]: any = useState([]);
  const [singleData, setSingleData]: any = useState({});
  const navigate = useNavigate();

  const handleGetLots = (data: any) => {
    setToggleValue(true);
    setSingleData(data);
    // API call here
    setLoading(true);
    axios.get(`${GETCUSTOMERWONLOTWITHAUCTIONID}?CustomerId=${data?.customerId}&AuctionId=${data?.auctionId}&BidderNo=${data?.bidderNumber}`).then((response: any) => {
      setLoading(false);
      setWonLots(response?.data?.data)
    }).catch((error) => {
      setLoading(false);
    });
  }

  // When click on confirm button it will redirect to create invoice 
  const handleInvoiceConfirm = () => {
    let lotIds: any = [];
    wonLots?.map((item: any) => {
      lotIds.push(item?.lotId)
    })
    setToggleValue(false);
    // API call to get invoice data
    setLoading(true);
    axios.get(`${GETINVOICERECORDBYSALESRECORD}?strLot=${lotIds}&AuctionId=${singleData?.auctionId}&BidderNo=${singleData?.bidderNumber}`).then((response: any) => {
      setLoading(false);
      const data = response?.data?.data;
      if (data) {
        navigate(routeConstant.CREATEINVOICE, {
          state: {
            auction: {
              "label": `${data?.auctionCode} - ${data?.auctionTitle}`,
              "value": data?.auctionId
            }, customer: {
              "label": `${data?.customerId} - ${data?.customerName}`,
              "value": data?.customerId
            },
            bidder: {
              "label": `${data?.bidderNumber}`,
              "value": parseInt(data?.bidderNumber)
            },
            lot: {
              "label": `${data?.lotNo} - ${removeHtmlTags(data?.lotTitle)}`,
              "value": data?.lotId
            },
            lotData: data?.invoiceDetail?.map((lData: any) => {
              return {
                label: `${lData.lotNo} - ${removeHtmlTags(lData.lotTitle)}`,
                value: lData.lotId,
              };
            }),

          },
        });
      }

    }).catch((error) => {
      setLoading(false);
    });
  }

  return (
    <>
      {/* Modal to display lots  */}
      <Modal show={toggleModal} size='lg' centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            Invoice Won Lots
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setToggleValue(false)}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive mx-n1 px-1 scrollbar">
            <table className="table fs--1 mb-0 border-top border-200">
              <thead>
                <tr>
                  <th className="bg-gradiant " scope="col">
                    Display Lot No
                  </th>
                  <th
                    className="bg-gradiant white-space-nowrap"
                    scope="col"
                    data-sort="lot"
                  >
                    <div className="d-flex align-items-center ">
                      <span>Quantity</span>
                    </div>
                  </th>
                  <th
                    className="bg-gradiant white-space-nowrap"
                    scope="col"
                    data-sort="ctrl"
                  >
                    <div className="d-flex align-items-center">
                      <span>Lot Title</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody
                className="list"
                id="table-latest-review-body"
              >
                {wonLots?.length > 0 ? (
                  wonLots.map(
                    (data: any, index: any) => {
                      return (
                        <tr
                          className="hover-actions-trigger btn-reveal-trigger position-static"
                          key={index}
                        >
                          <td className="align-middle white-space-nowrap p-2">
                            {data?.displayLotNo}
                          </td>
                          <td className="align-middle white-space-nowrap p-2">
                            {data?.quantity}
                          </td>
                          <td className="align-middle white-space-nowrap p-2">
                            {data?.title
                              ? removeHtmlTags(data?.title)
                              : "-"}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                    <td className="text-center p-4" colSpan={3}>
                      No records found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            onClick={handleInvoiceConfirm}
          >
            {CommonMessages.confirmButton}
          </button>
          <button
            className="btn btn-outline-danger"
            type="button"
            data-bs-dismiss="modal"
            onClick={() => setToggleValue(false)}
          >
            {CommonMessages.cancelButton}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="">
        <div className="row top_border">
          <div className="col-12">
            <div className="mb-5 border-300">
              <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                <div className="row pb-3">
                  <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                    <div className="table-responsive mx-n1 px-1">
                      <table className="table fs--1 mb-0 border-top border-200">
                        <thead>
                          <tr>
                            <th className="bg-gradiant " scope="col">
                              Action
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="lot"
                            >
                              <div className="d-flex align-items-center ">
                                <span>Lot #</span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "lotNumber")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "lotNumber")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="ctrl"
                            >
                              <div className="d-flex align-items-center">
                                <span>Ctrl # </span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "ctrlNumber")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "ctrlNumber")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-pre p-2"
                              scope="col"
                              data-sort="firstname"
                            >
                              <div className="d-flex align-items-center">
                                <span>Auction Title</span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "auctionTitle")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "auctionTitle")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="lotTitle"
                            >
                              <div className="d-flex align-items-center">
                                <span>Lot Title </span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "lotTitle")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "lotTitle")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="firstname"
                            >
                              <div className="d-flex align-items-center">
                                <span>Lot Status </span>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="firstname"
                            >
                              <div className="d-flex align-items-center">
                                <span>Created Date </span>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="strAuctionType"
                            >
                              <div className="d-flex align-items-center">
                                <span>Auction Type </span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "strAuctionType")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting(
                                        "DESC",
                                        "strAuctionType"
                                      )
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="price"
                            >
                              <div className="d-flex align-items-center">
                                <span>Price </span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "price")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "price")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="qty"
                            >
                              <div className="d-flex align-items-center">
                                <span>Quantity </span>
                              </div>
                            </th>

                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                            >
                              <div className="d-flex align-items-center">
                                <span>Bidder # </span>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                            >
                              <div className="d-flex align-items-center">
                                <span>Invoice #</span>
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    onClick={() =>
                                      handleSorting("ASC", "invoiceNumber")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    onClick={() =>
                                      handleSorting("DESC", "invoiceNumber")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                            <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="lotTitle"
                            >
                              <div className="d-flex align-items-center">
                                <span>Invoice Paid </span>
                              </div>
                            </th>
                            {/* <th
                              className="bg-gradiant white-space-nowrap"
                              scope="col"
                              data-sort="status"
                            >
                              <div className="d-flex align-items-center">
                                <span>Status </span>
                              </div>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody
                          className="list"
                          id="table-latest-review-body"
                        >
                          {filterSalesCosts?.length > 0 ? (
                            filterSalesCosts.map(
                              (data: any, index: any) => {
                                return (
                                  <tr
                                    className="hover-actions-trigger btn-reveal-trigger position-static"
                                    key={index}
                                  >
                                    <td className="align-middle white-space-nowrap text-start p-2">
                                      <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                        <Dropdown.Toggle
                                          className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                          variant="none"
                                        >
                                          <img
                                            src="/assets/img/ellipsis-solid.svg"
                                            style={{ width: "20px" }}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className={`dropdown-menu dropdown-menu-start py-2`}
                                        >
                                          {/* <Link
                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                            to={`${routeConstant.SALESMOREINFO
                                              }/${encryptId(
                                                data?.salesRecordId
                                              )}`}
                                          >
                                            Edit
                                            </Link> */}
                                          <Dropdown.Item
                                            as="button"
                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                            onClick={() =>
                                              handleEditModal(
                                                data?.salesRecordId
                                              )
                                            }>
                                            Edit
                                            <i className="fas fa-pen dropdown-icon"></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                            onClick={() =>
                                              handleDeleteModal(
                                                data,
                                                "salesWithCosts"
                                              )
                                            }
                                          >
                                            Delete
                                            <i className="fas fa-trash dropdown-icon"></i>
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            as="button"
                                            className="dropdown-item d-flex align-items-center justify-content-between"
                                            onClick={() =>
                                              getBidderNumber(data, index)
                                            }
                                          >
                                            Change Bidder
                                            <i className="fas fa-rotate dropdown-icon"></i>
                                          </Dropdown.Item>
                                          {/* Commented for now */}
                                          {/* When here if invoice is not generated then only display "Create Invoice" */}
                                          {!data?.invoiceAttach && data?.lotStatus == 1 &&
                                            <Dropdown.Item
                                              as="button"
                                              className="dropdown-item d-flex align-items-center justify-content-between"
                                              onClick={() => handleGetLots(data)}
                                            >
                                              Create Invoice
                                              <i className="fas fa-file-lines dropdown-icon"></i>
                                            </Dropdown.Item>
                                          }
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.displayLotNo ? data?.displayLotNo : data?.lotNumber}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.ctrlNumber}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.auctionTitle
                                        ? removeHtmlTags(data?.auctionTitle)
                                        : "-"}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.lotTitle
                                        ? removeHtmlTags(data?.lotTitle)
                                        : "-"}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.lotStatus === 1 ? (
                                        <span className="badge badge-dpa fs--2 badge-dpa-success">
                                          <span className="badge-label">
                                            Sold
                                          </span>
                                        </span>
                                      ) : data?.lotStatus === 2 ? (
                                        <span className="badge badge-dpa fs--2 badge-dpa-warning">
                                          <span className="badge-label">
                                            Passed
                                          </span>
                                        </span>
                                      ) : data?.lotStatus === 3 ? (
                                        <span className="badge badge-dpa fs--2 badge-dpa-secondary">
                                          <span className="badge-label">
                                            Buy Back
                                          </span>
                                        </span>
                                      ) : data?.lotStatus === 4 ? (
                                        <span className="badge badge-dpa fs--2 badge-dpa-info">
                                          <span className="badge-label">
                                            Non Payer
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                          <span className="badge-label">
                                            Pending
                                          </span>
                                        </span>
                                      )}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {moment(data?.createdOn)
                                        .format("MM/DD/YYYY")
                                        .toString()}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.strAuctionType}
                                    </td>
                                    {/* <td className="align-middle  p-2">
                                                                            {data.surcharge}
                                                                    </td> */}
                                    <td className="align-middle white-space-nowrap p-2">
                                      {formatCurrency(data?.price)}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {formatNumber(data?.quantity)}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.bidderNumber == 0 ? '-' : data?.bidderNumber}
                                    </td>
                                    <td className="align-middle white-space-nowrap p-2">
                                      {data?.invoiceNumber
                                        ? data?.invoiceNumber
                                        : "-"}
                                    </td>
                                    <td className="p-2 s-space-pre text-center">
                                      <span
                                        className={`badge badge-dpa badge-same-space payment_status-${onGetInvoicePaidCSS(
                                          data?.invoicestatus?.toString()
                                        )
                                          .toLocaleLowerCase()
                                          .replace(" ", "")}`}
                                      >
                                        {onGetInvoicePaid(data?.invoicestatus?.toString())}
                                      </span>
                                    </td>
                                    {/* <td className="align-middle white-space-nowrap p-2">
                                      {data?.invoiceAttach ? (
                                        <img
                                          src={checkIcon}
                                          alt="checkIcon"
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </td> */}
                                    {/* <td className="align-middle white-space-nowrap p-2">
                                      {data?.statusnew === "True" ? (
                                        <span>Posted </span>
                                      ) : data?.statusnew === "False" ? (
                                        <span>Not Posted </span>
                                      ) : (
                                        <span>{data?.statusnew} </span>
                                      )}
                                    </td> */}
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                              <td className="text-center p-4" colSpan={15}>
                                No records found!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {filterSalesCosts?.length > 0 && (
                  <ReactPaginate
                    pageCount={Math.ceil(totalRecords / paginationSize)}
                    previousLabel={"←"}
                    nextLabel={"→"}
                    onPageChange={({ selected }) => onHandleFetchMore(selected)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                  // {...pageNumber === 0 && {
                  //     forcePage: 0
                  // }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesWithCosts
