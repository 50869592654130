import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const ActiveStep = (data: string) => ({
    type: action.SETSALESREPACTIVETAB,
    payload: data
})

export const loader = (data: boolean) => ({
    type: action.SALESREPLOADER,
    payload: data
})

export const setSaleRepId = (data: any) => ({
    type: action.SETSALEREPID,
    payload: data
})

export const SalesRepContact = (data: any) => ({
    type: action.SALESREPCONTACT,
    payload: data
})
export const SalesRepAuction = (data: any) => ({
    type: action.SALESREPAUCTION,
    payload: data
})

export const SalesRepComm = (data: any) => ({
    type: action.SALESREPCOMM,
    payload: data
})

export const setAdjust = (data: any): Action => ({
    type: action.SETADJUST,
    payload: data
})

export const setAdjustFromResp = (data: any): Action => ({
    type: action.SETADJUSTFROMRESP,
    payload: data
})

export const setPayment = (data: any): Action => ({
    type: action.SETPAYMENT,
    payload: data
})

export const setPaymentResp = (data: any): Action => ({
    type: action.SETPAYMENTRESP,
    payload: data
})

export const SetCommissionOptions = (data: any): Action => ({
    type: action.SETCOMMISSIONOPTIONS,
    payload: data
})

export const salesRepAuctions = (data: any) => ({
    type: action.SALESREPAUCTIONS,
    payload: data
})

export const setAuctionInfoActiveTab = (data: any) => ({
    type: action.SETAUCTIONINFOACTIVETAB_SALESREP,
    payload: data
})

export const selectAuction = (data: any): Action => ({
    type: action.SELECTAUCTION_SALESREP,
    payload: data
})

export const setSalesRepLots = (data: any): Action => ({
    type: action.SALESREPLOTS,
    payload: data
})

