import React from "react";
import { Breadcrumb } from "../../common/interface";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ data }: { data: Breadcrumb[] }) => {
    return (
        <>
            <ol className="breadcrumb mb-0">
                {data.map((breadCrumb, index) => {
                    return (
                        <li className={`breadcrumb-item`} key={index}>
                            {data.length !== index + 1 ? <Link to={breadCrumb.url} aria-current="page">{breadCrumb.label}</Link> : breadCrumb.label}
                        </li>
                    )
                })}
            </ol>
        </>
    )
}

export default BreadCrumbs