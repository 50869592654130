import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import XLSX from 'xlsx-js-style';
import * as yup from 'yup';
import { ADVANCEDFILTERTRANSPORTERS, GETALLTRANSPORTERS } from "../../common/apiConstatnts";
import { formatPhoneNumber } from "../../common/constants";
import { Breadcrumb } from '../../common/interface';
import { EMAIL, EMAIL_MESSAGE } from "../../common/regexConstants";
import * as routeConstant from '../../common/routeConstants';
import handleError from "../../Utils/ApiCommonErrorMessages";
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import { generateTransportationMessageCsv, generateTransportationMessagePdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Transportation Messages', url: routeConstant.TRANSPORT }
]

const TransportListing = () => {
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterTransporter, setFilterTransporter] = useState([]);
    const [transporter, setTransporter] = useState<[]>([]);
    const [originalTransporter, setOriginalTransporter] = useState<[]>([]);
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState<any>({})
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [modalData, setModalData] = useState<any>({})
    const [searchText, setSearchText] = useState('');
    const [resetDate, setResetDate] = useState(true)
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const PER_PAGE = 10;
    const pageCount = Math.ceil(transporter.length / PER_PAGE);

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const initialValues = {
        fromDate: '', toDate: '', name: '', email: ''
    }
    const schema: any = yup.object().shape({
        fromDate: yup.string(),
        toDate: yup.string(),
        name: yup.string().nullable()
            .test('no-leading-space', 'Leading spaces are not allowed', value => value === null || value === undefined || !value.startsWith(' '))
            .matches(/^[A-Za-z\s]*$/, 'Please enter valid name.'),
        email: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true;
            return !/^\s/.test(value);
        }),
    });

    const {
        handleSubmit,
        register,
        getValues,
        reset,
        control,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialValues, mode: 'onChange' })

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalTransporter?.filter((x: any) => x.name?.toLocaleLowerCase()?.includes(keyword) ||
            x.email?.toLocaleLowerCase()?.includes(keyword) || x.message?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...transporter];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        paginateData(sortedData);
        setCurrentPage(0);
    };

    const paginateData = (data: any) => {
        setTransporter(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterTransporter(firstSet)
    }

    const getAllTransporters = () => {
        setLoading(true)
        axios.get(GETALLTRANSPORTERS, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterTransporter([]);
                return;
            }
            else {
                const TransporterData = response?.data?.data
                setOriginalTransporter(TransporterData);
                paginateData(TransporterData)
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });

    }

    const convertToDateOnlyFormat = (dateTimeString: any) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const handleAdvanceFilter = () => {
        const data = getValues()
        setLoading(true)
        if (data.email === '' && data.fromDate === '' && data.name === '' && data.toDate === '') {
            setLoading(false)
            toast.error('Please select at least one filter', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            axios.get(ADVANCEDFILTERTRANSPORTERS, {
                ...config,
                params: {
                    fromDate: data?.fromDate ? convertToDateOnlyFormat(data?.fromDate) : '',
                    toDate: data?.toDate ? moment(data?.toDate).format('YYYY-MM-DD 23:59:00') : '',
                    name: data?.name ? data?.name : '',
                    email: data?.email ? data?.email : ''
                }
            }).then((response) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setFilterTransporter([]);
                }
                else {
                    paginateData(response.data.data)
                    reset()
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
            return
        }

    }

    const clearAdvanceFilterData = () => {
        setFilterData({})
        reset();
        getAllTransporters();
        setResetDate(false)
    }

    const truncateString = (inputString: string): string => {
        if (inputString.length > 20) {
            return inputString.slice(0, 20) + '...';
        } else {
            return inputString;
        }
    }

    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(13).text(`Transportation-Report`, 15, 20);
        doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Sr. No.', 'Name', 'Email', 'Phone No.', 'Message', 'Date']]
        const data = transporter.map((data: any) => {
            return {
                transporterId: data.transporterId || 0,
                name: data.name || '-',
                email: data.email || '-',
                phone: data?.phone ? formatPhoneNumber(data?.phone) : '',
                message: data.message || "-",
                date: data.date ? moment(data?.date).format('MM/DD/YYYY hh:mm A') : "-"
            }
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Transportation-Report.pdf`);
    }

    const generateExcelWithTitle = () => {
        let data = transporter?.map((data: any) => {
            return {
                transporterId: data?.transporterId,
                name: data?.name || '-',
                email: data?.email || '-',
                phone: data?.phone ? formatPhoneNumber(data?.phone) : '',
                message: data?.message || '-',
                date: data.date ? moment(data?.date).format('MM/DD/YYYY hh:mm A') : '',
            };
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Transportation-Report'],
            [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Transporter Id', 'Name', 'Email', 'Phone', 'Message', 'Date'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 200 },
            { wpx: 100 },
            { wpx: 250 },
            { wpx: 100 },
        ];

        const headerStyle = {
            font: { bold: true, sz: 12 },
            alignment: { horizontal: 'center', vertical: 'center' },
        };

        const headerRowIndex = 3;
        const headerCells = ['A', 'B', 'C', 'D', 'E', 'F'];

        headerCells.forEach((cell, index) => {
            ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
        });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Transportation_Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        getAllTransporters()
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = transporter?.slice(offset, offset + PER_PAGE)
        setFilterTransporter(nextSet)
    }, [currentPage])

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    // this is for reset date in filter section.
    useEffect(() => {
        if (resetDate == false) {
            setTimeout(() => { setResetDate(true) }, 50)
        }
    }, [filterData])


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            <Modal show={toggleModal} size='lg' centered>
                <Modal.Header>
                    <h5 className="modal-title" id="verticallyCenteredModalLabel">Transporter Details</h5>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setToggleValue(false); setModalData({}); }}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalBody">
                        {Object.keys(modalData).map(data => (
                            data !== 'transporterId' &&
                            <div>
                                <h5 style={{ textTransform: "capitalize" }}>{data}</h5>
                                <p>{modalData[`${data}`]}</p>
                            </div>
                        ))}

                    </div>
                </Modal.Body>
            </Modal>

            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Transportation Messages'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            {/* <CSVLink
                                data={transporter}
                                {...transporter.length == 0 && { onClick: (event: any) => event.preventDefault() }}>
                                <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                                    {...transporter.length == 0 && { disabled: true }} >
                                    <i className="fas fa-download"></i>
                                    <span>Export to excel</span>
                                </button>
                            </CSVLink> */}
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => generateTransportationMessageCsv(transporter)} disabled={transporter.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateTransportationMessagePdf(transporter)} disabled={transporter.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3">
                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter); reset() })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form onSubmit={handleSubmit(() => { handleAdvanceFilter() })} noValidate>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Name</label>
                                                        <input
                                                            className="form-control form-icon-input"
                                                            type="text"
                                                            maxLength={30}
                                                            placeholder="Enter Name"
                                                            {...register('name')}
                                                        />
                                                        {errors.name && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.name.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Email</label>
                                                        <input
                                                            className="form-control form-icon-input"
                                                            type="text"
                                                            maxLength={100}
                                                            placeholder="Enter Email"
                                                            {...register('email')}
                                                        />
                                                        {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                                                    </div>
                                                </div>
                                                {resetDate ?
                                                    <div className="col-lg-3">
                                                        <div className="mt-2 mb-2">
                                                            <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">From Date ({defaultTimezone})</label>
                                                            <Controller
                                                                name="fromDate"
                                                                control={control}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <div className="date_icon">
                                                                        <DateTime
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            timeFormat={false}
                                                                            dateFormat="MM/DD/y"
                                                                            closeOnSelect={true}
                                                                            inputProps={{
                                                                                placeholder: "MM / DD / YYYY",
                                                                                className: "form-control form-icon-input only_date_icon",

                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div> : <></>}
                                                {resetDate ?
                                                    <div className="col-lg-3">
                                                        <div className="mt-2 mb-2">
                                                            <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">To Date ({defaultTimezone})</label>
                                                            <Controller
                                                                name="toDate"
                                                                control={control}
                                                                render={({ field: { onChange, value } }) => (
                                                                    <div className="date_icon">
                                                                        <DateTime
                                                                            onChange={onChange}
                                                                            value={value}
                                                                            timeFormat={false}
                                                                            dateFormat="MM/DD/y"
                                                                            closeOnSelect={true}
                                                                            inputProps={{
                                                                                placeholder: "MM / DD / YYYY",
                                                                                className: "form-control form-icon-input only_date_icon",

                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div> : <></>}
                                            </div>
                                            <div className="d-flex align-items-center ms-auto">
                                                <div className="flex-1 text-end  ms-3">
                                                    <span className="btn btn-outline-secondary mb-1 me-3" onClick={clearAdvanceFilterData}>Clear</span>
                                                    <button className="btn btn-primary mb-1" type="submit">Apply</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-4 border-300">

                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="date">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Action</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="name">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'name')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'name')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="email">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Email</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Phone</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'phone')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'phone')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="message">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Message</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'message')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'message')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="date">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Message Date ({defaultTimezone})</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'date')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'date')}></button>
                                                        </div>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterTransporter.length > 0 ? filterTransporter.map((transporterData: any, index: any) => {
                                                const date = momentUtil.parseTimeInUtc({ dateTimeString: transporterData?.date })

                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle  p-2">
                                                            <div className="cursor-pointer" title="View details" onClick={() => { setToggleValue(true); setModalData(transporterData) }}><i className="fas fa-eye dropdown-icon"></i></div>
                                                        </td>
                                                        <td className="align-middle  p-2">
                                                            <p className="mb-0 text-900">{transporterData.name ?? "-"}</p>
                                                        </td>
                                                        <td className="align-middle  p-2">
                                                            <p className="mb-0 text-900">{transporterData.email ?? "-"}</p>
                                                        </td>
                                                        <td className="align-middle review p-2">
                                                            {formatPhoneNumber(transporterData.phone) ?? "-"}
                                                        </td>
                                                        <td className="align-middle  p-2">
                                                            <p className="mb-0 text-900">{transporterData.message ? truncateString(transporterData.message) : "-"}</p>
                                                        </td>
                                                        <td className="align-middle  p-2">
                                                            <p className="mb-0 text-900">{transporterData.date ? date.format('MM/DD/YYYY') : "-"}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={8}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterTransporter.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default TransportListing