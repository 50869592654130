import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as routeConstant from '../../../../common/routeConstants'
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { GETSALESREPCOMMSALESINAUCTION, GETSALESWITHCOST, GETSELLERCOMMSALESINAUCTION, UPDATESALESINAUCTIONS } from "../../../../common/apiConstatnts";
import { ActiveStep, SalesRepAuction, loader, setAuctionInfoActiveTab } from "../../../../store/actions/SalesRepAction";
import axios from "axios";
import { CommonMessages, SalesRepMessages } from "../../../../common/messageConstants";
import { handleKeyDown } from "../../../../Utils/Utils";
import SalesWithCost from "./SalesWithCost";
import SalesByBidder from "./SalesByBidder";
import Select from 'react-select';
import CurrencyInput from "react-currency-input-field";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const SalesInAuction = () => {

    const dispatch = useDispatch()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const salesAuctionData = useSelector((state: any) => state.salesRep.auction);
    const saleRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const auctionId = useSelector((state: any) => state.salesRep.selectedAuction.value);

    // const [initialFormValue, setFormValue] = useState<any>(initialData);
    const scaleData = useSelector((state: any) => state.salesRep.commissionOptions)
    const [loading, setLoading] = useState<any>(false);
    const [calculationData, setCalculationData]: any = useState({})
    const [salesWithCostData, setSalesWithCostData]: any = useState([])

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const initialData = {
        defaultScaleSold: String(scaleData?.sold) || '',
        defaultScalesPassed: String(scaleData?.unSold) || '',
        defaultScalesBuyback: String(scaleData?.buyBack) || '',
        defaultScalesNonPayer: String(scaleData?.nonPay) || '',
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        formState: { errors, isValid }
    } = useForm({
        // resolver: yupResolver(schema), 
        defaultValues: initialData,
        mode: 'onChange'
    })

    const defaultScaleSold = watch('defaultScaleSold')
    const defaultScalesPassed = watch('defaultScalesPassed')
    const defaultScalesNonPayer = watch('defaultScalesNonPayer')
    const defaultScalesBuyback = watch('defaultScalesBuyback')

    // const onSubmit = (data: any) => {
    //     dispatch(loader(true))
    //     const payload = {
    //         salesRepSaleAuctionId: data.salesAuctionData?.data.salesRepSaleAuctionId ?? 0,
    //         salesRepId: saleRepId,
    //         scalesSoldId: parseInt(data?.scalesSoldId),
    //         passedUnsoldId: parseInt(data?.passedUnsoldId),
    //         buyBackId: parseInt(data?.buyBackId),
    //         lotId: parseInt(data?.lotId),
    //         sold: parseInt(data?.sold),
    //         passed: parseInt(data?.passed),
    //         totalPrice: parseInt(data?.totalPrice),
    //         invAd: parseInt(data?.invAd),
    //         sellerAdj: parseInt(data?.sellerAdj),
    //         commission: parseInt(data?.commission),
    //         commissionAdjust: parseInt(data?.commissionAdjust),
    //         salesTax: parseInt(data?.salesTax),
    //         payments: parseInt(data?.payments),
    //         balanceDue: parseInt(data?.balanceDue),
    //         isActive: true,
    //         isDelete: false
    //     }
    //     axios.post(UPDATESALESINAUCTIONS, payload)
    //         .then((response) => {
    //             dispatch(loader(false));
    //             if (response?.data?.success === "Fail") {
    //                 toast.error(response.data.message, {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                     autoClose: 2000,
    //                 });
    //                 return;
    //             }
    //             else {
    //                 toast.success(SalesRepMessages.salesInAuctionUpdated, {
    //                     position: toast.POSITION.TOP_RIGHT,
    //                     autoClose: 2000,
    //                 });
    //                 dispatch(SalesRepAuction(data));
    //                 handleNext()
    //             }
    //             dispatch(loader(false));
    //         }).catch((error) => {
    //             dispatch(loader(false));
    //             handleError(error);
    //           });
    // }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('adjust'))
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('inventory'))
    }

    // const salesRepSalesAuctionCommRecalc = () => {
    //     const formData = getValues()
    //     const params = {
    //         auctionId: auctionId,
    //         salesRepId: saleRepId,
    //         salesRepSoldId: formData?.defaultScaleSold,
    //         salesRepPassedId: formData?.defaultScalesPassed,
    //         salesRepBuyBackId: formData?.defaultScalesBuyback,
    //         salesRepNonPayerId: formData?.defaultScalesNonPayer,
    //     }
    //     setLoading(true)
    //     axios.get(GETSALESREPCOMMSALESINAUCTION, { ...config, params: params }).then((response) => {
    //         if (response?.data?.success === "Fail") {
    //             setLoading(false)
    //             toast.error(response.data.message, {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //             });
    //             return;
    //         }
    //         else {
    //             setLoading(false)
    //             const newCommission: any = response?.data?.data
    //             setCalculationData({
    //                 ...calculationData,
    //                 ...newCommission
    //             })
    //             setSalesWithCostData(newCommission)

    //             // reset(updatedData)
    //             toast.success('Commission Recalculated Successfully.', {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //             });

    //         }
    //     }, (error) => {
    //         setLoading(false)
    //         if (error.response.status !== 401) {
    //             toast.error(error?.response?.data?.message, {
    //                 position: toast.POSITION.TOP_RIGHT,
    //                 autoClose: 2000,
    //             });
    //         }
    //     })
    // }

    useEffect(() => {
        if (salesAuctionData?.salesRepId) {
            reset(salesAuctionData)
        }
    }, [])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <form
            // onSubmit={handleSubmit(onSubmit)}
            >
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-3 mb-2 border-300">
                            </div>
                            <h4>Sales In Auction</h4>
                            {/*hide commission section */}
                            {/* <div className="row">
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <label className='form-label'>Sold</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className='isActive'
                                        {...register('defaultScaleSold')}
                                        name='defaultScaleSold'
                                        options={scaleData?.list}
                                        value={scaleData?.list?.find((option: any) => option?.value == defaultScaleSold)}
                                        onChange={(e: any) => setValue('defaultScaleSold', e.value)}
                                    />

                                </div>
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <label className='form-label'>Passed</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className='isActive'
                                        {...register('defaultScalesPassed')}
                                        name='defaultScalesPassed'
                                        options={scaleData?.list}
                                        value={scaleData?.list?.find((option: any) => option?.value == defaultScalesPassed)}
                                        onChange={(e: any) => setValue('defaultScalesPassed', e.value)}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <label className='form-label'>Buyback</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className='isActive'
                                        {...register('defaultScalesBuyback')}
                                        name='defaultScalesBuyback'
                                        options={scaleData?.list}
                                        value={scaleData?.list?.find((option: any) => option?.value == defaultScalesBuyback)}
                                        onChange={(e: any) => setValue('defaultScalesBuyback', e.value)}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 mb-3">
                                    <label className='form-label'>Non-Payer</label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className='isActive'
                                        {...register('defaultScalesNonPayer')}
                                        name='defaultScalesNonPayer'
                                        options={scaleData?.list}
                                        value={scaleData?.list?.find((option: any) => option?.value == defaultScalesNonPayer)}
                                        onChange={(e: any) => setValue('defaultScalesNonPayer', e.value)}
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                                        onClick={() => salesRepSalesAuctionCommRecalc()}>
                                        <span>Recalculate Commission</span>
                                    </button>
                                </div>
                            </div>
                            <hr /> */}

                            <Tabs
                                defaultActiveKey="SalesWithCost"
                                unmountOnExit
                                className="nav-underline border-bottom"
                            >
                                <Tab eventKey="SalesWithCost" title="Sales With Cost">
                                    <SalesWithCost
                                        activeTab="SalesWithCost"
                                        setValue={setValue}
                                        setCalculationData={setCalculationData}
                                        calculationData={calculationData}
                                        setLoading={setLoading}
                                        setSalesWithCostData={setSalesWithCostData}
                                        salesWithCostData={salesWithCostData}
                                    />
                                </Tab>
                                <Tab eventKey="SalesByBidder" title="Sales by Bidder">
                                    <SalesByBidder
                                        activeTab="SalesByBidder"
                                        setValue={setValue}
                                        setCalculationData={setCalculationData}
                                        calculationData={calculationData}
                                        setLoading={setLoading}
                                    />
                                </Tab>
                            </Tabs>

                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>
                                {/* <button className="btn btn-primary mx-2 ms-auto" type="submit"
                                >{(ID == undefined ? (CommonMessages.saveNext) : (CommonMessages.updatedNext))}</button> */}
                                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.nextButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SalesInAuction;