import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { GETCOMMONVALUE  } from "../../common/apiConstatnts";
import { loader } from "../../store/actions/SalesRepAction";
import { CommonMessages } from "../../common/messageConstants";
import DateTime from "../../Utils/DateTime";
import { ERROR_MESSAGES, handleKeyDown } from "../../Utils/Utils";
import { setInvoicePayment } from "../../store/actions/InvoiceAction";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../Utils/ApiCommonErrorMessages";

const PaymentModal = ({ togglePayModalNew, closeModalPay, invoiceMasterID, modes, paymentEditData, invoiceCustomerID, selectedCustomer, setNetPayment }: any) => {

  const dispatch = useDispatch();
  const paymentData = useSelector((state: any) => state?.inovice?.payment);

  const initialPaymentData = {
    invoiceMasterId: invoiceMasterID ?? 0,
    invoicePaymentId: 0,
    date: new Date(),
    amountPaid: "",
    convenienceCharge: "",
    payType: "",
    check1: "",
    paymentUniqueId: "",
    isActive: true,
    isDelete: false,
    isChange: false,
    isSubmit: false,
    index: 0,
    cardNumber: "",
    expiryMonth: "",
    expiryyear: "",
    name: "",
    saveCard: false,
    cvv: "",
  };
  const [paymentTypeData, setPaymentTypeData] = useState<any>([]);
  const [paymentFormValue] = useState<any>(initialPaymentData);
  const [cardNumber1, setCardNumber1] = useState({});
  const paymentSchema: any = yup.object().shape({
    date: yup
      .date()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .required("Date is required."),
    amountPaid: yup
      .string()
      .required("Amount Paid is required.")
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? /^\S*$/g.test(value) : true;
        }
      ),
    check1: yup.string()
      .when('isCheck', {
        is: (value: boolean) => value === true,
        then: () => yup.number().typeError('Check must be a number.')
          .test("greater-than-zero", "Amount must be greater than 0", (value: any) => {
            // Check if value is numeric and greater than 0
            return parseFloat(value) > 0;
          }).required('Check is required.'), otherwise: () => yup.string().nullable(),
      }),
    isCheck: yup.boolean(),
    payType: yup.string().required("Pay type is required"),
  });

  const {
    register: registerPayment,
    handleSubmit: handleSubmitPayment,
    reset: resetPayment,
    watch: watchPayment,
    setValue: setValuePayment,
    getValues: getValuesPayment,
    clearErrors: clearErrorsPayment,
    control,
    formState: { errors: errorsPayment, isValid: isValidPayment }
  } = useForm({ resolver: yupResolver(paymentSchema), defaultValues: paymentFormValue, mode: 'onChange' });

  const watchPayType = watchPayment('payType');
  const watchIsCheck = watchPayment('isCheck');
  const amountPaid = watchPayment("amountPaid");
  const convenienceCharge = watchPayment("convenienceCharge");

  const mainSchema: any = yup.object().shape({
    paymentTaxExempt: yup.boolean(),
  });

  const {
    reset,
  } = useForm({ resolver: yupResolver(mainSchema), mode: 'onChange' });

  const onSubmitPayment = () => {
    let data = getValuesPayment();
    const checkValue = data.check === "" ? null : data.check1
    if (modes === "create") {
      const newPaymentData = {
        ...paymentFormValue,
        ...data,
        index: paymentData?.length == 0 ? 0 : paymentData?.length + 1,
        date: moment(data.date).format("YYYY-MM-DDTHH:mm"), // Correct 'MM' to 'mm' for minutes
        check1: checkValue,
        payType: parseInt(data.payType),
        paymentUniqueId: uuidv4(),
        isChange: true,
        cardNumber1: cardNumber1,
      };
      const updatedData = paymentData?.length ? [...paymentData, newPaymentData] : [newPaymentData];
      dispatch(setInvoicePayment(updatedData));
      toast.success("Payment added successfully !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      closeModalPay();
      resetPayment(initialPaymentData);
    } else {
      const updatedData = {
        ...data,
        date: moment(data.date).format('YYYY-MM-DDTHH:MM'),
        payType: parseInt(data.payType),
        check1: checkValue,
        isChange: true,
        cardNumber1: cardNumber1,
      }
      const updatePayment = paymentData.map((payment:any) => {
        if (payment.paymentUniqueId === data.paymentUniqueId) {
            return updatedData; // Replace the matching payment with updatedData
        }
        return payment; // Otherwise, return the payment unchanged
    });
      // Update net payment while update 
      const totalPaymentAmounts = updatePayment?.filter((item: any) => item.isDelete == false).reduce(
        (total: any, payment: any) => {
          const amount = parseFloat(payment?.amountPaid || 0);
          return total + amount;
        },
        0
      );
      setNetPayment(totalPaymentAmounts);
      dispatch(setInvoicePayment(updatePayment));
      toast.success("Payment updated successfully !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      resetPayment(initialPaymentData);
      closeModalPay();
    }
  }

  useEffect(() => {
    if (watchPayType == 77) {
      setValuePayment('isCheck', true)
      return
    } else {
      setValuePayment('isCheck', false)
    }
    if (watchPayType == 75) {
      setValuePayment('isCredit', true);
      return
    } else {
      setValuePayment("isCredit", false);
    }
    setValuePayment('isCredit', false);
    clearErrorsPayment(['check1', 'expiryyear', 'expiryMonth', 'cvv', 'name', 'cardNumber'])
    setValuePayment('isCheck', false);
    setValuePayment('check1', "");

  }, [watchPayType])

  useEffect(() => {
    dispatch(loader(true));
    axios.get(`${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem('companyId')}`).then((response) => {
      dispatch(loader(false));
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        setPaymentTypeData(response?.data?.data);
      }
    }) .catch((error) => {
      dispatch(loader(false));
      handleError(error)
    });
    if (paymentData?.invoicePaymentId) {
      reset(paymentData)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(paymentEditData)?.length) {
      resetPayment({ ...paymentEditData, amountPaid: Number(paymentEditData?.amountPaid || 0)?.toFixed(2), convenienceCharge: Number(paymentEditData?.convenienceCharge || 0)?.toFixed(2)});
    }
  }, [paymentEditData]);


  return (
    <>
      <Modal show={togglePayModalNew} size="lg" centered>
        <form>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              {modes === "create" ? "Add Payments" : "Edit Payments"}
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalPay}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <label className="form-label">
                  Payment Date<sup className="text-danger">*</sup>
                </label>
                <div className="date_icon">
                  <Controller
                    {...registerPayment("date")}
                    name="date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateTime
                        closeOnSelect={true}
                        onChange={onChange}
                        value={value}
                        timeFormat={false}
                        dateFormat="MM/DD/y"
                        inputProps={{
                          placeholder: "Date (MM/DD/YYYY)",
                          className:
                            "form-control form-icon-input only_date_icon",
                        }}
                      />
                    )}
                  />
                </div>
                {errorsPayment.date && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsPayment.date.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Pay type <sup className="text-danger">*</sup>
                </label>
                <select
                  className="form-select"
                  {...registerPayment("payType")}
                >
                  <option value="">Please select</option>
                  {paymentTypeData.map((paymentType: any) => {
                    return (
                      <option
                        value={parseInt(paymentType.objectValueId)}
                        selected={
                          paymentEditData?.payType ==
                          paymentType?.objectValueId
                        }
                      >
                        {paymentType.objectTypeValue}
                      </option>
                    );
                  })}
                </select>
                {errorsPayment.payType && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsPayment.payType.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Amount Paid ($)<sup className="text-danger">*</sup>
                </label>
                <CurrencyInput
                  type="text"
                  placeholder="Amount Paid ($)"
                  {...registerPayment("amountPaid")}
                  prefix="$"
                  value={amountPaid}
                  allowDecimals={true}
                  onKeyDown={handleKeyDown}
                  onValueChange={(value: any) => {
                    setValuePayment("amountPaid", value);
                  }}
                  className="form-control form-icon-input"
                />
                {errorsPayment.amountPaid && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsPayment.amountPaid.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Convenience charge($)</label>
                <CurrencyInput
                  type="text"
                  placeholder="Convenience charge ($)"
                  {...registerPayment("convenienceCharge")}
                  prefix="$"
                  value={convenienceCharge}
                  allowDecimals={true}
                  onKeyDown={handleKeyDown}
                  onValueChange={(value: any) => {
                    setValuePayment("convenienceCharge", value);
                  }}
                  className="form-control form-icon-input"
                />
              </div>
              {watchIsCheck && (
                <div className="col-md-12">
                  <label className="form-label">
                    Check # <sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control form-icon-input"
                    type="text"
                    placeholder="Check"
                    {...registerPayment("check1")}
                    onKeyDown={handleKeyDown}
                  />
                  {errorsPayment.check1 && (
                    <span
                      className="text-danger"
                      style={{ fontSize: "12px" }}
                    >
                      {errorsPayment.check1.message}
                    </span>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={closeModalPay}
            >
              {CommonMessages.cancelButton}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleSubmitPayment(onSubmitPayment)}
            >
              {modes != "create"
                ? CommonMessages.updateButton
                : CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default PaymentModal;