import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from '../Sidebar/Sidebar';
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { CREATELOTTEMPLATE, GETALLCATEGORIES, GETLOTTEMPLATEID, UPDATELOTTEMPLATE } from '../../common/apiConstatnts';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FormBuilder } from "@formio/react";
import { components } from "react-select";
import { formOptions } from "../../common/constants";
import { decryptId } from "../../Encryption/Encryption";
import { wordCounter } from "../../common/constants";
import { CommonMessages, LotTempMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Templates', url: routeConsant.LOTTEMPLATES },
    { label: 'Clone Lot Templates', url: '' }
]

const CloneLotTemplate = () => {
    const initialData = {
        templateName: "",
        description: "",
        category: []
    }
    const navigate = useNavigate()
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [loading, setLoading] = useState(false)
    const [allCat, setAllCat] = useState<any>([])
    const [cat, setCat] = useState<any>([])
    const [checked, setChecked] = useState<any>([]);
    const [lotTempId, setLotTempId] = useState<any>([]);
    const params = useParams();
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const maxWordCount = 250
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const schema: any = yup.object().shape({
        templateName: yup.string().required("Template Name is required!")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
        ,
        description: yup.string().required("Template Description is required!")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
        ,
        category: yup.array().min(1, "Select atleast one category").required("Select atleast one category")
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        clearErrors,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' });

    const handleCheck = (event: any) => {
        let catId = Number(event.target.value)
        let d = checked.find((item: any) => item.categoryId === catId);
        if (d) {
            const index = checked.findIndex((c: any) => c.categoryId === catId);
            checked[index].isActive = false
            checked[index].isDelete = true
            setCat(cat.filter((e: any) => (e.categoryId !== Number(catId))))
        } else {
            setCat([...cat, { "lotTemplateCategoryId": 0, "lotTemplateId": 0, "categoryId": Number(catId), "isActive": true, "isDelete": false }])
            setChecked([...checked, { "lotTemplateCategoryId": 0, "lotTemplateId": 0, "categoryId": Number(catId), "isActive": true, "isDelete": false }])
        }
        clearErrors("category")
    };

    const getLotTemplatebyId = (id: any) => axios.get(`${GETLOTTEMPLATEID}/?lotTemplateId=${id}`, config).then((response) => {
        setLoading(true);
        if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return;
        }
        else {
            const res = response.data.data
            setValue('templateName', res.templateName)
            setValue('description', res.description)
            setLotTempId(res.lotTemplateId)
            var categories = res.lstLotTemplateCategory
            axios.get(GETALLCATEGORIES, config).then((response) => {
                var data = response.data.data
                setAllCat(data)
            })
            const preForm = response.data.data.lstLotTemplateFields
            if (preForm.length > 0) {
                const f = preForm.map((field: any, index: any) => {
                    const values = field.lstLotTemplateFieldValue.map((op: any, index: any) => {
                        return { "value": op.fieldValue, "label": op.fieldValue, "lotTemplateFieldValueId": op.lotTemplateFieldValueId, "lotTemplateFieldId": op.lotTemplateFieldId }
                    })
                    return { "type": field.fieldType, "key": field.fieldLabel, "label": field.fieldLabel, values, "lotTemplateFieldId": field.lotTemplateFieldId, "lotTemplateId": field.lotTemplateId }
                })
                setFormData2({ "components": f })
            }

            setCat(categories)
            setChecked(categories)
        }
        setLoading(false)
    }).catch((error) => {
        setLoading(false);
        handleError(error)
      });

    useEffect(() => {
        setLoading(true);
        const decryptUser = decryptId(params.id);
        getLotTemplatebyId(decryptUser);
    }, [params.id]);


    const [formData2, setFormData2] = useState({
        components: [

        ]
    })

    const checkForChange = (e: any) => {
        if (e.target.firstElementChild) {
            var classNameForActive = e.target.firstElementChild.classList
            if (e.target.firstElementChild.classList[classNameForActive.length - 1] == 'active') {
                e.target.firstElementChild.classList.remove('active');
            } else {
                e.target.firstElementChild.classList.add('active');
            }
        }
    }

    const onsubmit = (e: any) => {
        const com = localStorage.getItem('companyId')
        const formValuesData = getValues();
        var subDataCat = [{}]
        var subDataField = [{}]
        checked.map((c: any, index: any) => {
            subDataCat.push({ "lotTemplateCategoryId": 0, "lotTemplateId": 0, "categoryId": c.categoryId, "isActive": c.isActive, "isDelete": c.isDelete })
        })
        formData2.components.map((data: any, index: any) => {
            if (data.values) {
                var lstlotTemplateFieldValueRequests = data.values.map((d: any, index: any) => {
                    return ({ "lotTemplateFieldValueId": 0, "lotTemplateFieldId": 0, "fieldValue": d.value, "sortOrder": index })
                })
                subDataField.push({ "lotTemplateFieldId": 0, "lotTemplateId": 0, "fieldType": data.type, "fieldLabel": data.label, "isRequired": false, "description": data.description, "isActive": true, "sortOrder": index, lstlotTemplateFieldValueRequests });
            } else {
                let lstlotTemplateFieldValueRequests = { "lotTemplateFieldValueId": 0, "lotTemplateFieldId": 0, "fieldValue": "", "sortOrder": 0 }
                subDataField.push({ "lotTemplateFieldId": 0, "lotTemplateId": 0, "fieldType": data.type, "fieldLabel": data.label, "isRequired": false, "description": data.description, "isActive": true, "sortOrder": index, "lstlotTemplateFieldValueRequests": [lstlotTemplateFieldValueRequests] });
            }
        })
        let payload = []
        var catData = subDataCat.filter(value => Object.keys(value).length !== 0);
        var fieldData = subDataField.filter(value => Object.keys(value).length !== 0);
        payload.push({ "lotTemplateId": 0, "templateName": formValuesData.templateName, "description": formValuesData.description, "companyId": com, "isActive": true, "isDelete": false, "lstlotTemplateCategoryRequests": catData, "lstlotTemplateFieldsRequests": fieldData })
        setLoading(true);
        axios.post(CREATELOTTEMPLATE, payload[0], config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
              }
              else{
                  toast.success(LotTempMessages.lotTempCloned, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                  });
              }
            navigate(routeConsant.LOTTEMPLATES)
        }) .catch((error) => {
            setLoading(false);
            handleError(error)
          });
    }


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row border-top justify-content-center pt-4">
                        <form onSubmit={handleSubmit(onsubmit)} noValidate >
                            <div className="row">
                                <div className="col-lg-12 mb-3">
                                    <h4>Template Details</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-10 mb-4">
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-check-label">Template Name:<sup className="text-danger">*</sup></label>
                                        <input type="text" {...register('templateName')} className="form-control" placeholder="Template Name" />
                                        {errors?.templateName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.templateName.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-check-label">Description<sup className="text-danger">*</sup></label>
                                        <textarea
                                            maxLength={maxWordCount}
                                            rows={4}
                                            className="form-control"
                                            placeholder=""
                                            {...register('description', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                        />
                                        {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                            {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                        </div>}
                                        {errors?.description && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.description.message}</span>}
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <input type="checkbox" className="form-check-input mt-1 me-1" name="isActive"/>
                                        <label className="form-check-label">Is Active</label>
                                    </div> */}
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <label className="form-check-label">Category<sup className="text-danger">*</sup></label>
                                    <div className="custom_box p-2 scrollbar" style={{ maxHeight: "170px", minHeight: "170px" }}>
                                        {allCat.length > 0 ? allCat.map((c: any, index: any) => {
                                            if (!c.isActive == false) {
                                                var result = cat.find((item: any) => item.categoryId === c.categoryId);
                                                if (result) {
                                                    return (
                                                        <div className="form-check form-check-block mt-0 pt-0 pb-1 mb-0">

                                                            <label className="form-check-label fw-400"><input className="form-check-input" type="checkbox" {...register('category')} name="category" checked={true} value={c.categoryId} onChange={(e) => handleCheck(e)} /> {c.categoryTitle}</label>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="form-check form-check-block mt-0 pt-0 pb-1 mb-0">
                                                            <input className="form-check-input" type="checkbox" {...register('category')} name="category" value={c.categoryId} onChange={(e) => handleCheck(e)} />
                                                            <label className="form-check-label fw-400">{c.categoryTitle}</label>
                                                        </div>
                                                    )
                                                }
                                            }
                                        }) :
                                            <div className="form-check form-check-block mt-0 pt-0 pb-1 mb-0">
                                                <label className="form-check-label fw-400">No Category Found</label>
                                            </div>
                                        }
                                    </div>
                                    {errors?.category && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.category.message}</span>}
                                </div>
                                <hr />
                                <h4 className="mb-3">Field Details</h4>
                            </div>
                            <div className="row" onClick={(e: any) => checkForChange(e)} style={{ height: '60vh', overflow: 'auto' }}>
                                <FormBuilder form={formData2} options={formOptions} />
                            </div>
                            <div className="border-top p-3 d-flex flex-wrap justify-content-end bg-white fix-bottom" data-wizard-footer="data-wizard-footer">
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="d-flex pager wizard list-inline mb-0">
                                        <Link to={routeConsant.LOTTEMPLATES} className="btn btn-outline-danger" type="button" data-wizard-prev-btn="data-wizard-prev-btn">{CommonMessages.cancelButton}</Link>
                                    </div>
                                    <div className="flex-1 text-end  ms-3">
                                        <button className="btn btn-primary" type="submit">{CommonMessages.saveButton}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CloneLotTemplate