import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  AUCTIONADVANCESEARCH,
  DELETEAUCTION,
  GETALLAUCTIONLIST,
  GETAUCTIONINFO,
  GETAUCTIONSTATUS,
  GETCOMMONVALUE
} from "../../common/apiConstatnts";
import { activationStatus } from "../../common/constants";
import CommonHelmet from "../../common/HelmetWrapper";
import Helper from "../../common/helpers";
import { Breadcrumb } from "../../common/interface";
import {
  AuctionMessages,
  CommonMessages
} from "../../common/messageConstants";
import * as routeConstant from "../../common/routeConstants";
import * as actions from "../../common/types";
import { encryptId } from "../../Encryption/Encryption";
import handleError from "../../Utils/ApiCommonErrorMessages";
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import {
  trimObjectValues,
  useDebounce
} from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConstant.DASHBOARD },
  { label: "Auctions", url: routeConstant.AUCTION },
];

interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const AuctionListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPermission: any = Helper.decodePermissions()?.find(
    (data: any) => data.moduleName === "Auction"
  );
  const [permission] = useState<any>(getPermission);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterAuction, setFilterAuction] = useState<any>([]);
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);
  const [state, setState] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [country, setCountry] = useState<any>([]);
  const [selectedCountry, setselectedCountry] = useState("");
  const [selectedState, setselectedState] = useState("");
  const [resetDate, setResetDate] = useState(true);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<PageData>({});
  const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [auctionList, setAuctionList] = useState<any>([]);

  // Clone modal toggle
  const [toggleClone, setToggleClone] = useState(false);

  //default Date Range data
  const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);
  const searchData = useSelector((state: any) => state.dashboard.searchData);
  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);
  const [filterData, setFilterData] = useState<any>({
    auctionId: searchData?.id ? searchData?.id : hammerAuctionId ? hammerAuctionId : "",
    startDateTime: searchData?.date
      ? searchData?.date
      : dateRangeData.default
        ? ""
        : dateRangeData.fromDate,
    endDateTime: searchData?.date
      ? searchData?.date
      : dateRangeData.default
        ? ""
        : dateRangeData.toDate,
    auctionDate: searchData?.date
      ? searchData?.date
      : dateRangeData.default
        ? ""
        : dateRangeData.auctionDate,
    city: "",
    state: "",
    country: "",
    status: "",
    auctionType: "",
    keyword: "",
    auctionTitle: searchData?.name ? searchData?.name : ''
  });
  const [defaultDate, setDefaultDate] = useState({
    startDateTime: "",
    endDateTime: "",
  });

  const getAllFilteredAuctions = () => {
    setLoading(true);
    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      setLoading(false);
      let auctionData = [];
      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
          };
        });

      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    }).catch((error) => {
      setLoading(false);
      handleError(error)
    });
  };

  useEffect(() => {
    getAllFilteredAuctions();
  }, [])

  useEffect(() => {
    if (hammerAuctionId) {
      setAlreadyFetchedDataPageNumber({})
      const resetFilter = {
        startDateTime: "",
        endDateTime: "",
        auctionId: hammerAuctionId,
        city: "",
        state: "",
        country: "",
        status: "",
        auctionType: "",
        isActive: -1,
        auctionDate: "",
        keyword: "",
      }
      setFilterData(resetFilter)
      clearSearchBar()
      currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)
      setShowFilter(true);
    } else {
      if (searchData?.id || searchData?.name || searchData?.date) {
        setShowFilter(false);
        dispatch({ type: actions.RESETSEARCHDATA });
        return;
      }
      else {
        clearAdvanceFilterData();
        clearSearchBar()
        setShowFilter(false);
      }
    }
  }, [hammerAuctionId]);

  const [error, setError] = useState({
    auctionDate: "",
  });

  const [status, setStatus] = useState<any>();
  const momentUtil = MomentUtil.getInstance();
  const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();

  const auctionStatus = [
    { value: "", label: "Select" },
    { value: "Inconfiguration", label: "In Configuration" },
    { value: "Active", label: "Active" },
    { value: "Sold", label: "Sold" },
  ];

  const auctionType = [
    { value: "", label: "Select" },
    { value: "LandAuction", label: "Land Auction" },
    { value: "EquipmentAuction", label: "Equipment Auction" },
    { value: "BusinessAuction", label: "Business Auction" },
  ];

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleSorting = (type: string, fieldName: string) => {
    let sortedAuction = [...filterAuction];
    if (type === "ASC") {
      if (typeof sortedAuction[0][fieldName] === "string") {
        sortedAuction.sort((a: any, b: any) =>
          a[fieldName].localeCompare(b[fieldName])
        );
      } else if (typeof sortedAuction[0][fieldName] === "number") {
        sortedAuction.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
      }
    } else {
      if (typeof sortedAuction[0][fieldName] === "string") {
        sortedAuction.sort((a: any, b: any) =>
          b[fieldName].localeCompare(a[fieldName])
        );
      } else if (typeof sortedAuction[0][fieldName] === "number") {
        sortedAuction.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
      }
    }

    paginateData(sortedAuction, currentPage);
  };

  let filterActivationStatus = activationStatus?.map((data: any) => {
    return {
      label: data.label,
      value: data.value,
    };
  });
  let filterAuctionType = auctionType?.map((aData: any, index: any) => {
    return {
      label: aData.label,
      value: aData.value,
    };
  });
  let filterCountry = country?.map((cData: any, index: any) => {
    return {
      label: cData.objectTypeValue,
      value: cData.objectValueId,
    };
  });
  let filterState = state?.map((sData: any, index: any) => {
    return {
      label: sData.objectTypeValue,
      value: sData.objectValueId,
    };
  });
  let filterCity = city?.map((cData: any, index: any) => {
    return {
      label: cData.objectTypeValue,
      value: cData.objectValueId,
    };
  });
  let filterStatus = auctionStatus?.map((sData: any, index: any) => {
    return {
      label: sData.label,
      value: sData.value,
    };
  });

  const getAllAuctions = (pageSize = 10, pageNum = 1) => {
    setLoading(true);
    if (hammerAuctionId && hammerAuctionId?.length !== 0) {
      setShowFilter(true);
      handleAdvanceFilter(filterData);
    } else {
      axios.get(`${GETAUCTIONINFO}?pageNumber=${pageNum}&pageSize=${paginationSize}`).then((response) => {
        setLoading(false);
        if (response?.data?.data?.success === "Fail") {
          setFilterAuction([]);
          return;
        } else {
          const auctionData = response?.data?.data?.data;
          setTotalRecords(response?.data?.data?.totalRecords);
          paginateData(auctionData, pageNum);
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
    }
  };

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const changeHandler = (fieldName: string, value: any) => {
    let formattedValue = value;
    let data = filterData;
    if (fieldName == "country") {
      setselectedCountry(value.value);
      setselectedState("");
      setCity([]);
      data = { ...data, state: "", city: "" };
    }
    if (fieldName === "state") {
      setselectedState(value.value);
      data = { ...data, city: "" };
    }
    if (fieldName == "startDateTime" || fieldName == "endDateTime" || fieldName == "auctionDate") {
      formattedValue = value ? convertToDateOnlyFormat(value) : '';
    }

    // if (fieldName == "auctionCode") {
    //     formattedValue = Number(value?.slice(0, 8)) == 0 ? null : Number(value?.slice(0, 8));
    // }
    const updateData = { ...data, [fieldName]: formattedValue };
    setFilterData(updateData);

    if (fieldName === "auctionDate") {
      if (!isValidDate(formattedValue)) {
        setError({
          ...error,
          [fieldName]:
            "Invalid date format. Please enter a valid date (MM-DD-YYYY).",
        });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }
  };

  const isValidDate = (date: string) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
    if (!datePattern.test(date)) return false;

    const [year, month, day] = date.split("-").map(Number);
    const parsedDate = new Date(year, month - 1, day);

    return (
      parsedDate.getFullYear() === year &&
      parsedDate.getMonth() === month - 1 &&
      parsedDate.getDate() === day
    );
  };

  const handleAdvanceFilter = (filterData: any, pageNum?: any) => {
    setLoading(true);
    const data = trimObjectValues(filterData);
    // return
    const params: any = {
      startDateTime: filterData?.startDateTime
        ? `${filterData?.startDateTime} 00:00:00`
        : "",
      endDateTime: filterData?.endDateTime
        ? `${filterData?.endDateTime} 23:59:59`
        : "",
      auctiondate: filterData?.auctionDate,
      auctionTitle: searchData?.name ? searchData?.name : '',
      isActive: data?.isActive?.value ?? -1,
      auctionId: data?.auctionId ?? "",
      city: data?.city?.value ? parseInt(data?.city.value) : "",
      state: data?.state?.value ? parseInt(data?.state.value) : "",
      country: data?.country?.value ? parseInt(data?.country.value) : "",
      status: data?.status?.value ?? "",
      auctionType: data?.auctionType?.value ? data?.auctionType.value : "",
      keyword: data?.keyword ?? "",
      pageNumber: pageNum
        ? pageNum
        : currentPage == 0
          ? currentPage + 1
          : currentPage,
      pageSize: paginationSize
    };
    axios.get(AUCTIONADVANCESEARCH, { ...config, params: params }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        /* toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }); */
        setFilterAuction([]);
        return;
      } else {
        paginateData(response?.data?.data?.data, pageNum ? pageNum : currentPage);
        setTotalRecords(response?.data?.data?.totalRecords);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
    return;
  };

  const handleEditModal = (auctionId: string, type: string) => {
    if (!permission?.isAllowEdit) {
      Helper.unAuthorized();
      return;
    }
    navigate(routeConstant.EDITAUCTION + "/" + auctionId, {
      state: { type: "edit" },
    });
  };

  const handleClone = (auctionId: string) => {
    if (!permission?.isAllowAdd) {
      Helper.unAuthorized();
      return;
    }
    // setToggleClone(true);
    navigate(routeConstant.CLONEAUCTION + "/" + auctionId, {
      state: { type: "clone" },
    });
  };

  const handleDeleteModal = (auctionId: string, type: string) => {
    if (!permission?.isAllowDelete) {
      Helper.unAuthorized();
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Auction!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.post(`${DELETEAUCTION}?auctionId=${auctionId}`).then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              // getAllAuctions();
              clearAdvanceFilterData(false, filterData);
              setSearchText("");
              toast.success(AuctionMessages.indexPageMessages.deleteAuction, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
          }
        ).catch((error) => {
          setLoading(false);
          handleError(error)
        });
      }
    });
  };

  const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
    setAlreadyFetchedDataPageNumber({});
    currentPage !== 1 && setCurrentPage(1);
    //this if called when click on clear filter button, also when function called without params.
    if (data == null) {
      setIsFiltered(false);
      const resetFilter = {
        startDateTime: "",
        endDateTime: "",
        auctionId: isLocalClear ? "" : hammerAuctionId,
        city: "",
        state: "",
        country: "",
        status: "",
        auctionType: "",
        isActive: -1,
        auctionDate: "",
        keyword: "",
      }
      handleAdvanceFilter(resetFilter, 1);
      clearSearchBar()
      setFilterData(resetFilter);
    }
    // this else if called when make search bar text empty
    else if (!isLocalClear) {
      handleAdvanceFilter({ ...data, }, 1);
      !isLocalClear && setSearchText("")
      setFilterData({ ...data, });
    }
    setError({ auctionDate: "" });
    setResetDate(false);
    setDefaultDate({ startDateTime: "", endDateTime: "" });
  };

  const clearSearchBar = () => {
    const searchbar: any = document.getElementById('searchBar')
    searchbar.value = ''
  }

  const dateRangehandler = () => {
    setDefaultDate({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
    });
    handleAdvanceFilter({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
      isActive: -1,
      pageNumber: currentPage == 0 ? currentPage + 1 : currentPage,
    });
  };

  const searchHandler = () => {
    handleAdvanceFilter({
      auctionId: searchData?.id,
      auctionTitle: searchData?.name,
      startDateTime: searchData?.date,
      endDateTime: searchData?.date,
      auctiondate: searchData?.date,
      isActive: -1,
      pageNumber: currentPage == 0 ? currentPage + 1 : currentPage,
    });
    setFilterData({ ...filterData, auctionId: searchData?.id })
  };

  const handleAdd = () => {
    if (!permission?.isAllowAdd) {
      Helper.unAuthorized();
      return;
    }
    navigate(routeConstant.NEWAUCTION, { state: { type: "create" } });
  };

  useEffect(() => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=City&companyId=${localStorage.getItem(
          "companyId"
        )}`
      )
      .then(
        (response) => {
          if (response?.data?.success === "Fail") {
            // toast.error(response?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
            return;
          }
          //   else{
          //       setCity(response.data.data);
          //   }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error)
      });

    axios
      .get(
        `${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem(
          "companyId"
        )}`
      )
      .then(
        (response) => {
          if (response?.data?.success === "Fail") {
            // toast.error(response?.data?.message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 2000,
            // });
            return;
          }
          // else{
          //       setState(response.data.data);
          //   }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error)
      });

    dispatch({ type: actions.RESETAUCTION });
    // dispatch({ type: actions.RESETSEARCHDATA });
    dispatch({ type: actions.RESETDATERANGE });
  }, []);

  const handleClose = () => {
    setToggleValue(!toggleModal);
  };

  const handleAuctionStatus = (auctionId: any) => {
    setLoading(true);
    axios
      .get(`${GETAUCTIONSTATUS}?auctionId=${auctionId}`, config)
      .then((response) => {
        setLoading(false);
        const data = response.data.data;
        if (response?.data?.success === "Fail") {
          setStatus([]);
          return;
        } else {
          setStatus(data);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error)
      });
  };

  //pagination related changes
  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(newPageNumber);
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
    updateData(page, data);
    setFilterAuction(data);
  };

  const debouncedRequest = useDebounce(() => {
    handleAdvanceFilter({ ...filterData });
  });

  // this useEffect called when click on pageNumber
  useEffect(() => {
    // debugger
    //when redirected from dashboard with global search.
    if (searchData?.id || searchData?.name || searchData?.date) {
      searchHandler();
      dispatch({ type: actions.RESETSEARCHDATA });
      return;
    }
    //when global search applied
    if (filterData?.keyword?.length > 2) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuction(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      handleAdvanceFilter({ ...filterData });
      return;
    }
    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuction(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      handleAdvanceFilter(filterData);
      return;
    }
    //when redirected from dashboard with countbox click.
    else if (dateRangeData.default == false || defaultDate?.startDateTime) {
      dateRangehandler();
      return;
    }
    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuction(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      getAllAuctions(paginationSize, currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=Country&companyId=${localStorage.getItem(
          "companyId"
        )}`,
        config
      )
      .then(
        (response: any) => {
          if (response?.data?.success === "Fail") {
            return;
          } else {
            setCountry(response.data.data);
          }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error)
      });
  }, []);

  useEffect(() => {
    if (!selectedCountry) {
      setCity([]);
      setState([]);
      return;
    } else {
      axios
        .get(
          `${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem(
            "companyId"
          )}&parentId=${selectedCountry}`,
          config
        )
        .then(
          (response) => {
            if (response?.data?.success === "Fail") {
              setState([])
              setCity([])
              return;
            } else {
              const data = response.data.data;
              setState(data);
              // const checkStateExists = data.filter((states: any) => states.objectValueId === getValues('state'))
              // if (checkStateExists.length === 0) {
              //     setCity([])
              // }
            }
          }
        ).catch((error) => {
          setLoading(false);
          setCity([]);
          setState([]);
          handleError(error)
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!selectedState) {
      setCity([]);
    } else {
      axios
        .get(
          `${GETCOMMONVALUE}?filter=City&companyId=${localStorage.getItem(
            "companyId"
          )}&parentId=${selectedState}`,
          config
        )
        .then(
          (response) => {
            if (response?.data?.success === "Fail") {
              setCity([])
              return;
            } else {
              const data = response.data.data;
              setCity(data);
            }
          }
        ).catch((error) => {
          setLoading(false);
          setCity([]);
          handleError(error);
        });
    }
  }, [selectedState]);

  // this is for reset date in filter section.
  useEffect(() => {
    if (resetDate == false) {
      setTimeout(() => {
        setResetDate(true);
      }, 50);
    }
  }, [filterData]);

  return (
    <>
      {/* Modal for clone selection */}
      <Modal show={toggleClone} onHide={() => setToggleClone(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Clone Auction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <h6 className="mb-3">Select the Auction Tabs to Clone</h6>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label fw-bold" htmlFor="selectAll">Select All</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Auction Info</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Auction Details</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Checklist</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Invoice Settings</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">E-Invoices</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Values Settings</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <label className="form-label" htmlFor="selectAll">Auction Close</label>
              <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" />
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => setToggleClone(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setToggleClone(false)}>
            Clone Auction
          </Button>
        </Modal.Footer>
      </Modal>
      <CommonHelmet title={"Auction - "} name={"auction"} content={"Auction List"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Auctions">
          <div className="search-box mt-2">
            <div className="d-flex align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  id='searchBar'
                  className="form-control search-input search form-control-sm"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  // value={searchText}
                  onChange={(e: any) => {
                    // setSearchText(e.target.value);
                    if (e.target.value?.length) {
                      setFilterData({ ...filterData, keyword: e.target.value })
                      setAlreadyFetchedDataPageNumber({});
                      setCurrentPage(1);
                      debouncedRequest();
                      return;
                    } else {
                      clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                      setFilterData({ ...filterData, keyword: '' })
                    }
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
                <button
                  type="button"
                  className="p-0 btn ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            <button
              onClick={() => handleAdd()}
              className="btn btn-primary ms-2 position-relative d-flex aling-items-center"
              type="button"
              data-bs-toggle="modal"
            >
              <span className="plus me-2">
                {" "}
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3"
                ></span>{" "}
              </span>{" "}
              <span> Configure New Auction </span>
            </button>
          </div>
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter"}
            >
              <div className="card bg-transparent position-relative p-3 mt-2 mb-3">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row g-3 pt-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Select Auction
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Title"
                            className="isActive clone-inventory"
                            name="auctionId"
                            options={auctionList}
                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                            value={auctionList?.find(
                              (option: any) =>
                                option?.value == filterData?.auctionId
                            )}
                            onChange={(e) => {
                              changeHandler("auctionId", e.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Auction Type
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="auctionType"
                            options={filterAuctionType}
                            value={filterData.auctionType}
                            onChange={
                              (e) => changeHandler("auctionType", e)
                            }
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Activation Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="isActive"
                            options={filterActivationStatus}
                            value={filterData.isActive}
                            onChange={(e) => changeHandler("isActive", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="status"
                            options={filterStatus}
                            value={filterData.status}
                            onChange={(e) => changeHandler("status", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Country
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="country"
                            options={filterCountry}
                            value={filterData.country}
                            onChange={(e) => changeHandler("country", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            State
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="state"
                            options={filterState}
                            value={filterData.state}
                            onChange={(e) => changeHandler("state", e)}
                            isDisabled={!filterData.country}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            City
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            name="city"
                            options={filterCity}
                            value={filterData.city}
                            onChange={(e) => changeHandler("city", e)}
                            isDisabled={!filterData.state}
                          />
                        </div>
                        {resetDate ? (
                          <div className="col-lg-3">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              Auction Date
                            </label>
                            <div className="date_icon">
                              <DateTime
                                inputProps={{
                                  className: "form-control form-icon-input only_date_icon",
                                  placeholder: "Date (MM/DD/YYYY)",
                                  name: "auctionDate",
                                }}
                                closeOnSelect={true}
                                dateFormat="MM/DD/y"
                                timeFormat={false}
                                value={filterData.auctionDate}
                                onChange={(value) =>
                                  changeHandler("auctionDate", value)
                                }
                              />
                            </div>
                            {error.auctionDate && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.auctionDate}
                              </span>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end mt-3 ms-3">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={() => { clearAdvanceFilterData(true) }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          onClick={() => {
                            currentPage !== 1 && setCurrentPage(1);
                            setAlreadyFetchedDataPageNumber({});
                            setIsFiltered(true);
                            currentPage == 1 && handleAdvanceFilter(filterData, 1);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeader>
        <Modal show={toggleModal} size="sm" centered>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Auction Status
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-3 mb-3">
              <div className="col-12">
                <div className="table-responsive mx-n1 px-1 scrollbar">
                  <table className="table border fs--1 mb-0">
                    <tbody className="list" id="table-latest-review-body">
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <th className="fs-16 p-2">Bidder:</th>
                        <td className="fs-16 p-2">{status?.bidderCount ?? 0}</td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <th className="fs-16 p-2">Inventory:</th>
                        <td className="fs-16 p-2">{status?.inventoryCount ?? 0}</td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <th className="fs-16 p-2">Sales:</th>{" "}
                        <td className="fs-16 p-2">{status?.salesCount ?? 0}</td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <th className="fs-16 p-2">Sold:</th>{" "}
                        <td className="fs-16 p-2">{status?.soldCount ?? 0}</td>
                      </tr>
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <th className="fs-16 p-2">Invoiced:</th>{" "}
                        <td className="fs-16 p-2">{status?.invoiceCount ?? 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              {CommonMessages.cancelButton}
            </button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-3 border-300">
                <div className="table-responsive mx-n1 px-1">
                  <table className="table fs--1 mb-0 border-top border-200">
                    <thead>
                      <tr>
                        <th className="bg-gradiant " scope="col">
                          Action
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="auctionCode"
                        >
                          <div className="d-flex align-items-center">
                            <span>Auction Code</span>
                            <div className="sorting_group">
                              <button
                                className="arrow-up"
                                onClick={() =>
                                  handleSorting("ASC", "auctionCode")
                                }
                              ></button>
                              <button
                                className="arrow-down"
                                onClick={() =>
                                  handleSorting("DESC", "auctionCode")
                                }
                              ></button>
                            </div>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="lastname"
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              Auction Start Date/Time ({defaultTimezone})
                            </span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="lastname"
                        >
                          <div className="d-flex align-items-center">
                            <span>
                              Auction End Date/Time ({defaultTimezone})
                            </span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="auctionTitle"
                        >
                          <div className="d-flex align-items-center">
                            <span>Auction Title</span>
                            <div className="sorting_group">
                              <button
                                className="arrow-up"
                                onClick={() =>
                                  handleSorting("ASC", "auctionTitle")
                                }
                              ></button>
                              <button
                                className="arrow-down"
                                onClick={() =>
                                  handleSorting("DESC", "auctionTitle")
                                }
                              ></button>
                            </div>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="company"
                        >
                          <div className="d-flex align-items-center">
                            <span>City</span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="company"
                        >
                          <div className="d-flex align-items-center">
                            <span>State</span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="company"
                        >
                          <div className="d-flex align-items-center">
                            <span>Status</span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="company"
                        >
                          <div className="d-flex align-items-center">
                            <span>Activation Status</span>
                          </div>
                        </th>
                        <th
                          className="bg-gradiant "
                          scope="col"
                          data-sort="company"
                        >
                          <div className="d-flex align-items-center">
                            <span>Auction Type</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list" id="table-latest-review-body">
                      {filterAuction?.length > 0 ? (
                        filterAuction?.map((auctionData: any, index: any) => {
                          const startDateTime = momentUtil.parseTimeInUtc({
                            dateTimeString: auctionData?.startDateTime,
                          });
                          const endDateTime = momentUtil.parseTimeInUtc({
                            dateTimeString: auctionData?.endDateTime,
                          });

                          return (
                            <tr
                              className="hover-actions-trigger btn-reveal-trigger position-static"
                              key={index}
                            >
                              <td className="align-middle white-space-nowrap text-start p-2">
                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                  <Dropdown.Toggle
                                    className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                    variant="none"
                                  >
                                    <img
                                      src="/assets/img/ellipsis-solid.svg"
                                      style={{ width: "20px" }}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    className={`dropdown-menu dropdown-menu-start py-2`}
                                  >
                                    <Dropdown.Item
                                      as="button"
                                      className="dropdown-item d-flex align-items-center justify-content-between"
                                      onClick={() =>
                                        handleClone(
                                          encryptId(auctionData.auctionId)
                                        )
                                      }
                                    >
                                      Clone Auction{" "}
                                      <i className="fas fa-clone dropdown-icon"></i>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="dropdown-item d-flex align-items-center justify-content-between"
                                      onClick={() =>
                                        handleEditModal(
                                          encryptId(auctionData.auctionId),
                                          "edit"
                                        )
                                      }
                                    >
                                      Edit{" "}
                                      <i className="fas fa-pen dropdown-icon"></i>{" "}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="dropdown-item d-flex align-items-center justify-content-between"
                                      onClick={() => {
                                        setToggleValue(!toggleModal);
                                        handleAuctionStatus(
                                          auctionData.auctionId
                                        );
                                      }}
                                    >
                                      Auction Status{" "}
                                      <i className="fas fa-eye dropdown-icon"></i>{" "}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="button"
                                      className="dropdown-item d-flex align-items-center justify-content-between"
                                      onClick={() =>
                                        handleDeleteModal(
                                          auctionData.auctionId,
                                          "delete"
                                        )
                                      }
                                    >
                                      Delete{" "}
                                      <i className="fas fa-trash dropdown-icon"></i>{" "}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td className="align-middle  p-2">
                                <Link
                                  className="btn text-decoration-underline m-0 p-0 fw-normal"
                                  data-bs-dismiss="modal"
                                  target="_blank"
                                  to={`${routeConstant.EDITAUCTION}/${encryptId(
                                    auctionData?.auctionId
                                  )}`}
                                >
                                  {auctionData.auctionCode}
                                </Link>
                              </td>
                              <td className="align-middle p-2">
                                {startDateTime.format("MM/DD/YYYY hh:mm A")}
                              </td>
                              <td className="align-middle p-2">
                                {endDateTime.format("MM/DD/YYYY hh:mm A")}
                              </td>
                              <td className="align-middle review p-2">
                                {auctionData.auctionTitle}
                              </td>
                              <td className="align-middle p-2">
                                {auctionData.cityName ?? "-"}
                              </td>
                              <td className="align-middle p-2">
                                {auctionData.stateName ?? "-"}
                              </td>
                              <td className="align-middle p-2">
                                <span
                                  className={`badge badge-dpa fs--2 ${auctionData.status == 1
                                    ? "badge-dpa-warning"
                                    : auctionData.status == 2
                                      ? "badge-dpa-success"
                                      : "badge-dpa-danger"
                                    }`}
                                >
                                  <span className="badge-label">
                                    {auctionData.strAuctionStatus}
                                  </span>
                                </span>
                              </td>
                              <td className="align-middle p-2">
                                <span
                                  className={`badge badge-dpa fs--2 ${auctionData.isActive
                                    ? "badge-dpa-success"
                                    : "badge-dpa-danger"
                                    }`}
                                >
                                  <span className="badge-label">
                                    {auctionData.isActive
                                      ? "ACTIVE"
                                      : "INACTIVE"}
                                  </span>
                                </span>
                              </td>
                              <td className="align-middle p-2 white-space-pre">
                                {auctionData.strAuctionType ?? "Pending"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                          <td className="text-center p-4" colSpan={10}>
                            No records found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {filterAuction?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    // pageCount={pageCount}
                    pageCount={Math.ceil(totalRecords / paginationSize)}
                    onPageChange={({ selected }) => handlePageClick(selected)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionListing;
