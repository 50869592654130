import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BuyersPremium from './BuyersPremium';
import ComissionsCategories from './ComissionsCategories';
import { Tab, Tabs } from 'react-bootstrap';
import { setAuctionValueActive } from '../../../../store/actions/AuctionAction';
import AuctionResultsConfig from './AuctionResultsConfig';

const Values = () => {
    const getActive = useSelector((state: any) => state.auction.value);
    const [active, setActive] = useState<any>({})
    const dispatch = useDispatch()

    useEffect(() => {
        setActive(getActive);
    }, [getActive])

    const handleChange = (key: string) => {
        /* if (key === 'BuyersPremium') {
            dispatch(setAuctionValueActive({ activeStep: 1, activeLabel: "Buyers Premium" }))
        } 
        else if (key === 'ComissionsCategories') {
            dispatch(setAuctionValueActive({ activeStep: 2, activeLabel: "Commissions & Categories" }))
        } 
        else if (key === 'AuctionResultsConfig') {
            dispatch(setAuctionValueActive({ activeStep: 3, activeLabel: "Auction Results Config" }))
        }  */
        if (key === 'ComissionsCategories') {
            dispatch(setAuctionValueActive({ activeStep: 1, activeLabel: "Commissions & Categories" }))
        }
        else if (key === 'AuctionResultsConfig') {
            dispatch(setAuctionValueActive({ activeStep: 2, activeLabel: "Auction Results Config" }))
        }
    }
    return (
        <div className="container-fluid for-fix-bottom-space">
            <div className="row top_border justify-content-center">
                <div className="col-xxl-10 pt-4 pb-4">
                    <div className="col-12">
                        <h4 className="mb-2">Values - {active.activeLabel}</h4>
                    </div>
                    <Tabs
                        // activeKey={active.activeStep === 1 ? "BuyersPremium" :  active.activeStep === 2 ? "ComissionsCategories" : active.activeStep === 3 ? "AuctionResultsConfig" : "Increments"}
                        activeKey={active.activeStep === 1 ? "ComissionsCategories" : active.activeStep === 2 ? "AuctionResultsConfig" : "Increments"}
                        className="nav-underline border-bottom  nav nav-tabs mb-3"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        {/* <Tab eventKey="BuyersPremium" title="Buyers Premium"></Tab> */}
                        <Tab eventKey="ComissionsCategories" title="Comissions & Categories"></Tab>
                        <Tab eventKey="AuctionResultsConfig" title="Auction Results Config"></Tab>
                    </Tabs>
                    {/* {
                        (active.activeStep === 1) ?
                            <BuyersPremium /> :
                            (active.activeStep === 2) ? <ComissionsCategories /> :
                                (active.activeStep === 3) ? <AuctionResultsConfig />
                                    : <></>
                    } */}
                    {
                        (active.activeStep === 1) ? <ComissionsCategories /> :
                            (active.activeStep === 2) ? <AuctionResultsConfig />
                                : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default Values;