import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import Select from "react-select";
import InnerHeader from "../InnerHeader/InnerHeader";
import ReactPaginate from "react-paginate";
import { Dropdown, Modal } from "react-bootstrap";
import {
  setInvoiceAdjust,
  setInvoicePayment,
} from "../../store/actions/InvoiceAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  GETALLINVOICE,
  ADVANCESEARCHINVOICE,
  GETINVOICEBYID,
  MULTIPLESENDEINVOICE,
  DELETEINVOICE,
  VOIDINVOICE,
  GETINVOICEHISTORY,
  GETINVOICEFOOTERBYAUCTIONID,
  GETCOMMONVALUE,
  GETCLOSEDAUCTOINS,
} from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../common/types";
import PdfFormat from "./PdfFormat";
import moment from "moment";
import { decryptId, encryptId } from "../../Encryption/Encryption";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import PayIcon from "../../assets/payment_icon.svg";
import {
  CommonMessages,
  InvoiceMessages,
  errorMessages,
} from "../../common/messageConstants";
import {
  ERROR_MESSAGES,
  formatCurrency,
  generatePDF,
  onGetInvoicePaid,
  onGetInvoicePaidCSS,
  trimObjectValues,
  useDebounce,
} from "../../Utils/Utils";
import DateTime from "../../Utils/DateTime";
import { useForm } from "react-hook-form";
import { useCurrentDateTime } from "../../Utils/useDateTime";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";
import Helper from "../../common/helpers";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConstant.DASHBOARD },
  { label: "Invoices", url: "" },
];
interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const Invoice = () => {

  const getPermission: any = Helper.decodePermissions()?.find(
    (data: any) => data.moduleName === "Invoices"
  );

  const [permission] = useState<any>(getPermission);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<[]>([]);
  const [filterInvoice, setFilterInvoice] = useState([]);
  // Set payment status
  const [paymentTypeData, setPaymentTypeData] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [formValues, setFormValues] = useState<any>();
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [toggleViewModal, setToggleViewModal] = useState<boolean>(false);
  const [toggleHistoryModal, setToggleHistoryModal] = useState<boolean>(false);
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [invoicePayload, setInvoicePayload] = useState<any>([]);
  const [pdfForEmail, setPdfForEmail] = useState<any>(null);
  const [searchText, setSearchText] = useState("");
  const [footerData, setFooterData] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes]: any = useState({});
  // Invoice history
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [error, setError] = useState({
    invoiceMasterId: "",
    bidderNumber: "",
    email: "",
    customerName: "",
    companyName: "",
    auctionId: "",
  });
  const [resetDate, setResetDate] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber]: any = useState<PageData>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [auctionList, setAuctionList] = useState<any>([]);
  const momentUtil = MomentUtil.getInstance();
  const dateTime = useCurrentDateTime();
  // const currentUTC = new Date();
  const currentDate: any = momentUtil.parseTimeInUtc({
    dateTimeString: dateTime?.currentDateTime,
  });
  const currentUTCDate = moment.utc(currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);
  const searchData = useSelector((state: any) => state.dashboard.searchData);
  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);

  const invoiceStatus = [
    {
      label: "Select Invoice status",
      value: '',
    },
    {
      label: "Fully Paid",
      value: 'FullyPaid',
    },
    {
      label: "Partial Paid",
      value: 'PartialPaid',
    },
    {
      label: "Balance Due",
      value: 'Pending',
    },
  ];

  const voidStatus = [
    {
      label: "Select void status",
      value: '',
    },
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  const initialFilterData = {
    invoiceMasterId: "",
    customerId: "",
    companyName: "",
    customerName: "",
    bidderNumber: "",
    void: "",
    auctionId: searchData?.id ? searchData?.id : hammerAuctionId ? hammerAuctionId : "",
    invoiceDate: "",
    sentInvoiceDate: "",
    startDateTime: searchData?.date
      ? searchData?.date
      : dateRangeData.default
        ? ""
        : dateRangeData.fromDate,
    endDateTime: searchData?.date
      ? searchData?.date
      : dateRangeData.default
        ? ""
        : dateRangeData.toDate,
    isActive: -1,
    status: '',
    keyword: "",
    email: "",
    auctionTitle: searchData?.name ? searchData?.name : ''
  };

  const [filterData, setFilterData] = useState<any>(initialFilterData);

  const {
    register: FilterRegister,
    reset: filterReset,
    setValue: filterSetValue,
    watch: filterWatch,
  } = useForm({
    defaultValues: initialFilterData,
    mode: "onChange",
  });

  const clearSearchBar = () => {
    const searchbar: any = document.getElementById('searchBar')
    searchbar.value = ''
  }

  useEffect(() => {
    if (hammerAuctionId) {
      const resetFilter: any = {
        invoiceMasterId: "",
        customerId: "",
        companyName: "",
        customerName: "",
        bidderNumber: "",
        void: "",
        auctionId: hammerAuctionId,
        invoiceDate: "",
        sentInvoiceDate: "",
        startDateTime: "",
        endDateTime: "",
        isActive: -1,
        status: '',
        keyword: "",
        email: "",
        auctionTitle: ""
      }
      setAlreadyFetchedDataPageNumber({})
      setFilterData(resetFilter)
      clearSearchBar()
      pageNumber != 1 ? setPageNumber(1) : clearAdvanceFilterData(false, resetFilter)
      setShowFilter(true);
    } else {
      if (searchData?.id || searchData?.name || searchData?.date) {
        setShowFilter(false);
        dispatch({ type: actions.RESETSEARCHDATA });
        return;
      }
      else {
        clearAdvanceFilterData();
        clearSearchBar()
        setShowFilter(false);
      }
    }
  }, [hammerAuctionId]);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const fileConfig: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      enctype: "multipart/form-data",
    },
  };

  const getAllInvoices = (
    pageSize = 10,
    pageNumber = 1,
    searchData: any = null
  ) => {
    if (hammerAuctionId && hammerAuctionId?.length !== 0) {
      setShowFilter(true);
      handleAdvanceFilter(filterData);
      return;
    }
    setLoading(true);
    axios.get(`${GETALLINVOICE}?pageNumber=${pageNumber}&pageSize=${paginationSize}`, config).then((response) => {
      setLoading(false);
      if (response?.data?.data?.success === "Fail") {
        toast.error(response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setFilterInvoice([]);
        return;
      } else {
        const invoiceData = response?.data?.data?.data;
        paginateData(invoiceData, pageNumber);
        setTotalRecords(response.data.data.totalRecords);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };
  // Get payment types to display paytype
  useEffect(() => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem(
          "companyId"
        )}`
      )
      .then(
        (response) => {
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setPaymentTypeData(response?.data?.data);
          }
        },
        (error) => {
          if (error?.response?.status !== 401) {
            toast.error(errorMessages.error401, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }
      );
    dispatch({ type: actions.RESETDATERANGE });
    getAllAuctions()
  }, []);

  // Create a file object from blob
  const createFileFromBlob = (blob: any, invoiceNumber: any) => {
    const file = new File([blob], `Invoice_No_${invoiceNumber}.pdf`, { type: 'application/pdf' });
    return file;
  };

  const getInvoiceByIds = (id: any, isDownload: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${GETINVOICEBYID}?invoiceMasterId=${id}`, { ...config })
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              setLoading(false)
              const dataInvoice = response?.data?.data;
              setInvoiceData(dataInvoice);
              setInvoiceList(dataInvoice?.lstInvoiceDeatils);
              const resetData = {
                ...dataInvoice,
                lstInvoiceDetailsRequests: dataInvoice?.lstInvoiceDeatils,
              };
              setFormValues(resetData);
              resolve(resetData);
              setLoading(true)
              axios.get(`${GETINVOICEFOOTERBYAUCTIONID}?AuctionId=${dataInvoice?.auctionId}`, config).then((response: any) => {
                let footerData = response?.data?.data?.description;
                setFooterData(footerData)
                setLoading(false)
                if (isDownload) {
                  generatePDF(dataInvoice, footerData, currentUTCDate, paymentTypeData, "download");
                } else {
                  const pdfBlob = generatePDF(dataInvoice, footerData, currentUTCDate, paymentTypeData, "blob");
                  const pdfFile = createFileFromBlob(pdfBlob, dataInvoice.invoiceMasterId);
                  // Create the new invoice data including the generated PDF Blob
                  const newInvoiceData = {
                    ...dataInvoice,
                    pdfForEmail: pdfFile,
                  };
                  setInvoicePayload((prevPayload: any) => [...prevPayload, newInvoiceData]);
                }
              });
            }
          },
          (error) => {
            setLoading(false);
            toast.error(errorMessages.error401, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        )
        .catch((error) => {
          setLoading(false);
          handleError(error);
          reject(error);
        });
    });
  };


  const handleSorting = (type: string, fieldName: string) => {
    let companyName;
    if (type === "ASC") {
      companyName = filterInvoice?.sort((a: any, b: any) =>
        a[fieldName]?.toLocaleLowerCase() > b[fieldName]?.toLocaleLowerCase()
          ? 1
          : -1
      );
    } else {
      companyName = filterInvoice?.sort((a: any, b: any) =>
        a[fieldName]?.toLocaleLowerCase() < b[fieldName]?.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    paginateData(companyName, pageNumber);
    // setPageNumber(0);
  };

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const changeHandler = (fieldName: string, value: any) => {
    let formattedValue = value;
    if (fieldName == "invoiceDate" || fieldName == "startDateTime" || fieldName == "endDateTime" || fieldName == "sentInvoiceDate") {
      formattedValue = value ? convertToDateOnlyFormat(value) : '';
    }
    const updateData = { ...filterData, [fieldName]: formattedValue };
    setFilterData(updateData);
    if (fieldName === "email") {
      const isValidemail = /^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)*)?\s*$/.test(
        value
      );
      const hasLeadingSpace = /^\s/.test(value);
      if (hasLeadingSpace) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else if (!isValidemail) {
        setError({ ...error, [fieldName]: "Please enter valid email only" });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }
    if (
      fieldName === "invoiceMasterId" ||
      fieldName === "bidderNumber" ||
      fieldName === "auctionId"
    ) {
      const isValidNumber = /^[0-9]*$/.test(value) && value?.length <= 20;
      const hasLeadingSpace = /^\s/.test(value);
      if (hasLeadingSpace) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else if (!isValidNumber) {
        setError({
          ...error,
          [fieldName]: "Please enter numbers only up to 20 digits",
        });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }

    if (fieldName === "companyName" || fieldName === "customerName") {
      const isValidTitle = !/^\s/.test(value);
      if (!isValidTitle) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }
  };

  const handleAdvanceFilter = (fdata: any, pageNum?: any) => {
    const data = trimObjectValues(fdata);
    setLoading(true);
    // Parse and format the date
    const startDateTime = fdata.startDateTime ? moment(data?.startDateTime,).format("YYYY-MM-DD") : '';
    const endDateTime = fdata.endDateTime ? moment(data?.endDateTime,).format("YYYY-MM-DD") : '';
    axios.get(ADVANCESEARCHINVOICE, {
      ...config,
      params: {
        invoiceMasterId: data.invoiceMasterId ?? "",
        auctionId: data.auctionId ?? "",
        customerId: data.customerId ?? "",
        companyName: data.companyName ?? "",
        bidderNumber: data.bidderNumber ?? "",
        customerName: data.customerName ?? "",
        isActive: data.isActive?.value ?? -1,
        invoiceDate: data?.invoiceDate,
        startDateTime: data?.startDateTime ? startDateTime : "",
        endDateTime: data?.endDateTime ? endDateTime : "",
        sentHistory: data?.sentInvoiceDate,
        email: data?.email ?? "",
        status: data?.status?.value ?? null,
        isVoid: data?.void?.value ?? null,
        auctionTitle: searchData?.name ? searchData?.name : '',
        keyword: data?.keyword,
        pageNumber: pageNum ? pageNum : pageNumber == 0 ? pageNumber + 1 : pageNumber,
        pageSize: paginationSize
      },
    })
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            setFilterInvoice([]);
            return;
          } else {
            const data = response?.data?.data?.data;
            setTotalRecords(response.data.data.totalRecords);
            paginateData(data, pageNum ? pageNum : pageNumber);
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    return;
  };

  const clearAdvanceFilterData = (isLocalClear: boolean = true, filterData: any = null) => {
    setAlreadyFetchedDataPageNumber({});
    pageNumber !== 1 && setPageNumber(1);
    setError({ email: "", bidderNumber: "", invoiceMasterId: "", customerName: "", companyName: "", auctionId: "" });
    setResetDate(false);
    if (filterData == null) {
      setIsFiltered(false);
      const resetFilter: any = {
        invoiceMasterId: "",
        customerId: "",
        bidderNumber: "",
        customerName: "",
        companyName: "",
        startDateTime: "",
        endDateTime: "",
        invoiceDate: "",
        isActive: -1,
        email: "",
        keyword: "",
        status: null,
        sentInvoiceDate: "",
        void: "",
        auctionId: isLocalClear ? "" : hammerAuctionId,
      };
      filterSetValue("sentInvoiceDate", "");
      handleAdvanceFilter(resetFilter, 1);
      clearSearchBar()
      setFilterData(resetFilter);
      filterReset(resetFilter);
      dispatch({ type: actions.RESETDATERANGE });
      dispatch({ type: actions.RESETSEARCHDATA });
    }

    if (!isLocalClear) {
      handleAdvanceFilter(filterData, 1);
      !isLocalClear && setSearchText("")
      setFilterData({ ...filterData, });
    }
  };

  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPageNumber(newPageNumber);
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
    updateData(page, data);
    setFilterInvoice(data);
  };

  const dateRangehandler = () => {
    handleAdvanceFilter({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
      isActive: -1,
      pageNumber: pageNumber == 0 ? pageNumber + 1 : pageNumber,
    }, pageNumber);
  };
  const handleDeleteModal = (invoiceMasterId: string, type: string) => {
    if (!permission.isAllowDelete) {
      Helper.unAuthorized();
      return;
    }
    else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.isConfirmed) {
            setLoading(true);
            axios
              .post(
                `${DELETEINVOICE}?invoiceMasterId=${invoiceMasterId}`,
                {},
                config
              )
              .then(
                (response) => {
                  setLoading(false);
                  if (response?.data?.success === "Fail") {
                    toast.error(
                      "Sorry, but deleting confirmed invoices is not possible",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                      }
                    );
                    return;
                  } else {
                    toast.success(InvoiceMessages.invoiceDeleted, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                    });
                  }
                  setSearchText("");
                  clearAdvanceFilterData(false, filterData);
                }).catch((error) => {
                  setLoading(false);
                  handleError(error)
                });;
          }
        }
      });
    }
  };

  const searchHandler = () => {
    handleAdvanceFilter({
      companyName: searchData?.name,
      invoiceMasterId: searchData?.id,
      startDateTime: searchData?.date,
      endDateTime: searchData?.date,
      isActive: -1,
      pageNumber: pageNumber == 0 ? pageNumber + 1 : pageNumber,
    }, pageNumber);
  };

  const handleCheckboxChange = (invoiceData: any, e: any) => {
    const isChecked = e.target.checked;
    // If isCheck is false then remove record from selected check box and invoice payload
    if (!isChecked) {
      // If checkbox is being unchecked, remove from selectedCheckboxes
      setSelectedCheckboxes((prevSelected: any) => {
        const { [invoiceData.invoiceMasterId]: removedId, ...rest } = prevSelected;
        return { ...rest };
      });
      setInvoicePayload((prevPayload: any) => {
        const filteredPayload = prevPayload.filter((data: any) => data.invoiceMasterId !== invoiceData.invoiceMasterId);
        return filteredPayload;
      });
    } else {
      // If checkbox is being checked, update selectedCheckboxes and perform PDF download
      setSelectedCheckboxes((prevSelected: any) => ({
        ...prevSelected,
        [invoiceData.invoiceMasterId]: true,
      }));
      getInvoiceByIds(invoiceData.invoiceMasterId,false)
    }
  };

  // Multiple send e invoice API 
  const handleSendInvoiceClick = () => {
    // invoicePayload is blank then display info that please select one
    if (invoicePayload?.length == 0) {
      Swal.fire({
        title: "Please select at least one E-Invoice",
        icon: "warning",
        confirmButtonColor: "#006800",
        confirmButtonText: "OK",
      });
    } else {
      setLoading(true);
      const formData: any = new FormData();
      // Multiple data payload
      let i = 0;
      invoicePayload?.forEach((data: any) => {
        formData.append(`invoice[${i}].invoiceEinvoiceId`, 0);
        formData.append(`invoice[${i}].invoiceId`, data?.invoiceMasterId);
        formData.append(`invoice[${i}].customerId`, data?.customerId);
        formData.append(`invoice[${i}].customerEmailID`, data?.customerEmail);
        formData.append(`invoice[${i}].pathFile`, data?.pdfForEmail)
        i++
      });
      axios.post(MULTIPLESENDEINVOICE, formData, fileConfig).then(
        (response) => {
          setLoading(false);
          setSearchText("");
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            toast.success(InvoiceMessages.eInvoiceSent, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setInvoicePayload([]);
            isFiltered == false ? getAllInvoices() : clearAdvanceFilterData(false, filterData);
            setSelectedCheckboxes({});
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error)
        });;
    }
  };
  const handleViewClose = () => {
    setToggleViewModal(!toggleViewModal);
    setIsPdfOpen(false);
  };

  //fucntion for showing view of pdf
  const openPdfViewer = (type: string, id: any) => {
    setIsPdfOpen(true);
    setToggleViewModal(true);
    getInvoiceByIds(id, false);
  };

  // function for showing sent history
  const openHistoryPopup = (invoiceId: any) => {
    setLoading(true)
    setToggleHistoryModal(true);
    axios
      .get(`${GETINVOICEHISTORY}?InvoiceId=${invoiceId}`)
      .then((response: any) => {
        const invoiceHistory = response?.data?.data;
        setInvoiceHistory(invoiceHistory);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        handleError(error)
      });
  }

  //function to close history popup
  const handleHistoryClose = () => {
    setToggleHistoryModal(false);
  };

  // this useEffect called when click on pageNumber
  useEffect(() => {
    //when redirected from dashboard with global search.
    if (searchData?.id || searchData?.name || searchData?.date) {
      searchHandler();
      dispatch({ type: actions.RESETSEARCHDATA });
      return;
    }

    //when global search applied
    if (filterData?.keyword) {
      //this prevents dual API call
      if (pageNumber == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterInvoice(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      handleAdvanceFilter(filterData);
      return;
    }
    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (pageNumber == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterInvoice(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      handleAdvanceFilter(filterData);
      return;
    }

    //when redirected from dashboard with countbox click.
    else if (dateRangeData.default == false) {
      dateRangehandler();
      // dispatch({ type: actions.RESETDATERANGE });
      return;
    }

    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterInvoice(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      getAllInvoices(paginationSize, pageNumber);
    }
  }, [pageNumber]);

  const handleEditInvoice = (id: any) => {
    if (!permission?.isAllowEdit) {
      Helper.unAuthorized();
      return;
    }
    else {
      navigate(`${routeConstant.UPDATEINVOICE}/${(id)}`)
      getInvoiceByIds(decryptId(id), false);
    }
  };

  const handleVoidInvoice = (invoiceMasterId: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once voided, you cannot undo this action!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${VOIDINVOICE}?invoiceMasterId=${invoiceMasterId}&isVoid=${true}`,
            {},
            config
          )
          .then(
            (response: any) => {
              setLoading(false);
              getAllInvoices();
              setPageNumber(0);
              setSearchText("");
              clearAdvanceFilterData();
              setSearchText("");
              toast.success(InvoiceMessages.voidInvoice, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }).catch((error) => {
              setLoading(false);
              handleError(error)
            });
      }
    });
  };

  const handleAdjustPayment = () => {
    if (!permission.isAllowAdd) {
      Helper.unAuthorized();
      return;
    }
    else {
      navigate(routeConstant.CREATEINVOICE);
      dispatch(setInvoiceAdjust([]));
      dispatch(setInvoicePayment([]));
    }
  };

  const getAllAuctions = () => {
    setLoading(true);
    axios.get(GETCLOSEDAUCTOINS).then((response: any) => {
      setLoading(false);
      let auctionData = []
      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
          };
        });
      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    });
  };

  // this is for reset date in filter section.
  useEffect(() => {
    if (resetDate == false) {
      setTimeout(() => {
        setResetDate(true);
      }, 50);
    }
  }, [filterData]);

  //debounce function for search functionality.
  const debouncedRequest = useDebounce(() => {
    handleAdvanceFilter(filterData);
  });

  return (
    <>
      <CommonHelmet title={"Invoice - "} name={"invoice"} content={"Invoice"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}

      <Sidebar />
      <Modal show={toggleViewModal} size="xl" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            Invoice Preview
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleViewClose}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="invoice-preview">
            <PdfFormat
              invoiceList={invoiceList}
              download={isDownload}
              view={isPdfOpen}
              invoiceData={invoiceData}
              footerData={footerData}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            data-bs-dismiss="modal"
            onClick={handleViewClose}
          >
            {CommonMessages.cancelButton}
          </button>
        </Modal.Footer>
      </Modal>

      {/*Modal for showing sent history*/}
      <Modal show={toggleHistoryModal} size="lg" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            <i className="fa-solid fa-file-import text-dark text-center w-15px me-2"></i>{" "}
            Invoice Sent History
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleHistoryClose}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            E-Invoice Send Logs :
          </h5>

          <div className="table-responsive mx-n1 px-1 e-invoice-date-table scrollbar mt-2">
            <table className="table fs--1 mb-0 border-top border-200">
              <thead>
                <tr>
                  <th className="bg-gradiant" scope="col">
                    E-Invoice Sent Date
                  </th>
                  <th
                    className="bg-gradiant"
                    scope="col"
                    data-sort="firstname"
                  >
                    <div className="d-flex align-items-center">
                      <span className="white-space-pre">E-Invoice Sent To</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="table-latest-review-body ">
                {invoiceHistory?.length > 0 ? (
                  invoiceHistory?.map(
                    (invoiceDatas: any, index: any) => {
                      const updatedTime = momentUtil.parseTimeInUtc({
                        dateTimeString: invoiceDatas?.createdOn,
                      });
                      return (
                        <tr
                          className="hover-actions-trigger btn-reveal-trigger position-static"
                          key={index}
                        >
                          <td className="p-2 white-space-pre">
                            {updatedTime.format("MM/DD/YYYY hh:mm A")}
                          </td>
                          <td className="p-2 white-space-pre">
                            {invoiceDatas?.customerEmailID}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr className="hover-actions-trigger btn-reveal-trigger position-static text-center">
                    <td className=" py-4" colSpan={15}>
                      No records found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger"
            type="button"
            data-bs-dismiss="modal"
            onClick={handleHistoryClose}
          >
            {CommonMessages.cancelButton}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Invoices">
          <div className="d-flex flex-wrap align-items-end">
            <div className="search-box mt-2">
              <div className="d-flex align-items-center">
                <div
                  className="position-relative d-flex align-items-center"
                  data-bs-toggle="search"
                  data-bs-display="static"
                >
                  <input
                    id='searchBar'
                    className="form-control search-input search form-control-sm"
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e: any) => {
                      if (e.target.value?.length) {
                        setFilterData({ ...filterData, keyword: e.target.value })
                        setAlreadyFetchedDataPageNumber({});
                        setPageNumber(1);
                        debouncedRequest();
                        return;
                      } else {
                        clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                        setFilterData({ ...filterData, keyword: '' })
                      }
                    }}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                  <button
                    type="button"
                    className="p-0 btn ms-3"
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  >
                    <i className="fas fa-filter dropdown-icon fs-16"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-auto mt-2 d-flex flex-wrap align-items-center ms-auto">
              <button
                onClick={handleAdjustPayment}
                className="btn btn-primary mx-1 position-relative d-flex aling-items-center "
                type="button"
                data-bs-toggle="modal"
              >
                <span className="plus me-2 ">
                  <span
                    className="fas fa-plus"
                    data-fa-transform="shrink-3"
                  ></span>
                </span>
                <span> Add New Invoice </span>
              </button>
              <button
                className="btn btn-outline-primary mx-1 position-relative d-flex aling-items-center"
                type="button"
                data-bs-toggle="modal"
              >
                Print Summary
              </button>
              <button
                className="btn btn-outline-primary mx-1 position-relative d-flex aling-items-center"
                type="button"
                data-bs-toggle="modal"
                onClick={() => {
                  handleSendInvoiceClick();
                }}
              >
                Send E-Invoice to List
              </button>
            </div>
          </div>
          {showFilter && (
            <div
              className={
                showFilter == true ? "mb-3" : "dropdown-advance-filter"
              }
            >
              <div className="card bg-transparent position-relative p-3 mt-3">
                <button
                  className="btn p-a p-1"
                  type="button"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label">Select Auction</label>
                            <Select
                              classNamePrefix="dpaSelect"
                              placeholder="Select Auction"
                              className="isActive swap-to-lot-menu clone-inventory"
                              options={auctionList}
                              filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                              value={auctionList.find(
                                (option: any) =>
                                  option.value == filterData?.auctionId
                              )}
                              onChange={(e: any) => {
                                changeHandler("auctionId", e?.value);
                                filterSetValue("auctionId", e?.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label" htmlFor="text">
                              Invoice #
                            </label>
                            <input
                              className="form-control form-icon-input"
                              type="text"
                              {...FilterRegister("invoiceMasterId")}
                              name="invoiceMasterId"
                              placeholder="Invoice #"
                              maxLength={21}
                              value={
                                searchData?.id
                                  ? searchData?.id
                                  : filterData?.invoiceMasterId
                              }
                              onChange={(e) => {
                                changeHandler(
                                  "invoiceMasterId",
                                  e.target.value
                                );
                                filterSetValue(
                                  "invoiceMasterId",
                                  e.target.value
                                );
                              }}
                            />
                            {error.invoiceMasterId && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.invoiceMasterId}
                              </span>
                            )}
                          </div>
                        </div>
                        {resetDate ? (
                          <div className="col-lg-3">
                            <div className="mt-2 mb-2">
                              <label
                                className="form-label"
                                htmlFor="bootstrap-wizard-validation-gender"
                              >
                                Invoice Date
                              </label>
                              <div className="date_icon">
                                <DateTime
                                  inputProps={{
                                    className:
                                      "form-control form-icon-input only_date_icon",
                                    placeholder: "Invoice Date",
                                    name: "invoiceDate",
                                  }}
                                  {...FilterRegister("invoiceDate")}
                                  closeOnSelect={true}
                                  value={
                                    filterData?.invoiceDate
                                  }
                                  onChange={(date: any) => {
                                    changeHandler("invoiceDate", date);
                                    filterSetValue("invoiceDate", date);
                                  }}
                                  timeFormat={false}
                                  dateFormat="MM/DD/y"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label" htmlFor="text">
                              Company Name
                            </label>
                            <input
                              className="form-control form-icon-input"
                              type="text"
                              {...FilterRegister("companyName")}
                              name="companyName"
                              placeholder="Company Name"
                              value={
                                searchData?.name
                                  ? searchData?.name
                                  : filterData?.companyName
                              }
                              onChange={(e) => {
                                filterSetValue("bidderNumber", e.target.value);
                                changeHandler("companyName", e.target.value);
                              }}
                            />
                            {error.companyName && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.companyName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label" htmlFor="text">
                              Bidder #
                            </label>
                            <input
                              className="form-control form-icon-input"
                              type="text"
                              {...FilterRegister("bidderNumber")}
                              name="bidderNumber"
                              placeholder="Bidder #"
                              maxLength={21}
                              value={
                                searchData?.id
                                  ? searchData?.id
                                  : filterData?.bidderNumber
                              }
                              onChange={(e) => {
                                changeHandler("bidderNumber", e.target.value);
                                filterSetValue("bidderNumber", e.target.value);
                              }}
                            />
                            {error.bidderNumber && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.bidderNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label" htmlFor="text">
                              Buyer Name
                            </label>
                            <input
                              className="form-control form-icon-input"
                              type="text"
                              {...FilterRegister("customerName")}
                              name="customerName"
                              placeholder="Buyer Name"
                              value={
                                searchData?.name
                                  ? searchData?.name
                                  : filterData?.customerName
                              }
                              onChange={(e) => {
                                changeHandler("customerName", e.target.value);
                                filterSetValue("customerName", e.target.value);
                              }}
                            />
                            {error.customerName && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.customerName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label className="form-label" htmlFor="text">
                              Email
                            </label>
                            <input
                              className="form-control form-icon-input"
                              type="text"
                              {...FilterRegister("email")}
                              name="email"
                              placeholder="abc@gmail.com"
                              value={
                                searchData?.id ? searchData?.id : filterData?.email
                              }
                              onChange={(e) => {
                                changeHandler("email", e.target.value);
                                filterSetValue("email", e.target.value);
                              }}
                            />
                            {error.email && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {error.email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              Payment Status
                            </label>
                            <Select
                              classNamePrefix="dpaSelect"
                              placeholder="Select"
                              {...FilterRegister("status")}
                              className="isActive clone-inventory"
                              name="status"
                              options={invoiceStatus}
                              value={filterData?.status}
                              onChange={(e) => {
                                changeHandler("status", e);
                                filterSetValue("status", e.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mt-2 mb-2">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              Void Status
                            </label>
                            <Select
                              classNamePrefix="dpaSelect"
                              placeholder="Select"
                              {...FilterRegister("void")}
                              className="isActive clone-inventory"
                              name="void"
                              options={voidStatus}
                              value={filterData?.void}
                              onChange={(e) => {
                                changeHandler("void", e);
                                filterSetValue("void", e.value);
                              }}
                            />
                          </div>
                        </div>
                        {resetDate ? (
                          <div className="col-lg-3">
                            <div className="mt-2 mb-2">
                              <label
                                className="form-label"
                                htmlFor="bootstrap-wizard-validation-gender"
                              >
                                Invoice Sent Date
                              </label>
                              <div className="date_icon">
                                <DateTime
                                  inputProps={{
                                    className:
                                      "form-control form-icon-input only_date_icon",
                                    placeholder: "Invoice Sent Date",
                                    name: "sentInvoiceDate",
                                  }}
                                  {...FilterRegister("sentInvoiceDate")}
                                  closeOnSelect={true}
                                  value={
                                    filterData?.sentInvoiceDate
                                  }
                                  onChange={(date: any) => {
                                    changeHandler("sentInvoiceDate", date);
                                    filterSetValue("sentInvoiceDate", date);
                                  }}
                                  timeFormat={false}
                                  dateFormat="MM/DD/y"
                                />
                              </div>
                            </div>
                          </div>) : <></>}
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end  ms-3 mt-2">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={() => { clearAdvanceFilterData(true) }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          onClick={() => {
                            pageNumber !== 1 && setPageNumber(1);
                            setAlreadyFetchedDataPageNumber({});
                            setIsFiltered(true);
                            pageNumber == 1 && handleAdvanceFilter(filterData, 1);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-5 border-300">
                <div>
                  <div className="table-responsive mx-n1 px-1">
                    <table className="table fs--1 mb-0 border-top border-200">
                      <thead>
                        <tr>
                          <th className="bg-gradiant" scope="col">
                            Action
                          </th>
                          {/* Change position of Invoice # */}
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="invoiceMasterId"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Invoice #</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">E-Invoice</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="date"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Invoice Date
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Auction Code - Title
                              </span>
                            </div>
                          </th>

                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="verificationCode"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Pickup Number
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="customerId"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Bidder #</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="bidderName"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Buyer Name
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="bidderName"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Void</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="companyName"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Company</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() =>
                                    handleSorting("ASC", "companyName")
                                  }
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() =>
                                    handleSorting("DESC", "companyName")
                                  }
                                ></button>
                              </div>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="total"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Sent History
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="email"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Email</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() => handleSorting("ASC", "email")}
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() => handleSorting("DESC", "email")}
                                ></button>
                              </div>
                            </div>
                          </th>

                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="buyersPrice"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Item Count
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="buyersPrice"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Item Total
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="buyersPrice"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Buyer Premium
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="total"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">Total</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="Privillege"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Balance Due
                              </span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="buyersPrice"
                          >
                            <div className="d-flex align-items-center">
                              <span className="white-space-pre">
                                Payment Status
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list" id="table-latest-review-body">
                        {filterInvoice?.length > 0 ? (
                          filterInvoice?.map(
                            (invoiceDatas: any, index: any) => {
                              return (
                                <tr
                                  className="hover-actions-trigger btn-reveal-trigger position-static"
                                  key={index}
                                >
                                  <td className="text-start p-2">
                                    <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                      <Dropdown.Toggle
                                        className="m-0 p-0"
                                        variant="none"
                                      >
                                        <button
                                          className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                          type="button"
                                        >
                                          <img
                                            src="/assets/img/ellipsis-solid.svg"
                                            style={{ width: "20px" }}
                                          />
                                        </button>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className={`dropdown-menu invoice-menu dropdown-menu-start py-2`}
                                      >
                                        <Dropdown.Item
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          onClick={() =>
                                            handleEditInvoice(
                                              encryptId(
                                                invoiceDatas.invoiceMasterId
                                              )
                                            )
                                          }
                                        >
                                          Edit
                                          <i className="fas fa-pen dropdown-icon text-center text-dark w-15px"></i>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          onClick={() =>
                                            handleDeleteModal(
                                              invoiceDatas.invoiceMasterId,
                                              "delete"
                                            )
                                          }
                                        >
                                          Delete
                                          <i className="fas fa-trash-o dropdown-icon text-center text-dark w-15px"></i>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          disabled={invoiceDatas?.isVoid}
                                          onClick={() => {
                                            getInvoiceByIds(
                                              invoiceDatas?.invoiceMasterId, true
                                            );
                                          }}
                                        >
                                          Download
                                          <i className="fas fa-download dropdown-icon text-center text-dark w-15px"></i>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          as="button"
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          // data-bs-toggle="modal"
                                          data-bs-target="#pdfInvoice"
                                          disabled={invoiceDatas?.isVoid}
                                          onClick={() => {
                                            openPdfViewer(
                                              "pdfInvoice",
                                              invoiceDatas.invoiceMasterId
                                            );
                                          }}
                                        >
                                          View
                                          <i className="fas fa-eye dropdown-icon text-dark text-center w-15px"></i>
                                        </Dropdown.Item>
                                        <button
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          disabled
                                        >
                                          Pay
                                          <img
                                            src={PayIcon}
                                            className="ms-auto"
                                          />
                                        </button>
                                        <button
                                          disabled={
                                            invoiceDatas.isVoid === true
                                          }
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          onClick={() =>
                                            handleVoidInvoice(
                                              invoiceDatas.invoiceMasterId
                                            )
                                          }
                                        >
                                          Void
                                          <i className="fas fa-file-lines dropdown-icon text-dark text-center w-15px"></i>
                                        </button>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  {/* Change position of invoice master Id */}
                                  <td className="p-2 white-space-pre">
                                    <Link
                                      className="btn text-decoration-underline m-0 p-0 fw-normal"
                                      data-bs-dismiss="modal"
                                      target="_blank"
                                      to={`${routeConstant.UPDATEINVOICE}/${encryptId(
                                        invoiceDatas?.invoiceMasterId
                                      )}`}
                                    >
                                      {invoiceDatas?.invoiceNumber}
                                    </Link>
                                  </td>
                                  <td className="text-start p-2">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`checkbox-${invoiceDatas.invoiceMasterId}`}
                                      checked={selectedCheckboxes[invoiceDatas.invoiceMasterId] || false}
                                      onChange={(e: any) =>
                                        handleCheckboxChange(invoiceDatas, e)
                                      }
                                    />
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {moment(invoiceDatas?.dateOfInvoice)
                                      .format("MM/DD/YYYY")
                                      .toString()}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.auctionCode} -{" "}
                                    {invoiceDatas?.auctionTitle}
                                  </td>

                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.pickupNumber
                                      ? invoiceDatas?.pickupNumber
                                      : "-"}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.bidderNumber ?? "-"}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.customerName ?? "-"}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.isVoid === true
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.companyName ?? "-"}
                                  </td>
                                  <td className="p-2 white-space-pre text-center">
                                    {invoiceDatas?.einvoiceDate ? (
                                      <button
                                        className="btn text-decoration-underline m-0 p-0 fw-normal text-start"
                                        data-bs-dismiss="modal"
                                        onClick={() => openHistoryPopup(invoiceDatas?.invoiceMasterId)}
                                      >
                                        {moment(invoiceDatas?.einvoiceDate)
                                          .format("MM/DD/YYYY")
                                          .toString()}
                                      </button>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {invoiceDatas?.email ?? "-"}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {
                                      invoiceDatas?.itemCount
                                        ? invoiceDatas?.itemCount
                                        : 0
                                    }
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {formatCurrency(
                                      invoiceDatas?.itemTotal
                                        ? invoiceDatas?.itemTotal
                                        : 0
                                    )}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {formatCurrency(
                                      invoiceDatas?.buyersPrice
                                        ? invoiceDatas?.buyersPrice
                                        : 0
                                    )}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {formatCurrency(invoiceDatas?.total)}
                                  </td>
                                  <td className="p-2 white-space-pre">
                                    {formatCurrency(invoiceDatas?.balanceDue)}
                                  </td>
                                  <td className="p-2 s-space-pre text-center">
                                    <span
                                      className={`badge badge-dpa badge-same-space payment_status-${onGetInvoicePaidCSS(
                                        invoiceDatas?.status.toString()
                                      )
                                        .toLocaleLowerCase()
                                        .replace(" ", "")}`}
                                    >
                                      {onGetInvoicePaid(invoiceDatas?.status.toString())}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr className="hover-actions-trigger btn-reveal-trigger position-static text-center">
                            <td className=" py-4" colSpan={18}>
                              No records found!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
                  <div className="">
                    {filterInvoice?.length > 0 && (
                      <ReactPaginate
                        pageCount={Math.ceil(totalRecords / paginationSize)}
                        previousLabel={"←"}
                        nextLabel={"→"}
                        onPageChange={({ selected }) => handlePageClick(selected)}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                        forcePage={
                          pageNumber == 0 ? pageNumber : pageNumber - 1
                        }

                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isPdfOpen && (
        <PdfFormat
          invoiceList={invoiceList}
          download={isDownload}
          view={isPdfOpen}
          invoiceData={invoiceData}
        />
      )} */}
    </>
  );
};
export default Invoice;
