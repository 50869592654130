import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const addAdjBids = (data: any): Action => ({
    type: action.ADDADJBIDS,
    payload: data,
});

export const deleteAdjBids = (absBidsUniqueId: any): Action => ({
    type: action.DELETEADJBIDS,
    payload: absBidsUniqueId,
});

export const updateAdjBids = (absBidsUniqueId: any, updatedData: any) => ({
    type: action.UPDATEADJBIDS,
    payload: { absBidsUniqueId, updatedData },
});
export const setAdjBidsFromResp = (data: any): Action => ({
    type: action.SETADJBIDSFROMRESP,
    payload: data
})
export const shippingData = (data: any): Action => ({
    type: action.SHIPPINGDATA,
    payload: data
})
export const updateshippingData = (name: any, value: any): Action => ({
    type: action.UPDATESHIPPINGDATA,
    payload: { name, value }
})