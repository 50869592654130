import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { useEffect, useState } from "react";

import ReactPaginate from "react-paginate";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { INVENTORYTITLEREPORT, TITLEADVANCEFILTER, GETALLAUCTIONLIST } from "../../common/apiConstatnts";

import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants';
import DateTime from "../../Utils/DateTime";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { useSelector } from "react-redux";
import { generateTitledReportCsv, generateTitledReportPdf } from "./ExportReportsPdfCsvFile";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Titled Report', url: routeConstant.SUSPENDREPORT }
]

const TitleReport = () => {
    const [loading, setLoading] = useState(false);
    const [filterTitle, setFilterTitle] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>({ lotNo: "", ctrlNo: "", sellerName: "", lotTitle: "", bidderNumber: "", customerName: "", titleAs: "", vin1: "", titleRecord: "", titleSent: "" });
    const [titleData, setTitleData]: any = useState([]);
    const [auctionData, setAuctionData] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [defaultAuction, setDefaultAuction] = useState<any>({});
    const [resetDate, setResetDate] = useState(true);
    const [globalAuction, setGlobalAuction] = useState<any>('')

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionData.find((option: any) => option.value === getGlobalAuction.value)
    
    const [error, setError] = useState({
        lotNo: "", ctrlNo: "", sellerName: "", lotTitle: "", bidderNumber: "", customerName: "", titleAs: "", vin1: ""
    });
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };
    const sendStatus = [
        {
            label: 'All',
            value: -1
        },
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ]

    const receivedStatus = [
        {
            label: 'All',
            value: -1
        },
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ]

    const PER_PAGE = 10;
    const pageCount = Math.ceil(titleData?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setTitleData(data);
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterTitle(firstSet);
        setCurrentPage(0);
    };

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...titleData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }
    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);

    }
    const handleAdvanceFilter = (filterData: any) => {
        setLoading(true);
        axios.get(TITLEADVANCEFILTER, {
            ...config,
            params: {
                lotNo: filterData?.lotNo,
                lotTitle: filterData?.lotTitle,
                ctrlNo: filterData?.ctrlNo,
                bidderNumber: filterData?.bidderNumber,
                titleAs: filterData?.title,
                customerName: filterData?.customerName,
                statusnew: filterData?.statusnew,
                vin1: filterData?.vin1,
                sold: filterData?.sold,
                sellerName: filterData?.sellerName,
                titleSent: filterData.titleSent ? `${filterData.titleSent} 00:00:00` : '',
                titleRecord: filterData.titleRecord ? `${filterData.titleRecord} 00:00:00` : '',
            },
        })
            .then(
                (response: any) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        setFilterTitle([]);
                        return;
                    }
                    else {
                        paginateData(response.data.data)
                    }
                } ).catch((error) => {
                    setLoading(false);
                    handleError(error);
                  });;
        return;
    };

    const clearAdvanceFilterData = () => {
        setFilterData({ lotNo: "", lotTitle: "", ctrlNo: "", bidderNumber: "", customerName: "", titleAs: "", vin1: "", sellerName: "", titleSent: "", titleRecord: "" });
        setError({
            lotNo: "", ctrlNo: "", sellerName: "", lotTitle: "", bidderNumber: "", customerName: "", titleAs: "", vin1: ""
        });
        getTitleReport(selectedAuction?.value);
        setResetDate(false)
    }

    const convertToDateOnlyFormat = (dateTimeString: any) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const changeHandler = (fieldName: string, value: any) => {
        let formattedValue = value;
        if (fieldName == "titleRecord") {
            formattedValue = convertToDateOnlyFormat(value);
        }
        if (fieldName == "titleSent") {
            formattedValue = convertToDateOnlyFormat(value);
        }
        // if (fieldName == "lotNo") {
        //     formattedValue = Number(value?.slice(0, 8)) == 0 ? null : Number(value?.slice(0, 8));
        // }

        const updateData = { ...filterData, [fieldName]: formattedValue }
        setFilterData(updateData);
        if (fieldName === "sellerName" || fieldName === "sellerName" || fieldName === "customerName" || fieldName === "titleAs" || fieldName === "vin1") {
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
        if (fieldName === "ctrlNo" || fieldName === "lotNo" || fieldName === "bidderNumber") {
            const isValidNumber = /^[0-9]*$/.test(value) && value.length <= 20;
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else if (!isValidNumber) {
                setError({ ...error, [fieldName]: 'Please enter number only upto 20 digits' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = titleData?.filter((x: any) =>
            x.customerName?.toLocaleLowerCase()?.includes(keyword) || x?.lotNo?.toString().includes(keyword)
            || x.titleAs?.toLocaleLowerCase()?.includes(keyword)
            || x.bidderNumber?.toString()?.includes(keyword)
            || x.lotTitle?.toLocaleLowerCase()?.includes(keyword)
            || x.vin?.toString().includes(keyword));
        paginateData(filterData);
        setCurrentPage(0)
    }
    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            // const auction = response?.data?.data?.map((d: any) => {
            //     const start: any = new Date(d.startDateTime);
            //     const current: any = UTCdateConverter(new Date());
            //     return { ...d, pendingTime: start - current }
            // })
            // const currentAuction = auction.find((obj: any) => obj.pendingTime == Math.min(...auction.filter((d: any) => d.pendingTime > 0).map((i: any) => i.pendingTime)));
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            setDefaultAuction(hammerAuction ? hammerAuction.value :auctionData?.[0]);
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionData(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    const getTitleReport = (auctionId: any) => {
        setLoading(true);
        if(auctionId){
            axios.get(`${INVENTORYTITLEREPORT}?AuctionId=${auctionId}`)
            .then((response) => {
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    setFilterTitle([]);
                    return;
                } else {
                    setLoading(false);
                    const data: any = response.data.data;
                    paginateData(data);
                }
            })
            .catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }
    };

    const handleAuctionChange = (e: any) => {
        const selectedAuctionCode: any = parseInt(e?.value);
        setGlobalAuction(e)
        setSelectedAuction(e);
        setDefaultAuction(e);
        setFilterTitle([]);
    };


    const generateExcelWithTitle = () => {
        let data = titleData?.map((data: any) => {
            return {
                lotNo: data.lotNo || "-",
                ctrl: data.ctrl || "-",
                description: data.description || "-",
                vin: data.vin || "-",
                status: data.status || "-",
                buyer: data.buyer || "-",
                seller: data.seller || "-",
                bidderNumber: data.bidderNumber || "-",
                sent: data.sent || "-",
                received: data.received || "-",
            };
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Titled-Report'],
            [`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Lot #', 'Ctrl', 'Description', 'Vin', 'Status', 'Buyer', 'Seller', 'Bidder #', 'Sent', 'Received'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Titled-Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');

        doc.setFontSize(13).text(`Titled-Report`, 15, 20);
        doc.setFontSize(11).text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Lot #', 'Ctrl', 'Description', 'Vin', 'Status', 'Buyer', 'Seller', 'Bidder #', 'Sent', 'Received']]
        const data = titleData.map((data: any) => {
            return {
                lotNo: data.lotNo || "-",
                ctrl: data.ctrl || "-",
                description: data.description || "-",
                vin: data.vin || "-",
                status: data.status || "-",
                buyer: data.buyer || "-",
                seller: data.seller || "-",
                bidderNumber: data.bidderNumber || "-",
                sent: data.sent || "-",
                received: data.received || "-",
            }
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Titled-Report.pdf`);
    }

    useEffect(() => {
        getAllAuctions();
    }, [])
 
    useEffect(() => {
        if (selectedAuction?.value) {
            getTitleReport(selectedAuction?.value)
        }
    }, [selectedAuction,])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
            setGlobalAuction(getGlobalAuction)
        } else {
            getAllAuctions();
            setFilterData([])
        }
    },[hammerAuction])

    // this is for reset date in filter section.
    useEffect(() => {
        if (resetDate == false) {
            setTimeout(() => { setResetDate(true) }, 50)
        }
    }, [filterData])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Titled Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-md-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="d-flex align-items-center">
                                    <div className="col-lg-6">

                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionData}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            // value={selectedAuction ? auctionData.find((option: any) => option.value === selectedAuction): hammerAuction }
                                            value={selectedAuction?.value ?  auctionData.find((option: any) => option.value === selectedAuction?.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                                    <form className="position-relative d-flex align-items-center mt-1" data-bs-toggle="search" data-bs-display="static">
                                        <button type="button" className="p-0 btn ms-3"
                                            onClick={(() => { setShowFilter(!showFilter) })}
                                        ><i className="fas fa-filter fs-16"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className=" col-md-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=>generateTitledReportCsv(titleData,globalAuction)} disabled={!titleData || titleData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateTitledReportPdf(titleData,globalAuction)} disabled={!titleData || titleData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Pdf</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-3">
                                <button className="btn p-1 p-a" type="button" onClick={() => { setShowFilter(!showFilter)}}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Buyer</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="customerName"
                                                        maxLength={30}
                                                        placeholder="Enter Buyer"
                                                        value={filterData.customerName}
                                                        onChange={((e) => changeHandler("customerName", e.target.value))}
                                                    />
                                                    {error?.customerName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.customerName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Seller</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        maxLength={30}
                                                        name="sellerName"
                                                        placeholder="Enter Seller Name"
                                                        value={filterData.sellerName}
                                                        onChange={((e) => changeHandler("sellerName", e.target.value))}
                                                    />
                                                    {error?.sellerName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.sellerName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Lot #</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lotNo"
                                                        maxLength={20}
                                                        placeholder="Lot"
                                                        value={filterData.lotNo}
                                                        onChange={((e) => changeHandler("lotNo", e.target.value))}
                                                    />
                                                    {error?.lotNo && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.lotNo}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Title As</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="titleAs"
                                                        maxLength={30}
                                                        placeholder="Enter Title"
                                                        value={filterData.titleAs}
                                                        onChange={((e) => changeHandler("titleAs", e.target.value))}
                                                    />
                                                    {error?.titleAs && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.titleAs}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Vin</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="vin1"
                                                        maxLength={15}
                                                        placeholder="Enter Vin"
                                                        value={filterData.vin1}
                                                        onChange={((e) => changeHandler("vin1", e.target.value))}
                                                    />
                                                    {error?.vin1 && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.vin1}</span>}
                                                </div>
                                            </div>
                                            {resetDate ?
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Title Sent</label>
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input only_date_icon",
                                                                    placeholder: "Title Sent On",
                                                                    name: "titleSent",
                                                                }}
                                                                closeOnSelect={true}
                                                                dateFormat="MM/DD/y"
                                                                timeFormat={false}
                                                                value={filterData.titleSent}
                                                                onChange={(value) => changeHandler("titleSent", value)}
                                                            />
                                                        </div>
                                                    </div></div> : <></>}
                                            {resetDate ?
                                                <div className="col-lg-3">
                                                    <div className="mt-2 mb-2">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Title Received</label>
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input only_date_icon",
                                                                    placeholder: "Title Received On",
                                                                    name: "titleRecord",
                                                                }}
                                                                closeOnSelect={true}
                                                                dateFormat="MM/DD/y"
                                                                timeFormat={false}
                                                                value={filterData.titleRecord}
                                                                onChange={(value) => changeHandler("titleRecord", value)}
                                                            />
                                                        </div>
                                                    </div> </div> : <></>}
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end  ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="button" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="button" onClick={() => handleAdvanceFilter(filterData)}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                    <div className="d-flex align-items-center">
                                                        <span>Lot #</span>
                                                        <div className="sorting_group">
                                                            <button className="arrow-up" onClick={() => handleSorting("ASC", "ctrlNo")} ></button>
                                                            <button className="arrow-down" onClick={() => handleSorting("DESC", "ctrlNo")}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="Ctrl">
                                                    <div className="d-flex align-items-center">
                                                        <span>Ctrl</span>
                                                        <div className="sorting_group">
                                                            <button className="arrow-up" onClick={() => handleSorting("ASC", "ctrlNo")}></button>
                                                            <button className="arrow-down" onClick={() => handleSorting("DESC", "ctrlNo")}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Lot Title</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotTitle')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotTitle')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Vin</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Status</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Buyer</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'customerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'customerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Seller</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'datetime')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'datetime')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Bidder #</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="message">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Sent</span>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="message">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Received</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterTitle?.length > 0 ? filterTitle?.map((tData: any, sIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={sIndex}>
                                                        <td className="align-middle  p-2">{tData.lotNo}</td>
                                                        <td className="align-middle  p-2">{tData.ctrlNo}</td>
                                                        <td className="align-middle  p-2">{tData.lotTitle}</td>
                                                        <td className="align-middle  p-2">{tData.vin}</td>
                                                        <td className="align-middle  p-2">
                                                            {" "}
                                                            <span
                                                                className={`badge badge-dpa status-${tData.stringInventoryStatus
                                                                    .replace(/ /g, "")
                                                                    .toLowerCase()}`}
                                                            >
                                                                {tData.stringInventoryStatus}
                                                            </span>
                                                        </td>
                                                        <td className="align-middle  p-2">{tData.customerName}</td>
                                                        <td className="align-middle  p-2">{tData.sellerName}</td>
                                                        <td className="align-middle  p-2">{tData.bidderNumber ? tData.bidderNumber : "-"}</td>
                                                        <td className="align-middle  p-2">
                                                            {tData.titleSent ? moment(tData.titleSent ).format('MM/DD/YYYY hh:mm A').toString() : '-'}</td>
                                                        <td className="align-middle  p-2">
                                                            {tData.titleRecord ? moment(tData.titleRecord).format('MM/DD/YYYY hh:mm A').toString() : '-'}</td>
                                                    </tr>
                                                )
                                            }) :
                                                (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                        <td
                                                            className="text-center p-4"
                                                            colSpan={13}
                                                        >
                                                            No records found!
                                                        </td>
                                                    </tr>
                                                )

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                {filterTitle?.length > 0 && (
                                    <ReactPaginate
                                        previousLabel={"←"}
                                        nextLabel={"→"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"pagination__link"}
                                        nextLinkClassName={"pagination__link"}
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                        forcePage={currentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TitleReport;