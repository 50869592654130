
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { GETCOMMONVALUE } from "../../common/apiConstatnts";
import { CommonMessages } from "../../common/messageConstants";
import { setInvoiceAdjust } from "../../store/actions/InvoiceAction";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES, handleKeyDown } from "../../Utils/Utils";

const AdjustModal = ({ toggleAdjModalNew, closeModal, ajustEditData, modes, invoiceMasterID, setLoading, setValue }: any) => {

  const dispatch = useDispatch()
  const adjustData = useSelector((state: any) => state?.inovice?.adjust);

  const initialAdjustData = {
    invoiceMasterId: invoiceMasterID ?? 0,
    invoiceAdjustId: 0,
    amount: "",
    chgCr: "",
    tax: "",
    taxAmount: "",
    description: "",
    adjustUniqueId: "",
    isActive: true,
    isDelete: false,
    isChange: false,
    isSubmit: false,
    index: 0
  }

  const options = [
    { value: 1, label: "Credit" },
    { value: 2, label: "Charge" }
  ];

  const [adjustFormValue, setAdjustFormValue] = useState<any>(initialAdjustData)
  const [description, setDescription] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>();

  const addAdjustSchema: any = yup.object().shape({
    amount: yup.string()
      .trim()
      .required('Amount is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s*$/.test(value) : true;
      }),
    chgCr: yup.string()
      .trim()
      .required('Chg/Cr is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s*$/.test(value) : true;
      }),
    description: yup.string().required('Description is required.'),
  })

  const {
    register: registerAdjust,
    handleSubmit: handleSubmitAdjust,
    reset: resetAdjust,
    getValues: getValuesAdjust,
    watch: adjustWatch,
    setValue: adjustSetValue,
    formState: { errors: errorsAdjust, isValid: isValidAdjust }
  } = useForm({ resolver: yupResolver(addAdjustSchema), defaultValues: adjustFormValue, mode: 'onChange' });

  const chgCr = adjustWatch('chgCr');
  const amount = adjustWatch("amount");
  const taxAmount = adjustWatch("taxAmount");

  useEffect(() => {
    if ((chgCr == "1" || !chgCr)) {
      adjustSetValue('taxAmount', "");
    } else {
      adjustSetValue('taxAmount', Number(ajustEditData?.taxAmount || 0).toFixed(2));
    }
  }, [chgCr]);

  useEffect(() => {
    axios.get(`${GETCOMMONVALUE}?filter=AdjustDescription&companyId=${localStorage.getItem("companyId")}`).then((response) => {
      if (response?.data?.success === "Fail") {
        return;
      }
      else {
        setDescription(response?.data?.data);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }, [])

  const onSubmitAdjust = () => {
    let data = getValuesAdjust();
    if (modes === "create") {
      const newAdjustData = {
        ...initialAdjustData,
        ...data,
        adjustUniqueId: uuidv4(),
        index: adjustData?.length == 0 ? 0 : adjustData?.length + 1,
        isChange: true,
        chgCr: parseInt(data.chgCr)
      }
      const updatedData = adjustData?.length ? [...adjustData, newAdjustData] : [newAdjustData]
      dispatch(setInvoiceAdjust(updatedData));
      resetAdjust(initialAdjustData);
      toast.success("Adjustment added successfully !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      closeModal()
    } else {
      const updatedData = {
        ...data, isChange: true
      }
      const updateAdjust = adjustData?.map((adjust: any) => {
        if (adjust.adjustUniqueId === data.adjustUniqueId) {
          return updatedData; // Replace the matching adjust with updatedData
        }
        return adjust; // Otherwise, return the payment unchanged
      });
      const totalAdjustAmounts = updateAdjust?.filter((item: any) => item.isDelete == false)?.reduce(
        (total: number, adjust: any) => {
          // Parse the amount and taxAmount
          const amount = parseFloat(adjust?.amount || 0);
          const taxAmount = parseFloat(adjust?.taxAmount || 0);

          // Add taxAmount to amount
          const adjustedAmount = amount + taxAmount;

          // Apply chgCr logic - if chgCr is credit then value should be minus or if charge then value is plus
          const finalAmount =
            adjust?.chgCr === 1 ? -adjustedAmount : adjustedAmount;

          // Sum the adjusted amounts
          return total + finalAmount;
        },
        0
      );

      // Update the state with the total adjustment amount
      setValue("invoiceAdjustment", totalAdjustAmounts);
      dispatch(setInvoiceAdjust(updateAdjust));
      toast.success("Adjustment updated successfully !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      closeModal();
    }
  }

  const handleOptionChange = (event: any) => {
    if (event.target.value) {
      setSelectedOption(event.target.value);
    }
    else {
      setSelectedOption("");
    }
  };

  useEffect(() => {
    if (Object.keys(ajustEditData)?.length) {
      setSelectedOption(ajustEditData?.chgCr);
      resetAdjust({ ...ajustEditData, amount: Number(ajustEditData?.amount)?.toFixed(2), taxAmount: Number(ajustEditData?.taxAmount)?.toFixed(2) });
    }
  }, [ajustEditData]);
  
  useEffect(() => {
    if (toggleAdjModalNew) {
      setTimeout(() => {
        setLoading(false);
      }, 500)
    }
  }, [toggleAdjModalNew])

  return (
    <>
      <Modal show={toggleAdjModalNew} size="lg" centered>
        <form>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              {modes === "create" ? "Add Adjustment" : "Edit Adjustment"}
            </h5>
            <button className="btn p-1" type="button" onClick={closeModal}>
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-3 mb-3">
              <div className="col-md-12">
                <label className="form-label">
                  Description <sup className="text-danger">*</sup>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...registerAdjust("description")}
                >
                  <option value="">Select</option>
                  {description?.map((initialData: any, index: any) => (
                    <option
                      key={index}
                      value={initialData.objectTypeValue}
                      selected={
                        ajustEditData?.description ===
                        initialData?.objectTypeValue
                      }
                    >
                      {initialData.objectTypeValue}
                    </option>
                  ))}
                </select>
                {errorsAdjust.description && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsAdjust.description.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Amount <sup className="text-danger">*</sup>
                </label>
                <CurrencyInput
                  type="text"
                  placeholder="Amount ($)"
                  {...registerAdjust("amount")}
                  prefix="$"
                  value={amount}
                  allowDecimals={true}
                  onKeyDown={handleKeyDown}
                  onValueChange={(value: any) => {
                    adjustSetValue("amount", value);
                  }}
                  className="form-control form-icon-input"
                />
                {errorsAdjust.amount && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsAdjust.amount.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Charge/Credit <sup className="text-danger">*</sup>
                </label>
                <select
                  className="form-select"
                  value={selectedOption}
                  aria-label="Default select example"
                  {...registerAdjust("chgCr", {
                    onChange: (e) => {
                      handleOptionChange(e);
                    },
                  })}
                >
                  <option value="">Select </option>
                  {options?.map((initialData: any, index: any) => {
                    return (
                      <option key={index} value={initialData.value}>
                        {initialData.label}
                      </option>
                    );
                  })}
                </select>
                {errorsAdjust.chgCr && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsAdjust.chgCr.message}
                  </span>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Taxable (Charge Only)</label>
                <CurrencyInput
                  type="text"
                  placeholder="Tax amount ($)"
                  {...registerAdjust("taxAmount")}
                  prefix="$"
                  value={taxAmount}
                  allowDecimals={true}
                  onKeyDown={handleKeyDown}
                  onValueChange={(value: any) => {
                    adjustSetValue("taxAmount", value);
                  }}
                  disabled={selectedOption == 2 ? false : true}
                  className="form-control form-icon-input"
                />
                {errorsAdjust.taxAmount && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorsAdjust.taxAmount.message}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              onClick={closeModal}
            >
              {CommonMessages.cancelButton}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              role="button"
              onClick={handleSubmitAdjust(onSubmitAdjust)}
            >
              {modes != "create"
                ? CommonMessages.updateButton
                : CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default AdjustModal;