import axios from "axios"
import { useEffect, useState } from "react"
import { CREATEAVALARA, UPDATEAVALARA, GETAVALARABYID } from "../../common/apiConstatnts";
import { toast } from "react-toastify"
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
import * as routeConsant from '../../common/routeConstants';
import { useSelector } from "react-redux";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants';
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import { wordCounter } from "../../common/constants";
import { AvalaraMessages, CommonMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../Utils/Utils";
import { FIRSTANDLASTNAMEREGEX } from "../../common/regexConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Tax & Currency', url :""},
    { label: 'Avalara', url: '' }
]

const Avalara = () => {
    const [loading, setLoading] = useState(false);
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const maxWordCount = 250;
    const [remainingCount, setRemainingCount] = useState(maxWordCount);

    const initialData = {
        isAlwaysUseAvalaraForNewAuctions: false,
        avalaraAccountName: "",
        avalaraAccountId: null,
        avalaraKey: "",
        isAvalaraUseSandBox: false,
        pingResults: "",
        companyId: null,
        isActive: true,
        isDelete: false,
        createdOn: "",
        createdBy: null,
        updatedOn: "",
        updatedBy: null,
        avalaraID: null,
        modifiedBy: ""
    }

    const [formValues, setFormValues] = useState<any>(initialData);


    const schema: any = yup.object().shape({
        isAlwaysUseAvalaraForNewAuctions: yup.boolean(),
        avalaraAccountId: yup.number().required("Avalara Account ID is required").typeError('Avalara Account ID must be a number.')
            .test(
                'maxDigits',
                'Please enter upto 10 digits',
                value => (value ? value.toString().length <= 10 : true)
            ),
        avalaraAccountName: yup.string().required("Avalara Account Name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid Avalara Account Name.').max(100, "Please enter  upto 100 characters."),
        avalaraKey: yup.string().required("Avalara Key is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid Avalara Key.').max(50, "Please enter  upto 50 characters."),
        isAvalaraUseSandBox: yup.boolean(),
        pingResults: yup.string().required("Ping Results required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid Ping Results.'),
    });

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' });

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const onSubmit = () => {
        const dataOriginal = getValues();
        let data = trimObjectValues(dataOriginal);
        setLoading(true);
        let URL
        let DATA
        if (formValues.avalaraID) {
            URL = UPDATEAVALARA
            DATA = data
        } else {
            URL = CREATEAVALARA
            DATA = {
                isAlwaysUseAvalaraForNewAuctions: data.isAlwaysUseAvalaraForNewAuctions,
                avalaraAccountId: data.avalaraAccountId,
                avalaraAccountName: data.avalaraAccountName,
                avalaraKey: data.avalaraKey,
                isAvalaraUseSandBox: data.isAvalaraUseSandBox,
                pingResults: data.pingResults,
            }
        }
        axios.post(URL, DATA, { ...config, ContentType: "application/json" }).then(
            (response) => {
                getAvalaraByID();
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    toast.success(AvalaraMessages.avalaraUpdated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }).catch((error)=>{
                setLoading(false);
                handleError(error)
            });
    };

    const getAvalaraByID = () => {
        setLoading(true);
        axios.get(GETAVALARABYID, config).then(
            (response) => {
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    const resultData = response?.data?.data;
                    setLoading(false);
                    if (resultData) {
                        const avalaraData = {
                            isAlwaysUseAvalaraForNewAuctions: resultData?.isAlwaysUseAvalaraForNewAuctions,
                            avalaraAccountName: resultData?.avalaraAccountName,
                            avalaraAccountId: resultData?.avalaraAccountId,
                            avalaraKey: resultData?.avalaraKey,
                            isAvalaraUseSandBox: resultData?.isAvalaraUseSandBox,
                            pingResults: resultData?.pingResults,
                            companyId: resultData?.companyId,
                            isActive: resultData?.isActive,
                            isDelete: resultData?.isDelete,
                            createdOn: resultData?.createdOn,
                            createdBy: resultData?.createdBy,
                            updatedOn: resultData?.updatedOn,
                            updatedBy: resultData?.updatedBy,
                            avalaraID: resultData?.avalaraID,
                            modifiedBy: resultData?.modifiedBy
                        }
                        setFormValues(avalaraData)
                        reset(avalaraData)
                    }
                }
            }).catch((error)=>{
                setLoading(false);
                handleError(error);
            })
    }

    useEffect(() => {
        getAvalaraByID();
    }, [])

    return (
        <>
            <CommonHelmet title={"Avalara - "} name={"avalara"} content={"Avalara"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <div className="row top_border justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-4 mb-3 border-300">
                                <h4>Avalara</h4>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            {...register("isAlwaysUseAvalaraForNewAuctions")}
                                        />
                                        <label className="form-label mx-2">Always Use Avalara for New Auctions</label>
                                        {errors.isAlwaysUseAvalaraForNewAuctions && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.isAlwaysUseAvalaraForNewAuctions.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">Avalara Account Name <sup className="text-danger">*</sup></label>
                                        <input type="text" placeholder="Enter Avalara Account Name" {...register('avalaraAccountName')} className="form-control" maxLength={101} />
                                        {errors.avalaraAccountName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.avalaraAccountName.message}</span>}
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-3">
                                        <label className="form-label">Avalara Account ID <sup className="text-danger">*</sup></label>
                                        <input type="text" placeholder="Enter Avalara Account ID" {...register('avalaraAccountId')} className="form-control" maxLength={11} onKeyDown={handleKeyDown}/>
                                        {errors.avalaraAccountId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.avalaraAccountId.message}</span>}
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-3">
                                        <label className="form-label">Avalara Key <sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control" placeholder="Enter Avalara Key" {...register('avalaraKey')} maxLength={51} />
                                        {errors.avalaraKey && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.avalaraKey.message}</span>}
                                    </div>
                                    <div className="col-lg-12 col-md-6 mb-2">
                                        <label className="form-label mb-2">Avalara Use Sandbox</label>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    {...register("isAvalaraUseSandBox")}
                                                />
                                                <label className="form-label mx-2"> Yes</label>
                                                {errors.isAvalaraUseSandBox && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.isAvalaraUseSandBox.message}</span>}
                                            </div>
                                            <div>
                                                <button className="btn btn-sm btn-primary px-2 py-1 me-2" type="submit">{CommonMessages.updateButton}</button>
                                                <button className="btn btn-sm btn-primary px-2 py-1" type="button">Activate Avalara</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">Ping Results <sup className="text-danger">*</sup></label>
                                        <textarea
                                            className="form-control"
                                            placeholder="Enter Ping Results"
                                            cols={5}
                                            rows={5}
                                            maxLength={maxWordCount}
                                            {...register('pingResults', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                        />
                                        {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                            {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                        </div>}
                                        {errors.pingResults && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.pingResults.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mb-3 d-flex flex-row-reverse">
                                        <button className="btn btn-light border border-danger text-danger" type='button'>Clear</button>
                                        <button className="btn btn-light border border-success mx-2 text-success" type='button'>Ping</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Avalara;
