import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { GETALLAUCTIONLIST, GETTAXSUMMARYBYJURISDICTION } from "../../common/apiConstatnts";
import { useSelector } from "react-redux";
import { generateTaxSummaryByJurisdictionCsv, generateTaxSummaryByJurisdictionPdf } from "./ExportReportsPdfCsvFile";
import moment from "moment";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Tax Summary By Jurisdiction Report', url: routeConstant.INVENTORYBYSELLER }
]

const TaxSummaryByJurisdiction = () => {
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const [loading, setLoading] = useState(false);
    const [filterTaxData, setFilterTaxData] = useState<any>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [globalAuction, setGlobalAuction] = useState<any>('');
    const [totalsByTaxRegion, setTotalsByTaxRegion] = useState<any>({});
    let AuctionStartDate = filterTaxData?.length > 0 ? filterTaxData[0].auctionDate
    : null;

    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
        setGlobalAuction(e)
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction: any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    };


    // Function to fetch TaxSummaryData  based on the selected auction ID
    const fetchTaxSummaryData = (auctionId: any) => {
        setLoading(true);
        if (auctionId) {
            // setTaxRateListing([]);
            axios.get(`${GETTAXSUMMARYBYJURISDICTION}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                const taxData = response?.data?.result?.data;
                if (taxData?.length > 0) {
                    setFilterTaxData(taxData);
                    const totals = calculateTotals(taxData);
                    setTotalsByTaxRegion(totals);
                }
                else {
                    setFilterTaxData([]);
                }
            }).catch((error: any) => {
                setLoading(false);
                handleError(error);
            });
        }
    };

    const calculateTotals = (data:any) => {
        const totals:any = {};
        data.forEach((regionData:any) => {
            const { taxRegion, subResp }:any= regionData;
    
            if (!totals[taxRegion]) {
                totals[taxRegion] = {
                    taxTypes: {},
                    totalLotPrice: 0.00,
                    totalTaxCharged: 0.00
                };
            }
            subResp.forEach((sub:any) => {
                // Accumulate total lot price and tax charged per taxRegion
                totals[taxRegion].totalLotPrice += sub.lotPrice;
                totals[taxRegion].totalTaxCharged += sub.taxCharged;
    
                // Accumulate totals by taxType within the taxRegion
                if (!totals[taxRegion].taxTypes[sub.taxType]) {
                    totals[taxRegion].taxTypes[sub.taxType] = 0;
                }
                totals[taxRegion].taxTypes[sub.taxType] += sub.taxCharged;
            });
        });
    
        return totals;
    };
    

    useEffect(() => {
        getAllAuctions();
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            fetchTaxSummaryData(selectedAuction?.value)
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (getGlobalAuctionIds) {
            setSelectedAuction(getGlobalAuction);
            setGlobalAuction(getGlobalAuction)
        }
        else {
            getAllAuctions();
        }
    }, [getGlobalAuction])

    const formatNumber = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };


    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const calculateTotalLotPrice = () => {
        let total = 0;
        filterTaxData?.forEach((item:any) => {
          item.subResp?.forEach((subItem:any) => {
            total += subItem.lotPrice;
          });
        });
        return total;
      };
      const totalLotPrice = calculateTotalLotPrice();
      // total of over all tax charged 
      const calculateTotalTaxCharged = () => {
        let total = 0;
        filterTaxData?.forEach((item:any) => {
          item.subResp?.forEach((subItem:any) => {
            total += subItem.taxCharged;
          });
        });
        return total;
      };
    const totalTaxCharged = calculateTotalTaxCharged();

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Tax Summary By Jurisdiction Report'>

                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                <div className="col-lg-8">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Auction"
                                        className="isActive swap-to-lot-menu clone-inventory"
                                        options={auctionList}
                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                        value={selectedAuction?.value ? auctionList.find((option: any) => option?.value === selectedAuction.value) : getGlobalAuction}
                                        onChange={(e: any) => {
                                            handleAuctionChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                                        onClick={() => generateTaxSummaryByJurisdictionCsv(filterTaxData, globalAuction,)}
                                        disabled={filterTaxData?.length == 0 || filterTaxData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2"
                                        onClick={() => generateTaxSummaryByJurisdictionPdf(filterTaxData, globalAuction)}
                                        disabled={filterTaxData?.length == 0 || filterTaxData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                            <div className="mb-5 border-300">
                                {
                                    filterTaxData && filterTaxData.length > 0 ? (
                                        <>
                                        <div className="table-responsive">
                                            <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                                <tbody>
                                                    <tr>
                                                        <td valign="top" style={{ border: 0, width: '100%' }}>
                                                            <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Tax Summary By Jurisdiction Report</p>
                                                            <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                                <span>{`Auction #${selectedAuction
                                                                    ? selectedAuction?.label?.split('-')[0]
                                                                    : globalAuction
                                                                        ? getGlobalAuctionIds
                                                                        : getGlobalAuctionIds?.label?.split('-')[0]
                                                                    }`}</span><br /><span>{AuctionStartDate}</span>

                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table className="w-100 mt-2" style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Seller Id</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Seller Name</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Tax Code</th>
                                                        <th style={{ padding: '8px', textAlign: 'left', fontSize:'12px' }}>Tax Region</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Tax Type</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Tax Name</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Lot #</th>
                                                        <th style={{ padding: '8px', textAlign: 'left', fontSize:'12px' }}>Address</th>
                                                        <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Type</th>
                                                        <th style={{ padding: '8px', textAlign: 'right', fontSize:'12px' }}>Lot Price</th>
                                                        <th style={{ padding: '8px', textAlign: 'right', fontSize:'12px' }}>Tax Charged</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filterTaxData.map((data: any, index: any) => (
                                                        <React.Fragment key={index}>
                                                            {/* Tax Region Header */}
                                                            <tr style={{ backgroundColor: '#c0c0c0' }}>
                                                                <td colSpan={10} style={{ padding: '8px', borderTop: '1px solid #c0c0c0', borderBottom: '1px solid #ccc',fontSize:'12px' }}>
                                                                    {data.taxRegion.toUpperCase()}
                                                                </td>
                                                                <td colSpan={3} style={{ padding: '8px', borderTop: '1px solid #c0c0c0', borderBottom: '1px solid #ccc',fontSize:'12px', fontWeight: 'bold', textAlign:'end' }}>
                                                                    ${totalsByTaxRegion[data.taxRegion]?.totalTaxCharged.toFixed(2)}
                                                                </td>
                                                            </tr>
                                                            {/*second header*/}
                                                            <tr style={{ backgroundColor: '#E0E0E0' }}>
                                                                {/* Combined unused columns */}
                                                                <td colSpan={3} style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc',fontSize:'12px' }}>
                                                                </td>

                                                                {/* Tax Region */}
                                                                <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'left',fontSize:'12px' }}>
                                                                    {data?.taxRegion.toUpperCase()}
                                                                </td>

                                                                {/* Tax Type */}
                                                                <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'12px' }}>
                                                                    {data?.taxType.toUpperCase()}
                                                                </td>

                                                                {/* Another combined unused column */}
                                                                <td colSpan={4} style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc',fontSize:'12px' }}>
                                                                    {/* Can leave empty or add description if needed */}
                                                                </td>

                                                                {/* Total Lot Price */}
                                                                <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'right',fontSize:'12px' }}>
                                                                    ${totalsByTaxRegion[data.taxRegion]?.totalLotPrice.toFixed(2)}
                                                                </td>

                                                                {/* Total Tax Charged */}
                                                                <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'right',fontSize:'12px' }}>
                                                                    ${totalsByTaxRegion[data.taxRegion]?.totalTaxCharged.toFixed(2)}
                                                                </td>
                                                            </tr>


                                                            {/* Rows for subResp */}
                                                            {data?.subResp?.map((sub: any, subIndex: any) => (
                                                                <tr key={subIndex}>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{data?.sellerId}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{data?.sellerName}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{data?.taxCode}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'left',fontSize:'11px' }}>{data?.taxRegion}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{data?.taxType}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{sub?.taxName}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{sub?.lotNo}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'left',fontSize:'11px' }}>{sub?.address ? sub?.address : '-'}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'center',fontSize:'11px' }}>{sub?.type}</td>
                                                                    {/* <td style={{ padding: '8px',borderTop: '1px solid #ccc',borderBottom: '1px solid #ccc'}}>{sub?.title}</td> */}
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'right',fontSize:'11px'  }}>{sub?.lotPrice ? `$${formatNumber(sub?.lotPrice)}` : '0.00'}</td>
                                                                    <td style={{ padding: '8px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', textAlign: 'right',fontSize:'11px'  }}>{sub?.taxCharged ? `$${formatNumber(sub?.taxCharged)}` : '0.00'}</td>
                                                                </tr>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}> </th>
                                                    <th style={{ padding: '8px', textAlign: 'center', fontSize:'12px' }}>Totals </th>
                                                    <th style={{ padding: '8px', textAlign: 'right', fontSize:'12px' }}>${formatNumber(totalLotPrice)}</th>
                                                    <th style={{ padding: '8px', textAlign: 'right', fontSize:'12px' }}>${formatNumber(totalTaxCharged)} </th>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                            {getNoDataView()}
                                        </>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxSummaryByJurisdiction;


