import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { Breadcrumb } from "../../common/interface";
import Headers from "../Header/Header";
const InnerHeader = ({ breadCrumb, children, title }: { breadCrumb: Breadcrumb[], children?: React.ReactNode, title: string }) => {
    return (
        <>
            <Headers />
            <nav aria-label="breadcrumb" className="navbar navbar-top border-bottom-0 navbar-expand bg-gradiant pt-2 pb-2" >
                <BreadCrumbs data={breadCrumb} />
            </nav>
            <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-12 mt-0">
                        <div className="bg-gradiant align-items-center justify-content-between ps-3 pe-3 pt-3 pb-3 mt-0 custom_border">
                            <div className="col-auto">
                                <h4 className="mb-0">{title}</h4>
                            </div>
                            <div className="row g-0 align-items-center justify-content-between">
                                {children}
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default InnerHeader
