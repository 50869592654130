import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GETAUCTIONRESULTCONFIG, UPDATEAUCTIONRESULTSCONFIG } from '../../../../common/apiConstatnts';
import { CommonMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionValueActive, setCloneTab, setCreateTab, setStep } from '../../../../store/actions/AuctionAction';
import { loader } from '../../../../store/actions/CommonAction';
import { AUCTIONRESULTSCONFIG } from '../../AuctionConstants';
import handleError from '../../../../Utils/ApiCommonErrorMessages';

const AuctionResultsConfig = () => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
    const dispatch = useDispatch()
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)

    const initialData = {
        bidHistory: false,
        serialNumber: false,
        sellerContactInfo: false,
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
    };

    const [formValues, setFormValues] = useState<any>(initialData)
    const [btnTxt, setBtnTxt] = useState<any>("")

    const schema: any = yup.object().shape({
        bidHistory: yup.boolean(),
        serialNumber: yup.boolean(),
        sellerContactInfo: yup.boolean(),
    })

    const {
        register,
        reset,
        handleSubmit,
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })

    const onSubmit = (dataOriginal: any) => {
        dispatch(loader(true))
        const data = dataOriginal;
        const payload = { ...data, auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId), };

        axios.post(UPDATEAUCTIONRESULTSCONFIG, payload, config).then((response: any) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success("Auction result config updated successfully !!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUCTIONRESULTSCONFIG]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUCTIONRESULTSCONFIG]))
                }
                handleNext()
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handlePrevious = () => {
        // dispatch(setAuctionValueActive({ activeStep: 2, activeLabel: "Commissions & Categories" }))
        dispatch(setAuctionValueActive({ activeStep: 1, activeLabel: "Commissions & Categories" }))
    }

    const handleNext = () => {
        dispatch(setStep(7))
    }

    const getAuctionConfigData = () => {
        dispatch(loader(true))
        const aId = clonedTabs.includes(AUCTIONRESULTSCONFIG) ? cloneAuctionId : auctionId
        axios.get(`${GETAUCTIONRESULTCONFIG}?auctionId=${parseInt(aId)}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                dispatch(loader(false))
                return;
            }
            else {
                const auctionConfigData = response?.data?.data;
                const updatedFormValues: { [key: string]: boolean } = {};
                auctionConfigData.forEach((config: any) => {
                    updatedFormValues[config.settingKey] = config.settingValue.toLowerCase() === 'true';
                });
                setFormValues(updatedFormValues);
                reset(updatedFormValues);
            }
            dispatch(loader(false));
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    useEffect(() => {
        getAuctionConfigData();
    }, [])

    // to render btn txt
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUCTIONRESULTSCONFIG)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUCTIONRESULTSCONFIG)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3 align-items-center mb-3">
                    <div className="col-lg-4">
                        <label>Show Bid History</label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="bidHistoryCheck"
                                {...register('bidHistory')}

                            />
                            <label className="form-check-label" htmlFor="bidHistoryCheck">Bid History</label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <label>Show Serial Number</label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="serialNumberCheck"
                                {...register('serialNumber')}

                            />
                            <label className="form-check-label" htmlFor="serialNumberCheck">Serial Number</label>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <label>Show Seller Contact</label>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="sellerContactInfoCheck"
                                {...register('sellerContactInfo')}

                            />
                            <label className="form-check-label" htmlFor="sellerContactInfoCheck">Seller Contact</label>
                        </div>
                    </div>

                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary ms-2" type="submit" data-bs-dismiss="modal" >{btnTxt}</button>
                    <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                </div>
            </form>
        </>
    )
}

export default AuctionResultsConfig;
