import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setAuctionInfoActiveTab, selectAuction } from "../../../../store/actions/SalesRepAction";
import auctionImage from "../../../../assets/Group 70.svg";
import Select from 'react-select';
import { GETAUCIONTALLYBYSALESREP, GETAUCTIONBYSALESREP } from "../../../../common/apiConstatnts";
import axios from "axios";
import { toast } from "react-toastify";
import SalesInAuction from "./SalesInAuction"
import Commission from "./Commission"
import Adjust from "./Adjust"
import Payment from "./Payment"
import Logs from "./Logs"
import InventoryAssociated from "./Inventory";
import { formatCurrency, formatNumber, isEmptyObject } from "../../../../Utils/Utils";
import Swal from 'sweetalert2';
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import AuctionContactInfo from "./AuctionContactInfo";
import { decryptId } from "../../../../Encryption/Encryption";

const AuctionInfoTabs = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const dispatch = useDispatch()
    // const auctionlistOptions = useSelector((state: any) => state.salesRep.salesRepAuctions)
    const [auctionlistOptions, setAuctionlistOptions] = useState([])
    const [loading, setLoading] = useState<boolean>(true);
    const [auctionTallyData, setAuctionTallyData] = useState<any>({});
    const [isFirstTimeRender, setFirstTimeRender] = useState(true)
    //used for getting auctionid and data selected from hammer
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const [selectedAuction, setSelectedAuction] = useState(getGlobalAuctionIds ? getGlobalAuction : auctionlistOptions?.[0]);
    const salesRepData = useSelector((state: any) => state.salesRep);
    const activeTab = salesRepData.AuctionInfoActiveTab
    const salesRepId = salesRepData.saleRepId
    const { inventoryLots, salesLots, sold, passes, buyBack, totalAmount } = auctionTallyData;
    const auctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);

    const handleChange = (key: string) => {
        dispatch(setAuctionInfoActiveTab(key))
    }

    const auctionSelectHandler = (e: any) => {
        setSelectedAuction(e)
        dispatch(selectAuction(e))
    }


    useEffect(() => {
        getSalesRepAuctions()
        return () => { }
    }, [])

    const getSalesRepAuctions = () => {
        axios.get(`${GETAUCTIONBYSALESREP}?salesRepId=${decryptId(ID)}`).then((response) => {
            setLoading(false);
            setFirstTimeRender(false)
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const auctionData = response?.data?.data
                let auctionlistOptions = auctionData ? auctionData?.map((i: any) => { return { label: `${i?.auctionCode} - ${i?.auctionTitle}`, value: i?.auctionId } }) : []
                const ids = auctionlistOptions?.map(({ value }: any) => value);
                auctionlistOptions = auctionlistOptions?.filter(({ value }: any, index: any) => value !== 0 && !ids.includes(value, index + 1));
                // dispatch(salesRepAuctions(auctionlistOptions))
                setAuctionlistOptions(auctionlistOptions)

            }
        }).catch((error) => {
            setLoading(false);
            // dispatch(loader(false))
            handleError(error);
        })
    }

    useEffect(() => {

        if (!isEmptyObject(auctionDetail)) {
            const valueFound = auctionlistOptions?.find((i: any) => i?.value == auctionDetail?.auctionId)

            if (valueFound) {
                setSelectedAuction(valueFound)
                dispatch(selectAuction(valueFound))
            } else {
                if (auctionlistOptions?.length) {
                    Swal.fire({
                        text: 'Sorry, this sales rep is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }

        } else if (getGlobalAuctionIds) {
            const matches = auctionlistOptions?.filter((option: any) => {
                return option?.value == getGlobalAuctionIds;
            });
            //If the auction is in the - Auction dropdown, it will be selected by default. 
            //else show swal msg if there's any option in auction list
            if (matches?.length > 0) {
                setSelectedAuction(getGlobalAuction);
                dispatch(selectAuction(getGlobalAuction))
            } else {
                if (auctionlistOptions?.length) {
                    Swal.fire({
                        text: 'Sorry, this sales rep is not associated with selected auction',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#006800',
                    });
                }
            }
        } else {
            const valueFound = auctionlistOptions?.[0]
            setSelectedAuction(valueFound)
            dispatch(selectAuction(valueFound))
        }
    }, [getGlobalAuctionIds, getGlobalAuction, auctionlistOptions, auctionDetail]);


    const getAuctionTallyData = (aucId: any, salesRepId: any) => {
        setLoading(true)
        axios.get(`${GETAUCIONTALLYBYSALESREP}?auctionId=${aucId}&salesRepsId=${salesRepId}`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data ? response.data.data : {};
                setAuctionTallyData(data);
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    useEffect(() => {
        if (auctionlistOptions?.length) {
            if (selectedAuction?.value) {
                auctionSelectHandler(selectedAuction);
                getAuctionTallyData(selectedAuction?.value, salesRepId);
            }
            dispatch(setAuctionInfoActiveTab("contactInfo"));
        }
    }, [selectedAuction])


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            {!isFirstTimeRender ? <div className="pt-0 top_border">
                {auctionlistOptions === undefined || auctionlistOptions?.length == 0 ? <>
                    <div className="d-flex align-items-center justify-content-center flex-wrap h-61vh verti_center">
                        <div className="auvtoin_immg">
                            <img src={auctionImage} />
                        </div>
                        <span className="d-block w-100 text-center mt-4 fs-20">Oops! This Sales Representative has no auction data.</span>
                    </div>
                </>
                    :
                    <>
                        <div className="col-12">
                            <div className="row pt-5 m-0 justify-content-center">
                                <div className="col-xxl-10">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-5 mb-2">
                                                    <label className="">Select Auction</label>
                                                    <Select classNamePrefix="dpaSelect" placeholder="Select Auction" className="w-100"
                                                        options={auctionlistOptions}
                                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                                        value={selectedAuction}
                                                        onChange={(e) => auctionSelectHandler(e)}
                                                    />
                                                </div>
                                                <div className="col-lg-7 mb-2">
                                                    <div className="auction_tally_box">
                                                        <label>Auction Tally</label>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                                                    <table className="table fs--1 mb-0 border border-200">
                                                                        <tbody className="">
                                                                            <tr>
                                                                                <th className="ps-2 pe-2 border">Inventory Lots</th>
                                                                                <th className="ps-2 pe-2 border">Total Lots from this sales rep</th>
                                                                                <th className="ps-2 pe-2 border">Sold</th>
                                                                                <th className="ps-2 pe-2 border">Passed</th>
                                                                                <th className="ps-2 pe-2 border">Buyback</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="ps-2 pe-2 border">{inventoryLots ?? '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{salesLots ? formatNumber(salesLots) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{sold ? formatNumber(sold) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{passes ? formatNumber(passes) : '0'}</td>
                                                                                <td className="ps-2 pe-2 border">{buyBack ? formatNumber(buyBack) : '0'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="ps-2 pe-2 border text-end" colSpan={5}>Sold USD : <strong>{totalAmount ? formatCurrency(totalAmount) : '$0.00'}</strong> </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <InnerHeaderTab breadCrumb={breadcrumbData} title={ID ? 'Edit Seller' : 'New Seller'}> */}
                                    <Tabs
                                        defaultActiveKey={activeTab}
                                        activeKey={activeTab}
                                        className="nav-underline border-bottom"
                                        onSelect={(key: any) => handleChange(key)}
                                    >
                                        <Tab eventKey="contactInfo" title="Contact Info" disabled={(salesRepId == undefined)}></Tab>
                                        <Tab eventKey="inventory" title="Inventory" disabled={(salesRepId == undefined)}></Tab>
                                        <Tab eventKey="sales" title="Sales in Auction" disabled={selectedAuction == undefined || salesRepId == undefined}></Tab>
                                        {/* <Tab eventKey="commision" title="Commission" disabled={selectedAuction == undefined || (salesRepId == undefined)}></Tab> */}
                                        <Tab eventKey="adjust" title="Adjusts" disabled={selectedAuction == undefined || (salesRepId == undefined)}></Tab>
                                        <Tab eventKey="payment" title="Payments" disabled={selectedAuction == undefined || (salesRepId == undefined)}></Tab>
                                        <Tab eventKey="logs" title="Adjusts/Payments Logs" disabled={selectedAuction == undefined || (salesRepId == undefined)}></Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        {/* </InnerHeaderTab > */}
                        {
                            (activeTab === 'contactInfo') ?
                                <AuctionContactInfo /> :
                                (activeTab === 'inventory') ?
                                    <InventoryAssociated /> :
                                    (activeTab === 'sales') ?
                                        <SalesInAuction /> :
                                        // (activeTab === 'commision') ?
                                        //     <Commission /> :
                                        (activeTab === 'adjust') ?
                                            <Adjust /> :
                                            (activeTab === 'logs') ?
                                                <Logs />
                                                :
                                                <Payment />

                        }</>}

            </div > : <></>}
        </>
    )
}

export default AuctionInfoTabs