import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const updateSeller = (data: any): Action => ({
    type: action.UPDATESELLER,
    payload: data
})
export const setSellerAdjust = (data: any): Action => ({
    type: action.SETSELLERADJUST,
    payload: data
})

export const setSellerAdjustFromResp = (data: any): Action => ({
    type: action.SETSELLERADJUSTFROMRESP,
    payload: data
})

export const deleteSellerAdjust = (adjustUniqueId: any): Action => ({
    type: action.DELETESELLERADJUST,
    payload: adjustUniqueId,
})

export const updateSellerAdjust = (adjustUniqueId: any, updatedSellerAdjust: any) => ({
    type: action.UPDATESELLERADJUST,
    payload: { adjustUniqueId, updatedSellerAdjust },
})

export const setSellerPayment = (data: any): Action => ({
    type: action.SETSELLERPAYMENT,
    payload: data
})
export const setSellerPaymentResp = (data: any): Action => ({
    type: action.SETSELLERPAYMENTRESP,
    payload: data
})

export const deleteSellerPayment = (paymentUniqueId: any): Action => ({
    type: action.DELETESELLERPAYMENT,
    payload: paymentUniqueId,
})

export const updateSellerPayment = (paymentUniqueId: any, updatedSellerPayment: any) => ({
    type: action.UPDATESELLERPAYMENT,
    payload: { paymentUniqueId, updatedSellerPayment },
})
export const setSellerRadioValue = (data: any): Action => ({
    type: action.SETSELLERRADIOVALUE,
    payload: data
})
export const setSellerRadioValueTwo = (data: any): Action => ({
    type: action.SETSELLERRADIOVALUE,
    payload: data
})

export const addLogNote = (data: any): Action => ({
    type: action.ADDLOGNOTE,
    payload: data,
});

export const deleteLogNote = (sellerLogNotesId: any): Action => ({
    type: action.DELETELOGNOTE,
    payload: sellerLogNotesId,
});

export const updateLogNote = (sellerLogNotesId: any, updatedNote: any) => ({
    type: action.UPDATELOGNOTE,
    payload: { sellerLogNotesId, updatedNote },
});

export const setActiveTab = (key: any) => ({
    type: action.SETACTIVETAB,
    payload: key,
});

export const SellerContact = (data: any) => ({
    type: action.SELLERCONTACT,
    payload: data
})

export const sellerLots = (data: any) => ({
    type: action.SELLERLOTS,
    payload: data
})

export const sellerAuctions = (data: any) => ({
    type: action.SELLERAUCTIONS,
    payload: data
})

export const setAuctionInfoActiveTab = (data: any) => ({
    type: action.SETAUCTIONINFOACTIVETAB,
    payload: data
})

export const selectAuction = (data: any) => ({
    type: action.SELECTAUCTION,
    payload: data
})

export const setContractDocumentData = (data: any): Action => ({
    type: action.SETCONTRACTDOCUMENT,
    payload: data
})

export const setAuctionContractDocumentData = (data: any): Action => ({
    type: action.SETAUCTIONCONTRACTDOCUMENT,
    payload: data
})

export const setSellerId = (data: any): Action => ({
    type: action.SETSELLERID,
    payload: data
})

export const setPrevSellerId = (data: any): Action => ({
    type: action.SETPREVSELLERID,
    payload: data
})

export const setNextSellerId = (data: any): Action => ({
    type: action.SETNEXTSELLERID,
    payload: data
})

export const setTotalSellers = (data: any): Action => ({
    type: action.TOTALSELLERS,
    payload: data
})

export const setSellerIndex = (data: any): Action => ({
    type: action.SELLERINDEX,
    payload: data
})