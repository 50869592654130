import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CommonHelmet from "../../../common/HelmetWrapper";
import { Breadcrumb } from "../../../common/interface";
import * as routeConstant from '../../../common/routeConstants';
import * as actions from '../../../common/types';
import * as AuctionConstants from "../../../components/Auction/AuctionConstants";
import { setStep } from "../../../store/actions/AuctionAction";
import InnerHeaderTab from "../../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../../Sidebar/Sidebar";
import AuctionClose from "./AuctionClose";
import AuctionDetail from './AuctionDetail/AuctionDetail';
import AuctionInfo from "./AuctionInfo";
import CheckList from "./CheckList";
import Einvoice from "./Einvoice";
import Invoices from "./Invoices/index";
import Values from "./Values";
import EmailReports from "./EmailReport";

const NewAuction = () => {
    const location = useLocation();
    const url = window.location.pathname
    const checkMode = url.split('/')[2]
    const splitNewConst = routeConstant.NEWAUCTION.split('/')[2]
    const splitEditConst = routeConstant.EDITAUCTION.split('/')[2]
    const auctionTypeText = (checkMode === splitNewConst) ? 'New Auction' : (checkMode === splitEditConst) ? 'Edit Auction' : 'Clone Auction'

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Auctions', url: routeConstant.AUCTION },
        { label: auctionTypeText, url: routeConstant.NEWAUCTION }
    ]

    const dispatch = useDispatch()
    const activeStep = useSelector((state: any) => state.auction.activeStep)
    const auctionCodeAndTitle = useSelector((state: any) => state.auction.auctionCodeAndTitle)
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedAuctionId = useSelector((state: any) => state.auction.clonedAuctionId);
    const loaderState = useSelector((state: any) => state.global.loading)
    const type = location.state?.type ?? null;
    const typeFromStore = useSelector((state: any) => state.auction.mode);
    const [modeType] = useState<any>(type === null ? typeFromStore : type)
    const [step, setSteps] = useState<number>(activeStep)
    const [loading, setLoading] = useState<boolean>(loaderState)
    const [showEnabled, setShowEnabled] = useState<boolean>(false)

    const handleNext = (nextStep: any) => {
        setSteps(nextStep);
        dispatch(setStep(nextStep))
    };

    useEffect(() => {
        setSteps(activeStep)
    }, [activeStep])

    useEffect(() => {
        setLoading(loaderState)
    }, [loaderState])

    useEffect(() => {
        let checkCondition: boolean
        if (modeType === "create" || modeType === "edit") {
            checkCondition = auctionId > 0 ? true : false
        } else {
            checkCondition = clonedAuctionId > 0 ? true : false
        }
        setShowEnabled(checkCondition)
    }, [auctionId, clonedAuctionId])

    useEffect(() => {
        return () => {
            dispatch({ type: actions.RESETAUCTION })
        }
    }, [])

    return (
        <>
            <CommonHelmet title={"Auction - "} name={"auction"} content={"Auction"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderTab breadCrumb={breadcrumbData} title={auctionTypeText == 'Edit Auction' ? '' : `${auctionTypeText}`}>
                    {auctionTypeText == 'Edit Auction' && (
                        <div className="d-flex flex-wrap gap-2">
                            <h4>{auctionTypeText}</h4>
                            {<span> - ({auctionCodeAndTitle?.auctionCode}) {auctionCodeAndTitle?.auctionTitle}</span>}
                        </div>
                    )}
                    <div className="pt-3">
                        <Tabs defaultActiveKey={step} activeKey={step} className="nav-underline" onSelect={(key) => handleNext(key)}>
                            <Tab eventKey={1} title={AuctionConstants.AUCTIONINFO}></Tab>
                            <Tab eventKey={2} title={AuctionConstants.AUCTIONDETAILS} disabled={!(showEnabled)}></Tab>
                            <Tab eventKey={3} title={AuctionConstants.CHECKLIST} disabled={!(showEnabled)}></Tab>
                            <Tab eventKey={4} title={AuctionConstants.INVOICESETTINGS} disabled={!(showEnabled)}></Tab>
                            <Tab eventKey={5} title={AuctionConstants.EMAILREPORTS} disabled={!(showEnabled)}></Tab>
                            {/* <Tab eventKey={5} title={AuctionConstants.EINVOICES} disabled={!(showEnabled)}></Tab> */}
                            <Tab eventKey={6} title={AuctionConstants.VALUESETTINGS} disabled={!(showEnabled)}></Tab>
                            <Tab eventKey={7} title={AuctionConstants.AUCTIONCLOSE} disabled={!(showEnabled)}></Tab>
                        </Tabs>
                        {/* <ul className="nav nav-wizard scrollbar stepper d-flex border border-300">
                            {
                                stepperData.map((data: any, index) => {
                                    return (
                                        <li className="nav-item" key={index}>
                                            <a
                                                onClick={() => handleNext(data.step)}
                                                className={`nav-link d-flex flex-nowrap align-items-center ${step >= data.step ? 'active' : ''} ${step === data.step ? 'current' : ''} ${(auctionTypeText === 'Edit Auction') ? 'edit-mode' : ''} fw-semi-bold`}
                                            >
                                                <div className="text-center d-flex flex-nowrap align-items-center">
                                                    <span className="nav-item-circle-parent d-flex flex-nowrap align-items-center">
                                                        <img src={step >= data.step ? data.activeIcon : data.icon} className="svgImg" alt="svgIcon" />
                                                        <span className="nav-item-circle ms-2 fs--1">{data.step}</span>
                                                    </span>
                                                    <span className="ms-2 fs--1 white-space-nowrap">{data.label}</span>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul> */}
                    </div>
                </InnerHeaderTab>
                {
                    (step == 1) ? <AuctionInfo /> :
                        (step == 2) ? <AuctionDetail /> :
                            (step == 3) ? <CheckList /> :
                                (step == 4) ? <Invoices /> :
                                    (step == 5) ? <EmailReports /> :
                                        // (step == 5) ? <Einvoice /> :
                                        (step == 6) ? <Values /> :
                                            (step == 7) ? <AuctionClose /> :
                                                <></>
                }
            </div>
        </>
    )
}

export default NewAuction