import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encryptId } from '../../../../Encryption/Encryption';
import { formatNumber } from '../../../../Utils/Utils';
import { GETSALESREPINFOBASEDONAUCTION } from '../../../../common/apiConstatnts';
import { formatPhoneNumber } from "../../../../common/constants";
import { CommonMessages } from '../../../../common/messageConstants';
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionDetailActive, setStep } from '../../../../store/actions/AuctionAction';
import handleError from '../../../../Utils/ApiCommonErrorMessages';
import { ActiveStep } from '../../../../store/actions/SalesRepAction';

const AuctionSalesRep = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filterSalesRep, setFilterSalesRep] = useState([]);
    const [originalSalesRep, setOriginalSalesRep] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [salesRep, setSalesRep] = useState<[]>([]);
    const [searchText, setSearchText] = useState('');
    const auctionId: any = useSelector((state: any) => state.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const PER_PAGE = 10;
    const pageCount = Math.ceil(salesRep.length / PER_PAGE);

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...salesRep];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setSalesRep(data);
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSalesRep(firstSet);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalSalesRep?.filter((x: any) => x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) 
            || x.company?.toLocaleLowerCase()?.includes(keyword) 
            || x.salesRepId?.toString()?.includes(keyword)
            || x.sellerIdCnt?.toString()?.includes(keyword)
            || x.lotCnt?.toString()?.includes(keyword)
            || x.mobileNo?.toString()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }

    const handlePrev = () => {
        dispatch(setAuctionDetailActive({ activeLabel: "AuctionSales" }))
    }
    const handleNext = () => {
        dispatch(setStep(3))
    }

    const getAuctionBasedOnSaleRep = () => {
        setLoading(true);
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(`${GETSALESREPINFOBASEDONAUCTION}?&auctionId=${aId}`).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setFilterSalesRep([]);
                return;
            }
            else {
                const SalesRepData = response.data.data;
                setOriginalSalesRep(SalesRepData);
                paginateData(SalesRepData);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    useEffect(() => {
        getAuctionBasedOnSaleRep();
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = salesRep?.slice(offset, offset + PER_PAGE);
        setFilterSalesRep(nextSet);
    }, [currentPage]);

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="row">
                <div className="col-md-6">
                    <h5 className='mt-4'>Auction Sales Representatives</h5>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <div className="search-box mt-2 p-2 ">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                <span className="fas fa-search search-box-icon"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 border-300">
                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                <table className="table fs--1 mb-0 border-top border-200">
                                    <thead>
                                        <tr>
                                            <th className="bg-gradiant" scope="col" data-sort="salesRepId">
                                                <div className='d-flex align-items-center'>
                                                    <span>Sales Rep Id</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepId')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepId')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="sellerIdCnt">
                                                <div className='d-flex align-items-center'>
                                                    <span>Seller Count</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerIdCnt')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerIdCnt')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="lotCnt">
                                                <div className='d-flex align-items-center'>
                                                    <span>Lot Count</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotCnt')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotCnt')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="firstname">
                                                <div className='d-flex align-items-center'>
                                                    <span>First Name</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="lastName">
                                                <div className='d-flex align-items-center'>
                                                    <span>Last Name</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'lastName')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'lastName')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="company">
                                                <div className='d-flex align-items-center'>
                                                    <span>Company</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'company')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'company')}></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="mobileNo">
                                                <div className='d-flex align-items-center'>
                                                    <span>Phone No</span>
                                                </div>
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-latest-review-body">
                                        {filterSalesRep.length > 0 ? filterSalesRep.map((salesRep: any, index: any) => {
                                            return (
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>

                                                    <td className="align-middle review p-2">
                                                        <Link className="btn text-decoration-underline m-0 p-0 fw-normal text-start" data-bs-dismiss="modal"
                                                            to={`${routeConstant.SALESREPRESENTATIVEMOREINFO}/${encryptId(salesRep.salesRepId)}`}
                                                            target="_blank"
                                                            onClick={() => {
                                                                dispatch(ActiveStep('auctionInfo'));
                                                            }}
                                                        >
                                                            {salesRep.salesRepId ? salesRep.salesRepId : "-"}
                                                        </Link>
                                                    </td>
                                                    <td className="align-middle review p-2">{salesRep.sellerIdCnt ? formatNumber(salesRep.sellerIdCnt) : "-"}</td>
                                                    <td className="align-middle review p-2">{salesRep.lotCnt ? formatNumber(salesRep.lotCnt) : "-"}</td>
                                                    <td className="align-middle  p-2">{salesRep.firstName ? salesRep.firstName : "-"}</td>
                                                    <td className="align-middle  p-2">{salesRep.lastName ? salesRep.lastName : "-"}</td>
                                                    <td className="align-middle review p-2">{salesRep.company ? salesRep.company : "-"}</td>
                                                    <td className="align-middle  p-2">{salesRep.mobileNo ? formatPhoneNumber(salesRep.mobileNo) : "-"}</td>

                                                </tr>
                                            )
                                        }) :
                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                <td className='text-center p-4' colSpan={13}>No records found!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {filterSalesRep.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}
export default AuctionSalesRep;