import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from "moment";
import { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { Dropdown, Modal } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
// import ReactPDF, { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import * as yup from 'yup';
import auction_white from "../../assets/dashboard/auction_white.svg";
import moreOption from "../../assets/dashboard/more_option.svg";
import noAuctionselected from "../../assets/dashboard/no_auction_selected.svg";
import arrow from "../../assets/sidebar-icons/arrow.svg";
import * as apiConstants from "../../common/apiConstatnts";
import { GETALLBIDDERCONFIG, GETALLINVOICE } from "../../common/apiConstatnts";
import Helper from '../../common/helpers';
import { AuctionMessages, CommonMessages, DashboardMessages, errorMessages } from '../../common/messageConstants';
import { INTEGER, INTEGER_MESSAGE } from '../../common/regexConstants';
import * as routeConstants from "../../common/routeConstants";
import { encryptId } from "../../Encryption/Encryption";
import { setDateRangeData, setSearchData } from '../../store/actions/DashboardActions';
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../Utils/useDateTime";
import { ERROR_MESSAGES, formatCurrency, formatNumber } from '../../Utils/Utils';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { IconAuction2, IconBoxArchive, IconDollar, Iconhammer, IconInvoice2, IconPaper, IconPeople, IconPeopleCircle, IconShapes, IconShop, IconStats } from '../SvgIcons/SvgIcons';
import { useTour } from "@reactour/tour";
import handleError from "../../Utils/ApiCommonErrorMessages";
import jsPDF from "jspdf";
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { buyersPremium } from "../../common/constants";
import CommonHelmet from "../../common/HelmetWrapper";

const Feature = () => {
    const dateTime = useCurrentDateTime()
    // const [sidebarToggle, setSidebarToggle] = useState(true)
    const [permission, setPermission] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [countData, setCountData] = useState<any>([]);
    const [auctionData, setAuctionData] = useState<any>([]);
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>({});
    const [ID, setID] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterAuctions, setFilterAuctions] = useState([]);
    const [AuctionChartData, setAuctionChartData] = useState<any>([]);
    const [CustomerChartData, setCustomerChartData] = useState<any>([]);
    const [SalesChartData, setSalesChartData] = useState<any>([]);
    const [InventoryChartData, setInventoryChartData] = useState<any>([]);
    const [SellingCategories, setSellingCategories] = useState<any>([]);
    const [auctionOverViewData, setAuctionOverViewData] = useState<any>({});
    // Dynamic chart data for invoice
    const [chartData, setChartData] = useState({
        series: [0, 0, 0], // Initial data for Paid, Partial Paid, Pending
        labels: ['Paid', 'Partial Paid', 'Pending'], //Labels 
    });
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const momentUtil = MomentUtil.getInstance();
    // const currentUTC = new Date();
    const currentDate: any = momentUtil.parseTimeInUtc({
        dateTimeString: dateTime?.currentDateTime,
    });
    const currentUTCDate = moment.utc(currentDate).format("MM/DD/YYYY h:mm A");
    // API call for get all invoices
    const getAllInvoices = () => {
        setLoading(true);
        axios.get(`${GETALLINVOICE}?pageNumber=-1`, config).then(
            (response: any) => {
                if (response?.data?.data?.success === "Fail") {
                    toast.error(response?.data?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setLoading(false);
                    return;
                } else {
                    const data = response?.data?.data?.data;
                    // Get all invoice count 
                    const totalRecords = response?.data?.data?.totalRecords
                    localStorage.setItem('invoiceTotalRecord', totalRecords)
                    // Process data to get counts for each payment status
                    const paidCount = data.filter((invoice: any) => invoice.strStatus === 'FullyPaid').length;
                    const partialPaidCount = data.filter((invoice: any) => invoice.strStatus === 'PartialPaid').length;
                    // I have taken status pending/null or 0 as a pending in invoice
                    const pendingCount = data.filter((invoice: any) => invoice.strStatus === 'Pending').length;
                    // Update chart data
                    setChartData({
                        series: [paidCount, partialPaidCount, pendingCount],
                        labels: ['Paid', 'Partial Paid', 'Pending'],
                    });
                    setLoading(false);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
    };

    //   USeEffect to get invoices list
    useEffect(() => {
        getAllInvoices()
    }, [])
    const primaryColor = useSelector((state: any) => state.theme.themeData.themeColor);
    const colorMode = useSelector((state: any) => state.theme.colorMode);

    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()

    const initialDates = {
        fromDate: moment(moment().subtract(90, 'days')).format('MM/DD/YYYY'),
        toDate: moment(moment().add(30, 'days')).format('MM/DD/YYYY')
    }

    const PER_PAGE = 5;
    const pageCount = Math.ceil(auctionData?.length / PER_PAGE);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setIsOpen } = useTour();
    const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);

    const schema: any = yup.object().shape({
        // sbName: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }),
        // sbId: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(INTEGER, INTEGER_MESSAGE),
        // sbDate: yup.string().nullable()
    });

    const {
        handleSubmit,
        register,
        getValues,
        control,
        watch,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: { sbName: '', sbId: '', sbDate: '' }, mode: 'onChange' })

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
    const sbDate = watch('sbDate');
    const getByData = [
        {
            nameLabel: 'Auction Title',
            idLabel: 'Auction ID',
            label: 'Search Auction',
            URL: apiConstants.AUCTIONADVANCESEARCH,
            route: routeConstants.EDITAUCTION,
            listRoute: routeConstants.AUCTION,
            idKey: 'auctionId'

        },
        {
            nameLabel: 'Buyer Name',
            idLabel: 'Buyer ID',
            label: 'Search Buyer',
            URL: apiConstants.ADVANCEFILTERCUSTOMERS,
            route: routeConstants.CUSTOMERSMOREINFO,
            listRoute: routeConstants.CUSTOMERS,
            idKey: 'customerId'


        },
        {
            nameLabel: 'Sales Name',
            idLabel: 'Sales ID',
            label: 'Search Sales',
            URL: apiConstants.ADVANCEFILTERSALESRECORD,
            route: routeConstants.SALESMOREINFO,
            listRoute: routeConstants.SALESRECORDS,
            idKey: 'salesRecordId'
        },
        {
            nameLabel: 'Seller Name',
            idLabel: 'Seller ID',
            label: 'Search Seller',
            URL: apiConstants.SELLERADVANCEFILTER,
            route: routeConstants.SELLERMOREINFO,
            listRoute: routeConstants.SELLER,
            idKey: 'sellerId',
        },
        {
            nameLabel: 'Invoice Name',
            idLabel: 'Invoice ID',
            label: 'Search Invoice',
            URL: apiConstants.ADVANCESEARCHINVOICE,
            route: routeConstants.UPDATEINVOICE,
            listRoute: routeConstants.INVOICE,
            idKey: 'invoiceId'

        },
    ]

    const getDataByIdAPI = async (modalData: any, id: any, params: any, searchData: any) => {
        setLoading(true)
        // return
        axios.get(modalData.URL, { ...config, params }).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            const data = response?.data?.data?.data;
            if (data?.length === 0) {
                reset()
                setToggleValue(false)
                setModalData({})
                toast.error(errorMessages.dataNotFound, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            if (data?.length === 1) {
                if (modalData?.route) {
                    navigate(`${modalData.route}/${encryptId(data[0][`${id.key}`])}`)
                }
                reset()
                setToggleValue(false)
                setModalData({})
                toast.success(DashboardMessages.successMessages, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            if (data?.length > 1) {
                dispatch(setSearchData({ id: searchData?.sbId, name: searchData?.sbName, date: searchData?.sbDate }))
                navigate(modalData.listRoute)
                reset()
                setToggleValue(false)
                setModalData({})
                toast.success(DashboardMessages.successMessages, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })

    }

    const convertToDateOnlyFormat = (dateTimeString: any) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const submitHandler = (modalData: any) => {
        let data: any = getValues();
        const newDate = sbDate ? convertToDateOnlyFormat(sbDate) : '';
        const convertedDate = sbDate ? convertToDateOnlyFormat(sbDate) : '';
        let params: any = {
            pageNumber: 1,
            startDateTime: sbDate ? `${newDate} 00:00:00` : '',
            endDateTime: sbDate ? `${newDate} 23:59:59` : '',
            isActive: -1,
        }

        let id = { key: modalData.idKey, value: data.sbId ? encryptId(data?.sbId) : '' }
        if (modalData?.nameLabel.includes('Customer')) {
            params = {
                ...params,
                firstName: data?.sbName ?? '',
                customerId: data?.sbId ?? '',
            }
        }
        if (modalData?.nameLabel.includes('Seller')) {
            params = {
                ...params,
                sellerId: data.sbId ?? '',
                firstName: data?.sbName ?? '',
            }
        }
        if (modalData?.nameLabel.includes('Sales')) {
            params = {
                ...params,
                salesRecordId: data.sbId ?? '',
                titleName: data?.sbName ?? '',
            }
        }
        if (modalData?.nameLabel.includes('Auction')) {
            params = {
                ...params,
                auctionId: data?.sbId ?? '',
                auctionTitle: data?.sbName ?? '',
            }
        }
        if (modalData?.nameLabel.includes('Invoice')) {
            params = {
                ...params,
                invoiceMasterId: data?.sbId ?? '',
                companyName: data?.sbName ?? '',
            }
        }

        data.sbDate = convertedDate;
        //common function for advanced search API handler used for Search Menu
        modalData.URL && getDataByIdAPI(modalData, id, params, data)
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        if (data?.length) {
            setAuctionData(data)
            const firstSet = data?.slice(0, PER_PAGE);
            setFilterAuctions(firstSet)
        }
    }

    const checkCurrentAuction = (startDate?: any, endDate?: any) => {
        const currentDate: any = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime }).format('YYYY-MM-DDTHH:mm:ss');
        // const currentDate = moment(moment.utc(new Date())).format('YYYY-MM-DDTHH:mm:ss')
        const testStartDate = moment(moment.utc(new Date(startDate))).format('YYYY-MM-DDTHH:mm:ss')
        const testEndDate = moment(moment.utc(new Date(endDate))).format('YYYY-MM-DDTHH:mm:ss')
        return ((Date.parse(testStartDate) - Date.parse(currentDate)) < 0 && (Date.parse(testEndDate) - Date.parse(currentDate)) > 0)
    }

    const getAllAuctions = async () => {
        setLoading(true)
        // this is for filter & sort auction data current Date to Future date wise.
        const currentDate = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime }).format('YYYY-MM-DDT00:00:00')
        const futureDate = moment(moment.utc(currentDate).add(30, 'days')).format('YYYY-MM-DDT00:00:00')

        await axios.get(apiConstants.AUCTIONADVANCESEARCH, { ...config, params: { startDateTime: currentDate, endDateTime: futureDate, isActive: -1, pageNumber: 1 } }).then((response) => {
            setLoading(false)
            if (response?.data?.data?.data?.length > 0) {
                const SortedAuctionData = response?.data?.data?.data?.slice(0, 5).map((i: any) => {
                    return {
                        ...i,
                        dateValue: Date.parse(i.startDateTime) - Date.parse(currentDate),
                        isLive: checkCurrentAuction(i?.startDateTime, i?.endDateTime)
                    }
                }).sort((a: any, b: any) => (a.dateValue > b.dateValue) ? 1 : -1)
                paginateData(SortedAuctionData ?? [])
            } else {
                setAuctionData([])
                setFilterAuctions([])
            }

        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    const countConst = [
        {
            label: 'Auctions',
            smallLabel: 'Auctions',
            count: countData.cntAuction,
            link: routeConstants.AUCTION
        },
        {
            label: 'Buyers',
            smallLabel: 'Buyers',
            count: countData.cntCustomer,
            link: routeConstants.CUSTOMERS
        },
        {
            label: 'Sales',
            count: countData.cntSales,
            link: routeConstants.SALESRECORDS
        },
        {
            label: 'Inventory',
            smallLabel: 'Inventory',
            count: countData.cntInventory,
            link: routeConstants.INVENTORY
        },
        {
            label: 'Invoices',
            count: countData.cntInvoice,
            link: routeConstants.INVOICE
        },
        {
            label: 'Best Selling',
            smallLabel: 'Best Selling Categories',
            count: countData.cntBestSellingCategory,
            link: ''
        },
    ]
    const getAllCounts = async (params?: any) => {
        setLoading(true)
        await axios.get(apiConstants.GETALLCOUNT, params ? { ...config, params } : config).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setCountData([]);
                return;
            }
            else {
                setCountData(response?.data?.data)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = auctionData.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = auctionData.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName);
    }

    const getAllChartData = async (params?: any) => {
        setLoading(true)
        await axios.get(apiConstants.GETAUCTIONCHARTDATA, params ? { ...config, params } : config).then((response: any) => {
            setAuctionChartData(response?.data?.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
        await axios.get(apiConstants.GETCUSTOMERCHARTDATA, params ? { ...config, params } : config).then((response: any) => {
            setCustomerChartData(response?.data?.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
        await axios.get(apiConstants.GETSALESCHARTDATA, params ? { ...config, params } : config).then((response: any) => {
            setSalesChartData(response?.data?.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
        await axios.get(apiConstants.GETINVENTORYCHARTDATA, params ? { ...config, params } : config).then((response: any) => {
            setInventoryChartData(response?.data?.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
        await axios.get(apiConstants.GETSELLINGCATEGORIES, params ? { ...config, params } : config).then((response: any) => {

            setSellingCategories(response?.data?.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }

    //for date range picker

    const handleDateRangePicker = async (start: any, end: any) => {
        const range = { fromDate: moment(start).format('MM/DD/YYYY').toString(), toDate: moment(end).format('MM/DD/YYYY').toString() }
        const params = { fromDate: range.fromDate, toDate: range.toDate }
        dispatch(setDateRangeData({ ...params, default: true }))
        getAllCounts(params)
        getAllChartData(params)
    }

    const handleDeleteModal = (auctionId: string, type: string) => {
        if (!permission.isAllowDelete) {
            Helper.unAuthorized()
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                axios.post(`${apiConstants.DELETEAUCTION}?auctionId=${auctionId}`, {}, config).then((response) => {
                    setLoading(false)
                    if (response?.data?.code !== 200) {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    } else {
                        getAllAuctions();
                        toast.success(AuctionMessages.indexPageMessages.deleteAuction, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        setCurrentPage(0)
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error)
                })
            }
        })
    }

    const CountCardHandler = async (link: any) => {
        await dispatch(setDateRangeData({ ...dateRangeData, default: false }));
        navigate(link);
    }

    const handleEdit = (id: any) => {
        if (!permission.isAllowEdit) {
            Helper.unAuthorized()
            return
        }
        navigate(`${routeConstants.EDITAUCTION}/${encryptId(id)}`)
    }

    const chartText = () => {
        if (colorMode == 'light') {
            return '#141824'
        } else {
            return '#e3e6ed'
        }
    }


    const getAuctionOverViewReport = (auctionId: any) => {
        setLoading(true);
        axios.get(`${apiConstants.GETAUCTIONOVERVIEWREPORT}?auctionId=${auctionId}`).then((response) => {
            setLoading(false);
            const overviewData = response?.data?.data;
            setAuctionOverViewData(overviewData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    useEffect(() => {
        if (getGlobalAuctionIds) {
            getAuctionOverViewReport(getGlobalAuctionIds);
        }
    }, [getGlobalAuctionIds]);

    useEffect(() => {
        handleDateRangePicker(initialDates.fromDate, initialDates.toDate)
        getAllAuctions()
        axios.get(apiConstants.GETMODULEWITHROLE, config).then((response) => {
            const permissionData = JSON.stringify(response.data.data)
            localStorage.setItem('permission', permissionData)
            const getPermission: any = Helper.decodePermissions()?.find((data: any) => data.moduleName === 'Auction')
            setPermission(getPermission)
        })
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = auctionData?.slice(offset, offset + PER_PAGE)
        setFilterAuctions(nextSet)
    }, [currentPage])

    // useEffect(() => {
    //     setPrimaryColor(themeData.themeColor)
    // }, [themeData.themeColor])

    useEffect(() => {
        axios.get(GETALLBIDDERCONFIG).then((response) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else if (response?.data?.data) {
                let bidderConfigData = response?.data?.data;
                const timeZoneSetting = bidderConfigData.find(
                    (setting: any) => setting.settingKey === "liveBoardTimeZone"
                );
                const timeZoneId = bidderConfigData.find(
                    (setting: any) => setting.settingKey === "timeZoneIdentifier"
                );
                const timeZoneIdentifier: any = timeZoneId?.settingValue
                    ? timeZoneId?.settingValue
                    : "America/Chicago";
                const liveBoardTimeZone: any = timeZoneSetting?.settingValue
                    ? timeZoneSetting?.settingValue
                    : "CDT";
                localStorage.setItem(
                    "timeZoneIdentifier",
                    timeZoneIdentifier
                );
                MomentUtil.getInstance().setTimezone(timeZoneIdentifier);
                MomentUtil.getInstance().setDefaultSourceTimezone(
                    timeZoneIdentifier
                );
                axios
                    .get(apiConstants.GETTIMEZONEIDENTIFIER, config)
                    .then((response: any) => {
                        // Set response in time zone state
                        if (response?.data?.data) {
                            response.data.data?.map(
                                (data: any, index: any) => {
                                    if (data.timeZoneIdentifierId == liveBoardTimeZone) {
                                        localStorage.setItem("liveBoardTimeZone", data?.timeZone);
                                    }
                                }
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
        //this is for set focus on header - auction hammer while login.
        // const tourShownBefore = localStorage.getItem("tourShownBefore");

        // if (!tourShownBefore) {
        //     setIsOpen(true);
        //     localStorage.setItem("tourShownBefore", "true");
        // }

    }, [])

    const generatePDF = () => {
        // doc.text is used to add the labels and the values.
        // doc.rect is used to draw the rectangles around the values to make them look like input fields.
        const doc: any = new jsPDF('p', 'pt', 'a4');
        const boxWidth = 100;
        const margin = 20;
        // Header section 
        // Header 
        const pageWidth = doc.internal.pageSize.width;
        const img = new Image();
        img.src = dpa_logo;
        doc.addImage(img, "SVG", 10, 30, 75, 30);

        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(auctionOverViewData?.companyName ?? '', 100, 30, { fontStyle: 'bold' });
        doc.text(auctionOverViewData?.auctionTitle ?? '', 100, 45, { fontStyle: 'bold' });

        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.text(auctionOverViewData?.auctionAddress ?? '', 100, 60);
        doc.text(`${auctionOverViewData?.cityName},${auctionOverViewData?.stateName}`, 100, 75);

        const rightAlignX = pageWidth - margin;
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text(` Auction Overview`, rightAlignX, 30, { align: 'right', fontStyle: 'bold' });

        doc.setFont("helvetica", "normal");
        doc.setFontSize(14);
        doc.text(`Auction #${auctionOverViewData?.auctionCode}`, rightAlignX, 45, { align: 'right' });

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text(moment(auctionOverViewData?.auctionDate).format('MM/DD/YYYY'), rightAlignX, 60, { align: 'right' });

        // Draw a horizontal line below the header
        doc.setLineWidth(0.5); // Set line width
        doc.line(10, 90, pageWidth - margin, 90); // Draw line from (10, 90) to (pageWidth - 10, 90)

        // row 1
        doc.setFontSize(10);
        doc.text('Invoiced Item Total:', 40, 114);
        // 140 - x axios , 92 - y axis , 80 - width
        doc.rect(130, 100, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.invoicedItemTotal ?? 0), 135, 114);

        doc.setFontSize(10);
        doc.text('Item Tax:', 285, 114);
        doc.rect(330, 100, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.itemTax ?? 0), 335, 114);

        doc.setFontSize(10);
        doc.text(`(${formatCurrency(auctionOverViewData?.itemtaxable ?? 0)} taxable)`, 432, 114);

        // row 2
        doc.setFontSize(10);
        doc.text('Buyers Premium:', 50, 138);
        doc.rect(130, 125, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.buyersPremium ?? 0), 135, 138);

        doc.setFontSize(10);
        doc.text('BP Tax:', 290, 138);
        doc.rect(330, 125, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.bpTax ?? 0), 335, 138);

        doc.setFontSize(10);
        doc.text(`(${formatCurrency(auctionOverViewData?.bPtaxable ?? 0)} taxable)`, 432, 138);

        // row 3
        doc.setFontSize(10);
        doc.text('Sub-total:', 85, 163);
        doc.rect(130, 150, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.subTotal ?? 0), 135, 163);

        doc.setFontSize(10);
        doc.text('Total Tax:', 282, 163);
        doc.rect(330, 150, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.totalTax ?? 0), 335, 163);

        // Line between "Invoiced Item Total" and "Item Tax"
        // Draw a horizontal line below row 3
        doc.setLineWidth(0.5); // Set line width
        // doc.line(175, 168, 380, 168); // Draw line from (140, 158) to (420, 158)
        // Draw a vertical line
        doc.line(175, 180, 380, 180);
        doc.line(175, 170, 175, 180); // Draw vertical line from (180, 125) to (180, 168)
        doc.line(380, 170, 380, 180); // Increased x-coordinate for the second vertical line
        doc.line(280, 180, 280, 187);

        // center row
        doc.setFontSize(10);
        doc.text('Invoice & Tax Sub-Total:', 116, 202);
        doc.rect(230, 188, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.invoiceandSubTotal ?? 0), 235, 202);
        doc.line(280, 209, 280, 212);
        doc.text('Invoice Adjustments:', 134, 227);
        doc.rect(230, 213, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.invoiceadjustments ?? 0), 235, 227);
        doc.line(280, 234, 280, 250);

        doc.line(280, 250, 350, 250);

        doc.text('Invoice Total:', 368, 251);
        doc.rect(430, 238, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.invoiceTotal ?? 0), 435, 252);

        doc.text('Sold - Not Invoiced:', 340, 275);
        doc.rect(430, 262, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.soldNotInvoiced ?? 0), 435, 276);

        doc.text('Total Auction Proceeds:', 321, 297);
        doc.rect(430, 285, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.totalAuctionProceeds ?? 0), 435, 299);

        doc.line(285, 320, pageWidth - margin, 320);

        doc.text('Total Passed Lots:', 74, 344);
        doc.rect(160, 330, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.totalPassedLots ?? 0), 165, 344);

        // Define the text and positions
        const text = 'Item Total Invoice and not Invoiced (no BP/Tax):';
        const valueText = formatCurrency(
            auctionOverViewData?.notInvoiceNoBPTax ? auctionOverViewData?.notInvoiceNoBPTax : "0"
        );
        const startX = 322;
        const startY = 340;
        const boxX = 430;
        const boxY = startY - 10;
        const boxHeight = 20;
        const maxWidth = boxX - startX - 10; // Adjust for padding

        // Split the text into lines
        const lines = doc.splitTextToSize(text, maxWidth);
        // Draw the wrapped text
        lines.forEach((line: any, index: any) => {
            doc.text(line, startX, startY + (index * 10));
        });
        // Draw the box
        doc.rect(boxX, boxY, boxWidth, boxHeight);
        // Draw the value text inside the box
        doc.text(valueText, boxX + 5, startY + 4);

        // 2 Tables for PATTYPE AND LOT SUMMARY

        const startTableY = 400;
        const startTableX = margin;
        const paymentRows: any = [];
        const headers = [
            { header: "Pay Type" },
            { header: "Invoice Only" },
        ];
        let payType = '';
        let invoiceAmount = '';

        auctionOverViewData?.lstpaymenttype?.forEach((item: any, index: any) => {
            payType = item?.paymentType;
            invoiceAmount = formatCurrency(item.invoiceAmount || 0);

            paymentRows.push([
                { content: payType, styles: { halign: 'left' } },
                { content: invoiceAmount, styles: { halign: 'right' } },
            ]);
        })
        // Commented for now
        /* paymentRows.push([
            { content: '', styles: { lineWidth: { left: 0, right: 0, top: 1, bottom: 0 } } },
            { content: 'Invoice Only', styles: { lineWidth: { left: 0, right: 0, top: 1, bottom: 0 } } },
        ]);
        paymentRows.push([
            { content: ' All Types', styles: { halign: 'left', } },
            { content: formatCurrency(auctionOverViewData?.allTypes ?? 0), styles: { halign: 'right' } },
        ]); */

        doc.autoTable({
            startY: startTableY,
            startX: startTableX,
            head: [headers.map(col => col.header)],
            body: paymentRows,
            showHead: 'everyPage',
            margin: { left: margin + 10, right: margin },
            tableWidth: doc.internal.pageSize.width * 0.5,
            theme: 'grid',
            pageBreak: 'auto',
            rowPageBreak: 'auto',
            styles: {
                fontSize: 8,
                // cellPadding: 3,
                overflow: 'linebreak',
                halign: 'left',
                textColor: "#000000"
            },
            headStyles: {
                halign: 'left',
                cellPadding: { top: 6, right: 5, bottom: 6, left: 5 },
                fillColor: "#ffffff",
                textColor: "#000000",
            },
        });

        // Table height for invoice detail table
        const table = doc.lastAutoTable;
        const Height = table.finalY

        doc.text('Payment Total:', 155, Height + 24);
        doc.rect(225, Height + 10, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.paymentTotal ?? 0), 230, Height + 24);

        doc.text('Paid from Deposit:', 140, Height + 48);
        doc.rect(225, Height + 35, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.paidFromDeposite ?? 0), 230, Height + 48);

        doc.text('Balance Due:', 162, Height + 74);
        doc.rect(225, Height + 60, boxWidth, 20);
        doc.text(auctionOverViewData?.balanceDue < 0 ? `(${formatCurrency(Math.abs(auctionOverViewData?.balanceDue))})` : formatCurrency(auctionOverViewData?.balanceDue), 230, Height + 74);

        doc.text(' Invoice Total:', 160, Height + 99);
        doc.rect(225, Height + 85, boxWidth, 20);
        doc.text(formatCurrency(auctionOverViewData?.invoiceTotal ?? 0), 230, Height + 99);

        doc.line(margin, 390, doc.internal.pageSize.width * 0.5 + 40, 390);
        doc.line(margin, Height + 110, doc.internal.pageSize.width * 0.5 + 40, Height + 110);

        doc.line(margin, 390, margin, Height + 110);
        doc.line(doc.internal.pageSize.width * 0.5 + 40, 390, doc.internal.pageSize.width * 0.5 + 40, Height + 110);

        // Second table 
        doc.line(doc.internal.pageSize.width * 0.5 + 50, 390, doc.internal.pageSize.width - margin, 390);
        doc.line(doc.internal.pageSize.width * 0.5 + 50, Height + 220, doc.internal.pageSize.width - margin, Height + 220);

        doc.line(doc.internal.pageSize.width * 0.5 + 50, 390, doc.internal.pageSize.width * 0.5 + 50, Height + 220);
        doc.line(doc.internal.pageSize.width - 20, 390, doc.internal.pageSize.width - 20, Height + 220);
        const marginLeft = doc.internal.pageSize.width * 0.5 + 175;
        const marginInput = doc.internal.pageSize.width * 0.5 + 220

        // Second section
        doc.text('Lot Count Summary', doc.internal.pageSize.width * 0.5 + 112, startTableY);
        doc.line(doc.internal.pageSize.width * 0.5 + 115, startTableY + 2, doc.internal.pageSize.width * 0.5 + 200, startTableY + 2);

        doc.text('Posted to Sales:', doc.internal.pageSize.width * 0.5 + 100, startTableY + 24);
        doc.rect(marginLeft, startTableY + 10, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.postedToSales ?? 0), marginInput, startTableY + 24);

        doc.text('Total Sales Records:', doc.internal.pageSize.width * 0.5 + 80, startTableY + 48);
        doc.rect(marginLeft, startTableY + 35, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.totalSalesRecord ?? 0), marginInput, startTableY + 48);

        doc.text('Status = Sold:', doc.internal.pageSize.width * 0.5 + 111, startTableY + 74);
        doc.rect(marginLeft, startTableY + 60, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.postedToSold ?? 0), marginInput, startTableY + 74);

        doc.text('Invoiced:', doc.internal.pageSize.width * 0.5 + 133, startTableY + 98);
        doc.rect(marginLeft, startTableY + 85, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.invoiced ?? 0), marginInput, startTableY + 98);

        doc.text('Uninvoiced:', doc.internal.pageSize.width * 0.5 + 120, startTableY + 124);
        doc.rect(marginLeft, startTableY + 110, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.noInvoice ?? 0), marginInput, startTableY + 124);

        doc.text('Total Unsold:', doc.internal.pageSize.width * 0.5 + 114, startTableY + 148);
        doc.rect(marginLeft, startTableY + 135, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.unsold ?? 0), marginInput, startTableY + 148);

        doc.text('Status = Passed:', doc.internal.pageSize.width * 0.5 + 96, startTableY + 174);
        doc.rect(marginLeft, startTableY + 160, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.postedToPassed ?? 0), marginInput, startTableY + 174);

        doc.text('Status = BuyBack:', doc.internal.pageSize.width * 0.5 + 90, startTableY + 198);
        doc.rect(marginLeft, startTableY + 185, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.postedToBuyBack ?? 0), marginInput, startTableY + 198);

        doc.text('Status = Pending:', doc.internal.pageSize.width * 0.5 + 94, startTableY + 223);
        doc.rect(marginLeft, startTableY + 210, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.postedToPending ?? 0), marginInput, startTableY + 223);

        doc.text('Status = <null>', doc.internal.pageSize.width * 0.5 + 100, startTableY + 250);
        doc.rect(marginLeft, startTableY + 235, boxWidth, 20);
        doc.text(formatNumber(auctionOverViewData?.nullInventoryStatusCount ?? 0), marginInput, startTableY + 250);

        // Define the text and positions
        const text1 = 'Note 1: Lots Posted only counts the first occurrences of each lot, even when sold multiple times as "Choice".';
        const text2 = 'Note 2: Total Sales Records give the number of records in Sales including duplicate of the same lot.';
        const text3 = 'Note 3: Unsold lots may include Sold lots that no longer have a matching Inventory record';
        const startTextX = doc.internal.pageSize.width * 0.5 + 60;
        const maxTextWidth = pageWidth - startTextX - margin; // Adjust for padding
        const startTextY = startTableY + 270;
        const lineHeight = 10; // Height of each line of text

        // Array of texts to display
        const texts = [text1, text2, text3];

        // Function to split and draw text
        const drawText = (text: any, startX: any, startY: any) => {
            const noteLines = doc.splitTextToSize(text, maxTextWidth);
            noteLines.forEach((line: any, index: any) => {
                doc.text(line, startX, startY + (index * lineHeight));
            });
            return startY + (noteLines.length * lineHeight);
        }

        // Draw the texts below each other
        let currentY = startTextY;
        texts.forEach(text => {
            currentY = drawText(text, startTextX, currentY);
            currentY += lineHeight; // Add a line height as a space between texts
        });


        // This is footer
        doc.setFontSize(8);
        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(`Auction Overview: ${auctionOverViewData?.auctionCode}`, 10, pageHeight - margin);
        doc.text(` Printed: ${currentUTCDate}`, pageSize.width - 70, pageHeight - margin, { align: 'center' });
        // window.open(doc.output('bloburl'), '_blank');
        doc.save('Auction_Summary.pdf');
    };

    return (
        <>
            <CommonHelmet title={""} name={"home"} content={"Home"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            <Modal show={toggleModal} size='lg' centered>
                <Modal.Header>
                    <h5 className="modal-title" id="verticallyCenteredModalLabel">{modalData.label}</h5>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setToggleValue(false); reset(); }}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <form onSubmit={handleSubmit(() => { submitHandler(modalData) })} noValidate>
                    <Modal.Body>
                        <p className="mb-1 mt-0">{`**Note: Please enter ${modalData?.nameLabel} or ${modalData?.idLabel} or Date.`}</p>
                        <div className="modalBody row">
                            <div className="col-lg-12">
                                <label className="form-label m-1">{modalData.nameLabel}</label>
                                <input
                                    className="form-control w-100"
                                    type="text"
                                    maxLength={70}
                                    placeholder={`Enter ${modalData.nameLabel}`}
                                    {...register('sbName')}
                                />

                            </div>
                            <div className="mt-2 col-lg-6">
                                <label className="form-label m-1 mx-2">{modalData.idLabel}</label>
                                <input
                                    className="form-control form-icon-input"
                                    type="number"
                                    maxLength={9}
                                    placeholder={`Enter ${modalData.idLabel}`}
                                    {...register('sbId')}
                                />
                            </div>
                            <div className="mt-2 col-lg-6">
                                <label className="form-label m-1">Date</label>
                                {/* <input
                                    className=" form-control form-icon-input"
                                    type="date"
                                    placeholder={`Select Date`}
                                    {...register('sbDate')}
                                /> */}

                                <Controller
                                    name="sbDate"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (

                                        <div className="date_icon">
                                            <DateTime
                                                onChange={onChange}
                                                closeOnSelect={true}
                                                value={value}
                                                timeFormat={false}
                                                dateFormat="MM/DD/y"
                                                inputProps={{
                                                    placeholder: "Select Date",
                                                    className: "form-control form-icon-input",
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                {errors?.sbDate && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.sbDate.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" onClick={() => { setToggleValue(false); reset(); }}>{CommonMessages.cancelButton}</button>
                        {/* <Link className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">Add More Details</Link> */}
                        <button className="btn btn-primary" type="submit" disabled={!isDirty}>Search</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Sidebar />
            <div className='content'>
                <Header />
                <div className=''>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className="d-flex flex-wrap bg-gradiant justify-content-between align-items-center border border-start-0 border-end-0 text-start ps-3 pe-3 pb-3 pt-3 mb-4" style={{ width: '100%' }}>
                                <p className='m-0'>Welcome To DPA Auctions</p>
                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                    <Dropdown.Toggle className="m-0 p-0 btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant="none" data-toggle="tooltip" data-placement="top" title="Open Search Menu">
                                        <img src={moreOption} style={{ width: "20px" }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2 `}>
                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between fw-bold gap-3" onClick={() => { setToggleValue(true); setModalData(getByData[0]); reset() }}><span>Search Auction</span> <IconAuction2 /></Dropdown.Item>
                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between fw-bold gap-3" onClick={() => { setToggleValue(true); setModalData(getByData[1]); reset() }}><span>Search Buyer</span> <IconPeople /></Dropdown.Item>
                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between fw-bold gap-3" onClick={() => { setToggleValue(true); setModalData(getByData[2]); reset() }}><span>Search Sales</span> <IconStats /></Dropdown.Item>
                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between fw-bold gap-3" onClick={() => { setToggleValue(true); setModalData(getByData[3]); reset() }}><span>Search Seller</span> <IconShop /></Dropdown.Item>
                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between fw-bold gap-3" onClick={() => { setToggleValue(true); setModalData(getByData[4]); reset() }}><span>Search Invoice</span> <IconPaper /></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <h6 className="">Select Date Range</h6>
                                <div className="width_210px">
                                    <div className="form_date_icon position-relative">
                                        <DateRangePicker
                                            onCallback={handleDateRangePicker}
                                            // initialSettings={{
                                            //     startDate: new Date(moment().subtract(90, 'days').format('YYYY-MM-DD')),
                                            //     endDate: new Date(moment().add(30, 'days').format('YYYY-MM-DD'))
                                            // }} 
                                            initialSettings={{
                                                startDate: new Date(initialDates.fromDate),
                                                endDate: new Date(initialDates.toDate)
                                            }}
                                        >
                                            <input role='button' className='form-control calender_bg pe-5' />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3 g-3 justify-content-center">
                            {countConst?.map((card: any) => {
                                return (
                                    <div key={card.label} className="h-100 col-12 col-xxl-2 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                        <div className="card flex-fill w-100 h-100" >
                                            <div className="card-header p-3 bg-gradiant with-img-title d-flex align-items-center justify-content-between" role='button' onClick={() => { CountCardHandler(card.link) }}>
                                                <h5 className="card-title mb-0">{card.label}</h5>
                                                {(card?.label === "Best Selling") ? <></> : <img src={arrow} className="svgImg" />}
                                            </div>
                                            <div className={(card?.label === "Best Selling") ? "card-body p-3 re-development " : "card-body p-3"}>
                                                <h6 className="card-subtitle text-muted mt-1"># {card.smallLabel ?? card.label}</h6>
                                                <h3 className="mt-2 mb-0 fw-500">{card.count}</h3>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row mb-3 g-3">
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">

                                        <div className="d-flex align-items-center">
                                            <Iconhammer />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Auctions</h4>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-body ps-0 pr-0 position-relative overflow-auto">
                                        <div className="d-flex justify-content-center">
                                            <Chart
                                                options={{
                                                    chart: {
                                                        toolbar: {
                                                            show: true,
                                                            tools: {
                                                                download: true,
                                                                selection: true,
                                                                zoom: true,
                                                                zoomin: true,
                                                                zoomout: true,
                                                                pan: true,
                                                                reset: true,
                                                            },
                                                            export: {
                                                                csv: {
                                                                    filename: "Auctions",
                                                                    columnDelimiter: ',',
                                                                    headerCategory: 'category',
                                                                    headerValue: 'value',
                                                                    /* dateFormatter(timestamp) {
                                                                        // return new Date(timestamp).toDateString()
                                                                    } */
                                                                },
                                                                svg: {
                                                                    filename: "Auctions",
                                                                },
                                                                png: {
                                                                    filename: "Auctions",
                                                                }
                                                            },
                                                        },

                                                        foreColor: chartText()

                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        categories: AuctionChartData ? AuctionChartData?.map((auction: any) => {
                                                            return auction.month
                                                        }) : [],
                                                    },
                                                    yaxis: {
                                                        min: 0,
                                                        max: Math.max(...AuctionChartData?.map((auction: any) => {
                                                            return auction.auctionCount
                                                        })) > 10 ? 40 : 10,
                                                        tickAmount: 10,
                                                    }
                                                }}

                                                series={
                                                    [
                                                        {
                                                            name: "Auction",
                                                            data: AuctionChartData ? AuctionChartData?.map((auction: any) => {
                                                                return auction.auctionCount
                                                            }) : [],
                                                            // color: '#006800'
                                                            color: `${primaryColor}`

                                                        }
                                                    ]
                                                }

                                                type='bar'

                                                height={250}
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <IconPeopleCircle />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Buyers</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body ps-0 pr-0 position-relative overflow-hidden">
                                        <div className="d-flex justify-content-center">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    chart: {
                                                        type: 'line',
                                                        zoom: {
                                                            enabled: true,
                                                        },
                                                        toolbar: {
                                                            show: true,
                                                            tools: {
                                                                download: true,
                                                                selection: false,
                                                                zoom: false,
                                                                zoomin: false,
                                                                zoomout: false,
                                                                pan: true,
                                                                reset: false,
                                                            },
                                                            export: {
                                                                csv: {
                                                                    filename: "Buyers",
                                                                    columnDelimiter: ',',
                                                                    headerCategory: 'category',
                                                                    headerValue: 'value',
                                                                    /* dateFormatter(timestamp) {
                                                                        // return new Date(timestamp).toDateString()
                                                                    } */
                                                                },
                                                                svg: {
                                                                    filename: "Buyers",
                                                                },
                                                                png: {
                                                                    filename: "Buyers",
                                                                }
                                                            },
                                                        },
                                                        foreColor: chartText()
                                                    },
                                                    xaxis: {
                                                        categories: CustomerChartData ? CustomerChartData?.map((customer: any) => {
                                                            return customer.month
                                                        }) : [],
                                                    },
                                                }}
                                                series={
                                                    [
                                                        {
                                                            name: "All",
                                                            data: CustomerChartData ? CustomerChartData?.map((customer: any) => {
                                                                return customer.totalCustomerCount
                                                            }) : [],
                                                            color: '#ff9900'
                                                        },
                                                        {
                                                            name: "Active",
                                                            data: CustomerChartData ? CustomerChartData?.map((customer: any) => {
                                                                return customer.activeCustomerCount
                                                            }) : [],
                                                            color: `${primaryColor}`
                                                        }
                                                    ]
                                                }
                                                type='line'
                                                style={{ width: "100%" }}
                                                height={250}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">

                                        <div className="d-flex align-items-center">
                                            <IconBoxArchive />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Inventory</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body ps-0 pr-0">
                                        <div className="d-flex justify-content-center">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    chart: {
                                                        toolbar: {
                                                            show: true,
                                                            tools: {
                                                                download: true,
                                                                selection: false,
                                                                zoom: false,
                                                                zoomin: false,
                                                                zoomout: false,
                                                                pan: false,
                                                                reset: false,
                                                            },
                                                            export: {
                                                                csv: {
                                                                    filename: "Inventories",
                                                                    columnDelimiter: ',',
                                                                    headerCategory: 'category',
                                                                    headerValue: 'value',
                                                                    /* dateFormatter(timestamp) {
                                                                        // return new Date(timestamp).toDateString()
                                                                    } */
                                                                },
                                                                svg: {
                                                                    filename: "Inventories",
                                                                },
                                                                png: {
                                                                    filename: "Inventories",
                                                                }
                                                            },
                                                        },
                                                        foreColor: chartText()

                                                    },
                                                    labels: ['Sold', 'Passed', 'BuyBack', 'Non Payer'],
                                                }}
                                                series={[55, 13, 43, 22]}
                                                type='pie'
                                                style={{ width: "100%" }}
                                                height={250}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">

                                        <div className="d-flex align-items-center">
                                            <IconDollar />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Sales</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body ps-0 pr-0">
                                        <div className="d-flex justify-content-center">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        categories: SalesChartData ? SalesChartData?.map((sales: any) => {
                                                            return sales.month
                                                        }) : [],
                                                    },
                                                    chart: {
                                                        toolbar: {
                                                            show: true,
                                                            tools: {
                                                                download: true,
                                                                selection: false,
                                                                zoom: false,
                                                                zoomin: false,
                                                                zoomout: false,
                                                                pan: false,
                                                                reset: false,
                                                            },
                                                            export: {
                                                                csv: {
                                                                    filename: "Sales",
                                                                    columnDelimiter: ',',
                                                                    headerCategory: 'category',
                                                                    headerValue: 'value',
                                                                    /* dateFormatter(timestamp) {
                                                                        // return new Date(timestamp).toDateString()
                                                                    } */
                                                                },
                                                                svg: {
                                                                    filename: "Sales",
                                                                },
                                                                png: {
                                                                    filename: "Sales",
                                                                }
                                                            },
                                                        },
                                                        foreColor: chartText()

                                                    },
                                                }}
                                                series={
                                                    [
                                                        {
                                                            name: "sales",
                                                            data: SalesChartData ? SalesChartData?.map((sales: any) => {
                                                                return sales.price
                                                            }) : [],
                                                            color: `${primaryColor}`
                                                        }
                                                    ]
                                                }
                                                type='bar'
                                                style={{ width: "100%" }}
                                                height={250}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Invoices graph - Bar graph */}
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <IconInvoice2 />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Invoices</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body ps-0 pr-0">
                                        <div className="d-flex justify-content-center">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    chart: {
                                                        toolbar: {
                                                            show: true,
                                                            tools: {
                                                                download: true,
                                                                selection: false,
                                                                zoom: false,
                                                                zoomin: false,
                                                                zoomout: false,
                                                                pan: false,
                                                                reset: false,
                                                            },
                                                            export: {
                                                                csv: {
                                                                    filename: "Invoices",
                                                                    columnDelimiter: ',',
                                                                    headerCategory: 'category',
                                                                    headerValue: 'value',
                                                                    /* dateFormatter(timestamp) {
                                                                        // return new Date(timestamp).toDateString()
                                                                    } */
                                                                },
                                                                svg: {
                                                                    filename: "Invoices",
                                                                },
                                                                png: {
                                                                    filename: "Invoices",
                                                                }
                                                            },
                                                        },
                                                        foreColor: chartText()

                                                    },
                                                    labels: chartData.labels,
                                                }}
                                                series={chartData.series}
                                                type='pie'
                                                style={{ width: "100%" }}
                                                height={250}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-xl-4 col-lg-6 col-md-6 chart_width">
                                <div className="card flex-fill w-100 h-100">
                                    <div className="card-header pt-3 pb-3 bg-gradiant d-flex align-items-center justify-content-between">

                                        <div className="d-flex align-items-center">
                                            <IconShapes />
                                            <div className="ms-2">
                                                <h4 className="card-title fw-light m-0 mb-0">Best Selling Categories</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body px-4 py-4 re-development">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex justify-content-between card-title">
                                                <span>Air Applicators</span>
                                                <span>150</span>
                                            </div>
                                            <div className="d-flex justify-content-between card-title">
                                                <span>Air Compressors</span>
                                                <span>200</span>
                                            </div>
                                            <div className="d-flex justify-content-between card-title">
                                                <span>Air Crafts</span>
                                                <span>310</span>
                                            </div>
                                            <div className="d-flex justify-content-between card-title">
                                                <span>Fork Lift</span>
                                                <span>210</span>
                                            </div>
                                            <div className="d-flex justify-content-between card-title">
                                                <span>Agriculture</span>
                                                <span>450</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center top_border mb-5">
                            <div className="col-xxl-7 col-xl-9 col-lg-12 col-md-12 col-sm-12 mt-5">
                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                                    <span className="fs-18 fw-bold">Auction Summary</span>
                                    {/* {getGlobalAuctionIds && <button className="btn btn-primary btn-sm" onClick={generatePDF} disabled={!auctionOverViewData}>Print</button>} */}
                                    {getGlobalAuctionIds && <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-4 pe-4 ms-2"
                                        onClick={generatePDF} disabled={!auctionOverViewData}>
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>}
                                </div>
                                {getGlobalAuctionIds ?
                                    <>
                                        <div className="border p-3 mb-3 border-radius">
                                            <div className="row g-2 align-items-center mb-1">
                                                <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">Invoiced Item Total:</label>
                                                <div className="col-md-2">
                                                    <input type="text" className="form-control form-icon-input" placeholder="itemTotal" value={formatCurrency(
                                                        auctionOverViewData?.invoicedItemTotal ? auctionOverViewData?.invoicedItemTotal : "0"
                                                    )} />
                                                </div>
                                                <label className="col-md-2 text-md-end col-form-label col-form-label-sm p-0">Item Tax:</label>
                                                <div className="col-md-2">
                                                    <input type="text" className="form-control form-icon-input" placeholder="itemTotalTax" value={formatCurrency(
                                                        auctionOverViewData?.itemTax ? auctionOverViewData?.itemTax : "0"
                                                    )} />
                                                </div>
                                                <label className="col-md-3 col-form-label col-form-label-sm fs-10">({formatCurrency(auctionOverViewData?.itemtaxable ?? 0)} taxable)</label>
                                            </div>
                                            <div className="row g-2 align-items-center mb-1">
                                                <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">Buyer's Premium:</label>
                                                <div className="col-md-2">
                                                    <input type="text" className="form-control form-icon-input" placeholder="buyerPremium" value={formatCurrency(
                                                        auctionOverViewData?.buyersPremium ? auctionOverViewData?.buyersPremium : "0"
                                                    )} />
                                                </div>
                                                <label className="col-md-2 text-md-end col-form-label col-form-label-sm p-0">BP Tax:</label>
                                                <div className="col-md-2">
                                                    <input type="text" className="form-control form-icon-input" placeholder="(Taxable)" value={formatCurrency(
                                                        auctionOverViewData?.bpTax ? auctionOverViewData?.bpTax : "0"
                                                    )} />
                                                </div>
                                                <label className="col-md-3 col-form-label col-form-label-sm fs-10">({formatCurrency(auctionOverViewData?.bPtaxable ?? 0)} taxable)</label>
                                            </div>

                                            <div className="row g-2 align-items-center mb-1">
                                                <label className="col-md-3 p-0 text-md-end col-form-label col-form-label-sm">Sub-Total:</label>
                                                <div className="col-md-2">
                                                    <input type="text" className="form-control form-icon-input" placeholder="subTotal" value={formatCurrency(
                                                        auctionOverViewData?.subTotal ? auctionOverViewData?.subTotal : "0"
                                                    )} />
                                                </div>
                                                <label className="col-md-2 text-md-end col-form-label col-form-label-sm p-0">Total Tax:</label>
                                                <div className="col-md-2 position-relative">
                                                    <input type="text" className="form-control form-icon-input" placeholder="taxabletotalTax" value={formatCurrency(
                                                        auctionOverViewData?.totalTax ? auctionOverViewData?.totalTax : "0"
                                                    )} />
                                                    <span className="forLine"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="curly_line dashboard_curly_line">
                                            <div className="">
                                            </div>
                                        </div>
                                        <div className="border bg-light border-radius p-3 mb-2">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-5">
                                                    <div className="row g-2 align-items-center mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm ps-2">Invoice & Tax Sub-Total:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="taxAmount" value={formatCurrency(
                                                                auctionOverViewData?.invoiceandSubTotal ? auctionOverViewData?.invoiceandSubTotal : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 align-items-center mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Invoice Adjustments:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(
                                                                auctionOverViewData?.invoiceadjustments ? auctionOverViewData?.invoiceadjustments : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row g-2 align-items-center mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">CC Surcharge:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceTotal" value={formatCurrency(
                                                        auctionOverViewData?.invoiceadjustments ? auctionOverViewData?.invoiceadjustments : "0"
                                                    )}   />
                                                        </div>
                                                    </div> */}
                                                    <div className="row g-2 align-items-center mb-1">
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-6">
                                                            <div className="curly_line dashboard_curly_line invoice_total_line">
                                                                <div className="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Invoice Total:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="taxAmount" value={formatCurrency(
                                                                auctionOverViewData?.invoiceTotal ? auctionOverViewData?.invoiceTotal : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Sold - Not Invoiced:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(
                                                                auctionOverViewData?.soldNotInvoiced ? auctionOverViewData?.soldNotInvoiced : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Total Auction Proceeds:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceTotal" value={formatCurrency(
                                                                auctionOverViewData?.totalAuctionProceeds ? auctionOverViewData?.totalAuctionProceeds : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    {/* <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Inventory Adjustment Total:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="taxAmount" value={formatCurrency(
                                                        auctionOverViewData?.totalAuctionProceeds ? auctionOverViewData?.totalAuctionProceeds : "0"
                                                    )} />
                                                        </div>
                                                    </div> */}
                                                    <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Total Passed Lots:</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(
                                                                auctionOverViewData?.totalPassedLots ? auctionOverViewData?.totalPassedLots : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 align-items-center justify-content-end mb-1">
                                                        <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Item total invoice and not invoice (no BP/TAX):</label>
                                                        <div className="col-md-6">
                                                            <input type="text" className="form-control form-icon-input" placeholder="invoiceTotal" value={formatCurrency(
                                                                auctionOverViewData?.notInvoiceNoBPTax ? auctionOverViewData?.notInvoiceNoBPTax : "0"
                                                            )} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="border border-radius p-3 mb-2">

                                                    <div className="table-responsive mx-n1 px-1">
                                                        <table className="table fs--1 mb-0 border border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="p-2">Pay Type</th>
                                                                    <th className="p-2">Invoice Only</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {auctionOverViewData?.lstpaymenttype?.length > 0 ? auctionOverViewData?.lstpaymenttype?.map((payType: any) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="p-2">{payType?.paymentType}</td>
                                                                            <td className="p-2">{formatCurrency(payType?.invoiceAmount || 0)}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={2} className="text-center">No record found</td>
                                                                    </tr>
                                                                }
                                                                {/* Commented for now */}
                                                                {/* <tr>
                                                                    <td className="p-2 fw-bold"></td>
                                                                    <td className="p-2 fw-bold">Invoice Only</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-2">All Types</td>
                                                                    <td className="p-2">{formatCurrency(auctionOverViewData?.allTypes ?? 0)}</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="col-lg-12 mt-3">
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm ps-2">Payment Total:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input" placeholder="taxAmount" value={formatCurrency(auctionOverViewData?.paymentTotal ?? 0)}  />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Paid from Deposit:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(auctionOverViewData?.paidFromDeposite ?? 0)}/>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Balance Due:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(auctionOverViewData?.balanceDue ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Invoice Total:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input" placeholder="invoiceAdjustment" value={formatCurrency(auctionOverViewData?.invoiceTotal ?? 0)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="border border-radius p-3 mb-2">
                                                    <h6 className="text-center"><span className="border-bottom">Lot Count Summary</span></h6>
                                                    <div className="col-lg-12 mt-3 mb-3">
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm ps-2">Posted to Sales:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="taxAmount" value={(auctionOverViewData?.postedToSales ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Total Sales Records:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.totalSalesRecord ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Status = Sold:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.postedToSold ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Invoiced:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.invoiced ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Uninvoiced:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.noInvoice ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Total Unsold:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.unsold ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Status = Passed:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.postedToPassed ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Status = BuyBack:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.postedToBuyBack ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Status = Pending:</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.postedToPending ?? 0)} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-1">
                                                            <label className="col-md-6 p-0 text-md-end col-form-label col-form-label-sm">Status = null</label>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control form-icon-input text-center" placeholder="invoiceAdjustment" value={(auctionOverViewData?.nullInventoryStatusCount ?? 0)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="fs-14"><b>Note 1:</b> Lots Posted only counts the first occurrences of each lot, even when sold multiple times as "Choice".</p>
                                                    <p className="fs-14"><b>Note 2:</b> Total Sales Records give the number of records in Sales including duplicate of the same lot.</p>
                                                    <p className="fs-14"><b>Note 3:</b> Unsold lots may include Sold lots that no longer have a matching Inventory record</p>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="border border-radius d-flex flex-wrap align-items-center justify-content-center p-4 text-center">
                                            <div className="text-center col-md-6">
                                                <img src={noAuctionselected} className="mw-100" />
                                            </div>
                                            <p className="mt-3 text-center fw-bold w-100">Select auction to get auction summary</p>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Feature
