import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import moment from "moment"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import ReactPaginate from "react-paginate"
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import { AUCTIONWISESELLER, GETAUCTIONSELLERLOTS, GETSELLERAUCTIONS } from "../../common/apiConstatnts"
import { formatPhoneNumber, UTCdateConverter } from "../../common/constants"
import { Breadcrumb } from "../../common/interface"
import { errorMessages } from "../../common/messageConstants"
import * as routeConstant from '../../common/routeConstants'
import { encryptId } from "../../Encryption/Encryption"
import { formatCurrency, formatNumber, removeHtmlTags } from "../../Utils/Utils"
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import MomentUtil from "../../Utils/MomentUtil"
import handleError from "../../Utils/ApiCommonErrorMessages"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Auction Report', url: routeConstant.SELLERAUCTIONSUMMARYREPORT }
]

const SellerAuctionSummaryReport = () => {
    const [loading, setLoading] = useState(false);
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const [sellerSummaryData, setSellerSummaryData] = useState<any[]>([]);
    const [filterSellerSum, setFilterSellerSum] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [filterModalData, setFilterModalData] = useState([]);
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [modalData, setModalData] = useState<any>([]);
    const [originalModalData, setOriginalModalData] = useState<any>([]);
    const [modalCurrentPage, setModalCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState<any>('');
    const [modalTitle, setModalTitle] = useState<any>('');

    const PER_PAGE = 10;
    const MODAL_PER_PAGE = 5;
    const pageCount = Math.ceil(sellerSummaryData.length / PER_PAGE);
    const modalPageCount = Math.ceil(modalData.length / MODAL_PER_PAGE);

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...sellerSummaryData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setSellerSummaryData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSellerSum(firstSet)
    }

    const getSellerSummaryData = (auctionId?: any) => {
        setLoading(true)
        axios.get(`${AUCTIONWISESELLER}?AuctionId=${auctionId}`).then((response: any) => {
            setLoading(false)
            const sellerSumResp = response.data.data
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterSellerSum([]);
                return;
            } else {
                paginateData(sellerSumResp)
                setCurrentPage(0)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');
        doc.setFontSize(13).text(`Seller Auction Report`, 15, 15);
        doc.setFontSize(13).text(`Auction Name:- ${selectedAuction?.label}`, 15, 23);
        doc.setFontSize(11).text(`Created On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`, 15, 30);
        const head = [['Seller ID', 'Seller Name', 'Email', 'Lot Count', 'Mobile']]
        const data = sellerSummaryData.map((data: any) => {
            return {
                sellerId: data?.selleId,
                sellerName: data?.firstName + ' ' + data?.lastName,
                // company: data?.company,
                email: data?.email,
                countSellerLot: formatNumber(data?.countSellerLot),
                mobile: data?.mobile ? formatPhoneNumber(data.mobile) : '-'
            }
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Seller-Auction-Report.pdf`);
    }

    const handleAuctionChange = (e: any) => {
        const selectedAuctionCode: any = parseInt(e?.value);
        setSelectedAuction(e);
        // getSellerSummaryData(selectedAuctionCode);
    };

    const generateExcelWithTitle = () => {
        if (!sellerSummaryData || sellerSummaryData.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'No Records',
                text: 'No record found to generate Excel.',
                confirmButtonColor: '#006800',
            });
            return;
        }
        let data = sellerSummaryData?.map((data: any) => {
            return {
                sellerId: data?.selleId,
                sellerName: data?.firstName + ' ' + data?.lastName,
                // company: data?.company,
                email: data?.email,
                countSellerLot: formatNumber(data?.countSellerLot),
                mobile: data?.mobile ? formatPhoneNumber(data.mobile) : '-'
            };
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Seller Auction Report'],
            [`Auction Name:- ${selectedAuction?.label}`],
            [`Created On: ${currTime.format('MM/DD/YYYY hh:mm A')} (${defaultTimezone})`],
            [],
            ['Seller ID', 'Seller Name', 'Email', 'Lot Count', 'Mobile'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Seller-Auction-Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETSELLERAUCTIONS).then((response: any) => {
            setLoading(false);
            // const auction = response.data.map((d: any) => {
            //     const start: any = new Date(d.startDateTime);
            //     const current: any = UTCdateConverter(new Date());
            //     return { ...d, pendingTime: start - current }
            // })
            // const currentAuction = auction.find((obj: any) => obj.pendingTime == Math.min(...auction.filter((d: any) => d.pendingTime > 0).map((i: any) => i.pendingTime)));
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctioncode} - ${lData.auctiontitle}`,
                    value: lData.auctionId,
                };
            });
            setSelectedAuction(auctionData[0]);
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });;
    };

    const getLotsBySellerAuction = (sellerId: any, auctionId: any) => {
        setLoading(true);
        axios.get(`${GETAUCTIONSELLERLOTS}?auctionId=${auctionId}&sellerId=${sellerId}`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data;
                const modifyData = data.map((num: any, index: any) => { return { ...num, srNo: index + 1 } })
                modalPaginateData(modifyData)
                setOriginalModalData(modifyData)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const modalPaginateData = (data: any) => {
        setModalData(data)
        const firstSet = data?.slice(0, MODAL_PER_PAGE);
        setFilterModalData(firstSet)
    }

    const handleModalPageClick = ({ selected: selectedPage }: any) => {
        setModalCurrentPage(selectedPage);
    }

    const handleModalOpen = (sellerId: any, auctionId: any, title: any) => {
        setToggleValue(true)
        setModalCurrentPage(0)
        setModalTitle(title)
        getLotsBySellerAuction(sellerId, auctionId)
    }

    const handleLotSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = originalModalData?.filter((x: any) =>
            x?.lotNo.toString()?.toLocaleLowerCase()?.includes(keyword) ||
            x.title?.toLocaleLowerCase()?.includes(keyword) ||
            x?.displayLotNo?.toLocaleLowerCase()?.includes(keyword)
        )
        modalPaginateData(filterData)
        setModalCurrentPage(0)
    }

    const handleSortingModal = (type: string, fieldName: string) => {
        let sortedData = [...modalData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        modalPaginateData(sortedData);
        setCurrentPage(0);
    }

    useEffect(() => {
        handleLotSearch(searchText)
    }, [searchText])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = sellerSummaryData?.slice(offset, offset + PER_PAGE)
        setFilterSellerSum(nextSet)
    }, [currentPage])

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerSummaryData(selectedAuction.value)
        }
    }, [selectedAuction])

    useEffect(() => {
        const offset = modalCurrentPage * MODAL_PER_PAGE
        const nextSet: any = modalData?.slice(offset, offset + MODAL_PER_PAGE)
        setFilterModalData(nextSet)
    }, [modalCurrentPage])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={toggleModal} size="lg" centered>
                <Modal.Header>
                    <div className="d-flex flex-column">
                        <h5 className="modal-title mb-1" id="verticallyCenteredModalLabel">Lot Details</h5>
                        <h5 className="modal-title">Seller: {modalTitle}</h5>
                    </div>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                        setToggleValue(false)
                        modalPaginateData([])
                    }}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalBody">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="border-300">
                                        <div className="col-lg-6">
                                            <div className="search-box mb-3 w-100">
                                                <div className="d-flex align-items-center position-relative d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        className="form-control search-input search form-control-sm w-100"
                                                        placeholder="Search Lot"
                                                        value={searchText}
                                                        onChange={(e: any) => setSearchText(e.target.value)}
                                                    />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive mx-n1 scrollbar">
                                            <table className="table fs--1 mb-3 border-top border-200">
                                                <thead>
                                                    <tr>
                                                        <th className="bg-gradiant " scope="col" data-sort="message">
                                                            <div className='d-flex align-items-center'>
                                                                <span>Lot #</span>
                                                                <div className="sorting_group">
                                                                    <button className='arrow-up' onClick={() => handleSortingModal('ASC', 'lotNo')} ></button>
                                                                    <button className='arrow-down' onClick={() => handleSortingModal('DESC', 'lotNo')}></button>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        {/* <th className="bg-gradiant " scope="col" data-sort="message">
                                                            <div className='d-flex align-items-center'>
                                                                <span>Display Lot #</span>
                                                                <div className="sorting_group">
                                                                    <button className='arrow-up' onClick={() => handleSortingModal('ASC', 'displayLotNo')} ></button>
                                                                    <button className='arrow-down' onClick={() => handleSortingModal('DESC', 'displayLotNo')}></button>
                                                                </div>
                                                            </div>
                                                        </th> */}
                                                        <th className="bg-gradiant " scope="col" data-sort="message">
                                                            <div className='d-flex align-items-center'>
                                                                <span>Lot Title</span>
                                                                <div className="sorting_group">
                                                                    <button className='arrow-up' onClick={() => handleSortingModal('ASC', 'title')} ></button>
                                                                    <button className='arrow-down' onClick={() => handleSortingModal('DESC', 'title')}></button>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="bg-gradiant " scope="col" data-sort="message">
                                                            <div className='d-flex align-items-center'>
                                                                <span>Status</span>
                                                                <div className="sorting_group">
                                                                    <button className='arrow-up' onClick={() => handleSortingModal('ASC', 'lotStatus')} ></button>
                                                                    <button className='arrow-down' onClick={() => handleSortingModal('DESC', 'lotStatus')}></button>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className="bg-gradiant " scope="col" data-sort="message">
                                                            <div className='d-flex align-items-center'>
                                                                <span>Bid Amount ($)</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list" id="table-latest-review-body">
                                                    {
                                                        filterModalData.length > 0 ? filterModalData.map((data: any, index: any) => {
                                                            return (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                    <td className="align-middle review p-2">
                                                                        <p className="mb-0 text-900">{data.displayLotNo ? data.displayLotNo : data.lotNo}</p>
                                                                    </td>
                                                                    {/* <td className="align-middle review p-2">
                                                                        <p className="mb-0 text-900">{data.displayLotNo ?? '-'}</p>
                                                                    </td> */}
                                                                    <td className="align-middle review p-2">
                                                                        {/* <p className="mb-0 text-900">{data?.title ? removeHtmlTags(data?.title) : '-'}</p> */}
                                                                        <Link className="btn text-decoration-underline m-0 p-0 fw-normal text-start" data-bs-dismiss="modal"
                                                                            to={`${routeConstant.UPDATEINVENTORY}/${encryptId(data?.lotId)}`}
                                                                            target="_blank"
                                                                        >
                                                                            {data?.title ? removeHtmlTags(data?.title) : '-'}
                                                                        </Link>
                                                                    </td>
                                                                    {/* <td className="align-middle review p-2">
                                                                        <span className="badge badge-dpa status-notposted">{data?.lotStatus ? data?.lotStatus : '-'}</span>
                                                                    </td> */}
                                                                    <td className="align-middle white-space-nowrap p-2">
                                                                        {" "}
                                                                        <span
                                                                            className={`badge badge-dpa status-${data?.lotStatus
                                                                                ?.replace(/ /g, "")
                                                                                .toLowerCase()}`}
                                                                        >
                                                                            {data?.lotStatus}
                                                                        </span>
                                                                    </td>
                                                                    <td className="align-middle review p-2">
                                                                        <p className="mb-0 text-900">{data.bidAmount ? formatCurrency(data.bidAmount) : '0'}</p>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end me-4'>
                                    {filterModalData.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={modalPageCount}
                                            onPageChange={handleModalPageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={modalCurrentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Auction Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-md-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="d-flex align-items-center">
                                    <div className="col-lg-6">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={auctionList.find((option: any) => option.value === selectedAuction.value)}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={generateExcelWithTitle}
                                        disabled={filterSellerSum.length == 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => exportPDF()}
                                        disabled={filterSellerSum.length == 0} ><i className="fas fa-download"></i><span>Pdf</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-3 border-top border-200">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Seller Id</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'selleId')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'selleId')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Seller Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Email</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Lot Count</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'countSellerLot')}></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'countSellerLot')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Mobile</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterSellerSum.length > 0 ? filterSellerSum.map((fData: any, fIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                        <td className="align-middle  p-2">{fData.selleId}</td>
                                                        <td className="align-middle  p-2">
                                                            <Link className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                to={`${routeConstant.SELLERMOREINFO}/${encryptId(fData?.selleId)}`} target="_blank"
                                                            >
                                                                {fData.firstName + ' ' + fData.lastName}
                                                            </Link>
                                                        </td>
                                                        <td className="align-middle  p-2">{fData.email ?? '-'}</td>
                                                        <td className="align-middle  p-2">

                                                            {fData.countSellerLot == 0 ?
                                                                <span className="m-0 p-0 fw-normal">{formatNumber(fData.countSellerLot)}</span>
                                                                :
                                                                <span className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                    onClick={() => handleModalOpen(fData?.selleId, selectedAuction?.value, `${fData.firstName} ${fData.lastName}`)}>
                                                                    {fData.countSellerLot}
                                                                </span>
                                                            }
                                                        </td>

                                                        <td className="align-middle  p-2">{fData.mobile ? formatPhoneNumber(fData.mobile) : '-'}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={10}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterSellerSum.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerAuctionSummaryReport