import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";

interface CheckboxProps {
  onChange: (selectedOption: any) => void;
  options: any[];
  defaultSelected?: string;
}

const CustomCheckbox = ({ options, onChange, defaultSelected }: CheckboxProps) => {
  const [option, setOption] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (options?.length) {
      setOption([{ name: "All Selected", id: "select_all" }, ...options]);
      if (defaultSelected) {
        let defaultSelectedArr = options.filter((data: any) => defaultSelected?.split(',')?.includes(`${data?.id}`))
        defaultSelectedArr =
          options.length == defaultSelectedArr.length ?
            [{ name: "All Selected", id: "select_all" }, ...defaultSelectedArr]
            :
            defaultSelectedArr

        setSelectedValue(defaultSelectedArr)
      } else {
        setSelectedValue([]);
      }
    } else {
      setOption([])
    }
  }, [options]);

  useEffect(() => {
    const allOptionsSelected = selectedValue?.length === option?.length;
    setSelectAllChecked(allOptionsSelected);
  }, [selectedValue, option]);

  const onSelect = (selectedList: any, selectedItem: any) => {
    let updatedSelectedList: any = [...selectedList];
    if (selectedItem.id === "select_all") {
      if (!selectAllChecked) {
        updatedSelectedList.splice(0, updatedSelectedList?.length, ...option);
      } else {
        updatedSelectedList?.splice(0, updatedSelectedList?.length);
      }
      // setSelectAllChecked(!selectAllChecked);
    } else {
      const selectAllIndex = updatedSelectedList?.findIndex(
        (item: any) => item.id === "select_all"
      );
      if (selectAllIndex !== -1) {
        updatedSelectedList?.splice(selectAllIndex, option?.length);
        // setSelectAllChecked(false);
      }
    }
    setSelectedValue(updatedSelectedList);
    if (
      updatedSelectedList?.length + 1 === option?.length &&
      updatedSelectedList[0]?.id != "select_all"

    ) {
      setSelectedValue([
        { name: "All Selected", id: "select_all" },
        ...updatedSelectedList,
      ]);
      setSelectAllChecked(true);
    }

    onChange(updatedSelectedList?.map((item: any) => item.id));
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    if (removedItem.id === "select_all") {
      setSelectedValue([]);
    } else {
      // selectedList.splice(selectedList[0], 1);
      //setSelectedValue(selectedList);
      setSelectedValue(selectedList.filter((data: any) => data.id != removedItem.id && data.id != 'select_all'));
      onChange(selectedList.filter((data: any) => data.id != removedItem.id).map((item: any) => item.id));
    }
  };

  return (
    <>
      <div className={selectAllChecked ? 'all-selected' : ''}>
        <Multiselect
          options={option}
          selectedValues={selectedValue}
          onSelect={onSelect}
          onRemove={onRemove}
          className="nobg"
          displayValue="name"
          showCheckbox
          hidePlaceholder
        // hideSelectedList={selectedValue?.length > 1 ? true : false}
        />
      </div>
      {/* {selectedValue?.length > 1 && (
        <input
          className="form-control form-icon-input"
          value={
            selectAllChecked ? selectedValue?.length - 1 : selectedValue?.length
          }
        />
      )} */}
    </>
  );
};

export default CustomCheckbox;
