import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { GETASSOCIATEDSELLERS } from "../../../common/apiConstatnts";
import axios from "axios";
import { toast } from "react-toastify";
import { ActiveStep, loader } from "../../../store/actions/SalesRepAction";
import { useDispatch } from "react-redux";
import * as routeConstant from '../../../common/routeConstants'
import { Link } from "react-router-dom";
import { decryptId } from "../../../Encryption/Encryption";
import { CommonMessages, errorMessages } from "../../../common/messageConstants";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const SellerAssociated = () => {
    const dispatch = useDispatch();
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const [sellerData, setSellerData] = useState<any>([]);
    const [filterSellers, setFilterSellers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');

    const PER_PAGE = 5;
    const pageCount = Math.ceil(sellerData?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setSellerData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSellers(firstSet);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handlePrevious = () => {
        dispatch(ActiveStep('auctionInfo'));
    }

    const getAllSellers = () => {
        dispatch(loader(true));
        axios.get(`${GETASSOCIATEDSELLERS}?sellerRepId=${decryptId(ID)}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.data?.success === "Fail") {
                dispatch(loader(false));
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterSellers([]);
                return;
            }
            else {
                const SalesRepData = response?.data?.data;
                paginateData(SalesRepData);
            }
            dispatch(loader(false));
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase();
        if (keyword === '') {
            getAllSellers();
            return;
        }
        const filterData: any = sellerData?.filter((x: any) => x.firstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.lastName?.toLocaleLowerCase()?.includes(keyword) || x.company?.toLocaleLowerCase()?.includes(keyword) || x.sellerId.toString().includes(keyword));
        paginateData(filterData);
        setCurrentPage(0)
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = sellerData?.slice(offset, offset + PER_PAGE);
        setFilterSellers(nextSet);
    }, [currentPage])

    useEffect(() => {
        getAllSellers();
    }, [])

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText])

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10">
                        <div className="mt-2 mb-3 border-300">
                            <div className="row">
                                <div className="col-12 mt-3 d-flex flex-column  justify-content-start">
                                    <h4>Sellers Associated</h4>
                                    <div className="search-box mt-2 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                                <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                                <span className="fas fa-search search-box-icon"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant" scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Seller ID</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant w-25" scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Name</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>State Name</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant w-25" scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Company</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Created Date</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Activation Status</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list scrollbar" id="table-latest-review-body">
                                                        {filterSellers?.length > 0 ?
                                                            filterSellers?.map((seller: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle  p-2">{seller.sellerId ?? "-"}</td>
                                                                        <td className="align-middle  p-2 w-25">{seller ? `${seller.firstName || ""} ${seller.lastName || ""}`.trim() : "-"}</td>
                                                                        <td className="align-middle review p-2">{seller?.stateName ?? "-"}</td>
                                                                        <td className="align-middle review p-2 w-25">{seller.company ?? "-"}</td>
                                                                        <td className="align-middle  p-2">
                                                                            {moment(seller?.createdOn).format('MM/DD/YYYY').toString()}
                                                                        </td>
                                                                        <td className="align-middle text-start status p-2">
                                                                            <span className={`badge badge-dpa fs--2 ${seller.isActive === true ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                                                <span className="badge-label">{seller.isActive == true ? 'Active' : 'Inactive'}</span>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterSellers?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerAssociated;
