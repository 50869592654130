import { Action } from '../../common/interface';
import * as actions from '../../common/types';

export interface InventoryState {
    activeStep: number,
    auctionData: object,
    lotId: number,
    clonedLotId: number,
    prevLotId: number
    nextLotId: number
    totalLots: number
    index: number
    lotInfo: object
    activeTab: string
    mode: string,
    clonedTabs: [],
    createTabs: [],
    generatedLotCtrlRec: object
    commission: object,
    lotPage: object, // old 
    activePage: object, // old
    logNote: any, // old
    inventory: object // old
    invValid: boolean, // old
};

const initialState: InventoryState = {
    activeStep: 1,
    auctionData: { auctionId: 0, auctionTitle: "", auctionType: 0, auctionCode: 0 },
    lotId: 0,
    clonedLotId: 0,
    prevLotId: 0,
    nextLotId: 0,
    totalLots: 0,
    index: 0,
    lotInfo: { activeStep: 1, activeLabel: "lots" },
    activeTab: "lots",
    mode: "create",
    clonedTabs: [],
    createTabs: [],
    generatedLotCtrlRec: {
        ctrlNo: 0,
        lotNo: 0,
        displayLotNo: "",
        recId: 0,
        auctionId: 0,
        auctionEmail: "",
        isMaster: false
    },
    commission: { sellerId: 0 },

    lotPage: {}, // old
    activePage: {}, // old
    logNote: [], // old
    inventory: {}, // old
    invValid: false, // old
};

export const InventoryReducer = (
    state: InventoryState = initialState,
    action: Action,
) => {
    switch (action.type) {
        case actions.LOGPAGEINVENTOY: {
            return { ...state, lotPage: action.payload }
        }
        case actions.ADDRESSINVENTORY: {
            return { ...state, lotPage: action.payload }
        }
        case actions.INVENTORYACTIVE: {
            return { ...state, activePage: action.payload }
        }
        case actions.ACTIVESTEP: {
            return { ...state, activeTab: action.payload }
        }
        case actions.ADDLOGNOTE:
            return {
                ...state,
                logNote: [...state.logNote, action.payload],
            };
        case actions.DELETELOGNOTE:
            return {
                ...state,
                logNote: state.logNote.filter((entry: any) => entry.lotNotesId !== action.payload),
            };
        case actions.UPDATELOGNOTE:
            return {
                ...state,
                logNote: state.logNote.map((note: any) =>
                    note.sellerLogNotesId === action.payload.lotNotesId ? action.payload.updatedNote : note
                ),
            };
        case actions.RESETINV: {
            return initialState
        }
        case actions.SETLOTCTRLREC: {
            return { ...state, generatedLotCtrlRec: action.payload }
        }

        /** New changes for seperate tab API */

        case actions.SETMODE: {
            return { ...state, mode: action.payload }
        }
        case actions.SETLOTCLONETABS: {
            return { ...state, clonedTabs: action.payload }
        }
        case actions.SETLOTCREATETABS: {
            return { ...state, createTabs: action.payload }
        }
        case actions.ACTIVESTEP: {
            return { ...state, activeStep: action.payload }
        }
        case actions.SETLOTID: {
            return { ...state, lotId: action.payload }
        }
        case actions.SETPREVLOTID: {
            return { ...state, prevLotId: action.payload }
        }
        case actions.SETNEXTLOTID: {
            return { ...state, nextLotId: action.payload }
        }
        case actions.TOTALLOTS: {
            return { ...state, totalLots: action.payload }
        }
        case actions.INDEX: {
            return { ...state, index: action.payload }
        }
        case actions.SETCLONELOTID: {
            return { ...state, clonedLotId: action.payload }
        }
        case actions.SETAUCTIONDATA: {
            return { ...state, auctionData: action.payload }
        }
        case actions.SETSELLERIDFORCOMM: {
            return { ...state, commission: action.payload }
        }
        default:
            return state;
    }
}
