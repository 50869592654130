import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import InnerHeader from '../InnerHeader/InnerHeader';
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import Select from "react-select";
import { GETALLAUCTIONLIST, BALANCEDUEBYBIDDERREPORT } from '../../common/apiConstatnts';
import axios from 'axios';
import { useSelector } from 'react-redux';
import handleError from '../../Utils/ApiCommonErrorMessages';
import {generateBalanceDueByBidderPdf, generateBalanceDueByBidderCsv} from './ExportReportsPdfCsvFile';
import parsePhoneNumberFromString from 'libphonenumber-js';
import moment from 'moment';

const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Reports", url: routeConstant.REPORTS },
    { label: "Balance Due By Bidder ",url: routeConstant.BALANCEDUEBYBIDDER},
];

const BalanceDueByBidder = () => {   
    const [loading, setLoading] = useState(false);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [auctionList, setAuctionList] = useState<any []>([]);
    const [auctionCode, setAuctionCode] = useState('')
    const [balanceDueData, setBalanceDueData] = useState<any[]>([]);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)
    let AuctionStartDate = balanceDueData?.length > 0 ? balanceDueData[0].startDateTime : null;

    const config: any = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST, config).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    const getBalanceDueByBidder = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${BALANCEDUEBYBIDDERREPORT}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const balanceDueData = response.data.data;
                if (response?.data?.success === "Fail") {
                    setBalanceDueData([]);
                    return;
                } else {
                    setBalanceDueData(balanceDueData);
                    setLoading(false)
                }
            }).catch((error)=>{
                setLoading(false)
                handleError(error);
            })
        }   

    }

    useEffect(() => {
        getAllAuctions()
    }, [])

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    useEffect(() => {
        if (selectedAuction?.value) {
            getBalanceDueByBidder(selectedAuction.value);
        }
    }, [selectedAuction]);

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction?.value)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const totals = {
        totalInvoice : balanceDueData.reduce((acc, item) => acc + parseFloat(item.customerInvoiceTotal), 0),
        totalPaid : balanceDueData.reduce((acc, item) => acc + parseFloat(item.customerPaidToDateTotal), 0),
        totalBalanceDue : balanceDueData.reduce((acc, item) => acc + parseFloat(item.customerBalDueTotal), 0),
    };

    const totalLotLength = balanceDueData?.reduce((acc, data) => {
        return acc + (data?.lstInvoiceBalDue?.reduce((innerAcc:any, invoice:any) => {
            return innerAcc + (invoice?.lstLotInvoice?.length || 0);
        }, 0) || 0);
    }, 0);

    const formatPhoneNumber = (phoneNumber:any) => {
        try {
            const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
            const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber);
            if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                const countryCode = parsedPhoneNumber.countryCallingCode;
                const nationalNumber = parsedPhoneNumber.nationalNumber;                
                if (countryCode === '1') { 
                    const areaCode = nationalNumber.slice(0, 3);
                    const restOfNumber = nationalNumber.slice(3);
                    return `+1 (${areaCode}) ${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3)}`;
                } else {
                    return `+${countryCode}-${nationalNumber.slice(0, 5)}-${nationalNumber.slice(5)}`;
                }
            }
            return phoneNumber;
        } catch (error) {
            console.error('Error formatting phone number:', error);
            return phoneNumber;
        }
    };
    
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Balance Due By Bidder'>
                    <div className="col-12">
                        <div className="row justify-content-between g-2 mt-1 align-items-end">
                            <div className="col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="col-lg-8">
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Auction"
                                    className="isActive swap-to-lot-menu clone-inventory"                                    
                                    options={auctionList}
                                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                    value={selectedAuction.value ? auctionList.find((option: any) => option.value === selectedAuction.value): hammerAuction }
                                    onChange={(e: any) => {
                                        handleAuctionChange(e);
                                    }}
                                />
                                </div>                               
                            </div>
                            <div className="col-md-4 col-lg-3 mt-2 ms-auto">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=>generateBalanceDueByBidderCsv(balanceDueData, totals,totalLotLength )} disabled={balanceDueData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={()=> generateBalanceDueByBidderPdf( balanceDueData, totals,totalLotLength )} disabled={balanceDueData.length === 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                        {balanceDueData.length === 0 ?
                            getNoDataView() : 
                            <> 
                                <div className="table-responsive">
                                    <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                                <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Balance Due By Bidder</p>
                                                <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                    <span>{`Auction #${
                                                        hammerAuction 
                                                        ? hammerAuction?.label?.split('-')[0] 
                                                        : auctionCode 
                                                            ? auctionCode 
                                                            : selectedAuction?.value
                                                    }`}</span><br/><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                                </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                    <table className="w-100 mt-4" style={{ borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan={5} style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div></div></th>
                                                <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Invoice Total</div></th>
                                                <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Paid to Date</div></th>
                                                <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Balance Due</div></th>
                                            </tr>
                                        </thead>                                       
                                            <tbody>
                                                { balanceDueData?.map((data:any, key:any)=> {
                                                  return(
                                                    <>
                                                    <tr>
                                                        <td colSpan={5} style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1",}}><div><span className="fw-bold">{data.customerId} - {data.customerName}; {data.company && data.company}</span> {formatPhoneNumber(data.mobile)} </div></td>
                                                        <td style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.customerInvoiceTotal)}</div></td>
                                                        <td style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${data.customerPaidToDateTotal ? formatAmount(data.customerPaidToDateTotal) : '0.00'}</div></td>
                                                        <td style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.customerBalDueTotal)}</div></td>
                                                    </tr>
                                                    {data.lstInvoiceBalDue && data.lstInvoiceBalDue.length > 0 && data.lstInvoiceBalDue.map((invoiceData: any, invIndex: any) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td colSpan={5} style={{ fontSize: '11px', backgroundColor: '#fafafa', whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1" }}>
                                                                        <div>Invoice ID: {invoiceData.invoiceMasterId}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: '11px', backgroundColor: '#fafafa', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}>
                                                                        <div>${formatAmount(invoiceData.invoiceTotal)}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: '11px', backgroundColor: '#fafafa', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}>
                                                                        <div>${invoiceData.paidToDate ? formatAmount(invoiceData.paidToDate) : '0.00'}</div>
                                                                    </td>
                                                                    <td style={{ fontSize: '11px', backgroundColor: '#fafafa', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}>
                                                                        <div>${formatAmount(invoiceData.balDue)}</div>
                                                                    </td>
                                                                </tr>
                                                                {invoiceData.lstLotInvoice && invoiceData.lstLotInvoice.length > 0 && invoiceData.lstLotInvoice.map((lotData: any, index: any) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1" }}></td>
                                                                            <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1" }}>{lotData.lotNo}</td>
                                                                            <td colSpan={3} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1" }}>{lotData.lotTitle.replace(/<[^>]*>?/gm, "")}</td>
                                                                            <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}>
                                                                                ${formatAmount(lotData.lotPrize)}
                                                                            </td>
                                                                            <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}></td>
                                                                            <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1" }}></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })}
                                                 </>
                                                  )
                                                })}
                                            </tbody>                                
                                            <tfoot style={{marginTop: "5px"}}>
                                                <tr>
                                                    <th colSpan={5} style={{ fontSize: '12px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>{`Totals for ${totalLotLength} lots`}</div></th>
                                                    <th style={{ fontSize: '12px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${totals.totalInvoice ? formatAmount(totals.totalInvoice) : '0.00'}</div></th>
                                                    <th style={{ fontSize: '12px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${totals.totalPaid ? formatAmount(totals.totalPaid) : '0.00'}</div></th>
                                                    <th style={{ fontSize: '12px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${totals.totalBalanceDue ? formatAmount(totals.totalBalanceDue) : '0.00'}</div></th>
                                                </tr>
                                            </tfoot>
                                    </table>
                                </div>
                            </>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default BalanceDueByBidder;