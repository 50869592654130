import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as yup from 'yup';
import { AddressForm, onGetDefaultValues } from "../../../common/AddressForm";
import { GETCOMMONVALUE, GETSELLER, UPDATESALESREP } from "../../../common/apiConstatnts";
import { charCountForAddress } from "../../../common/commonFunctions";
import { wordCounter } from "../../../common/constants";
import { CommonMessages, SalesRepMessages } from "../../../common/messageConstants";
import { AlPHANUMERIC, COMPANYNAMEREGEX, FIRSTANDLASTNAMEREGEX, SPECIALCHARS } from "../../../common/regexConstants";
import * as routeConstant from '../../../common/routeConstants';
import { decryptId } from "../../../Encryption/Encryption";
import { ActiveStep, loader, SalesRepContact, setSaleRepId } from "../../../store/actions/SalesRepAction";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES } from "../../../Utils/Utils";

const ContactInfo = ({
    countryCodeNew,
    prevCountryCode
}: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const initalData = {
        saleRepId: "",
        companyId: localStorage.getItem('companyId'),
        initialsId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        title: "",
        // address: "",
        // city: "",
        // state: "",
        // country: "",
        // zipCode: "",
        latitude: '',
        longitude: '',
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        codes: "",
        notes: "",
        saleRepTypeId: "",
        isActive: true,
        add1: "",
        add2: "",
        city1: "",
        state1: "",
        country1: "",
        zip: "",
        county1: ''
    }
    const [initialFormValue, setFormValue] = useState<any>(initalData)
    const contactData = useSelector((state: any) => state?.salesRep?.contact);
    const saleRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const [initials, setIntials] = useState<[]>([])
    const [salesRepType, setSalesRepType] = useState<[]>([])
    const [country, setCountry] = useState<any>([])
    const [state, setState] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [isChanged, setIsChanged] = useState<any>(false)
    const [formValues, setFormValues] = useState<any>({})
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [location, setLocation] = useState('');
    const [latLog, setlatLog] = useState<any>(null);
    const [addressLine1, setAddressLine1] = useState<any>("");
    const [remainingCountAdd, setRemainingCountAdd] = useState(250);
    const [remainingCountAdd2, setRemainingCountAdd2] = useState(250);
    const [sellers, setSellers] = useState([])
    const maxWordCount = 150
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [flag, setFlag] = useState<any>('us')

    const contactDataNew = useSelector((state: any) => state.salesRep)

    const [address, setAddress] = useState('');
    const [isAddressFilled, setIsAddressFilled] = useState(false);

    const determineFlag = (mobileNumber: any) => {
        try {
            const parsedPhoneNumber = parsePhoneNumber(`+${mobileNumber}`);
            if (parsedPhoneNumber) {
                const countryCode = parsedPhoneNumber.country;
                // setCountryCode(countryCode);
                return countryCode
            } else {
                // setCountryCode('us');
            }
        } catch (error) {
            // setCountryCode('us');
        }
    };

    const [countryCode, setCountryCode] = useState('us');

    useEffect(() => {
        if (prevCountryCode) {
            setCountryCode(prevCountryCode);
        }
    }, [prevCountryCode]);

    useEffect(() => {
        if (prevCountryCode !== countryCode) {
            setValue('mobile', '');
            setValue('phone', '');
        }
    }, [address, prevCountryCode, countryCode])

    const generatePhoneValidationSchema = (countryCode: any) => {
        return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
            if (!value) return true;
            try {
                const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false; // Invalid phone number format
                }
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
                const expectedLength = value.length - countryCodeLength;
                return nationalNumber.toString().length === expectedLength;
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return false; // Return false if an error occurs during parsing
            }
        });
    };

    const schema: any = yup.object().shape({
        // initialsId: yup.string().required("Please select initials."),
        saleRepTypeId: yup.string().required("Please select Sales Rep Type."),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),
        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),

        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        company: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name'),
        title: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(AlPHANUMERIC, 'Please enter valid Title.').max(150, "Please enter upto 150 characters."),
        zip: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        mobile: generatePhoneValidationSchema(countryCode).required('Phone number is required.'),
        phone: generatePhoneValidationSchema(countryCode).nullable(),
        fax: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(/^(\d{10})*$/, 'Please enter number with 10 digits'),
        email: yup.string().required("Email address is required.")
            .test("is-email", "Email must be a valid email.", (value) => {
                if (!value) {
                    return true;
                }
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
            }).max(50, "Please enter upto 50 characters."),
        add1: yup.string().required("Address is required.").matches(SPECIALCHARS, 'Please enter valid address'),
        paymentTaxExempt: yup.boolean(),
        city1: yup.string().required('City is required.'),
        state1: yup.string().required('State is required.'),
        country1: yup.string().required('Country is required.'),
        codes: yup.string().nullable().max(150, "Please enter upto 150 characters."),
        notes: yup.string().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
    })

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        clearErrors,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' });

    const selectedCountry = watch('country');
    const selectedState = watch('state');
    const activationStatus = watch('isActive');
    const watchInitials = watch('initialsId')
    const saleRepTypeId = watch('saleRepTypeId')
    // const handleChangeAddressLine = (e: any) => {
    //     const inputText = e.target.value;
    //     const currentCount = inputText.length;
    //     const remaining = 250 - currentCount;

    //     if (remaining < 0) {
    //         return;
    //     }
    //     if (e.target.name === 'addressLine1') {
    //         setAddressLine1(e.target.value)
    //         charCountForAddress(e.target.value.length)
    //         clearErrors('address')
    //         setRemainingCountAdd(remaining);
    //     } else if (e.target.name === 'location') {
    //         setLocation(e.target.value)
    //         setRemainingCountAdd2(remaining);
    //     }
    // }

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        setIsAddressFilled(true);
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        } else if (e.target.name === 'addressLine2') {
            setRemainingCountAdd2(remaining);

        } else if ((e.target.name === 'location')) {
            setLocation(inputText)
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        }
    }

    const handleClose = () => {
        setToggleValue(!toggleModal);
        setLocation('');
    }

    const handleSubmitMap = () => {
        setLatitude(latLog?.lat());
        setLongitude(latLog?.lng());
        setToggleValue(!toggleModal);
    }

    const onSubmit = (data: any) => {
        dispatch(loader(true))
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const payload = {
                ...data,
                saleRepId: saleRepId,
                addressLine1: data.add1,
                address: data.add1,
                addressLine2: data.address2,
                country: parseInt(countryId),
                state: parseInt(stateId),
                city: parseInt(cityId),
                zipCode: data.zip,
                fax: data?.fax?.toString(),
                latitude: data?.latitude?.toString(),
                longitude: data?.longitude?.toString(),
                isActive: data.isActive,
                county: parseInt(countyId),
                countyName: data?.county1
            }
            const Message = ID ? SalesRepMessages.salesRepUpdated : SalesRepMessages.salesRepCreated

            axios.post(UPDATESALESREP, payload)
                .then((response) => {
                    dispatch(loader(false));
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                    else {
                        const salesRepId = response.data.data;
                        dispatch(setSaleRepId(salesRepId));
                        dispatch(SalesRepContact(payload));
                        toast.success(Message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        })
                        ID ? dispatch(ActiveStep('auctionInfo')) : navigate(-1);
                    }

                    dispatch(loader(false));
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error);
                });
        })
    }

    const handleNext = () => {
        dispatch(ActiveStep('auctionInfo'))
    }

    const getMobileNum = (phoneNumberString: any) => {
        try {
            let countryNCode = determineFlag(phoneNumberString)
            const phoneNumber = parsePhoneNumber(phoneNumberString, countryNCode ? countryNCode : 'US');
            if (phoneNumber) {
                const nationalNumber = phoneNumber.number;
                return nationalNumber;
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error parsing phone number:', error);
            return null;
        }
    };

    const setData = () => {
        reset(contactData);
        setValue('saleRepTypeId', contactData.saleRepTypeId)
        setAddressLine1(contactData.address)
        determineFlag(contactData?.mobile)
        setAddress(contactData.address)
        setValue('add1', contactData.address)
        setValue('city1', contactData.city1)
        setValue('state1', contactData.state1)
        setValue('country1', contactData.country1)
        setValue('zip', contactData.zipCode)
        setValue('county1', contactData?.countyName)
        setValue('mobile', contactData?.mobile)
        setValue('phone', contactData?.phone)
        setLatitude(contactData.latitude)
        setLongitude(contactData.longitude)
    }

    useEffect(() => {
        if (!selectedCountry) {
            setValue('city', "")
            setValue('state', "")
            setCity([])
            setState([])
            return
        } else {
            axios.get(`${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem('companyId')}&parentId=${selectedCountry}`).then((response) => {
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    return;
                }
                else {
                    const data = response.data.data
                    setState(data)
                    setValue('state', getValues('state'))
                    clearErrors('state')
                    clearErrors("country")
                    clearErrors('zipCode')
                    clearErrors('county1')
                    setFormValues({ ...formValues, country: selectedCountry })
                    const checkStateExists = data.filter((states: any) => states.objectValueId === getValues('state'))
                    if (checkStateExists.length === 0) {
                        setValue('city', "")
                        setValue('state', "")
                        setCity([])
                    }
                }
            }).catch((error) => {
                setValue('state', "")
                setValue('city', "")
                setValue('county1', "")
                setCity([])
                setState([])
                dispatch(loader(false));
                handleError(error);
            });
        }
    }, [selectedCountry, isChanged])

    useEffect(() => {
        if (!selectedState) {
            setValue('city', "")
            setCity([])
        } else {
            clearErrors(['state'])
            axios.get(`${GETCOMMONVALUE}?filter=City&companyId=${localStorage.getItem('companyId')}&parentId=${selectedState}`).then((response) => {
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    return;
                }
                else {
                    const data = response.data.data
                    setCity(data);
                    setValue('city', getValues('city'));
                    clearErrors('city');
                    setFormValues({ ...formValues, state: selectedState });
                    const checkCityExists = data.filter((cities: any) => cities.objectValueId === getValues('city'));
                    if (checkCityExists.length === 0) {
                        setValue('city', "");
                    }
                }
            }).catch((error) => {
                setValue('city', "")
                setCity([]);
                dispatch(loader(false));
                handleError(error);
            });
        }
    }, [selectedState, isChanged])

    useEffect(() => {
        setValue('address', addressLine1);
    }, [addressLine1])

    useEffect(() => {
        if (Object.keys(contactData).length > 0) {
            setData()
        }
    }, [contactData])

    useEffect(() => {
        if (Object.keys(contactData).length > 0) {
            setData()
        }
        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setIntials(formateData)
                if (contactData.initialsId !== "") {
                    setValue('initialsId', contactData.initialsId)
                }
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });

        axios.get(`${GETCOMMONVALUE}?filter=Country&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                setCountry(response.data.data)
                if (contactData?.country) {
                    setValue("country", contactData?.country)
                }
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });

        axios.get(`${GETCOMMONVALUE}?filter=SalesRepType&companyId=${localStorage.getItem('companyId')}`).then((response) => {
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setSalesRepType(formateData)
                if (contactData?.saleRepTypeId !== undefined || contactData?.saleRepTypeId !== "") {
                    setValue('saleRepTypeId', contactData.saleRepTypeId)
                    return
                }
                setValue('saleRepTypeId', "")
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
    }, [])


    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const handleSellerCheck = () => {

        if (activationStatus && sellers.length > 0) {
            toast.error('You can not inActive this sales representatives beacuse it is assign to a seller.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            setValue('isActive', true)
        }
    }
    useEffect(() => {
        if (ID && contactData.isActive) {
            axios.get(`${GETSELLER}?pageNumber=-1`, config).then((response: any) => {
                if (response?.data?.data?.success === "Fail") {
                    toast.error(response?.data?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    })
                    return;
                }
                else {
                    const sellerdata = response?.data?.data?.data
                    const id = decryptId(ID)
                    const foundObjects = sellerdata?.filter((obj: any) => obj?.salesPerson?.includes(id));
                    setSellers(foundObjects)

                }
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error);
            });
        }
    }, [ID])

    const updateCountryCode = (newAddress: any, code: any) => {
    };

    useEffect(() => {
        if (prevCountryCode !== countryCode) {
            setValue('mobile', '');
            setValue('phone', '');
        }
    }, [countryCode])

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10">
                        <div className="mt-4 mb-2 border-300">
                            <h4>Contact and Default Info</h4>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row g-3">
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                    <input type="text" {...register('firstName')} className="form-control" maxLength={20} placeholder="First Name" />
                                    {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input type="text" {...register('middleName')} className="form-control" maxLength={20} placeholder="Middle Name" />
                                    {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('lastName')} maxLength={20} placeholder="Last Name" />
                                    {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Sales Representative Type <sup className="text-danger">*</sup></label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Sales Representative Type"
                                        options={salesRepType}
                                        {...register('saleRepTypeId')}
                                        value={salesRepType?.find((option: any) => option?.value == saleRepTypeId)}
                                        onChange={(e: any) => setValue('saleRepTypeId', e.value)}
                                        className='isActive'
                                    />

                                    {errors.saleRepTypeId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.saleRepTypeId.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Company</label>
                                    <input type="text" className="form-control" {...register('company')} maxLength={100} placeholder="Company" />
                                    {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Title</label>
                                    <input className="form-control" type="text" {...register('title')} maxLength={151} placeholder="Title" />
                                    {errors.title && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.title.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('email')} maxLength={51} placeholder="Email" />
                                    {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                                </div>
                                <AddressForm
                                    errors={errors}
                                    onHandleChangeAddressLine={handleChangeAddressLine}
                                    register={register}
                                    setValue={setValue}
                                    setCountryCode={setCountryCode}
                                    onSetAddress={setAddress}
                                    addressLine2={true}
                                    addressFull={true}
                                    onAddressChange={updateCountryCode}
                                    watch={watch}
                                    trigger={trigger}
                                />
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>

                                    <Controller
                                        // name="mobile"
                                        {...register('mobile')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />

                                    {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Alternate Number </label>
                                    <Controller
                                        // name="phone"
                                        {...register('phone')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                </div>

                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Fax</label>
                                    <input type="text" className="form-control" {...register('fax')} maxLength={10} placeholder="Fax" />
                                    {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <label className="form-label">Codes</label>
                                    <input type="text" className="form-control" {...register('codes')} maxLength={50} placeholder="Codes" />
                                    {errors.codes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.codes.message}</span>}
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-label">Note</label>
                                    <textarea rows={3} className="form-control"
                                        maxLength={maxWordCount}
                                        {...register('notes', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                        placeholder="Note"
                                    />
                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}

                                    {errors.notes && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.notes.message}</span>}


                                </div>
                                <div className="row mb-4">
                                    {ID && (
                                        <>
                                            <div className="col-lg-4 mt-3 pl-2">
                                                <label>Status</label>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckDefault"
                                                        {...register('isActive')}
                                                        onClick={handleSellerCheck}

                                                    />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom">
                                <Link className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>
                                <button className="btn btn-primary mx-2 ms-auto" type="submit"
                                >{ID == undefined ? (CommonMessages.saveNext) : (CommonMessages.updatedNext)}</button>
                                {ID && <button className="btn btn-secondary ms-2" type="button" onClick={handleNext} disabled={saleRepId === 0}>{CommonMessages.skipButton}</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo;