import React, { useEffect, useState } from "react"
import Sidebar from "../../Sidebar/Sidebar"
import InnerHeaderTab from "../../InnerHeader/InnerHeaderTab/InnerHeaderTab"
import * as routeConstant from '../../../common/routeConstants'
import { Breadcrumb } from "../../../common/interface"
import { Tab, Tabs } from "react-bootstrap"
import ContactInfo from "./ContactInfo"
import { useDispatch, useSelector } from "react-redux"
import { ActiveStep, SalesRepAuction, SalesRepComm, SalesRepContact, setAdjustFromResp, setPaymentResp, setSaleRepId, SetCommissionOptions } from "../../../store/actions/SalesRepAction"
import axios from "axios"
import { GETALLSALESREPCOMMISSIONSCALE, GETSALESREPID } from "../../../common/apiConstatnts"
import { decryptId } from "../../../Encryption/Encryption"
import { toast } from "react-toastify"
import { v4 as uuidv4 } from 'uuid';
import SellerAssociated from "./SellerAssociated"
import AuctionInfoTabs from "./AuctionInfo"
import { parsePhoneNumber } from "libphonenumber-js"
import handleError from "../../../Utils/ApiCommonErrorMessages"
import CommonHelmet from "../../../common/HelmetWrapper"

const MoreInfo = () => {
    const dispatch = useDispatch()
    const tabActive = useSelector((state: any) => state.salesRep.activeTab);
    const salesRepData = useSelector((state: any) => state.salesRep?.contact);
    const saleRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const loader: boolean = useSelector((state: any) => state.salesRep.loading);
    const [activeTab, setActiveTab] = useState<string>(tabActive);
    const [loading, setLoading] = useState<boolean>(loader);
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const pageType = ID ? 'Edit Sales Representatives' : 'New Sales Representatives'

    const [countryCode, setCountryCode] = useState('us')
    const [prevCountryCode, setPrevCountryCode] = useState('us');

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Sales Representatives', url: routeConstant.SALESREPRESENTATIVE },
        { label: pageType, url: '' }
    ]

    const handleChange = (key: any) => {
        dispatch(ActiveStep(key));
    }

    const GetAllSalesRepCommissionScale = () => {
        setLoading(true);
        axios.get(GETALLSALESREPCOMMISSIONSCALE).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const scaleData = response.data.data.lstSalesRepCommissionScale;
                const filterData = scaleData.map((scale: any) => {
                    return {
                        label: scale.label,
                        salesRepCommissionScaleId: scale.salesRepCommissionScaleId,
                        salesRepCommissionBuyBack: scale.salesRepCommissionBuyBack,
                        salesRepCommissionNonPayer: scale.salesRepCommissionNonPayer,
                        salesRepCommissionSold: scale.salesRepCommissionSold,
                        salesRepCommissionUnSold: scale.salesRepCommissionUnSold,
                    }
                })
                let sold, unSold, buyBack, nonPay;
                let list: any = []
                for (let i = 0; i < filterData.length; i++) {
                    const scale = filterData[i]
                    const data = { label: scale.label, value: scale.salesRepCommissionScaleId };
                    list.push(data)
                    if (scale.salesRepCommissionSold) sold = scale.salesRepCommissionScaleId;
                    if (scale.salesRepCommissionUnSold) unSold = scale.salesRepCommissionScaleId;
                    if (scale.salesRepCommissionBuyBack) buyBack = scale.salesRepCommissionScaleId;
                    if (scale.salesRepCommissionNonPayer) nonPay = scale.salesRepCommissionScaleId;
                }
                dispatch(SetCommissionOptions({ list: list, sold: sold, unSold: unSold, buyBack: buyBack, nonPay: nonPay }))
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    useEffect(() => {
        setActiveTab(tabActive);
    }, [tabActive])

    useEffect(() => {
        setLoading(loader)
    }, [loader])

    useEffect(() => {
        if (ID) {
            setLoading(true)
            const decryptedId = decryptId(ID)
            axios.get(`${GETSALESREPID}?id=${decryptedId}`).then((response: any) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    const respData = response.data.data
                    const saleRepId = respData.saleRepId
                    const contactData = {
                        saleRepId: saleRepId,
                        initialsId: respData.initialsId,
                        firstName: respData.firstName,
                        middleName: respData.middleName,
                        lastName: respData.lastName,
                        saleRepTypeId: respData.saleRepTypeId === 0 ? "" : respData.saleRepTypeId,
                        company: respData.company,
                        title: respData.title,
                        address: respData.address,
                        city: respData.city,
                        state: respData.state,
                        country: respData.country,
                        zipCode: respData.zipCode,
                        email: respData.email,
                        phone: respData.phone,
                        mobile: respData.mobile,
                        fax: respData.fax ?? "",
                        codes: respData.codes,
                        notes: respData.notes,
                        latitude: respData.latitude,
                        longitude: respData.longitude,
                        isActive: respData.isActive,
                        city1: respData.cityName,
                        state1: respData.stateName,
                        country1: respData.countryName,
                        zip: respData.zipCode,
                        county: respData?.county,
                        countyName:respData?.countyName
                    }
                    const parsedNumber: any = parsePhoneNumber(`+${respData?.mobile}`);
                    const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
                    setCountryCode(respCountryCode)
                    setPrevCountryCode(respCountryCode)
                    const adj = respData?.lstSalesRepTax[0]
                    const pay = respData.lstSalesRepPaymenttax[0]

                    const modifyAdjList = respData?.lstSalesRepTax[0]?.lstsalesRepAdjusts.map((adjLstData: any) => {
                        return { ...adjLstData, isChange: false, isSubmit: true, adjustUniqueId: uuidv4() }
                    })
                    const modifiedAdj = { ...adj, adjustTaxExempt: adj?.adjustTaxExempt ?? false, lstsalesRepAdjusts: modifyAdjList }

                    const modifyPayList = respData?.lstSalesRepPaymenttax[0]?.lstsalesRepPayments.map((payLstData: any) => {
                        return { ...payLstData, isChange: false, isSubmit: true, paymentUniqueId: uuidv4() }
                    })
                    const modifiedPay = { ...pay, paymentTaxExempt: pay?.paymentTaxExempt ?? false, lstsalesRepPayments: modifyPayList }

                    dispatch(setSaleRepId(saleRepId));
                    dispatch(SalesRepAuction(respData.lstSalesRepSaleInAuction[0]));
                    dispatch(SalesRepContact(contactData));
                    dispatch(setAdjustFromResp(modifiedAdj));
                    dispatch(setPaymentResp(modifiedPay));
                    dispatch(SalesRepComm(respData.lstSalesRepCommission[0]));
                    GetAllSalesRepCommissionScale();
                }
                setLoading(false)
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        }
    }, [])

    return (
        <>
            <CommonHelmet title = {"Sales Rep - "} name = {"salesRep"} content={"Sales Rep" } />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0 for-fix-bottom-space">
                <InnerHeaderTab breadCrumb={breadcrumbData} title={ID ? "" : pageType}>
                    {ID && (
                        <div className="d-flex flex-wrap gap-2">
                            <h4>{pageType}</h4>
                            {salesRepData.saleRepId && <span> - ({salesRepData.saleRepId}) {salesRepData.firstName} {salesRepData?.middleName} {salesRepData.lastName}</span>}
                        </div>
                    )}
                    <Tabs
                        activeKey={activeTab}
                        className="nav-underline"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="contact" title="Contact and Default Info"></Tab>
                        {<Tab eventKey="auctionInfo" title="Auction Info" disabled={(saleRepId === 0)}></Tab>}
                        {<Tab eventKey="sellers" title="Sellers Associated" disabled={(saleRepId === 0)}></Tab>}
                    </Tabs>
                </InnerHeaderTab >
                {
                    (activeTab === 'contact') ?
                        <ContactInfo
                            countryCodeNew={countryCode}
                            prevCountryCode={prevCountryCode}
                        /> :
                        (activeTab === 'sellers') ?
                            <SellerAssociated /> :
                            (activeTab === 'auctionInfo') ?
                                <AuctionInfoTabs /> : <></>

                }
            </div >
        </>
    )
}

export default MoreInfo