import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import DateTime from "../../../../Utils/DateTime";
import MomentUtil from "../../../../Utils/MomentUtil";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { useCurrentDateTime } from "../../../../Utils/useDateTime";
import { DECODEVIN, GETLOTAUTOBYID, UPDATELOTAUTO } from '../../../../common/apiConstatnts';
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import { AlPHANUMERICTYPETWO, INTEGER, INTEGER_MESSAGE, SPECIALCHARS, YEAR, YEAR_MESSAGE } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setCloneTab, setCreateTab, setInventoryInfoActive, setStep } from "../../../../store/actions/Inventory";
import { LOTAUTO } from "../../InventoryConstants";
import { wordCounter } from "../../../../common/constants";

const Auto = () => {
    const dispatch = useDispatch()
    const reCountry = useSelector((state: any) => state?.global?.country) || [];
    const maxWordCountShort = 250;
    const [remainingCount2, setRemainingCount2] = useState(maxWordCountShort);

    const lotId = useSelector((state: any) => state.inventory.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
    const createTabs = useSelector((state: any) => state.inventory.createTabs)
    const mode = useSelector((state: any) => state.inventory.mode)

    let filterCountry = reCountry?.map((aData: any, index: any) => {
        return {
            label: aData.objectTypeValue, value: aData.objectValueId
        }
    })

    const [AddedVIN, setAddedVIN] = useState<any>(false)
    const [toggleVIN, setToggleVIN] = useState<any>(false)
    const [reportData, setReportData] = useState<any>({})

    const initialData = {
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        isDelete: false,
        lotAutoId: 0,
        lotId: lotId,
        year: '',
        country: '',
        manufacturer: '',
        vehicleType: '',
        model: '',
        bodyStyle: '',
        color: '',
        doors: '',
        idNumber: '',
        itemAssetNumber: '',
        plateNumber: '',
        plateState: '',
        noOfPlate: 0,
        plateExpiration: '',
        odometer: '',
        units: '',
        actual: '',
        vin1: '',
        vin2: '',
        titledUnit: false,
        titleRecord: '',
        titleSent: '',
        smogComplete: '',
        infoTitle: '',
        acquiredDate: '',
        dateRemoved: '',
        engine: "",
        cylinder: "",
        fuelType: "",
        transmission: "",
        driveType: "",
        itemLocation: '',
        equipmentContact: '',
        phoneNumber: '',
        notesField: '',
        asInWhereIs: '',
        autoCustomField: '',
        displayVin: "VIN1"
    }

    const vinOption = [
        { value: "VIN1", label: "VIN 1" },
        { value: "VIN2", label: "VIN 2" },
    ]

    const [selectedVIN, setSelectedVIN] = useState<any>('');
    const [vin1Value, setVin1Value] = useState('');
    const [vin2Value, setVin2Value] = useState('');
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
    const dateTime = useCurrentDateTime()
    const [initialFormValues, setInitialFormValues] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState([]);
    const [btnText, setBtnTxt] = useState<any>("")

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const schema: any = yup.object().shape({
        year: yup
            .string()
            // .required('Year is required.')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            })
            .matches(YEAR, YEAR_MESSAGE),
        country: yup
            .string()
            // .required('Country is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        manufacturer: yup.
            string()
            // .required('Manufacturer is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).max(49, 'Please enter upto 50 characters.'),
        vehicleType: yup
            .string()
            // .required('Vehicle is required')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        model: yup
            .string()
            // .required('Model is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).max(49, 'Please enter upto 50 characters.'),
        bodyStyle: yup
            .string()
            // .required('Body Style is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).max(150, 'Please enter upto 150 characters.'),
        color: yup
            .string()
            // .required('Color is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).max(49, 'Please enter upto 50 characters.'),
        noOfPlate: yup
            .string()
            // .required('No of Plate is required.')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(INTEGER, INTEGER_MESSAGE),
        doors: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        idNumber: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        itemAssetNumber: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        plateNumber: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        plateState: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        plateExpiration: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        odometer: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        units: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        actual: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE),
        vin1: yup.string().nullable().required("VIN is required.").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).matches(AlPHANUMERICTYPETWO, 'Please enter valid VIN Number').when({
            is: (value: any) => value !== null && value !== undefined && value !== '',
            then: (schema) => schema.min(17, 'Please enter valid VIN Number'),
        }),
        vin2: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).matches(AlPHANUMERICTYPETWO, 'Please enter valid VIN Number').when({
            is: (value: any) => value !== null && value !== undefined && value !== '',
            then: (schema) => schema.min(17, 'Please enter valid VIN Number'),
        }),
        // titleRecord: yup.string().nullable().required("Title Recv'd is required."),
        titleSent: yup.string().test('valid-datetime', 'Invalid datetime', function (value) {
            // const parsedDate: any = new Date(value);
            // const currentDate: any = UTCdateConverter(new Date())
            // if (parsedDate.getFullYear() < 2000 ||
            //     parsedDate.getFullYear() > 2500 || !ID && parsedDate - currentDate < 0) {
            //     return this.createError({
            //         message: 'Please enter a valid date that is greater than the current UTC date.',
            //     });
            // }
            // return !isNaN(parsedDate);
            const parsedDate = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ" });
            const currentDate = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });

            if (
                parsedDate.year() < 2000 ||
                parsedDate.year() > 2500 ||
                (parsedDate.diff(currentDate, 'minutes') < 0)
            ) {
                return this.createError({
                    message: 'Please enter a valid date that is greater than the current UTC date.',
                });
            }

            // Calculate the difference in minutes between parsed date and current date
            const timeDifferenceInMinutes = parsedDate.diff(currentDate, 'minutes');

            if (timeDifferenceInMinutes < 0 && !window.location.pathname.includes('update-inventory')) {
                return this.createError({
                    message: 'The start time must be in the future.',
                });
            }

            return true;

        }),
        smogComplete: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Smog Complete').max(49, 'Please enter upto 50 characters.'),
        infoTitle: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Info Title').max(49, 'Please enter upto 50 characters.'),
        notesField: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Notes Field').max(250, 'Please enter upto 250 characters.'),
        asInWhereIs: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid As In, Where Is').max(49, 'Please enter upto 50 characters.'),
        autoCustomField: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Auto Custom Field').max(49, 'Please enter upto 50 characters.'),
        itemLocation: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Item Location').max(49, 'Please enter upto 50 characters.'),
        equipmentContact: yup.string().nullable().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Equipment Contact').max(49, 'Please enter upto 50 characters.'),
    })

    const {
        register,
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialData, mode: 'onChange' });

    const dVIN1 = watch('vin1')
    const titledUnit = watch('titledUnit')
    const titleSent = watch('titleSent')
    const dVIN2 = watch('vin2')
    const titleRecord = watch('titleRecord')
    const displayVin = watch('displayVin')
    const watchCountry = watch('country')

    const VINschema: any = yup.object().shape({
        VIN1: yup.string().matches(AlPHANUMERICTYPETWO, 'Please enter valid VIN Number').min(17, 'Please enter valid VIN Number'),
        VIN2: yup.string().matches(AlPHANUMERICTYPETWO, 'Please enter valid VIN Number').min(17, 'Please enter valid VIN Number'),
    })

    const {
        register: VINRegister,
        handleSubmit: VINHandleSubmit,
        control: VINControl,
        getValues: VINGetValues,
        reset: VINReset,
        setValue: VINSetValues,
        watch: VINWatch,
        clearErrors: VINClearErrors,
        formState: { errors: VINErrors, isValid: VINIsValid },
    } = useForm({ resolver: yupResolver(VINschema), defaultValues: { VIN1: '', VIN2: '' }, mode: 'onChange' });

    const vin1 = VINWatch('VIN1')
    const vin2 = VINWatch('VIN2')

    useEffect(() => {
        getInventoryAutoById()
        setCountry(filterCountry)
    }, []);

    const getInventoryAutoById = () => {
        setLoading(true)
        const id = mode === "clone" ? cloneLotId : lotId
        axios.get(`${GETLOTAUTOBYID}?lotId=${id}`, { ...config }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                return;
            } else {
                let data = response.data.data;
                const titleRecord = data.titleRecord ? momentUtil.parseTimeInUtc({ dateTimeString: data?.titleRecord }) : "";
                const titleSent = data.titleSent ? momentUtil.parseTimeInUtc({ dateTimeString: data?.titleSent }) : "";
                const acquiredDate = data.acquiredDate ? momentUtil.parseTimeInUtc({ dateTimeString: data?.acquiredDate }) : "";
                const dateRemoved = data.dateRemoved ? momentUtil.parseTimeInUtc({ dateTimeString: data?.dateRemoved }) : "";
                data = {
                    ...data,
                    titleRecord: titleRecord,
                    titleSent: titleSent,
                    acquiredDate: acquiredDate,
                    dateRemoved: dateRemoved
                }
                if (data.vin2 !== "") {
                    setAddedVIN(true)
                }
                reset(data)
                // dispatch(setInventoryData(lotData));
            }
        }).catch((error) => {
            setLoading(false);
        });
    }

    const onSubmit = () => {
        const dataOriginal = getValues();
        const autoData = trimObjectValues(dataOriginal);
        setLoading(true)
        const titleSent_parsed = dataOriginal.titleSent ? momentUtil.convertTimeToUtc(dataOriginal.titleSent) : null
        const titleRecord_parsed = dataOriginal.titleRecord ? momentUtil.convertTimeToUtc(dataOriginal.titleRecord) : null
        const acquiredDate_parsed = autoData.acquiredDate ? momentUtil.convertTimeToUtc(autoData.acquiredDate) : ""
        const dateRemoved_parsed = autoData.dateRemoved ? momentUtil.convertTimeToUtc(autoData.dateRemoved) : ""
        axios.post(UPDATELOTAUTO, {
            ...autoData,
            lotId: mode === "clone" ? cloneLotId : lotId,
            isDelete: false,
            isActive: true,
            noOfPlate: autoData.noOfPlate === "" ? 0 : autoData.noOfPlate,
            country: autoData.country.toString(),
            titleRecord: titleRecord_parsed,
            titleSent: titleSent_parsed,
            acquiredDate: acquiredDate_parsed,
            dateRemoved: dateRemoved_parsed
        }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            } else {
                toast.success(InventoryMessages.autoUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, LOTAUTO]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, LOTAUTO]))
                }
                handleNext()
            }
        }, (error) => {
            setLoading(false);
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        })
    }

    const handleNext = () => {
        dispatch(setStep("titleAssignment"))
        // dispatch(setInventoryInfoActive({ activeTab: "records" }));
    }

    const handlePrevious = () => {
        dispatch(setStep('commCost'))
    }

    const onLookupSave = (vinType: any) => {
        setLoading(true)
        let vinValue = vinType == 'vin1' ? VINWatch('VIN1') : VINWatch('VIN2')
        const VIN2 = '5YJSA3DS*EF'
        const VIN1 = '5UXWX7C5*BA'

        axios.get(`${DECODEVIN}/${vinValue}?format=json`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            } else {
                const data = response?.data.Results[0]
                const userFilledData = getValues()
                const mainData = {
                    ...userFilledData,
                    vin1: data.VIN,
                    model: data.Model,
                    vehicleType: data.VehicleType,
                    manufacturer: data.Manufacturer,
                    year: data.ModelYear,
                    country: data.PlantCountry,
                    bodyStyle: data.BodyClass,
                    doors: data.Doors,
                    idNumber: data.ModelID,
                    engine: data.EngineModel,
                    cylinder: data.EngineCylinders,
                    fuelType: data.FuelTypePrimary,
                    transmission: data.TransmissionStyle,
                    driveType: data.DriveType
                }
                reset(mainData)
                setReportData(mainData)
                handleClose()
                toast.success(InventoryMessages.autoVinVerified, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            handleError(error);
        })
    }

    const generateExcelWithTitle = () => {
        let data = reportData
        const wb = XLSX.utils.book_new();
        const wsData = [
            // [`Seller Name:${lotsData[0].sellerfirstName} ${lotsData[0].sellerlastName}`],
            [`VIN Report`],
            [`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`],
            [],
            ["VIN 1",
                "Model",
                "Vehicle Type",
                "Manufacturer",
                "Model Year",
                "Country",
                "BodyClass",
                "Doors",
                "Id Number",
                "Engine",
                "Cylinder",
                "Fuel Type",
                "Transmission",
                "Drive Type",
                "Plate State",
                "Units",
                "Color",
                "Item Asset Number",
                "Plate Number",
                "No Of Plate",
                "Plate Expiration",
                "Odometer",
                "Actual",
                "vin2",
                "Titled Unit",
                "Title Record",
                "Title Sent",
                "Smog Complete",
                "Info Title",
                "Acquired Date",
                "Date Removed"],
            [...Object.values(reportData)],
            // ...data.map((item: any) => Object.values(item))
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'VIN_Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
        setReportData({})
        setToggleVIN(false)
    };

    const handleVINSelect = (event: any) => {
        setSelectedVIN(event.target.value);
    };

    const loadValues = () => {
        setToggleVIN(true)
        VINSetValues('VIN1', dVIN1)
        VINSetValues('VIN2', dVIN2)
        setVin1Value(dVIN1)
        setVin2Value(dVIN2)
        VINClearErrors(['VIN1', 'VIN2'])
    }

    const handleClose = () => {
        setToggleVIN(false)
        if (!VINErrors.VIN1) {
            setValue('vin1', vin1Value)
        }
        if (!VINErrors.VIN2) {
            setValue('vin2', vin2Value)
        }
    }

    /* useEffect(() => {
        // setLoading(true)
        if (lotPage[0]) {
            const modifyData = {
                ...lotPage[0],
                titleRecord: lotPage[0].titleRecord ? new Date(lotPage[0].titleRecord) : '',
                titleSent: lotPage[0].titleSent ? new Date(lotPage[0].titleSent) : '',
                acquiredDate: lotPage[0].acquiredDate ? new Date(lotPage[0].acquiredDate) : '',
                dateRemoved: lotPage[0].dateRemoved ? new Date(lotPage[0].dateRemoved) : ''

            };
            setInitialFormValues(modifyData)
            reset(modifyData)
            if (lotPage[0].vin2) {
                setAddedVIN(true)
            }
        }
        // onLookupSave()
        // setLoading(false)
    }, []); */

    useEffect(() => {
        clearErrors(['titleRecord']);
    }, [titleRecord, titleSent]);

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTAUTO)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTAUTO)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <Modal show={toggleVIN} className="md-modal" centered>
                <form >
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">
                            VIN Lookup
                        </h5>
                        <button
                            className="btn p-1"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mb-2">
                            <p className=" mb-3 fw-bold">Select which field to save the VIN and VIN Report to.</p>
                            <div className="col-lg-12 mb-3">
                                <label className="form-label">VIN {AddedVIN ? "1" : ""}</label>
                                <input
                                    className="form-control form-icon-input"
                                    placeholder={`VIN ${AddedVIN ? "1" : ""}`}
                                    maxLength={17}
                                    onKeyDown={handleKeyDown}
                                    {...VINRegister('VIN1', {
                                        onChange: (e: any) => setVin1Value(e.target.value)
                                    })}
                                />
                                {VINErrors.VIN1 && <span className='text-danger' style={{ fontSize: "12px" }}>{VINErrors.VIN1.message}</span>}
                            </div>
                            {AddedVIN && <div className="col-lg-12">
                                <label className="form-label">VIN 2</label>
                                <input
                                    className="form-control form-icon-input"
                                    placeholder="VIN 2"
                                    maxLength={17}
                                    onKeyDown={handleKeyDown}
                                    {...VINRegister('VIN2', {
                                        onChange: (e: any) => setVin2Value(e.target.value)
                                    })}
                                />
                                {VINErrors.VIN2 && <span className='text-danger' style={{ fontSize: "12px" }}>{VINErrors.VIN2.message}</span>}
                            </div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <div className="d-flex justify-content-between"> */}

                        <div className="w-100 d-flex flex-wrap align-items-center justify-content-between gap-2">
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-dismiss="modal"
                                value={vin1Value}
                                disabled={VINErrors.VIN1 ? true : false}
                                onClick={() => onLookupSave('vin1')}
                            >
                                VIN {AddedVIN ? "1" : ""}
                            </button>
                            {AddedVIN && <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-dismiss="modal"
                                value={vin2Value}
                                disabled={(VINErrors.VIN2 || vin2Value === "") ? true : false}
                                onClick={() => onLookupSave('vin2')}
                                onChange={(e: any) => setVin2Value(e.target.value)}
                            >
                                VIN 2
                            </button>}
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-dismiss="modal"
                                disabled={Object.values(reportData).length ? false : true}
                                onClick={() => generateExcelWithTitle()}
                            >
                                Generate Report
                            </button>
                            <button
                                className="btn btn-outline-danger ms-auto"
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            {/* <button
                                className="btn btn-primary"
                                type="button"
                            >
                                Save
                            </button> */}
                        </div>
                        {/* </div> */}
                    </Modal.Footer>
                </form>
            </Modal>
            <div>
                <div className="row top_border justify-content-center g-3 mt-0">
                    <div className="col-xxl-10">
                        <div className="mb-3">
                            <h4>Specs - Title - Engine</h4>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {loading && <div className="loader-wrap">
                                <img src={`${localStorage.getItem('globalLoader')}`} />
                            </div>}
                            <div className="row g-3 mb-4">
                                <div className="col-12 mt-0">
                                    <div className="row align-items-start">
                                        <div className="col-lg-3 mt-3">
                                            <label className="form-label ">VIN {AddedVIN ? "1" : ""}<sup className="text-danger">*</sup></label>
                                            <input className="form-control form-icon-input"
                                                placeholder={`VIN ${AddedVIN ? "1" : ""}`}
                                                maxLength={17}
                                                {...register('vin1')}
                                                onKeyDown={handleKeyDown}
                                            />
                                            {errors.vin1 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.vin1.message}</span>}
                                        </div>
                                        <div className="col-lg-5 mt-3">
                                            <div className="d-flex flex-wrap align-items-end">
                                                {AddedVIN ?
                                                    <div className="button_minus_input d-flex flex-wrap align-items-end">
                                                        <div className="vin_btn">
                                                            <div className="w-100"><label className="form-label ">VIN 2</label></div>
                                                            <input className="form-control form-icon-input"
                                                                placeholder="VIN 2"
                                                                maxLength={17}
                                                                {...register('vin2')}
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </div>
                                                        <span className="vin_close_btn" onClick={() => setAddedVIN(false)}>  </span>
                                                        {errors.vin2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.vin2.message}</span>}
                                                    </div>
                                                    :
                                                    <div className="mt-4">
                                                        <span className="btn btn-primary" role='button' onClick={() => setAddedVIN(true)}>
                                                            <span className="fas fa-plus" data-fa-transform="shrink-3"></span> Add VIN</span>
                                                    </div>
                                                }
                                                <button className="btn btn-primary ms-2 mt-3" type='button' disabled={(errors.vin1 || errors.vin2) ? true : false} onClick={loadValues}>VIN Lookup</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-3">
                                            <label className="form-label">Notes</label>
                                            <textarea
                                                className="form-control"
                                                aria-label="With textarea"
                                                placeholder="Notes"
                                                maxLength={maxWordCountShort}
                                                {...register("notesField", {
                                                    onChange: (e) =>
                                                        setRemainingCount2(
                                                            wordCounter(e.target.value, maxWordCountShort)
                                                        ),
                                                })}
                                            />
                                            {remainingCount2 != maxWordCountShort && (
                                                <div
                                                    className={remainingCount2 <= 0 ? "text-danger" : ""}
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                        width: "100%",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {remainingCount2 <= 0
                                                        ? "Maximum word limit reached"
                                                        : `Remaining words: ${remainingCount2}`}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {AddedVIN === true ?
                                    <>
                                        <div className="code-to-copy col-lg-3">
                                            <div className="w-100"><label className="form-label ">Display Vin</label></div>
                                            <Select
                                                classNamePrefix="dpaSelect"
                                                placeholder="Select"
                                                className='isActive'
                                                {...register('displayVin')}
                                                name='displayVin'
                                                options={vinOption}
                                                value={vinOption?.find((option: any) => option?.value == displayVin)}
                                                onChange={(e: any) => setValue('displayVin', e.value)}
                                            />

                                        </div>
                                    </>
                                    : <></>
                                }
                                <div className="col-lg-3">
                                    <label className="form-label">Year</label>
                                    <input className="form-control form-icon-input"
                                        type="text"
                                        maxLength={4}
                                        {...register('year')}
                                        placeholder="Enter a year (e.g., 2023)"
                                    />
                                    {errors.year && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.year.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label" >Country </label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Country"
                                        {...register('country')}
                                    />
                                    {/* <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className='isActive'
                                        {...register('country')}
                                        name='country'
                                        options={country}
                                        value={country?.find((option: any) => option?.value == watchCountry)}
                                        onChange={(e: any) => setValue('country', e.value)}
                                    /> */}
                                    {errors.country && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.country.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Manufacturer</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Manufacturer"
                                        maxLength={50}
                                        {...register('manufacturer')} />
                                    {errors.manufacturer && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.manufacturer.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Vehicle Type</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Vehicle Type"
                                        maxLength={50}
                                        {...register('vehicleType')} />
                                    {errors.vehicleType && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.vehicleType.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Model</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Model"
                                        maxLength={50}
                                        {...register('model')} />
                                    {errors.model && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.model.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Body Style</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Body Style"
                                        maxLength={150}
                                        {...register('bodyStyle')} />
                                    {errors.bodyStyle && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.bodyStyle.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Color</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Color"
                                        maxLength={50}
                                        {...register('color')} />
                                    {errors.color && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.color.message}</span>}

                                </div>

                                <div className="col-lg-3">
                                    <label className="form-label">Doors</label>
                                    <input className="form-control form-icon-input"
                                        maxLength={10}
                                        {...register('doors')}
                                        placeholder="Doors"
                                        onKeyDown={handleKeyDown}
                                    />
                                    {errors.doors && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.doors.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Id Number</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Id Number"
                                        maxLength={10}
                                        {...register('idNumber')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.idNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.idNumber.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label ">Item Asset Number</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Item Asset Number"
                                        maxLength={10}
                                        {...register('itemAssetNumber')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.itemAssetNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.itemAssetNumber.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Plate Number</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Plate Number"
                                        maxLength={10}
                                        {...register('plateNumber')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.plateNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.plateNumber.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label ">Plate State</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Plate State"
                                        maxLength={10}
                                        {...register('plateState')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.plateState && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.plateState.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Number of Plates</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Number of Plates"
                                        maxLength={10}
                                        {...register('noOfPlate')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.noOfPlate && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.noOfPlate.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Plate expiration</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Plate expiration"
                                        maxLength={10}
                                        {...register('plateExpiration')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.plateExpiration && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.plateExpiration.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Odometer</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Odo Meter"
                                        maxLength={10}
                                        {...register('odometer')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.odometer && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.odometer.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label ">Unit</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Unit"
                                        maxLength={10}
                                        {...register('units')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.units && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.units.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Actual</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Actual"
                                        maxLength={10}
                                        {...register('actual')}
                                        onKeyDown={handleKeyDown} />
                                    {errors.actual && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.actual.message}</span>}
                                </div>

                                <div className="col-lg-3">
                                    <label className="form-check-label text-900 fw-400">Titled Unit</label>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input form-check-states"
                                            type="checkbox"
                                            id="titledUnit"
                                            placeholder="Title Unit"
                                            {...register('titledUnit', {
                                                onChange: (e) => {
                                                    e.target.checked && setValue('titleRecord', `${moment(new Date()).format('MM/DD/y hh:mm A')}`)
                                                }
                                            })}

                                        />
                                        <label className="form-check-label text-900 fw-400" htmlFor="titledUnit">Yes</label>
                                    </div>
                                </div>

                                {/* <div className="col-lg-3">
                                    <label className="form-label">Title Rec'd</label>
                                    <input className="form-control form-icon-input"
                                    placeholder="Title Rec'd"
                                        maxLength={50}
                                        {...register('titleRecord')} />
                                    {errors.titleRecord && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.titleRecord.message}</span>}
                                </div> */}
                                <div className="col-lg-3">
                                    <label className="form-label">Title Recv'd</label>
                                    <Controller
                                        name="titleRecord"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    onChange={onChange}
                                                    dateFormat="MM/DD/y"
                                                    timeFormat="hh:mm A"
                                                    value={value}
                                                    closeOnSelect={true}
                                                    inputProps={
                                                        {
                                                            placeholder: 'Select Date and Time',
                                                        }}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.titleRecord && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.titleRecord.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label"><Link to='' onClick={() => { setValue('titleSent', `${moment(new Date()).format('MM/DD/y hh:mm A')}`) }}>Title Sent</Link></label>
                                    <Controller
                                        name="titleSent"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon" onClick={() => {
                                                if (titleSent?.length == 0) {
                                                    setValue('titleSent', `${moment(new Date()).format('MM/DD/y hh:mm A')}`)
                                                }
                                            }}>
                                                <DateTime
                                                    onChange={onChange}
                                                    dateFormat="MM/DD/y"
                                                    timeFormat="hh:mm A"
                                                    closeOnSelect={true}
                                                    value={value}
                                                    // open={false}
                                                    inputProps={
                                                        {
                                                            placeholder: 'Select Date and Time',
                                                        }}
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.titleSent && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.titleSent.message}</span>}

                                </div>
                                {/* <div className="col-lg-4">
                                    <label className="form-label">Title Sent</label>
                                    <input className="form-control form-icon-input"
                                    placeholder="Title Sent"
                                        maxLength={50}
                                        {...register('titleSent')} />
                                    {errors.titleSent && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.titleSent.message}</span>}
                                </div> */}
                                <div className="col-lg-3">
                                    <label className="form-label">Smog complete</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Smog Complete"
                                        maxLength={50}
                                        {...register('smogComplete')} />
                                    {errors.smogComplete && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.smogComplete.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Info Title</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Info Title"
                                        maxLength={50}
                                        {...register('infoTitle')} />
                                    {errors.infoTitle && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.infoTitle.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Acquired Date</label>
                                    <Controller
                                        name="acquiredDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    onChange={onChange}
                                                    value={value}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    dateFormat="MM/DD/y"
                                                    inputProps={{
                                                        placeholder: "Acquired Date",
                                                        className: "form-control form-icon-input only_date_icon",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Removed Date</label>
                                    <Controller
                                        name="dateRemoved"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    onChange={onChange}
                                                    value={value}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    dateFormat="MM/DD/y"
                                                    inputProps={{
                                                        placeholder: "Removed Date",
                                                        className: "form-control form-icon-input only_date_icon",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Engine</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Engine"
                                        {...register('engine')} />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Cylinders</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Cylinders"
                                        {...register('cylinder')} />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Fuel Type</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Fuel Type"
                                        {...register('fuelType')} />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Transmission</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Transmission"
                                        {...register('transmission')} />
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Drive Type</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Drive Type"
                                        {...register('driveType')} />
                                </div>
                                {/* <div className="col-lg-4">
                                    <label className="form-label">Engine</label>
                                    <select className="form-select" aria-label="Default select example" {...register('engine')}>
                                        <option value="">Select Engine Type</option>
                                        {
                                            engineType.map((initialData: any, index) => {
                                                return (
                                                    <option key={index} value={index}>{initialData.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                                {/* <div className="col-lg-4">
                                    <label className="form-label">Cylinders</label>
                                    <select className="form-select" aria-label="Default select example" {...register('cylinder')}>
                                        <option value="">Select Cylinder</option>
                                        {
                                            cylinderType.map((initialData: any, index) => {

                                                return (
                                                    <option key={index} value={index}>{initialData.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                                {/* <div className="col-lg-4">
                                    <label className="form-label">Fuel Types</label>
                                    <select className="form-select" aria-label="Default select example" {...register('fuelType')}>
                                        <option value="">Select Engine Type</option>
                                        {
                                            fuelType.map((initialData: any, index) => {

                                                return (
                                                    <option key={index} value={index}>{initialData.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                                {/* <div className="col-lg-6">
                                    <label className="form-label">Transmission</label>
                                    <select className="form-select" aria-label="Default select example" {...register('transmission')}>
                                        <option value="">Select Engine Type</option>
                                        {
                                            transmissionType.map((initialData: any, index) => {

                                                return (
                                                    <option key={index} value={index}>{initialData.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label">Drive Type</label>
                                    <select className="form-select" aria-label="Default select example" {...register('driveType')}>
                                        <option value="">Select Engine Type</option>
                                        {
                                            driveType.map((initialData: any, index) => {

                                                return (
                                                    <option key={index} value={index}>{initialData.label}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> */}
                                <div className="col-lg-6">
                                    <label className="form-label">Item Location</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Item Location"
                                        maxLength={50}
                                        {...register('itemLocation')} />
                                    {errors.itemLocation && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.itemLocation.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Alternate Number</label>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country="us"
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phoneNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phoneNumber.message}</span>}

                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Equipment Contact</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Equipment Contact"
                                        maxLength={50}
                                        {...register('equipmentContact')} />
                                    {errors.equipmentContact && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.equipmentContact.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">As In, Where Is</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="As In, Where Is"
                                        maxLength={50}
                                        {...register('asInWhereIs')} />
                                    {errors.asInWhereIs && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.asInWhereIs.message}</span>}
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Auto Custom Field</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Auto Custom Field"
                                        maxLength={50}
                                        {...register('autoCustomField')} />
                                    {errors.autoCustomField && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.autoCustomField.message}</span>}
                                </div>
                                {/* <div className="col-lg-4">
                                    <label className="form-label">Note Field</label>
                                    <input className="form-control form-icon-input"
                                        placeholder="Note Field"
                                        maxLength={50}
                                        {...register('notesField')} />
                                    {errors.notesField && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.notesField.message}</span>}
                                </div> */}

                                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                                    <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
                                    <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
                                    <button className="btn btn-secondary ms-2" onClick={handleNext}>{CommonMessages.skipButton}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Auto;