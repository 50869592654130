import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { AddressForm, onGetDefaultValues } from "../../../common/AddressForm";
import { CREATESELLERCONTACT, UPDATEAUCTIONSELLER,GETALLSELLERCOMMISSIONSCALE, GETBYSELLERID, GETCOMMONVALUE, GETCUSTOMERBYEMAIL, GETSALESREPRESENTATIVE, SELLERLOTS, UPDATESELLERCONTACT } from "../../../common/apiConstatnts";
import { charCountForAddress } from "../../../common/commonFunctions";
import { sellerStatusOptions, wordCounter } from "../../../common/constants";
import { CommonMessages, SellerMessages } from "../../../common/messageConstants";
import { COMPANYNAMEREGEX, EMAIL, EMAIL_MESSAGE, FIRSTANDLASTNAMEREGEX, INTEGER, INTEGER_MESSAGE, SPECIALCHARS } from "../../../common/regexConstants";
import * as routeConstant from '../../../common/routeConstants';
import { decryptId, encryptId } from "../../../Encryption/Encryption";
import { setAddressTypes } from "../../../store/actions/CommonAction";
import { sellerLots, setActiveTab, setContractDocumentData, setNextSellerId, setPrevSellerId, setSellerId, setSellerIndex, setTotalSellers, updateSeller } from "../../../store/actions/Seller";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES, handleKeyDown } from "../../../Utils/Utils";

const ContactDefault = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const companyId: any = localStorage.getItem('companyId')
    const sellerData = useSelector((state: any) => state.seller);
    const modalData = useSelector((state: any) => state.seller?.contact);
    const addressTypes = useSelector((state: any) => state.global.addressTypes);
    const [initials, setIntials] = useState<[]>([]);
    const [addressType, setAddressType] = useState<any>([]);
    const [salesPerson, setSalesPerson] = useState<any>([]);
    const [taxVal, setTaxVal] = useState(sellerData?.exempt ?? false);
    const [loading, setLoading] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [cnfError, setCnfError] = useState<any>(false);
    const [existingEmail, setExistingEmail] = useState<any>();
    const [data, setData] = useState<any>();
    const [sellerStatusData, setSellerStatusData]: any = useState(null);
    const [address, setAddress] = useState('');
    const [isAddressFilled, setIsAddressFilled] = useState(false);
    const [inputMail, setInputMail] = useState();
    const [remainingCountAdd, setRemainingCountAdd] = useState(250);
    const [remainingCountAdd2, setRemainingCountAdd2] = useState(250);
    const [location, setLocation] = useState('');
    const [foundEmail, setFoundEmail] = useState('');
    const [reportingIsSame, setReportingIsSame] = useState(false);
    const maxWordCount2 = 250
    const [remainingCountForNote, setRemainingCountForNote] = useState(maxWordCount2)
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount2);
    const [sellerID, setSellerID]=useState<any>();
    const getGlobalAuctionIds = useSelector(
        (state: any) => state?.global?.auctionHammerData?.value
    );
    const AuctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);
    // useEffect(() => {
    //     if (inputMail == "") {
    //         setValue('mobile', '');
    //         setValue('phone', '');
    //     }
    // }, [inputMail])

    const handleEmailChange = (e: any) => {
        const inputEmail = e.target.value;
        setInputMail(inputEmail);
        if (inputEmail == existingEmail) {
            // const parsedNumber: any = parsePhoneNumber(`+${data?.mobile}`);
            // const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
            // setCountryCode(respCountryCode)
            // setPrevCountryCode(respCountryCode)
            // setValue('sellerStatus', data?.sellerStatus);
            setAddress(data?.addresses[0]?.addressLine1)
            setValue('mobile', data?.mobile)
            setValue('phone', data?.phone)
            setValue('add1', data?.addresses[0]?.addressLine1)
            setValue('city1', data?.addresses[0]?.cityName)
            setValue('state1', data?.addresses[0]?.stateName)
            setValue('country1', data?.addresses[0]?.countryName)
            setValue('zip', data?.addresses[0]?.zipCode)
            setValue('county1', data?.coutyName)
            setValue('address2', data?.addresses[0]?.addressLine2)
        }
        else if (inputEmail && EMAIL.test(inputEmail)) {
            // setSellerStatusData(sellerStatusOptions);

            axios.get(`${GETCUSTOMERBYEMAIL}?emailId=${encodeURIComponent(inputEmail)}`)
                .then((response: any) => {
                    const data = response.data;
                    setFoundEmail(data.data.email);
                    if (data?.success === "Success") {
                        Swal.fire({
                            title: "Buyer found for this email",
                            text: "Do you want to use this and merge to become seller ?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#006800',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                const parsedNumber: any = parsePhoneNumber(`+${data.data.mobile}`);
                                const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
                                setCountryCode(respCountryCode)
                                setPrevCountryCode(respCountryCode)
                                // setValue('sellerStatus', 2);
                                setValue('password', data.data.password);
                                setValue('confirmPassword', data.data.password);
                                setValue('mobile', data.data.mobile);
                                setValue('phone', data.data.phone);
                                setAddress(data.data.addressLine1);
                                setValue('add1', data.data.addressLine1);
                                setValue('city1', data.data.cityName);
                                setValue('state1', data.data.stateName);
                                setValue('country1', data.data.countryName);
                                setValue('zip', data.data.zipCode);
                                setValue('county1', data.data.countyName);
                                setValue('address2', data.data.addressLine2);
                            }
                        });
                    }
                    else {
                        handleInvalidEmail();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
        }

        else {
            handleInvalidEmail();
        }

    }

    const handleInvalidEmail = () => {
        setCountryCode('');
        setPrevCountryCode('');
        // setValue('sellerStatus', 1);
        setValue('password', '');
        setValue('confirmPassword', '');
        setValue('mobile', '');
        setValue('phone', '');
        setAddress('');
        setValue('add1', '');
        setValue('city1', '');
        setValue('state1', '');
        setValue('country1', '');
        setValue('zip', '');
        setValue('county1', '');
        setValue('address2', '');
    }

    const [countryCode, setCountryCode] = useState('us');
    const [prevCountryCode, setPrevCountryCode] = useState('us');

    useEffect(() => {
        if (prevCountryCode !== countryCode) {
            setValue('mobile', '');
            setValue('phone', '');
        }
    }, [address, prevCountryCode, countryCode])


    const initialData = {
        companyId: parseInt(companyId),
        initials: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        title: "",
        add1: "",
        add2: "",
        city1: "",
        state1: "",
        country1: "",
        zip: "",
        county1: '',
        email: "",
        email2: "",
        phone: "",
        mobile: "",
        fax: "",
        taxId: "",
        codes: "",
        authority: "",
        salesPerson: [],
        sellerStatus: 2,
        password: '',
        confirmPassword: '',
        // reason: '',
        equipment: '',
        notes: '',
        reportingEmail: '',
        reportingEmail2: '',
    }

    const [initialFormValue, setInitialFormValue] = useState<any>(initialData)

    const taxExemptType = [
        { label: 'Yes', value: true, selected: false },
        { label: 'No', value: false, selected: true }
    ]
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const generatePhoneValidationSchema = () => {
        return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
            if (!value) return true;
            try {
                const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false; // Invalid phone number format
                }
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
                const expectedLength = value.length - countryCodeLength;
                return nationalNumber.toString().length === expectedLength;
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return false; // Return false if an error occurs during parsing
            }
        });
    };

    const schema: any = yup.object().shape({
        initials: yup.string().notRequired(),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),

        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),
        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        company: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name.').max(100, "Please enter upto 100 characters."),
        mobile: generatePhoneValidationSchema().required('Phone number is required.'),
        // phone: generatePhoneValidationSchema().nullable(),
        fax: yup.string().nullable().matches(/^(\d{10})*$/, 'Please enter number with 10 digits'),
        title: yup.string().nullable().matches(SPECIALCHARS, 'Please enter valid Title').max(150, "Please enter upto 150 characters."),
        add1: yup.string().required("Address is required.").matches(SPECIALCHARS, 'Please enter valid address'),
        city1: yup.string().required('City is required.'),
        state1: yup.string().required('State is required.'),
        country1: yup.string().required('Country is required.'),
        zip: yup
            .string().required('Zip code is required')
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces.')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code.')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        email: yup.string().required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        reportingEmail: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        reportingEmail2: yup.string().nullable()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        // email2: yup.string().nullable()
        //     .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        //         if (!value) return true; // If value is empty, it's handled by the .required() validation
        //         return !/^\s/.test(value); // Check if value doesn't start with a space
        //     })
        //     .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters."),
        isExempt: yup.boolean(),
        taxId: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE).max(9, "Please enter upto 9 characters."),
        codes: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE).max(9, "Please enter upto 9 characters."),
        authority: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).max(20, "Please enter upto 20 characters."),
        salesPerson: yup.array().required("Select at least one option").min(1, 'Select at least one option'),
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters long")
            .max(20, "Please enter upto 20 characters")
            .test('no-blank-space', 'Blank spaces are not allowed',
                (value) => {
                    return value ? !/^\s/.test(value) : true;
                })
            .test(
                'uppercase',
                'At least one uppercase letter is required',
                (value) => /[A-Z]/.test(value)
            )
            .test(
                'lowercase',
                'At least one lowercase letter is required',
                (value) => /[a-z]/.test(value)
            )
            .test(
                'numbers',
                'At least one number is required',
                (value) => (value.match(/[0-9]/g) || []).length >= 1
            )
            .test(
                'specialCharacter',
                'At least one special character is required',
                (value) => /[!@#$%^&*()_+\-=\[\]{}|';:.<>?]/.test(value)
            ),
        confirmPassword: yup.string().required("Confirm password is required").oneOf([yup.ref('password'), ""], 'Password must match with confirm password'),

    });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        trigger,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const sellerStatus = watch('sellerStatus');
    const watchInitials = watch('initials')
    const email = watch('email');
    const password = watch('password')
    const mobile = watch('mobile')
    const phone = watch('phone')
    const cnfpassword = watch('confirmPassword')
    const countryName = watch('country1')
    const watchMobile = watch("mobile")
    const reportingEmail = watch("reportingEmail")
    const reportingEmail2 = watch("reportingEmail2")

    useEffect(() => {
        if (reportingEmail && reportingEmail == reportingEmail2) {
            setReportingIsSame(true)
        } else {
            setReportingIsSame(false)
        }

    }, [reportingEmail, reportingEmail2])


    const updateCountryCode = (newAddress: any, code: any) => {
        // if (initialCountryName && newAddress !== initialCountryName) {
        //     setCountryCode(code);
        //     setValue('mobile', '');
        // } else {
        //     return;
        // }
    }

    // const handleChangeAddressLine = (e: any) => {
    //     if (e.target.name === 'addressLine1') {
    //         setAddressLine1(e.target.value)
    //     } else if (e.target.name === 'addressLine2') {
    //         setAddressLine2(e.target.value)
    //     } else if ((e.target.name === 'locations')) {
    //         setLocations(e.target.value)
    //     }
    // }

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        setIsAddressFilled(true);
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        } else if (e.target.name === 'addressLine2') {
            setRemainingCountAdd2(remaining);

        } else if ((e.target.name === 'location')) {
            setLocation(inputText)
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
            setRemainingCountAdd(remaining);
        }
    }


    const handleNext = () => {
        setLoading(false);
        dispatch(setActiveTab('auctionInfo'));
    }

    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setIntials(formateData)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });

        axios.get(`${GETCOMMONVALUE}?filter=addressType&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const data = response.data.data
                let addressTypes: any = {}
                for (let i = 0; i < data.length; i++) {
                    let adr = data[i]
                    addressTypes[`${adr.objectTypeValue.split(" ")[0]}`] = { sellerAddressType: adr.objectValueId, strSellerAddressType: adr.objectTypeValue }
                }
                dispatch(setAddressTypes(addressTypes))
                setAddressType(response.data.data)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }, [])


    const handleRadio = (rValue: boolean) => {
        if (rValue === false) {
            setValue('taxId', null)
            setValue('codes', null)
            setValue('authority', null)
        } else {
            setValue('taxId', sellerData?.taxId ?? '0')
            setValue('codes', sellerData?.codes ?? '0')
            setValue('authority', sellerData?.authority ?? '')
        }
        setTaxVal(rValue)
        setValue('exempt', rValue)
        clearErrors(['taxId', 'codes', 'authority', 'salesPerson']);
    }

    const getSalesRep = () => {
        axios.get(`${GETSALESREPRESENTATIVE}?pageNumber=-1`, config).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                toast.error(response.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const salesRepData = response?.data?.data?.data
                const filterData = salesRepData?.map((sales: any) => {
                    return { value: sales.saleRepId, label: `${sales.saleRepId} - ${sales.firstName} ${sales.lastName}` }
                })
                const existingValue = getValues('salesPerson')
                if (typeof existingValue === "string") {
                    const salesDataArr = existingValue.split(',')
                    const salesValueForDD = salesRepData?.map((sData: any) => {
                        if (salesDataArr.includes(sData.saleRepId.toString())) {
                            return sData
                        }
                    })?.filter((data: any) => data !== undefined)
                    setValue('salesPerson', salesValueForDD?.map((sData: any) => { return { value: sData.saleRepId, label: `${sData.saleRepId} - ${sData.firstName} ${sData.lastName}` } }))
                }
                setSalesPerson(filterData);
            }
        }).catch((error) => {
            setLoading(false);

        });
    }


    const associateSellerToAuction =(sellerID:any)=>{
        const auctionId = getGlobalAuctionIds;
        Swal.fire({
            title: 'Are you sure?',
            text: 'you want to add this Seller to the current Auction?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                // Run this block if the user chooses "Yes"
                const payload = [
                    {
                        auctionId: auctionId,
                        isRemove: false,
                        sellerId: sellerID
                    }
                ];
                setLoading(true);
                axios.post(UPDATEAUCTIONSELLER, payload, config)
                    .then((response) => {
                        setLoading(false);
                        if (response?.data?.success === "Fail") {
                            toast.error(response?.data?.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        } else {
                            setLoading(false);
                            toast.success("This seller has been successfully associated with the auction", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(sellerID)}`)
                            setTimeout(() => handleNext(), 350)
                        }
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            }
            else{
                setLoading(false);
                navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(sellerID)}`)
                handleNext();
                return;
            }
            // If the user clicks "No", nothing happens
        });

    }
    const onSubmit = (data: any) => {
        if (reportingIsSame) {
            return
        }
        setLoading(true);
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const formData: any = {
                sellerId: data.sellerId === undefined ? 0 : data.sellerId,
                companyId: parseInt(companyId),
                initials: parseInt(data.initials),
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                company: data.company,
                title: data.title,
                email: data.email,
                email2: data.email2,
                equipment: data.equipment,
                notes: data.notes,
                phone: data.phone?.toString(),
                mobile: data.mobile?.toString(),
                fax: data.fax?.toString(),
                exempt: data.exempt ?? false,
                taxId: data.taxId ? data.taxId : 0,
                codes: data.codes ? data.codes : 0,
                authority: data.authority ? data.authority : "",
                salesPerson: data.salesPerson?.map(((sData: any) => sData.value)).join(','),
                latitude: data?.latitude?.toString(),
                longitude: data?.longitude?.toString(),
                auctionTally: "string",
                isActive: data?.isActive === true ? true : false,
                password: data?.password ? data?.password : '',
                confirmPassword: data?.confirmPassword ? data?.confirmPassword : '',
                sellerStatus: data?.sellerStatus ? data?.sellerStatus : 2,
                reportingEmail: data?.reportingEmail ? data?.reportingEmail : '',
                reportingEmail2: data?.reportingEmail2 ? data?.reportingEmail2 : '',
                // Added new params mode, Admin portal - 1 , Customer portal - 2
                mode: 1,
                sellerAddress: {
                    sellerId: sellerData?.sellerId == undefined ? 0 : sellerData?.sellerId,
                    addressLine1: data.add1,
                    addressLine2: data.address2,
                    country: parseInt(countryId),
                    state: parseInt(stateId),
                    city: parseInt(cityId),
                    zipCode: data.zip,
                    county: parseInt(countyId),
                    countyName: data?.county1,
                    companyId: parseInt(companyId),
                    sellerAddressType: addressTypes?.Billing?.sellerAddressType,
                    strSellerAddressType: addressTypes?.Billing?.strSellerAddressType,
                    sellerAddressId: sellerData?.addresses?.length > 0 ? sellerData?.addresses[0]?.sellerAddressId : 0,
                    isActive: true
                }
            }
            //this is for trim all string type value in object
            Object.keys(formData).forEach(k => formData[k] = typeof formData[k] == 'string' ? formData[k].trim() : formData[k]);
            if (ID === undefined && !sellerData?.sellerId) {
                axios.post(CREATESELLERCONTACT, formData, config).then((response) => {
                    setLoading(false);
                    dispatch(updateSeller({ ...sellerData, sellerId: response?.data?.data, companyId: parseInt(companyId) }))
                    if (response?.data?.success == "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        const sellerId = response?.data?.data;
                        setSellerID(sellerId)
                        const auctionId = getGlobalAuctionIds;
                        if (auctionId) {
                            associateSellerToAuction(sellerId);
                        } else {
                            setLoading(false);
                            toast.success('Seller saved successfully', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            navigate(routeConstant.SELLER)
                        }
                        // handleNext();
                    }
                }).catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
            }
            else {
                axios.post(UPDATESELLERCONTACT, formData, { ...config, 'ContentType': 'application/json' })
                    .then((response) => {
                        setLoading(false);
                        if (response?.data?.success === "Fail") {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        }
                        else {
                            toast.success(SellerMessages.sellerUpdated, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            })
                        }
                        dispatch(updateSeller(formData));
                        reset(initialData);
                        setLoading(false);
                        handleNext();
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            }
        })
    }

    const GetAllSalesRepCommissionScale = (newData: any) => {
        axios.get(GETALLSELLERCOMMISSIONSCALE, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const scaleData = response.data?.data?.lstSellerCommissionScale ?? []
                const filterData = scaleData?.map((scale: any) => {
                    return {
                        label: scale.taxRegionName,
                        sellerCommissionBuyBack: scale.sellerCommissionBuyBack,
                        sellerCommissionScaleId: scale.sellerCommissionScaleId,
                        sellerCommissionNonPayer: scale.sellerCommissionNonPayer,
                        sellerCommissionSold: scale.sellerCommissionSold,
                        sellerCommissionUnSold: scale.sellerCommissionUnSold,
                    }
                })
                let sold, unSold, buyBack, nonPay;
                let list: any = []
                for (let i = 0; i < filterData.length; i++) {
                    const scale = filterData[i]
                    const data = { label: scale.label, value: scale.sellerCommissionScaleId }
                    list.push(data)
                    if (scale.sellerCommissionSold) sold = scale.sellerCommissionScaleId;
                    if (scale.sellerCommissionUnSold) unSold = scale.sellerCommissionScaleId;
                    if (scale.sellerCommissionBuyBack) buyBack = scale.sellerCommissionScaleId;
                    if (scale.sellerCommissionNonPayer) nonPay = scale.sellerCommissionScaleId;
                }
                dispatch(updateSeller({ ...newData, commissionOptions: { list: list, sold: sold, unSold: unSold, buyBack: buyBack, nonPay: nonPay } }))
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const getSellerLots = () => {
        axios.get(`${SELLERLOTS}?sellerId=${ID ? decryptId(ID) : sellerData?.sellerId}`).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const lotsData = response?.data?.data;
                dispatch(sellerLots(lotsData))
                // let auctionlistOptions = lotsData?.map((i: any) => { return { label: `${i?.auctionCode} - ${i?.auctionTitle}`, value: i?.auctionId } })
                // const ids = auctionlistOptions?.map(({ value }: any) => value);
                // auctionlistOptions = auctionlistOptions?.filter(({ value }: any, index: any) => value !== 0 && !ids.includes(value, index + 1));
                // dispatch(sellerAuctions(auctionlistOptions))
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const togglePassword = (field: any) => {
        if (field === "password") {
            setPasswordType(passwordType === "password" ? "text" : "password");
        } else if (field === "confirmPassword") {
            setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
        }
    };

    useEffect(() => {
        if (ID !== undefined || sellerData?.sellerId) {
            setLoading(true);
            const url =
                AuctionDetail?.auctionId ? `${GETBYSELLERID}?sellerId=${decryptId(ID)}&auctionId=${AuctionDetail.auctionId}` :
                    getGlobalAuctionIds ? `${GETBYSELLERID}?sellerId=${decryptId(ID)}&auctionId=${getGlobalAuctionIds}` :
                        `${GETBYSELLERID}?sellerId=${decryptId(ID)}`

            axios.get(url).then((response) => {
            // setLoading(false);
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    const data = response?.data?.data;

                    const adjustData =
                    {
                        sellerAdjustId: 0,
                        sellerId: data?.sellerId,
                        taxRateByZipCode: data?.lstSellerAdjustsTax[0]?.taxRateByZipCode,
                        noAdjusts: data?.lstSellerAdjustsTax[0]?.noAdjusts,
                        ajustmentSalesTax: data?.lstSellerAdjustsTax[0]?.ajustmentSalesTax,
                        taxExempt: data?.lstSellerAdjustsTax[0]?.taxExempt ?? false,
                        isActive: true,
                        isDelete: false,
                        lstAdjusts: data?.lstSellerAdjustsTax[0]?.lstadjusts?.map((i: any) => { return { ...i, adjustUniqueId: uuidv4() } }) ?? []
                    }
                    const newData = {
                        ...data,
                        // commision: {data?.lstSellerCommission},
                        adjust: adjustData,
                        payment: data?.lstSellerPayments?.map((i: any) => { return { ...i, paymentUniqueId: uuidv4() } }),
                        logNote: data?.lstSellerLogNotes?.map((i: any) => { return { ...i, logUniqueId: uuidv4() } }),
                        isActive: data.isActive,
                        exempt: data.exempt ?? false,
                        addresses: data?.lstSellerAddress,
                        sellerStatus: data?.sellerStatus ? data?.sellerStatus : 2,
                        confirmPassword: data?.password ? data?.password : '',
                        phone: data?.phone || "",
                    }
                    setData(newData)
                    setExistingEmail(newData.email);
                    reset(newData)
                    getSalesRep()
                    const parsedNumber: any = parsePhoneNumber(`+${newData?.mobile}`);
                    const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
                    setCountryCode(respCountryCode)
                    setPrevCountryCode(respCountryCode)
                    setAddress(newData?.addresses[0]?.addressLine1)
                    setValue('mobile', newData?.mobile)
                    setValue('phone', newData?.phone)
                    setValue('add1', newData?.addresses[0]?.addressLine1)
                    setValue('city1', newData?.addresses[0]?.cityName)
                    setValue('state1', newData?.addresses[0]?.stateName)
                    setValue('country1', newData?.addresses[0]?.countryName)
                    setValue('zip', newData?.addresses[0]?.zipCode)
                    setValue('county1', newData?.addresses[0]?.countyName)
                    setValue('address2', newData?.addresses?.addressLine2)
                    setLatitude(newData?.latitude)
                    setLongitude(newData?.longitude)
                    setValue('equipment', newData?.equipment)
                    setValue('notes', newData?.notes)
                    setTaxVal(newData?.exempt)
                    GetAllSalesRepCommissionScale(newData)
                    dispatch(setContractDocumentData(data?.lstSellerContractDocument))
                    getSellerLots()
                    dispatch(setSellerId(data?.sellerId))
                    dispatch(setPrevSellerId(data?.prevSellerId))
                    dispatch(setNextSellerId(data?.nextSellerId))
                    dispatch(setTotalSellers(data?.totalSellers))
                    dispatch(setSellerIndex(data?.index))
                    setTimeout(() => setLoading(false), 500)
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setLoading(false);
            getSalesRep()
            if (Object.keys(modalData).length !== 0) {
                reset(modalData)
            }
        }
    }, [ID]);

    useEffect(() => {
        const cnfPassword = getValues('confirmPassword')
        const errorKeys = Object.keys(errors)
        if (cnfPassword !== "" && (password !== cnfPassword) && !errorKeys.includes('confirmPassword')) {
            setCnfError(true)
            return
        }
        setCnfError(false)
    }, [password]);

    useEffect(() => {
        setCnfError(false);
    }, [cnfpassword]);

    useEffect(() => {
        if ((ID || sellerData?.sellerId) && sellerData?.sellerStatus !== 1 && (email === existingEmail && email !== "")) {
            // If ID exists or sellerId exists in sellerData and sellerStatus is not 3, filter out status 1 and set sellerStatusData
            let newData = sellerStatusOptions?.filter((data: any) => data.value !== 1);
            setSellerStatusData(newData);
        }
        else {
            setSellerStatusData(sellerStatusOptions)
        }
    }, [email, existingEmail, ID, sellerData, sellerStatusOptions]);


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center top_border">
                        <div className="col-xxl-10 mt-3">
                            <div className="mt-2 mb-2 border-300">
                                <h4>Contact and Default Info</h4>
                            </div>
                            <div className="row g-3 mb-4">

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">First Name <sup className="text-danger">*</sup></label>
                                    <input type="text" {...register('firstName')} className="form-control" placeholder="First Name" maxLength={20} />
                                    {errors.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.firstName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Middle Name</label>
                                    <input type="text" {...register('middleName')} className="form-control" placeholder="Middle Name" maxLength={20} />
                                    {errors.middleName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.middleName.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Last Name <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" {...register('lastName')} placeholder="Last Name" maxLength={20} />
                                    {errors.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.lastName.message}</span>}
                                </div>
                                {/* <div className="col-lg-3">
                                    <label className="form-label">Alt ID <sup className="text-danger">*</sup></label>
                                    <div className="code-to-copy">
                                        <input
                                            // name='altId'
                                            className="form-control form-icon-input"
                                            type="text"
                                            placeholder="Alt ID"
                                            {...register('altId')}
                                        />
                                    </div>
                                    {errors.altId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.altId.message}</span>}
                                </div> */}
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Company</label>
                                    <input type="text" className="form-control" {...register('company')} placeholder="Company" maxLength={100} />
                                    {errors.company && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.company.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" {...register('title')} placeholder="Title" maxLength={151} />
                                    {errors.title && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.title.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Email <sup className="text-danger">*</sup></label>
                                    <input type="text" className="form-control" placeholder="Email" maxLength={51}
                                        {...register('email', { onChange: handleEmailChange })} disabled={!!sellerData?.sellerId} />
                                    {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                                </div>

                                <div className="col-lg-6">
                                    <label className="form-label">Equipment</label>

                                    <textarea
                                        className="form-control form-icon-input"
                                        placeholder="Equipment"
                                        rows={2}
                                        maxLength={maxWordCount2}
                                        {...register("equipment", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount2)) })}
                                    />
                                    {remainingCount2 != maxWordCount2 && <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                                    </div>}
                                    {errors.equipment && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.equipment.message}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="form-label">Notes</label>

                                    <textarea
                                        className="form-control form-icon-input"
                                        placeholder="Notes"
                                        rows={2}
                                        maxLength={maxWordCount2}
                                        {...register("notes", { onChange: (e) => setRemainingCountForNote(wordCounter(e.target.value, maxWordCount2)) })}
                                    />
                                    {remainingCountForNote != maxWordCount2 && <div className={remainingCountForNote <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCountForNote <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCountForNote}`}
                                    </div>}
                                </div>
                                {/* <div className="col-lg-6">
                                    <label className="form-label">Email 2</label>
                                    <input type="text" className="form-control" {...register('email2')} placeholder="Email" maxLength={51} />
                                </div> */}

                                <AddressForm
                                    // address={address}
                                    errors={errors}
                                    onHandleChangeAddressLine={handleChangeAddressLine}
                                    register={register}
                                    setValue={setValue}
                                    setCountryCode={setCountryCode}
                                    onAddressChange={updateCountryCode}
                                    onSetAddress={setAddress}
                                    watch={watch}
                                    trigger={trigger}
                                />

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                                    <Controller
                                        // name="mobile"
                                        {...register('mobile')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.mobile && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.mobile.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Alternate Number </label>
                                    <Controller
                                        // name="phone"
                                        {...register('phone')}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <PhoneInput
                                                value={value}
                                                onChange={onChange}
                                                country={countryCode}
                                                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                            />
                                        )}
                                    />
                                    {errors.phone && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone.message}</span>}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Fax</label>
                                    <input type="text" className="form-control" {...register('fax')} placeholder="Fax" maxLength={11} />
                                    {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                                </div>

                                <div className="col-lg-12">
                                    <label className="form-label">Sales Person <sup className="text-danger">*</sup></label>
                                    <Controller
                                        name="salesPerson"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix="dpaSelect"
                                                isMulti
                                                placeholder="Select"
                                                options={salesPerson}
                                            />
                                        )}
                                    />
                                    {errors.salesPerson && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.salesPerson.message}</span>}
                                </div>

                                <div className="col-lg-5">
                                    <label className="form-label">Seller Summary/Pickup Summary Report Email</label>
                                    <input type="text" className="form-control" placeholder="Seller Summary/Pickup Summary Report Email" maxLength={51} {...register('reportingEmail')} />
                                    {errors.reportingEmail && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.reportingEmail.message}</span>}
                                    {reportingIsSame && <span className='text-danger' style={{ fontSize: "12px" }}> Reporting Emails must not be same.</span>}
                                </div>
                                <div className="col-lg-5">
                                    <label className="form-label">Seller Pickup Summary Report Email</label>
                                    <input type="text" className="form-control" placeholder="Seller Pickup Summary Report Email" maxLength={51} {...register('reportingEmail2')} />
                                    {errors.reportingEmail2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.reportingEmail2.message}</span>}
                                    {reportingIsSame && <span className='text-danger' style={{ fontSize: "12px" }}> Reporting Emails must not be same.</span>}
                                </div>

                                {/* Is Active */}
                                {ID && <div className="col-lg-2 pl-2">
                                    <label className="form-label">Status</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            {...register('isActive')}
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                    </div>
                                </div>}



                                {/* seller "Approval status" hide field. remove this line if uncomment this.*/}

                                {/* <div className="col-lg-3">
                                    <label className="form-label">
                                        Approval Status
                                    </label>
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Seller Status"
                                        options={sellerStatusData}
                                        {...register('sellerStatus')}
                                        value={sellerStatusData?.find((option: any) => option?.value == sellerStatus)}
                                        onChange={(e: any) => setValue('sellerStatus', e.value)}
                                        className='isActive'
                                    />
                                    {errors.smsType && (
                                        <span className="text-danger" style={{ fontSize: "12px" }}>
                                            {errors.smsType.message}
                                        </span>
                                    )}
                                </div>
                                {sellerStatus == 3 ?
                                    <div className="col-lg-4 pl-2">
                                        <label className="form-label">Rejected Reason <sup className="text-danger">*</sup></label>
                                        <textarea
                                            className="form-control"
                                            placeholder={'Enter Rejected Reason'}
                                            cols={5}
                                            rows={5}
                                            {...register('reason')}
                                        ></textarea>
                                        {errors.reason && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.reason.message}</span>}
                                    </div>
                                    : <></>} */}

                                <div className="col-xxl-12">
                                    <div className="mt-2 mb-3 border-300">
                                        <h4>Seller / Buyer Credentials</h4>
                                    </div>

                                    <div className="row g-3">
                                        <div className="col-lg-4">
                                            <label className="form-label">Email</label>
                                            <input type="text" className="form-control" value={email} placeholder="Email" maxLength={51} disabled={!!sellerData?.sellerId} />
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <label className="form-label">Password <sup className="text-danger">*</sup></label>
                                            <div className='position-relative'>
                                                <input type={passwordType} className="form-control" placeholder="Password" {...register('password')} maxLength={20} />
                                                <span className='hide_show_pass'>
                                                    <i
                                                        onClick={() => togglePassword("password")}
                                                        className={
                                                            passwordType === "password"
                                                                ? "fas fa-eye-slash"
                                                                : "fas fa-eye"
                                                        }
                                                    ></i>
                                                </span>
                                            </div>
                                            {errors.password && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.password.message}</span>}
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <label className='form-label'>Confirm Password <sup className="text-danger">*</sup></label>
                                            <div className='position-relative'>
                                                <input
                                                    type={confirmPasswordType}
                                                    placeholder='Confirm Password'
                                                    className='w-100 form-control'
                                                    {...register('confirmPassword')}
                                                    maxLength={20}
                                                />
                                                <span className='hide_show_pass'>
                                                    <i
                                                        onClick={() => togglePassword("confirmPassword")}
                                                        className={
                                                            confirmPasswordType === "password"
                                                                ? "fas fa-eye-slash"
                                                                : "fas fa-eye"
                                                        }
                                                    ></i>
                                                </span>
                                            </div>
                                            {errors.confirmPassword && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.confirmPassword.message}</span>}
                                            {cnfError && <span className='text-danger' style={{ fontSize: "12px" }}>Password must match with confirm password</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>

                                <div className="col-12">
                                    <h5 className="fw-400">Tax Exempt <sup className="text-danger">*</sup></h5>
                                    {taxExemptType?.map((taxType: any, index: number) => {
                                        return (
                                            <div className="form-check form-check-inline mb-3">
                                                <input
                                                    type="radio"
                                                    id={`contactExempt_${index}`}
                                                    className="form-check-input"
                                                    onClick={() => handleRadio(taxType.value)}
                                                    checked={taxVal === taxType.value}
                                                />
                                                <label className="form-check-label" htmlFor={`contactExempt_${index}`} >{taxType.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Tax ID</label>
                                    <input type="text" className="form-control" {...register('taxId')} disabled={!taxVal} placeholder="Tax ID" maxLength={10} onKeyDown={handleKeyDown} />
                                    {errors.taxId && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxId.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Codes</label>
                                    <input type="text" className="form-control" {...register('codes')} disabled={!taxVal} placeholder="Code" maxLength={10} onKeyDown={handleKeyDown} />
                                    {errors.codes && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.codes.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <label className="form-label">Authority</label>
                                    <input type="text" className="form-control" {...register('authority')} disabled={!taxVal} placeholder="Authority" maxLength={21} onKeyDown={handleKeyDown} />
                                    {errors.authority && taxVal && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.authority.message}</span>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                        <div>
                            <Link className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                        </div>
                        <div>
                            <button className="btn btn-primary ms-2" type="submit" >{ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)}</button>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactDefault;

