
import * as action from '../../common/types'
import { Action } from '../../common/constants'
import { date } from 'yup';

export const setLogPage = (data: any): Action => ({
    type: action.LOGPAGEINVENTOY,
    payload: data
})
export const deleteInventory = (uniqueId: any): Action => ({
    type: action.DELETEINVENTORY,
    payload: uniqueId,
});

export const setStep = (data: any): Action => ({
    type: action.ACTIVESTEP,
    payload: data
})

export const setInventoryData = (data: any): Action => ({
    type: action.LOGPAGEINVENTOY,
    payload: data
})

export const setInventoryInfoActive = (data: any): Action => ({
    type: action.INVENTORYACTIVE,
    payload: data
})

export const addLogNote = (data: any): Action => ({
    type: action.ADDLOGNOTE,
    payload: data,
});

export const deleteLogNote = (lotNotesId: any): Action => ({
    type: action.DELETELOGNOTE,
    payload: lotNotesId,
});

export const updateLogNote = (lotNotesId: any, updatedNote: any) => ({
    type: action.UPDATELOGNOTE,
    payload: { lotNotesId, updatedNote },
});

export const setGeneratedLotCtrlRec = (data: any) => ({
    type: action.SETLOTCTRLREC,
    payload: data
})

/** New changes for seperate tab API */

export const setMode = (data: any): Action => ({
    type: action.SETMODE,
    payload: data
})

export const setLotId = (data: any): Action => ({
    type: action.SETLOTID,
    payload: data
})

export const setPrevLotId = (data: any): Action => ({
    type: action.SETPREVLOTID,
    payload: data
})

export const setNextLotId = (data: any): Action => ({
    type: action.SETNEXTLOTID,
    payload: data
})

export const setTotalLots = (data: any): Action => ({
    type: action.TOTALLOTS,
    payload: data
})

export const setIndex = (data: any): Action => ({
    type: action.INDEX,
    payload: data
})

export const setCloneLotId = (data: any): Action => ({
    type: action.SETCLONELOTID,
    payload: data
})

export const setCloneTab = (data: any): Action => ({
    type: action.SETLOTCLONETABS,
    payload: data
})

export const setCreateTab = (data: any): Action => ({
    type: action.SETLOTCREATETABS,
    payload: data
})

export const setAuctionDataToStore = (data: any): Action => ({
    type: action.SETAUCTIONDATA,
    payload: data
})

export const setSellerIdForComm = (data: any): Action => ({
    type: action.SETSELLERIDFORCOMM,
    payload: data
})
