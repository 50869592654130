import { Tab, Tabs } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import * as routeConstant from '../../../../common/routeConstants';
import { useDispatch, useSelector } from "react-redux";
import { setAuctionInfoActiveTab } from "../../../../store/actions/Seller";
import { CommonMessages } from "../../../../common/messageConstants";
import SalesWithCost from "./SalesWithCost";
import SalesByBidder from "./SalesByBidder";
import Select from 'react-select';
import { GETSELLERCOMMSALESINAUCTION } from "../../../../common/apiConstatnts";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const SalesInAuction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const sellerData = useSelector((state: any) => state.seller)
    const commisionData = sellerData?.commision
    let scaleData = sellerData?.commissionOptions
    const selectedAuction = useSelector((state: any) => state.seller.selectedAuction)
    const auctionId = selectedAuction.value

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const [loading, setLoading] = useState<any>(false);
    const [calculationData, setCalculationData]: any = useState({})
    const [salesWithCostData, setSalesWithCostData]: any = useState([])

    const initialData = {
        defaultScaleSold: String(scaleData?.sold) || '',
        defaultScalesPassed: String(scaleData?.unSold) || '',
        defaultScalesBuyback: String(scaleData?.buyBack) || '',
        defaultScalesNonPayer: String(scaleData?.nonPay) || '',
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        formState: { errors, isValid }
    } = useForm({
        // resolver: yupResolver(schema), 
        defaultValues: initialData,
        mode: 'onChange'
    })

    const formData = getValues()

    const defaultScaleSold = watch('defaultScaleSold')
    const defaultScalesPassed = watch('defaultScalesPassed')
    const defaultScalesNonPayer = watch('defaultScalesNonPayer')
    const defaultScalesBuyback = watch('defaultScalesBuyback')

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('tax'))
        // dispatch(setActiveTab('notes'))
    }

    const handlePrevious = () => {
        if (sellerData?.sellerId) {
            // navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(sellerData?.sellerId)}`)
            dispatch(setAuctionInfoActiveTab('inventory'))
            return
        }
    }

    const sellerSalesAuctionCommRecalc = () => {
        const formData = getValues()
        const params = {
            auctionId: auctionId,
            sellerId: sellerData?.sellerId,
            sellerSoldId: formData?.defaultScaleSold,
            sellerPassedId: formData?.defaultScalesPassed,
            sellerBuyBackId: formData?.defaultScalesBuyback,
            sellerNonPayerId: formData?.defaultScalesNonPayer,
        }
        setLoading(true)
        axios.get(GETSELLERCOMMSALESINAUCTION, { ...config, params: params }).then((response) => {
            if (response?.data?.success === "Fail") {
                setLoading(false)
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setLoading(false)
                const newCommission: any = response?.data?.data
                setCalculationData({
                    ...calculationData,
                    ...newCommission
                })
                setSalesWithCostData(newCommission)

                // reset(updatedData)
                toast.success('Commission Recalculated Successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });

            }
        }, (error) => {
            setLoading(false)
            if (error.response.status !== 401) {
                toast.error(error?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center mt-3">
                    <div className="col-xxl-10">
                        <h4 className="mb-2">Sales In Auction</h4>
                        {/* <div className="mt-4 mb-4 border p-4 bg-light fs-16 border-radius text-center">
                            Under Development
                        </div> */}
                        <div className="row">
                            <div className="col-3">
                                <label className='form-label'>Sold</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    name='defaultScaleSold'
                                    options={scaleData?.list}
                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScaleSold)}
                                    onChange={(e: any) => setValue('defaultScaleSold', String(e.value))}
                                />
                            </div>
                            <div className="col-3">
                                <label className='form-label'>Passed/Unsold</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    name='defaultScalesPassed'
                                    options={scaleData?.list}
                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesPassed)}
                                    onChange={(e: any) => setValue('defaultScalesPassed', String(e.value))}
                                />
                            </div>
                            <div className="col-3">
                                <label className='form-label'>Buyback</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    name='defaultScalesBuyback'
                                    options={scaleData?.list}
                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesBuyback)}
                                    onChange={(e: any) => setValue('defaultScalesBuyback', String(e.value))}
                                />
                            </div>
                            <div className="col-3">
                                <label className='form-label'>Non-Payer</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    name='defaultScalesNonPayer'
                                    options={scaleData?.list}
                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesNonPayer)}
                                    onChange={(e: any) => setValue('defaultScalesNonPayer', String(e.value))}
                                />

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 my-3">
                            <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                                onClick={() => sellerSalesAuctionCommRecalc()}
                            >
                                <span>Recalculate Commission </span>
                            </button>
                        </div>
                        <hr />
                        <Tabs
                            defaultActiveKey="SalesWithCost"
                            className="nav-underline border-bottom"
                        >
                            <Tab eventKey="SalesWithCost" title="Sales With Cost">
                                <SalesWithCost
                                    activeTab="SalesWithCost"
                                    setValue={setValue}
                                    setCalculationData={setCalculationData}
                                    calculationData={calculationData}
                                    setLoading={setLoading}
                                    setSalesWithCostData={setSalesWithCostData}
                                    salesWithCostData={salesWithCostData}
                                />
                            </Tab>
                            <Tab eventKey="SalesByBidder" title="Sales by Bidder">
                                <SalesByBidder
                                    activeTab="SalesByBidder"
                                    setValue={setValue}
                                    setCalculationData={setCalculationData}
                                    calculationData={calculationData}
                                />
                            </Tab>
                        </Tabs>

                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                    {/* <button className="btn btn-primary ms-2" type="button" onClick={handleNext}>{CommonMessages.saveNext}</button> */}
                    <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.nextButton}</button>
                </div>
            </div>
        </>
    )

}
export default SalesInAuction;