import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from '../Sidebar/Sidebar';
import * as routeConsant from '../../common/routeConstants'
import { Breadcrumb } from '../../common/interface';
import { GETALLLOTTEMPLATE, DELETELOTTEMPLATE, LOTTEMPLATEFILTER, GETALLCATEGORYTITLE } from '../../common/apiConstatnts';
import axios from 'axios';
import { Dropdown, Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { activationStatus } from "../../common/constants";
import moreOptions from "../../assets/more_op.svg";
import { Link, useNavigate } from 'react-router-dom';
import InnerHeader from '../InnerHeader/InnerHeader';
import { encryptId } from '../../Encryption/Encryption';
import Helper from '../../common/helpers';
import * as types from '../../common/permissionTypes';
import { CommonMessages, LotTempMessages, errorMessages } from '../../common/messageConstants';
import handleError from '../../Utils/ApiCommonErrorMessages';


const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Lot Templates', url: routeConsant.LOTTEMPLATES }
]

const LotTemplates = () => {
    const navigate = useNavigate()
    const initialData = {
        cloneList: "",
    }
    const [loading, setLoading] = useState(false)
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [gender, setGender] = useState<[]>([])
    const [initial, setInitial] = useState<[]>([])
    const [roles, setRoles] = useState<[]>([])
    const [users, setUsers] = useState<[]>([])
    const [originalUsers, setOriginalUsers] = useState<[]>([])
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [currentUser, setCurrentUser] = useState<any>({})
    const [cloneData, setCloneData] = useState<any>({})
    const [showFilter, setShowFilter] = useState(false)
    const [mode, setMode] = useState<string>('create')
    const [filterUsers, setFilterUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [cloneId, setCloneId] = useState<any>(0);
    const [filterData, setFilterData] = useState<any>({ templateName: '', category: '', isActive: -1 })
    const PER_PAGE = 10;
    const pageCount = Math.ceil(users.length / PER_PAGE);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({ cloneList: "" });
    const [lotid, setId] = useState('')
    const [searchText, setSearchText] = useState('')

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    let filterRoleData =
        roles.map((roleData: any) => {
            return {
                label: roleData.categoryTitle, value: roleData.categoryId
            }
        })

    let filterActivationStatus =
        activationStatus.map((data: any) => {
            return {
                label: data.label, value: data.value
            }
        })

    const paginateData = (data: any) => {
        setUsers(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterUsers(firstSet)
    }

    const getAllLotTemplates = () => {
        setLoading(true)
        axios.get(GETALLLOTTEMPLATE, config).then((response) => {
            setLoading(false)
            const userData = response?.data?.data
            // setFilterUsers(userData)
            // setUsers(userData)
            reset(userData)
            const sd = userData.map((ud: any) => {
                return { label: ud.templateName, value: ud.lotTemplateId }
            })
            setCloneData(sd);
            paginateData(userData);
            setOriginalUsers(userData);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterUsers([]);
                return;
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
          });
    }

    const schema: any = yup.object().shape({
        cloneList: yup.object({
            value: yup.string().required("Please select a lot template"),
        }),
    });

    const {
        register,
        handleSubmit,
        getValues,
        reset,
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialData, mode: 'onChange' })

    useEffect(() => {
        getAllLotTemplates();
        setLoading(true);
        axios.get(GETALLCATEGORYTITLE, config).then((response) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setRoles(response?.data?.data);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
          });
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = users.slice(offset, offset + PER_PAGE)
        setFilterUsers(nextSet)
    }, [currentPage])

    const onSubmit = () => {
        const data = getValues()
        setLoading(true)

    }

    const handleAdvanceFilter = () => {
        setLoading(true);
        if (filterData.category) {
            var joinedStr = filterData.category.map((list: any) => list.value).join(",");
        }
        axios.get(LOTTEMPLATEFILTER, {
            ...config,
            params: {
                templateName: filterData.templateName,
                categoryIds: joinedStr ? joinedStr : "",
                isActive: filterData.isActive.value ?? -1
            }
        }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterUsers([]);
                return;
            }
            else {
                setFilterUsers(response.data.data)
            }
            // paginateData(response.data.data)
        }).catch((error) => {
            setLoading(false);
            handleError(error)
          });
        return
    }

    const clearAdvanceFilterData = () => {
        // setActive(false)
        setFilterData({ category: [], templateName: '', isActive: -1 })
        getAllLotTemplates();
    }

    const handleClose = () => {
        setCurrentUser({})
        setCloneId(0)
        setErrors({ cloneList: "" })
        setToggleValue(!toggleModal)
    }

    const handleEditModal = (userId: string, type: string) => {
        // const id = encrypt({userId}, SECRETKEY);
        if (type === 'edit') {
            const checkEditPer = Helper.checkAdminPermission(types.EDIT)
            if (checkEditPer) {
                const encryptedId = encryptId(userId);
                navigate(`${routeConsant.EDITLOTTEMPLATES}/${encryptedId}`);
            }

        } else if (type === 'clone') {
            setErrors({ cloneList: '' })
            const encryptedId = encryptId(userId);
            navigate(`${routeConsant.CLONELOTTEMPLATES}/${encryptedId}`)
        } else {
            const checkDelPer = Helper.checkAdminPermission(types.DELETE)
            if (checkDelPer) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to delete this record!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#006800',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setLoading(true);
                        axios.post(`${DELETELOTTEMPLATE}?lotTemplateId=${userId}`, {}, config).then((response) => {
                            setLoading(false);
                            toast.success(LotTempMessages.lotTempDeleted, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            getAllLotTemplates()
                            setCurrentPage(0)
                            clearAdvanceFilterData()
                            setSearchText('')
                        }).catch((error) => {
                            setLoading(false);
                            handleError(error)
                          });
                    }
                })
            }
        }
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        if (keyword === '') {
            getAllLotTemplates()
            return
        }
        const filterData: any = originalUsers?.filter((x: any) => x.templateName?.toLocaleLowerCase()?.includes(keyword) ||
            x.description?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData)
        setFilterUsers(filterData)
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const changeHandler = (fieldName: string, value: any) => {
        const updateData = { ...filterData, [fieldName]: value }
        setFilterData(updateData)
    }

    const handleSorting = (type: string, fieldName: string) => {
        if (fieldName != "category") {
            let userName
            if (type === 'ASC') {
                userName = filterUsers.sort((a: any, b: any) =>
                    (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
                )
            } else {
                userName = filterUsers.sort((a: any, b: any) =>
                    (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
                )
            }
            paginateData(userName);
        } else {
            let userName
            if (type === 'ASC') {
                userName = filterUsers.sort((a: any, b: any) =>
                    (a['categoryTitle'].toLocaleLowerCase() > b['categoryTitle'].toLocaleLowerCase()) ? 1 : -1
                )
            } else {
                userName = filterUsers.sort((a: any, b: any) =>
                    (a['categoryTitle'].toLocaleLowerCase() < b['categoryTitle'].toLocaleLowerCase()) ? 1 : -1
                )
            }
            paginateData(userName);
            setCurrentPage(0);
        }
    }

    const cloneLotTemplate = () => {
        if (cloneId != 0) {
            handleEditModal(String(cloneId), 'clone')
        } else {
            setErrors({ cloneList: 'Please Select Atleast One Template' })
        }
    }

    const cloneLotTemplateId = (id: any) => {
        setErrors({ cloneList: "" })
        setCloneId(id.value)
    }

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            {/* create user modal starts */}
            <Modal show={toggleModal} className='md-modal' centered>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Clone Lot Template' : 'Edit User'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='col-lg-12'>
                                <label className="form-label mb-2">Select Template Name</label>
                            </div>
                            <div className='col-lg-12'>
                                <Select classNamePrefix="dpaSelect"
                                    name='cloneList'
                                    placeholder="Select"
                                    className='role'
                                    options={cloneData}
                                    value={cloneData.value}
                                    onChange={(e: any) => cloneLotTemplateId(e)}
                                />
                                {errors?.cloneList && <span className='text-danger' style={{ fontSize: "12px" }}>{errors?.cloneList}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="button" onClick={cloneLotTemplate}>{CommonMessages.nextButton}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* create user modal ends */}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Lot Templates'>
                    <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="search-box mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                        <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                        <span className="fas fa-search search-box-icon"></span>
                                        <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })}><i className="fas fa-filter fs-16"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                                <button className="btn btn-primary mb-2 ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                                    onClick={() => {
                                        const checkPer = Helper.checkAdminPermission(types.ADD)
                                        if (checkPer) {
                                            reset(initialData)
                                            setToggleValue(!toggleModal)
                                            setMode('create')
                                        }
                                    }}>
                                    <span className="plus me-2"> <span className="fas fa-copy" data-fa-transform="shrink-3"></span> </span> <span> Clone Template </span>
                                </button>
                                <Link to={routeConsant.CREATELOTTEMPLATES} className="btn btn-outline-primary  mb-2 ms-2 position-relative d-flex aling-items-center">
                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span><span> Add New Template </span>
                                </Link>
                            </div>
                        </div>

                        {
                            showFilter &&
                            <div className={showFilter == true ? 'mb-3' : 'dropdown-advance-filter'}>
                                <div className="card bg-transparent position-relative p-3">
                                    <button className="btn p-a p-1" type="button" onClick={() => setShowFilter(!showFilter)}>
                                        <span className="fas fa-times text-danger fs-20"></span>
                                    </button>
                                    <div className="card-body p-0">
                                        <div className="text-start">
                                            <form >
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="mt-2 mb-2">
                                                            <label className="form-label" htmlFor="text">Template Name</label>
                                                            <input
                                                                className="form-control form-icon-input"
                                                                type="text"
                                                                name="templateName"
                                                                placeholder="Template Name"
                                                                value={filterData.templateName}
                                                                onChange={((e) => changeHandler("templateName", e.target.value))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mt-2 mb-2">
                                                            <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Category</label>
                                                            <Select classNamePrefix="dpaSelect"
                                                                isMulti
                                                                placeholder="Select"
                                                                className='role'
                                                                name='category'
                                                                options={filterRoleData}
                                                                value={filterData.category}
                                                                onChange={(e: any) => changeHandler("category", e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mt-2 mb-2">
                                                            <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Activation Status</label>
                                                            <Select classNamePrefix="dpaSelect"
                                                                placeholder="Select"
                                                                className='isActive'
                                                                name='isActive'
                                                                options={filterActivationStatus}
                                                                value={filterData.isActive}
                                                                onChange={(e: any) => changeHandler("isActive", e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="d-flex align-items-center ms-auto">
                                                <div className="flex-1 text-end  ms-3">
                                                    <button className="btn btn-outline-secondary mb-1 me-3" type="submit" onClick={clearAdvanceFilterData}>Clear</button>
                                                    <button className="btn btn-primary mb-1" type="submit" onClick={handleAdvanceFilter}>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant" scope="col">Action</th>
                                                            <th className="bg-gradiant" scope="col" data-sort="User">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Template Name</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'templateName')}></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'templateName')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Email">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Description</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'description')}></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'description')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Privillege">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Category</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'category')}></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'category')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Activation">Activation Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="table-latest-review-body">
                                                        {filterUsers.length > 0 ? filterUsers.map((user: any, index: any) => {
                                                            return (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                    <td className="align-middle white-space-nowrap text-start p-2">
                                                                        <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                            <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                                <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src={moreOptions} style={{ width: "20px" }} />
                                                                                </button>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(user.lotTemplateId, 'clone')}>Clone Template <i className="fas fa-clone dropdown-icon"></i> </Dropdown.Item>
                                                                                <Link className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => localStorage.setItem('LotTempName', user.templateName)} to={`${routeConsant.LOTTEMPLATESPREVIEWPAGE}/${encryptId(user.lotTemplateId)}`}>Preview <i className="fas fa-eye dropdown-icon"></i></Link>
                                                                                <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(user.lotTemplateId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(user.lotTemplateId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown >
                                                                    </td >
                                                                    <td className="align-middle white-space-nowrap p-2">{user.templateName}</td>
                                                                    <td className="align-middle white-space-nowrap p-2">{user.description}</td>
                                                                    <td className="align-middle p-2">{user.categoryTitle}</td>
                                                                    <td className="align-middle text-start status p-2">
                                                                        {user.isActive == true ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                                            <span className="badge-label">Active</span>

                                                                        </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                                            <span className="badge-label">Inactive</span>

                                                                        </span>}
                                                                    </td>
                                                                </tr >
                                                            )
                                                        }) :
                                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                <td className='text-center p-4' colSpan={5}>No records found!</td>
                                                            </tr>
                                                        }
                                                    </tbody >
                                                </table >
                                            </div >
                                        </div >
                                    </div >
                                </div >
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterUsers.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />)}
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
};

export default LotTemplates
