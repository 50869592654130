import moment from 'moment-timezone';


interface ParseTimeOptions {
    dateTimeString?: any;
    parseFormat?: string;
}

class MomentUtil {
    private static instance: MomentUtil | null = null;
    private timezone: string;
    private defaultSourceTimezone: string;
    private constructor(defaultTimezone: string) {
        this.timezone = defaultTimezone;
        this.defaultSourceTimezone = defaultTimezone;
        moment.tz.setDefault(defaultTimezone);
    }

    // Get the singleton instance of MomentUtil
    public static getInstance(): MomentUtil {
        if (!MomentUtil.instance) {
            const defaultTimezone = MomentUtil.getTimezoneFromLocalStorage();
            MomentUtil.instance = new MomentUtil(defaultTimezone);
        }
        return MomentUtil.instance;
    }

    // Function to get the timezone identifier from local storage or fallback to default
    private static getTimezoneFromLocalStorage(): string {
        const timezone = localStorage.getItem('timeZoneIdentifier');
        return timezone ? timezone : 'America/Chicago'; // Default to CDT if not available in local storage
    }

    // Function to get the timezone from local storage or fallback to default
    private static getLiveBoardTimezoneFromLocalStorage(): string | null {
        const liveBoardTimezone = localStorage.getItem('liveBoardTimeZone');
        return liveBoardTimezone ? liveBoardTimezone : 'UTC';
    }

    // Function to parse time in UTC
    public parseTimeInUtc(options?: ParseTimeOptions): moment.Moment {
        const { dateTimeString, parseFormat = "YYYY-MM-DDTHH:mm:ss" } = options || {};

        if (!dateTimeString) {
            return moment.utc().tz(this.timezone);
        }

        let parsedTimeUtc: moment.Moment;
        if (parseFormat) {
            parsedTimeUtc = moment.utc(dateTimeString, parseFormat);
        } else {
            parsedTimeUtc = moment.utc(dateTimeString);
        }

        const parsedTimeInTimezone = parsedTimeUtc.tz(this.timezone);
        return parsedTimeInTimezone;
    }

    // Function to format time using the default timezone
    public formatWithDefaultTimezone(dateTimeString: string, format: string): string {
        return moment(dateTimeString).format(format);
    }

    // Function to format time using a specified timezone
    public formatWithTimezone(dateTimeString: string, format: string, timezone: string): string {
        return moment.tz(dateTimeString, timezone).format(format);
    }
    // Function to set the default source timezone
    public setDefaultSourceTimezone(timezone: string): void {
        this.defaultSourceTimezone = timezone;
    }
    // Function to convert time from any timezone to UTC
    public convertTimeToUtc(dateTimeString: string): string {
        return moment(dateTimeString).utc().format('YYYY-MM-DDTHH:mm');
    }
    // Function to set the timezone
    public setTimezone(timezone: string): void {
        this.timezone = timezone;
        moment.tz.setDefault(timezone);
    }

    public static clearInstance(): void {
        MomentUtil.instance = null;
    }

    public unix(dateAndTime: number): moment.Moment {
        return moment.unix(dateAndTime).tz(this.timezone);
    }

    // Public method to retrieve the name from local storage
    public getLiveBoardTimezoneFromLocalStorage(): string | null {
        return MomentUtil.getLiveBoardTimezoneFromLocalStorage();
    }
}

export default MomentUtil;
