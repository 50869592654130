import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MomentUtil from "../../../Utils/MomentUtil";
import { GETAUCTIONDATESBYAUCTIONID, RUNAUCTIONSOLDPROCESS } from '../../../common/apiConstatnts';
import { errorMessages } from "../../../common/messageConstants";
import { setStep } from "../../../store/actions/AuctionAction";
import { loader } from "../../../store/actions/CommonAction";
import { useCurrentDateTime } from "../../../Utils/useDateTime";
import { AUCTION } from "../../../common/routeConstants";
import { Link } from "react-router-dom";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const AuctionClose = () => {
    const dispatch = useDispatch()
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const [auctionCloseDate, setAuctionCloseData] = useState<any[]>([])
    const momentUtil = MomentUtil.getInstance();
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
    const dateTime: any = useCurrentDateTime()

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const handlePrevious = () => {
        dispatch(setStep(6));
    }

    const getAuctionClosedData = () => {
        dispatch(loader(true));
        const aId = cloneAuctionId > 0 ? cloneAuctionId : auctionId
        axios.get(`${GETAUCTIONDATESBYAUCTIONID}?auctionId=${aId}`).then((response) => {
            dispatch(loader(false))
            if (response.data.success === "Success") {
                const auctionCloseData = response?.data?.data;
                setAuctionCloseData(auctionCloseData);
            } else {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    useEffect(() => {
        getAuctionClosedData();
    }, [auctionId])

    const handleRunNowProcess = (date: any) => {
        dispatch(loader(true))
        const payload = {
            executionDate: date,
            auctionId: cloneAuctionId > 0 ? cloneAuctionId : auctionId
        }

        axios.post(RUNAUCTIONSOLDPROCESS, payload, config).then((response) => {
            dispatch(loader(false))
            if (response.data.success === "Success") {
                getAuctionClosedData()
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            } else {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const getNoDataView = () => {
        return (
            <tr className="">
                <td className="text-center p-4" colSpan={8}>
                    No records found!
                </td>
            </tr>
        );
    };

    return (
        <div className="container-fluid for-fix-bottom-space">
            <div className="row top_border justify-content-center">
                <div className="col-xxl-10 pt-4 pb-4">
                    <div className="col-12">
                        <h4 className="mb-3">Auction Close Process</h4>
                    </div>
                    <form>
                        <div className="row g-3 mb-4">
                            <div className="col-lg-12">
                                <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                                    <div className="table-responsive scrollbar">
                                        <table className="table table-bordered mb-0 fs--1">
                                            <thead>
                                                <tr>
                                                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Day</th>
                                                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Auction End Date/Time ({defaultTimezone})</th>
                                                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Auction Close Status</th>
                                                    <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Run Close Process</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list scroll">
                                                <>
                                                    {auctionCloseDate && auctionCloseDate.length > 0 ? auctionCloseDate?.map((data, index) => {
                                                        const currentDT = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });
                                                        const startDateTime = momentUtil.parseTimeInUtc({ dateTimeString: data?.auctionEndDateTime });
                                                        return (
                                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 name">{data.days}</td>
                                                                <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto"> {startDateTime.format("MM/DD/YYYY hh:mm A")}</td>
                                                                <td className="align-middle text-start status p-2">
                                                                    {data.status === 1 ? (
                                                                        <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                                            <span className="badge-label">PENDING</span>
                                                                        </span>
                                                                    ) : data.status === 2 ? (
                                                                        <span className="badge badge-dpa fs--2 badge-dpa-warning">
                                                                            <span className="badge-label">RUNNING</span>
                                                                        </span>
                                                                    ) : data.status === 3 ? (
                                                                        <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                                            <span className="badge-label">EXECUTED</span>
                                                                        </span>
                                                                    ) : data.status === 4 ? (
                                                                        <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                                            <span className="badge-label">FAILED</span>
                                                                        </span>
                                                                    ) : null}
                                                                </td>
                                                                <td className="align-middle text-start status p-2">
                                                                    <button type="button" disabled={data?.isDisabled || data.status === 2} className="btn btn-outline-secondary me-2" onClick={() => handleRunNowProcess(data.auctionEndDateTime)}>
                                                                        {data?.status == 2 || data?.status == 3 ? "RE-RUN" : "RUN NOW"}
                                                                    </button></td>
                                                            </tr>
                                                        )
                                                    }
                                                    ) : getNoDataView()}
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>Previous</button>
                            <Link className="btn btn-outline-danger me-auto" to={AUCTION}>Cancel</Link>
                            <Link className="btn btn-primary ms-2" type="button" to={AUCTION}>Finish</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AuctionClose;

