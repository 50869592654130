import { Link } from 'react-router-dom'
import React from "react"

const HeaderInfo = ({ offCanvas, setOffCanvas }: any) => {
    return (
        <>
            <div
                className={`offcanvas offcanvas-end ${offCanvas}`}
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header">
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => setOffCanvas("")}
                    />
                </div>
                <div className="offcanvas-body">
                    <div className="text-center mb-4">
                    </div>
                    <p className="text-center">
                        DPA Auctions is a team of professionals that has been bringing Buyers
                        and Sellers together since 1972. We are dedicated to making sure your
                        Buying and Selling experience is professional and personable.
                    </p>
                    <div className="text-center">

                    </div>
                    <div className="text-center">

                        <p className="mb-2">
                            <Link to="tel:8004929090" className="text-primary letter-spacing">
                                800-492-9090
                            </Link>
                        </p>
                        <p className="mb-2">
                            <Link
                                to="mailto:customer.service@DPAauctions.com"
                                className="text-primary letter-spacing word-wrap-break-word"
                            >
                                customer.service@DPAauctions.com
                            </Link>

                        </p>

                    </div>


                </div>


            </div>
        </>
    )
}

export default HeaderInfo