import React from "react";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import * as routeConstant from '../../common/routeConstants'
import { Breadcrumb } from "../../common/interface";
import Sidebar from "../Sidebar/Sidebar";
import editIcon from "../../assets/edit_icon_tab.svg"
import deleteIcon from "../../assets/delet_icon_tab.svg"
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { GETALLBIDINCREMENT, UPDATEBIDINCREMENT } from "../../common/apiConstatnts";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { wordCounter } from "../../common/constants";
import { BidIncrementMessages, CommonMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, formatNumber, trimObjectValues } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const BidIncrement = () => {
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Bid Increment', url: '' }
    ]

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const bidIncFor = [
        { value: 1, label: 'Auctions' },
        { value: 3, label: 'Checklist' },
    ]

    const [loading, setLoading] = useState(false)
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleModalRange, setToggleValueRange] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [bidIncrementData, setBidIncrementData] = useState([]);
    const [bidRangeData, setBidRangeData] = useState([]);
    const [activeBidIndex, setActiveBidIndex] = useState(0);
    const [activeBidId, setActiveBidId] = useState<any>();
    const [activeUniqueId, setActiveUniqueId] = useState();
    const [lastLessThanValue, setLastLessThanValue] = useState<any>()
    const [readMore, setReadMore] = useState<boolean>(false)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [deleteDisable, setDeleteDisable] = useState<any>('')
    const [isBidValid, setIsBidValid] = useState<boolean>(false)
    const [isLimitReach, setIsLimitReach] = useState<boolean>(false);
    const decimalValForNxt = 0.01
    const maxWordCount = 50
    const [remainingCount, setRemainingCount] = useState(maxWordCount);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount)

    const initialBidData = {
        bidIncrementId: 0,
        label: '',
        description: '',
        bidIncrementFor: '',
        mode: mode,
        isDefault: false,
        isActive: true
    }

    const intialRangeData = {
        bidIncrementRangeId: 0,
        bidIncrementId: 0,
        startingFrom: "",
        toLessThan: "",
        increment: "",
        isActive: true,
        companyId: localStorage.getItem('companyId')
    }

    const [initialBidForm, setInitialBidForm] = useState<any>(initialBidData);
    const [initialRangeForm, setInitialRangeForm] = useState<any>(intialRangeData);

    const schema: any = yup.object().shape({
        label: yup.string().required("Label is required").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, "Please enter upto 50 characters."),
        description: yup.string().required('Description is required').test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        })
    });

    const rangeSchema: any = yup.object().shape({
        startingFrom: yup
            .number()
            .max(99999999, "Please enter a value up to 8 digit")
            .transform((value, originalValue) => {
                const stringValue = String(originalValue);
                if (stringValue?.trim() === '') { return undefined }
                if (stringValue?.includes('-') || stringValue?.includes('+')) { return NaN; }
                return value
            })
            .test('is-finite', 'Starting from value should not be Infinity', (value) => Number.isFinite(value))
            .typeError('Please enter valid number')
            .required('Starting from is required'),
        toLessThan: yup.number()
            .max(99999999, "Please enter a value up to 8 digit")
            .typeError('Please enter valid number')
            .transform((value, originalValue) => {
                const stringValue = String(originalValue);
                if (stringValue?.trim() === '') { return undefined }
                if (stringValue?.includes('+')) { return NaN; }
                return value
            })
            .test('is-greater-than-first-value', 'To less than must be greater than starting from.',
                function (value: any) {
                    const { startingFrom } = this.parent;
                    return value > startingFrom;
                }
            )
            .required('To less than is required.'),
        increment: yup
            .number()
            .transform((value, originalValue) => {
                const stringValue = String(originalValue);
                if (stringValue?.trim() === '') { return undefined }
                if (stringValue?.includes('-') || stringValue?.includes('+')) { return NaN; }
                return value
            })
            .typeError('Please enter valid number.')
            .test('notZero', 'Increment cannot be zero', (value) => value !== 0)
            .required('Increment is required.'),
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialBidForm, mode: 'onChange' })

    const {
        register: rangeRegister,
        handleSubmit: rangeHandleSubmit,
        reset: rangeReset,
        setValue: rangeSetValue,
        formState: { errors: rangeErrors, isValid: rangeIsValid, isDirty: rangeIsDirty },
    } = useForm({ resolver: yupResolver(rangeSchema), defaultValues: initialRangeForm, mode: 'onChange' });

    const getAllBidData = () => {
        setLoading(true)
        axios.get(GETALLBIDINCREMENT, config).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const bidData = response.data.data
                const updatedBidData = bidData.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstBidIncrementRange: data.lstBidIncrementRange.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })
                const firstIndexRange = updatedBidData[0]?.lstBidIncrementRange ?? []
                // setActiveBidId(updatedBidData[0]?.bidIncrementId)
                setActiveBidId(updatedBidData.find((i: any) => i.isDefault == true).bidIncrementId)
                setStartingFrom(firstIndexRange)
                setBidIncrementData(updatedBidData);
                setBidRangeData(updatedBidData.find((i: any) => i.isDefault == true).lstBidIncrementRange);
                setActiveUniqueId(updatedBidData.find((i: any) => i.isDefault == true)?.uniqueId);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        })
    }

    const setStartingFrom = (rangeData: any) => {
        const getActiveRange = rangeData.filter((data: any) => data.mode !== 'delete')
        const lastIndexRange = getActiveRange?.length > 0 ? getActiveRange[getActiveRange?.length - 1] : 0
        const setStartingFromNumber = lastIndexRange.toLessThan ? lastIndexRange.toLessThan + decimalValForNxt : 0.01
        setLastLessThanValue(setStartingFromNumber);
        rangeSetValue('startingFrom', setStartingFromNumber);
    }

    const handleClose = () => {
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    reset(initialBidData);
                    setToggleValue(!toggleModal);
                }
            })
            return
        }
        reset(initialBidData);
        setToggleValue(!toggleModal);
    }

    const handleCloseRange = () => {
        if (rangeIsDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    setToggleValueRange(!toggleModalRange);
                    rangeReset(intialRangeData);
                }
            })
            return
        }
        rangeReset(intialRangeData);
        setToggleValueRange(!toggleModalRange);
    }

    const getBidIncrementId = (bidId: number, uniqueId: string, index: number) => {
        const found: any = bidIncrementData.find((obj: any) => {
            return obj.uniqueId === uniqueId;
        });
        const range = found.lstBidIncrementRange
        setActiveBidId(bidId)
        setActiveUniqueId(found.uniqueId)
        setStartingFrom(range)
        setActiveBidIndex(index)
        setBidRangeData(range.filter((data: any) => data.mode !== 'delete'))
    }

    const handleEditModel = (e: any, uniqId: number) => {
        e.stopPropagation()
        setMode('edit')
        setToggleValue(!toggleModal)
        const getData = bidIncrementData.find((data: any) => data.uniqueId === uniqId)
        reset(getData)
    }

    const onSubmit = (data: any) => {
        const trimData = trimObjectValues(data);

        let updatedData: any
        if (trimData.bidIncrementId === 0 && trimData.uniqueId === undefined) {
            const newBid = { ...initialBidData, ...trimData, bidIncrementFor: '1', lstBidIncrementRange: [], mode: "add", uniqueId: uuidv4() }
            updatedData = [...bidIncrementData, newBid]
        } else {
            updatedData = bidIncrementData.map((bData: any) => {
                if (bData.uniqueId === trimData.uniqueId) {
                    return { ...bData, ...trimData }
                }
                return bData
            })
        }
        const checkDefault = updatedData.filter((data: any) => data.isDefault === true).length
        if (checkDefault === 0) {
            updatedData[0].isDefault = true
        }
        setBidIncrementData(updatedData)
        setActiveBidId(updatedData[updatedData.length - 1]?.bidIncrementId)
        setBidRangeData(updatedData[updatedData.length - 1]?.lstBidIncrementRange ?? []);
        setActiveUniqueId(updatedData[updatedData.length - 1]?.uniqueId)
        setToggleValue(!toggleModal)
        reset(initialBidData)
    }

    const onSubmitRange = (data: any) => {
        if (!(data.startingFrom >= lastLessThanValue)) {
            rangeSetValue('startingFrom', lastLessThanValue)
            toast.error(`Starting from value should be greater than last less than value ${lastLessThanValue - decimalValForNxt}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return
        }

        const updatedBidData: any = bidIncrementData.map((bData: any) => {
            if (bData.uniqueId === activeUniqueId) {
                const updateMode = bData.lstBidIncrementRange.map((item: any) => (
                    {
                        ...item,
                        mode: (item.bidIncrementRangeId === 0) ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                    }
                ))
                const newBidData = { ...bData, lstBidIncrementRange: updateMode }
                return { ...newBidData, lstBidIncrementRange: [...newBidData.lstBidIncrementRange, { ...data, bidIncrementId: activeBidId, mode: "add", uniqueId: uuidv4() }] }
            }
            const updateMode = bData.lstBidIncrementRange.map((item: any) => (
                {
                    ...item,
                    mode: (item.bidIncrementRangeId === 0) ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                }
            ))
            return { ...bData, lstBidIncrementRange: updateMode }
        })

        const updateBidRange: any = updatedBidData.find((data: any) => data.uniqueId === activeUniqueId)?.lstBidIncrementRange
        setBidRangeData(updateBidRange)
        setBidIncrementData(updatedBidData)
        setLastLessThanValue(data.toLessThan + decimalValForNxt)
        rangeReset(intialRangeData)
        setToggleValueRange(!toggleModalRange)
    }

    const handleSave = () => {
        setLoading(true)
        const filterDummy = bidIncrementData.map((data: any) => {
            if (data.bidIncrementId === 0 && data.mode === 'delete') {
                return {}
            }
            return data
        }).filter((bData: any) => Object.keys(bData).length > 0)
        axios.post(UPDATEBIDINCREMENT, filterDummy, config).then((response) => {
            setLoading(false);
            getAllBidData();
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(BidIncrementMessages.bidIncrementUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
                getAllBidData()
            }
        }).catch((error: any) => {
            setLoading(false)
            if (error.response) {
                toast.error(error?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
            if (error?.response?.data?.code === 409) {
                getAllBidData()
            }
        })
    }

    // Delete range using unique key 
    const handleRangeDelete = (bidUniqueId: any, rangeUniqueId: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this range!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // const modifiedBidData: any = bidIncrementData.map((bid: any) => {
                //     if (bid.uniqueId === bidUniqueId) {
                //         // Filter out the deleted range from the bid range data
                //         const modifiedRanges = bid.lstBidIncrementRange.filter((range: any) => range.uniqueId !== rangeUniqueId);
                //         return { ...bid, lstBidIncrementRange: modifiedRanges };
                //     }
                //     return bid;
                // });

                const modifiedBidData: any = bidIncrementData.map((data: any) => {
                    const modifyRange = data.lstBidIncrementRange.map((rg: any) => {
                        if (rg.uniqueId === rangeUniqueId) {
                            return { ...rg, mode: "delete" }
                        }
                        return rg
                    })

                    return { ...data, lstBidIncrementRange: modifyRange }
                })
                // Update the bid increment data state with the modified data
                setBidIncrementData(modifiedBidData);
                // Find and update the bid range data state if require
                const activeBid = modifiedBidData.find((bid: any) => bid.uniqueId === bidUniqueId);
                if (activeBid) {
                    const activeBidRanges = activeBid.lstBidIncrementRange.filter((range: any) => range.mode !== 'delete');
                    setBidRangeData(activeBidRanges);
                }
                const getBid = modifiedBidData.find((data: any) => data.uniqueId === bidUniqueId)?.lstBidIncrementRange
                let getLastLessThan = getBid.filter((data: any) => data.mode !== "delete")
                setLastLessThanValue(getLastLessThan[getLastLessThan?.length - 1]?.toLessThan + decimalValForNxt)
            }
        })
        return
    }

    const handleBidDelete = (e: any, bidUniqueId: any) => {
        e.stopPropagation()
        const checkDefault: any = bidIncrementData.find((data: any) => data.uniqueId === bidUniqueId)
        if (checkDefault.isDefault) {
            setDeleteDisable(bidUniqueId);
            toast.error(errorMessages.defaultBid, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
            setTimeout(() => {
                setDeleteDisable('')
            }, 3000)
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this bid!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updateBid: any = bidIncrementData.map((data: any) => {
                    if (bidUniqueId === data.uniqueId) {
                        return { ...data, mode: 'delete' };
                    }
                    return data;
                })
                const getActive = updateBid.filter((data: any) => data.mode !== 'delete');
                const firstIndexRange = getActive[0]?.lstBidIncrementRange ?? []
                setActiveBidIndex(0);
                setBidIncrementData(updateBid);
                // setActiveBidId(getActive[0]?.bidIncrementId);
                setActiveBidId(getActive.find((i: any) => i.isDefault == true)?.bidIncrementId)
                setStartingFrom(firstIndexRange);
                // setBidRangeData(firstIndexRange);
                setBidRangeData(getActive.find((i: any) => i.isDefault == true)?.lstBidIncrementRange);
                setActiveUniqueId(getActive.find((i: any) => i.isDefault == true)?.uniqueId)
                // setActiveUniqueId(getActive[0]?.uniqueId);
            }
        })
        return;
    }

    const handleDefault = (e: any, bidUnique: any) => {
        e.stopPropagation();
        const chkEvent = e.target;
        const isChecked = chkEvent.checked;
        if (isChecked) {
            chkEvent.checked = false;
            const elem: any = document.getElementsByClassName('form-check-input');
            const oldData: any = bidIncrementData.find((data: any) => data.isDefault === true);
            const newData: any = bidIncrementData.find((data: any) => data.uniqueId === bidUnique);
            Swal.fire({
                title: 'Are you sure?',
                text: `Bid "${oldData.label}" is already Set as Default. Are you sure you want to Set Bid "${newData.label}" as Default!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    for (let i = 0; i < elem.length; i++) {
                        elem[i].checked = chkEvent === elem[i]
                    }
                    const updateDefault: any = bidIncrementData.map((data: any) => {
                        if (data.uniqueId === bidUnique) {
                            return { ...data, isDefault: chkEvent.checked }
                        }
                        return { ...data, isDefault: false }
                    })
                    setBidIncrementData(updateDefault);
                }
            })
        } else {
            chkEvent.checked = true;
        }
    }

    useEffect(() => {
        getAllBidData();
    }, [])

    useEffect(() => {
        const checkLast = bidRangeData?.length > 0 ? bidRangeData?.filter((data: any) => data.mode !== 'delete') : [];
        const getBool = checkLast?.length === 1 ? true : false;
        setIsLast(getBool);
    }, [bidRangeData])

    useEffect(() => {
        const filterDummy = bidIncrementData.filter((data: any) => data.mode !== 'delete');
        const checkRange = filterDummy.every((data: any) => data.lstBidIncrementRange.length > 0);
        setIsBidValid(checkRange);
    }, [bidIncrementData])

    return (
        <>
            <CommonHelmet title={"Bid Inc - "} name={"bidIncrement"} content={"Bid Increment"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            {/* bid modal starts */}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add New Bid' : 'Edit Bid Item'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Label <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Label"
                                    maxLength={maxWordCount}
                                    {...register("label")}
                                />

                                {errors.label && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.label.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Description <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Description"
                                    maxLength={maxWordCount}
                                    {...register("description", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount)) })}
                                />
                                {remainingCount2 != maxWordCount && <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                    {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                                </div>}
                                {errors.description && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.description.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit">{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* bid modal ends */}

            {/* range modal starts */}
            <Modal show={toggleModalRange} size='xl' centered>
                <form onSubmit={rangeHandleSubmit(onSubmitRange)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Add New Range</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseRange}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Starting From ($)<sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Starting From"
                                    maxLength={8}
                                    {...rangeRegister('startingFrom')}
                                />
                                {rangeErrors.startingFrom && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.startingFrom.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">To Less Than ($)<sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="To Less Than"
                                    maxLength={8}
                                    {...rangeRegister('toLessThan')}
                                />
                                {rangeErrors.toLessThan && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.toLessThan.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Increment ($)<sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Increment"
                                    maxLength={8}
                                    {...rangeRegister('increment')}
                                />
                                {rangeErrors.increment && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.increment.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleCloseRange}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit" disabled={!rangeIsValid}>{CommonMessages.saveButton}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* range modal ends */}

            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Bid Increment - Standard'>
                </InnerHeader>
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="mb-4 mt-4">
                                    <div className="row g-3">
                                        <div className="col-auto flex-grow-1">
                                            <p className="mb-0 fs--1">Create Bid Increment Tables to be used for 1) Absentee Bids, and 2) Auto-enter a bid increment when a entering or importing lots into inventory based on the starting bid. To select a table for either of these, see the relevant tab in Auctions, Absentee Bids or Checklist.<button type="button" className="btn text-primary p-0 ms-1" onClick={() => setReadMore(!readMore)}>{readMore ? 'Read Less' : 'Read More'}</button></p>
                                        </div>
                                    </div>
                                    {readMore && <div className="mb-4">
                                        <div className="row g-3">
                                            <div className="col-lg-6 mt-3">
                                                <h5 className="mb-2">Increment Tables</h5>
                                                <p className="mb-0 fs--1">Select a Table by Clicking the green triangle and then edit or add increments in the column on the right.</p>
                                            </div>
                                            <div className="col-lg-6 mt-3">
                                                <h5 className="mb-2">Increments</h5>
                                                <p className="mb-0 fs--1">Enter the Starting From and Up To cutoffs and the increment amount. Do not enter a "To" value for the last one.</p>
                                                <small className="mb-0 fw-bold"> NOTE : The From value should be exactly the same as the previous "To" value, e.g. 0 to 100, 100 to 500, 500 to 1000. Do not enter 99,99.99 or similar this is handled. </small>
                                            </div>
                                        </div>
                                    </div>}
                                    <hr />
                                    <div className="row g-3">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center justify-content-end mb-3">
                                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                                    onClick={() => {
                                                        setToggleValue(!toggleModal)
                                                        setMode('create')
                                                    }}
                                                >
                                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Bid </span>
                                                </button>
                                            </div>
                                            <div id="tableExample4">
                                                <div className="table-responsive tbody_scroll_cus">
                                                    <table className="table border-radius border td_bottom_border_0 fs--1 table-sm mb-0">
                                                        <thead>
                                                            <tr className="bg-gradiant">
                                                                <th className="border-bottom ps-1 pe-1 pt-2 pb-2">
                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                                        <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "20%" }}>Label</div>
                                                                        <div className="me-auto align-middle pe-2" style={{ width: "40%" }}>Description</div>
                                                                        <div className="me-auto align-middle pe-2" style={{ width: "30%" }}>Bid Increment For</div>
                                                                        <div className="align-middle payment text-end" style={{ width: "10%" }}>Action</div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list scrollbar">
                                                            {bidIncrementData.length > 0 && bidIncrementData.filter((data: any) => data.mode !== 'delete')?.length > 0 ? <tr>
                                                                <td className="align-middle ps-1 pe-1 name pt-2 pb-2">
                                                                    {bidIncrementData.map((bid: any, index: any) => {
                                                                        return (
                                                                            <>
                                                                                {(bid.mode !== "delete") &&
                                                                                    <div className={`d-flex flex-wrap border_ac ${(activeBidId == bid.bidIncrementId && activeUniqueId === bid.uniqueId) ? 'active' : ''}`}
                                                                                        onClick={() => getBidIncrementId(bid.bidIncrementId, bid.uniqueId, index)}
                                                                                        key={index}
                                                                                    >
                                                                                        <div className="d-flex w-100 flex-wrap align-items-center justify-content-between position-relative">
                                                                                            <div style={{ width: "20%" }} className="d-flex pe-2 flex-wrap align-items-center" >
                                                                                                <span className="ellipsis">{bid.label}</span>
                                                                                            </div>
                                                                                            <div style={{ width: "40%" }} className="me-auto align-middle pe-2">
                                                                                                <span className="ellipsis">{bid.description}</span>
                                                                                            </div>
                                                                                            <div style={{ width: "30%" }} className="me-auto align-middle pe-2">
                                                                                                <span>{bidIncFor?.find((data: any) => data.value == bid?.bidIncrementFor)?.label}</span>
                                                                                            </div>
                                                                                            <div style={{ width: "10%" }} className="align-middle payment text-end">
                                                                                                <div className="action_btn">
                                                                                                    <button type="button" className="action_icons text-decoration-none btn p-0"
                                                                                                        onClick={(e) => handleEditModel(e, bid.uniqueId)}
                                                                                                    > <img src={editIcon} /> </button>
                                                                                                    <button
                                                                                                        onClick={(e) => handleBidDelete(e, bid.uniqueId)}
                                                                                                        type="button"
                                                                                                        className="action_icons text-decoration-none ms-2 btn p-0"
                                                                                                        disabled={deleteDisable === bid.uniqueId}>
                                                                                                        <img src={deleteIcon} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-check w-100 ms-auto mt-1">
                                                                                            <input className="form-check-input" id={`Default${index}`} type="checkbox" onChange={(e) => handleDefault(e, bid.uniqueId)} defaultChecked={bid.isDefault === true} />
                                                                                            <label className={`form-check-label ${(bid.isDefault) ? 'text-danger' : ''}`} htmlFor={`Default${index}`}>{bid.isDefault ? 'Default' : 'Set as Default'}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </td >
                                                            </tr > : <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                <td className='text-center p-4'>No Bid found!</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="d-flex flex-wrap align-items-end justify-content-between mb-3">
                                                <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please add atleast one range against each bid.</i></span>
                                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 ms-auto position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#add_new_range"
                                                    onClick={() => {
                                                        if (lastLessThanValue >= 99999999) {
                                                            toast.warning(`You have exceeded the range limit. So you can't add new range.`, {
                                                                position: toast.POSITION.TOP_RIGHT,
                                                                autoClose: 4000,
                                                            });
                                                            return
                                                        }
                                                        setToggleValueRange(!toggleModalRange)
                                                        rangeSetValue('startingFrom', lastLessThanValue)
                                                    }}
                                                >
                                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Range </span>
                                                </button>
                                            </div>
                                            <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                                                <div className="table-responsive scrollbar border-top border-bottom">
                                                    <table className="table table-bordered table-sm mb-0 fs--1">
                                                        <thead>
                                                            <tr>
                                                                <th className="p-2">Starting From ($)</th>
                                                                <th className="p-2">To Less Than ($)</th>
                                                                <th className="p-2">Increment ($)</th>
                                                                <th style={{ width: "35px" }}></th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="list">
                                                            {bidRangeData?.length > 0 ? bidRangeData?.map((rangData: any, index: number) => {
                                                                return (
                                                                    rangData.mode !== "delete" && <tr key={index}>
                                                                        <td className="p-2">{formatNumber(rangData.startingFrom)}</td>
                                                                        <td className="p-2">{formatNumber(rangData.toLessThan)}</td>
                                                                        <td className="p-2">{formatNumber(rangData.increment)}</td>
                                                                        <td className="text-center p-2" style={{ width: "30px" }}>
                                                                            {(bidRangeData?.length === index + 1 && !isLast) &&
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn p-0 d-flex align-items-center"
                                                                                    onClick={() => handleRangeDelete(activeUniqueId, rangData.uniqueId)}
                                                                                ><img src={deleteIcon} height={12} /></button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={4}>No Range found!</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <Link
                            to={routeConstant.DASHBOARD}
                            className="btn btn-outline-danger me-auto"
                            type="button"
                            data-wizard-prev-btn="data-wizard-prev-btn"
                        >
                            {CommonMessages.cancelButton}
                        </Link>
                        <button className="btn btn-primary mx-2" onClick={handleSave}>{CommonMessages.saveButton}</button>
                    </div>
                </div>

            </div >
        </>
    )
}
export default BidIncrement;