import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from 'yup';
import DateTime from "../../../Utils/DateTime";
import MomentUtil from "../../../Utils/MomentUtil";
import { ERROR_MESSAGES } from "../../../Utils/Utils";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import { DELETELOTNOTES, GETLOTNOTESBYID, UPDATELOTNOTES } from "../../../common/apiConstatnts";
import { wordCounter } from "../../../common/constants";
import { CommonMessages, InventoryMessages, errorMessages } from "../../../common/messageConstants";
import * as routeConstant from '../../../common/routeConstants';
import { setStep } from "../../../store/actions/AuctionAction";
import { setCloneTab, setCreateTab } from "../../../store/actions/Inventory";
import { LOTNOTES } from "../InventoryConstants";

const Notes = () => {
    const momentUtil = MomentUtil.getInstance()
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const enteredBy = decodedToken.user;
    const lotId = useSelector((state: any) => state.inventory.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
    const createTabs = useSelector((state: any) => state.inventory.createTabs)
    const modeType = useSelector((state: any) => state.inventory.mode)

    const initialData = {
        lotId: modeType !== "clone" ? parseInt(lotId) : parseInt(cloneLotId),
        lotNotesId: 0,
        noteDate: "",
        date: "",
        dateDue: "",
        description: "",
        enteredBy: enteredBy,
        status: false,
        logNotesType: ""
    }

    const status: any = [
        { value: true, label: "Done" },
        { value: false, label: "Pending" }
    ]

    const type = [
        { value: "Phone", label: "Phone" },
        { value: "Email", label: "Email" },
        { value: "Mail", label: "Mail" },
        { value: "Fax", label: "Fax" },
        { value: "Other", label: "Other" },
    ]

    const dispatch = useDispatch()
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [showOpt, setShowOpt] = useState<string>('');
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [logNotes, setLogNotes] = useState([]);
    const [filterLogNotes, setFilterLogNotes] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [originalNotes, setOriginalNotes] = useState<any>([])

    const PER_PAGE = 10;
    const pageCount = Math.ceil(logNotes?.length / PER_PAGE);
    const maxWordCount = 100
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const paginateData = (data: any) => {
        setLogNotes(data);
        const firstSet = data;
        setFilterLogNotes(firstSet);
    }

    const schema: any = yup.object().shape({
        // dateDue: yup.date().required('Date is required.'),
        date: yup.string().required('Date is required.').test('valid-datetime', 'Invalid datetime', function (value) {
            const parsedDate: any = new Date(value);
            if (parsedDate.getFullYear() > 2500) {
                return this.createError({
                    message: 'Please Enter valid Date.',
                });
            }
            return !isNaN(parsedDate);
        }),
        description: yup.string()
            .required('Description is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        logNotesType: yup.string().required('Please select Log Notes Type'),
        status: yup.boolean(),
    })

    const {
        register: logRegister,
        handleSubmit: logHandleSubmit,
        getValues: logGetValues,
        reset: logReset,
        watch: logWatch,
        setValue: logSetValue,
        control: logControl,
        clearErrors: logClearErrors,
        formState: { errors: logErrors, isValid: logIsValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })

    const watchDateChange = logWatch('date')
    const watchStatus = logWatch('status')
    const logNotesType = logWatch('logNotesType')

    const getInventoryNotesById = () => {
        setLoading(true);
        const id = modeType === "clone" ? cloneLotId : lotId
        axios.get(`${GETLOTNOTESBYID}/?lotId=${id}`).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setOriginalNotes([])
                paginateData([])
                return;
            } else {
                let respData = response.data.data;
                /* if (!clonedTabs.includes(LOTNOTES) && cloneLotId > 0) {
                    respData = []
                } */
                setOriginalNotes(respData)
                paginateData(respData)
            }
        }).catch((error) => {
            setLoading(false);
        });
    }

    const onSubmitNotes = () => {
        const data = logGetValues();
        const date = momentUtil.formatWithTimezone(data.date, "YYYY-MM-DD", "Atlantic/St_Helena")
        const time = momentUtil.formatWithTimezone(data.date, "HH:mm:ss", "Atlantic/St_Helena")
        const noteDate = momentUtil.parseTimeInUtc().format('YYYY-MM-DD');
        data.date = date;
        data.time = time;
        data.noteDate = noteDate;
        data.dateDue = momentUtil.formatWithTimezone(data.dateDue, "YYYY-MM-DD", "Atlantic/St_Helena")
        data.enteredBy = enteredBy;

        setLoading(true);
        axios.post(UPDATELOTNOTES, data).then((response) => {
            if (response?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterLogNotes([]);
                return;
            } else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, LOTNOTES]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, LOTNOTES]))
                }
                getInventoryNotesById()
                logReset(filterLogNotes)
                toast.success(data.lotNotesId > 0 ? InventoryMessages.notesUpdated : InventoryMessages.notesCreated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }).finally(() => {
            setLoading(false);
            setToggleValue(!toggleModal);
            logReset(filterLogNotes);
            setRemainingCount(maxWordCount);
        });
    };

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = logNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = logNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName);
    }

    const handleEditModal: any = (lotNotesId: any, type: string) => {
        if (type === 'edit') {
            setMode('edit')
            setShowOpt('')
            setToggleValue(!toggleModal)
            let getData: any = logNotes.find((data: any) => data.lotNotesId === lotNotesId)
            const combineNoteDateTime = `${moment(getData.noteDate).format('YYYY-MM-DD')}T${getData.noteTime}`
            const convertToMoment = momentUtil.parseTimeInUtc({ dateTimeString: combineNoteDateTime }).format('MM/DD/YYYY hh:mm A')
            const dueDate = momentUtil.parseTimeInUtc({ dateTimeString: getData.dateDue }).format('MM/DD/YYYY hh:mm A')
            getData.dateDue = dueDate;
            getData.date = convertToMoment;
            setFormValue(getData)
            logReset(getData)
            return
        }
        setShowOpt('')
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(`${DELETELOTNOTES}?lotNotesId=${lotNotesId}`)
                    .then((response) => {
                        setLoading(false)
                        getInventoryNotesById()
                        logReset(filterLogNotes)
                        setSearchText('')
                        if (response?.data?.success === "Fail") {
                            toast.error(response?.data?.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            });
                            return;
                        }
                        else {
                            toast.success(InventoryMessages.notesDeleted, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            }
            setCurrentPage(0)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const handleClose = () => {
        logReset(initialData)
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    logReset(initialData)
                    setToggleValue(!toggleModal)
                    setRemainingCount(maxWordCount)
                }
            })
            return
        }
        setToggleValue(!toggleModal)
        setRemainingCount(maxWordCount)
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        if (keyword === '') {
            paginateData(originalNotes)
            setCurrentPage(0)
            return
        }
        const filterData: any = originalNotes?.filter((x: any) => x.description?.toLocaleLowerCase()?.includes(keyword) ||
            x.enteredBy?.toLocaleLowerCase()?.includes(keyword) ||
            x.type?.toLocaleLowerCase()?.includes(keyword))
        paginateData(filterData)
        setCurrentPage(0)
    }

    const toggleOptionsDD = (index: string) => {
        if (showOpt === '' || showOpt !== index) {
            setShowOpt(index)
            return
        }
        setShowOpt('')
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = logNotes?.slice(offset, offset + PER_PAGE)
        setFilterLogNotes(nextSet)
    }, [currentPage])

    useEffect(() => {
        if (watchDateChange) {
            const selectedDate: any = moment(watchDateChange).format('MM/DD/YYYY hh:mm A')
            logSetValue('dateDue', moment(selectedDate).add(1, 'M').format('MM/DD/YYYY hh:mm A'))
        }
    }, [watchDateChange])

    const prevStep = () => {
        /* dispatch(setStep('commCost')) */
        dispatch(setStep('docURls'))
    }

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    useEffect(() => {
        if (logNotesType) logClearErrors(["logNotesType"]);
    }, [logNotesType])

    useEffect(() => {
        getInventoryNotesById()
    }, [])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={toggleModal} size='xl' centered>
                <form onSubmit={logHandleSubmit(onSubmitNotes)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'New Notes' : 'Edit Notes'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label className="form-label">Date and Time<sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <Controller
                                        name="date"
                                        control={logControl}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    {...logRegister('dateDue')}
                                                    onChange={onChange}
                                                    dateFormat="MM/DD/y"
                                                    timeFormat="hh:mm A"
                                                    value={value}
                                                    closeOnSelect={true}
                                                    inputProps={{ placeholder: 'Select Date and Time' }}
                                                />
                                            </div>
                                        )}
                                    />
                                    {logErrors.date && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.date.message}</span>}
                                </div>

                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Due Date</label>
                                <div className="code-to-copy">
                                    <input
                                        className="form-control form-icon-input"
                                        type="text"
                                        placeholder="Due date"
                                        {...logRegister('dateDue')}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Description <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <textarea
                                        className="form-control form-icon-input"
                                        rows={4}
                                        cols={4}
                                        maxLength={maxWordCount}
                                        placeholder="Description"
                                        {...logRegister('description', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}

                                    ></textarea>
                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}
                                    {logErrors.description && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.description.message}</span>}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Status <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...logRegister('status')}
                                    name='status'
                                    options={status}
                                    value={status?.find((option: any) => option?.value == watchStatus)}
                                    onChange={(e: any) => logSetValue('status', e.value)}
                                />
                                {logErrors.status && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.status.message}</span>}
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Log Note Type <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...logRegister('logNotesType')}
                                    name='logNotesType'
                                    options={type}
                                    value={type?.find((option: any) => option?.value == logNotesType)}
                                    onChange={(e: any) => logSetValue('logNotesType', e.value)}
                                />

                                {logErrors.logNotesType && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.logNotesType.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <span className="btn btn-primary" onClick={logHandleSubmit(onSubmitNotes)}>{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</span>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="row justify-content-center top_border">
                <div className="col-lg-12 mt-3">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="search-box">
                            <div className="d-flex align-items-center">
                                <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                    <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search"
                                        value={searchText} onChange={(e: any) => setSearchText(e.target.value)}
                                    />
                                    <span className="fas fa-search search-box-icon"></span>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                            onClick={() => {
                                logReset(initialData)
                                setToggleValue(!toggleModal)
                                setMode('create')
                            }}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Notes </span>
                        </button>

                    </div>
                    <div className="bg-white mb-5 border-300">
                        <div className="table-responsive mx-n1 px-1 scrollbar">
                            <table className="table fs--1 mb-0 border-top border-200">
                                <thead>
                                    <tr>
                                        <th className="bg-gradiant " scope="col">Action</th>
                                        <th className="bg-gradiant " scope="col" data-sort="Date">
                                            <div className='d-flex align-items-center'>
                                                <span>Date</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant" scope="col" data-sort="description">
                                            <div className='d-flex align-items-center'>
                                                <span>Description</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'description')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'description')}  ></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant " scope="col" data-sort="enteredBy">
                                            <div className='d-flex align-items-center'>
                                                <span>Entered By</span>
                                                <div className="sorting_group">
                                                    <button className='arrow-up' onClick={() => handleSorting('ASC', 'enteredBy')} ></button>
                                                    <button className='arrow-down' onClick={() => handleSorting('DESC', 'enteredBy')} ></button>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant " scope="col" data-sort="dateDue">
                                            <div className='d-flex align-items-center'>
                                                <span>Due Date</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant " scope="col" data-sort="type">
                                            <div className='d-flex align-items-center'>
                                                <span>Type</span>
                                            </div>
                                        </th>
                                        <th className="bg-gradiant " scope="col" data-sort="status">
                                            <div className='d-flex align-items-center'>
                                                <span>Status</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="table-latest-review-body">
                                    {filterLogNotes?.length > 0 ? filterLogNotes.map((data: any, index: any) => (
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                <div className="font-sans-serif btn-reveal-trigger position-static">
                                                    <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button" onClick={() => toggleOptionsDD(index)}> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                    </button>
                                                    <div className={`dropdown-menu dropdown-menu-start py-2 ${(showOpt === index) ? 'show' : ''}`}>
                                                        <button className="dropdown-item d-flex align-items-center justify-content-between" type="button" onClick={() => handleEditModal(data?.lotNotesId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </button>
                                                        <button className="dropdown-item d-flex align-items-center justify-content-between" type="button" onClick={() => handleEditModal(data?.lotNotesId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="align-middle p-2">{`${momentUtil.parseTimeInUtc({ dateTimeString: data?.noteDate }).format("MM/DD/YYYY")} ${momentUtil.parseTimeInUtc({ dateTimeString: data?.noteTime, parseFormat: "HH:mm:ss" }).format("hh:mm A")}`}</td>
                                            <td className="align-middle p-2">{data?.description}</td>
                                            <td className="align-middle p-2">{data?.enteredBy}</td>
                                            <td className="align-middle p-2">{moment(data?.dateDue).format("MM/DD/YYYY  hh:mm A")}</td>
                                            <td className="align-middle p-2">{data?.logNotesType}</td>
                                            <td className="align-middle text-start status p-2">
                                                <span className={`badge badge-dpa fs--2 ${data?.status === true ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                    <span className="badge-label">{data?.status === true ? 'Done' : 'Pending'}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    )) :
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={8}>No records found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {filterLogNotes?.length > 0 && (
                            <ReactPaginate
                                previousLabel={"←"}
                                nextLabel={"→"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={currentPage}
                            />)}
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="submit" onClick={prevStep}>{CommonMessages.previousButton}</button>
                <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
                <button className="btn btn-secondary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn" onClick={() => dispatch(setStep('lotDoc'))}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}

export default Notes      
