import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { encryptId } from "../../../../Encryption/Encryption";
import * as routeConstant from "../../../../common/routeConstants";
import { SEARCHMASTERSELLERINAUCTION, SEARCHAUCTIONSELLERINAUCTION } from "../../../../common/apiConstatnts"
import axios from "axios";
import { useSelector } from "react-redux";
import { setActiveTab } from "../../../../store/actions/Seller";
interface MasterTableProps {
  items: any[];
  onMove: (selectedItems: {}) => void;
  icon: any;
  tableLabel: string;
  selectedValueCount: (isAnyValueSelected: boolean) => void;
  isCrossIconShow?: boolean;
  openCancelPopUp?: (data: any) => void;
  table: any;
  onClick: () => void
}

const ITEMS_PER_LOAD = 13;

const MasterTable = forwardRef(
  (props: MasterTableProps, ref: ForwardedRef<any>) => {
    const {
      items,
      onMove,
      icon,
      tableLabel,
      selectedValueCount,
      isCrossIconShow,
      openCancelPopUp,
      table,
      onClick
    } = props;
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItems, setSelectedItems] = useState<{
      [key: number]: boolean;
    }>({});
    const [actualItems, setActualItems] = useState<any[]>([]);
    const [displayedItems, setDisplayedItems] = useState<any[]>([]);
    const [filteredItemsEmpty, setFilteredItemsEmpty] = useState(false);
    const [loading, setLoading] = useState(false);
    const auctionId = useSelector((state: any) => state?.auction?.auctionId);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      getSelectedItems: () => {
        return selectedItems;
      },
      resetCheckedItems: () => {
        setSelectedItems({});
      },
      clearSearchBox: () => {
        setSearchTerm("");
        setActualItems(items); // Ensure the list is reset
        setFilteredItemsEmpty(false); // Reset filteredItemsEmpty flag
        setDisplayedItems(items?.slice(0, ITEMS_PER_LOAD));
      },
    }));

    const handleCheckboxChange = (item: any, index: number) => {
      setSelectedItems((prevState) => ({
        ...prevState,
        [index]: !prevState[index], // Toggle the checkbox state based on index
      }));
    };

    useEffect(() => {
      if (items?.length > 0) {
        setActualItems(items);
        setDisplayedItems(items?.slice(0, ITEMS_PER_LOAD));
      } else {
        setActualItems([]);
        setDisplayedItems([]);
      }
      return () => { };
    }, [items]);

    useEffect(() => {
      selectedValueCount(!isEmptyObject(removeFalseValues(selectedItems)));
      return () => { };
    }, [selectedItems]);

    useEffect(() => {
      const handleScroll = () => {
        if (
          tableContainerRef.current &&
          tableContainerRef.current.scrollTop + tableContainerRef.current.clientHeight >= tableContainerRef.current.scrollHeight - 5
        ) {
          loadMoreItems();
        }
      };

      // for lazy loading 
      const container = tableContainerRef.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll);
        }
      };
    }, [displayedItems, actualItems]);

    const handleSearch = (e: any) => {
      const keyword = e?.target?.value?.toLocaleLowerCase();
      // const API = table == "table1" ? `${SEARCHMASTERSELLERINAUCTION}?` : `${SEARCHAUCTIONSELLERINAUCTION}?auctionId=${auctionId}&`;
      // if (keyword?.length >= 3) {
      //   axios?.get(`${API}keyword=${keyword}`).then((response) => {
      //     console.log('response: ', response);
      //     const filteredItems = response?.data?.data
      //     setActualItems(filteredItems);
      //     setFilteredItemsEmpty(filteredItems?.length === 0);
      //     setDisplayedItems(filteredItems?.slice(0, ITEMS_PER_LOAD));
      //   }).catch(() => {
      //   })
      // }
      // else {
      //   setActualItems(items);
      //   setFilteredItemsEmpty(false);
      //   setDisplayedItems(items?.slice(0, ITEMS_PER_LOAD));
      //   return;
      // }
      setSearchTerm(e.target.value);
      if (keyword.length === 0) {
        setActualItems(items);
        setFilteredItemsEmpty(false);
        setDisplayedItems(items.slice(0, ITEMS_PER_LOAD));
        return;
      }
      const filteredItems = items.filter(
        (x: any) =>
          x?.firstName?.toLocaleLowerCase()?.includes(keyword) ||
          x?.lastName?.toLocaleLowerCase()?.includes(keyword) ||
          x?.companyName?.toLocaleLowerCase()?.includes(keyword) ||
          x?.sellerId?.toString()?.includes(keyword)
      );
      setActualItems(filteredItems);
      setFilteredItemsEmpty(filteredItems.length === 0);
      setDisplayedItems(filteredItems?.slice(0, ITEMS_PER_LOAD));
    };

    const isEmptyObject = (obj: Record<string, any>): boolean => {
      return Object.keys(obj).length === 0;
    };

    const removeFalseValues = (
      obj: Record<string, boolean>
    ): Record<string, boolean> => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== false)
      );
    };

    const handleSorting = (type: string, fieldName: string) => {
      let sortedData = [...actualItems];
      if (type === "ASC") {
        if (typeof sortedData[0][fieldName] === "string") {
          sortedData.sort((a: any, b: any) =>
            a[fieldName]?.localeCompare(b[fieldName])
          );
        } else if (typeof sortedData[0][fieldName] === "number") {
          sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
        }
      } else {
        if (typeof sortedData[0][fieldName] === "string") {
          sortedData.sort((a: any, b: any) =>
            b[fieldName]?.localeCompare(a[fieldName])
          );
        } else if (typeof sortedData[0][fieldName] === "number") {
          sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
        }
      }
      setActualItems(sortedData);
      setDisplayedItems(sortedData?.slice(0, displayedItems.length));
    };

    const loadMoreItems = () => {
      if (loading) return;
      setLoading(true);
      setTimeout(() => {
        const newItems = actualItems?.slice(
          displayedItems.length,
          displayedItems.length + ITEMS_PER_LOAD
        );
        setDisplayedItems((prevItems) => [...prevItems, ...newItems]);
        setLoading(false);
      }, 500);
    };

    const getNoDataView = () => {
      return (
        <tr className="">
          <td className="text-center p-4" colSpan={8}>
            No records found!
          </td>
        </tr>
      );
    };

    return (
      <div className="col-lg-6 mb-3 minus-arrow-size">
        <div className="d-flex align-items-center justify-content-between mb-3 add_with_search">
          <div className="d-flex flex-wrap">
            <img src={icon} className="opacity-50" />
            <h4 className="ms-2 mb-0">{tableLabel}</h4>
          </div>
          <div className="search-box mt-0 ms-auto">
            <input
              className="form-control search-input search form-control-sm"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onChange={(e: any) => handleSearch(e)}
            />

            {/* <input type="text" className="form-control search-input search form-control-sm p-2 ps-6" placeholder="Search" value={searchTerm} onChange={(e: any) => handleSearch(e)} /> */}
            {/* <input type="text" className="form-control search-input search form-control-sm p-2 ps-6" placeholder="Search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} /> */}

            <span className="fas fa-search search-box-icon"></span>
          </div>
        </div>

        <div className="table-responsive scrollbar min-height-486px border th-sticky" ref={tableContainerRef}>
          <table className="table table-sm mb-0 fs--1">
            <thead>
              <tr>
                <th
                  className="bg-gradiant p-2 "
                  scope="col"
                  data-sort="sellerId"
                >
                  <div className="d-flex align-items-center">
                    <span>Select</span>
                  </div>
                </th>
                <th
                  className="bg-gradiant p-2"
                  scope="col"
                  data-sort="sellerId"
                >
                  <div className="d-flex align-items-center">
                    <span>Seller Id</span>
                    <div className="sorting_group">
                      <button
                        className="arrow-up"
                        onClick={() => handleSorting("ASC", "sellerId")}
                      ></button>
                      <button
                        className="arrow-down"
                        onClick={() => handleSorting("DESC", "sellerId")}
                      ></button>
                    </div>
                  </div>
                </th>
                <th
                  className="bg-gradiant p-2"
                  scope="col"
                  data-sort="firstName"
                >
                  <div className="d-flex align-items-center">
                    <span>First Name</span>
                    <div className="sorting_group">
                      <button
                        className="arrow-up"
                        onClick={() => handleSorting("ASC", "firstName")}
                      ></button>
                      <button
                        className="arrow-down"
                        onClick={() => handleSorting("DESC", "firstName")}
                      ></button>
                    </div>
                  </div>
                </th>
                <th
                  className="bg-gradiant p-2"
                  scope="col"
                  data-sort="lastName"
                >
                  <div className="d-flex align-items-center">
                    <span>Last Name</span>
                    <div className="sorting_group">
                      <button
                        className="arrow-up"
                        onClick={() => handleSorting("ASC", "lastName")}
                      ></button>
                      <button
                        className="arrow-down"
                        onClick={() => handleSorting("DESC", "lastName")}
                      ></button>
                    </div>
                  </div>
                </th>
                <th className="bg-gradiant p-2" scope="col" data-sort="companyName">
                  <div className="d-flex align-items-center">
                    <span>Company</span>
                    <div className="sorting_group">
                      <button
                        className="arrow-up"
                        onClick={() => handleSorting("ASC", "companyName")}
                      ></button>
                      <button
                        className="arrow-down"
                        onClick={() => handleSorting("DESC", "companyName")}
                      ></button>
                    </div>
                  </div>
                </th>
                <th className="sort bg-gradiant ps-2"></th>
              </tr>
            </thead>

            <tbody className="list scroll">
              {actualItems && actualItems?.length > 0
                ? filteredItemsEmpty
                  ? getNoDataView()
                  : displayedItems?.map(
                    (item, index) =>
                      item && (
                        <tr key={item.sellerId}>
                          <td className="align-middle p-2">
                            <input
                              type="checkbox"
                              className="form-check-input form-check-states"
                              checked={!!selectedItems[item?.sellerId]}
                              onChange={() =>
                                handleCheckboxChange(item, item?.sellerId)
                              }
                            />
                          </td>
                          <td className="align-middle p-2">
                            {" "}
                            <Link
                              className="btn text-decoration-underline m-0 p-0 fw-normal text-start"
                              data-bs-dismiss="modal"
                              to={`${routeConstant.SELLERMOREINFO
                                }/${encryptId(item?.sellerId)}`}
                              onClick={onClick}
                              target="_blank"
                            >
                              {item?.sellerId}
                            </Link>
                          </td>
                          <td className="align-middle p-2">
                            {item?.firstName}
                          </td>
                          <td className="align-middle p-2">
                            {item?.lastName}
                          </td>
                          <td className="align-middle p-2">
                            {item?.companyName}
                          </td>
                          <td className="align-middle p-2">
                            {isCrossIconShow && (
                              <button
                                onClick={() => {
                                  if (openCancelPopUp) {
                                    openCancelPopUp(item);
                                  }
                                }}
                                className="btn p-1"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <span className="fas fa-times text-danger fs-20 pe-auto"></span>
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                  )
                : getNoDataView()}
              {loading && (
                <tr>
                  <td className="text-center p-2 fw-bold" colSpan={6}>Loading...</td>
                </tr>
              )}

            </tbody>

          </table>
        </div>
      </div>
    );
  }
);

export default MasterTable;