import React, { useEffect, useState } from "react"
import { GETBIDDERSBYCUSTOMERID, UPDATEBIDDERNUMBER, DELETEBIDDERNUMBER, GETALLLOTSBYBIDDERNUMBER, GETWONLOTSBYBIDDERNUMBER, GETLOSSLOTSBYBIDDERNUMBER, SOLDAUCTIONLIST, GETWINLOTS, SAVELOTSSELLERMAP, CREATEBIDDERNUMBER } from "../../../common/apiConstatnts"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import * as routeConstant from '../../../common/routeConstants';
import { toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import Swal from "sweetalert2"
import { addBidderStatus, loader, setActiveTabCustomer } from "../../../store/actions/CustomerAction"
import { Dropdown, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { decryptId } from "../../../Encryption/Encryption"
import { CommonMessages, CustomerMessages, errorMessages } from "../../../common/messageConstants";
import { formatCurrency, formatNumber, removeHtmlTags } from "../../../Utils/Utils";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const BidderNumber = () => {
    const dispatch = useDispatch()
    const [bidderNumberList, setBidderNumberList] = useState<[]>([])
    const [filterBidderNumber, setFilterBidderNumber] = useState<[]>([])
    const [currentPage, setCurrentPage] = useState(0);
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleSeller, setToggleSeller] = useState<boolean>(false)
    const [modalCurrentPage, setModalCurrentPage] = useState(0);
    const [modalData, setModalData] = useState<any>([]);
    const [filterModalData, setFilterModalData] = useState([]);
    const [soldAuction, setSoldAuction] = useState<any>([])
    const [dataForSeller, setDataForSeller] = useState<any>({ auctionId: '', bidderNumber: '' })
    const [sellerInfo, setSellerInfo] = useState<any>([])
    const [lotSelected, setLotSelected] = useState<any>([])
    const [activeBidder, setActiveBidder] = useState<any>("")
    const [modalTitle, setModalTitle] = useState<any>("")
    const [modalError, setModalError] = useState<any>({})
    const query = window.location.href;
    const vars = query.split("/");
    const ID = decryptId(vars[5]);

    const PER_PAGE = 10;
    const MODAL_PER_PAGE = 5;
    const pageCount = Math.ceil(bidderNumberList.length / PER_PAGE);
    const modalPageCount = Math.ceil(modalData.length / MODAL_PER_PAGE);
    const customerId = useSelector((state: any) => state.customer.contactInfo?.customerId)
    const addBidderValue = useSelector((state: any) => state.customer.addBidderStatus)


    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }
    const paginateData = (data: any) => {
        setBidderNumberList(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterBidderNumber(firstSet)
    }

    const modalPaginateData = (data: any) => {
        setModalData(data)
        const firstSet = data?.slice(0, MODAL_PER_PAGE);
        setFilterModalData(firstSet)
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }
    const handleModalPageClick = ({ selected: selectedPage }: any) => {
        setModalCurrentPage(selectedPage);
    }

    const getBidderById = () => {
        dispatch(loader(true))
        axios.get(`${GETBIDDERSBYCUSTOMERID}?customerId=${ID}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterModalData([]);
                return;
            }
            else {
                const data = response.data.data
                const modifyData = data?.map((num: any, index: any) => { return { ...num, srNo: index + 1 } })
                const getActiveBidder = data?.find((bData: any) => bData?.isActiveforbidding === true)
                setActiveBidder(getActiveBidder?.bidderNumber)
                paginateData(modifyData)
            }
            dispatch(loader(false))
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const getLotsByBidderNo = (bidNum: any, API: any) => {
        dispatch(loader(true))
        axios.get(`${API}?bidderNumber=${bidNum}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                return;
            }
            else {
                const data = response.data.data;
                const modifyData = data.map((num: any, index: any) => { return { ...num, srNo: index + 1 } })
                modalPaginateData(modifyData)
            }
            dispatch(loader(false))
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handleUpdateBidder = (e: any, bidderNumber: any, elemId: any) => {
        const actualValue = !e.target.checked
        const elem: any = document.getElementById(elemId)
        const checkboxes = document.querySelectorAll('.form-check-input:checked');
        const count = checkboxes.length;
        if (count === 0) {
            elem.checked = true
            toast.error(errorMessages.activeBidderNumber, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: `You want Bidder# ${bidderNumber} to set as default?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(loader(true))
                axios.post(`${UPDATEBIDDERNUMBER}?customerId=${ID}&bidderNumber=${bidderNumber}&isActiveforbidding=${!actualValue}`).then((response) => {
                    dispatch(loader(false));
                    if (!actualValue === true) {
                        const allCheckbox: any = document.getElementsByClassName('form-check-input')
                        for (let i = 0; i < allCheckbox.length; i++) {
                            allCheckbox[i].checked = false
                        }
                        elem.checked = true
                        setActiveBidder(bidderNumber)
                    }
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(CustomerMessages.bidderNumStausChange, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                    dispatch(loader(false))
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error);
                })
                return
            }
            elem.checked = actualValue
        })
    }

    const handleModalOpen = (bidNum: any, API: any, label: any) => {
        setToggleValue(true)
        setModalCurrentPage(0)
        // modalPaginateData()
        setModalTitle(label)
        getLotsByBidderNo(bidNum, API)
    }

    const handleSellerModalOpen = (bidderNumber: any) => {
        dispatch(loader(true))
        setToggleSeller(true)
        setDataForSeller({ bidderNumber: bidderNumber })
        axios.get(`${SOLDAUCTIONLIST}?bidderNumber=${bidderNumber}`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setSoldAuction([]);
                return;
            }
            else {
                const data = response.data.data ?? []
                setSoldAuction(data);
            }
            dispatch(loader(false))
        }).catch((error) => {
            dispatch(loader(false));
            if (error.response.status === 404) {
                toast.error(errorMessages.auctionNotFound, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            handleError(error);
        })
    }
    const handleNext = () => {
        dispatch(setActiveTabCustomer('creditCards'));
    }
    const getSellerByAuctionId = (e: any) => {
        setSellerInfo([])
        const auctionId = e.target.value
        if (auctionId) {
            dispatch(loader(true))
            const payload = { ...dataForSeller, auctionId: parseInt(auctionId) }
            setDataForSeller(payload)
            axios.get(`${GETWINLOTS}?bidderNumber=${payload.bidderNumber}&auctionId=${payload.auctionId}`).then((response) => {
                dispatch(loader(false))
                if (response?.data?.success === "Fail") {
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setSellerInfo([]);
                    return;
                }
                else {
                    setSellerInfo(response.data.data);
                }
            }).catch((error) => {
                dispatch(loader(false));
                if (error.response.status === 404) {
                    toast.error(errorMessages.auctionNotFound, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
                handleError(error);
            })
            return
        }
        setDataForSeller({ ...dataForSeller, auctionId: '' })
    }

    const handleSellerChecks = (e: any, lotId: any) => {
        const check = e.target.checked
        if (check) {
            const addChecks = [...lotSelected, lotId]
            setLotSelected(addChecks)
            return
        }
        const removeChecks = lotSelected.filter((sId: any) => sId !== lotId)
        setLotSelected(removeChecks)
    }

    const handleSellerClose = () => {
        setToggleSeller(false)
        setDataForSeller({})
        setSellerInfo([])
        setLotSelected([])
        setSoldAuction([])
    }

    const handleSave = () => {
        const sellerDataPayload = lotSelected.map((sData: any) => {
            const data = sellerInfo.find((siData: any) => siData.lotId === sData)
            return { lotId: data.lotId, sellerId: data.sellerId, isBuyBack: true }
        })
        const payload = { ...dataForSeller, customerId: ID, lstBidderSellerLotMapping: sellerDataPayload }
        dispatch(loader(true))
        axios.post(SAVELOTSSELLERMAP, payload).then((response) => {
            dispatch(loader(false))
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success("Seller linked sucessfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                handleSellerClose()
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handleDelete = (bidder: any) => {
        if (bidder.isActiveforbidding) {
            toast.error(CustomerMessages.bidDefaultCanNotDeleted, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this Bidder Number!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(loader(true));
                    axios.post(`${DELETEBIDDERNUMBER}?customerId=${bidder?.customerID}&bidderNumber=${bidder?.bidderNumber}`, { ...config })
                        .then((response: any) => {
                            dispatch(loader(false));
                            if (response?.data?.success === "Fail") {
                                toast.error(response?.data?.message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                });
                                return;
                            } else {
                                toast.success(CustomerMessages.bidderNumDeleted, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2000,
                                });
                                getBidderById();
                            }
                        }).catch((error) => {
                            dispatch(loader(false));
                            handleError(error);
                        })
                }
            })
        }
    }
    const handlePrevious = () => {
        dispatch(setActiveTabCustomer('contact'));
    }

    const handleAddBidder = () => {
        // dispatch(loader(true))
        axios
            .post(`${CREATEBIDDERNUMBER}?customerId=${customerId}`)
            .then(
                (response) => {
                    // dispatch(loader(false))
                    dispatch(addBidderStatus(true));
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    } else {
                        toast.success(CustomerMessages.bidderNumCreated, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }
            ).catch((error) => {
                dispatch(loader(false))
                handleError(error);
            });
    };    

    useEffect(() => {
        if (addBidderValue) {
            getBidderById()
            dispatch(addBidderStatus(false))
            setCurrentPage(0)
        }
    }, [addBidderValue])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet: any = bidderNumberList.slice(offset, offset + PER_PAGE)
        setFilterBidderNumber(nextSet)
    }, [currentPage])

    useEffect(() => {
        const allCheckbox: any = document.getElementsByClassName('form-check-input')
        const getCheckedEle: any = document.getElementById(`flexSwitchCheckDefault_${activeBidder}`)
        for (let i = 0; i < allCheckbox.length; i++) {
            allCheckbox[i].checked = false
        }
        if (getCheckedEle) {
            getCheckedEle.checked = true
        }
    }, [filterBidderNumber])

    useEffect(() => {
        const offset = modalCurrentPage * MODAL_PER_PAGE
        const nextSet: any = modalData?.slice(offset, offset + MODAL_PER_PAGE)
        setFilterModalData(nextSet)
    }, [modalCurrentPage])

    useEffect(() => {
        getBidderById()
    }, [])

    return (
        <>
            <Modal show={toggleModal} size="lg" centered>
                <Modal.Header>
                    <h5 className="modal-title" id="verticallyCenteredModalLabel">Bidder Details {`(${modalTitle})`}</h5>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                        setToggleValue(false)
                        modalPaginateData([])
                    }}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalBody">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="border-300">
                                        <div className="row ps-3 pe-3 pb-3">
                                            <div className="col-12">
                                                <div className="border-300">
                                                    <div className="table-responsive mx-n1 scrollbar">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant " scope="col">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>#</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Auction Name</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Auction date</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Lot #</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Lot Title</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="message">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Bid Amount</span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {
                                                                    filterModalData.length > 0 ? filterModalData.map((data: any, index: any) => {
                                                                        return (
                                                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                                <td className="align-middle  p-2">
                                                                                    <p className="mb-0 text-900">{data.srNo}</p>
                                                                                </td>
                                                                                <td className="align-middle  p-2">
                                                                                    <p className="mb-0 text-900">{data.auctionName}</p>
                                                                                </td>
                                                                                <td className="align-middle  p-2">
                                                                                    <p className="mb-0 text-900">{data.auctionDate}</p>
                                                                                </td>
                                                                                <td className="align-middle review p-2">
                                                                                    <p className="mb-0 text-900">{data.lotNo}</p>
                                                                                </td>
                                                                                <td className="align-middle review p-2">
                                                                                    <p className="mb-0 text-900">{data.lotTitle ? removeHtmlTags(data.lotTitle) : '-'}</p>
                                                                                </td>
                                                                                <td className="align-middle  p-2">
                                                                                    <p className="mb-0 text-900"> {formatCurrency(data.amount)}</p>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                        :
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                            <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-end me-4'>
                                            {filterModalData.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"←"}
                                                    nextLabel={"→"}
                                                    pageCount={modalPageCount}
                                                    onPageChange={handleModalPageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}
                                                    forcePage={modalCurrentPage}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* link to seller modal starts */}
            <Modal show={toggleSeller} size='lg' className={modalError?.bidNum ? "modal-md" : ""} centered>
                <Modal.Header>
                    <h5 className="modal-title" id="verticallyCenteredModalLabel">Link to Seller</h5>
                    <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleSellerClose}>
                        <span className="fas fa-times text-danger fs-20"></span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {modalError?.bidNum ?
                        <p>Unfortunately, there have been no successful bids for this bidder# {modalError?.bidNum}, so it is not possible to provide a link to the seller in this case.</p>
                        :
                        <div className="modalBody">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="border-300">
                                            <div className="row ps-3 pe-3 pb-3">
                                                <div className="col-lg-6">
                                                    <label htmlFor="soldAuctions">Sold Auctions</label>
                                                    <select name="soldAuctions" id="soldAuctions" className="form-select mb-3" onChange={(e) => getSellerByAuctionId(e)}>
                                                        <option value="">Select Auction</option>
                                                        {
                                                            soldAuction.map((sData: any, sKey: any) => {
                                                                return (
                                                                    <option key={sKey} value={sData.auctionId}>{sData.auctionCodeName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {sellerInfo.length > 0 && <div className="col-12">
                                                    <div className="table-responsive mx-n1 px-1 scrollbar">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="align-middle white-space-nowrap text-start p-2">Select</th>
                                                                    <th className="align-middle white-space-nowrap text-start p-2">Items</th>
                                                                    <th className="align-middle white-space-nowrap text-start p-2">Seller</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    sellerInfo.map((sData: any, sIndex: any) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={sIndex}>
                                                                                    <td className="align-middle white-space-nowrap text-start p-2">
                                                                                        <input type="checkbox" onClick={(e) => handleSellerChecks(e, sData.lotId)} />
                                                                                    </td>
                                                                                    <td className="align-middle white-space-nowrap text-start p-2">{sData.lotTitle ? removeHtmlTags(sData.lotTitle) : '-'}</td>
                                                                                    <td className="align-middle white-space-nowrap text-start p-2">{sData.sellerName}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {modalError?.bidNum ?
                        <button
                            className="btn btn-primary"
                            onClick={() => { setToggleSeller(false) }}>
                            OK
                        </button>
                        :
                        <>
                            <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleSellerClose}>{CommonMessages.cancelButton}</button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleSave}
                                disabled={!(Object.values(dataForSeller).every(value => value !== '') && lotSelected.length > 0)}
                            >{CommonMessages.saveButton}</button>
                        </>
                    }
                </Modal.Footer>
            </Modal>

            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10 mt-3 d-flex flex-column justify-content-start mb-1">
                        <h4 className="mt-2">Bidder Numbers</h4>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex gap-1">
                            </div>
                            <div className="d-flex gap-1" style={{ position: "relative", bottom: "24px" }}>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={handleAddBidder}
                                >
                                    <span className="plus me-2">
                                        {" "}
                                        <span className="fas fa-plus"></span>
                                    </span>{" "}
                                    <span>New Bidder Number</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-10">
                        <table className="table fs--1 mb-0 border-top border-200">
                            <thead>
                                <tr>
                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                        <div className='d-flex align-items-center'>
                                            <span>Action</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                        <div className='d-flex align-items-center'>
                                            <span>Bidder#</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                        <div className='d-flex align-items-center'>
                                            <span>Total Lots</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="lastname">
                                        <div className='d-flex align-items-center'>
                                            <span>Total Won</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                        <div className='d-flex align-items-center'>
                                            <span>Total Loss</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="company">
                                        <div className='d-flex align-items-center'>
                                            <span>Total Lot Amount</span>
                                        </div>
                                    </th>
                                    <th className="bg-gradiant" scope="col" data-sort="company">
                                        <div className='d-flex align-items-center'>
                                            <span>Generated Status</span>
                                        </div>
                                    </th>
                                    {/* <th className="bg-gradiant" scope="col" data-sort="company">
                                        <div className='d-flex align-items-center'>
                                            <span>Link to seller</span>
                                        </div>
                                    </th> */}
                                    {/* <th className="bg-gradiant" scope="col" data-sort="company">
                                        <div className='d-flex align-items-center'>
                                            <span>Default</span>
                                        </div>
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody className="list" id="table-latest-review-body">
                                {filterBidderNumber.length > 0 ? filterBidderNumber.map((bidderNum: any, index: any) => {
                                    return (
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                            <td className="align-middle  p-2">
                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                    <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant="none">
                                                        <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                        <Dropdown.Item role='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleDelete(bidderNum)} >Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td className="align-middle  p-2">{bidderNum.bidderNumber}</td>
                                            <td className="align-middle  p-2">
                                                {bidderNum.totalLots == 0 ?
                                                    <span className="m-0 p-0 fw-normal">{formatNumber(bidderNum.totalLots)}</span>
                                                    :
                                                    <span className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal" onClick={() => handleModalOpen(bidderNum.bidderNumber, GETALLLOTSBYBIDDERNUMBER, 'Total')}>
                                                        {formatNumber(bidderNum.totalLots)}
                                                    </span>
                                                }
                                            </td>
                                            <td className="align-middle  p-2">
                                                {bidderNum.totalWon == 0 ?
                                                    <span className="m-0 p-0 fw-normal">{formatNumber(bidderNum.totalWon)}</span>
                                                    :
                                                    <span className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal" onClick={() => handleModalOpen(bidderNum.bidderNumber, GETWONLOTSBYBIDDERNUMBER, 'Won')}>
                                                        {formatNumber(bidderNum.totalWon)}
                                                    </span>
                                                }
                                            </td>
                                            <td className="align-middle  p-2">
                                                {bidderNum.totalLoss == 0 ?
                                                    <span className="m-0 p-0 fw-normal">{formatNumber(bidderNum.totalLoss)}</span>
                                                    :
                                                    <span className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal" onClick={() => handleModalOpen(bidderNum.bidderNumber, GETLOSSLOTSBYBIDDERNUMBER, 'Loss')}>
                                                        {formatNumber(bidderNum.totalLoss)}
                                                    </span>
                                                }
                                            </td>
                                            <td className="align-middle  p-2">{formatCurrency(bidderNum?.totalAmount)}</td>
                                            <td className="align-middle  p-2">{bidderNum.generationType}</td>
                                            {/* <td className="align-middle  p-2">
                                                <button className="btn btn-sm btn-primary px-2 py-1" type="button"
                                                    onClick={() => {
                                                        if (bidderNum.totalWon == 0) {
                                                            setModalError({ bidNum: bidderNum.bidderNumber })
                                                            setToggleSeller(true)
                                                            return
                                                        }
                                                        setModalError({})
                                                        handleSellerModalOpen(bidderNum.bidderNumber)
                                                    }}
                                                >
                                                    Link
                                                </button>
                                            </td> */}
                                            {/* <td className="align-middle  p-2">
                                                <div className="form-check form-switch m-0">
                                                    <input
                                                        className="form-check-input"
                                                        defaultChecked={bidderNum.isActiveforbidding}
                                                        type="checkbox"
                                                        role="switch"
                                                        onChange={(e) => handleUpdateBidder(e, bidderNum.bidderNumber, `flexSwitchCheckDefault_${bidderNum.bidderNumber}`)}
                                                        id={`flexSwitchCheckDefault_${bidderNum.bidderNumber}`} />
                                                </div>
                                            </td> */}
                                        </tr>
                                    )
                                }) :
                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                        <td className='text-center p-4' colSpan={8}>No records found!</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                    {filterBidderNumber.length > 0 && (
                        <ReactPaginate
                            previousLabel={"←"}
                            nextLabel={"→"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                            forcePage={currentPage}
                        />)}
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipNext}</button>
                </div>
            </div>
        </>
    )
}

export default BidderNumber