import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { DELETESELLER, GETALLAUCTIONLIST, GETCOMMONVALUE, GETSELLER, SELLERADVANCEFILTER } from "../../common/apiConstatnts";
import { activationStatus } from "../../common/constants";
import Helper from "../../common/helpers";
import { Breadcrumb } from '../../common/interface';
import { errorMessages, SellerMessages } from "../../common/messageConstants";
import { COMPANYNAMEREGEX, FIRSTANDLASTNAMEREGEX, PHONEFILTER, PHONE_MESSAGEFILTER } from "../../common/regexConstants";
import * as routeConstant from '../../common/routeConstants';
import * as actions from '../../common/types';
import { encryptId } from "../../Encryption/Encryption";
import DateTime from "../../Utils/DateTime";
import { ERROR_MESSAGES, trimObjectValues, useDebounce } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import MomentUtil from "../../Utils/MomentUtil";
import { Link } from "react-router-dom";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { setActiveTab } from "../../store/actions/Seller";
import CommonHelmet from "../../common/HelmetWrapper";
import { setAuctionCodeAndTitle } from "../../store/actions/AuctionAction";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Sellers', url: routeConstant.SELLER }
]

const Seller = () => {

    const companyId: any = localStorage.getItem('companyId')
    const initialData = {
        sellerId: 0,
        companyId: parseInt(companyId),
        seller: "",
        altId: "",
        initials: "",
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        title: ""
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const getPermission: any = Helper.decodePermissions()?.find((data: any) => data.moduleName === 'Sellers')
    const [permission] = useState<any>(getPermission);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [filterSeller, setFilterSeller] = useState([]);
    const [seller, setSeller] = useState<[]>([]);
    const [originalSeller, setOriginalSeller] = useState<[]>([]);
    const [showFilter, setShowFilter] = useState(false);
    const [mode, setMode] = useState<string>('create');;
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [initialFormValue, setFormValue] = useState<any>(initialData);
    const [currentSalesRep, setCurrentSeller] = useState<any>({});
    const [initial, setInitial] = useState<[]>([]);
    const [states, setStates] = useState<[]>([]);
    const [country, setCountry] = useState<any>([]);
    const [state, setState] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [selectedCountry, setselectedCountry] = useState('');
    const [selectedState, setselectedState] = useState('');
    const [resetDate, setResetDate] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0)
    const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<any>({});
    const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE)
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [auctionList, setAuctionList] = useState<any>([]);
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        email: ''
    });

    const PER_PAGE = 10;
    const momentUtil = MomentUtil.getInstance();
    const dateRangeData = useSelector((state: any) => state.dashboard.dateRange)
    const searchData = useSelector((state: any) => state.dashboard.searchData)
    const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const [filterData, setFilterData] = useState<any>(
        {
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            isActive: -1,
            state: "",
            country: "",
            startDateTime: searchData?.date ? searchData?.date : dateRangeData.default ? '' : dateRangeData.fromDate,
            endDateTime: searchData?.date ? searchData?.date : dateRangeData.default ? '' : dateRangeData.toDate,
            auctionId: hammerAuctionId ? hammerAuctionId : '',
            keyword: ''
        });


    useEffect(() => {
        dispatch(setAuctionCodeAndTitle({}))
        if (hammerAuctionId) {
            setFilterData({ ...filterData, auctionId: hammerAuctionId });
            setAlreadyFetchedDataPageNumber({})
            setShowFilter(true);
            setCurrentPage(1);
        }
        else {
            clearAdvanceFilterData();
            setShowFilter(false);
        }

    }, [hammerAuctionId]);


    useEffect(() => {
        if (hammerAuctionId) {
            setAlreadyFetchedDataPageNumber({})
            const resetFilter = {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                isActive: -1,
                state: "",
                country: "",
                startDateTime: "",
                endDateTime: "",
                auctionId: hammerAuctionId ? hammerAuctionId : '',
                keyword: ''
            }
            setFilterData(resetFilter)
            clearSearchBar()
            currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)
            setShowFilter(true);
        } else {
            if (searchData?.id || searchData?.name || searchData?.date) {
                setShowFilter(false);
                dispatch({ type: actions.RESETSEARCHDATA });
                return;
            }
            else {
                clearAdvanceFilterData();
                clearSearchBar()
                setShowFilter(false);
            }
        }
    }, [hammerAuctionId]);



    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const schema: any = yup.object().shape({
        altId: yup.number().required("Alt ID is required").typeError('Alt ID must be a number.')
            .transform((value, originalValue) => {
                return (originalValue === '') ? undefined : value
            }),
        initials: yup.string().required("Please select initals."),
        firstName: yup.string().required("First name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid first name.').max(20, "Please enter upto 20 characters."),
        lastName: yup.string().required("Last name is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).matches(FIRSTANDLASTNAMEREGEX, 'Please enter valid last name.').max(20, "Please enter upto 20 characters."),

        middleName: yup.string().notRequired()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test('alphanumeric-dot', 'Please enter valid middle name', (value) => {
                return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
            }),
        company: yup.string().required("Company name is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(COMPANYNAMEREGEX, 'Please enter valid company name'),
        mobile: yup
            .string()
            .trim()
            .transform((value, originalValue) => {
                return originalValue === '' ? undefined : value;
            })
            .required('Phone number is required.')
            .test('valid-mobile', 'Please enter a valid phone number.', (value) => {
                if (!value) {
                    return true;
                }
                const mobileRegex = /^(\d{1,2})?\s?\d{10}$/;
                return mobileRegex.test(value);
            }),
        title: yup.string().required("Title is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
    });

    const filterSchema: any = yup.object().shape({
        // firstName: yup.string()
        //     .nullable()
        //     .matches(/^[A-Za-z\s]+$/, 'Only letters and spaces are allowed')
        //     .test('no-leading-space', 'Leading spaces are not allowed', value => value === null || value === undefined || !value.startsWith(' ')),
        firstName: yup
            .string()
            .nullable()
            .test(
                "no-leading-space",
                "Leading spaces are not allowed",
                (value) =>
                    value === null || value === undefined || !value.startsWith(" ")
            )
            .matches(/^[A-Za-z\s]*$/, "Only letters and spaces are allowed"),
        lastName: yup
            .string()
            .nullable()
            .test(
                "no-leading-space",
                "Leading spaces are not allowed",
                (value) =>
                    value === null || value === undefined || !value.startsWith(" ")
            )
            .matches(/^[A-Za-z\s]*$/, "Only letters and spaces are allowed"),
        company: yup
            .string()
            .nullable()
            .test(
                "no-leading-space",
                "Leading spaces are not allowed",
                (value) =>
                    value === null || value === undefined || !value.startsWith(" ")
            ),
        mobile: yup.string().nullable().matches(PHONEFILTER, PHONE_MESSAGEFILTER),
    });

    const {
        register: FilterRegister,
        handleSubmit: filterHandleSubmit,
        reset: filterReset,
        clearErrors: clearFilterError,
        setValue: filterSetValue,
        watch: filterWatch,
        getValues: filterGetValues,
        formState: {
            errors: filterErrors,
            isValid: filterIsValid,
            isDirty: filterIsDirty,
        },
    } = useForm({
        resolver: yupResolver(filterSchema),
        // defaultValues: initialFilterData,
        mode: "onChange",
    });

    const {
        handleSubmit,
        register,
        reset,
        watch,
        control,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })
    const watchedFields = watch();

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...filterSeller];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }

        paginateData(sortedData, currentPage);
        // setCurrentPage(1);
    };

    let filterActivationStatus = activationStatus.map((data: any) => {
        return {
            label: data.label, value: data.value
        }
    })

    const convertToDateOnlyFormat = (dateTimeString: any) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const changeHandler = (fieldName: string, value: any) => {
        let formattedValue = value;
        if (fieldName == "country") {
            setselectedCountry(value.value)
        }
        if (fieldName === "state") {
            setselectedState(value.value);
        }
        if (fieldName == "startDateTime") {
            formattedValue = value ? convertToDateOnlyFormat(value) : '';
        }
        if (fieldName == "endDateTime") {
            formattedValue = value ? convertToDateOnlyFormat(value) : '';
        }

        const updateData = { ...filterData, [fieldName]: formattedValue }
        setFilterData(updateData);
        if (fieldName === "companyName" || fieldName === "firstName" || fieldName === "lastName") {
            const isValidTitle = !/^\s/.test(value);
            if (!isValidTitle) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
        if (fieldName === "email") {
            const isValidemail = /^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)*)?\s*$/.test(value);
            const hasLeadingSpace = /^\s/.test(value);
            if (hasLeadingSpace) {
                setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
                return;
            } else if (!isValidemail) {
                setError({ ...error, [fieldName]: 'Please enter valid email only' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const handleDeleteModal = (sellerId: string, type: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                axios.post(`${DELETESELLER}?sellerId=${sellerId}`, {}, config).then((response) => {
                    setLoading(false)
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        toast.success(SellerMessages.sellerDeleted, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                    setCurrentPage(1);
                    clearAdvanceFilterData(false, filterData);
                }).catch((error) => {
                    setLoading(false);
                    handleError(error);
                });
            }
        })
    }

    const handleDelete = (id: any) => {
        if (!permission.isAllowDelete) {
            Helper.unAuthorized()
            return
        }
        handleDeleteModal(id, 'delete')
    }

    const handleEdit = (id: any) => {
        if (!permission?.isAllowEdit) {
            Helper.unAuthorized()
            return
        }
        navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(id)}`)
    }

    const getAllStates = async () => {
        await axios.get(`${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setStates(response?.data?.data);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    // pagination related changes arranged in sequence for take easy reference.
    const handleAdvanceFilter = (fData: any, pageNum?: any) => {
        const data = trimObjectValues(fData);
        setLoading(true)
        axios.get(SELLERADVANCEFILTER, {
            ...config,
            params: {
                auctionId: data?.auctionId ?? "",
                sellerId: data?.sellerId ?? "",
                firstName: data?.firstName ?? '',
                lastName: data?.lastName ?? '',
                email: data?.email ?? '',
                companyName: data?.companyName ?? '',
                isActive: data?.isActive?.value ?? -1,
                startDateTime: data?.startDateTime ? `${data?.startDateTime} 00:00:00` : '',
                endDateTime: data?.endDateTime ? `${data?.endDateTime} 23:59:59` : '',
                country: data?.country?.value ?? '',
                state: data?.state?.value ?? '',
                keyword: data?.keyword ?? '',
                pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
                pageSize: paginationSize
            }
        }).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterSeller([]);
                return;
            }
            else {
                paginateData(response?.data?.data?.data, pageNum ? pageNum : currentPage)
                setTotalRecords(response?.data?.data?.totalRecords)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
        return;
    }

    const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
        setAlreadyFetchedDataPageNumber({});
        currentPage !== 1 && setCurrentPage(1);
        if (data == null) {
            setIsFiltered(false)
            const resetFilter = {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                isActive: -1,
                startDateTime: '',
                endDateTime: '',
                state: '',
                country: '',
                auctionId: isLocalClear ? "" : hammerAuctionId,
                keyword: ''
            }
            handleAdvanceFilter(resetFilter, 1);
            clearSearchBar()
            setFilterData(resetFilter);
        } else if (!isLocalClear) {
            // this else if called when make search bar text empty
            handleAdvanceFilter(data, 1);
            setFilterData(data)
        }
        dispatch({ type: actions.RESETSEARCHDATA });
        dispatch({ type: actions.RESETDATERANGE });
        setError({ firstName: '', lastName: '', companyName: '', email: '' });

        if (!isLocalClear) {
            handleAdvanceFilter(data, 1);
            setFilterData(data)
        }
        setCurrentPage(1);
        setResetDate(false)
    }

    const searchHandler = () => {
        handleAdvanceFilter({
            firstName: searchData?.name,
            sellerId: searchData?.id,
            startDateTime: searchData?.date,
            endDateTime: searchData?.date,
            isActive: -1
        })
    }

    const getAllSellers = (pageSize: any = 10, pageNum: any = 1) => {
        setLoading(true)
        axios.get(`${GETSELLER}?pageNumber=${pageNum}&pageSize=${paginationSize}`, config).then((response) => {
            if (response?.data?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                setFilterSeller([]);
                return;
            }
            else {
                const sellerdata = response?.data?.data?.data
                // setOriginalSeller(sellerdata)
                // paginateData(sellerdata)
                setTotalRecords(response?.data?.data?.totalRecords)
                paginateData(sellerdata, pageNum);
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const handlePageClick = (selected: any) => {
        const newPageNumber = selected + 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(newPageNumber)
    }

    const updateData = (pageNumber: number, newData: any) => {
        setAlreadyFetchedDataPageNumber((prevState: any) => ({
            ...prevState,
            [pageNumber]: newData
        }));
    };

    const paginateData = (data: any, pageNum: any = 1) => {
        let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
        updateData(page, data)
        setFilterSeller(data);
    }

    const debouncedRequest = useDebounce(() => {
        handleAdvanceFilter(filterData);
    });

    const getAllAuctions = () => {
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            let auctionData = []
            if (response?.data?.data) {
                auctionData = response?.data?.data?.map((lData: any) => {
                    return {
                        label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                        value: lData.auctionId,
                    };
                });
            }
            setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
            setAuctionList([]);
        });;
    };

    const clearSearchBar = () => {
        const searchbar: any = document.getElementById('searchBar')
        searchbar.value = ''
    }

    // this useEffect called when click on pageNumber
    useEffect(() => {
        //when redirected from dashboard with global search.
        if (searchData?.id || searchData?.name || searchData?.date) {
            searchHandler()
            return
        }
        //when global search applied
        if (filterData?.keyword) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSeller(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            handleAdvanceFilter(filterData)
            return
        }

        //when advanced search filter applied
        else if (isFiltered) {
            //this prevents dual API call
            if (currentPage == 0) {
                return
            }
            //when clicked on already fetched page
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSeller(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            handleAdvanceFilter(filterData)
            return
        }

        //when clicked on already fetched page
        else {
            if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
                setFilterSeller(alreadyFetchedDataPageNumber[currentPage])
                return
            }
            getAllSellers(paginationSize, currentPage)
        }

    }, [currentPage])

    useEffect(() => {
        getAllAuctions()
        axios.get(`${GETCOMMONVALUE}?filter=Initials&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setInitial(response.data.data);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
        getAllStates()
        dispatch({ type: actions.RESETSEARCHDATA })
        dispatch({ type: actions.RESETDATERANGE })
    }, [])

    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=Country&companyId=${localStorage.getItem("companyId")}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                setCountry(response.data.data);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        })
    }, [])

    useEffect(() => {
        if (!selectedCountry) {
            setCity([])
            setState([])
            return
        } else {
            axios.get(`${GETCOMMONVALUE}?filter=State&companyId=${localStorage.getItem("companyId")}&parentId=${selectedCountry}`, config).then((response) => {
                if (response?.data?.success === "Fail") {
                    setState([])
                    setFilterData({ ...filterData, state: '' })
                    return;
                }
                else {
                    const data = response.data.data;
                    setFilterData({ ...filterData, state: '' })
                    setState(data)
                }
            }).catch((error) => {
                setState([]);
                setLoading(false);
                handleError(error);
            });
        }
    }, [selectedCountry])

    // this is for reset date in filter section.
    useEffect(() => {
        if (resetDate == false) {
            setTimeout(() => { setResetDate(true) }, 50)
        }
    }, [filterData])

    useEffect(() => {
        if (hammerAuctionId) {
            setAlreadyFetchedDataPageNumber({})
            const resetFilter = {
                firstName: '',
                lastName: '',
                email: '',
                companyName: '',
                isActive: -1,
                state: '',
                country: '',
                startDateTime: '',
                endDateTime: '',
                auctionId: hammerAuctionId ? hammerAuctionId : '',
                keyword: ''
            }
            setFilterData(resetFilter)
            clearSearchBar()
            currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)
            setShowFilter(true);
        } else {
            if (searchData?.id || searchData?.name || searchData?.date) {
                setShowFilter(false);
                dispatch({ type: actions.RESETSEARCHDATA });
                return;
            }
            else {
                clearAdvanceFilterData();
                clearSearchBar()
                setShowFilter(false);
            }
        }
    }, [hammerAuctionId]);

    return (
        <>
            <CommonHelmet title={"Seller - "} name={"seller"} content={"Seller"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Sellers'>
                    <div className="search-box mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input
                                    className="form-control search-input search form-control-sm"
                                    id="searchBar"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    onChange={(e: any) => {
                                        if (e.target.value.length != 0) {
                                            setFilterData({ ...filterData, keyword: e.target.value })
                                            setAlreadyFetchedDataPageNumber({})
                                            setCurrentPage(1)
                                            debouncedRequest();
                                            return
                                        } else {
                                            clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                                            setFilterData({ ...filterData, keyword: '' })
                                        }
                                    }
                                    }
                                />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
                        <button className="btn btn-primary ms-2 position-relative d-flex aling-items-center" type="button"
                            onClick={() => {
                                if (!permission.isAllowAdd) {
                                    Helper.unAuthorized()
                                    return
                                }
                                navigate(routeConstant.SELLERMOREINFO)
                                // reset(initialData)
                                // setToggleValue(!toggleModal)
                                setMode('create')
                            }}
                        >
                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Seller </span>
                        </button>
                    </div>
                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-2">
                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter) })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-lg-3">
                                                    <label className="form-label">Select Auction</label>
                                                    <Select
                                                        classNamePrefix="dpaSelect"
                                                        placeholder="Select Auction"
                                                        className="isActive swap-to-lot-menu clone-inventory"
                                                        options={auctionList}
                                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                                        value={auctionList.find((option: any) => option.value == filterData.auctionId)}
                                                        onChange={(e: any) => {
                                                            changeHandler("auctionId", e?.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">First Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="firstName"
                                                        placeholder="First name"
                                                        value={filterData.firstName}
                                                        onChange={((e) => changeHandler("firstName", e.target.value))}
                                                    />
                                                    {error.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.firstName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Last Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lastName"
                                                        placeholder="Last name"
                                                        value={filterData.lastName}
                                                        onChange={((e) => changeHandler("lastName", e.target.value))}
                                                    />
                                                    {error.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.lastName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Email</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={filterData.email}
                                                        onChange={((e) => changeHandler("email", e.target.value))}
                                                    />
                                                    {error.email && <span className='text-danger' style={{ fontSize: "12px" }}>{error.email}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Company Name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="companyName"
                                                        placeholder="Company Name"
                                                        value={filterData.companyName}
                                                        onChange={((e) => changeHandler("companyName", e.target.value))}
                                                    />
                                                    {error.companyName && <span className='text-danger' style={{ fontSize: "12px" }}>{error.companyName}</span>}
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Activation Status</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select Activation Status"
                                                        className='isActive'
                                                        name='isActive'
                                                        options={filterActivationStatus}
                                                        value={filterData.isActive}
                                                        onChange={(e) => changeHandler("isActive", e)}
                                                    />
                                                </div>
                                                {resetDate ?
                                                    <div className="col-lg-3">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">From Created Date
                                                            {/* ({defaultTimezone}) */}
                                                        </label>
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input only_date_icon",
                                                                    placeholder: "MM / DD / YYYY",
                                                                    name: "startDateTime",

                                                                }}
                                                                closeOnSelect={true}
                                                                value={filterData.startDateTime}
                                                                onChange={(date: any) => changeHandler("startDateTime", date)}
                                                                timeFormat={false}
                                                                dateFormat="MM/DD/y"
                                                            />
                                                        </div>
                                                    </div> : <></>}
                                                {resetDate ?
                                                    <div className="col-lg-3">
                                                        <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">To Created Date
                                                            {/* ({defaultTimezone}) */}
                                                        </label>
                                                        <div className="date_icon">
                                                            <DateTime
                                                                inputProps={{
                                                                    className: "form-control form-icon-input only_date_icon",
                                                                    placeholder: "MM / DD / YYYY",
                                                                    name: "endDateTime",

                                                                }}
                                                                closeOnSelect={true}
                                                                value={filterData.endDateTime}
                                                                onChange={(date: any) => changeHandler("endDateTime", date)}
                                                                timeFormat={false}
                                                                dateFormat="MM/DD/y"
                                                            />
                                                        </div>
                                                    </div> : <></>}
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">
                                                        Country
                                                    </label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select Country"
                                                        className='isActive'
                                                        name='country'
                                                        options={country.map((country: any) => {
                                                            return {
                                                                label: country.objectTypeValue, value: country.objectValueId
                                                            }
                                                        })}
                                                        value={filterData?.country}
                                                        onChange={(e) => changeHandler("country", e)}
                                                    />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">State</label>
                                                    <Select classNamePrefix="dpaSelect"
                                                        placeholder="Select State"
                                                        className='isActive'
                                                        name='state'
                                                        options={state?.map((state: any) => {
                                                            return {
                                                                label: state.objectTypeValue, value: state.objectValueId
                                                            }
                                                        })}
                                                        isDisabled={!filterData.country}
                                                        value={filterData?.state}
                                                        onChange={(e) => changeHandler("state", e)}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end ms-3 mt-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="submit" onClick={() => { clearAdvanceFilterData(true) }}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="submit"
                                                    onClick={() => {
                                                        currentPage !== 1 && setCurrentPage(1);
                                                        setAlreadyFetchedDataPageNumber({});
                                                        setIsFiltered(true);
                                                        currentPage == 1 && handleAdvanceFilter(filterData, 1);
                                                    }}
                                                >Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-5 border-300">
                                                <div>
                                                    <div className="table-responsive mx-n1 px-1">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant " scope="col">Action</th>
                                                                    <th className="bg-gradiant " scope="col">Seller ID</th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="firstname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>First Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'firstName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'firstName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="lastname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Last Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'lastName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'lastName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="lastname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Email</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'email')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'email')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Created Date
                                                                                {/* ({defaultTimezone}) */}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant" scope="col" data-sort="firstname">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>State</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'stateName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'stateName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="company">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Company</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'company')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'company')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="company">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Activation Status</span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterSeller?.length > 0 ? filterSeller.map((sellerData: any, index: any) => {
                                                                    const createdOn = momentUtil.parseTimeInUtc({ dateTimeString: sellerData?.createdOn })

                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                    <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                                        <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                        </button>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEdit(sellerData.sellerId)}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                        <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleDelete(sellerData.sellerId)} >Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            {/* <td className="align-middle  p-2">{sellerData.sellerId ?? "-"}</td> */}
                                                                            <td className="align-middle  p-2"
                                                                            >
                                                                                <Link className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                                    target="_blank"
                                                                                    to={`${routeConstant.SELLERMOREINFO}/${encryptId(sellerData?.sellerId)}`}
                                                                                    onClick={() => { dispatch(setActiveTab("contact")) }}
                                                                                >
                                                                                    {sellerData?.sellerId}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="align-middle  p-2">{sellerData.firstName ?? "-"}</td>
                                                                            <td className="align-middle  p-2">{sellerData.lastName ?? "-"}</td>
                                                                            <td className="align-middle  p-2">{sellerData.email ?? "-"}</td>
                                                                            <td className="align-middle  p-2">
                                                                                {createdOn.format('MM/DD/YYYY')}
                                                                            </td>
                                                                            <td className="align-middle review p-2">{sellerData?.stateName ?? "-"}</td>
                                                                            <td className="align-middle review p-2">{sellerData.company ?? "-"}</td>
                                                                            <td className="align-middle text-start status p-2">
                                                                                <span className={`badge badge-dpa fs--2 ${sellerData.isActive === true ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                                                    <span className="badge-label">{sellerData.isActive == true ? 'Active' : 'Inactive'}</span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={9}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterSeller?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={Math.ceil(totalRecords / paginationSize)}
                                            onPageChange={({ selected }) => handlePageClick(selected)}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}
export default Seller;