import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../common/interface";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import * as routeConsant from "../../common/routeConstants";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { base, BIDDERCONFIGCREATE, GETALLADMINSETTINGUSINGBLOB, GETALLBIDDERCONFIG, GETCOMMONVALUE, GETTIMEZONEIDENTIFIER, UPDATEBLOBADMINSETTINGS } from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import { EMAIL, EMAIL_MESSAGE } from "../../common/regexConstants";
import { AdminConfigBidderOption, AdminConfigLastOption, AdminConfigTimeZone, AdminConfigVerificationOption, InvoiceConfig } from "../../common/constants";
import { BidderConfigMessages, CommonMessages, errorMessages } from "../../common/messageConstants";
import { convertSecondsToMinutesAndSeconds, ERROR_MESSAGES, trimObjectValues } from "../../Utils/Utils";
import defaultImg from "../../assets/img/dpa_banner.png"
import Select from 'react-select';
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConsant.DASHBOARD },
  { label: "Admin Configuration", url: '' },
];

const BidderConfig = () => {
  const initialData = {
    companyId: localStorage.getItem("companyId"),
    bidderConfiguration: 0,
    invoiceConfiguration: 0,
    lateFees: 0,
    liveBoardTimeZone: '',
    verificationType: 0,
    email: '',
    emailToSeller: false,
    timeZoneIdentifier: '',
    durationMin: "0",
    durationSec: "0",
    signupImage: '',
    ApplyDefaultTimeZoneAllApp: true,
    blackListCustomerDays: 0,
    lateFeesCalculationDays: 0,
    lateFeesCalculation: 0,
    invoiceNumber: 0,
  }

  const [loading, setLoading] = useState(false);
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;
  const [formValues] = useState<any>(initialData);
  const [bidderConfigId, setBidderConfigId] = useState<any>(0);
  const [bidderConfigOption] = useState<any[]>(AdminConfigBidderOption);
  const [lateFeeOptions] = useState<any[]>(AdminConfigLastOption);
  const [VerificationOptions] = useState<any[]>(AdminConfigVerificationOption);
  const [timeZoneOptions, setTimeZoneOptions] = useState<any[]>([]);
  const [timeZoneIdentifier, setTimeZoneIdentifier] = useState<any[]>([])
  const [imageUrl, setImageUrl]: any = useState(null)
  const [defaultImgFile, setDefaultImgFile] = useState(null)
  // Get total invoice record to disable field of invoice number
  const invoiceTotalRecord: any = localStorage.getItem('invoiceTotalRecord');
  const momentUtil = MomentUtil.getInstance()
  const defaultTimezoneData: any = [{
    label: '(UTC) America/Chicago', value: 'America/Chicago'
  }]
  const numbers = Array.from({ length: 5 }, (_, index) => index + 1);
  const numbersSec = Array.from({ length: 60 }, (_, index) => index + 1);
  const schema: any = yup.object().shape({
    bidderConfiguration: yup.string().required("Bidder limit field is required."),
    // lateFeesCalculation: yup.string().matches(/^(\d{0,2})*$/, 'Please enter valid percentage'),
    // lateFeesCalculationDays: yup.string().matches(/^(\d{0,2})*$/, 'Please enter valid number'),
    // blackListCustomerDays: yup.string().matches(/^(\d{0,2})*$/, 'Please enter valid number'),
    verificationType: yup.string().required("Verification Type is required."),
    liveBoardTimeZone: yup.string().required('Default time zone is required.'),
    email: yup.string().required("Email field is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE)
      .max(49, 'please enter upto 50 characters'),
    durationSec: yup.string().required("Please ensure that the minimum duration is 30 seconds.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("time-req", "Please ensure that the minimum duration is 30 seconds.", function (value) {
        const min = watch('durationMin')
        if (value < '30' && min == '0') {
          return this.createError({
            message: 'Please ensure that the minimum duration is 30 seconds.',
          });
        };
        return true;
      }),
    durationMin: yup.string().required("Please ensure that the minimum duration is 30 seconds.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }).test("time-req", "Please ensure that the minimum duration is 30 seconds.", function (value) {
        const sec = watch('durationSec')
        if (value == '0' && sec < '30') {
          return this.createError({
            message: 'Please ensure that the minimum duration is 30 seconds.',
          });
        };
        return true;
      }),
    invoiceNumber: yup.string()
      .transform((value, originalValue) => {
        return originalValue.toString().trim() === '' ? null : value;
      })
      .nullable()
      .test('is-number', 'Only numbers are allowed in Invoice Number.', (value) => {
        // Allow null (if transformed from empty string) or validate as numeric
        if (!value) return true; // Allow null (no validation)
        return /^\d+$/.test(value); // Validate as numeric
      }),
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' });

  const bidderConfiguration = watch('bidderConfiguration')
  const liveBoardTimeZone = watch('liveBoardTimeZone')
  const verificationType = watch('verificationType')
  const invConfig = watch('invoiceConfiguration')
  const defData = getValues()
  const durationSec = watch("durationSec");
  const durationMin = watch("durationMin");
  const signupImage = watch('signupImage');
  const lateFees = watch('lateFeesCalculation');
  const customerDays = watch('blackListCustomerDays');
  const lateFeeDays = watch('lateFeesCalculationDays');

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setDefaultImgFile(null)
    setImageUrl(imageUrl);
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    if (durationMin != 0 || durationSec >= 30) {
      clearErrors(["durationMin", "durationSec"]);
    }
  }, [durationMin, durationSec]);

  useEffect(() => {
    setLoading(true);
    // Get time zone and identifier listing for default time zone dropdown 
    axios.get(GETTIMEZONEIDENTIFIER, config).then((response: any) => {
      // Set response in time zone state
      if (response?.data?.data) {

        let formateData = response.data.data?.map((data: any, index: any) => {
          //Format response in label and value format for select dropdown
          return {
            label: data?.label,
            value: data?.timeZoneIdentifierId,
          };
        })

        setTimeZoneIdentifier(response?.data?.data)
        setTimeZoneOptions(formateData);
      } else {
        setTimeZoneIdentifier(defaultTimezoneData)
        setTimeZoneOptions(defaultTimezoneData);
      }
    }).catch((err) => {
      setLoading(false);
      handleError(err);
    })

    axios.get(GETALLADMINSETTINGUSINGBLOB).then((response) => {
      setLoading(false);
      if (response?.data?.data?.success === "Fail") {
        toast.error(response?.data?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        let bidderConfigData = response?.data?.data;
        const bidderConfigSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "BidderConfiguration"
        );
        const lateFeesSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "lateFees"
        );
        const verificationSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "verificationType"
        );
        const emailSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "email"
        );
        const timeZoneSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "liveBoardTimeZone"
        );
        const emailToSellerSetting = bidderConfigData.find(
          (setting: any) => setting.settingKey === "emailToSeller"
        );
        const invoiceConfig = bidderConfigData.find(
          (setting: any) => setting.settingKey === "invoiceConfiguration"
        );
        const timeZoneIdentifier = bidderConfigData.find(
          (setting: any) => setting.settingKey === "timeZoneIdentifier"
        );
        const reSendTimerDuration = bidderConfigData.find(
          (setting: any) => setting.settingKey === "reSendTimerDuration"
        );
        const signupImage = bidderConfigData.find(
          (setting: any) => setting.settingKey === "signupImage"
        );
        const ApplyDefaultTimeZoneAllApp = bidderConfigData.find(
          (setting: any) => setting.settingKey === "ApplyDefaultTimeZoneAllApp"
        );
        const ApplylateFeesCalculationDays = bidderConfigData.find(
          (setting: any) => setting.settingKey === "lateFeesCalculationDays"
        );
        const ApplyblackListCustomerDays = bidderConfigData.find(
          (setting: any) => setting.settingKey === "blackListCustomerDays"
        );
        const ApplyLateFeesCalculation = bidderConfigData.find(
          (setting: any) => setting.settingKey === "lateFeesCalculation"
        );
        const invoiceNumber = bidderConfigData.find(
          (setting: any) => setting.settingKey === "invoiceNumber"
        );
        setBidderConfigId(bidderConfigSetting?.adminSettingId);
        convertSecondsToMinutesAndSeconds(
          reSendTimerDuration?.settingValue,
          setValue,
          "durationMin",
          "durationSec"
        );
        const initialFormDataSet = {
          bidderConfiguration: bidderConfigSetting?.settingValue ? bidderConfigSetting?.settingValue : 3,
          lateFees: lateFeesSetting?.settingValue ? lateFeesSetting?.settingValue : 3,
          verificationType: verificationSetting?.settingValue ? verificationSetting?.settingValue : 2,
          liveBoardTimeZone: timeZoneSetting?.settingValue ? Number(timeZoneSetting?.settingValue) : '',
          email: emailSetting?.settingValue ? emailSetting?.settingValue : '',
          emailToSeller: emailToSellerSetting?.settingValue === 'True' ? true : false,
          invoiceConfiguration: invoiceConfig?.settingValue ? invoiceConfig?.settingValue : 1,
          timeZoneIdentifier: timeZoneIdentifier?.settingValue ? timeZoneIdentifier?.settingValue : "",
          ApplyDefaultTimeZoneAllApp: ApplyDefaultTimeZoneAllApp?.settingValue ? ApplyDefaultTimeZoneAllApp?.settingValue : "",
          signupImage: signupImage?.settingValue ? signupImage?.settingValue : '',
          lateFeesCalculation: ApplyLateFeesCalculation?.settingValue ? ApplyLateFeesCalculation?.settingValue : 0,
          blackListCustomerDays: ApplyblackListCustomerDays?.settingValue ? ApplyblackListCustomerDays?.settingValue : 0,
          lateFeesCalculationDays: ApplylateFeesCalculationDays?.settingValue ? ApplylateFeesCalculationDays?.settingValue : 0,
          invoiceNumber: invoiceNumber?.settingValue === 0 ? 1 : invoiceNumber?.settingValue
        };

        signupImage?.settingValue.includes('defaultImg') && setDefaultImgFile(initialFormDataSet.signupImage)
        // setImageUrl(`${base}${signupImage?.settingValue}`)
        setImageUrl(`${signupImage?.settingValue}?${signupImage?.photosWithSasToken}`)
        reset(initialFormDataSet);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }, []);
  useEffect(() => {
    const matchedLiveBoardTimeZone = timeZoneOptions?.find((option: any) => option?.value == liveBoardTimeZone)
    if (matchedLiveBoardTimeZone == undefined) {
      setValue('liveBoardTimeZone', 'America/Chicago')
    }
  }, [timeZoneOptions])

  const onSubmit = (data: any) => {
    var img: any = document.getElementById('signupImg');
    var width = img?.naturalWidth;
    var height = img?.naturalHeight;
    if (width >= 1920 && height >= 1080) {
      const trimData = trimObjectValues(data);
      const reSendTimerDuration = parseInt(durationSec) + (parseInt(durationMin) * 60)
      const image = typeof (data.signupImage) == 'string' && data.signupImage !== '' ? null : typeof (data.signupImage) == 'object' ? data.signupImage[0] : defaultImgFile
      const liveBoardTimeZoneIdentifier = timeZoneIdentifier?.find(
        (item: any) => item?.timeZoneIdentifierId == data.liveBoardTimeZone
      );
      setLoading(true);
      if (superAdminRole === "True") {
        const formData: any = new FormData();
        formData.append("bidderConfiguration", parseInt(data.bidderConfiguration));
        formData.append("lateFees", parseInt(data.lateFees));
        formData.append("verificationType", parseInt(data.verificationType));
        formData.append("liveBoardTimeZone", data?.liveBoardTimeZone);
        formData.append("email", data.email);
        formData.append("emailToSeller", data.emailToSeller);
        formData.append("invoiceConfiguration", data.invoiceConfiguration);
        formData.append("blackListCustomerDays", parseInt(data.blackListCustomerDays));
        formData.append("lateFeesCalculationDays", parseInt(data.lateFeesCalculationDays));
        formData.append("lateFeesCalculation", parseInt(data.lateFeesCalculation));
        formData.append(
          "timeZoneIdentifier",
          liveBoardTimeZoneIdentifier
            ? liveBoardTimeZoneIdentifier?.identifier
            : "America/Chicago"
        );
        formData.append("reSendTimerDuration", reSendTimerDuration);
        formData.append("signupImage", image);
        formData.append("invoiceNumber", data?.invoiceNumber ? data?.invoiceNumber : 0);
        formData.append("ApplyDefaultTimeZoneAllApp", data.ApplyDefaultTimeZoneAllApp ? 'True' : 'False');
        axios
          .post(UPDATEBLOBADMINSETTINGS, formData).then((response) => {
            if (bidderConfigId === 0) {
              setLoading(false);
              if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                return;
              }
              else {
                localStorage.setItem(
                  "liveBoardTimeZone",
                  liveBoardTimeZoneIdentifier?.timeZone
                );
                localStorage.setItem(
                  "timeZoneIdentifier",
                  liveBoardTimeZoneIdentifier?.identifier
                );
                localStorage.setItem(
                  "timeZoneId",
                  data?.liveBoardTimeZone
                );
                momentUtil.setTimezone(liveBoardTimeZoneIdentifier?.identifier)
                momentUtil.setDefaultSourceTimezone(liveBoardTimeZoneIdentifier?.identifier)
                toast.success(BidderConfigMessages.adminConfigCreated, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            } else {
              setLoading(false);
              if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });

                return;
              }
              else {
                localStorage.setItem(
                  "liveBoardTimeZone",
                  liveBoardTimeZoneIdentifier?.timeZone
                );
                localStorage.setItem('timeZoneIdentifier', liveBoardTimeZoneIdentifier?.identifier)
                localStorage.setItem(
                  "timeZoneId",
                  data?.liveBoardTimeZone
                );
                momentUtil.setTimezone(liveBoardTimeZoneIdentifier?.identifier)
                momentUtil.setDefaultSourceTimezone(liveBoardTimeZoneIdentifier?.identifier)
                toast.success(BidderConfigMessages.adminConfigUpdated, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            }
          }).catch((error) => {
            setLoading(false);
            handleError(error);
          })
      }
      else {
        setLoading(false);
        toast.error("Only super admin has permission to perform this operation on the page.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Image size must be 1920x1080 pixels or larger. Please upload a higher resolution image.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleDeleteImage = () => {
    setValue('signupImage', '')
    setImageUrl(defaultImg);
    fetch(defaultImg)
      .then((res) => res.blob())
      .then((blob) => {
        const defaultImgBlob: any = new File([blob], 'defaultImg.png', { type: 'image/png' });
        setDefaultImgFile(defaultImgBlob)
      })
      .catch((error) => {
        console.error('Error fetching default image:', error);
      });
  }


  return (
    <>
      <CommonHelmet title={"Admin Config - "} name={"adminConfiguration"} content={"Admin Configuration"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid for-fix-bottom-space">
            <div className="row top_border justify-content-center">
              <div className="col-xxl-10 pt-4">
                <h4 className="fw-bold d-block mb-2">Admin Configuration</h4>
                <div className="row g-3 mb-4">
                  <div className="col-lg-4">
                    <div className="">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Bidder number create limit (In Customer Portal) <sup className="text-danger">*</sup>
                      </label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Default Time Zone"
                        options={bidderConfigOption}
                        {...register('bidderConfiguration')}
                        value={bidderConfigOption?.find((option: any) => option?.value == bidderConfiguration)}
                        onChange={(e: any) => setValue('bidderConfiguration', e.value)}
                        className='isActive'
                      />
                      {errors.bidderConfiguration && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.bidderConfiguration.message}
                        </span>
                      )}
                    </div>
                    <p>
                      <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Limit apply only on customer portal.</i></span>
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <div className="">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Default Time Zone <sup className="text-danger">*</sup>
                      </label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Default Time Zone"
                        options={timeZoneOptions}
                        {...register('liveBoardTimeZone')}
                        value={timeZoneOptions?.find((option: any) => option?.value == liveBoardTimeZone)

                        }
                        onChange={(e: any) => setValue('liveBoardTimeZone', e.value)}
                        className='isActive'
                      />

                      {errors.liveBoardTimeZone && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.liveBoardTimeZone.message}
                        </span>
                      )}
                      <p>
                        <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: This time zone will be used in the live auction board.</i></span>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-check form-switch  mt-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        {...register('ApplyDefaultTimeZoneAllApp')}
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Update time zone overall application</label>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Late Fees (%)
                      </label>
                      {/* <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Default Time Zone"
                        options={lateFeeOptions}
                        {...register('lateFees')}
                        value={lateFeeOptions?.find((option: any) => option?.value == lateFees)}
                        onChange={(e: any) => setValue('lateFees', e.value)}
                        className='isActive'
                      /> */}
                      <input
                        type="number"
                        className="form-control  me-2"
                        placeholder="Late Fees (%)"
                        maxLength={2}
                        value={lateFees ? `${Math.max(0, lateFees)}` : 0}
                        {...register("lateFeesCalculation", {
                          onChange: (e) => {
                            const data = e.target.value < 100 ? `${Number(e.target.value.slice(0, 5))}` : lateFees
                            setValue("lateFeesCalculation", data)
                          }
                        })}
                      />
                      {errors.lateFeesCalculation && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.lateFeesCalculation.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Late Fees Calculations (Days {'<'} 31)
                      </label>

                      <input
                        type="number"
                        className="form-control  me-2"
                        placeholder="Late Fees Calculations (Days)"
                        maxLength={2}
                        value={lateFeeDays ? `${Math.max(0, lateFeeDays)}` : 0}
                        {...register("lateFeesCalculationDays", {
                          onChange: (e) => {
                            const data = e.target.value < 31 ? `${Number(e.target.value.slice(0, 2))}` : lateFeeDays
                            setValue("lateFeesCalculationDays", data)
                          }
                        })}
                      />
                      {errors.lateFeesCalculationDays && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.lateFeesCalculationDays.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Blacklist Buyer (Days {'<'} 31)
                      </label>

                      <input
                        type="number"
                        className="form-control  me-2"
                        placeholder="Blacklist Buyer (Days)"
                        maxLength={2}
                        value={customerDays ? `${Math.max(0, customerDays)}` : 0}
                        {...register("blackListCustomerDays", {
                          onChange: (e) => {
                            const data = e.target.value < 31 ? `${Number(e.target.value.slice(0, 2))}` : customerDays
                            setValue("blackListCustomerDays", data)
                          }
                        })}
                      />
                      {errors.blackListCustomerDays && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.blackListCustomerDays.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Verification Type<sup className="text-danger">*</sup>
                      </label>
                      <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Default Time Zone"
                        options={VerificationOptions}
                        {...register('verificationType')}
                        value={VerificationOptions?.find((option: any) => option?.value == verificationType)}
                        onChange={(e: any) => setValue('verificationType', e.value)}
                        className='isActive'
                      />
                      {errors.verificationType && (
                        <span className="text-danger" style={{ fontSize: "12px" }}>
                          {errors.verificationType.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Seller Signup Email <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control  me-2"
                        placeholder="abc@gmail.com"
                        maxLength={50}
                        {...register("email")}
                      />
                      {errors.email && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.email.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Resend OTP timer<sup className="text-danger">*</sup></label>
                    <div className="d-flex dur">
                      <select
                        {...register("durationMin")}
                        className="form-select"
                      >
                        <option value="0">0 mins</option>
                        {numbers.map((num, index) => {
                          return (
                            <option value={num} key={index}>
                              {num} mins
                            </option>
                          );
                        })}
                      </select>
                      <span className="ms-2 me-2">:</span>
                      <select
                        {...register("durationSec")}
                        className="form-select"
                      >
                        <option value="0">0 secs</option>
                        {numbersSec.map((num, index) => {
                          return (
                            <option value={num} key={index}>
                              {num} secs
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {(errors?.durationMin) ? (
                      <span className="text-danger" style={{ fontSize: "12px" }}>
                        {errors?.durationMin.message}
                      </span>
                    ) : errors?.durationSec && (
                      <span className="text-danger" style={{ fontSize: "12px" }}>
                        {errors?.durationSec.message}
                      </span>
                    )}
                    <p>
                      <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Please ensure that the minimum duration is 30 seconds.</i></span>
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <div className="">
                      <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Invoice Number Starts From
                      </label>
                      <input
                        type="text"
                        className="form-control  me-2"
                        placeholder="Invoice Number"
                        {...register("invoiceNumber")}
                        disabled={invoiceTotalRecord?.length > 0}
                      />
                      {errors.invoiceNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.invoiceNumber.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">Signup Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      {...register('signupImage')}
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note: Image size must be 1920x1080 pixels or larger.<br />This image will be used in the customer portal signup form backgroud image.</i></span>
                  </div>
                  <div className="col-lg-4 ">
                    <div className="form-check form-switch  mt-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        {...register('emailToSeller')}
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Send Email to Seller</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="img_with_cross position-relative">
                      {imageUrl && <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} id="signupImg" />}
                      {!defaultImgFile && signupImage && <button className="del_img_btn_pro" type="button" onClick={handleDeleteImage}><i className="fa fa-times"></i></button>}
                    </div>
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                  <div className="col-12">
                    <h4 className="">Invoice Configuration</h4>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex flex-wrap align-items-center">
                      {InvoiceConfig.map((configType: any, index: number) => {
                        return (
                          <div className="d-flex form-check form-check-inline align-items-center p-0" key={index}>
                            <input
                              name="invoiceConfiguration"
                              type="radio"
                              id={`inv_${index}`}
                              onChange={() => setValue('invoiceConfiguration', configType.value)}
                              className="form-check-input m-2"
                              checked={configType.value == invConfig}
                            // defaultChecked={defData.invoiceConfiguration === undefined ? configType.selected : defData.invoiceConfiguration == configType.value}
                            />
                            <label className="form-check-label" htmlFor={`inv_${index}`} >{configType.label}</label>
                          </div>
                        )
                      })}
                      {errors.taxExempt && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxExempt.message}</span>}
                    </div>
                  </div>
                </div >
              </div >
              <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <Link
                  to={routeConsant.DASHBOARD}
                  className="btn btn-outline-danger me-auto"
                  type="button"
                  data-wizard-prev-btn="data-wizard-prev-btn"
                >
                  {CommonMessages.cancelButton}
                </Link>
                <button
                  className="btn btn-primary mx-2"
                  type="submit"
                >
                  {CommonMessages.saveButton}
                </button>
              </div>
            </div >
          </div >
        </form >
      </div >
    </>
  );
}
export default BidderConfig;