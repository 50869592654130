// AddNewLotModal.js
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from 'react-select';

const AddNewLotModal = ({ showModal, handleClose, handleSave, futureAuctionData, selectedAuction, setSelectedAuction }: any) => {
    const auctionHammerData = useSelector((state: any) => state.global.auctionHammerData)

    let auctions = futureAuctionData?.map((aData: any, index: any) => {
        return {
            label: `${aData.auctionCode} - ${aData.auctionTitle}`,
            value: aData.auctionId,
            auctionEmail: aData?.auctionEmail ? aData?.auctionEmail : ''
        }
    })

    useEffect(() => {
        if (showModal) {
            if (auctionHammerData && Object?.keys(auctionHammerData)?.length > 0) {
                const data = auctions.find((a: any) => a.value === auctionHammerData.value) ?? ""
                setSelectedAuction(data)
            } else {
                setSelectedAuction("")
            }
        }

    }, [showModal])


    return (
        <Modal show={showModal} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>New Inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="futureAuction">Auctions</label>
                    <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Auction"
                        className='isActive'
                        name='futureAuction'
                        id="futureAuction"
                        options={auctions}
                        value={selectedAuction}
                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                        onChange={(e) => setSelectedAuction(e)}
                    />
                    {/* <select name="futureAuction" id="futureAuction" className="form-select" onChange={(e) => setSelectedAuction(e.target.value)}>
                        <option value="">Select Auction</option>
                        {futureAuctionData?.map((aData: any, aIndex: any) => (
                            <option value={aData.auctionId} key={aIndex}>{aData.auctionCode} - {aData.auctionTitle}</option>
                        ))}
                    </select> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSave(null)} disabled={!(selectedAuction !== "")}>
                    Continue
                </Button>
                <Button variant="outline-primary" onClick={() => handleSave(0)}>
                    Add to Master
                </Button>
                <Button variant="outline-danger" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNewLotModal;
