import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CustomCkEditor from '../../../../Utils/CustomCkEditor';
import parse from "html-react-parser";
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';

const EmailTemplate = ({ register, activeStep, ckData, setCkData, errors, isError }: any) => {
    const activeEmailReportsSetting = useSelector((state: any) => state.auction.emailReport)

    return (
        <>
            <div className="row">
                <div className="mt-2 mb-3">
                    <Tabs
                        defaultActiveKey="source"
                        className="mb-3 nav-underline border-bottom"
                    >
                        <Tab eventKey="source" title="Source">
                            <label className="form-check-label">Template Content <sup className="text-danger">*</sup></label>
                            <CustomCkEditor
                                data={ckData}
                                onTextChanged={(value: any) => setCkData(value)} />
                        </Tab>
                        <Tab eventKey="preview" title="Preview">
                            <div className="card box-shadow">
                                <div className="card-body ck_editor">
                                    {ckData ? parse(ckData) : ''}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                    {isError && <span className='text-danger' style={{ fontSize: "12px" }}>{"Template content is required."}</span>}

                </div>
                <div className="col-lg-12 mb-2">
                    <label className="form-check-label">Subject <sup className="text-danger">*</sup></label>
                    <input type="text" className="form-control mb-1" placeholder="" maxLength={100} {...register('subject')} name="subject" />
                    {errors?.subject && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.subject.message}
                        </span>
                    )}
                </div>
                <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="text">CC  address 1</label>
                    <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={50}
                        placeholder=""
                        {...register('ccAddress')}
                    />
                    {errors?.ccAddress && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.ccAddress.message}
                        </span>
                    )}
                </div>
                <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="text">CC Address 2</label>
                    <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={50}
                        placeholder=""
                        {...register('cc2Address')}
                    />
                    {errors?.cc2Address && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.cc2Address.message}
                        </span>
                    )}
                </div>
                <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="text">BCC Email Address</label>
                    <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={50}
                        placeholder=""
                        {...register('bccAddress')}
                    />

                </div>
                <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="text">From Address</label>
                    <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={50}
                        {...register('fromAddress')}
                    />
                    {errors?.fromAddress && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.fromAddress.message}
                        </span>
                    )}
                </div>
                <div className="col-sm-6 mb-2">
                    <label className="form-label" htmlFor="text">Reply To Address</label>
                    <input
                        className="form-control form-icon-input"
                        type="text"
                        maxLength={50}
                        {...register('replyToAddress')}
                    />
                    {errors?.replyToAddress && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.replyToAddress.message}
                        </span>
                    )}
                </div>
                <div className="col-lg-12 mb-2">
                    <label className="form-label" htmlFor="text">Email Invoice Footer Text</label>
                    <textarea
                        className="form-control form-icon-input"
                        placeholder=""
                        rows={2}
                        {...register('emailInvoiceFooterText')}
                    />
                    {errors?.emailInvoiceFooterText && (
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                        >
                            {errors?.emailInvoiceFooterText.message}
                        </span>
                    )}
                </div>
                {/* When active step is seller summary report */}
                {activeStep.activeLabel=='Seller Summary Report' &&
                <div className="col-lg-4 col-sm-4">
                    <label className="form-label">Include Seller Number in Subject
                    <Tippy content="Subject will appear with seller number appended to the end of subject line.">
                            <i className="eye_icons position-absolute fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                          </Tippy>
                    </label>
                    <div className="form-check">
                        <label className="form-label" htmlFor="includeSellerNumberinSubject">Yes</label>
                        <input className="form-check-input mt-1" type="checkbox" id="includeSellerNumberinSubject" {...register('includeSellerNumberinSubject')} />
                    </div>
                </div>
                }
                 {activeStep.activeLabel=='Seller Pickup Summary Report' &&
                <div className="col-lg-4 col-sm-4">
                    <label className="form-label">Send Seller Pickup Summary Report to Seller Summary Report Email 
                    </label>
                    <div className="form-check">
                        <label className="form-label" htmlFor="sendPickupSummarytoSellerSummaryReportEmail">Yes</label>
                        <input className="form-check-input mt-1" type="checkbox" id="sendPickupSummarytoSellerSummaryReportEmail" {...register('sendPickupSummarytoSellerSummaryReportEmail')} />
                    </div>
                </div>
                }
                <div className={activeEmailReportsSetting.activeLabel=='Seller Email Template' ? "col-lg-4 col-sm-4" : "col-lg-6 col-sm-6"}>
                    <label className="form-label">Include PDF version with Bidder Email Report

                    </label>
                    <div className="form-check">
                        <label className="form-label" htmlFor="includePDFVersionWithBidderEInvoice">Yes</label>
                        <input className="form-check-input mt-1" type="checkbox" id="includePDFVersionWithBidderEInvoice" {...register('includePDFVersionWithBidderEInvoice')} />
                    </div>
                </div>
                <div className={activeEmailReportsSetting.activeLabel=='Seller Email Template' ? "col-lg-4 col-sm-4" : "col-lg-6 col-sm-6"}>
                    <label className="form-label">Exclude Text version of invoice in Email Body</label>
                    <div className="form-check">
                        <label className="form-label" htmlFor="excludeTextofInvoiceInEmailBody">Yes</label>
                        <input className="form-check-input mt-1" type="checkbox" id="excludeTextofInvoiceInEmailBody" {...register('excludeTextofInvoiceInEmailBody')} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailTemplate