import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface SellerState {
    contact: {}
    contactDefault: {}
    salesAuction: {}
    tax: {}
    commision: {}
    activeTab: {}
    adjust: []
    payment: []
    logNote: []
    commissionOptions: {}
    sellerLots: []
    sellerAuctions: []
    AuctionInfoActiveTab: String
    selectedAuction: {},
    contractDocument: []
    sellerId: number
    prevSellerId: number
    nextSellerId: number
    totalSellers: number
    sellerIndex: number
}

const initialState: SellerState = {
    activeTab: { key: 'contact' },
    contact: {},
    contactDefault: {},
    salesAuction: {},
    tax: {},
    commision: {},
    adjust: [],
    payment: [],
    logNote: [],
    commissionOptions: {},
    sellerLots: [],
    sellerAuctions: [],
    selectedAuction: {},
    AuctionInfoActiveTab: 'contactInfo',
    contractDocument: [],
    sellerId: 0,
    prevSellerId: 0,
    nextSellerId: 0,
    totalSellers: 0,
    sellerIndex: 0,
};

export const SellerReducer = (
    state: SellerState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.UPDATESELLER: {
            return { ...state, ...action.payload }
        }
        case actions.SELLERCONTACT: {
            return { ...state, contact: action.payload }
        }
        case actions.SETSELLERADJUST: {
            return { ...state, adjust: [...state.adjust, action.payload] }
        }
        case actions.SETSELLERADJUSTFROMRESP: {
            return {
                ...state, adjust: action.payload, taxExempt: action.payload.taxExempt
            }
        }
        case actions.DELETESELLERADJUST: {
            return {
                ...state,
                adjust: state.adjust.filter((entry: any) => entry.adjustUniqueId !== action.payload),
            }
        }
        case actions.UPDATESELLERADJUST:
            return {
                ...state,
                adjust: state.adjust.map((entry: any) =>
                    entry.adjustUniqueId === action.payload.adjustUniqueId ? action.payload.updatedSellerAdjust : entry
                ),
            };
        case actions.SETSELLERPAYMENT: {
            return { ...state, payment: [...state.payment, action.payload] }
        }
        case actions.SETSELLERPAYMENTRESP: {
            return { ...state, payment: action.payload }
        }
        case actions.DELETESELLERPAYMENT: {
            return {
                ...state,
                payment: state.payment.filter((entry: any) => entry.paymentUniqueId !== action.payload),
            }
        }
        case actions.UPDATESELLERPAYMENT:
            return {
                ...state,
                payment: state.payment.map((entry: any) =>
                    entry.paymentUniqueId === action.payload.paymentUniqueId ? action.payload.updatedSellerPayment : entry
                ),
            };
        case actions.SETSELLERRADIOVALUE: {
            return { ...state, radioValue: action.payload }
        }
        case actions.RESETSELLER: {
            return initialState
        }
        case actions.ADDLOGNOTE:
            return {
                ...state,
                logNote: [...state.logNote, action.payload],
            };
        case actions.DELETELOGNOTE:
            return {
                ...state,
                logNote: state.logNote.filter((entry: any) => entry.sellerLogNotesId !== action.payload),
            };
        case actions.UPDATELOGNOTE:
            return {
                ...state,
                logNote: state.logNote.map((note: any) =>
                    note.sellerLogNotesId === action.payload.sellerLogNotesId ? action.payload.updatedNote : note
                ),
            };
        case actions.SETACTIVETAB:
            return {
                ...state,
                activeTab: { key: action.payload }
            };

        case actions.SELLERLOTS:
            return {
                ...state,
                sellerLots: action.payload
            };

        case actions.SELLERAUCTIONS:
            return {
                ...state,
                sellerAuctions: action.payload
            };

        case actions.SETAUCTIONINFOACTIVETAB:
            return {
                ...state,
                AuctionInfoActiveTab: action.payload
            };

        case actions.SELECTAUCTION:
            return {
                ...state,
                selectedAuction: action.payload
            };

        case actions.SETCONTRACTDOCUMENT:
            return {
                ...state,
                contractDocument: action.payload
            };
        
        case actions.SETAUCTIONCONTRACTDOCUMENT:
            return {
                ...state,
                auctionContractDocument: action.payload
            };

        case actions.SETSELLERID: {
            return { ...state, sellerId: action.payload }
        }
        case actions.SETPREVSELLERID: {
            return { ...state, prevSellerId: action.payload }
        }
        case actions.SETNEXTSELLERID: {
            return { ...state, nextSellerId: action.payload }
        }
        case actions.TOTALSELLERS: {
            return { ...state, totalSellers: action.payload }
        }
        case actions.SELLERINDEX: {
            return { ...state, sellerIndex: action.payload }
        }
        default:
            return state;
    }
};