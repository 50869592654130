import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import { Breadcrumb } from '../../common/interface';
import * as routeConstant from '../../common/routeConstants'
import { formatCurrency } from "../../Utils/Utils";
const CommsionSummaryReport = () => {

    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState<any>();
    const [filterCommission, setFilterCommission] = useState<any>();

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Reports', url: routeConstant.REPORTS },
        { label: 'Commision Summary Report', url: "#" }
    ]

    const handleSearch = (e: any) => {

    }
    const handleSorting = (type: string, fieldName: string) => {

    }
    const generateExcelWithTitle = () => {

    }
    const exportPDF = () => {

    }
    return (
        <>

            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Commission Summary Report'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3"><i className="fas fa-filter fs-16"></i></button>
                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={generateExcelWithTitle}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => exportPDF()}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>

                    </div>

                </InnerHeader >
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="row ps-3 pe-3 pb-3">
                                    <div className="col-12">
                                        <div className="mb-5 border-300">
                                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col" data-sort="module">#</th>
                                                            <th className="bg-gradiant " scope="col" data-sort="module">
                                                                <div className='d-flex align-items-center'>
                                                                    <span> Seller Name</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerName')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerName')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Total Ext Price</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'totalExtPrice')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'totalExtPrice')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Sold</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'sold')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'sold')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Passed</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'passed')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'passed')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Buyback</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'buyback')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'buyback')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Unsold</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'unsold')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'unsold')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Comm Total</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'commTotal')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'commTotal')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Adjust</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'adjust')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'adjust')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Payments</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'payments')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'payments')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>NET</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'net')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'net')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="table-latest-review-body">
                                                        {filterCommission?.length > 0 ? filterCommission?.map((fData: any, fIndex: any) => {
                                                            return (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                                    <td className="align-middle  p-2">{fData.lotId}</td>
                                                                    <td className="align-middle  p-2">{fData.lotNo}</td>
                                                                    <td className="align-middle  p-2">{fData.lotTitle}</td>
                                                                    <td className="align-middle  p-2">{fData.sellerName}</td>
                                                                    <td className="align-middle  p-2">{fData.unit ?? 0}</td>
                                                                    <td className="align-middle  p-2">{formatCurrency(fData.price ?? 0)}</td>
                                                                    <td className="align-middle  p-2">{fData.lessCommission ?? 0}</td>
                                                                    <td className="align-middle  p-2">{fData.lessListingFee ?? 0}</td>
                                                                    <td className="align-middle  p-2">{fData.salesTaxonSellerFees ?? 0}</td>
                                                                    <td className="align-middle  p-2">{fData.total ?? 0}</td>
                                                                </tr>
                                                            )
                                                        }) :
                                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                <td className='text-center p-4' colSpan={16}>No records found!</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default CommsionSummaryReport;