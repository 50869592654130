import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../../../Utils/Utils";
import {
  GETLOTREALESTATEPROPERTYDETAILSBYID,
  UPDATELOTREALESTATEPROPERTY
} from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  AlPHANUMERIC,
  INTEGER,
  INTEGER_MESSAGE,
  YEAR,
  YEAR_MESSAGE
} from "../../../../common/regexConstants";
import * as routeConstant from "../../../../common/routeConstants";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../../store/actions/Inventory";
import { LOTPROPERTYDETAILS, PropertyDetailsChecks } from "../../InventoryConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const PropertyDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnTxt] = useState<any>("")
  const lotId = useSelector((state: any) => state?.inventory?.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const mode = useSelector((state: any) => state.inventory.mode)
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    isActive: true,
    isDelete: false,
    lotRealEstatePropertyDetailsId: 0,
    lotId: mode === "clone" ? cloneLotId : lotId,
    buildingStyle: "",
    house: "",
    rooms: "",
    beds: "",
    baths: "",
    buildingSquareFootage: "",
    exterior: "",
    roof: "",
    yearBuilt: "",
    heatingSystem: "",
    coolingSystem: "",
    alternateUses: "",
    garageOfCars: "",
    attachesDetached: true,
    floorMasterBedroom: false,
    inlawApartment: false,
    waterFront: false,
    waterView: false,
    pool: false,
    atlight: false,
    median: false,
    cut: false,
    dotPermit: false,
    trafficReports: false,
    rightOfWay: false,
    publicStreet: false,
    pri: false,
    soilBorings: false,
    compactionTest: false,
    floodZone: false,
    wetLand: false,
    pond: false,
    underGroundTrank: false,
    phase1: false,
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const schema: any = yup.object().shape({
    buildingStyle: yup
      .string()
      // .required("Building Style is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .max(49, "Please enter upto 50 characters."),
    house: yup
      .string()
      // .required("House is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(INTEGER, INTEGER_MESSAGE),
    rooms: yup
      .string()
      // .required("Rooms is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(INTEGER, INTEGER_MESSAGE),
    beds: yup
      .string()
      // .required("Beds is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(INTEGER, INTEGER_MESSAGE),
    baths: yup
      .string()
      // .required("Baths is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(INTEGER, INTEGER_MESSAGE),
    buildingSquareFootage: yup
      .string()
      // .required("Building Square Footage is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Footage")
      .max(49, "Please enter upto 50 characters."),
    yearBuilt: yup
      .string()
      // .required("Year Built is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(YEAR, YEAR_MESSAGE),
    heatingSystem: yup
      .string()
      // .required("Heating System is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Heating System")
      .max(49, "Please enter upto 50 characters."),
    coolingSystem: yup
      .string()
      // .required("Cooling System is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Cooling System")
      .max(49, "Please enter upto 50 characters."),
    alternateUses: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(INTEGER, INTEGER_MESSAGE),
    exterior: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Exterior")
      .max(49, "Please enter upto 50 characters."),
    roof: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Roof")
      .max(49, "Please enter upto 50 characters."),
    garageOfCars: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(AlPHANUMERIC, "Please enter valid Garage")
      .max(49, "Please enter upto 50 characters."),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  const onSubmit = () => {
    setLoading(true);
    const dataOriginal = getValues();
    const propertyData = trimObjectValues(dataOriginal);

    const payload = {
      ...propertyData,
      lotId: mode === "clone" ? cloneLotId : lotId,
      alternateUses: propertyData.alternateUses ? parseInt(propertyData.alternateUses) : 0,
      house: propertyData.house ? parseInt(propertyData.house) : 0,
      rooms: propertyData.rooms ? parseInt(propertyData.rooms) : 0,
      beds: propertyData.beds ? parseInt(propertyData.beds) : 0,
      baths: propertyData.baths ? parseInt(propertyData.baths) : 0,
      yearBuilt: propertyData.yearBuilt ? parseInt(propertyData.yearBuilt) : 0,
    }

    axios.post(UPDATELOTREALESTATEPROPERTY, payload, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, LOTPROPERTYDETAILS]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, LOTPROPERTYDETAILS]))
        }
        nextStep();
        toast.success(InventoryMessages.prorpertyDetailUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  useEffect(() => {
    setLoading(true);
    const id = mode === "clone" ? cloneLotId : lotId
    axios.get(`${GETLOTREALESTATEPROPERTYDETAILSBYID}/?lotId=${id}`, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        return;
      } else {
        let data = response.data.data;
        data = {
          ...data,
          alternateUses: data.alternateUses > 0 ? parseInt(data.alternateUses) : "",
          house: data.house > 0 ? parseInt(data.house) : "",
          rooms: data.rooms > 0 ? parseInt(data.rooms) : "",
          beds: data.beds > 0 ? parseInt(data.beds) : "",
          baths: data.baths > 0 ? parseInt(data.baths) : "",
          yearBuilt: data.yearBuilt > 0 ? parseInt(data.yearBuilt) : "",
        }
        reset(data)
      }
    }).catch((error) => {
      setLoading(false);
    });
  }, []);

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTPROPERTYDETAILS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTPROPERTYDETAILS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  const prevStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "taxes" }));
  };

  const nextStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "interior" }));
  };

  return (
    <>
      {" "}
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="loader-wrap">
              <img src={`${localStorage.getItem("globalLoader")}`} />
            </div>
          )}
          <div className="row g-3 mb-3">
            <div className="col-lg-6">
              <label className="form-label">
                Building Style
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Building Style"
                {...register("buildingStyle")}
              />
              {errors.buildingStyle && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.buildingStyle.message}
                </span>
              )}
            </div>
            <div className="col-lg-6">
              <label className="form-label">
                House / Style / Unit
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="House / Style / Unit"
                {...register("house")}
                onKeyDown={handleKeyDown}
              />
              {errors.house && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.house.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Room
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="Room"
                {...register("rooms")}
                onKeyDown={handleKeyDown}
              />
              {errors.rooms && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.rooms.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Beds
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="Beds"
                {...register("beds")}
                onKeyDown={handleKeyDown}
              />
              {errors.beds && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.beds.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Baths
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="Baths"
                {...register("baths")}
                onKeyDown={handleKeyDown}
              />
              {errors.baths && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.baths.message}
                </span>
              )}
            </div>
            <div className="col-lg-12">
              <label className="form-label">
                Building Square Footage
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Building Sqaure Footage"
                {...register("buildingSquareFootage")}
              />
              {errors.buildingSquareFootage && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.buildingSquareFootage.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Exterior </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Exterior"
                {...register("exterior")}
              />
              {errors.exterior && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.exterior.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Roof</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Roof"
                {...register("roof")}
              />
              {errors.roof && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.roof.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Year Built
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={4}
                placeholder="Year Built"
                {...register("yearBuilt")}
                onKeyDown={handleKeyDown}
              />
              {errors.yearBuilt && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.yearBuilt.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Heating System
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Heating System"
                {...register("heatingSystem")}
              />
              {errors.heatingSystem && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.heatingSystem.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Cooling System
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Cooling System"
                {...register("coolingSystem")}
              />
              {errors.coolingSystem && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.coolingSystem.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Alternate Uses</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={10}
                placeholder="Alternate Uses"
                {...register("alternateUses")}
                onKeyDown={handleKeyDown}
              />
              {errors.alternateUses && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.alternateUses.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">Garage of Cars</label>
              <input
                className="form-control form-icon-input"
                type="text"
                maxLength={50}
                placeholder="Garage of Cars"
                {...register("garageOfCars")}
              />
              {errors.garageOfCars && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.garageOfCars.message}
                </span>
              )}
            </div>
            {PropertyDetailsChecks.map((pData: any, pIndex: any) => {
              return (
                <>
                  <div key={pIndex}>
                    <h4>{pData.heading}</h4>
                  </div>
                  {
                    pData.checks.map((pcData: any, pcIndex: any) => {
                      return (
                        <div className="col-lg-3" key={pcIndex}>
                          <label className="form-label">{pcData.label}</label>
                          <div className="form-check">
                            <input
                              className="form-check-input form-check-states"
                              type="checkbox"
                              id={pcData.key}
                              {...register(pcData.key)}
                            />
                            <label htmlFor={pcData.key} className="form-check-label text-900 fw-400">Yes</label>
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              )
            })}
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
              <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
              <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
              <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
              <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default PropertyDetails;
