import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { Controller, useForm } from 'react-hook-form';
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { GETCOMMONVALUE, UPDATEADJUSTS } from "../../../../common/apiConstatnts";
import { CommonMessages, SellerMessages } from "../../../../common/messageConstants";
import { DECIMAL, DECIMAL_MESSAGE } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES, formatCurrency, handleKeyDown } from "../../../../Utils/Utils";
import moment from "moment";

const Adjust = () => {

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const sellerData = useSelector((state: any) => state.seller)
    const auctionId = sellerData.selectedAuction.value
    const adjustData = sellerData?.adjust

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const initialData = {
        amount: "",
        chgCr: "",
        tax: 0,
        taxAmount: "",
        description: "",
        adjustUniqueId: 0,
        isActive: true,
        isDelete: false,
        auctionId: auctionId,
    }

    const mainInitialData = {
        sellerAdjustId: 0,
        sellerId: sellerData.sellerId ? sellerData?.sellerId : 0,
        taxRateByZipCode: "0.00",
        noAdjusts: "0.00",
        ajustmentSalesTax: "0.00",
        taxExempt: false,
        isActive: true,
        isDelete: false,
    }

    const taxExemptType = [
        { label: 'Yes', value: true, selected: false },
        { label: 'No', value: false, selected: true }
    ]

    const options = [
        { value: 1, label: "Credit" },
        { value: 2, label: "Charge" }
    ];


    const [mode, setMode] = useState<string>('create')
    const [loading, setLoading] = useState(false);
    const [toggleAdjModal, setToggleAdjModal] = useState<boolean>(false)
    const [adjustment, setAdjustment] = useState<any>(adjustData?.lstAdjusts ? adjustData?.lstAdjusts : [])
    const [filterAdjustment, setFilterAdjustment] = useState([])
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [payload, setPayload] = useState<any>([]);
    const [taxVal, setTaxVal] = useState(adjustData?.taxExempt === undefined ? false : adjustData?.taxExempt);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedOption, setSelectedOption] = useState<any>();
    const [description, setDescription] = useState<any>();
    const dispatch = useDispatch();
    const PER_PAGE = 10;
    const pageCount = Math.ceil(adjustment?.length / PER_PAGE);

    const paginateData = (data: any) => {
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterAdjustment(firstSet)
    }

    const maxWordCount = 250
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const mainSchema: any = yup.object().shape({
        taxExempt: yup.string().nullable(),
        taxRateByZipCode: yup.string().required('Tax Rate By Zip Code is required').matches(DECIMAL, DECIMAL_MESSAGE)
            .test('less-than-100', 'Please enter rate between 0-99 % decimal upto 2 digit.', (value: any) => parseFloat(value || 0) < 100),
        noAdjusts: yup.string().required('No Adjusts is required')
            .test('less-than-1cr', "Please enter upto 8 digits", (value: any) => parseFloat(value || 0) < 10000000)
            .matches(DECIMAL, DECIMAL_MESSAGE),
        ajustmentSalesTax: yup.string().required('Adjustment Sales Tax is required')
            .matches(DECIMAL, DECIMAL_MESSAGE).test('less-than-1cr', "Please enter upto 8 digits", (value: any) => parseFloat(value || 0) < 10000000)
    })

    const schema: any = yup.object().shape({
        amount: yup.string().required('Amount is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }),
        // chgCr: yup.string().required('Charge/Credit is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL73, DECIMAL73_MESSAGE),
        chgCr: yup.string().required("Charge/Credit is required"),
        // tax: yup.string().required('Tax is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //     return value ? /^\S*$/g.test(value) : true;
        // }).matches(DECIMAL73, DECIMAL73_MESSAGE),
        // taxAmount: yup.string().when('selectedOption', {
        //     is: (value: any) => value == 2,
        //     then: () => yup
        //         .string()
        //         .required("Tax Amount is required")
        //         .test('no-blank-space', 'Blank spaces are not allowed',
        //             (value) => {
        //                 return value ? !/^\s/.test(value) : true;
        //             })
        //         .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //             return value ? /^\S*$/g.test(value) : true;
        //         }),
        //     otherwise: () => yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //         return value ? /^\S*$/g.test(value) : true;
        //     })
        // }),
        description: yup.string().required('Description is required.'),
    })

    const {
        register: registerAdjust,
        getValues: getValuesAdjust,
        handleSubmit: handleSubmitAdjust,
        reset: resetAdjust,
        watch: adjustWatch,
        setValue: adjustSetValue,
        control: adjustControl,
        formState: { errors: errorsAdjust, isValid: isValidAdjust }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' });


    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(mainSchema), defaultValues: mainInitialData, mode: 'onChange' })

    const noAdjusts: any = watch('noAdjusts')
    const ajustmentSalesTax: any = watch('ajustmentSalesTax')

    const amount = adjustWatch('amount')
    const taxAmount = adjustWatch('taxAmount');
    const chgCr = adjustWatch('chgCr');


    useEffect(() => {
        if (chgCr == "1" || !chgCr) {
            adjustSetValue('taxAmount', "0.00")
        } else {
            adjustSetValue('taxAmount', Number(initialFormValue?.taxAmount || 0)?.toFixed(2));
        }
    }, [chgCr])

    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=AdjustDescription&companyId=${localStorage.getItem("companyId")}`).then((response) => {
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectTypeValue
                    }
                })
                setDescription(formateData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }, [])
    const onSubmitAdjust = () => {
        let obj, updatedData
        let data = getValuesAdjust();
        if (mode === "create") {
            data = { ...data, adjustUniqueId: uuidv4(), sellerAdjustId: 0, chgCr: parseInt(data.chgCr) }
            // dispatch(updateSeller({ ...sellerData, adjust: { ...sellerData?.adjust, lstAdjusts: [...adjustData?.lstAdjusts, data] } }))
            setAdjustment([...adjustment, data])
            setPayload([...payload, data])
        } else {
            obj = adjustment?.find((i: any) => initialFormValue.adjustUniqueId === i.adjustUniqueId)
            updatedData = adjustment?.filter((i: any) => initialFormValue.adjustUniqueId !== i.adjustUniqueId)
            obj = { ...obj, ...data, chgCr: parseInt(data.chgCr) }
            // dispatch(updateSeller({ ...sellerData, adjust: { ...sellerData?.adjust, lstAdjusts: [...updatedData, obj] } }))
            setAdjustment([...updatedData, obj])
            const newPayload = payload?.filter((i: any) => initialFormValue.adjustUniqueId !== i.adjustUniqueId)
            setPayload([...newPayload, obj])
        }
        resetAdjust(initialData)
        setCurrentPage(0)
        handleClose()
    }

    const IsTaxFieldsChanged = (newData: any) => {
        if (!newData?.taxRateByZipCode && !newData?.noAdjusts && !newData?.ajustmentSalesTax) {
            return false
        }

        if (adjustData.taxRateByZipCode == newData?.taxRateByZipCode && adjustData.noAdjusts == newData?.noAdjusts && adjustData.ajustmentSalesTax == newData?.ajustmentSalesTax) {
            return false
        }
        return true
    }

    const onSave = () => {
        let data: any = getValues();
        data = {
            ...data,
            // auctionId: auctionId,
            taxExempt: taxVal,
            lstAdjusts: payload?.length === 0 ? [] : IsTaxFieldsChanged(data) ? adjustment : payload,
            taxRateByZipCode: Number(data?.noAdjusts || 0.00)?.toFixed(2),
            noAdjusts: Number(data?.noAdjusts || 0.00)?.toFixed(2),
            ajustmentSalesTax: Number(data?.ajustmentSalesTax || 0.00)?.toFixed(2),
        }
        setLoading(true)
        axios.post(UPDATEADJUSTS, data, { ...config, 'ContentType': 'application/json' })
            .then((response) => {
                const updatedData = response?.data?.data
                const adjustData = {
                    sellerAdjustId: 0,
                    sellerId: data?.sellerId,
                    taxRateByZipCode: Number(updatedData[0]?.taxRateByZipCode || 0.00)?.toFixed(2),
                    noAdjusts: Number(updatedData[0]?.noAdjusts || 0.00)?.toFixed(2),
                    ajustmentSalesTax: Number(updatedData[0]?.ajustmentSalesTax || 0.00)?.toFixed(2),
                    taxExempt: updatedData[0]?.taxExempt,
                    isActive: true,
                    isDelete: false,
                    lstAdjusts: updatedData.map((i: any) => { return { ...i, adjustUniqueId: uuidv4() } }) ?? []
                }
                dispatch(updateSeller({ ...sellerData, adjust: adjustData }))
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    setLoading(false);
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    setLoading(false);
                    toast.success(SellerMessages.adjustcreated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    })
                }
                handleNext();
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
    }


    const handleClose = () => {
        setToggleAdjModal(!toggleAdjModal)
        resetAdjust(initialData)
        setRemainingCount(maxWordCount)
        setSelectedOption("")
    }


    const handleRadio = (rValue: boolean) => {
        setTaxVal(rValue)
        if (rValue === false) {
            setValue('taxRateByZipCode', "0.00")
            setValue('noAdjusts', "0.00")
            setValue('ajustmentSalesTax', "0.00")
        } else {
            setValue('taxRateByZipCode', Number(adjustData?.taxRateByZipCode || 0.00).toFixed(2) ?? "0.00")
            setValue('noAdjusts', Number(adjustData?.noAdjusts || 0.00).toFixed(2) ?? "0.00")
            setValue('ajustmentSalesTax', Number(adjustData?.ajustmentSalesTax || 0.00).toFixed(2) ?? "0.00")
        }
        clearErrors(['taxExempt', 'ajustmentSalesTax', 'noAdjusts', 'taxRateByZipCode']);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleEditModal: any = (adjustUniqueId: any, type: string) => {
        if (type === 'edit') {
            setMode('edit')
            setToggleAdjModal(!toggleAdjModal)
            const getData = adjustment.find((data: any) => data.adjustUniqueId === adjustUniqueId)
            setSelectedOption(getData.chgCr)
            setFormValue({ ...getData, amount: Number(getData.amount)?.toFixed(2), taxAmount: Number(getData.taxAmount)?.toFixed(2) })
            resetAdjust({ ...getData, amount: Number(getData.amount)?.toFixed(2), taxAmount: Number(getData.taxAmount)?.toFixed(2) })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const getDeletedData: any = adjustment.find((data: any) => data.adjustUniqueId === adjustUniqueId)
                const updatedData = adjustment.map((data: any) => {
                    if (data.adjustUniqueId == adjustUniqueId) {
                        if (data.sellerAdjustId === 0) {
                            return null
                        }
                        return { ...data, isDelete: true, isActive: false }
                    }
                    return data
                }).filter((i: any) => i)
                setAdjustment(updatedData)
                if (getDeletedData?.sellerAdjustId === 0) {
                    setPayload(payload.filter((i: any) => i.adjustUniqueId !== adjustUniqueId))
                }
                else if (payload.find((i: any) => getDeletedData?.sellerAdjustId == i.sellerAdjustId)) {
                    const updatedPayload = payload.map((i: any) => {
                        if (getDeletedData?.sellerAdjustId == i.sellerAdjustId) {
                            return { ...i, isActive: false, isDelete: true }
                        } else {
                            return i
                        }
                    })
                    setPayload(updatedPayload)
                }
                else {
                    setPayload([...payload, { ...getDeletedData, isActive: false, isDelete: true }])
                }
                setCurrentPage(0)
                toast.success(SellerMessages.deletedMsg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('payment'))
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('docs'))
    }

    const filterAdjDataByAuction = () => {
        let data = adjustData?.lstAdjusts.filter((adjust: any) => adjust?.auctionId === auctionId)
        setAdjustment(data)
    }

    useEffect(() => {
        if (adjustment?.length) {
            paginateData(adjustment)
        } else {
            paginateData([])
        }

        if (sellerData?.sellerId && adjustData.sellerAdjustId !== undefined) {
            reset(adjustData)
            handleRadio(adjustData?.taxExempt === undefined ? false : adjustData?.taxExempt)
        }
    }, [adjustData, adjustment])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = adjustment?.slice(offset, offset + PER_PAGE)
        setFilterAdjustment(nextSet)
    }, [currentPage])

    useEffect(() => {
        filterAdjDataByAuction()
    }, [auctionId])

    const handleOptionChange = (event: any) => {
        if (event.value) {
            setSelectedOption(event.value);
        }
        else {
            setSelectedOption("");
        }
    };


    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={toggleAdjModal} size='lg' centered>
                <form noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add Adjustment' : 'Edit Adjustment'}</h5>
                        <button className="btn p-1" type="button" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label">Description <sup className="text-danger">*</sup></label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    {...registerAdjust('description')}
                                >
                                    <option value="">Select </option>
                                    {description?.map((initialData: any, index: any) => {
                                        return (
                                            <option key={index} value={initialData?.value}>
                                                {initialData?.value}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errorsAdjust.description &&
                                    <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.description.message}</span>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount ($)<sup className="text-danger">*</sup></label>

                                <Controller
                                    name="amount"
                                    control={adjustControl}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount ($)"
                                            {...registerAdjust('amount')}
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={amount}
                                            onValueChange={(value: any) =>
                                                adjustSetValue("amount", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />
                                {errorsAdjust.amount && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.amount.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Charge/Credit <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...registerAdjust('chgCr')}
                                    name='chgCr'
                                    options={options}
                                    value={options?.find((option: any) => option?.value == selectedOption)}
                                    onChange={(e: any) => {
                                        adjustSetValue('chgCr', e.value)
                                        handleOptionChange(e)
                                    }}
                                />
                                {errorsAdjust.chgCr && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.chgCr.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Taxable ($) (Charge Only){selectedOption == 2 ? <sup className="text-danger">*</sup> : ""}</label>
                                <Controller
                                    name="taxAmount"
                                    control={adjustControl}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Tax Amount"
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={taxAmount}
                                            disabled={selectedOption == 2 ? false : true}
                                            onValueChange={(value: any) => adjustSetValue("taxAmount", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />
                                {errorsAdjust.taxAmount && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.taxAmount.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit" onClick={handleSubmitAdjust(onSubmitAdjust)}>{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-3 mb-4 border-300">
                                <div className="col-12 mt-3 d-flex flex-wrap justify-content-between">
                                    <h4>Adjusts</h4>
                                    <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex aling-items-center" type="button"
                                        onClick={() => {
                                            resetAdjust(initialData)
                                            setToggleAdjModal(!toggleAdjModal)
                                            setMode('create')
                                        }}
                                    >
                                        <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Adjustment </span>
                                    </button>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div className="mb-5 border-300">
                                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col">Action</th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Date</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant w-25" scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Description</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Charge/Credit ($)</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Amount ($)</span>
                                                                </div>
                                                            </th>

                                                            {/* <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Tax</span>
                                                                </div>
                                                            </th> */}
                                                            <th className="bg-gradiant " scope="col">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Taxable ($) (Charges Only)</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="table-latest-review-body">
                                                        {(filterAdjustment?.length > 0) ?
                                                            filterAdjustment.filter((i: any) => i.isDelete === false)?.map((data: any, index: any) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                                    <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                    </button>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                    <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.adjustUniqueId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                    <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.adjustUniqueId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">
                                                                                {moment(data?.updatedOn ? data?.updatedOn : data?.createdOn ? data?.createdOn : new Date()).format('MM/DD/YYYY').toString()}
                                                                            </p>
                                                                        </td>
                                                                        <td className="align-middle  p-2 w-25">
                                                                            <p className="fs--1 text-1000 mb-0" data-toggle="tooltip" title={data?.description}>
                                                                                {data?.description && `${data?.description.substring(0, 33)}${data?.description.length > 33 ? '...' : ''}`}
                                                                            </p>
                                                                        </td>
                                                                        <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.chgCr == "2" ? "Charge" : "Credit"}</p>
                                                                        </td>
                                                                        <td className="align-middle review p-2">
                                                                            <p className="fs--1 text-1000 mb-0">
                                                                                {data.chgCr == 2 ? formatCurrency(data?.amount) : `(${formatCurrency(data?.amount)})`}
                                                                            </p>
                                                                        </td>
                                                                        {/* <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.tax}</p>
                                                                        </td> */}
                                                                        <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.taxAmount === "" ? "-" : formatCurrency(data?.taxAmount)}</p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                            (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={7}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            {filterAdjustment?.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"←"}
                                                    nextLabel={"→"}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    previousLinkClassName={"pagination__link"}
                                                    nextLinkClassName={"pagination__link"}
                                                    disabledClassName={"pagination__link--disabled"}
                                                    activeClassName={"pagination__link--active"}
                                                    forcePage={currentPage}
                                                />)}
                                        </div>
                                    </div>
                                </div>
                                {adjustment.length ?
                                    <>
                                        <div className="col-12">
                                            <h4>Tax Exempt</h4>
                                            {taxExemptType?.map((taxType: any, index: number) => {
                                                return (
                                                    <div className="form-check form-check-inline mb-3">
                                                        <input
                                                            type="radio"
                                                            id={`adj_${index}`}
                                                            className="form-check-input"
                                                            onClick={() => handleRadio(taxType.value)}
                                                            checked={taxVal === taxType?.value}
                                                            {...register('taxExempt')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`adj_${index}`} >{taxType.label}</label>
                                                    </div>
                                                )
                                            })}
                                            {errors.taxExempt && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxExempt.message}</span>}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <label className="form-label">Tax Rate By Zip Code (%) {taxVal && (<sup className="text-danger">*</sup>)}</label>
                                                <input className="form-control" onKeyDown={handleKeyDown} disabled={!taxVal} {...register('taxRateByZipCode')} maxLength={5} placeholder="Tax Rate By Zip Code" />
                                                {errors.taxRateByZipCode && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRateByZipCode.message}</span>}
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <label className="form-label">No Adjusts ($) {taxVal && (<sup className="text-danger">*</sup>)}</label>
                                                <Controller
                                                    name="noAdjusts"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            type="text"
                                                            disabled={!taxVal}
                                                            placeholder="No Adjusts"
                                                            {...register('noAdjusts')}
                                                            prefix="$"
                                                            onKeyDown={handleKeyDown}
                                                            value={noAdjusts}
                                                            decimalsLimit={2}
                                                            onValueChange={(value: any) =>
                                                                setValue("noAdjusts", value)}
                                                            className="form-control form-icon-input"
                                                        />
                                                    )}
                                                />
                                                {errors.noAdjusts && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.noAdjusts.message}</span>}
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <label className="form-label">Adjustment Sales Tax ($) {taxVal && (<sup className="text-danger">*</sup>)}</label>
                                                <Controller
                                                    name="ajustmentSalesTax"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            type="text"
                                                            disabled={!taxVal}
                                                            placeholder="No Adjusts"
                                                            {...register('ajustmentSalesTax')}
                                                            prefix="$"
                                                            onKeyDown={handleKeyDown}
                                                            value={ajustmentSalesTax}
                                                            decimalsLimit={2}
                                                            onValueChange={(value: any) =>
                                                                setValue("ajustmentSalesTax", value)}
                                                            className="form-control form-icon-input"
                                                        />
                                                    )}
                                                />
                                                {errors.ajustmentSalesTax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ajustmentSalesTax.message}</span>}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                        <button className="btn btn-primary ms-2" type="submit">{ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)}</button>
                        <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Adjust