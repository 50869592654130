import axios from "axios";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { toast } from "react-toastify";
import XLSX from 'xlsx-js-style';
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import { GETALLAUCTIONLIST, GETLIENHOLDERDATA, GETLIENHOLDERNAME, GETSELLER, LIENHOLDERADVANCESEARCH } from "../../common/apiConstatnts";
import { UTCdateConverter } from "../../common/constants";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import handleError from "../../Utils/ApiCommonErrorMessages";
import MomentUtil from "../../Utils/MomentUtil";
import { formatCurrency, formatNumber, removeHtmlTags } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Lienholder Report', url: routeConstant.LIENHOLDERREPORT }
]

const LienholderReport = () => {
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState<any>({ firstName: "", lastName: "", lotTitle: "", lotId: "", lotNo: "", SellerId: "" });
    const [originalLien, setOriginalLien] = useState<any>([])
    const [lienData, setLienData] = useState<any[]>([]);
    const [filterLien, setFilterLien] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [seller, setSeller] = useState<any>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const auctionId = selectedAuction?.value;
    const [lienList, setLienList] = useState<[]>([]);
    const [selectedLien, setSelectedLien] = useState<any>({});

    const [isLienEnabled, setIsLienEnabled] = useState(false);

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction: any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        lotTitle: '',
        lotId: '',
        lotNo: ''
    });

    const PER_PAGE = 10;
    const pageCount = Math.ceil(lienData.length / PER_PAGE);

    const handleSearch = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        const filterData: any = originalLien?.filter((x: any) => x.lotTitle?.toLocaleLowerCase()?.includes(keyword) ||
            x.sellerName?.toLocaleLowerCase()?.includes(keyword));
        paginateData(filterData);
        setCurrentPage(0);
    }
    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...lienData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }


    // Function to clear the advanced filter data and reset error states
    const clearAdvanceFilterData = () => {
        // Reset the filter data to initial empty values
        setFilterData({ firstName: "", lastName: "", lotTitle: "", lotId: "", lotNo: "", SellerId: "" });
        // Reset the error states to initial empty values
        setError({
            firstName: '',
            lastName: '',
            lotTitle: '',
            lotId: '',
            lotNo: ''
        })
        // getLienData(selectedLien?.value);
    }

    const paginateData = (data: any) => {
        setLienData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterLien(firstSet)
    }

    const changeHandler = (fieldName: string, value: any) => {
        let data = value;
        // if (fieldName == 'lotId' || fieldName == 'lotNo') {
        //     data = Number(value?.slice(0, 8)) == 0 ? null : Number(value?.slice(0, 8))
        // }
        const updateData = { ...filterData, [fieldName]: data }
        setFilterData(updateData);
        if (fieldName === "firstName" || fieldName === "lastName" || fieldName === "lotTitle") {
            const isValidTitle = !/^\s/.test(value);
            if (!isValidTitle) {
                setError({ ...error, [fieldName]: 'Leading spaces are not allowed' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
        if (fieldName === "lotId" || fieldName === "lotNo") {
            const isValidNumber = /^[0-9]*$/.test(value) && value.length <= 20;
            if (!isValidNumber) {
                setError({ ...error, [fieldName]: 'Please enter number only upto 20 digits' });
                return;
            } else {
                setError({ ...error, [fieldName]: '' });
            }
        }
    }

    const handleAdvanceFilter = () => {
        setLoading(true)
        axios.get(`${LIENHOLDERADVANCESEARCH}?auctionId=${selectedAuction?.value}&lienholder=${selectedLien?.value}&firstName=${filterData.firstName}&lastName=${filterData.lastName}&lotTitle=${filterData.lotTitle}&lotId=${filterData.lotId}&lotNo=${filterData.lotNo}&SellerId=${filterData.SellerId}`)
            .then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setFilterLien([]);
                    return;
                } else {
                    paginateData(response.data.data);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error)
            });
        return
    }

    // Function to fetch lien data based on the selected lienholder and auction ID
    const getLienData = (auctionId?: any, lienholder?: any) => {
        // Set loading state to true to indicate data fetching in progress
        setLoading(true);
        if (auctionId && lienholder) {
            axios.get(`${GETLIENHOLDERDATA}?AuctionId=${auctionId}&lienholder=${lienholder}`).then((response: any) => {
                setLoading(false);
                const lienResp = response?.data?.data;
                if (response?.data?.success === "Fail") {
                    // Display an error toast notification with the failure message
                    // toast.error(response?.data?.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    setFilterLien([]);
                    return;
                } else {
                    paginateData(lienResp);
                    setOriginalLien(lienResp);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error)
            });;
        }
    }


    const getSellers = () => {
        setLoading(true)
        axios.get(`${GETSELLER}?pageNumber=-1`).then((response: any) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const sellerResp = response?.data?.data?.data;
                const formatSeller = sellerResp.map((sData: any) => { return { label: `${sData.sellerId} - ${sData.firstName} ${sData.lastName}`, value: sData.sellerId } })
                setSeller(formatSeller);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }

    // Function to fetch all closed auctions and update the auction list state
    const getAllAuctions = () => {
        setLoading(true);

        // Make a GET request to fetch all closed auctions
        axios.get(GETALLAUCTIONLIST).then((response) => {
            // Set loading state to false as the data fetching is complete
            setLoading(false);

            // Extract auction data from the API response and map it to the desired format
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });

            // Update the auction list state with the fetched auction data
            setAuctionList(auctionData);

            // If there are auctions available, set the first auction as the selected auction
            if (auctionData.length > 0) {
                setSelectedAuction(auctionData[0]);
                // Optionally, you can fetch lien holder names for the first auction
                fetchLienHolderName(auctionData[0].value);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });;
    };

    // Function to fetch lien holder names based on the selected auction ID
    const fetchLienHolderName = (auctionId: any) => {
        setLoading(true);
        setLienList([]);
        if (auctionId) {
            axios.get(`${GETLIENHOLDERNAME}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    setFilterLien([]);
                }
                else {
                    const lienHolderData = response?.data?.data?.map((lData: any) => {
                        return {
                            label: `${lData?.lienholderName}`,
                            value: lData?.lienholderName,
                        };
                    });
                    setLienList(lienHolderData);
                    setIsLienEnabled(lienHolderData?.length > 0);
                    // If no lien holder data is available, clear the selected lien and filter lien data
                    if (lienHolderData?.length === 0) {
                        setSelectedLien({});
                        setFilterLien([]);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error)
            });;
        }
    };

    // useEffect hook to fetch all auctions when the component mounts
    useEffect(() => {
        getAllAuctions();
    }, []);


    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
        fetchLienHolderName(e?.value);
    };


    useEffect(() => {
        // if (selectedAuction?.value) {
        fetchLienHolderName(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value)
        // }
    }, [selectedAuction, hammerAuction])

    useEffect(() => {
        if (hammerAuction) {
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    }, [hammerAuction])

    const handleLienChange = (e: any) => {
        setSelectedLien(e);
        // getLienData(e?.value);
        getLienData(selectedAuction?.value ? selectedAuction?.value : hammerAuction?.value, e?.value)
    };

    const exportPDF = () => {
        const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })
        const doc: any = new jsPDF();

        const header = (doc: any) => {
            const pageWidth = doc.internal.pageSize.width;
            const img = new Image();
            img.src = dpa_logo;
            doc.addImage(img, "SVG", 7, 9, 30, 12);

            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text("Del Peterson & Associates, Inc.", 40, 12, { fontStyle: "bold" });
            doc.text(`${selectedAuction?.label}`, 40, 17, { fontStyle: 'bold' });

            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const rightAlignX = pageWidth - 10;
            doc.setFontSize(12);
            doc.setFont("helvetica", "bold");
            doc.text("Line Holder Report", rightAlignX, 12, { align: "right", fontStyle: "bold" });
            doc.setFont("helvetica", "bold");
            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);
            const todayTime = new Date().toLocaleString();
            doc.text(`Printed On (${defaultTimezone}): ${currTime.format('MM/DD/YYYY hh:mm A')}`, rightAlignX, 17, { align: 'right' });

        };

        const footer = (doc: any) => {
            const pageCount = doc.internal.getNumberOfPages();
            doc.setFontSize(8);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Page ${pageCount}`, 10, pageHeight - 10);
            doc.text(
                `Line Holder Report`,
                pageSize.width / 2,
                pageHeight - 10,
                { align: "center" }
            );
            const todayTime = new Date().toLocaleString();
            doc.text(todayTime, pageSize.width - 15, pageHeight - 10, {
                align: "right",
            });
        };
        const head = [['Lot Id', 'Lot #', 'Lot Title', 'Seller Name', 'Unit', 'Price', 'Less Commission', 'Less Listing Fee', 'Sales Tax on Seller Fees', 'Total']]
        const data = lienData.map((data: any) => {
            return {
                lotId: data.lotId || "-",
                lotNo: data.lotNo || "-",
                lotTitle: data.lotTitle ? removeHtmlTags(data.lotTitle) : "-",
                sellerName: data.sellerName || "-",
                unit: formatNumber(data.unit) || "0",
                price: formatCurrency(data.price) || "0",
                lessCommission: formatCurrency(data.lessCommission) || "0",
                lessListingFee: formatCurrency(data.lessListingFee) || "0",
                salesTaxonSellerFees: formatCurrency(data.salesTaxonSellerFees) || "0",
                total: formatCurrency(data.total) || "0",
            }
        })
        const newr: any = data.map((i: any) => {
            return Object.values(i)
        })

        doc.autoTable({
            startY: 35,
            head: head,
            body: newr,
            headStyles: {
                cellPadding: { top: 2, right: 1, bottom: 1, left: 1 },
                fontSize: 9,
                fillColor: null,
                textColor: '#666666',
            },
            styles: {
                fontSize: 8,
                cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
            },
            margin: { top: 40, left: 8, right: 8 },
            didDrawPage: () => {
                header(doc);
                footer(doc);
            },
        });

        doc.save(`Lienholder-Report.pdf`);
    }

    const generateExcelWithTitle = () => {
        let data = lienData?.map((data: any) => {
            return {
                lotId: data.lotId || "-",
                lotNo: data.lotNo || "-",
                lotTitle: data.lotTitle ? removeHtmlTags(data.lotTitle) : "-",
                sellerName: data.sellerName || "-",
                unit: formatNumber(data.unit) || "0",
                price: formatCurrency(data.price) || "0",
                lessCommission: formatCurrency(data.lessCommission) || "0",
                lessListingFee: formatCurrency(data.lessListingFee) || "0",
                salesTaxonSellerFees: formatCurrency(data.salesTaxonSellerFees) || "0",
                total: formatCurrency(data.total) || "0",
            }
        });

        const wb = XLSX.utils.book_new();
        const wsData = [
            ['Lienholder-Report'],
            [`Printed On (in UTC): ${moment(UTCdateConverter(new Date())).format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Lot Id', 'Lot #', 'Lot Title', 'Seller Name', 'Unit', 'Price', 'Less Commission', 'Less Listing Fee', 'Sales Tax on Seller Fees', 'Total'],
            ...data.map((item: any) => Object.values(item)),
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        ws['!cols'] = [
            { wpx: 50 },
            { wpx: 50 },
            { wpx: 150 },
            { wpx: 100 },
            { wpx: 50 },
            { wpx: 100 },
            { wpx: 120 },
            { wpx: 120 },
            { wpx: 150 },
            { wpx: 100 },
        ];

        const headerStyle = {
            font: { bold: true, sz: 12 },
        };

        const headerRowIndex = 3;
        const headerCells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

        headerCells.forEach((cell, index) => {
            ws[`${cell}${headerRowIndex + 1}`].s = headerStyle;
        });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Lienholder_Report.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = lienData?.slice(offset, offset + PER_PAGE)
        setFilterLien(nextSet)
    }, [currentPage])

    useEffect(() => {
        getSellers();
        // getLienData(selectedLien?.value);
    }, [selectedLien])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Lienholder Report'>
                    <div className="search-box d-flex flex-wrap justify-content-between w-100 mt-2">
                        <div className="d-flex align-items-center">
                            <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                <input className="form-control search-input search" type="text" placeholder="Search" aria-label="Search" onChange={(e: any) => handleSearch(e)} />
                                <span className="fas fa-search search-box-icon"></span>
                                <button type="button" className="p-0 btn ms-3" onClick={(() => { setShowFilter(!showFilter) })} ><i className="fas fa-filter fs-16"></i></button>

                            </div>
                        </div>
                        <div className="ms-4 pt-2 pb-2 d-flex gap-2"
                        >
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={generateExcelWithTitle} disabled={lienData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Excel</span>
                            </button>
                            <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => exportPDF()} disabled={lienData.length == 0}>
                                <i className="fas fa-download"></i>
                                <span>Pdf</span>
                            </button>
                        </div>

                    </div>
                    <div className="col-12 mt-2" >
                        <div className="row g-3">
                            <div className="col-lg-4 col-md-5 ">
                                <label className="form-label ms-1 mb-1">Select Auction</label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Auction"
                                    className="isActive swap-to-lot-menu clone-inventory"
                                    options={auctionList}
                                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                    // value={selectedAuction}
                                    value={selectedAuction.value ? auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                    onChange={handleAuctionChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-5 ">
                                <label className="form-label ms-1 mb-1">Select Lien</label>
                                {lienList?.length > 0 ? (
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select"
                                        className="isActive swap-to-lot-menu clone-inventory"
                                        options={lienList}
                                        isDisabled={!isLienEnabled}
                                        // value={selectedLien}
                                        onChange={handleLienChange}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                            !selectedAuction || selectedAuction == ""
                                                ? "Select an auction first"
                                                : "No Lein exist"
                                        }
                                        disabled
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {showFilter &&
                        <div className={showFilter == true ? '' : 'dropdown-advance-filter'}>
                            <div className="card bg-transparent position-relative p-3 mt-3">

                                <button className="btn p-1 p-a" type="button" onClick={(() => { setShowFilter(!showFilter); setFilterData({ firstName: "", lastName: "", lotTitle: "", lotId: "", lotNo: "", SellerId: "" }) })}>
                                    <span className="fas fa-times text-danger fs-20"></span>
                                </button>
                                <div className="card-body p-0">
                                    <div className="text-start">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">First name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="firstName"
                                                        maxLength={30}
                                                        placeholder="Enter First Name"
                                                        value={filterData.firstName}
                                                        onChange={((e) => changeHandler("firstName", e.target.value))}
                                                    />
                                                    {error?.firstName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.firstName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Last name</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lastName"
                                                        maxLength={30}
                                                        placeholder="Enter Last Name"
                                                        value={filterData.lastName}
                                                        onChange={((e) => changeHandler("lastName", e.target.value))}
                                                    />
                                                    {error?.lastName && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.lastName}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Lot title</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lotTitle"
                                                        maxLength={30}
                                                        placeholder="Enter Lot Title"
                                                        value={filterData?.lotTitle}
                                                        onChange={((e) => changeHandler("lotTitle", e?.target?.value))}
                                                    />
                                                    {error?.lotTitle && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.lotTitle}</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Lot #</label>
                                                    <input
                                                        className="form-control form-icon-input"
                                                        type="text"
                                                        name="lotNo"
                                                        maxLength={21}
                                                        placeholder="Enter Lot #"
                                                        value={filterData.lotNo}
                                                        onChange={((e) => changeHandler("lotNo", e.target.value))}
                                                    />
                                                    {error?.lotNo && <span className='text-danger' style={{ fontSize: "12px" }}>{error?.lotNo}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mt-2 mb-2">
                                                    <label className="form-label">Seller</label>
                                                    <select
                                                        name="SellerId"
                                                        className="form-select"
                                                        value={filterData.SellerId}
                                                        onChange={((e) => changeHandler("SellerId", e.target.value))}
                                                    >
                                                        <option value="">Select Seller</option>
                                                        {seller.map((sData: any, sIndex: any) => {
                                                            return (
                                                                <option value={sData.value} key={sIndex}>{sData.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center ms-auto">
                                            <div className="flex-1 text-end  ms-3">
                                                <button className="btn btn-outline-secondary mb-1 me-3" type="button" onClick={clearAdvanceFilterData}>Clear</button>
                                                <button className="btn btn-primary mb-1" type="button" onClick={handleAdvanceFilter}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            <tr>
                                                {/* <th className="bg-gradiant " scope="col" data-sort="module">
                                                    <div className='d-flex align-items-center'>
                                                        <span> Lot Id</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotId')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotId')}></button>
                                                        </div>
                                                    </div>
                                                </th> */}
                                                <th className="bg-gradiant " scope="col" data-sort="phone">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Lot #</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotNo')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotNo')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="lotTitle">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Lot Title</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotTitle')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotTitle')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="sellerName">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Seller Name</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="payoff">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Pay Off</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'sellerName')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'sellerName')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="unit">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Unit</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'unit')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'unit')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="price">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Price</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'price')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'price')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="lessCommission">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Less Commission</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lessCommission')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lessCommission')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="lessListingFee">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Less Listing Fee</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'lessListingFee')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'lessListingFee')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="salesTax">
                                                    <div className='d-flex align-items-center'>
                                                        <span>SalesTax on Seller Fees</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesTaxonSellerFees')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesTaxonSellerFees')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="bg-gradiant " scope="col" data-sort="total">
                                                    <div className='d-flex align-items-center'>
                                                        <span>Total</span>
                                                        <div className="sorting_group">
                                                            <button className='arrow-up' onClick={() => handleSorting('ASC', 'total')} ></button>
                                                            <button className='arrow-down' onClick={() => handleSorting('DESC', 'total')}></button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">
                                            {filterLien?.length > 0 ? filterLien?.map((fData: any, fIndex: any) => {
                                                return (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                        {/* <td className="align-middle  p-2">{fData.lotId}</td> */}
                                                        <td className="align-middle  p-2">{fData.lotNo}</td>
                                                        <td className="align-middle  p-2">{removeHtmlTags(fData.lotTitle)}</td>
                                                        <td className="align-middle  p-2">{fData.sellerName}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.unit) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatNumber(fData.payoff) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatCurrency(fData.price) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatCurrency(fData.lessCommission) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatCurrency(fData.lessListingFee) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatCurrency(fData.salesTaxonSellerFees) ?? 0}</td>
                                                        <td className="align-middle  p-2">{formatCurrency(fData.total) ?? 0}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                    <td className='text-center p-4' colSpan={10}>No records found!</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='d-flex align-items-center justify-content-end mt-3 mb-3'>
                                    {filterLien?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LienholderReport;