import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import * as routeConstant from '../../common/routeConstants';
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { Breadcrumb } from "../../common/interface";
import InnerHeader from "../InnerHeader/InnerHeader";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { CommonMessages } from "../../common/messageConstants";
import MomentUtil from "../../Utils/MomentUtil";


const CreateAuctionBid = () => {
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const [loading, setLoading] = useState<any>(false);
    const mode = ID === undefined ? 'create' : 'edit';

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Auction Bid', url: routeConstant.AUCTIONBID },
        { label: 'Add New Auction Bid', url: '' }
    ]

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const schema: any = yup.object().shape({});
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const onSubmit = () => {

    }

    return (
        <>
            <>
                {loading && <div className="loader-wrap">
                    <img src={`${localStorage.getItem('globalLoader')}`} />
                </div>}
                <Sidebar />
                <div className="content pt-0 ">
                    <div className="">
                        <InnerHeader breadCrumb={breadcrumbData} title={mode === "create" ? "Create Auction Cloud Bid" : "Edit Auction Cloud Bid"}> </InnerHeader >
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="container-fluid ">
                            <div className="row justify-content-center top_border pt-4">
                                <div className="col-xxl-10">
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="fw-bold d-block">Auction Cloud Bid</h4>
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Auction Id<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Auction Id" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Lot #<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Item" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Buyer Name<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Bidder" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Bidder #<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Bidder" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Category<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Category" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Amount<sup className="text-danger">*</sup></label>
                                            <input type="text" {...register('auctionId')} className="form-control" placeholder="Amount" />
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-3">
                                            <label className="form-label">Date and Time {defaultTimezone}<sup className="text-danger">*</sup></label>
                                            <Controller
                                                name="endDateTime"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <DateTimePicker
                                                        name="endDateTime"
                                                        className="form-control"
                                                        format="dd-MM-y h:mm a"
                                                        value={value}
                                                        onChange={onChange}
                                                        closeWidgets
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid for-fix-bottom-space">
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom">
                                <Link to={routeConstant.AUCTIONBID} className="btn btn-outline-danger">{CommonMessages.cancelButton}</Link>
                                <button className="btn btn-primary ms-2" type="submit">{CommonMessages.saveButton}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        </>
    )
}
export default CreateAuctionBid;

