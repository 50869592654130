import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAuctionEmailReportsActive, setCloneTab, setCreateTab, setInvoiceTemplateActive, setSellerTemplateActive, setStep } from '../../../../store/actions/AuctionAction'
import { AuctionMessages, CommonMessages } from '../../../../common/messageConstants'
import { AUCTIONEINVOICES } from '../../AuctionConstants'
import * as yup from "yup";
import { EMAIL, EMAIL_MESSAGE, SPECIALCHARS, URL, URL_MESSAGE } from "../../../../common/regexConstants";
import { ERROR_MESSAGES } from '../../../../Utils/Utils'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as routeConstant from '../../../../common/routeConstants';
import { toast } from 'react-toastify'
import { loader } from '../../../../store/actions/CommonAction'
import { GETALLTEMPLATE, GETAUCTIONREPORTSBYAUCTIONID, GETEINVOICESETTINGS, UPDATEEINVOICE } from '../../../../common/apiConstatnts'
import axios from 'axios'
import handleError from '../../../../Utils/ApiCommonErrorMessages'
import EmailTemplate from './EmailTemplate'

const SellerEmailTemplate = () => {
    const activeSellerTemplate = useSelector((state: any) => state.auction.sellerTemplate)
    const [activeStep, setActiveStep] = useState(1)
    const [activeLabel, setActiveLabel] = useState("Seller Summary Report")
    const [btnTxt, setBtnTxt] = useState<any>("")
    const [sellerSummary, setsellerSummary] = useState('');
    const [sellerPickup, setSellerPickup] = useState('');
    const dispatch = useDispatch();
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUCTIONEINVOICES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUCTIONEINVOICES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])
    // Active step
    useEffect(() => {
        setActiveStep(activeSellerTemplate.activeStep)
    }, [activeSellerTemplate.activeStep])

    useEffect(() => {
        setActiveLabel(activeSellerTemplate.activeLabel)
    }, [activeSellerTemplate.activeLabel])

    // Active step change 
    const handleChange = (key: string) => {
        if (key === 'sellerSummary') {
            dispatch(setSellerTemplateActive({ activeStep: 1, activeLabel: "Seller Summary Report" }))
        } else if (key === 'sellerPickup') {
            dispatch(setSellerTemplateActive({ activeStep: 2, activeLabel: "Seller Pickup Summary Report" }))
        };
    }

    // Inititial data for balance due and paid 
    const initialData = {
        einvoiceID: 0,
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
        invoiceEmailTemplate: "",
        sellerEmailTemplate: "",
        invoiceEmailTemplateValue: "",
        sellerEmailTemplateValue: "",
        ccAddress: "",
        bccAddress: "",
        fromAddress: "",
        replyToAddress: "",
        emailInvoiceFooterText: "",
        correspondance: null,
        paymentURL: null,
        excludeTextofInvoiceInEmailBody: false,
        includePDFVersionWithBidderEInvoice: false,
        auctionReportTemplate: 3,
        subject: "",
        cc2Address: "",
        includeSellerNumberinSubject: false,
        sendPickupSummarytoSellerSummaryReportEmail: false,
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        isDelete: false,
    }

    const [formValues, setFormValues] = useState<any>(initialData);
    const [isSummaryError, setSummaryError] = useState(false)
    const [isPickupError, setPickupError] = useState(false)
    // Schema 
    const schema: any = yup.object().shape({
        subject: yup.string().required("Subject is required"),
        fromAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        replyToAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        ccAddress: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        cc2Address: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        emailInvoiceFooterText: yup
            .string()
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, 'Please enter valid string'),
    });

    // Form defination for balance due 
    const {
        register: registerSellerSummary,
        handleSubmit: handleSubmitSellerSummary,
        reset: resetSellerSummary,
        watch: watchSellerSummary,
        setValue: setValueSellerSummary,
        formState: { errors: errorsSellerSummary },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: "onChange" });

    // Form defination for balance Paid 
    const {
        register: registerPickupSummary,
        handleSubmit: handleSubmitPickupSummary,
        reset: resetPickupSummary,
        watch: watchPickupSummary,
        setValue: setValuePickupSummary,
        formState: { errors: errorsPickupSummary },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: "onChange" });

    const onSubmitSellerSummary = (data: any) => {
        if (sellerSummary === "") {
            setSummaryError(true)
            return
        }
        submitTemplate(data);
    }

    const onSubmitPickupSummary = (data: any) => {
        if (sellerPickup === "") {
            setPickupError(true)
            return
        }
        submitTemplate(data);


    }
    const submitTemplate = (data: any) => {
        const payload = {
            companyId: localStorage.getItem('companyId'),
            einvoiceID: data.einvoiceID ? parseInt(data.einvoiceID) : 0,
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            sellerEmailTemplate: data?.sellerEmailTemplate.toString() ?? "",
            sellerEmailTemplateValue: activeSellerTemplate.activeStep == 1 ? sellerSummary : sellerPickup,
            invoiceEmailTemplate:  "",
            invoiceEmailTemplateValue: "",
            ccAddress: data?.ccAddress,
            bccAddress: data?.bccAddress,
            fromAddress: data?.fromAddress,
            replyToAddress: data?.replyToAddress,
            emailInvoiceFooterText: data?.emailInvoiceFooterText,
            excludeTextofInvoiceInEmailBody: data?.excludeTextofInvoiceInEmailBody,
            includePDFVersionWithBidderEInvoice: data?.includePDFVersionWithBidderEInvoice,
            auctionReportTemplate: activeSellerTemplate.activeStep == 1 ? 3 : 4,
            subject: data?.subject,
            cc2Address: data?.cc2Address,
            isActive: true,
            isDelete: false,
            includeSellerNumberinSubject: false,
            sendPickupSummarytoSellerSummaryReportEmail: false
        }
        // Update email reports 
        axios.post(UPDATEEINVOICE, payload, config).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUCTIONEINVOICES]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUCTIONEINVOICES]))
                }
                toast.success(AuctionMessages.eInvoiceMessages.eInvoiceUpdates, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                handleNext();

            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handlePrev = () => {
        // When tab is balance due then previous means
        if (activeSellerTemplate.activeStep == 1) {
            dispatch(setAuctionEmailReportsActive({ activeStep: 1, activeLabel: "Invoice Email Template" }))
        } else {
            dispatch(setSellerTemplateActive({ activeStep: 1, activeLabel: "Seller Summary Report" }))
        }
    }

    const handleNext = () => {
        // When tab is balance due then previous means
        if (activeSellerTemplate.activeStep == 1) {
            dispatch(setSellerTemplateActive({ activeStep: 2, activeLabel: "Seller Pickup Summary Report" }))
        } else {
            dispatch(setStep(6));
        }
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getTemplateData = () => {
        dispatch(loader(true));
        const aId = clonedTabs.includes(AUCTIONEINVOICES) ? cloneAuctionId : auctionId
        axios.get(`${GETALLTEMPLATE}?templateType=5`).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                const templateContent = response.data.data;
                dispatch(loader(true));
                axios.get(`${GETAUCTIONREPORTSBYAUCTIONID}?AuctionId=${aId}&auctionReportTemplate=${activeSellerTemplate.activeStep == 1 ? 3 : 4}`, config).then((response: any) => {
                    if (response?.data?.success === "Fail") {
                        dispatch(loader(false));
                        if (templateContent?.length > 0) {
                            // Check if sellerSummaryReport exists
                            const hasSellerSummaryReport = templateContent.some((item: any) => item?.strTemplateFor === "sellerSummaryReport");
                            // Check if sellerPickupSummaryReport exists
                            const hasSellerPickupSummaryReport = templateContent.some((item: any) => item?.strTemplateFor === "sellerPickupSummaryReport");

                            if (activeSellerTemplate.activeStep == 1) {
                                if (hasSellerSummaryReport) {
                                    const filteredData = templateContent.filter((item: any) => item?.strTemplateFor == "sellerSummaryReport");
                                    setsellerSummary(filteredData[0]?.templateContent);
                                    setValueSellerSummary('sellerEmailTemplate', filteredData[0]?.templatemasterId)
                                } else {
                                    setsellerSummary('');

                                }
                            } else {
                                if (hasSellerPickupSummaryReport) {
                                    const filteredData = templateContent.filter((item: any) => item?.strTemplateFor == "sellerPickupSummaryReport");
                                    setSellerPickup(filteredData[0]?.templateContent);
                                    setValuePickupSummary('sellerEmailTemplate', filteredData[0]?.templatemasterId)

                                } else {
                                    setSellerPickup('');
                                }
                            }
                        } else {
                            setsellerSummary('');
                            setSellerPickup('');
                        }
                        return;
                    }
                    else {

                        dispatch(loader(false));
                        let respData = response.data.data
                        if (!clonedTabs.includes(AUCTIONEINVOICES) && cloneAuctionId > 0) {
                            respData = { ...respData, einvoiceID: 0 }
                        }
                        if (activeSellerTemplate.activeStep == 1) {
                            resetSellerSummary(respData)
                            setsellerSummary(respData?.sellerEmailTemplateValue)
                        } else {
                            resetPickupSummary(respData)
                            setSellerPickup(respData?.sellerEmailTemplateValue)
                        }
                    }
                }, (error) => {
                    dispatch(loader(false))
                    if (error?.response?.status !== 401) {
                        toast.error("Some error occurred!", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                    }
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error);
                })
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }
    // Get default data from einvoice setting
    useEffect(() => {
        getTemplateData();
    }, [activeSellerTemplate.activeStep])

    useEffect(() => {
        // if (Object.keys(eInvoice).length === 0) {
        dispatch(loader(true))
        axios.get(GETEINVOICESETTINGS, config).then((response) => {
            dispatch(loader(false));
            const eInvoiceData = response.data.data;
            delete eInvoiceData.autoInvoiceCashDiscountText
            delete eInvoiceData.einvoicesettingsId
            delete eInvoiceData.modifiedBy
            delete eInvoiceData.updatedBy
            delete eInvoiceData.updatedOn
            delete eInvoiceData.createdBy
            delete eInvoiceData.createdOn
            delete eInvoiceData.isDelete
            const updatedData = { ...eInvoiceData, fromAddress: eInvoiceData.auctionEmail, replyToAddress: eInvoiceData.auctionEmail }
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                // reset(updatedData);
                const values = [
                    { key: 'ccAddress', value: eInvoiceData.ccAddress },
                    { key: 'bccAddress', value: eInvoiceData.bccAddress },
                    { key: 'fromAddress', value: eInvoiceData.auctionEmail },
                    { key: 'replyToAddress', value: eInvoiceData.auctionEmail },
                    { key: 'emailInvoiceFooterText', value: eInvoiceData.emailInvoiceFooterText }
                ];

                values.forEach(({ key, value }) => setValueSellerSummary(key, value));
                values.forEach(({ key, value }) => setValuePickupSummary(key, value));

            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
        return
        // }
    }, [])
    return (
        <div className="container-fluid for-fix-bottom-space">
            {/* 2 different submit for sellerSummary and sellerPickup */}
            <form onSubmit={activeSellerTemplate.activeStep == 1 ? handleSubmitSellerSummary(onSubmitSellerSummary) : handleSubmitPickupSummary(onSubmitPickupSummary)}>
                <div className="for-fix-bottom-space">
                    <Tabs
                        activeKey={activeStep === 1 ? "sellerSummary" : "sellerPickup"}
                        defaultActiveKey="sellerSummary"
                        className="nav-underline border-bottom  nav nav-tabs mb-3"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="sellerSummary" title="Seller Summary Report">
                            <EmailTemplate register={registerSellerSummary} activeStep={activeSellerTemplate} ckData={sellerSummary} setCkData={setsellerSummary} errors={errorsSellerSummary} isError={isSummaryError} />
                        </Tab>
                        <Tab eventKey="sellerPickup" title="Seller Pickup Summary Report">
                            <EmailTemplate register={registerPickupSummary} activeStep={activeSellerTemplate} ckData={sellerPickup} setCkData={setSellerPickup} errors={errorsPickupSummary} isError={isPickupError} />
                        </Tab>
                    </Tabs>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
                    <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                </div>
            </form>
        </div>
    )
}

export default SellerEmailTemplate