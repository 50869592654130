import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { GETSELLERCOMMISSIONS, SELLERCOMMISSIONRECALCUTE, UPDATECOMMISION } from "../../../../common/apiConstatnts";
import { commissionType } from "../../../../common/constants";
import { CommonMessages, errorMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import { formatCurrency } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const Commision = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const dispatch = useDispatch()
    const sellerData = useSelector((state: any) => state.seller)
    let scaleData = sellerData?.commissionOptions
    const companyId: any = localStorage.getItem('companyId')
    const [loading, setLoading] = useState(false);
    const selectedAuction = useSelector((state: any) => state.seller.selectedAuction)
    const auctionId = selectedAuction.value
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const initialData = {
        companyId: companyId,
        auctionId: auctionId,
        isActive: true,
        isDelete: false,
        sellerCommissionId: 0,
        sellerId: sellerData?.sellerId ?? 0,
        sellerCommissionType: 1,
        defaultScalesSold: String(scaleData?.sold) || '',
        defaultScalesPassed: String(scaleData?.unSold) || '',
        defaultScalesBuyback: String(scaleData?.buyBack) || '',
        defaultScalesNonPayer: String(scaleData?.nonPay) || '',
        commissionsTotal: 0,
        commissionsTaxable: 0,
        commissionsAvalaraTax: 0,
        commissionsTaxByzip: 0,
        sellerAdjustsTotal: 0,
        sellerAdjustsTaxable: 0,
        sellerAdjustsAvalaraTax: 0,
        sellerAdjustsTaxByZip: 0,
        invAdjustsTotal: 0,
        invAdjustsTaxable: 0,
        invAdjustsAvalaraTax: 0,
        invAdjustsTaxByZip: 0
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        reset,
        clearErrors,
        watch,
        formState: { errors, isValid }
    } = useForm({
        // resolver: yupResolver(schema), 
        defaultValues: initialData,
        mode: 'onChange'
    })
    const defaultScalesSold = watch('defaultScalesSold')
    const defaultScalesPassed = watch('defaultScalesPassed')
    const defaultScalesNonPayer = watch('defaultScalesNonPayer')
    const defaultScalesBuyback = watch('defaultScalesBuyback')
    const sellerCommissionType = watch('sellerCommissionType')

    const formValues = getValues()

    const handleRadio = (rValue: any) => {
        setValue('sellerCommissionType', rValue)
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('notes'))
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('tax'))
    }

    const sellerCommissionRecalculate = () => {
        const formData = getValues()
        const params = {
            auctionId: auctionId,
            sellerId: sellerData?.sellerId,
            sellerSoldId: formData?.defaultScalesSold,
            sellerPassedId: formData?.defaultScalesPassed,
            sellerBuyBackId: formData?.defaultScalesBuyback,
            sellerNonPayerId: formData?.defaultScalesNonPayer,
            commissionCalculationType: formData?.sellerCommissionType
        }
        setLoading(true)
        axios.get(SELLERCOMMISSIONRECALCUTE, { ...config, params: params }).then((response) => {
            if (response?.data?.success === "Fail") {
                setLoading(false)
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                setLoading(false)
                const newCommission: any = response?.data?.data
                const updatedData = {
                    ...formData,
                    commissionsTotal: newCommission?.commissionsTotal ?? 0,
                    commissionsTaxable: newCommission?.commissionsTaxable ?? 0,
                    commissionsAvalaraTax: newCommission?.commissionsAvalaraTax ?? 0,
                    commissionsTaxByzip: newCommission?.commissionsTaxByzip ?? 0,
                    sellerAdjustsTotal: newCommission?.sellerAdjustsTotal ?? 0,
                    sellerAdjustsTaxable: newCommission?.sellerAdjustsTaxable ?? 0,
                    sellerAdjustsAvalaraTax: newCommission?.sellerAdjustsAvalaraTax ?? 0,
                    sellerAdjustsTaxByZip: newCommission?.sellerAdjustsTaxByZip ?? 0,
                    invAdjustsTotal: newCommission?.invAdjustsTotal ?? 0,
                    invAdjustsTaxable: newCommission?.invAdjustsTaxable ?? 0,
                    invAdjustsAvalaraTax: newCommission?.invAdjustsAvalaraTax ?? 0,
                    invAdjustsTaxByZip: newCommission?.invAdjustsTaxByZip ?? 0
                }
                reset(updatedData)
                toast.success('Commission Recalculated Successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });

            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const handleSave = () => {
        setLoading(true)
        const data = getValues()
        axios.post(UPDATECOMMISION, data, config).then((response) => {
            dispatch(updateSeller({ ...sellerData, commission: data }))
                setLoading(false);
                handleNext();
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    toast.success('Commission saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    })
                }

        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

    const sellerCommissionsByAuction = (auctionId:any) => {
        setLoading(true)
        axios.get(`${GETSELLERCOMMISSIONS}?sellerId=${sellerData?.sellerId}&auctionId=${auctionId}`, config).then(
            (response) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    // toast.error(response.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 2000,
                    // });
                    reset(initialData)
                    return;
                }
                const data = response?.data?.data || initialData;
                reset(data)

            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });;
    }
    useEffect(()=>{
        if(auctionId){
            sellerCommissionsByAuction(auctionId)
        }else if(getGlobalAuctionIds){
            sellerCommissionsByAuction(getGlobalAuctionIds)
        }
        },[auctionId,getGlobalAuctionIds])
    // useEffect(() => {
    //     if (auctionId) {
    //         sellerCommissionsByAuction()
    //     }
    // }, [auctionId])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-3 mb-4 border-300">
                                {/* <div className="mt-4 mb-4 border p-4 bg-light fs-16 border-radius text-center">
                                        Under Development
                            </div> */}
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <div className="mb-3 border-300">
                                            <h4>Commission Type</h4>
                                        </div>
                                        <div className="row">
                                            {commissionType.map((comm: any, index: any) => {
                                                return (
                                                    <div className="col-lg-3 col-md-4" key={index}>
                                                        <input
                                                            name='sellerCommissionType'
                                                            className='form-check-input me-2'
                                                            id={comm.label}
                                                            onChange={() => handleRadio(comm.value)}
                                                            type="radio"
                                                            checked={sellerCommissionType == comm?.value}
                                                        />
                                                        <label className='form-check-label' htmlFor={comm.label}>{comm.label}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <hr></hr>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-2 border-300">
                                            <h4>Default Scales</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <label className='form-label'>Sold</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select"
                                                    className='isActive'
                                                    {...register('defaultScalesSold')}
                                                    name='defaultScalesSold'
                                                    options={scaleData?.list}
                                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesSold)}
                                                    onChange={(e: any) => setValue('defaultScalesSold', String(e.value))}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <label className='form-label'>Passed/Unsold</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select"
                                                    className='isActive'
                                                    {...register('defaultScalesPassed')}
                                                    name='defaultScalesPassed'
                                                    options={scaleData?.list}
                                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesPassed)}
                                                    onChange={(e: any) => setValue('defaultScalesPassed', String(e.value))}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <label className='form-label'>Buyback</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select"
                                                    className='isActive'
                                                    {...register('defaultScalesBuyback')}
                                                    name='defaultScalesBuyback'
                                                    options={scaleData?.list}
                                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesBuyback)}
                                                    onChange={(e: any) => setValue('defaultScalesBuyback', String(e.value))}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <label className='form-label'>Non-Payer</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select"
                                                    className='isActive'
                                                    {...register('defaultScalesNonPayer')}
                                                    name='defaultScalesNonPayer'
                                                    options={scaleData?.list}
                                                    value={scaleData?.list?.find((option: any) => option?.value == defaultScalesNonPayer)}
                                                    onChange={(e: any) => setValue('defaultScalesNonPayer', String(e.value))}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mt-3">
                                        <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                                            onClick={() => sellerCommissionRecalculate()}
                                        >
                                            <span><span className="plus fas fa-refresh"></span> Recalculate Commission </span>
                                        </button>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="table-responsive mx-n1 px-1 scrollbar">
                                            <table className="table fs--1 mb-0 border border-200">
                                                <thead>
                                                    <tr>
                                                        <th> </th>
                                                        <th className="p-2">Total</th>
                                                        <th className="p-2">Taxable</th>
                                                        <th className="p-2">Avalara Tax</th>
                                                        <th className="p-2">Tax By Zip</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2">Commissions </td>
                                                        <td className="p-2"><input value={formValues?.commissionsTotal ? formatCurrency(formValues?.commissionsTotal) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.commissionsTaxable ? formatCurrency(formValues?.commissionsTaxable) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.commissionsAvalaraTax ? formatCurrency(formValues?.commissionsAvalaraTax) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.commissionsTaxByzip ? formatCurrency(formValues?.commissionsTaxByzip) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2">Seller Adjusts</td>
                                                        <td className="p-2"><input value={formValues?.sellerAdjustsTotal ? formatCurrency(formValues?.sellerAdjustsTotal) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.sellerAdjustsTaxable ? formatCurrency(formValues?.sellerAdjustsTaxable) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.sellerAdjustsAvalaraTax ? formatCurrency(formValues?.sellerAdjustsAvalaraTax) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.sellerAdjustsTaxByZip ? formatCurrency(formValues?.sellerAdjustsTaxByZip) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2">Inv. Adjust</td>
                                                        <td className="p-2"><input value={formValues?.invAdjustsTotal ? formatCurrency(formValues?.invAdjustsTotal) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.invAdjustsTaxable ? formatCurrency(formValues?.invAdjustsTaxable) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.invAdjustsAvalaraTax ? formatCurrency(formValues?.invAdjustsAvalaraTax) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                        <td className="p-2"><input value={formValues?.invAdjustsTaxByZip ? formatCurrency(formValues?.invAdjustsTaxByZip) : '$0.00'} type="text" className="form-control" readOnly /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                        <button className="btn btn-primary ms-2" type="submit">{ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)}</button>
                        <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Commision;