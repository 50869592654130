import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  LOGIN,
  FORGOTPASSWORD,
  RESETPASSWORD,
  GETDEFAULTLOADER,
  GETDEFAULTLOGO,
  base,
  GETUSERBYID,
  GETDEFAULTLOGOBLOB,
  GETDEFAULTLOADERBLOB,
  GETBYIDUSINGBLOB,
} from "../../common/apiConstatnts";
import { useNavigate, Link } from "react-router-dom";
import * as routeConstant from "../../common/routeConstants";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
import successIcon from "../../assets/success.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { EMAIL, EMAIL_MESSAGE } from "../../common/regexConstants";
import { useDispatch } from "react-redux";
import { setLoggedUserData, setSidebarOpen } from "../../store/actions/CommonAction";
import { LoginMessage, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";

const Signin = () => {
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [newPassword, setNewPassword] = useState("password");
  const [cnfNewPassword, setCnfNewPassword] = useState("password");
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const [rememberMe, setRememberMe]: any = useState(false);
  const [cnfError, setCnfError] = useState<boolean>(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  // Get the stored item on component mount
  useEffect(() => {
    const storedValue: any = localStorage.getItem('rememberMe');
    if (storedValue) {
      setRememberMe(JSON.parse(storedValue));
    }
    if (JSON.parse(storedValue)) {
      const storedPayload = localStorage.getItem('payload');
      if (storedPayload) {
        const parsedPayload = JSON.parse(storedPayload);
        setValue('email', parsedPayload.username);
        setValue('password', parsedPayload.password);
      }
    }
  }, []);

  const handleRememberMe = () => {
    localStorage.setItem('rememberMe', JSON.stringify(!rememberMe));
    setRememberMe(!rememberMe);
    if (!rememberMe) {
      const payload = {
        username: getValues('email'),
        password: getValues('password'),
      };
      localStorage.setItem('payload', JSON.stringify(payload));
    } else {
      localStorage.removeItem('payload');
    }
  };

  const navigate = useNavigate();
  const url = window.location.pathname.split("/")[1];
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const getEmail: any = searchParams.get("Email");
  const schema: any = yup.object().shape({
    email: yup.string().required("Email address is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE),
    password: yup.string().required("Password is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
  });

  const forgotSchema: any = yup.object().shape({
    email: yup.string().required("Email address is required.")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE),
  });

  const resetSchema: any = yup.object().shape({
    newPassword: yup
      .string()
      .required("Password is required")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .min(8, "Password must be at least 8 characters long")
      .test('no-blank-space', 'Blank spaces are not allowed',
        (value) => {
          return value ? !/^\s/.test(value) : true;
        })
      .test(
        'uppercase',
        'At least one uppercase letter is required',
        (value) => /[A-Z]/.test(value)
      )
      .test(
        'lowercase',
        'At least one lowercase letter is required',
        (value) => /[a-z]/.test(value)
      )
      .test(
        'numbers',
        'At least one number is required',
        (value) => (value.match(/[0-9]/g) || []).length >= 1
      )
      .test(
        'specialCharacter',
        'At least one special character is required',
        (value) => /[!@#$%^&*()_+\-=\[\]{}|';:.<>?]/.test(value)
      ),
    confirmPassword: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .oneOf(
        [yup.ref("newPassword"), ""],
        "Password must match with new password."
      )
      .required("Confirm Password is required."),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const {
    register: forgotRegister,
    handleSubmit: forgotHandleSubmit,
    reset,
    formState: { errors: forgotError, isValid: forgotIsValid },
  } = useForm({ resolver: yupResolver(forgotSchema), mode: "onChange" });

  const {
    register: resetRegister,
    handleSubmit: resetHandleSubmit,
    watch: watchReset,
    getValues: getValuesReset,
    clearErrors: clearErrorsReset,
    formState: { errors: resetError, isValid: resetIsValid },
  } = useForm({ resolver: yupResolver(resetSchema), mode: "onChange" });

  const newP = watchReset('newPassword')
  const cnfpassword = watchReset('confirmPassword')

  useEffect(() => {
    const cnfPassword = getValuesReset('confirmPassword')
    const errorKeys = Object.keys(resetError)
    if (cnfPassword !== "" && (newP !== cnfPassword) && !errorKeys.includes('confirmPassword')) {
      setCnfError(true)
      return
    }
    setCnfError(false)
    clearErrorsReset('confirmPassword')
  }, [newP])

  useEffect(() => {
    setCnfError(false)
  }, [cnfpassword])

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      username: getValues("email"),
      password: getValues("password"),
    };
    axios.post(LOGIN, payload).then(async (response) => {

      const token = response.data.data;
      const decodedToken: any = jwt_decode(token);
      localStorage.setItem("token", token);
      localStorage.setItem("companyId", decodedToken.cmp);
      localStorage.setItem("sidebarOpen", "true");
      dispatch(setSidebarOpen(true))
      setTimeout(() => {
        toast.success(LoginMessage.loginSuccessful, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500,
          className: 'toast-position'
        });
      }, 1000);

      getLoader(decodedToken.cmp)
      await axios
        .get(`${GETDEFAULTLOGOBLOB}?companyId=${decodedToken.cmp}`)
        .then((response) => {
          setLoading(false);
          const logoURL = response.data.data;
          localStorage.setItem("logo", logoURL);
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
      if (rememberMe) {
        localStorage.setItem('payload', JSON.stringify(payload));
      }
      getLoggedUserData(token, decodedToken?.sub)
      navigate(routeConstant.DASHBOARD);
    }).catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    })
  }

  const forgotOnSubmit = (data: any) => {
    setLoading(true);
    axios.post(`${FORGOTPASSWORD}?EmailAddress=${data.email}`).then(
      (response) => {
        setLoading(false);
        onResetCaptcha()
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          setMailSent(!mailSent);
          toast.success(LoginMessage.emailSent, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            setMailSent(!mailSent);
            navigate(routeConstant.DASHBOARD);
          }, 5000);
        }
      },
    ).catch((error) => {
      onResetCaptcha()
      setLoading(false);
      handleError(error);
    });
  };

  const resetOnSubmit = (data: any) => {
    setLoading(true);
    const payload = {
      emailAddress: getEmail,
      password: data.newPassword,
      token: token
    };
    axios.post(RESETPASSWORD, payload).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          toast.success(LoginMessage.changePasswordSuccessfully, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          navigate(routeConstant.SIGNIN);
        }
      },
    ).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleNewPassword = () => {
    if (newPassword === "password") {
      setNewPassword("text");
      return;
    }
    setNewPassword("password");
  };

  const toggleCnfNewPassword = () => {
    if (cnfNewPassword === "password") {
      setCnfNewPassword("text");
      return;
    }
    setCnfNewPassword("password");
  };

  const getLoader = (companyId?: string) => {
    axios.get(`${GETDEFAULTLOADERBLOB}?companyId=${companyId ?? ''}`).then((response) => {
      // if (localStorage.getItem('globalLoader') != response.data.data) {
      localStorage.setItem('globalLoader', response?.data?.data)
      // }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }

  useEffect(() => {
    const token: any = localStorage.getItem("token");
    if (token !== null) {
      try {
        jwt_decode(token);
        navigate(routeConstant.DASHBOARD);
      } catch (e) {
        navigate(routeConstant.SIGNIN);
      }
    }
    getLoader()
  }, []);

  useEffect(() => {
    onResetCaptcha()
    return () => { }
  }, [])

  const onCaptchaChange = (value: any) => {
    setCaptchaValue(value);
    setCaptchaValid(true);
  }

  const handleExpire = () => {
    onResetCaptcha()
  }

  const onResetCaptcha = () => {
    // Reset the reCAPTCHA component
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    setCaptchaValid(false);
    // Also reset the captcha value
    setCaptchaValue(null);
  };

  const getLoggedUserData = (token: any, LoggedUserId: any) => {
    setLoading(true)
    const config: any = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    };
    axios.get(GETBYIDUSINGBLOB, { ...config, params: { id: LoggedUserId } }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        const data = response.data?.data;
        dispatch(setLoggedUserData({ ...data, profilePicture: `${response.data?.data?.profilePicture ? `${data?.profilePicture}?${data?.photosWithSasToken}` : ""}` }))
        // setProfilePicture(`${process.env.REACT_APP_API_URL}${response.data?.data?.profilePicture}`)
      }
    }).catch((error) => {
      setLoading(true);
      handleError(error)
    })
  }

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div>
        <div className="main" id="top">
          <div className="container-fluid px-0" data-layout="container">
            <div className="row vh-100 g-0">
              <div className="col-lg-6 position-relative d-none d-lg-block">
                <div
                  className="bg-holder"
                  style={{ background: "../public/assets/img/bg/30.jpeg" }}
                ></div>
              </div>
              <div className="col-lg-6">
                <div className="bg-white row flex-center h-100 g-0 px-4 px-sm-0 pt-4 pb-4">
                  <div className="col col-sm-6 col-lg-7 col-xl-6">
                    {!mailSent && (
                      <a
                        className="d-flex flex-start text-decoration-none mb-4"
                        href="#"
                      >
                        <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                          <img
                            src="assets/img/icons/DPA-Auctions-Logo.svg"
                            alt="DPA Auction"
                            width="100"
                          />
                        </div>
                      </a>
                    )}
                    {(routeConstant.SIGNIN.split("/")[1] === url ||
                      url === "") && (
                        <>
                          <div className="text-start mb-4">
                            <h3 className="text-1000 text-start">
                              Login to your account
                            </h3>
                          </div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3 text-start login_icons">
                              <label className="form-label">
                                Email address <sup className="text-danger">*</sup>
                              </label>
                              <div className="form-icon-container">
                                <input
                                  className="form-control form-icon-input p-3 ps-6"
                                  id="email"
                                  type="email"
                                  placeholder="name@example.com"
                                  {...register("email")}
                                />
                                <span className="fas fa-user text-900  d-flex align-items-center fs--1 form-icon"></span>
                                {errors?.email && (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {errors?.email.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 text-start login_icons">
                              <label className="form-label">
                                Password <sup className="text-danger">*</sup>
                              </label>
                              <div className="form-icon-container for_password">
                                <input
                                  className="form-control form-icon-input p-3 ps-6"
                                  id="password"
                                  type={passwordType}
                                  placeholder="Password"
                                  {...register("password")}
                                />
                                <i
                                  onClick={togglePassword}
                                  className={
                                    passwordType == "password"
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                                <span className="fas fa-key text-900 d-flex align-items-center fs--1 form-icon "></span>
                              </div>
                              {errors?.password && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {errors?.password.message}
                                </span>
                              )}
                            </div>
                            <div className="row flex-between-center mb-7">
                              <div className="col-auto">
                                <div className="form-check auth mb-0">
                                  {/* <input
                                    className="form-check-input"
                                    id="basic-checkbox"
                                    type="checkbox"
                                  /> */}
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="basic-checkbox"
                                    checked={rememberMe}
                                    onChange={handleRememberMe}
                                  />
                                  <label
                                    className="form-check-label mb-0"
                                    htmlFor="basic-checkbox"
                                  >
                                    Remember me
                                  </label>
                                </div>
                              </div>
                              <div className="col-auto">
                                <Link
                                  className="fs--1 fw-semi-bold"
                                  to={routeConstant.FORGOTPASSWORD}
                                >
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className="mt-5">
                                <button
                                  className="btn btn-primary w-100 mb-3 pt-3 pb-3"
                                  type="submit"
                                  disabled={!isValid}
                                >
                                  Login Now
                                </button>
                              </div>
                              {/* <div className="position-relative">
                                <hr className="bg-200 mt-5 mb-5" />
                                <div className="divider-content-center">
                                  or use{" "}
                                </div>
                              </div>
                              <div className="col-12 text-center">
                                <a
                                  className="fs--1 btn"
                                  href="#"
                                  style={{
                                    color: "#fff",
                                    background: "rgb(69 165 235)",
                                  }}
                                >
                                  {" "}
                                  <span className="uil-microsoft"></span> Sign-in
                                  using Microsoft
                                </a>
                              </div> */}
                            </div>
                          </form>
                        </>
                      )}

                    {routeConstant.FORGOTPASSWORD.split("/")[1] === url && (
                      <>
                        <div className="text-start mb-6">
                          {!mailSent && (
                            <>
                              <div className="text-start mb-4">
                                <h3 className="text-1000">
                                  Forgot your password?
                                </h3>
                                <p className="text-700 fs-18">
                                  Enter your email below and we will send you a
                                  reset link
                                </p>
                              </div>
                              <form
                                className="d-flex align-items-center mt-5 mb-5"
                                onSubmit={forgotHandleSubmit(forgotOnSubmit)}
                              >
                                <div className="mb-3 col text-start login_icons">
                                  <label className="form-label" htmlFor="email">
                                    Email Address
                                  </label>
                                  <div className="form-icon-container d-flex flex-wrap align-items-center mb-4">
                                    <span className="d-flex align-items-center fas fa-envelope text-900 fs--1 form-icon"></span>
                                    <input
                                      className="form-control form-icon-input flex-1 p-3 ps-6"
                                      id="email"
                                      type="email"
                                      placeholder="Email"
                                      {...forgotRegister("email")}
                                    />
                                    <span className="fas fa-user text-900  d-flex align-items-center fs--1 form-icon"></span>
                                    <button
                                      className="btn btn-primary ms-2 p-3 mt-0 button_size"
                                      type="submit"
                                      disabled={!forgotIsValid || !captchaValid}
                                    >
                                      Send
                                      <span className="fas fa-chevron-right ms-2"></span>
                                    </button>

                                    {forgotError.email && (
                                      <span
                                        className="text-danger w-100 mt-1"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {forgotError?.email.message}
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    <ReCAPTCHA
                                      ref={recaptchaRef}
                                      sitekey="6LeNcy0nAAAAAD6qwdR0BvxwVo2Qmae3R3KtRFXN"
                                      onChange={onCaptchaChange}
                                      onExpired={handleExpire}
                                    />
                                  </div>
                                </div>
                              </form>
                              <div className="mt-3 text-center">
                                <Link
                                  className="fs--1 fw-bold"
                                  to={routeConstant.SIGNIN}
                                  onClick={() => {
                                    onResetCaptcha()
                                    reset()
                                  }}
                                >
                                  <i className="fas fa-arrow-left me-1"></i>{" "}
                                  Back to Login
                                </Link>
                              </div>
                            </>
                          )}
                          {mailSent && (
                            <div className="row justify-content-center">
                              <div className="col-xxl-10 text-center">
                                <img
                                  className="mw-75 m-auto"
                                  src={successIcon}
                                />
                                <h3 className="mb-4 mt-3">Email Sent!</h3>
                                <p className="fs-18">
                                  Go to your inbox, and email with a link to set
                                  your new password has been sent out just now.
                                  Make sure to check your spam folder too.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {routeConstant.RESETPASSWORD.split("/")[1] === url && (
                      <>
                        <div className="text-start mb-6">
                          <div className="text-start mb-4">
                            <h3 className="text-1000">Reset password</h3>
                            <p className="text-700 fs-20">
                              Type your new password
                            </p>
                          </div>
                          <form
                            className="mt-5"
                            onSubmit={resetHandleSubmit(resetOnSubmit)}
                          >
                            <div className="mb-3 text-start login_icons">
                              <label
                                className="form-label"
                                htmlFor="newPassword"
                              >
                                New Password
                              </label>
                              <div className="form-icon-container for_password">
                                <input
                                  className="form-control form-icon-input p-3 ps-6"
                                  id="newPassword"
                                  type={newPassword}
                                  placeholder="Type new password"
                                  {...resetRegister("newPassword")}
                                />
                                <i
                                  onClick={toggleNewPassword}
                                  className={
                                    newPassword === "password"
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                                <span className="fas fa-key text-900 fs--1 form-icon d-flex align-items-center"></span>
                              </div>
                              {resetError.newPassword && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {resetError.newPassword.message}
                                </span>
                              )}
                            </div>
                            <div className="mb-3 text-start login_icons">
                              <label
                                className="form-label"
                                htmlFor="confirmPassword"
                              >
                                Confirm New Password
                              </label>
                              <div className="form-icon-container for_password">
                                <input
                                  className="form-control form-icon-input p-3 ps-6"
                                  id="confirmPassword"
                                  type={cnfNewPassword}
                                  placeholder="Type confirm new password"
                                  {...resetRegister("confirmPassword")}
                                />
                                <i
                                  onClick={toggleCnfNewPassword}
                                  className={
                                    cnfNewPassword === "password"
                                      ? "fas fa-eye-slash"
                                      : "fas fa-eye"
                                  }
                                ></i>
                                <span className="fas fa-key text-900 fs--1 form-icon d-flex align-items-center"></span>
                              </div>
                              {resetError.confirmPassword && (<span className="text-danger" style={{ fontSize: "12px" }}>{resetError.confirmPassword.message}</span>)}
                              {cnfError && (<span className="text-danger" style={{ fontSize: "12px" }}>Password must match with new password.</span>)}
                            </div>
                            <button
                              className="btn btn-primary w-100 pt-3 pb-3"
                              type="submit"
                              disabled={!resetIsValid}
                            >
                              Update Password
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <input className="version" type="hidden" id="version" name="version" value="SP 34" disabled />

    </>
  );
};

export default Signin;
