import React, { useState, useEffect } from "react";
import logo from "../../assets/img/dpa-logo.jpg";
import { formatCurrency, parseHtml, removeHtmlTags } from "../../Utils/Utils";
import moment from "moment";
import watermark from "../../assets/bill_for_sale.png";
import { formatPhoneNumber } from "../../common/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { GETCOMMONVALUE } from "../../common/apiConstatnts";
import { errorMessages } from "../../common/messageConstants";
const PdfFormat = ({ invoiceList, download, view, invoiceData, footerData }: any) => {
  let classes = "a4 modal-fade hidden";
  let c = "for-pdf";
  let w = "100%";
  if (download === true) {
    c = "for-pdf";
    classes = "a4 modal-fade";
    w = "73%";
  }

  if (view === true) {
    c = "";
    classes = "a4 modal-fade";
    w = "100%";
  }
  const [paymentTypeData, setPaymentTypeData] = useState<any>([]);

  useEffect(() => {
    axios
      .get(
        `${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem(
          "companyId"
        )}`
      )
      .then(
        (response) => {
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setPaymentTypeData(response?.data?.data);
          }
        },
        (error) => {
          if (error?.response?.status !== 401) {
            toast.error(errorMessages.error401, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }
      );
  }, []);

  return (
    <>
      <div className={c}>
        <div id="pdfInvoice" className={classes} >
          {/* Header */}
          <div className="row">
            <div className="col-2">
              <div className="position-relative">
                <img src={logo} alt="DPA Invoice" width="120px" />
              </div>
            </div>
            <div className="col-7">
              <h3 className="fw-300">{invoiceData?.companyName}</h3>
              <h3 className="fw-300">{invoiceData?.auctionTitle}</h3>
              <p className="m-0">{invoiceData?.auctionAddress}</p>
              <p className="m-0">{invoiceData?.auctionCity}, {invoiceData?.auctionState} {invoiceData?.zipCode ?? ''}</p>
            </div>
            <div className="col-3 text-end">
              <h3 className="fw-300">Invoice #: {invoiceData?.invoiceNumber}</h3>
              <p className="m-0">{moment(invoiceData?.dateOfInvoice)
                .format('MMM DD, YYYY')
                .toString()}</p>
              <p className="m-0 fw-bold"> Auction #: {invoiceData?.auctionId}</p>
              <p className="m-0 fw-bold">Bidder #: {invoiceData?.bidderNumber}</p>
              <p className="m-0 fw-bolder">
                Verification Code:
              </p>
              <p className="m-0">
                {invoiceData?.invoicePaid == '1' ? (
                  <h5 className="mt-1">
                    {invoiceData?.verificationCode ?? '-'}
                  </h5>
                ) : (
                  <h3 className="mt-1" style={{ color: '#8B0000' }}>Not Paid <br /> In Full</h3>
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <p className="m-0">{invoiceData?.customerName} (Bidder: {invoiceData?.bidderNumber})</p>
            <p className="m-0">{invoiceData?.customerAddress}</p>
            <p className="m-0">{invoiceData?.customerCity} {invoiceData?.customerState}</p>
            <p className="m-0">{invoiceData?.customerCountry} {invoiceData?.customerState}</p>
            <p className="m-0">
              {invoiceData?.customerMobile
                ? formatPhoneNumber(invoiceData?.customerMobile)
                : ""}
            </p>
            <p className="m-0">{invoiceData?.customerEmail}</p>
          </div>
          <table
            width={w}
            cellSpacing="0"
            cellPadding="0"
            className="ctable table_collap_a mt-5"
          >
            <thead>
              <tr>
                <th align="right" className="fs-12 pd-5 text-end">
                  Lot #
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Type
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Price
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Qty
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Extended Amt
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Inv.Adjust
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Buyers Prem
                </th>
                <th align="right" className="fs-12 pd-5 text-end">
                  Totals
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceList?.map((invoiceData: any, index: any) => {

              const itemContact = invoiceData.itemContact || '';
              const trimmedContact = itemContact.trim();
              const [name, phoneNumber] = trimmedContact.split(',').map((part:any) => part.trim());

              const formattedPhoneNumber = phoneNumber ? formatPhoneNumber(phoneNumber) : '';

              const displayContact = phoneNumber
              ? (name ? `${name}, ${formattedPhoneNumber}` : formattedPhoneNumber)
              : itemContact;

                return (
                  <>
                    <tr key={index}>
                      <td align="left" className="fs-12 pd-5">
                        {invoiceData?.lotNo}
                      </td>
                      <td align="left" className="fs-12 pd-5">
                        {invoiceData?.type}
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency(invoiceData?.price || 0)}
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        1
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency(invoiceData?.price || 0)}
                      </td>
                      <td align="left" className="fs-12 pd-5">

                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency(invoiceData?.buyersPrice || 0)}
                      </td>

                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency(invoiceData?.price + invoiceData.buyersPrice)}
                      </td>
                    </tr>
                    <tr key={index}>
                      <td align="right" colSpan={3} className="fs-12 pd-5">
                        Taxes & Rate
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {invoiceData?.taxrate ? invoiceData?.taxrate : 0}%
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency((invoiceData?.price * invoiceData?.taxrate) / 100)}
                      </td>
                      <td align="center" className="fs-12 pd-5">
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency((invoiceData?.buyersPrice * invoiceData?.taxrate) / 100)}
                      </td>
                      <td align="right" className="fs-12 pd-5">
                        {formatCurrency((invoiceData.price + invoiceData.buyersPrice) * invoiceData?.taxrate / 100)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={8} className="fs-12 pd-5">
                        {removeHtmlTags(invoiceData.title)}
                        <br />
                        Item Contact: {displayContact}
                        <br />
                        Item Location: {invoiceData?.strCity || ''} {invoiceData?.strState || ''}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          {/* Buyer's premium */}
          <table
            width={w}
            cellSpacing="0"
            cellPadding="0"
            className="ctable table_collap_a mt-5"
          >
            <tbody>
              <tr>
                <td align="left" className="fs-12 pd-5" colSpan={4}>
                  * Buyer's Premium Applied
                </td>
                <td align="left" className="fs-12 pd-5 text-end">
                  * Extended Amt
                </td>
                <td align="left" className="fs-12 pd-5 text-end">
                  Inv.Adjust
                </td>
                <td align="left" className="fs-12 pd-5 text-end">
                  Buyers Prem
                </td>
                <td align="left" className="fs-12 pd-5 text-end">
                  Totals
                </td>
              </tr>
              <tr>
                <td align="center" className="fs-12 pd-5" colSpan={3} rowSpan={2}>
                  <h3>Invoice Totals</h3>
                </td>
                <td align="left" className="fs-12 pd-5">
                  Items
                </td>
                <td align="right" className="fs-12 pd-5 ">
                  {/* Items */}
                  {formatCurrency(invoiceData?.itemTotal || 0)}
                </td>
                <td align="right" className="fs-12 pd-5">

                </td>
                <td align="right" className="fs-12 pd-5">
                  {formatCurrency(invoiceData?.totalBuyerPremium || 0)}
                </td>
                <td align="right" className="fs-12 pd-5">
                  {formatCurrency(invoiceData?.subTotal || 0)}
                </td>
              </tr>
              <tr>
                <td align="left" className="fs-12 pd-5">
                  Taxes
                </td>
                <td align="right" className="fs-12 pd-5">
                  {formatCurrency(invoiceData?.taxableItemTotal || 0)}
                </td>
                <td align="right" className="fs-12 pd-5">
                </td>
                <td align="right" className="fs-12 pd-5">
                  {formatCurrency(invoiceData?.taxablebuyersPremium || 0)}
                </td>
                <td align="right" className="fs-12 pd-5">
                  {formatCurrency(invoiceData?.taxabletotalTax || 0)}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-4">
              <table cellSpacing="0" cellPadding="0" className="ctable table_collap_a mt-1 w-100">
                <tbody>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Invoice Adjustments
                    </td>
                  </tr>
                  {invoiceData?.lstInvoiceAdjust?.lenght > 0 ? invoiceData?.lstInvoiceAdjust?.map((invoiceData: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td align="left" className="fs-12 pd-5">
                            {formatCurrency(invoiceData.amount || 0)}
                          </td>
                          <td align="left" className="fs-12 pd-5">
                            {invoiceData.description}
                          </td>
                        </tr>
                      </>
                    )
                  })
                    :
                    <>
                      <tr>
                        <td align="left" className="fs-12 pd-5" colSpan={2}>
                        </td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>
            <div className="col-md-4">
              <table cellSpacing="0" cellPadding="0" className="ctable table_collap_a mt-1 w-100">
                <tr>
                  <td align="left" className="fs-12 pd-5">
                    Date
                  </td>
                  <td align="left" className="fs-12 pd-5">
                    Payments
                  </td>
                  <td align="left" className="fs-12 pd-5">
                    Type
                  </td>
                  <td align="left" className="fs-12 pd-5">
                    BP %
                  </td>
                  <td align="left" className="fs-12 pd-5">
                    Conv. Fee
                  </td>
                </tr>
                {invoiceData?.lstInvoicePayment?.map((invoiceData: any, index: any) => {
                  const payType = paymentTypeData.find(
                    (type: any) => type?.objectValueId === invoiceData?.payType
                  )?.objectTypeValue || "-";
                  return (
                    <>
                      <tr>
                        <td align="left" className="fs-12 pd-5">
                          {moment(
                            invoiceData?.date,
                          ).format("MM/DD/YYYY")}
                        </td>
                        <td align="left" className="fs-12 pd-5">
                          {formatCurrency(invoiceData?.amountPaid || 0)}
                        </td>
                        <td align="left" className="fs-12 pd-5">
                          {payType}
                        </td>
                        <td align="left" className="fs-12 pd-5">
                          {formatCurrency(invoiceData?.buyerPremium || 0)}
                        </td>
                        <td align="left" className="fs-12 pd-5">
                          {formatCurrency(invoiceData?.convenienceCharge || 0)}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </table>
            </div>
            <div className="col-md-4">
              <table cellSpacing="0" cellPadding="0" className="ctable table_collap_a mt-1 w-100">
                <tbody>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Adjustments
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      {invoiceData?.invoiceAdjustment < 0 ? `(${formatCurrency(Math.abs(invoiceData?.invoiceAdjustment))})` : formatCurrency(invoiceData?.invoiceAdjustment)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Shipping
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>

                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Packing
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>

                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Invoice Total
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      {formatCurrency(invoiceData?.invoiceTotal || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Deposit
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      {formatCurrency(invoiceData?.depositePaid || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Payments
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      {formatCurrency(invoiceData?.invoicePayment || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Balance Due
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      {invoiceData?.balanceDue < 0 ? `(${formatCurrency(Math.abs(invoiceData?.balanceDue))})` : formatCurrency(invoiceData?.balanceDue)}
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Conv Fee
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Bal Due+Conv Fee
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                      Change
                    </td>
                    <td align="left" className="fs-12 pd-5" colSpan={2}>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row m-2">
            {invoiceData?.notes && parseHtml(invoiceData?.notes)}
            {footerData && parseHtml(footerData)}
          </div>
        </div>
      </div>
    </>
  );
};
export default PdfFormat;
