import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { sidebarData } from "../../common/constants";
import toggleOpen from "../../assets/sidebar-icons/toggal_open.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarOpen } from "../../store/actions/CommonAction";

const Sidebar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const url = window.location.pathname.split('/')[1]
    const childUrl = window.location.pathname.split('/')[2]
    const [sidebarStatus, setSidebarStatus] = useState<any>(false)
    const [activeIndex, setActiveIndex] = useState<any>(null)
    const [childToggle, setChildToggle] = useState<any>(localStorage.getItem('childToggle'))
    const themeColor = useSelector((state: any) => state?.theme?.themeData.themeColor)
    const sidebarRef = useRef(null);  // Reference to the sidebar container

    useEffect(() => {
        localStorage.setItem('sidebarOpen', 'false')
        dispatch(setSidebarOpen(false))
    }, [])

    const handleSideToggle = () => {
        if (localStorage.getItem('sidebarOpen') === 'true') {
            localStorage.setItem('sidebarOpen', 'false')
            setSidebarStatus(false)
            dispatch(setSidebarOpen(false))
            return
        }
        localStorage.setItem('sidebarOpen', 'true')
        setSidebarStatus(true)
        dispatch(setSidebarOpen(true))
    }

    const handleNavigation = (url: string) => {
        if (url !== '#') {
            navigate(url)
            return
        }
    }

    const scrollToView = (element: any) => {
        // if (element && sidebarRef.current) {
        //     setTimeout(() => {
        //         element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        //     }, 500);
        // }
    }

    useEffect(() => {
        if (localStorage.getItem('ParentIndex') == String(sidebarData?.findIndex((i) => i.label == 'Admin Settings'))) {
            setActiveIndex(sidebarData?.findIndex((i) => i.label == 'Admin Settings'))
            return
        }
        return () => { }
    }, [])

    return (
        <div className={`${sidebarStatus ? 'navbar-vertical-collapsed' : ''}`}>
            <nav className="navbar navbar-vertical navbar-expand-lg scrollbar">
                <div className="navbar-vertical-footer border-right border-bottom">
                    <button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center ps-3" onClick={handleSideToggle}>
                        <span className="uil uil-left-arrow-to-left fs-0"></span>
                        <span className="uil uil-arrow-from-right fs-0"><img src={toggleOpen} className="svgImg" alt="" /></span>
                    </button>
                </div>
                <div className={`collapse navbar-collapse`} id="navbarVerticalCollapse">
                    <div className="navbar-vertical-content" ref={sidebarRef}>
                        <ul className="navbar-nav flex-column" id="navbarVerticalNav">
                            {
                                sidebarData.map((sidebar, index) => {
                                    const isActive = (activeIndex === index);
                                    return (
                                        <li className={`nav-item ${isActive ? 'active' : ''} ${sidebar.childData.length > 0 ? 'submenu' : ''}`} key={index}>
                                            <div
                                                className={`nav-item-wrapper ${url === sidebar.slug ? "current_page" : ""} ${sidebar.childData.length > 0 ? 'submenu' : ''}`}
                                                onClick={() => handleNavigation(sidebar.url ?? '#')}
                                                ref={isActive ? scrollToView : null}
                                            >
                                                <Link
                                                    to={sidebar.url}
                                                    className="nav-link dropdown-indicator label-1"
                                                    onClick={() => {
                                                        if (activeIndex === index) {
                                                            setActiveIndex(null)
                                                            localStorage.setItem('ParentIndex', `null`)
                                                            localStorage.setItem('childToggle', `null`)
                                                            return
                                                        }
                                                        setActiveIndex(index)
                                                        localStorage.setItem('ParentIndex', `null`)
                                                        localStorage.setItem('childToggle', `null`)
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center overflow-hidden">
                                                        <span className="nav-link-icon">
                                                            <sidebar.icon fill={url === sidebar.slug ? themeColor : "#525b75"} />
                                                        </span>
                                                        <span className={`nav-link-text w-100 ${url === sidebar.slug ? "text-primary" : ""}`} >{sidebar.label}</span>
                                                        {sidebar.childData.length > 0 && (
                                                            <>
                                                                <div className="dropdown-indicator-icon">
                                                                    <span className="fas fa-angle-right">  </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </Link>

                                                {sidebar?.childData?.length > 0 && <div className="parent-wrapper label-1">
                                                    <ul className="nav collapse parent scrollbar" data-bs-parent="#navbarVerticalCollapse">
                                                        <li className="collapsed-nav-item-title d-none">{sidebar.childTitle}</li>
                                                        {(sidebar.childData?.[0]?.subChild !== undefined) && sidebar.childData.map((sideChild, childIndex) => {
                                                            const isChildActive = (childToggle === String(childIndex));
                                                            return (
                                                                <li className={`nav-item ${isChildActive ? 'subchild-active' : ''}`} key={childIndex}>
                                                                    <Link
                                                                        to={'#'}
                                                                        onClick={() => {
                                                                            if (isChildActive) {
                                                                                setChildToggle(null)
                                                                                localStorage.setItem('childToggle', `null`)
                                                                                return
                                                                            }
                                                                            localStorage.setItem('ParentIndex', `${index}`)
                                                                            setChildToggle(String(childIndex))
                                                                            localStorage.setItem('childToggle', `${childIndex}`)
                                                                        }}
                                                                    >
                                                                        <div className="d-flex align-items-center nav-link" ref={isChildActive ? scrollToView : null}>
                                                                            <span className="nav-link-text w-100">{sideChild.label}</span>
                                                                            <div className="dropdown-indicator-icon">
                                                                                <span className="fas fa-angle-right">  </span>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <div className="parent-wrapper">
                                                                        <ul className="nav parent">
                                                                            {sideChild.subChild?.map((subChildData, subChildIndex) => {
                                                                                return (
                                                                                    <li className={`nav-item ${subChildData.url?.split('/')[2] === childUrl ? 'subchildoption-active' : ''}`} key={subChildIndex}>
                                                                                        <Link to={subChildData.url} className="nav-link">
                                                                                            <div className="d-flex align-items-center"><span className="nav-link-text w-100">{subChildData.childLabel}</span></div>
                                                                                        </Link>
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}

                                                        {(sidebar.childData?.[0]?.subChild === undefined) && sidebar.childData.map((sideChild, childIndex) => {
                                                            return (
                                                                <li className={`nav-item ${(sideChild.url?.split('/')[2] === childUrl && childUrl !== undefined) ? 'active' : ''}`} key={childIndex}>
                                                                    <Link to={sideChild.url ?? ''} className="nav-link" data-bs-toggle="" aria-expanded="false">
                                                                        <div className="d-flex align-items-center"><span className="nav-link-text w-100">{sideChild.label}</span></div>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Sidebar