import * as actions from '../../common/types'
import { Action } from '../../common/interface'

export interface SalesRecordState {
    absBids: []
    shipping: {}
    salesRecord: {}
}

const initialState: SalesRecordState = {
    absBids: [],
    shipping: {},
    salesRecord: {}
};

export const SalesRecordReducer = (
    state: SalesRecordState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.ADDADJBIDS:
            return {
                ...state,
                absBids: [...state.absBids, action.payload],
            };
   
        case actions.DELETEADJBIDS:
            return {
                ...state,
                absBids: state.absBids.filter((entry: any) => entry.absBidsUniqueId !== action.payload),
            };
        case actions.UPDATEADJBIDS:
            return {
                ...state,
                absBids: state.absBids.map((data: any) =>
                    data.absBidsUniqueId === action.payload.absBidsUniqueId ? action.payload.updatedData : data
                ),
            };
        case actions.SETADJBIDSFROMRESP: {
            return { ...state, absBids: action.payload }
        }
        case actions.SHIPPINGDATA: {
            return { ...state, shipping: action.payload }
        }
        case actions.UPDATESHIPPINGDATA: {
            const { name, value } = action.payload;
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    [name]: value
                }
            };
        }
        default:
            return state;
    }
};