import axios from "axios";
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import toggleOpen from "../../assets/sidebar-icons/toggal_open.svg";
import team57 from "../../assets/userProfile.svg";
import {
  GETALLAUCTIONLIST,
  GETNOTIFICATIONS,
  MARKALLASREADNOTIFCATION,
  MARKASREADNOTIFCATION,
} from "../../common/apiConstatnts";
import { SearchReportsData } from "../../common/constants";
import { HeaderMessages } from "../../common/messageConstants";
import * as routeConsant from "../../common/routeConstants";
import {
  setAuctionHammerData,
  setLoggedUserData,
  setNotificationCount,
  setNotifications,
  setRedirectedtoHeader,
} from "../../store/actions/CommonAction";
import ThemeChanger from "./ThemeChanger";
import { Button, Offcanvas } from "react-bootstrap";
import HeaderInfo from "./HeaderInfo";
import Select from 'react-select';
import { fetchInitialDateTime } from "../../Utils/useDateTime";
import { selectAuction as sellerSelectAuction } from "../../store/actions/Seller";
import { selectAuction as salesRepSelectAuction } from "../../store/actions/SalesRepAction";
import handleError from "../../Utils/ApiCommonErrorMessages";
import * as actions from "../../common/types";
import { setAuctionDataToStore } from "../../store/actions/Inventory";
import ImageWithFallback from "../../common/ImageWithFallback";
import { setAuctionCodeAndTitle } from "../../store/actions/AuctionAction";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logo: any = localStorage.getItem("logo");
  const token: any = localStorage.getItem("token");
  const getUrl = window.location.pathname;
  const getEditUrl = getUrl.includes('/auctions/edit-auction') || getUrl.includes('/inventory/update-inventory')
  const decodedToken: any = jwt_decode(token);
  const LoggedUserId: any = decodedToken?.sub;
  const baseUrl = process.env.REACT_APP_API_URL;
  const notifications = useSelector((state: any) => state.global.notifications);
  const notificationCount = useSelector(
    (state: any) => state.global.notificationCount
  );


  const auctionlistOptionsSalesRep = useSelector((state: any) => state.salesRep.salesRepAuctions)
  const auctionlistOptionsSeller = useSelector((state: any) => state.seller.sellerAuctions);
  const selectedAuction = useSelector((state: any) => state.seller.selectedAuction);
  const userData = useSelector((state: any) => state.global.loggedUserData);
  const [count, setCount] = useState(0);
  const [list, setList] = useState<any[]>(notifications);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportLinks, setReportLinks] = useState<any>(SearchReportsData);
  const [searchText, setSearchText] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);
  const [auction, setAuction]: any = useState({});
  const [auctionList, setAuctionList] = useState<any>([]);
  const auctionSelectedFromHammer = useSelector((state: any) => state?.global?.auctionHammerData);
  const AuctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);
  const AuctionID = useSelector<any>((state: any) => state?.auction?.auctionId);
  const showAuctionEditDetails = AuctionDetail && Object?.keys(AuctionDetail)?.length > 0 ? `${AuctionDetail?.auctionCode} - ${AuctionDetail?.auctionTitle}` : "";
  // const showAuctionEditDetails = Object?.keys(AuctionDetail)?.length > 0 ? `${AuctionDetail?.auctionCode} - ${AuctionDetail?.auctionTitle}` : "";
  const AuctionSelectInventory = useSelector((state: any) => state?.inventory?.auctionData);
  const auctionIdInventory = AuctionSelectInventory?.auctionId;
  const showAuctionEditInventory = AuctionSelectInventory?.auctionId > 0 ? (AuctionSelectInventory?.auctionCode + "-" + AuctionSelectInventory?.auctionTitle) : "";
  const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);

  useEffect(() => {
    handleVisibilityChange();
    //commented because using redux now  instead of localstorage;
    // const auctionId: any = localStorage.getItem("auctionId");
    // const auctionTitle: any = localStorage.getItem("auctionTitle");
    const auctionId: any = getGlobalAuction?.value;
    const auctionTitle: any = getGlobalAuction?.title;
    setAuction({
      value: auctionId,
      label: auctionTitle,
    });
  }, []);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const handleLogOut = () => {
    localStorage.removeItem("sidebarOpen");
    localStorage.removeItem("logo");
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
    localStorage.removeItem("permission");
    dispatch(setAuctionHammerData({}));
    dispatch(sellerSelectAuction({}));
    dispatch(setLoggedUserData({}));
    dispatch(salesRepSelectAuction({}));
    dispatch({ type: actions.RESETAUCTION });
    navigate(routeConsant.SIGNIN);
  };

  const markAsRead = (notificationId: any, readStatus: boolean) => {
    setLoading(true);
    axios
      .post(`${MARKASREADNOTIFCATION}?notificationId=${notificationId}&isRead=${!readStatus}`)
      .then((response) => {
        callNotificationAPI();
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  const markAllasRead = () => {
    setLoading(true);
    axios.post(`${MARKALLASREADNOTIFCATION}?isRead=true`).then(
      (response) => {
        callNotificationAPI();
        setLoading(false);
        if (response?.data?.success === "Fail") {
          return;
        } else {
          toast.success(HeaderMessages.notificationMessage, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1200,
          });
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  const handleDownload = (
    e: any,
    filePath: any,
    notificationId: any,
    readStatus: boolean
  ) => {
    e.stopPropagation();
    const downloadUrl = filePath;
    const fileName = filePath?.split("\\")[filePath?.split("\\").length - 1];
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = fileName;
    anchor.click();
    markAsRead(notificationId, readStatus);
  };

  const callNotificationAPI = () => {
    // setLoading(true);
    axios.get(GETNOTIFICATIONS).then(
      (response) => {
        // setLoading(false);
        if (response?.data?.success === "Fail") {
          dispatch(setNotifications([]));
          dispatch(setNotificationCount(0));
          return;
        } else {
          let notificationData = response.data.data;
          const unreadCount = notificationData?.filter(
            (nData: any) => nData.isRead === false
          );
          notificationData = notificationData?.map((nData: any) => {
            const respPath = nData?.responseFilePath;
            const path = respPath?.split("Asset")[1];
            return { ...nData, responseFilePath: `${baseUrl}/Asset${path}` };
          });
          dispatch(setNotifications(notificationData));
          dispatch(setNotificationCount(unreadCount?.length));
        }
      }).catch((error) => {
        // setLoading(false);
        handleError(error);
      });
  };

  const handleReportSearch = (e: any) => {
    if (e == "") {
      setReportLinks(SearchReportsData);
      return;
    }
    const keyword = e?.toLocaleLowerCase();
    const filterData: any = SearchReportsData?.filter((x: any) =>
      x.label?.toLocaleLowerCase()?.includes(keyword)
    );
    setReportLinks(filterData);
  };

  const getAllAuctions = () => {
    setLoading(true);
    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      setLoading(false);
      let auctionData = []
      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            title: lData.auctionTitle,
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
            auctionStatus: lData.auctionStatus
          };
        });
      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    }).catch((error) => {
      setLoading(false);
      handleError(error)
    });
  };



  // Function to be executed when the user comes back to the tab
  function onTabVisibilityChange() {
    if (!document.hidden) {
      // Tab is visible, execute your function here
      fetchInitialDateTime();
    }
  }

  // Function to be called when the tab visibility changes
  const handleVisibilityChange = () => {
    document.addEventListener("visibilitychange", onTabVisibilityChange);
  }

  useEffect(() => {
    setList(notifications);
  }, [notifications]);

  useEffect(() => {
    setCount(typeof notificationCount == "undefined" ? 0 : notificationCount);
  }, [notificationCount]);

  useEffect(() => {
    getAllAuctions()
    const delayInMilliseconds = 25000; // 15 secs
    const timerId = setInterval(callNotificationAPI, delayInMilliseconds);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    handleReportSearch(searchText);
  }, [searchText]);

  const auctionHammerData = (e: any) => {
    // Check if the URL indicates edit mode for auctions or inventory
    const isInEditMode = getUrl.includes('/auctions/edit-auction');
    const isInInventoryEdit = getUrl.includes('/inventory/update-inventory');

    // Initialize variables for SweetAlert configuration
    let title = '';
    let text = '';
    let route = '';

    // Determine SweetAlert configuration based on conditions
    if (isInEditMode && e?.value !== AuctionID) {
      // If in auction edit mode and changes are detected
      title = 'Auction change identified';
      text = 'Do you want to discard the changes on the current page? You will be redirected to the listing page. Please confirm.';
      route = routeConsant?.AUCTION; // Route to auction listing page
    } else if (isInInventoryEdit && e?.value !== auctionIdInventory) {
      // If in inventory edit mode and changes are detected
      title = 'Inventory change identified';
      text = 'Do you want to discard the changes on the current page? You will be redirected to the listing page. Please confirm.';
      route = routeConsant?.INVENTORY; // Route to inventory listing page
    } else {
      // If no mode matches or no changes detected, proceed with setting the auction data
      setAuction(e);
      return;
    }

    // Show SweetAlert dialog with the determined title, text, and buttons
    Swal.fire({
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonColor: '#006800',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Ok'
    }).then((result) => {
      // Handle user interaction with the dialog
      if (result.isConfirmed) {
        // If user clicks Ok, navigate to the determined route
        handleAuctionSubmit(e, true);
        navigate(route);
      } else {
        // If user cancels or closes the dialog, handle accordingly
        setShow(false); // Example function call, handle as per your application logic
      }
    });
  }

  const handleAuctionSubmit = (e: any, isFromHammer: boolean = false) => {
    const commonvalue: any = isFromHammer ? e : auction;

    const matchesSeller = auctionlistOptionsSeller?.filter((option: any) => {
      return option?.value == commonvalue?.value;
    });
    const matchesSalesRep = auctionlistOptionsSalesRep?.filter((option: any) => {
      return option?.value == commonvalue?.value;
    });

    if (commonvalue) {
      dispatch(setAuctionHammerData(commonvalue));
      dispatch(sellerSelectAuction(matchesSeller?.length > 0 ? commonvalue : selectedAuction));// when user apply hammer and go to seller auction info select auction
      dispatch(salesRepSelectAuction(matchesSalesRep?.length > 0 ? commonvalue : selectedAuction));// when user apply hammer and go to sales rep auction info select auction
      dispatch(setAuctionCodeAndTitle({}))
      window.dispatchEvent(new Event("storage")); // Trigger storage event
      toast.success("Auction set as default auction !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setShow(false);
    }
  };

  const handleRemoveAuction = () => {
    setAuction(null);
    dispatch(setAuctionHammerData({}));
    dispatch(sellerSelectAuction({}));
    dispatch(salesRepSelectAuction({}));
    dispatch({ type: actions.RESETAUCTION });
    window.dispatchEvent(new Event("storage")); // Trigger storage event
    toast.success("Auction is removed as default auction !", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
    setShow(false);
  };


  useEffect(() => {
    if (!getUrl.includes('/inventory/update-inventory')) {
      dispatch(setAuctionDataToStore({}));
    }
  }, [window.location]);

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div className="container-fluid px-0" data-layout="container">
        <div className="pt-9">
          <nav
            className="navbar navbar-top navbar-expand fixed-top margin-left-4"
            id="navbarDefault"
          >
            <div className="collapse navbar-collapse justify-content-between">
              <div className="navbar-logo">
                <button
                  className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarVerticalCollapse"
                  aria-controls="navbarVerticalCollapse"
                  aria-expanded="false"
                  aria-label="Toggle Navigation"
                >
                  <img src={toggleOpen} className="svgImg" />
                </button>
                <button className="navbar-brand me-1 me-sm-3 border-0 p-0">
                  <div className="d-flex align-items-center">
                    <Link to={routeConsant.DASHBOARD}>
                      <div className="d-flex align-items-center">
                        <img src={logo} alt="dpa" width="auto" height="50" />
                      </div>
                    </Link>
                  </div>
                </button>
              </div>

              <ul className="navbar-nav navbar-nav-icons flex-row ms-auto align-items-center">
                <div className="show-quickview d-flex flex-wrap align-items-center me-3 mt-1">

                  <h5>
                    <span className="fw-bold text-primary badge badge-dpa status-sold" >
                      {auctionSelectedFromHammer?.value && (
                        <>
                          {auctionSelectedFromHammer?.label}
                        </>
                      )}
                      {!auctionSelectedFromHammer?.value && showAuctionEditDetails && (
                        <>
                          {showAuctionEditDetails}
                        </>
                      )}
                      {!auctionSelectedFromHammer?.value && !showAuctionEditDetails && showAuctionEditInventory && (
                        <>
                          {showAuctionEditInventory}
                        </>
                      )}
                      {!auctionSelectedFromHammer?.value && !showAuctionEditDetails && !showAuctionEditInventory && (
                        <>
                          {""}
                        </>
                      )}
                    </span>
                  </h5>



                </div>
                <li className="show-quickview d-flex flex-wrap align-items-center me-1">
                  <Button
                    onClick={() => setShow(true)}
                    className="first-step notification-icon menu-icon text-dark bg-200 border border-300 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0 position-relative"
                  >
                    <i title="Select Auction">
                      <span className="notification-icon menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0 position-relative">
                        <i className="fa-solid fa-gavel"></i>
                      </span>
                    </i>
                  </Button>
                  <Offcanvas
                    show={show}
                    onHide={() => setShow(false)}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Auction Selection</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <label className="form-label">
                        We’re excited to introduce a new feature that allows you
                        to select an AUCTION and have its effects reflected
                        across all pages.<br></br><br></br>This means that once you
                        choose an auction, its impact will be visible throughout
                        your entire experience on our platform<br></br><br></br>
                      </label>
                      <form>

                        <Select
                          classNamePrefix="dpaSelect"
                          placeholder="Select Auction"
                          className="isActive swap-to-lot-menu clone-inventory"
                          options={auctionList}
                          // isDisabled={getEditUrl}
                          filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                          value={auctionList.find((option: any) => option.value == auction?.value)}
                          onChange={(e: any) => {
                            // setAuction(e);
                            auctionHammerData(e)
                          }}
                        />

                        <div className="mt-3">
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            // onClick={handleAuctionSubmit}
                            onClick={(e: any) => { handleAuctionSubmit(e) }}
                            disabled={auction?.value == null || auction?.value == ''}
                          >
                            Go and Set as default
                          </button>
                          <button
                            className="btn btn-outline-danger mx-2"
                            type="button"
                            disabled={auction?.value == null || auction?.value == ''}
                            onClick={handleRemoveAuction}
                          >
                            Remove Selected
                          </button>
                        </div>
                      </form>
                    </Offcanvas.Body>
                  </Offcanvas>
                </li>
                <li>
                  <Dropdown className="custom-dropdown notification-menu">
                    <Dropdown.Toggle
                      className="px-1 dropdown-caret-none"
                      variant="none"
                    >
                      <i title="Notifications">
                        <span className="notification-icon menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0 position-relative">
                          <i className="fas fa-bell"></i>
                          {count !== undefined && count == 0 ? (
                            <></>
                          ) : (
                            <span className="notification-count">{count}</span>
                          )}
                        </span>
                      </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      variant="none"
                      className={`p-0 dropdown-menu-end navbar-dropdown-caret dropdown-profile shadow border border-300`}
                    >
                      <div className="card position-relative border-0">
                        {/* <Dropdown.Header>Notifications (<span>12</span>)</Dropdown.Header> */}
                        <Dropdown.Header className="flex justify-content-between border-bottom">
                          <span>Notifications</span>
                          {list?.length > 0 && (
                            <button
                              className="btn btn-outline-primary btn-sm p-1 fs-11"
                              onClick={markAllasRead}
                            >
                              Mark All As Read
                            </button>
                          )}
                        </Dropdown.Header>
                        <ul className="nav scrollbar">
                          {list?.length > 0 ? (
                            list?.map((nList: any, index: any) => {
                              return (
                                <li
                                  className={`nav-item ${!nList.isRead ? "unread" : ""
                                    }`}
                                  key={index}
                                >
                                  <span
                                    onClick={() =>
                                      markAsRead(
                                        nList.notificationId,
                                        nList.isRead
                                      )
                                    }
                                    className={`btn nav-link justify-content-between`}
                                  >
                                    <span>
                                      <span className="text-start">
                                        {nList.status
                                          ? nList.module === "Lot Import" ? "Lots imported successfully" :
                                            nList.module === "Invoice" ? "Auction run process completed successfully" :
                                              nList.module === "Send Email" ? "Email sending process completed" : ""
                                          : ""
                                        }
                                      </span>
                                      <span className="fs-12 text-muted text-muted text-start">
                                        {nList.module}
                                      </span>
                                    </span>
                                    {/* {nList.module === "Lot Import" && (
                                      <button
                                        onClick={(e) =>
                                          handleDownload(
                                            e,
                                            nList.responseFilePath,
                                            nList.notificationId,
                                            nList.isRead
                                          )
                                        }
                                        className="download-btn fs-20 btn p-1"
                                        type="button"
                                      >
                                        <i className="fa-solid fa-download"></i>
                                      </button>
                                    )}  */}
                                  </span>
                                </li>
                              );
                            })
                          ) : (
                            <li className="text-center">No Notifications</li>
                          )}
                        </ul>
                        {list?.length > 4 && (
                          <div className="dropdown-footer border-top">
                            <a href="#!"> Load More</a>
                          </div>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* {reports section remove from header} */}
                {/* <li>
                  <Dropdown className="custom-dropdown notification-menu">
                    <Dropdown.Toggle
                      className="px-1 dropdown-caret-none"
                      variant="none"
                    >
                      <i title="Reports">
                        <span className="notification-icon menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0 position-relative">
                          <i className="fa-solid fa-chart-column"></i>
                        </span>
                      </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      variant="none"
                      className={`p-0 dropdown-menu-end navbar-dropdown-caret dropdown-profile shadow border border-300`}
                    >
                      <div className="card position-relative border-0">
                        <Dropdown.Header className="flex flex-wrap border-bottom h-auto p-3">
                          <span>
                            <i className="fa-solid fa-chart-column"></i> Reports
                          </span>
                          <div className="search-box w-100 mt-2">
                            <div className="d-flex align-items-center w-100">
                              <div
                                className="position-relative d-flex align-items-center w-100"
                                data-bs-toggle="search"
                                data-bs-display="static"
                              >
                                <input
                                  className="form-control search-input search form-control-sm"
                                  maxLength={60}
                                  type="text"
                                  placeholder="Search"
                                  aria-label="Search"
                                  value={searchText}
                                  onChange={(e: any) =>
                                    setSearchText(e.target.value)
                                  }
                                />
                                <span className="fas fa-search search-box-icon"></span>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Header>
                        {searchText ? (
                          <ul className="nav d-flex flex-column overflow-auto scrollbar ps-2 m-0">
                            {reportLinks.length ? (
                              reportLinks.map((cdata: any) => (
                                <li className="nav-item">
                                  <Link
                                    className="nav-link d-flex align-items-center pt-2 pb-2"
                                    to={cdata?.url}
                                  >
                                    {cdata?.label}
                                  </Link>
                                </li>
                              ))
                            ) : (
                              <li className="">
                                  <p className="text-center mt-3">
                                  not found
                                </p>
                              </li>
                            )}
                          </ul>
                        ) : (
                          <Accordion defaultActiveKey="0">
                            {ReportsLinkData.map((data: any, index: any) => (
                              <Accordion.Item eventKey={index}>
                                <Accordion.Header>
                                  {data.label}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ul className="nav d-flex flex-column overflow-auto scrollbar mb-2 pb-1">
                                    {data.child.map((cdata: any) => (
                                      <li className="nav-item">
                                        <Link
                                          className="nav-link d-flex align-items-center pt-2 pb-2"
                                          to={cdata?.url}
                                        >
                                          {cdata?.label}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}

                <li>
                  <ThemeChanger />
                </li>
                <li>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="px-1 dropdown-caret-none"
                      variant="none"
                    >
                      <i title="User Profile">
                        <span>
                          <ImageWithFallback
                            className={
                              userData?.profilePicture
                                ? "rounded-circle box-shadow-avtar"
                                : "user-bg-var"
                            }
                            src={
                              userData?.profilePicture
                                ? userData?.profilePicture
                                : team57
                            }
                            alt="profile" />
                        </span>
                      </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      variant="none"
                      className={`m-0 p-0 dropdown-menu-end navbar-dropdown-caret dropdown-profile shadow border border-300`}
                    >
                      <div className="card position-relative border-0">
                        <div className="card-body p-0">
                          <div className="text-center pt-4 pb-3">
                            <div className="avatar avatar-xl">
                              {/* <img className="rounded-circle" src={userData?.profilePicture ? userData?.profilePicture : team57} alt="profile" /> */}
                              <span>
                                <img
                                  className={
                                    userData?.profilePicture
                                      ? "rounded-circle box-shadow-avtar"
                                      : "user-bg-var box-shadow-avtar"
                                  }
                                  src={
                                    userData?.profilePicture
                                      ? userData?.profilePicture
                                      : team57
                                  }
                                  alt=""
                                  onError={(e: any) => {
                                    e.target.src = team57;
                                    e.target.className = "user-bg-var";
                                  }}
                                />
                              </span>
                            </div>
                            <h6 className="mt-2 text-black">
                              {userData?.strInitial} {userData?.firstName}{" "}
                              {userData?.lastName}
                            </h6>
                            <h6 className="mt-2 text-black">
                              {userData?.strRoleName &&
                                userData?.strRoleName.charAt(0).toUpperCase() +
                                userData?.strRoleName.slice(1)}
                            </h6>
                          </div>
                        </div>
                        <div className="overflow-auto scrollbar">
                          <ul className="nav d-flex flex-column mb-2 pb-1">
                            <li className="nav-item">
                              <Link
                                className="nav-link d-flex align-items-center px-3"
                                onClick={() =>
                                  dispatch(setRedirectedtoHeader(true))
                                }
                                to={routeConsant?.USER}
                              >
                                {" "}
                                <span
                                  className="me-2 text-900"
                                  data-feather="lock"
                                ></span>
                                User Profile
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="card-footer p-0 border-top">
                          {/* <ul className="nav d-flex flex-column my-3">
                                                        <li className="nav-item"><a className="nav-link d-flex align-items-center px-3" href="#!"> <span className="me-2 text-900" data-feather="user-plus"></span>Sign Up</a></li>
                                                    </ul>
                                                    <hr /> */}
                          <div className="px-3 mt-2">
                            <button
                              className="btn btn-dpa-secondary d-flex flex-center w-100"
                              onClick={handleLogOut}
                            >
                              <span className="me-2" data-feather="log-out">
                                {" "}
                              </span>
                              Sign out
                            </button>
                          </div>
                          <div className="my-2 text-center fw-bold fs--2 text-600">
                            <a
                              className="text-600 me-1"
                              href={routeConsant.POLICIES}
                            >
                              Privacy policy
                            </a>
                            &bull;
                            <a
                              className="text-600 mx-1"
                              href={routeConsant.TERMS}
                            >
                              Terms
                            </a>
                            {/* <a className="text-600 ms-1" href="#!">Cookies</a> */}
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <HeaderInfo />
    </>
  );
};

export default Header;
