import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import axios from "axios";
import parse from "html-react-parser";
import { CommonMessages } from "../../common/messageConstants";
import { Breadcrumb } from "../../common/interface";
import { decryptId } from "../../Encryption/Encryption";
import { GETALLVALUEDEFAULT, UPDATETEMPLATE, GETBYEMAILTEMPLATEID } from '../../common/apiConstatnts';
import * as routeConstant from '../../common/routeConstants';
import CustomCkEditor from "../../Utils/CustomCkEditor";
import handleError from "../../Utils/ApiCommonErrorMessages";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from "../Sidebar/Sidebar";

type propsType = {
    templateTypeName: 'Auction' | 'SMS' | 'Report' | 'Invoice' | 'Seller' | 'SalesRep' | 'Inventory' | 'SalesRecord' | 'Buyer' | 'ContactUs' | 'Appraisal' | 'Partners'|'Authorization'|'Email';
    objectType?: 'AuctionTempOptions' | 'ReportTempOptions' | 'InvoiceTempOptions' | 'SMSType' | 'SellerTempOptions' | 'SalesRepTempOptions'|'InventoryTempOptions'|'SalesRecordOptions'|'CustomerTempOptions'|'ContactUsTempOptions'|'AppraisalTempOptions'|'PartnersTempOptions'|'AuthorizationOptions'|'EmailOptions';
    templateTypeId: number;
    templateList: string;
}

type templateForType = {
    label: string;
    value: string;
}

const AddEditTemplate = ({ templateTypeName, objectType, templateTypeId, templateList }: propsType) => {
    const initialData = {
        templatemasterId: 0,
        templateFor: 0,
        strTemplateFor: "",
        templateName: "",
        templateContent: "",
        templateType: 0,
        isActive: true,
        isDelete: false,
        createdBy: 0,
        createdOn: '',
        updatedBy: 0,
        updatedOn: ''
    }
    const navigate = useNavigate();
    const params = useParams();
    const ID = params.id ? decryptId(params.id) : undefined;
    const [loading, setLoading] = useState<boolean>(false);
    const [templateForData, setTemplateForData] = useState<Array<templateForType>>([]);
    const [formValues] = useState<any>(initialData);
    // const [breadcrumbData, setBreadcrumbData] = useState<Breadcrumb[]>([]);
    /**
     * Set Breadcrumb Data
     */
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: `${templateTypeName} Templates`, url: templateList },
        { label: ID ? `Edit ${templateTypeName} Template` : `Create ${templateTypeName} Template`, url: '' }
    ];

    const schema: any = yup.object().shape({
        templateFor: yup.string().required("Template for is required"),
        templateContent: yup.string().required("Template Content is required")
            .test('no-leading-space', "No leading spaces allowed", value => {
                if (!value) return true;
                return !/^\s/.test(value);
            }),
        templateName: yup.string()
            // .matches(/^(?!^\d+$)[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, 'Please enter a valid Template name')
            .required("Template name is required")
            .max(100, "Please enter up to 100 characters."),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })
    const templateContent = watch('templateContent')

    const onSubmit = (data: any) => {
        let URL = UPDATETEMPLATE;
        let message = `${templateTypeName} template ${ID ? 'updated' : 'created'} successfully!`;
        const payload = {
            companyId: localStorage.getItem('companyId'),
            isActive: data.isActive,
            isDelete: false,
            templatemasterId: ID ? ID : 0,
            templateFor: parseInt(data?.templateFor),
            templateName: data?.templateName,
            templateContent: data?.templateContent,
            templateType: templateTypeId
        }
        setLoading(true);
        axios.post(URL, payload).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            const navigation = templateTypeId == 1
                ? routeConstant.AUCTIONTEMPLATES :
                templateTypeId == 2
                    ? routeConstant.SMSTEMPLATES :
                    templateTypeId == 3
                        ? routeConstant.REPORTTEMPLATES :
                        templateTypeId == 4
                            ? routeConstant.INVOICETEMPLATES :
                            templateTypeId == 5
                                ? routeConstant.SELLERTEMPLATES :
                                templateTypeId == 6
                                    ? routeConstant.SALESREPTEMPLATES :
                                    templateTypeId == 7
                                        ? routeConstant.INVENTORYTEMPLATES :
                                        templateTypeId == 8
                                            ? routeConstant.SALESRECORDTEMPLATES :
                                            templateTypeId == 9
                                                ? routeConstant.CUSTOMERTEMPLATES :
                                                templateTypeId == 10
                                                    ? routeConstant.CONTACTUSTEMPLATES :
                                                    templateTypeId == 11
                                                        ? routeConstant.APPRAISALTEMPLATES :
                                                        templateTypeId == 13
                                                        ? routeConstant.AUTHORIZATIONTEMPLATES :
                                                        templateTypeId == 14
                                                        ? routeConstant.EMAILTEMPLATES :
                                                        routeConstant.PARTNERTEMPLATES;
            navigate(navigation)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        })
    }

    const onEditorChange = (value: any) => {
        setValue('templateContent', value, { shouldValidate: true });
    };

    useEffect(() => {
        getTemplateForData();
    }, []);

    const getTemplateForData = () => {
        setLoading(true);
        axios.get(GETALLVALUEDEFAULT).then((response) => {
            let data = response?.data?.data;
            let filteredData = data?.find((item: any) => item?.objectType === objectType);
            setLoading(false);
            if (filteredData) {
                filteredData = filteredData.lstObjectValue.map((item: any) => ({
                    label: item?.objectTypeValue,
                    value: item?.objectValueId
                }));
                setTemplateForData(filteredData);
            }
            // If ID is present then fetch the data for edit
            setLoading(true);
            if (ID) {
                axios.get(`${GETBYEMAILTEMPLATEID}?templateMasterId=${ID}`).then((response) => {
                    let respData = response?.data?.data;
                    const ckData = respData?.templateContent;
                    reset(respData)
                    setValue('templateContent', ckData)
                }).catch((error) => {
                    handleError(error);
                })
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} alt='global-loader' />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row border-top justify-content-center pt-4">
                        <div className="col-xxl-10">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row ">
                                    <div className="col-lg-12">
                                        <h4 className="mb-3">{ID ? `Edit ${templateTypeName} Template` : `Create ${templateTypeName} Template`}</h4>
                                    </div>
                                        <div className="col-lg-12 mb-3">
                                            <label className="form-label">Template For <sup className="text-danger">*</sup></label>
                                            <select id="templateFor" className="form-select" {...register('templateFor')}>
                                                <option value="">Please select</option>
                                                {
                                                    templateForData.map((template: templateForType, index: number) => {
                                                        return (
                                                            <option value={template.value} key={index}>{template.label}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {errors.templateFor && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.templateFor.message}</span>}
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <label className="form-label">Template Name <sup className="text-danger">*</sup></label>
                                        <input type="text" className="form-control" placeholder="Template name" {...register('templateName')} />
                                        {errors.templateName && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.templateName.message}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <Tabs
                                            defaultActiveKey="preview"
                                            className="mb-3 nav-underline border-bottom"
                                        >
                                            <Tab eventKey="preview" title="Source">
                                                <label className="form-check-label">Template Content <sup className="text-danger">*</sup></label>
                                                <CustomCkEditor data={templateContent} onTextChanged={onEditorChange} />
                                                {errors.templateContent && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.templateContent.message}</span>}
                                            </Tab>
                                            <Tab eventKey="source" title="Preview">
                                                <div className="card box-shadow">
                                                    <div className="card-body">
                                                        {parse(templateContent)}
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                                {ID && <div className="mt-4 pl-2">
                                    <label>Status</label>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            {...register('isActive')}
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active</label>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                            <Link to={routeConstant.AUCTIONTEMPLATES} className="btn btn-outline-danger me-auto" type="button" data-wizard-prev-btn="data-wizard-prev-btn" >
                                                {CommonMessages.cancelButton}
                                            </Link>
                                            <button className="btn btn-primary mx-2" type="submit" >{ID ? (CommonMessages.updateButton) : (CommonMessages.createButton)}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEditTemplate;