import { Action } from '../../common/interface'
import * as actions from '../../common/types'

export interface AuctionState {
    auctionId: number
    auctionCodeAndTitle: object
    clonedAuctionId: number
    clonedTabs: []
    createTabs: []
    mode: string
    auctionType: number
    activeStep: number
    auctionInfo: object
    invoice: object
    auctionDetails: object
    value: object
    invoiceSetting: object
    emailReport: object
    invoiceTemplate: object
    sellerTemplate : object
}

const initialState: AuctionState = {
    auctionId: 0,
    auctionCodeAndTitle: {},
    clonedAuctionId: 0,
    clonedTabs: [],
    createTabs: [],
    mode: "create",
    auctionType: 2,
    activeStep: 1,
    auctionInfo: { activeStep: 1, activeLabel: "Address & Dates" },
    invoice: { activeStep: 1, activeLabel: "Auto Invoicing" },
    auctionDetails: { activeLabel: "AuctionSeller" },
    value: { activeStep: 1, activeLabel: "Commissions & Categories" },
    invoiceSetting: { activeStep: 1, activeLabel: "Invoice Setting" },
    emailReport: { activeStep: 1, activeLabel: "Invoice Email Template" },
    invoiceTemplate: { activeStep: 1, activeLabel: "Invoice Balance Due" },
    sellerTemplate : { activeStep: 1, activeLabel: "Seller Summary Report" }
};

export const AuctionReducer = (
    state: AuctionState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.AUCTIONID: {
            return { ...state, auctionId: action.payload }
        }
        case actions.SETCLONEAUCTIONID: {
            return { ...state, clonedAuctionId: action.payload }
        }
        case actions.SETAUCTIONCODEANDTITLE: {
            return { ...state, auctionCodeAndTitle: action.payload }
        }
        case actions.SETMODE: {
            return { ...state, mode: action.payload }
        }
        case actions.SETCLONETABS: {
            return { ...state, clonedTabs: action.payload }
        }
        case actions.SETCREATETABS: {
            return { ...state, createTabs: action.payload }
        }
        case actions.AUCTIONTYPE: {
            return { ...state, auctionType: action.payload }
        }
        case actions.ACTIVESTEP: {
            return { ...state, activeStep: action.payload }
        }
        case actions.AUCTIONNFOACTIVE: {
            return { ...state, auctionInfo: action.payload }
        }
        case actions.CHECKLIST: {
            return { ...state, checkList: action.payload }
        }
        case actions.AUCTIONINFO: {
            return { ...state, auctionInfoData: action.payload }
        }
        case actions.VALUEDATA: {
            return { ...state, valuesData: action.payload }
        }
        case actions.AUCTIONINVOICEDATA: {
            return { ...state, invoiceData: action.payload }
        }
        case actions.AUCTIONINVOICE: {
            return { ...state, invoice: { ...state.invoice, ...action.payload } }
        }
        case actions.AUCTIONDETAILS: {
            return { ...state, auctionDetails: { ...state.auctionDetails, ...action.payload } }
        }
        case actions.AUCTIONVALUE: {
            return { ...state, value: { ...state.value, ...action.payload } }
        }
        case actions.SELLERREPORT: {
            return { ...state, sellerReport: action.payload }
        }
        case actions.RESETAUCTION: {
            return initialState
        }
        case actions.AUCTIONINVOICESETTING: {
            return { ...state, invoiceSetting: { ...state.invoiceSetting, ...action.payload } }
        }
        // Email reports
        case actions.AUCTIONEMAILREPORTS: {
            return { ...state, emailReport: { ...state.emailReport, ...action.payload } }
        }
        case actions.INVOICETEMPLATE: {
            return { ...state, invoiceTemplate: { ...state.invoiceTemplate, ...action.payload } }
        }
        case actions.SELLERTEMPLATE: {
            return { ...state, sellerTemplate: { ...state.sellerTemplate, ...action.payload } }
        }
        default:
            return state;
    }
};
