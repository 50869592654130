import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Breadcrumb } from "../../common/interface";
import { CommonMessages, errorMessages, Timezone } from "../../common/messageConstants";
import * as routeConstant from "../../common/routeConstants";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import jwt_decode from "jwt-decode";
import {
  DELETETIMEZONEIDENTIFIER,
  GETALLBIDDERCONFIG,
  GETBYTIMEZONEIDENTIFIERID,
  GETTIMEZONEIDENTIFIER,
  UPDATETIMEZONEIDENTIFIER,
} from "../../common/apiConstatnts";
import TimezoneSelect from "react-timezone-select";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const TimeZoneIdentifier = () => {
  const initialData = {
    companyId: localStorage.getItem("companyId"),
    isActive: true,
    isDelete: false,
    timeZoneIdentifierId: 0,
    timeZone: "",
    identifier: "",
    label: "",
    offset: 0,
    altName: "",
  };
  const [selectedTimezone, setSelectedTimezone]: any = useState({});

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("create");
  const [timezoneData, setTimezoneData] = useState([]);
  const [defaultTz, setDefaultTz] = useState('')
  const [filterTimezoneData, setFilterTimezoneData] = useState([]);
  const PER_PAGE = 10;
  const pageCount = Math.ceil(timezoneData?.length / PER_PAGE);
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;

  // Set the initial value to current UTC time
  const schema: any = yup.object().shape({
    timeZone: yup.string().required("Please select time zone"),
  });

  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Time Zone Identifier", url: "" },
  ];

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const paginateData = (data: any) => {
    setTimezoneData(data);
    const firstSet = data?.slice(0, PER_PAGE);
    setFilterTimezoneData(firstSet);
  };

  const handlePageClick = ({ selected: selectedPage }: any) => {
    setCurrentPage(selectedPage);
  };

  const onSubmit = (data: any) => {
    const payload = {
      companyId: localStorage.getItem("companyId"),
      isActive: true,
      isDelete: false,
      timeZoneIdentifierId: 0,
      timeZone: selectedTimezone.abbrev,
      identifier: selectedTimezone.value,
      label: selectedTimezone.label,
      offset: selectedTimezone.offset,
      altName: selectedTimezone.altName,
    };
    setLoading(true);
    axios
      .post(UPDATETIMEZONEIDENTIFIER, [payload], config)
      .then((response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
          return;
        } else {
          setLoading(false);
          toast.success(
            mode == "create"
              ? Timezone.timezoneCreate
              : Timezone.timezoneUpdate,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        }
      })
      .catch((error)=>{
        setLoading(false);
        handleError(error)
    })
      .finally(() => {
        setLoading(false);
        setToggleValue(!toggleModal);
        reset(initialData);
        setSelectedTimezone({});
        getAllTimeZoneIdentifier();
      });
  };

  const handleClose = (isDirtyCheck = true) => {
    if (isDirtyCheck) {
      if (isDirty) {
        Swal.fire({
          title: "Are you sure?",
          text: "Changes will be discarded!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Discard",
        }).then((result) => {
          if (result.isConfirmed) {
            reset(initialData);
            setToggleValue(!toggleModal);
          }
        });
        return;
      }
    }
    setToggleValue(!toggleModal);
    reset(initialData);
  };

  const handleDeleteModal = (id: any, timeZone: any) => {
    if (timeZone === defaultTz) {
      toast.error('You can not delete timezone because this timezone is used in as default timezone !', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else {
      Swal.fire({
        title: "Are you sure ?",
        text: "you want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios
            .post(
              `${DELETETIMEZONEIDENTIFIER}?timeZoneIdentifierId=${id}`,
              config
            )
            .then((response: any) => {
              setLoading(false);
              setSearchText("");
              toast.success(Timezone.timezoneDelete, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              getAllTimeZoneIdentifier();
            })
            .catch((error)=>{
              setLoading(false);
              handleError(error)
          })
        }
      });
    }
  };

  useEffect(() => {
    setLoading(true)
    axios.get(GETALLBIDDERCONFIG, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.data?.success === "Fail") {
          toast.error(response?.data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          let bidderConfigData = response?.data?.data;
          const timeZoneSetting = bidderConfigData.find(
            (setting: any) => setting.settingKey === "liveBoardTimeZone"
          );
          const liveBoardTimeZone = timeZoneSetting?.settingValue
            ? timeZoneSetting?.settingValue
            : ""
          setDefaultTz(liveBoardTimeZone);
        }
      }).catch((error)=>{
        setLoading(false);
        handleError(error);
    });
  }, [])
  // search bar
  const handleSearch = (e: any) => {
    const keyword = e?.toLocaleLowerCase();
    if (keyword !== "") {
      const filterData: any = timezoneData?.filter(
        (x: any) =>
          x.timeZone?.toLocaleLowerCase()?.includes(keyword) ||
          x.identifier?.toString()?.includes(keyword)
      );
      paginateData(filterData);
      setCurrentPage(0);
    }
  };

  const getAllTimeZoneIdentifier = () => {
    axios
      .get(GETTIMEZONEIDENTIFIER, config)
      .then((response: any) => {
        if (response?.data?.data.length > 0) {
          paginateData(response?.data?.data);
        } else {
          toast.error("No record found", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setLoading(false);

      })
      .catch((error)=>{
        setLoading(false);
        handleError(error)
    })
  };

  useEffect(() => {
    getAllTimeZoneIdentifier();
  }, []);

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const nextSet = timezoneData?.slice(offset, offset + PER_PAGE);
    setFilterTimezoneData(nextSet);
  }, [currentPage]);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const handleOnChange = (e: any) => {
    setSelectedTimezone(e);
    setValue("timeZone", e.abbrev);
  };

  return (
    <>
      <CommonHelmet title={"Time Zone - "} name={"timeZoneIdentifier"} content={"Time Zone Identifier"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <Modal show={toggleModal} size="lg" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            {mode === "create" ? "Add Time Zone" : "Edit Time Zone"}
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={handleOnChange}
              placeholder={"Select time zone"}
            />
            {errors.timeZone && (
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {errors.timeZone.message}
              </span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => {
                handleClose();
              }}
            >
              {CommonMessages.cancelButton}
            </button>
            <button className="btn btn-primary" type="submit">
              {mode != "create"
                ? CommonMessages.updateButton
                : CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Time Zone Identifier">
          <div className="search-box mt-2">
            <div className="d-flex align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  className="form-control search-input search form-control-sm"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
                <span className="fas fa-search search-box-icon"></span>
              </div>
            </div>
          </div>
          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            {superAdminRole === "True" ? (
              <button
                className="btn btn-outline-primary"
                type="button"
                data-bs-dismiss="modal"
                onClick={() => {
                  setToggleValue(!toggleModal);
                  setMode("create");
                  reset(initialData);
                }}
              >
                <span className="plus me-2">
                  <span
                    className="fas fa-plus"
                    data-fa-transform="shrink-3"
                  ></span>
                </span>
                <span> Add Time Zone</span>
              </button>
            ) : (
              ""
            )}
          </div>
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-4 border-300">
                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                  <div className="table-responsive mx-n1 px-1 scrollbar">
                    <table className="table fs--1 mb-0 border-top border-200">
                      <thead>
                        <tr>
                          <th className="bg-gradiant" scope="col">
                            Action
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Label</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Time Zone</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Identifier</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Offset</span>
                            </div>
                          </th>

                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Alt. Name</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>IsActive</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list" id="table-latest-review-body">
                        {filterTimezoneData?.length > 0 ? (
                          filterTimezoneData?.map(
                            (timezone: any, index: any) => {
                              return (
                                <tr
                                  className="hover-actions-trigger btn-reveal-trigger position-static"
                                  key={index}
                                >
                                  <td className="align-middle white-space-nowrap text-start p-2">
                                    <Dropdown
                                      className={`font-sans-serif btn-reveal-trigger position-static`}
                                    >
                                      <Dropdown.Toggle
                                        className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                        variant="none"
                                      >
                                        <img
                                          src="/assets/img/ellipsis-solid.svg"
                                          style={{ width: "20px" }}
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className={`dropdown-menu dropdown-menu-start py-2`}
                                      >
                                        <Dropdown.Item
                                          as="button"
                                          className="dropdown-item d-flex align-items-center justify-content-between"
                                          onClick={() =>
                                            handleDeleteModal(
                                              timezone?.timeZoneIdentifierId, timezone?.timeZone
                                            )
                                          }
                                        >
                                          Delete
                                          <i className="fas fa-trash dropdown-icon"></i>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td className="align-middle p-2">
                                    {timezone?.label}
                                  </td>
                                  <td className="align-middle p-2">
                                    {timezone?.timeZone}
                                  </td>
                                  <td className="align-middle p-2">
                                    {timezone?.identifier}
                                  </td>
                                  <td className="align-middle p-2">
                                    {timezone?.offset}
                                  </td>
                                  <td className="align-middle p-2">
                                    {timezone?.altName}
                                  </td>
                                  <td className="align-middle p-2">
                                    <span
                                      className={`badge badge-dpa fs--2 ${timezone?.isActive
                                        ? "badge-dpa-success"
                                        : "badge-dpa-danger"
                                        }`}
                                    >
                                      <span className="badge-label">
                                        {timezone?.isActive
                                          ? "ACTIVE"
                                          : "INACTIVE"}
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <>
                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                              <td className="text-center p-4" colSpan={7}>
                                No records found!
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {timezoneData?.length > 0 && (
                  <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimeZoneIdentifier;
