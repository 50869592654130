import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import moment from "moment"
import { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import {  GETSALESREPCOMMISSIONSREPORT,GETALLAUCTIONLIST } from "../../common/apiConstatnts"
import { UTCdateConverter } from "../../common/constants"
import { Breadcrumb } from "../../common/interface"
import { errorMessages } from "../../common/messageConstants"
import * as routeConstant from '../../common/routeConstants'
import { encryptId } from "../../Encryption/Encryption"
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar"
import { formatCurrency, formatNumber } from "../../Utils/Utils"
import MomentUtil from "../../Utils/MomentUtil"
import handleError from "../../Utils/ApiCommonErrorMessages"
import { generateSalesRepCommReportCsv, generateSalesRepCommReportPdf } from "./ExportReportsPdfCsvFile"

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Sales Rep Commission Report', url: routeConstant.SALESREPCOMMISSIONREPORT }
]
const SalesRepCommissionReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const [loading, setLoading] = useState(false);
    const [salesRepCommissionData, setSalesRepCommissionData] = useState<any[]>([]);
    const [filterSalesRepCommission, setFilterSalesRepCommission] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});

    const PER_PAGE = 10;
    const pageCount = Math.ceil(salesRepCommissionData?.length / PER_PAGE);

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const handleSorting = (type: string, fieldName: string) => {
        let sortedData = [...salesRepCommissionData];
        if (type === "ASC") {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    a[fieldName].localeCompare(b[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            }
        } else {
            if (typeof sortedData[0][fieldName] === "string") {
                sortedData.sort((a: any, b: any) =>
                    b[fieldName].localeCompare(a[fieldName])
                );
            } else if (typeof sortedData[0][fieldName] === "number") {
                sortedData.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
            }
        }
        paginateData(sortedData);
        setCurrentPage(0);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const paginateData = (data: any) => {
        setSalesRepCommissionData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSalesRepCommission(firstSet)
    }

    const getSellerSummaryData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
        axios.get(`${GETSALESREPCOMMISSIONSREPORT}?auctionId=${auctionId}`).then((response: any) => {
            setLoading(false)
            const sellerSumResp = response?.data?.data
            if (response?.data?.success === "Fail") {
                // toast.error(response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setFilterSalesRepCommission([]);
                return;
            } else {
                paginateData(sellerSumResp)
                setCurrentPage(0)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
      }
    }

    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            // const auction = response.data.map((d: any) => {
            //     const start: any = new Date(d.startDateTime);
            //     const current: any = UTCdateConverter(new Date());
            //     return { ...d, pendingTime: start - current }
            // })
            // const currentAuction = auction.find((obj: any) => obj.pendingTime == Math.min(...auction.filter((d: any) => d.pendingTime > 0).map((i: any) => i.pendingTime)));
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error)
          });;
    };

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = salesRepCommissionData?.slice(offset, offset + PER_PAGE)
        setFilterSalesRepCommission(nextSet)
    }, [currentPage])

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerSummaryData(selectedAuction.value);
        }
    }, [selectedAuction])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Sales Rep Commission Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-md-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="d-flex align-items-center">
                                    <div className="col-lg-6">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=" col-md-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=> generateSalesRepCommReportCsv(salesRepCommissionData)}
                                        disabled={filterSalesRepCommission?.length == 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={() => generateSalesRepCommReportPdf(salesRepCommissionData)}
                                        disabled={filterSalesRepCommission?.length == 0}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>Pdf</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div>
                                    <div className="row ps-3 pe-3 pb-3">
                                        <div className="col-12">
                                            <div className="mb-5 border-300">
                                                <div>
                                                    <div className="table-responsive mx-n1 px-1 scrollbar">
                                                        <table className="table fs--1 mb-0 border-top border-200">
                                                            <thead>
                                                                <tr>
                                                                    <th className="bg-gradiant " scope="col" data-sort="module">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span> Sales Rep Id</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Sales Rep Name</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepName')} ></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepName')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Comm Type</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'commissionType')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'commissionType')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Sold</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepSold')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepSold')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Passed</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepPassed')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepPassed')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Buyback</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepBuyback')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepBuyback')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Comm Total</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepCommissionTotal')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepCommissionTotal')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Adjust Total</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepAdjustTotal')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepAdjustTotal')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <th className="bg-gradiant " scope="col" data-sort="phone">
                                                                        <div className='d-flex align-items-center'>
                                                                            <span>Invoice Total</span>
                                                                            <div className="sorting_group">
                                                                                <button className='arrow-up' onClick={() => handleSorting('ASC', 'salesRepInvoiceAdjustsTotal')}></button>
                                                                                <button className='arrow-down' onClick={() => handleSorting('DESC', 'salesRepInvoiceAdjustsTotal')}></button>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list" id="table-latest-review-body">
                                                                {filterSalesRepCommission?.length > 0 ? filterSalesRepCommission.map((fData: any, fIndex: any) => {
                                                                    return (
                                                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={fIndex}>
                                                                            <td className="align-middle  p-2">{fData.salesRepId}</td>
                                                                            <td className="align-middle  p-2">
                                                                                <Link className="btn text-decoration-underline m-0 p-0 fw-normal" data-bs-dismiss="modal"
                                                                                    target="_blank"
                                                                                    to={`${routeConstant.SALESREPRESENTATIVEMOREINFO}/${encryptId(fData?.salesRepId)}`}
                                                                                >
                                                                                    {fData?.salesRepName}
                                                                                </Link>
                                                                            </td>
                                                                            <td className="align-middle  p-2">{fData?.commissionType ?? '-'}</td>
                                                                            <td className="align-middle  p-2">{fData?.salesRepSold ?? '-'}</td>
                                                                            <td className="align-middle  p-2">{fData?.salesRepPassed ?? '-'}</td>
                                                                            <td className="align-middle  p-2">{fData?.salesRepBuyback ?? '-'}</td>
                                                                            <td className="align-middle  p-2">{formatCurrency(fData?.salesRepCommissionTotal) ?? '0'}</td>
                                                                            <td className="align-middle  p-2">{formatCurrency(fData?.salesRepAdjustTotal) ?? '0'}</td>
                                                                            <td className="align-middle  p-2">{formatCurrency(fData?.salesRepInvoiceAdjustsTotal) ?? '0'}</td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={10}>No records found!</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    {filterSalesRepCommission?.length > 0 && (
                                        <ReactPaginate
                                            previousLabel={"←"}
                                            nextLabel={"→"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            forcePage={currentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesRepCommissionReport