import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from 'moment';
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { GETCOMMONVALUE, UPDATEPAYMENTS } from "../../../../common/apiConstatnts";
import { DECIMAL73, DECIMAL73_MESSAGE } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { decryptId } from "../../../../Encryption/Encryption";
import { setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import Select from 'react-select';
import { CommonMessages, errorMessages, SellerMessages } from "../../../../common/messageConstants";
import DateTime from "../../../../Utils/DateTime";
import { ERROR_MESSAGES, formatCurrency, handleKeyDown } from "../../../../Utils/Utils";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const Payment = () => {

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const companyId: any = localStorage.getItem('companyId')
    const sellerData = useSelector((state: any) => state.seller)
    const auctionId = sellerData.selectedAuction.value
    const paymentData = sellerData.payment

    const initialData = {
        auctionId: auctionId,
        payments: 0,
        date: '',
        amountPaid: '',
        amountRecd: '',
        payType: "",
        check: '',
        paymentUniqueId: 0,
        companyId: companyId,
        isActive: true,
        isDelete: false,
        sellerPaymentId: 0,
        sellerId: ID ? decryptId(ID) : 0,
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const dispatch = useDispatch()
    const [payments, setPayments] = useState<any>(paymentData ? paymentData : [])
    const [filterPayments, setFilterPayments] = useState([]);
    const [toggleModal, setToggleValue] = useState<boolean>(false);
    const [paymentTypeData, setPaymentTypeData] = useState<any>([]);
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [mode, setMode] = useState<string>('create')
    const [currentPage, setCurrentPage] = useState(0);
    const [payload, setPayload] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const PER_PAGE = 10;
    const pageCount = Math.ceil(payments?.length / PER_PAGE);
    const paginateData = (data: any) => {
        setPayments(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterPayments(firstSet)
    }

    const schema: any = yup.object().shape({
        date: yup
            .date()
            .transform((value, originalValue) => {
                return originalValue === "" ? null : value;
            }),
        amountPaid: yup
            .string()
            .required("Amount Paid is required.")
            .test(
                "no-blank-space",
                ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
                (value) => {
                    return value ? /^\S*$/g.test(value) : true;
                }
            ), payType: yup.string().required('Please select Pay Type.'),
        isCheck: yup.boolean(),
        check: yup.string().when('isCheck', {
            is: (value: boolean) => value === true,
            then: () => yup.number().typeError('Check must be a number.')
                .test("greater-than-zero", "Amount must be greater than 0", (value: any) => {
                    // Check if value is numeric and greater than 0
                    return parseFloat(value) > 0;
                }).required('Check is required.'),
            otherwise: () => yup.string().nullable(),
        },
        ),
    })

    const {
        register: registerPayment,
        handleSubmit: handleSubmitPayment,
        setValue: setValuePayment,
        getValues: getValuesPayment,
        reset: setResetPayment,
        clearErrors: ClearErrorsPayment,
        watch: watchPayment,
        control,
        formState: { errors: errorsPayment, isValid: isValidPayment }
    } = useForm({ resolver: yupResolver(schema), defaultValues: { ...initialFormValue }, mode: 'onChange' });

    const paymentType = watchPayment('payType');
    const amountPaid = watchPayment('amountPaid');
    const amountRecd = watchPayment('amountRecd');

    const onSubmitPayment = () => {
        let updatedPayments, getData: any;
        getData = getValuesPayment();
        const checkValue = getData.check === "" ? null : getData.check
        if (mode === "create") {
            let uuid = uuidv4()
            // dispatch(updateSeller({ ...sellerData, payment: [...sellerData.payment, { ...getData, paymentUniqueId: uuid }] }))
            setPayments([...payments, {
                ...getData,
                date: moment(getData?.date).format('YYYY-MM-DDTHH:MM'),
                paymentUniqueId: uuid,
                check: checkValue,
                amountPaid: getData?.amountPaid ? getData?.amountPaid : null,
                amountRecd: getData?.amountRecd ? getData?.amountRecd : null,
                taxAmount: getData?.taxAmount ? getData?.taxAmount : null
            }])
            setPayload([...payload, {
                ...getData,
                date: moment(getData?.date).format('YYYY-MM-DDTHH:MM'),
                paymentUniqueId: uuid,
                check: checkValue,
                amountPaid: getData?.amountPaid ? getData?.amountPaid : null,
                amountRecd: getData?.amountRecd ? getData?.amountRecd : null,
                taxAmount: getData?.taxAmount ? getData?.taxAmount : null
            }])
            setResetPayment(initialData)
            handleClose()
        } else {
            const getObjData = payments.find((data: any) => data.paymentUniqueId === initialFormValue?.paymentUniqueId)
            // updatedPayments = payments.filter((data: any) => data.paymentUniqueId != initialFormValue?.paymentUniqueId)

            updatedPayments = payments.map((p: any) => {
                if (getData?.paymentUniqueId == p.paymentUniqueId) {
                    return {
                        ...p, ...getData,
                        date: moment(getData?.date).format('YYYY-MM-DDTHH:MM'),
                        check: checkValue,
                        amountPaid: getData?.amountPaid ? getData?.amountPaid : 0,
                        amountRecd: getData?.amountRecd ? getData?.amountRecd : 0,
                        taxAmount: getData?.taxAmount ? getData?.taxAmount : 0
                    }
                }
                return p
            })

            // dispatch(updateSeller({ ...sellerData, payment: [...updatedPayments, { ...getObjData, ...getData }] }))
            setPayments(updatedPayments)
            // setPayments([...updatedPayments, {
            //     ...getObjData, ...getData,
            //     date: moment(getData?.date).format('YYYY-MM-DDTHH:MM'),
            //     check: checkValue,
            //     amountPaid: getData?.amountPaid ? getData?.amountPaid : 0,
            //     amountRecd: getData?.amountRecd ? getData?.amountRecd : 0

            // }])
            const updatedPayload = payload?.filter((data: any) => data.paymentUniqueId != initialFormValue?.paymentUniqueId)

            setPayload([...updatedPayload, {
                ...getObjData, ...getData,
                date: moment(getData?.date).format('YYYY-MM-DDTHH:MM'),
                check: checkValue,
                amountPaid: getData?.amountPaid ? getData?.amountPaid : 0,
                amountRecd: getData?.amountRecd ? getData?.amountRecd : 0,
                taxAmount: getData?.taxAmount ? getData?.taxAmount : 0
            }])
            setResetPayment(initialData)
            handleClose()
        }
    }
    const [checkVal, setCheckVal] = useState(false);
    const handleSelect = (e: any) => {
        if (e.value == '77') {
            setCheckVal(true)
            setValuePayment('isCheck', true)
            ClearErrorsPayment(['payType'])
            return
        }
        setCheckVal(false)
        setValuePayment('isCheck', false)
        setValuePayment('check', '')
        ClearErrorsPayment(['check', 'payType'])
    }

    const handleClose = () => {
        setCheckVal(false)
        setToggleValue(!toggleModal)
        setResetPayment(initialData)
    }

    const handleEditModal: any = (paymentUniqueId: any, type: string) => {
        if (type === 'edit') {
            setMode('edit')
            setToggleValue(!toggleModal)
            const getData = payments.find((data: any) => data.paymentUniqueId === paymentUniqueId)
            setFormValue(getData)
            setResetPayment({ ...getData, date: moment(getData?.date).format('MM/DD/YYYY'), amountPaid: Number(getData?.amountPaid)?.toFixed(2), amountRecd: Number(getData?.amountRecd)?.toFixed(2) })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const getData = payments.find((data: any) => data.paymentUniqueId === paymentUniqueId)
                const updatedData = payments.filter((i: any) => i.paymentUniqueId !== paymentUniqueId)
                // dispatch(updateSeller({ ...sellerData, payment: updatedData }))
                setPayments(updatedData)
                if (getData?.sellerPaymentId === 0) {
                    setPayload(payload.filter((i: any) => i.paymentUniqueId !== paymentUniqueId))
                }
                else if (payload.find((i: any) => getData?.sellerPaymentId == i.sellerPaymentId)) {
                    const updatedPayload = payload.map((i: any) => {
                        if (getData?.sellerPaymentId == i.sellerPaymentId) {
                            return { ...i, isActive: false, isDelete: true }
                        } else {
                            return i
                        }
                    })
                    setPayload(updatedPayload)
                }
                else {
                    setPayload([...payload, { ...getData, isActive: false, isDelete: true }])
                }
                setCurrentPage(0)
                toast.success(SellerMessages.deletedMsg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }
    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('adjust'))
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('adpaylog'))
    }

    const onSave = () => {
        if (!payload?.length || !sellerData?.sellerId) {
            handleNext()
            return
        }
        setLoading(true)
        axios.post(UPDATEPAYMENTS, payload, { ...config, 'ContentType': 'application/json' })
            .then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    const updatedData = response?.data?.data?.map((i: any) => { return { ...i, paymentUniqueId: uuidv4() } })
                    dispatch(updateSeller({ ...sellerData, payment: updatedData }))
                    setLoading(false)
                    toast.success('Payments saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
                handleNext();
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
    }

    const filterPayDataByAuction = () => {
        let data = paymentData.filter((payment: any) => payment?.auctionId === auctionId);
        setPayments(data);
    }

    useEffect(() => {
        paginateData(payments);
    }, [payments])

    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=PaymentType&companyId=${localStorage.getItem('companyId')}`, config).then((response) => {
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                let formateData = response.data.data?.map((data: any, index: any) => {

                    return {
                        label: data.objectTypeValue, value: data.objectValueId
                    }
                })
                setPaymentTypeData(formateData);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }, [])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = payments?.slice(offset, offset + PER_PAGE)
        setFilterPayments(nextSet)
    }, [currentPage])

    useEffect(() => {
        filterPayDataByAuction()
    }, [auctionId])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={toggleModal} size='lg' centered>
                <form>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add Payments' : 'Edit Payments'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label className="form-label">Date <sup className="text-danger">*</sup></label>
                                {/* <input
                                    className="form-control form-icon-input"
                                    type="date"
                                    placeholder="Date"
                                    {...registerPayment('date')}
                                /> */}

                                <div className="date_icon">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <DateTime
                                                closeOnSelect={true}
                                                onChange={onChange}
                                                value={value}
                                                timeFormat={false}
                                                dateFormat="MM/DD/y"
                                                inputProps={{
                                                    placeholder: "Date",
                                                    className: "form-control form-icon-input only_date_icon",
                                                }}
                                            />
                                        )}
                                    />

                                </div>
                                {errorsPayment.date && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.date.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount Paid ($)<sup className="text-danger">*</sup></label>
                                <Controller
                                    name="amountPaid"
                                    control={control}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount Paid"
                                            {...registerPayment('amountPaid')}
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={amountPaid}
                                            onValueChange={(value: any) => setValuePayment("amountPaid", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />

                                {errorsPayment.amountPaid && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.amountPaid.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount Received ($)</label>

                                <Controller
                                    name="amountRecd"
                                    control={control}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount Received"
                                            {...registerPayment('amountRecd')}
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={amountRecd}
                                            onValueChange={(value: any) => setValuePayment("amountRecd", value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />

                                {errorsPayment.amountRecd && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.amountRecd.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Pay type <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...registerPayment('payType')}
                                    name='payType'
                                    options={paymentTypeData}
                                    value={paymentTypeData?.find((option: any) => option?.value == paymentType)}
                                    onChange={(e: any) => {
                                        setValuePayment('payType', e.value)
                                        handleSelect(e)
                                    }}
                                />

                                {errorsPayment.payType && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.payType.message}</span>}
                            </div>
                            {checkVal && <div className="col-md-12">
                                <label className="form-label">Check <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Check"
                                    {...registerPayment('check')}
                                    maxLength={12}
                                    onKeyDown={handleKeyDown}
                                />
                                {errorsPayment.check && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsPayment.check.message}</span>}
                            </div>}

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit" onClick={handleSubmitPayment(onSubmitPayment)}>{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center">
                    <div className="col-xxl-10">
                        <div className="mt-3 mb-4 border-300">
                            <div className="row">
                                <div className="col-12 d-flex flex-wrap justify-content-between">
                                    <h4>Payments</h4>
                                    <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
                                        onClick={() => {
                                            setResetPayment(initialData)
                                            setToggleValue(!toggleModal)
                                            setMode('create')
                                        }}>
                                        <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Payments </span>
                                    </button>
                                </div>

                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div>
                                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col">Action</th>
                                                            <th className="bg-gradiant " scope="col" data-sort="firstname">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Date</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="lastname">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Amount Paid ($)</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="company">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Amount Recd ($)</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="phoneNo">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Pay Type</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Privillege">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Check</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list" id="table-latest-review-body">
                                                        {filterPayments?.length > 0 ?
                                                            filterPayments?.map((data: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                                    <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                    </button>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                    <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.paymentUniqueId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                    <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.paymentUniqueId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td className="align-middle customer white-space-nowrap p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.date ? moment(data?.date).format('MM/DD/YYYY').toString() : '-'}</p>
                                                                        </td>
                                                                        <td className="align-middle customer white-space-nowrap p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.amountPaid == null ? '-' : formatCurrency(data?.amountPaid)}</p>
                                                                        </td>
                                                                        <td className="align-middle customer white-space-nowrap p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.amountRecd == null ? '-' : formatCurrency(data?.amountRecd)}</p>
                                                                        </td>
                                                                        <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.payType && paymentTypeData?.find((pData: any) => pData.value == data.payType)?.label}</p>
                                                                        </td>
                                                                        <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.check == null ? '-' : data?.check}</p>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={6}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterPayments?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary ms-2" type="button" onClick={onSave} >{ID ? (CommonMessages.updatedNext) : (CommonMessages.saveNext)}</button>
                    <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                </div>
            </div>

        </>
    )
}

export default Payment;