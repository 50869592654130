import React, { useEffect, useState, Component } from "react";
import { CKEditor } from 'ckeditor4-react';
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from '../Sidebar/Sidebar';
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { GETEMAILTEMPLATE, UPDATEEMAILTEMPLATE, SAVEEMAILTEMPLATE } from '../../common/apiConstatnts';
// import loader from "../../assets/loader.gif";
import { Tab, Tabs } from "react-bootstrap";
import * as yup from 'yup';
import parse from 'html-react-parser';
import { toast } from "react-toastify";
import { register } from "../../serviceWorker";
import { Link } from "react-router-dom";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonMessages, emailHeaderFooterMessages, errorMessages } from "../../common/messageConstants";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../Utils/CustomCkEditor";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Email Setting', url : "" },
    { label: 'Email Header & Footer', url: "routeConsant.EMAILHEADERFOOTER" }
]

const EmailHeaderFooter = () => {
    const initialData = {
        auctionDefaultId: 0,
        companyId: localStorage.getItem('companyId'),
        auctionEmail: "",
        defaultcreditLimit: "",
        defaultTimeZone: "",
        defaultCurrency: "",
        defaultMasterBidderId: "",
        defaultMasterBidder: "",
        defaultMasterBidderFullName: "",
        defaultMasterSellerId: "",
        defaultMasterSeller: "",
        defaultMasterSellerFullName: "",
        defaultUploadTerms: ""
    }

    const navigate = useNavigate()
    const [formValues, setFormValues] = useState<any>(initialData)
    const [emailValue, setEmailValue] = useState<any>('')
    const [templateSel, setTemplateSel] = useState('header')
    const [subjectLine, setSubjectLine] = useState()
    const [comId, setCompId] = useState<any>(0)
    const [isA, setIsA] = useState<any>(true)
    const [loading, setLoading] = useState(false)
    const [ckEditorInit, setCkEditor] = useState<any>()
    const [showData, setShow] = useState<any>()
    const [modifiedBy, setModifiedBy] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [updateDate, setUpdateDate] = useState<any>(false);

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        params: {
            id: localStorage.getItem('companyId'),
            emailType: templateSel
        },
    };

    const configToken: any = {
        timeout: 5000,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        paras: {
            id: localStorage.getItem('companyId')
        }
    };

    const schema: any = yup.object().shape({
        subjectLine: yup.string().max(100, 'please enter upto 100 characters'),
    });

    const {
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) })


    const onsubmit = (e: any) => {
        setLoading(true);
        setUpdateDate(true);
        e.preventDefault()
        const payload = {
            emailTemplateId: comId,
            companyId: localStorage.getItem('companyId'),
            emailType: templateSel,
            smtpMailId: 1,
            emailValue: emailValue,
            isActive: isA,
            subjectLine: e.target.subjectLine.value,
        }
        const URL = comId == 0 ? SAVEEMAILTEMPLATE : UPDATEEMAILTEMPLATE
        axios.post(URL, payload, configToken).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(emailHeaderFooterMessages.emailHeaderFooterCreated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const creteTemplate = (e: string) => {
        setLoading(true);
        const defaultPayload = {
            emailTemplateId: 0,
            companyId: localStorage.getItem('companyId'),
            emailType: e,
            smtpMailId: 1,
            emailValue: emailValue,
            isActive: isA,
            subjectLine: `Subject Line for ${e}`,
        }
        axios.post(SAVEEMAILTEMPLATE, defaultPayload, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                //   autoClose: 2000,
                // });
                return;
            }
            else {
                toast.success(emailHeaderFooterMessages.blankTemplate, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
        getEmailTemplate(e)
    }


    const getEmailTemplate = (e: string) => {
        setLoading(true)
        axios.get(`${GETEMAILTEMPLATE}?emailType=${e}`, configToken).then((response) => {
            setLoading(false)
            setCkEditor("")
            if (response?.data?.success === "Fail") {
                // toast.error(response?.data?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // });
                setEmailValue("");
                return;
            }
            else {
                const data = response.data.data[0]
                setModifiedBy(data.modifiedBy)
                if (data) {
                    const ckData = data.emailValue
                    setShow(ckData);
                    setFormValues({ ...data, defaultcreditLimit: String(data?.defaultcreditLimit) });
                    setUpdateDate(false);
                }
                setEmailValue(data.emailValue)
                setSubjectLine(data.subjectLine)
                setCompId(data.emailTemplateId)
                setIsA(data.isActive)
            }
            setLoading(false);
        },
        ).catch((error) => {
            setLoading(false);
            handleError(error);
            if (error.response.status == 404) {
                setTemplateSel(e)
                creteTemplate(e)
            }
        });
    }

    const handleClick = (e: any) => {
        setLoading(true);
        setTemplateSel(e);
        getEmailTemplate(e);
    }

    const onEditorChange = (value: any) => {
        setEmailValue(value)
    }

    const subChange = (e: any) => {
        setSubjectLine(e.target.value)
        const newSubjectLine = e.target.value;
        if (newSubjectLine.length <= 100) {
            setSubjectLine(newSubjectLine);
            setErrorMessage('');
        } else {
            setErrorMessage('Please enter up to 100 characters');
        }
    }

    useEffect(() => {
        // setEmailValue("<p>Demo</p>") 
        getEmailTemplate('header')
    }, [updateDate])

    return (
        <>
            <CommonHelmet title={"Email Header Footer - "} name={"emailHeaderFooter"} content={"Email Header Footer"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row border-top justify-content-center pt-4">
                        <div className="col-xxl-10">
                            <form onSubmit={(onsubmit)} noValidate>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="mb-3">Email Header & Footer</h4>
                                            <label>Mail Content Settings (SMTP Server option only)</label>
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <Tabs
                                                defaultActiveKey="header"
                                                onSelect={(e) => handleClick(e)}
                                                id="uncontrolled-tab-example"
                                                className="mb-3 nav-underline border-bottom"
                                            >
                                                <Tab eventKey="header" title="Header"></Tab>
                                                <Tab eventKey="footer" title="Footer"></Tab>
                                            </Tabs>

                                            <Tabs
                                                defaultActiveKey="preview"
                                                className="mb-3 nav-underline border-bottom"
                                            >
                                                <Tab eventKey="preview" title="Source">
                                                    <div className="mb-3">
                                                        <label className="form-check-label">Email Subject</label>
                                                        <input type="text" name="subjectLine" className="form-control mb-1" placeholder="Email Subject" value={subjectLine} onChange={(e) => subChange(e)} maxLength={100} />
                                                        {errorMessage && <span className='text-danger' style={{ fontSize: "12px" }}>{errorMessage}</span>}
                                                    </div>
                                                    <div>
                                                        <label className="form-check-label">Email Content</label>
                                                        <CustomCkEditor data={emailValue} onTextChanged={onEditorChange} />
                                                    </div>

                                                </Tab>
                                                <Tab eventKey="source" title="Preview">
                                                    <div className="card box-shadow">
                                                        <div className="card-body ck_editor">
                                                            {parse(emailValue)}
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                    <Link to={routeConsant.DASHBOARD} className="btn btn-outline-danger me-auto" type="button" data-wizard-prev-btn="data-wizard-prev-btn" >
                                        {CommonMessages.cancelButton}
                                    </Link>
                                    <ModifiedBy data={formValues} />
                                    <LastModified data={formValues} />
                                    <button className="btn btn-primary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.saveButton}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default EmailHeaderFooter
