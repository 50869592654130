import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeaderTab from "../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import Select from "react-select";
import axios from "axios";
import {
  GETALLFUTUREAUCTION,
  GETALLLOTS,
  REARRANGEMULTIPLELOTSFORAUCTION,
} from "../../common/apiConstatnts";
import { useNavigate } from "react-router-dom";
import { removeHtmlTags } from "../../Utils/Utils";
import {
  CommonMessages,
  InventoryMessages,
} from "../../common/messageConstants";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import handleError from "../../Utils/ApiCommonErrorMessages";

const RenumberLots = () => {
  // Breadcrumb
  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: "/" },
    { label: "Inventory", url: routeConstant.INVENTORY },
    { label: "Renumber Lots", url: routeConstant.RENUMBERLOTS },
  ];

  // Token configuration
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const navigate = useNavigate();
  const [futureAuctionData, setFutureAuctionData] = useState<any>([]);
  const [originalLots, setOriginalLots] = useState<any[]>([]);
  const [massInputs, setMassInputs] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState<any>("");
  const [lotsForRenumber, setLotsForRenumber] = useState<any>([]);
  const [sortedInputValue, setSortedInputValue] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<any[]>([]);
  const [dragStartData, setDragStartData] = useState<any>({});
  const [dragEnterData, setDragEnterData] = useState<any>({});
  const [duplicateEntries, setDuplicateEntries] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  // API call for getting all future auctions
  const getAllFutureAuctions = () => {
    axios.get(GETALLFUTUREAUCTION, config).then((response) => {
      if (response?.data?.success === "Fail") {
        return;
      } else {
        const futureAuction = response.data;
        setFutureAuctionData(futureAuction.data);
      }
    });
  };

  const getAllLots = () => {
    setLoading(true);
    axios
      .get(`${GETALLLOTS}?pageNumber=-1`, config)
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          return;
        } else {
          const lotData = response?.data?.data?.data;
          setOriginalLots(lotData);
          // if (selectedAuctionForRearrange) {
          //     const lotDataUpdated = lotData.filter(
          //         (oData: any) => oData.auctionId === selectedAuctionForRearrange
          //     );
          //     setLotsForRenumber(lotDataUpdated);
          // }
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };
  useEffect(() => {
    getAllLots();
  }, []);

  // Edit function for mass edit input field
  const handleMassEditInputChange = (lRenumber: any, e: any) => {
    const newValue = e.target.value.trim();
    if (newValue <= 0 && newValue !== "") {
      toast.error("Lot number should be greater than 0 !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else {
      // Update new values in new lot number
      const updatedData = lotsForRenumber.map((lData: any) => {
        if (lData.lotId === lRenumber.lotId) {
          return { ...lData, newLotNo: e.target.value };
        } else {
          return lData;
        }
      });
      setLotsForRenumber(updatedData);

      if (newValue === "") {
        setInputValue(
          inputValue.filter((item) => item.lotId !== lRenumber.lotId)
        ); // Remove item from array
      } else {
        const updatedValuesArray = [...inputValue]; // Clone the existing inputValue array
        const existingItemIndex = updatedValuesArray.findIndex(
          (item) => item.lotId === lRenumber.lotId
        );
        if (existingItemIndex !== -1) {
          updatedValuesArray[existingItemIndex] = {
            lotId: lRenumber.lotId,
            newLotNo: newValue,
          }; // Update existing item
        } else {
          updatedValuesArray.push({
            lotId: lRenumber.lotId,
            newLotNo: newValue,
          }); // Add new item
        }
        setInputValue(updatedValuesArray);

        // Check for duplicates after updating inputValue
        const duplicates: any = updatedValuesArray.filter(
          (item: any, index: any) =>
            updatedValuesArray.some(
              (elem, idx) => elem.newLotNo === item.newLotNo && idx !== index
            )
        );
        if (duplicates.length > 0) {
          setDuplicateEntries(duplicates); //Add duplicate records in array
          // toast.error('Duplicate lot numbers are not allowed !', {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 2000,
          // })
        } else {
          setDuplicateEntries([]); // Clear duplicates if no longer present
        }
      }
    }
  };

  // When press Tab key then value should be auto increment
  {
    /* const onKeyDown = (e: any) => {
        if (e.key == "Tab") {
            let value1 = parseInt(inputValue[0].newLotNo, 10) - 1; // Subtract 1 to start from the correct initial value
            const updatedData = lotsForRenumber.map((lData: any) => {
                value1++; // Increment value1 before using it
                const newLotNo = value1.toString(); // Convert to string for the current item
                return { ...lData, newLotNo }; // Update newLotNo with the correct value
            });
            setLotsForRenumber(updatedData);

            if (inputValue.length > 0) {
                let value = inputValue[0].newLotNo;
                lotsForRenumber.filter((item: any) => item.lotId !== inputValue[0].lotId).map((item: any) => {
                    value++
                    return (
                        inputValue.push({ lotId: item.lotId, newLotNo: value.toString() })

                    )
                })
                setInputValue(inputValue)
                setSortedInputValue(inputValue)
            }

        }
    }
    */
  }

  // Drag table rows
  const handleDragStart = (e: any, data: any) => {
    !massInputs && setDragStartData(data);
  };
  
  const handleDragEnter = (data: any, e: any) => {
    !massInputs && setDragEnterData(data);
  };

  const newFun = async (data: any[]): Promise<any[]> => {
    data = data.filter((nData: any) => Object.keys(nData).length > 0);
    data = data.map((nData: any, nIndex: any) => {
      return { ...nData, newLotNo: nIndex + 1 }; 
    });
    return data;
  };

  const handleDragEnd = async (e: any) => {
    if (!massInputs) {
      let data = lotsForRenumber;
      const dragItemIndex = data.findIndex((item: any) => String(item.lotId) == String(dragStartData.lotId));
      const dropItemIndex = data.findIndex((item: any) => String(item.lotId) == String(dragEnterData.lotId));
      const temp = data[dragItemIndex];
      data[dragItemIndex] = data[dropItemIndex];
      data[dropItemIndex] = temp;
      try {
        const updatedData = await newFun(data);
        const newData = [...updatedData,{}]
        setLotsForRenumber(newData);
      } catch (error) {
        console.error("Error processing data:", error);
      }
    }
  };

  // sorting for lot re-number
  const handleLotSorting = (type: string, fieldName: string) => {
    const stringNewLotNos = lotsForRenumber.filter(
      (item: any) => typeof item.newLotNo === "string"
    );
    const numberNewLotNos = lotsForRenumber.filter(
      (item: any) => typeof item.newLotNo === "number"
    );

    const sortedData = [...stringNewLotNos];
    if (type === "ASC") {
      sortedData.sort((a, b) => a.newLotNo - b.newLotNo);
      setSortedInputValue(
        [...inputValue].sort((a, b) => a.newLotNo - b.newLotNo)
      );
    } else if (type === "DESC") {
      sortedData.sort((a, b) => b.newLotNo - a.newLotNo);
      setSortedInputValue(
        [...inputValue].sort((a, b) => b.newLotNo - a.newLotNo)
      );
    }
    setLotsForRenumber([...sortedData, ...numberNewLotNos]);
  };

  const handleRenumberClose = () => {
    setSelectedAuction("");
    setLotsForRenumber([]);
    navigate(routeConstant.INVENTORY);
  };

  // Save new renumber lots
  const handleRenumber = () => {
    if (massInputs) {
      const duplicates: any = inputValue.filter((item: any, index: any) =>
        inputValue.some(
          (elem, idx) => elem.newLotNo === item.newLotNo && idx !== index
        )
      );
      if (duplicates.length > 0) {
        // setDuplicateEntries(duplicates)
        toast.error("Duplicate lot numbers are not allowed !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else if (inputValue.length !== lotsForRenumber.length) {
        toast.error("All the input fields are required !", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to renumber lots!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Swap it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const filterEmpty = lotsForRenumber.filter(
              (lData: any) => Object.keys(lData).length > 0
            );
            const oldNewLotData = filterEmpty.map((fData: any) => {
              return { oldLotNo: String(fData.displayLotNo), newLotNo: String(fData.newLotNo) };
            });
            const payload = {
              auctionId: filterEmpty[0]?.auctionId ?? [],
              lstReArrangeLots: oldNewLotData,
            };
            axios.post(REARRANGEMULTIPLELOTSFORAUCTION, payload).then(
              (response) => {
                getAllLots();
                if (response?.data?.success === "Fail") {
                  toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  return;
                } else {
                  toast.success(InventoryMessages.lotRenumbering, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                }
                handleRenumberClose();
              }).catch((error) => {
                handleRenumberClose();
                setLoading(false);
                handleError(error);
              });;
          }
        });
      }
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to renumber lots!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Swap it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const filterEmpty = lotsForRenumber.filter(
            (lData: any) => Object.keys(lData).length > 0
          );          
          const oldNewLotData = filterEmpty.map((fData: any) => {
            return { oldLotNo: String(fData.displayLotNo), newLotNo: String(fData.newLotNo) };
          });
          const payload = {
            auctionId: filterEmpty[0]?.auctionId ?? [],
            lstReArrangeLots: oldNewLotData,
          };
          axios.post(REARRANGEMULTIPLELOTSFORAUCTION, payload).then(
            (response) => {
              getAllLots();
              if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                return;
              } else {
                toast.success(InventoryMessages.lotRenumbering, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
              handleRenumberClose();
            }
          ).catch((error) => {
            handleRenumberClose();
            setLoading(false);
            handleError(error);
          });;
        }
      });
    }
  };
  // Call future auctions
  useEffect(() => {
    getAllFutureAuctions();
  }, []);
  useEffect(() => {
    const lotData = originalLots.filter(
      (oData: any) => oData.auctionId === selectedAuction
    );
    setLotsForRenumber(
      lotData.map((lData: any, index: any) => {
        return { ...lData, newLotNo: index + 1 };
      })
    );
  }, [selectedAuction]);

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0 pb-3 container-fluid for-fix-bottom-space">
        <InnerHeaderTab breadCrumb={breadcrumbData} title="Renumber Lots">
          <div className="d-flex flex-wrap align-items-end">
            <div className="col-sm-5 ps-0">
              <div className="mt-2 mb-2">
                {futureAuctionData.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select Auction"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="auctionId"
                    options={futureAuctionData?.map((fData: any) => {
                      return {
                        value: fData.auctionId,
                        label: `${fData.auctionCode} - ${fData.auctionTitle}`,
                      };
                    })}
                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                    onChange={(e: any) => {
                      setSelectedAuction(e.value);
                      // const lotData = originalLots.filter(
                      //     (oData: any) => oData.auctionId === e.value
                      // );

                      // setLotsForRenumber(
                      //     lotData.map((lData: any, index: any) => {
                      //         return { ...lData, newLotNo: index + 1 };
                      //     })
                      // );
                      setInputValue([]);
                      setMassInputs(false);
                    }}
                  />
                ) : (
                  <span>No future auctions available</span>
                )}
              </div>
            </div>
            <div className="col-auto mt-2 d-flex flex-wrap align-items-center ms-auto">
              {lotsForRenumber?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-outline-primary mb-2 ms-2"
                  onClick={() => setMassInputs(true)}
                >
                  Mass Edit
                </button>
              )}
            </div>
          </div>
        </InnerHeaderTab>
        <form noValidate>
          <div className="container-fluid">
            <div className="row top_border">
              <div className="col-12">
                <div className="border-300">
                  <div className="table-responsive p-0 height_minus_300 scrollbar border th-sticky">
                    <table className="table fs--1 mb-0 border-top border-200">
                      <thead>
                        <tr>
                          <th className="ps-2 pe-2 bg-gradiant col-1">Lot #</th>
                          {massInputs && (
                            <th
                              className="ps-2 pe-2 bg-gradiant col-1"
                              scope="col"
                              data-sort="Lots"
                            >
                              <div className="d-flex align-items-center">
                                New Lot #
                                <div className="sorting_group">
                                  <button
                                    className="arrow-up"
                                    type="button"
                                    onClick={() =>
                                      handleLotSorting("ASC", "newLotNo")
                                    }
                                  ></button>
                                  <button
                                    className="arrow-down"
                                    type="button"
                                    onClick={() =>
                                      handleLotSorting("DESC", "newLotNo")
                                    }
                                  ></button>
                                </div>
                              </div>
                            </th>
                          )}
                          <th className="ps-2 pe-2 bg-gradiant">Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lotsForRenumber.length > 0 ? (
                          lotsForRenumber.map((lRenumber: any, index: any) => {
                            const isDuplicate =
                              duplicateEntries.length > 0 &&
                                duplicateEntries.find(
                                  (item: any) => item?.lotId == lRenumber?.lotId
                                )
                                ? true
                                : false;
                            if (Object.keys(lRenumber).length > 0) {
                              return (
                                <>
                                  <tr
                                    draggable={!massInputs && true}
                                    onDragStart={(e) =>
                                      handleDragStart(e, lRenumber)
                                    }
                                    onDragEnter={(e) =>
                                      handleDragEnter(lRenumber, e)
                                    }
                                    onDragEnd={(e) => handleDragEnd(e)}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{
                                      cursor: massInputs ? "auto" : "grabbing",
                                    }}
                                  >
                                    <td className="ps-2 pe-2">
                                      {lRenumber.displayLotNo}
                                    </td>
                                    {massInputs && (
                                      <td className="ps-2 pe-2">
                                        <input
                                          key={lRenumber?.lotId}
                                          className={`position-inherit form-control ${isDuplicate
                                            ? "border border-danger border-width-2"
                                            : ""
                                            }`}
                                          type="text"
                                          pattern="[a-zA-Z0-9]*"
                                          defaultValue={
                                            sortedInputValue.find(
                                              (item) =>
                                                item.lotId === lRenumber.lotId
                                            )?.newLotNo || ""
                                          }
                                          onFocus={(e) => {
                                            const defaultValue =
                                              e.target.value.trim();
                                            if (inputValue.length >= 1) {
                                              if (defaultValue === "") {
                                                const lastUpdatedRecord =
                                                  inputValue[
                                                  inputValue.length - 1
                                                  ]; // Get the last updated record
                                                const isDuplicate =
                                                  inputValue.some(
                                                    (item) =>
                                                      item.lotId ===
                                                      lRenumber.lotId
                                                  );
                                                if (!isDuplicate) {
                                                  const previousLotNo =
                                                    lastUpdatedRecord?.newLotNo ||
                                                    0;
                                                  if (!isNaN(previousLotNo)) {
                                                    const incrementedValue =
                                                      parseInt(previousLotNo) + 1;
                                                    e.target.value =
                                                      incrementedValue.toString();
                                                  }
                                                  handleMassEditInputChange(
                                                    lRenumber,
                                                    e
                                                  ); // Call your existing onChange handler
                                                }
                                              }
                                            }
                                          }}
                                          onInput={(e: any) => {
                                            // Remove any non-alphanumeric characters
                                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                          }}
                                          onChange={(e) =>
                                            handleMassEditInputChange(
                                              lRenumber,
                                              e
                                            )
                                          }
                                          // onKeyDown={onKeyDown}
                                          required
                                        />
                                        {isDuplicate && (
                                          <span className="text-danger ml-2">
                                            Duplicate entry not allowed
                                          </span>
                                        )}
                                      </td>
                                    )}
                                    <td className="ps-2 pe-2">
                                      {lRenumber.title
                                        ? removeHtmlTags(lRenumber.title)
                                        : "-"}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan={3} className="ps-2 pe-2 text-center">
                                {selectedAuction === ""
                                  ? "Please select auction."
                                  : "No lots mapped with this auction."}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom">
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleRenumberClose}
            >
              {CommonMessages.backButton}
            </button>
            {futureAuctionData.length > 0 && (
              <button
                className="btn btn-primary mx-2"
                type="button"
                onClick={handleRenumber}
                disabled={lotsForRenumber.length === 0}
              >
                Renumber lots
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default RenumberLots;
