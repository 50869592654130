import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { setAuctionInfoActiveTab } from "../../../../store/actions/Seller";
import { CommonMessages, SellerMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import MomentUtil from "../../../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../../../Utils/useDateTime";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { ERROR_MESSAGES } from "../../../../Utils/Utils";
import Swal from "sweetalert2";
import { Dropdown, Modal } from "react-bootstrap";
import DateTime from "../../../../Utils/DateTime";
import moment from "moment";
import { wordCounter } from "../../../../common/constants";
import Select from 'react-select';
import axios from "axios";
import { GETSELLERNOTES, UPDATESELLERNOTES } from "../../../../common/apiConstatnts";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

const SellerNotes = () => {
    const dispatch = useDispatch()
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const enteredBy = decodedToken.user;
    const sellerData = useSelector((state: any) => state.seller);
    const sellerId: any = sellerData?.sellerId ?? 0;
    const auctionId = sellerData?.selectedAuction?.value ?? 0;
      
    const status: any = [
        { value: true, label: "Done" },
        { value: false, label: "Pending" }
    ]

    const type = [
        { value: "Phone", label: "Phone" },
        { value: "Email", label: "Email" },
        { value: "Mail", label: "Mail" },
        { value: "Fax", label: "Fax" },
        { value: "Other", label: "Other" },
    ]

    const initialData = {
        sellerAuctionNotesId: 0,
        logNoteDate: "",
        logNoteTime: "00:00:00",
        description: "",
        enteredBy: enteredBy,
        status: false,
        logNotesType: "",
        isActive: true,
        isDelete: false,
    }

    const [searchText, setSearchText] = useState('')
    const [toggleModal, setToggleModal] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [sellerNotes, setSellerNotes] = useState([]);
    const [originalSellerNotes, setOriginalSellerNotes] = useState([]);
    const [filterSellerNotes, setFilterSellerNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showOpt, setShowOpt] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(0);
    const maxWordCount = 250
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const PER_PAGE = 5;
    const pageCount = Math.ceil(sellerNotes?.length / PER_PAGE);
    const yesterday = moment().subtract(1, 'day');

    const disablePastDt = (current: any) => {
        return current.isAfter(yesterday);
    };

    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const dateTime = useCurrentDateTime()
    const schema: any = yup.object().shape({
        logNoteDate: yup.string().required('Date is required.').test('valid-datetime', 'Invalid datetime', function (value) {
            const parsedDate: any = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ" });
            const currentDate: any = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });

            if (
                parsedDate.year() < 2000 ||
                parsedDate.year() > 2500 ||
                (!ID && parsedDate.diff(currentDate, 'minutes') < 0)
            ) {
                return this.createError({
                    message: 'Please enter a valid date that is greater than the current UTC date.',
                });
            }

            // Calculate the difference in minutes between parsed date and current date
            const timeDifferenceInMinutes = parsedDate.diff(currentDate, 'minutes');

            if (timeDifferenceInMinutes < 0) {
                return this.createError({
                    message: 'The start time must be in the future.',
                });
            }

            return true;

        }),
        description: yup.string().required('Description is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            })
        ,
        logNotesType: yup.string().required('Please select type'),
        status: yup.string().required('Please select status'),
    })
    const {
        register: sellerRegister,
        handleSubmit: sellerHandleSubmit,
        getValues: sellerGetValues,
        setValue: sellerSetValue,
        reset: sellerReset,
        watch: sellerWatch,
        control,
        formState: { errors: sellerErrors, isValid: sellerIsValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })
    const watchStatus = sellerWatch('status')
    const logNotesType = sellerWatch('logNotesType')

    const onSubmitNotes = () => {
        const data = sellerGetValues();
        if (data.status == true || data.status == "true") {
            data.status = true;
        } else {
            data.status = false;
        }
        const payload = {
            sellerAuctionNotesId: data?.sellerAuctionNotesId,
            date: moment(data.logNoteDate).format("YYYY-MM-DD"),
            dateDue: moment(data.logNoteDate).format("YYYY-MM-DD"),
            auctionId: auctionId,
            time: moment(data.logNoteDate).format("HH:mm:ss"),
            description: data?.description,
            enteredBy: enteredBy,
            status: data?.status,
            sellerId: sellerId,
            logNotesType: data?.logNotesType,
            isActive: true,
            isDelete: false,
            companyId: parseInt(sellerData?.companyId)
        }
        setLoading(true);
        axios.post(UPDATESELLERNOTES, payload).then((response: any) => {
            setLoading(false);
            setToggleModal(!toggleModal)
            toast.success(data?.sellerAuctionNotesId == 0 ? SellerMessages.sellerNoteCreated : SellerMessages.sellerNoteUpdated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            getSellerNotes();
        }).catch((error) => {
            setLoading(false);
            handleError(error)
        });
    }

    const getSellerNotes = () => {
        setLoading(true);
        axios.get(`${GETSELLERNOTES}?sellerId=${sellerId}&auctionId=${auctionId}`).then((response: any) => {
            setLoading(false);
            const data = response?.data?.data;
            if (data) {
                paginateData(data);
                setOriginalSellerNotes(data);
            }
        })
    }

    useEffect(() => {
        getSellerNotes();
    }, [])

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = sellerNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = sellerNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName);
    }
    const handleEditModal: any = (data: any, mode: string) => {
        
        if (mode == 'delete') {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const payload = {
                        sellerAuctionNotesId: data?.sellerAuctionNotesId,
                        date: moment(data.logNoteDate).format("YYYY-MM-DD"),
                        dateDue: moment(data.logNoteDate).format("YYYY-MM-DD"),
                        auctionId: auctionId,
                        time: moment(data.logNoteDate).format("HH:mm:ss"),
                        description: data?.description,
                        enteredBy: enteredBy,
                        status: data?.status,
                        sellerId: sellerId,
                        logNotesType: data?.logNotesType,
                        isActive: mode == 'delete' ? false : true,
                        isDelete: mode == 'delete' ? true : false,
                        companyId: parseInt(sellerData?.companyId)
                    }
                    setLoading(true);
                    axios.post(UPDATESELLERNOTES, payload).then((response: any) => {
                        setLoading(false);
                        toast.success(SellerMessages.sellerNoteDeleted, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        getSellerNotes();
                        setCurrentPage(0)
                    }).catch((error) => {
                        setLoading(false);
                        handleError(error)
                    });
                }
                return
            })
            return

        } else {
            data = {
                ...data,
                logNoteDate: moment(data.logNoteDate).format('YYYY-MM-DDTHH:mm:ss'),
                logNoteTime: moment(data.logNoteTime).format('HH:mm:ss'),
                isActive: true,
                isDelete: false,
            }
            setMode('update');
            setShowOpt('');
            setToggleModal(!toggleModal);
            sellerReset(data);
        }
    }

    const handleClose = () => {
        sellerReset(initialData)
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    sellerReset(initialData)
                    setToggleModal(!toggleModal)
                    setRemainingCount(maxWordCount)
                }
            })
            return
        }
        setToggleModal(!toggleModal)
        setRemainingCount(maxWordCount)
    }
    // Paginated data
    const paginateData = (data: any) => {
        setSellerNotes(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterSellerNotes(firstSet)
    }

    // Go to previous
    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('notes'));
    }
    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('docs'));
    }
    // Search handler
    const handleSearch = (value: any) => {
        setSearchText(value);
        if (value === '') {
            getSellerNotes();
            return
        }
        const filterData: any = originalSellerNotes?.filter((x: any) => x.description?.toLocaleLowerCase()?.includes(value) ||
            x.enteredBy?.toLocaleLowerCase()?.includes(value) ||
            x.logNotesType?.toLocaleLowerCase()?.includes(value));
        paginateData(filterData);
        setCurrentPage(0);
    }
    return (
        // Modal for create/edit
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={toggleModal} size='xl' centered>
                <form noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'New Seller Log Note' : 'Edit Seller Log Note'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label className="form-label">Date  ({defaultTimezone})<sup className="text-danger">*</sup></label>
                                <div className="date_icon">
                                    <Controller
                                        name="logNoteDate"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    {...sellerRegister('logNoteDate')}
                                                    onChange={(date: any) => {
                                                        onChange(date);
                                                    }}
                                                    closeOnSelect={true}
                                                    dateFormat="MM/DD/y"
                                                    timeFormat="hh:mm A"
                                                    value={value}
                                                    isValidDate={disablePastDt}
                                                    inputProps={
                                                        {
                                                            placeholder: 'Select Date and Time',
                                                            onInput: (e: any) => {
                                                                const cursorPosition: any = e.target.selectionStart;
                                                                onChange(e.target.value);
                                                                e.target.setSelectionRange(cursorPosition, cursorPosition);
                                                            },
                                                        }} />

                                            </div>
                                        )}
                                    />
                                </div>
                                {sellerErrors.logNoteDate && <span className='text-danger' style={{ fontSize: "12px" }}>{sellerErrors.logNoteDate.message}</span>}

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">Description <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <textarea
                                        className="form-control form-icon-input"
                                        rows={4}
                                        cols={4}
                                        maxLength={maxWordCount}
                                        {...sellerRegister('description', { onChange: (e: any) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                    />
                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}
                                    {sellerErrors.description && <span className='text-danger' style={{ fontSize: "12px" }}>{sellerErrors.description.message}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Status <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...sellerRegister('status')}
                                    name='status'
                                    options={status}
                                    value={status?.find((option: any) => option?.value == watchStatus)}
                                    onChange={(e: any) => sellerSetValue('status', e.value)}
                                />
                                {sellerErrors.status && <span className='text-danger' style={{ fontSize: "12px" }}>{sellerErrors.status.message}</span>}
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Log Note Type <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...sellerRegister('logNotesType')}
                                    name='logNotesType'
                                    options={type}
                                    value={type?.find((option: any) => option?.value == logNotesType)}
                                    onChange={(e: any) => sellerSetValue('logNotesType', e.value)}
                                />
                                {sellerErrors.logNotesType && <span className='text-danger' style={{ fontSize: "12px" }}>{sellerErrors.logNotesType.message}</span>}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" onClick={sellerHandleSubmit( onSubmitNotes)} type="submit">{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center">
                    <div className="col-xxl-10 mt-3">
                        <h4>Seller Notes</h4>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="search-box">
                                <div className="d-flex align-items-center">
                                    <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                        <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => handleSearch(e.target.value)} />
                                        <span className="fas fa-search search-box-icon"></span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                onClick={() => {
                                    sellerReset(initialData)
                                    setToggleModal(!toggleModal);
                                    setMode('create');
                                }}
                            >
                                <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Seller Notes </span>
                            </button>
                        </div>

                        {/* Grid for seller notes */}
                        <div className="bg-white mb-5 border-300">
                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                <table className="table fs--1 mb-0 border-top border-200">
                                    <thead>
                                        <tr>
                                            <th className="bg-gradiant " scope="col">Action</th>
                                            <th className="bg-gradiant " scope="col" data-sort="Date">
                                                <div className='d-flex align-items-center'>
                                                    <span>Date</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="description">
                                                <div className='d-flex align-items-center'>
                                                    <span>Description</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'description')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'description')}  ></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col" data-sort="enteredBy">
                                                <div className='d-flex align-items-center'>
                                                    <span>Entered By</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'enteredBy')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'enteredBy')} ></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col" data-sort="type">
                                                <div className='d-flex align-items-center'>
                                                    <span>Type</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col" data-sort="status">
                                                <div className='d-flex align-items-center'>
                                                    <span>Status</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-latest-review-body">
                                        {filterSellerNotes?.length > 0 ? filterSellerNotes.map((data: any, index: any) => {
                                            return (
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                    <td className="align-middle white-space-nowrap text-start p-2">
                                                        <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                            <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                                <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                </button>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data, 'update')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td className="align-middle  p-2">
                                                        <a className="d-flex align-items-center">
                                                            <h6 className="mb-0 text-900">{moment(data?.logNoteDate).format('MM/DD/YYYY')}</h6>
                                                        </a>
                                                    </td>
                                                    <td className="align-middle  p-2">
                                                        <a className="d-flex align-items-center">
                                                            <h6 className="mb-0 text-900">{data?.description}</h6>
                                                        </a>
                                                    </td>
                                                    <td className="align-middle review p-2">
                                                        <a className="d-flex align-items-center">
                                                            {data?.enteredBy}
                                                        </a>
                                                    </td>
                                                    <td className="align-middle  p-2">
                                                        <a className="d-flex align-items-center">
                                                            <h6 className="mb-0 text-900">{data?.logNotesType ?? '-'}</h6>
                                                        </a>
                                                    </td>
                                                    <td className="align-middle text-start status p-2">
                                                        {data?.status == true ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                            <span className="badge-label">Done</span>
                                                        </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                            <span className="badge-label">Pending</span>
                                                        </span>}
                                                    </td>
                                                </tr>
                                            )
                                        }) : <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={8}>No records found!</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            {filterSellerNotes.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={currentPage}
                                />)}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                    <div>
                        <button className="btn btn-secondary" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                    </div>
                    <div>
                        <button className="btn btn-primary mb-1 ms-2" type="button" onClick={handleNext}>{CommonMessages.saveNext}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerNotes