import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const setDateRangeData = (data: any): Action => ({
    type: action.SETDATERANGE,
    payload: data
})

export const setSearchData = (data: any): Action => ({
    type: action.SETSEARCHDATA,
    payload: data
})