import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { GETLOTDOCUMENTSBYLOTID, UPDATELOTDOCUMENT } from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages, errorMessages } from "../../../../common/messageConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import * as routeConstant from '../../../../common/routeConstants';
import { setStep } from "../../../../store/actions/Inventory";

const LotDocument = () => {
    const dispatch = useDispatch()
    const lotId = useSelector((state: any) => state.inventory.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const mode = useSelector((state: any) => state.inventory.mode)
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/pdf"];
    const [docsData, setdocsData] = useState<any>([]);
    const [OriginaldocsData, setOriginaldocsData] = useState<any>([]);
    const [filterDocs, setFilterDocs] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [docUpload, setDocUpload] = useState<boolean>(false);
    const [lotDocument, setLotDocument] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState('');

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            enctype: "multipart/form-data",
        }
    };

    const PER_PAGE = 10;
    const pageCount = Math.ceil(docsData?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setdocsData(data);
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterDocs(firstSet);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase();
        if (keyword.length > 0) {
            const filterData: any = OriginaldocsData?.filter((x: any) => x?.documentName?.toLocaleLowerCase()?.includes(keyword))
            paginateData(filterData)
            setCurrentPage(0)
        } else {
            setDefaultData()
        }
    }

    const handleUpload = (e: any) => {
        const filesLength = e.target.files?.length;
        if (!allowedTypes.includes(e.target.files[0]?.type)) {
            toast.warning(errorMessages.formatNotAllowed, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            e.target.value = null;
            return;
        } else {
            let uploadedData: any = []
            for (let i = 0; i < filesLength; i++) {
                const file = e.target.files[i]
                if (!allowedTypes.includes(file?.type)) continue
                const docObj = {
                    lotscannedDocumentId: 0,
                    lotId: mode === "clone" ? cloneLotId : lotId,
                    scannedDocument: file,
                    documentName: "",
                    isActive: true,
                    isDelete: false,
                    uniqueId: uuidv4()
                }
                uploadedData.push(docObj)
            }
            setLotDocument([...lotDocument, ...uploadedData])
        }
    }

    const handleSubmit = (e: any) => {
        setLoading(true)
        e.preventDefault();
        const formData: any = new FormData();
        const finalData = lotDocument?.filter((data: any) => Object.keys(data)?.length > 0);

        if (finalData.length > 0) {
            let i = 0;
            finalData.forEach((doc: any) => {
                formData.append(`photos[${i}].lotscannedDocumentId`, doc.lotscannedDocumentId);
                formData.append(`photos[${i}].lotId`, doc.lotId);
                formData.append(`photos[${i}].scannedDocument`, doc.scannedDocument);
                formData.append(`photos[${i}].documentName`, doc.scannedDocument ? doc.scannedDocument : "");
                formData.append(`photos[${i}].isActive`, doc.isActive);
                formData.append(`photos[${i}].isDelete`, doc.isDelete)
                i++
            });
            axios.post(UPDATELOTDOCUMENT, formData).then(async (response: any) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    const data = response?.data?.data;
                    setdocsData([...docsData, ...data]);
                    setLoading(false);
                    toast.success(InventoryMessages.lotDocAdded, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    setDefaultData()
                    setLotDocument([]);
                    setDocUpload(false);
                    setSearchText('');
                }
            }, (error: any) => {
                setLoading(false);
                setLotDocument([]);
                setDocUpload(false);
                setSearchText('');
                toast.warning("Please upload file upto 15 mb.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 4000,
                });
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });;
        } else {
            setLoading(false);
            setCurrentPage(0);
            toast.error(errorMessages.nothingToSave, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }

    const deleteDocument = (id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this document!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const mergeData = OriginaldocsData.map((oData: any) => {
                    if (oData.lotscannedDocumentId == id) {
                        return { ...oData, isActive: false, isDelete: true }
                    }
                    return oData
                })
                const formData: any = new FormData();
                let i = 0;
                mergeData.forEach((doc: any) => {
                    formData.append(`photos[${i}].lotscannedDocumentId`, doc.lotscannedDocumentId);
                    formData.append(`photos[${i}].lotId`, doc.lotId);
                    formData.append(`photos[${i}].scannedDocument`, doc.scannedDocument);
                    formData.append(`photos[${i}].documentName`, doc.documentName);
                    formData.append(`photos[${i}].isActive`, doc.isActive);
                    formData.append(`photos[${i}].isDelete`, doc.isDelete)
                    i++
                });
                axios.post(UPDATELOTDOCUMENT, formData).then(async (response: any) => {
                    setLoading(false);
                    if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    } else {
                        toast.success(InventoryMessages.lotDocDeleted, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        setDefaultData()
                        setLoading(false);
                        setDocUpload(false);
                        setSearchText('');
                    }
                }).catch( (error: any) => {
                    setLoading(false);
                    setDocUpload(false);
                    setSearchText('');
                });
            }
        })
    }

    const setDefaultData = () => {
        setLoading(true)
        const id = mode === "clone" ? cloneLotId : lotId
        axios.get(`${GETLOTDOCUMENTSBYLOTID}?lotId=${id}`, { ...config }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setdocsData([]);
                setOriginaldocsData([])
                paginateData([])
                return;
            } else {
                const data = response.data.data;
                setdocsData(data);
                setOriginaldocsData(data)
                paginateData(data)
            }
        }).catch((error) => {
            setLoading(false)
        });
    }

    const handleDownload = (docs: any) => {
        const downloadUrl = `${docs.scannedDocument}?${docs.sasToken}`;
        const anchor = document?.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = docs;
        anchor.target = '_blank'
        anchor.click();
    }

    const handlePrevious = () => {
        /* dispatch(setStep('docURls')); */
        dispatch(setStep('notes'));
    }

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = docsData?.slice(offset, offset + PER_PAGE);
        setFilterDocs(nextSet);
    }, [currentPage])

    useEffect(() => {
        paginateData(docsData)
    }, [docsData])

    useEffect(() => {
        setDefaultData();
    }, [])

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText])

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Modal show={docUpload} className='md-modal' centered>
                <form noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Upload Lot Document</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => setDocUpload(false)}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <p className='form-label mb-2 fs--1'>Upload Lot Document : PDF or Docs File</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-lg-12'>
                                <label className="form-label mb-2">Upload File</label>
                                <input
                                    type='file'
                                    className='form-control'
                                    onChange={handleUpload}
                                    multiple
                                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={() => { setDocUpload(false) }}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="button" onClick={handleSubmit}>Upload</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <div className="col-xxl-10">
                        <div className="mt-2 mb-3 border-300">
                            <div className="row">
                                <div className="col-12 mt-3 d-flex flex-column justify-content-start">
                                    <h4>Lot Documents</h4>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <div className="search-box mt-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                                    <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-1">
                                            <button type="button" className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => setDocUpload(true)}>
                                                <i className="fas fa-plus"></i>
                                                <span>Add Document</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","seller","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Action</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex '>
                                                                    <span>Sr No.</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Ctrl">
                                                                <div className='d-flex'>
                                                                    <span>Lot Document Name</span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list scrollbar" id="table-latest-review-body">
                                                        {filterDocs?.length > 0 ?
                                                            filterDocs?.map((doc: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        <td className="align-middle white-space-nowrap text-start p-2">
                                                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant="none">
                                                                                    <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                    <Dropdown.Item onClick={() => handleDownload(doc)} as='button' className="dropdown-item d-flex align-items-center justify-content-between">Download  <i className="fas fa-download"></i></Dropdown.Item>
                                                                                    <Dropdown.Item as='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => deleteDocument(doc?.lotscannedDocumentId)} >Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td className="p-2">{`${PER_PAGE * currentPage + index + 1}`}</td>
                                                                        <td className="align-middle seller white-space-nowrap p-2">
                                                                        <Link
                                                                                target="_blank"
                                                                                to={`${doc?.scannedDocument}?${doc?.sasToken}`}
                                                                            >{doc?.documentName ?? "-"}</Link>
                                                                            </td>
                                                                        {/* <td className="align-middle seller white-space-nowrap p-2">
                                                                            <Link
                                                                                target="_blank"
                                                                                to={`${process.env.REACT_APP_API_URL}${doc?.scannedDocument}`}
                                                                            >{doc?.scannedDocument ? doc?.scannedDocument?.split("\\")[3] : "-"}</Link>
                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={5}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterDocs?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom z-index-3">
                        <button className="btn btn-secondary " type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal">{CommonMessages.cancelButton}</Link>
                        <Link to={routeConstant.INVENTORY} className="btn btn-primary ms-2 " type="button" data-bs-dismiss="modal">Finish</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LotDocument;