import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as yup from 'yup';
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../../../Utils/Utils";
import { GETAUTOINVOICEBYAUCTIONID, UPDATEAUTOINVOICE } from "../../../../common/apiConstatnts";
import { BPMethod, invoicePrintPdfPref, wordCounter } from "../../../../common/constants";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants";
import { DECIMAL73, DECIMAL73_MESSAGE } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInvoiceActive, setCloneTab, setCreateTab, setStep } from "../../../../store/actions/AuctionAction";
import { loader } from "../../../../store/actions/CommonAction";
import { AUTOINVOICING } from "../../AuctionConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const AutoInvoicing = () => {
    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const initialData = {
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        bpMethod: 1,
        creditCardPaymentSurcharge: "",
        bpPaymentTypeOfUse: "",
        invoiceAdjustmentPercentageToAdd: "",
        includeTaxCalculation: false,
        printInvoice: false,
        oneSellerOnlyPerInvoice: false,
        includeCashDiscLineInvoice: "",
        alwaysCreatePDFInvoice: false,
        alwaysCreatePaidPDFInvoice: false,
        printCustomReports: false,
        autoOneSellerOnlyPerInvoice: false,
        alwaysShowPrintDialog: false,
        pdfCustomReports: false,
        // autoInvoicing:0
    }
    const dispatch = useDispatch()
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const [formValues, setFormValues] = useState<any>(initialData);
    const maxWordCount = 100
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [btnTxt, setBtnTxt] = useState<any>("")

    const options = [
        { value: '1', label: 'Accts Rec' },
        { value: '2', label: 'ACH Transfer' },
        { value: '3', label: 'Cash' },
        { value: '4', label: 'Check' },
        { value: '5', label: 'Credit Card' },
        { value: '6', label: 'Internet' },
        { value: '7', label: 'Internet CC' },
        { value: '8', label: 'No BP' },
        { value: '9', label: 'Online' },
        { value: '10', label: 'Other' },
        { value: '11', label: 'Proceeds Pymt' },
        { value: '12', label: 'Wire Transfer' },
    ];

    const schema: any = yup.object().shape({
        bpMethod: yup.number().required('BP method is required'),
        creditCardPaymentSurcharge: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL73, DECIMAL73_MESSAGE),
        invoiceAdjustmentPercentageToAdd: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL73, DECIMAL73_MESSAGE),
        copies: yup.number().required('Copies is required')
            .typeError('Copies must be a number')
            .transform((value, originalValue) => {
                return (originalValue === '') ? undefined : value
            })
            .test(
                'maxDigits',
                'Please enter upto 9 digits',
                value => (value ? value.toString().length <= 9 : true)
            ),
        includeCashDiscLineInvoice: yup.string().required("Include cash disc line invoice is required").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }),
    })

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })
    const bpPaymentTypeOfUse = watch('bpPaymentTypeOfUse')
    const watchBpMethod = watch('bpMethod')

    const onSubmit = (dataOriginal: any) => {
        const data = trimObjectValues(dataOriginal);
        const payload = {
            ...data,
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            creditCardPaymentSurcharge: parseFloat(data?.creditCardPaymentSurcharge) ?? 0,
            bpPaymentTypeOfUse: data?.bpPaymentTypeOfUse ?? "",
            invoiceAdjustmentPercentageToAdd: parseFloat(data?.invoiceAdjustmentPercentageToAdd) ?? 0
        }
        dispatch(loader(true));
        axios.post(UPDATEAUTOINVOICE, payload, config).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUTOINVOICING]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUTOINVOICING]))
                }
                toast.success(AuctionMessages.invoiceMessages.autoInvoiceUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
            handleNext();
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    const handlePrev = () => {
        dispatch(setStep(3));
    }

    const handleNext = () => {
        dispatch(setAuctionInvoiceActive({ activeStep: 2, activeLabel: "Invoice Footer" }));
    }

    const handleCheck = (value: any) => {
        setValue('bpMethod', Number(value));
    }

    useEffect(() => {
        dispatch(loader(true))
        const aId = clonedTabs.includes(AUTOINVOICING) ? cloneAuctionId : auctionId
        axios.get(`${GETAUTOINVOICEBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                dispatch(loader(false))
                return;
            }
            else {
                dispatch(loader(false));
                let respData = response.data.data
                if (!clonedTabs.includes(AUTOINVOICING) && cloneAuctionId > 0) {
                    respData = { ...respData, autoInvoicing: 0 }
                }
                reset(respData)
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }, [])

    // render btn txt
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUTOINVOICING)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUTOINVOICING)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3">
                    <div className="col-lg-12 col-md-12 ">
                        <label className="form-label">BP method</label>
                        <div className="d-flex flex-wrap">
                            {
                                BPMethod?.map((bpData: any, index: any) => {
                                    return (
                                        <div className="form-check me-5" key={index}>
                                            <label htmlFor={`bpData_${index}`} className="form-check-label">{bpData.label}</label>
                                            <input
                                                type="radio"
                                                id={`bpData_${index}`}
                                                placeholder="BP method"
                                                className="form-check-input"
                                                value={bpData.value}
                                                checked={bpData.value === watchBpMethod}
                                                onChange={() => handleCheck(bpData.value)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                        <label className="form-label">Credit Card Payment Surcharge</label>
                        <input className="form-control" placeholder="Credit Card Payment Surcharge" {...register('creditCardPaymentSurcharge')} maxLength={11} />
                        {errors.creditCardPaymentSurcharge && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.creditCardPaymentSurcharge.message}</span>}
                    </div>
                    <div className="col-lg-6 col-md-6 ">
                        <label className="form-label">BP Payment Type to Use</label>

                        <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            options={options}
                            {...register('bpPaymentTypeOfUse')}
                            value={options?.find((option: any) => option?.value == bpPaymentTypeOfUse)}
                            onChange={(e: any) => setValue('bpPaymentTypeOfUse', e.value)}
                            className='isActive'
                        />

                        {errors.bpPaymentTypeOfUse && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.bpPaymentTypeOfUse.message}</span>}
                    </div>
                    <div className="col-12 ">
                        <label className="form-label">Invoice Adjustment % to Add</label>
                        <div className="border border-radius p-4">
                            <div className="row">
                                <div className="col-lg-4 mt-1 mb-2">
                                    <input className="form-control" {...register('invoiceAdjustmentPercentageToAdd')} placeholder="Invoice Adjustment % to Add" maxLength={11} />
                                    {errors.invoiceAdjustmentPercentageToAdd && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.invoiceAdjustmentPercentageToAdd.message}</span>}
                                </div>
                                <div className="col-lg-4 col-sm-4 mt-1 mb-2">
                                    <div className="form-check">
                                        <label className="form-label" htmlFor="includeTaxCalculation">Include tax calculation</label>
                                        <input className="form-check-input mt-1" type="checkbox" id="includeTaxCalculation" {...register('includeTaxCalculation')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                        <div className="">
                            <label className="form-label">Print Invoice</label>
                            <div className="form-check">
                                <label className="form-label" htmlFor="printInvoice">Yes</label>
                                <input className="form-check-input mt-1" type="checkbox" {...register('printInvoice')} id="printInvoice" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                        <div className="">
                            <label className="form-label">Copies <sup className="text-danger">*</sup></label>
                            <input className="form-control" type="text" placeholder="Copies" {...register('copies')} maxLength={10} onKeyDown={handleKeyDown} />
                            {errors.copies && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.copies.message}</span>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                        <div className="">
                            <label className="form-label">One Seller Only Per Invoice</label>
                            <div className="form-check">
                                <label className="form-label" htmlFor="oneSellerOnlyPerInvoice">Yes</label>
                                <input className="form-check-input mt-1" type="checkbox" {...register('oneSellerOnlyPerInvoice')} id="oneSellerOnlyPerInvoice" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                        <div className="">
                            <label className="form-label">Include Cash Disc Line on Invoice <sup className="text-danger">*</sup></label>
                            <textarea rows={3} className="form-control" {...register('includeCashDiscLineInvoice')} placeholder="Include Cash Disc Line on Invoice"
                                maxLength={maxWordCount}
                                {...register('includeCashDiscLineInvoice', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}

                            ></textarea>
                            {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                            </div>}
                            {errors.includeCashDiscLineInvoice && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.includeCashDiscLineInvoice.message}</span>}
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                        <label className="form-label ">Invoice Print/PDF Preference</label>
                    </div>
                    {
                        invoicePrintPdfPref?.map((pdfData, index) => {
                            return (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="">
                                        <span className="rounded_border ms-4">{pdfData.type}</span>
                                        <div className="form-check">
                                            <label className="form-label" htmlFor={`${pdfData.value}`}>{pdfData.label} </label>
                                            <input className="form-check-input mt-1" {...register(`${pdfData.value}`)} type="checkbox" id={`${pdfData.value}`} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                        <button className="btn btn-primary ms-2" type="submit" >{btnTxt}</button>
                        <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                    </div>
                </div>
            </form>
        </>

    )
}

export default AutoInvoicing