import React, { useEffect, useState } from 'react';
import moment from 'moment';
import MomentUtil from '../../Utils/MomentUtil';

const LastModified = ({ data }: any) => {
  const momentUtil = MomentUtil?.getInstance()
  const defaultTimezone = momentUtil?.getLiveBoardTimezoneFromLocalStorage()
  const formattedDateAndTime = (date: any) => {
    const timestamp = date;
    const formattedTime = momentUtil?.parseTimeInUtc({ dateTimeString: timestamp })?.format('MM/DD/YYYY hh:mm A ');
    // const formattedTime = moment(timestamp).format('MM/DD/YYYY hh:mm:ss A');
    if (formattedTime !== "Invalid date") {
      return formattedTime;
    }
    else {
      return "-"
    }
  };
  return (
    <>
      {data?.createdBy ?
        <div className=" d-flex align-items-center px-2 mt-1">
          <h6> {data.updatedBy == null ? `Created On (${defaultTimezone}): ${formattedDateAndTime(data.createdOn)}` : `Modified On (${defaultTimezone}): ${formattedDateAndTime(data.updatedOn)}`}</h6>
        </div> : ''}
    </>
  );
};

export default LastModified;
