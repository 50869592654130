//Login page
export const LoginMessage = {
    loginSuccessful: "Login successfully!",
    emailSent: "Email sent!",
    changePasswordSuccessfully: "Password changed successfully!"
}
//Header page
export const HeaderMessages = {
    notificationMessage: "All notifications read !"
}

//Auction Page

export const AuctionMessages = {
    //index
    indexPageMessages: {
        deleteAuction: "Auction deleted successfully!"
    },
    //auctionInfo
    auctionInfoMessages: {
        auctionCreated: "Address data created successfully!",
        auctionUpdated: "Address data updated successfully!",
        setDefaultlogoPhotos: "Set default successfully",
        logoUpdated: "Logo & Photos updated successfully!",
        changeDiscard: "Changes discarded!",
        minOneImage: "Please upload minimum one image.",
        automatedMailUpdated: "Automated email data updated successfully!",
        webTextUpdated: "Web text data updated successfully!"
    },
    //checkList
    checkListMessage: {
        checkListUpdated: "Check list updated successfully!"
    },
    //invoice
    invoiceMessages: {
        autoInvoiceUpdated: "Auto invoicing updated successfully!",
        invoiceFooterUpdated: "Invoice footer updated successfully!",
    },
    //Values
    valuesMessages: {
        bidderNumberCreated: "Bidder number created successfully !",
        buyerPremierUpdated: "Buyers premium updated successfully!",
        commCatUpdated: "Commission & categories updated successfully !",
        incrementsUpdated: "Values increments updated successfully!"
    },
    //e-Invoice
    eInvoiceMessages: {
        eInvoiceUpdates: "Email Report updated successfully!"
    },
    sellerReportMessages: {
        sellerReportUpdated: 'Seller Report  updated successfully'
    }
}

//Bid Management
export const AuctionBidMessages = {
    bidAdded: "Bid added successfully!",
    bidUpdated: "Bid updated successfully!",
    bidDeleted: "Bid deleted successfully!"
}
//Auction Default
export const AuctionDefaultMessages = {
    auctionDefaultCreated: "Auction Defaults created successfully!",
    auctionDefualtUpdated: "Auction Defaults updated successfully!"
}
export const AuctionTemplateMessages = {
    auctionTemplateCreated: "Auction template created successfully!",
    auctionTemplateUpdated: "Auction template updated successfully!",
    auctionTemplateDeleted: "Auction template deleted successfully!"
}
export const AutomateMailMessages = {
    automateEmailUpdated: "Email template updated successfully!",
    newBlankTemplate: "New blank tempalte initiated",
}
export const ReportTemplateMessages = {
    reportTemplateCreated: "Report template created successfully!",
    reportTemplateUpdated: "Report template updated successfully!",
    reportTemplateDeleted: "Report template deleted successfully!"
}
export const InvoiceTemplateMessages = {
    invoiceTemplateCreated: "Invoice template created successfully!",
    invoiceTemplateUpdated: "Invoice template updated successfully!",
    invoiceTemplateDeleted: "Invoice template deleted successfully!"
}
//Admin Config
export const BidderConfigMessages = {
    adminConfigCreated: "Admin configuration created successfully!",
    adminConfigUpdated: "Admin configuration updated successfully!",

}
//Bid increment
export const BidIncrementMessages = {
    bidIncrementUpdated: 'Bid increment updated successfully'
}
//BuyerPremiumScale
export const BuyerPremiumSalesTaxMessage = {
    bpSaleUpdated: 'BP sales tax updated successfully',
    bpSaleCreated: 'BP sales tax created successfully'
}

//BuyerPremiumScale
export const BuyerPremiumScaleMessage = {
    bpScaleUpdated: 'Buyers premium by scales created successfully',
    bpScaleCreated: 'Buyers premium by scales updated successfully'
}

//category
export const CategoryMessages = {
    categoryCreated: "Category created successfully!",
    categoryUpdated: "Category updated successfully!",
    categoryDeleted: "Category deleted successfully!"
}

//company
export const CompanyMessage = {
    companyUpdated: "Company info updated successfully!",
}

//customer

export const CustomerMessages = {
    indexPageMessages: {
        customerDeleted: "Buyer deleted successfully!"
    },
    customerCreated: "Buyer created sucessfully",
    customerUpdated: "Buyer updated sucessfully",
    addressCreated: 'Addresses created successfully',
    bidDocCreated: "Document created successfully!",
    bidDocUpdated: "Document updated successfully!",
    bidDocDeleted: "Document deleted successfully!",
    bidDefaultCanNotDeleted: "Default bidder number can not be deleted!",
    bidderNumStausChange: "Status changed successfully!",
    bidderNumDeleted: "Bidder Number Deleted successfully!",
    bidderNumCreated: "New bidder number added successfully",
}

//dashboard
export const DashboardMessages = {
    successMessages: "Search Success!"
}

//eInvoiceSetting
export const EInvoieSettingMessages = {
    eInvoiceCreated: "E-Invoice Settings created successfully!",
    eInvoiceUpdated: "E-Invoice Settings updated successfully!"
}

//emailHeaderFooter
export const emailHeaderFooterMessages = {
    emailHeaderFooterCreated: "Email template updated successfully!",
    blankTemplate: "New Blank Tempalte Initiated"
}

//emailSetting
export const EmailSettingMessages = {
    testEmailCreated: "Test Email sent successfully!",
    emailSettingUpdated: "Email setting updated successfully!"
}

//emailTemplate
export const EmailTemplateMessages = {
    emailTemplateCreated: "Email template created successfully!",
    emailTemplateUpdated: "Email template updated successfully!",
    emailTemplateDeleted: "Email template deleted successfully!"
}

//SMS Template
export const SMSTemplateMessages = {
    SMSTemplateCreated: "SMS template created successfully!",
    SMSTemplateUpdated: "SMS template updated successfully!",
    SMSTemplateDeleted: "SMS template deleted successfully!"
}

//imageConfiguration
export const ImageConfigurationMessages = {
    imgConfCreated: "Image Configuration created successfully!",
    imgConfUpdated: "Image Configuration updated successfully!"
}

//inventory
export const InventoryMessages = {
    inventoryDeleted: "Inventory deleted successfully!",
    inventorySilentUpdated: "Silent inventory Updated!",
    exportExcelProcessing: "Excel import is under processing, once imported you'll be notified!",
    lotSwap: "Lot swaped successfully!",
    lotMoved: "Lot moved successfully!",
    lotRearrange: "Lot rearrange done successfully!",
    lotRenumbering: "Lot renumbering done successfully!",
    assginedMasterInv: "Assigned master inventory to auction!",
    lotCreated: "Lot created successfully",
    lotUpdated: "Lot updated successfully",
    addressUpdated: "Address updated successfully",
    logoPhotosUpdated: "Logo & Photos updated successfully!",
    logoPhotoSetdefault: "Photo set as default successfully",
    changeDiscared: "Changes Discarded!",
    notesCreated: "Note created successfully",
    notesUpdated: "Note updated successfully",
    notesDeleted: "Note deleted successfully!",
    autoUpdated: "Auto details Updated successfully",
    autoVinVerified: "VIN verified successfully!",
    agentUpdated: "Agent updated successfully",
    deedLocUpdated: "Deed location updated successfully",
    exteriorUpdated: "Exterior updated successfully",
    interiorUpdated: "Interior updated successfully",
    prorpertyDetailUpdated: "Interior updated successfully",
    recordUpdated: "Record updated successfully",
    taxesUpdated: "Taxes updated successfully",
    silentUpdated: 'Silent invetory updated successfully',
    lotDocAdded: "Lot document added successfully!",
    lotDocDeleted: "Lot document deleted successfully!",
    MediaUpdated: "Media updated successfully!"
}

//invoice
export const InvoiceMessages = {
    eInvoiceSent: "E-Invoice email sent successfully!",
    invoiceCreated: "Invoice created successfully!",
    invoiceUpdated: "Invoice updated successfully!",
    invoiceDeleted: "Invoice deleted successfully!",
    invoiceRevCreated: "Invoice reviewed and update successfully!",
    invoiceRevUpdated: "Invoice reviewed and update successfully!",
    voidInvoice: "Invoice is void now , you can not perform any action!"
}
//lotTemplate
export const LotTempMessages = {
    lotTempCreated: "Lot template created successfully!",
    lotTempUpdated: "Lot template updated successfully!",
    lotTempCloned: "Lot template created successfully!",
    lotTempDeleted: "Lot template deleted successfully!"
}

//taxRate 
export const TaxRateMessage = {
    taxRateSaved: "Tax rate created successfully",
    taxRateUpdated: "Tax rate updated successfully",
    deleteTaxRate: "Tax rate deleted succefully"
}
//role
export const RoleMessages = {
    roleCreated: "Role created successfully",
    roleUpdated: "Role updated successfully!",
    roleDeleted: "Role deleted successfully!"
}
//salesRecords
export const SalesRecMessages = {
    salesRecCreated: "Sales record created successfully",
    salesRecUpdated: "Sales record updated successfully",
    salesRecDeleted: "Sales record deleted successfully!",
    bidderNumUpdated: "Bidder Number updated!",
    LotStatusUpdated: "Lot status is updated successfully in inventory !"
}
//salesRep
export const SalesRepMessages = {
    salesRepCreated: "Sales Representatives created successfully!",
    salesRepUpdated: "Sales representative updated successfully!",
    salesRepDeleted: "Sales representative deleted successfully!",
    adjustUpdated: "Adjust updated successfully!",
    commissionUpdated: "Commission updated successfully!",
    salesInAuctionUpdated: "Sales in auction updated successfully!"
}
//seller- confirm first
export const SellerMessages = {
    sellerCreated: "Seller created successfully!",
    sellerDeleted: "Seller deleted successfully!",
    sellerUpdated: "Seller updated successfully!",
    sellerContactUpdate: "Seller contact info updated successfully!",
    addressUpdated: "Addresses created successfully",
    adjustcreated: 'Adjusts created successfully',
    adjustUpdated: 'Adjusts updated successfully',
    deletedMsg: "Deleted successfully",
    commissionUpdted: 'Commission created successfully',
    logNotesCreated: 'Log Notes created successfully',
    paymentUpdated: 'Adjusts created successfully',
    documentAdded: "Document added successfully!",
    documentDeleted: "Document deleted successfully!",
    sellerNoteCreated: "Seller Notes created successfully!",
    sellerNoteUpdated: "Seller Notes updated successfully!",
    sellerNoteDeleted: "Seller Notes deleted successfully!",
}
//commission
export const commissionMessages = {
    taxableCreated: "Taxable states created successfully",
    taxableUpdated: "Taxable states updated successfully",
    SellerCommissionsCreated: "Seller commission saved successfully",
    SalesRepCommissionsCreated: "Sales Rep commission saved successfully"
}

//sellerReport 
export const SellerRepMessages = {
    sellerRepCreated: "Seller report created successfully!",
    sellerRepUpdated: "Seller report updated successfully!"
}

// taxCurrency 
export const AvalaraMessages = {
    avalaraUpdated: "Avalara updated successfully!"
}

//user
export const UserMessages = {
    userCreated: "User created successfully!",
    userUpdated: "User updated successfully!",
    userDeleted: "User deleted successfully!",
}

//valueDefault

export const ValueDefMessages = {
    valueDefUpdated: 'Record updated successfully'
}
//Timezone 

export const Timezone = {
    timezoneCreate: 'Time zone created successfully',
    timezoneUpdate: 'Time zone updated successfully',
    timezoneDelete: 'Time zone deleted successfully',
}
//common buttons 

export const CommonMessages = {
    saveButton: "Save",
    updateButton: "Update",
    createButton: "Create",
    updatedNext: "Update & Next",
    saveNext: "Save & Next",
    skipButton: "Skip",
    skipNext: "Skip & Next",
    nextButton: "Next",
    backButton: "Back",
    cancelButton: "Cancel",
    previousButton: "Previous",
    exportExcel: "Excel",
    exportPdf: "Pdf",
    confirmButton: "Confirm",

}

export const errorMessages = {
    error401: "Some error occurred!",
    fileSizeError: "File size must be less than 2 MB!",
    formatNotAllowed: "This file format not allowed!",
    sessionTimeOut: "Session timeout!",
    notAuthorize: "You are not authorized for this operation!",
    selectOneState: "Please select at least one state!",
    defaultBid: "Default bid can't be deleted",
    defaultRecord: "Default record can't be deleted",
    networkIssue: "Seems Like Network Issue",
    defaultValueScale: "Default valued scale can't be deleted",
    defaultScale: "Default scale can't be deleted",
    addOneScle: "Please add atleast one scale",
    addOneRange: "Please add atleast one range against each scale.",
    nothingToSave: "Nothing to save!",
    notDeleteSeller: "Cannot delete seller as inventory is assigned",
    roleAssigned: "This Role is Already Assigned to User",
    selectPermission: "Please select atleast one Permission.",
    downloadPdfError: "Error occured while downloading Pdf",
    selectImg: "Please select an image to upload",
    dataNotFound: "Data not found!",
    recordNotFound: "Record not found!",
    auctionNotFound: "Auction not found!",
    activeBidderNumber: "Aleast one bidder number should be active!",
    xlsxFormat: "Only xlsx file format is support",
    mapBeforeSwap: "Map this lot to an auction before swapping",
    mapBeforeMove: "Map this lot to an auction before moving",
    masterInvNotFound: "No Master Inventory found!",
    notDeleteCategory: "Cannot delete Category as Inventory is assigned",
    canNotPerformDelete: "You can not perform this action because invoice is verified/void",
    auctionSelectMsg : 'Please select an auction!'

}