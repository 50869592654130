import GridLayout from 'react-grid-layout';
import { SortableContainer } from 'react-sortable-hoc';
import { base } from '../../../../../common/apiConstatnts';
import SingleElement from './SingleElement';

const SortableGrid = SortableContainer(({ images, onCheckboxChange, imagesCheckedToDel }: any) => {
    return (
        <GridLayout className="layout" cols={3} rowHeight={100} width={600}>
            {
                images?.map((iData: any, iIndex: any) => (
                    <SingleElement
                        key={iIndex}
                        index={iIndex}
                        uniqueIndex={iIndex}
                        uniqueId={iData.uniqueId}
                        url={iData.lotLogoPhotosId !== 0 ? `${iData.photos}?${iData.photosWithSasToken}` : URL.createObjectURL(iData.photos)}
                        data={iData}
                        onCheckboxChange={onCheckboxChange}
                        imagesCheckedToDel={imagesCheckedToDel}
                    />
                ))
            }
        </GridLayout>
    );
});

export default SortableGrid;