import React, { useEffect, useState } from "react";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from '../Sidebar/Sidebar';
import { Breadcrumb } from '../../common/interface';
import * as routeConsant from '../../common/routeConstants'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { GETMAILSERVER, SENDTESTEMAIL, UPDATEMAILSERVER } from '../../common/apiConstatnts';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import { wordCounter } from "../../common/constants";
import { EMAIL, EMAIL_MESSAGE } from "../../common/regexConstants";
import { CommonMessages, EmailSettingMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import Select from 'react-select';
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConsant.DASHBOARD },
    { label: 'Email Setting', url: "" },
    { label: 'Email Send Option', url: ""}
]

const EmailSetting = () => {
    const authenticateOptions = [
        { label: 'True', value: 0 },
        { label: 'False', value: 1 },
    ]
    const sslOptions = [
        { label: 'True', value: true },
        { label: 'False', value: false },
    ]
    const initialData = {
        smtpMailId: "",
        companyId: localStorage.getItem('companyId'),
        isSmtp: "",
        host: "",
        hostPort: "",
        fromAddress: "",
        authenticationType: "",
        useSsl: "",
        username: "",
        password: "",
        bccAddress: "",
        replyToAddress: "",
        getTranscript: "",
        showResultDialog: "",
        emailSendResult: "",
        isActive: "",
    }

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [mailServer, setMailServer] = useState<any>(initialData)
    const [formValues, setFormValues] = useState<any>({})
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [readMore, setReadMore] = useState(false);
    const [radioCheck, setRadioCheck] = useState('true')
    const [toEmail, setToEmail] = useState<string>('')
    const [modifiedBy, setModifiedBy] = useState('')

    const extraContent = " sends each email to the outbox of the email program on the local machine sending the emails. The \"SMTP Server\" option sends email directly through the SMTP mail server provided by your ISP using the settings below. Be aware that sending large numbers of emails by either method may draw the attention of your ISP and possibly lead to your account being flagged for generating spam. We suggest using ConstantContact.com or similar mailing services for sending email \"blasts\"."

    const linkName = readMore ? 'Read Less ' : '... Read More'

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };

    const maxWordCount = 250;
    const maxWordCount2 = 500;
    const [remainingCount, setRemainingCount] = useState(maxWordCount);
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount2);

    const schema: any = yup.object().shape({
        host: yup.string().required("Host Name is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/, ('Host Name is not valid')).max(50, "Please enter up to 50 characters."),
        hostPort: yup.string().required("Host Port Number is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(/^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/, ("Please Enter Valid Port Number!")),
        bccAddress: yup.string()
            .required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter up to 50 characters."),
        fromAddress: yup.string()
            .required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter up to 50 characters."),
        replyToAddress: yup.string()
            .required("Email address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE)
            .max(50, "Please enter up to 50 characters."),
        getTranscript: yup.string().required("Please select Get Transcript.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        authenticationType: yup.string().required("Please select Authentication Type.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        isSmtp: yup.string().required("Please select SMTP.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        showResultDialog: yup.string().required("Please select Show Result Dialog.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        toMailAddress: toggleModal ? yup.string()
            .required("Email Address is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE)
            .max(50, "Please enter up to 50 characters.") :
            yup.string()
                .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                    if (!value) return true; // If value is empty, it's handled by the .required() validation
                    return !/^\s/.test(value); // Check if value doesn't start with a space
                })
                .matches(EMAIL, EMAIL_MESSAGE)
                .max(50, "Please enter up to 50 characters."),
        username: yup.string()
            .required("Username is required.")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE)
            .max(50, "Please enter up to 50 characters."),
        password: yup.string().required("Please enter the password")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).test('len', 'Please enter between 8 to 20 characters.', val => val.length > 7 && val.length < 20),
    });

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        control,
        watch,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: mailServer, mode: 'onChange' })

    const authenticationType = watch('authenticationType')
    const useSsl = watch('useSsl')
    const getTranscript = watch('getTranscript')
    const showResultDialog = watch('showResultDialog')

    useEffect(() => {
        getMailServerDetails()
        setToEmail('')
        // if(mailServer.isSmtp){
        //     setRadioCheck('true')
        // }else{
        //     setRadioCheck('false')
        // }
    }, [])

    const getMailServerDetails = () => axios.get(GETMAILSERVER, config).then((response) => {
        const data = response.data.data;
        if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            return
        }
        setModifiedBy(response.data.data.modifiedBy)
        setMailServer(data)
        reset(data)
        if (response.data.data.isSmtp) {
            setRadioCheck('true')
        } else {
            setRadioCheck('false')
        }
    }).catch((error) => {
        setLoading(false);
        handleError(error);
    });


    const onsubmit = (e: any) => {
        setLoading(true);
        var data = getValues()
        var flagIsSmtp
        reset(data)
        if (data.isSmtp == 'true') {
            flagIsSmtp = true
        } else {
            flagIsSmtp = false
        }
        const payload = {
            authenticationType: Number(data.authenticationType),
            bccAddress: data.bccAddress,
            companyId: data.companyId,
            createdBy: data.createdBy,
            fromAddress: data.fromAddress,
            getTranscript: Boolean(data.getTranscript),
            host: data.host,
            hostPort: data.hostPort,
            isSmtp: flagIsSmtp,
            password: data.password,
            replyToAddress: data.replyToAddress,
            showResultDialog: Boolean(data.showResultDialog),
            smtpMailId: data.smtpMailId,
            useSsl: Boolean(data.useSsl),
            username: data.username,
            emailSendResult: data.emailSendResult
        }
        axios.post(UPDATEMAILSERVER, payload, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return
            }
            else {
                setLoading(false);
                toast.success(EmailSettingMessages.emailSettingUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const sendTestMail = (e: any) => {
        // e.preventDefault()
        setLoading(true)
        const payload = { toMailAddress: toEmail }
        axios.post(`${SENDTESTEMAIL}?toMailAddress=${toEmail}`, payload, config).then((response) => {
            setLoading(false);
            toast.success(EmailSettingMessages.testEmailCreated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            setToEmail('')
            handleClose()
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const openModal = (e: any) => {
        setToEmail('');
        clearErrors("toMailAddress")
        setValue('toMailAddress', '')
        setToggleValue(e)
    }

    const radioChange = (e: any) => {
        setRadioCheck(e.target.value)
    }

    const handleClose = () => {
        setToEmail('')
        setToggleValue(!toggleModal)
    }


    return (
        <>  
        <CommonHelmet title={"Email Send Option - "} name={"emailSendOption"} content={"Email Send Option"} />
        {loading && <div className="loader-wrap">
            <img src={`${localStorage.getItem('globalLoader')}`} />
        </div>}
            <Modal show={toggleModal} size='sm' centered>
                <form onSubmit={handleSubmit(sendTestMail)} noValidate>
                    <ModalHeader>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">Send Test Email</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}><span className="fas fa-times fs-20 text-danger"></span></button>
                    </ModalHeader>
                    <ModalBody>
                        <label className="form-label" >To Email<sup className="text-danger">*</sup></label>
                        <input className="form-control form-icon-input toMailAddress" type="email" maxLength={51} value={toEmail} placeholder="Enter Email" {...register('toMailAddress', { onChange: (e: any) => setToEmail(e.target.value) })}
                        />
                        {errors.toMailAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.toMailAddress.message}</span>}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>Cancel</button>
                        <button className="btn btn-primary" type="submit">Send</button>
                    </ModalFooter>
                </form>
            </Modal>
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
                <div className="container-fluid ">
                    <div className="row border-top justify-content-center">
                        <div className="col-xxl-10 pt-4">
                            {/* <div className="bg-white mb-5 border-300"> */}
                            <form onSubmit={handleSubmit(onsubmit)} noValidate>
                                <div className="container-fluid g-0 for-fix-bottom-space">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="mb-3">Email Send Options</h4>
                                            <label>Send Email Using</label>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <label className="form-check-label"><input type="radio" value="false" {...register('isSmtp')} className="form-check-input" onChange={(e) => radioChange(e)} checked={radioCheck == 'false'} /> Local Email Server</label>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <label className="form-check-label"><input type="radio" value="true" {...register('isSmtp')} className="form-check-input" onChange={(e) => radioChange(e)} checked={radioCheck == 'true'} /> SMTP Server</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="">
                                                    <p className="fs--1 mb-0">Selecting a method to send emails: The "Local Email Client" (default) uses FileMaker's standard method
                                                        which{readMore && extraContent}<a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>{linkName}</a> </p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <label className="fw-bold d-block">SMTP Mail Server Setting</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Host<sup className="text-danger">*</sup></label>
                                                <input className="form-control" type="text" {...register('host')} placeholder="Host" maxLength={51} />
                                                {errors.host && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.host.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Host Port<sup className="text-danger">*</sup></label>
                                                <input className="form-control" type="text" {...register('hostPort')} placeholder="Host Port" minLength={1} maxLength={4} />
                                                {errors.hostPort && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.hostPort.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-3">
                                            <label className="form-label">From Address<sup className="text-danger">*</sup></label>
                                            <input className="form-control" type="text" {...register('fromAddress')} placeholder="Email" maxLength={51}></input>
                                            {errors.fromAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fromAddress.message}</span>}
                                            <small>(To set auction specific from or ReplyTo, see the E-Invoice tab in auction)</small>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Authentication Type <sup className="text-danger">*</sup></label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select Authentication Type "
                                                    options={authenticateOptions}
                                                    {...register('authenticationType')}
                                                    value={authenticateOptions?.find((option: any) => option?.value == authenticationType)}
                                                    onChange={(e: any) => setValue('authenticationType', e.value)}
                                                    className='isActive'
                                                />

                                                {errors.authenticationType && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.authenticationType.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Use SSL<sup className="text-danger">*</sup></label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select Use SSL"
                                                    options={sslOptions}
                                                    {...register('useSsl')}
                                                    value={sslOptions?.find((option: any) => option?.value == useSsl)}
                                                    onChange={(e: any) => setValue('useSsl', e.value)}
                                                    className='isActive'
                                                />
                                                {errors.useSsl && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.useSsl.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Username<sup className="text-danger">*</sup></label>
                                                <input className="form-control" type="text" {...register('username')} placeholder="Username" maxLength={51} />
                                                {errors.username && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.username.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Password<sup className="text-danger">*</sup></label>
                                                <input className="form-control" type="password" {...register('password')} placeholder="Password" maxLength={20} />
                                                {errors.password && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.password.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <p className="mb-2 fs--1 fw-bold">GMail and GSuite User must do the Following:</p>
                                            <p className="mb-0 fs--1 fw-bold">1) Use your master account as the login</p>
                                            <p className="mb-0 fs--1 fw-bold">2) Turn on "2-Factor Verification"</p>
                                            <p className="mb-2 fs--1 fw-bold">3) use a 16-digit "App Specific Password"</p>
                                            <p className="mb-0 fw-bold"><small className="mt-2">Find these options under security or privacy on your google account setup</small></p>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-sm btn-primary px-2 py-1 mb-1" type="button" onClick={() => { openModal(!toggleModal) }}><img src="/material-email.svg" className="me-2" />Send Test Email MSG</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label className="form-label" >BCC Address<sup className="text-danger">*</sup></label>
                                                <input className="form-control" type="text" {...register('bccAddress')} placeholder="BCC Address" maxLength={51} />
                                                {errors.bccAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.bccAddress.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label className="form-label" >ReplyTo Address<sup className="text-danger">*</sup></label>
                                                <input
                                                    className="form-control"
                                                    placeholder="ReplyTo Address"
                                                    maxLength={51}
                                                    {...register('replyToAddress')}
                                                />
                                                {errors.replyToAddress && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.replyToAddress.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label className="form-label" >Email Send Result </label>
                                                <textarea className="form-control" placeholder="" rows={7} maxLength={maxWordCount2}
                                                    {...register('emailSendResult', { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount2)) })}
                                                />
                                                {remainingCount2 != maxWordCount2 && <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                                    {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Get Transcript</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select Get Transcript"
                                                    options={sslOptions}
                                                    {...register('getTranscript')}
                                                    value={sslOptions?.find((option: any) => option?.value == getTranscript)}
                                                    onChange={(e: any) => setValue('getTranscript', e.value)}
                                                    className='isActive'
                                                />
                                                {errors.getTranscript && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.getTranscript.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label className="form-label" >Show Result Dialog</label>
                                                <Select
                                                    classNamePrefix="dpaSelect"
                                                    placeholder="Select Result Dialog"
                                                    options={sslOptions}
                                                    {...register('showResultDialog')}
                                                    value={sslOptions?.find((option: any) => option?.value == showResultDialog)}
                                                    onChange={(e: any) => setValue('showResultDialog', e.value)}
                                                    className='isActive'
                                                />
                                                {errors.showResultDialog && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.showResultDialog.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                                                <Link to={routeConsant.DASHBOARD} className="btn btn-outline-danger me-auto" type="button" data-wizard-prev-btn="data-wizard-prev-btn" >
                                                    {CommonMessages.cancelButton}
                                                </Link>
                                                <ModifiedBy data={formValues} />
                                                <LastModified data={formValues} />
                                                <button className="btn btn-primary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.saveButton}</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailSetting
