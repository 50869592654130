import axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from 'html-react-parser';
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { GETAUCTOMATEDEMAILBYAUCTIONID, UPDATEAUTOMATEDEMAIL } from "../../../../common/apiConstatnts";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInfoActive, setCloneTab, setCreateTab } from "../../../../store/actions/AuctionAction";
import { loader } from "../../../../store/actions/CommonAction";
import { AUCTOMATEDEMAIL } from "../../AuctionConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../../../Utils/CustomCkEditor";

const AutomatedEmail = () => {
    const dispatch = useDispatch()
    const auctionId: any = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)
    const [automatedEmail, setAutomatedEmail] = useState<any>([])
    const [editor, setEditor] = useState<any>()
    const [activeKey, setActiveKey] = useState<string>("reg");
    const [initializeEditor, setIntitializeEditor] = useState<boolean>(false);
    const [data, setData] = useState<any>({})
    const [btnTxt, setBtnTxt] = useState<any>("")
    // Data to set in ckeditor
    const [email, setEmail] = useState('');

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        paras: {
            id: localStorage.getItem('companyId')
        }
    }

    const handleSave = () => {
        dispatch(loader(true))
        const payload = Object.keys(data).map((keys) => {
            return {
                auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
                emailType: keys,
                subjectLine: keys,
                emailValue: data?.[keys],
                companyId: localStorage.getItem('companyId'),
                auctionAutomatedEmailId: automatedEmail?.length > 0 ? automatedEmail?.filter((aData: any) => aData?.emailType === keys).length > 0 ?
                    automatedEmail?.find((aData: any) => aData?.emailType === keys).auctionAutomatedEmailId : 0 : 0,
                isActive: true,
                isDelete: false
            }
        })
        axios.post(UPDATEAUTOMATEDEMAIL, payload, config).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                if (mode === "clone") {
                    dispatch(setCloneTab([...clonedTabs, AUCTOMATEDEMAIL]))
                }
                if (mode === "create") {
                    dispatch(setCreateTab([...createTabs, AUCTOMATEDEMAIL]))
                }
                toast.success(AuctionMessages.auctionInfoMessages.automatedMailUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                nextStep();
            }
            dispatch(loader(false))
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }


    const nextStep = () => {
        dispatch(setAuctionInfoActive({ activeStep: 4, activeLabel: "Web Text" }))
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActive({ activeStep: 2, activeLabel: "Logo & Photos" }))
    }

    useEffect(() => {
        setActiveKey(activeKey)
    }, [activeKey])

    useEffect(() => {
        if (initializeEditor) {
            setTimeout(() => {
                setEditor("")
                setIntitializeEditor(false)
            }, 100)
        }
    }, [initializeEditor, data])
    // Ckeditor text change
    const onEditorEmailChange = (value: any) => {
        const updateData = { ...data, [activeKey]: value }
        setData(updateData)
    }

    useEffect(() => {
        const dataForEditor = {
            reg: automatedEmail?.length > 0 && automatedEmail?.filter((aData: any) => aData?.emailType === "reg" || aData?.emailType === "regMail").length > 0 ?
                automatedEmail?.find((aData: any) => aData?.emailType === "reg" || aData?.emailType === "regMail")?.emailValue : "",
            // absBids: automatedEmail?.emailType === "absBids" ? automatedEmail?.emailValue : "",
            bidder: automatedEmail?.length > 0 && automatedEmail?.filter((aData: any) => aData?.emailType === "bidder").length > 0 ?
                automatedEmail?.find((aData: any) => aData?.emailType === "bidder")?.emailValue : "",
            altBid: automatedEmail?.length > 0 && automatedEmail?.filter((aData: any) => aData?.emailType === "altBid").length > 0 ?
                automatedEmail?.find((aData: any) => aData?.emailType === "altBid")?.emailValue : "",
        }
        setData(dataForEditor)
        setIntitializeEditor(true)
    }, [automatedEmail, activeKey])

    useEffect(() => {
        dispatch(loader(true));
        const aId = clonedTabs.includes(AUCTOMATEDEMAIL) ? cloneAuctionId : auctionId
        axios.get(`${GETAUCTOMATEDEMAILBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                dispatch(loader(false))
                return;
            }
            else {
                dispatch(loader(false));
                let respData = response.data.data
                if (!clonedTabs.includes(AUCTOMATEDEMAIL) && cloneAuctionId > 0) {
                    respData = respData.map((rData: any) => {
                        return { ...rData, auctionAutomatedEmailId: 0 }
                    })
                }
                setAutomatedEmail(respData)
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }, [])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(AUCTOMATEDEMAIL)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(AUCTOMATEDEMAIL)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <div className="col-lg-12 mb-3">
                <Tabs
                    defaultActiveKey={activeKey}
                    onSelect={(key: any) => {
                        setActiveKey(key)
                    }}
                    id="uncontrolled-tab-example"
                    className="mb-3 nav-underline border-bottom"
                >
                    <Tab eventKey="reg" title="Reg"></Tab>
                    {/* <Tab eventKey="absBids" title="Abs Bids"></Tab> */}
                    <Tab eventKey="bidder" title="Bidder"></Tab>
                    <Tab eventKey="altBid" title="Alt Bid"></Tab>
                </Tabs>
                <Tabs
                    defaultActiveKey="source"
                    className="mb-3 nav-underline border-bottom "
                >
                    <Tab eventKey="source" title="Source">
                        {/* Add custom ckeditor */}
                        <CustomCkEditor
                            data={data?.[activeKey] || {}}
                            onTextChanged={onEditorEmailChange} />
                    </Tab>
                    <Tab eventKey="preview" title="Preview">
                        <div className="card box-shadow">
                            <div className="card-body ck_editor">
                                {(data?.[activeKey] !== undefined) ? parse(data?.[activeKey]) : ""}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                <button
                    className="btn btn-primary ms-2"
                    type="button"
                    onClick={handleSave}
                >
                    {btnTxt}
                </button>
                <button className="btn btn-secondary ms-2" type="button" onClick={nextStep}>{CommonMessages.skipButton}</button>
            </div>
        </>
    )
}

export default AutomatedEmail;