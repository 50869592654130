import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { setAdjust, loader, setAdjustFromResp, setAuctionInfoActiveTab } from "../../../../store/actions/SalesRepAction";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { taxExemptType } from "../../../../common/constants";
import { v4 as uuidv4 } from 'uuid';
import * as routeConstant from '../../../../common/routeConstants'
import { Link } from "react-router-dom";
import axios from "axios";
import { GETCOMMONVALUE, UPDATESALESADJUST } from "../../../../common/apiConstatnts";
import { DECIMAL, DECIMAL73, DECIMAL73_MESSAGE, DECIMAL_MESSAGE } from "../../../../common/regexConstants";
import { CommonMessages, SalesRepMessages, errorMessages } from "../../../../common/messageConstants";
import { ERROR_MESSAGES, formatCurrency, handleKeyDown } from "../../../../Utils/Utils";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import moment from "moment";

const Adjust = () => {

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const dispatch = useDispatch()
    const salesRepId = useSelector((state: any) => state.salesRep.saleRepId);
    const adjustData = useSelector((state: any) => state.salesRep.adjust);
    const selectedAuction = useSelector((state: any) => state.salesRep.selectedAuction);
    const auctionId = selectedAuction?.value;

    const initialAdjustData = {
        auctionId: auctionId,
        salesRepAdjustId: 0,
        salesRepId: salesRepId,
        amount: "",
        chgCr: "",
        tax: 0,
        taxAmount: "",
        description: "",
        adjustUniqueId: "",
        isActive: true,
        isDelete: false,
        isChange: false,
        isSubmit: false
    }

    const mainFormInitial = {
        salesRepAdjustId: 0,
        salesRepId: salesRepId,
        adjustTaxExempt: false,
        taxRateByZipCode: "0.00",
        noAdjusts: "0.00",
        ajustmentSalesTax: "0.00",
    }

    const options = [
        { value: 1, label: "Credit" },
        { value: 2, label: "Charge" }
    ];

    const [mode, setMode] = useState<string>('create')
    const [toggleAdjModal, setToggleAdjModal] = useState<boolean>(false)
    const [adjustment, setAdjustment] = useState<[]>([])
    const [filterAdjustment, setFilterAdjustment] = useState([])
    const [adjustFormValue, setAdjustFormValue] = useState<any>(initialAdjustData)
    const [mainFormValue, setMainFormValue] = useState<any>(mainFormInitial)
    const [currentPage, setCurrentPage] = useState(0);
    const [taxVal, setTaxVal] = useState(false);
    const [description, setDescription] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<any>();


    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const maxWordCount = 250
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const PER_PAGE = 10;
    const pageCount = Math.ceil(adjustment?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setAdjustment(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterAdjustment(firstSet)
    }

    const addAdjustSchema: any = yup.object().shape({
        amount: yup.string()
            .trim()
            .required('Amount is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            })
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s*$/.test(value) : true;
            }),
        chgCr: yup.string()
            .trim()
            .required('Chg/Cr is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            }).matches(DECIMAL73, DECIMAL73_MESSAGE)
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s*$/.test(value) : true;
            }),
        // tax: yup.string()
        //     .trim()
        //     .required('Tax is required.').test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //         return value ? /^\S*$/g.test(value) : true;
        //     }).matches(DECIMAL73, DECIMAL73_MESSAGE)
        //     .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        //         return value ? !/^\s*$/.test(value) : true;
        //     }),
        taxAmount: yup.string().when('selectedOption', {
            is: (value: any) => value == 2,
            then: () => yup
                .string()
                .trim()
                .required("Tax Amount is required")
                .test('no-blank-space', 'Blank spaces are not allowed',
                    (value) => {
                        return value ? !/^\s/.test(value) : true;
                    })
                .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                    return value ? /^\S*$/g.test(value) : true;
                }),
            otherwise: () => yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? /^\S*$/g.test(value) : true;
            })
                .matches(DECIMAL73, DECIMAL73_MESSAGE)
        }),
        description: yup.string().required('Description is required.'),
    })

    const {
        register: registerAdjust,
        handleSubmit: handleSubmitAdjust,
        reset: resetAdjust,
        getValues: getValuesAdjust,
        watch: adjustWatch,
        setValue: adjustSetValue,
        control: adjustControl,
        formState: { errors: errorsAdjust, isValid: isValidAdjust }
    } = useForm({ resolver: yupResolver(addAdjustSchema), defaultValues: adjustFormValue, mode: 'onChange' });

    const mainSchema: any = yup.object().shape({
        adjustTaxExempt: yup.boolean(),
        taxRateByZipCode: yup.string().required("Tax Rate by Zip Code is required").matches(DECIMAL, DECIMAL_MESSAGE)
            .test('less-than-100', 'Please enter rate between 0-99 % upto 2 decimal.', (value: any) => parseFloat(value || 0) < 100),
        noAdjusts: yup.string().required("No Adjusts is required").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL, DECIMAL_MESSAGE).test('less-than-1cr', "Please enter upto 8 digits", (value: any) => parseFloat(value || 0) < 10000000),
        ajustmentSalesTax: yup.string().required("Adjustment Sales Tax is required").test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(DECIMAL, DECIMAL_MESSAGE).test('less-than-1cr', "Please enter upto 8 digits", (value: any) => parseFloat(value || 0) < 10000000)
    })

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        clearErrors,
        watch,
        formState: { errors, isValid }
    } = useForm({ resolver: yupResolver(mainSchema), defaultValues: mainFormValue, mode: 'onChange' });


    const watchTaxExempt = watch('adjustTaxExempt');
    const taxAmount = adjustWatch('taxAmount');
    const chgCr = adjustWatch('chgCr');
    const amount = adjustWatch('amount');

    const noAdjusts: any = watch('noAdjusts')
    const ajustmentSalesTax: any = watch('ajustmentSalesTax')

    useEffect(() => {
        if(adjustFormValue?.chgCr) {
            setSelectedOption(adjustFormValue?.chgCr);
        }
    }, [adjustFormValue]);

    useEffect(() => {
        if (chgCr == "1" || !chgCr) {
            adjustSetValue('taxAmount', "0.00")
        } else {
            adjustSetValue('taxAmount', Number(adjustFormValue?.taxAmount || 0.00)?.toFixed(2));
        }
    }, [chgCr])


    useEffect(() => {
        axios.get(`${GETCOMMONVALUE}?filter=AdjustDescription&companyId=${localStorage.getItem("companyId")}`).then((response) => {
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                setDescription(response.data.data);
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });

        adjustSetValue("taxAmount", 0.00);
    }, [])

    const onSubmitAdjust = () => {
        let data = getValuesAdjust()
        if (mode === "create") {
            const newAdjustData = { ...initialAdjustData, ...data, adjustUniqueId: uuidv4(), isChange: true, chgCr: parseInt(data.chgCr) }
            const updatedData =
                adjustData?.lstsalesRepAdjusts?.length ?
                    [...adjustData?.lstsalesRepAdjusts, newAdjustData]
                    :
                    [newAdjustData]
            dispatch(setAdjust(updatedData));
            resetAdjust(initialAdjustData)
            setCurrentPage(0)
            handleClose()
        } else {
            const updateAdjust = adjustData?.lstsalesRepAdjusts.map((adj: any) => {
                if (adj.adjustUniqueId === data.adjustUniqueId) {
                    return { ...data, isChange: true, chgCr: parseInt(data.chgCr) }
                }
                return adj
            })
            dispatch(setAdjust(updateAdjust));
            resetAdjust(initialAdjustData)
            setCurrentPage(0)
            handleClose()
        }
    }

    const IsTaxFieldsChanged = (newData: any) => {
        if (!newData?.taxRateByZipCode && !newData?.noAdjusts && !newData?.ajustmentSalesTax) {
            return false
        }

        if (adjustData.taxRateByZipCode == newData?.taxRateByZipCode && adjustData.noAdjusts == newData?.noAdjusts && adjustData.ajustmentSalesTax == newData?.ajustmentSalesTax) {
            return false
        }
        return true
    }

    useEffect(() => {
        if (!watchTaxExempt) {
            clearErrors(['taxRateByZipCode', 'noAdjusts', 'ajustmentSalesTax'])
        }
    }, [watchTaxExempt])

    const onSave = () => {
        let data: any = getValues();
        let adjustListing = adjustData?.lstsalesRepAdjusts
        dispatch(loader(true));
        adjustListing = adjustListing?.filter((adjData: any) => adjData.isChange === true).length === 0 ? [] : IsTaxFieldsChanged(data) ? adjustListing : adjustListing?.filter((adjData: any) => adjData.isChange === true)
        const payload = {
            ...data,
            taxExempt: data.adjustTaxExempt,
            lstsalesRepAdjusts: adjustListing,
            noAdjusts: Number(data.noAdjusts || 0.00).toFixed(2),
            taxRateByZipCode: Number(data.taxRateByZipCode || 0.00).toFixed(2),
            ajustmentSalesTax: Number(data.ajustmentSalesTax || 0.00).toFixed(2),
        }
        axios.post(UPDATESALESADJUST, payload, { ...config, 'ContentType': 'application/json' }).then((response) => {
            dispatch(loader(false));
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const respData = response.data.data;
                dispatch(setAdjustFromResp({
                    ...payload,
                    lstsalesRepAdjusts: respData.map((adj: any) => {
                        return {
                            ...adj,
                            isChange: false,
                            isSubmit: true,
                            adjustUniqueId: uuidv4(),
                        }
                    })
                }))
                toast.success(SalesRepMessages.adjustUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            handleNext();
            dispatch(loader(false));
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });
    }

    const handleClose = () => {
        setToggleAdjModal(!toggleAdjModal);
        resetAdjust(initialAdjustData);
        setRemainingCount(maxWordCount);
        setSelectedOption("");
    }

    const handleRadio = (rValue: any) => {
        if (!rValue) {
            setValue('taxRateByZipCode', "0.00")
            setValue('noAdjusts', "0.00")
            setValue('ajustmentSalesTax', "0.00")
        } else {
            setValue('taxRateByZipCode', Number(adjustData?.taxRateByZipCode || 0.00).toFixed(2) ?? "0.00")
            setValue('noAdjusts', Number(adjustData?.noAdjusts || 0.00).toFixed(2) ?? "0.00")
            setValue('ajustmentSalesTax', Number(adjustData?.ajustmentSalesTax || 0.00).toFixed(2) ?? "0.00")
        }
        setValue('adjustTaxExempt', rValue);
        setTaxVal(rValue);
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleEditModal: any = (adjustUniqueId: any, type: string) => {
        if (type === 'edit') {
            setMode('edit')
            setToggleAdjModal(!toggleAdjModal)
            const getData: any = adjustment.find((data: any) => data.adjustUniqueId === adjustUniqueId)            
            setSelectedOption(getData?.chgCr)
            setAdjustFormValue({ ...getData, amount: Number(getData?.amount)?.toFixed(2), taxAmount: Number(getData?.taxAmount || 0.00)?.toFixed(2) })
            resetAdjust({ ...getData, amount: Number(getData?.amount)?.toFixed(2), taxAmount: Number(getData?.taxAmount || 0.00)?.toFixed(2) })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updateData = adjustData?.lstsalesRepAdjusts?.map((data: any) => {
                    if (data.adjustUniqueId === adjustUniqueId) {
                        return { ...data, isActive: false, isDelete: true, isChange: data.isSubmit }
                    }
                    return data
                })
                dispatch(setAdjust(updateData));
            }
        }).catch((error) => {
                  dispatch(loader(false));
                  handleError(error);
                });
    }

    const handleNext = () => {
        // dispatch(setAdjust(adjustData?.lstsalesRepAdjusts?.filter((adj: any) => adj.isSubmit === true)));
        dispatch(setAuctionInfoActiveTab('payment'))
    }

    const handlePrevious = () => {
        // dispatch(setAdjust(adjustData?.lstsalesRepAdjusts?.filter((adj: any) => adj.isSubmit === true)));
        dispatch(setAuctionInfoActiveTab('sales'))
    }

    const filterAdjustDataByAuction = () => {
        let data: any = adjustData?.lstsalesRepAdjusts?.filter((payment: any) => payment?.auctionId === auctionId)
        paginateData(data)
    }

    useEffect(() => {
        if (auctionId) {
            filterAdjustDataByAuction()
        }
    }, [auctionId, adjustData])

    useEffect(() => {
        if (adjustment.length) {
            const offset = currentPage * PER_PAGE
            const nextSet = adjustment?.slice(offset, offset + PER_PAGE)
            setFilterAdjustment(nextSet)
        }
    }, [currentPage]);

    useEffect(() => {
        if (adjustData?.salesRepId) {
            setTaxVal(adjustData.adjustTaxExempt)
            reset(adjustData)
        }
    }, [])


    const handleOptionChange = (event: any) => {
        if (event.target.value) {
            setSelectedOption(event.target.value);
        }
        else {
            setSelectedOption("");
        }
    };

    const handleAmountChange = (value: any) => {
        adjustSetValue("amount", value);
    };

    const handleTaxAmountChange = (value: any) => {
        adjustSetValue("taxAmount", value);
    }
    return (
        <>
            {/* create adjust modal starts */}
            <Modal show={toggleAdjModal} size='lg' centered>
                <form onSubmit={handleSubmitAdjust(onSubmitAdjust)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add Adjustment' : 'Edit Adjustment'}</h5>
                        <button className="btn p-1" type="button" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label">Description <sup className="text-danger">*</sup></label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    {...registerAdjust('description')}
                                >
                                    <option value="">Select </option>
                                    {description?.map((initialData: any, index: any) => {
                                        return (
                                            <option key={index} value={initialData.objectTypeValue}>
                                                {initialData.objectTypeValue}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errorsAdjust.description &&
                                    <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.description.message}</span>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Amount ($)<sup className="text-danger">*</sup></label>

                                <Controller
                                    name="amount"
                                    control={adjustControl}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Amount"
                                            {...registerAdjust('amount')}
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={amount}
                                            onValueChange={(value: any) => handleAmountChange(value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />
                                {errorsAdjust.amount && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.amount.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Charge/Credit <sup className="text-danger">*</sup></label>

                                <select
                                    className="form-select"
                                    value={selectedOption}
                                    aria-label="Default select example"
                                    {...registerAdjust('chgCr', {
                                        onChange: (e) => {
                                            handleOptionChange(e);
                                        }
                                    })}
                                >
                                    <option value="">Select </option>
                                    {options?.map((initialData: any, index: any) => {
                                        return (
                                            <option key={index} value={initialData.value}>
                                                {initialData.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                {errorsAdjust.chgCr && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.chgCr.message}</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Taxable ($) (Charge Only){selectedOption == 2 ? <sup className="text-danger">*</sup> : ""}</label>

                                <Controller
                                    name="taxAmount"
                                    control={adjustControl}
                                    render={({ field }) => (
                                        <CurrencyInput
                                            type="text"
                                            placeholder="Tax Amount"
                                            prefix="$"
                                            onKeyDown={handleKeyDown}
                                            value={taxAmount}
                                            disabled={selectedOption == 2 ? false : true}
                                            onValueChange={(value: any) => handleTaxAmountChange(value)}
                                            className="form-control form-icon-input"
                                        />
                                    )}
                                />
                                {errorsAdjust.taxAmount && <span className='text-danger' style={{ fontSize: "12px" }}>{errorsAdjust.taxAmount.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit"
                        >{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* create adjust modal ends */}

            <div className="container-fluid for-fix-bottom-space">
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="row justify-content-center">
                        <div className="col-xxl-10">
                            <div className="mt-3 mb-4 border-300">
                                <div className="row">
                                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between">
                                        <h4>Adjusts</h4>
                                        <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex align-items-center" type="button"
                                            onClick={() => {
                                                resetAdjust(initialAdjustData)
                                                setAdjustFormValue(initialAdjustData)
                                                setToggleAdjModal(!toggleAdjModal)
                                                setMode('create')
                                            }}>
                                            <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Adjustment </span>
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <div className="mb-5 border-300">
                                            <div className="mb-5 border-300">
                                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                                    <table className="table fs--1 mb-0 border-top border-200">
                                                        <thead>
                                                            <tr>
                                                                <th className="bg-gradiant " scope="col">Action</th>
                                                                <th className="bg-gradiant " scope="col">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Date</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant" scope="col" style={{ width: '300px' }}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Description</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant" scope="col">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Charge/Credit</span>
                                                                    </div>
                                                                </th>
                                                                <th className="bg-gradiant" scope="col" >
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Amount ($)</span>
                                                                    </div>
                                                                </th>

                                                                <th className="bg-gradiant" scope="col">
                                                                    <div className='d-flex align-items-center'>
                                                                        <span>Taxable ($) (Charges Only)</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list scrollbar" id="table-latest-review-body">
                                                            {(filterAdjustment?.length > 0) ?
                                                                filterAdjustment?.map((data: any, index: any) => {
                                                                    return (
                                                                        data?.isActive && <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                            <td className="align-middle white-space-nowrap text-start p-2">
                                                                                <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                                                    <Dropdown.Toggle className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" variant='none'>
                                                                                        <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                                                        <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.adjustUniqueId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                                                        <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.adjustUniqueId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="fs--1 text-1000 mb-0">
                                                                                    {moment(data?.updatedOn ? data?.updatedOn : data?.createdOn ? data?.createdOn : new Date()).format('MM/DD/YYYY').toString()}
                                                                                </p>
                                                                            </td>
                                                                            <td className="align-middle  p-2" style={{ width: '300px' }}>
                                                                                <p className="fs--1 text-1000 mb-0 text-truncate">{data?.description}</p>
                                                                            </td>
                                                                            <td className="align-middle  p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.chgCr == "2" ? "Charge" : "Credit"}</p>
                                                                            </td>
                                                                            <td className="align-middle review p-2">
                                                                                <p className="fs--1 text-1000 mb-0">
                                                                                    {data.chgCr == 2 ? formatCurrency(data?.amount) : `(${formatCurrency(data?.amount)})`}
                                                                                </p>
                                                                            </td>
                                                                            {/* <td className="align-middle  p-2">
                                                                            <p className="fs--1 text-1000 mb-0">{data?.tax}</p>
                                                                        </td> */}
                                                                            <td className="align-middle  p-2">
                                                                                <p className="fs--1 text-1000 mb-0">{data?.taxAmount === "" ? "0.00" : formatCurrency(data?.taxAmount)}</p>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                        <td className='text-center p-4' colSpan={7}>No records found!</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                {filterAdjustment?.length > 0 && (
                                                    <ReactPaginate
                                                        previousLabel={"←"}
                                                        nextLabel={"→"}
                                                        pageCount={pageCount}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"pagination__link"}
                                                        nextLinkClassName={"pagination__link"}
                                                        disabledClassName={"pagination__link--disabled"}
                                                        activeClassName={"pagination__link--active"}
                                                        forcePage={currentPage}
                                                    />)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h4>Tax Exempt</h4>
                                        {taxExemptType.map((taxType: any, index: number) => {
                                            return (
                                                <div className="form-check form-check-inline mb-3" key={index}>
                                                    <input
                                                        name="adjustTaxExempt"
                                                        type="radio"
                                                        id={`adj_${index}`}
                                                        onChange={() => handleRadio(taxType.value)}
                                                        className="form-check-input"
                                                        checked={taxType.value === watchTaxExempt}
                                                        defaultChecked={adjustData.adjustTaxExempt === undefined ? taxType.selected : adjustData.adjustTaxExempt === taxType.value}
                                                    />
                                                    <label className="form-check-label" htmlFor={`adj_${index}`} >{taxType.label}</label>
                                                </div>
                                            )
                                        })}
                                        {errors.taxExempt && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxExempt.message}</span>}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">Tax Rate By Zip Code (%) {watchTaxExempt && (<sup className="text-danger">*</sup>)}</label>
                                            <input type="text" placeholder="Tax Rate By Zip Code" className="form-control" disabled={!taxVal} {...register('taxRateByZipCode')} maxLength={5} onKeyDown={handleKeyDown} />
                                            {errors.taxRateByZipCode && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.taxRateByZipCode.message}</span>}
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">No Adjusts ($) {watchTaxExempt && (<sup className="text-danger">*</sup>)}</label>
                                            <Controller
                                                name="noAdjusts"
                                                control={control}
                                                render={({ field }) => (
                                                    <CurrencyInput
                                                        type="text"
                                                        disabled={!taxVal}
                                                        placeholder="No Adjusts"
                                                        {...register('noAdjusts')}
                                                        prefix="$"
                                                        onKeyDown={handleKeyDown}
                                                        value={noAdjusts}
                                                        decimalsLimit={2}
                                                        onValueChange={(value: any) =>
                                                            setValue("noAdjusts", value)}
                                                        className="form-control form-icon-input"
                                                    />
                                                )}
                                            />
                                            {errors.noAdjusts && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.noAdjusts.message}</span>}
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <label className="form-label">Adjustment Sales Tax ($) {watchTaxExempt && (<sup className="text-danger">*</sup>)}</label>
                                            <Controller
                                                name="ajustmentSalesTax"
                                                control={control}
                                                render={({ field }) => (
                                                    <CurrencyInput
                                                        type="text"
                                                        disabled={!taxVal}
                                                        placeholder="No Adjusts"
                                                        {...register('ajustmentSalesTax')}
                                                        prefix="$"
                                                        onKeyDown={handleKeyDown}
                                                        value={ajustmentSalesTax}
                                                        decimalsLimit={2}
                                                        onValueChange={(value: any) =>
                                                            setValue("ajustmentSalesTax", value)}
                                                        className="form-control form-icon-input"
                                                    />
                                                )}
                                            />
                                            {errors.ajustmentSalesTax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.ajustmentSalesTax.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                            <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SALESREPRESENTATIVE}>{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary mx-2 ms-auto" type="submit"
                            >{ID == undefined ? (CommonMessages.saveNext) : (CommonMessages.updatedNext)}</button>
                            <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Adjust;