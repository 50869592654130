import { useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CommonHelmet from "../../../common/HelmetWrapper";
import { Breadcrumb } from "../../../common/interface";
import * as routeConstant from '../../../common/routeConstants';
import * as actions from '../../../common/types';
import { setActiveTab, setSellerId, } from "../../../store/actions/Seller";
import InnerHeaderTab from "../../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../../Sidebar/Sidebar";
import AuctionInfoTabs from "./AuctionInfo";
import ContactDefault from "./ContactDefault";
import ContractDocuments from "./ContractDocuments";
import Tippy from "@tippyjs/react";
import { encryptId } from "../../../Encryption/Encryption";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GETBYSELLERID } from "../../../common/apiConstatnts";
import { toast } from "react-toastify";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const MoreSellerDetails = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];

    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Sellers', url: routeConstant.SELLER },
        { label: ID ? 'Edit Seller' : 'New Seller', url: '' }
    ]
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const sellerData = useSelector((state: any) => state?.seller);
    const activeTab = sellerData?.activeTab?.key;
    const sellerId = sellerData?.sellerId;
    // const dataForDisplayLotNo = useSelector((state: any) => state?.seller.generatedLotCtrlRec);
    const nextSellerIdFromStore = useSelector((state: any) => state?.seller.nextSellerId)
    const prevSellerIdFromStore = useSelector((state: any) => state?.seller.prevSellerId)
    const totalSellersFromStore = useSelector((state: any) => state?.seller.totalSellers)
    const indexFromStore = useSelector((state: any) => state?.seller.sellerIndex)

    //when redirected from Auction sellers tab from auction details set this auction.
    const AuctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);

    //hammer auction when selected any auction from hammer.
    const getGlobalAuctionIds = useSelector(
        (state: any) => state?.global?.auctionHammerData?.value
    );

    const [inputValue, setInputValue] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const auctionId = AuctionDetail?.auctionId || getGlobalAuctionIds

    const handleChange = (key: string) => {
        dispatch(setActiveTab(key))
    }

    useEffect(() => {
        return () => {
            dispatch({ type: actions?.RESETSELLER })
        }
    }, [])

    const handlePrevLot = () => {
        dispatch(setActiveTab("contact"));
        if (prevSellerIdFromStore) {
            dispatch(setSellerId(prevSellerIdFromStore))
            navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(prevSellerIdFromStore)}`, { state: { type: "edit" } });
        }
    };

    const handleNextLot = () => {
        dispatch(setActiveTab("contact"));
        if (nextSellerIdFromStore) {
            dispatch(setSellerId(nextSellerIdFromStore))
            navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(nextSellerIdFromStore)}`, { state: { type: "edit" } });
        }
    };

    const handleToggle = () => {
        let check: any = document.getElementById('type_true_0')
        if (check) {
            check.checked = true
        }
        setDropdownOpen(!dropdownOpen);
    };

    const handleSellerPageChange = () => {
        setLoading(true)
        axios.get(`${GETBYSELLERID}?sellerId=${inputValue}&auctionId=${auctionId}`).then((response) => {
            setLoading(false)
            if (response.data.success === 'Success') {
                const sellerId = response?.data?.data?.sellerId;
                setInputValue('')
                dispatch(setActiveTab("contact"));
                dispatch(setSellerId(sellerId))
                navigate(`${routeConstant.SELLERMOREINFO}/${encryptId(sellerId)}`)
                setDropdownOpen(false);
            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            handleError(error);
        });
    };


    return (
        <>
            <CommonHelmet title={"Seller - "} name={"seller"} content={"Seller"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeaderTab breadCrumb={breadcrumbData} title={ID ? '' : 'New Seller'}>
                    {ID && (
                        <div className="d-flex flex-wrap gap-2">
                            <h4>Edit Seller</h4>
                            {sellerData?.sellerId ?
                                <span> - ({sellerData?.sellerId}) {sellerData?.firstName} {sellerData?.middleName ?? ""} {sellerData?.lastName}</span>
                                : <></>
                            }
                        </div>
                    )}
                    {sellerId > 0 && (
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                            {auctionId > 0 &&
                                (
                                    <div className="edit-inv-goto w-100 right-0">
                                        <div className="next_and_pre_arrow d-flex flex-wrap justify-content-end">
                                            <button
                                                className="btn btn-outline-primary p-0 ms-auto"
                                                onClick={handlePrevLot}
                                                disabled={prevSellerIdFromStore === 0}
                                            >
                                                <Tippy
                                                content={"Previous Seller"}
                                                    placement="bottom"
                                                    arrow={false}
                                                    offset={[0, 8]}
                                                >
                                                    <i
                                                        className="fa-solid fa-arrow-left p-2"
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </Tippy>
                                            </button>
                                            <button
                                                className="btn btn-outline-primary p-0 ms-0"
                                                onClick={handleNextLot}
                                                disabled={nextSellerIdFromStore === 0}
                                            >
                                                <Tippy
                                                content={"Next Seller"}
                                                    placement="bottom"
                                                    arrow={false}
                                                    offset={[0, 8]}
                                                >
                                                    <i
                                                        className="fa-solid fa-arrow-right p-2"
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </Tippy>
                                            </button>
                                            <Dropdown
                                                className="custom-dropdown ms-0"
                                                show={dropdownOpen} // Pass the state to control visibility
                                                onToggle={handleToggle} // Handle toggle event
                                            >
                                                <Dropdown.Toggle
                                                    className="px-1 dropdown-caret-none"
                                                    id="dropdown-autoclose-true"
                                                    variant="none"
                                                >
                                                    <span
                                                        className="menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0"
                                                    onClick={() => {
                                                            setInputValue("");
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    variant="none"
                                                    className="p-0 dropdown-menu-end navbar-dropdown-caret shadow border border-300"
                                                >
                                                    <div className="card position-relative border-0 top_arrow">
                                                        <Dropdown.Header className="border-bottom">
                                                            Go to
                                                        </Dropdown.Header>
                                                        <div className="pt-2 ps-3 pe-3 pb-3">
                                                            {/* <div className="d-flex gap-3">
                                                                {radioKey.map((item: any, index: any) => {
                                                                    return (
                                                                        <div className="d-flex">
                                                                            <input
                                                                                type="radio"
                                                                                className="form-check-input me-2"
                                                                                name="radioKey"
                                                                                value={item.value}
                                                                                id={`type_true_${index}`}
                                                                                onClick={() => handleRadioChange(item.value)}
                                                                                defaultChecked={item.selected}
                                                                            />
                                                                            <label htmlFor={`type_true_${index}`}>
                                                                                {item.label}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div> */}
                                                            <div className="d-flex align-items-center mt-3">
                                                                <input
                                                                    className="form-control form-icon-input me-2"
                                                                    placeholder="Seller Id"
                                                                    name="inputValue"
                                                                    value={inputValue}
                                                                    onChange={(e: any) =>
                                                                        setInputValue(e.target.value)
                                                                    }
                                                                />
                                                                <button
                                                                    className="d-flex btn btn-primary ps-2 pe-2 align-items-center"
                                                                    onClick={handleSellerPageChange}
                                                                    disabled={!inputValue}
                                                                >
                                                                    Go
                                                                    <i className="fa-solid fa-arrow-right mt-1 ms-1"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <span className="fs-12 fw-bold text-end me-2">
                                                Seller #{sellerId} - Seller Index <span>{indexFromStore}</span> out of
                                                <span>{totalSellersFromStore}</span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                    <Tabs
                        defaultActiveKey={activeTab}
                        activeKey={activeTab}
                        className="nav-underline "
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="contact" title="Contact & Default Info" disabled={(sellerId === undefined)}></Tab>
                        {/* <Tab eventKey="addresses" title="Address Info" disabled={(sellerId === undefined)}></Tab> */}
                        <Tab eventKey="auctionInfo" title="Auction Info" disabled={(sellerId === undefined)}></Tab>
                        <Tab eventKey="contractDocs" title="Contract Documents" disabled={(sellerId === undefined)}></Tab>
                    </Tabs>
                </InnerHeaderTab >
                {
                    (activeTab === 'contact') ?
                        <ContactDefault /> :
                        (activeTab === 'auctionInfo') ?
                            <AuctionInfoTabs />
                            :
                            (activeTab === 'contractDocs') ?
                                <ContractDocuments />
                                :
                                <></>
                }
            </div >
        </>
    )
}

export default MoreSellerDetails;