import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../../Utils/Utils";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import {
  GETALLVALUEDEFAULT,
  GETLOTADDRESSBYID,
  GETTAXRATEBYLOTADDRESS,
  UPDATELOTADDRESS
} from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { wordCounter } from "../../../../common/constants";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  EMAIL,
  EMAIL_MESSAGE,
  SPECIALCHARS,
  URL,
  URL_MESSAGE
} from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive,
  setStep
} from "../../../../store/actions/Inventory";
import MapContainer from "../../../Auction/NewAuction/AuctionInfo/MapContainer";
import mapIcon from "../../../../assets/map.svg";
import Tippy from "@tippyjs/react";
import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";
import CurrencyInput from "react-currency-input-field";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import GooglePlaces from "../../../Company/GooglePlaces";
import { LOTADDRESS } from "../../InventoryConstants";
import GoogleMap from "../../../../Utils/GoogleMap";
import { Modal } from "react-bootstrap";

const Address = () => {
  const dispatch = useDispatch();
  const lotId = useSelector((state: any) => state?.inventory?.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
  const createTabs = useSelector((state: any) => state.inventory.createTabs)
  const mode = useSelector((state: any) => state.inventory.mode)
  const getLotCtrlRec = useSelector((state: any) => state?.inventory?.generatedLotCtrlRec);

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    lotId: lotId,
    lotAddressId: 0,
    companyName: "",
    stateDateTime: "2023-08-29T12:33:41.412Z",
    endDateTime: "2023-08-29T12:33:41.412Z",
    auctionEmail: getLotCtrlRec?.auctionEmail ? getLotCtrlRec?.auctionEmail : "",
    website: "",
    contactAgent: getLotCtrlRec?.contactAgent ? getLotCtrlRec?.contactAgent : "",
    notice: "",
    customNotice: "",
    phone1: "",
    phone2: "",
    fax: "",
    biddersInMaster: false,
    useGeocoding: false,
    auctionMap: false,
    latitude: "",
    longitude: "",
    showLatLong: false,
    inventoryInMaster: false,
    client: "",
    auctionNotes: "",
    taxExempt: false,
    taxRate: 0,
    autoSelectTaxRate: true,
    add1: "",
    add2: "",
    city1: "",
    state1: "",
    country1: "",
    zip: "",
    county1: ""
  };

  const [countryCode, setCountryCode] = useState('us')
  const [prevCountryCode, setPrevCountryCode] = useState('us');
  const [isAddressSelected, setAddressSelected] = useState(false)

  const generatePhoneValidationSchema = () => {
    return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
      if (!value) return true;
      try {
        const phoneNumber = parsePhoneNumberFromString(`+${value}`);
        if (!phoneNumber || !phoneNumber.isValid()) {
          return false; // Invalid phone number format
        }
        const nationalNumber = phoneNumber.nationalNumber;
        const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
        const expectedLength = value.length - countryCodeLength;
        return nationalNumber.toString().length === expectedLength;
      } catch (error) {
        console.error('Error parsing phone number:', error);
        return false; // Return false if an error occurs during parsing
      }
    });
  };

  const schema: any = yup.object().shape({
    taxRate: yup.string().when("taxExempt", {
      is: (taxExempt: any, value: boolean) => taxExempt === true,
      then: () =>
        yup
          .string()
          .nullable()
          .test(
            "no-blank-space",
            ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
            (value) => {
              return value ? /^\S*$/g.test(value) : true;
            }
          )
          .test("max-5-digits", "Maximum 5 digits allowed", (value) => {
            return value ? value.length <= 5 : true;
          }),
      otherwise: () => yup.string(),
    }),
    companyName: yup
      .string()
      .notRequired()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Company Name")
      .max(50, "Please enter upto 50 characters."),
    contactAgent: yup
      .string()
      .required("Contact agent is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Contact Agent")
      .max(20, "Please enter upto 20 characters."),
    notice: yup
      .string()
      .nullable()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Notice")
      .max(50, "Please enter upto 50 characters."),
    customNotice: yup
      .string()
      .nullable()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Custom Notice")
      .max(50, "Please enter upto 50 characters."),
    add1: yup
      .string()
      .required("Address Line 1 is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Address"),
    country1: yup.string().required("Please select Country."),
    state1: yup.string().required("Please select State."),
    city1: yup.string().required("Please select City."),
    zip: yup
      .string()
      .required("Zip code is required")
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? !/^\s/.test(value) : true;
        }
      )
      .matches(
        /^[0-9a-zA-Z\s-]+$/,
        "Zip code must contain only numbers, alphabets, hyphens, and spaces."
      )
      .matches(
        /^(?!-)[\w\s-]*(?<!-)$/,
        "Hyphens are not allowed at the beginning or end of the zip code."
      )
      .min(3, "Zip code must be at least 3 characters.")
      .max(12, "Zip code cannot be longer than 12 characters."),
    // zipCode: yup.string().required("Zip code is required.").test('no-blank-space', 'Blank spaces are not allowed', (value) => {
    //     return value ? !/^\s/.test(value) : true;
    // }),
    website: yup
      .string()
      .nullable()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(URL, URL_MESSAGE)
      .max(100, "Please enter upto 100 characters."),
    auctionEmail: yup
      .string()
      .required("Auction Email is required.")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE)
      .max(50, "Please enter upto 50 characters."),
    fax: yup
      .string()
      .notRequired()
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? /^\S*$/g.test(value) : true;
        }
      )
      .matches(/^(\d{10})*$/, "Please enter number with 10 digits"),
    client: yup
      .string()
      .nullable()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Client Name")
      .max(50, "Please enter upto 50 characters."),
    phone1: generatePhoneValidationSchema().required(
      "Phone number is required."
    ),
    phone2: generatePhoneValidationSchema().nullable(),
  });

  const [loading, setLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(initialData);
  const maxWordCountShort = 100;
  const [remainingCount2, setRemainingCount2] = useState(maxWordCountShort);
  const [toggleModal, setToggleValue] = useState<boolean>(false)
  const [address, setAddress] = useState('');
  const [isData, setIsData] = useState<boolean>(false)
  const [btnText, setBtnTxt] = useState<any>("")

  useEffect(() => {
    if (prevCountryCode !== countryCode) {
      setValue('phone1', '');
      setValue('phone2', '');
    }
  }, [address, prevCountryCode, countryCode]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    clearErrors,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
    mode: "onChange",
  });

  const handleClose = () => {
    setToggleValue(false)
    // setLocation('')
  }

  const watchTaxExemptCheck = watch("taxExempt");
  const taxRate = watch("taxRate");

  useEffect(() => {
    if (watchTaxExemptCheck === false) {
      if (isData) {
        if (address === "" || selectedCountry === "" || selectedCity === "" || selectedState === "" || selectedZipcode === "") {
          toast.warning("Please enter proper address to get tax", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setValue("taxExempt", true)
          return
        } else {
          getTaxRate()
        }
      }
      setValue("taxRate", taxRate);
    } else {
      setValue("taxRate", 0);
    }
  }, [watchTaxExemptCheck]);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const selectedCountry = watch("country1");
  const selectedCity = watch("city1");
  const selectedState = watch("state1");
  const selectedZipcode = watch("zip");

  const handleChangeAddressLine = (e: any) => {

    charCountForAddress(e.target.value.length)
    setAddress(e.target.value)
    setValue('add1', e.target.value)
    const inputText = e.target.value;
    const currentCount = inputText.length;
    const remaining = 250 - currentCount;

    if (remaining < 0) {
      return;
    }
    if (e.target.name === 'addressLine1') {
      setValue('add1', inputText)
      setAddress(inputText)
      charCountForAddress(inputText.length)
    } else if ((e.target.name === 'locations')) {
      setAddress(inputText)
      setValue('add1', inputText)
      charCountForAddress(inputText.length)
    }
  }

  const getTaxRate = () => {
    setLoading(true);
    const payload = {
      lotId: lotId,
      addressLine1: address,
      city: getValues("city1"),
      state: getValues("state1"),
      country: getValues("country1"),
      zipCode: getValues("zip"),
      taxExempt: watchTaxExemptCheck
    }
    axios.post(GETTAXRATEBYLOTADDRESS, payload, config).then((response: any) => {
      if (response?.data?.success === "Fail") {
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        const data = response.data.data;
        setValue("taxRate", data);
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  const prevStep = () => {
    /* dispatch(setInventoryInfoActive({ activeTab: "lotsPage" })); */
    dispatch(setStep('lots'))
  };

  const onSubmit = () => {
    let data: any = getValues();
    setLoading(true);
    onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
      const payload = {
        ...data,
        lotId: cloneLotId > 0 ? cloneLotId : lotId,
        taxRate: data?.taxRate ?? parseFloat(data?.taxRate),
        latitude: data?.latitude ? data?.latitude.toString() : '',
        longitude: data?.longitude ? data?.longitude.toString() : '',
        zipCode: data?.zip,
        addressLine1: data.add1,
        addressLine2: data.address2,
        country: parseInt(countryId),
        state: parseInt(stateId),
        city: parseInt(cityId),
        county: parseInt(countyId),
        countyName: data?.county1,
        stateDateTime: "2023-08-29T12:33:41.412Z",
        endDateTime: "2023-08-29T12:33:41.412Z",
      }
      axios.post(UPDATELOTADDRESS, payload, config).then((response: any) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          if (mode === "clone") {
            dispatch(setCloneTab([...clonedTabs, LOTADDRESS]))
          }
          if (mode === "create") {
            dispatch(setCreateTab([...createTabs, LOTADDRESS]))
          }
          toast.success(InventoryMessages.addressUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          /* dispatch(setInventoryInfoActive({ activeTab: "logoPhotos" })); */
          dispatch(setStep('commCost'))
        }
      }, (error) => {
        setLoading(false);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      })
    })

  };

  useEffect(() => {
    clearErrors(["city1", "country1", "state1", "zip", "add1", "county1"])
  }, [address]);

  const updateCountryCode = (newAddress: any, code: any) => {
  };

  const getLotAddressbyId = (id: any) => {
    setLoading(true);
    axios.get(`${GETLOTADDRESSBYID}?lotId=${id}`, { ...config }).then((response: any) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        setIsData(true)
        return;
      } else {
        let respData = response.data.data;
        if (!clonedTabs.includes(LOTADDRESS) && cloneLotId > 0) {
          respData = { ...respData, lotAddressId: 0 }
        }
        setAddress(respData.addressLine1)
        setValue('add1', respData.addressLine1)
        setValue('add2', respData.addressLine2)
        setValue('city1', respData?.city1 ? respData?.city1 : respData?.cityName);
        setValue('state1', respData?.state1 ? respData?.state1 : respData?.stateName);
        setValue('country1', respData?.country1 ? respData?.country1 : respData?.countryName);
        setValue('zip', respData?.zipCode);
        setValue('county1', respData?.county1 ? respData?.county1 : respData?.countyName)
        setIsData(true)
        reset({
          ...respData,
          add1: respData.addressLine1,
          address2: respData?.addressLine2
        })
      }
    }).catch((error) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    const id = clonedTabs.includes(LOTADDRESS) ? cloneLotId : lotId
    getLotAddressbyId(id);
  }, []);

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTADDRESS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTADDRESS)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  const onAddressSelected = () => {
    // getTaxRate()
    setAddressSelected(true)
  }

  useEffect(() => {

    if (selectedCity && selectedCountry && selectedState && selectedZipcode && isAddressSelected) {
      setAddressSelected(false)
      if (watchTaxExemptCheck === false) {
        getTaxRate()
      }
    }

    return () => { }
  }, [selectedCity, selectedCountry, selectedState, selectedZipcode, isAddressSelected])


  return (
    <>

      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <div className="row top_border justify-content-center">
        <div className="col-xxl-10 mt-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3 mb-3">
              <div className="col-lg-12">
                <label className="form-label">Company Name</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Company Name"
                  maxLength={51}
                  {...register("companyName")}
                />
                {errors.companyName && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.companyName.message}
                  </span>
                )}
              </div>
              {/* Auction Email */}
              <div className="col-lg-3">
                <label className="form-label">
                  Auction Email <sup className="text-danger">*</sup>
                </label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Auction Email"
                  maxLength={51}
                  {...register("auctionEmail")}
                />
                {errors.auctionEmail && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.auctionEmail.message}
                  </span>
                )}
              </div>
              {/* Website */}
              <div className="col-lg-3">
                <label className="form-label">Website</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Website"
                  maxLength={51}
                  {...register("website")}
                />
                {errors.website && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.website.message}
                  </span>
                )}
              </div>
              {/* Notice/Status */}
              <div className="col-lg-3">
                <label className="form-label">Notice/Status</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Notice/Status"
                  maxLength={51}
                  {...register("notice")}
                />
                {errors.notice && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.notice.message}
                  </span>
                )}
              </div>
              {/* Custom Notice */}
              <div className="col-lg-3">
                <label className="form-label">Custom Notice</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Custom Notice"
                  maxLength={51}
                  {...register("customNotice")}
                />
                {errors.customNotice && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.customNotice.message}
                  </span>
                )}
              </div>
              <AddressForm
                errors={errors}
                onHandleChangeAddressLine={handleChangeAddressLine}
                register={register}
                setValue={setValue}
                setCountryCode={setCountryCode}
                onSetAddress={setAddress}
                onAddressChange={updateCountryCode}
                watch={watch}
                trigger={trigger}
                onAddressSelected={onAddressSelected}
              />
              {/* Contact Agent */}
              <div className="col-lg-3">
                <label className="form-label">
                  Contact Agent <sup className="text-danger">*</sup>
                </label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Contact Agent"
                  maxLength={21}
                  {...register("contactAgent")}
                />
                {errors.contactAgent && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.contactAgent.message}
                  </span>
                )}
              </div>
              {/* Mobile */}
              <div className="col-lg-3">
                <label className="form-label">
                  Mobile <sup className="text-danger">*</sup>
                </label>
                <Controller
                  // name="phone1"
                  {...register("phone1")}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value}
                      onChange={onChange}
                      country={countryCode}
                      priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                    />
                  )}
                />
                {errors.phone1 && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.phone1.message}
                  </span>
                )}
              </div>
              {/* Alternate Number */}
              <div className="col-lg-3">
                <label className="form-label">Alternate Number</label>
                <Controller
                  // name="phone2"
                  {...register("phone2")}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      value={value}
                      onChange={onChange}
                      country={countryCode}
                      priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                    />
                  )}
                />
                {errors.phone2 && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.phone2.message}
                  </span>
                )}
              </div>
              {/* Fax */}
              <div className="col-lg-3">
                <label className="form-label">Fax</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Fax"
                  maxLength={10}
                  {...register("fax")}
                />
                {errors.fax && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.fax.message}
                  </span>
                )}
              </div>
              <div className="col-12 mt-0">
                <div className="row mt-3">
                  {/* <div className="col-lg-2">
                    <label className="form-label">Tax Exempt</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="taxExempt"
                        {...register("taxExempt")}
                      />
                      <label htmlFor="taxExempt"> Yes </label>
                    </div>
                  </div> */}
                  <div className="col-lg-4">
                    <label className="form-label">Tax Rate (%)</label>
                    <CurrencyInput
                      {...register("taxRate")}
                      name="taxRate"
                      placeholder="Tax Rate"
                      allowDecimals={true}
                      decimalsLimit={5}
                      value={taxRate}
                      onValueChange={(value: any) => setValue("taxRate", value)}
                      className="form-control form-icon-input"
                      disabled
                    />

                    {watchTaxExemptCheck && errors.taxRate && (
                      <span className="text-danger" style={{ fontSize: "12px" }}>
                        {errors.taxRate.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <span
                      className="btn btn-primary mt-4"
                      onClick={(e) => getTaxRate()}
                    >
                      Calculate Tax Rate
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-0">
                <div className="row mt-4">
                  {/* <div className="col-lg-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      placeholder="Bidders in Master"
                      {...register("biddersInMaster")}
                      id="biddersInMaster"
                    />
                    <label className="form-label" htmlFor="biddersInMaster">
                      Bidders in Master
                    </label>
                  </div>
                </div> */}
                  {/* <div className="col-lg-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      placeholder="Use Geo Coding"
                      {...register("useGeocoding")}
                      id="useGeocoding"
                    />
                    <label className="form-label" htmlFor="useGeocoding">
                      Use Geo Coding
                    </label>
                  </div>
                </div> */}
                  <div className="col-lg-3">
                    <div className="form-check">
                      <input
                        id="showMap"
                        className="form-check-input"
                        type="checkbox"
                        placeholder="Show Auction Map"
                        {...register("auctionMap")}
                      />
                      <label className="form-label" htmlFor="showMap">
                        Show Auction Map
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="latLong"
                      {...register("showLatLong")}
                    />
                    <label className="form-label" htmlFor="latLong">
                      Lat/Lon
                    </label>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="col-lg-12">
                <label className="form-label">Client</label>
                <input
                  className="form-control form-icon-input"
                  placeholder="Client"
                  maxLength={51}
                  {...register("client")}
                />
                {errors.client && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.client.message}
                  </span>
                )}
              </div>
              <div className="col-lg-12">
                <label className="form-label">Auction Note</label>
                <textarea
                  className="form-control"
                  aria-label="With textarea"
                  placeholder="Auction Note"
                  maxLength={maxWordCountShort}
                  {...register("auctionNotes", {
                    onChange: (e) =>
                      setRemainingCount2(
                        wordCounter(e.target.value, maxWordCountShort)
                      ),
                  })}
                />
                {remainingCount2 != maxWordCountShort && (
                  <div
                    className={remainingCount2 <= 0 ? "text-danger" : ""}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      width: "100%",
                      fontSize: "12px",
                    }}
                  >
                    {remainingCount2 <= 0
                      ? "Maximum word limit reached"
                      : `Remaining words: ${remainingCount2}`}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
              <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
              <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal">{CommonMessages.cancelButton}</Link>
              <button className="btn btn-primary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Address;

