import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import InnerHeader from '../InnerHeader/InnerHeader';
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import Select from "react-select";
import { GETALLAUCTIONLIST, SELLERBYSALESREPREPORT } from '../../common/apiConstatnts';
import axios from 'axios';
import { useSelector } from 'react-redux';
import handleError from '../../Utils/ApiCommonErrorMessages';
import { generateSummaryOfSellerBySalesRepCsv, generateSummaryOfSellerBySalesRepPdf } from './ExportReportsPdfCsvFile';
import moment from 'moment';

const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Reports", url: routeConstant.REPORTS },
    { label: "Summary of Sellers by Sales Rep",url: routeConstant.SUMMARYOFSELLERBYSALESREP},
];

const SummaryOfSellerBySalesRep = () => {
    const [loading, setLoading] = useState(false);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [auctionList, setAuctionList] = useState<any []>([]);
    const [auctionCode, setAuctionCode] = useState('')
    const [sellerSummaryBySalesRepData, setSellerSummaryBySalesRep] = useState<any[]>([]);
    const [sellerSummaryTotals, setSellerSummaryTotals] = useState<any>({})
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const config: any = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST, config).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    };

    const getSummaryOfSellerBySalesRep = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${SELLERBYSALESREPREPORT}?auctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                const SellerBySalesRepData = response.data.data;
                if (SellerBySalesRepData?.lstSummaryForSeller == null) {
                    setSellerSummaryBySalesRep([]);
                    return;
                } else {
                    setSellerSummaryBySalesRep(SellerBySalesRepData?.lstSummaryForSeller);
                    setSellerSummaryTotals(SellerBySalesRepData);
                    setLoading(false)
                }
            }).catch((error)=>{
                setLoading(false)
                handleError(error);
            })
        }   

    }

    useEffect(() => {
        getAllAuctions()
    }, [])

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    useEffect(() => {
        if (selectedAuction?.value ) {
            getSummaryOfSellerBySalesRep(selectedAuction?.value)
        }
    }, [selectedAuction])

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Summary of Sellers by Sales Rep'>
                    <div className="col-12">
                        <div className="row justify-content-between g-2 mt-1 align-items-end">
                            <div className="col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-1"> Select Auction</label>
                                <div className="col-lg-8">
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select Auction"
                                    className="isActive swap-to-lot-menu clone-inventory"                                    
                                    options={auctionList}
                                    filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                    value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                    onChange={(e: any) => {
                                        handleAuctionChange(e);
                                    }}
                                />
                                </div>                               
                            </div>
                            <div className="col-md-4 col-lg-3 mt-2 ms-auto">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={()=> generateSummaryOfSellerBySalesRepCsv(sellerSummaryBySalesRepData, sellerSummaryTotals)} disabled={sellerSummaryBySalesRepData.length === 0}>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2" onClick={()=> generateSummaryOfSellerBySalesRepPdf(sellerSummaryBySalesRepData, sellerSummaryTotals)}
                                    disabled={sellerSummaryBySalesRepData.length === 0} >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                        {sellerSummaryBySalesRepData?.length > 0 ?
                            <> 
                                <div className="table-responsive">
                                    <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                                <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Summary of Sellers by Sales Rep</p>
                                                <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                    <span>{`Auction #${
                                                        selectedAuction 
                                                        ? selectedAuction?.label?.split('-')[0]
                                                        : auctionCode 
                                                            ? hammerAuction 
                                                            : hammerAuction?.label?.split('-')[0]
                                                    }`}</span><br />
                                                </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="w-100 mt-4" style={{ borderCollapse: 'collapse' }}>
                                    {sellerSummaryBySalesRepData && sellerSummaryBySalesRepData?.length > 0 && sellerSummaryBySalesRepData.map((data:any, index) =>{
                                        return(
                                            <>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1", }}><div>Date</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'center', border: "1px solid #e1e1e1", }}><div>Auc Code</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1", }}><div>Seller Name</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'center', border: "1px solid #e1e1e1", }}><div>ID</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Total Sold</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Commission</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Buyback</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Comm</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Passed</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Comm</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>BP</div></th>
                                                    <th style={{ fontSize: '12px',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Listing Fees</div></th>
                                                </tr>
                                            </thead>
                                             <thead key={index}>
                                                <tr>
                                                    <th colSpan={4} style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'left', border: "1px solid #e1e1e1",}}><div>{data.strSalesRep}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalSold)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalSoldCommission)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalBuyBack)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalBuyBackCommission)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalPassed)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalPassedCommission)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalBuyerPremium)}</div></th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalListingFees)}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.lstSellerSummary && data?.lstSellerSummary.length > 0 && data?.lstSellerSummary.map((sellerLst:any, key:any) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td style={{ fontSize: '11px', textAlign: 'left', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>{moment(sellerLst.startDateTime).format('MM/DD/YYYY')}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'center', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>{sellerSummaryTotals?.auctionCode}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'left', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>{sellerLst.sellerName}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'center', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>{sellerLst.sellerId}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.soldAmount)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.soldCommission)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.buyBackAmount)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.buyBackCommission)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.passedAmount)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.passedCommission)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.buyerPremium)}</td>
                                                            <td style={{ fontSize: '11px', textAlign: 'right', whiteSpace: "nowrap", border: "1px solid #e1e1e1" }}>${formatAmount(sellerLst.listingFees)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tbody style={{marginTop: "5px"}}>
                                                <tr>
                                                    <td colSpan={4} style={{ border: "0", }}><div></div></td>
                                                    <td style={{borderBottom: "1px solid #e1e1e1", }}><div></div></td>
                                                    <td colSpan={2} style={{ border: "0", }}><div></div></td>
                                                    <td style={{borderBottom: "1px solid #e1e1e1", }}><div></div></td>
                                                    <td colSpan={2} style={{ border: "0", }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',  }}><div>Net Sales</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalNetSales)}</div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Listing Fees</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.listingFees)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Net Sales Commission</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalNetSalesCommission)}</div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Listing Fee Credits</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.listingFeesCredits)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',}}><div>Net No Sales</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalNetNoSales)}</div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',}}><div>Fastline Advertising</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.fastLineAdvertising)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',}}><div>Net No Sales Commission</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.totalNetNoSalesCommission)}</div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',}}><div>Lien Search Fee</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.lienSearchFee)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Direct Social Package</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.directSocialPackage)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff',  borderBottom: '0',}}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff',  borderBottom: '0',}}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff',  borderBottom: '0',}}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff',  borderBottom: '0',}}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Preferred Social Fee</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.preferredSocialFee)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Removal Fee</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.removalFee)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Commission Credit</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.commissionCredit)}</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0',}}><div></div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div></div></td>
                                                    <td colSpan={2} style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Bad Debt</div></td>
                                                    <td style={{ fontSize: '11px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1", }}><div>${formatAmount(data.badDebt)}</div></td>
                                                    <td colSpan={2} style={{ fontWeight: "Bold" , fontSize: '12px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', borderBottom: '0', }}><div>Total Commissions</div></td>
                                                    <td colSpan={2} style={{ fontWeight: "Bold" ,fontSize: '12px', whiteSpace: "nowrap", textAlign: 'right', backgroundColor: '#ffffff', border: "1px solid #e1e1e1",  }}><div>${formatAmount(data.totalCommissions)}</div></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12} style={{ borderBottom: "1px solid #e1e1e1", }}><div></div></td>
                                                </tr>
                                            </tbody>
                                            </>
                                        )
                                    })}
                                    <tfoot style={{marginTop: "5px"}}>
                                        <tr>
                                            <th rowSpan={2} colSpan={4} style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1",}}><div>Total</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Total Sold</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Commission</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Buyback</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Comm</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Passed</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Comm</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>BP</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>Listing</div></th>
                                        </tr>
                                        <tr>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalSold)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalSoldCommission)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalBuyBack)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalBuyBackCommission)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalPassed)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalPassedCommission)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalBuyerPremium)}</div></th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#f1f1f1',  whiteSpace: "nowrap", textAlign: 'right', border: "1px solid #e1e1e1", }}><div>${formatAmount(sellerSummaryTotals.finalListingFees)}</div></th>
                                        </tr>

                                    </tfoot>
                                </table>
                                </div>
                            </>
                            :  getNoDataView()
                        }                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SummaryOfSellerBySalesRep;
