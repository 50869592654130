import React, { useEffect, useState } from "react";
import InnerHeaderBreadCrumb from "../InnerHeader/InnerHeaderBreadCrumb/InnerHeaderBreadCrumb";
import Sidebar from "../Sidebar/Sidebar";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import {
  GETCOMMONVALUE,
  GETAUCTIONDEFAULT,
  UPDATEAUCTION,
  CREATEAUCTION,
  GETTIMEZONEIDENTIFIER,
} from "../../common/apiConstatnts";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tab, Tabs } from "react-bootstrap";
import { CKEditor } from "ckeditor4-react";
import parse from "html-react-parser";
import ModifiedBy from "../LastModifiedDateTime/ModifiedBy";
import LastModified from "../LastModifiedDateTime/LastModifiedDateTime";
import { AlPHANUMERIC, EMAIL, EMAIL_MESSAGE, DECIMAL73, DECIMAL73_MESSAGE, STRINGSPACE } from "../../common/regexConstants";
import { wordCounter } from "../../common/constants";
import { AuctionDefaultMessages, CommonMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES, handleKeyDown, trimObjectValues } from "../../Utils/Utils";
import Select from 'react-select';
import CurrencyInput from "react-currency-input-field";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../Utils/CustomCkEditor";
import CommonHelmet from "../../common/HelmetWrapper";

// import loader from "../../assets/loader.gif";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConstant.DASHBOARD },
  { label: "Auction Defaults", url: "" },
];

const AuctionDefault = () => {
  const initialData = {
    auctionDefaultId: 0,
    companyId: localStorage.getItem("companyId"),
    auctionEmail: "",
    defaultcreditLimit: "",
    defaultTimeZone: "",
    defaultCurrency: "",
    defaultMasterBidderId: "",
    defaultMasterBidder: "",
    defaultMasterBidderFullName: "",
    defaultMasterSellerId: "",
    defaultMasterSeller: "",
    defaultMasterSellerFullName: "",
    defaultUploadTerms: "",
  };
  const timezone = localStorage.getItem("timeZoneId");

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<any>(initialData);
  const [defaultCurrency, setDefaultCurrency] = useState<[]>([]);
  const [defaultTimeZone, setDefaultTimeZone] = useState<[]>([]);
  // const [ckEditorInit, setCkEditor] = useState<any>();
  const [modifiedBy, setModifiedBy] = useState('');
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);

  const superAdminRole = decodedToken.isDefaultRole;

  const schema: any = yup.object().shape({
    auctionEmail: yup
      .string()
      .required("Email address is required")
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(EMAIL, EMAIL_MESSAGE)
      .test('len', 'Please enter upto 50 characters', val => val.length < 50),
    defaultcreditLimit: yup
      .string()
      .required("Default credit limit is required")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL73, DECIMAL73_MESSAGE),
    defaultTimeZone: yup.string().required("Please select timezone"),
    defaultCurrency: yup.string().required("Please select currency"),
    defaultMasterBidderId: yup
      .string()
      .required("Default Master Bidder Id is required")
      .matches(/^\d+$/, "Default Master Bidder ID must be a number")
      .test('len', 'Please enter upto 10 digits', val => val.length < 10),
    defaultMasterBidder: yup
      .string()
      .required("Default Master Bidder is required")
      .matches(/^\d+$/, "Default Master Bidder must be a number")
      .test('len', 'Please enter upto 10 digits', val => val.length < 10),
    defaultMasterBidderFullName: yup
      .string()
      .required("Please enter Bidder name")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s*$/.test(value) : true;
      })
      .test("alphanumeric-dot", "Please enter valid Bidder name", (value) => {
        return value ? /^(?![0-9]*$)[a-zA-Z0-9 ]+$/.test(value) : true;
      })
      .test('len', 'Please enter upto 50 characters', val => val.length < 50),
    defaultMasterSellerId: yup
      .string()
      .required("Default Master Seller Id is required")
      .matches(/^\d+$/, "Default Master Seller ID must be a number")
      .test('len', 'Please enter upto 10 digits', val => val.length < 10),
    defaultMasterSeller: yup
      .string()
      .required("Default Master Seller is required")
      .matches(/^\d+$/, "Default Master Seller must be a number")
      .test('len', 'Please enter upto 10 digits', val => val.length < 10),
    defaultMasterSellerFullName: yup
      .string()
      .required("Seller name is required")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? !/^\s*$/.test(value) : true;
      })
      .test("alphanumeric-dot", "Please enter valid Seller name", (value) => {
        return value ? /^(?![0-9]*$)[a-zA-Z0-9 ]+$/.test(value) : true;
      })
      .test('len', 'Please enter upto 50 characters', val => val.length < 50)
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (watchDefaultTimeZone == "" && timezone) {
      setValue("defaultTimeZone", timezone);
    }
  }, [timezone]);

  const watchDefaultTimeZone = watch('defaultTimeZone')
  const watchDefaultCurrency = watch('defaultCurrency')
  const defaultcreditLimit = watch('defaultcreditLimit')
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${GETCOMMONVALUE}?filter=DefaultCurrency&companyId=${localStorage.getItem(
          "companyId"
        )}`,
        config
      )
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            // toast.error(response?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
            return;
          }
          else {
            let formateData = response.data.data?.map((data: any, index: any) => {

              return {
                label: data.objectTypeValue, value: data.objectValueId
              }
            })
            setDefaultCurrency(formateData);
          }
        }
      ).catch((error) => {
        setLoading(false);
        handleError(error);
      });
    axios.get(GETTIMEZONEIDENTIFIER, config).then((response: any) => {
      // Set response in time zone state
      let formateData = response.data.data?.map((data: any, index: any) => {
        //Format response in label and value format for select dropdown
        return {
          label: data?.label,
          value: data?.timeZoneIdentifierId,
        };
      })
      setDefaultTimeZone(formateData)
    }).catch((err) => {
      handleError(err)
    })
  }, []);



  const getauctionDefualt = (() => {
    axios.get(GETAUCTIONDEFAULT, config).then(
      (response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          // toast.error(response?.data?.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          return;
        }
        else {
          const data = response?.data?.data;
          if (data) {
            setModifiedBy(response?.data?.data?.modifiedBy)

            const modifiedData = {
              ...data,
              defaultcreditLimit: String(data?.defaultcreditLimit),
              defaultMasterBidderId: String(data.defaultMasterBidderId),
              defaultMasterBidder: String(data.defaultMasterBidder),
              defaultMasterSellerId: String(data.defaultMasterSellerId),
              defaultMasterSeller: String(data.defaultMasterSeller),
            };

            setFormValues(modifiedData);
            // setUpdateDate(false);
            reset(modifiedData);
          }

        }
        setLoading(false);
      }
    ).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  })

  useEffect(() => {
    getauctionDefualt();
  }, [])
  const onSubmit = (data: any) => {
    const trimData = trimObjectValues(data);

    if (superAdminRole === "True") {
      const addDefaultUploadTerms: any = {
        ...trimData,
        defaultUploadTerms: formValues.defaultUploadTerms,
      };
      const {
        createdOn,
        companyLogo,
        createdBy,
        isDelete,
        updatedBy,
        updatedOn,
        ...payload
      } = addDefaultUploadTerms;
      const apiUrl = data.auctionDefaultId == 0 ? CREATEAUCTION : UPDATEAUCTION;
      const message =
        data.auctionDefaultId == 0
          ? AuctionDefaultMessages.auctionDefaultCreated
          : AuctionDefaultMessages.auctionDefualtUpdated;
      setLoading(true);
      axios.post(apiUrl, { ...payload, isActive: true }, config).then(
        (response: any) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          }
          else {
            toast.success(message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            getauctionDefualt();
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
    else {
      setLoading(false);
      toast.error("Only super admin has permission to perform this operation on the page.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const onEditorChange = (value: any) => {
    setFormValues({ ...formValues, defaultUploadTerms: value });
  };

  return (
    <>
        <CommonHelmet title={"Auction Defualt - "} name={"company"} content={"Company Info"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeaderBreadCrumb breadCrumb={breadcrumbData} />
        <div className="container-fluid for-fix-bottom-space">
          <div className="row justify-content-center border-top pt-4 pb-4">
            <div className="col-xxl-10">
              <div className="container-fluid">
                <div className="row">
                  <h4 className="mb-3">Auction Defaults</h4>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <h5 className="mb-2">Auction Email :</h5>
                </div>
              </div>
              {/* <div className="bg-white mb-5 border-300"> */}
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label className="form-label">
                        Email <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        maxLength={50}
                        {...register("auctionEmail")}
                      />
                      {errors.auctionEmail && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.auctionEmail.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-12 m-0">
                      <hr></hr>
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        Default Credit Limit
                        <sup className="text-danger">*</sup>
                      </label>
                      <CurrencyInput
                        type="text"
                        placeholder="Default Credit Limit"
                        {...register("defaultcreditLimit")}
                        // prefix="$"
                        allowDecimals={false}
                        decimalsLimit={10}
                        onKeyDown={handleKeyDown}
                        value={defaultcreditLimit}
                        onValueChange={(value: any) => setValue("defaultcreditLimit", value)}
                        className="form-control form-icon-input"
                        required
                      />
                      {errors.defaultcreditLimit && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultcreditLimit.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-12 m-0">
                      <hr></hr>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Default Time Zone </label>
                        <Select
                          classNamePrefix="dpaSelect"
                          placeholder="Select Default Time Zone"
                          options={defaultTimeZone}
                          {...register('defaultTimeZone')}
                          value={defaultTimeZone?.find((option: any) => option?.value == watchDefaultTimeZone)}
                          onChange={(e: any) => setValue('defaultTimeZone', e.value)}
                          className='isActive'
                        />

                        {errors.defaultTimeZone && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.defaultTimeZone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Default Currency </label>
                        <Select
                          classNamePrefix="dpaSelect"
                          placeholder="Select Default Currency"
                          options={defaultCurrency}
                          {...register('defaultCurrency')}
                          value={defaultCurrency?.find((option: any) => option?.value == watchDefaultCurrency)}
                          onChange={(e: any) => setValue('defaultCurrency', e.value)}
                          className='isActive'
                        />

                        {errors.defaultCurrency && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {errors.defaultCurrency.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 m-0">
                      <hr></hr>
                    </div>
                    <div className="col-lg-12 mt-2">
                      <h5 className="mb-2">Default Master Bidder :</h5>
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        ID <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="ID"
                        maxLength={10}
                        {...register("defaultMasterBidderId")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors.defaultMasterBidderId && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterBidderId.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        # <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="123"
                        maxLength={10}
                        {...register("defaultMasterBidder")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors.defaultMasterBidder && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterBidder.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        Full Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control form-icon-input"
                        type="text"
                        placeholder="Full Name"
                        maxLength={50}
                        {...register("defaultMasterBidderFullName")}
                      />

                      {errors.defaultMasterBidderFullName && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterBidderFullName.message}
                        </span>
                      )}
                    </div>

                    <div className="col-lg-12 m-0">
                      <hr></hr>
                    </div>
                    <div className="col-lg-12 m-0">
                      <h5 className="mb-2">Default Master Seller :</h5>
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        ID <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="ID"
                        maxLength={10}
                        {...register("defaultMasterSellerId")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors.defaultMasterSellerId && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterSellerId.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        # <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="123"
                        maxLength={10}
                        {...register("defaultMasterSeller")}
                        onKeyDown={handleKeyDown}
                      />
                      {errors.defaultMasterSeller && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterSeller.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-4 col-sm-4 mb-3">
                      <label className="form-label">
                        Full Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        maxLength={50}
                        {...register("defaultMasterSellerFullName")}
                      />
                      {errors.defaultMasterSellerFullName && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "12px" }}
                        >
                          {errors.defaultMasterSellerFullName.message}
                        </span>
                      )}
                    </div>
                    <div className="col-lg-12 m-0">
                      <hr></hr>
                    </div>
                    <div className="col-lg-12 mt-1">
                      <h5 className="mb-2">Default Upload Terms :</h5>
                    </div>
                    <div className="col-12">
                      <Tabs
                        defaultActiveKey="preview"
                        className="mb-3 nav-underline"
                      >
                        <Tab eventKey="preview" title="Source">
                          {/* {ckEditorInit} */}
                          <CustomCkEditor
                            data={formValues?.defaultUploadTerms}
                            onTextChanged={onEditorChange} />
                        </Tab>
                        <Tab eventKey="source" title="Preview">
                          <div className="card box-shadow">
                            <div className="card-body ck_editor">
                              {formValues?.defaultUploadTerms &&
                                parse(formValues?.defaultUploadTerms)}
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>

                {/* Static Footer */}
                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                  <Link
                    to={routeConstant.DASHBOARD}
                    className="btn btn-outline-danger me-auto"
                    type="button"
                    data-wizard-prev-btn="data-wizard-prev-btn"
                  >
                    {CommonMessages.cancelButton}
                  </Link>
                  <ModifiedBy data={formValues} />
                  <LastModified data={formValues} />
                  <button
                    className="btn btn-primary mx-2"
                    type="submit"
                    data-wizard-next-btn="data-wizard-next-btn"
                  >
                    {CommonMessages.saveButton}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuctionDefault;
