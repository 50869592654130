import React, { useState } from "react";
import { bidderExemptType, formatPhoneNumber } from "../../common/constants";
import Tippy from "@tippyjs/react";
import { formatCurrency, removeHtmlTags } from "../../Utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import * as routeConstant from "../../common/routeConstants";
import { encryptId } from "../../Encryption/Encryption";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { errorMessages, InvoiceMessages } from "../../common/messageConstants";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import {
  ADDNEWINVOICE,
  CHECKINVOICECUSTOMER,
  GETCUSTOMERBYAUCTION,
  GETINVOICECUSTOMER,
  GETLISTTAXRATEBYLOTADDRESS,
  MOVELOTTOANOTHERCUSTOMER,
} from "../../common/apiConstatnts";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { useDispatch } from "react-redux";
import { setStep } from "../../store/actions/Inventory";
import MomentUtil from "../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../Utils/useDateTime";

// Interface
interface LotsDataListingProps {
  wonLotsList: any;
  ID: any;
  onSubmit: any;
  invoiceDetails: any;
  initailInvoiceData: any;
  handleRadioChangebid: any;
  bidExempt: any;
  config?: any;
  showListing: any;
  setShowListing: any;
  isVerified: any;
}
const LotsDataListing: React.FC<LotsDataListingProps> = ({
  wonLotsList,
  ID,
  onSubmit,
  initailInvoiceData,
  handleRadioChangebid,
  bidExempt,
  config,
  showListing,
  setShowListing,
  isVerified,
}) => {
  const momentUtil = MomentUtil.getInstance();
  const dateTime = useCurrentDateTime();
  const [loading, setLoading] = useState(false);
  const [singleLot,setSingleLot]:any = useState({});
  // Delete ID state
  const [selectDeleteID, setSelectDeleteId]: any = useState("");
  // Display customer transfer popup
  const [customerModal, setCustomerModal] = useState<any>(false);
  // Select customer and bidder after trasfer click
  const [selectedBidder, setSelectedBidder] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState<any>("");
  // Customer list
  const [customerList, setCustomerList] = useState([]);
  // Display lot detail modal
  const [lotDetailModal, setLotDetailModal] = useState<any>(false);
  const [getInvoice, setGetInvoice]:any= useState([]);
  // State to select invoice
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  // Usenavigate hook
  const navigate = useNavigate();

  const dispatch = useDispatch()
  // API integrated new tax exempt function
  const handleTaxExemptChange = (index: number, data: any) => {
    // Copy the current object
    const newData = { ...data };
    const currentExempt = newData?.exempt;
    const currentTaxRate = newData?.taxrate;
    // Toggle the exempt value
    newData.exempt =
      currentExempt === null || currentExempt === false ? true : false;
    // If tax exempt is true means yes then tax rate is 0
    if (newData?.exempt) {
      newData.taxrate = 0
      // Calculate remove tax from total :
      // tax amount = (Lot Price + BP) * Tax %
      // Total amount without tax = Total - taxAmount
      const taxAmount = (Number(newData?.price) + Number(newData?.buyersPrice))
      newData.total = taxAmount
      // Update the state with the new object
      const newShowListing = [...showListing];
      newShowListing[index] = newData;
      setShowListing(newShowListing);
    } else {
      // else if tax rate is false means tax should be calulate so call API to get tax rate
      // Payload
      const payload = [
        {
          lotId: data?.lotId,
          addressLine1: data?.addressLine1 !== null ? data?.addressLine1 : data?.lotaddressline1,
          city: data?.strCity,
          state: data?.strState,
          country: data?.strCountry,
          zipCode: data?.zipCode,
          taxExempt: false
        }
      ]
      setLoading(true);
      axios
        .post(
          GETLISTTAXRATEBYLOTADDRESS,
          payload,
          config
        )
        .then(
          (response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              const data: any = response?.data?.data;
              // Set new address rate in the selected taxrate
              newData.taxrate = data[0]?.taxRate;
              //  // Calculate using new tax from total :
              // tax amount = (Lot Price + BP) * Tax %
              // Total amount without tax = Total + taxAmount
              const taxAmount = (Number(newData?.price) + Number(newData?.buyersPrice)) * (newData.taxrate) / 100
              newData.total = newData?.total + taxAmount
              // Update the state with the new object
              const newShowListing = [...showListing];
              newShowListing[index] = newData;
              setShowListing(newShowListing);
              setLoading(false)
            }
          }).catch((error) => {
            setLoading(false);
            handleError(error);
          });
    }

  };
  //  Delete Lot function
  const handleDeleteLot = (id: any, data: any) => {
    setSingleLot(data);
    if (initailInvoiceData?.isVoid) {
      toast.success(errorMessages.canNotPerformDelete, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else {
      setSelectDeleteId(id);
      const checkActiveLots = showListing.filter((fData: any) => fData.isActive === true)
      // If data length is 1 then can not delete data
      if (checkActiveLots?.length == 1) {
        Swal.fire({
          title: "Sorry,cannot delete this record ",
          text: "we need to keep atleast one lot",
          icon: "warning",
          confirmButtonColor: "#006800",
          confirmButtonText: "ok",
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this record!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete it!",
        }).then(
          (result) => {
            if (result?.isConfirmed) {
              // Step 1: Confirmation Prompt for Deletion
              // Step 2: Popup for Lot Transfer
              Swal.fire({
                title: "Transfer Lot?",
                text: "Do you want to transfer this lot to another buyer?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#006800",
                cancelButtonColor: "#d33",
                confirmButtonText: "Transfer to Another Buyer",
                cancelButtonText: "Remove  Invoice",
                showCloseButton: true,
              }).then((transferResult) => {
                if (transferResult?.isConfirmed) {
                  // Display modal popup for customer transfer
                  setCustomerModal(true);
                  setLoading(true);
                  // AuctionId
                  const auctionId = ID ? initailInvoiceData?.auctionId : wonLotsList[0].auctionId;
                  //  Get customers by auction Id - Change endpoint once API get
                  axios.get(`${GETCUSTOMERBYAUCTION}?AuctionId=${auctionId}&lotId=${data?.lotId}&CustomerId=${initailInvoiceData?.customerId}`, config).then(
                    (response) => {
                      setLoading(false);
                      if (response?.data?.success === "Fail") {
                        toast.error(response.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 2000,
                        });
                        return;
                      } else {
                        const data: any = response?.data?.data;
                        const customerData = data?.map((lData: any) => {
                          return {
                            label: `${lData?.customerId} - ${lData?.customerName}`,
                            value: lData?.customerId,
                            bidderNo: lData?.bidderNumber
                          };
                        });
                        setCustomerList(customerData);
                      }
                    }).catch((error) => {
                      setLoading(false);
                      handleError(error)
                    });
                } else {
                  const updatelots = showListing?.map((lotA: any) => {
                    if (lotA?.lotId === id) {
                      return { ...lotA, isActive: false, isDelete: true };
                    }
                    return lotA;
                  });

                  setShowListing(updatelots);
                }
              });
            }
          },
          (error) => {
            toast.error(errorMessages?.error401, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        );
      }
    }
  };

  // Close popup
  const handleClose = () => {
    setCustomerModal(false);
    setSelectedCustomer("");
    setSelectedBidder("");
    setCustomerList([]);
  };

  // Customer change function
  const handleCustomerChange = (e: any) => {
    const selectedCustomerId = e?.value;
    setSelectedBidder(e.bidderNo);
    setSelectedCustomer(selectedCustomerId);
  };


  // Functionality to transfer customer
  const handleTransfer = () => {
    const customerId = parseInt(selectedCustomer);
    const bidderNumber = parseInt(selectedBidder);
    const auctionId = ID
      ? initailInvoiceData?.auctionId
      : wonLotsList[0].auctionId;

    axios
      .get(
        `${CHECKINVOICECUSTOMER}?AuctionId=${auctionId}&customerId=${customerId}&bidderNumber=${bidderNumber}`
      )
      .then((response: any) => {
        setLoading(false);
        setCustomerModal(false);
        setSelectedCustomer("");
        setSelectedBidder("");
        handleClose();
        if (response?.data?.data === true) {
          Swal.fire({
            title: "Invoice is already done for this customer.",
            text: "Do you want to add this lot to the existing invoice or create another invoice?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Add to existing invoice",
            cancelButtonText: "Create new invoice",
            confirmButtonColor: "#006800",
            cancelButtonColor: "#d33",
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              getInvoiceCustomer();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              onSubmit({
                actionType: "createInvoice",
                customerId: selectedCustomer,
                bidderNumber: selectedBidder,
                lotId: selectDeleteID,
              });
            }
          });
        } else if (response?.data?.data === false) {
          const payload = {
            invoiceMasterId: 0,
            dateOfInvoice: momentUtil.parseTimeInUtc({
              dateTimeString: dateTime?.currentDateTime,
            }).format("YYYY-MM-DDTHH:mm:ss.SSS"),
            auctionId: initailInvoiceData?.auctionId,
            customerId: selectedCustomer,
            bidderNumber: selectedBidder,
            invoiceTotal: singleLot?.buyersPrice + singleLot?.price+singleLot?.lottaxabletotaltax,
            verificationCode: "",
            isActive: true,
            isDelete: false,
            isVerified: false,
            // When create invoice payment is always pending
            invoicePaid: 3,
            isVoid: false,
            invoiceNumber: 0,
            buyersPremium: singleLot?.buyersPrice,
            subTotal: singleLot?.buyersPrice + singleLot?.price,
            itemTotal: singleLot?.price,
            lotTaxSubTotal: singleLot?.buyersPrice + singleLot?.price+singleLot?.lottaxabletotaltax,
            balanceDue: singleLot?.buyersPrice + singleLot?.price+singleLot?.lottaxabletotaltax,
            payment: 0,
            bidExempt: false,
            invoiceAdjustment: 0,
            invoicePayment: 0,
            depositePaid: 0,
            notes: "" ,
            perLotInternetFeeTotal: 0,
            taxableItemTotal: singleLot?.lottaxableitemtotal,
            taxablePerLotInternetFeeTotal: 0,
            taxablebuyersPremium: singleLot?.lottaxablebuyersPremium,
            taxabletotalTax: singleLot?.lottaxabletotaltax,
            lstInvoiceDetailsRequests: [
                {
                    invoiceDetailId: 0,
                    invoiceMasterId: 0,
                    lotId: singleLot?.lotId,
                    type:  singleLot?.type,
                    price:  singleLot?.price,
                    qty: 1,
                    extendedAmount: singleLot?.extendedAmount,
                    invAdjust: "",
                    surchages: "",
                    internetFee: 0,
                    buyersPrice: singleLot?.buyersPrice,
                    total: singleLot?.total,
                    taxRate: singleLot?.taxrate,
                    exempt: singleLot?.exempt,
                    notes: '',
                    isActive: singleLot?.isActive,
                    isDelete: singleLot?.isDelete,
                    lotTaxableItemTotal: singleLot?.lottaxableitemtotal,
                    lotTaxableBuyersPremium: singleLot?.lottaxablebuyersPremium,
                    lotTaxableTotalTax: singleLot?.lottaxabletotaltax
                }
            ],
            lstInvoiceAdjustRequest: [],
            lstInvoicePaymentRequest: []
        };
        setLoading(true);
          axios
            .post(ADDNEWINVOICE, payload, config)
            .then((response) => {
              setLoading(false);
              if (response?.data?.success === "Fail") {
                toast.error(
                  `${response?.data?.message}, because its reviewed and verified`,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  }
                );
                return;
              } else {
                toast.success(InvoiceMessages.invoiceCreated, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                navigate(routeConstant.INVOICE);
                setLoading(false);
              }
            })
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error)
      });
  };

  //  Get invoice customer
  const getInvoiceCustomer = () => {
    setLotDetailModal(true);
    const customerId = parseInt(selectedCustomer);
    const bidderNumber = parseInt(selectedBidder);
    const auctionId = ID
      ? initailInvoiceData?.auctionId
      : wonLotsList[0].auctionId;
    axios
      .get(
        `${GETINVOICECUSTOMER}?AuctionId=${auctionId}&customerId=${customerId}&bidderNumber=${bidderNumber}`
      )
      .then((response: any) => {
        const getData = response?.data?.data;
        setGetInvoice(getData);
        if (getData?.length > 1) {
          setLotDetailModal(true);
        } else {
          movelotInOtherCustomer(getData[0]?.invoiceMasterId);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error)
      });
  };

  // Move lots to other customer
  const movelotInOtherCustomer = (invoiceMasterId
    :any) => {
    const getDataofSelectedCheckbox: any = getInvoice?.filter((item: any) => {
      return item?.invoiceMasterId === selectedInvoice;
    });

    const getNewMasterId: any = getInvoice?.map((item: any) => {
      return item.invoiceMasterId;
    });

    const getDataofDeleteId: any = showListing?.filter((item: any) => {
      return item?.lotId === selectDeleteID;
    });
    const payload = {
      ...getDataofDeleteId[0],
      auctionId: ID ? initailInvoiceData?.auctionId : wonLotsList[0]?.auctionId,
      customerId: ID ? initailInvoiceData?.customerId : selectedCustomer,
    };

    const auctionId = ID
      ? initailInvoiceData?.auctionId
      : wonLotsList[0]?.auctionId;
    const NewcustomerId = getDataofSelectedCheckbox[0]?.customerId
      ? getDataofSelectedCheckbox[0]?.customerId
      : selectedCustomer;
    const NewBidderNumber = getDataofSelectedCheckbox[0]?.bidderNumber
      ? getDataofSelectedCheckbox[0]?.bidderNumber
      : selectedBidder;
    const lotId = selectDeleteID;
    const NewInvoiceMasterId = getDataofSelectedCheckbox[0]?.invoiceMasterId
      ? getDataofSelectedCheckbox[0]?.invoiceMasterId
      : getNewMasterId;
    const oldInvoiceMasterId = initailInvoiceData?.invoiceMasterId
      ? initailInvoiceData?.invoiceMasterId
      : 0;

    axios
      .post(
        `${MOVELOTTOANOTHERCUSTOMER}?auctionId=${auctionId}&NewcustomerId=${NewcustomerId}&NewbidderNumber=${NewBidderNumber}&lotId=${lotId}&NewinvoiceMasterId=${invoiceMasterId}&oldInvoiceMasterId=${oldInvoiceMasterId}`,
        payload,
        config
      )
      .then((response: any) => {
        setLoading(false);
        if (response.data.data === true) {
          toast.success("Lot moved to another customer successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLotDetailModal(false);
          navigate(routeConstant.INVOICE);
        } else if (response.data.data === false) {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };
  return (
    <>
      {/* Loading */}
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      {/* If customer modal true then display this popup */}
      <Modal show={customerModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Buyer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-check-label">Select Buyer</label>
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              className="isActive swap-to-lot-menu clone-inventory"
              name="customerId"
              options={customerList}
              value={
                selectedCustomer
                  ? customerList.find(
                    (option: any) => option?.value === selectedCustomer
                  )
                  : null
              }
              onChange={(e: any) => {
                handleCustomerChange(e);
              }}
            />
          </div>
          {selectedBidder &&
            <div>
              <label className="form-check-label">Bidder # - {selectedBidder}</label>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleTransfer()}
            disabled={selectedCustomer?.length == 0}
          >
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for get Invoice popup */}
      {/*Modal for selecting the invoiceMasterId*/}
      {getInvoice?.length > 1 ? (
        <Modal
          show={lotDetailModal}
          onHide={() => setLotDetailModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p>Buyer Id - {selectedCustomer}</p>
              <p>Bidder # - {selectedBidder}</p>
              <p>Select Invoice #</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-style-none invoice_number">
              {getInvoice?.map((initialData: any, index: any) => (
                <li key={index}>
                  <div className="form-check">
                    <label className={initialData.invoiceNumber}>
                      <span>{initialData.invoiceMasterId}</span>
                      <input
                        id={`type_${initialData.value}`}
                        className="form-check-input"
                        type="radio"
                        name="invoiceDetails"
                        value={initialData.invoiceMasterId}
                        onChange={() =>
                          setSelectedInvoice(initialData.invoiceMasterId)
                        } // Attach onChange event handler
                      />
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => setLotDetailModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => movelotInOtherCustomer(getInvoice[0]?.invoiceMasterId)}>
              Transfer
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
      {/* Section 1 - customer personal info / invoice number */}
      <div className="d-flex justify-content-between pt-0 flex-wrap mt-4 mb-4">
        <div className="d-flex flex-wrap justify-content-between col-lg-4">
          <div className="pe-4">
            <p className="mb-0 fs--1 fw-medium">
              <i className="fa-solid fa-user me-2"></i>
              {ID
                ? initailInvoiceData?.customerName
                : wonLotsList[0]?.customerName}
            </p>
            <p className="mb-0 fs--1 fw-medium">
              <i className="fa fa-location-dot me-2"></i>
              {ID
                ? `${initailInvoiceData?.customerAddress},${initailInvoiceData?.customerCity},${initailInvoiceData?.customerState},${initailInvoiceData?.customerCountry}`
                : wonLotsList[0]?.customerAddress}
            </p>
            <p className="mb-0 fs--1 fw-medium">
              <i className="fa fa-envelope me-2"></i>
              {ID
                ? initailInvoiceData?.customerEmail
                : wonLotsList[0]?.customerEmail}
            </p>
            <p className="mb-0 fs--1 fw-medium">
              <i className="fa-solid fa-phone me-2"></i>
              {ID
                ? formatPhoneNumber(
                  initailInvoiceData?.customerMobile ??
                  initailInvoiceData?.customerMobile
                )
                : formatPhoneNumber(
                  wonLotsList[0]?.customerMobile ??
                  wonLotsList[0]?.customerMobile
                )}
            </p>
          </div>
        </div>
        {/* Bidder exempt selection */}
        <div className="col-lg-4">
          <div>
            <h6 className="fw-400">Bidder Exempt</h6>
            {bidderExemptType?.map((bidType: any, index: number) => {
              return (
                <div className="form-check form-check-inline mb-3">
                  <input
                    id={`type_${bidType?.value}`}
                    type="radio"
                    className="form-check-input me-2"
                    name="bidExempt"
                    value={bidType?.value}
                    checked={bidType?.value === bidExempt}
                    disabled={initailInvoiceData?.isVoid === true}
                    onChange={() => handleRadioChangebid(bidType?.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`contactExempt_${index}`}
                  >
                    {bidType?.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        {/* Invoice number,auction id and customer name */}
        <div className="col-lg-4">
          {ID ? (
            <h6 className="text-end">
              Invoice #: {initailInvoiceData?.invoiceNumber}
            </h6>
          ) : (
            <></>
          )}
          {ID ? (
            <h6 className="text-end">
              Auction: {initailInvoiceData?.auctionCode} - {initailInvoiceData?.auctionTitle}
            </h6>
          ) : (
            <></>
          )}
          {ID ? (
            <h6 className="text-end">
              Buyer Name: {initailInvoiceData?.customerName}
            </h6>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Section - 2 - Table to display lots listing  */}
      <div className="table-responsive scrollbar">
        <table className="table fs--1 mb-0 border-top border-200">
          {/* Table head for title */}
          <thead>
            <tr>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Lot #</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Title</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Type</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Lot Price</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Quantity</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Item Contact </span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Item Location </span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Buyer Premium</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Tax%</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Exempt</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center">
                  <span>Total</span>
                </div>
              </th>
              <th className="bg-gradiant p-2" scope="col" data-sort="Status">
                <div className="d-flex align-items-center justify-content-end">
                  <span>DEL</span>
                </div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="list">
            {showListing.length > 0 ? (
              <>
                {showListing?.map((data: any, index: any) => {
                  if (!data.isDelete) {
                    return (
                      <tr
                        className="hover-actions-trigger btn-reveal-trigger position-static"
                        key={index}
                      >
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {data?.lotNo}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          <Tippy
                            content={
                              <div style={{ fontSize: "small" }}>
                                <p>Lot ID: {data?.lotId}</p>
                                <p>Title: {removeHtmlTags(data?.title)}</p>
                                <p>Type: {data?.type}</p>
                                <p>Lot Price: {formatCurrency(data?.price)}</p>
                                <p>Quantity: 1</p>
                                <p>
                                  Item Contact:
                                  {formatPhoneNumber(data?.itemContact)}
                                </p>
                                <p>Item Location: {data?.itemLocation}</p>
                                <p>Buyer Premium: {formatCurrency(data?.buyersPrice)}</p>
                                <p>Taxrate: {data?.taxrate}</p>
                                <p>
                                  Tax Exempt:
                                  {data.exempt ? "Yes" : "No"}
                                </p>
                                <p>Total: {formatCurrency(data?.total)}</p>
                              </div>
                            }
                            placement="auto"
                            animation={'fade'}
                          >
                            <Link
                              className="btn text-decoration-underline m-0 p-0 fw-normal text-start"
                              data-bs-dismiss="modal"
                              to={`${routeConstant.UPDATEINVENTORY}/${encryptId(
                                data?.lotId
                              )}`}
                              onClick={() => {
                                dispatch(setStep("lots"));
                              }}
                              target="_blank"
                            >
                              {data?.title ? removeHtmlTags(data?.title) : "-"}
                            </Link>
                          </Tippy>
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {data?.type}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {data?.price ? formatCurrency(data?.price) : "-"}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          1
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {data?.itemContact &&
                            data?.itemContact.trim().startsWith(",")
                            ? formatPhoneNumber(
                              data?.itemContact.trim().substring(1)
                            )
                            : formatPhoneNumber(data?.itemContact)}
                        </td>
                        {/* Item location has country and zipcode  */}
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {data?.strCity} , {data?.strState}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {formatCurrency(data?.buyersPrice)}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {/* If exempt yea means tax will be 0 else false then tax count */}
                          {data?.exempt
                            ? 0
                            : data?.taxrate
                              ? data?.taxrate
                              : 0}
                        </td>
                        {/* If exempt false means we have to count tax and if true then tax is 0 */}
                        {/*Toggle taxexempt to change new exempt from API */}
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {/* If invoice is verified then display span or else clikable link */}
                          {initailInvoiceData?.isVoid ? (
                            <span
                              className={`${data?.exempt
                                ? "m-0 p-0 fw-normal text-start"
                                : "m-0 p-0 fw-normal text-start text-danger"
                                }`}
                            >
                              {data?.exempt ? "Yes" : "No"}
                            </span>
                          ) : (
                            <Link
                              className={`${data?.exempt
                                ? "text-decoration-underline m-0 p-0 fw-normal text-start"
                                : "text-decoration-underline m-0 p-0 fw-normal text-start text-danger"
                                }`}
                              to="#"
                              role="button"
                              onClick={() => handleTaxExemptChange(index, data)}
                            >
                              {data?.exempt ? "Yes" : "No"}
                            </Link>
                          )}
                        </td>
                        <td className="align-middle white-space-nowrap text-start p-2">
                          {formatCurrency(data?.total)}
                        </td>
                        <td
                          className="align-middle text-end p-2"
                          onClick={() => handleDeleteLot(data?.lotId, data)}
                        >
                          <span className="text-danger">
                            <i className="fa fa-trash"></i>
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </>
            ) : (
              <>
                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                  <td className="text-center p-4" colSpan={14}>
                    No records found!
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LotsDataListing;
