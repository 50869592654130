import React, { useEffect, useState } from 'react'
import { GETALLCATEGORIES, GETALLSALESREPCOMMISSIONSCALE, GETALLSELLERCOMMISSIONSCALE, GETBPBYSCALES, RECALCULATESALESPERSONCOMM, RECALCULATESELLERCOMM } from '../../../../common/apiConstatnts'
import axios, { AxiosResponse } from 'axios'
import Select from 'react-select';
import { toast } from 'react-toastify';
import { errorMessages } from '../../../../common/messageConstants';
import CurrencyInput from 'react-currency-input-field';
import { handleKeyDown } from '../../../../Utils/Utils';
import handleError from '../../../../Utils/ApiCommonErrorMessages';
const Commission = ({ register, setValue, watch, getValues, setLoading, errors }: any) => {
  const [categoryOption, setCategoryOption] = useState<any>([]);
  const [sellerCommissionsOption, setSellerCommissionsOption] = useState<any>([]);
  // const [salesRepCommissionsOption, setSalesRepCommissionsOption] = useState<any>([]);
  const [bpScalesOption, setBPScalesOption] = useState<any>([]);
  const data = getValues()
  const commissionsData = data?.salesRecordCommisson
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const bpScaleId = watch("bpScaleId")
  const categoryId = watch("categoryId")
  const lotStatus = watch("lotStatus")
  // const salesPersonNonPayerId = watch("salesPersonNonPayerId")
  // const salesPersonBuyBackId = watch("salesPersonBuyBackId")
  // const salesPersonUnSoldId = watch("salesPersonUnSoldId")
  // const salesPersonSoldId = watch("salesPersonSoldId")
  const sellerNonPayerId = watch("sellerNonPayerId")
  const sellerBuyBackId = watch("sellerBuyBackId")
  const sellerUnSoldId = watch("sellerUnSoldId")
  const sellerSoldId = watch("sellerSoldId")
  const lotprize = watch("price")
  // const salesPersonCalculatedCommission = watch('salesPersonCalculatedCommission')
  // const salesPersonReportedCommission = watch('salesPersonReportedCommission')
  const sellerCalculatedCommission = watch('sellerCalculatedCommission')
  const sellerReportedCommission = watch('sellerReportedCommission')

  const GetAllSellerCommissionScale = () => {
    axios.get(GETALLSELLERCOMMISSIONSCALE, config).then((response) => {
      if (response?.data?.success === "Fail") {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        const scaleData = response.data?.data?.lstSellerCommissionScale ?? []
        const sellerCommissions = scaleData?.map((scale: any) => {
          return {
            label: scale?.taxRegionName,
            value: scale?.sellerCommissionScaleId
          }
        })
        setSellerCommissionsOption(sellerCommissions)
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  }

  // const GetAllSalesRepCommissionScale = () => {
  //   axios.get(GETALLSALESREPCOMMISSIONSCALE, config).then((response) => {
  //     if (response?.data?.success === "Fail") {
  //       toast.error(response.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 2000,
  //       });
  //       return;
  //     }
  //     else {
  //       const scaleData = response.data?.data?.lstSalesRepCommissionScale ?? []
  //       const salesRepCommissions = scaleData?.map((scale: any) => {
  //         return {
  //           label: scale?.taxRegionName,
  //           value: scale?.salesRepCommissionScaleId
  //         }
  //       })
  //       setSalesRepCommissionsOption(salesRepCommissions)
  //     }
  //   }).catch((error) => {
  //     setLoading(false);
  //     handleError(error);
  //   });
  // }

  const getAllCategoriesList = () => {
    axios.get(`${GETALLCATEGORIES}?pageNumber=-1`, config).then(
      (response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        const data = response?.data?.data?.data;
        let categories = data?.map((aData: any, index: any) => {
          return {
            label: aData.categoryTitle, value: aData.categoryId
          }
        })
        setCategoryOption(categories)
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  }

  const getBPScale = () => {
    axios.get(`${GETBPBYSCALES}`, config).then(
      (response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        const data = response?.data?.data;
        let bpScales = data?.map((aData: any, index: any) => {
          return {
            label: aData?.taxRegionName, value: aData?.bpBySalesScaleId
          }
        })
        setBPScalesOption(bpScales)
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  }

  const recalculateSeller = () => {
    let commId: any
    if (lotStatus == 1) {
      commId = sellerSoldId
    } else if (lotStatus == 2) {
      commId = sellerUnSoldId
    }
    else if (lotStatus == 3) {
      commId = sellerBuyBackId
    }
    else if (lotStatus == 4) {
      commId = sellerNonPayerId
    }

    if (!commId) return;

    setLoading(true)
    axios.get(`${RECALCULATESELLERCOMM}?lotprize=${lotprize}&commissionId=${commId}`).then(
      (response) => {
        setLoading(false)
        if (response?.data?.success === "Fail") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        const data = response?.data?.data;
        setValue('sellerCalculatedCommission', data)
        setValue('sellerReportedCommission', 0)
        toast.success('Commission Recalculated Successfully.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
  }

  // const recalculateSalesPerson = () => {
  //   let commId: any
  //   if (lotStatus == 1) {
  //     commId = salesPersonSoldId
  //   } else if (lotStatus == 2) {
  //     commId = salesPersonUnSoldId
  //   }
  //   else if (lotStatus == 3) {
  //     commId = salesPersonBuyBackId
  //   }
  //   else if (lotStatus == 4) {
  //     commId = salesPersonNonPayerId
  //   }

  //   if (!commId) return;

  //   setLoading(true)
  //   axios.get(`${RECALCULATESALESPERSONCOMM}?lotprize=${lotprize}&commissionId=${commId}`).then(
  //     (response) => {
  //       setLoading(false)
  //       if (response?.data?.success === "Fail") {
  //         toast.error(response.data.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 2000,
  //         });
  //         return;
  //       }
  //       const data = response?.data?.data;
  //       setValue('salesPersonCalculatedCommission', data)
  //       setValue('salesPersonReportedCommission', 0)
  //       toast.success('Commission Recalculated Successfully.', {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 2000,
  //       });
  //     }).catch((error) => {
  //       setLoading(false);
  //       handleError(error);
  //     });;
  // }

  useEffect(() => {
    // GetAllSalesRepCommissionScale()
    GetAllSellerCommissionScale()
    getAllCategoriesList()
    getBPScale()
  }, [])

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-lg-12">
          <h4>BP Calc Method Scale : </h4>
        </div>
        <div className="col-md-3 col-md-3">
          <label className="form-label">BP Scale </label>
          <div className="code-to-copy z-index-9999">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={bpScalesOption}
              {...register("bpScaleId")}
              value={bpScalesOption?.find(
                (option: any) => option?.value == bpScaleId
              )}
              onChange={(e: any) => {
                setValue("bpScaleId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Category </label>
          <div className="code-to-copy z-index-9999">
          <Select
            classNamePrefix="dpaSelect"
            placeholder="Select"
            options={categoryOption}
            {...register("categoryId")}
            value={categoryOption?.find(
              (option: any) => option?.value == categoryId
            )}
            onChange={(e: any) => {
              setValue("categoryId", e.value);
            }}
            className="isActive"
          />
          </div>    

          {/* <select className="form-select">
            {categoryOption.map((category: any) => (
              <option value={category.categoryId}>
                {category.categoryTitle}
              </option>
            ))}
          </select> */}
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-lg-12">
          <h4>Seller : </h4>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Sold</label>
          <div className="code-to-copy z-index-9999">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={sellerCommissionsOption}
              {...register("sellerSoldId")}
              value={sellerCommissionsOption?.find(
                (option: any) => option?.value == sellerSoldId
              )}
              onChange={(e: any) => {
                setValue("sellerSoldId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Passed/Unsold</label>
          <div className="code-to-copy z-index-9999">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={sellerCommissionsOption}
              {...register("sellerUnSoldId")}
              value={sellerCommissionsOption?.find(
                (option: any) => option?.value == sellerUnSoldId
              )}
              onChange={(e: any) => {
                setValue("sellerUnSoldId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">BuyBack</label>
          <div className="code-to-copy z-index-9999">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={sellerCommissionsOption}
              {...register("sellerBuyBackId")}
              value={sellerCommissionsOption?.find(
                (option: any) => option?.value == sellerBuyBackId
              )}
              onChange={(e: any) => {
                setValue("sellerBuyBackId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-3">
          <label className="form-label">Non-Payer</label>
          <div className="code-to-copy z-index-9999">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={sellerCommissionsOption}
              {...register("sellerNonPayerId")}
              value={sellerCommissionsOption?.find(
                (option: any) => option?.value == sellerNonPayerId
              )}
              onChange={(e: any) => {
                setValue("sellerNonPayerId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-3">
          <label className="form-label">Calculated Comm</label>
          <div className="code-to-copy">
            <CurrencyInput
              type="text"
              placeholder="Calculated Commission"
              {...register("sellerCalculatedCommission")}
              prefix="$"
              onKeyDown={handleKeyDown}
              value={sellerCalculatedCommission}
              onValueChange={(value: any) =>
                setValue("sellerCalculatedCommission", value)
              }
              className="form-control form-icon-input"
            />
            {errors.sellerCalculatedCommission && (
              <span
                className="text-danger"
                style={{ fontSize: "12px" }}
              >
                {errors.sellerCalculatedCommission.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Reported Comm</label>
          <div className="code-to-copy">
            <CurrencyInput
              type="text"
              placeholder="Calculated Commission"
              {...register("sellerReportedCommission")}
              prefix="$"
              onKeyDown={handleKeyDown}
              value={sellerReportedCommission}
              onValueChange={(value: any) =>
                setValue("sellerReportedCommission", value)
              }
              className="form-control form-icon-input"
            />
            {errors.sellerReportedCommission && (
              <span
                className="text-danger"
                style={{ fontSize: "12px" }}
              >
                {errors.sellerReportedCommission.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-3 my-6">
          <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
            onClick={() => recalculateSeller()}
          >
            <span><span className="plus fas fa-refresh"></span> Recalculate Commission </span>
          </button>
        </div>
      </div>

      {/* <div className="row g-3 mb-3">
        <div className="col-lg-12">
          <h4> Sales Rep:</h4>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Sold</label>
          <div className="code-to-copy">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={salesRepCommissionsOption}
              {...register("salesPersonSoldId")}
              value={salesRepCommissionsOption?.find(
                (option: any) => option?.value == salesPersonSoldId
              )}
              onChange={(e: any) => {
                setValue("salesPersonSoldId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Passed/Unsold</label>
          <div className="code-to-copy">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={salesRepCommissionsOption}
              {...register("salesPersonUnSoldId")}
              value={salesRepCommissionsOption?.find(
                (option: any) => option?.value == salesPersonUnSoldId
              )}
              onChange={(e: any) => {
                setValue("salesPersonUnSoldId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">BuyBack</label>
          <div className="code-to-copy">
            <Select
              classNamePrefix="dpaSelect"
              placeholder="Select"
              options={salesRepCommissionsOption}
              {...register("salesPersonBuyBackId")}
              value={salesRepCommissionsOption?.find(
                (option: any) => option?.value == salesPersonBuyBackId
              )}
              onChange={(e: any) => {
                setValue("salesPersonBuyBackId", e.value);
              }}
              className="isActive"
            />
          </div>
        </div>

        <div className="col-lg-3 col-md-3">
          <label className="form-label">Non-Payer</label>
          <Select
            classNamePrefix="dpaSelect"
            placeholder="Select"
            options={salesRepCommissionsOption}
            {...register("salesPersonNonPayerId")}
            value={salesRepCommissionsOption?.find(
              (option: any) => option?.value == salesPersonNonPayerId
            )}
            onChange={(e: any) => {
              setValue("salesPersonNonPayerId", e.value);
            }}
            className="isActive"
          />
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Calculated Comm</label>
          <div className="code-to-copy">
            <CurrencyInput
              type="text"
              placeholder="Calculated Commission"
              {...register("salesPersonCalculatedCommission")}
              prefix="$"
              allowDecimals={false}
              decimalsLimit={10}
              onKeyDown={handleKeyDown}
              value={salesPersonCalculatedCommission}
              onValueChange={(value: any) =>
                setValue("salesPersonCalculatedCommission", value)
              }
              className="form-control form-icon-input"
              required
            />
            {errors.salesPersonCalculatedCommission && (
              <span
                className="text-danger"
                style={{ fontSize: "12px" }}
              >
                {errors.salesPersonCalculatedCommission.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-3">
          <label className="form-label">Reported Comm</label>
          <div className="code-to-copy">
            <CurrencyInput
              type="text"
              placeholder="Reported Commission"
              {...register("salesPersonReportedCommission")}
              prefix="$"
              allowDecimals={false}
              decimalsLimit={10}
              onKeyDown={handleKeyDown}
              value={salesPersonReportedCommission}
              onValueChange={(value: any) =>
                setValue("salesPersonReportedCommission", value)
              }
              className="form-control form-icon-input"
              required
            />
            {errors.salesPersonReportedCommission && (
              <span
                className="text-danger"
                style={{ fontSize: "12px" }}
              >
                {errors.salesPersonReportedCommission.message}
              </span>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-3 my-6">
          <button className="btn btn-primary position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal"
            onClick={() => recalculateSalesPerson()}
          >
            <span><span className="plus fas fa-refresh"></span> Recalculate Commission </span>
          </button>
        </div>
      </div> */}
    </>
  );
}

export default Commission;