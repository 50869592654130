import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { GETALLAUCTIONLIST, GETINVOICESUMMARYREPORT } from "../../common/apiConstatnts";
import { useSelector } from "react-redux";
import { generateInvoiceSummaryCsv, generateInvoiceSummaryPdf } from "./ExportReportsPdfCsvFile";
import moment from "moment";


const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Invoice Summary Report', url: "" }
]

const InvoiceSummaryReport = () => {
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const [loading, setLoading] = useState(false);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [invoiceSummaryData, setInvoiceSummaryData] = useState<any>(null);
    const [invoiceInnerData, setInvoiceInnerData] = useState<any>([]);
    const hammerAuction: any = auctionList.find((option: any) => option.value === getGlobalAuction.value);
    const [globalAuction, setGlobalAuction] = useState<any>('');
    const InnerData = invoiceSummaryData?.lstInvoiceMasterDetial;
    let AuctionStartDate = invoiceSummaryData ? invoiceSummaryData?.auctionStartDate : null;
    const handleAuctionChange = (e: any) => {
        setSelectedAuction(e);
        setGlobalAuction(e)
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction: any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });;
    };

    // Function to fetch TaxSummaryData  based on the selected auction ID
    const fetchInvoiceSummaryData = (auctionId: any) => {
        setLoading(true);
        if (auctionId) {
            axios.get(`${GETINVOICESUMMARYREPORT}?AuctionId=${auctionId}`).then((response) => {
                setLoading(false);
                const data = response?.data?.data;

                if (data?.lstInvoiceMasterDetial == null) {
                    setInvoiceSummaryData(null);
                    setInvoiceInnerData([]);
                }
                else {
                    setInvoiceSummaryData(data);
                    setInvoiceInnerData(data?.lstInvoiceMasterDetial)
                }
            }).catch((error: any) => {
                setLoading(false);
                handleError(error);
            });
        }
        // Set loading state to true to indicate data fetching in progress
    };

    useEffect(() => {
        getAllAuctions();
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            fetchInvoiceSummaryData(selectedAuction?.value)
        }
    }, [selectedAuction]);

    useEffect(() => {
        if (getGlobalAuctionIds) {
            setSelectedAuction(getGlobalAuction);
            setGlobalAuction(getGlobalAuction)
        }
        else {
            getAllAuctions();
        }
    }, [getGlobalAuction])

    const formatAmount = (number: any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Invoice Summary Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                <div className="col-lg-8">
                                    <Select
                                        classNamePrefix="dpaSelect"
                                        placeholder="Select Auction"
                                        className="isActive swap-to-lot-menu clone-inventory"
                                        options={auctionList}
                                        filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                        value={selectedAuction?.value ? auctionList.find((option: any) => option?.value === selectedAuction.value) : getGlobalAuction}
                                        onChange={(e: any) => {
                                            handleAuctionChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3"
                                        onClick={() => generateInvoiceSummaryCsv(invoiceSummaryData,getGlobalAuction)}
                                        disabled={invoiceInnerData?.length == 0 || invoiceInnerData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3 ms-2"
                                        onClick={() => generateInvoiceSummaryPdf(invoiceSummaryData,getGlobalAuction)}
                                        disabled={invoiceInnerData?.length == 0 || invoiceInnerData == undefined}
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only_view">
                            {invoiceInnerData && invoiceInnerData?.length > 0 ?
                                <>
                                    <table className="w-100" style={{ border: 0, verticalAlign: 'top' }}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ border: 0, width: '100%' }}>
                                                    <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Invoice Summary Report</p>
                                                    <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                                        <span>{`Auction #${selectedAuction
                                                            ? selectedAuction?.label?.split('-')[0]
                                                            : invoiceInnerData?.auctionCode
                                                                ? hammerAuction
                                                                : hammerAuction?.label?.split('-')[0]
                                                            }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>

                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="table-responsive scrollbar">
                                        <table className="w-100 mt-2" style={{ borderCollapse: 'collapse' }}>
                                            <thead className="">
                                                <tr>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'center' }}><div>Inv #</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'center' }}><div>Bidder #</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Items</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Premium</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Taxable</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Sales Tax</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Adjust</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Total</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Total Payments</div></th>
                                                    <th style={{ fontSize: '14px', whiteSpace: "nowrap", textAlign: 'right' }}><div>Balance Due</div></th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={2} style={{ fontSize: '16px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'left', borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center", borderRight: "1px solid #e1e1e1" }}><div>Auction Totals</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumItems)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumPremium)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTaxable)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumSalesTax)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumAdjust)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTotal)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderRight: "1px solid #e1e1e1", borderTop: "3px solid #000000", borderBottom: "3px solid #000000", borderLeft: "5px solid #acacac",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTotalPayment)}</div></th>
                                                    <th style={{ fontSize: '14px', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right',borderTop: "3px solid #000000", borderBottom: "3px solid #000000",alignItems:"right" }}><div>${formatAmount(invoiceSummaryData?.sumBalanceDue)}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {InnerData && InnerData?.map((invoiceLst: any, key: any) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td style={{ fontSize: '14px', textAlign: 'center', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>{invoiceLst?.invoiceNumber}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'center', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>{invoiceLst?.bidder}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.items)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.premium)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.taxable)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.salesTax)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.adjust)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1",alignItems:"center" }}>${formatAmount(invoiceLst?.total)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap", borderRight: "1px solid #e1e1e1", borderLeft: "5px solid #acacac",alignItems:"center" }}>${formatAmount(invoiceLst?.totalPayment)}</td>
                                                            <td style={{ fontSize: '14px', textAlign: 'right', whiteSpace: "nowrap",alignItems:"center" }}>${formatAmount(invoiceLst?.balanceDue)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                    <td colSpan={2} style={{ fontSize: '16px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'left', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center", }}><div>Auction Totals</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumItems)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumPremium)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTaxable)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumSalesTax)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumAdjust)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTotal)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderLeft: "5px solid #acacac",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumTotalPayment)}</div></td>
                                                    <td style={{ fontSize: '14px',fontWeight:'bold', backgroundColor: '#f1f1f1', whiteSpace: "nowrap", textAlign: 'right', borderTop: "3px solid #303030", borderBottom: "3px solid #303030", borderRight: "1px solid #e1e1e1",alignItems:"center" }}><div>${formatAmount(invoiceSummaryData?.sumBalanceDue)}</div></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </>
                                :
                                <>
                                    {getNoDataView()}
                                </>


                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default InvoiceSummaryReport;
