import axios from "axios";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import InnerHeader from "../InnerHeader/InnerHeader";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from "../../common/routeConstants";
import ReactPaginate from "react-paginate";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  CREATETAXRATE,
  DELETETAXRATEBYID,
  UPDATETAXRATE,
  GETALLTAXRATE,
  GETCOMMONVALUE,
  GETTAXRATEBYID,
  GETALLCATEGORIES,
} from "../../common/apiConstatnts";
import Swal from "sweetalert2";
import {
  CommonMessages,
  TaxRateMessage,
  errorMessages,
} from "../../common/messageConstants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import GooglePlacesWithCSC from "../Company/GooglePlacesWithCSC";
import { charCountForAddress } from "../../common/commonFunctions";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SPECIALCHARS } from "../../common/regexConstants";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import { AddressForm, onGetDefaultValues } from "../../common/AddressForm";
import Select from "react-select";
import AsyncPagination from "../../common/AsyncPagination";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: routeConstant.DASHBOARD },
  { label : "Tax & Currency", url :"" },
  { label: "Tax Rate", url: "" },
];

const TaxRate = () => {
  const initialData = {
    taxRateId: "",
    taxExempt: false,
    categoryId: "",
    // addressLine: '',
    // country: '',
    // state: '',
    // city: '',
    // zipcode: '',
    add1: "",
    add2: "",
    city1: "",
    state1: "",
    country1: "",
    zip: "",
    county:''
  };

  const [loading, setLoading] = useState(false);
  const [taxRateData, setTaxRateData] = useState<[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [initialFormValue, setFormValue] = useState<any>(initialData);
  const [filterData, setFilterData] = useState<any>({});
  const [filterTaxRate, setFilterTaxRate] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [originalTaxRate, setOriginalTaxRate] = useState<[]>([]);
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("create");
  const [allCat, setAllCat] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [country, setCountry] = useState<any>([]);
  const [state, setState] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [remainingCountAdd, setRemainingCountAdd] = useState(250);
  const [addressLine1, setAddressLine1] = useState("");
  const [currentUser, setCurrentUser] = useState<any>();

  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("us");
  const [isAddressFilled, setIsAddressFilled] = useState(false);
  const [catValue, setCatValue]: any = useState({});
  const schema: any = yup.object().shape({
    category: yup.string().required("Category is required field"),
    add1: yup
      .string()
      .required("Address line 1 is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      .matches(SPECIALCHARS, "Please enter valid Address"),
    country1: yup.string().required("Please select country"),
    state1: yup.string().required("Please select state"),
    city1: yup.string().required("Please select city"),
  });
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    clearErrors,
    setValue,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValue,
    mode: "onChange",
  });
  const category = watch("categoryId");
  const selectedCountry = watch("country1");
  const selectedState = watch("state1");

  const reGolbalCate = useSelector(
    (state: any) => state?.global?.globalCategories
  );

  const PER_PAGE = 10;
  const pageCount = Math.ceil(taxRateData?.length / PER_PAGE);
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const paginateData = (data: any) => {
    setTaxRateData(data);
    const firstSet: any = data?.slice(0, PER_PAGE);
    setFilterTaxRate(firstSet);
  };
  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const nextSet: any = taxRateData?.slice(offset, offset + PER_PAGE);
    setFilterTaxRate(nextSet);
  }, [currentPage]);

  const handlePageClick = ({ selected: selectedPage }: any) => {
    setCurrentPage(selectedPage);
  };

  const getAllTaxRate = () => {
    setLoading(true);
    axios.get(GETALLTAXRATE, config).then(
      (response: any) => {
        if (response?.data?.success === "Fail") {
          setLoading(false);
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setFilterTaxRate([]);
          return;
        } else {
          const data = response?.data?.data;
          setOriginalTaxRate(data);
          paginateData(data);
        }
        setLoading(false);
      }).catch((error)=>{
        setLoading(false);
        handleError(error);
    });
  };
  useEffect(() => {
    getAllTaxRate();
  }, []);

  const changeHandler = (fieldName: string, value: any) => {};

  const clearAdvanceFilterData = () => {};
  const handleAdvanceFilter = () => {};

  const onSubmit = (data: any) => {
    setLoading(true);

    onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any) => {
      const payload = {
        taxRateId: data?.taxRateId ? data?.taxRateId : 0,
        taxExempt: data?.taxExempt,
        categoryId: parseInt(data?.category),
        addressLine: data?.add1,
        country: parseInt(countryId),
        state: parseInt(stateId),
        city: parseInt(cityId),
        zipcode: data?.zip,
      };
      if (mode === "create") {
        axios.post(CREATETAXRATE, payload, config).then(
          (response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              setLoading(false);
              toast.success(TaxRateMessage.taxRateSaved, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              reset(initialData);
              setAddressLine1("");
              setAddress("");
              setToggleValue(!toggleModal);
              getAllTaxRate();
            }
          }).catch((error)=>{
            setLoading(false);
            handleError(error)
        });
      } else {
        axios.post(UPDATETAXRATE, payload, config).then(
          (response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              toast.success(TaxRateMessage.taxRateUpdated, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              reset(initialData);
              setAddressLine1("");
              setAddress("");
              setToggleValue(!toggleModal);
            }
          }).catch((error)=>{
            setLoading(false);
            handleError(error)
        });
      }
    });
  };
  const handleEditModal = (taxRateId: string, type: string) => {
    if (type === "edit") {
      setMode("edit");
      setLoading(true);
      axios.get(`${GETTAXRATEBYID}?taxrateId=${taxRateId}`, config).then(
        (response: any) => {
          setToggleValue(!toggleModal);
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            const data = response.data.data;
            reset(data);
            setAddressLine1(response.data.data.addressLine1);
            setAddress(response.data.data.addressLine);
            setCurrentUser(data);
            setCatValue({
              value: data?.categoryId,
              label: data?.description,
            });
            setValue("city1", response?.data?.data?.cityName);
            setValue("state1", response?.data?.data?.stateName);
            setValue("country1", response?.data?.data?.countryName);
            setValue("zip", response?.data?.data?.zipCode);
          }
        } ).catch((error)=>{
          setLoading(false);
          handleError(error)
      });
      return;
    }
    if (type === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios
            .post(`${DELETETAXRATEBYID}?taxrateId=${taxRateId}`, {}, config)
            .then(
              (response) => {
                if (response?.data?.success === "Fail") {
                  toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  return;
                } else {
                  setLoading(false);
                  toast.success(TaxRateMessage.deleteTaxRate, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                  getAllTaxRate();
                  setCurrentPage(0);
                  setSearchText("");
                }
              } ).catch((error)=>{
                setLoading(false);
                handleError(error)
            });
        }
      });
    }
  };

  const handleDisplay = (e: any, isDisplay: any, elemId: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Display will be changed",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {});
  };
  const handleSearch = (e: any) => {
    const keyword = e?.toLocaleLowerCase();
    const filterData: any = originalTaxRate?.filter(
      (x: any) =>
        x.description?.toLocaleLowerCase()?.includes(keyword) ||
        x.taxRateId?.toString()?.includes(keyword)
    );

    paginateData(filterData);
    setCurrentPage(0);
  };

  // const handleChangeAddressLine = (e: any) => {
  //     const inputText = e.target.value;
  //     const currentCount = inputText.length;
  //     const remaining = 250 - currentCount;

  //     if (remaining < 0) {
  //         return;
  //     }
  //     if (e.target.name === 'addressLine1') {
  //         setValue('addressLine1', inputText)
  //         setAddressLine1(inputText)
  //         charCountForAddress(inputText.length)
  //         setRemainingCountAdd(remaining);
  //     }
  // }

  const handleChangeAddressLine = (e: any) => {
    charCountForAddress(e.target.value.length);
    setAddress(e.target.value);
    setValue("address", e.target.value);
    setValue("add1", e.target.value);
    setIsAddressFilled(true);
  };

  const handleClose = () => {
    setCurrentUser({});
    if (isDirty) {
      Swal.fire({
        title: "Are you sure?",
        text: "Changes will be discarded!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Discard",
      }).then((result) => {
        if (result.isConfirmed) {
          reset(initialData);
          setToggleValue(!toggleModal);
        }
      });
      return;
    }
    reset(initialData);
    setToggleValue(!toggleModal);
  };

  useEffect(() => {
    clearErrors(["city1", "country1", "state1", "zip", "add1"]);
  }, [address]);
  let filterCategory = reGolbalCate?.map((aData: any, index: any) => {
    return {
      label: aData.categoryTitle,
      value: aData.categoryId,
    };
  });
  useEffect(() => {
    setAllCat(filterCategory);
  }, []);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const updateCountryCode = (newAddress: any, code: any) => {};

  return (
    <>
            <CommonHelmet title={"Tax Rate - "} name={"taxRate"} content={"Tax Rate"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Modal show={toggleModal} size="lg" centered>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              {mode === "create" ? "Add Tax Rate" : "Edit Tax Rate"}
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-3 mb-3">
              <div className=" col-lg-12">
                <div className="code-to-copy">
                  <div className=" col-md-12">
                    <label className="form-label">
                      Category<sup className="text-danger">*</sup>
                    </label>
                    <Select
                        classNamePrefix="dpaSelect"
                        placeholder="Select Category"
                        options={allCat}
                        {...register("category")}
                        value={allCat?.find(
                          (option: any) => option?.value == category
                        )}
                        onChange={(e: any) => setValue("category", e.value)}
                        className="isActive"
                        isDisabled={currentUser?.categoryId > 0}
                      />
                      {/*comment AsyncPagination as it's not able to search the data in list */}
                    {/* <AsyncPagination
                      register={register}
                      name="category"
                      setValue={setValue}
                      APIEndpoint={GETALLCATEGORIES}
                      label="categoryTitle"
                      value="categoryId"
                      selectedValue={catValue}
                      isDisabled={currentUser?.categoryId > 0}
                    /> */}
                    {errors.category && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.category.message}
                      </span>
                    )}
                  </div>
                  {/* <div className="col-lg-12 mt-4">
                                        <label className="form-label">
                                            Address Line 1<sup className="text-danger">*</sup>
                                        </label>
                                        <GooglePlacesWithCSC
                                            value={addressLine1}
                                            handleChangeAddressLine={handleChangeAddressLine}
                                            type="addressLine1"
                                            setValue={setValue}
                                            setAddressLine1={setAddressLine1}
                                            setIsChanged={setIsChanged}
                                            setCountry={setCountry}
                                            name="addressLine1"
                                            zipCodeType="zipCode"
                                            remainingCountAdd={remainingCountAdd}

                                        />
                                        {errors.addressLine1 && (
                                            <span
                                                className="text-danger"
                                                style={{ fontSize: "12px" }}
                                            >
                                                {errors.addressLine1.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className="row d-flex mt-4">
                                        <div className="col-lg-3">
                                            <label className="form-label">
                                                City<sup className="text-danger">*</sup>{" "}
                                            </label>
                                            <select className="form-select" {...register("city")}>
                                                <option value="">Select City</option>
                                                {city.map((cityData: any, index: any) => {
                                                    return (
                                                        <option key={index} value={cityData.objectValueId}>
                                                            {cityData.objectTypeValue}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errors.city && (
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    {errors.city.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label">
                                                State<sup className="text-danger">*</sup>{" "}
                                            </label>
                                            <select className="form-select" {...register('state')}>
                                                <option value="">Select State</option>
                                                {
                                                    state?.map((sData: any, index: any) => {
                                                        return (
                                                            <option key={index} value={sData.objectValueId} disabled>{sData.objectTypeValue} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {errors.state && (
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    {errors.state.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label">
                                                Country<sup className="text-danger">*</sup>{" "}
                                            </label>
                                            <select className="form-select" {...register("country")}>
                                                <option value="">Select Country</option>
                                                {country?.map((countryData: any, index: any) => {
                                                    return (
                                                        <option key={index} value={countryData.objectValueId}>
                                                            {countryData.objectTypeValue}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errors.country && (
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    {errors.country.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-lg-3">
                                            <label className="form-label">
                                                Zip Code<sup className="text-danger">*</sup>
                                            </label>
                                            <input
                                                className="form-control form-icon-input"
                                                placeholder="Zip Code"
                                                maxLength={12}
                                                {...register("zipCode")}
                                            />
                                            {errors.zipCode && (
                                                <span
                                                    className="text-danger"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    {errors.zipCode.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> */}
                  <div className="col-lg-12 mt-4">
                    <AddressForm
                      errors={errors}
                      onHandleChangeAddressLine={handleChangeAddressLine}
                      register={register}
                      setValue={setValue}
                      setCountryCode={setCountryCode}
                      onSetAddress={setAddress}
                      taxRate={true}
                      isFull={true}
                      onAddressChange={updateCountryCode}
                      watch={watch}
                      latLongMap={false}
                      trigger={trigger}
                    />
                  </div>

                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-lg-2">
                        <label className="form-label ">Tax Exempt</label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            {...register("taxExempt")}
                          />
                          <span className="ms-1 ps-1 mt-1"> Yes </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              {CommonMessages.cancelButton}
            </button>
            <button className="btn btn-primary" type="submit">
              {mode != "create"
                ? CommonMessages.updateButton
                : CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Sidebar />
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Tax Rate">
          <div className="search-box">
            <div className="d-flex mt-2 align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  className="form-control search-input search form-control-sm"
                  type="text"
                  id="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                />
                <span className="fas fa-search search-box-icon"></span>
                <button
                  type="button"
                  className="p-0 btn ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            <button
              className="btn btn-primary ms-2 position-relative d-flex aling-items-center"
              type="button"
              data-bs-toggle="modal"
              onClick={() => {
                reset(initialData);
                setToggleValue(!toggleModal);
                setMode("create");
                setFormValue(initialData);
              }}
            >
              <span className="plus me-2">
                {" "}
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3"
                ></span>{" "}
              </span>{" "}
              <span> Add New Tax Rate</span>
            </button>
          </div>
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter"}
            >
              <div className="card bg-transparent position-relative p-3 mt-2">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row g-3">
                        <div className="col-lg-3">
                          <label className="form-label" htmlFor="text">
                            ID
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            name="userName"
                            placeholder="Id"
                            value={filterData.userName}
                            onChange={(e) =>
                              changeHandler("userName", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-3">
                          <label className="form-label" htmlFor="text">
                            Name
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            name="userName"
                            placeholder="Name"
                            value={filterData.userName}
                            onChange={(e) =>
                              changeHandler("userName", e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-3">
                          <label className="form-label" htmlFor="text">
                            Code
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="code"
                            name="code"
                            placeholder="Code"
                            value={filterData.email}
                            onChange={(e) =>
                              changeHandler("code", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end mt-3 ms-3">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={clearAdvanceFilterData}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          onClick={handleAdvanceFilter}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeader>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="mb-5 border-300">
                <div className="row top_border">
                  <div className="col-12">
                    <div className="mb-5 border-300">
                      <div className="table-responsive mx-n1 px-1 scrollbar">
                        <table className="table fs--1 mb-0 border-top border-200">
                          <thead>
                            <tr>
                              <th className="bg-gradiant" scope="col">
                                Action
                              </th>
                              <th className="bg-gradiant" scope="col">
                                ID
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="Initials"
                              >
                                <div className="d-flex align-items-center">
                                  <span>Rate Name/Description</span>
                                </div>
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="Email"
                              >
                                <div className="d-flex align-items-center">
                                  <span>Code</span>
                                </div>
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="phoneNum"
                              >
                                ST
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isSeller"
                              >
                                Zip
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                Tax Rate %
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                Country %
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                State %
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                City %
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                Special %
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                Display
                              </th>
                              <th
                                className="bg-gradiant"
                                scope="col"
                                data-sort="isBuyer"
                              >
                                Default
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list" id="table-latest-review-body">
                            {filterTaxRate.length > 0 ? (
                              filterTaxRate.map((data: any, index: any) => {
                                return (
                                  <>
                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                      <td className="align-middle white-space-nowrap text-start p-2">
                                        <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                          <Dropdown.Toggle
                                            className="m-0 p-0"
                                            variant="none"
                                          >
                                            <button
                                              className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                              type="button"
                                            >
                                              {" "}
                                              <img
                                                src="/assets/img/ellipsis-solid.svg"
                                                style={{ width: "20px" }}
                                              />
                                            </button>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            className={`dropdown-menu dropdown-menu-start py-2`}
                                          >
                                            <Dropdown.Item
                                              as="button"
                                              className="dropdown-item d-flex align-items-center justify-content-between"
                                              onClick={() =>
                                                handleEditModal(
                                                  data.taxRateId,
                                                  "edit"
                                                )
                                              }
                                            >
                                              Edit{" "}
                                              <i className="fas fa-pen dropdown-icon"></i>{" "}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as="button"
                                              className="dropdown-item d-flex align-items-center justify-content-between"
                                              onClick={() =>
                                                handleEditModal(
                                                  data?.taxRateId,
                                                  "delete"
                                                )
                                              }
                                            >
                                              Delete{" "}
                                              <i className="fas fa-trash dropdown-icon"></i>{" "}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.taxRateId}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.description
                                          ? data.description
                                          : "-"}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.taxCode ? data.taxCode : "-"}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.stateName ? data.stateName : "-"}
                                      </td>
                                      <td className="align-middle  p-2">
                                        {data.zipCode ? data.zipCode : "-"}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data?.overAlltaxRate}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.taxRateCountry
                                          ? data.taxRateCountry
                                          : "0"}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.taxRateState
                                          ? data.taxRateState
                                          : "-"}
                                      </td>
                                      <td className="align-middle p-2">
                                        {data.taxRateCity
                                          ? data.taxRateCity
                                          : "-"}
                                      </td>
                                      <td className="align-middle p-2">3</td>
                                      <td className="align-middle  p-2">
                                        <div className="form-check form-switch m-0">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            onChange={(e: any) => {
                                              handleDisplay(
                                                e,
                                                data.isDisplay,
                                                `flexSwitchCheckDefault_${data.isDisplay}`
                                              );
                                            }}
                                            id={`flexSwitchCheckDefault_${data.isDisplay}`}
                                          />
                                        </div>
                                      </td>

                                      <td className="align-middle  p-2">
                                        <div className="form-check form-switch m-0">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                  <td className="text-center p-4" colSpan={13}>
                                    No records found!
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {filterTaxRate.length > 0 && (
                    <ReactPaginate
                      previousLabel={"←"}
                      nextLabel={"→"}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TaxRate;
