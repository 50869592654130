import React from "react";
import * as routeConstant from '../../common/routeConstants'
import { Breadcrumb } from "../../common/interface";
import Sidebar from "../Sidebar/Sidebar";
import editIcon from "../../assets/edit_icon_tab.svg"
import deleteIcon from "../../assets/delet_icon_tab.svg"
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { GETALLVALUEDEFAULT, UPDATEVALUEDEFAULT } from "../../common/apiConstatnts";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import Select from 'react-select';
import jwt_decode from "jwt-decode";
import InnerHeader from "../InnerHeader/InnerHeader";
import { CommonMessages, ValueDefMessages, errorMessages } from "../../common/messageConstants";
import { ERROR_MESSAGES } from "../../Utils/Utils";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";

const ValueDefault = () => {
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstant.DASHBOARD },
        { label: 'Value Defaults', url: '' }
    ]

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const superAdminRole = decodedToken.isDefaultRole;
    const [loading, setLoading] = useState(false)
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [toggleModalRange, setToggleValueRange] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [bidIncrementData, setBidIncrementData] = useState([]);
    const [bidRangeData, setBidRangeData] = useState([]);
    const [activeBidId, setActiveBidId] = useState<any>();
    const [originalBidIncrementData, setOriginalBidIncrementData] = useState<any>([]);
    const [bidOriginalRangeData, setBidOriginalRangeData] = useState([]);
    const [activeUniqueId, setActiveUniqueId] = useState();
    const [lastLessThanValue, setLastLessThanValue] = useState<any>()
    const [isLast, setIsLast] = useState<boolean>(false)
    const [deleteDisable, setDeleteDisable] = useState<any>('')
    const [isBidValid, setIsBidValid] = useState<boolean>(false)
    const [parent, setParent] = useState<any>({})
    const [selectedParent, setSelectedParent] = useState<any>({})
    const decimalValForNxt = 0.01

    const initialBidData = {
        objectTypeId: 0,
        objectType: '',
        mode: mode,
        isActive: true
    }

    const intialRangeData = {
        objectValueId: "",
        objectTypeId: "",
        objectTypeValue: "",
        displayOrderNumber: '',
        companyId: localStorage.getItem('companyId'),
        parentId: 0,
        isActive: true,
        isDelete: false,
        uniqueId: uuidv4(),
        mode: "",
    }

    const [initialBidForm, setInitialBidForm] = useState<any>(initialBidData)
    const [initialRangeForm, setInitialRangeForm] = useState<any>(intialRangeData)

    const schema: any = yup.object().shape({
        objectType: yup.string().required("Object Type is required.").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true;
            return !/^\s/.test(value);
        }).max(20, "Please enter  upto 20 characters.")
    });

    const rangeSchema: any = yup.object().shape({
        objectTypeValue: yup.string().required("Object Value is required.").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true;
            return !/^\s/.test(value);
        }).max(100, "Please enter upto 100 characters."),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialBidForm, mode: 'onChange' })

    const {
        register: rangeRegister,
        handleSubmit: rangeHandleSubmit,
        reset: rangeReset,
        setValue,
        formState: { errors: rangeErrors, isValid: rangeIsValid, isDirty: rangeIsDirty },
    } = useForm({ resolver: yupResolver(rangeSchema), defaultValues: initialRangeForm, mode: 'onChange' })

    const getAllBidData = (fromSearch?: any) => {
        setLoading(true)
        axios.get(GETALLVALUEDEFAULT, config).then((response) => {
            setLoading(false)
            if (response?.data?.success === "Fail") {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const bidData = response.data.data
                const updatedBidData = bidData.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstObjectValue: data.lstObjectValue.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })

                const p = bidData.map((ud: any) => {
                    return ({
                        label: ud.objectType,
                        options: ud.lstObjectValue.map((x: any) => {
                            return { label: x.objectTypeValue, value: x.objectValueId, parentId: x.parentId }
                        })
                    }
                    )
                })
                resetSearchInput('keySearch')
                resetSearchInput('rangeSearch')
                setParent(p)
                setBidIncrementData(updatedBidData);
                setOriginalBidIncrementData(updatedBidData);
                if (!fromSearch) {
                    const firstIndexRange = updatedBidData[0]?.lstObjectValue ?? []
                    setActiveBidId(updatedBidData[0]?.objectTypeId);
                    setActiveUniqueId(updatedBidData[0]?.uniqueId);
                    setBidRangeData(firstIndexRange);
                    setBidOriginalRangeData(firstIndexRange)
                }
                else {
                    setBidRangeData(updatedBidData.find((i: any) => activeBidId == i.objectTypeId).lstObjectValue);
                    setBidOriginalRangeData(updatedBidData.find((i: any) => activeBidId == i.objectTypeId).lstObjectValue);
                    setActiveBidId(activeBidId);
                    setActiveUniqueId(updatedBidData.find((i: any) => activeBidId == i.objectTypeId).uniqueId)
                }
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        })

    }

    const handleClose = () => {
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    reset(initialBidData)
                    setToggleValue(!toggleModal)
                }
            })
            return
        }
        reset(initialBidData)
        setToggleValue(!toggleModal)
    }

    const handleCloseRange = () => {
        if (rangeIsDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    setToggleValueRange(!toggleModalRange)
                    rangeReset(intialRangeData)
                }
            })
            return
        }
        rangeReset(intialRangeData)
        setToggleValueRange(!toggleModalRange)
    }

    const getBidIncrementId = (uniqueID: number) => {
        const found: any = bidIncrementData?.find((obj: any) => {
            return obj.uniqueId === uniqueID;
        });
        const range = found?.lstObjectValue
        setActiveBidId(found?.objectTypeId)
        setActiveUniqueId(found?.uniqueId)
        // setStartingFrom(range)
        setBidRangeData(range?.filter((data: any) => data))
        setBidOriginalRangeData(range?.filter((data: any) => data))

    }
    const handleButtonClick = () => {
        if (superAdminRole === "True") {
            setToggleValue(!toggleModal);
            setMode('create');
        } else {
            toast.error("Only super admin can add the new key.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    };

    const handleEditModel = (e: any, uniqId: number) => {
        e.stopPropagation()
        if (superAdminRole === "True") {
            setMode('edit')
            setToggleValue(!toggleModal)
            const getData = bidIncrementData?.find((data: any) => data.uniqueId === uniqId)
            reset(getData)
        }
        else {
            toast.error('Only super admin can edit the key.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    }

    const handleButtonClickValue = () => {
        if (superAdminRole === "True") {
            setToggleValueRange(!toggleModalRange);
            setMode('create');
            setSelectedParent(null);
        } else {
            toast.error("Only super admin can add the new key value.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    };

    const handleEditValueModel = (e: any, uniqId: number) => {
        // e.stopPropagation()
        setMode('edit');
        setSelectedParent(null);
        if (superAdminRole === "True") {
            setToggleValueRange(!toggleModalRange)
            let parent: any = {};
            bidIncrementData.map((d: any) => {
                if (d.lstObjectValue?.find((data: any) => data.uniqueId === uniqId) != undefined) {
                    parent = d.lstObjectValue?.find((data: any) => data.uniqueId === uniqId)
                }
            })
            setValue('objectTypeValue', parent.objectTypeValue)
            setValue('displayOrderNumber', parent.displayOrderNumber)
            setValue('parentId', parent.parentId)
            setValue('objectValueId', parent.objectValueId)
            setValue('objectTypeId', parent.objectTypeId)
            setValue('uniqueId', parent.uniqueId)
            setSelectedParent({ label: parent.parentName, value: parent.parentId });
            // setParentId(parent.parentId)
        }
        else {
            toast.error('Only super admin can edit the key value.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    }
    //// needs changes in this for payload update,  when added new key modal
    const onSubmit = (data: any) => {
        setLoading(true);
        let updatedData: any;
        let newBid: any;
        if (data.objectTypeId === 0 && data.uniqueId === undefined) {
            setLoading(false);
            newBid = {
                ...initialBidData, ...data, lstObjectValue: [], mode: "add", uniqueId: uuidv4(), changed: true
            }
            updatedData = [...originalBidIncrementData, newBid]
        } else {
            setLoading(false);
            updatedData = originalBidIncrementData.map((bData: any) => {
                if (bData.uniqueId === data.uniqueId) {
                    return { ...bData, ...data, changed: true }
                }
                return bData
            })
        }
        setLoading(false);
        const checkDefault = updatedData.filter((data: any) => data.isDefault === true).length
        if (checkDefault === 0) {
            updatedData[0].isDefault = true
        }
        setLoading(false);
        resetSearchInput('keySearch')
        setBidIncrementData(updatedData)
        setOriginalBidIncrementData(updatedData)
        setActiveUniqueId(updatedData[0].uniqueId)
        setActiveBidId(updatedData[0].objectTypeId)
        setBidRangeData(updatedData[0].lstObjectValue)
        setToggleValue(!toggleModal)
        reset(initialBidData)
    }

    //  // needs changes in this for payload update ,submit added value range value
    const onSubmitRange = (formdata: any) => {
        const data = { ...formdata, displayOrderNumber: formdata.displayOrderNumber || null }
        const updatedBidData: any = bidIncrementData.map((bData: any) => {
            if (bData.uniqueId === activeUniqueId) {
                setLoading(false);
                if (bData.lstObjectValue?.length > 0) {
                    const updateMode = bData.lstObjectValue.map((item: any) => {
                        if (item.uniqueId === data.uniqueId) {
                            return ({
                                objectValueId: data.objectValueId,
                                objectTypeId: data.objectTypeId,
                                objectTypeValue: data.objectTypeValue,
                                displayOrderNumber: data.displayOrderNumber || null,
                                companyId: data.companyId,
                                parentId: parseInt(selectedParent?.value),
                                parentName: selectedParent?.label,
                                isActive: true,
                                isDelete: false,
                                uniqueId: data.uniqueId,
                                mode: 'update',
                                changed: true
                            })
                        }
                        return item

                        // else {
                        //     return ({
                        //         ...item,
                        //         mode: (item.objectValueId == "" || item.objectValueId == 0) ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
                        //     })
                        // }
                    })
                    const newBidData = { ...bData, lstObjectValue: updateMode, changed: true }
                    if (data.objectTypeId == '') {
                        return {
                            ...newBidData, lstObjectValue: [...newBidData.lstObjectValue, {
                                ...data, objectTypeId: activeBidId, mode: "add", uniqueId: uuidv4(), objectValueId: 0,
                                companyId :localStorage.getItem('companyId'), 
                                 changed: true, parentId: parseInt(selectedParent?.value), parentName: selectedParent?.label
                            }]
                        }
                    } else {
                        return newBidData
                    }
                } else {
                    const newBidData = { ...bData, changed: true, lstObjectValue: [{ ...data, objectTypeId: activeBidId, mode: "add", objectValueId: 0, uniqueId: uuidv4(), companyId: localStorage.getItem('companyId'), changed: true, parentId: parseInt(selectedParent?.value), parentName: selectedParent?.label }] }
                    return newBidData
                }
            }
            // const updateMode = bData.lstObjectValue.map((item: any) => (
            //     {
            //         ...item,
            //         mode: (item.objectValueId === 0) ? 'add' : (item.mode === 'delete') ? 'delete' : 'update'
            //     }
            // ))
            // return { ...bData, lstObjectValue: updateMode }
            return bData
        })
        const updateBidRange: any = updatedBidData.find((data: any) => data.uniqueId === activeUniqueId)?.lstObjectValue
        resetSearchInput('rangeSearch')
        setBidRangeData(updateBidRange)
        setBidOriginalRangeData(updateBidRange)
        setBidIncrementData(updatedBidData)
        setOriginalBidIncrementData(updatedBidData)
        setLastLessThanValue(data.toLessThan + decimalValForNxt)
        rangeReset(intialRangeData)
        setToggleValueRange(!toggleModalRange)
    }

    // // needs changes in this for payload update , this is main save function called
    const handleSave = () => {
        if (superAdminRole === "True") {
            let filterDummy = bidIncrementData.map((data: any) => {
                if (data.objectTypeId === 0 && data.mode === 'delete') {
                    return {}
                }
                return data
            }).filter((bData: any) => Object.keys(bData).length > 0 && bData?.changed)

            //filter for set payload only changed data in lstObjectValue.
            filterDummy = filterDummy.map((obj: any) => {
                return {
                    ...obj,
                    lstObjectValue: obj?.lstObjectValue.filter((obj: any) => obj.changed)
                }
            })
            setLoading(true);
            axios.post(UPDATEVALUEDEFAULT, filterDummy, config).then((response) => {
                setLoading(false);
                getAllBidData()
                toast.success(ValueDefMessages.valueDefUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }).catch((error) => {
                setLoading(false);
                handleError(error)
            })
        }
        else {
            setLoading(false);
            toast.error("Only super admin has permission to perform this operation on the page.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    }
    // needs changes in this for payload update
    const handleRangeDelete = (bidUniqueId: any, rangeUniqueId: string) => {
        if (superAdminRole === "True") {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const modifyBid: any = bidIncrementData.map((data: any) => {
                        let cData = data;
                        if (data.uniqueId === bidUniqueId) {
                            cData = { ...cData, changed: true }
                        }
                        const modifyRange = cData.lstObjectValue.map((rg: any) => {
                            if (rg.uniqueId === rangeUniqueId) {
                                return { ...rg, mode: "delete", isDelete: true, isActive: false, changed: true }
                            }
                            return rg
                        })
                        return { ...cData, lstObjectValue: modifyRange }
                    })

                    const getBid = modifyBid?.find((data: any) => data.uniqueId === bidUniqueId)?.lstObjectValue
                    let getLastLessThan = getBid.filter((data: any) => data.mode !== "delete")
                    setLastLessThanValue(getLastLessThan[getLastLessThan?.length - 1]?.toLessThan + decimalValForNxt)
                    setBidRangeData(getLastLessThan)
                    setBidOriginalRangeData(getLastLessThan)
                    resetSearchInput('rangeSearch')
                    setBidIncrementData(modifyBid)
                }
            })
            return
        }
        else {
            toast.error('Only super admin can delete the key value.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })

        }
    }
    //changes for payload update.
    const handleBidDelete = (e: any, bidUniqueId: any) => {
        if (superAdminRole === "True") {
            e.stopPropagation()
            const checkDefault: any = bidIncrementData?.find((data: any) => data.uniqueId === bidUniqueId)
            if (checkDefault.isDefault) {
                setDeleteDisable(bidUniqueId)
                toast.error(errorMessages.defaultRecord, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
                setTimeout(() => {
                    setDeleteDisable('')
                }, 3000)
                return
            }
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to delete this record ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    const updateBid: any = originalBidIncrementData.map((data: any) => {
                        if (bidUniqueId === data.uniqueId) {
                            return { ...data, mode: 'delete', changed: true }
                        }
                        return data
                    })
                    const getActive = updateBid.filter((data: any) => data.mode !== 'delete')
                    const firstIndexRange = getActive[0]?.lstBidIncrementRange ?? []
                    setBidIncrementData(updateBid)
                    setOriginalBidIncrementData(updateBid)
                    resetSearchInput('keySearch')
                    setActiveBidId(getActive[0]?.objectTypeId)
                    setBidRangeData(getActive[0]?.lstObjectValue)
                    setActiveUniqueId(getActive[0]?.uniqueId)
                }
            })
            return
        }
        else {
            toast.error('Only super admin can delete the key.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            })
        }
    }

    const handleSearchObjects = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase()
        if (keyword === '') {
            // getAllBidData()
            setBidIncrementData(originalBidIncrementData)
            setActiveUniqueId(originalBidIncrementData[0]?.uniqueId)
            setActiveBidId(originalBidIncrementData[0]?.objectTypeId)
            setBidRangeData(originalBidIncrementData[0]?.lstObjectValue)
            setBidOriginalRangeData(originalBidIncrementData[0]?.lstObjectValue)
            return
        }
        const filterData: any = originalBidIncrementData?.filter((x: any) => x.objectType?.toLocaleLowerCase()?.includes(keyword))
        setBidIncrementData(filterData)
        setActiveUniqueId(filterData[0]?.uniqueId)
        setActiveBidId(filterData[0]?.objectTypeId)
        setBidRangeData(filterData[0]?.lstObjectValue)
        setBidOriginalRangeData(filterData[0]?.lstObjectValue)
    }

    const handleSearchObjectValues = (e: any) => {
        const keyword = e.target.value?.toLocaleLowerCase();
        if (keyword === '') {
            // getAllBidData('fromSearch');
            setBidRangeData(bidOriginalRangeData)

            return;
        }
        const filterData: any = bidOriginalRangeData?.filter((x: any) => x.objectTypeValue?.toLocaleLowerCase()?.includes(keyword))
        setBidRangeData(filterData);
    }

    const resetSearchInput = (id: any) => {
        const keySearchBar: any = document.getElementById(id);
        keySearchBar.value = ''
    }

    useEffect(() => {
        getAllBidData();
    }, [])

    useEffect(() => {
        const checkLast = bidRangeData?.filter((data: any) => data.mode !== 'delete')
        const getBool = checkLast?.length === 1 ? true : false
        setIsLast(getBool)
    }, [bidRangeData])

    useEffect(() => {
        const filterDummy = bidIncrementData.filter((data: any) => data)
        const checkRange = filterDummy.every((data: any) => data.lstObjectValue.length > 0)
        setIsBidValid(checkRange)
    }, [bidIncrementData])

    return (
        <>
            <CommonHelmet title={"Value Default - "} name={"valueDefault"} content={"Value Default"} />
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}

            {/* bid modal starts */}
            <Modal show={toggleModal} size='lg' centered>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add Key' : 'Edit Key'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Object Type <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Key Name"
                                    {...register('objectType')}
                                    maxLength={21}
                                />
                                {errors.objectType && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.objectType.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit">
                            {(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* bid modal ends */}

            {/* range modal starts */}
            <Modal show={toggleModalRange} size='lg' centered>
                <form onSubmit={rangeHandleSubmit(onSubmitRange)}>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'Add New Key Value' : 'Edit Key Values'} </h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseRange}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Parent </label>
                                <Select classNamePrefix="dpaSelect"
                                    name='cloneList'
                                    placeholder="Select Parent"
                                    className='role'
                                    options={parent}
                                    value={selectedParent}
                                    onChange={(selectedOption: any) => {
                                        setSelectedParent(selectedOption);
                                    }}
                                />
                                {rangeErrors.toLessThan && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.toLessThan.message}</span>}
                                <span style={{ fontSize: "12px" }}><i>**Note: Parent is connection of value object in hierarchy.</i></span>
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Object Values <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Key Value Name"
                                    {...rangeRegister('objectTypeValue')}
                                    maxLength={101}
                                />
                                {rangeErrors.objectTypeValue && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.objectTypeValue.message}</span>}
                            </div>
                            <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Add display Order</label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Add display Order"
                                    {...rangeRegister('displayOrderNumber')}
                                    pattern="[0-9]*"
                                    maxLength={5}
                                    onInput={(e: any) => {
                                        e.target.value = e.target.value.replace(/\D/g, '').slice(0, 5);
                                    }}
                                />

                                {rangeErrors.displayOrderNumber && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.displayOrderNumber.message}</span>}
                            </div>
                            {/* <div className="col-md-12">
                                <label className="form-label" htmlFor="text">Action <sup className="text-danger">*</sup></label>
                                <input
                                    className="form-control form-icon-input"
                                    type="text"
                                    placeholder="Increment"
                                    {...rangeRegister('increment')}
                                />
                                {rangeErrors.increment && <span className='text-danger' style={{ fontSize: "12px" }}>{rangeErrors.increment.message}</span>}
                            </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleCloseRange}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" type="submit"  >{CommonMessages.saveButton}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* range modal ends */}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Value Defaults'>
                </InnerHeader >
                <div className="container-fluid for-fix-bottom-space">
                    <div className="row top_border">
                        <div className="col-12">
                            <div className="mb-5 border-300">
                                <div className="mb-4 mt-4">
                                    <div className="row g-3">
                                        <div className="col-auto flex-grow-1">
                                            <span className='mt-2 mb-0' style={{ fontSize: "12px" }}><i><span className="text-danger">**</span>Note : Only super admin has permission to perform this operation on the page..</i></span>
                                        </div>
                                    </div>

                                    {/* <div className="row g-3">
                                        <div className="col-auto flex-grow-1">
                                            <p className="mb-0 fs--1">Create Bid Increment Tables to be used for 1) Absentee Bids, and 2) Auto-enter a bid increment when a entering or importing lots into inventory based on the starting bid. To select a table for either of these, see the relevant tab in Auctions, Absentee Bids or Checklist.<button type="button" className="btn text-primary p-0 ms-1" onClick={() => setReadMore(!readMore)}>{readMore ? 'Read Less' : 'Read More'}</button></p>
                                        </div>
                                    </div>
                                    {readMore && <div className="mb-4">
                                        <div className="row">
                                            <div className="col-lg-6 mt-3">
                                                <h5 className="mb-2">Increment Tables</h5>
                                                <p className="mb-0">Select a Table by Clicking the green triangle and then edit or add increments in the column on the right.</p>
                                            </div>
                                            <div className="col-lg-6 mt-3">
                                                <h5 className="mb-2">Increments</h5>
                                                <p className="mb-0">Enter the Starting From and Up To cutoffs and the increment amount. Do not enter a "To" value for the last one.</p>
                                                <small className="mb-0 fw-bold"> NOTE : The From value should be exactly the same as the previous "To" value, e.g. 0 to 100, 100 to 500, 500 to 1000. Do not enter 99,99.99 or similar this is handled. </small>
                                            </div>
                                        </div>
                                    </div>} */}
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 add_with_search">
                                                <div className="search-box mt-0 me-3">
                                                    <input id={'keySearch'} type="text" className="form-control search-input search form-control-sm p-2 ps-6" placeholder="Search Key" onChange={(e: any) => handleSearchObjects(e)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                                    onClick={handleButtonClick}
                                                    // disabled={bidIncrementData?.length == 0}
                                                >
                                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Key </span>
                                                </button>
                                            </div>
                                            <div id="tableExample4">
                                                <div className="table-responsive scrollbar border">
                                                    <table className="table border-radius fs--1 table-sm mb-0">
                                                        <thead className="sticky-top">
                                                            <tr className="bg-gradiant">
                                                                <th className="border-bottom p-2">
                                                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                                        <div className="d-flex pe-3 flex-wrap align-items-center" style={{ width: "20%" }}>Sr. No.</div>
                                                                        <div className="d-flex pe-3 flex-wrap align-items-center me-auto" style={{ width: "20%" }}>Key</div>
                                                                        {/* <div className="me-auto align-middle tax pe-3" style={{ width: "40%" }}>Description</div> */}
                                                                        {/* <div className="me-auto align-middle tax pe-3" style={{ width: "30%" }}>Bid Increment For</div> */}
                                                                        <div className="align-middle payment text-end text-nowrap" style={{ width: "15%" }}>Action</div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list scroll">
                                                            {bidIncrementData?.length > 0 && bidIncrementData.filter((data: any) => data && data?.mode != 'delete')?.length > 0 ? <tr>
                                                                <td className="align-middle ps-2 pe-2 pt-2 pb-2">
                                                                    {bidIncrementData?.filter((data: any) => data && data?.mode != 'delete').map((bid: any, index: any) => {
                                                                        return (
                                                                            <>
                                                                                {/* {(bid.mode !== "delete") && */}
                                                                                <div className={`d-flex flex-wrap border_ac ${(activeUniqueId === bid.uniqueId) ? 'active' : ''}`}
                                                                                    // onClick={() => getBidIncrementId(bid.objectTypeId)}
                                                                                    onClick={() => getBidIncrementId(bid.uniqueId)}
                                                                                    key={index}
                                                                                >
                                                                                    <div className="d-flex w-100 flex-wrap align-items-center justify-content-between position-relative">
                                                                                        <div style={{ width: "20%" }} className="align-middle">
                                                                                            {index + 1}
                                                                                        </div>
                                                                                        <div style={{ width: "50%" }} className="d-flex pe-3 flex-wrap align-items-center me-auto" >
                                                                                            <span>{bid.objectType}</span>
                                                                                        </div>
                                                                                        {/* <div style={{ width: "45%" }} className="me-auto align-middle tax pe-3">
                                                                                                <span className="ellipsis">{bid.description}</span>
                                                                                            </div>
                                                                                            <div style={{ width: "20%" }} className="me-auto align-middle tax pe-3">
                                                                                                <span>{bidIncFor?.find((data: any) => data.value == bid?.bidIncrementFor)?.label}</span>
                                                                                            </div> */}
                                                                                        <div style={{ width: "10%" }} className="align-middle payment text-end">
                                                                                            <div className="action_btn d-flex align-items-center justify-content-end">
                                                                                                <button type="button" className="action_icons d-flex align-items-center text-decoration-none btn p-0"
                                                                                                    onClick={(e) => handleEditModel(e, bid.uniqueId)}
                                                                                                > <img src={editIcon} /> </button>
                                                                                                <button
                                                                                                    onClick={(e) => handleBidDelete(e, bid.uniqueId)}
                                                                                                    type="button"
                                                                                                    className="action_icons d-flex align-items-center text-decoration-none ms-2 btn p-0"
                                                                                                    disabled={deleteDisable === bid.objectTypeId}>
                                                                                                    <img src={deleteIcon} />
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="form-check w-100 ms-auto mt-1"> */}
                                                                                    {/* <input className="form-check-input" id={`Default${index}`} type="checkbox" onChange={(e) => handleDefault(e, bid.uniqueId)} defaultChecked={bid.isDefault === true} /> */}
                                                                                    {/* <label className={`form-check-label ${(bid.isDefault) ? 'text-danger' : ''}`} htmlFor={`Default${index}`}>{bid.isDefault ? 'Default' : 'Set as Default'}</label> */}
                                                                                    {/* </div> */}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </td >
                                                            </tr > : <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                <td className='text-center p-4'>No Bid found!</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 add_with_search">
                                                <div className="search-box mt-0 me-3">
                                                    <input type="text" id="rangeSearch" className="form-control search-input search form-control-sm p-2 ps-6" disabled={bidIncrementData?.length == 0} placeholder="Search Key Values" onChange={(e: any) => handleSearchObjectValues(e)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                                <button className="btn btn-outline-secondary Add_New_Bid mt-1 mb-1 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#add_new_range"
                                                    onClick={handleButtonClickValue}
                                                    disabled={bidIncrementData?.length == 0}
                                                >
                                                    <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add New Key Value </span>
                                                </button>

                                            </div>
                                            <div id="tableExample2" className="border" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                                                <div className="table-responsive scrollbar">
                                                    <table className="table table-sm mb-0 fs--1">
                                                        <thead className="sticky-top">
                                                            <tr>
                                                                <th className="sort bg-gradiant border-0 p-0" style={{ width: "70px" }}> <div className="p-2 border-top border-bottom min-height-50">Sr. No.</div></th>
                                                                <th className="sort bg-gradiant border-0 p-0"><div className="p-2 border-top border-bottom min-height-50">Key Value</div></th>
                                                                <th className="sort bg-gradiant border-0 p-0"><div className="p-2 border-top border-bottom min-height-50">Parent</div></th>
                                                                <th className="sort bg-gradiant border-0 p-0"><div className="p-2 border-top border-bottom min-height-50">Display Order</div></th>
                                                                {superAdminRole === "True" && (
                                                                    <th className="sort bg-gradiant border-0 p-0" style={{ width: "80px" }} scope="col"><div className="p-2 border-top border-bottom min-height-50">Action</div></th>
                                                                )}
                                                            </tr>
                                                        </thead>

                                                        <tbody className="list scroll">
                                                            {bidRangeData?.length > 0 ? bidRangeData?.filter((data: any) => data && data?.mode != 'delete').map((rangData: any, index: number) => {
                                                                return (
                                                                    rangData.mode !== "delete" && <tr key={index}>
                                                                        <td className="align-middle ps-2" style={{ width: "70px" }}>{index + 1}</td>
                                                                        <td className="align-middle p-2 name">{rangData.objectTypeValue}</td>
                                                                        <td className="align-middle p-2 upto">{rangData.parentName}</td>
                                                                        <td className="align-middle p-2 upto">{rangData.displayOrderNumber}</td>
                                                                        <td className="align-middle p-2 delete" style={{ width: "80px" }}>
                                                                            <div className="d-flex aling-items-center justify-content-end">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn d-flex align-items-center p-0 align-items-center"
                                                                                    onClick={() => handleEditValueModel(activeUniqueId, rangData.uniqueId)}
                                                                                ><img src={editIcon} height={12} /></button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn ms-2 d-flex align-items-center p-0 align-items-center"
                                                                                    onClick={() => handleRangeDelete(activeUniqueId, rangData.uniqueId)}
                                                                                ><img src={deleteIcon} height={12} /></button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={6}>No Object Values found!</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <Link
                            to={routeConstant.DASHBOARD}
                            className="btn btn-outline-danger me-auto"
                            type="button"
                            data-wizard-prev-btn="data-wizard-prev-btn"
                        >
                            {CommonMessages.cancelButton}
                        </Link>
                        <button className="btn btn-primary mx-2" onClick={handleSave} disabled={bidIncrementData?.length == 0} >{CommonMessages.saveButton}</button>
                    </div>
                </div>
            </div >
        </>
    )
}
export default ValueDefault;