import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ERROR_MESSAGES, trimObjectValues } from "../../../../Utils/Utils";
import {
  GETLOTREALESTATETAXESBYID,
  UPDATELOTREALESTATETAXES
} from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import {
  AlPHANUMERIC,
  DECIMAL,
  DECIMAL93,
  DECIMAL93_MESSAGE,
  INTEGER_MESSAGE,
} from "../../../../common/regexConstants";
import {
  setCloneTab,
  setCreateTab,
  setInventoryInfoActive
} from "../../../../store/actions/Inventory";
import * as routeConstant from "../../../././../common/routeConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { LOTTAXES } from "../../InventoryConstants";

const Taxes = () => {
  const dispatch = useDispatch();
  const lotId = useSelector((state: any) => state.inventory?.lotId);
  const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
  const mode = useSelector((state: any) => state.inventory.mode)
  const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
  const createTabs = useSelector((state: any) => state.inventory.createTabs)

  const initialData = {
    companyId: localStorage.getItem("companyId"),
    isActive: true,
    isDelete: false,
    lotRealEstateTaxesId: 0,
    lotId: mode === "clone" ? cloneLotId : lotId,
    taxMap1: "",
    taxMap2: "",
    taxMap3: "",
    taxMap4: "",
    municipality: "",
    taxDeling: "",
    schoolTax: "",
    villageTax: "",
    tcTax: "",
    taxAmountDueAsListFilling: "",
    rp5217FillingFee: "",
    tp584FillingFee: "",
    countryClerkFee: "",
    redeedPrepFee: "",
    reAddEndumdFee: "",
    reFeeAdd1: "",
    reFeeAdd2: "",
    reFeeAdd3: "",
    terms: "",
    assessments: "",
    eassements: "",
    annualIncome: "",
    tenant: "",
    improvements: "",
  };

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [initialFormValues, setInitialFormValues] = useState();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnTxt] = useState<any>("")

  const schema: any = yup.object().shape({
    taxMap1: yup
      .string()
      // .required("Tax Map 1 is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    taxMap2: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      // .matches(DECIMAL, INTEGER_MESSAGE)
      .matches(DECIMAL93, DECIMAL93_MESSAGE),
    taxMap3: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      // .matches(DECIMAL, INTEGER_MESSAGE)
      .matches(DECIMAL93, DECIMAL93_MESSAGE),
    taxMap4: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      })
      // .matches(DECIMAL, INTEGER_MESSAGE)
      .matches(DECIMAL93, DECIMAL93_MESSAGE),
    villageTax: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    tcTax: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    tp584FillingFee: yup
      .string()
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    municipality: yup
      .string()
      // .required("Municipality is required.")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    taxAmountDueAsListFilling: yup
      .string()
      // .required("Tax Amount Due is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    rp5217FillingFee: yup
      .string()
      // .required("rp5217 Filling Fee is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    taxDeling: yup
      .string()
      // .required("Tax Deling is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    schoolTax: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    countryClerkFee: yup
      .string()
      // .required("Country Clerk Fee is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    redeedPrepFee: yup
      .string()
      // .required("RE deed Prep Fee is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    reAddEndumdFee: yup
      .string()
      // .required("RE add Fee is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    reFeeAdd1: yup
      .string()
      // .required("RE FeeAdd 1 is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    reFeeAdd2: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    reFeeAdd3: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? /^\S*$/g.test(value) : true;
    }).matches(DECIMAL93, DECIMAL93_MESSAGE),
    terms: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required(" Terms is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(AlPHANUMERIC, "Please enter valid Term")
      .max(99, "Please enter upto 100 characters."),
    assessments: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required("Assessments is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(AlPHANUMERIC, "Please enter valid Assessment")
      .max(99, "Please enter upto 100 characters."),
    eassements: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required(" Eassements is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(AlPHANUMERIC, "Please enter valid E-assessment")
      .max(49, "Please enter upto 50 characters."),
    annualIncome: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required(" Annual Income  is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(DECIMAL, INTEGER_MESSAGE)
      .max(9, "Please enter upto 9 digit."),
    tenant: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required(" Tenant is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(AlPHANUMERIC, "Please enter valid Tenant")
      .max(49, "Please enter upto 50 characters."),
    improvements: yup
      .string()
      .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
        if (!value) return true;
        return !/^\s/.test(value);
      })
      /* .required(" Improvements is required.")
      .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
        return value ? /^\S*$/g.test(value) : true;
      }) */
      .matches(AlPHANUMERIC, "Please enter valid Improvements")
      .max(49, "Please enter upto 50 characters."),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  const onSubmit = () => {
    setLoading(true);
    const dataOriginal = getValues();
    const taxData = trimObjectValues(dataOriginal);

    const payload = {
      ...taxData,
      lotId: mode === "clone" ? cloneLotId : lotId,
      taxMap1: taxData.taxMap1 ? parseFloat(taxData.taxMap1) : 0,
      taxMap2: taxData.taxMap2 ? parseFloat(taxData.taxMap2) : 0,
      taxMap3: taxData.taxMap3 ? parseFloat(taxData.taxMap3) : 0,
      taxMap4: taxData.taxMap4 ? parseFloat(taxData.taxMap4) : 0,
      villageTax: taxData.villageTax ? parseFloat(taxData.villageTax) : 0,
      schoolTax: taxData.schoolTax ? parseFloat(taxData.schoolTax) : 0,
      tcTax: taxData.tcTax ? parseFloat(taxData.tcTax) : 0,
      tp584FillingFee: taxData.tp584FillingFee ? parseFloat(taxData.tp584FillingFee) : 0,
      municipality: taxData.municipality ? parseFloat(taxData.municipality) : 0,
      taxAmountDueAsListFilling: taxData.taxAmountDueAsListFilling ? parseFloat(taxData.taxAmountDueAsListFilling) : 0,
      rp5217FillingFee: taxData.rp5217FillingFee ? parseFloat(taxData.rp5217FillingFee) : 0,
      taxDeling: taxData.taxDeling ? parseFloat(taxData.taxDeling) : 0,
      countryClerkFee: taxData.countryClerkFee ? parseFloat(taxData.countryClerkFee) : 0,
      redeedPrepFee: taxData.redeedPrepFee ? parseFloat(taxData.redeedPrepFee) : 0,
      reAddEndumdFee: taxData.reAddEndumdFee ? parseFloat(taxData.reAddEndumdFee) : 0,
      reFeeAdd1: taxData.reFeeAdd1 ? parseFloat(taxData.reFeeAdd1) : 0,
      reFeeAdd2: taxData.reFeeAdd2 ? parseFloat(taxData.reFeeAdd2) : 0,
      reFeeAdd3: taxData.reFeeAdd3 ? parseFloat(taxData.reFeeAdd3) : 0,
    };

    axios.post(UPDATELOTREALESTATETAXES, payload, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, LOTTAXES]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, LOTTAXES]))
        }
        nextStep();
        toast.success(InventoryMessages.taxesUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error);
    });
  };

  useEffect(() => {
    setLoading(true);
    const id = mode === "clone" ? cloneLotId : lotId
    axios.get(`${GETLOTREALESTATETAXESBYID}?lotId=${id}`, { ...config }).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        return;
      } else {
        let data = response.data.data;
        data = {
          ...data,
          taxMap1: data.taxMap1 > 0 ? parseFloat(data.taxMap1) : "",
          taxMap2: data.taxMap2 > 0 ? parseFloat(data.taxMap2) : "",
          taxMap3: data.taxMap3 > 0 ? parseFloat(data.taxMap3) : "",
          taxMap4: data.taxMap4 > 0 ? parseFloat(data.taxMap4) : "",
          villageTax: data.villageTax > 0 ? parseFloat(data.villageTax) : "",
          schoolTax: data.schoolTax > 0 ? parseFloat(data.schoolTax) : "",
          tcTax: data.tcTax > 0 ? parseFloat(data.tcTax) : "",
          tp584FillingFee: data.tp584FillingFee > 0 ? parseFloat(data.tp584FillingFee) : "",
          municipality: data.municipality > 0 ? parseFloat(data.municipality) : "",
          taxAmountDueAsListFilling: data.taxAmountDueAsListFilling > 0 ? parseFloat(data.taxAmountDueAsListFilling) : "",
          rp5217FillingFee: data.rp5217FillingFee > 0 ? parseFloat(data.rp5217FillingFee) : "",
          taxDeling: data.taxDeling > 0 ? parseFloat(data.taxDeling) : "",
          countryClerkFee: data.countryClerkFee > 0 ? parseFloat(data.countryClerkFee) : "",
          redeedPrepFee: data.redeedPrepFee > 0 ? parseFloat(data.redeedPrepFee) : "",
          reAddEndumdFee: data.reAddEndumdFee > 0 ? parseFloat(data.reAddEndumdFee) : "",
          reFeeAdd1: data.reFeeAdd1 > 0 ? parseFloat(data.reFeeAdd1) : "",
          reFeeAdd2: data.reFeeAdd2 > 0 ? parseFloat(data.reFeeAdd2) : "",
          reFeeAdd3: data.reFeeAdd3 > 0 ? parseFloat(data.reFeeAdd3) : "",
        }
        reset(data)
      }
    }).catch((error) => {
      setLoading(false);
    });
  }, []);

  // to render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (lotId > 0 && createTabs.includes(LOTTAXES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneLotId > 0 && clonedTabs.includes(LOTTAXES)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  const prevStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "agents" }));
  };

  const nextStep = () => {
    dispatch(setInventoryInfoActive({ activeTab: "propertyDetails" }));
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading && (
            <div className="loader-wrap">
              <img src={`${localStorage.getItem("globalLoader")}`} />
            </div>
          )}
          <div className="row g-3 mb-3">
            <div className="col-lg-3">
              <label className="form-label">
                Tax Map 1
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Map 1"
                {...register("taxMap1")}
                maxLength={13}
              />
              {errors.taxMap1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxMap1.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 2</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Map 2"
                {...register("taxMap2")}
                maxLength={13}
              />
              {errors.taxMap2 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxMap2.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 3</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Map 2"
                {...register("taxMap3")}
                maxLength={13}
              />
              {errors.taxMap3 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxMap3.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Tax Map 4</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Map 4"
                {...register("taxMap4")}
                maxLength={13}
              />
              {errors.taxMap4 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxMap4.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">
                Tax Deling
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax Deling"
                {...register("taxDeling")}
                maxLength={13}
              />
              {errors.taxDeling && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxDeling.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">School Tax</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="School Tax"
                {...register("schoolTax")}
                maxLength={13}
              />
              {errors.schoolTax && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.schoolTax.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">Village Tax</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Village Tax"
                {...register("villageTax")}
                maxLength={13}
              />
              {errors.villageTax && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.villageTax.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">T/C Tax</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="T/C tax"
                {...register("tcTax")}
                maxLength={13}
              />
              {errors.tcTax && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.tcTax.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">
                Tax amount due at List Filling{" "}

              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tax amount due at List Filling "
                {...register("taxAmountDueAsListFilling")}
                maxLength={13}
              />
              {errors.taxAmountDueAsListFilling && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.taxAmountDueAsListFilling.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">
                RP5217 Filling Fee
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RP5217 Filling Fee"
                {...register("rp5217FillingFee")}
                maxLength={13}
              />
              {errors.rp5217FillingFee && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.rp5217FillingFee.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">TP584 Filling Fee</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="TP584 Fillin Fee"
                {...register("tp584FillingFee")}
                maxLength={13}
              />
              {errors.tp584FillingFee && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.tp584FillingFee.message}
                </span>
              )}
            </div>
            <div className="col-lg-3">
              <label className="form-label">
                Country Clerk Fee
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Country Clerk Fee"
                {...register("countryClerkFee")}
                maxLength={13}
              />
              {errors.countryClerkFee && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.countryClerkFee.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Municipality
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Municipality"
                {...register("municipality")}
                maxLength={13}
              />
              {errors.municipality && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.municipality.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                RE DeedPrepFee
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RE DeeedPrepFee"
                {...register("redeedPrepFee")}
                maxLength={13}
              />
              {errors.redeedPrepFee && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.redeedPrepFee.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                RE AddendumDFee
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RE AddendumDFee"
                {...register("reAddEndumdFee")}
                maxLength={13}
              />
              {errors.reAddEndumdFee && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.reAddEndumdFee.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                RE FeeAdd 1
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RE FeeAdd 1"
                {...register("reFeeAdd1")}
                maxLength={13}
              />
              {errors.reFeeAdd1 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.reFeeAdd1.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">RE FeeAdd 2</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RE FeeAdd 2"
                {...register("reFeeAdd2")}
                maxLength={13}
              />
              {errors.reFeeAdd2 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.reFeeAdd2.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">RE FeeAdd 3</label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="RE FeeAdd 3"
                {...register("reFeeAdd3")}
                maxLength={13}
              />
              {errors.reFeeAdd3 && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.reFeeAdd3.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Terms
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Terms"
                {...register("terms")}
                maxLength={100}
              />
              {errors.terms && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.terms.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Assessments
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Assessments"
                {...register("assessments")}
                maxLength={100}
              />
              {errors.assessments && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.assessments.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Eassements
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Eassements"
                {...register("eassements")}
                maxLength={50}
              />
              {errors.eassements && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.eassements.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Annual Income
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Annual Income"
                {...register("annualIncome")}
                maxLength={10}
              />
              {errors.annualIncome && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.annualIncome.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Tenant
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Tenant"
                {...register("tenant")}
                maxLength={50}
              />
              {errors.tenant && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.tenant.message}
                </span>
              )}
            </div>
            <div className="col-lg-4">
              <label className="form-label">
                Improvement
              </label>
              <input
                className="form-control form-icon-input"
                type="text"
                placeholder="Improvement"
                {...register("improvements")}
                maxLength={50}
              />
              {errors.improvements && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {errors.improvements.message}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
              <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
              <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger me-auto">{CommonMessages.cancelButton}</Link>
              <button className="btn btn-primary ms-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
              <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Taxes;
