import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { GETCHECKLISTBYAUCTIONID, GETSILENTBYLOTID, UPDATELOTSILENT } from "../../../../common/apiConstatnts";
import { CommonMessages, InventoryMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setCloneTab, setCreateTab, setInventoryInfoActive, setStep } from "../../../../store/actions/Inventory";
import DateTime from "../../../../Utils/DateTime";
import MomentUtil from "../../../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../../../Utils/useDateTime";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { LOTSILENT } from "../../InventoryConstants";
import TimeSelector from "../../../../Utils/MinSec";

const Silent = () => {
    const dispatch = useDispatch();
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const lotId = useSelector((state: any) => state.inventory.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const mode = useSelector((state: any) => state.inventory.mode)
    const auctionData = useSelector((state: any) => state.inventory.auctionData);
    const clonedTabs = useSelector((state: any) => state?.inventory.clonedTabs);
    const createTabs = useSelector((state: any) => state.inventory.createTabs)
    const dateTime = useCurrentDateTime()

    const initialData = {
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        isDelete: false,
        lotSilentId: 0,
        lotId: mode === "clone" ? cloneLotId : lotId,
        startDateTime: '',
        endDateTime: '',
        dynamicExtensionMin: "0",
        dynamicExtensionSec: '0',
        defaultExtensionMin: '0',
        defaultExtensionSec: '0',
        isSilentApplied: false,
    }

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [dateError, setDateError] = useState('');
    const [newStartDate, setNewStartDate] = useState<any>('');
    const [newEndDate, setNewEndDate] = useState<any>('');
    const [silentDataFromApi, setSilentDataFromApi] = useState<any>()
    const [btnText, setBtnTxt] = useState<any>("")

    const numbers = Array.from({ length: 60 }, (_, index) => index + 1);

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const schema: any = yup.object().shape({
        startDateTime: yup.string()
            .required('Start Date Time is required')
            .test('valid-datetime', 'Invalid datetime', function (value) {
                /* const parsedDate: any = new Date(value);
                // const currentDate: any = UTCdateConverter(new Date())
                const currentDate: any = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });
                if (parsedDate.getFullYear() < 2000 ||
                    parsedDate.getFullYear() > 2500 || !lotId && parsedDate - currentDate < 0) {
                    return this.createError({
                        message: 'Please Enter valid Date.',
                    });
                }
                return !isNaN(parsedDate); */
                const parsedDate = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ" });
                const currentDate = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });

                if (
                    parsedDate.year() < 2000 ||
                    parsedDate.year() > 2500 ||
                    (!lotId && parsedDate.diff(currentDate, 'minutes') < 0)
                ) {
                    return this.createError({
                        message: 'Please enter a valid date that is greater than the current UTC date.',
                    });
                }

                // Calculate the difference in minutes between parsed date and current date
                const timeDifferenceInMinutes = parsedDate.diff(currentDate, 'minutes');

                if (timeDifferenceInMinutes < 0) {
                    return this.createError({
                        message: 'The start time must be in the future.',
                    });
                }

                return true;
            }),
        endDateTime: yup.string()
            .required('End Date Time is required')
            .test('valid-datetime', 'Invalid datetime', function (value) {
                const parsedDate: any = new Date(value);
                // const currentDate: any = UTCdateConverter(new Date())
                const currentDate: any = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });
                if (parsedDate.getFullYear() < 2000 ||
                    parsedDate.getFullYear() > 2500 || !lotId && parsedDate - currentDate < 0) {
                    return this.createError({
                        message: 'Please Enter valid Date.',
                    });
                }
                return !isNaN(parsedDate);
            }),
        /* dynamicExtensionSec: yup.string().required("Dynamic Extension is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test("time-req", "Please ensure that the minimum dynamic extension is 15 seconds.", function (value) {
                const min = parseInt(this.parent.dynamicExtensionMin, 10);
                if (parseInt(value, 10) < 15 && min === 0) {
                    return this.createError({
                        message: 'Please ensure that the minimum dynamic extension is 15 seconds.',
                    });
                }
                return true;
            }),

        dynamicExtensionMin: yup.string().required("Dynamic Extension is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test("time-req", "Please ensure that the minimum dynamic extension is 15 seconds.", function (value) {
                const sec = parseInt(this.parent.dynamicExtensionSec, 10);
                if (parseInt(value, 10) === 0 && sec < 15) {
                    return this.createError({
                        message: 'Please ensure that the minimum dynamic extension is 15 seconds.',
                    });
                }
                return true;
            }),
        defaultExtensionSec: yup.string().required("Default Extension is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .test("time-req", "Please ensure that the minimum default extension is 15 seconds.", function (value) {
                const min = parseInt(this.parent.defaultExtensionMin, 10);
                if (parseInt(value, 10) < 15 && min === 0) {
                    return this.createError({
                        message: 'Please ensure that the minimum default extension is 15 seconds.',
                    });
                }
                return true;
            }),
        defaultExtensionMin: yup.string().required("Default Extension is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            })
            .test("time-req", "Please ensure that the minimum default extension is 15 seconds.", function (value) {
                const sec = parseInt(this.parent.defaultExtensionSec, 10);
                if (parseInt(value, 10) === 0 && sec < 15) {
                    return this.createError({
                        message: 'Please ensure that the minimum default extension is 15 seconds.',
                    });
                }
                return true;
            }) */
    })

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        control,
        watch,
        clearErrors,
        formState: { errors }
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialData, mode: 'onChange' });

    const dynamicExtensionMin = watch('dynamicExtensionMin');
    const dynamicExtensionSec = watch('dynamicExtensionSec');
    const defaultExtensionMin = watch('defaultExtensionMin');
    const defaultExtensionSec = watch('defaultExtensionSec');

    useEffect(() => {
        if (parseInt(dynamicExtensionMin, 10) !== 0 || parseInt(dynamicExtensionSec, 10) >= 15) {
            clearErrors(["dynamicExtensionMin", "dynamicExtensionSec"]);
        }
    }, [dynamicExtensionMin, dynamicExtensionSec]);

    useEffect(() => {
        if (parseInt(defaultExtensionMin, 10) !== 0 || parseInt(defaultExtensionSec, 10) >= 15) {
            clearErrors(["defaultExtensionMin", "defaultExtensionSec"]);
        }
    }, [defaultExtensionMin, defaultExtensionSec]);

    const handleStartDateChange = (date: any) => {
        const newStartDate = new Date(date);
        setStartDate(newStartDate);
        const EndDate = endDate ? new Date(endDate) : new Date(newEndDate);
        if (EndDate < newStartDate) {
            setDateError('End Date must be greater than Start Date.');
        } else {
            if (EndDate.getTime() - newStartDate.getTime() < 2 * 60000) {
                setDateError('Minimum 2-minute time difference required between Start Date and End Date.');
            } else {
                setDateError('');
            }
        }
    };

    const handleEndDateChange = (date: any) => {
        const newEndDate = new Date(date);
        setEndDate(newEndDate);
        const StartDate = startDate ? new Date(startDate) : new Date(newStartDate);
        if (StartDate >= newEndDate) {
            setDateError('End Date must be greater than Start Date.');
        } else {
            if (newEndDate.getTime() - StartDate.getTime() < 2 * 60000) {
                setDateError('Minimum 2-minute time difference required between Start Date and End Date.');
            } else {
                setDateError('');
            }
        }
    };

    const onSubmit = () => {
        if (dateError) {
            return;
        }
        setLoading(true)
        let silentData: any = getValues();
        const defaultExtensionSecNumber = parseInt(silentData.defaultExtensionSec, 10);
        const defaultExtensionMinNumber = parseInt(silentData.defaultExtensionMin, 10);
        const defaultExtensionSeconds = defaultExtensionMinNumber * 60 + defaultExtensionSecNumber;
        const dynamicExtensionSecNumber = parseInt(silentData.dynamicExtensionSec, 10);
        const dynamicExtensionMinNumber = parseInt(silentData.dynamicExtensionMin, 10);
        const dynamicExtensionSeconds = (dynamicExtensionMinNumber * 60) + dynamicExtensionSecNumber;
        const startDate = momentUtil.convertTimeToUtc(silentData.startDateTime)
        const endDate = momentUtil.convertTimeToUtc(silentData.endDateTime)

        silentData = {
            ...silentData,
            lotId: mode === "clone" ? cloneLotId : lotId,
            lotSilentId: silentData.lotSilentId ? silentData.lotSilentId : 0,
            startDateTime: startDate,
            endDateTime: endDate,
            defaultExtension: defaultExtensionSeconds,
            dynamicExtensions: dynamicExtensionSeconds
        }

        axios.post(UPDATELOTSILENT, silentData, { ...config }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            } else {
                toast.success(InventoryMessages.silentUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
            }
            if (mode === "clone") {
                dispatch(setCloneTab([...clonedTabs, LOTSILENT]))
            }
            if (mode === "create") {
                dispatch(setCreateTab([...createTabs, LOTSILENT]))
            }
            nextStep();
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    const nextStep = () => {
        dispatch(setStep('docURls'))
    }

    const handlePrevious = () => {
        dispatch(setStep("realEstate"))
        dispatch(setInventoryInfoActive({ activeTab: "records" }));
    }

    useEffect(() => {
        axios.get(`${GETCHECKLISTBYAUCTIONID}?AuctionId=${auctionData.auctionId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                setLoading(false)
                reset(silentDataFromApi)
                return;
            } else {
                let respData = response.data.data
                const dynamicExtFromAuction = respData.dynamicExtension
                let data = silentDataFromApi ?? {}
                if ((data.defaultExtensionMin === 0 && data.defaultExtensionSec === 0) || Object.keys(data).length === 0) {
                    data = {
                        ...data,
                        defaultExtensionMin: parseInt(dynamicExtFromAuction?.split(':')[1], 10),
                        defaultExtensionSec: parseInt(dynamicExtFromAuction?.split(':')[2], 10),
                    }
                }
                reset(data)
                setLoading(false)
            }
        }, (error) => {
            setLoading(false)
            if (error?.response?.status !== 401) {
                toast.error("Some error occurred!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        })
    }, [silentDataFromApi])

    useEffect(() => {
        setLoading(true);
        const id = mode === "clone" ? cloneLotId : lotId
        axios.get(`${GETSILENTBYLOTID}/?lotId=${id}`, { ...config }).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setSilentDataFromApi({})
                return;
            } else {
                const data = response.data.data;
                const defaultExtensionSeconds: any = data?.defaultExtension;
                const dynamicExtensionsSeconds: any = data?.dynamicExtensions;
                const dynamicExtensionsMinutes: any = Math.floor(dynamicExtensionsSeconds / 60);
                const dynamicExtensionsRemainingSeconds: any = dynamicExtensionsSeconds % 60;
                const defaultExtensionsMinutes: any = Math.floor(defaultExtensionSeconds / 60);
                const defaultExtensionsRemainingSeconds: any = defaultExtensionSeconds % 60;
                const timeZoneStartDate = momentUtil.parseTimeInUtc({ dateTimeString: data?.startDateTime });
                const timeZoneEndDate = momentUtil.parseTimeInUtc({ dateTimeString: data?.endDateTime });
                setNewStartDate(data?.startDateTime);
                setNewEndDate(data?.endDateTime);

                setSilentDataFromApi({
                    ...data,
                    dynamicExtensionMin: Number(dynamicExtensionsMinutes) > 0 ? dynamicExtensionsMinutes : 0,
                    dynamicExtensionSec: Number(dynamicExtensionsRemainingSeconds) > 0 ? dynamicExtensionsRemainingSeconds : 0,
                    defaultExtensionMin: Number(defaultExtensionsMinutes) > 0 ? defaultExtensionsMinutes : 0,
                    defaultExtensionSec: Number(defaultExtensionsRemainingSeconds) > 0 ? defaultExtensionsRemainingSeconds : 0,
                    startDateTime: data?.startDateTime ? timeZoneStartDate : '',
                    endDateTime: data?.endDateTime ? timeZoneEndDate : '',
                });
            }
        }).catch((error) => {
            setLoading(false);
        });
    }, []);

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTSILENT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTSILENT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    return (
        <>
            <div className="row top_border justify-content-center">
                <div className="col-xxl-10 mt-3">
                    <div className="mb-3">
                        <h3>Silent</h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        {loading && <div className="loader-wrap">
                            <img src={`${localStorage.getItem('globalLoader')}`} />
                        </div>}
                        <div className="row g-3 mb-3">
                            <div className="col-lg-6">
                                <label className="form-label">Start Date and Time ({defaultTimezone})<sup className="text-danger">*</sup></label>
                                <Controller
                                    name="startDateTime"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <div className="date_icon">
                                            <DateTime
                                                onChange={(date) => {
                                                    onChange(date);
                                                    handleStartDateChange(date);
                                                }}
                                                closeOnSelect={true}
                                                dateFormat="MM/DD/y"
                                                timeFormat="hh:mm A"
                                                value={value}
                                                inputProps={
                                                    {
                                                        placeholder: 'Select Date and Time',
                                                        onInput: (e: any) => {
                                                            const cursorPosition: any = e.target.selectionStart;
                                                            onChange(e.target.value);
                                                            e.target.setSelectionRange(cursorPosition, cursorPosition);
                                                        },
                                                    }} />
                                        </div>
                                    )}
                                />
                                {errors.startDateTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.startDateTime.message}</span>}

                            </div>
                            <div className="col-lg-6">
                                <label className="form-label">End Date and Time ({defaultTimezone})<sup className="text-danger">*</sup></label>
                                <Controller
                                    name="endDateTime"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <div className="date_icon">
                                            <DateTime
                                                onChange={(date) => {
                                                    onChange(date);
                                                    handleEndDateChange(date);
                                                }}
                                                dateFormat="MM/DD/y"
                                                closeOnSelect={true}
                                                timeFormat="hh:mm A"
                                                value={value}
                                                inputProps={
                                                    {
                                                        placeholder: 'Select Date and Time',
                                                        onInput: (e: any) => {
                                                            const cursorPosition: any = e.target.selectionStart;
                                                            onChange(e.target.value);
                                                            e.target.setSelectionRange(cursorPosition, cursorPosition);
                                                        },
                                                    }} />
                                        </div>
                                    )}
                                />
                                {dateError && (
                                    <span className='text-danger' style={{ fontSize: "12px" }}>
                                        {dateError}
                                    </span>
                                )}
                                {errors.endDateTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.endDateTime.message}</span>}

                            </div>
                            {/* <div className="col-lg-6">
                                <label className="form-label">Dynamic Extension Time</label>
                                <div className="d-flex dur">
                                    <select
                                        className="form-select scrollbar"
                                        {...register('dynamicExtensionMin')}
                                    >
                                        <option value="0">0 mins</option>
                                        {numbers.map((num, index) => {
                                            return (
                                                <option value={num} key={index}>
                                                    {num} mins
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <span className="ms-2 me-2">:</span>
                                    <select
                                        className="form-select scrollbar"
                                        defaultValue="15"
                                        {...register('dynamicExtensionSec')}
                                    >
                                        <option value="0">0 secs</option>
                                        {numbers.map((num: any, index: any) => {
                                            return (
                                                <option value={num} key={index} >
                                                    {num} secs
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {(errors?.dynamicExtensionSec) ? (
                                    <span className="text-danger" style={{ fontSize: "12px" }}>
                                        {errors?.dynamicExtensionSec.message}
                                    </span>
                                ) : errors?.dynamicExtensionMin && (
                                    <span className="text-danger" style={{ fontSize: "12px" }}>
                                        {errors?.dynamicExtensionMin.message}
                                    </span>
                                )}
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label">Default Dynamic Extension - (from Auction Setting)</label>
                                <div className="d-flex dur">
                                    <select
                                        className="form-select scrollbar"
                                        {...register('defaultExtensionMin')}
                                    >
                                        <option value="0">0 mins</option>
                                        {numbers.map((num, index) => {
                                            return (
                                                <option value={num} key={index}>
                                                    {num} mins
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <span className="ms-2 me-2">:</span>
                                    <select
                                        className="form-select scrollbar"
                                        {...register('defaultExtensionSec')}
                                    >
                                        <option value="0">0 secs</option>
                                        {numbers.map((num, index) => {
                                            return (
                                                <option value={num} key={index} >
                                                    {num} secs
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {(errors?.defaultExtensionSec) ? (
                                    <span className="text-danger" style={{ fontSize: "12px" }}>
                                        {errors?.defaultExtensionSec.message}
                                    </span>
                                ) : errors?.defaultExtensionMin && (
                                    <span className="text-danger" style={{ fontSize: "12px" }}>
                                        {errors?.defaultExtensionMin.message}
                                    </span>
                                )}
                            </div> */}
                            <TimeSelector
                                label="Dynamic Extension Time"
                                registerMin={register('dynamicExtensionMin')}
                                registerSec={register('dynamicExtensionSec')}
                                errorsMin={errors?.dynamicExtensionMin}
                                errorsSec={errors?.dynamicExtensionSec}
                                isRequired={false}  // If required, pass true
                            />
                               <TimeSelector
                                label="Default Dynamic Extension - (from Auction Setting)"
                                registerMin={register('defaultExtensionMin')}
                                registerSec={register('defaultExtensionSec')}
                                errorsMin={errors?.defaultExtensionMin}
                                errorsSec={errors?.defaultExtensionSec}// This can be customized or passed as props
                                isRequired={false}  // Pass true if the field is required
                            />
                            <div className="col-lg-4 mt-4 pl-2">
                                <label>Silent Status</label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                        {...register('isSilentApplied')}
                                    // checked={suspend ? false : true}
                                    />
                                    {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label> */}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom z-index-3">
                                <button className="btn btn-secondary " type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                                <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal">{CommonMessages.cancelButton}</Link>
                                <button className="btn btn-primary ms-2 " type="submit">{btnText}</button>
                                <button className="btn btn-secondary mx-2" data-wizard-next-btn="data-wizard-next-btn" onClick={nextStep}>{CommonMessages.skipButton}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Silent;