import axios, { AxiosResponse } from 'axios';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { GETCURRENTDATETIME } from '../common/apiConstatnts';
 
// Define the interface for date and time
interface DateTime {
    date: string;
    time: string;
    currentDateTime: string;
}
 
interface ApiResponse {
    code: number;
    message: string;
    success: string;
    data: {
        currentDateTime: string;
    };
}
 
let isAlreadyCalled = false;
 
// Fetch initial date and time from the API
const fetchInitialDateTime = async (): Promise<string | null> => {
    try {
        // Replace 'your_api_endpoint_here' with your actual API endpoint
        const response: AxiosResponse<ApiResponse> = await axios.get<ApiResponse>(GETCURRENTDATETIME);
        // Extract and return the currentDateTime from the response data
        return response.data.data.currentDateTime;
    } catch (error) {
        console.error('Error fetching initial date and time:', error);
        return null;
    }
};
 
// Create a custom hook to fetch the initial date and time from an API and continue updating it locally
const useCurrentDateTime = (): DateTime | null => {
    const [initialDateTime, setInitialDateTime] = useState<DateTime | null>(null);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
 
    // Parse the date and time string into the desired format
    const parseDateTime = (currentDateTime: string): DateTime => {
        // const dateTime = new Date(currentDateTime);
        const date = currentDateTime.split('T')[0]; // Get date in format "YYYY-MM-DD"
        const time = currentDateTime.split('T')[1]; // Get time without milliseconds
        return { date, time, currentDateTime };
    };
 
    const fetchDateTime = async () => {
        const dateTimeString = await fetchInitialDateTime();
        if (dateTimeString) {
            const parsedDateTime = parseDateTime(dateTimeString);
            setInitialDateTime(parsedDateTime);
        }
 
        isAlreadyCalled = false
 
    };
 
    useEffect(() => {
 
        if (!isAlreadyCalled) {
            fetchDateTime();
            isAlreadyCalled = true;
        }
 
        const intervalId = setInterval(() => {
            setElapsedTime(prevElapsedTime => prevElapsedTime + 1000); // Update elapsed time every second
        }, 1000); // Update every second
 
        return () => clearInterval(intervalId);
    }, []);
 
    if (!initialDateTime) {
        return null;
    }
 
    // Utility function to pad zero to single digit numbers
    const padZero = (num: number): string => {
        return num < 10 ? `0${num}` : num.toString();
    };
 
    // Calculate the current time based on the initial time and elapsed time
    const calculateCurrentTime = (initialTime: string, elapsedTime: number): string => {
        const initialTimeParts = initialTime.split(':').map(part => parseInt(part));
        const millisecondsInHour = 60 * 60 * 1000;
        const millisecondsInMinute = 60 * 1000;
        const millisecondsInSecond = 1000;
 
        let hours = initialTimeParts[0];
        let minutes = initialTimeParts[1];
        let seconds = initialTimeParts[2];
 
        let totalMilliseconds = (hours * millisecondsInHour) + (minutes * millisecondsInMinute) + (seconds * millisecondsInSecond) + elapsedTime;
 
        hours = Math.floor(totalMilliseconds / millisecondsInHour);
        totalMilliseconds %= millisecondsInHour;
        minutes = Math.floor(totalMilliseconds / millisecondsInMinute);
        totalMilliseconds %= millisecondsInMinute;
        seconds = Math.floor(totalMilliseconds / millisecondsInSecond);
 
        // Format the time as HH:mm:ss
        return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
    };
 
    // Calculate the current date and time based on the initial server time and elapsed time
    const currentDateTimeFunc: DateTime = {
        date: initialDateTime.date,
        time: calculateCurrentTime(initialDateTime.time, elapsedTime),
        currentDateTime: `${initialDateTime.date}T${calculateCurrentTime(initialDateTime.time, elapsedTime)}`
    };
 
    return currentDateTimeFunc;
};
 
// Create the context
const DateTimeContext = React.createContext<DateTime | null>(null);
 
interface DateTimeProviderProps {
    children: ReactNode;
}
 
// Provider component to provide the date and time to its children
export const DateTimeProvider: React.FC<DateTimeProviderProps> = ({ children }) => {
    const dateTime = useCurrentDateTime();
 
    return (
        <DateTimeContext.Provider value={dateTime}>
            {children}
        </DateTimeContext.Provider>
    );
};
 
// Custom hook to consume the date and time from the context
export const useDateTime = (): DateTime | null => {
    const context = useContext(DateTimeContext);
    if (context === null) {
        throw new Error('useDateTime must be used within a DateTimeProvider');
    }
    return context;
};
 
export { fetchInitialDateTime, useCurrentDateTime };
 