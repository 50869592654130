import axios from "axios"
import moment from "moment"
import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Swal from "sweetalert2"
import * as XLSX from 'xlsx'
import {  GETSELLERADJUSTSUMMARY, GETALLAUCTIONLIST } from "../../common/apiConstatnts";
import { Breadcrumb } from "../../common/interface";
import * as routeConstant from '../../common/routeConstants';
import InnerHeader from "../InnerHeader/InnerHeader"
import Sidebar from "../Sidebar/Sidebar";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages"
import dpa_logo from '../../assets/img/dpa-logo.jpg';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import {generateSellerAdjustmentCsv, generateSellerAdjustmentPdf} from './ExportReportsPdfCsvFile';

const breadcrumbData: Breadcrumb[] = [
    { label: 'Home', url: routeConstant.DASHBOARD },
    { label: 'Reports', url: routeConstant.REPORTS },
    { label: 'Seller Adjustments Report', url: routeConstant.SALLERADJUSTREPORT }
]

const SellerAdjustmentReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const currTime = momentUtil.parseTimeInUtc({ dateTimeString: new Date() })

    const [loading, setLoading] = useState(false);
    const [sellerAdjustData, setSellerAdjustData] = useState<any[]>([]);
    const [auctionList, setAuctionList] = useState<[]>([]);
    const [selectedAuction, setSelectedAuction] = useState<any>({});
    const [typeIndex, setTypeIndex] = useState<any>(0);
    const [reportData, setReportData] = useState<any>([]);
    const [auctionCode, setAuctionCode] = useState('')
    const tableRef:any = useRef(null);

    let AuctionStartDate = sellerAdjustData?.length > 0 ? sellerAdjustData[0].auctionStartDate : null;
    AuctionStartDate = AuctionStartDate?.replace(/-/g, '/');

    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const hammerAuction:any = auctionList.find((option: any) => option.value === getGlobalAuction.value)

    const getSellerAdjustData = (auctionId?: any) => {
        setLoading(true)
        if(auctionId){
            axios.get(`${GETSELLERADJUSTSUMMARY}?AuctionId=${auctionId}`).then((response: any) => {
                setLoading(false)
                if (response?.data?.success === "Fail") {
                    setSellerAdjustData([])
                    return;
                } else {
                    const sellerAdjustData = response.data.data;
                    setSellerAdjustData(sellerAdjustData);
                }
            }).catch((error) => {
                setLoading(false);
                handleError(error);
            });
        }      
    }

    const handleAuctionChange = (e: any) => {
        const auctionCode = e.label.split('-')
        setAuctionCode(auctionCode[0])
        setSelectedAuction(e);
    };

    const getAllAuctions = () => {
        setLoading(true);
        axios.get(GETALLAUCTIONLIST).then((response: any) => {
            setLoading(false);
            const auctionData = response?.data?.data?.map((lData: any) => {
                return {
                    label: `${lData.auctionCode} - ${lData.auctionTitle}`,
                    value: lData.auctionId,
                };
            });
            if (getGlobalAuction?.value) {
                setSelectedAuction(auctionData.find((auction:any) => auction.value === getGlobalAuction.value));
            } else {
                setSelectedAuction(auctionData[0]);
            }
            setAuctionList(auctionData);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
          });;
    };

    useEffect(() => {
        getAllAuctions()
    }, [])

    useEffect(() => {
        if (selectedAuction?.value) {
            getSellerAdjustData(selectedAuction.value);
        }
    }, [selectedAuction]);

    useEffect(()=>{
        if(hammerAuction){
            setSelectedAuction(hammerAuction)
        } else {
            getAllAuctions();
        }
    },[hammerAuction])

    const getNoDataView = () => {
        return (
            <div className="text-center mt-5">
                <span className="p-4">
                    No records found!
                </span>
            </div>
        );
    };

    const formatAmount = (number:any) => {
        const absoluteValue = Math.abs(number);
        const formattedNumber = absoluteValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return number < 0 ? `(${formattedNumber})` : formattedNumber;
    };

    const calculateTotalAmount = () => {
        let total = 0;
        sellerAdjustData.forEach(item => {
            total += item.totalAmount;
        });
        return formatAmount(total);
    };

    const printTable = () => {
        const printWindow:any = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print Table</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #e1e1e1;
                        }
                        th, td {
                            font-size: 12px;
                            border: 1px solid #e1e1e1;
                            padding: 8px;
                            text-align: right;
                        }
                        th {
                            text-align: left;
                        }
                        tfoot th {
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    ${tableRef.current.outerHTML}
                    <script>
                        window.onload = function() {
                            window.print();
                            window.close();
                        };
                    </script>
                </body>
            </html>
        `);
        printWindow.document.close();
    };

    useEffect(() => {
        const handleKeyDown = (event:any) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                printTable();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    
    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <Sidebar />
            <div className="content pt-0">
                <InnerHeader breadCrumb={breadcrumbData} title='Seller Adjustments Report'>
                    <div className="col-12">
                        <div className="row justify-content-between align-items-end">
                            <div className=" col-sm-6 mt-2">
                                <label className="form-label ms-1 mb-2"> Select Auction</label>
                                    <div className="col-lg-8">
                                        <Select
                                            classNamePrefix="dpaSelect"
                                            placeholder="Select Auction"
                                            className="isActive swap-to-lot-menu clone-inventory"
                                            options={auctionList}
                                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                                            value={selectedAuction.value ?  auctionList.find((option: any) => option.value === selectedAuction.value) : hammerAuction}
                                            onChange={(e: any) => {
                                                handleAuctionChange(e);
                                            }}
                                        />
                                    </div>
                            </div>
                            <div className=" col-sm-6 mt-2">
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3" onClick={()=>generateSellerAdjustmentCsv(sellerAdjustData)}
                                        disabled={sellerAdjustData.length === 0 }>
                                        <i className="fas fa-download"></i>
                                        <span>Excel</span>
                                    </button>
                                    <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-1 ms-2 ps-3 pe-3 ms-2" onClick={()=> generateSellerAdjustmentPdf(sellerAdjustData)}
                                        disabled={sellerAdjustData.length === 0 }
                                    >
                                        <i className="fas fa-download"></i>
                                        <span>PDF</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </InnerHeader>
                <div className="container-fluid">
                    <div className="row top_border">
                        <div className="col-12 mt-4 mb-4 forpdf_only">
                        {sellerAdjustData.length === 0 ?
                            getNoDataView() : 
                            <>
                            <div className="table-responsive mx-n1 px-1">
                                <table className="table fs--1 mb-0">
                                    <tbody>
                                        <tr>
                                            <td valign="top" style={{ border: 0, width: '100%' }}>
                                            <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', margin: 0 }}>Seller Adjustment Summary</p>
                                            <p style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', margin: 0 }}>
                                            <span>  {`Auction #${
                                                selectedAuction 
                                                ? selectedAuction?.label?.split('-')[0]
                                                : auctionCode 
                                                    ? hammerAuction 
                                                    : hammerAuction?.label?.split('-')[0]
                                            }`}</span><br /><span>{moment(AuctionStartDate).format('MM/DD/YYYY')}</span>
                                            </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-responsive mx-n1 px-1">
                                <table className="table fs--1 mb-0" ref={tableRef} >
                                <thead>
                                    <tr>
                                        <th style={{ fontSize:"12px", width: '15%' }}><div></div></th>
                                        <th style={{  fontSize:"12px",width: '45%', textAlign: 'left', whiteSpace: "pre", }} colSpan={2}><div>Seller # & Name</div></th>
                                        <th style={{ fontSize:"12px", width: '10%', textAlign: 'center', whiteSpace: "pre", }}><div>Type</div></th>
                                        <th style={{ fontSize:"12px", width: '20%', textAlign: 'right' , whiteSpace: "pre", }}><div>Amount</div></th>
                                        <th style={{fontSize:"12px",  width: '10%', textAlign: 'right' , whiteSpace: "pre", paddingRight: "5px" }}><div>Sales Tax</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sellerAdjustData?.map((item, index) => {
                                      return(
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td colSpan={6} style={{ padding: 0 }}>
                                                    <div className="table-responsive mx-n1 px-1">
                                                        <table className="table fs--1 mb-0">
                                                            <tr>
                                                                <th style={{ fontWeight: 'bold',  width: '15%', backgroundColor: '#e1e1e1', whiteSpace: "pre",   }}></th>
                                                                <th style={{fontSize:"11px", fontWeight: 'bold',  width: '60%', backgroundColor: '#e1e1e1', whiteSpace: "pre", textAlign: 'left', padding: '3px' }} colSpan={3}>{item.description}</th>
                                                                <th style={{ fontSize:"11px", fontWeight: 'bold',  width: '15%', backgroundColor: '#e1e1e1', whiteSpace: "pre", textAlign: 'right', padding: '3px' }}>${formatAmount(item.totalAmount)}</th>
                                                                <th style={{ fontWeight: 'bold',  width: '10%', backgroundColor: '#e1e1e1', whiteSpace: "pre", padding: '3px' }}></th>
                                                            </tr>
                                                            {item.lstSellerAdjust?.map((adjustment:any, idx:any) => (
                                                                <React.Fragment key={idx}>
                                                                    <tr>
                                                                        <td style={{fontSize:"11px",  width: '15%', textAlign: 'left' ,whiteSpace: "pre", }}>{adjustment.date}</td>
                                                                        <td style={{fontSize:"11px",   width: '10%', textAlign: 'left',whiteSpace: "pre", }}>{adjustment.sellerId}</td>
                                                                        <td style={{fontSize:"11px",   width: '35%', textAlign: 'left',whiteSpace: "pre", }}>{adjustment.sellerName}</td>
                                                                        <td style={{fontSize:"11px",   width: '10%', textAlign: 'center',whiteSpace: "pre", }}>{adjustment.type}</td>
                                                                        <td style={{fontSize:"11px",  width: '20%', textAlign: 'right', whiteSpace: "pre", paddingRight: "5px" }}>
                                                                        {adjustment.type === "Credit" ? `$(${formatAmount(adjustment.amount)})` : `$${formatAmount(adjustment.amount)}`}
                                                                        </td>
                                                                        <td style={{fontSize:"11px",   width: '10%', textAlign: 'right',whiteSpace: "pre", paddingRight: "5px" }}>{"-"}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))}
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                      )                                                              
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th style={{ fontSize: '11px', width: '70%', textAlign: 'right' ,whiteSpace: "pre",}} colSpan={4}><div>TOTAL Seller Adjustments</div></th>
                                        <th style={{ fontSize: '11px', width: '20%', textAlign: 'right' ,whiteSpace: "pre", }}><div>${calculateTotalAmount()}</div></th>
                                        <th style={{ fontSize: '11px', width: '10%', textAlign: 'right' }}><div></div></th>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                            </>
                        }                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SellerAdjustmentReport