import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    Alignment, Autosave, Bold, ClassicEditor, Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Highlight,
    Indent,
    Italic,
    List,
    Paragraph, Underline,
    Undo,
    Link,
    BlockQuote,
} from "ckeditor5";
import 'ckeditor5/ckeditor5.css';
import { FC, useEffect, useRef, useState } from 'react';

interface CustomCkEditorProps {
    onTextChanged: (text: string) => void;
    data: any;
}

export const CustomCkEditor: FC<CustomCkEditorProps> = ({
    onTextChanged,
    data
}) => {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    useEffect(() => {
        setIsLayoutReady(true);
        return () => setIsLayoutReady(false);
    }, []);

    const handleChange = (event: any, editor: any) => {
        const data = editor?.getData();
        onTextChanged(data);
    };

    return (
        <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
            <div className="editor-container__editor">
                <div ref={editorRef}>{isLayoutReady && <CKEditor editor={ClassicEditor}
                    data={data}
                    onChange={handleChange}
                    config={{
                        initialData: '',
                        toolbar: {
                            items: [
                                'undo',
                                'redo',
                                '|',
                                'fontSize',
                                'fontFamily',
                                'fontColor',
                                'fontBackgroundColor',
                                '|',
                                'bold',
                                'italic',
                                'underline',
                                '|',
                                'highlight',
                                '|',
                                'alignment',
                                '|',
                                'bulletedList',
                                '|',
                                'link'
                            ],
                            shouldNotGroupWhenFull: true
                        },
                        plugins: [
                            Alignment,
                            Autosave,
                            Bold,
                            Essentials,
                            FontBackgroundColor,
                            FontColor,
                            FontFamily,
                            FontSize,
                            GeneralHtmlSupport,
                            Highlight,
                            Indent,
                            Italic,
                            List,
                            Paragraph,
                            Underline,
                            Undo,
                            Link,
                            BlockQuote,
                        ],
                        fontFamily: {
                            options: [
                                'default','Arial','Times New Roman','courier'
                            ],
                            supportAllValues: true
                        },
                        fontSize: {
                            options: [
                                '8px','9px','10px','11px', '12px', '13px', '14px', '16px', '18px'
                            ],
                            supportAllValues: true
                        },
                    }} />}
                </div>
            </div>
        </div>
    )
};

export default CustomCkEditor