import { yupResolver } from "@hookform/resolvers/yup";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from "yup";
import {
  ADVANCESEARCHINVENTORY,
  BIDHISTORY,
  BIDMANAGEMENTADVANCEFILTER,
  BIDMANAGEMENTGETBYID,
  BULKMAXAMOUNT,
  CREATEBIDMANAGEMENT,
  DELETEBIDMANAGEMENT,
  GETALLBIDMANAGEMENT,
  GETALLCUSTOMERS,
  GETALLFUTUREAUCTION,
  GETBIDDERSBYCUSTOMERID,
  UPDATEBIDMANAGEMENT,
} from "../../common/apiConstatnts";

import jwt_decode from "jwt-decode";
import CurrencyInput from "react-currency-input-field";
import { Breadcrumb } from "../../common/interface";
import {
  AuctionBidMessages,
  CommonMessages,
} from "../../common/messageConstants";
import { DECIMAL73, DECIMAL73_MESSAGE } from "../../common/regexConstants";
import * as routeConstant from "../../common/routeConstants";
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../Utils/useDateTime";
import {
  ERROR_MESSAGES,
  formatCurrency,
  formatNumber,
  handleKeyDown,
  trimObjectValues,
  useDebounce,
} from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { encryptId } from "../../Encryption/Encryption";
interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const AuctionBidListing = () => {
  const initialData = {
    biddingManagementId: "",
    auctionCode: "",
    date: "",
    lotNo: "",
    bidderNumber: "",
    customerId: "",
    amount: "",
    maxAmount: "",
    isActive: "",
    isDelete: "",
  };
  const momentUtil = MomentUtil.getInstance();
  const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [auctionBidData, setAuctionBidData] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxValue, setMaxValue]: any = useState(0);
  const [bidIncrementIds, setBidIncrementIds]: any = useState([]);
  const [editMaxValue, setEditMaxValue] = useState(false);
  const [displayEditbtn, setDisplayEditBtn] = useState(false);
  const [filterError, setFilterError] = useState({
    auctionCode: "",
    displayLotNo: "",
    bidderNumber: "",
    customerName: "",
    keyword: "",
  });
  const [filterAuctionBid, setFilterAuctionBid] = useState<any>([]);
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [toggleMassModal, setToggleMassModal] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("create");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedAuction, setSelectedAuction] = useState("");
  const [currentBid, setCurrentBid] = useState<any>({});
  const [bidData, setBidData] = useState<[]>([]);
  const [auctionData, setAuctionData] = useState<[]>([]);
  const [lots, setLots] = useState<[]>([]);
  const [bidderList, setBidderList] = useState([]);
  const [fromLotData, setFromLotData] = useState<any>();
  const [customerId, setCustomerId] = useState<any>();
  const [auctionCode, setAuctionCode] = useState<any>();
  const [bidderNumber, setBidderNumber] = useState<any>([]);
  const PER_PAGE = 10;
  const [increments, setIncrements]: any = useState(null);
  const [incValue, setIncValue] = useState(2);
  const [bidPrice, setBidPrice]: any = useState(2);
  const [openBidModal, setOpenBidModal] = useState(false);
  const [bidHistory, setBidHistory] = useState([])
  const dateTime = useCurrentDateTime();
  // const currentUTC = new Date();
  const currentDate: any = momentUtil.parseTimeInUtc({
    dateTimeString: dateTime?.currentDateTime,
  });

  const currentUTCDate = moment.utc(currentDate).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);
  const [resetDate, setResetDate] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE);
  const [oldMaxValue, setOldMaxValue] = useState(0);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<PageData>({});
  const token: any = localStorage.getItem("token");
  const decodedToken: any = jwt_decode(token);
  const superAdminRole = decodedToken.isDefaultRole;
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const initialFilterData = {
    auctionCode: "",
    displayLotNo: "",
    bidderNumber: "",
    category: "",
    customerName: "",
    date: "",
    keyword: "",
    auctionId: hammerAuctionId ? hammerAuctionId : "",
  };
  const [filterData, setFilterData] = useState<any>(initialFilterData);

  // Set the initial value to current UTC time
  const schema: any = yup.object().shape({
    auctionCode: yup
      .string()
      .required("Auction is required field")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    lotNo:
      lots?.length > 0
        ? yup
          .string()
          .required("Lot Number is required field")
          .test(
            "no-leading-space",
            ERROR_MESSAGES.NO_LEADING_SPACES,
            (value) => {
              if (!value) return true;
              return !/^\s/.test(value);
            }
          )
        : yup.string(),
    // datetime: yup.string().required("Date Time is required"),
    customerId: yup
      .string()
      .required("Buyer Name is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    bidderNumber:
      bidderList?.length > 0
        ? yup
          .string()
          .required("Bidder Number is required field")
          .test(
            "no-leading-space",
            ERROR_MESSAGES.NO_LEADING_SPACES,
            (value) => {
              if (!value) return true;
              return !/^\s/.test(value);
            }
          )
        : yup.string(),
    amount: yup
      .string()
      .required("Amount is required field")
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? /^\S*$/g.test(value) : true;
        }
      )
      .matches(DECIMAL73, DECIMAL73_MESSAGE),
    date: yup
      .string()
      .test("valid-datetime", "Invalid datetime", function (value) {
        if (currentBid?.biddingManagementId > 0) {
          return true;
        }
        const parsedDate = moment(value);
        const currentDate = momentUtil.parseTimeInUtc({
          dateTimeString: dateTime?.currentDateTime,
        });

        if (
          parsedDate.year() < 2000 ||
          parsedDate.year() > 2500 ||
          parsedDate.diff(currentDate, "minutes") < 0
        ) {
          return this.createError({
            message:
              `Please enter a valid date that is greater than the current date.`,
          });
        }

        // Calculate the difference in minutes between parsed date and current date
        const timeDifferenceInMinutes = parsedDate.diff(currentDate, "minutes");

        if (timeDifferenceInMinutes <= 0) {
          return this.createError({
            message: "The start time must be in the future.",
          });
        }
        return true;
      }),
  });
  const schema2: any = yup.object().shape({
    auctionCode: yup
      .string()
      .required("Auction is required field")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
    displayLotNo: yup
      .string()
      .required("Lot Number is required field")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true;
        return !/^\s/.test(value);
      }),
  });
  const {
    register: filterRegister,
    reset: filterReset,
    setValue: filterSetValue,
    watch: filterWatch,
    getValues: filterGetValues,
  } = useForm({
    defaultValues: initialFilterData,
    resolver: yupResolver(schema2),
    mode: "onChange",
  });

  const searchText2 = filterWatch("keyword");

  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Bid Management", url: "" },
  ];
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({ resolver: yupResolver(schema) });

  const auction = watch("auctionCode");
  const biddNumber = watch("bidderNumber");
  const lot = watch("lotNo");
  const customer = watch("customerId");
  const amount = watch("amount");
  const bidDate = watch("date");
  const yesterday = moment().subtract(1, "day");
  const maxAmount = watch("maxAmount");

  const disablePastDt = (current: any) => {
    return current.isAfter(yesterday);
  };
  // const // clearSearchBar = () => {
  //   const searchbar: any = document.getElementById('searchBar')
  //   searchbar.value = ''
  // }

  useEffect(() => {
    clearErrors(["auctionCode"]);
  }, [auction]);
  useEffect(() => {
    clearErrors(["bidderNumber"]);
  }, [bidderNumber]);
  useEffect(() => {
    clearErrors(["lotNo"]);
  }, [lot]);
  useEffect(() => {
    clearErrors(["customerId"]);
  }, [customer]);
  useEffect(() => {
    clearErrors(["amount"]);
  }, [amount]);
  useEffect(() => {
    clearErrors(["maxAmount"]);
  }, [maxAmount]);

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum
    updateData(page, data);
    setAuctionBidData(data);
    setFilterAuctionBid(data);
  };


  // this useEffect called when click on pageNumber
  useEffect(() => {
    // debugger
    //when global search applied
    if (filterData?.keyword) {
      //this prevents dual API call
      if (currentPage == 0) {
        return
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuctionBid(alreadyFetchedDataPageNumber[currentPage])
        return
      }
      handleAdvanceFilter(filterData, currentPage)
      return
    }

    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (currentPage == 0) {
        return
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuctionBid(alreadyFetchedDataPageNumber[currentPage])
        return
      }
      handleAdvanceFilter(filterData, currentPage)
      return
    }

    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterAuctionBid(alreadyFetchedDataPageNumber[currentPage])
        return
      }
      getAllBid(paginationSize, currentPage)
    }

  }, [currentPage])

  const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
    setAlreadyFetchedDataPageNumber({})
    currentPage !== 1 && setCurrentPage(1);
    setFilterError({
      auctionCode: "",
      bidderNumber: "",
      displayLotNo: "",
      customerName: "",
      keyword: "",
    });

    if (data == null) {
      setIsFiltered(false);
      const resetFilter = {
        auctionCode: "",
        displayLotNo: "",
        bidderNumber: "",
        customerName: "",
        category: "",
        date: "",
        keyword: "",
        auctionId: isLocalClear ? "" : hammerAuctionId
      }
      handleAdvanceFilter(resetFilter, 1);
      setFilterData(resetFilter);
      setShowFilter(!showFilter);
    } else if (!isLocalClear) {
      // this else if called when make search bar text empty
      handleAdvanceFilter({...data}, 1);
      setFilterData({ ...data})
    }
    setResetDate(false);
    setEditMaxValue(false);
    setDisplayEditBtn(false);
    setBidIncrementIds([]);
  };

  const handleAdvanceFilter = (fData: any, pageNum: any) => {
    const filterData1 = trimObjectValues(fData);

    if (
      filterData1?.bidderNumber ||
      filterData1?.displayLotNo ||
      filterData1?.customerName ||
      hammerAuctionId
    ) {
      setLoading(true);

      const params = {
        displayLotNo: filterData1.displayLotNo ?? "",
        bidderNumber: filterData1.bidderNumber ?? "",
        auctionCode: filterData1.auctionCode ?? "",
        customerName: filterData1.customerName ?? "",
        pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
        keyword: filterData1?.keyword ?? '',
        auctionId: hammerAuctionId ? hammerAuctionId : '',
        pageSize: paginationSize
      };

      const queryString = new URLSearchParams(params).toString();
 // instead of axios used fetch because we're using customer portal api as per task 3740 backe 
      fetch(`${BIDMANAGEMENTADVANCEFILTER}?${queryString}`)
        .then(response => response.json())
        .then((data) => {
          setLoading(false);
          if (data?.success === "Fail") {
            setFilterAuctionBid([]);
            return;
          } else {
            const bidData = data?.data?.data;
            setTotalRecords(data.data.totalRecords);

            // Find the record with the maximum max value
            let maxRecord = bidData.reduce((prevMax: any, current: any) =>
              prevMax.max > current.max ? prevMax : current
            );

            setOldMaxValue(maxRecord?.max);
            const extractedBidIds = bidData.map((item: any) => ({
              bidManagementId: item.biddingManagementId,
            }));

            // Set the extractedBidIds array in state
            setBidIncrementIds(extractedBidIds);
            setDisplayEditBtn(true);
            paginateData(bidData, pageNum ? pageNum : currentPage);
            setTotalRecords(data?.data?.totalRecords);
            setEditMaxValue(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      // If none of the conditions are met, don't run the API call and reset loading state
      setLoading(false);
      getAllBid(paginationSize, currentPage);
    }

    return;
  };

  const handleMaxBidSave = () => {
    const payload = {
      maxAmount: maxValue,
      bidManagementIdRequests: bidIncrementIds,
    };
    axios.post(BULKMAXAMOUNT, payload, config).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setToggleMassModal(false);
        return;
      } else {
        if (response?.data?.data) {
          toast.success(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setToggleMassModal(false);
          // debouncedRequest();
          setAlreadyFetchedDataPageNumber({})

        }
      }
    }).catch((error) => {
      setLoading(false);
      handleError(error)
    });
  };

  const changeHandler = (fieldName: string, value: any) => {
    const updateData = { ...filterData, [fieldName]: value };
    setFilterData(updateData);
    if (
      fieldName === "auctionCode" ||
      fieldName === "bidderNumber" ||
      fieldName === "lotNo"
    ) {
      const isValidNumber = /^[0-9]*$/.test(value) && value.length <= 20;
      const hasLeadingSpace = /^\s/.test(value);
      if (hasLeadingSpace) {
        setFilterError({
          ...filterError,
          [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES,
        });
        return;
      } else if (!isValidNumber) {
        setFilterError({
          ...filterError,
          [fieldName]: "Please enter number only upto 20 digits.",
        });
        return;
      } else {
        setFilterError({ ...filterError, [fieldName]: "" });
      }
    }
    if (fieldName === "customerName") {
      const isValidTitle = !/^\s/.test(value);
      if (!isValidTitle) {
        setFilterError({
          ...filterError,
          [fieldName]: "Leading spaces are not allowed",
        });
        return;
      } else {
        setFilterError({ ...filterError, [fieldName]: "" });
      }
    }
  };

  const handleSorting = (type: string, fieldName: string) => {

    let sortedData = [...filterAuctionBid];
    if (type === "ASC") {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData?.sort((a: any, b: any) =>
          a[fieldName]?.localeCompare(b[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData?.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
      }
    } else {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData?.sort((a: any, b: any) =>
          b[fieldName]?.localeCompare(a[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData?.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
      }
    }
    paginateData(sortedData, currentPage);
  };

  //debounce function for search functionality.
  // const debouncedRequest = useDebounce(() => {
  //   handleAdvanceFilter(filterData, currentPage)
  // });

  useEffect(() => {
    setValue("datetime", currentUTCDate);
  }, [setValue, currentUTCDate, currentDate]);

  const onSubmit = (dataOriginal: any) => {
    setLoading(true);
    if (error) {
      // If there is an error, do not proceed with the submission
      setLoading(false);
      return;
    }

    const data = trimObjectValues(dataOriginal);
    const payload = {
      biddingManagementId: data?.biddingManagementId
        ? parseInt(data?.biddingManagementId)
        : 0,
      auctionCode: parseInt(data?.auctionCode),
      date: data?.datetime,
      dateTime: data?.datetime,
      lotNo: data?.lotNo ? parseInt(data?.lotNo) : 0,
      bidderNumber: data?.bidderNumber ? parseInt(data?.bidderNumber) : 0,
      customerId: data?.customerId,
      amount: parseFloat(data?.amount),
      maxAmount: parseFloat(data?.maxAmount),
      isActive: true,
      isDelete: false,
    };

    if (mode === "create") {
      fetch(CREATEBIDMANAGEMENT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(async (response) => {

          const data = await response.json();
          if (data?.success === "Fail") {
            setLoading(false);
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setLoading(false);
            toast.success(AuctionBidMessages.bidAdded, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setToggleValue(!toggleModal);
            reset(initialData);
            clearAdvanceFilterData();
            setSelectedCustomer("");
            setCustomerId("");
            setAuctionCode("");
            setLots([]);
            setBidderList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      const payload2: any = {
        biddingManagementId: parseInt(data?.biddingManagementId),
        Amount: parseFloat(data?.amount),
        MaxAmount: parseFloat(data?.maxAmount),
      };
      setLoading(true);

      const queryParams = new URLSearchParams(payload2).toString();

      fetch(`${UPDATEBIDMANAGEMENT}?${queryParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const data = await response.json();
          if (data?.success === "Fail") {
            setLoading(false);
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setLoading(false);
            toast.success(AuctionBidMessages.bidUpdated, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            clearAdvanceFilterData(false, filterData);
            setError(false);
            setErrorMsg("");
            handleClose(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    }
  };

  const handleClose = (isDirtyCheck = true) => {
    setCurrentBid({});
    setError(false);
    setErrorMsg("");
    if (isDirtyCheck) {
      if (isDirty) {
        Swal.fire({
          title: "Are you sure?",
          text: "Changes will be discarded!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#006800",
          cancelButtonColor: "#d33",
          confirmButtonText: "Discard",
        }).then((result) => {
          if (result.isConfirmed) {
            reset(initialData);
            setToggleValue(!toggleModal);
            setSelectedCustomer("");
            setCustomerId("");
            setAuctionCode("");
            setError(false);
            setErrorMsg("");
          }
        });
        return;
      }
    }
    setToggleValue(!toggleModal);
    setSelectedCustomer("");
    setCustomerId("");
    setAuctionCode("");
    setLots([]);
    setBidderList([]);
    reset(initialData);
  };

  const handleEditModal = (biddingManagementId: string, type: string) => {
    if (type === "edit") {
      setMode("edit");
      setLoading(true);
      fetch(`${BIDMANAGEMENTGETBYID}?biddingManagementId=${biddingManagementId}`)
        .then((response) => {

          return response.json(); // Parse the JSON response
        })
        .then((data: any) => {
          setLoading(false);
          if (data?.success === "Fail") {
            toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setLoading(false);
            setToggleValue(!toggleModal);
            const responseData = data.data;
            const timezoneDate = momentUtil.parseTimeInUtc({
              dateTimeString: responseData?.date,
            });
            reset(responseData);
            setCurrentBid(responseData);
            setValue(
              "datetime",
              responseData.date
                ? momentUtil
                  .parseTimeInUtc({ dateTimeString: responseData?.date })
                  .format("MM/DD/YYYY hh:mm A")
                : ""
            );
            setValue("date", timezoneDate);
            setValue("customerId", responseData?.customerName);
            setValue("auctionCode", responseData?.auctionTitle);
            setValue("lotId", responseData?.lotTitle);
            setValue("bidderNumber", responseData?.bidderNumber);
            setValue("maxAmount", responseData?.max);
          }
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
      return;
    } else if (type === "history") {
      setOpenBidModal(true);
      // Use 7497 ID for testing
      axios
        .get(`${BIDHISTORY}?biddingManagementId=${biddingManagementId}`, config)
        .then((response) => {

          if (response?.data?.success === "Fail") {
            setLoading(false);
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setLoading(false);
            setBidHistory(response?.data?.data);
          }
        });
    }
    else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          fetch(`${DELETEBIDMANAGEMENT}?biddingManagementId=${biddingManagementId}`, {
            method: "POST",
            headers: {
              // Include any headers you may need
              // 'Authorization': 'Bearer your_token_here',
              'Content-Type': 'application/json', // Set Content-Type if necessary
            },
            body: JSON.stringify({}), // Empty body if required
          })
            .then((response) => response.json())
            .then((data) => {
              if (data?.success === "Fail") {
                setLoading(false);
                toast.error(data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                return;
              } else {
                setLoading(false);
                clearAdvanceFilterData(false, filterData);
                toast.success(AuctionBidMessages.bidDeleted, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              handleError(error);
            });
        }
      });
    }
  };



  //get auctions
  const getAllAuctions = () => {
    setLoading(true);
    axios.get(GETALLFUTUREAUCTION, config).then((response: any) => {
      setLoading(false);
      const auction = response?.data?.data ? response?.data?.data : [];
      const auctionData = auction.map((lData: any) => {
        return {
          label: `${lData.auctionCode} - ${lData.auctionTitle}`,
          value: lData.auctionCode,
        };
      });
      setAuctionData(auctionData);
    });
  };

  const getAllLots = (data: any) => {
    const url = `${ADVANCESEARCHINVENTORY}?auctionCode=${data}&pageNumber=-1`;
    axios
      .get(url, config)
      .then((response: any) => {
        const lotsData = response?.data?.data?.data;
        if (response.data.success === "Fail") {
          Swal.fire({
            title: "No lots available for the selected auction.",
            text: "Please select another auction.",
            icon: "warning",
            confirmButtonColor: "#006800",
            confirmButtonText: "Ok",
          });
          setLots([]);
        } else {
          const lotData = lotsData?.map((lData: any) => {
            const parsedTitle = parseHTML(lData.title);
            return {
              label: `${lData.lotNo} - ${parsedTitle}`,
              value: lData.lotNo,
            };
          });
          setLots(lotData);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

// get all bid
  const getAllBid = (pageSize = 10, pageNumber = 1, searchData = null) => {
    if (hammerAuctionId && hammerAuctionId?.length !== 0) {
      setShowFilter(true);
      handleAdvanceFilter(filterData, pageNumber);
      return;
    }
    setLoading(true);
    // Using the Fetch API instead of axios
    fetch(`${GETALLBIDMANAGEMENT}?pageNumber=${pageNumber}&pageSize=${paginationSize}`)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data?.success === "Fail") {
          setBidData([]);
          setFilterAuctionBid([]);
          setLoading(false);
          return;
        } else {
          const bidData = data?.data?.data;
          setBidData(bidData);
          paginateData(bidData, pageNumber || currentPage);
          setTotalRecords(data.data.totalRecords);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };


  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    setCurrentPage(newPageNumber);
  }

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };


  const loadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    try {
      const response: AxiosResponse = await axios.get(
        `${GETALLCUSTOMERS}?pageNumber=${page}`,
        config
      );
      const responseJSON: any = response?.data?.data;

      const getCustomers = responseJSON?.data?.filter((item: any) => {
        return item.customerId !== null &&
          item.suspendStatus !== true &&
          item.isActive === true;
      });

      const customerData = getCustomers?.map((lData: any) => {
        return {
          label: `${lData.customerId} - ${lData.firstName} - ${lData.lastName}`,
          value: lData.customerId,
        };
      });

      return {
        options: customerData,
        hasMore: response?.data?.data?.hasNextPage,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      setLoading(false);
      handleError(error);
      throw error; // Re-throw the error to handle it further if needed

    }
  };

  // get all bidder number
  const getBidderNumbers = (customerId: number) => {
    setLoading(true);
    axios
      .get(`${GETBIDDERSBYCUSTOMERID}?customerId=${customerId}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success === "Fail") {
          Swal.fire({
            title: "No Bidder Number available for the selected customer.",
            text: "Please select another auction.",
            icon: "warning",
            confirmButtonColor: "#006800",
            confirmButtonText: "Ok",
          });
          setBidderList([]);
        } else {
          const biddersData: any = response.data.data;
          const bidderData = biddersData?.map((lData: any) => {
            return {
              label: `${lData.bidderNumber}`,
              value: lData.bidderNumber,
            };
          });
          setBidderList(bidderData);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };



  const handleCustomerChange = (e: any) => {
    const selectedCustomerId = e?.value;
    setSelectedCustomer(selectedCustomerId);
    getBidderNumbers(selectedCustomerId);
    setValue("bidderNumber", "");
  };

  const handleAuctionChange = (e: any) => {
    const selectedAuctionCode: any = parseInt(e?.value);
    setSelectedAuction(selectedAuctionCode);
    setValue("auctionCode", e?.value);
    getAllLots(selectedAuctionCode);
  };

  useEffect(() => {
    setAlreadyFetchedDataPageNumber({})
    // clearSearchBar()
    if (hammerAuctionId) {
      const resetFilter = {
        lotNo: '',
        bidderNumber: '',
        auctionCode: "",
        customerName: "",
        keyword: '',
        auctionId: hammerAuctionId
      };
      filterReset(resetFilter)
      handleAdvanceFilter(resetFilter, 1)
      setFilterData(resetFilter)
      pageNumber !== 1 ? setPageNumber(1) : clearAdvanceFilterData(false, resetFilter)
    }
    else {
      clearAdvanceFilterData();
      setShowFilter(false);
    }


  }, [hammerAuctionId]);


  useEffect(() => {
    if (increments) {
      handleIncValueChange();
    }
  }, [increments, currentBid]);

  const handleIncValueChange = () => {
    const newAmount = Number(currentBid.amount);
    setBidPrice(newAmount + incValue);
    for (const range of increments) {
      if (newAmount >= range.startingFrom && newAmount <= range.toLessThan) {
        setIncValue(range.increment);
        setBidPrice(newAmount + range.increment);
        break;
      } else {
        setIncValue(range.increment);
        setBidPrice(newAmount + range.increment);
      }
    }
  };

  const handleAmountChange = (value: any) => {
    const enteredValue = Number(value);
    if (value >= 0) {
      setBidPrice(enteredValue);
    }

    if (Number(amount) > enteredValue) {
      setError(true);
      setErrorMsg("Maximum bid should be greater than or equal to current bid");
    } else {
      if (Number(enteredValue) % 1 != 0) {
        setError(true);
        setErrorMsg("Bid Value Should not be in decimal value.");
      } else if (Number(enteredValue) % incValue != 0) {
        setError(true);
        setErrorMsg(`Bid Value should be incremented by ${incValue}`);
      } else {
        setBidPrice(enteredValue);
        setError(false);
      }
    }
  };

  useEffect(() => {
    setBidderNumber(biddNumber);
    setValue("bidderNumber", biddNumber);
  }, [bidderList, biddNumber]);

  // this is for reset date in filter section.
  useEffect(() => {
    if (resetDate == false) {
      setTimeout(() => {
        setResetDate(true);
      }, 50);
    }
  }, [filterData]);

  const parseHTML = (htmlString: any) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");
    return parsedHTML.body.textContent || "";
  };

  return (
    <>
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <Modal show={toggleModal} size="lg" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            {mode === "create" ? "Add Bid Management" : "Edit Bid Management"}
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              handleClose();
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Place a bid on behalf of bidder"
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              {/* <div className="col-lg-12 position-relative content-hide-show mb-5 ps-4">
                <i className="fas fa-eye " onClick={toggleVisibility}></i>
                {isVisible && <p id="lotsText" className="lots-text position-absolute">Auction which have lots are visible here</p>}
              </div> */}
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Auction <sup className="text-danger">*</sup>
                </label>
                {mode === "edit" ? (
                  <input
                    type="text"
                    {...register("auctionCode")}
                    className="form-control"
                    disabled
                  />
                ) : (
                  <Select
                    classNamePrefix="dpaSelect"
                    {...register("auctionCode")}
                    placeholder="Select"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="auctionCode"
                    options={auctionData}
                    value={auctionData.find(
                      (option: any) => option.value === auctionCode
                    )}
                    isDisabled={currentBid?.biddingManagementId > 0}
                    onChange={(e: any) => {
                      handleAuctionChange(e);
                    }}
                  />
                )}
                {errors.auctionCode && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.auctionCode.message}
                  </span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Lot # {lots?.length > 0 ? <sup className="text-danger">*</sup> : ""}
                </label>
                {lots?.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select"
                    {...register("lotNo")}
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="z"
                    options={lots}
                    // value={lots.find((option: any) => option.value === lotNumber)}
                    isDisabled={currentBid?.biddingManagementId > 0}
                    onChange={(e: any) => {
                      setFromLotData({ ...fromLotData, lots: e.value });
                      setValue("lotNo", e?.value);
                    }}
                  />
                ) : mode === "edit" ? (
                  <input
                    type="text"
                    {...register("lotNo")}
                    className="form-control"
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      !selectedAuction
                        ? "Select an auction first"
                        : "No Lot number exist"
                    }
                    disabled
                  />
                )}
                {errors.lotNo && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.lotNo.message}
                  </span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Bidder Name <sup className="text-danger">*</sup>
                </label>

                {mode == "edit" ? (
                  <input
                    type="text"
                    {...register("customerId")}
                    className="form-control"
                    disabled
                  />
                ) : (
                  <AsyncPaginate
                    classNamePrefix="dpaSelect"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="customerId"
                    placeholder="Select"
                    isDisabled={currentBid?.biddingManagementId > 0}
                    loadOptions={loadOptions}
                    additional={{
                      page: 1,
                    }}
                    onChange={(e: any) => {
                      handleCustomerChange(e);
                      setValue("customerId", e?.value);
                    }}
                  />
                )}
                {errors.customerId && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.customerId.message}
                  </span>
                )}
              </div>

              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Bidder #
                  {bidderList?.length > 0 ? (
                    <sup className="text-danger">*</sup>
                  ) : (
                    ""
                  )}
                </label>

                {bidderList?.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="bidderNumber"
                    options={bidderList}
                    // value={bidderNumber ? { value: biddNumber, label: bidderNumber } : undefined}
                    isDisabled={currentBid?.biddingManagementId > 0}
                    onChange={(e: any) => {
                      setFromLotData({ ...fromLotData, bidderList: e.value });
                      setValue("bidderNumber", e.value);
                    }}
                  />
                ) : mode === "edit" ? (
                  <input
                    type="text"
                    {...register("bidderNumber")}
                    className="form-control"
                    disabled
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      !selectedCustomer
                        ? "Select a customer first"
                        : "No Bidder number exist"
                    }
                    disabled
                  />
                )}

                {errors.bidderNumber && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.bidderNumber.message}
                  </span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Amount <sup className="text-danger">*</sup>
                </label>
                <CurrencyInput
                  type="text"
                  placeholder="Amount"
                  {...register("amount")}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                  value={amount}
                  onValueChange={(value: any) => {
                    setValue("amount", value);
                    handleAmountChange(value);
                  }}
                  className="form-control form-icon-input"
                />
                {error && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errorMsg}
                  </span>
                )}
                {errors.amount && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.amount.message}
                  </span>
                )}
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">Mass Edit Max Bid Amount</label>
                <CurrencyInput
                  type="text"
                  placeholder="Mass Edit Max Bid Amount"
                  {...register("maxAmount")}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                  value={maxAmount}
                  onValueChange={(value: any) => {
                    setValue("maxAmount", value);
                  }}
                  className="form-control form-icon-input"
                />
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  Bid Date and Time ({defaultTimezone})
                  <sup className="text-danger">*</sup>
                </label>
                <div
                  tabIndex={0}
                  className="custom-border"
                  {...(currentBid?.biddingManagementId > 0 && {
                    style: {
                      pointerEvents: "none",
                    },
                  })}
                >
                  <div
                    className={`input-group-new rdt ${currentBid?.biddingManagementId > 0
                      ? "calendar-icon-bg-color"
                      : ""
                      }`}
                  >
                    <Controller
                      name="date"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="date_icon w-100">

                          <DateTime
                            {...register("date")}
                            onChange={onChange}
                            isValidDate={disablePastDt}
                            value={value ? new Date(bidDate) : currentDate}
                            dateFormat="MM/DD/y"
                            timeFormat="hh:mm A"
                            closeOnSelect={true}
                            inputProps={{
                              placeholder: "Select Date and Time",
                              disabled: currentBid?.biddingManagementId > 0,
                              className: "form-control remove-border ", // Add the form-control class for styling and remove-border class for removing the border
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>

                {errors.datetime && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors.datetime.message}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => {
                handleClose();
              }}
            >
              {CommonMessages.cancelButton}
            </button>
            <button className="btn btn-primary" type="submit">
              {mode != "create"
                ? CommonMessages.updateButton
                : CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={toggleMassModal} size="lg" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            Mass Edit Max Bid Amount
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setToggleMassModal(!toggleMassModal);
              setMaxValue(0);
            }}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">Old Max Amount</label>
                <input
                  type="text"
                  className="form-control form-icon-input"
                  placeholder="Old Max Amount"
                  value={formatCurrency(oldMaxValue)}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-md-6 mb-3">
                <label className="form-label">
                  New Max Amount <sup className="text-danger">*</sup>
                </label>
                <CurrencyInput
                  type="text"
                  placeholder="New Max Amount"
                  prefix="$"
                  onKeyDown={handleKeyDown}
                  value={maxValue}
                  onValueChange={(value: any) => {
                    setMaxValue(value);
                  }}
                  className="form-control form-icon-input"
                />
                {/* <input
                  type="number"
                  className="form-control form-icon-input"
                  placeholder="New Max Amount"
                  value={maxValue}
                  onChange={(e: any) => setMaxValue(e.target.value)}
                /> */}
                <span className="mt-2 mb-0" style={{ fontSize: "12px" }}>
                  <i>
                    <span className="text-danger">**</span>Note: New max value
                    should be greater than old max value.
                  </i>
                </span>
                {/* {oldMaxValue >= maxValue && maxValue <= 0 ? (

                 
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => {
                handleClose();
              }}
            >
              {CommonMessages.cancelButton}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleMaxBidSave}
              disabled={oldMaxValue >= maxValue && maxValue == 0}
            >
              {CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={openBidModal} size="xl" centered>
        <Modal.Header>
          <h5 className="modal-title" id="verticallyCenteredModalLabel">
            Bid History
          </h5>
          <button
            className="btn p-1"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenBidModal(!openBidModal)}
          >
            <span className="fas fa-times text-danger fs-20"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive mx-n1 px-1 scrollbar">
            <table className="table fs--1 mb-0 border-top border-200">
              <thead>
                <tr>
                  <th className="bg-gradiant" scope="col" data-sort="firstname">
                    <div className="d-flex align-items-center">
                      <span>Previous Amount</span>
                    </div>
                  </th>
                  <th className="bg-gradiant" scope="col" data-sort="firstname">
                    <div className="d-flex align-items-center">
                      <span>Current Amount</span>
                    </div>
                  </th>
                  <th className="bg-gradiant" scope="col" data-sort="firstname">
                    <div className="d-flex align-items-center">
                      <span>Previous Max Amount</span>
                    </div>
                  </th>
                  <th className="bg-gradiant" scope="col" data-sort="lastname">
                    <div className="d-flex align-items-center">
                      <span>Current Max Amount</span>
                    </div>
                  </th>
                  <th className="bg-gradiant" scope="col" data-sort="firstname">
                    <div className="d-flex align-items-center">
                      <span>Modified By</span>
                    </div>
                  </th>
                  <th className="bg-gradiant" scope="col" data-sort="firstname">
                    <div className="d-flex align-items-center">
                      <span>Created On</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="table-latest-review-body">
                {bidHistory.length > 0 ? (
                  bidHistory?.map((bid: any, index: any) => {
                    return (
                      <tr
                        className="hover-actions-trigger btn-reveal-trigger position-static"
                        key={index}
                      >
                        <td className="align-middle p-2">
                          {formatCurrency(bid?.previousAmount)}
                        </td>
                        <td className="align-middle p-2">
                          {formatCurrency(bid?.currentAmount)}
                        </td>
                        <td className="align-middle p-2">
                          {formatCurrency(bid.previousMaxAmount)}
                        </td>
                        <td className="align-middle p-2">
                          {formatCurrency(bid?.currentMaxAmount)}
                        </td>
                        <td className="align-middle p-2">{bid?.modifiedBy}</td>
                        <td className="align-middle p-2">{bid?.createdOn}</td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="text-center p-4" colSpan={7}>
                        No records found!
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-danger me-auto"
            data-bs-dismiss="modal"
            role="button"
            onClick={() => setOpenBidModal(!openBidModal)}
          >
            {CommonMessages.cancelButton}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Bid Management">
          <div className="search-box mt-2">
            <div className="d-flex align-items-center">

              {/*Commented this code for hiding search textbox and filter button as per task 3739*/}

              {/* <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  className="form-control search-input search form-control-sm"
                  type="text"
                  id="searchBar"
                  placeholder="Search"
                  aria-label="Search"
                  onChange={(e: any) => {
                    filterSetValue("keyword", e.target.value);
                    setFilterData({ ...filterData, keyword: e.target.value })
                    if (e.target.value) {
                      setAlreadyFetchedDataPageNumber({});
                      setCurrentPage(1);
                      debouncedRequest();
                    } else {
                      clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                      setFilterData({ ...filterData, keyword: '' })
                    }
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
                <button
                  type="button"
                  className="p-0 btn ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                    setEditMaxValue(true);
                    setDisplayEditBtn(false);
                    setBidIncrementIds([]);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </button>
              </div> */}
            </div>
          </div>

          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            {superAdminRole === "True" ? (
              <>
                {editMaxValue && (
                  <button
                    className="btn btn-outline-primary me-2 ps-3 pe-3"
                    type="button"
                    data-bs-dismiss="modal"
                    disabled={showFilter && editMaxValue && !displayEditbtn}
                    onClick={() => {
                      setToggleMassModal(!toggleMassModal);
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click here to edit multiple records to add max amount"
                  >
                    <span className="plus me-1">
                      <span
                        className="fa-solid fa-arrow-up-right-dots"
                        data-fa-transform="shrink-3"
                      ></span>
                    </span>
                    <span> Mass Edit Max Bid Amount</span>
                  </button>
                )}
                <button
                  className="btn btn-outline-primary ps-3 pe-3"
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setToggleValue(!toggleModal);
                    setMode("create");
                    reset(initialData);
                    getAllAuctions();
                  }}
                >
                  <span className="plus me-1">
                    <span
                      className="fas fa-plus"
                      data-fa-transform="shrink-3"
                    ></span>
                  </span>
                  <span> Add Bid Management</span>
                </button>
              </>
            ) : (
              ""
            )}
          </div>

          {/*Commented this code for hiding search textbox and filter button as per task 3739*/}
          {/* {showFilter && ( */}
          <div
            className={showFilter == true ? "" : "dropdown-advance-filter"}
          >
            <div className="card bg-transparent position-relative p-3 mt-2">
              <button
                className="btn p-1 p-a"
                type="button"
                onClick={() => {
                  setShowFilter(!showFilter);
                  setEditMaxValue(false);
                  filterReset(initialFilterData);
                  setCurrentPage(1);
                  setPageNumber(1);
                  setBidIncrementIds([]);
                }}
              >
                {/* <span className="fas fa-times text-danger fs-20"></span> */}
              </button>
              <div className="card-body p-0">
                <div className="text-start">
                  <form>
                    <div className="row g-3">
                      {/*Commented this code for hiding auction code textbox as per task 3739*/}
                      {/* <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Auction Code
                            <sup className="text-danger">*</sup>
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            maxLength={21}
                            {...filterRegister("auctionCode")}
                            name="auctionCode"
                            placeholder="Auction Code"
                            value={filterData.auctionCode}
                            onChange={(e) => {
                              changeHandler("auctionCode", e.target.value);
                              filterSetValue("auctionCode", e.target.value);
                            }}
                          />
                          {filterError.auctionCode && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterError.auctionCode}
                            </span>
                          )}
                        </div> */}
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="bootstrap-wizard-validation-gender"
                        >
                          Lot #
                        </label>
                        <input
                          className="form-control form-icon-input"
                          type="text"
                          {...filterRegister("displayLotNo")}
                          name="displayLotNo"
                          maxLength={21}
                          placeholder="Lot #"
                          value={filterData.displayLotNo}
                          onChange={(e) => {
                            changeHandler("displayLotNo", e.target.value);
                            filterSetValue("displayLotNo", e.target.value);
                          }}
                        />
                        {filterError.displayLotNo && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {filterError.displayLotNo}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="bootstrap-wizard-validation-gender"
                        >
                          Bidder Name
                        </label>
                        <input
                          className="form-control form-icon-input"
                          type="text"
                          {...filterRegister("customerName")}
                          name="customerName"
                          placeholder="Bidder Name"
                          value={filterData.customerName}
                          onChange={(e) => {
                            changeHandler("customerName", e.target.value);
                            filterSetValue("customerName", e.target.value);
                          }}
                        />
                        {filterError.customerName && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {filterError.customerName}
                          </span>
                        )}
                      </div>
                      <div className="col-lg-3">
                        <label
                          className="form-label"
                          htmlFor="bootstrap-wizard-validation-gender"
                        >
                          Bidder #
                        </label>
                        <input
                          className="form-control form-icon-input"
                          type="text"
                          {...filterRegister("bidderNumber")}
                          maxLength={21}
                          name="bidderNumber"
                          placeholder="Bidder #"
                          value={filterData.bidderNumber}
                          onChange={(e) => {
                            changeHandler("bidderNumber", e.target.value);
                            filterSetValue("bidderNumber", e.target.value);
                          }}
                        />
                        {filterError.bidderNumber && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "12px" }}
                          >
                            {filterError.bidderNumber}
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                  <div className="d-flex align-items-center ms-auto">
                    <div className="flex-1 text-end mt-3 ms-3">
                      <button
                        className="btn btn-outline-secondary mb-1 me-3"
                        type="submit"
                        onClick={() => { clearAdvanceFilterData(true) }}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-primary mb-1"
                        type="submit"
                        onClick={() => {
                          currentPage !== 1 && setCurrentPage(1);
                          setAlreadyFetchedDataPageNumber({});
                          setIsFiltered(true);
                          currentPage == 1 && handleAdvanceFilter(filterData, 1);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-4 border-300">
                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                  <div className="table-responsive mx-n1 px-1 scrollbar">
                    <table className="table fs--1 mb-0 border-top border-200">
                      <thead>
                        <tr>
                          <th className="bg-gradiant" scope="col">
                            Action
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Auction Code</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Lot #</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Bidder Name</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() =>
                                    handleSorting("ASC", "customerName")
                                  }
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() =>
                                    handleSorting("DESC", "customerName")
                                  }
                                ></button>
                              </div>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Bidder #</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="lastname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Category</span>
                              <div className="sorting_group">
                                <button
                                  className="arrow-up"
                                  onClick={() =>
                                    handleSorting("ASC", "strCategory")
                                  }
                                ></button>
                                <button
                                  className="arrow-down"
                                  onClick={() =>
                                    handleSorting("DESC", "strCategory")
                                  }
                                ></button>
                              </div>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Amount</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Max</span>
                            </div>
                          </th>
                          <th
                            className="bg-gradiant"
                            scope="col"
                            data-sort="firstname"
                          >
                            <div className="d-flex align-items-center">
                              <span>Bid Date/Time ({defaultTimezone})</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list" id="table-latest-review-body">
                        {filterAuctionBid?.length > 0 ? (
                          filterAuctionBid?.map((bidder: any, index: any) => {
                            const updatedTime = momentUtil?.parseTimeInUtc({
                              dateTimeString: bidder?.date,
                            });
                            return (
                              <tr
                                className="hover-actions-trigger btn-reveal-trigger position-static"
                                key={index}
                              >
                                <td className="align-middle white-space-nowrap text-start p-2">
                                  <Dropdown
                                    className={`font-sans-serif btn-reveal-trigger position-static`}
                                  >
                                    <Dropdown.Toggle
                                      className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                      variant="none"
                                    >
                                      <img
                                        src="/assets/img/ellipsis-solid.svg"
                                        style={{ width: "20px" }}
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      className={`dropdown-menu dropdown-menu-start py-2`}
                                    >
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                        onClick={() =>
                                          handleEditModal(
                                            bidder?.biddingManagementId,
                                            "edit"
                                          )
                                        }
                                      >
                                        Edit
                                        <i className="fas fa-pen dropdown-icon"></i>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                        onClick={() =>
                                          handleEditModal(
                                            bidder?.biddingManagementId,
                                            "delete"
                                          )
                                        }
                                      >
                                        Delete
                                        <i className="fas fa-trash dropdown-icon"></i>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as="button"
                                        className="dropdown-item d-flex align-items-center justify-content-between"
                                        onClick={() =>
                                          handleEditModal(
                                            bidder?.biddingManagementId,
                                            "history"
                                          )
                                        }
                                      >
                                        Bid History
                                        <i className="fa-solid fa-clock-rotate-left"></i>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                                <td className="align-middle p-2">
                                  {bidder.auctionCode}
                                </td>
                                <td className="align-middle p-2">
                                  {bidder.displayLotNo}
                                </td>
                                <td className="align-middle p-2">
                                  <Link
                                    className="btn text-decoration-underline m-0 p-0 fw-normal"
                                    data-bs-dismiss="modal"
                                    target="_blank"
                                    to={`${routeConstant.CUSTOMERSMOREINFO
                                      }/${encryptId(
                                        bidder?.customerId
                                      )}`}
                                  >
                                    {bidder.customerName}
                                  </Link>
                                </td>
                                <td className="align-middle p-2">
                                  {bidder?.bidderNumber > 0
                                    ? bidder?.bidderNumber
                                    : "-"}
                                </td>
                                <td className="align-middle p-2">
                                  {bidder.strCategory}
                                </td>
                                <td className="align-middle p-2">
                                  {formatCurrency(bidder?.amount)}
                                </td>
                                <td className="align-middle p-2">
                                  {formatCurrency(bidder?.max)}
                                </td>
                                <td className="align-middle text-start status p-2">
                                  {updatedTime.format("MM/DD/YYYY hh:mm A")}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                              <td className="text-center p-4" colSpan={9}>
                                No records found!
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {filterAuctionBid.length > 0 && (
                  <ReactPaginate
                    previousLabel={"←"}
                    nextLabel={"→"}
                    pageCount={Math.ceil(totalRecords / paginationSize)}
                    onPageChange={({ selected }) => handlePageClick(selected)}
                    containerClassName={"pagination"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"pagination__link--disabled"}
                    activeClassName={"pagination__link--active"}
                    forcePage={currentPage == 0 ? currentPage : currentPage - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuctionBidListing;
