import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import defaultLoader from "./assets/loader.gif";
interface AppProps {
  children: React.ReactNode;
}

const App: React.FC<AppProps> = ({ children }: AppProps) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    moment.suppressDeprecationWarnings = true;
    return () => { }
  }, [])


  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);

  return (
    <>
      {loading ? (
        <div className="loader-wrap">
          {localStorage.getItem('globalLoader') ? (
            <img src={`${localStorage.getItem('globalLoader')}`} />
          ) : (
            <img src={`${defaultLoader}`} />
          )}
        </div>
      ) : (
        <div>
          {children}
        </div>
      )}
    </>
  );
};

export default App;
