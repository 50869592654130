import React, { useEffect, useState } from "react";
import { CustomerCardList, cardBrandMapping } from "../../../common/constants";
import { decryptId } from "../../../Encryption/Encryption";
import { GETALLCARDSBYCUSTOMERID } from "../../../common/apiConstatnts";
import * as routeConstant from '../../../common/routeConstants';
import axios from "axios";
import noCardIcon from '../../../assets/noCardIcon.svg'
import { Link } from "react-router-dom";
import { setActiveTabCustomer } from "../../../store/actions/CustomerAction";
import { useDispatch } from "react-redux";
import { CommonMessages } from "../../../common/messageConstants";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const CreditCards = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const actualId = decryptId(ID)
    const [loading, setLoading] = useState<boolean>(false);
    const [ccList, setCcList] = useState<any[]>([]);
    const dispatch = useDispatch();

    const getCards = () => {
        setLoading(true);
        axios.get(`${GETALLCARDSBYCUSTOMERID}?customerId=${actualId}`).then((response) => {
            const card = response?.data?.data;
            const modifyCards = (card?.length > 0) ? card.map((cData: any) => {
                const cardData: any = cardBrandMapping.find((cmData: any) => {
                    return cmData.brand.toLocaleLowerCase() === cData.brand.toLocaleLowerCase();
                });
                return { ...cData, logoheights: cardData?.logoheights, cardLogos: cardData?.cardLogos, gradient: cardData?.gradient }
            }) : []
            setCcList(modifyCards);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        })
    };

    const handleNext = () => {
        dispatch(setActiveTabCustomer('uploadPhoto'));
    }
    const handlePrevious = () => {
        dispatch(setActiveTabCustomer('bidderNum'));
    }

    useEffect(() => {
        if (!ID) {
            setCcList(CustomerCardList);
            return
        }
        getCards()
    }, []);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <div className="col-lg-12">
                        <section className="pb-5 mb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 mb-1">
                                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                                            <h3 className="mt-3 mb-1 fw-normal">{ccList.length > 0 ? "Saved Cards" : ""}</h3>
                                        </div>
                                    </div>
                                    <div className="cc-list col-12 mt-4">
                                        <div className="row g-4">
                                            {(ccList.length > 0) ? ccList?.map((card: any, index: any) => {
                                                return (
                                                    <div className="col-md-6 col-lg-4 col-xxl-3" key={index}>
                                                        <div className={`cc-card card bg-gradient-${(!ID) ? index + 1 : card.gradient} overflow-hidden border-0`}>
                                                            <div
                                                                className="card-body p-0 d-flex flex-column">
                                                                <div className="px-4 pt-3 pb-2 flex-fill d-flex flex-column justify-content-between">
                                                                    <div>
                                                                        <img src={card.cardLogos} height={card.logoheights} />
                                                                    </div>
                                                                    <div className="cc-number fs-24 d-flex align-items-center gap-2">
                                                                        <span className="fs-12">
                                                                            &#11044;&#11044;&#11044;&#11044;
                                                                        </span>
                                                                        <span className="fs-12">
                                                                            &#11044;&#11044;&#11044;&#11044;
                                                                        </span>
                                                                        <span className="fs-12">
                                                                            &#11044;&#11044;&#11044;&#11044;
                                                                        </span>
                                                                        <span>{card?.lastFour}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="cc-lower px-4 pb-3 d-flex flex-column justify-content-end">
                                                                    <div className="text-uppercase">{card?.cardHolderName}</div>
                                                                    <div className="fs-14">
                                                                        {card.cardExpiry}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <>
                                                <div className="text-center border p-4 mt-2 mb-2 no-card">
                                                    <div className="no_card_icon">
                                                        <img src={noCardIcon} alt="No card" />
                                                    </div>
                                                    <p className="mt-5 fs-20">No card found</p>
                                                </div>
                                            </>}
                                            {/* add new card
                                            <div className="col-md-6 col-lg-4 col-xxl-3">
                                                <div className="cc-card card bg-border overflow-hidden border-0">
                                                    <a href="#" className="stretched-link"></a>
                                                    <div className="card-body d-flex align-items-center justify-content-center">
                                                        <img height="40" src={IconAddCC} />
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS}>{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipNext}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreditCards;