import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import parsePhoneNumberFromString, {
  parsePhoneNumber,
} from "libphonenumber-js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { onGetDefaultValues } from "../../../common/AddressForm";
import {
  CREATEBIDDERNUMBER,
  CREATECUSTOMER,
  GETCUSTOMERBYID,
  GETSELLER,
  GOTOBIDDERNUMBER,
  UPDATECUSTOMER,
} from "../../../common/apiConstatnts";
import { Breadcrumb } from "../../../common/interface";
import {
  CustomerMessages,
  errorMessages,
} from "../../../common/messageConstants";
import {
  COMPANYNAMEREGEX,
  EMAIL,
  EMAIL_MESSAGE,
  FIRSTANDLASTNAMEREGEX,
  INTEGER,
  SPECIALCHARS,
} from "../../../common/regexConstants";
import * as routeConstant from "../../../common/routeConstants";
import * as actions from "../../../common/types";
import { decryptId, encryptId } from "../../../Encryption/Encryption";
import {
  addBidderStatus,
  loader,
  setActiveTabCustomer,
  setAddressInfo,
  setBidderDocumentData,
  setContactInfo,
  setCustomerId,
  setCustomerIndex,
  setInterest,
  setNextCustomerId,
  setPrevCustomerId,
  setProfileInfo,
  setTotalCustomers,
} from "../../../store/actions/CustomerAction";
import MomentUtil from "../../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../../Utils/useDateTime";
import { ERROR_MESSAGES } from "../../../Utils/Utils";
import ContactInfo from "../../Customers/MoreInfo/ContactInfo";
import InnerHeaderTab from "../../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../../Sidebar/Sidebar";
import BidderDocuments from "./BidderDocuments";
import BidderNumber from "./BidderNumber";
import CreditCards from "./CreditCards";
import UplaodImage from "./UploadImage";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import AuctionTab from "./Auction";
import CommonHelmet from "../../../common/HelmetWrapper";
import Tippy from "@tippyjs/react";


const MoreInfo = () => {
  const query = window.location.href;
  const vars = query.split("/");
  const ID = vars[5];
  const customerLabel = ID === undefined ? "New Buyer" : "Edit Buyer";
  const mode = ID === undefined ? "create" : "edit";
  const breadcrumbData: Breadcrumb[] = [
    { label: "Home", url: routeConstant.DASHBOARD },
    { label: "Buyers", url: routeConstant.CUSTOMERS },
    { label: customerLabel, url: "" },
  ];
  const dispatch = useDispatch();
  const location = useLocation();
  const existsData = location.state;
  const timezone = localStorage.getItem("timeZoneId");

  const initalData = {
    customerId: 0,
    companyId: localStorage.getItem("companyId"),
    initialsId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    userName: "",
    auctionEmail: "",
    password: "",
    company: "",
    phone: "",
    mobile: "",
    fax: "",
    notes: "",
    age: "",
    dateOfBirth: "",
    sellerId: "",
    taxId: "",
    latitude: "",
    longitude: "",
    locations: "",
    latLog: "",
    driverLicense: "",
    location: "",
    accountCode: "",
    externalBidder: "",
    internalBidderId: "",
    lotsOfIntrest: "",
    taxExempt: false,
    reseller: false,
    taxFromOnFile: false,
    shippingOutOfState: false,
    buyBackBidderOnly: false,
    shippingInstate: false,
    customerTimeZone: "",
    // lstCustomerSuspendRequest: {},
    isActive: true,
    suspendStatus: false,
    suspendedReason: "",
    add1: "",
    add2: "",
    city1: "",
    state1: "",
    country1: "",
    zip: "",
    levelType: "1",
    county1: ''
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialFormValue, setFormValue] = useState<any>(initalData);
  // const [activeTab, setActiveTab] = useState<string>('contact');
  const [modifiedBy, setModifiedBy] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locations, setLocations] = useState("");
  const [latLog, setlatLog] = useState<any>(null);
  const [cnfError, setCnfError] = useState<any>(false);
  const [customerStatus, setCustomerStatus] = useState<any>(true);
  const [sellerList, setSellerList] = useState<any>([]);
  const [cId, setCId] = useState("");
  const customerId = useSelector(
    (state: any) => state.customer.contactInfo?.customerId
  );

  const customer = useSelector((state: any) => state.customer);
  const addressData = useSelector((state: any) => state.customer?.addressInfo);
  const activeTab = customer.activeTab;
  const loaderValue = useSelector((state: any) => state.customer.loader);
  const contactInfo = customer.contactInfo;
  const addressTypes = useSelector((state: any) => state.global.addressTypes);

  const nextCustomerIdFromStore = useSelector((state: any) => state?.customer?.nextCustomerId)
  const prevCustomerIdFromStore = useSelector((state: any) => state?.customer?.prevCustomerId)
  const totalCustomersFromStore = useSelector((state: any) => state?.customer?.totalCustomers)
  const indexFromStore = useSelector((state: any) => state?.customer?.customerIndex)

  //when redirected from Auction sellers tab from auction details set this auction.
  const AuctionDetail = useSelector((state: any) => state?.auction?.auctionCodeAndTitle);

  //hammer auction when selected any auction from hammer.
  const getGlobalAuctionIds = useSelector(
    (state: any) => state?.global?.auctionHammerData?.value
  );

  const auctionId = AuctionDetail?.auctionId || getGlobalAuctionIds
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("us");
  const [prevCountryCode, setPrevCountryCode] = useState("us");
  const [address, setAddress] = useState("");
  const [level, setLevel] = useState("");
  const [selectedSellers, setSelectedSellerList] = useState<any>([]);
  const dateTime = useCurrentDateTime();
  const momentUtil = MomentUtil.getInstance();

  const generatePhoneValidationSchema = () => {
    return yup
      .string()
      .nullable()
      .test("phone-validation", "Invalid phone number", (value) => {
        if (!value) return true;
        try {
          const phoneNumber = parsePhoneNumberFromString(`+${value}`);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return false; // Invalid phone number format
          }
          const nationalNumber = phoneNumber.nationalNumber;
          const countryCodeLength =
            phoneNumber.countryCallingCode.toString().length;
          const expectedLength = value.length - countryCodeLength;
          return nationalNumber.toString().length === expectedLength;
          return true; // Valid phone number
        } catch (error) {
          console.error("Error parsing phone number:", error);
          return false; // Return false if an error occurs during parsing
        }
      });
  };

  const schema: any = yup.object().shape({
    initialsId: yup.string(),
    firstName: yup
      .string()
      .required("First name is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(FIRSTANDLASTNAMEREGEX, "Please enter valid first name")
      .max(20, "Please enter upto 20 characters"),
    lastName: yup
      .string()
      .required("Last name is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(FIRSTANDLASTNAMEREGEX, "Please enter valid last name")
      .max(20, "Please enter upto 20 characters"),
    middleName: yup
      .string()
      .notRequired()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("alphanumeric-dot", "Please enter valid middle name", (value) => {
        return value ? FIRSTANDLASTNAMEREGEX.test(value) : true;
      }),
    accountCode: yup
      .string()
      .notRequired()
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? /^\S*$/g.test(value) : true;
        }
      )
      .matches(INTEGER, "Please enter valid account code")
      .max(10, "Please enter upto 10 characters"),
    company: yup
      .string()
      // .required('Company is required.')
      .nullable()
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      }),
    mobile: generatePhoneValidationSchema().required(
      "Phone number is required."
    ),
    phone: generatePhoneValidationSchema().nullable(),
    fax: yup
      .number()
      .nullable()
      .typeError("Fax must be a number")
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .nullable(),
    sellerId: yup
      .string()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      }),
    dateOfBirth: yup
      .string()
      .nullable()
      .test("valid-datetime", "Invalid datetime", function (value) {
        if (!value) {
          return true;
        }
        const parsedDate: any = new Date(value);
        // const currentDate: any = new Date();
        const currentDate: any = momentUtil.parseTimeInUtc({
          dateTimeString: dateTime?.currentDateTime,
        });

        if (parsedDate > currentDate) {
          return this.createError({
            message: "Future Date not allowed!",
          });
        }
        const ageDifferenceMilliseconds = currentDate - parsedDate;
        const ageDifferenceYears =
          ageDifferenceMilliseconds / (1000 * 60 * 60 * 24 * 365); // Convert milliseconds to years
        if (ageDifferenceYears < 18) {
          return this.createError({
            message: "Age must be 18 or older!",
          });
        }
        return true;
      }),
    userName: yup
      .string()
      .required("User Email is required")
      .matches(EMAIL, EMAIL_MESSAGE)
      .max(50, "Please enter upto 50 characters"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .max(20, "Please enter upto 20 characters")
      .test("no-blank-space", "Blank spaces are not allowed", (value) => {
        return value ? !/^\s/.test(value) : true;
      })
      .test("uppercase", "At least one uppercase letter is required", (value) =>
        /[A-Z]/.test(value)
      )
      .test("lowercase", "At least one lowercase letter is required", (value) =>
        /[a-z]/.test(value)
      )
      .test(
        "numbers",
        "At least one number is required",
        (value) => (value.match(/[0-9]/g) || []).length >= 1
      )
      .test(
        "specialCharacter",
        "At least one special character is required",
        (value) => /[!@#$%^&*()_+\-=\[\]{}|';:.<>?]/.test(value)
      ),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf(
        [yup.ref("password"), ""],
        "Password must match with confirm password"
      )
      .min(8, "Password must be at least 8 characters long")
      .max(20, "Please enter upto 20 characters"),
    suspendStatus: yup.boolean(),
    suspendedReason: yup.string().when("suspendStatus", {
      is: (value: any) => value === true,
      then: () =>
        yup
          .string()
          .test(
            "no-blank-space",
            ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
            (value) => {
              return value ? !/^\s*$/.test(value) : true;
            }
          )
          .required("Reason for suspend is required"),
      otherwise: () => yup.string().nullable(),
    }),
    add1: yup
      .string()
      .required("Address Line 1 is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(SPECIALCHARS, "Please enter valid Address"),
    country1: yup.string().required("Please select country"),
    state1: yup.string().required("Please select state"),
    city1: yup.string().required("Please select city"),
    zip: yup
      .string()
      .required("Zip code is required")
      .test("no-blank-space", "Blank spaces are not allowed.", (value) => {
        return value ? !/^\s/.test(value) : true;
      })
      .matches(
        /^[0-9a-zA-Z\s-]+$/,
        "Zip code must contain only numbers, alphabets, hyphens, and spaces."
      )
      .matches(
        /^(?!-)[\w\s-]*(?<!-)$/,
        "Hyphens are not allowed at the beginning or end of the zip code."
      )
      .min(3, "Zip code must be at least 3 characters.")
      .max(12, "Zip code cannot be longer than 12 characters."),
  });

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    reset,
    watch,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFormValue,
    mode: "onChange",
  });
  useEffect(() => {
    if (customerTimeZone == "" && timezone) {
      setValue("customerTimeZone", timezone);
    }
  }, [timezone]);
  const suspendStatus = watch("suspendStatus");
  const password = watch("password");
  const cnfpassword = watch("confirmPassword");
  const active = watch("isActive");
  const seller = watch("sellerId");
  const initialsId = watch("initialsId");
  const address1 = watch("add1");
  const customerTimeZone = watch("customerTimeZone");
  const suspendedReason = watch("suspendedReason");

  useEffect(() => {
    const cnfPassword = getValues("confirmPassword");
    const errorKeys = Object.keys(errors);
    if (
      cnfPassword !== "" &&
      password !== cnfPassword &&
      !errorKeys.includes("confirmPassword")
    ) {
      setCnfError(true);
      return;
    }
    setCnfError(false);
    clearErrors("confirmPassword");
  }, [password]);

  useEffect(() => {
    setCnfError(false);
  }, [cnfpassword]);

  const handleNext = () => {
    dispatch(setActiveTabCustomer("bidderNum"));
  };

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    // 04 / 10 / 2000
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    // const formattedDate = `${month}/${day}/${year}`;
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
      let URL: any;
      let Message: any;
      if (ID === undefined) {
        URL = CREATECUSTOMER;
        Message = CustomerMessages.customerCreated;
      } else {
        URL = UPDATECUSTOMER;
        Message = CustomerMessages.customerUpdated;
      }
      delete data.lstCustomerInterest;
      delete data.createdBy;
      delete data.createdOn;
      delete data.updatedBy;
      delete data.updatedOn;
      // return
      const commonPayload = {
        ...data,
        customerId: ID ? decryptId(ID) : 0,
        userName: data.userName,
        auctionEmail: data.userName,
        dateOfBirth: data?.dateOfBirth
          ? convertToDateOnlyFormat(data?.dateOfBirth)
          : null,
        initialsId: parseInt(data.initialsId),
        companyId: parseInt(data.companyId) || parseInt(contactInfo?.companyId),
        age: data?.age ? parseInt(data?.age) : 0,
        // sellerId: parseInt(data.sellerId),
        sellerId: selectedSellers?.join(","),
        taxId: data.taxId ? parseInt(data.taxId) : 0,
        fax: data?.fax?.toString(),
        mobile: data?.mobile?.toString(),
        phone: data?.phone?.toString() ?? "",
        internalBidderId: parseInt(data?.internalBidderId),
        latitude: data?.latitude?.toString(),
        longitude: data?.longitude?.toString(),
        isActive: data.isActive,
        customerTimeZone: parseInt(data?.customerTimeZone),
        suspendStatus: data.suspendStatus,
        suspendedReason: data.suspendedReason ? data.suspendedReason : "",
        level: data.levelType,
        // Added new params mode, Admin portal - 1 , Customer portal - 2
        mode: 1,
        customerAddressRequestModel: {
          customerAddressId: addressData[0]
            ? addressData[0].customerAddressId
            : 0,
          customerAddressType: addressTypes?.Billing?.customerAddressType,
          customerId: ID ? decryptId(ID) : 0,
          addressLine1: data.add1 || data?.lstCustomerAddress[0]?.addressLine1,
          addressLine2: data.address2,
          country: parseInt(countryId),
          state: parseInt(stateId),
          city: parseInt(cityId),
          county: parseInt(countyId),
          countyName: data?.county1,
          zipCode: data.zip,
          sameAsBillAddress: false,
          strCustomerAddressType: addressTypes?.Billing?.strCustomerAddressType,
          isActive: true,
        },
      };
      const payload =
        ID && data?.suspendStatus
          ? {
            ...commonPayload,
            lstCustomerSuspendRequest: {
              customersuspendId: 0,
              customerId: data.customerId,
              datetime: moment().format("YYYY-MM-DDTHH:mm"),
              suspendedReason: data.suspendedReason
                ? data.suspendedReason
                : "",
            },
          }
          : {
            ...commonPayload,
            lstCustomerSuspendRequest: {
              suspendedReason: "",
            },
          };

      //this is for trim all string type value in object
      Object.keys(payload).forEach(
        (k) =>
        (payload[k] =
          typeof payload[k] == "string" ? payload[k].trim() : payload[k])
      );
      axios
        .post(URL, payload, { ...config, ContentType: "application/json" })
        .then(
          (response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              toast.success(Message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });

              dispatch(
                setContactInfo({
                  ...payload,
                  dateOfBirth: data?.dateOfBirth
                    ? moment(data?.dateOfBirth).format("MM/DD/yyyy")
                    : null,
                  levelType: data?.levelType,
                })
              );
              if (ID === undefined) {
                setCId(response.data.data);
                navigate(`${encryptId(response.data.data)}`);
              }
            }
            handleNext();
            // if (mode !== 'edit') {
            //     navigate(routeConstant.CUSTOMERS);
            // }
          }).catch((error) => {
            dispatch(loader(false))
            handleError(error);
          });
    });
  };

  const getAllSellers = () => {
    dispatch(loader(true));
    axios.get(`${GETSELLER}?pageNumber=-1`, config).then(
      (response) => {
        dispatch(loader(false));
        if (response?.data?.data?.success === "Fail") {
          toast.error(response?.data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          const sellerData = response?.data?.data?.data;

          let formateSeller = sellerData?.map((seller: any, index: any) => {
            return {
              id: seller.sellerId,
              name: `${seller.sellerId}- ${seller.firstName} ${seller.lastName}`,
            };
          });
          setSellerList(formateSeller);
        }
      }).catch((error) => {
        dispatch(loader(false))
        handleError(error);
      });
  };

  useEffect(() => {
    setValue("add1", contactInfo?.lstCustomerAddress?.addressLine1);
    setAddress(contactInfo?.lstCustomerAddress?.addressLine1);
  }, [contactInfo]);

  useEffect(() => {
    getAllSellers();
  }, []);

  useEffect(() => {
    setValue("sellerId", seller);
  }, [sellerList, seller]);

  const handleChange = (key: string) => {
    if (key === "sales") {
      dispatch(setContactInfo(getValues()));
      dispatch(setActiveTabCustomer(key));
      return;
    }
    dispatch(setActiveTabCustomer(key));
  };

  useEffect(() => {
    setLoading(loaderValue);
  }, [loaderValue]);

  useEffect(() => {
    if (prevCountryCode !== countryCode) {
      setValue("mobile", "");
      setValue("phone", "");
    }
  }, [countryCode]);

  useEffect(() => {
    if (ID !== undefined) {
      setLoading(true);
      const url = AuctionDetail?.auctionId ? `${GETCUSTOMERBYID}?customerId=${decryptId(ID)}&AuctionId=${AuctionDetail?.auctionId}` :
        getGlobalAuctionIds ? `${GETCUSTOMERBYID}?customerId=${decryptId(ID)}&AuctionId=${getGlobalAuctionIds}` :
          `${GETCUSTOMERBYID}?customerId=${decryptId(ID)}`

      axios.get(url).then(
          (response: any) => {
            if (response?.data?.success === "Fail") {
              setLoading(false)
              toast.error(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            } else {
              const data = response.data.data;
              const profileData = {
                driverLicense: data.driverLicense,
                driverLicenseState: data.driverLicenseState,
                shippingAddress1: data.shippingAddress1,
                shippingAddress2: data.shippingAddress2,
                taxId: data.taxId || "",
                profilePicture: data.profilePicture,
              };
              setModifiedBy(response.data.data.modifiedBy);
              setLatitude(response.data.data.latitude);
              setLongitude(response.data.data.longitude);
              setLevel(data.level);
              const payload = {
                ...data,
                dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth) : "",
                confirmPassword: data.password,
                suspendStatus:
                  data.suspendStatus == null ? false : data?.suspendStatus,
                suspendedReason: data.suspendedReason
                  ? data?.suspendedReason
                  : "",
                customerTimeZone: data.customerTimeZone
                  ? data.customerTimeZone
                  : 66,
                phone: data.phone || "",
                mobile: data.mobile,
              };
              const code = payload.lstCustomerInterest.map((data: any) => {
                return {
                  customerInterestId: data.customerInterestId,
                  interestAreas: data.interestAreas,
                  isActive: true,
                  isDelete: false,
                };
              });
              const parsedNumber: any = parsePhoneNumber(`+${data?.mobile}`);
              const respCountryCode = parsedNumber
                ? parsedNumber.country?.toLowerCase()
                : null;
              setAddress(data?.lstCustomerAddres?.addressLine1);
              setCountryCode(respCountryCode);
              setPrevCountryCode(respCountryCode);
              dispatch(setContactInfo(payload));
              dispatch(setProfileInfo(profileData));
              dispatch(setInterest(code));
              dispatch(setBidderDocumentData(data?.lstBidderDocument));
              dispatch(setAddressInfo(data?.lstCustomerAddress));
              dispatch(setCustomerId(data?.customerId))
              dispatch(setPrevCustomerId(data?.prevCustomerId))
              dispatch(setNextCustomerId(data?.nextCustomerId))
              dispatch(setTotalCustomers(data?.totalCustomer))
              dispatch(setCustomerIndex(data?.index))
              setFormValue(payload);
              reset(payload);
              setTimeout(() => setLoading(false), 500)
            }
          }).catch((error) => {
            setLoading(false)
            handleError(error);
          });
    }

    return () => {
      dispatch({ type: actions.RESETCUSTOMER });
    };
  }, [ID]);

  const handlePrevLot = () => {
    dispatch(setActiveTabCustomer("contact"));
    if (prevCustomerIdFromStore) {
      dispatch(setCustomerId(prevCustomerIdFromStore))
      navigate(`${routeConstant.CUSTOMERSMOREINFO}/${encryptId(prevCustomerIdFromStore)}`, { state: { type: "edit" } });
    }
  };

  const handleNextLot = () => {
    dispatch(setActiveTabCustomer("contact"));
    if (nextCustomerIdFromStore) {
      dispatch(setCustomerId(nextCustomerIdFromStore))
      navigate(`${routeConstant.CUSTOMERSMOREINFO}/${encryptId(nextCustomerIdFromStore)}`, { state: { type: "edit" } });
    }
  };

  const handleCustomerPageChange = () => {
    setLoading(true)
    axios.get(`${GOTOBIDDERNUMBER}?bidderNumber=${inputValue}&auctionId=${auctionId}`).then((response) => {
      setLoading(false)
      if (response.data.success === 'Success') {
        const customerId = response?.data?.data?.customerId;
        setInputValue('')
        dispatch(setActiveTabCustomer("contact"));
        dispatch(setCustomerId(customerId))
        navigate(`${routeConstant.CUSTOMERSMOREINFO}/${encryptId(customerId)}`)
        setDropdownOpen(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }).catch((error) => {
      setLoading(false)
      handleError(error);
    });
  };

  const handleToggle = () => {
    let check: any = document.getElementById('type_true_0')
    if (check) {
      check.checked = true
    }
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
        <CommonHelmet title = {"Buyer - "} name = {"buyer"} content={"buyer" } />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        {/* <form
        onSubmit={handleSubmit(onSubmit)}
        > */}
        <InnerHeaderTab
          breadCrumb={breadcrumbData}
          title={mode === "create" ? "New Buyer" : ""}
        >
          {ID && (
            <div className="d-flex flex-wrap gap-2">
              <h4>Edit Buyer</h4>
              {contactInfo.customerId && contactInfo.customerId !== 0 ?
                <span>
                - ({contactInfo.customerId}) {contactInfo.firstName} {contactInfo.lastName}
                </span>
                :
                cId ?
                  <span>
                - ({cId}) {contactInfo.firstName} {contactInfo.lastName}
                  </span> : <></>
              }
            </div>
          )}
          {customerId > 0 && (
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
              {auctionId > 0 &&
                (
                  <div className="edit-inv-goto w-100 right-0">
                    <div className="next_and_pre_arrow d-flex flex-wrap justify-content-end">
                      <button
                        className="btn btn-outline-primary p-0 ms-auto"
                        onClick={handlePrevLot}
                        disabled={prevCustomerIdFromStore === 0}
                      >
                        <Tippy
                        content={"Previous Buyer"}
                          placement="bottom"
                          arrow={false}
                          offset={[0, 8]}
                        >
                          <i
                            className="fa-solid fa-arrow-left p-2"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </Tippy>
                      </button>
                      <button
                        className="btn btn-outline-primary p-0 ms-0"
                        onClick={handleNextLot}
                        disabled={nextCustomerIdFromStore === 0}
                      >
                        <Tippy
                        content={"Next Buyer"}
                          placement="bottom"
                          arrow={false}
                          offset={[0, 8]}
                        >
                          <i
                            className="fa-solid fa-arrow-right p-2"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </Tippy>
                      </button>
                      <Dropdown
                        className="custom-dropdown ms-0"
                        show={dropdownOpen} // Pass the state to control visibility
                        onToggle={handleToggle} // Handle toggle event
                      >
                        <Dropdown.Toggle
                          className="px-1 dropdown-caret-none"
                          id="dropdown-autoclose-true"
                          variant="none"
                        >
                          <span
                            className="menu-icon text-dark bg-200 hover-bg-300 d-flex align-items-center justify-content-center p-1 rounded-circle fs-0"
                            onClick={() => {
                              setInputValue("");
                            }}
                          >
                            <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant="none"
                          className="p-0 dropdown-menu-end navbar-dropdown-caret shadow border border-300"
                        >
                          <div className="card position-relative border-0 top_arrow">
                            <Dropdown.Header className="border-bottom">
                              Go to
                            </Dropdown.Header>
                            <div className="pt-2 ps-3 pe-3 pb-3">
                              {/* <div className="d-flex gap-3">
                                                        {radioKey.map((item: any, index: any) => {
                                                            return (
                                                                <div className="d-flex">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input me-2"
                                                                        name="radioKey"
                                                                        value={item.value}
                                                                        id={`type_true_${index}`}
                                                                        onClick={() => handleRadioChange(item.value)}
                                                                        defaultChecked={item.selected}
                                                                    />
                                                                    <label htmlFor={`type_true_${index}`}>
                                                                        {item.label}
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </div> */}
                              <div className="d-flex align-items-center mt-3">
                                <input
                                  className="form-control form-icon-input me-2"
                                  placeholder="Bidder #"
                                  name="inputValue"
                                  value={inputValue}
                                  onChange={(e: any) =>
                                    setInputValue(e.target.value)
                                  }
                                />
                                <button
                                  className="d-flex btn btn-primary ps-2 pe-2 align-items-center"
                                  onClick={handleCustomerPageChange}
                                  disabled={!inputValue}
                                >
                                  Go
                                  <i className="fa-solid fa-arrow-right mt-1 ms-1"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className="fs-12 fw-bold text-end me-2">
                      Buyer #{customerId} - Buyer Index <span>{indexFromStore}</span> out of
                        <span>{totalCustomersFromStore}</span>
                      </span>
                    </div>
                  </div>
                )}
            </div>
          )}
          <div className="d-flex justify-content-between">
            <div>
              <Tabs
                defaultActiveKey="contact"
                className="nav-underline"
                activeKey={activeTab}
                onSelect={(key: any) => handleChange(key)}
              >
                <Tab
                  eventKey="contact"
                  title="Contact Info"
                  disabled={customerId === undefined}
                ></Tab>
                {/* <Tab eventKey="address" title="Address Info" disabled={(customerId === undefined)}></Tab> */}
                <Tab
                  eventKey="bidderNum"
                  title="Bidder Number"
                  disabled={customerId === undefined}
                ></Tab>
                <Tab
                  eventKey="creditCards"
                  title="Saved Cards"
                  disabled={customerId === undefined}
                ></Tab>
                <Tab
                  eventKey="uploadPhoto"
                  title="Profile"
                  disabled={customerId === undefined}
                ></Tab>
                <Tab
                  eventKey="bidderDocs"
                  title="Bidder Documents"
                  disabled={customerId === undefined}
                ></Tab>
                {
                  ID &&
                  <Tab
                    eventKey="auctionTab"
                    title="Auctions"
                    disabled={customerId === undefined}
                  ></Tab>
                }
                {/* <Tab eventKey="sales" title="Interest Codes"></Tab> */}
              </Tabs>
            </div>
          </div>
        </InnerHeaderTab>
        {activeTab === "contact" ? (
          <ContactInfo
            register={register}
            control={control}
            errors={errors}
            trigger={trigger}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            setData={existsData}
            mode={mode}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setlatLog={setlatLog}
            setLocations={setLocations}
            locations={locations}
            latLog={latLog}
            longitude={longitude}
            latitude={latitude}
            isValid={isValid}
            initialFormValue={initialFormValue}
            modifiedBy={modifiedBy}
            suspendStatus={suspendStatus}
            cnfError={cnfError}
            active={active}
            watch={watch}
            sellerList={sellerList}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setPrevCountryCode={setPrevCountryCode}
            prevCountryCode={prevCountryCode}
            generatePhoneValidationSchema={generatePhoneValidationSchema}
            address1={address}
            initialsId={initialsId}
            seller={seller}
            customerTimeZone={customerTimeZone}
            suspendedReason={suspendedReason}
            level={level}
            setSelectedSellerList={setSelectedSellerList}
            onSubmit={handleSubmit(onSubmit)}
          />
        ) :
          activeTab === "bidderNum" ? (
            <BidderNumber />
          ) : activeTab === "creditCards" ? (
            <CreditCards />
          ) : activeTab === "uploadPhoto" ? (
            <UplaodImage />
          ) : activeTab === "bidderDocs" ? (
            <BidderDocuments />
          ) :
            activeTab === "auctionTab" ? (
              <AuctionTab />
            ) : (
              <></>
            )}
        {/* </form> */}
      </div>
    </>
  );
};
export default MoreInfo;
