import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { ADVANCEFILTERCUSTOMERS, DELETECUSTOMER, GETALLAUCTIONLIST, GETALLCUSTOMERS } from "../../common/apiConstatnts";
import { activationStatus, formatPhoneNumber, } from "../../common/constants";
import { Breadcrumb } from '../../common/interface';
import { CustomerMessages, } from "../../common/messageConstants";
import { EMAIL_MESSAGE, PHONEFILTER, PHONE_MESSAGEFILTER } from "../../common/regexConstants";
import * as routeConstant from '../../common/routeConstants';
import * as actions from '../../common/types';
import { encryptId } from "../../Encryption/Encryption";
import { setAddressInfo, setBidderDocumentData, setContactInfo, setInterest, setProfileInfo } from "../../store/actions/CustomerAction";
import DateTime from "../../Utils/DateTime";
import { handleKeyDown, trimObjectValues, useDebounce } from "../../Utils/Utils";
import InnerHeader from "../InnerHeader/InnerHeader";
import Sidebar from "../Sidebar/Sidebar";
import MomentUtil from "../../Utils/MomentUtil";
import handleError from "../../Utils/ApiCommonErrorMessages";
import CommonHelmet from "../../common/HelmetWrapper";
import Helper from "../../common/helpers";
import { setAuctionCodeAndTitle } from "../../store/actions/AuctionAction";

const breadcrumbData: Breadcrumb[] = [
  { label: 'Home', url: routeConstant.DASHBOARD },
  { label: 'Buyers', url: '' }
]

interface PageData {
  [pageNumber: number]: any[]; // Define the type of page data
}

const CustomerListing = () => {
  const getPermission: any = Helper.decodePermissions()?.find(
    (data: any) => data.moduleName === "Buyer"
  );
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const [permission] = useState<any>(getPermission);
  const [loading, setLoading] = useState(false);
  const [filterCustomers, setFilterCustomers] = useState<any>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [defaultDate, setDefaultDate] = useState({
    startDateTime: "",
    endDateTime: "",
  });
  const [resetDate, setResetDate] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] =
    useState<PageData>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const momentUtil = MomentUtil.getInstance();
  const PER_PAGE: any = process.env.REACT_APP_PAGESIZE;
  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);

  const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);
  const searchData = useSelector((state: any) => state.dashboard.searchData);
  const initialFilterData = {
    firstName: "",
    customerId: "",
    lastName: "",
    companyName: "",
    phoneNo: "",
    userName: "",
    isActive: -1,
    keyword: "",
    bidderNumber: "",
    startDateTime: searchData?.date ? searchData?.date : dateRangeData?.default ? "" : dateRangeData?.fromDate,
    endDateTime: searchData?.date ? searchData?.date : dateRangeData?.default ? "" : dateRangeData?.toDate,
    auctionId: hammerAuctionId ? hammerAuctionId : "",
  };
  // const [filterData, setFilterData] = useState<any>(initialFilterData);
  //    Schema defination for advance search filter

  const filterSchema: any = yup.object().shape({
    firstName: yup
      .string()
      .nullable()
      .test(
        "no-leading-space",
        "Leading spaces are not allowed",
        (value) =>
          value === null || value === undefined || !value.startsWith(" ")
      )
      .matches(/^[A-Za-z\s]*$/, "Only letters and spaces are allowed"),
    lastName: yup
      .string()
      .nullable()
      .test(
        "no-leading-space",
        "Leading spaces are not allowed",
        (value) =>
          value === null || value === undefined || !value.startsWith(" ")
      )
      .matches(/^[A-Za-z\s]*$/, "Only letters and spaces are allowed"),
    companyName: yup
      .string()
      .nullable()
      .test(
        "no-leading-space",
        "Leading spaces are not allowed",
        (value) =>
          value === null || value === undefined || !value.startsWith(" ")
      ),
    userName: yup
      .string()
      .nullable()
      .matches(/^\s*([\w\.-]+@[\w-]+(?:\.[\w-]+)*)?\s*$/, EMAIL_MESSAGE),
    phoneNo: yup.string().nullable().matches(PHONEFILTER, PHONE_MESSAGEFILTER),
    bidderNumber: yup.string(),
    startDateTime: yup
      .string()
      .nullable()
      .test(
        "no-date-text",
        "Date text is not allowed",
        (value) =>
          value === null ||
          value === undefined ||
          !/\d{4}-\d{2}-\d{2}/.test(value)
      ),
  });

  const {
    register: FilterRegister,
    handleSubmit: filterHandleSubmit,
    reset: filterReset,
    clearErrors: clearFilterError,
    setValue: filterSetValue,
    watch: filterWatch,
    getValues: filterGetValues,
    formState: {
      errors: filterErrors,
      isValid: filterIsValid,
      isDirty: filterIsDirty,
    },
  } = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues: initialFilterData,
    mode: "onChange",
  });

  const searchText2 = filterWatch("keyword");
  const filterData = filterGetValues()

  useEffect(() => {
    dispatch(setAuctionCodeAndTitle({}))
    setAlreadyFetchedDataPageNumber({})
    clearSearchBar()
    if (hammerAuctionId) {
      const resetFilter = {
        firstName: "",
        customerId: "",
        lastName: "",
        companyName: "",
        phoneNo: "",
        userName: "",
        isActive: -1,
        keyword: "",
        bidderNumber: "",
        startDateTime: "",
        endDateTime: "",
        auctionId: hammerAuctionId,
      };
      filterReset(resetFilter)
      setShowFilter(true);
      pageNumber !== 1 ? setPageNumber(1) : clearAdvanceFilterData(false, resetFilter)
    }
    else {
      if (searchData?.id || searchData?.name || searchData?.date) {
        setShowFilter(false);
        dispatch({ type: actions.RESETSEARCHDATA });
        return;
      }
      else {
        clearAdvanceFilterData();
        setShowFilter(false);
      }
    }

  }, [hammerAuctionId]);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const maxWordCount = 250;
  const [clearDateTime, setClearDateTime] = useState(false);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [auctionList, setAuctionList] = useState<any>([]);

  let filterActivationStatus = activationStatus?.map((data: any) => {
    return { label: data.label, value: data.value };
  });

  const handleSorting = (type: string, fieldName: string) => {
    let sortedData = [...filterCustomers];
    if (type === "ASC") {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData.sort((a: any, b: any) =>
          a[fieldName]?.localeCompare(b[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData?.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
      }
    } else {
      if (typeof sortedData[0][fieldName] === "string") {
        sortedData?.sort((a: any, b: any) =>
          b[fieldName]?.localeCompare(a[fieldName])
        );
      } else if (typeof sortedData[0][fieldName] === "number") {
        sortedData?.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
      }
    }
    paginateData(sortedData,pageNumber);
  };

  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPageNumber(newPageNumber);
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum;
    updateData(page, data);
    setFilterCustomers(data);
  };

  const getAllCustomers = (pageNum: number = 1) => {
    if (hammerAuctionId) {
      setShowFilter(true);
      handleAdvanceFilter({
        auctionId: filterData?.auctionId,
        startDateTime: "",
        endDateTime: "",
        auctiondate: "",
        isActive: -1,
        pageNumber: pageNumber == 0 ? pageNumber + 1 : pageNumber,
      }, pageNumber);
      return;
    }
    setLoading(true);
    axios.get(`${GETALLCUSTOMERS}?pageNumber=${pageNum}&pageSize=${PER_PAGE}`, config).then(
      (response) => {
        if (response?.data?.data?.success === "Fail") {
          toast.error(response?.data?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
          setFilterCustomers([]);
          return;
        } else {
          const customersData = response?.data?.data?.data;
          setTotalRecords(response?.data?.data?.totalRecords);
          paginateData(customersData, pageNum);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });;
  };

  const handleAddNewCustomer = () => {
    if (!permission.isAllowAdd) {
      Helper.unAuthorized();
      return// Optionally, you can prevent navigation by setting it to "#" or some other fallback.
    }
    else {
      navigate(`${routeConstant.CUSTOMERSMOREINFO}`);
      dispatch(setContactInfo({}));
      dispatch(setProfileInfo({}));
      dispatch(setInterest([]));
      dispatch(setBidderDocumentData([]));
      dispatch(setAddressInfo([]));
    }
  }
  const clearAdvanceFilterData = (isLocalClear: boolean = true, fdata: any = null) => {
    // setActive(false)
    setAlreadyFetchedDataPageNumber({});
    pageNumber !== 1 && setPageNumber(1);
    filterReset(initialFilterData);
    setClearDateTime(true);
    if (fdata == null) {
      setIsFiltered(false);
      const resetFilter = {
        firstName: "",
        lastName: "",
        userName: "",
        companyName: "",
        bidderNumber: "",
        phoneNo: "",
        isActive: -1,
        startDateTime: null,
        endDateTime: null,
        keyword: "",
        customerId: "",
        auctionId: "",
      }
      handleAdvanceFilter(resetFilter, 1);
      filterReset(resetFilter)
      clearSearchBar()
    }
    if (!isLocalClear) {
      filterReset(fdata)
      handleAdvanceFilter(fdata);
    }
    dispatch({ type: actions.RESETSEARCHDATA });
    dispatch({ type: actions.RESETDATERANGE });
    setResetDate(false);
    setDefaultDate({ startDateTime: "", endDateTime: "" });
  };

  const clearSearchBar = () => {
    const searchbar: any = document.getElementById('searchBar')
    searchbar.value = ''
  }

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const changeHandler = (fieldName: string, value: any) => {
    let formattedValue = value;
    if (fieldName === "startDateTime" || fieldName === "endDateTime") {
      const isValidDate = !isNaN(new Date(value).getTime());
      if (value !== "" && !isValidDate) {
        setStartDateError(
          "Please enter a valid date in the format MM/DD/YYYY."
        );
        return;
      } else {
        setStartDateError("");
      }
      formattedValue = value ? convertToDateOnlyFormat(value) : '';
    }
    const updateData = { ...filterData, [fieldName]: formattedValue };
    filterReset(updateData)
  };

  const handleEdit = (customerId: string) => {
    if (!permission?.isAllowEdit) {
      Helper.unAuthorized();
      return// Optionally, you can prevent navigation by setting it to "#" or some other fallback.
    } else {
      navigate(`${routeConstant.CUSTOMERSMOREINFO}/${encryptId(customerId)}`);
    }
  }

  const handleDeleteModal = (customerId: string, type: string) => {
    if (!permission.isAllowDelete) {
      Helper.unAuthorized();
      return;
    }
    else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#006800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          axios
            .post(`${DELETECUSTOMER}?customerId=${customerId}`, {}, config)
            .then(
              (response) => {
                setLoading(false);
                toast.success(
                  CustomerMessages.indexPageMessages.customerDeleted,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  }
                );
                // getAllCustomers();
                clearAdvanceFilterData(false, filterData);
              }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
        }
      });
    }
  };

  const handleAdvanceFilter = (fdata: any, pageNum: number = 1) => {
    let data: any = trimObjectValues(fdata);
    setLoading(true);
    axios
      .get(ADVANCEFILTERCUSTOMERS, {
        ...config,
        params: {
          customerId: data?.customerId,
          startDateTime: data.startDateTime ? `${data.startDateTime} 00:00:00` : "",
          endDateTime: data.endDateTime ? `${data.endDateTime} 23:59:59` : "",
          firstName: data?.firstName,
          lastName: data?.lastName,
          userName: data?.userName,
          companyName: data?.companyName,
          phoneNo: data?.phoneNo,
          isActive: data?.isActive?.value ?? -1,
          pageNumber: pageNum,
          auctionId: data?.auctionId ?? "",
          keyword: data?.keyword,
          bidderNumber: data?.bidderNumber,
          pageSize: PER_PAGE
        },
      })
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            // toast.error(response?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
            setFilterCustomers([]);
            return;
          } else {
            const customersData = response.data.data.data;
            setTotalRecords(response.data.data.totalRecords);
            paginateData(customersData, pageNum);
            setPageNumber(pageNum)
          }
        }).catch((error) => {
          setLoading(false);
          handleError(error);
        });;
    return;
  };

  const dateRangehandler = () => {
    setDefaultDate({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
    });
    handleAdvanceFilter({
      startDateTime: dateRangeData.fromDate,
      endDateTime: dateRangeData.toDate,
      isActive: -1,
      pageNumber: pageNumber == 0 ? pageNumber + 1 : pageNumber,
    });
    setPageNumber(1)
  };

  const searchHandler = () => {
    handleAdvanceFilter({
      firstName: searchData?.name,
      customerId: searchData?.id,
      startDateTime: searchData?.date,
      endDateTime: searchData?.date,
      isActive: -1,
      pageNumber: pageNumber == 0 ? pageNumber + 1 : pageNumber,
    });
    setPageNumber(1)
  };


  const getAllAuctions = () => {
    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      let auctionData = []

      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
          };
        });

      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    });
  };

  useEffect(() => {
    //when redirected from dashboard with global search.
    if (searchData?.id || searchData?.name || searchData?.date) {
      searchHandler();
      dispatch({ type: actions.RESETSEARCHDATA });
      return;
    }
    //when global search applied
    if (filterData?.keyword) {
      //this prevents dual API call
      if (pageNumber == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterCustomers(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      handleAdvanceFilter({ ...filterData }, pageNumber);
      return;
    }
    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (pageNumber == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterCustomers(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      handleAdvanceFilter(filterData, pageNumber);
      return;
    }
    //when redirected from dashboard with countbox click.
    else if (dateRangeData.default == false || defaultDate?.startDateTime) {
      dateRangehandler();
      return;
    }
    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(pageNumber)) {
        setFilterCustomers(alreadyFetchedDataPageNumber[pageNumber]);
        return;
      }
      getAllCustomers(pageNumber);
    }
  }, [pageNumber]);


  //debounce function for search functionality.
  const debouncedRequest = useDebounce(() => {
    handleAdvanceFilter(filterData);
  });

  // this is for reset date in filter section.
  useEffect(() => {
    if (resetDate == false) {
      setTimeout(() => {
        setResetDate(true);
      }, 50);
    }
  }, [filterData]);

  useEffect(() => {
    getAllAuctions();
  }, [])

  return (
    <>
      <CommonHelmet title={"Buyer - "} name={"buyer"} content={"Buyer"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}
      <Sidebar />
      <div className="content pt-0">
        <InnerHeader breadCrumb={breadcrumbData} title="Buyers">
          <div className="search-box mt-2">
            <div className="d-flex align-items-center">
              <div
                className="position-relative d-flex align-items-center"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  id='searchBar'
                  className="form-control search-input search form-control-sm"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  // value={searchText2}
                  onChange={(e: any) => {
                    filterSetValue("keyword", e.target.value);
                    if (e.target.value) {
                      setAlreadyFetchedDataPageNumber({});
                      pageNumber !== 1 && setPageNumber(1);
                      debouncedRequest()
                    } else {
                      clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                    }
                  }}
                />
                <span className="fas fa-search search-box-icon"></span>
                <button
                  type="button"
                  className="p-0 btn ms-3"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter fs-16"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto mt-2 d-flex flex-wrap align-items-center">
            <button
              className="btn btn-outline-primary"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleAddNewCustomer}
            >
              <span className="plus me-2">
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3"
                ></span>{" "}
              </span>{" "}
              <span> Add New Buyer</span>
            </button>
          </div>
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter"}
            >
              <div className="card bg-transparent position-relative p-3 mt-2">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <form noValidate>
                  <div className="card-body p-0">
                    <div className="text-start">
                      <div className="row g-3">
                        <div className="col-lg-3">
                          <label className="form-label">
                            {" "}
                            Select Auction
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction"
                            className="isActive swap-to-lot-menu clone-inventory"
                            options={auctionList}
                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                            value={auctionList.find(
                              (option: any) =>
                                option.value == filterData?.auctionId
                            )}
                            onChange={(e: any) => {
                              changeHandler("auctionId", e?.value);
                              filterSetValue("auctionId", e?.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Buyer Id
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...FilterRegister("customerId")}
                            placeholder="Buyer Id"
                          />
                          {filterErrors.customerId && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.customerId.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Bidder #
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...FilterRegister("bidderNumber")}
                            placeholder="Bidder #"
                          />
                          {filterErrors.bidderNumber && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.bidderNumber.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            First Name
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            placeholder="First Name"
                            {...FilterRegister("firstName")}
                          />
                          {filterErrors.firstName && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.firstName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Last Name
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...FilterRegister("lastName")}
                            placeholder="Last Name"
                          />
                          {filterErrors.lastName && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.lastName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Email
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            {...FilterRegister("userName")}
                            placeholder="Email"
                          />
                          {filterErrors.userName && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.userName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Company Name
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            placeholder="Company Name"
                            {...FilterRegister("companyName")}
                          />
                          {filterErrors.companyName && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.companyName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Phone Number
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            maxLength={13}
                            {...FilterRegister("phoneNo")}
                            placeholder="Phone Number"
                            onKeyDown={handleKeyDown}
                          />
                          {filterErrors.phoneNo && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {filterErrors.phoneNo.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Activation Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            className="isActive"
                            {...FilterRegister("isActive")}
                            name="isActive"
                            options={filterActivationStatus}
                            value={filterData.isActive}
                            onChange={(e: any) => {
                              changeHandler("isActive", e);
                              filterSetValue("isActive", e);
                            }}
                          />

                          {/* <Select classNamePrefix="dpaSelect"
                              placeholder="Select"
                              className='isActive'
                              {...FilterRegister('isActive')}
                              name='isActive'
                              options={filterActivationStatus}
                              value={filterData.isActive}
                              onChange={(e) => changeHandler("isActive", e)}
                          /> */}
                        </div>
                        {resetDate ? (
                          <div className="col-lg-3">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              From Created Date
                            </label>
                            <div className="date_icon">
                              <DateTime
                                inputProps={{
                                  className:
                                    "form-control form-icon-input only_date_icon",
                                  name: "startDateTime",
                                  placeholder: "From Created Date",
                                }}
                                {...FilterRegister("startDateTime")}
                                dateFormat="MM/DD/y"
                                closeOnSelect={true}
                                timeFormat={false}
                                value={filterData.startDateTime}
                                onChange={(value) =>
                                  changeHandler("startDateTime", value)
                                }
                                key={
                                  clearDateTime
                                    ? "clearedStartDateTime"
                                    : "startDateTime"
                                }
                              />
                              {startDateError && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {startDateError}
                                </span>
                              )}
                              {filterErrors.startDateTime && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: "12px" }}
                                >
                                  {filterErrors.startDateTime.message}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {resetDate ? (
                          <div className="col-lg-3">
                            <label
                              className="form-label"
                              htmlFor="bootstrap-wizard-validation-gender"
                            >
                              To Created Date
                            </label>
                            <div className="date_icon">
                              <DateTime
                                inputProps={{
                                  className:
                                    "form-control form-icon-input only_date_icon",
                                  name: "endDateTime",
                                  placeholder: "To Created Date",
                                }}
                                {...FilterRegister("endDateTime")}
                                dateFormat="MM/DD/y"
                                closeOnSelect={true}
                                timeFormat={false}
                                value={filterData.endDateTime}
                                onChange={(value) =>
                                  changeHandler("endDateTime", value)
                                }
                                key={
                                  clearDateTime
                                    ? "clearedStartDateTime"
                                    : "endDateTime"
                                }
                              />
                            </div>
                            {endDateError && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {endDateError}
                              </span>
                            )}
                            {filterErrors.endDateTime && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "12px" }}
                              >
                                {filterErrors.endDateTime.message}
                              </span>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex align-items-center ms-auto">
                        <div className="flex-1 text-end mt-3 ms-3">
                          <span
                            className="btn btn-outline-secondary mb-1 me-3"
                            onClick={() => { clearAdvanceFilterData(true) }}
                          >
                            Clear
                          </span>
                          <span
                            className="btn btn-primary mb-1"
                            onClick={() => {
                              setAlreadyFetchedDataPageNumber({});
                              pageNumber !== 1 && setPageNumber(1);
                              setIsFiltered(true);
                              const fdata = filterGetValues()
                              pageNumber == 1 && handleAdvanceFilter(fdata, 1);
                            }}
                          >
                            Apply
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </InnerHeader>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-5 border-300">
                <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-5 border-300">
                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                          <div className="table-responsive mx-n1 px-1">
                            <table className="table fs--1 mb-0 border-top border-200">
                              <thead>
                                <tr>
                                  <th className="bg-gradiant" scope="col">
                                    Action
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="company"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Buyer Id</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>First Name</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "firstName")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "firstName")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Last Name</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "lastName")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "lastName")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Email</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "lastName")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "lastName")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>
                                        Created Date
                                        {/* ({defaultTimezone}) */}
                                      </span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="lastname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Company</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "company")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "company")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Phone No</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant"
                                    scope="col"
                                    data-sort="company"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Activation Status</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                className="list"
                                id="table-latest-review-body"
                              >
                                {filterCustomers.length > 0 ? (
                                  filterCustomers?.map(
                                    (customers: any, index: any) => {
                                      const createdDate = moment(
                                        momentUtil.parseTimeInUtc({
                                          dateTimeString: customers?.createdOn,
                                        })
                                      );
                                      return (
                                        <tr
                                          className="hover-actions-trigger btn-reveal-trigger position-static"
                                          key={index}
                                        >
                                          <td className="align-middle white-space-nowrap text-start p-2">
                                            <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                              <Dropdown.Toggle
                                                className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                                variant="none"
                                              >
                                                <img
                                                  src="/assets/img/ellipsis-solid.svg"
                                                  style={{ width: "20px" }}
                                                />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu
                                                className={`dropdown-menu dropdown-menu-start py-2`}
                                              >
                                                <Dropdown.Item
                                                  as="button"
                                                  className="dropdown-item d-flex align-items-center justify-content-between"
                                                  onClick={() =>
                                                    handleEdit(
                                                      customers.customerId
                                                    )
                                                  }>

                                                  Edit
                                                  <i className="fas fa-pen dropdown-icon"></i>
                                                </Dropdown.Item>
                                                {/* </Link> */}
                                                <Dropdown.Item
                                                  as="button"
                                                  className="dropdown-item d-flex align-items-center justify-content-between"
                                                  onClick={() =>
                                                    handleDeleteModal(

                                                      customers.customerId,
                                                      "delete"
                                                    )
                                                  }
                                                >
                                                  Delete{" "}
                                                  <i className="fas fa-trash dropdown-icon"></i>{" "}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </td>

                                          {/* <td className="align-middle p-2">{customers.customerId ?? '-'}</td> */}
                                          <td className="align-middle  p-2">
                                            <Link
                                              className="btn text-decoration-underline m-0 p-0 fw-normal"
                                              data-bs-dismiss="modal"
                                              target="_blank"
                                              to={`${routeConstant.CUSTOMERSMOREINFO
                                                }/${encryptId(
                                                  customers.customerId
                                                )}`}
                                            >
                                              {customers.customerId}
                                            </Link>
                                          </td>
                                          <td className="align-middle p-2">
                                            {customers.firstName ?? "-"}
                                          </td>
                                          <td className="align-middle p-2">
                                            {customers.lastName ?? "-"}
                                          </td>
                                          <td className="align-middle p-2">
                                            {customers.userName ?? "-"}
                                          </td>
                                          <td className="align-middle p-2">
                                            {createdDate.format("MM/DD/YYYY")}
                                          </td>
                                          <td className="align-middle p-2">
                                            {customers.company ?? "-"}
                                          </td>
                                          <td className="align-middle p-2">
                                            {customers.mobile
                                              ? formatPhoneNumber(
                                                customers.mobile
                                              )
                                              : "-"}
                                          </td>
                                          <td className="align-middle text-start status p-2">
                                            {customers.isActive == true ? (
                                              <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                <span className="badge-label">
                                                  Active
                                                </span>
                                              </span>
                                            ) : (
                                              <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                <span className="badge-label">
                                                  Inactive
                                                </span>
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                    <td className="text-center p-4" colSpan={9}>
                                      No records found!
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {filterCustomers.length > 0 && (
                    <ReactPaginate
                      pageCount={Math.ceil(totalRecords / PER_PAGE)}
                      previousLabel={"←"}
                      nextLabel={"→"}
                      onPageChange={({ selected }) => handlePageClick(selected)}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CustomerListing;
