import * as action from '../../common/types'
import { Action } from '../../common/constants'

export const setContactInfo = (data: any): Action => ({
    type: action.CUSTOMERCONTACTINFO,
    payload: data
})
export const setAddressInfo = (data: any): Action => ({
    type: action.CUSTOMERADDRESSINFO,
    payload: data
})
export const setProfileInfo = (data: any): Action => ({
    type: action.CUSTOMERPROFILEINFO,
    payload: data
})
export const setInterest = (data: any): Action => ({
    type: action.CUSTOMERINTEREST,
    payload: data
})

export const setActiveTabCustomer= (key: any) => ({
    type: action.SETACTIVETABCUSTOMER,
    payload: key,
});

export const loader = (data: boolean): Action => ({
    type: action.CUSTOMERLOADER,
    payload: data
})

export const addBidderStatus = (data: boolean): Action => ({
    type: action.ADDBIDDERSTATUS,
    payload: data
})

export const setBidderDocumentData = (data: any): Action => ({
    type: action.SETBIDDERDOCUMENT,
    payload: data
})

export const setCustomerId = (data: any): Action => ({
    type: action.SETCUSTOMERID,
    payload: data
})

export const setPrevCustomerId = (data: any): Action => ({
    type: action.SETPREVCUSTOMERID,
    payload: data
})

export const setNextCustomerId = (data: any): Action => ({
    type: action.SETNEXTCUSTOMERID,
    payload: data
})

export const setTotalCustomers = (data: any): Action => ({
    type: action.TOTALCUSTOMERS,
    payload: data
})

export const setCustomerIndex = (data: any): Action => ({
    type: action.CUSTOMERINDEX,
    payload: data
})