import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as yup from "yup";
import { ERROR_MESSAGES, trimObjectValues } from "../../../../Utils/Utils";
import { GETALLEMAILTEMPLATE, GETSELLERREPORT, GETSELLERREPORTBYAUCTIONID, UPDATEAUCTIONSELLERREPORT } from "../../../../common/apiConstatnts";
import { wordCounter } from "../../../../common/constants";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../../common/messageConstants";
import { AlPHANUMERIC, EMAIL, EMAIL_MESSAGE, SPECIALCHARS } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionInvoiceSettingActive, setCloneTab, setCreateTab, setStep } from "../../../../store/actions/AuctionAction";
import { loader } from "../../../../store/actions/CommonAction";
import { SELLERREPORTEMAILSETTING } from "../../AuctionConstants";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../../../Utils/CustomCkEditor";

const SellerReport = () => {
    const auctionId = useSelector((state: any) => state.auction.auctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const createTabs = useSelector((state: any) => state.auction.createTabs)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const mode = useSelector((state: any) => state.auction.mode)

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };

    const initialData = {
        companyId: localStorage.getItem('companyId'),
        isActive: true,
        auctionSellerReportId: '',
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
        invoiceEmailTemplate: '',
        sellerEmailTemplate: '',
        ccAddress: '',
        cc2Address: '',
        correspondance: '',
        sellerSubjectLine: '',
        sellerBodyText: '',
        sellerSummaryFooter: '',
    }

    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState<any>(initialData);
    const [isSeller, setIsSeller] = useState<boolean>(false)
    const [sellerReport, setSellerReport] = useState<any>({})
    const [activeTab, setActiveTab] = useState<string>("")
    const [bodyTemplateData, setBodyTemplateData] = useState<any[]>([])
    const [footerTemplateData, setFooterTemplateData] = useState<any[]>([])
    const [bodyTemplateDD, setBodyTemplateDD] = useState<any[]>([])
    const [footerTemplateDD, setFooterTemplateDD] = useState<any[]>([])
    const [bodyTemplateValue, setBodyTemplateValue] = useState<any>("")
    const [footerTemplateValue, setFooterTemplateValue] = useState<any>("")
    const [btnTxt, setBtnTxt] = useState<any>("")
    const [isBodyError, setIsBodyError] = useState<boolean>(false)
    const [isFooterError, setIsFooterError] = useState<boolean>(false)
    const maxWordCount = 100
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [remainingCount2, setRemainingCount2] = useState(maxWordCount)

    const schema: any = yup.object().shape({
        invoiceEmailTemplate: yup.string().required('Please select invoice email template').test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        sellerEmailTemplate: yup.string().required('Please select seller email template').test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        ccAddress: yup.string().required("CC address is required field").matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        cc2Address: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        correspondance: yup.string().matches(EMAIL, EMAIL_MESSAGE).test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        sellerBodyText: yup.string().matches(AlPHANUMERIC, "Please enter valid Text").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        sellerSubjectLine: yup.string().matches(SPECIALCHARS, "Please enter valid Text").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).max(49, 'Please enter upto 50 characters'),
        sellerSummaryFooter: yup.string().matches(AlPHANUMERIC, "Please enter valid Text").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: "onChange" });

    const selectedBodyEmailTemp = watch('invoiceEmailTemplate')
    const selectedFooterEmailTemp = watch('sellerEmailTemplate')

    const handlePrev = () => {
        dispatch(setAuctionInvoiceSettingActive({ activeStep: 1, activeLabel: "Auto Invoicing" }))
    }

    const handleNext = () => {
        dispatch(setStep(5))
    }

    const handleChange = (key: string) => {
        setActiveTab(key)
    }

    const onSubmit = (dataOriginal: any) => {
        if (bodyTemplateValue === "") {
            setIsBodyError(true)
            return
        }
        if (footerTemplateValue === "") {
            setIsFooterError(true)
            return
        }
        const data = trimObjectValues(dataOriginal)
        const payload = {
            auctionSellerReportId: data?.auctionSellerReportId ? parseInt(data.auctionSellerReportId) : 0,
            auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
            invoiceEmailTemplate: data.invoiceEmailTemplate,
            sellerEmailTemplate: data.sellerEmailTemplate,
            invoiceEmailTemplateValue: bodyTemplateValue,
            sellerEmailTemplateValue: footerTemplateValue,
            ccAddress: data.ccAddress,
            cc2Address: data.cc2Address,
            correspondance: data.correspondance,
            sellerSubjectLine: data.sellerSubjectLine,
            sellerBodyText: data.sellerBodyText,
            sellerSummaryFooter: data.sellerSummaryFooter,
            isActive: true,
            isDelete: false
        }
        dispatch(loader(true))
        axios.post(UPDATEAUCTIONSELLERREPORT, payload, config)
            .then((response) => {
                dispatch(loader(false));
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                }
                else {
                    if (mode === "clone") {
                        dispatch(setCloneTab([...clonedTabs, SELLERREPORTEMAILSETTING]))
                    }
                    if (mode === "create") {
                        dispatch(setCreateTab([...createTabs, SELLERREPORTEMAILSETTING]))
                    }
                    toast.success(AuctionMessages.sellerReportMessages.sellerReportUpdated, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    })
                }
                handleNext()
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            })
    }

    useEffect(() => {
        if (selectedBodyEmailTemp) {
            const tempValue = bodyTemplateData.find((data) => data.value == selectedBodyEmailTemp)?.template
            if (tempValue) {
                if (activeTab === 'bodyEmailTemplate') {
                    setBodyTemplateValue(tempValue)
                }
                setBodyTemplateValue(tempValue)
            }
            return
        }
    }, [selectedBodyEmailTemp, bodyTemplateData])

    useEffect(() => {
        if (selectedFooterEmailTemp) {
            const tempValue = footerTemplateData.find((data) => data.value == selectedFooterEmailTemp)?.template
            if (tempValue) {
                if (activeTab === 'footerEmailTemplate') {
                    setFooterTemplateValue(tempValue)
                }
                setFooterTemplateValue(tempValue)
            }
            return
        }
    }, [selectedFooterEmailTemp, footerTemplateData])

    useEffect(() => {
        if (isSeller) {
            dispatch(loader(true))
            axios.get(`${GETALLEMAILTEMPLATE}?emailType=auctionSellerBody`).then((response) => {
                dispatch(loader(false));
                if (response?.data?.success === "Fail") {
                    return;
                }
                else {
                    const bodData = response?.data?.data;
                    const ddData = bodData?.map((inv: any) => { return { value: inv.emailTemplateId, label: inv.subjectLine } });
                    const allData = bodData?.map((inv: any) => { return { value: inv.emailTemplateId, template: inv.emailValue } });
                    setBodyTemplateDD(ddData);
                    setBodyTemplateData(allData);
                    setValue('invoiceEmailTemplate', sellerReport.invoiceEmailTemplate ?? "");
                    setBodyTemplateValue(sellerReport.invoiceEmailTemplateValue ?? "");
                    setActiveTab("bodyEmailTemplate");
                }
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            })

            axios.get(`${GETALLEMAILTEMPLATE}?emailType=auctionSellerFooter`).then((response) => {
                dispatch(loader(false));
                if (response?.data?.success === "Fail") {
                    return;
                }
                else {
                    const fotData = response?.data?.data
                    const ddData = fotData?.map((sel: any) => { return { value: sel.emailTemplateId, label: sel.subjectLine } })
                    const allData = fotData?.map((sel: any) => { return { value: sel.emailTemplateId, template: sel.emailValue } })
                    setFooterTemplateDD(ddData)
                    setFooterTemplateData(allData)
                    setValue('sellerEmailTemplate', sellerReport.sellerEmailTemplate ?? "")
                    setFooterTemplateValue(sellerReport.sellerEmailTemplateValue ?? "")
                }
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            })

            if (Object.keys(sellerReport).length === 0) {
                axios.get(`${GETSELLERREPORT}`, config).then((response) => {
                    dispatch(loader(false));
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        reset(response.data.data);
                    }
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error)
                })
                return
            }
            reset(sellerReport)
        }
    }, [isSeller])

    useEffect(() => {
        dispatch(loader(true));
        const aId = clonedTabs.includes(SELLERREPORTEMAILSETTING) ? cloneAuctionId : auctionId
        axios.get(`${GETSELLERREPORTBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                setIsSeller(true);
                dispatch(loader(false));
                return;
            }
            else {
                dispatch(loader(false));
                let respData = response.data.data
                if (!clonedTabs.includes(SELLERREPORTEMAILSETTING) && cloneAuctionId > 0) {
                    respData = { ...respData, auctionSellerReportId: 0 }
                }
                setSellerReport(respData)
                setBodyTemplateValue(respData?.invoiceEmailTemplateValue)
                setFooterTemplateValue(respData?.sellerEmailTemplateValue)
                setIsSeller(true)
            }
        }, (error) => {
            setIsSeller(true)
            dispatch(loader(false))
            if (error?.response?.status !== 401) {
                toast.error("Some error occurred!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }, [])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (auctionId > 0 && createTabs.includes(SELLERREPORTEMAILSETTING)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneAuctionId > 0 && clonedTabs.includes(SELLERREPORTEMAILSETTING)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])


    const onBodyChange = (value: any) => {
        setIsBodyError(false)
        setBodyTemplateValue(value)
    }

    const onFooterChange = (value: any) => {
        setIsFooterError(false)
        setFooterTemplateValue(value)
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mt-4 mb-3">
                    <div className="row g-3">
                        <div className="col-12">
                            <h4 className="">Seller Report Email Setting </h4>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-between mb-2">
                                <label className="form-label">
                                    Email Template <sup className="text-danger">*</sup>{" "}
                                </label>
                                <span className=" border border-dark-subtle rounded_border">Body</span>
                            </div>
                            <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select Email Template"
                                options={bodyTemplateDD}
                                {...register('invoiceEmailTemplate')}
                                value={bodyTemplateDD?.find((option: any) => option?.value == selectedBodyEmailTemp)}
                                onChange={(e: any) => setValue('invoiceEmailTemplate', e.value)}
                                className='isActive'
                            />

                            {errors?.invoiceEmailTemplate && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.invoiceEmailTemplate.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-between mb-2">
                                <label className="form-label">
                                    Email Template <sup className="text-danger">*</sup>{" "}
                                </label>
                                <span className="border border-dark-subtle rounded_border">Footer</span>
                            </div>
                            <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select Email Template"
                                options={footerTemplateDD}
                                {...register('sellerEmailTemplate')}
                                value={footerTemplateDD?.find((option: any) => option?.value == selectedFooterEmailTemp)}
                                onChange={(e: any) => setValue('sellerEmailTemplate', e.value)}
                                className='isActive'
                            />

                            {errors?.sellerEmailTemplate && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.sellerEmailTemplate.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-12">
                            <Tabs
                                defaultActiveKey="bodyEmailTemplate"
                                onSelect={(key: any) => handleChange(key)}
                                id="uncontrolled-tab-example"
                                className="mb-3 nav-underline border-bottom"
                            >
                                <Tab eventKey="bodyEmailTemplate" title="Body Email Template">

                                    <Tabs
                                        defaultActiveKey="source"
                                        className="mb-3 nav-underline border-bottom"
                                    >
                                        <Tab eventKey="source" title="Source">
                                            <label className="form-check-label">Template Content <sup className="text-danger">*</sup></label>
                                            <CustomCkEditor
                                                data={bodyTemplateValue ?? ''}
                                                onTextChanged={onBodyChange} />
                                        </Tab>
                                        <Tab eventKey="preview" title="Preview">
                                            <div className="card box-shadow">
                                                <div className="card-body ck_editor">
                                                    {bodyTemplateValue ? parse(bodyTemplateValue) : ''}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>
                                <Tab eventKey="footerEmailTemplate" title="Footer Email Template">
                                    <Tabs
                                        defaultActiveKey="source"
                                        className="mb-3 nav-underline border-bottom"
                                    >
                                        <Tab eventKey="source" title="Source">
                                            <label className="form-check-label">Template Content <sup className="text-danger">*</sup></label>
                                            <CustomCkEditor
                                                data={footerTemplateValue ?? ''}
                                                onTextChanged={onFooterChange} />
                                        </Tab>
                                        <Tab eventKey="preview" title="Preview">
                                            <div className="card box-shadow">
                                                <div className="card-body ck_editor">
                                                    {footerTemplateValue ? parse(footerTemplateValue) : ''}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </Tab>
                            </Tabs>


                            {isBodyError && <span className='text-danger' style={{ fontSize: "12px" }}>{"Body email template is required."}</span>}
                            {isFooterError && <span className='text-danger' style={{ fontSize: "12px" }}>{"Footer email template is required."}</span>}
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="text">CC  Address 1<sup className="text-danger">*</sup></label>
                            <input
                                className="form-control form-icon-input"
                                type="text"
                                maxLength={50}
                                placeholder=""
                                {...register("ccAddress")}
                            />
                            {errors?.ccAddress && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.ccAddress.message}
                                </span>
                            )}
                            <div>
                                <label className="form-label mt-2">(Email address to CC Seller Summary - Optional)</label>
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <label className="form-label" htmlFor="text">CC Address 2</label>
                            <input
                                className="form-control form-icon-input"
                                type="text"
                                maxLength={50}
                                placeholder=""
                                {...register("cc2Address")}
                            />
                            {errors?.cc2Address && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.cc2Address.message}
                                </span>
                            )}
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label" htmlFor="text">Correspondence Address </label>
                            <input
                                className="form-control form-icon-input"
                                type="text"
                                maxLength={50}
                                placeholder=""
                                {...register("correspondance")}
                            />
                            {errors?.correspondance && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.correspondance.message}
                                </span>
                            )}
                            <div>
                                <label className="form-label mt-2">(Reply to Email Address for Seller Summary )</label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label" htmlFor="text">
                                Seller Summary Subject Line
                            </label>
                            <input
                                className="form-control form-icon-input"
                                type="text"
                                maxLength={50}
                                placeholder=""
                                {...register('sellerSubjectLine')} />
                            {errors?.sellerSubjectLine && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.sellerSubjectLine.message}
                                </span>
                            )}
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label" htmlFor="text">
                                Seller Summary Body Text
                            </label>
                            <textarea
                                className="form-control form-icon-input"
                                placeholder=""
                                maxLength={maxWordCount}
                                rows={2}
                                {...register("sellerBodyText", { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                            ></textarea>
                            {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                            </div>}
                            {errors?.sellerBodyText && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.sellerBodyText.message}
                                </span>
                            )}
                            <div>
                                <label className="form-label mt-2">(Text which wil appear at the start of every Seller Summary : plain text only)</label>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <label className="form-label" htmlFor="text">
                                Seller Summary Footer Text
                            </label>
                            <textarea
                                className="form-control form-icon-input"
                                placeholder=""
                                maxLength={maxWordCount}
                                rows={2}
                                {...register("sellerSummaryFooter", { onChange: (e) => setRemainingCount2(wordCounter(e.target.value, maxWordCount)) })}
                            ></textarea>
                            {remainingCount2 != maxWordCount && <div className={remainingCount2 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                {remainingCount2 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount2}`}
                            </div>}
                            {errors?.sellerSummaryFooter && (
                                <span
                                    className="text-danger"
                                    style={{ fontSize: "12px" }}
                                >
                                    {errors?.sellerSummaryFooter.message}
                                </span>
                            )}
                            <div>
                                <label className="form-label mt-2">(Text which wil appear at the start of every Seller Summary : plain text only)</label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                    <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrev}>{CommonMessages.previousButton}</button>
                    <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>{CommonMessages.cancelButton}</Link>
                    <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
                    <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                </div>
            </form>
        </>
    )
}

export default SellerReport;