import axios from "axios";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import { GETSELLERBYAUCTIONWISELOT } from "../../../../common/apiConstatnts";
import { CommonMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { decryptId, encryptId } from "../../../../Encryption/Encryption";
import { sellerLots, setActiveTab, setAuctionInfoActiveTab } from "../../../../store/actions/Seller";
import { formatCurrency, formatNumber, removeHtmlTags } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";

const InventoryAssociated = () => {
    const dispatch = useDispatch()

    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const [loading, setLoading] = useState(false);
    const SellerLots = useSelector((state: any) => state.seller.sellerLots);
    const auctionlistOptions = useSelector((state: any) => state.seller.sellerAuctions);
    const selectedAuction = useSelector((state: any) => state.seller.selectedAuction);
    const getGlobalAuctionIds = useSelector((state: any) => state?.global?.auctionHammerData?.value);
    const selectedAuctionId = selectedAuction.value;
    const [lotsData, setLotsData] = useState<any>(SellerLots);
    const [OriginalLotsData, setOriginalLotsData] = useState<any>(SellerLots);
    const [filterLots, setFilterLots] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const PER_PAGE = 10;
    const pageCount = Math.ceil(lotsData?.length / PER_PAGE);

    const paginateData = (data: any) => {
        setLotsData(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterLots(firstSet)
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('contactInfo'));
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('sales'))
    }


    const getLotsByAuctionId = (auctionId: any) => {
        setLoading(true);
        axios.get(`${GETSELLERBYAUCTIONWISELOT}?sellerId=${decryptId(ID)}&auctionId=${auctionId}`).then((response) => {
            
            if (response?.data?.data?.success === "Fail") {
                setLoading(false);
                toast.error(response?.data?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const lotsData = response?.data?.data
                dispatch(sellerLots(lotsData))
                setLotsData(lotsData)
                setOriginalLotsData(lotsData)
                setLoading(false);
            }
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }
    useEffect(() => {
        // if (selectedAuctionId !== getGlobalAuctionIds) {
        //     getLotsByAuctionId(selectedAuctionId);
        // } else
         if (selectedAuctionId) {
            getLotsByAuctionId(selectedAuctionId)
        } else if (getGlobalAuctionIds) {
            getLotsByAuctionId(getGlobalAuctionIds)
        }
    }, [selectedAuctionId, getGlobalAuctionIds])

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        const filterData: any = OriginalLotsData?.filter((x: any) =>
            x.title?.toLocaleLowerCase()?.includes(keyword) ||
            x.sellerfirstName?.toLocaleLowerCase()?.includes(keyword) ||
            x.sellerlastName?.toLocaleLowerCase()?.includes(keyword) ||
            x.statusnew?.toLocaleLowerCase()?.includes(keyword) ||
            x.lotNo?.toString()?.includes(keyword) ||
            x.auctionId?.toString()?.includes(keyword)
        )
        paginateData(filterData)
        setCurrentPage(0)
    }
    const handleSorting = (type: string, fieldName: string) => {
        let inventory
        if (type === 'ASC') {
            if (fieldName == 'lotNo') {
                inventory = lotsData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);

            } else {
                inventory = lotsData.sort((a: any, b: any) =>
                    (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
                )
            }
        } else {
            if (fieldName == 'lotNo') {
                inventory = lotsData.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
            } else {
                inventory = lotsData.sort((a: any, b: any) =>
                    (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
                )
            }
        }
        paginateData(inventory);
    }
    const generateExcelWithTitle = () => {
        let data = lotsData?.map((data: any) => {
            return {
                lotNo: data.lotNo,
                title: removeHtmlTags(data.title),
                reserve: data?.reserve ? data?.reserve : '-',
                listLow: data.listLow,
                listHigh: data.listHigh,
                location: data?.location?.replaceAll(',', '').trim().length === 0 ? "-" : data?.location,
                exempt: data?.taxExempt ? 'Yes' : 'No',
                taxRate: data.taxRate ? data.taxRate : '0',
                status: data?.statusnew
            }
        })
        const wb = XLSX.utils.book_new();
        const wsData = [
            [`Seller Name:${lotsData[0].sellerfirstName} ${lotsData[0].sellerlastName}`],
            [`Auction Name: ${lotsData[0].auctionTitle}`],
            [`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`],
            [],
            ['Lot #', 'Title', 'Reserve', 'List-Low', 'List-High', 'location', 'Tax Exempt', 'Tax Rate', 'Status'],
            ...data.map((item: any) => Object.values(item))
        ];
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Seller_Lots.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };

    const exportPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4');
        doc.setFont("helvetica", "bold").setFontSize(14).text(`Seller Name: ${lotsData[0].sellerfirstName} ${lotsData[0].sellerlastName}`, 15, 10);
        doc.text(`Auction Name: ${lotsData[0].auctionTitle}`, 15, 20);
        doc.setFontSize(11).text(`Printed On: ${moment().format('MM/DD/YYYY hh:mm A')}`, 15, 30);
        const head = [['Lot #', 'Title', 'Reserve', 'List-Low', 'List-High', 'Location', 'Tax Exempt', 'Tax Rate', 'Status']]
        const data = lotsData.map((data: any) => {
            return {
                lotNo: data.lotNo,
                title: removeHtmlTags(data?.title),
                reserve: data?.reserve ? data?.reserve : '-',
                listLow: data.listLow,
                listHigh: data.listHigh,
                location: data?.location?.replaceAll(',', '').trim().length === 0 ? "-" : data?.location,
                exempt: data?.taxExempt ? 'Yes' : 'No',
                taxRate: data.taxRate ? data.taxRate : '0',
                status: data?.statusnew
            }
        })
        const newr = data.map((i: any) => {
            return Object.values(i)
        })

        autoTable(doc, {
            startY: 35,
            headStyles: { fillColor: '#176707' },
            head: head,
            body: newr,
            didDrawCell: (data) => { },
        });

        doc.save(`Seller:${lotsData[0].sellerId}-${lotsData[0].sellerfirstName}_${lotsData[0].sellerlastName}-Lots.pdf`);
    }


    useEffect(() => {
        const offset = currentPage * PER_PAGE;
        const nextSet = lotsData?.slice(offset, offset + PER_PAGE);
        setFilterLots(nextSet);
    }, [currentPage]);

    useEffect(() => {
        paginateData(lotsData);
    }, [lotsData]);

    useEffect(() => {
        handleSearch(searchText);
    }, [searchText]);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center">
                    <div className="col-xxl-10">
                        <div className="mt-2 mb-4 border-300">
                            <div className="row">
                                <div className="col-12 mt-3 d-flex flex-column justify-content-start">
                                    <h4>Inventory</h4>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <div className="search-box mt-2">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                                    <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                                    <span className="fas fa-search search-box-icon"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <>
                                            <div className="d-flex gap-2">
                                                <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={generateExcelWithTitle} disabled={filterLots && filterLots?.length === 0}>
                                                    <i className="fas fa-download"></i>
                                                    <span>Excel</span>
                                                </button>
                                                <button className="btn hover-bg-100 border border-primary text-primary d-flex align-items-center gap-3" onClick={() => exportPDF()} disabled={filterLots?.length === 0}>
                                                    <i className="fas fa-download"></i>
                                                    <span>PDF</span>
                                                </button>
                                            </div>
                                        </>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-5 border-300">
                                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                                            <div className="table-responsive mx-n1 px-1">
                                                <table className="table fs--1 mb-0 border-top border-200">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Lot #</span>
                                                                    <div className="sorting_group">
                                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'lotNo')} ></button>
                                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'lotNo')}></button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            {/* <th className="bg-gradiant " scope="col" data-sort="Lots">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Display Lot #</span>
                                                                </div>
                                                            </th> */}
                                                            <th className="bg-gradiant w-25" scope="col" data-sort="Ctrl">
                                                                <div className='d-flex align-items-center '>
                                                                    <span>Title</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="firstname">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Reserve</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Title">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>List-Low</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant" scope="col" data-sort="Title">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>List-High</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant w-25" scope="col" data-sort="Status">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Location</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Status">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Tax Exempt</span>
                                                                </div>
                                                            </th>

                                                            <th className="bg-gradiant " scope="col" data-sort="Status">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Tax Rate</span>
                                                                </div>
                                                            </th>
                                                            <th className="bg-gradiant " scope="col" data-sort="Status">
                                                                <div className='d-flex align-items-center'>
                                                                    <span>Status</span>
                                                                </div>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="list scrollbar" id="table-latest-review-body">
                                                        {filterLots?.length > 0 ?
                                                            filterLots?.map((lot: any, index: number) => {
                                                                return (
                                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                                        {/* <td className="p-2">
                                                                            {lot.displayLotNo ? lot.displayLotNo : lot.lotNo}
                                                                        </td> */}
                                                                        <td className="p-2">
                                                                            <Link
                                                                                className="btn text-decoration-underline m-0 p-0 fw-normal"
                                                                                data-bs-dismiss="modal"
                                                                                target="_blank"
                                                                                to={`${routeConstant.UPDATEINVENTORY
                                                                                    }/${encryptId(lot?.lotId)}`}
                                                                            >
                                                                                {lot?.displayLotNo ? lot?.displayLotNo : lot?.lotNo}
                                                                            </Link>
                                                                        </td>
                                                                        {/* <td className="p-2">{lot.displayLotNo ?? "-"}</td> */}
                                                                        <td className="p-2 w-25">{lot.title ? removeHtmlTags(lot.title) : "-"}</td>
                                                                        <td className="p-2">{lot?.reserve ? formatCurrency(lot?.reserve) : "-"}</td>
                                                                        <td className="p-2">{formatNumber(lot?.listLow) ?? "-"}</td>
                                                                        <td className="p-2">{formatNumber(lot?.listHigh) ?? "-"}</td>
                                                                        <td className="p-2 w-25">{lot?.location?.replaceAll(',', '').trim().length ? lot?.location : "-"}</td>
                                                                        <td className="align-middle text-start status p-2">
                                                                            <span className={`badge badge-dpa fs--2 ${lot?.taxExempt ? 'badge-dpa-success' : 'badge-dpa-danger'}`}>
                                                                                <span className="badge-label">{lot?.taxExempt ? 'Yes' : 'No'}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td className="align-middle p-2">{lot?.taxRate ?? "0"}</td>
                                                                        <td className="align-middle p-2"> <span className={`badge badge-dpa status-${lot?.statusnew.replace(/ /g, '').toLowerCase()}`}>{lot?.statusnew}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            ) : (
                                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                                                    <td className='text-center p-4' colSpan={9}>No records found!</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {filterLots?.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={"←"}
                                                nextLabel={"→"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"pagination__link"}
                                                nextLinkClassName={"pagination__link"}
                                                disabledClassName={"pagination__link--disabled"}
                                                activeClassName={"pagination__link--active"}
                                                forcePage={currentPage}
                                            />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                        <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                        <button className="btn btn-primary ms-2" type="button" onClick={handleNext} disabled={!selectedAuctionId}>{CommonMessages.nextButton}</button>
                        <button className="btn btn-secondary mx-2" type="button" onClick={handleNext} data-wizard-next-btn="data-wizard-next-btn">{CommonMessages.skipButton}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventoryAssociated
