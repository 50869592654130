import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAuctionDetailActive } from '../../../../store/actions/AuctionAction';
import AuctionCustomer from './AuctionCustomer';
import AuctionInvoice from './AuctionInvoice';
import AuctionSales from './AuctionSales';
import AuctionSalesRep from './AuctionSalesRep';
import AuctionSeller from './AuctionSeller';
import Lots from './Lots';

const AuctionDetails = () => {
    const getActive = useSelector((state: any) => state.auction.auctionDetails);
    const [active, setActive] = useState<any>({});
    const dispatch = useDispatch();

    useEffect(() => {
        setActive(getActive);
    }, [getActive]);

    const handleChange = (key: string) => {
        dispatch(setAuctionDetailActive({ activeLabel: key }))
    }
    return (<>
        <div className="container-fluid for-fix-bottom-space">
            <div className="row top_border justify-content-center">
                <div className="col-xxl-10 pt-4 pb-4">
                    <Tabs
                        defaultActiveKey={"AuctionSeller"}
                        activeKey={active.activeLabel}
                        className="nav-underline border-bottom"
                        onSelect={(key: any) => handleChange(key)}
                    >
                        <Tab eventKey="AuctionSeller" title="Auction Seller"></Tab>
                        <Tab eventKey="AuctionCustomer" title="Auction Buyers"></Tab>
                        <Tab eventKey="AuctionInventory" title="Auction Inventory"></Tab>
                        <Tab eventKey="AuctionInvoice" title="Auction Invoice"></Tab>
                        <Tab eventKey="AuctionSales" title="Auction Sales"></Tab>
                        <Tab eventKey="AuctionSalesRep" title="Auction Sales Rep"></Tab>
                    </Tabs>
                    {
                        (active.activeLabel === 'AuctionSeller') ?
                            <AuctionSeller /> :
                            (active.activeLabel === 'AuctionCustomer') ?
                                <AuctionCustomer /> :
                                (active.activeLabel === 'AuctionInventory') ?
                                    <Lots /> :
                                    (active.activeLabel === 'AuctionInvoice') ?
                                        <AuctionInvoice /> :
                                        (active.activeLabel === 'AuctionSales') ?
                                            <AuctionSales /> :
                                            (active.activeLabel === 'AuctionSalesRep') ?
                                                <AuctionSalesRep /> :
                                                <></>
                    }
                </div>
            </div>
        </div>
    </>)

}

export default AuctionDetails;