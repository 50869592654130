import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { ERROR_MESSAGES } from "../../../../Utils/Utils";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import { GETLOTTITLEASSIGNMENTBYLOTID, GETWONTITLEDETAILSBYLOTID, UPDATELOTTITLEASSIGNMENT } from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { CommonMessages } from "../../../../common/messageConstants";
import { SPECIALCHARS } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setCloneTab, setCreateTab, setInventoryInfoActive, setStep } from "../../../../store/actions/Inventory";
import { LOTTITLEASSIGNMENT } from "../../InventoryConstants";

const TitleAssignment = () => {
    const initialValues = {
        companyId: localStorage.getItem("companyId"),
        lotTitleAssignmentId: 0,
        company: "",
        firstName: "",
        lastName: "",
        add1: "",
        add2: "",
        address2: "",
        country1: "",
        state1: "",
        city1: "",
        zip: "",
        county1: "",
        sameAsBuyer: true
    }
    const [loading, setLoading] = useState(false);
    const [btnText, setBtnTxt] = useState<any>("")
    const [wonBidderResp, setWonBidderResp] = useState<boolean>(false)
    const [titleAssignmentResp, setTitleAssignmentResp] = useState<boolean>(false)
    const [wonBidderData, setWonBidderData] = useState<any>({})
    const [titleAssignmentData, setTitleAssignmentData] = useState<any>({})
    const [address, setAddress] = useState('');
    const [isAddressSelected, setAddressSelected] = useState(false)
    const dispatch = useDispatch()
    const lotId = useSelector((state: any) => state?.inventory?.lotId);
    const cloneLotId = useSelector((state: any) => state.inventory.clonedLotId)
    const clonedTabs = useSelector((state: any) => state.inventory.clonedTabs)
    const createTabs = useSelector((state: any) => state.inventory.createTabs)
    const mode = useSelector((state: any) => state.inventory.mode)

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const schema: any = yup.object().shape({
        company: yup
            .string()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, "Please enter valid Company Name")
            .max(50, "Please enter upto 50 characters."),
        firstName: yup
            .string()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, "Please enter valid First name")
            .max(20, "Please enter upto 20 characters."),
        lastName: yup
            .string()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, "Please enter valid  First name")
            .max(20, "Please enter upto 20 characters."),
        add1: yup
            .string()
            .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(SPECIALCHARS, "Please enter valid Address"),
        country1: yup
            .string()
            .test(
                "no-blank-space",
                ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
                (value) => {
                    return value ? !/^\s/.test(value) : true;
                }
            ),
        state1: yup
            .string()
            .test(
                "no-blank-space",
                ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
                (value) => {
                    return value ? !/^\s/.test(value) : true;
                }
            ),
        city1: yup
            .string()
            .test(
                "no-blank-space",
                ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
                (value) => {
                    return value ? !/^\s/.test(value) : true;
                }
            ),
        zip: yup.string()
            .test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]*$/, "Zip code must contain only numbers, alphabets, hyphens, and spaces.")
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, "Hyphens are not allowed at the beginning or end of the zip code.")
            /* .min(3, "Zip code must be at least 3 characters.") */
            .max(12, "Zip code cannot be longer than 12 characters."),
    });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        trigger,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
        mode: "onChange",
    });

    const watchSameAsBuyer = watch('sameAsBuyer')

    const onSubmit = (data: any) => {
        setLoading(true)
        onGetDefaultValues(data, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const payload = {
                companyId: parseInt(data.companyId),
                lotId: lotId,
                lotTitleAssignmentId: data.lotTitleAssignmentId,
                customerAuctionInfoId: Object.keys(wonBidderData).length > 0 ? wonBidderData.customerAuctionInfoId : null,
                sameAsBuyer: data.sameAsBuyer,
                firstName: data.firstName,
                lastName: data.lastName,
                company: data.company,
                addressLine1: data.add1,
                addressLine2: data.address2 ?? "",
                city: data.city1 === "" ? 0 : cityId,
                state: data.state1 === "" ? 0 : stateId,
                country: data.country1 === "" ? 0 : countryId,
                zipCode: data.zip,
                county: data.county1 === "" ? 0 : countyId,
                isActive: true,
                isDelete: false,
            }
            axios.post(UPDATELOTTITLEASSIGNMENT, payload, config).then((response) => {
                setLoading(false);
                if (response?.data?.success === "Fail") {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    return;
                } else {
                    if (mode === "clone") {
                        dispatch(setCloneTab([...clonedTabs, LOTTITLEASSIGNMENT]))
                    }
                    if (mode === "create") {
                        dispatch(setCreateTab([...createTabs, LOTTITLEASSIGNMENT]))
                    }
                    handleNext();
                    toast.success("Title Assignment saved successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                }
            }).catch((error) => {
                handleError(error)
            });
        })
    }

    const prevStep = () => {
        dispatch(setStep("auto"));
    }

    const handleNext = () => {
        dispatch(setStep("realEstate"))
        dispatch(setInventoryInfoActive({ activeTab: "records" }));
    };

    const onAddressSelected = () => {
        setAddressSelected(true)
    }

    const updateCountryCode = (newAddress: any, code: any) => {
    };

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('add1', e.target.value)
        const inputText = e.target.value;
        const currentCount = inputText.length;
        const remaining = 250 - currentCount;

        if (remaining < 0) {
            return;
        }
        if (e.target.name === 'addressLine1') {
            setValue('add1', inputText)
            setAddress(inputText)
            charCountForAddress(inputText.length)
        } else if ((e.target.name === 'locations')) {
            setAddress(inputText)
            setValue('add1', inputText)
            charCountForAddress(inputText.length)
        }
    }

    const setDataInForm = (data: any, checkValue: boolean) => {
        const titleAssignmentId = getValues().lotTitleAssignmentId
        if (Object.keys(data).length > 0) {
            setAddress(data.addressLine1)
            reset({
                ...data,
                add1: data.addressLine1 ?? "",
                address2: data?.addressLine2 ?? "",
                city1: data.cityName ?? "",
                state1: data.stateName ?? "",
                country1: data.countryName ?? "",
                zip: data.zipCode ?? "",
                county1: data?.countyName ?? "",
                sameAsBuyer: checkValue
            })
            setValue("lotTitleAssignmentId", titleAssignmentId)
        } else {
            setAddress("")
            reset({ ...initialValues, address2: "", sameAsBuyer: checkValue })
            setValue("lotTitleAssignmentId", titleAssignmentId)
        }
    }

    const getLotTitleAssignmentById = (id: any) => {
        setLoading(true);
        axios.get(`${GETLOTTITLEASSIGNMENTBYLOTID}?lotId=${id}`, { ...config }).then((response: any) => {
            setLoading(false);
            getWonBidderTitleDetailsByLotId(id)
            if (response?.data?.success === "Fail") {
                setTitleAssignmentResp(true)
                return;
            } else {
                let respData = response.data.data;
                if (!clonedTabs.includes(LOTTITLEASSIGNMENT) && cloneLotId > 0) {
                    respData = { ...respData, lotTitleAssignmentId: 0 }
                }
                setTitleAssignmentData(respData)
                setTitleAssignmentResp(true)
                setAddress(respData.addressLine1)
                reset({
                    ...respData,
                    add1: respData.addressLine1,
                    address2: respData?.addressLine2,
                    city1: respData.cityName ?? "",
                    state1: respData.stateName ?? "",
                    country1: respData.countryName ?? "",
                    zip: respData.zipCode,
                    county1: respData?.countyName ?? ""
                })
            }
        })
    }

    const getWonBidderTitleDetailsByLotId = (id: any) => {
        setLoading(true);
        axios.get(`${GETWONTITLEDETAILSBYLOTID}?lotId=${id}`, { ...config }).then((response: any) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                setWonBidderResp(true)
                return;
            } else {
                let respData = response.data.data;
                setWonBidderData({
                    ...respData,
                    add1: respData.addressLine1,
                    address2: respData?.addressLine2,
                    city1: respData.cityName ?? "",
                    state1: respData.stateName ?? "",
                    country1: respData.countryName ?? "",
                    zip: respData.zipCode,
                    county1: respData?.countyName ?? ""
                })
                setWonBidderResp(true)
            }
        });
    }

    useEffect(() => {
        if (wonBidderResp && titleAssignmentResp) {
            if (Object.keys(titleAssignmentData).length === 0 && Object.keys(wonBidderData).length > 0) {
                setDataInForm(wonBidderData, true)
            }
        }
    }, [wonBidderResp, titleAssignmentResp])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            if (mode === "create") {
                text = (lotId > 0 && createTabs.includes(LOTTITLEASSIGNMENT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
            } else {
                text = lotId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
            }
        } else {
            text = (cloneLotId > 0 && clonedTabs.includes(LOTTITLEASSIGNMENT)) ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    useEffect(() => {
        const id = clonedTabs.includes(LOTTITLEASSIGNMENT) ? cloneLotId : lotId
        getLotTitleAssignmentById(id);
    }, []);

    return (
        <>
            {loading && (
                <div className="loader-wrap">
                    <img src={`${localStorage.getItem("globalLoader")}`} />
                </div>
            )}
            <div className="row top_border justify-content-center">
                <div className="col-xxl-10 mt-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-3">
                            <div className="col-lg-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        {...register("sameAsBuyer", {
                                            onChange: (e: any) => {
                                                if (e.target.checked) {
                                                    setDataInForm(wonBidderData, true)
                                                } else {
                                                    setDataInForm(titleAssignmentData, false)
                                                }
                                            }
                                        })}
                                        id="sameAsBuyer"
                                    />
                                    <label htmlFor="sameAsBuyer">Same as Buyer</label>
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label className="form-label mb-2">Company Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Company Name"
                                        maxLength={51}
                                        disabled={watchSameAsBuyer ? true : false}
                                        {...register("company")}
                                    />
                                    {errors.company && (
                                        <span className="text-danger" style={{ fontSize: "12px" }}>
                                            {errors.company.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label className="form-label mb-2">First Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="First Name"
                                        maxLength={51}
                                        disabled={watchSameAsBuyer ? true : false}
                                        {...register("firstName")}
                                    />
                                    {errors.firstName && (
                                        <span className="text-danger" style={{ fontSize: "12px" }}>
                                            {errors.firstName.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label className="form-label mb-2">Last Name</label>
                                    <input
                                        className="form-control"
                                        placeholder="Last Name"
                                        maxLength={51}
                                        disabled={watchSameAsBuyer ? true : false}
                                        {...register("lastName")}
                                    />
                                    {errors.lastName && (
                                        <span className="text-danger" style={{ fontSize: "12px" }}>
                                            {errors.lastName.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 mb-3">
                            <AddressForm
                                errors={errors}
                                onHandleChangeAddressLine={handleChangeAddressLine}
                                register={register}
                                setValue={setValue}
                                required={false}
                                onSetAddress={setAddress}
                                onAddressChange={updateCountryCode}
                                watch={watch}
                                trigger={trigger}
                                onAddressSelected={onAddressSelected}
                                latLongMap={false}
                                sameAsBuyer={watchSameAsBuyer}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={prevStep}>{CommonMessages.previousButton}</button>
                            <Link to={routeConstant.INVENTORY} className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal">{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary mx-2" type="submit" data-wizard-next-btn="data-wizard-next-btn">{btnText}</button>
                            <button className="btn btn-secondary me-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default TitleAssignment;