import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from 'yup';
import { ERROR_MESSAGES, trimObjectValues } from "../../../Utils/Utils";
import { GETCHECKLISTBYAUCTIONID, UPDATEAUCTIONCHECKLIST } from "../../../common/apiConstatnts";
import { auctionCheckList, buyersPremium, silentAuctions } from "../../../common/constants";
import { AuctionMessages, CommonMessages, errorMessages } from "../../../common/messageConstants";
import { AlPHANUMERIC } from "../../../common/regexConstants";
import * as routeConstant from '../../../common/routeConstants';
import { setAuctionDetailActive, setCloneTab, setCreateTab, setStep } from "../../../store/actions/AuctionAction";
import { loader } from "../../../store/actions/CommonAction";
import { CHECKLIST } from "../AuctionConstants";
import handleError from "../../../Utils/ApiCommonErrorMessages";
import Select from 'react-select';
import TimeSelector from "../../../Utils/MinSec";

const CheckList = () => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  }

  const auctionId: any = useSelector((state: any) => state.auction.auctionId);
  const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
  const createTabs = useSelector((state: any) => state.auction.createTabs)
  const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
  const mode = useSelector((state: any) => state.auction.mode)
  const initialData = {
    auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
    showStartingBid: false,
    featureAuction: false,
    displayImageName: false,
    useGeocoding: false,
    silentOnlineBidding: false,
    dynamicExtension: false,
    dynamicOpening: false,
    raceHorseOption: false,
    lotsOpenAtOnce: 0,
    buyersPremiumTaxable: false,
    bpTaxedonTaxExemptLots: false,
    defaultAbsBidTableToUse: "",
    ccAuthGateway: "",
    depositCCFunction: "",
    depositTransactionDesciption: "",
    minRegistractionDepositAmt: 0,
    depPercentageofCreditRequest: 0,
    approveBidderOnAuthorization: false,
    saveCCInfo: false,
    requestAmount: 0,
    requiredDeposit: 0,
    invoicePaymentGateway: "Nelix",
    checkListId: 0,
    defaultExtMins: "0",
    defaultExtSecs: "0",
  }
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState<any>(initialData)
  const [btnTxt, setBtnTxt] = useState<any>("")
  const mins = Array.from({ length: 60 }, (_, index) => index < 9 ? `0${index + 1}` : index + 1);
  const secs = Array.from({ length: 60 }, (_, index) => index < 9 ? `0${index + 1}` : index + 1);

  const schema: any = yup.object().shape({
    ccAuthGateway: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? !/^\s*$/.test(value) : true;
    }).matches(AlPHANUMERIC, "Please enter valid cc auth gateway").max(50, "Please enter upto 50 characters"),
    invoicePaymentGateway: yup.string().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
      return value ? !/^\s*$/.test(value) : true;
    }).matches(AlPHANUMERIC, "Please enter valid invoice payment gateway").max(50, "Please enter upto 50 characters"),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })

  const onSubmit = (dataOriginal: any) => {
    dispatch(loader(true))
    const data = trimObjectValues(dataOriginal);

    const dynamicTime : any  = `00:${data.defaultExtMins < 10
      ? "0" + data.defaultExtMins
      : data.defaultExtMins
      }:${data.defaultExtSecs < 10
        ? "0" + data.defaultExtSecs
        : data.defaultExtSecs
      }`
    const payload = {
      ...data,
      auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
      dynamicExtension: dynamicTime,
    }
    axios.post(UPDATEAUCTIONCHECKLIST, payload, config).then((response) => {
      dispatch(loader(false));
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      else {
        if (mode === "clone") {
          dispatch(setCloneTab([...clonedTabs, CHECKLIST]))
        }
        if (mode === "create") {
          dispatch(setCreateTab([...createTabs, CHECKLIST]))
        }
        toast.success(AuctionMessages.checkListMessage.checkListUpdated, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        handleNext()
      }
    }).catch((error) => {
      dispatch(loader(false));
      handleError(error);
    })
  }

  const handlePrevious = () => {
    dispatch(setStep(2));
    dispatch(setAuctionDetailActive({ activeLabel: "AuctionSeller" }))
  }
  const handleNext = () => {
    dispatch(setStep(4))
  }

  useEffect(() => {
    dispatch(loader(true));
    const aId = clonedTabs.includes(CHECKLIST) ? cloneAuctionId : auctionId
    axios.get(`${GETCHECKLISTBYAUCTIONID}?AuctionId=${aId}`, config).then((response: any) => {
      if (response?.data?.success === "Fail") {
        dispatch(loader(false))
        return;
      }
      else {
        dispatch(loader(false));
        let respData = response.data.data;
        const dextentionData = respData.dynamicExtension ?? "00:00:00"
        const dynamicExtension = dextentionData.split(':');
            const modifiedDynamicExtension = dynamicExtension.map((num:any) => num.replace(/^0+/, '') || '0');

        respData = {
          ...respData,
          defaultExtMins: modifiedDynamicExtension[1] ?? 0,
          defaultExtSecs: modifiedDynamicExtension[2] ?? 0
        }
        if (!clonedTabs.includes(CHECKLIST) && cloneAuctionId > 0) {
          respData = { ...respData, checkListId: 0 }
        }
        reset(respData)
      }
    }).catch((error) => {
      dispatch(loader(false));
      handleError(error);
    })
  }, [])

  // render btn text
  useEffect(() => {
    let text = ""
    if (mode !== "clone") {
      if (mode === "create") {
        text = (auctionId > 0 && createTabs.includes(CHECKLIST)) ? CommonMessages.updatedNext : CommonMessages.saveNext
      } else {
        text = auctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
      }
    } else {
      text = (cloneAuctionId > 0 && clonedTabs.includes(CHECKLIST)) ? CommonMessages.updatedNext : CommonMessages.saveNext
    }
    setBtnTxt(text)
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid for-fix-bottom-space">
          <div className="row top_border justify-content-center">
            <div className="col-xxl-10 pt-4 pb-4">
              <h4 className="mb-3">Checklist</h4>
              <div className="mt-3">
                <label>Auction Checklist</label>
                <div className="d-flex flex-wrap">
                  {auctionCheckList.map((aCheckList, index) => {
                    return (
                      <div className="form-check me-xl-5 me-4" key={index}>
                        <label className="form-check-label text-900 fw-400">
                          <input
                            className="form-check-input form-check-states"
                            type="checkbox"
                            {...register(`${aCheckList.key}`)}
                          />
                          {aCheckList.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr></hr>
              <div className="mt-3">

                <div className="d-flex flex-wrap row">
                  <div className="col-lg-3 col-md-6">
                    <label>Types Of Bidding</label>
                    <div className="form-check me-xl-5 me-4">
                      <label className="form-check-label text-900 fw-400">
                        <input
                          className="form-check-input form-check-states"
                          type="checkbox"
                          {...register("silentOnlineBidding")}
                        />
                        Silent Online Bidding
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6">
                    <label className="mb-2">Default Dynamic Extension Time</label>
                    <div className='d-flex dur'>
                      <select {...register('defaultExtMins')} className='form-select scrollbar'>
                        <option value="00">00 mins</option>
                        {
                          mins.map((num, index) => {
                            return (
                              <option value={num} key={index}>{num} mins</option>
                            )
                          })
                        }
                      </select>
                      <span className='ms-2 me-2'>:</span>
                      <select {...register('defaultExtSecs')} className='form-select scrollbar'>
                        <option value="00">00 secs</option>
                        {
                          secs.map((num, index) => {
                            return (
                              <option value={num} key={index}>{num} secs</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <TimeSelector
                      label="Default Dynamic Extension Time"
                      registerMin={register('defaultExtMins')}
                      registerSec={register('defaultExtSecs')}
                    // mins={mins}
                    // secs={secs}
                    />
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="mt-3">
                <label>Silent Auctions</label>
                <div className="d-flex flex-wrap align-items-center">
                  {silentAuctions.map((sAuctions, index) => {
                    return (
                      <div className="form-check me-xl-5 me-4" key={index}>
                        <label className="form-check-label text-900 fw-400">
                          <input
                            className="form-check-input form-check-states"
                            type="checkbox"
                            {...register(`${sAuctions.key}`)}
                          />
                          {sAuctions.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <hr></hr>
              <div className="mt-3">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <label>Buyers Premium</label>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center">
                      {buyersPremium.map((bpData, index) => {
                        return (
                          <div className="form-check me-xl-5 me-4">
                            <label
                              className="form-check-label text-900 fw-400"
                              key={index}
                            >
                              <input
                                className="form-check-input form-check-states"
                                type="checkbox"
                                {...register(`${bpData.key}`)}
                              ></input>
                              {bpData.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="mt-3">
                <label>Silent Auctions</label>
                <div className="row mt-2 aling-items-center">
                  <div className="col-lg-4 col-md-6 mb-3">
                    <label className="form-label">CC Auth Gateway</label>
                    <input
                      className="form-control form-icon-input"
                      type="text"
                      placeholder="CC Auth Gateway"
                      {...register("ccAuthGateway")}
                      maxLength={51}
                    />
                    {errors.ccAuthGateway && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.ccAuthGateway.message}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <label className="form-label">Invoice Payment Gateway{" "}</label>
                    <input
                      className="form-control form-icon-input"
                      type="text"
                      placeholder="Invoice Payment Gateway"
                      {...register("invoicePaymentGateway")}
                      maxLength={51}
                    />
                    {errors.invoicePaymentGateway && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.invoicePaymentGateway.message}
                      </span>
                    )}
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="form-check me-xl-5 me-4">
                      <label className="form-check-label text-900 fw-400">
                        <input
                          className="form-check-input form-check-states"
                          type="checkbox"
                          {...register("approveBidderOnAuthorization")}
                        />
                        Approve Bidder on Authorization
                      </label>
                    </div>
                    <div className="form-check me-xl-5 me-4">
                      <label className="form-check-label text-900 fw-400">
                        <input
                          className="form-check-input form-check-states"
                          type="checkbox"
                          {...register("saveCCInfo")}
                        />
                        Save CC Info
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom"
            data-wizard-footer="data-wizard-footer"
          >
            <button
              className="btn btn-secondary ms-2 me-2"
              type="button"
              onClick={handlePrevious}
            >
              {CommonMessages.previousButton}
            </button>
            <Link
              className="btn btn-outline-danger me-auto"
              type="button"
              data-bs-dismiss="modal"
              to={routeConstant.AUCTION}
            >
              {CommonMessages.cancelButton}
            </Link>
            <button className="btn btn-primary ms-2" type="submit">
              {btnTxt}
            </button>
            <button
              className="btn btn-secondary ms-2"
              type="button"
              onClick={handleNext}
            >
              {CommonMessages.skipButton}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default CheckList