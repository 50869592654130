import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { UPDATELOGNOTES } from "../../../../common/apiConstatnts";
import { wordCounter } from "../../../../common/constants";
import { CommonMessages, errorMessages, SellerMessages } from "../../../../common/messageConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { deleteLogNote, setAuctionInfoActiveTab, updateSeller } from "../../../../store/actions/Seller";
import DateTime from "../../../../Utils/DateTime";
import MomentUtil from "../../../../Utils/MomentUtil";
import { useCurrentDateTime } from "../../../../Utils/useDateTime";
import { ERROR_MESSAGES } from "../../../../Utils/Utils";
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import { loader } from "../../../../store/actions/CommonAction";

const LogNotes = () => {
    const query = window.location.href;
    const vars = query.split("/");
    const ID = vars[5];
    const token: any = localStorage.getItem("token");
    const decodedToken: any = jwt_decode(token);
    const enteredBy = decodedToken.user;
    const sellerData = useSelector((state: any) => state.seller);


    const initialData = {
        sellerLogNotesId: 0,
        date: "",
        time: "00:00:00",
        description: "",
        enteredBy: enteredBy,
        status: false,
        logNotesType: "",
        isActive: true,
        isDelete: false
    }

    const status: any = [
        { value: true, label: "Done" },
        { value: false, label: "Pending" }
    ]

    const type = [
        { value: "Phone", label: "Phone" },
        { value: "Email", label: "Email" },
        { value: "Mail", label: "Mail" },
        { value: "Fax", label: "Fax" },
        { value: "Other", label: "Other" },
    ]

    const dispatch = useDispatch()
    const logNoteData = sellerData?.logNote;
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('create')
    const [showOpt, setShowOpt] = useState<string>('');
    const [initialFormValue, setFormValue] = useState<any>(initialData)
    const [logNotes, setLogNotes] = useState(logNoteData);
    const [filterLogNotes, setFilterLogNotes] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [searchText, setSearchText] = useState('')

    const PER_PAGE = 5;
    const pageCount = Math.ceil(logNotes.length / PER_PAGE);

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const yesterday = moment().subtract(1, 'day');

    const disablePastDt = (current: any) => {
        return current.isAfter(yesterday);
    };

    const maxWordCount = 250
    const [remainingCount, setRemainingCount] = useState(maxWordCount)

    const paginateData = (data: any) => {
        setLogNotes(data)
        const firstSet = data?.slice(0, PER_PAGE);
        setFilterLogNotes(firstSet)
    }
    const momentUtil = MomentUtil.getInstance()
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage()
    const dateTime = useCurrentDateTime()

    const schema: any = yup.object().shape({
        date: yup.string().required('Date is required.').test('valid-datetime', 'Invalid datetime', function (value) {
            // const parsedDate: any = new Date(value);
            // const currentDate: any = UTCdateConverter(new Date())
            // if (parsedDate.getFullYear() < 2000 ||
            //     parsedDate.getFullYear() > 2500 || !ID && parsedDate - currentDate < 0) {
            //     return this.createError({
            //         message: 'Please enter a valid date that is greater than the current UTC date.',
            //     });
            // }
            // return !isNaN(parsedDate);
            const parsedDate = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ" });
            const currentDate = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime });

            if (
                parsedDate.year() < 2000 ||
                parsedDate.year() > 2500 ||
                (!ID && parsedDate.diff(currentDate, 'minutes') < 0)
            ) {
                return this.createError({
                    message: 'Please enter a valid date that is greater than the current UTC date.',
                });
            }

            // Calculate the difference in minutes between parsed date and current date
            const timeDifferenceInMinutes = parsedDate.diff(currentDate, 'minutes');

            if (timeDifferenceInMinutes < 0) {
                return this.createError({
                    message: 'The start time must be in the future.',
                });
            }

            return true;

        }),
        description: yup.string().required('Description is required.')
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true;
                return !/^\s/.test(value);
            })
        ,
        logNotesType: yup.string().required('Please select type'),
        status: yup.string().required('Please select status'),
    })

    const {
        register: logRegister,
        handleSubmit: logHandleSubmit,
        getValues: logGetValues,
        setValue: logSetValue,
        reset: logReset,
        watch: logWatch,
        control,
        formState: { errors: logErrors, isValid: logIsValid, isDirty },
    } = useForm({ resolver: yupResolver(schema), defaultValues: initialFormValue, mode: 'onChange' })
    const watchStatus = logWatch('status')
    const logNotesType = logWatch('logNotesType')

    const onSubmitNotes = () => {
        let newData
        const data = logGetValues();
        if (data.status == true || data.status == "true") {
            data.status = true;
        } else {
            data.status = false;
        }
        if (mode === 'create') {
            const logNoteData = {
                ...initialData,
                ...data,
                status: data?.status,
                dateDue: momentUtil.parseTimeInUtc({ dateTimeString: data.date }).format("YYYY-MM-DD"),
                date: momentUtil.parseTimeInUtc({ dateTimeString: data.date }).format("YYYY-MM-DD"),
                sellerLogNotesId: 0,
                sellerId: parseInt(sellerData?.sellerId),
                companyId: parseInt(sellerData?.companyId),
                time: momentUtil.parseTimeInUtc({ dateTimeString: data.date }).format("HH:mm:ss"),
                isActive: true,
                isDelete: false,
                logUniqueId: uuidv4()
            }
            setLogNotes([...logNotes, logNoteData])
            dispatch(updateSeller({ ...sellerData, logNote: [...sellerData.logNote, logNoteData] }))
            logReset(initialData)
            setToggleValue(!toggleModal)
            setRemainingCount(maxWordCount)

        } else {
            const getObjData = logNotes.find((data: any) => data.logUniqueId === initialFormValue?.logUniqueId)
            newData = logNotes.filter((data: any) => data.logUniqueId != initialFormValue?.logUniqueId)
            dispatch(updateSeller({ ...sellerData, logNote: [...newData, { ...getObjData, ...data }] }))
            setLogNotes([...newData, { ...getObjData, ...data }])
            logReset(initialData)
            setToggleValue(!toggleModal)
            setRemainingCount(maxWordCount)
        }
    }

    const handlePageClick = ({ selected: selectedPage }: any) => {
        setCurrentPage(selectedPage);
    }

    const handleSorting = (type: string, fieldName: string) => {
        let userName
        if (type === 'ASC') {
            userName = logNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        } else {
            userName = logNotes.sort((a: any, b: any) =>
                (a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()) ? 1 : -1
            )
        }
        paginateData(userName);
    }

    const handleEditModal: any = (sellerLogNotesId: any, type: string) => {
        let getData;
        if (type === 'edit') {
            setMode('edit')
            setShowOpt('')
            setToggleValue(!toggleModal)
            getData = logNotes.find((data: any) => data.sellerLogNotesId === sellerLogNotesId)
            getData = {
                ...getData,
                date: momentUtil.parseTimeInUtc({ dateTimeString: getData.date, }).format('YYYY-MM-DDTHH:mm:ss'),
                time: momentUtil.parseTimeInUtc({ dateTimeString: getData.date, }).format('HH:mm:ss'),
                isActive: true,
                isDelete: false

            }
            setFormValue(getData)
            logReset(getData)
            return
        }
        setShowOpt('')
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this record!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#006800',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteLogNote(sellerLogNotesId));
                const updatedData = logNotes.map((data: any) => {
                    if (data.sellerLogNotesId === sellerLogNotesId) {
                        return {
                            ...data,
                            isDelete: true,
                            isActive: false
                        };
                    }
                    return data;
                });
                setLogNotes(updatedData);
            }
            setCurrentPage(0)
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
          });
    }

    const handleClose = () => {
        logReset(initialData)
        if (isDirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Changes will be discarded!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Discard'
            }).then((result) => {
                if (result.isConfirmed) {
                    logReset(initialData)
                    setToggleValue(!toggleModal)
                    setRemainingCount(maxWordCount)
                }
            })
            return
        }
        setToggleValue(!toggleModal)
        setRemainingCount(maxWordCount)
    }

    const handleSearch = (e: any) => {
        const keyword = e?.toLocaleLowerCase()
        if (keyword === '') {
            paginateData(logNoteData)
            return
        }
        const filterData: any = logNotes?.filter((x: any) => x.description?.toLocaleLowerCase()?.includes(keyword) ||
            x.enteredBy?.toLocaleLowerCase()?.includes(keyword) ||
            x.logNotesType?.toLocaleLowerCase()?.includes(keyword));
        paginateData(filterData);
        setCurrentPage(0);
    }
    const toggleOptionsDD = (index: string) => {
        if (showOpt === '' || showOpt !== index) {
            setShowOpt(index)
            return
        }
        setShowOpt('')
    }

    const handleNext = () => {
        dispatch(setAuctionInfoActiveTab('sellerNotes'));
    }

    const handlePrevious = () => {
        dispatch(setAuctionInfoActiveTab('commision'));
    }

    const onSubmit = () => {
        axios.post(UPDATELOGNOTES, logNotes, { ...config, 'ContentType': 'application/json' })
            .then((response) => {
                toast.success(SellerMessages.logNotesCreated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                })
                handleNext()
                setSearchText('')
                setLoading(false)
            }).catch((error) => {
                setLoading(false);
                handleError(error);
              });
    }

    useEffect(() => {
        paginateData(logNotes)
    }, [logNotes])

    useEffect(() => {
        const offset = currentPage * PER_PAGE
        const nextSet = logNotes.slice(offset, offset + PER_PAGE)
        setFilterLogNotes(nextSet)
    }, [currentPage])

    useEffect(() => {
        handleSearch(searchText)
    }, [searchText])

    return (
        <>
            <Modal show={toggleModal} size='xl' centered>
                <form noValidate>
                    <Modal.Header>
                        <h5 className="modal-title" id="verticallyCenteredModalLabel">{(mode === 'create') ? 'New Log Note' : 'Edit Log Note'}</h5>
                        <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span className="fas fa-times text-danger fs-20"></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-3 mb-3">
                            <div className="col-md-6">
                                <label className="form-label">Date  ({defaultTimezone})<sup className="text-danger">*</sup></label>
                                {/* <div className="code-to-copy">
                                    <input
                                        className="form-control form-icon-input"
                                        type="datetime-local"
                                        {...logRegister('date')}
                                    />
                                    {logErrors.date && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.date.message}</span>}
                                </div> */}


                                <div className="date_icon">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="date_icon">
                                                <DateTime
                                                    onChange={(date) => {
                                                        onChange(date);
                                                    }}
                                                    closeOnSelect={true}
                                                    dateFormat="MM/DD/y"
                                                    timeFormat="hh:mm A"
                                                    value={value}
                                                    isValidDate={disablePastDt}
                                                    inputProps={
                                                        {
                                                            placeholder: 'Select Date and Time',
                                                            onInput: (e: any) => {
                                                                const cursorPosition: any = e.target.selectionStart;
                                                                onChange(e.target.value);
                                                                e.target.setSelectionRange(cursorPosition, cursorPosition);
                                                            },
                                                        }} />

                                            </div>
                                        )}
                                    />
                                </div>
                                {logErrors.date && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.date.message}</span>}

                            </div>
                            {/* <div className="col-md-6">
                                <label className="form-label">Due Date <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <input
                                        className="form-control form-icon-input"
                                        type="date"
                                        {...logRegister('dateDue')}
                                    />
                                    {logErrors.dateDue && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.dateDue.message}</span>}

                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <label className="form-label">Description <sup className="text-danger">*</sup></label>
                                <div className="code-to-copy">
                                    <textarea
                                        className="form-control form-icon-input"
                                        rows={4}
                                        cols={4}
                                        maxLength={maxWordCount}
                                        {...logRegister('description', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                                    />
                                    {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                                        {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                                    </div>}
                                    {logErrors.description && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.description.message}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Status <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...logRegister('status')}
                                    name='status'
                                    options={status}
                                    value={status?.find((option: any) => option?.value == watchStatus)}
                                    onChange={(e: any) => logSetValue('status', e.value)}
                                />
                                {logErrors.status && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.status.message}</span>}
                            </div>
                            <div className="col-lg-6">
                                <label className="form-label" htmlFor="bootstrap-wizard-validation-gender">Log Note Type <sup className="text-danger">*</sup></label>
                                <Select
                                    classNamePrefix="dpaSelect"
                                    placeholder="Select"
                                    className='isActive'
                                    {...logRegister('logNotesType')}
                                    name='logNotesType'
                                    options={type}
                                    value={type?.find((option: any) => option?.value == logNotesType)}
                                    onChange={(e: any) => logSetValue('logNotesType', e.value)}
                                />
                                {logErrors.logNotesType && <span className='text-danger' style={{ fontSize: "12px" }}>{logErrors.logNotesType.message}</span>}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" onClick={handleClose}>{CommonMessages.cancelButton}</button>
                        <button className="btn btn-primary" onClick={logHandleSubmit(onSubmitNotes)} type="submit">{(mode != 'create' ? (CommonMessages.updateButton) : (CommonMessages.saveButton))}</button>
                    </Modal.Footer>
                </form>
            </Modal>
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center">
                    <div className="col-xxl-10 mt-3">
                        <h4>DPA Notes</h4>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="search-box">
                                <div className="d-flex align-items-center">
                                    <div className="position-relative d-flex align-items-center" data-bs-toggle="search" data-bs-display="static">
                                        <input className="form-control search-input search form-control-sm" type="text" placeholder="Search" aria-label="Search" value={searchText} onChange={(e: any) => setSearchText(e.target.value)} />
                                        <span className="fas fa-search search-box-icon"></span>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary  mb-2 ms-2 position-relative d-flex aling-items-center" type="button" data-bs-toggle="modal" data-bs-target="#Add_New_Bid"
                                onClick={() => {
                                    logReset(initialData)
                                    setToggleValue(!toggleModal)
                                    setMode('create')
                                }}
                            >
                                <span className="plus me-2"> <span className="fas fa-plus" data-fa-transform="shrink-3"></span> </span> <span> Add Notes </span>
                            </button>

                        </div>
                        <div className="bg-white mb-5 border-300">
                            <div className="table-responsive mx-n1 px-1 scrollbar">
                                <table className="table fs--1 mb-0 border-top border-200">
                                    <thead>
                                        <tr>
                                            <th className="bg-gradiant " scope="col">Action</th>
                                            <th className="bg-gradiant " scope="col" data-sort="Date">
                                                <div className='d-flex align-items-center'>
                                                    <span>Date</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant" scope="col" data-sort="description">
                                                <div className='d-flex align-items-center'>
                                                    <span>Description</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'description')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'description')}  ></button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col" data-sort="enteredBy">
                                                <div className='d-flex align-items-center'>
                                                    <span>Entered By</span>
                                                    <div className="sorting_group">
                                                        <button className='arrow-up' onClick={() => handleSorting('ASC', 'enteredBy')} ></button>
                                                        <button className='arrow-down' onClick={() => handleSorting('DESC', 'enteredBy')} ></button>
                                                    </div>
                                                </div>
                                            </th>
                                            {/* <th className="bg-gradiant " scope="col" data-sort="dateDue">
                                                <div className='d-flex align-items-center'>
                                                    <span>Due Date</span>
                                                </div>
                                            </th> */}
                                            <th className="bg-gradiant " scope="col" data-sort="type">
                                                <div className='d-flex align-items-center'>
                                                    <span>Type</span>
                                                </div>
                                            </th>
                                            <th className="bg-gradiant " scope="col" data-sort="status">
                                                <div className='d-flex align-items-center'>
                                                    <span>Status</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="list" id="table-latest-review-body">
                                        {filterLogNotes?.length > 0 ? filterLogNotes.map((data: any, index: any) => (
                                            <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                <td className="align-middle white-space-nowrap text-start p-2">
                                                    <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                                        <Dropdown.Toggle className="m-0 p-0" variant='none'>
                                                            <button className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2" type="button"> <img src="/assets/img/ellipsis-solid.svg" style={{ width: "20px" }} />
                                                            </button>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className={`dropdown-menu dropdown-menu-start py-2`}>
                                                            <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.sellerLogNotesId, 'edit')}>Edit <i className="fas fa-pen dropdown-icon"></i> </Dropdown.Item>
                                                            <Dropdown.Item type='button' className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => handleEditModal(data?.sellerLogNotesId, 'delete')}>Delete <i className="fas fa-trash dropdown-icon"></i> </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td className="align-middle  p-2">
                                                    <a className="d-flex align-items-center">
                                                        <h6 className="mb-0 text-900">{momentUtil.parseTimeInUtc({ dateTimeString: data?.date }).format('MM/DD/YYYY')}</h6>
                                                    </a>
                                                </td>
                                                <td className="align-middle  p-2">
                                                    <a className="d-flex align-items-center">
                                                        <h6 className="mb-0 text-900">{data?.description}</h6>
                                                    </a>
                                                </td>
                                                <td className="align-middle review p-2">
                                                    <a className="d-flex align-items-center">
                                                        {data?.enteredBy}
                                                    </a>
                                                </td>
                                                {/* <td className="align-middle  p-2">
                                                    <a className="d-flex align-items-center">
                                                        <h6 className="mb-0 text-900">{moment(data?.dateDue).format('MM/DD/YYYY').toString()}</h6>
                                                    </a>
                                                </td> */}
                                                <td className="align-middle  p-2">
                                                    <a className="d-flex align-items-center">
                                                        <h6 className="mb-0 text-900">{data?.logNotesType ?? '-'}</h6>
                                                    </a>
                                                </td>
                                                <td className="align-middle text-start status p-2">
                                                    {data?.status == true ? <span className="badge badge-dpa fs--2 badge-dpa-success">
                                                        <span className="badge-label">Done</span>

                                                    </span> : <span className="badge badge-dpa fs--2 badge-dpa-danger">
                                                        <span className="badge-label">Pending</span>

                                                    </span>}
                                                </td>

                                            </tr>
                                        )) : <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                            <td className='text-center p-4' colSpan={8}>No records found!</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            {filterLogNotes.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"←"}
                                    nextLabel={"→"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={"pagination__link--disabled"}
                                    activeClassName={"pagination__link--active"}
                                    forcePage={currentPage}
                                />)}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                    <div>
                        <button className="btn btn-secondary" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                        <Link className="btn btn-outline-danger ms-2 me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.SELLER}>{CommonMessages.cancelButton}</Link>
                    </div>
                    <div>
                        <button className="btn btn-primary mb-1 ms-2" type="button" onClick={onSubmit}>{CommonMessages.saveNext}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogNotes
