import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import DefaultImage from '../../../assets/userProfile.svg';
import { GETCUSTOMERBLOBPROFILE, profileBase, UPDATECUSTOMERPROFILE } from '../../../common/apiConstatnts';
import { CommonMessages, errorMessages } from "../../../common/messageConstants";
import { INTEGER, INTEGER_MESSAGE } from "../../../common/regexConstants";
import * as routeConstant from '../../../common/routeConstants';
import { decryptId } from "../../../Encryption/Encryption";
import { setActiveTabCustomer, setProfileInfo } from "../../../store/actions/CustomerAction";
import { ERROR_MESSAGES } from "../../../Utils/Utils";
import handleError from "../../../Utils/ApiCommonErrorMessages";

const UplaodImage = () => {
    const initialData = {
        driverLicense: "",
        driverLicenseState: "",
        shippingAddress1: "",
        shippingAddress2: "",
        taxId: "",
        profilePicture: "",
        driverLicensePhoto: ""
    }

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            enctype: "multipart/form-data",
        }
    };

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const dispatch = useDispatch();
    const customerDetail = useSelector((state: any) => state.customer.profileInfo);
    const query = window.location.href;
    const vars = query.split("/");
    const ID = decryptId(vars[5]);

    const driverLicenseUpload = useSelector((state: any) => state.customer.contactInfo?.driverLicensePhoto);
    const [profile, setProfile] = useState<any>(initialData);
    const [loading, setLoading] = useState<boolean>(false);
    const [profilePicture, setProfileImage] = useState<any>(null);

    const [driverLicensePhoto, setDriverLicensePhoto] = useState<any>(null);
    const [shippingAddress1, setShippingAddressLine1] = useState('');
    const [shippingAddress2, setShippingAddressLine2] = useState('');
    const [taxId, setTaxId] = useState(0);
    const schema: any = yup.object().shape({
         taxId: yup.string().notRequired().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? /^\S*$/g.test(value) : true;
        }).matches(INTEGER, INTEGER_MESSAGE)
    })

    const {
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema), defaultValues: profile, mode: "onChange" });

    const handleNext = () => {
        dispatch(setActiveTabCustomer('bidderDocs'));
    }
    const handlePrevious = () => {
        dispatch(setActiveTabCustomer('creditCards'));
    }
    // Get customer profile
    useEffect(()=>{
        axios.get(`${GETCUSTOMERBLOBPROFILE}?customerId=${ID}`).then((response) => {
            setLoading(false);
            if (response?.data?.data?.success === "Fail") {
              toast.error(response?.data?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              return;
            }
            else {
             const profile = response?.data?.data?.profilePicture;
             const token = response?.data?.data?.photosWithSasToken;
             const profileImage = `${profile}?${token}`
             setProfileImage(profileImage);
            }
          }, (error) => {
            setLoading(false);
            if (error.response.status !== 401) {
              toast.error(errorMessages.error401, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
          });
    },[])

    const onSubmit = (e: any) => {
        setLoading(true);
        const formData: any = new FormData();
        formData.append("profilePicture", profilePicture);
        axios.post(`${UPDATECUSTOMERPROFILE}?customerId=${ID}`, formData, config).then((response) => {
            setLoading(false);
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const updatedData = response;
                setProfile(updatedData)
                dispatch(setProfileInfo(updatedData));
            }
            handleNext();
        }).catch((error) => {
            setLoading(false);
            handleError(error);
        });
    }

    useEffect(() => {
        reset(customerDetail);
        setShippingAddressLine1(customerDetail?.shippingAddress1 ? customerDetail?.shippingAddress1 === "null" ? "" : customerDetail?.shippingAddress1 : "");
        setShippingAddressLine2(customerDetail?.shippingAddress2 ? customerDetail?.shippingAddress2 === "null" ? "" : customerDetail?.shippingAddress2 : "");
        // setProfileImage(customerDetail?.profilePicture);
        setDriverLicensePhoto(driverLicenseUpload);
        setTaxId(customerDetail?.taxId)
    }, []);

    return (
        <>
            {loading && <div className="loader-wrap">
                <img src={`${localStorage.getItem('globalLoader')}`} />
            </div>}
            <div className="container-fluid for-fix-bottom-space">
                <div className="row justify-content-center top_border">
                    <form>
                        <div className="row mt-1 justify-content-center g-4">
                            <div className="col-xl-5 col-lg-6 mb-4">
                                <label className="form-label text-900">Profile Picture</label>
                                <div className="d-grid align-items-center justify-content-center border rounded-3 d-flex flex-column overflow-hidden align-items-center min-height-300">
                                    <div className="uploadedImage m-0 default-image-size full_image_orignal">
                                            {profilePicture ? (
                                            <img src={typeof profilePicture === 'string' ? profilePicture : URL?.createObjectURL(profilePicture)} alt="" 
                                                    onError={(e: any) => {
                                                        e.target.src = DefaultImage
                                                        e.target.className = 'defualt_img user-bg-var p-2'
                                                    }}
                                                />
                                            ) : (
                                                <div className="defualt_img">
                                                    <img src={DefaultImage} className="defualt_img user-bg-var p-2" alt="" />
                                                </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end border-top bg-white p-3 fix-bottom" data-wizard-footer="data-wizard-footer">
                            <button className="btn btn-secondary ms-2 me-2" type="button" onClick={handlePrevious}>{CommonMessages.previousButton}</button>
                            <Link className="btn btn-outline-danger me-auto" type="button" data-bs-dismiss="modal" to={routeConstant.CUSTOMERS}>{CommonMessages.cancelButton}</Link>
                            <button className="btn btn-primary mx-2" type="button" onClick={onSubmit} data-wizard-next-btn="data-wizard-next-btn">Next</button>
                            {/* <button className="btn btn-secondary ms-2" type="button" onClick={handleNext}>{CommonMessages.skipButton}</button> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UplaodImage;