import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as yup from "yup";
import checkMark from "../../assets/check_mark.svg";
import moreOptions from "../../assets/more_op.svg";
import {
  ADVANCESEARCHINVENTORY,
  CREATESILENTAUCTION,
  DELETEINVENTORY,
  DELETEINVENTORYUSINGBLOB,
  GENERATELOTCTRLREC,
  GETALLAUCTIONLIST,
  GETALLFUTUREAUCTION,
  GETALLLOTS, GETALLMASTERINVENTORY, GETAUCTIONLOTS, GETAUCTIONSELLERMAPPING, GETCHECKLISTBYAUCTIONID,
  GETSELLER,
  IMPORTLOTEXCEL,
  MOVELOTBETWEENAUCTION,
  REARRANGELOTFORAUCTION, SWAPLOT,
  TAGMASTERINVTOAUCTION
} from "../../common/apiConstatnts";
import Helper from "../../common/helpers";
import { Breadcrumb } from "../../common/interface";
import {
  CommonMessages,
  errorMessages,
  InventoryMessages
} from "../../common/messageConstants";
import { INTEGER, INTEGER_MESSAGE } from "../../common/regexConstants";
import * as routeConstant from "../../common/routeConstants";
import { encryptId } from "../../Encryption/Encryption";
import {
  setAuctionDataToStore,
  setGeneratedLotCtrlRec,
  setInventoryData,
  setLogPage,
  setStep
} from "../../store/actions/Inventory";
import DateTime from "../../Utils/DateTime";
import MomentUtil from "../../Utils/MomentUtil";
import {
  convertSecondsToMinutesAndSeconds,
  ERROR_MESSAGES,
  handleKeyDown, removeHtmlTags, trimObjectValues,
  useDebounce
} from "../../Utils/Utils";
import AddNewLotModal from "../AddNewLotModal/AddNewLotModal";
import InnerHeaderTab from "../InnerHeader/InnerHeaderTab/InnerHeaderTab";
import Sidebar from "../Sidebar/Sidebar";
import * as actions from "../../common/types";
import handleError from "../../Utils/ApiCommonErrorMessages";
import { Helmet } from "react-helmet";
import CommonHelmet from "../../common/HelmetWrapper";
import { setMode as modeFromInvStore } from "../../store/actions/Inventory";
import TimeSelector from "../../Utils/MinSec";

const breadcrumbData: Breadcrumb[] = [
  { label: "Home", url: "/" },
  { label: "Inventory", url: routeConstant.INVENTORY },
];

const Inventory = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const initialData = {
    lotId: "",
    lotNo: "",
    ctrlNo: "",
    title: "",
    auctionType: "",
    strAuctionType: "",
    statusnew: "",
    taxExempt: "",
    taxRate: "",
    sellerId: "",
    quantity: "",
    masterInventory: "",
  };

  const initialSilent = {
    auctionId: 0,
    startDate: "",
    startTime: "",
    endTime: "",
    durationMin: "0",
    durationSec: "0",
    lotNumber: "",
    lotsInGroup: "",
    timeBtwGroupMin: "0",
    timeBtwGroupSec: "0",
    ring: "",
    // dynamicExtension: 0,
    dynamicExtensionMin: "0",
    dynamicExtensionSec: "0",
  };

  const dateRangeData = useSelector((state: any) => state.dashboard.dateRange);

  const config: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const getPermission: any = Helper.decodePermissions()?.find(

    (data: any) => data.moduleName === "Inventory"
  );
  const [dragStartData, setDragStartData] = useState<any>({});
  const [dragEnterData, setDragEnterData] = useState<any>({});
  const [permission] = useState<any>(getPermission);
  const [excel, setExcel] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterLots, setFilterLots] = useState<any>([]);
  const [mode, setMode] = useState<string>("create");
  const [showFilter, setShowFilter] = useState(false);
  const [toggleModal, setToggleValue] = useState<boolean>(false);
  const [silentToggleModal, setSilentToggleValue] = useState<boolean>(false);
  const [excelUpload, setExcelUpload] = useState<boolean>(false);
  const [toggleSwap, setToggleSwap] = useState<boolean>(false);
  const [fromAuctionData, setFromAuctionData] = useState<any>();
  const [toggleMove, setToggleMove] = useState<boolean>(false);
  const [toggleRearrange, setToggleRearrange] = useState<boolean>(false);
  const [toggleRenumber, setToggleRenumber] = useState<boolean>(false);
  const [toggleMasterMap, setToggleMasterMap] = useState<boolean>(false);
  const [selectedAuction, setSelectedAuction] = useState<any>("");
  const [masterInvLots, setMasterInvLots] = useState<any>([]);
  const [originalMasterInv, setOriginalMasterInv] = useState<any>([]);
  const [checkMasterInv, setCheckMasterInv] = useState<any>([]);
  const [lots, setLots] = useState<[]>([]);
  const [originalLots, setOriginalLots] = useState<any[]>([]);
  const [cloneData, setCloneData] = useState<any>({});
  const [futureAuctionData, setFutureAuctionData] = useState<any>([]);
  const [formatedAuctionData, setFormatedAuctionData] = useState<any>([]);
  const [cloneId, setCloneId] = useState<any>(0);
  const [errors, setErrors] = useState({ cloneList: "" });
  const [toLotNos, setToLotNos] = useState<any>([]);
  const [fromLotData, setFromLotData] = useState<any>();
  const [rearrangeAuctionId, setRearrangeAuctionId] = useState<any>("");
  const [lotsForRenumber, setLotsForRenumber] = useState<any>([]);
  const [toggleDeleteModal, setToggleDeleteModal] = useState<any>(false);
  const [currentAuction, setCurrentAuction] = useState<any>("");
  const [deleteLotId, setDeleteLotId] = useState<any>();
  const [sortedInputValue, setSortedInputValue] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [auctionList, setAuctionList] = useState<any>([]);
  const [sellerList, setSellerList] = useState<any>([]);

  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] = useState<any>({});
  const [paginationSize, setPaginationSize] = useState<any>(process.env.REACT_APP_PAGESIZE);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [defaultDate, setDefaultDate] = useState({
    startDateTime: "",
    endDateTime: "",
  });

  const globalAuctionHammerStatus = useSelector((state: any) => state?.global?.auctionHammerData?.auctionStatus);
  const hammerAuctionId = useSelector((state: any) => state?.global?.auctionHammerData?.value);

  const [auctionData, setAuctionData] = useState<any>(null)

  const [filterData, setFilterData] = useState<any>({
    lotId: "",
    auctionCode: "",
    lotNo: "",
    masterInventory: -1,
    ctrlNo: "",
    title: "",
    strAuctionType: "",
    statusnew: "",
    taxExempt: -1,
    taxRate: "",
    sellerId: "",
    quantity: "",
    isActive: -1,
    silentAuction: -1,
    // auctionTitle: auctionTitle ?? "",
    auctionId: hammerAuctionId ? hammerAuctionId : "",
    displayLotNo: "",
  });
  // useEffect(() => {

  //   // const handleStorageChange = (event: StorageEvent) => {

  //   //   const localValue = localStorage.getItem("auctionTitle")
  //   //   if (event.key === 'auctionTitle' && localValue !== "undefined") {
  //   //     const localAuctionIdValue = localStorage.getItem("auctionId")
  //   //     if (hammerAuctionId !== localAuctionIdValue) {
  //   //       setGlobalAuctionId(localValue);
  //   //     }
  //   //   }
  //   // }

  //   const handleStorageChange = (event: StorageEvent) => {
  //     const localValue = localStorage.getItem("auctionTitle")
  //     const localAuctionIdValue = localStorage.getItem("auctionId")
  //     if (event.key === 'auctionId') {
  //       if (hammerAuctionId !== localAuctionIdValue) {
  //         // If not then set new value to auction title
  //         setGlobalAuctionId(localAuctionIdValue);
  //       }
  //     }
  //   }

  //   window.addEventListener("storage", handleStorageChange);
  // }, []);


  // useEffect(() => {
  //   if (storedValue !== null) {
  //     // Call your API here
  //     setGlobalAuctionId(storedValue);
  //   }
  // }, [storedValue]);

  //don't remove for now - Check localstorage update and update title instantly from localstorage
  // useEffect(() => {
  //   const handleStorageChange = (event: StorageEvent) => {
  //     const localValue: any = hammerAuctionId;
  //     if (event.key === 'auctionId' && localValue !== "undefined") {
  //       if (hammerAuctionId !== localValue) {
  //         setGlobalAuctionId(localValue);
  //       }
  //     }
  //   }
  //   window.addEventListener("storage", handleStorageChange);
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   }
  // }, [hammerAuctionId]);



  // Check update title instantly using redux

  useEffect(() => {
    if (hammerAuctionId) {
      setShowFilter(true);
      setAlreadyFetchedDataPageNumber({})
      const resetFilter = {
        startDateTime: '',
        endDateTime: '',
        lotId: "",
        ctrlNo: "",
        title: "",
        statusnew: "",
        strAuctionType: "",
        taxExempt: -1,
        sellerId: "",
        quantity: "",
        masterInventory: -1,
        isActive: -1,
        silentAuction: -1,
        auctionTitle: "",
        auctionId: hammerAuctionId,
        pageNumber: currentPage == 0 ? currentPage + 1 : currentPage,
      }
      setFilterData(resetFilter)
      clearSearchBar()
      currentPage != 1 ? setCurrentPage(1) : clearAdvanceFilterData(false, resetFilter)

    } else {
      clearAdvanceFilterData();
      clearSearchBar()
      setShowFilter(false);
    }
  }, [hammerAuctionId]);


  // const [searchText, setSearchText] = useState("");
  const numbers = Array.from({ length: 59 }, (_, index) => index + 1);
  const extNumbers = Array.from({ length: 59 }, (_, index) => index < 9 ? `0${index + 1}` : index + 1);
  const PER_PAGE = 10;
  const pageCount = Math.ceil(lots.length / PER_PAGE);
  const [showModal, setShowModal] = useState<boolean>(false);
  const momentUtil = MomentUtil.getInstance();
  const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();

  const handleShowModal = () => {
    if (!permission.isAllowAdd) {
      Helper.unAuthorized();
      return;
    }
    else {
      setShowModal(true);
    }
    // You can also perform any additional logic here if needed
  };
  const handleCloseModal = () => {
    setSelectedAuction("")
    setShowModal(false)
  }

  useEffect(() => {
    dispatch(setStep("lots"));
    dispatch(setInventoryData({}));
    return () => { };
  }, []);

  const schema: any = yup.object().shape({
    cloneList: yup.object({
      value: yup.string().required("Please select a lot template"),
    }),
  });

  const schema2: any = yup.object().shape({
    auctionId: yup
      .string()
      .required("Please select a Auction Title")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("valid-string", "", function (value) {
        if (parseInt(value) == 0) {
          return this.createError({
            message: "Please select Auction Title",
          });
        }
        return true;
      }),
    // startDate: yup
    //   .string()
    //   .required("Start Date is required")
    //   .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   }),
    // startTime: yup
    //   .string()
    //   .required("Start Time is required")
    //   .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   }),
    // endTime: yup
    //   .string()
    //   .required("End Time is required")
    //   .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   }),
    // duration: yup.string().required("Duration is required"),
    lotNumber: yup
      .string()
      .required("Lot Number is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .matches(/^[0-9 ,-]*$/, "Only valid format allowed for this Lot Number"),
    lotsInGroup: yup
      .string()
      .required("Lots in Group is required")
      .test(
        "no-blank-space",
        ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED,
        (value) => {
          return value ? /^\S*$/g.test(value) : true;
        }
      )
      .matches(INTEGER, INTEGER_MESSAGE)
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      }),
    // timeBtwGroupSec: yup.string().required("Time Btw Group is required")
    //   .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   })
    //   .test("time-req", "Time Btw Group is required", function (value) {
    //     const min = watch('timeBtwGroupMin')
    //     if (value == '0' && min == '0') {
    //       return this.createError({
    //         message: 'Time Btw Group is required',
    //       });
    //     };
    //     return true;
    //   }),
    // timeBtwGroupMin: yup.string().required("Time Btw Group is required")
    //   .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
    //     if (!value) return true; // If value is empty, it's handled by the .required() validation
    //     return !/^\s/.test(value); // Check if value doesn't start with a space
    //   })
    //   .test("time-req", "Time Btw Group is required", function (value) {
    //     const sec = watch('timeBtwGroupSec')
    //     if (value == '0' && sec == '0') {
    //       return this.createError({
    //         message: 'Time Btw Group is required',
    //       });
    //     };
    //     return true;
    //   }),

    durationSec: yup
      .string()
      .required("Duration is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("time-req", "Duration is required", function (value) {
        const min = watch("durationMin");
        if (value == "0" && min == "0") {
          return this.createError({
            message: "Duration is required",
          });
        }
        return true;
      }),
    durationMin: yup
      .string()
      .required("Duration is required")
      .test("no-leading-space", ERROR_MESSAGES.NO_LEADING_SPACES, (value) => {
        if (!value) return true; // If value is empty, it's handled by the .required() validation
        return !/^\s/.test(value); // Check if value doesn't start with a space
      })
      .test("time-req", "Duration is required", function (value) {
        const sec = watch("durationSec");
        if (value == "0" && sec == "0") {
          return this.createError({
            message: "Duration is required",
          });
        }
        return true;
      }),
    ring: yup
      .number()
      .typeError("Ring must be a number")
      .notRequired()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      }),
    // timeBtwGroup: yup.string().required("Time Between Groups is required"),
  });

  const auctionTypes = [
    { value: "", label: "All" },
    { value: "1", label: "Land Auction" },
    { value: "2", label: "Equipment Auction" },
    { value: "3", label: "Business Auction" },
  ];

  const inventoriesStatus = [
    // { value: "0",label: "Select Status" },
    { value: "", label: "All" },
    { value: "Sold", label: "Sold" },
    { value: "Passed", label: "Passed" },
    { value: "BuyBack", label: "Buy Back" },
    { value: "NonPayer", label: "Non-Payer" },
    { value: "Posted", label: "Posted" },
    { value: "Not Posted", label: "Not Posted" },
    { value: "Pending", label: "Pending" },
  ];

  const silentStatus = [
    {
      label: "All",
      value: -1,
    },
    {
      label: "Silent",
      value: 1,
    },
    {
      label: "Non-Silent",
      value: 0,
    },
  ];

  const taxExemptStatus = [
    {
      label: "All",
      value: -1,
    },
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const lotNoStatus = [
    {
      label: "All",
      value: -1,
    },
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const { handleSubmit, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialData,
    mode: "onChange",
  });

  const {
    register: silentRegister,
    handleSubmit: handleSilentSubmit,
    getValues: getSiletValues,
    setValue: setSilentValues,
    reset: resetSilent,
    watch,
    clearErrors,
    control,
    formState: { errors: silentErrors },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: initialSilent,
    mode: "onChange",
  });
  const a = getSiletValues();

  const auctionId = watch("auctionId");
  const timeBtwGroupSec: any = watch("timeBtwGroupSec");
  const timeBtwGroupMin = watch("timeBtwGroupMin");
  const durationSec = watch("durationSec");
  const durationMin = watch("durationMin");
  const dynamicExtensionMin = watch("dynamicExtensionMin");
  const dynamicExtensionSec = watch("dynamicExtensionSec");

  const extractTextFromHTML = (html: string): string => {
    // Replace non-breaking spaces with regular spaces
    const normalizedHTML = html?.replace(/&nbsp;/g, " ");

    const doc = new DOMParser().parseFromString(normalizedHTML, "text/html");
    const textContent = doc.body.textContent || "";

    // Remove consecutive spaces and trim the text
    const trimmedTextContent = textContent?.replace(/\s+/g, " ").trim();

    return trimmedTextContent;
  };

  const getAllLots = (selectedAuctionForRearrange: any = null, pageNum: any = 1) => {
    if (hammerAuctionId && hammerAuctionId?.length !== 0) {
      setShowFilter(true);
      handleAdvanceFilter(filterData);
      return;
    }
    setLoading(true);
    axios
      .get(`${GETALLLOTS}?pageNumber=${pageNum}&pageSize=${paginationSize}`, config)
      .then((response: any) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          setFilterLots([]);
          return;
        } else {
          const lotData = response?.data?.data?.data;
          setOriginalLots(lotData);
          setTotalRecords(response.data.data.totalRecords);
          paginateData(lotData, pageNum);

          if (selectedAuctionForRearrange) {
            const lotDataUpdated = lotData.filter(
              (oData: any) => oData.auctionId === selectedAuctionForRearrange
            );

            setLotsForRenumber(lotDataUpdated);
          }
          reset(lotData);
          const sd = lotData.map((ud: any) => {
            return { label: extractTextFromHTML(ud.title), value: ud.lotId };
          });
          setCloneData(sd);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onGetAllMasterInventory = () => {
    axios.get(GETALLMASTERINVENTORY, config).then((response) => {
      // setLoading(false);
      if (response?.data?.success === "Fail") {
        // toast.error(response?.data?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 2000,
        // });
        return;
      } else {
        let masterInv = response?.data?.data;
        masterInv = masterInv.map((mData: any) => {
          return { ...mData, isCheck: false };
        });
        setMasterInvLots(masterInv);
        setOriginalMasterInv(masterInv);
      }
      // setLoading(false);
    });
  };

  const getAllFutureAuctions = () => {
    // setLoading(true);
    axios.get(GETALLFUTUREAUCTION, config).then((response) => {
      // setLoading(false);
      if (response?.data?.success === "Fail") {
        // toast.error(response?.data?.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 2000,
        // });
        return;
      } else {
        const futureAuction = response.data;
        let formateData = futureAuction?.data?.map((data: any, index: any) => {
          return {
            label: `${data.auctionCode} - ${data.auctionTitle}`,
            value: data.auctionId,
          };
        });
        setFutureAuctionData(futureAuction.data);
        setFormatedAuctionData(formateData);
      }
      // setLoading(false);
    });
  };

  const handleSorting = (type: string, fieldName: string) => {
    let inventory;
    if (type === "ASC") {
      if (fieldName == "lotNo" || fieldName == "ctrlNo") {
        inventory = filterLots.sort((a: any, b: any) => a[fieldName] - b[fieldName]);
      } else {
        inventory = filterLots.sort((a: any, b: any) =>
          a[fieldName].toLocaleLowerCase() > b[fieldName].toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    } else {
      if (fieldName == "lotNo" || fieldName == "ctrlNo") {
        inventory = filterLots.sort((a: any, b: any) => b[fieldName] - a[fieldName]);
      } else {
        inventory = filterLots.sort((a: any, b: any) =>
          a[fieldName].toLocaleLowerCase() < b[fieldName].toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    }
    paginateData(inventory,currentPage);
    // setCurrentPage(1);
  };

  const handleAdvanceFilter = (filterData: any, pageNum?: any) => {
    // return
    setLoading(true);
    const data = trimObjectValues(filterData);
    axios
      .get(ADVANCESEARCHINVENTORY, {
        ...config,
        params: {
          // lotNo: data?.lotNo,
          displayLotNo: data?.displayLotNo,
          ctrlNo: data?.ctrlNo,
          auctionCode: data?.auctionCode,
          title: data?.title,
          auctionType: data?.strAuctionType?.value,
          statusnew: data?.statusnew?.value,
          taxExempt: data?.taxExempt?.value ?? -1,
          sellerId: data?.role?.value,
          quantity: data?.quantity,
          sold: data?.sold,
          isActive: data?.isActive?.value ?? -1,
          silentAuction: data?.silentAuction?.value ?? -1,
          masterInventory: data?.masterInventory?.value ?? -1,
          AuctionTitle: data?.auctionTitle?.value,
          auctionId: data?.auctionId,
          keyword: data?.keyword ?? "",
          pageNumber: pageNum ? pageNum : currentPage == 0 ? currentPage + 1 : currentPage,
          pageSize: paginationSize
        },
      }).then((response) => {
        setLoading(false);
        if (response?.data?.success === "Fail") {
          setFilterLots([]);
          return;
        } else {
          const lotData = response?.data?.data?.data || []
          paginateData(lotData, pageNum ? pageNum : currentPage);
          setTotalRecords(response?.data?.data?.totalRecords);
          reset(lotData);
          const sd = lotData?.map((ud: any) => {
            return { label: extractTextFromHTML(ud.title), value: ud.lotId };
          });
          setCloneData(sd);
        }
      }, (error) => {
        setLoading(false);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate(routeConstant.SIGNIN);
        }
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setFilterLots([]);
      });
    return;
  };

  let filterSilentAuction = silentStatus.map((data: any) => {
    return {
      label: data.label,
      value: data.value,
    };
  });

  let filterLotNoStatus = lotNoStatus.map((data: any) => {
    return {
      label: data.label,
      value: data.value,
    };
  });

  let filterTaxExempt = taxExemptStatus.map((data: any) => {
    return {
      label: data.label,
      value: data.value,
    };
  });

  const clearSearchBar = () => {
    const searchbar: any = document.getElementById('searchBar')
    searchbar.value = ''
  }

  const clearAdvanceFilterData = (isLocalClear: boolean = true, data: any = null) => {
    setAlreadyFetchedDataPageNumber({});
    setDefaultDate({ startDateTime: "", endDateTime: "" });
    currentPage !== 1 && setCurrentPage(1);
    setError({ displayLotNo: "", ctrlNo: "", title: "" });
    if (data == null) {
      setIsFiltered(false);
      const resetFilter = {
        displayLotNo: "",
        ctrlNo: "",
        title: "",
        statusnew: "",
        role: [],
        auctionCode: "",
        strAuctionType: "",
        taxExempt: -1,
        sellerId: "",
        quantity: "",
        isActive: -1,
        silentAuction: -1,
        masterInventory: -1,
        auctionTitle: "",
        auctionId: isLocalClear ? "" : hammerAuctionId,
      }
      setFilterData(resetFilter);
      handleAdvanceFilter(resetFilter, 1);
      clearSearchBar()
      setFilterData(resetFilter);
    }
    else if (!isLocalClear) {
      setFilterData(data)
      handleAdvanceFilter(data, 1);
    }
  };

  const [error, setError] = useState({
    displayLotNo: "",
    ctrlNo: "",
    title: "",
  });

  const changeHandler = (fieldName: string, value: any) => {
    if (fieldName === "auctionTitle" && currentAuction !== "") {
      setCurrentAuction("");
      paginateData(originalLots);
    }
    const updatedData = { ...filterData, [fieldName]: value };
    setFilterData(updatedData);
    if (fieldName === "lotNo" || fieldName === "ctrlNo") {
      const isValidNumber = /^[0-9]*$/.test(value) && value.length <= 20;
      const hasLeadingSpace = /^\s/.test(value);
      if (hasLeadingSpace) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else if (!isValidNumber) {
        setError({
          ...error,
          [fieldName]: "Please enter numbers only upto 20 digits",
        });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }
    if (fieldName === "title") {
      const isValidTitle = !/^\s/.test(value);
      if (!isValidTitle) {
        setError({ ...error, [fieldName]: ERROR_MESSAGES.NO_LEADING_SPACES });
        return;
      } else {
        setError({ ...error, [fieldName]: "" });
      }
    }
  };

  const handleDeleteModal = (flag: any, msg: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${msg}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#006800",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteLotOption = flag;
        const URL = deleteLotOption === 2 ? DELETEINVENTORYUSINGBLOB : DELETEINVENTORY
        setLoading(true);
        axios.post(`${URL}?lotId=${deleteLotId}&deleteLotOption=${deleteLotOption}`, { ...config }).then((response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            setTimeout(() => {
              toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }, 300);
            setToggleDeleteModal(false);
          }
          clearAdvanceFilterData(false, filterData);
        }).catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
      }
    });
  };

  const handleEditModal = (lotId: any, type: string) => {
    if (type === "edit") {
      if (!permission?.isAllowEdit) {
        Helper.unAuthorized();
        return;
      }
      // navigate(`${routeConstant.UPDATEINVENTORY}/${encryptId(lotId)}`);
      navigate(routeConstant.UPDATEINVENTORY + "/" + lotId, {
        state: { type: "edit" },
      });
    }
  };

  const handleClose = () => {
    setCloneId(0);
    setErrors({ cloneList: "" });
    // clearAdvanceFilterData();
    setToggleValue(!toggleModal);
    reset(initialData);
  };

  const handleSilentClose = () => {
    setErrors({ cloneList: "" });
    setSilentToggleValue(!silentToggleModal);
    // clearAdvanceFilterData();
    resetSilent(initialSilent);
  };

  const handleUploadClose = () => {
    setExcelUpload(!excelUpload);
    // clearAdvanceFilterData();
    setExcel("");
  };

  const dateRangehandler = () => {
    if (dateRangeData.fromDate) {
      setDefaultDate({
        startDateTime: dateRangeData.fromDate,
        endDateTime: dateRangeData.toDate,
      });
      handleAdvanceFilter({
        startDateTime: dateRangeData.fromDate,
        endDateTime: dateRangeData.toDate,
        lotId: "",
        ctrlNo: "",
        title: "",
        statusnew: "",
        strAuctionType: "",
        taxExempt: -1,
        sellerId: "",
        quantity: "",
        masterInventory: -1,
        isActive: -1,
        silentAuction: -1,
        auctionTitle: "",
        pageNumber: currentPage == 0 ? currentPage + 1 : currentPage
      });
    }
  };

  const handlePageClick = (selected: any) => {
    const newPageNumber = selected + 1;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(newPageNumber);
  };

  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const paginateData = (data: any, pageNum: any = 1) => {
    let page: any = pageNum == 0 ? pageNum + 1 : pageNum;

    updateData(page, data);
    setFilterLots(data);
  };

  const debouncedRequest = useDebounce(() => {
    handleAdvanceFilter({ ...filterData });
  });

  // this useEffect called when click on pageNumber
  useEffect(() => {
    //when global search applied
    if (filterData?.keyword) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterLots(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      handleAdvanceFilter(filterData);
      return;
    }

    //when redirected from dashboard with countbox click.
    else if (dateRangeData.default == false || defaultDate?.startDateTime) {
      dateRangehandler();
      return;
    }

    //when advanced search filter applied
    else if (isFiltered) {
      //this prevents dual API call
      if (currentPage == 0) {
        return;
      }
      //when clicked on already fetched page
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterLots(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }

      handleAdvanceFilter(filterData);
      return;
    }

    //when clicked on already fetched page
    else {
      if (alreadyFetchedDataPageNumber?.hasOwnProperty(currentPage)) {
        setFilterLots(alreadyFetchedDataPageNumber[currentPage]);
        return;
      }
      getAllLots(null, currentPage);
    }
  }, [currentPage]);

  //----------------------------------------------------------------

  useEffect(() => {
    getAllFutureAuctions();
    getAllAuctions();
    onGetAllMasterInventory()
    dispatch({ type: actions.RESETINV });
  }, []);

  const for_loop = [];
  for (let i = 0; i <= 60; i++) {
    for_loop.push(<option value={i}>{i}</option>);
  }

  const onSubmit = () => {
    const data = getValues();
    setLoading(true);
  };

  const convertToDateOnlyFormat = (dateTimeString: any) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const onSilentSubmit = () => {
    const dataOriginal = getSiletValues();
    const data = trimObjectValues(dataOriginal);
    const startDate = momentUtil.convertTimeToUtc(data.startDate);
    const endDateTime = momentUtil.convertTimeToUtc(data.endTime);
    const formattedStartDate = convertToDateOnlyFormat(startDate);

    setLoading(true);
    const timeBtw: any = `00:${data.timeBtwGroupMin < 10
      ? "0" + data.timeBtwGroupMin
      : data.timeBtwGroupMin
      }:${data.timeBtwGroupSec < 10
        ? "0" + data.timeBtwGroupSec
        : data.timeBtwGroupSec
      }`;

    const durationTime: any = `00:${data.durationMin < 10
      ? "0" + data.durationMin
      : data.durationMin
      }:${data.durationSec < 10
        ? "0" + data.durationSec
        : data.durationSec
      }`;
    const dynamicExtensionSecNumber = parseInt(data.dynamicExtensionSec, 10);
    const dynamicExtensionMinNumber = parseInt(data.dynamicExtensionMin, 10);
    const dynamicExtensionTime: any = `00:${dynamicExtensionMinNumber < 10
      ? "0" + dynamicExtensionMinNumber
      : dynamicExtensionMinNumber
      }:${dynamicExtensionSecNumber < 10
        ? "0" + dynamicExtensionSecNumber
        : dynamicExtensionSecNumber
      }`;
    const dynamicExtensionSeconds =
      dynamicExtensionMinNumber * 60 + dynamicExtensionSecNumber;
    const startTime = auctionData?.startDateTime?.split('T')[1]
    const endTime = auctionData?.endDateTime?.split('T')[1]

    const silentData = {
      ...data,
      startTime,
      endTime,
      startDate: formattedStartDate,
      timeBtwGroup: timeBtw,
      duration: durationTime,
      // dynamicExtensions: dynamicExtensionSeconds,
      dynamicExtensions: dynamicExtensionTime
    };
    // return
    axios
      .post(CREATESILENTAUCTION, silentData)
      .then((response) => {
        if (response?.data?.success === "Fail") {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          toast.success(InventoryMessages.inventorySilentUpdated, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        clearAdvanceFilterData(false, filterData)
        setLoading(false);
        handleSilentClose();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(errorMessages.error401, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const cloneInventoryTemplate = () => {
    const newcloneId = encryptId(cloneId?.value);
    if (cloneId != 0) {
      if (!permission.isAllowAdd) {
        Helper.unAuthorized();
        return;
      }
      setErrors({ cloneList: "" });
      // navigate(`${routeConstant.CLONEINVENTORY}/${encryptId(cloneId?.value)}`);
      navigate(routeConstant.CLONEINVENTORY + "/" + newcloneId, {
        state: { type: "clone" },
      });
    } else {
      setErrors({ cloneList: "Please Select Atleast One Template" });
    }
  };

  const cloneInventoryTemplateId = (id: any) => {
    setErrors({ cloneList: "" });
    setCloneId(id);
  };

  const auctionChange = (value: any) => {
    if (value > 0) {
      axios
        .get(`${GETCHECKLISTBYAUCTIONID}?AuctionId=${value}`, config)
        .then((response) => {
          if (response?.data?.success === "Fail") {
            return;
          } else {
            const respData = response?.data?.data
            const dextentionData = respData.dynamicExtension ?? "00:00:00"
            const dynamicExtension = dextentionData.split(':');
            const modifiedDynamicExtension = dynamicExtension.map((num: any) => num.replace(/^0+/, '') || '0');
            setSilentValues("dynamicExtensionMin", modifiedDynamicExtension[1])
            setSilentValues("dynamicExtensionSec", modifiedDynamicExtension[2])
          }
        });
      let d = futureAuctionData?.find((item: any) => item.auctionId == value);
      setAuctionData(d)
      const startDate = d?.startDateTime;
      const timeZoneStartDate: any = momentUtil.parseTimeInUtc({
        dateTimeString: d?.startDateTime,
      });
      const timeZoneEndDate: any = momentUtil.parseTimeInUtc({
        dateTimeString: d?.endDateTime,
      });
      setSilentValues("startDate", timeZoneStartDate);
      setSilentValues("endTime", timeZoneEndDate);
      return;
    }
    setSilentValues("startDate", "");
    setSilentValues("endTime", "");
  };

  const handleDownloadClick = () => {
    const downloadUrl = `${process.env.REACT_APP_URL}/assets/inventoryImportTemplate.xlsx`;
    // const downloadUrl = 'http://20.213.247.145:8080/Asset/InventoryExcel/Inventory Import_db45_09282023.xlsx';
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = "Inventory_Import_Template.xlsx";
    anchor.click();
  };

  const handleExcelChange = (event: any) => {
    const file = event.target.files[0];
    const getExt = file.name?.split(".")[1];
    if (getExt !== "xlsx") {
      toast.error(errorMessages.xlsxFormat, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setExcel(file);
  };

  // const handleImport = () => {

  //   const formData: any = new FormData();
  //   formData.append("file", excel);
  //   setLoading(true);
  //   axios.post(IMPORTLOTEXCEL, formData, {
  //       ...config.headers,
  //       enctype: "multipart/form-data",
  //     })
  //     .then(
  //       (response) => {

  //         setLoading(false);
  //         handleUploadClose();
  //         if (response?.data?.success === "Fail") {
  //           toast.error(response?.data?.message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 2000,
  //           });
  //           return;
  //         } else {
  //           setLoading(false);
  //           toast.success(
  //             InventoryMessages.exportExcelProcessing,
  //             {
  //               position: toast.POSITION.TOP_RIGHT,
  //               autoClose: 2000,
  //             }
  //           );
  //         }
  //       },
  //       (error) => {
  //         setLoading(false);
  //         if (error.response.status == 401) {
  //           localStorage.clear();
  //           navigate(routeConstant.SIGNIN);
  //         }
  //         toast.error(errorMessages.error401, {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 2000,
  //         });
  //       }
  //     );
  // };

  const handleImport = () => {
    const formData: any = new FormData();
    formData.append("file", excel);
    setLoading(true);

    axios
      .post(IMPORTLOTEXCEL, formData, {
        ...config.headers,
        enctype: "multipart/form-data",
      })
      .then((response) => {
        setLoading(false);
        handleUploadClose();
        if (response?.data?.success === "Fail") {
          Swal.fire({
            text: response?.data?.message,
            icon: "error",
            confirmButtonColor: "#006800",
            confirmButtonText: "ok",
          });
        } else {
          Swal.fire({
            text: InventoryMessages.exportExcelProcessing,
            icon: "success",
            confirmButtonColor: "#006800",
            confirmButtonText: "ok",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate(routeConstant.SIGNIN);
        }
        toast.error(errorMessages.error401, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const handleSwapLot = (data: any) => {
    const auctionId = data?.auctionId;
    if (auctionId > 0) {
      setLoading(true)
      axios.get(`${GETAUCTIONLOTS}?AuctionId=${auctionId}`, config).then((response: any) => {

        if (response?.data?.success === "Fail") {
          return;
        }
        else {
          const existingLotIds = data?.lotId // Replace `data` with your actual data source
          const respData = response?.data?.data;
          const filteredRespData = respData?.filter(
            (lData: any) => lData?.lotId !== existingLotIds
          );
          const lotData = filteredRespData?.map((lData: any) => {
            return { label: `${lData?.displayLotNo} - ${removeHtmlTags(lData?.title)}`, value: lData?.lotNo };
          });
          setToLotNos(lotData);
          setFromLotData({
            lotLabel: `${data?.displayLotNo} - ${removeHtmlTags(data?.title)}`,
            auctionId: data?.auctionId,
            auctionCode: data?.auctionCode,
            fromLotNo: data?.lotNo,
            auctionTitle: data?.auctionTitle,
          });
          setLoading(false);
          setToggleSwap(true);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      })
    }
    else {
      toast.error(errorMessages.mapBeforeSwap, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

  };
  const handleSwapClose = () => {
    setToLotNos([]);
    setFromLotData({});
    // clearAdvanceFilterData();
    setToggleSwap(false);
  };


  const handleSwap = () => {
    setLoading(true);
    axios
      .post(
        `${SWAPLOT}?auctionId=${fromLotData?.auctionId}&fromLotNo=${fromLotData?.fromLotNo}&toLotNo=${fromLotData?.toLotNo}`
      )
      .then(
        (response) => {
          setLoading(false);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            toast.success(InventoryMessages.lotSwap, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          handleSwapClose();
          clearAdvanceFilterData(false, filterData);
        },
        (error) => {
          setLoading(false);
          handleSwapClose();
          handleError(error);
        }
      );
  };

  const handleMoveLot = (data: any) => {
    if (data.auctionId === null) {
      toast.error(errorMessages.mapBeforeMove, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setToggleMove(true);
    const auctionsExceptCurrent = futureAuctionData.filter(
      (fData: any) => fData.auctionId !== data.auctionId
    );
    const mapAuctionOptions = auctionsExceptCurrent.map((aData: any) => {
      return {
        value: aData.auctionId,
        label: `${aData.auctionCode} - ${aData.auctionTitle}`,
      };
    });

    const parser = new DOMParser();
    const titleDocument = parser.parseFromString(data.title, "text/html");
    const lotTitle = titleDocument.body.textContent || "";

    setFromAuctionData({
      auctionFrom: data.auctionId,
      auctionOptions: mapAuctionOptions,
      lotNo: data.lotNo,
      lotTitle: lotTitle,
      auctionTitle: `${data.auctionTitle}`,
      lotId: data?.lotId
    });
  };

  const handleMove = () => {
    if (fromAuctionData?.auctionFrom == 0) {
      const payload = {
        auctionId: fromAuctionData?.toAuction,
        lstlotRequestModel: [{ lotId: fromAuctionData?.lotId, lotNo: 0 },],
      };
      handleAssignClick(payload)
      return
    }
    setLoading(true);
    axios.post(`${MOVELOTBETWEENAUCTION}?fromAuctionId=${fromAuctionData.auctionFrom}&toAuctionId=${fromAuctionData.toAuction}&lotNo=${fromAuctionData.lotNo}`).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        toast.success(InventoryMessages.lotMoved, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
      handleMoveClose();
      clearAdvanceFilterData(false, filterData);
    }, (error) => {
      setLoading(false);
      handleMoveClose();
      if (error.response.status == 401) {
        localStorage.clear();
        navigate(routeConstant.SIGNIN);
      }
      toast.error(errorMessages.error401, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
  };

  const handleMoveClose = () => {
    setFromAuctionData({});
    setToggleMove(false);
  };

  const handleRearrange = () => {
    setLoading(true);
    axios
      .post(`${REARRANGELOTFORAUCTION}?auctionId=${rearrangeAuctionId}`)
      .then(
        (response) => {
          setLoading(false);
          getAllLots(rearrangeAuctionId);
          if (response?.data?.success === "Fail") {
            toast.error(response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            return;
          } else {
            toast.success(InventoryMessages.lotRearrange, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          handleRearrangeClose();
        },
        (error) => {
          setLoading(false);
          handleRearrangeClose();
          if (error.response.status == 401) {
            localStorage.clear();
            navigate(routeConstant.SIGNIN);
          }
          if (error.response.status !== 401 && error.response.status !== 404) {
            toast.error(errorMessages.error401, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        }
      );
  };

  const handleRearrangeClose = () => {
    setToggleRearrange(false);
    setRearrangeAuctionId("")
  };

  // // Re-number lots
  // const handleRenumber = () => {
  //   if (inputValue.length !== lotsForRenumber.length) {
  //     toast.error('All the input fields are required !', {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 2000,
  //     })
  //   } else {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You want to swap lots!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#006800",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, Swap it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         setLoading(true);
  //         const filterEmpty = lotsForRenumber.filter(
  //           (lData: any) => Object.keys(lData).length > 0
  //         );
  //         const oldNewLotData = filterEmpty.map((fData: any) => {
  //           return { oldLotNo: fData.lotNo, newLotNo: fData.newLotNo };
  //         });
  //         const payload = {
  //           auctionId: filterEmpty[0]?.auctionId ?? [],
  //           lstReArrangeLots: oldNewLotData,
  //         };
  //         axios.post(REARRANGEMULTIPLELOTSFORAUCTION, payload).then(
  //           (response) => {
  //             setLoading(false);
  //             getAllLots();
  //             if (response?.data?.success === "Fail") {
  //               toast.error(response?.data?.message, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //                 autoClose: 2000,
  //               });
  //               return;
  //             } else {
  //               toast.success(InventoryMessages.lotRenumbering, {
  //                 position: toast.POSITION.TOP_RIGHT,
  //                 autoClose: 2000,
  //               });
  //             }
  //             handleRenumberClose();
  //           },
  //           (error) => {
  //             setLoading(false);
  //             handleRenumberClose();
  //             if (error.response.status == 401) {
  //               localStorage.clear();
  //               navigate(routeConstant.SIGNIN);
  //             }
  //             toast.error(error?.response?.data?.message, {
  //               position: toast.POSITION.TOP_RIGHT,
  //               autoClose: 2000,
  //             });
  //           }
  //         );
  //       }
  //     });
  //   }

  // };

  const handleSelectLots = (e: any, lotId: any) => {
    if (e.target.checked) {
      e.target.checked = true;
      const data = [...checkMasterInv, lotId];
      setCheckMasterInv(data);
      return;
    }
    const removeEle = checkMasterInv.filter((cData: any) => cData !== lotId);
    setCheckMasterInv(removeEle);
  };

  const handleMasterInvSearch = (e: any) => {
    const keyword = e.target.value?.toLocaleLowerCase();
    if (keyword !== "") {
      const filterData: any = originalMasterInv?.filter(
        (item: any) =>
          item?.recId == keyword ||
          item?.ctrlNo == keyword ||
          item?.title?.toLocaleLowerCase()?.includes(keyword)
      );
      setMasterInvLots(filterData);
      return;
    }
    setMasterInvLots(originalMasterInv);
  };

  const handleAssignClick = (movePayload?: any) => {
    setLoading(true);
    const masterLots = checkMasterInv.map((cmData: any) => { return { lotId: parseInt(cmData), lotNo: 0 } });
    let payload: any
    if (movePayload) {
      payload = movePayload
    } else {
      payload = {
        auctionId: parseInt(selectedAuction),
        lstlotRequestModel: masterLots,
      };
    }

    axios.post(TAGMASTERINVTOAUCTION, payload).then((response) => {
      setLoading(false);
      if (response?.data?.success === "Fail") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else {
        toast.success(InventoryMessages.assginedMasterInv, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
      movePayload && handleMoveClose();
      clearAdvanceFilterData(false, filterData)
      handleAssignMasterClose();
    }, (error) => {
      setLoading(false);
      handleAssignMasterClose();
      if (error.response.status == 401) {
        localStorage.clear();
        navigate(routeConstant.SIGNIN);
      }
      toast.error(errorMessages.error401, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
  };

  const handleRenumberClose = () => {
    setSelectedAuction("");
    setCurrentAuction("");
    setLotsForRenumber([]);
    setToggleRenumber(false);
  };

  const handleAssignMasterClose = () => {
    setMasterInvLots(originalMasterInv);
    setCheckMasterInv([]);
    setSelectedAuction("");
    setToggleMasterMap(false);
  };

  const handleAllCheck = (event: any) => {
    let elem: any = document.getElementsByClassName("masterCheckbox");
    for (let i = 0; i < elem.length; i++) {
      elem[i].checked = event.target.checked;
    }
    const data = masterInvLots.map((module: any) => {
      return { ...module, isCheck: event.target.checked };
    });
    event.target.checked === true
      ? setCheckMasterInv(
        data.map((i: any) => {
          return i.lotId;
        })
      )
      : setCheckMasterInv([]);
  };

  const getAllAuctions = () => {
    axios.get(GETALLAUCTIONLIST).then((response: any) => {
      let auctionData = []

      if (response?.data?.data) {
        auctionData = response?.data?.data?.map((lData: any) => {
          return {
            label: `${lData.auctionCode} - ${lData.auctionTitle}`,
            value: lData.auctionId,
          };
        });

      }
      setAuctionList([{ label: "Select Auction", value: "" }, ...auctionData]);
    });
  };

  // useEffect(() => {
  //   handleSearch(searchText);
  // }, [searchText]);

  useEffect(() => {
    clearErrors(["startDate", "endTime", "auctionId"]);
  }, [auctionId]);

  useEffect(() => {
    if (timeBtwGroupMin != "0" || timeBtwGroupSec >= 30) {
      clearErrors(["timeBtwGroupSec", "timeBtwGroupMin"]);
    }
  }, [timeBtwGroupMin, timeBtwGroupSec]);

  useEffect(() => {
    if (durationMin != "0" || durationSec != "0") {
      clearErrors(["durationMin", "durationSec"]);
    }
  }, [durationMin, durationSec]);

  const getAllSellers = (auctionId?: any) => {
    setFilterData({ ...filterData, role: '' })
    const URL = auctionId ? `${GETAUCTIONSELLERMAPPING}?auctionId=${auctionId}&pageNumber=-1` : `${GETSELLER}?pageNumber=-1`
    // setLoading(true);
    axios.get(URL, config).then(
      (response) => {
        // setLoading(false);
        if (response?.data?.data?.success === "Fail") {
          setSellerList([]);

          return;
        } else {
          const sellers = response?.data?.data?.data
          const sellerData = sellers?.map((lData: any) => {
            return {
              value: lData.sellerId,
              label: `${lData.sellerId} - ${lData.firstName} ${lData.lastName}`
            };
          })
          setSellerList(sellerData);
        }
      }).catch((error) => {
        setLoading(false);
        handleError(error);
      });
  };

  useEffect(() => {
    getAllSellers(filterData?.auctionId)
  }, [filterData.auctionId])


  const handleSave = (value?: any) => {
    setLoading(true);
    const auctionId = value === null ? selectedAuction.value : 0;
    axios.get(`${GENERATELOTCTRLREC}?auctionId=${auctionId}`).then(
      (response) => {
        setLoading(true);
        const respData = response.data.data;
        const auctionData = futureAuctionData.find((fData: any) => fData.auctionId === auctionId)
        const objToDispatch =
          auctionId === 0
            ? {
              recId: respData.recId,
              ctrlNo: respData.ctrlNo,
              lotNo: 0,
              displayLotNo: "",
              auctionId: auctionId,
              auctionEmail: "",
              contactAgent: auctionData?.contactAgent ?? "",
              isMaster: true,
            }
            : {
              lotNo: respData.lotNo,
              displayLotNo: respData.displayLotNo,
              ctrlNo: respData.ctrlNo,
              recId: 0,
              auctionId: auctionId,
              auctionEmail: selectedAuction?.auctionEmail ?? "",
              contactAgent: auctionData?.contactAgent ?? "",
              isMaster: false,
            };
        const aData = futureAuctionData?.find((fData: any) => fData.auctionId == auctionId) ?? { auctionId: 0, auctionTitle: "", auctionType: 0, auctionCode: 0 };
        dispatch(setGeneratedLotCtrlRec(objToDispatch));
        dispatch(setAuctionDataToStore({ auctionId: aData.auctionId, auctionTitle: aData.auctionTitle, auctionType: aData.auctionType, auctionCode: aData.auctionCode }));
        navigate(routeConstant.ADDNEWINVENTORY, { state: { type: "create" } });
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        toast.error(errorMessages.error401, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    );
  };

  return (
    <>
      <CommonHelmet title={"Inventory - "} name={"invetory"} content={"Inventory List"} />
      {loading && (
        <div className="loader-wrap">
          <img src={`${localStorage.getItem("globalLoader")}`} />
        </div>
      )}

      {/* Silent Inventory Modal */}
      <Modal show={silentToggleModal} className="modal-lg" centered>
        <form onSubmit={handleSilentSubmit(onSilentSubmit)} noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Silent Auction Setting
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleSilentClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <p className="form-label mb-2 fs--1">
                  Silent Auction Settings in the required format. Date in the
                  form of MM/DD/YYYY (e.g 01/07/2016) and 24-hour time in the
                  form of hh:mm A (e.g 09:05 PM)
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-3">
                <label
                  className="form-label"
                  htmlFor="bootstrap-wizard-validation-gender"
                >
                  Auction Title <sup className="text-danger">*</sup>
                </label>
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select Auction Title"
                  className="isActive"
                  {...silentRegister("auctionId")}
                  name="auctionId"
                  options={formatedAuctionData}
                  value={formatedAuctionData?.find(
                    (option: any) => option?.value == auctionId
                  )}
                  onChange={(e: any) => {
                    setSilentValues("auctionId", e.value);
                    auctionChange(e.value);
                  }}
                />
                {silentErrors?.auctionId && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {silentErrors?.auctionId.message}
                  </span>
                )}
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Start Date and Time ({defaultTimezone})
                  <sup className="text-danger">*</sup>
                </label>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="date_icon">
                      <DateTime
                        onChange={onChange}
                        closeOnSelect={true}
                        dateFormat="MM/DD/y"
                        timeFormat="hh:mm A"
                        value={value}
                        // value={value == '' ? value : convertToTimeZone(value, timeZoneIdentifier)}
                        inputProps={{
                          disabled: true,
                          placeholder: "Select Date and Time",
                          className:
                            "form-control form-icon-input only_date_icon",
                          onInput: (e: any) => {
                            const cursorPosition: any = e.target.selectionStart;
                            onChange(e.target.value);
                            e.target.setSelectionRange(
                              cursorPosition,
                              cursorPosition
                            );
                          },
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  End Date and Time ({defaultTimezone})
                  <sup className="text-danger">*</sup>
                </label>
                <Controller
                  name="endTime"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="date_icon">
                      <DateTime
                        onChange={onChange}
                        closeOnSelect={true}
                        value={value}
                        initialValue={value}
                        dateFormat="MM/DD/y"
                        timeFormat="hh:mm A"
                        inputProps={{
                          disabled: true,
                          placeholder: "Select Date and Time",
                          className:
                            "form-control form-icon-input only_date_icon",
                          onInput: (e: any) => {
                            const cursorPosition: any = e.target.selectionStart;
                            onChange(e.target.value);
                            e.target.setSelectionRange(
                              cursorPosition,
                              cursorPosition
                            );
                          },
                        }}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="col-lg-12 mb-3">
                <label className="form-label mb-2">
                  Lot Numbers <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  {...silentRegister("lotNumber")}
                  placeholder="Lot Number"
                  className="form-control"
                />
                {silentErrors?.lotNumber && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {silentErrors?.lotNumber.message}
                    <br />
                  </span>
                )}
                <span className="form-label mb-2 fs--1">
                  Enter lots as a range "1-5", list, "1,2,5,9" or combimation,
                  "1,2,5-9,11". (Number range only, e.g. "3-8", not "3a-3d")
                </span>
              </div>
              <div className="col-lg-6 mb-3">
                <label className="form-label mb-2">
                  Lots in Group <sup className="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  maxLength={10}
                  {...silentRegister("lotsInGroup")}
                  placeholder="Lots in Group"
                  className="form-control"
                />
                {silentErrors?.lotsInGroup && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {silentErrors?.lotsInGroup.message}
                  </span>
                )}
              </div>
              {/* <div className="col-lg-6 mb-3">
                <label className="form-label mb-2">
                  Time btw Groups (mm:ss)
                </label>
                <div className="d-flex dur">
                  <select
                    {...silentRegister("timeBtwGroupMin")}
                    className="form-select scrollbar"
                  >
                    <option value="0">0 mins</option>
                    {numbers.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num} mins
                        </option>
                      );
                    })}
                  </select>
                  <span className="ms-2 me-2">:</span>
                  <select
                    {...silentRegister("timeBtwGroupSec")}
                    className="form-select scrollbar"
                  >
                    <option value="0">0 secs</option>
                    {numbers.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num} secs
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div> */}

              {/* Time Between Groups */}
              <TimeSelector
                label="Time btw Groups (mm:ss)"
                registerMin={silentRegister('timeBtwGroupMin')}
                registerSec={silentRegister('timeBtwGroupSec')}
                errorsMin={silentErrors?.timeBtwGroupMin}
                errorsSec={silentErrors?.timeBtwGroupSec}
              />

              {/* Duration */}
              <TimeSelector
                label="Duration (mm:ss)"
                registerMin={silentRegister('durationMin')}
                registerSec={silentRegister('durationSec')}
                errorsMin={silentErrors?.durationMin}
                errorsSec={silentErrors?.durationSec}
                isRequired={true} // Duration is required
              />

              {/* Dynamic Extension Time */}
              <TimeSelector
                label="Dynamic Extension Time"
                registerMin={silentRegister('dynamicExtensionMin')}
                registerSec={silentRegister('dynamicExtensionSec')}
                errorsMin={silentErrors?.dynamicExtensionMin}
                errorsSec={silentErrors?.dynamicExtensionSec}
              />

              {/* <div className="col-lg-6 mb-3">
                <label className="form-label mb-2">
                  Duration (mm:ss)<sup className="text-danger">*</sup>
                </label>
                <div className="d-flex dur">
                  <select
                    {...silentRegister("durationMin")}
                    className="form-select"
                  >
                    <option value="0">0 mins</option>
                    {numbers.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num} mins
                        </option>
                      );
                    })}
                  </select>
                  <span className="ms-2 me-2">:</span>
                  <select
                    {...silentRegister("durationSec")}
                    className="form-select"
                  >
                    <option value="0">0 secs</option>
                    {numbers.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num} secs
                        </option>
                      );
                    })}
                  </select>
                </div>
                {silentErrors?.durationMin ? (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {silentErrors?.durationMin.message}
                  </span>
                ) : (
                  silentErrors?.durationSec && (
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {silentErrors?.durationSec.message}
                    </span>
                  )
                )}
              </div> */}
              {/* Dyanmic Extension Time */}
              {/* <div className="col-lg-6">
                <label className="form-label mb-2">
                  Dynamic Extension Time
                </label>
                <div className="d-flex dur">
                  <select
                    className="form-select scrollbar"
                    {...silentRegister("dynamicExtensionMin")}
                  >
                    <option value="00">0 mins</option>
                    {extNumbers.map((num, index) => {
                      return (
                        <option value={num} key={index}>
                          {num} mins
                        </option>
                      );
                    })}
                  </select>
                  <span className="ms-2 me-2">:</span>
                  <select
                    className="form-select scrollbar"
                    // defaultValue="15"
                    {...silentRegister("dynamicExtensionSec")}
                  >
                    <option value="00">0 secs</option>
                    {extNumbers.map((num: any, index: any) => {
                      return (
                        <option value={num} key={index}>
                          {num} secs
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div> */}
              <div className="col-lg-6 mb-3">
                <label className="form-label mb-2">Ring (optional)</label>
                <input
                  type="text"
                  {...silentRegister("ring")}
                  placeholder="Ring"
                  className="form-control"
                />
                {silentErrors?.ring && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {silentErrors?.ring.message}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleSilentClose}
            >
              {CommonMessages.cancelButton}
            </button>
            <button className="btn btn-primary" type="submit">
              {CommonMessages.saveButton}
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* import excel modal */}
      <Modal show={excelUpload} className="md-modal" centered>
        <form noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Bulk Inventory Upload
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleUploadClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <p className="form-label mb-2 fs--1">
                  Bulk Upload inventory via Excel or CSV
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label className="form-label mb-2">Upload File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => handleExcelChange(e)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleDownloadClick}
            >
              Download Template
            </button>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleUploadClose}
            >
              {CommonMessages.cancelButton}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleImport}
              disabled={excel === ""}
            >
              Upload
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* swap Lot # modal */}
      <Modal show={toggleSwap} className="md-modal" centered>
        <form noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Swap Lot #
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleSwapClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                Auction :
              </label>
              <div className="col-md-8 col-form-label">
                <span className="fw-normal">
                  {fromLotData?.auctionCode} - {fromLotData?.auctionTitle}
                </span>
              </div>
            </div>
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                From Lot :
              </label>
              <div className="col-md-8 col-form-label  fw-normal">
                <span className="fw-normal">{removeHtmlTags(fromLotData?.lotLabel)}</span>

              </div>
            </div>
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                To Lot :
              </label>
              <div className="col-md-8 col-form-label fw-normal">
                {toLotNos?.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect fw-normal"
                    placeholder="Select"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="toLotNos"
                    options={toLotNos}
                    onChange={(e: any) => {
                      setFromLotData({ ...fromLotData, toLotNo: e.value });
                    }}
                  />
                ) : (
                  <span>No other lots available in auction for swapping</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleSwapClose}
            >
              {CommonMessages.cancelButton}
            </button>
            {toLotNos?.length > 0 && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSwap}
                disabled={fromLotData?.toLotNo === undefined}
              >
                Swap
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      {/* move Lot # modal */}
      <Modal show={toggleMove} className="md-modal" centered>
        <form noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              {fromAuctionData?.auctionFrom == 0 ? 'Move Lot to Auction' : 'Move Lot from Auction'}
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleMoveClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                Lot :
              </label>
              <div className="col-sm-8 ps-0 mt-2">
                <span>{fromAuctionData?.lotTitle}</span>
              </div>
            </div>
            {fromAuctionData?.auctionFrom != 0 && <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                From Auction :
              </label>
              <div className="col-sm-8 ps-0 mt-2">
                <span>{fromAuctionData?.auctionTitle}</span>
              </div>
            </div>}
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                To Auction :
              </label>
              <div className="col-sm-8 ps-0">
                {fromAuctionData?.auctionOptions?.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="toLotNos"
                    options={fromAuctionData?.auctionOptions}
                    onChange={(e: any) => {
                      setFromAuctionData({
                        ...fromAuctionData,
                        toAuction: e.value,
                      });
                    }}
                  />
                ) : (
                  <span>No other lots available in auction for swapping</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleMoveClose}
            >
              {CommonMessages.cancelButton}
            </button>
            {fromAuctionData?.auctionOptions?.length > 0 && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleMove}
                disabled={fromAuctionData.toAuction === undefined}
              >
                Move
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      {/* rearrange lot modal */}
      <Modal show={toggleRearrange} className="md-modal" centered>
        <form noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Rearrange Lots
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleRearrangeClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <label className="col-sm-4 col-form-label text-nowrap">
                Auction :
              </label>
              <div className="col-sm-8 ps-0">
                {futureAuctionData.length > 0 ? (
                  <Select
                    classNamePrefix="dpaSelect"
                    placeholder="Select"
                    className="isActive swap-to-lot-menu clone-inventory"
                    name="auctionId"
                    options={futureAuctionData?.map((fData: any) => {
                      return {
                        value: fData.auctionId,
                        label: `${fData.auctionCode} - ${fData.auctionTitle}`,
                      };
                    })}
                    onChange={(e: any) => {
                      setRearrangeAuctionId(e.value);
                    }}
                  />
                ) : (
                  <span>No future auctions available</span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleRearrangeClose}
            >
              {CommonMessages.cancelButton}
            </button>
            {futureAuctionData.length > 0 && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleRearrange}
                disabled={rearrangeAuctionId === ""}
              >
                Rearrange
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      {/* map master inventory modal */}
      <Modal show={toggleMasterMap} size="lg" centered>
        <form noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Assign Master Inventory
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleAssignMasterClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <div className="col-sm-6 mb-2">
                <div className="search-box mb-1 mt-1 w-100">
                  <div className="d-flex align-items-center position-relative d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control search-input search form-control-sm w-100"
                      placeholder="Master Inventories"
                      onChange={(e) => handleMasterInvSearch(e)}
                    />
                    <span className="fas fa-search search-box-icon"></span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="h-500 scrollbar">
                  <table className="table fs--1 mb-0 border-top border-200">
                    <thead>
                      <tr>
                        <th className="align-middle bg-gradiant">
                          <input
                            onClick={(e) => handleAllCheck(e)}
                            className="form-check-input mt-0 me-1"
                            id="selectAll"
                            type="checkbox"
                            checked={
                              checkMasterInv.length == masterInvLots.length
                            }
                          />
                          <label htmlFor="selectAll" className="fw-bold fs--1">
                            {" "}
                            Select All
                          </label>
                        </th>
                        <th className="bg-gradiant">
                          <div className="d-flex align-items-center">
                            <span>Ctrl No</span>
                          </div>
                        </th>
                        <th className="bg-gradiant">
                          <div className="d-flex align-items-center">
                            <span>Rec Id - Lots</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterInvLots.map((mLots: any, mIndex: any) => {
                        return (
                          <tr
                            className="hover-actions-trigger btn-reveal-trigger position-static"
                            key={mIndex}
                          >
                            <td className="align-middle  p-2">
                              <input
                                className="masterCheckbox form-check-input mt-0 me-1"
                                type="checkbox"
                                onClick={(e: any) =>
                                  handleSelectLots(e, mLots.lotId)
                                }
                                checked={
                                  checkMasterInv.indexOf(mLots.lotId) !== -1
                                }
                              />
                            </td>
                            <td className="align-middle  p-2">
                              {mLots?.ctrlNo ?? "-"}
                            </td>
                            <td className="align-middle  p-2">
                              {mLots?.recId == 0 || mLots?.recId
                                ? `${mLots?.recId} - `
                                : ""}
                              {mLots.title
                                ? extractTextFromHTML(mLots.title)
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-2 mb-2">
              <div className="col-12">
                <label className="form-label text-nowrap">
                  Auction <sup className="text-danger">*</sup>
                </label>
                <div className="col-lg-6 ps-0">
                  {futureAuctionData.length > 0 ? (
                    <Select
                      classNamePrefix="dpaSelect"
                      placeholder="Select"
                      className="isActive swap-to-lot-menu clone-inventory"
                      name="auctionId"
                      options={futureAuctionData?.map((fData: any) => {
                        return {
                          value: fData.auctionId,
                          label: `${fData.auctionCode} - ${fData.auctionTitle}`,
                        };
                      })}
                      onChange={(e: any) => {
                        setSelectedAuction(e.value);
                      }}
                    />
                  ) : (
                    <span>No future auctions available</span>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleAssignMasterClose}
            >
              Cancel
            </button>
            {futureAuctionData.length > 0 && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => handleAssignClick()}
                disabled={
                  !(selectedAuction !== "" && checkMasterInv.length > 0)
                }
              >
                Assign
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      {/*  Clone modal starts */}
      <Modal show={toggleModal} className="md-modal" centered>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Clone Inventory
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <label className="form-label mb-2">
                  Select Title Name<sup className="text-danger">*</sup>
                </label>
              </div>
              <div className="col-lg-12">
                <Select
                  classNamePrefix="dpaSelect"
                  name="cloneList"
                  placeholder="Select"
                  className="clone-inventory"
                  options={cloneData}
                  value={cloneData.value}
                  onChange={(e: any) => cloneInventoryTemplateId(e)}
                />
                {errors?.cloneList && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.cloneList}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-danger"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              {CommonMessages.cancelButton}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={cloneInventoryTemplate}
            >
              Next
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/*  delete modal starts */}
      <Modal
        show={toggleDeleteModal}
        size={"lg"}
        className="md-modal-xxl"
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Header>
            <h5 className="modal-title" id="verticallyCenteredModalLabel">
              Delete Inventory
            </h5>
            <button
              className="btn p-1"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setToggleDeleteModal(false)}
            >
              <span className="fas fa-times text-danger fs-20"></span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <label>How do you want to delete the inventory ?</label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() =>
                handleDeleteModal(1, "remove this record from Web.")
              }
            >
              Remove from Web
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() =>
                handleDeleteModal(2, "remove this record from Web & Admin.")
              }
            >
              Remove from Web & Admin
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() =>
                handleDeleteModal(3, "remove this record permanently.")
              }
            >
              Delete
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/** ADD New Inventory Modal */}
      <AddNewLotModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        futureAuctionData={futureAuctionData}
        selectedAuction={selectedAuction}
        setSelectedAuction={setSelectedAuction}
      />

      <Sidebar />
      <div className="content pt-0 pb-3">
        <InnerHeaderTab breadCrumb={breadcrumbData} title="Inventory">
          <div className="d-flex flex-wrap align-items-end mb-2">
            <div className="search-box mt-2 mb-2">
              <div className="d-flex align-items-center">
                <div
                  className="position-relative d-flex align-items-center"
                  data-bs-toggle="search"
                  data-bs-display="static"
                >
                  <input
                    id='searchBar'
                    className="form-control search-input search form-control-sm"
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e: any) => {
                      setFilterData({ ...filterData, keyword: e.target.value })
                      if (e.target.value.length) {
                        setAlreadyFetchedDataPageNumber({});
                        setCurrentPage(1);
                        debouncedRequest();
                        return;
                      } else {
                        clearAdvanceFilterData(false, { ...filterData, keyword: '' });
                      }
                    }}
                  />
                  <span className="fas fa-search search-box-icon"></span>
                  <button
                    type="button"
                    className="p-0 btn ms-3"
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  >
                    <i className="fas fa-filter fs-16"></i>
                  </button>
                </div>
              </div>
              {/* <div className="mt-2 mb-2">
                <Select
                  classNamePrefix="dpaSelect"
                  placeholder="Select Auction Title"
                  className="isActive clone-inventory"
                  name="auctionTitle"
                  options={filterAuctionData}
                  value={currentAuction}
                  onChange={(e) => loadLotsBasedAuction(e)}
                />
              </div> */}
            </div>

            <div className="col-auto mt-2 d-flex flex-wrap align-items-center ms-auto">
              {(globalAuctionHammerStatus !== "Sold") && <button
                type="button"
                className="btn btn-outline-primary mb-2 ms-2"
                onClick={handleShowModal}
              >
                <span className="plus me-2">
                  <span
                    className="fas fa-plus"
                    data-fa-transform="shrink-3"
                  ></span>
                </span>
                <span> Add New Inventory </span>
              </button>}
              <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                <Dropdown.Toggle
                  className="btn btn-outline-primary  mb-2 ms-2 dropdown-caret-none btn btn-outline-primary transition-none btn-reveal fs--1"
                  variant="none"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Open Search Menu"
                >
                  <span className="plus">
                    <span>More</span>
                    <span
                      className="fas fa-angle-down fs--2 ms-2"
                      data-fa-transform="shrink-3"
                    ></span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className={`dropdown-menu toggal-icons-4 dropdown-menu-start py-2 `}
                >
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => {
                      if (masterInvLots?.length == 0) {
                        toast.error(errorMessages.masterInvNotFound, {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 3000,
                        });
                        return;
                      }
                      setToggleMasterMap(true);
                    }}
                  >
                    {/* <IconRearrange /> */}
                    <span className="plus fas fa-people-carry-box"></span>
                    <span> Assign Master Inventory </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    onClick={() => navigate(routeConstant.RENUMBERLOTS)}
                  >
                    <span className="plus fa fa-arrow-up-1-9"></span>
                    Renumber Lots
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => {
                      setToggleRearrange(true);
                    }}
                  >
                    <span className="plus fas fa-refresh"></span>
                    <span> Rearrange Lots </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => {
                      if (!permission.isAllowImport) {
                        Helper.unAuthorized();
                        return;
                      }
                      reset(initialData);
                      setExcelUpload(!excelUpload);
                      setMode("silent");
                    }}
                  >
                    <span className="plus fas fa-file"></span>{" "}
                    <span> Import Excel </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => {
                      reset(initialData);
                      setSilentToggleValue(!silentToggleModal);
                      setMode("silent");
                    }}
                  >
                    <span className="plus fas fa-cog"></span>{" "}
                    <span> Silent Auction Setting </span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    className="dropdown-item d-flex align-items-center fw-bold gap-2"
                    type="button"
                    data-bs-toggle="modal"
                    onClick={() => {
                      if (!permission.isAllowAdd) {
                        Helper.unAuthorized();
                        return;
                      }
                      reset(initialData);
                      setToggleValue(!toggleModal);
                      setMode("create");
                    }}
                  >
                    <span className="plus fas fa-copy"></span>{" "}
                    <span> Clone Inventory </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {showFilter && (
            <div
              className={showFilter == true ? "" : "dropdown-advance-filter "}
            >
              <div className="card bg-transparent position-relative p-3 mt-2 mb-3">
                <button
                  className="btn p-1 p-a"
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                    // setFilterData({
                    //   lotNo: "",
                    //   ctrlNo: "",
                    //   title: "",
                    //   statusnew: "",
                    //   role: [],
                    //   auctionCode: "",
                    //   strAuctionType: "",
                    //   taxExempt: -1,
                    //   sellerId: "",
                    //   quantity: "",
                    //   isActive: -1,
                    //   silentAuction: -1,
                    //   masterInventory: -1,
                    //   auctionTitle: "",
                    // });
                  }}
                >
                  <span className="fas fa-times text-danger fs-20"></span>
                </button>
                <div className="card-body p-0">
                  <div className="text-start">
                    <form>
                      <div className="row g-3">
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Select Auction
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Title"
                            className="isActive clone-inventory"
                            name="auctionTitle"
                            options={auctionList}
                            filterOption={(option: any, searchText: any) => option?.data?.label.toLowerCase().includes(searchText.toLowerCase()) ? true : false}
                            value={auctionList?.find(
                              (option: any) =>
                                option?.value == filterData?.auctionId
                            )}
                            onChange={(e) => {
                              changeHandler("auctionId", e.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Lot #
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            name="lotNo"
                            placeholder="Lot #"
                            value={filterData?.displayLotNo}
                            maxLength={21}
                            onChange={(e) =>
                              changeHandler("displayLotNo", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                          {error.displayLotNo && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.displayLotNo}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Ctrl No
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            name="ctrlNo"
                            placeholder="Ctrl"
                            maxLength={21}
                            value={filterData.ctrlNo}
                            onChange={(e) =>
                              changeHandler("ctrlNo", e.target.value)
                            }
                            onKeyDown={handleKeyDown}
                          />
                          {error.ctrlNo && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.ctrlNo}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Title
                          </label>
                          <input
                            className="form-control form-icon-input"
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={filterData.title}
                            onChange={(e) =>
                              changeHandler("title", e.target.value)
                            }
                          />
                          {error.title && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              {error.title}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Auction Type
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Type"
                            className="isActive"
                            name="strAuctionType"
                            options={auctionTypes}
                            value={filterData.strAuctionType}
                            onChange={(e) =>
                              changeHandler("strAuctionType", e)
                            }
                          />
                          {/* <select
                              className="form-select"
                              aria-label="Default select example"
                              value={filterData.strAuctionType}
                              onChange={(e) =>
                                changeHandler("strAuctionType", e.target.value)
                              }
                            >
                              {auctionTypes &&
                                auctionTypes.map((initialData: any, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={initialData?.value}
                                    >
                                      {initialData.label}
                                    </option>
                                  );
                                })}
                            </select> */}
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Tax Exempt
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select  Tax Exempt"
                            className="isActive clone-inventory"
                            name="taxExempt"
                            options={filterTaxExempt}
                            value={filterData.taxExempt}
                            onChange={(e) => changeHandler("taxExempt", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Seller
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Seller"
                            className="isActive clone-inventory"
                            name="sellerId"
                            options={sellerList}
                            value={filterData.role}
                            onChange={(e) => changeHandler("role", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Silent Inventory
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Silent Inventory"
                            className="isActive clone-inventory"
                            name="silentAuction"
                            options={filterSilentAuction}
                            value={filterData.silentAuction}
                            onChange={(e) =>
                              changeHandler("silentAuction", e)
                            }
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Status
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Auction Status"
                            className="isActive"
                            name="statusnew"
                            options={inventoriesStatus}
                            value={filterData.statusnew}
                            onChange={(e) => changeHandler("statusnew", e)}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label
                            className="form-label"
                            htmlFor="bootstrap-wizard-validation-gender"
                          >
                            Master Inventory
                          </label>
                          <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select Master Inventory"
                            className="isActive clone-inventory"
                            name="masterInventory"
                            options={filterLotNoStatus}
                            value={filterData.masterInventory}
                            onChange={(e) =>
                              changeHandler("masterInventory", e)
                            }
                          />
                        </div>
                      </div>
                    </form>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="flex-1 text-end mt-3 ms-3">
                        <button
                          className="btn btn-outline-secondary mb-1 me-3"
                          type="submit"
                          onClick={() => { clearAdvanceFilterData() }}
                        >
                          Clear
                        </button>
                        <button
                          className="btn btn-primary mb-1"
                          type="submit"
                          // onClick={() => {
                          //   handleAdvanceFilter(filterData);
                          // }}
                          onClick={() => {
                            setAlreadyFetchedDataPageNumber({});
                            currentPage !== 1 && setCurrentPage(1);
                            setIsFiltered(true);
                            currentPage == 1 && handleAdvanceFilter(filterData, 1);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InnerHeaderTab>
        <div className="container-fluid">
          <div className="row top_border">
            <div className="col-12">
              <div className="mb-5 border-300">
                <div>
                  <div className="row pb-3">
                    <div className="col-12">
                      <div className="border-300">
                        <div>
                          <div className="table-responsive mx-n1 px-1">
                            <table className="table fs--1 mb-0 border-top border-200">
                              <thead>
                                <tr>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                  >
                                    Action
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Ctrl"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Ctrl</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "ctrlNo")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "ctrlNo")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Lots"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Lot #</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "lotNo")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "lotNo")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant white-space-pre p-2"
                                    scope="col"
                                    data-sort="firstname"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Auction Code - Title</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Title"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Title</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "title")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "title")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Status</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting("ASC", "statusnew")
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting("DESC", "statusnew")
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Auction Type</span>
                                      <div className="sorting_group">
                                        <button
                                          className="arrow-up"
                                          onClick={() =>
                                            handleSorting(
                                              "ASC",
                                              "strAuctionType"
                                            )
                                          }
                                        ></button>
                                        <button
                                          className="arrow-down"
                                          onClick={() =>
                                            handleSorting(
                                              "DESC",
                                              "strAuctionType"
                                            )
                                          }
                                        ></button>
                                      </div>
                                    </div>
                                  </th>

                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Silent Inventory</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Tax Exempt</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Seller</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Status"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Rec Id</span>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Privillege"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Master Inventory</span>
                                      <div className="sorting_group"></div>
                                    </div>
                                  </th>
                                  <th
                                    className="bg-gradiant p-2 white-space-pre"
                                    scope="col"
                                    data-sort="Privillege"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span>Activation Status</span>
                                      <div className="sorting_group"></div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                className="list"
                                id="table-latest-review-body"
                              >
                                {filterLots.length > 0 ? (
                                  filterLots.map((data: any) => {
                                    const lotId = encryptId(data?.lotId);
                                    return (
                                      <tr
                                        className="hover-actions-trigger btn-reveal-trigger position-static"
                                      /* draggable
                                                                      onDragStart={(e) => handleDragStart(e, data)}
                                                                      onDragEnter={() => handleDragEnter(data)}
                                                                      onDragEnd={(e) => handleDragEnd(e)} */
                                      >
                                        <td className="align-middle white-space-pre text-start p-2">
                                          <Dropdown className="font-sans-serif btn-reveal-trigger position-static">
                                            <Dropdown.Toggle
                                              className="btn btn-sm dropdown-caret-none transition-none btn-reveal fs--2 pt-1 pb-1 ps-2 pe-2"
                                              variant="none"
                                            >
                                              <img
                                                src={moreOptions}
                                                style={{ width: "20px" }}
                                              />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                              className={`dropdown-menu dropdown-menu-start py-2 `}
                                            >
                                              <Dropdown.Item
                                                as="button"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                                onClick={() =>
                                                  handleEditModal(
                                                    encryptId(data.lotId),
                                                    "edit"
                                                  )
                                                }
                                              >
                                                Edit{" "}
                                                <i className="fas fa-pen dropdown-icon"></i>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                                onClick={() => {
                                                  if (!permission.isAllowAdd) {
                                                    Helper.unAuthorized();
                                                    return;
                                                  }
                                                  navigate(routeConstant.CLONEINVENTORY + "/" + lotId, {
                                                    state: { type: "clone" },
                                                  });
                                                }}
                                              >
                                                Clone Inventory{" "}
                                                <i className="fas fa-clone dropdown-icon"></i>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                                onClick={() => {
                                                  if (
                                                    !permission.isAllowDelete
                                                  ) {
                                                    Helper.unAuthorized();
                                                    return;
                                                  }
                                                  if (data.isSilentApplied) {
                                                    toast.error(
                                                      "Cannot delete selected inventory due to silent settings applied",
                                                      {
                                                        position:
                                                          toast.POSITION
                                                            .TOP_RIGHT,
                                                        autoClose: 2000,
                                                      }
                                                    );
                                                    return;
                                                  }
                                                  setDeleteLotId(data.lotId);
                                                  setToggleDeleteModal(true);
                                                }}
                                              >
                                                Delete{" "}
                                                <i className="fas fa-trash dropdown-icon"></i>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                                onClick={() =>
                                                  handleSwapLot(data)
                                                }
                                              >
                                                Swap{" "}
                                                <i className="fas fa-rotate dropdown-icon"></i>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                as="button"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                                onClick={() =>
                                                  handleMoveLot(data)
                                                }
                                              >
                                                Move{" "}
                                                <i className="fa-solid fa-arrows-up-down-left-right fs-16"></i>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                          <div className="font-sans-serif btn-reveal-trigger position-static"></div>
                                        </td>
                                        {/* <td className="align-middle white-space-pre p-2">
                                          {data.ctrlNo}
                                        </td> */}
                                        <td className="align-middle  p-2">
                                          <Link
                                            className="btn text-decoration-underline m-0 p-0 fw-normal"
                                            data-bs-dismiss="modal"
                                            target="_blank"
                                            to={`${routeConstant.UPDATEINVENTORY}/${encryptId(data?.lotId)}`}
                                            onClick={() => { dispatch(modeFromInvStore("edit")) }}
                                          >
                                            {data.ctrlNo}
                                          </Link>
                                        </td>
                                        <td className="align-middle white-space-pre p-2">
                                          {data.displayLotNo
                                            ? data.displayLotNo
                                            : data.lotNo}
                                        </td>
                                        <td className="align-middle p-2 white-space-pre">
                                          {data?.auctionCode} -{" "}
                                          {data?.auctionTitle}
                                        </td>
                                        <td className="align-middle p-2 white-space-pre">
                                          {data.title
                                            ? extractTextFromHTML(data.title)
                                            : "-"}
                                        </td>
                                        <td className="align-middle white-space-pre p-2">
                                          <span
                                            className={`badge badge-dpa status-${data.statusnew
                                              ?.replace(/ /g, "")
                                              .toLowerCase()}`}
                                          >
                                            {data.statusnew}
                                          </span>
                                        </td>
                                        <td className="align-middle white-space-pre p-2">
                                          {data.strAuctionType
                                            ?.replace(/([A-Z])/g, " $1")
                                            .charAt(0)
                                            .toUpperCase() +
                                            data.strAuctionType
                                              ?.replace(/([A-Z])/g, " $1")
                                              .slice(1)}
                                        </td>
                                        <td className="align-middle white-space-pre text-start status p-2">
                                          {data.isSilentApplied == true ? (
                                            <img
                                              src={checkMark}
                                              height={"30px"}
                                              alt="filter"
                                            ></img>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td className="align-middle white-space-pre text-start status p-2">
                                          <span
                                            className={`badge badge-dpa fs--2 ${data.taxExempt
                                              ? "badge-dpa-success"
                                              : "badge-dpa-danger"
                                              }`}
                                          >
                                            {data.taxExempt ? "Yes" : "No"}
                                          </span>
                                        </td>
                                        <td className="align-middle white-space-pre p-2">
                                          {data.sellerfirstName}{" "}
                                          {data.sellerlastName}
                                        </td>
                                        <td className="align-middle white-space-pre p-2">
                                          {data.recId ?? "-"}
                                        </td>
                                        <td className="align-middle text-start status p-2">
                                          <span
                                            className={`badge badge-dpa fs--2 ${data.masterInventory === 1
                                              ? "badge-dpa-success"
                                              : "badge-dpa-danger"
                                              }`}
                                          >
                                            {data.masterInventory === 1
                                              ? "Yes"
                                              : "No"}
                                          </span>
                                        </td>
                                        <td className="align-middle white-space-pre text-start status p-2">
                                          <span
                                            className={`badge badge-dpa fs--2 ${data.isActive == true
                                              ? "badge-dpa-success"
                                              : "badge-dpa-danger"
                                              }`}
                                          >
                                            {data.isActive == true
                                              ? "Active"
                                              : "Inactive"}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                                    <td
                                      className="text-center p-4"
                                      colSpan={13}
                                    >
                                      No records found!
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {filterLots.length > 0 && (
                    <ReactPaginate
                      previousLabel={"←"}
                      nextLabel={"→"}
                      pageCount={Math.ceil(totalRecords / paginationSize)}
                      onPageChange={({ selected }) => handlePageClick(selected)}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                      forcePage={
                        currentPage == 0 ? currentPage : currentPage - 1
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventory;
