import { yupResolver } from "@hookform/resolvers/yup";
import Tippy from '@tippyjs/react';
import axios from "axios";
import parse from "html-react-parser";
import parsePhoneNumber, { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import handleError from "../../../../Utils/ApiCommonErrorMessages";
import CustomCkEditor from "../../../../Utils/CustomCkEditor";
import DateTime from "../../../../Utils/DateTime";
import MomentUtil from "../../../../Utils/MomentUtil";
import { ERROR_MESSAGES, formatCurrency, formatNumber, handleKeyDown } from "../../../../Utils/Utils";
import { useCurrentDateTime } from "../../../../Utils/useDateTime";
import { AddressForm, onGetDefaultValues } from "../../../../common/AddressForm";
import { CHECKAUCTIONCODEEXISTS, CREATEADDRESSDATES, GENERATEAUCTIONCODE, GETALLBIDINCREMENT, GETAUCTIONINFOBYID, GETBPBYSCALES, UPDATEADDRESSDATES } from "../../../../common/apiConstatnts";
import { charCountForAddress } from "../../../../common/commonFunctions";
import { auctionStatus, auctionType, wordCounter } from "../../../../common/constants";
import { AuctionMessages, CommonMessages } from "../../../../common/messageConstants";
import { AlPHANUMERICTYPETWO, EMAIL, EMAIL_MESSAGE, FAX, FAX_MESSAGE, SPECIALCHARS, URL_MESSAGE } from "../../../../common/regexConstants";
import * as routeConstant from '../../../../common/routeConstants';
import { setAuctionCodeAndTitle, setAuctionId, setAuctionInfoActive, setAuctionType, setCloneAuctionId, setCloneTab } from "../../../../store/actions/AuctionAction";
import { loader, setAuctionHammerData } from "../../../../store/actions/CommonAction";
import { selectAuction as salesRepSelectAuction } from "../../../../store/actions/SalesRepAction";
import { selectAuction as sellerSelectAuction } from "../../../../store/actions/Seller";
import LastModified from "../../../LastModifiedDateTime/LastModifiedDateTime";
import ModifiedBy from "../../../LastModifiedDateTime/ModifiedBy";
import { ADDRESSDATES } from "../../AuctionConstants";

const AddressDates = ({ auctionId }: any) => {
    const companyId = localStorage.getItem('companyId')
    const mode = useSelector((state: any) => state.auction.mode)
    const auctionIds = useSelector((state: any) => state.auction.auctionId)
    const cloneAuctionId = useSelector((state: any) => state.auction.clonedAuctionId)
    const clonedTabs = useSelector((state: any) => state.auction.clonedTabs)
    const getGlobalAuction = useSelector((state: any) => state?.global?.auctionHammerData);
    const selectedAuctionType = useSelector((state: any) => state.auction.auctionType)
    const [selectedOption] = useState<any>(selectedAuctionType)
    const momentUtil = MomentUtil.getInstance();
    const defaultTimezone = momentUtil.getLiveBoardTimezoneFromLocalStorage();
    const dateTime = useCurrentDateTime();

    const config: any = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
    }

    const initialData = {
        companyId: companyId,
        auctionCode: "",
        auctionTitle: "",
        startDateTime: "",
        endDateTime: "",
        shortDescription: "",
        contactAgent: "",
        website: "",
        auctionEmail: "",
        phone1: "",
        phone2: "",
        fax: "",
        status: 2,
        auctionType: selectedOption,
        latitude: "",
        longitude: "",
        customeNotice: "",
        isActive: true,
        displayOnWebsite: false,
        auctionBufferTime: 0,
        auctionBufferTimeHrs: "00",
        auctionBufferTimeMins: "00",
        defaultBidIncrement: '',
        add1: "",
        add2: "",
        city1: "",
        state1: "",
        country1: "",
        zip: "",
        county1: '',
        terms: "",
        auctionClosingTime: "03",
        bpBySalesScaleId: "",
    }

    const dispatch = useDispatch()
    const maxWordCount = 250
    const auctionInfoData = useSelector((state: any) => state.auction.auctionInfoData)
    const addressDates = auctionInfoData?.addressDates
    const [formValues, setFormValues] = useState<any>(initialData)
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [toggleModal, setToggleValue] = useState<boolean>(false)
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [location, setLocation] = useState('')
    const [latLog, setlatLog] = useState<any>(null)
    const [auctionCodeExists, setAuctionCodeExists] = useState<boolean>(false)
    const [bidIncrementOptions, setBidIncrementOptions] = useState<any>([])
    const hours = Array.from({ length: 12 }, (_, index) => index < 9 ? `0${index + 1}` : index + 1);
    const mins = Array.from({ length: 59 }, (_, index) => index < 9 ? `0${index + 1}` : index + 1);
    const disAppearMins = Array.from({ length: 59 - 3 + 1 }, (_, index) => {
        const number = index + 3;
        return number < 10 ? `0${number}` : `${number}`;
    });
    const [remainingCount, setRemainingCount] = useState(maxWordCount)
    const [remainingCount3, setRemainingCount3] = useState(maxWordCount)
    const [selectedAuction, setSelectedAuction] = useState<any>(null);
    const [selectedTime, setSelectedTime] = useState("03"); // Default selected time
    const [selectedTimeValue, setSelectedTimeValue] = useState("03");
    const [errorMessage, setErrorMessage] = useState('');
    const [defaultAuctionValue, setDefaultAuctionValue] = useState<any>(addressDates?.auctionType ? addressDates?.auctionType : '2');
    const [address, setAddress] = useState('');
    const [editor, setEditor] = useState<any>()
    const [webText, setWebText] = useState<any>({
        auctionWebTextId: 0,
        auctionId: mode !== "clone" ? parseInt(auctionId) : parseInt(cloneAuctionId),
        title: "",
        description: "",
        terms: "",
        companyId: companyId,
        isActive: true,
        isDelete: false
    })

    const [endDateTimeEr, setEndDateTimeEr] = useState<any>(false);
    const [startDateTimeExisting, setStartDateTimeExisting] = useState<string>("")
    const [endDateTimeExisting, setEndDateTimeExisting] = useState<string>("")
    const [countryCode, setCountryCode] = useState('us')
    const [prevCountryCode, setPrevCountryCode] = useState('us');
    const [isResp, setIsResp] = useState<boolean>(false)
    const [btnTxt, setBtnTxt] = useState<any>("")
    const auctionStatusFromDB = useRef("")
    const auctionCodeFromDB = useRef("")
    const auctionCodeExistErr = useRef(false)

    const [bpBySalesData, setBpBySalesData] = useState([])
    const [selectedOpt, setSelectedOpt] = useState('');
    const selectedData: any = bpBySalesData.find((data: any) => data.bpBySalesScaleId === parseInt(selectedOpt));

    useEffect(() => {
        if (prevCountryCode !== countryCode) {
            setValue('phone1', '');
            setValue('phone2', '');
        }
    }, [address, prevCountryCode, countryCode])

    useEffect(() => {
        const defaultAuction = auctionType.find((option: any) => option.value == defaultAuctionValue);
        if (defaultAuction) {
            setSelectedAuction(defaultAuction);
        }
    }, [auctionType, defaultAuctionValue]);

    const dateFormat = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';

    const generatePhoneValidationSchema = () => {
        return yup.string().nullable().test('phone-validation', 'Invalid phone number', value => {
            if (!value) return true;
            try {
                const phoneNumber = parsePhoneNumberFromString(`+${value}`);
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false; // Invalid phone number format
                }
                const nationalNumber = phoneNumber.nationalNumber;
                const countryCodeLength = phoneNumber.countryCallingCode.toString().length;
                const expectedLength = value.length - countryCodeLength;
                return nationalNumber.toString().length === expectedLength;
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return false; // Return false if an error occurs during parsing
            }
        });
    };

    const schema: any = yup.object().shape({
        auctionCode: yup.number().typeError('Auction code must be a number').required('Auction code is required')
            .test(
                'maxDigits',
                'Please enter upto 9 digits',
                value => (value ? value.toString().length <= 9 : true)
            ),
        terms: yup.string().required("Terms are required")
            .test('no-leading-space', "No leading spaces allowed", value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }),
        auctionTitle: yup.string().required("Auction Title is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            }).matches(SPECIALCHARS, 'Please enter valid Title').max(100, "Please enter upto 100 characters."),

        defaultBidIncrement: yup.string().required().test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        bpBySalesScaleId: yup.string().required().test('no-leading-space', "Please select BP scale", (value: any) => {
            if (value === "" || value === "0") {
                return false; // Show validation error if value is an empty string or 0
            }
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }),
        startDateTime: yup.string()
            .required('Start DateTime is required')
            .test('is-greater-than-current', `Start DateTime must be greater than the current ${defaultTimezone} DateTime`, value => {
                const selectedDate = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: dateFormat });
                const currentUTCDateTime = momentUtil.parseTimeInUtc({ dateTimeString: dateTime?.currentDateTime, parseFormat: "YYYY-MM-DDTHH:mm" });

                if (auctionId !== 0) {

                    const exisingStartDateTime = momentUtil.parseTimeInUtc({ dateTimeString: startDateTimeExisting });

                    if (selectedDate.isSame(exisingStartDateTime)) {
                        return true;
                    } else if (selectedDate.isSameOrAfter(currentUTCDateTime)) {
                        return true;
                    }

                } else if (selectedDate.isSameOrAfter(currentUTCDateTime)) {
                    return true;
                }
                return false;

            })
            .test('is-valid-end-time', 'End DateTime must be greater than Start DateTime by at least 5 minutes', function (value) {
                const { endDateTime } = this.parent;
                if (!endDateTime || !value) return true;

                const selectedDate = momentUtil.parseTimeInUtc({ dateTimeString: endDateTime, parseFormat: dateFormat }).seconds(0);
                const currentUTCDateTime = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: dateFormat })
                const newTime = currentUTCDateTime.add(5, 'm');

                // Check if startDateTime is greater than the current UTC time

                if (auctionId !== 0) {

                    const exisingStartDateTime = momentUtil.parseTimeInUtc({ dateTimeString: startDateTimeExisting });
                    const exisingEndDateTime = momentUtil.parseTimeInUtc({ dateTimeString: endDateTimeExisting });

                    if (currentUTCDateTime.isSame(exisingStartDateTime) && selectedDate.isSame(exisingEndDateTime)) {
                        return true;
                    } else if (selectedDate.isSameOrAfter(newTime)) {
                        setEndDateTimeEr(``)
                        return true;
                    } else {
                        setEndDateTimeEr(`End DateTime must be greater than Start DateTime by at least 5 minutes`)
                        return true;
                    }

                } else if (selectedDate.isSameOrAfter(newTime)) {
                    setEndDateTimeEr(``)
                    return true
                } else {
                    setEndDateTimeEr(`End DateTime must be greater than Start DateTime by at least 5 minutes`)
                    return true;
                }
            }),
        endDateTime: yup.string()
            .required('End DateTime is required')
            .test('is-greater-than-start', 'End DateTime must be greater than Start DateTime by at least 5 minutes', function (value) {
                const { startDateTime } = this.parent;
                if (!startDateTime || !value) return true;

                const selectedDate = momentUtil.parseTimeInUtc({ dateTimeString: value, parseFormat: dateFormat });
                const currentUTCDateTime = momentUtil.parseTimeInUtc({ dateTimeString: startDateTime, parseFormat: dateFormat })
                const newTime = currentUTCDateTime.add(5, 'm');

                if (auctionId !== 0) {

                    const exisingStartDateTime = momentUtil.parseTimeInUtc({ dateTimeString: startDateTimeExisting });
                    const exisingEndDateTime = momentUtil.parseTimeInUtc({ dateTimeString: endDateTimeExisting });

                    if (currentUTCDateTime.isSame(exisingStartDateTime) && selectedDate.isSame(exisingEndDateTime)) {
                        return true;
                    } else if (selectedDate.isSameOrAfter(newTime)) {
                        return true;
                    }
                } else if (selectedDate.isSameOrAfter(newTime)) {
                    return true;
                }

                return false;
            }),
        shortDescription: yup.string().required("Short description is required").test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
            if (!value) return true; // If value is empty, it's handled by the .required() validation
            return !/^\s/.test(value); // Check if value doesn't start with a space
        }).matches(SPECIALCHARS, 'Please enter valid Description'),
        contactAgent: yup.string().required("Contact agent is required").matches(AlPHANUMERICTYPETWO, 'Please enter valid Contact Agent').max(50, "Please enter upto 50 characters"),
        website: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).matches(/^(?!https:\/\/www$)((https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?)?$/, URL_MESSAGE),
        auctionEmail: yup.string()
            .required("Auction email is required")
            .test('no-leading-space', ERROR_MESSAGES.NO_LEADING_SPACES, value => {
                if (!value) return true; // If value is empty, it's handled by the .required() validation
                return !/^\s/.test(value); // Check if value doesn't start with a space
            })
            .matches(EMAIL, EMAIL_MESSAGE).max(50, "Please enter upto 50 characters"),
        add1: yup.string().required("Address is required").test('no-blank-space', 'Blank spaces are not allowed', (value) => {
            return value ? !/^\s/.test(value) : true;
        }),

        zip: yup
            .string().required('Zip code is required')
            .test('no-blank-space', 'Blank spaces are not allowed', (value) => {
                return value ? !/^\s/.test(value) : true;
            })
            .matches(/^[0-9a-zA-Z\s-]+$/, 'Zip code must contain only numbers, alphabets, hyphens, and spaces')
            .matches(/^(?!-)[\w\s-]*(?<!-)$/, 'Hyphens are not allowed at the beginning or end of the zip code')
            .min(3, 'Zip code must be at least 3 characters.')
            .max(12, 'Zip code cannot be longer than 12 characters.'),
        country1: yup.string().required("Country is required"),
        state1: yup.string().required("State is required"),
        city1: yup.string().required("City is required"),
        phone1: generatePhoneValidationSchema().required('Phone number is required'),
        phone2: generatePhoneValidationSchema().nullable(),

        fax: yup.string().nullable().test("no-blank-space", ERROR_MESSAGES.BLANK_SPACES_NOT_ALLOWED, (value) => {
            return value ? !/^\s*$/.test(value) : true;
        }).matches(FAX, FAX_MESSAGE),
        status: yup.string().required('Status is required'),
        auctionType: yup.string().required('Auction type is required'),
        displayOnWebsite: yup.boolean().nullable(),
        auctionClosingTime: yup.string().nullable().test('time-req', `Please select a time of 3 minutes or more.`, (value: any) => {
            if (parseInt(value) < 3) {
                return false;
            }
            return true;
        })
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        getValues,
        clearErrors,
        trigger,
        formState: { errors, isValid },
    } = useForm({ resolver: yupResolver(schema), defaultValues: formValues, mode: 'onChange' })

    const watchScales = watch('bpBySalesScaleId')
    const selectedStatus = watch('status')
    const defaultBidIncrement = watch('defaultBidIncrement')
    const auctionTypeValue = watch('auctionType')
    const endDateTime = watch('endDateTime')
    const terms = watch('terms');
    const yesterday = moment().subtract(1, 'day');

    const disablePastDt = (current: any) => {
        return current.isAfter(yesterday);
    };

    const handleChangeAddressLine = (e: any) => {
        charCountForAddress(e.target.value.length)
        setAddress(e.target.value)
        setValue('address', e.target.value)
        setValue('add1', e.target.value)
    }

    const createCloneAuction = (data: any, type: string, e: any = {}) => {
        axios.post(CREATEADDRESSDATES, data, config).then((response) => {
            if (response?.data?.message === "Auction code already exists") {
                axios.get(GENERATEAUCTIONCODE).then((response: any) => {
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        dispatch(loader(false))
                        return;
                    }
                    else {
                        const auctionCode = response.data.data;
                        const updatedData = { ...e, auctionCode }
                        onSubmit(updatedData)
                        dispatch(loader(false))
                    }
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error)
                })
            } else if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                dispatch(loader(false));
                return;
            } else {
                const auctionId = response.data.data;
                dispatch(setAuctionCodeAndTitle({ auctionCode: data.auctionCode, auctionTitle: data.auctionTitle }))
                if (type === "create") {
                    dispatch(setAuctionId(auctionId))
                } else {
                    dispatch(setCloneAuctionId(auctionId))
                    dispatch(setCloneTab([...clonedTabs, ADDRESSDATES]))
                }
                toast.success(AuctionMessages.auctionInfoMessages.auctionCreated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                dispatch(setAuctionInfoActive({ activeStep: 2, activeLabel: "Logo & Photos" }))
                dispatch(loader(false));
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error)
        })
    }

    const updateAuction = (data: any, type: string) => {
        axios.post(UPDATEADDRESSDATES, { ...data, auctionId: cloneAuctionId > 0 ? cloneAuctionId : auctionIds }, config).then((response: any) => {
            dispatch(loader(false))
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                toast.success(AuctionMessages.auctionInfoMessages.auctionUpdated, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                dispatch(setAuctionCodeAndTitle({ auctionCode: data.auctionCode, auctionTitle: data.auctionTitle }))
                dispatch(setAuctionInfoActive({ activeStep: 2, activeLabel: "Logo & Photos" }))
                dispatch(loader(false))
                //this if condition called when hammer selected auction's title changed 
                if (getGlobalAuction?.value == data?.auctionId) {
                    const auction = { title: data?.auctionTitle, label: `${data.auctionCode} - ${data?.auctionTitle}`, value: data?.auctionId }
                    dispatch(setAuctionHammerData(auction));
                    dispatch(sellerSelectAuction(auction));
                    dispatch(salesRepSelectAuction(auction));
                    window.dispatchEvent(new Event("storage"));
                }

            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error)
        })
    }

    const onSubmit = (e: any) => {
        if (endDateTimeEr) {
            return;
        }
        if (e.status == 3 && auctionStatusFromDB.current !== "") {
            Swal.fire({
                icon: "warning",
                title: "Are you Sure ?",
                text: "You've marked status of auction as Sold",
                showCancelButton: true,
                confirmButtonColor: '#006800',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, continue'
            }).then((result) => {
                if (result.isConfirmed) {
                    auctionStatusFromDB.current = ""
                    onSubmit(e)
                }
            });
            return
        }
        dispatch(loader(true));

        onGetDefaultValues(e, (countryId: any, stateId: any, cityId: any, countyId: any) => {
            const startDate = momentUtil.convertTimeToUtc(e.startDateTime);
            const endDate = momentUtil.convertTimeToUtc(e.endDateTime);
            const newData = {
                ...e,
                latitude: e?.latitude?.toString(),
                longitude: e?.longitude?.toString(),
                addressLine1: e.add1,
                addressLine2: e.address2,
                country: parseInt(countryId),
                state: parseInt(stateId),
                city: parseInt(cityId),
                county: parseInt(countyId),
                // countyName: e?.county1,
                zipCode: e.zip,
                startDateTime: startDate,
                endDateTime: endDate,
                auctionBufferTime: `${e?.auctionBufferTimeHrs}:${e?.auctionBufferTimeMins}:00`,
                auctionId: parseInt(auctionId),
                auctionClosingTime: `00:${e?.auctionClosingTime}:00`,
                companyId: companyId,
                isActive: true,
                isDelete: false,
                auctionWebText: webText
            }
            if (mode === "create") {
                dispatch(loader(true))
                if (auctionIds === 0) {
                    createCloneAuction({ ...newData, auctionId: auctionIds > 0 ? auctionIds : 0 }, "create", e)
                } else {
                    updateAuction(newData, "edit")
                }
                return
            } else if (mode === "edit") {
                dispatch(loader(true))
                updateAuction(newData, "edit")
                return
            } else if (mode === "clone") {
                dispatch(loader(true))
                if (cloneAuctionId === 0) {
                    createCloneAuction({ ...newData, auctionId: cloneAuctionId > 0 ? cloneAuctionId : 0 }, "clone", e)
                } else {
                    updateAuction(newData, "edit")
                }
                return
            }
        })
    }


    const getBidIncrementOptions = () => {
        axios.get(GETALLBIDINCREMENT).then((response) => {
            if (response?.data?.success === "Fail") {
                return;
            }
            else {
                const defaultBidIncrement = getValues("defaultBidIncrement")
                const data = response.data.data?.filter((i: any) => i?.bidIncrementFor == 1).map((i: any) => {
                    return { label: i?.label, value: i?.bidIncrementId, isDefault: i?.isDefault }
                })
                if (defaultBidIncrement === "") {
                    setValue('defaultBidIncrement', data.find((i: any) => i.isDefault === true)?.value)
                }
                setBidIncrementOptions(data);
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        });
    }

    const checkAuctionCodeExists = () => {
        const code = getValues('auctionCode')
        const valid = mode === "edit" || (mode === "create" && auctionId > 0) || (mode === "clone" && cloneAuctionId > 0)
        if (valid && errors?.auctionCode === undefined && code != auctionCodeFromDB.current) {
            dispatch(loader(true))
            axios.get(`${CHECKAUCTIONCODEEXISTS}?auctionCode=${code}`).then((response) => {
                dispatch(loader(false))
                const codeExists = response.data.data
                setAuctionCodeExists(codeExists)
                if (codeExists) {
                    auctionCodeExistErr.current = true
                    return
                }
            }).catch((error) => {
                dispatch(loader(false));
                handleError(error)
            })
        }
    }

    const handleAuctionCodeChange = () => {
        auctionCodeExistErr.current = false
        setAuctionCodeExists(true)
    }

    useEffect(() => {
        if (selectedStatus == 3) {
            setValue('displayOnWebsite', true)
            return
        }
        if (selectedStatus != 2) {
            setValue('displayOnWebsite', false)
        }
    }, [selectedStatus])

    useEffect(() => {
        clearErrors(["city", "country", "state", "zipCode", "addressLine1"])
    }, [addressLine1]);

    const getAuctionData = (id: any) => {
        dispatch(loader(true))
        axios.get(`${GETAUCTIONINFOBYID}?auctionId=${id}`, config).then((response: any) => {
            if (response?.data?.success === "Fail") {
                dispatch(loader(false))
                return;
            }
            else {
                const data = response?.data?.data
                if (mode !== "clone") {
                    dispatch(setAuctionId(data.auctionId))
                }

                let timeString = data?.auctionClosingTime; // "00:08:00"
                // Use moment to parse the time string
                let time = moment(timeString, 'HH:mm:ss');
                // Get the minutes part
                let minutes = time.format('mm');

                const auctionWebText = data.auctionWebText === null ? {
                    auctionWebTextId: 0,
                    auctionId: mode !== "clone" ? parseInt(auctionIds) : parseInt(cloneAuctionId),
                    title: "",
                    description: "",
                    terms: "",
                    companyId: companyId,
                    isActive: true,
                    isDelete: false
                } : data.auctionWebText
                const parsedNumber: any = parsePhoneNumber(`+${data?.phone1}`);
                const respCountryCode = parsedNumber ? parsedNumber.country?.toLowerCase() : null;
                if ((mode === "clone" && cloneAuctionId > 0) || mode !== "clone") {
                    dispatch(setAuctionCodeAndTitle({ auctionCode: data.auctionCode, auctionTitle: data.auctionTitle, auctionId: data.auctionId }))
                }
                setCountryCode(respCountryCode)
                setPrevCountryCode(respCountryCode)
                setFormValues(data)
                setAddress(data.addressLine1)
                setAddressLine1(data.addressLine1)
                setAddressLine2(data.addressLine2)
                setDefaultAuctionValue(data?.auctionType ? data?.auctionType : '')
                const timeZoneStartDate = momentUtil.parseTimeInUtc({ dateTimeString: data?.startDateTime });
                const timeZoneEndDate = momentUtil.parseTimeInUtc({ dateTimeString: data?.endDateTime });
                reset({
                    ...data,
                    status: mode === 'clone' && cloneAuctionId === 0 ? 2 : data.status,
                    auctionTitle: mode !== "clone" ? data.auctionTitle : cloneAuctionId > 0 && clonedTabs.includes(ADDRESSDATES) ? data.auctionTitle : "",
                    auctionType: selectedOption,
                    startDateTime: mode !== "clone" ? timeZoneStartDate : cloneAuctionId > 0 && clonedTabs.includes(ADDRESSDATES) ? timeZoneStartDate : "",
                    endDateTime: mode !== "clone" ? timeZoneEndDate : cloneAuctionId > 0 && clonedTabs.includes(ADDRESSDATES) ? timeZoneEndDate : "",
                    auctionBufferTimeHrs: data?.auctionBufferTime ? data?.auctionBufferTime?.toString().split(':')[0] : '00',
                    auctionBufferTimeMins: data?.auctionBufferTime ? data?.auctionBufferTime?.toString().split(':')[1] : '00',
                    auctionClosingTime: data?.auctionClosingTime ? minutes : '03',
                    add1: data?.addressLine1,
                    add2: data?.addressLine2 === 'null' ? "" : data?.addressLine2,
                    city1: data.cityName,
                    state1: data?.state1 !== '' ? data?.state1 : data.stateName,
                    country1: data?.country1 !== '' ? data?.country1 : data.countryName,
                    zip: data?.zipCode === 'null' ? data?.zipCode : data?.zipCode,
                    county1: data?.countyName === ' ' ? data?.countyName : data?.countyName,
                    terms: data?.auctionWebText?.terms ? data?.auctionWebText?.terms : ''
                })
                getBidIncrementOptions()
                setSelectedOpt(data?.bpBySalesScaleId)
                setValue('city1', data?.city1 ? data?.city1 : data?.cityName);
                setValue('state1', data?.state1 ? data?.state1 : data?.stateName);
                setValue('country1', data?.country1 ? data?.country1 : data?.countryName);
                setValue('address2', data?.addressLine2)
                setValue('zip', data?.zipCode)
                setValue('county1', data?.countyName)
                setWebText(auctionWebText)
                setLatitude(data.latitude)
                setLongitude(data.longitude)
                setValue('terms', data?.auctionWebText?.terms ?? '');
                setValue('auctionType', data?.auctionType)
                setStartDateTimeExisting(data?.startDateTime)
                setEndDateTimeExisting(data?.endDateTime)
                setIsResp(true)
                const status = mode === 'clone' && cloneAuctionId === 0 ? 2 : data.status
                auctionStatusFromDB.current = status == 3 ? "" : status
                auctionCodeFromDB.current = data.auctionCode
            }
            dispatch(loader(false));

        }).catch((error) => {
            dispatch(loader(false));
            handleError(error)
        })
    }

    const onEditorTermsChange = (value: any) => {
        setValue('terms', value, { shouldValidate: true });
        setWebText((prevState: any) => ({
            ...prevState,
            'terms': value
        }));
    }

    useEffect(() => {
        if (cloneAuctionId > 0 || auctionIds > 0) {
            const idToFetchAuction = cloneAuctionId > 0 && clonedTabs.includes(ADDRESSDATES) ? cloneAuctionId : auctionIds
            getAuctionData(idToFetchAuction)
            return
        }
        getBidIncrementOptions()
    }, []);

    const handleSelectChange = (event: any) => {
        const selectedValue: any = parseInt(event.target.value);
        setSelectedTimeValue(selectedValue)
        // if (selectedValue >= 3) {
        //     setSelectedTime(selectedValue);
        //     clearErrors(['auctionClosingTime'])
        //     // setErrorMessage(''); // Clear error message if valid selection
        // } 
    };

    const getBpData = () => {
        axios.get(GETBPBYSCALES, config).then((response) => {
            dispatch(loader(false))
            if (response?.data?.success === "Fail") {
                toast.error(response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                return;
            }
            else {
                const scaleData = response.data.data;
                const updatedScaleData = scaleData.map((data: any) => {
                    return {
                        ...data,
                        mode: "update",
                        uniqueId: uuidv4(),
                        lstBPBySalesRange: data.lstBPBySalesRange.map((rg: any) => { return { ...rg, uniqueId: uuidv4(), mode: "update" } })
                    }
                })
                const defaultBP = updatedScaleData.find((b: any) => b.isDefault === true)
                const defaultBPId = Object?.keys(defaultBP).length > 0 ? defaultBP.bpBySalesScaleId : ""
                if ((mode === 'clone' && cloneAuctionId === 0) || (mode === 'create' && auctionIds === 0)) {
                    setSelectedOpt(defaultBPId)
                    setValue('bpBySalesScaleId', defaultBPId)
                }
                setBpBySalesData(updatedScaleData)
            }
        }).catch((error) => {
            dispatch(loader(false));
            handleError(error);
        })
    }

    useEffect(() => {
        setSelectedOpt(getValues('bpBySalesScaleId'))
    }, [watchScales])

    useEffect(() => {
        getBpData()
        if (isResp || mode === 'create') {
            if ((mode === 'clone' && cloneAuctionId === 0) || (mode === 'create' && auctionIds === 0)) {
                dispatch(loader(true))
                axios.get(GENERATEAUCTIONCODE).then((response) => {
                    dispatch(loader(false))
                    if (response?.data?.success === "Fail") {
                        toast.error(response?.data?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                        });
                        return;
                    }
                    else {
                        const auctionCode = response.data.data;
                        setValue("auctionCode", auctionCode)
                    }
                }).catch((error) => {
                    dispatch(loader(false));
                    handleError(error)
                })
            }
        }
    }, [isResp])

    // to render btn text
    useEffect(() => {
        let text = ""
        if (mode !== "clone") {
            text = auctionIds > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
        } else {
            text = cloneAuctionId > 0 ? CommonMessages.updatedNext : CommonMessages.saveNext
        }
        setBtnTxt(text)
    }, [])

    const updateCountryCode = (newAddress: any, code: any) => {
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="position-relative for-loading" >
                <div className="row g-3 ">
                    <div className="col-lg-9">
                        <label className="form-label">
                            Auction Title <sup className="text-danger">*</sup>
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Auction Title"
                            {...register('auctionTitle')}
                            maxLength={101}
                        />
                        {errors.auctionTitle && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.auctionTitle.message}</span>}
                    </div>
                    <div className="col-lg-3">
                        <label className="form-label">Auction Type <sup className="text-danger">*</sup></label>
                        <div className="position-relative">
                            <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select"
                                options={auctionType}
                                {...register('auctionType')}
                                value={auctionType?.find((option: any) => option?.value == auctionTypeValue)}
                                onChange={(e: any) => {
                                    setValue('auctionType', e.value)
                                    dispatch(setAuctionType(e.value));
                                    const selectedValue = e.value;
                                    const selectedOption = auctionType.find(
                                        (option) => option.value == selectedValue
                                    );
                                    setSelectedAuction(selectedOption);
                                }}
                                className='isActive w-100-40'
                            />

                            <Tippy content={selectedAuction?.content}>
                                <i className="eye_icons position-absolute fa-solid fa-circle-info p-2" style={{ cursor: "pointer" }}  ></i>
                            </Tippy>
                        </div>
                        {errors.auctionType && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.auctionType.message}</span>}
                    </div>
                    <div className="col-xxl-3 col-lg-3 col-md-12">
                        <label className="form-label">
                            Default Bid Increment <sup className="text-danger">*</sup>
                        </label>
                        <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            options={bidIncrementOptions}
                            {...register('defaultBidIncrement')}
                            value={bidIncrementOptions?.find((option: any) => option?.value == defaultBidIncrement)}
                            onChange={(e: any) => setValue('defaultBidIncrement', e.value)}
                            className='isActive'
                        />
                        {errors.defaultBidIncrement && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.defaultBidIncrement.message}</span>}
                    </div>
                    <div className='col-lg-3'>
                        <label className="form-label">Auction Code <sup className="text-danger">*</sup></label>
                        <div className="position-relative">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Auction Code"
                                {...register('auctionCode', {
                                    onChange: handleAuctionCodeChange,
                                    onBlur: checkAuctionCodeExists,
                                })}
                                maxLength={10}
                                onKeyDown={handleKeyDown}
                            />
                            {errors.auctionCode && <span className='text-danger' style={{ fontSize: "12px", padding: 0 }}>{errors.auctionCode.message}</span>}
                            {auctionCodeExistErr.current === true && <span className='text-danger' style={{ fontSize: "12px", padding: 0 }}>Auction Code already exists</span>}
                        </div>
                    </div>
                    <div className="col-xxl-3 col-lg-3 col-md-12">
                        <label className="form-label">
                            Auction Status <sup className="text-danger">*</sup>
                        </label>
                        <Select
                            classNamePrefix="dpaSelect"
                            placeholder="Select"
                            options={auctionStatus}
                            {...register('status')}
                            value={auctionStatus?.find((option: any) => option?.value == selectedStatus)}
                            onChange={(e: any) => setValue('status', e.value)}
                            className='isActive'
                        />
                        {errors.status && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.status.message}</span>}
                    </div>
                    <div className="col-xxl-3 col-lg-3 col-md-12">
                        <label className="form-label ">Display on website</label>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" disabled={(selectedStatus != 2)} {...register('displayOnWebsite')} />
                        </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <label className="form-label">
                            Start Date and Time ({defaultTimezone}) <sup className="text-danger">*</sup>
                        </label>
                        <Controller
                            name="startDateTime"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <div className="date_icon">
                                    <DateTime
                                        onChange={(date) => {
                                            onChange(date);
                                        }}
                                        closeOnSelect={true}
                                        dateFormat="MM/DD/y"
                                        timeFormat="hh:mm A"
                                        value={value}
                                        isValidDate={disablePastDt}
                                        inputProps={
                                            {
                                                placeholder: 'Select Date and Time',
                                                onInput: (e: any) => {
                                                    const cursorPosition: any = e.target.selectionStart;
                                                    onChange(e.target.value);
                                                    e.target.setSelectionRange(cursorPosition, cursorPosition);
                                                },
                                            }} />
                                </div>
                            )}
                        />
                        {errors.startDateTime && (
                            <span className="text-danger" style={{ fontSize: "12px" }}>
                                {errors.startDateTime.message}
                            </span>
                        )}
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <label className="form-label">
                            End Date and Time ({defaultTimezone}) <sup className="text-danger">*</sup>
                        </label>
                        <Controller
                            name="endDateTime"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <div className="date_icon">
                                    <DateTime
                                        onChange={(date) => {
                                            onChange(date);

                                        }}
                                        closeOnSelect={true}
                                        isValidDate={disablePastDt}
                                        value={value}
                                        initialValue={value}
                                        dateFormat="MM/DD/y"
                                        timeFormat="hh:mm A"
                                        inputProps={{
                                            placeholder: 'Select Date and Time',
                                            onInput: (e: any) => {
                                                const cursorPosition: any = e.target.selectionStart;
                                                onChange(e.target.value);
                                                e.target.setSelectionRange(cursorPosition, cursorPosition);
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        />

                        {endDateTime && endDateTimeEr ? (
                            <span className='text-danger' style={{ fontSize: "12px" }}>
                                {endDateTimeEr}
                            </span>
                        )
                            :
                            errors.endDateTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.endDateTime.message}</span>}
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <label className="form-label">
                            Contact Agent <sup className="text-danger">*</sup>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Contact Agent"
                            {...register('contactAgent')}
                            maxLength={51}
                        />
                        {errors.contactAgent && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.contactAgent.message}</span>}
                    </div>
                    <div className="col-lg-12">
                        <label className="form-label">
                            Short Description <sup className="text-danger">*</sup>
                        </label>
                        <textarea
                            className="form-control"
                            placeholder="Short description about auction"
                            rows={4}
                            maxLength={maxWordCount}
                            {...register('shortDescription', { onChange: (e) => setRemainingCount(wordCounter(e.target.value, maxWordCount)) })}
                        ></textarea>
                        {remainingCount != maxWordCount && <div className={remainingCount <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                            {remainingCount <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount}`}
                        </div>}
                        {errors.shortDescription && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.shortDescription.message}</span>}
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <label className="form-label">Website</label>
                        <input
                            className="form-control"
                            placeholder="Website"
                            {...register('website')}
                            type="url"
                        />
                        {errors.website && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.website.message}</span>}
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <label className="form-label">Auction Email <sup className="text-danger">*</sup></label>
                        <input
                            className="form-control"
                            placeholder="Auction Email"
                            {...register('auctionEmail')}
                            maxLength={51}

                        />
                        {errors.auctionEmail && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.auctionEmail.message}</span>}
                    </div>
                    <AddressForm
                        errors={errors}
                        onHandleChangeAddressLine={handleChangeAddressLine}
                        register={register}
                        setValue={setValue}
                        setCountryCode={setCountryCode}
                        onAddressChange={updateCountryCode}
                        onSetAddress={setAddress}
                        watch={watch}
                        trigger={trigger}
                    />
                    <div className="col-lg-3 col-md-6">
                        <label className="form-label">Phone Number <sup className="text-danger">*</sup></label>
                        <div className="form-icon-container for_password">
                            <Controller
                                // name="phone1"
                                {...register('phone1')}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        value={value}
                                        onChange={onChange}
                                        country={countryCode}
                                        priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                    />
                                )}
                            />
                            {errors.phone1 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone1.message}</span>}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <label className="form-label">Alternate Number</label>
                        <Controller
                            // name="phone2"
                            {...register('phone2')}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    value={value}
                                    onChange={onChange}
                                    country={countryCode}
                                    priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                                />
                            )}
                        />
                        {errors.phone2 && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.phone2.message}</span>}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <label
                            className="form-label">
                            Auction Buffer Time
                            <Tippy content="Add buffer time to display before auction time starts In">
                                <i className="eye_icons fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                            </Tippy>
                        </label>

                        <div className='d-flex dur'>
                            <select {...register('auctionBufferTimeHrs')} className='form-select scrollbar'>
                                <option value="00">00 hours</option>
                                {
                                    hours.map((num, index) => {
                                        return (
                                            <option value={num} key={index}>{num} hours</option>
                                        )
                                    })
                                }
                            </select>
                            <span className='ms-2 me-2'>:</span>
                            <select {...register('auctionBufferTimeMins')} className='form-select scrollbar'>
                                <option value="00">00 mins</option>
                                {
                                    mins.map((num, index) => {
                                        return (
                                            <option value={num} key={index}>{num} mins</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {errors.fax && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.fax.message}</span>}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <label className="form-label">Auction Disappear Time
                            <Tippy content="Add time when no lots in live auction then liveboard will disappear after entered Auction Disappear Time">
                                <i className="eye_icons fa-solid fa-circle-info ps-2" style={{ cursor: "pointer" }}  ></i>
                            </Tippy>
                        </label>
                        <div className='d-flex dur'>
                            <select {...register('auctionClosingTime')} className='form-select  text-start ps-2 scrollbar' defaultValue={selectedTime}>
                                {
                                    disAppearMins
                                        ?.filter((num: any) => parseInt(num) >= 3) // Filter out values less than 3
                                        .map((num: any, index: any) => (
                                            <option selected={num === "03"} value={num} key={index}>
                                                {num} mins
                                            </option>
                                        ))
                                }
                            </select>
                        </div>
                        {errors.auctionClosingTime && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.auctionClosingTime.message}</span>}

                    </div>
                    <div className="col-lg-12 ">
                        <label className="form-label">Custom Notice</label>
                        <textarea
                            className="form-control"
                            rows={4}
                            maxLength={maxWordCount}
                            placeholder="Custom Notice"
                            {...register('customeNotice', { onChange: (e) => setRemainingCount3(wordCounter(e.target.value, maxWordCount)) })}
                        ></textarea>
                        {remainingCount3 != maxWordCount && <div className={remainingCount3 <= 0 ? 'text-danger' : ''} style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', fontSize: "12px" }}>
                            {remainingCount3 <= 0 ? 'Maximum word limit reached' : `Remaining words: ${remainingCount3}`}
                        </div>}
                        {errors.customeNotice && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.customeNotice.message}</span>}
                    </div>
                    <div className="row g-3 mb-4">
                        <div className="col-lg-4 col-sm-4">
                            <label className="form-label mb-2">Default BP Scale for this Auction <sup className="text-danger">*</sup></label>
                            <Select
                                classNamePrefix="dpaSelect"
                                placeholder="Select"
                                className='isActive'
                                {...register('bpBySalesScaleId')}
                                name='bpBySalesScaleId'
                                options={bpBySalesData}
                                value={bpBySalesData?.find((option: any) => option?.bpBySalesScaleId == watchScales)}
                                onChange={(e: any) => setValue('bpBySalesScaleId', e.bpBySalesScaleId)}
                            />
                            {errors.bpBySalesScaleId && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.bpBySalesScaleId.message}</span>}
                        </div>
                    </div>
                    {selectedData && <div className="row g-3 mb-4">
                        <div className="col-lg-12">
                            <div id="tableExample2" data-list="{&quot;valueNames&quot;:[&quot;name&quot;,&quot;upto&quot;,&quot;Percentage&quot;],&quot;Fixed&quot;:5,&quot;Percent&quot;Min&quot;Cap&quot;:true}">
                                <div className="table-responsive scrollbar">
                                    <table className="table table-bordered mb-0 fs--1">
                                        <thead>
                                            <tr>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">From</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Upto</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Percentage %</th>
                                                <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Fixed Amount</th>
                                                {/* <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Level Min</th>
                                            <th className="bg-gradiant border-bottom border-top ps-3 pe-3 pt-2 pb-2">Level Cap</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="list scroll">
                                            <>
                                                {selectedData && selectedData?.lstBPBySalesRange.map((data: any, index: any) => (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle ps-3 pe-3 pt-2 pb-2 name">{formatNumber(data.from)}</td>
                                                        <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{formatNumber(data.upTo)}</td>
                                                        <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{data.percentage}</td>
                                                        <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{formatCurrency(data.fixedAmount)}</td>
                                                        {/* <td className="align-middle ps-3 pe-3 pt-2 pb-2 upto">{data.levelMin}</td>
                                                    <td className="align-middle ps-3 pe-3 pt-2 pb-2 Percentage">{data.levelCap}</td> */}
                                                    </tr >
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="col-lg-12 ">
                        <label className="form-label">Terms <sup className="text-danger">*</sup></label>
                        <Tabs
                            defaultActiveKey="source"
                            className="mb-3 nav-underline border-bottom "
                        >
                            <Tab eventKey="source" title="Source">
                                {/* {editor} */}
                                {/* Add custom ckeditor */}
                                <CustomCkEditor
                                    data={terms}
                                    onTextChanged={onEditorTermsChange} />
                            </Tab>
                            <Tab eventKey="preview" title="Preview">
                                <div className="card box-shadow">
                                    <div className="card-body ck_editor">
                                        {/* {parseHtml(webText?.terms)} */}
                                        {terms && parse(terms)}
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                        {/* {isTermsError && <span className='text-danger' style={{ fontSize: "12px" }}>{"Term are required"}</span>} */}
                        {errors.terms && <span className='text-danger' style={{ fontSize: "12px" }}>{errors.terms.message}</span>}
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between border-top bg-white p-3 fix-bottom">
                    <div className="">
                        <Link className="btn btn-outline-danger" type="button" data-bs-dismiss="modal" to={routeConstant.AUCTION}>Cancel</Link>
                    </div>
                    <div className="d-flex flex-wrap justify-content-end ms-auto">
                        <ModifiedBy data={formValues} />
                        <LastModified data={formValues} />
                    </div>
                    <button className="btn btn-primary ms-2" type="submit">{btnTxt}</button>
                </div>
            </form >
        </>
    )
}
export default AddressDates;

