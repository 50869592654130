import * as actions from '../../common/types'
import { Action } from '../../common/interface'
import { themes } from '../../common/constants';

export interface ThemeState {
    themeData: object,
    colorMode: string,
}
const initialState: ThemeState = {
    themeData: {
        themeName: 'theme1',
        themeColor: "#176707",
    },
    colorMode: 'light'
};
export const ThemeReducer = (
    state: ThemeState = initialState,
    action: Action
) => {
    switch (action.type) {
        case actions.SETTHEMECOLOR: {
            const foundTheme = themes?.find(theme => theme.themeName === action.payload.themeName);
            return { ...state, themeData: foundTheme ? action.payload : initialState.themeData }
        }
        case actions.SETCOLORMODE: {
            return { ...state, colorMode: action.payload }
        }
        default:
            return state
    }
}
