import React from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import * as routeConstants from '../../common/routeConstants'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import { Breadcrumb } from '../../common/interface'
import logo from '../../assets/img/dpa-logo.jpg'

const PrivacyPolicy = () => {
    const breadcrumbData: Breadcrumb[] = [
        { label: 'Home', url: routeConstants.DASHBOARD },
        { label: ' Privacy Policy', url: "" }
    ]
    
    const navigate = useNavigate()
    const goback =()=>{
        navigate(routeConstants.DASHBOARD)
    }
    return (
        <main className="">
            <section className="pt-4 pb-5 bg_light privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className='p-4 text-center'>
                                <img src={logo} className='p-logo' />
                            </div>
                        </div>
                        <div className='col-12 mb-5'>
                            <div className='p-3 bg-light'>
                                <BreadCrumbs data={breadcrumbData} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex flex-wrap content_pages">
                                <h2 className="text-dark fs-26 mb-4">Privacy Policy</h2>
                                <p>
                                    This website may, from time to time, use one or more analytics
                                    service providers, including, for example, Google, or others. This
                                    website may use the following Google analytics features: Adwords
                                    and Remarketing, and other services and features. We may combine
                                    information gathered from one or more of these services. For more
                                    information on how Google collects and uses the information
                                    collected through our use of the Google analytics and advertising
                                    services, see the Google, Inc. Privacy Policy.
                                </p>
                                <p>
                                    For more information about the Google analytics features, click
                                    here. For more information on how to see and change your Google Ad
                                    Settings, click here. We, or other third- party vendors, including
                                    Google, may use cookies in connection with your use of the DPA
                                    Auctions website. Google Ads remarketing allows us to tailor our
                                    marketing to better suit your needs and only display ads that are
                                    relevant to you. Cookies may transmit information about you and
                                    your use of this website. Cookies may track activities over time
                                    and across websites. You can disable some (but not all) Cookies in
                                    your device or browser settings, but doing so may affect your
                                    ability to use this website.
                                </p>
                                <hr />
                                <h6 className="text-dark fs-20 mt-5 mb-3">Your California Privacy Rights</h6>
                                <p>
                                    If you are a California resident, California law may provide you
                                    with additional rights regarding our use of your personal
                                    information. To learn more about your California privacy rights,
                                    visit this page.
                                </p>
                                <p>
                                    California’s “Shine the Light” law (Civil Code Section § 1798.83)
                                    permits users of our website that are California residents to
                                    request certain information regarding our disclosure of personal
                                    information to third parties for their direct marketing purposes.
                                    To make such a request, please write or email to us at:
                                </p>
                                <p>
                                    <span className="location_in">
                                        DPA Auctions
                                        <br />
                                        PO Box 654
                                        <br />
                                        Fremont, NE 68025
                                    </span>
                                    <br />
                                    <br />
                                    <Link to="mailto:marketing.info@dpaauctions.com">
                                        Marketing.info@dpaauctions.com
                                    </Link>
                                    <br />
                                    <br />
                                    <Link to="tel: 4850-3638-7034">4850-3638-7034</Link>
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <button type="button" className="btn btn-outline-primary" onClick={goback} ><span> Go Back to Home </span></button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        

    )
}

export default PrivacyPolicy